import './Bold.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    inline?: boolean
}

export const Bold: React.SFC<Props> = props => {
    const { children, className, inline } = props
    const bem = new BEM('Bold')

    if (inline) {
        return <span className={bem.getClassName(className)}>{children}</span>
    }

    return <p className={bem.getClassName(className)}>{children}</p>
}
