import React from 'react'
import { localize, notification, permissions } from '~/bootstrap'
import { ActionButton } from '~/components/Core/ActionButton/ActionButton'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Guard } from '~/components/Core/Guard/Guard'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AlertType } from '~/generated/graphql'
import { CustomerItemType, ItemCustomerInfoSummary } from '../Customer/ItemCustomerInfoSummary'
import { CreateTaskActionButton } from '../Task/CreateTaskActionButton'
import { ArchiveAlertModal } from './ArchiveAlert/ArchiveAlertModal'
import { EditInboxItemImpactActionButton } from './EditInboxItemImpactLevel/EditInboxItemImpactActionButton'
import { UnarchiveAlertMutateFN, UnarchiveAlertMutation } from './UnarchiveInboxNewsItem/UnarchiveAlertMutation'

interface Props {
    alert: Alert
}

type Alert = Pick<AlertType, 'id' | '__typename' | 'name' | 'impactLevel'> & {
    allPresentDepartments: CustomerItemType['departments']
    customerFrameworks: CustomerItemType['customerFrameworks']
    inboxStatus?: { archivedAt?: Date }
}

export class InboxDetailActionButtons extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { alert } = this.props
        const { customerFrameworks, allPresentDepartments } = this.props.alert

        return (
            <Row>
                <Guard condition={permissions.canArchiveInboxItems(this.context.activeDepartmentId)}>
                    {!!alert.inboxStatus?.archivedAt ? this.renderUnarchiveButton() : this.renderArchiveButton()}
                </Guard>
                <EditInboxItemImpactActionButton actionButton defaultImpact={alert.impactLevel} alertIds={[alert.id]} />
                <Guard condition={permissions.canCreateTasks(this.context.activeDepartmentId)}>
                    <CreateTaskActionButton linkedItem={alert} />
                </Guard>
                <ActionButton
                    tooltipContent={
                        <ItemCustomerInfoSummary item={{ departments: allPresentDepartments, customerFrameworks }} />
                    }
                    icon={IconType.info}
                />
            </Row>
        )
    }

    private renderArchiveButton() {
        const { id, name } = this.props.alert

        return (
            <ModalManager
                renderModal={closeModal => (
                    <ArchiveAlertModal alertId={id} alertName={name} requestClose={closeModal} />
                )}
                render={openModal => (
                    <ActionButton
                        icon={IconType.archive}
                        onClick={openModal}
                        tooltipContent={
                            <Paragraph small emphasis>
                                {localize.translate(t => t.Generic.archive)}
                            </Paragraph>
                        }
                    />
                )}
            />
        )
    }

    private renderUnarchiveButton() {
        return (
            <UnarchiveAlertMutation>
                {(mutate, { loading }) => (
                    <ActionButton
                        icon={IconType.unarchive}
                        tooltipContent={
                            <Paragraph small emphasis>
                                {localize.translate(t => t.Generic.dearchive)}
                            </Paragraph>
                        }
                        loading={loading}
                        onClick={this.handleUnarchive(mutate)}
                    />
                )}
            </UnarchiveAlertMutation>
        )
    }

    private handleUnarchive = (mutate: UnarchiveAlertMutateFN) => async () => {
        const { alert } = this.props

        const response = await mutate({
            variables: {
                alertId: alert.id,
                departmentId: this.context.activeDepartmentId,
                customerSlug: this.context.customer.slug,
            },
        })

        if (response && response.data && response.data.unarchiveAlertOnInbox) {
            notification.success(localize.translate(t => t.Generic.successfullyUnarchived))
        }
    }
}
