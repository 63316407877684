import './DepartmentSwitchPopup.scss'

import React from 'react'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { localize } from '~/bootstrap'

import { Modal, ModalSize } from '~/components/Core/Feedback/Modal/Modal'
import { Row } from '~/components/Core/Layout/Row'
import { LabelTag } from '~/components/Core/DataDisplay/LabelTag/LabelTag'
import { IconType } from '~/components/Core/Icon/IconType'
import { Icon } from '~/components/Core/Icon/Icon'
import { BEM } from '~/services/BEMService'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Column } from '~/components/Core/Layout/Column'
interface Props {
    to?: string
    departmentIdToToggleTo: number
    departmentNameToToggleTo: string
    onToggle?: () => void
    render?: (openModal: () => void) => React.ReactNode
    title?: string
}

export class DepartmentSwitchPopup extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    private loc = localize.namespaceTranslate(t => t.Department.DepartmentSwitchPopup)
    private bem = new BEM('DepartmentSwitchPopup')

    public render() {
        const { render, title } = this.props

        return (
            <>
                <ModalManager
                    openOnMount={!render}
                    render={render}
                    renderModal={closeModal => (
                        <Modal
                            className={this.bem.getClassName()}
                            title={title || this.loc(t => t.navigateToDepartmentTitle)}
                            requestClose={closeModal}
                            actions={() => this.renderActions(closeModal)}
                            modalSize={ModalSize.small}
                        >
                            <Column bigBottomSpacing={true}>
                                {this.renderError()}
                                {this.renderMessage()}
                                {this.renderSwitchContent()}
                            </Column>
                        </Modal>
                    )}
                />
                {!render && <NoResults />}
            </>
        )
    }

    private renderActions = (requestClose: () => void) => {
        const { departmentIdToToggleTo } = this.props
        const isUnauthorized = !this.context.departments.some(d => d.id === departmentIdToToggleTo)

        return (
            <Row alignRight={true}>
                <Button type={ButtonType.tertiary} onClick={requestClose}>
                    {localize.translate(t => t.Generic.cancel)}
                </Button>
                <Button disabled={isUnauthorized} onClick={this.handleClick}>
                    {this.loc(t => t.switchToDepartment)}
                </Button>
            </Row>
        )
    }

    private handleClick = () => {
        const { onToggle, departmentIdToToggleTo } = this.props

        this.context.toggleDepartment(departmentIdToToggleTo)

        if (onToggle) {
            onToggle()
        }
    }

    private renderError() {
        const { departmentIdToToggleTo } = this.props
        const isAuthorized = this.context.departments.some(d => d.id === departmentIdToToggleTo)

        if (isAuthorized) {
            return
        }

        return (
            <Row flexStart={true} smallSpacing={true} className={this.bem.getElement('error-container')}>
                <Icon type={IconType.attention} className={this.bem.getElement('error-icon')} />
                <Paragraph emphasis={true} className={this.bem.getElement('error-text')}>
                    {this.loc(t => t.navigateToDepartmentWarning)}
                </Paragraph>
            </Row>
        )
    }

    private renderMessage() {
        return (
            <Paragraph className={this.bem.getElement('message')}>
                {this.loc(t => t.navigateToDepartmentConfirmation)}
            </Paragraph>
        )
    }

    private renderSwitchContent() {
        const { departmentNameToToggleTo } = this.props

        return (
            <Row fullWidth={true} className={this.bem.getElement('department-container')}>
                <Column>
                    <div>
                        <Paragraph>{this.loc(t => t.currentDepartment)}</Paragraph>
                    </div>
                    <div>
                        <LabelTag
                            className={this.bem.getElement('active-department-text')}
                            label={this.context.activeDepartment.name}
                            subtle={true}
                        />
                    </div>
                </Column>
                <Icon type={IconType.nextArrow} className={this.bem.getElement('arrow-icon')} />
                <Column>
                    <div>
                        <Paragraph>{this.loc(t => t.requiredDepartment)}</Paragraph>
                    </div>
                    <div>
                        <LabelTag label={departmentNameToToggleTo} subtle={true} />
                    </div>
                </Column>
            </Row>
        )
    }
}
