import React from 'react'
import { MutationFn } from 'react-apollo'
import { ModalStep, MultiStepModal } from '~/components/Core/Feedback/Modal/MultiStepModal'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    CreateCustomerFrameworkMutation,
    CreateCustomerFrameworkMutationVariables,
    CustomerFrameworkColorEnumType,
    CustomerFrameworkIconEnumType,
} from '~/generated/graphql'
import { CreateCustomerFramework } from '../CustomerFrameworkMutations/CreateCustomerFramework'
import { CreateCustomerFrameworkModalFrameworkContainer } from './CreateCustomerFrameworkModalFrameworkContainer'
import { CreateCustomerFrameworkModalProfileContainer } from './CreateCustomerFrameworkModalProfileContainer'
import { RadioItem } from '~/components/Domain/CustomerFramework/CustomerFrameworkRadio'
import { localize, notification } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { CustomerFrameworkWithFramework } from '~/views/Customer/Settings/CustomerFramework/CustomerFrameworkOverviewView'

interface Props {
    closeModal: () => void
    customerFrameworks: CustomerFrameworkWithFramework[]
}

interface State {
    selectedFrameworkId: number | null
    frameworkThemeIds: number[]
    name: string | null
    selectedColor?: CustomerFrameworkColorEnumType
    selectedIcon?: CustomerFrameworkIconEnumType
}

export class CreateCustomerFrameworkMultiStepModal extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {
        selectedFrameworkId: null,
        frameworkThemeIds: [],
        selectedColor: undefined,
        name: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Settings.CustomerFrameworks.createMultiModal)

    public render() {
        const { closeModal } = this.props

        return (
            <CreateCustomerFramework>
                {(mutate, { loading }) => {
                    const steps = this.getSteps(mutate, loading)

                    return <MultiStepModal requestClose={closeModal} steps={steps} />
                }}
            </CreateCustomerFramework>
        )
    }

    private getSteps(
        mutate: MutationFn<CreateCustomerFrameworkMutation, CreateCustomerFrameworkMutationVariables>,
        loading: boolean
    ): ModalStep[] {
        const { selectedColor, selectedFrameworkId } = this.state
        const { customerFrameworks } = this.props

        const usedFrameworkIds = customerFrameworks.map(({ framework }) => framework.id)

        return [
            {
                name: 'frameworks',
                title: this.loc(t => t.title),
                content: () => (
                    <CreateCustomerFrameworkModalFrameworkContainer
                        handleOnSelect={this.handleOnFrameworkSelect}
                        selectedFrameworkId={selectedFrameworkId}
                        usedFrameworkIds={usedFrameworkIds}
                    />
                ),
                actions: setActiveIndex => requestClose => this.renderFirstModalActions(requestClose, setActiveIndex),
            },
            {
                name: 'profile',
                title: this.loc(t => t.title),
                content: () => (
                    <CreateCustomerFrameworkModalProfileContainer
                        selectedColor={selectedColor}
                        handleOnNameChange={this.handleOnNameChange}
                        handleOnRadioChange={this.handleOnRadioChange}
                    />
                ),
                actions: setActiveIndex => requestClose =>
                    this.renderFinalModalActions(mutate, loading, requestClose, setActiveIndex),
            },
        ]
    }

    private renderFirstModalActions = (requestClose?: () => void, setActiveIndex?: (index: number) => void) => {
        const isDisabled = !this.state.selectedFrameworkId

        return (
            <Row smallSpacing={true} alignRight={true}>
                <Button type={ButtonType.tertiary} onClick={requestClose}>
                    {localize.translate(t => t.Core.Modal.cancel)}
                </Button>
                <Button
                    onClick={setActiveIndex ? () => setActiveIndex(1) : undefined}
                    disabled={isDisabled}
                    iconRight={IconType.nextArrow}
                >
                    {localize.translate(t => t.Generic.next)}
                </Button>
            </Row>
        )
    }

    private handleOnNameChange = (value: string | null) => {
        this.setState({ name: value })
    }

    private handleOnRadioChange = (option: RadioItem, name: string) => {
        if (name === 'color') {
            this.setState({
                selectedColor: option.color as CustomerFrameworkColorEnumType,
            })
            return
        }

        this.setState({
            selectedIcon: option.icon as CustomerFrameworkIconEnumType,
        })
    }

    private renderFinalModalActions(
        mutate: MutationFn<CreateCustomerFrameworkMutation, CreateCustomerFrameworkMutationVariables>,
        loading: boolean,
        requestClose?: () => void,
        setActiveIndex?: (index: number) => void
    ) {
        return (
            <Row spaceBetween={true}>
                <Button
                    type={ButtonType.secondary}
                    onClick={() => {
                        if (setActiveIndex) {
                            setActiveIndex(0)
                        }
                    }}
                    icon={IconType.backArrow}
                    disabled={loading}
                >
                    {localize.translate(t => t.Generic.previous)}
                </Button>
                <Row smallSpacing={true}>
                    <Button type={ButtonType.tertiary} onClick={requestClose} disabled={loading}>
                        {localize.translate(t => t.Core.Modal.cancel)}
                    </Button>
                    <Button onClick={() => this.handleSubmit(mutate)} loading={loading} disabled={loading}>
                        {this.loc(t => t.confirmLabel)}
                    </Button>
                </Row>
            </Row>
        )
    }

    private handleOnFrameworkSelect = (id: number, themeIds: number[]) => {
        this.setState({ selectedFrameworkId: id, frameworkThemeIds: themeIds })
    }

    private async handleSubmit(
        mutate: MutationFn<CreateCustomerFrameworkMutation, CreateCustomerFrameworkMutationVariables>
    ) {
        const { closeModal } = this.props
        const { selectedFrameworkId, frameworkThemeIds, selectedIcon, selectedColor, name } = this.state

        if (!selectedFrameworkId) {
            return
        }

        const response = await mutate({
            variables: {
                customerId: this.context.customer.id,
                frameworkId: selectedFrameworkId,
                themeIds: frameworkThemeIds,
                topicIds: [],
                customerFramework: {
                    name,
                    color: selectedColor,
                    icon: selectedIcon,
                },
            },
        })

        if (response && response.data?.createCustomerFramework) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))
            closeModal()
            this.context.refetch()
        }
    }
}
