import { sessionClient, errorClient } from '~/bootstrap'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import { ErrorType } from '~/services/ErrorService'
import introspectionQueryResultData from '../generated/introspection-result.json'
import { GraphQLError } from 'graphql'
import { config } from '~/config'

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(error => {
            // eslint-disable-next-line no-console
            console.log(`[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`)

            // If the error has an extention code publish that as the error type.
            if (error.extensions && error.extensions.code) {
                errorClient.publishError(error.extensions.code, error)
                return
            }

            errorClient.publishError(ErrorType.graphql, error)
        })
    }

    if (networkError) {
        errorClient.publishError(ErrorType.network, networkError as GraphQLError)

        // eslint-disable-next-line no-console
        console.log(`[Network error]: ${networkError}`)
    }
})

const endpoint = '/graphql/customer'

const httpLink = createUploadLink({
    uri: `${config.graphQLApi}${endpoint}`,
    credentials: 'same-origin',
    // // this is needed to not trigger csrf protection on the server
    headers: { 'Apollo-Require-Preflight': 'true' },
}) as ApolloLink

const authLink = new ApolloLink((operation, forward) => {
    const session = sessionClient.getSession()
    const authorization = session ? `Bearer ${session.token}` : ''
    const language = session ? session.language : 'nl'

    operation.setContext({
        headers: {
            authorization,
            language,
        },
    })

    return forward(operation)
})

const link = ApolloLink.from([authLink, errorLink, httpLink])

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
})

const cache = new InMemoryCache({ fragmentMatcher })

export const apolloClient = new ApolloClient({
    link: link as any,
    cache,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
        },
        query: {
            fetchPolicy: 'network-only',
        },
        mutate: {
            errorPolicy: 'none',
        },
    },
})
