import { userClient, localize } from '~/bootstrap'

import { isBefore, setSeconds, setMinutes, setHours } from 'date-fns'

export const getGreetingForTimeOfDay = () => {
    const user = userClient.getCurrentUser()
    const loc = localize.namespaceTranslate(t => t.Generic)

    if (!user) {
        return loc(t => t.hello)
    }

    const isBeforeHour = (hour: number) => isBefore(new Date(), setSeconds(setMinutes(setHours(new Date(), hour), 0), 0))

    const timeIsBeforeEndOfMidnight = isBeforeHour(6)

    const timeIsBeforeEndOfMorning = isBeforeHour(12)

    const timeIsBeforeEndOfNoon = isBeforeHour(18)

    const timeIsBeforeEndOfEvening = isBeforeHour(24)

    if (timeIsBeforeEndOfMidnight) {
        return loc(t => t.midnight, { user: user.profile.fullName })
    }

    if (timeIsBeforeEndOfMorning) {
        return loc(t => t.goodMorning, { user: user.profile.fullName })
    }

    if (timeIsBeforeEndOfNoon) {
        return loc(t => t.goodAfternoon, { user: user.profile.fullName })
    }

    if (timeIsBeforeEndOfEvening) {
        return loc(t => t.goodEvening, { user: user.profile.fullName })
    }

    return loc(t => t.goodDay, { user: user.profile.fullName })
}
