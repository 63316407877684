import React from 'react'
import { PageQuery, PageQueryOptions } from '~/components/Domain/PageQuery/PageQuery'
import {
    MonitoringReportTopicsDocument,
    MonitoringReportTopicsQuery as MonitoringReportTopics,
    MonitoringReportTopicsQueryVariables,
} from '~/generated/graphql'

interface Props {
    children: (data: MonitoringReportTopicsQueryType, options: PageQueryOptions) => React.ReactNode
}

export type MonitoringReportTopicsQueryType = NonNullable<MonitoringReportTopics['allDepartments']>

export class MonitoringReportTopicsQuery extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <PageQuery<MonitoringReportTopicsQueryType, MonitoringReportTopicsQueryVariables>
                query={MonitoringReportTopicsDocument}
                // without no-cache, graphql will cross fill topics from other departments
                fetchPolicy="no-cache"
            >
                {children}
            </PageQuery>
        )
    }
}
