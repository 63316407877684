import React from 'react'
import { localize } from '~/bootstrap'
import { Button } from '~/components/Core/Button/Button'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { DrawerModal } from '~/components/Core/Feedback/Modal/DrawerModal'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { Step } from '~/components/Core/Layout/Step'
import { StepContainer } from '~/components/Core/Layout/StepContainer'
import { MonitoringReportSelectTable } from './MonitoringReportSelectTable'
import { AddLinkedMonitoringReportModal } from '../AddLinkedMonitoringReportModal/AddLinkedMonitoringReportModal'
import { MonitoringReportsQueryType } from '../MonitoringReportsTable/MonitoringReportsQuery'

interface Props {
    onRequestClose: () => void
    onSubmit: (monitoringReport: MonitoringReportsQueryType) => void
    isMonitoringReportAdded: (monitoringReport: MonitoringReportsQueryType) => boolean
}

interface State {
    search?: string | null
}

export class SelectMonitoringReportModal extends React.PureComponent<Props, State> {
    public state: State = {
        search: null,
    }
    private loc = localize.namespaceTranslate(t => t.Monitoring.SelectMonitoringReportModal)

    public render() {
        const { onRequestClose, onSubmit } = this.props
        const { search } = this.state

        return (
            <DrawerModal title={this.loc(t => t.title)} requestClose={onRequestClose} hideButtons={true}>
                <StepContainer
                    hideStepCounter={true}
                    steps={[
                        {
                            content: () => (
                                <Step
                                    title={this.loc(t => t.steps.monitoringReportSelect)}
                                    titleActionComponent={
                                        <Row>
                                            <Search onChange={search => this.setState({ search })} />
                                            <ModalManager
                                                render={openModal => (
                                                    <Button onClick={openModal} icon={IconType.add} rounded={true}>
                                                        {this.loc(t => t.addMonitoringReport)}
                                                    </Button>
                                                )}
                                                renderModal={closeModal => (
                                                    <AddLinkedMonitoringReportModal
                                                        requestClose={closeModal}
                                                        onSubmit={control => this.handleOnSubmit(control, closeModal)}
                                                    />
                                                )}
                                            />
                                        </Row>
                                    }
                                >
                                    <MonitoringReportSelectTable
                                        search={search ? search : undefined}
                                        isMonitoringReportAdded={this.props.isMonitoringReportAdded}
                                        onMonitoringReportSelect={onSubmit}
                                    />
                                </Step>
                            ),
                        },
                    ]}
                />
            </DrawerModal>
        )
    }

    private handleOnSubmit = (control: MonitoringReportsQueryType, closeModal: () => void) => {
        const { onSubmit } = this.props

        if (onSubmit) {
            onSubmit(control)
            closeModal()
        }
    }
}
