import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    CreateAssessmentSectionDocument,
    CreateAssessmentSectionMutationVariables,
    CreateAssessmentSectionMutation,
} from '~/generated/graphql'

interface Props {
    children: (
        mutate: MutationFn<CreateAssessmentSectionMutation, CreateAssessmentSectionMutationVariables>,
        result: MutationResult<CreateAssessmentSectionMutation>
    ) => JSX.Element
}

export class CreateNewAssessmentSectionMutation extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props
        return (
            <GQLMutation<CreateAssessmentSectionMutation, CreateAssessmentSectionMutationVariables>
                mutation={CreateAssessmentSectionDocument}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
