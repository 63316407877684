import React from 'react'
import { RouteComponentProps } from 'react-router'
import { localize, permissions, notification } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { routes } from '~/views/routes'
import { Row } from '~/components/Core/Layout/Row'
import { Guard } from '~/components/Core/Guard/Guard'
import { ChangePasswordModal } from '~/components/Domain/Settings/Employees/ChangePasswordModal'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { Avatar, AvatarSize } from '~/components/Core/DataDisplay/Avatar/Avatar'
import { TextLink } from '~/components/Core/Text/TextLink'
import gql from 'graphql-tag'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { GQLMutation } from '~/graphql/Mutation'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { breadcrumbs } from '~/views/breadcrumbs'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

export const CONSULTANT_QUERY = gql`
    query consultant($consultantId: Int!) {
        consultant(consultantId: $consultantId) {
            id
            customers {
                id
                name
            }
            user {
                id
                email
                role
                profile {
                    id
                    fullName
                    firstName
                    lastName
                    phoneNumber
                    avatar
                }
            }
        }
    }
`

const DELETE_CONSULTANT_MUTATION = gql`
    mutation deleteConsultant($consultantId: Int!) {
        deleteConsultant(consultantId: $consultantId)
    }
`

interface MutationResponse {
    deleteConsultant: boolean
}

interface MutationVariables {
    consultantId: number
}

export interface Consultant {
    id: number
    customers: {
        id: number
        name: string
    }[]
    user: {
        email: string
        role?: string
        id: number
        profile: {
            id: number
            fullName: string
            firstName: string
            lastName: string
            phoneNumber: string
            avatar: string
        }
    }
}

export class OrganizationEmployeesDetailView extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Consultant.OrganizationEmployeesDetailView)
    private employeeLoc = localize.namespaceTranslate(t => t.Employee)

    public render() {
        const id = parseInt(this.props.match.params.id!, 10)

        return (
            <PageQuery<Consultant> query={CONSULTANT_QUERY} variables={{ consultantId: id }}>
                {consultant => (
                    <Column>
                        <Guard condition={permissions.isCurrentUser(consultant.user.id)}>
                            <PageHeader
                                title={this.loc(t => t.myDetails)}
                                breadCrumbs={[
                                    breadcrumbs.consultant().organization.index,
                                    breadcrumbs.consultant().organization.employees.index,
                                ]}
                            />
                        </Guard>

                        <Guard condition={!permissions.isCurrentUser(consultant.user.id)}>
                            <PageHeader
                                documentTitle={'Medewerker gegevens'}
                                title={consultant.user.profile.fullName}
                                breadCrumbs={[
                                    breadcrumbs.consultant().organization.index,
                                    breadcrumbs.consultant().organization.employees.index,
                                ]}
                            />
                        </Guard>

                        <FieldSet>
                            <Field label={this.employeeLoc(t => t.attributes.avatar)}>
                                <Avatar
                                    size={AvatarSize.large}
                                    label={consultant.user.profile.fullName}
                                    uri={consultant.user.profile.avatar}
                                />
                            </Field>
                        </FieldSet>
                        <FieldSet>
                            <Field label={this.employeeLoc(t => t.attributes.name)}>
                                <p>{consultant.user.profile.fullName}</p>
                            </Field>
                            <Field label={this.employeeLoc(t => t.attributes.phoneNumber)}>
                                <p>{consultant.user.profile.phoneNumber}</p>
                            </Field>
                            <Field label={this.employeeLoc(t => t.attributes.email)}>
                                <TextLink href={`mailto:${consultant.user.email}`}>{consultant.user.email}</TextLink>
                            </Field>
                            {consultant.customers && consultant.customers.length > 0 && (
                                <Field label={this.employeeLoc(t => t.attributes.customers)}>
                                    <ul>
                                        {consultant.customers.map(customer => (
                                            <li key={`${customer.name}-${customer.id}`}>
                                                <TextLink to={routes.consultant.customers.view(customer.id)}>
                                                    {customer.name}
                                                </TextLink>
                                            </li>
                                        ))}
                                    </ul>
                                </Field>
                            )}
                        </FieldSet>

                        <Row wrap={true} smallSpacing={true}>
                            <Guard
                                condition={permissions.isConsultantAdministratorAndNotCurrentUser(consultant.user.id)}
                            >
                                {this.renderDeleteButton(consultant)}
                            </Guard>
                            <Guard condition={permissions.isCurrentUser(consultant.user.id)}>
                                <ChangePasswordModal>
                                    {openModal => (
                                        <Button onClick={openModal} type={ButtonType.secondary}>
                                            {localize.translate(t => t.User.changePassword)}
                                        </Button>
                                    )}
                                </ChangePasswordModal>
                            </Guard>
                            <Guard condition={permissions.isCurrentUserOrConsultantAdministrator(consultant.user.id)}>
                                <Button to={routes.consultant.organization.employees.edit(id)}>
                                    {localize.translate(t => t.User.changeUserDetails)}
                                </Button>
                            </Guard>
                        </Row>
                    </Column>
                )}
            </PageQuery>
        )
    }

    private renderDeleteButton(consultant: Consultant): React.ReactNode {
        return (
            <GQLMutation<MutationResponse, MutationVariables>
                mutation={DELETE_CONSULTANT_MUTATION}
                variables={{ consultantId: consultant.id }}
            >
                {(mutate, { loading }) => (
                    <ConfirmModal
                        loading={loading}
                        onConfirm={async closeModal => {
                            const response = await mutate()

                            if (response && response.data && response.data.deleteConsultant) {
                                notification.success('Medewerker verwijderd')
                                closeModal()
                                this.props.history.push(routes.consultant.organization.employees.index)
                            }
                        }}
                        message={this.loc(t => t.confirmDeleteEmployee, { name: consultant.user.profile.fullName })}
                    >
                        {openModal => (
                            <Button onClick={openModal} danger={true}>
                                {this.loc(t => t.deleteEmployee)}
                            </Button>
                        )}
                    </ConfirmModal>
                )}
            </GQLMutation>
        )
    }
}
