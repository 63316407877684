import React from 'react'
import { localize } from '~/bootstrap'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { BEM, ClassValue } from '~/services/BEMService'
import { ArticleBreadCrumbsArticle, ArticleTitleBreadCrumbs } from '../Compliance/Norms/ArticleTitleBreadCrumbs'
import { GroupedTable } from '../Compliance/Norms/GroupedTable'
import { InsetCard } from '../Compliance/Norms/InsetCard'
import './GroupedLawArticlesTable.scss'
import { Subtle } from '~/components/Core/Typography/Subtle'
import { ExpiredArticleInfoBlock } from '~/components/Domain/Assessement/ExpiredArticleInfoBlock'
import { LawArticleType, TopicType } from '~/generated/graphql'

interface Props {
    className?: ClassValue
    topic: TopicType
    filterOutExpired?: boolean
    selectableCheckboxes?: boolean
    defaultAbstractLawArticleIds?: number[]
    onChange?: (abstractLawArticleIds: number[]) => void
}

interface GroupedLawArticle {
    title: string
    rows: React.ReactNode[]
    order: number
}

interface State {
    selectedAbstractLawArticleIds: number[]
}

export class GroupedLawArticlesTable extends React.PureComponent<Props, State> {
    public state: State = {
        selectedAbstractLawArticleIds: this.props.defaultAbstractLawArticleIds || [],
    }

    private bem = new BEM('GroupedLawArticlesTable')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Norms.Topic.GroupedLawArticleTable)

    public componentDidMount() {
        this.triggerOnChange()
    }

    public render() {
        const { topic, selectableCheckboxes } = this.props
        const { selectedAbstractLawArticleIds } = this.state
        const groupedData = this.groupArticleByLawGroup(topic.linkedLawArticles)

        return (
            <InsetCard>
                <Row spaceBetween={true}>
                    <Column>
                        <h4>{topic.name}</h4>
                    </Column>
                    {selectedAbstractLawArticleIds && selectableCheckboxes && (
                        <Row>
                            <Subtle>{this.loc(t => t.fullTopic)}</Subtle>
                            <Checkbox
                                indeterminate={this.isIndeterminate()}
                                checked={selectedAbstractLawArticleIds.length > 0}
                                name={'checkbox'}
                                onChange={this.handleOnSelectAllToggle}
                            />
                        </Row>
                    )}
                </Row>
                <GroupedTable rows={groupedData} />
            </InsetCard>
        )
    }

    private groupArticleByLawGroup(articles: LawArticleType[]): GroupedLawArticle[] {
        const groupedData: GroupedLawArticle[] = []
        const { selectableCheckboxes, filterOutExpired, defaultAbstractLawArticleIds = [] } = this.props
        const { selectedAbstractLawArticleIds } = this.state

        const filteredArticles = articles.filter(article => {
            if (article.expired && filterOutExpired) {
                return false
            }

            if (article.expired && !defaultAbstractLawArticleIds.includes(article.abstractLawArticleId)) {
                return false
            }

            return true
        })

        for (const article of filteredArticles) {
            let group = groupedData.find(group => group.title === article.lawGroup.name)

            if (!group) {
                group = {
                    title: article.lawGroup.name,
                    order: article.lawGroup.order,
                    rows: [],
                }

                groupedData.push(group)
            }

            const content = (
                <Column smallSpacing={true}>
                    <Row key={article.id} smallSpacing={true} spaceBetween={true}>
                        <Row alignCenter={true} smallSpacing={true}>
                            <ArticleTitleBreadCrumbs article={article as ArticleBreadCrumbsArticle} />
                            <DateFormat date={new Date(article.updatedAt)} readable={true} noWeekday={true} />
                        </Row>
                        {selectableCheckboxes && (
                            <Row alignRight={true} smallSpacing={true}>
                                <div className={this.bem.getElement('icon')}>{this.renderTypeIcon(article)}</div>
                                <Checkbox
                                    name={`abstractLawArticleIds[${articles.indexOf(article)}]`}
                                    value={article.abstractLawArticleId}
                                    checked={selectedAbstractLawArticleIds.includes(article.abstractLawArticleId)}
                                    onChange={this.toggleSelectedArticle(article.abstractLawArticleId)}
                                />
                            </Row>
                        )}
                    </Row>
                    {article.expired && <ExpiredArticleInfoBlock expiredAt={new Date(article.expired.expiredAt)} />}
                </Column>
            )

            group.rows.push(content)
        }

        return groupedData.sort((a, b) => a.order - b.order)
    }

    private renderTypeIcon(article: LawArticleType) {
        const hasAssessment = article.assessments.length

        if (hasAssessment) {
            return <Icon type={IconType.assessment} count={hasAssessment > 1 ? hasAssessment : undefined} />
        }

        return
    }

    private toggleSelectedArticle = (abstractLawArticleId: number) => () => {
        this.setState(state => {
            if (state.selectedAbstractLawArticleIds.includes(abstractLawArticleId)) {
                return {
                    selectedAbstractLawArticleIds: state.selectedAbstractLawArticleIds.filter(
                        id => id !== abstractLawArticleId
                    ),
                }
            }

            return { selectedAbstractLawArticleIds: [...state.selectedAbstractLawArticleIds, abstractLawArticleId] }
        }, this.triggerOnChange)
    }

    private handleOnSelectAllToggle = () => {
        const allNonExpiredIds = this.getAllIds()

        this.setState(state => {
            if (state.selectedAbstractLawArticleIds.length === 0) {
                return {
                    selectedAbstractLawArticleIds: allNonExpiredIds,
                }
            }

            return {
                selectedAbstractLawArticleIds: [],
            }
        }, this.triggerOnChange)
    }

    private triggerOnChange = () => {
        if (this.props.onChange) {
            this.props.onChange(this.state.selectedAbstractLawArticleIds)
        }
    }

    private isIndeterminate() {
        const { selectedAbstractLawArticleIds } = this.state

        const allNonExpiredIds = this.getAllIds()

        return !allNonExpiredIds.every(id => selectedAbstractLawArticleIds.includes(id))
    }

    private getAllIds() {
        const { topic, filterOutExpired, defaultAbstractLawArticleIds = [] } = this.props

        return topic.linkedLawArticles
            .filter(article => {
                if (filterOutExpired) {
                    return !article.expired
                }

                if (article.expired && !defaultAbstractLawArticleIds.includes(article.abstractLawArticleId)) {
                    return false
                }

                return true
            })
            .map(article => article.abstractLawArticleId)
    }
}
