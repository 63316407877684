import './TimelineGroup.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { EventsGroupedByDay } from './Timeline'
import { Paragraph } from '../../Typography/Paragraph'
import { TimelineDot } from './TimelineDot'
import { DateFormat } from '~/components/Core/Date/DateFormat'

interface Props {
    className?: ClassValue
    group: EventsGroupedByDay
}

interface State {}

export class TimelineGroup extends React.PureComponent<Props, State> {
    private bem = new BEM('TimelineGroup')

    public render() {
        const { group, className } = this.props

        return (
            <ol className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('metadata')}>
                    <TimelineDot />
                    <Paragraph className={this.bem.getElement('date')}>
                        <DateFormat date={group.day} />
                    </Paragraph>
                </div>
                <ol className={this.bem.getElement('content')}>{this.renderContent()}</ol>
            </ol>
        )
    }

    private renderContent() {
        const { group } = this.props

        return group.events.map((groupItem, index) => {
            return <li key={`grouped-item-${index}-${group.events.length}`}>{groupItem.content()}</li>
        })
    }
}
