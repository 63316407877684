import './FrameworkIcon.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Icon } from '~/components/Core/Icon/Icon'
import { CustomerFrameworkIconEnumType, CustomerFrameworkColorEnumType } from '~/generated/graphql'

// Backworks compatiblity
// CustomerFrameworkIconEnumType
// CustomerFrameworkColorEnumType

interface Props {
    className?: ClassValue
    // The line below needs refactoring, not I didn't know how to do this otherwise
    frameworkType: CustomerFrameworkIconEnumType
    color?: string
    isInactive?: boolean
    isSmall?: boolean
    isExtraSmall?: boolean
    isDot?: boolean
    isNotVisible?: boolean
    tooltip?: string
    label?: string
    isPaleSaturated?: boolean
}

interface State {}

export const frameworkIconColorOrder = [
    CustomerFrameworkColorEnumType.green,
    CustomerFrameworkColorEnumType.yellow,
    CustomerFrameworkColorEnumType.orange,
    CustomerFrameworkColorEnumType.red,
    CustomerFrameworkColorEnumType.lightBlue,
    CustomerFrameworkColorEnumType.blue,
    CustomerFrameworkColorEnumType.pink,
    CustomerFrameworkColorEnumType.purple,
    CustomerFrameworkColorEnumType.darkBlue,
    CustomerFrameworkColorEnumType.black,
]

export class FrameworkIcon extends React.PureComponent<Props, State> {
    private bem = new BEM('FrameworkIcon', () => ({
        [this.props.frameworkType]: true,
        'is-inactive': this.props.isInactive,
        'is-small': this.props.isSmall,
        'is-extra-small': this.props.isExtraSmall,
        'is-dot': this.props.isDot,
        'is-not-visible': this.props.isNotVisible,
        [`color-${this.props.color}`]: !!this.props.color,
        'has-label': !!this.props.label,
        'is-pale-saturated': this.props.isPaleSaturated,
    }))

    public render() {
        const { tooltip, label, isNotVisible } = this.props

        const content = this.getIcon()

        if (label && !tooltip) {
            return this.renderWithLabel(content, label)
        }

        if (label && tooltip) {
            return this.renderWithLabelAndTooltip(tooltip, content, label)
        }

        if (!tooltip || isNotVisible) {
            return content
        }

        return (
            <Tooltip content={tooltip}>
                <>{content}</>
            </Tooltip>
        )
    }

    private renderWithLabel(content: JSX.Element, label: string) {
        return (
            <div className={this.bem.getElement('profile-wrapper')}>
                {content} <Paragraph>{label}</Paragraph>
            </div>
        )
    }

    private renderWithLabelAndTooltip(tooltip: string, content: JSX.Element, label: string) {
        return (
            <div className={this.bem.getElement('profile-wrapper')}>
                <Tooltip content={tooltip}>{content}</Tooltip> <Paragraph>{label}</Paragraph>
            </div>
        )
    }

    private getIcon() {
        const { className, frameworkType: profileType, isDot } = this.props

        if (isDot) {
            return <figure className={this.bem.getClassName(className)} />
        }

        const iconExists = Object.values(CustomerFrameworkIconEnumType).includes(profileType)

        // if the server returns an icon name we don't recognise return an default icon
        if (!iconExists) {
            // tslint:disable-next-line:jsx-use-translation-function
            return <span className={this.bem.getClassName(className)}>?z</span>
        }

        return <Icon type={profileType} className={this.bem.getClassName(className)} />
    }
}
