import './LinkList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconType } from '../Icon/IconType'
import { ComponentTitle } from '../Text/ComponentTitle'
import { Icon } from '../Icon/Icon'
import { TextLink } from '../Text/TextLink'
import { Column } from './Column'
import { Button, ButtonType } from '../Button/Button'
import { localize } from '~/bootstrap'
import { CSSTransition } from 'react-transition-group'
import { animations, animationTiming } from '~/animations'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { PdfPreviewModal } from '~/components/Core/Feedback/Modal/PdfPreviewModal'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { LawArticleExpiredObjectType } from '~/generated/graphql'

export interface LinkListItem {
    abstractLawArticleId?: number
    label: string
    labelSuffix?: React.ReactNode
    date?: Date
    icon: IconType
    route?: string
    href?: string
    pdf?: string
    checkbox?: React.ReactNode
    expired?: LawArticleExpiredObjectType
    external?: boolean
}

interface Props {
    className?: ClassValue
    title: string
    items: LinkListItem[]
    showAmount: number
    showCheckboxes?: boolean
    preventShowMoreBehaviour?: boolean
    renderExpiredStatus?: (id?: number, expired?: LawArticleExpiredObjectType) => JSX.Element
}

interface State {
    expanded: boolean
}

export class LinkList extends React.PureComponent<Props, State> {
    public static defaultProps: Partial<Props> = {
        showAmount: 3,
    }

    public state: State = {
        expanded: false,
    }

    private bem = new BEM('LinkList')

    public render() {
        const { className, title, items, showAmount, preventShowMoreBehaviour } = this.props
        const visibleLinks = items.slice(0, showAmount)
        const hiddenLinks = items.slice(showAmount)
        const allLinks = items

        return preventShowMoreBehaviour ? (
            <Column className={this.bem.getClassName(className)}>
                <ComponentTitle title={title} />
                <ul className={this.bem.getElement('linked-document-list')}>{allLinks.map(this.renderList)}</ul>
            </Column>
        ) : (
            <Column className={this.bem.getClassName(className)}>
                <ComponentTitle title={title} />
                <ul className={this.bem.getElement('linked-document-list')}>
                    {visibleLinks.map(this.renderList)}
                    {this.renderCollapsedLinks(hiddenLinks)}
                    {items.length > showAmount! && this.renderExpandButton()}
                </ul>
            </Column>
        )
    }

    private renderList = (link: LinkListItem, i: number) => {
        const { showCheckboxes, renderExpiredStatus } = this.props

        return (
            <React.Fragment key={`${i}-${link.label}`}>
                <li className={this.bem.getElement('linked-document')}>
                    {showCheckboxes && <div className={this.bem.getElement('checkbox-container')}>{link.checkbox}</div>}
                    <Icon className={this.bem.getElement('icon')} type={link.icon} />
                    <div className={this.bem.getElement('linked-item-container')}>
                        {link.route && (
                            <TextLink className={this.bem.getElement('link')} to={link.route} external={link.external}>
                                {link.label}
                            </TextLink>
                        )}
                        {link.href && (
                            <TextLink className={this.bem.getElement('link')} href={link.href} external={true}>
                                {link.label}
                            </TextLink>
                        )}
                        {link.pdf && (
                            <ModalManager
                                render={openModal => (
                                    <TextLink
                                        className={this.bem.getElement('link')}
                                        onClick={openModal}
                                        href={link.pdf}
                                    >
                                        {link.label}
                                    </TextLink>
                                )}
                                renderModal={closeModal => (
                                    <PdfPreviewModal requestClose={closeModal} title={link.label} fileUri={link.pdf!} />
                                )}
                            />
                        )}
                        {link.labelSuffix && <span className={this.bem.getElement('suffix')}>{link.labelSuffix}</span>}
                        {link.date && (
                            <DateFormat
                                readable={true}
                                noWeekday={true}
                                className={this.bem.getElement('date')}
                                date={link.date}
                            />
                        )}
                    </div>
                </li>
                {renderExpiredStatus && renderExpiredStatus(link.abstractLawArticleId, link.expired)}
            </React.Fragment>
        )
    }

    private renderCollapsedLinks(links: LinkListItem[]) {
        const { expanded } = this.state

        if (!expanded) {
            return null
        }

        return links.map((link, i) => {
            return (
                <CSSTransition
                    key={`add-${link.label}-${i}`}
                    timeout={animationTiming.defaultTiming}
                    classNames={animations.fadeIn}
                    appear={true}
                    in={true}
                    unmountOnExit={true}
                >
                    {this.renderList(link, i)}
                </CSSTransition>
            )
        })
    }

    private renderExpandButton() {
        const { expanded } = this.state
        const arrowIcon = expanded ? IconType.arrowUp : IconType.arrowDown

        return (
            <Button
                type={ButtonType.actionLink}
                onClick={() =>
                    this.setState(() => ({
                        expanded: !this.state.expanded,
                    }))
                }
                icon={arrowIcon}
            >
                <span>
                    {expanded
                        ? localize.translate(t => t.Generic.showLess)
                        : localize.translate(t => t.Generic.showMore)}
                </span>
            </Button>
        )
    }
}
