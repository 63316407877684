import './RadarItemCard.scss'

import React from 'react'

import { BEM } from '~/services/BEMService'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { RadarItemContent, RadarItemContentProps } from './RadarItemContent'

interface Props extends RadarItemContentProps {
    unfollowed?: boolean
}

interface State {}

interface ModifierParams {
    unfollowed: boolean
}

export class RadarItemCard extends React.PureComponent<Props, State> {
    private bem = new BEM<ModifierParams>('RadarItemCard', params => ({
        isUnfollowed: params.unfollowed,
    }))

    public render() {
        const { className, unfollowed, ...restProps } = this.props

        return (
            <Card className={this.bem.getClassName(className, { unfollowed: !!unfollowed })}>
                <RadarItemContent unfollowed={unfollowed} {...restProps} />
            </Card>
        )
    }
}
