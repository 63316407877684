import './ThemeOverviewSummaryModal.scss'
import React from 'react'
import { localize } from '~/bootstrap'
import { Modal, ModalSize } from '~/components/Core/Feedback/Modal/Modal'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM } from '~/services/BEMService'
import { AssessmentScoreBarTooltipContent } from '../Compliance/Assessments/AssessmentScoreBar/AssessmentScoreBarTooltipContent'
import { ThemesOverviewThemeType } from './ThemesOverviewTable/ThemesOverviewTableQuery'
import { CustomerFrameworksBlockList } from '../CustomerFramework/CustomerFrameworksBlockList'

interface Props {
    requestClose: () => void
    theme: ThemesOverviewThemeType
}

export class ThemeOverviewSummaryModal extends React.PureComponent<Props> {
    private bem = new BEM('ThemeOverviewSummaryModal')
    private dneLoc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessTable)
    private loc = localize.namespaceTranslate(
        t => t.Customer.LegalFrameworkView.ThemesOverviewView.ThemeOverviewSummaryModal
    )

    public render() {
        const { requestClose, theme } = this.props

        return (
            <Modal
                hideButtons
                modalSize={ModalSize.small}
                title={theme.name}
                requestClose={requestClose}
                className={this.bem.getClassName()}
            >
                <Column bigBottomSpacing>
                    <Column bigSpacing>
                        {this.renderFrameworksSection()}
                        {this.renderAssessmentsSection()}
                    </Column>
                </Column>
            </Modal>
        )
    }

    private renderFrameworksSection() {
        return (
            <Column>
                <Paragraph>{this.loc(t => t.description)}</Paragraph>
                <CustomerFrameworksBlockList customerFrameworks={this.props.theme.customerFrameworks} />
            </Column>
        )
    }

    private renderAssessmentsSection() {
        return (
            <Column>
                <Paragraph bold>{this.loc(t => t.dne)}</Paragraph>
                <Row spaceBetween>
                    <AssessmentScoreBarTooltipContent
                        className={this.bem.getElement('assessments')}
                        title={this.dneLoc(t => t.design)}
                        assessments={this.props.theme.topicAssessmentCoverage?.design}
                    />
                    <AssessmentScoreBarTooltipContent
                        className={this.bem.getElement('assessments')}
                        title={this.dneLoc(t => t.effectiveness)}
                        assessments={this.props.theme.topicAssessmentCoverage?.effectiveness}
                    />
                </Row>
            </Column>
        )
    }
}
