import React from 'react'
import { InfiniteScrollOptions, InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    DepartmentTopicDataMetadataType,
    RiskType,
    ThemesDetailCardListDocument,
    ThemesDetailCardListQueryVariables,
    TopicsForThemeFilters,
} from '~/generated/graphql'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'

export interface ThemesDetailCardTopicType {
    id: number
    name: string
    isPinned: boolean
    isApplicable?: boolean
    hasCompliance?: boolean
    highestRisk?: Pick<RiskType, 'id' | 'name' | 'severity' | 'severityBruto' | 'isAccepted'>
    nonApplicable?: {
        id: number
        reason: string | null
    }
    customerFrameworks: CustomerFramework[]
    departmentTopicData?: {
        id: number
        topicAssessmentDesignMetadata?: DepartmentTopicDataMetadataType
        topicAssessmentEffectivenessMetadata?: DepartmentTopicDataMetadataType
    }
}
interface Props {
    children: (
        options: InfiniteScrollOptions<ThemesDetailCardTopicType, ThemesDetailCardListQueryVariables>
    ) => JSX.Element
    themeId: number
    filters: TopicsForThemeFilters
}

export class ThemesDetailCardListQuery extends React.PureComponent<Props, {}> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, themeId, filters } = this.props

        return (
            <InfiniteScrollQuery<ThemesDetailCardTopicType, ThemesDetailCardListQueryVariables>
                query={ThemesDetailCardListDocument}
                variables={{
                    themeId,
                    customerFrameworkIds: this.context.activeProfiles,
                    departmentId: this.context.activeDepartmentId,
                    customerSlug: this.context.customer.slug,
                    filters: {
                        ...filters,
                        departmentId: this.context.activeDepartmentId,
                        filterPinned: true,
                    },
                }}
            >
                {options => children(options)}
            </InfiniteScrollQuery>
        )
    }
}
