import gql from 'graphql-tag'
import React from 'react'
import { localize } from '~/bootstrap'
import { RowData, SortOption, SortState, Table } from '~/components/Core/DataDisplay/Table/Table'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { Select, SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { MediaQuery, Screensize } from '~/components/Core/Layout/MediaQuery'
import { Page } from '~/components/Core/Layout/Page'
import { PageActions } from '~/components/Core/Layout/PageActions'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { PageOffsetPadding } from '~/components/Core/Layout/PageOffsetPadding'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { ConsultationItemList } from '~/components/Domain/Consultations/ConsultationItemList/ConsultationItemList'
import { MobileConsultationItem } from '~/components/Domain/Consultations/MobileConsultationItem/MobileConsultationItem'
import { TabletConsultationItem } from '~/components/Domain/Consultations/TabletConsultationItem/TabletConsultationItem'
import { CustomerFrameworkIconList } from '~/components/Domain/CustomerFramework/CustomerFrameworkIconList'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { CustomerFramework, CustomerFrameworkFragment } from '~/graphql/types/CustomerFramework'
import { routes } from '~/views/routes'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { breadcrumbs } from '~/views/breadcrumbs'
import { ParamManager, NewParamState } from '~/components/Core/ParamManager/ParamManager'

interface Props {}

interface State {}

interface Filters extends SortState {
    search: string | null
    status: string | null
}

const LIST_CONSULTATIONS_QUERY = gql`
    query consultations(
        $skip: Int
        $take: Int
        $filters: ConsultationFilters
        $sort: ConsultationSort
        $customerSlug: String
        $departmentId: Int
    ) {
        consultations(skip: $skip, take: $take, filters: $filters, sort: $sort, customerSlug: $customerSlug) {
            hasNextPage
            nodes {
                id
                name
                startsAt
                endsAt
                source
                customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                    ...CustomerFrameworkFields
                }
            }
        }
    }
    ${CustomerFrameworkFragment}
`

interface Consultation {
    id: number
    name: string
    startsAt: string | null
    endsAt: string | null
    source: string
    customerFrameworks: CustomerFramework[]
}

const defaultSortDirection: SortOption = { field: 'endsAt', direction: 'ASC' }

export class ConsultationsOverviewView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Consultations.Attributes)

    public render() {
        return (
            <ParamManager<Filters>
                defaultState={{
                    sortDirection: defaultSortDirection,
                    search: '',
                    status: 'active',
                }}
            >
                {({ paramState, setParamState }) => {
                    const statusOptions = [
                        {
                            label: localize.translate(t => t.Customer.Consultations.ConsultationsOverview.filter.all),
                            value: null,
                        },
                        {
                            label: localize.translate(
                                t => t.Customer.Consultations.ConsultationsOverview.filter.activeConsultations
                            ),
                            value: 'active',
                        },
                        {
                            label: localize.translate(
                                t => t.Customer.Consultations.ConsultationsOverview.filter.inactiveConsultations
                            ),
                            value: 'inactive',
                        },
                    ]

                    const statusInitialDefaultValue = statusOptions[0]
                    const statusDefaultValue = paramState.status
                        ? (statusOptions.find(option => option.value === paramState.status) as SelectOption)
                        : statusInitialDefaultValue

                    return (
                        <Page>
                            <PageHeader
                                breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).legalFramework.index]}
                                title={localize.translate(t => t.Customer.Consultations.ConsultationsOverview.title)}
                            />
                            <PageActions
                                primaryComponent={
                                    <Search
                                        defaultValue={paramState.search}
                                        placeholder={localize.translate(t => t.Generic.search.byNameOrSource)}
                                        onChange={search => setParamState({ search })}
                                    />
                                }
                                secondaryComponent={
                                    <Select
                                        name={'status'}
                                        searchable={false}
                                        clearable={false}
                                        onChange={value =>
                                            setParamState({
                                                status: value ? ((value as SelectOption).value as string) : null,
                                            })
                                        }
                                        defaultValue={[statusDefaultValue]}
                                        options={statusOptions}
                                    />
                                }
                            />

                            <InfiniteScrollQuery<Consultation>
                                query={LIST_CONSULTATIONS_QUERY}
                                take={20}
                                variables={{
                                    filters: {
                                        search: paramState.search,
                                        status: paramState.status,
                                        customerFrameworkIds: this.context.activeProfiles,
                                        departmentId: this.context.activeDepartmentId,
                                    },
                                    sort: {
                                        [paramState.sortDirection.field]: paramState.sortDirection.direction,
                                        nulls: 'NULL_LAST',
                                    },
                                    customerSlug: this.context.customer.slug,
                                    departmentId: this.context.activeDepartmentId,
                                }}
                            >
                                {({ data, loading, loadingMore }) => {
                                    const consultations = data && data.nodes ? data.nodes : []
                                    return (
                                        <>
                                            <MediaQuery breakpoint={Screensize.mobile}>
                                                {this.renderMobileConsultationList(consultations, loading, loadingMore)}
                                            </MediaQuery>
                                            <MediaQuery breakpoint={Screensize.betweenTabletAndDesktop}>
                                                {this.renderTabletConsultationList(consultations, loading, loadingMore)}
                                            </MediaQuery>
                                            <MediaQuery breakpoint={Screensize.desktop}>
                                                {this.renderDesktopConsultationTable(
                                                    consultations,
                                                    loading,
                                                    loadingMore,
                                                    paramState,
                                                    setParamState
                                                )}
                                            </MediaQuery>
                                        </>
                                    )
                                }}
                            </InfiniteScrollQuery>
                        </Page>
                    )
                }}
            </ParamManager>
        )
    }

    private renderMobileConsultationList(consultations: Consultation[], loading: boolean, loadingMore: boolean) {
        if (loading) {
            return <Spinner delayed={true} />
        }

        if (consultations.length === 0) {
            return <NoResults />
        }

        return (
            <PageOffsetPadding>
                <ConsultationItemList>
                    {consultations.map(consultation => (
                        <li key={consultation.id}>
                            <MobileConsultationItem
                                to={routes
                                    .customer(this.context.customer.slug)
                                    .consultations.detail.view(consultation.id)}
                                title={consultation.name}
                                source={localize.translate(t => {
                                    const name = t.Consultation.source[consultation.source]
                                    return name ? name : consultation.source
                                })}
                                startsAt={consultation.startsAt}
                                endsAt={consultation.endsAt}
                                profiles={consultation.customerFrameworks}
                            />
                        </li>
                    ))}
                    {loadingMore && <Spinner delayed={true} />}
                </ConsultationItemList>
            </PageOffsetPadding>
        )
    }

    private renderTabletConsultationList(consultations: Consultation[], loading: boolean, loadingMore: boolean) {
        if (loading) {
            return <Spinner delayed={true} />
        }

        if (consultations.length === 0) {
            return <NoResults />
        }

        return (
            <ConsultationItemList>
                {consultations.map(consultation => (
                    <li key={consultation.id}>
                        <TabletConsultationItem
                            to={routes.customer(this.context.customer.slug).consultations.detail.view(consultation.id)}
                            title={consultation.name}
                            source={localize.translate(t => {
                                const name = t.Consultation.source[consultation.source]
                                return name ? name : consultation.source
                            })}
                            startsAt={consultation.startsAt}
                            endsAt={consultation.endsAt}
                            profiles={consultation.customerFrameworks}
                        />
                    </li>
                ))}
                {loadingMore && <Spinner delayed={true} />}
            </ConsultationItemList>
        )
    }

    private renderDesktopConsultationTable(
        consultations: Consultation[],
        loading: boolean,
        loadingMore: boolean,
        paramState: Filters,
        setParamState: (newState: NewParamState<Filters>) => void
    ) {
        const consultationsTableData: RowData[] = consultations.map(consultation => ({
            id: consultation.id,
            columns: {
                name: (
                    <TableLink
                        to={routes.customer(this.context.customer.slug).consultations.detail.view(consultation.id)}
                    >
                        {consultation.name}
                    </TableLink>
                ),
                newsSource: localize.translate(t => {
                    const name = t.Consultation.source[consultation.source]
                    return name ? name : consultation.source
                }),
                startsAt: consultation.startsAt ? (
                    <DateFormat readable={true} noWeekday={true} date={new Date(consultation.startsAt)} />
                ) : null,
                endsAt: consultation.endsAt ? (
                    <DateFormat readable={true} noWeekday={true} date={new Date(consultation.endsAt)} />
                ) : null,
                profiles: <CustomerFrameworkIconList customerFrameworks={consultation.customerFrameworks} />,
            },
        }))

        return (
            <Table
                defaultSortDirection={paramState.sortDirection}
                onSortDirectionChange={(field, direction) => setParamState({ sortDirection: { field, direction } })}
                loading={loading}
                loadingMore={loadingMore}
                columns={[
                    { field: 'name', headerLabel: this.loc(t => t.name) },
                    { field: 'newsSource', headerLabel: this.loc(t => t.source), sortable: true },
                    { field: 'startsAt', headerLabel: this.loc(t => t.effectiveFrom), sortable: true },
                    { field: 'endsAt', headerLabel: this.loc(t => t.endDate), sortable: true },
                    { field: 'profiles', headerLabel: this.loc(t => t.profiles) },
                ]}
                data={consultationsTableData}
            />
        )
    }
}
