import './Radio.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    name: string
    onChange?: (checked: any, name: string) => void
    defaultChecked?: boolean
    label?: React.ReactNode
    value?: any
    checked?: boolean
    large?: boolean
    disabled?: boolean
}

interface State {
    isChecked: boolean
}

export class Radio extends React.PureComponent<Props, State> {
    private bem = new BEM('Radio', () => ({
        large: this.props.large,
        disabled: this.props.disabled,
    }))

    public render() {
        const { label, className, name, defaultChecked, checked, disabled } = this.props

        return (
            <label className={this.bem.getClassName(className)}>
                <input
                    onChange={this.handleOnChange}
                    type="radio"
                    name={name}
                    id={`input-${name}`}
                    defaultChecked={defaultChecked}
                    className={this.bem.getElement('input')}
                    checked={checked}
                    disabled={disabled}
                />
                <div className={this.bem.getElement('radio')}>
                    <div className={this.bem.getElement('mark')} />
                </div>
                <div className={this.bem.getElement('label')}>{label}</div>
            </label>
        )
    }

    private handleOnChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        const { onChange, value, name } = this.props

        if (onChange) {
            onChange(value, name)
        }
    }
}
