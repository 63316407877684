import React from 'react'
import { InfiniteScrollOptions, InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    InternalAlertStatusState,
    InternalAlertsForCustomerDocument,
    InternalAlertsForCustomerQueryVariables,
} from '~/generated/graphql'
import { SentBackInternalAlert } from './PaginatedSentBackInternalAlertsQuery'

interface Props {
    search?: string
    groupIds?: number[]
    sourceIds?: number[]
    children: (
        options: InfiniteScrollOptions<SentBackInternalAlert, InternalAlertsForCustomerQueryVariables>
    ) => JSX.Element
    includePendingAndAccepted?: boolean
}

export class SentBackInternalAlertsQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, search, sourceIds, groupIds, includePendingAndAccepted: includePending } = this.props

        return (
            <InfiniteScrollQuery<SentBackInternalAlert, InternalAlertsForCustomerQueryVariables>
                take={3}
                variables={{
                    filters: {
                        search,
                        sourceIds,
                        groupIds,
                        status: includePending ? undefined : InternalAlertStatusState.returned,
                    },
                }}
                query={InternalAlertsForCustomerDocument}
            >
                {children}
            </InfiniteScrollQuery>
        )
    }
}
