import './NewsUrlButton.scss'
import React from 'react'
import { NewsItemType } from '~/generated/graphql'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { BEM } from '~/services/BEMService'

interface Props {
    news: Pick<NewsItemType, 'originalSource' | 'customSourceUrl'>
    autoTrim?: boolean
}

export class NewsUrlButton extends React.PureComponent<Props> {
    private bem = new BEM('NewsUrlButton', () => ({
        autoTrim: this.props.autoTrim,
    }))

    public render() {
        const { customSourceUrl, originalSource } = this.props.news

        const sourceUrl = customSourceUrl || originalSource

        if (!sourceUrl) {
            return null
        }

        const trimmedSourceUrl =
            !this.props.autoTrim && sourceUrl.length > 23 ? `${sourceUrl.substring(0, 20)}...` : sourceUrl

        return (
            <Button
                className={this.bem.getClassName()}
                icon={IconType.newTab}
                href={sourceUrl}
                external={true}
                type={ButtonType.actionLink}
            >
                {trimmedSourceUrl}
            </Button>
        )
    }
}
