import './MatrixLines.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { animations } from '~/animations'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

interface Props {
    className?: ClassValue
    lines: MatrixLine[]
    svgWidth: number
    itemHeight: number
    itemMargin: number
    activeLeftItemIds?: number[]
    activeRightItemIds?: number[]
}

interface State {}

export interface MatrixLine {
    leftItemId: number
    rightItemId: number
    leftItemIndex: number
    rightItemIndex: number
}

export class MatrixLines extends React.PureComponent<Props, State> {
    private bem = new BEM('MatrixLines')

    public render() {
        const { className } = this.props

        return (
            <svg
                style={{
                    height: this.calculateSvgHeight(),
                }}
                className={this.bem.getClassName(className)}
            >
                <TransitionGroup component={null}>{this.renderLinks()}</TransitionGroup>
            </svg>
        )
    }

    private getXPosition(index: number) {
        const { itemHeight, itemMargin, svgWidth } = this.props

        return itemHeight * index + itemMargin * index + svgWidth / 2
    }

    private renderLinks() {
        const { lines, svgWidth } = this.props

        if (!lines.length) {
            return null
        }

        return lines.map(link => {
            const { activeLeftItemIds, activeRightItemIds } = this.props

            // Skip line if it's not active (if there are active items being passed, otherwise all items are active)
            if (activeLeftItemIds && !activeLeftItemIds.includes(link.leftItemId)) {
                return null
            }
            if (activeRightItemIds && !activeRightItemIds.includes(link.rightItemId)) {
                return null
            }

            const leftXPosition = this.getXPosition(link.leftItemIndex)
            const rightXPosition = this.getXPosition(link.rightItemIndex)

            return (
                <CSSTransition
                    key={`${link.leftItemId}-${link.rightItemId}-animation-wrapper`}
                    classNames={animations.fadeIn}
                    timeout={225}
                    in={true}
                >
                    <g key={`${link.leftItemId}-${link.rightItemId}-link`}>
                        <path
                            className={this.bem.getElement('line')}
                            // M stands for move to, H is a horizontal move to starting from the last x, V is a vertical move to starting from the last y
                            d={`M 0,${leftXPosition} H ${svgWidth / 2} V ${rightXPosition} H ${svgWidth}`}
                        />
                        <path
                            className={this.bem.getElement('line', () => ({ isDashed: true }))}
                            d={`M 0,${leftXPosition} H ${svgWidth / 2} V ${rightXPosition} H ${svgWidth}`}
                        />
                    </g>
                </CSSTransition>
            )
        })
    }

    private calculateSvgHeight() {
        const { lines, itemMargin } = this.props

        const leftItemIds: number[] = []
        const rightItemIds: number[] = []

        lines.forEach(line => {
            if (!leftItemIds.includes(line.leftItemId)) {
                leftItemIds.push(line.leftItemId)
            }

            if (!rightItemIds.includes(line.rightItemId)) {
                rightItemIds.push(line.rightItemId)
            }
        })

        const amountOfItems = Math.max(leftItemIds.length, rightItemIds.length) - 1

        return this.getXPosition(amountOfItems) + itemMargin
    }
}
