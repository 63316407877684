import gql from 'graphql-tag'
import React from 'react'
import { localize } from '~/bootstrap'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { RowData, SortOption, SortState, Table } from '~/components/Core/DataDisplay/Table/Table'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { TableList } from '~/components/Core/DataDisplay/TableList/TableList'
import { TableListItem } from '~/components/Core/DataDisplay/TableList/TableListItem'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Column } from '~/components/Core/Layout/Column'
import { MediaQuery, Screensize } from '~/components/Core/Layout/MediaQuery'
import { Page } from '~/components/Core/Layout/Page'
import { PageActions } from '~/components/Core/Layout/PageActions'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { NewsGroupSelect } from '~/components/Domain/News/NewsGroupSelect'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'
import { ParamManager } from '~/components/Core/ParamManager/ParamManager'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { NewsSourcesLabel } from '~/components/Domain/News/NewsSourcesLabel'

interface Props {}

interface State {}

interface Filters extends SortState {
    search: string | null
    activeSourceId?: SelectOption | null
}

const LIST_NEWS_BY_SOURCE_QUERY = gql`
    query newsItems($skip: Int, $take: Int, $filters: NewsFilters, $sort: NewsSort) {
        newsItems(skip: $skip, take: $take, filters: $filters, sort: $sort) {
            hasNextPage
            nodes {
                id
                title
                publicationDate
                sources {
                    id
                    name
                }
            }
        }
    }
`

interface NewsItem {
    id: number
    title: string
    publicationDate: string
    sources: {
        id: number
        name: string
    }[]
}

const defaultSortDirection: SortOption = { field: 'publicationDate', direction: 'DESC' }

export class NewsOverviewView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {
        search: '',
        sortDirection: defaultSortDirection,
        activeSourceId: null,
    }

    private columns = [
        {
            field: 'title',
            headerLabel: localize.translate(t => t.News.Attributes.name),
        },
        {
            field: 'source',
            headerLabel: localize.translate(t => t.News.Attributes.source),
            sortable: true,
        },
        {
            field: 'publicationDate',
            headerLabel: localize.translate(t => t.News.Attributes.date),
            sortable: true,
        },
    ]

    private loc = localize.namespaceTranslate(t => t.Customer.News.NewsOverviewView)

    public render() {
        return (
            <ParamManager<Filters>
                defaultState={{
                    search: '',
                    sortDirection: defaultSortDirection,
                    activeSourceId: null,
                }}
            >
                {({ paramState, setParamState }) => {
                    const { search, sortDirection, activeSourceId } = paramState

                    return (
                        <Page>
                            <PageHeader title={this.loc(t => t.title)} />
                            <PageActions
                                primaryComponent={
                                    <Search
                                        defaultValue={search}
                                        placeholder={this.loc(t => t.byName)}
                                        onChange={search => setParamState({ search })}
                                    />
                                }
                                secondaryComponent={
                                    <NewsGroupSelect
                                        defaultValue={activeSourceId ? [activeSourceId] : undefined}
                                        onChange={option => setParamState({ activeSourceId: option })}
                                    />
                                }
                            />
                            <InfiniteScrollQuery<NewsItem>
                                query={LIST_NEWS_BY_SOURCE_QUERY}
                                variables={{
                                    filters: {
                                        search,
                                        groupIds: activeSourceId && activeSourceId.value,
                                    },
                                    sort: {
                                        [sortDirection.field]: sortDirection.direction,
                                    },
                                }}
                            >
                                {({ data, loading, loadingMore }) => {
                                    const nodes = data ? data.nodes : []

                                    if (!loading && !nodes.length) {
                                        return (
                                            <NoResults
                                                label={
                                                    search
                                                        ? `${this.loc(t => t.noResultsFor)}"${search}"`
                                                        : this.loc(t => t.noResults)
                                                }
                                            />
                                        )
                                    }

                                    const news = data?.nodes ? data.nodes : []
                                    const tableData = this.getTableData(news)

                                    return (
                                        <>
                                            <MediaQuery breakpoint={Screensize.mobile}>
                                                {this.renderTableList(tableData, loading)}
                                            </MediaQuery>
                                            <MediaQuery breakpoint={Screensize.tablet}>
                                                <Table
                                                    columns={this.columns}
                                                    loading={loading}
                                                    loadingMore={loadingMore}
                                                    defaultSortDirection={sortDirection}
                                                    onSortDirectionChange={(field, direction) =>
                                                        setParamState({ sortDirection: { field, direction } })
                                                    }
                                                    data={tableData}
                                                />
                                            </MediaQuery>
                                        </>
                                    )
                                }}
                            </InfiniteScrollQuery>
                        </Page>
                    )
                }}
            </ParamManager>
        )
    }

    private renderTableList(tableData: RowData[], loading: boolean) {
        return (
            <TableList loading={loading} loadingMore={loading}>
                {tableData.map(news => (
                    <TableListItem key={news.id}>
                        <Column>
                            <h4>{news.columns.title}</h4>
                            <div>
                                <p>
                                    {localize.translate(t => t.News.Attributes.source)}
                                    {news.columns.source}
                                </p>
                                <p>
                                    {localize.translate(t => t.News.Attributes.date)}
                                    {news.columns.publicationDate}
                                </p>
                            </div>
                        </Column>
                    </TableListItem>
                ))}
            </TableList>
        )
    }

    private getTableData(newsItems: NewsItem[]) {
        return newsItems.map(newsItem => {
            const route = routes.customer(this.context.customer.slug).news.detail.view(newsItem.id)

            return {
                id: newsItem.id,
                columns: {
                    title: <TableLink to={route}>{newsItem.title}</TableLink>,
                    source: <NewsSourcesLabel sources={newsItem.sources} />,
                    publicationDate: (
                        <DateFormat readable={true} noWeekday={true} date={new Date(newsItem.publicationDate)} />
                    ),
                },
            }
        })
    }
}
