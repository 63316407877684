import './GroupedTable.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    rows: Rows[]
}

interface Rows {
    title: string
    rows: React.ReactNode[]
}

interface State {}

export class GroupedTable extends React.PureComponent<Props, State> {
    private bem = new BEM('GroupedTable')

    public render() {
        const { className, rows } = this.props
        const tableData = this.transformRowsToTableStructure(rows)

        return (
            <table className={this.bem.getClassName(className)}>
                <tbody>{tableData}</tbody>
            </table>
        )
    }

    private transformRowsToTableStructure(rows: Rows[]) {
        return rows.map(title => {
            return (
                <React.Fragment key={title.title}>
                    <tr className={this.bem.getElement('title')}>
                        <td className={this.bem.getElement('padding-title')}>{title.title}</td>
                    </tr>

                    {title.rows.map((row, i) => (
                        <tr key={`${i}-${title.rows.length}`} className={this.bem.getElement('border-bottom')}>
                            <td className={this.bem.getElement('padding')}>{row}</td>
                        </tr>
                    ))}
                </React.Fragment>
            )
        })
    }
}
