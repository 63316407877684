import React from 'react'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { localize, notification, permissions } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'
import { BEM, ClassValue } from '~/services/BEMService'
import { RevertIgnoreAlert, RevertIgnoreAlertMutationFN } from './mutations/RevertIgnoreAlert'

interface Props {
    alertId: number
    alertDepartmentId?: number
    onUnarchive: () => void
    className: ClassValue
}

interface State {}

export class UndoIgnoreInboxControlButtonContainer extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('UndoIgnoreInboxControlButtonContainer')

    public render() {
        const { className, alertDepartmentId } = this.props

        if (!permissions.canEditInboxControls()) {
            return null
        }

        if (!alertDepartmentId) {
            return null
        }

        return (
            <RevertIgnoreAlert>
                {(mutate, { loading }) => {
                    return (
                        <Button
                            className={this.bem.getClassName(className)}
                            loading={loading}
                            onClick={() => this.handleSubmit(mutate)}
                            type={ButtonType.secondary}
                            icon={IconType.revert}
                        />
                    )
                }}
            </RevertIgnoreAlert>
        )
    }

    private async handleSubmit(mutate: RevertIgnoreAlertMutationFN) {
        const { alertId, alertDepartmentId, onUnarchive } = this.props

        const response = await mutate({
            variables: {
                alertId,
                departmentId: alertDepartmentId!,
            },
        })

        if (response && response.data && response.data.revertIgnoreAlert) {
            notification.success(localize.translate(t => t.Generic.successfullyUnarchived))
            onUnarchive()
        }
    }
}
