import './RiskLabel.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconLabel } from '~/components/Core/Text/IconLabel'
import { IconType } from '~/components/Core/Icon/IconType'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { RiskIndicator, SeverityLevel } from './RiskIndicator'
import { matchPath } from 'react-router'

interface Risk {
    id: number
    name: string
    severity: SeverityLevel
    isAccepted: boolean
}
interface Props {
    className?: ClassValue
    risk: Risk
    iconOnly?: boolean
}

interface State {}

export class RiskLabel extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('RiskLabel')

    public render() {
        const { className, risk, iconOnly } = this.props

        const link = routes.customer(this.context.customer.slug).risks.heatmap.view(risk.id)
        const match = matchPath(window.location.pathname, {
            path: link,
        })

        return (
            <IconLabel
                className={this.bem.getClassName(className)}
                iconType={IconType.risk}
                to={match ? undefined : link}
            >
                <div className={this.bem.getElement('wrapper')}>
                    <RiskIndicator className={this.bem.getElement('risk-icon')} severity={risk.severity} />
                    {iconOnly ? null : risk.name}
                </div>
            </IconLabel>
        )
    }
}
