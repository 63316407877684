import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { InfiniteScrollQuery, InfiniteScrollOptions } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { DepartmentType, LegalFrameworkCuratedByEnum, TopicsDocument } from '~/generated/graphql'

interface Props {
    children: (options: InfiniteScrollOptions<Topic>) => React.ReactNode
    filters?: Filters
    forModal?: boolean
    forCustomer?: boolean
}

interface Filters {
    search?: string | null
    themeIds?: number[]
    customerFrameworkIds?: number[]
    curatedBy?: LegalFrameworkCuratedByEnum
}

export interface Topic {
    id: number
    name: string
    theme: {
        id: number
        name?: string | null
    }
    customerFrameworks: CustomerFramework[]
    departments?: Pick<DepartmentType, 'id' | 'name'>[] | null
}

export class TopicsQuery extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, filters, forModal, forCustomer } = this.props
        return (
            <InfiniteScrollQuery<Topic>
                take={20}
                query={TopicsDocument}
                variables={{
                    customerSlug: this.context.customer.slug,
                    departmentId: forCustomer ? undefined : this.context.activeDepartmentId,
                    filters,
                }}
                useWindow={!forModal}
            >
                {options => children(options)}
            </InfiniteScrollQuery>
        )
    }
}
