import React from 'react'
import { localize } from '~/bootstrap'
import { Select, SelectOption } from '~/components/Core/DataEntry/Form/Select'

interface Props {
    defaultValue?: boolean // is archived?
    onChange?: (archived: boolean) => void
}

export class MonitoringReportsStatusSelect extends React.PureComponent<Props> {
    public options = [
        {
            label: localize.translate(t => t.Customer.MonitoringView.MonitoringOverviewView.StatusSelect.open),
            value: null,
        },
        {
            label: localize.translate(t => t.Customer.MonitoringView.MonitoringOverviewView.StatusSelect.archived),
            value: 'archived',
        },
    ]

    public render() {
        const { defaultValue, onChange } = this.props

        return (
            <Select
                isFilter
                name="status"
                searchable={false}
                options={this.options}
                defaultValue={defaultValue ? [this.options[1]] : [this.options[0]]}
                onChange={(option: SelectOption<string>) => onChange?.(!!option.value)}
            />
        )
    }
}
