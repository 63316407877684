import './NewsSourcesOverviewView.scss'

import React from 'react'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { localize, permissions } from '~/bootstrap'
import { routes } from '~/views/routes'
import { breadcrumbs } from '~/views/breadcrumbs'
import { Column } from '~/components/Core/Layout/Column'
import { ExpandableNewsGroupSourcesList } from '~/views/Customer/Settings/NewsSources/ExpandableNewsGroupSourcesList'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Guard } from '~/components/Core/Guard/Guard'
import { Button } from '~/components/Core/Button/Button'
import { BEM } from '~/services/BEMService'
import { Row } from '~/components/Core/Layout/Row'
import { Bold } from '~/components/Core/Typography/Bold'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { CustomerEnabledNewsSource } from './CustomerEnabledNewsSource'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'

interface Props {}

interface State {}

export class NewsSourcesOverviewView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('NewsSourcesOverviewView')
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.NewsSourcesOverview)
    private locRoute = localize.translate(t => t.Customer.routes[routes.customer().settings.news.index])

    public render() {
        return (
            <Column>
                <PageHeader
                    className={this.bem.getElement('page-header')}
                    title={this.locRoute}
                    breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).settings.index]}
                    actionComponent={this.renderEditButton()}
                />
                <Paragraph>
                    {this.loc(t => t.titlePart1)}
                    <Bold inline={true}>{this.loc(t => t.signaling)}</Bold>
                    {this.loc(t => t.titlePart2)}
                </Paragraph>
                <CustomerEnabledNewsSource>
                    {({ data, loading }) => {
                        if (loading) {
                            return <Spinner delayed={true} />
                        }

                        return (
                            <Column noSpacing={true}>
                                {this.renderShowFromDate(data?.customerEnabledNewsSource?.showFrom)}
                                <ExpandableNewsGroupSourcesList />
                            </Column>
                        )
                    }}
                </CustomerEnabledNewsSource>
            </Column>
        )
    }

    private renderEditButton() {
        const buttonLink = routes.customer(this.context.customer.slug).settings.news.edit

        return (
            <Row>
                <Guard condition={permissions.isCustomerAdministrator()}>
                    <Button to={buttonLink}>{this.loc(t => t.edit)}</Button>
                </Guard>
            </Row>
        )
    }

    private renderShowFromDate(showFrom?: Date | null) {
        if (!showFrom) {
            return
        }

        const date = localize.dateFormat(new Date(showFrom), { noWeekday: true, readable: true })

        return (
            <Column extraSmallSpacing={true} className={this.bem.getElement('show-from-container')}>
                <SectionTitle>{localize.translate(t => t.Customer.Settings.NewsSourcesEditView.sources)}</SectionTitle>
                <Row>
                    <Paragraph className={this.bem.getElement('show-from')}>
                        {this.loc(t => t.articlesFrom, { date })}
                    </Paragraph>
                </Row>
            </Column>
        )
    }
}
