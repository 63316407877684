import './PreviewModal.scss'

import React from 'react'

import { BEM } from '~/services/BEMService'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Content } from '~/components/Core/Layout/Content'
import { IconType } from '~/components/Core/Icon/IconType'

interface Props {
    className?: string
    requestClose?: () => void
}

export class PreviewModal extends React.Component<Props> {
    private bem = new BEM('PreviewModal')

    private closeButton = React.createRef<Button>()

    public async componentDidMount() {
        document.addEventListener('keyup', this.handleKeyUp)

        if (this.closeButton.current) {
            this.closeButton.current.focus()
        }
    }

    public componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyUp)
    }

    public render() {
        const { className, requestClose, children } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <Button
                    className={this.bem.getElement('close')}
                    icon={IconType.closeModal}
                    type={ButtonType.noStyling}
                    onClick={requestClose}
                    ref={this.closeButton}
                />
                <Content className={this.bem.getElement('content')}>{children}</Content>
            </div>
        )
    }

    private handleKeyUp = (event: KeyboardEvent) => {
        // 27 = ESC
        if (event.keyCode !== 27) {
            return
        }

        if (this.props.requestClose) {
            this.props.requestClose()
        }
    }
}
