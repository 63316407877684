import './TaskTemplateNameContainer.scss'

import React from 'react'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { TaskTemplateViewModalContainer } from './TaskTemplateViewModalContainer'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { ConsultantTaskTemplateType, TaskTemplateType } from '~/generated/graphql'
import { localize } from '~/bootstrap'

interface Props {
    taskTemplate: TaskTemplateType | ConsultantTaskTemplateType
    onCreateTaskTemplate?: () => void
    isSubTask?: boolean
    parentTaskTemplateId?: number
    parentTaskTemplateName?: string
    forConsultantTemplate?: boolean
    forSuggestedTemplate?: boolean
    renderedOnPage?: boolean // vs rendered in modal
}

export class TaskTemplateNameContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('TaskTemplateNameContainer')

    public render() {
        return <ModalManager render={this.renderButton} renderModal={this.renderModal} />
    }

    private renderButton = (openModal: () => void) => {
        const { taskTemplate, forSuggestedTemplate } = this.props
        const { name } = taskTemplate

        return (
            <Button type={ButtonType.noStyling} onClick={openModal} className={this.bem.getClassName()}>
                <Paragraph bold={true} className={this.bem.getElement('name')}>
                    {name}
                </Paragraph>
                {forSuggestedTemplate && 'type' in taskTemplate && taskTemplate.type && (
                    <Paragraph subtle={true} className={this.bem.getElement('type')}>
                        {localize.translate(
                            t => t.Customer.Settings.TaskTemplatesOverviewView.TaskTemplateModal[taskTemplate.type!]
                        )}
                    </Paragraph>
                )}
            </Button>
        )
    }

    private renderModal = (closeModal: () => void) => {
        const { taskTemplate, isSubTask, onCreateTaskTemplate, parentTaskTemplateId } = this.props
        const { parentTaskTemplateName, forConsultantTemplate, forSuggestedTemplate, renderedOnPage } = this.props

        return (
            <TaskTemplateViewModalContainer
                requestClose={() => {
                    closeModal()

                    if (renderedOnPage && onCreateTaskTemplate) {
                        onCreateTaskTemplate()
                    }
                }}
                taskTemplateId={taskTemplate.id}
                isSubTask={isSubTask}
                onMutateTaskTemplate={onCreateTaskTemplate}
                parentTaskTemplateId={parentTaskTemplateId}
                parentTaskTemplateName={parentTaskTemplateName}
                forConsultantTemplate={forConsultantTemplate}
                forSuggestedTemplate={forSuggestedTemplate}
            />
        )
    }
}
