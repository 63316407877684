import React from 'react'
import { localize } from '~/bootstrap'
import { ItemListSpacer } from '~/components/Core/DataDisplay/ItemListSpacer/ItemListSpacer'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Column } from '~/components/Core/Layout/Column'
import { FetchMoreButton } from '~/components/Core/Pagination/FetchMoreButton'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import {
    PublishedCustomerNewsDocument,
    PublishedCustomerNewsQuery,
    PublishedCustomerNewsQueryVariables,
} from '~/generated/graphql'
import { DepartmentSwitchPopup } from '../Department/DepartmentSwitchPopup'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'
import { Row } from '~/components/Core/Layout/Row'
import { IconType } from '~/components/Core/Icon/IconType'
import { Icon } from '~/components/Core/Icon/Icon'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconLabel } from '~/components/Core/Text/IconLabel'

interface Props {
    alertId: number
}

type PublishedCustomerNewsQueryType = NonNullable<
    NonNullable<PublishedCustomerNewsQuery['publishedCustomerNews']>['nodes']
>[0]

export class AlertsPublishedFromSameSignalingNews extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(
        t => t.Customer.Inbox.InboxDetailView.AlertsPublishedFromSameSignalingNews
    )

    public render() {
        const { alertId } = this.props

        return (
            <PaginatableQuery<PublishedCustomerNewsQueryType, PublishedCustomerNewsQueryVariables>
                path="publishedCustomerNews"
                take={3}
                variables={{ filters: { sharesNewsWithAlertId: alertId } }}
                query={PublishedCustomerNewsDocument}
            >
                {({ data, loadingMore, loading, canFetchMore, fetchMore }) => {
                    const publishedCustomerNews = data && data.nodes ? data.nodes : []

                    if (loading) {
                        return <Spinner />
                    }

                    if (!publishedCustomerNews.length) {
                        return null
                    }

                    const totalCount = data?.totalCount || 0
                    const currentCount = publishedCustomerNews.length
                    const nextBatchCount = this.getLoadMoreCount(currentCount, totalCount)

                    return (
                        <Column>
                            <Row smallSpacing>
                                <Paragraph large bold>
                                    {this.loc(t => t.title)}
                                </Paragraph>
                                <Icon large type={IconType.signaling} />
                            </Row>
                            <ItemListSpacer>{publishedCustomerNews.map(this.renderItem.bind(this))}</ItemListSpacer>
                            {canFetchMore && (
                                <FetchMoreButton
                                    label={this.loc(t => t.showMore, { nextBatchCount, totalCount })}
                                    fetchMore={fetchMore}
                                    loadingMore={loadingMore}
                                />
                            )}
                        </Column>
                    )
                }}
            </PaginatableQuery>
        )
    }

    private renderItem(publishedAlert: PublishedCustomerNewsQueryType) {
        if (!publishedAlert.departments?.length) {
            return null
        }

        if (publishedAlert.departments.length === 1) {
            const d = publishedAlert.departments[0]

            return (
                <li>
                    <DepartmentSwitchPopup
                        departmentIdToToggleTo={d.id}
                        departmentNameToToggleTo={d.name}
                        onToggle={() => this.proceedToAlert(publishedAlert.alert.id)}
                        render={openPopup => this.renderLabel(publishedAlert.alert.name, openPopup)}
                    />
                    <Paragraph small subtle>
                        {localize.translate(t => t.Entities.Department)}: {d.name}
                    </Paragraph>
                </li>
            )
        }

        return (
            <li>
                <Column>
                    <Row smallSpacing>
                        <Icon type={IconType.inbox} />
                        <Paragraph truncateEllipsis>{publishedAlert.alert.name}</Paragraph>
                    </Row>
                    <Row wrap>
                        {publishedAlert.departments?.map(d => (
                            <DepartmentSwitchPopup
                                departmentIdToToggleTo={d.id}
                                departmentNameToToggleTo={d.name}
                                onToggle={() => this.proceedToAlert(publishedAlert.alert.id)}
                                render={openPopup => (
                                    <Row extraSmallSpacing>
                                        <Button
                                            type={ButtonType.subtleIcon}
                                            iconRight={IconType.textLink}
                                            onClick={openPopup}
                                        >
                                            {d.name}
                                        </Button>
                                    </Row>
                                )}
                            />
                        ))}
                    </Row>
                </Column>
            </li>
        )
    }

    private getLoadMoreCount(nodeCount: number, totalCount: number = 0) {
        const leftOverNodes = totalCount - nodeCount

        if (leftOverNodes <= 0) {
            return 0
        }

        if (leftOverNodes > 3) {
            return 3
        }

        return leftOverNodes
    }

    private proceedToAlert(alertId: number) {
        const alertPath = routes.customer(this.context.customer.slug).inbox.detail.view(alertId)
        const newWindow = window.open(alertPath, '_blank', 'noopener, noreferrer')
        if (newWindow) {
            newWindow.opener = null
        }
    }

    private renderLabel(alertName: string, openPopup?: () => void) {
        return (
            <IconLabel iconType={IconType.inbox} onClick={openPopup}>
                {alertName}
            </IconLabel>
        )
    }
}
