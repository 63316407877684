import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { CheckedMonitoringReportTopics, MonitoringReportsCreateFormModal } from './MonitoringReportsCreateFormModal'
import { CreateMonitoringReportMutationFN, MonitoringReportCreateMutation } from './MonitoringReportsCreateMutation'
import { FormState } from '~/components/Core/DataEntry/Form/Form'
import { routes } from '~/views/routes'
import { withRouter, RouteComponentProps } from 'react-router'
import { notification, localize, permissions } from '~/bootstrap'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Button } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'

interface Props extends RouteComponentProps {}

class MonitoringReportsCreateButtonContainer extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.MonitoringOverviewView)

    public render() {
        if (!permissions.canEditMonitoringReport()) {
            return null
        }

        return (
            <MonitoringReportCreateMutation>
                {(createMonitoringReport, { loading }) => (
                    <ModalManager
                        render={openModal => (
                            <Button icon={IconType.add} onClick={openModal} rounded={true}>
                                {this.loc(t => t.createNew)}
                            </Button>
                        )}
                        renderModal={closeModal => (
                            <MonitoringReportsCreateFormModal
                                loading={loading}
                                onSubmit={this.handleOnSubmit(createMonitoringReport, closeModal)}
                                onRequestClose={closeModal}
                            />
                        )}
                    />
                )}
            </MonitoringReportCreateMutation>
        )
    }

    private handleOnSubmit =
        (createMonitoringReport: CreateMonitoringReportMutationFN, closeModal: () => void) =>
        async (state: FormState, checkedDepartmentTopics?: CheckedMonitoringReportTopics[]) => {
            const { name, file, assessedAt } = state

            const response = await createMonitoringReport({
                variables: {
                    fields: {
                        name,
                        file,
                        assessedAt: assessedAt || new Date(),
                        checkedDepartmentTopics,
                    },
                },
            })

            if (response && response.data && response.data.createMonitoringReport) {
                notification.success(localize.translate(t => t.Generic.successfullyCreated))
                closeModal()

                const { id } = response.data.createMonitoringReport
                this.props.history.push(routes.customer(this.context.customer.slug).monitoring.reports.detail.view(id))
            }
        }
}

export default withRouter(MonitoringReportsCreateButtonContainer)
