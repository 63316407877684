import './InternalAlertTimelineLog.scss'
import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { BEM } from '~/services/BEMService'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize } from '~/bootstrap'
import {
    InboxInternalAlertQuery,
    InternalAlertLogFragmentFragment,
    InternalAlertStatusState,
} from '~/generated/graphql'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import { Timeline } from '~/components/Core/DataDisplay/Timeline/Timeline'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { FetchMore } from '~/components/Core/Pagination/FetchMore'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'

type InternalAlert = Pick<NonNullable<InboxInternalAlertQuery['internalAlert']>, 'id' | 'publishedAt' | 'publishedBy'>

interface Props {
    internalAlert: InternalAlert
    logs: InternalAlertLogFragmentFragment[]
    loading: boolean
    fetchMore: () => void
    canFetchMore: boolean
    loadingMore: boolean
    totalCount: number
}

export class InternalAlertTimelineLog extends React.PureComponent<Props> {
    private bem = new BEM('InternalAlertTimelineLog')
    private loc = localize.namespaceTranslate(t => t.Customer.Signaling.SignalingDetail)

    public render() {
        const { logs, loading, fetchMore, canFetchMore, loadingMore, totalCount } = this.props

        if (loading) {
            return <Spinner />
        }

        const currentCount = logs.length
        const events = this.getInternalAlertEvents()

        return (
            <>
                <Timeline events={events} />
                <FetchMore
                    canFetchMore={canFetchMore}
                    fetchMore={fetchMore}
                    loadingMore={loadingMore}
                    // because we are manually appending the created event
                    totalCount={totalCount + 1}
                    currentCount={currentCount}
                />
            </>
        )
    }

    private getInternalAlertEvents() {
        const { logs, internalAlert, canFetchMore } = this.props

        let createdEvent: InternalAlertLogFragmentFragment | undefined = undefined

        const events = logs.map(log => {
            let title: string
            let note: string | null

            const fullName = log.user?.profile.fullName

            switch (log.metaData.newState) {
                case InternalAlertStatusState.returned:
                    title = this.loc(t => t.refusedBy, { fullName })
                    note = log.metaData.returnNote
                    break
                case InternalAlertStatusState.new:
                    // we only want to show the created event at the bottom of the timeline, so we skip it here
                    if (log.type === 'INTERNAL_ALERT_WAS_CREATED') {
                        // created event should only fire once, so overwrites to this variable can be ignored
                        createdEvent = log
                        break
                    }

                    // a "new" event is dispatched when redefining relevance
                    title = this.loc(t => t.updatedRelevantBy, { fullName })
                    break
                case InternalAlertStatusState.accepted:
                    title = fullName ? this.loc(t => t.acceptedBy, { fullName }) : this.loc(t => t.accepted)
                    break
                case InternalAlertStatusState.archived:
                    title = this.loc(t => t.acceptedBy, { fullName })
                    note = log.metaData.archivedReason
                    break
                default:
                    title = log.metaData.newState
            }

            return {
                content: () => this.renderLogContent(title, note, log.occuredOn, log.user?.profile.fullName),
                date: log.occuredOn,
            }
        })

        if (internalAlert?.publishedAt && !canFetchMore && createdEvent) {
            events.push({
                content: () =>
                    this.renderLogContent(
                        this.loc(t => t.markedRelevantBy, {
                            fullName:
                                createdEvent!.user?.profile.fullName ||
                                internalAlert?.publishedBy?.user.profile.fullName,
                        }),
                        createdEvent!.metaData.note,
                        internalAlert.publishedAt,
                        internalAlert?.publishedBy?.user.profile.fullName
                    ),
                date: internalAlert?.publishedAt,
            })
        }

        return events
    }

    private renderLogContent(title: string, note: string | null, occuredOn: Date, fullName?: string) {
        if (!note) {
            return <Paragraph bold>{title}</Paragraph>
        }

        return (
            <ModalManager
                render={openModal => (
                    <Button type={ButtonType.noStyling} onClick={openModal}>
                        <Column>
                            <Paragraph bold>{title}</Paragraph>
                            <Paragraph className={this.bem.getElement('truncated-note')}>{note}</Paragraph>
                        </Column>
                    </Button>
                )}
                renderModal={closeModal => (
                    <Modal
                        requestClose={closeModal}
                        hideButtons
                        title={
                            <>
                                <Row>
                                    {fullName && (
                                        <UserProfileIcon small={true} fullName={fullName} showTooltip={true} />
                                    )}
                                    <Paragraph className={this.bem.getElement('title-styling')}>{title}</Paragraph>
                                </Row>
                                <div className={this.bem.getElement('spacing')}>{this.renderDate(occuredOn, true)}</div>
                            </>
                        }
                    >
                        <div className={this.bem.getElement('content-styling')}>
                            <div className={this.bem.getElement('content-styling')}>
                                <Paragraph bold>{this.loc(t => t.note)}</Paragraph>
                                <Paragraph className={this.bem.getElement('spacing')}>{note}</Paragraph>
                            </div>
                        </div>
                    </Modal>
                )}
            />
        )
    }

    private renderDate(date: Date, subtler?: boolean) {
        return (
            <Paragraph subtle={!subtler} verySubtle={subtler}>
                <DateFormat date={new Date(date)} readable={true} includeTime={true} noWeekday={true} />
            </Paragraph>
        )
    }
}
