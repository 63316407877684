import './DrawerModal.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Button, ButtonType } from '../../Button/Button'
import { ConfirmModal } from './ConfirmModal'
import { localize } from '~/bootstrap'
import { Row } from '../../Layout/Row'
import { IconType } from '../../Icon/IconType'
import { MakeRoomForChat } from '~/components/Chrome/ChatPlaceholder/MakeRoomForChat'

export interface DrawerModalProps {
    className?: ClassValue
    title?: string | JSX.Element
    requestClose?: () => void
    onAction?: () => void
    onDelete?: () => void
    confirmButtonLabel?: string
    actions?: (requestClose?: () => void) => JSX.Element
    hideButtons?: boolean
    renderSidebar?: JSX.Element | null
    sidebarTitle?: string | JSX.Element
    sidebarTitleAttentionIcon?: React.ReactNode
    contentHeader?: JSX.Element
}

interface State {
    currentStep: number
}

export class DrawerModal extends React.Component<DrawerModalProps, State> {
    public state: State = {
        currentStep: 0,
    }

    private bem = new BEM('DrawerModal', () => ({
        'has-sidebar': !!this.props.renderSidebar,
    }))

    public render() {
        const {
            className,
            children,
            actions,
            requestClose,
            hideButtons,
            title,
            renderSidebar,
            sidebarTitle,
            sidebarTitleAttentionIcon,
            contentHeader,
        } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <Row className={this.bem.getElement('header')} spaceBetween={true}>
                    <h2 className={this.bem.getElement('title')}>{title}</h2>
                    <Button
                        type={ButtonType.noStyling}
                        onClick={requestClose}
                        icon={IconType.closeModal}
                        ariaLabel={'Closes modal'}
                        className={this.bem.getElement('close-button')}
                    />
                </Row>
                {contentHeader && <div className={this.bem.getElement('content-header')}>{contentHeader}</div>}
                <div className={this.bem.getElement('content')}>
                    <div className={this.bem.getElement('main-content')}>{children}</div>
                    {renderSidebar && (
                        <>
                            <div className={this.bem.getElement('seperator')} />
                            <div className={this.bem.getElement('sidebar')}>
                                {sidebarTitle && (
                                    <h3 className={this.bem.getElement('sidebar-title')}>
                                        {sidebarTitle}
                                        {sidebarTitleAttentionIcon}
                                    </h3>
                                )}
                                {renderSidebar}
                            </div>
                        </>
                    )}
                </div>
                {(!hideButtons || actions) && (
                    <>
                        <div className={this.bem.getElement('action-bar-spacer')} />
                        <div className={this.bem.getElement('action-bar')}>
                            <MakeRoomForChat>
                                {heightMeasurementRef => (
                                    <div ref={heightMeasurementRef}>
                                        {!!actions && actions(requestClose)}
                                        {!actions && !hideButtons && this.renderButtons()}
                                    </div>
                                )}
                            </MakeRoomForChat>
                        </div>
                    </>
                )}
            </div>
        )
    }
    private renderButtons = () => {
        const { requestClose, onAction, confirmButtonLabel, onDelete } = this.props

        return (
            <>
                {!!onDelete && (
                    <>
                        <ConfirmModal onConfirm={closeModal => this.onDelete(closeModal)}>
                            {openModal => (
                                <Button onClick={openModal} type={ButtonType.secondary} danger={true}>
                                    {localize.translate(t => t.Core.DrawerModal.delete)}
                                </Button>
                            )}
                        </ConfirmModal>
                    </>
                )}
                <Button type={ButtonType.secondary} onClick={requestClose}>
                    {localize.translate(t => t.Core.DrawerModal.cancel)}
                </Button>
                {onAction && (
                    <Button onClick={onAction}>
                        {confirmButtonLabel ? confirmButtonLabel : localize.translate(t => t.Core.DrawerModal.confirm)}
                    </Button>
                )}
            </>
        )
    }

    private onDelete = (closeModal: () => void): void => {
        const { onDelete } = this.props
        if (onDelete) {
            onDelete()
        }
        closeModal()
    }
}
