import './CustomerFeedFrequencySelect.scss'

import { startOfToday } from 'date-fns'
import React from 'react'
import { localize } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerSubscribedToFeedTypeFrequencyTypeEnumType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'

interface Props {
    defaultFrequency?: number
    defaultFrequencyType?: CustomerSubscribedToFeedTypeFrequencyTypeEnumType
    defaultStartDate?: string | Date
    disabled?: boolean
}

export class CustomerFeedFrequencySelect extends React.PureComponent<Props> {
    private bem = new BEM('CustomerFeedFrequencySelect')
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerFeeds.CustomerFeedFrequencySelect)

    private frequencyTypeOptions = Object.keys(CustomerSubscribedToFeedTypeFrequencyTypeEnumType).map(k => ({
        label: this.loc(t => t.frequencyType[k]),
        value: CustomerSubscribedToFeedTypeFrequencyTypeEnumType[k],
    }))

    public render() {
        return (
            <Row smallSpacing={true} className={this.bem.getClassName()}>
                {this.renderFrequencyField()}
                {this.renderStartDateField()}
            </Row>
        )
    }

    private renderFrequencyField() {
        const { defaultFrequency, disabled } = this.props
        const defaultFrequencyTypeValue = this.getDefaultFrequencyTypeOption()

        return (
            <>
                <Field
                    label={this.loc(t => t.every)}
                    forInput="frequency"
                    className={this.bem.getElement('frequency-label')}
                />
                <Form.Input
                    name="frequency"
                    defaultValue={defaultFrequency?.toString()}
                    // tslint:disable-next-line:jsx-use-translation-function
                    placeholder="X"
                    className={this.bem.getElement('frequency-input')}
                    disabled={disabled}
                />
                {/* the reason for this wrapper div is because the Form.Select className is broken */}
                <div className={this.bem.getElement('frequency-type-input')}>
                    <Form.Select
                        name="frequencyType"
                        defaultValue={defaultFrequencyTypeValue}
                        options={this.frequencyTypeOptions}
                        disabled={disabled}
                    />
                </div>
            </>
        )
    }

    private renderStartDateField() {
        const { defaultStartDate, disabled } = this.props

        return (
            <>
                <Field
                    label={this.loc(t => t.startAt)}
                    forInput="startDate"
                    className={this.bem.getElement('startat-label')}
                />
                <Form.DatePicker
                    name="startDate"
                    defaultValue={defaultStartDate ? new Date(defaultStartDate) : undefined}
                    minDate={startOfToday()}
                    disabled={disabled}
                />
            </>
        )
    }

    private getDefaultFrequencyTypeOption() {
        const { defaultFrequencyType } = this.props
        if (!defaultFrequencyType) {
            return
        }

        const defaultValue = this.frequencyTypeOptions.find(op => op.value === defaultFrequencyType)
        if (!defaultValue) {
            return
        }

        return [defaultValue]
    }
}
