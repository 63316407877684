import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { CustomerFeedField, CustomerFeedFormFields, CustomerFeedStatusOption } from '../CustomerFeedFormFields'
import { getCustomerFeedSettingsForType } from '../customerFeedTypeSettingHelpers'
import { EditFeed, EditFeedMutationFN } from '../mutations/EditFeed'
import { CustomerSubscribedToFeedType } from '~/generated/graphql'
import { DeleteFeed, DeleteFeedMutationFN } from '../mutations/DeleteFeed'
import { getHours, getMinutes } from 'date-fns'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'

interface Props {
    customerFeed: CustomerSubscribedToFeedType
    onRequestClose: () => void
    onDelete: () => void
}

export class EditFeedModal extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerFeeds)

    public render() {
        return (
            <DeleteFeed>
                {(deleteMutate, { loading: deleteLoading }) => (
                    <EditFeed>
                        {(editMutate, { loading: editLoading }) => {
                            const loading = deleteLoading || editLoading

                            return (
                                <Form onSubmit={this.handleEdit(editMutate)}>
                                    {this.renderModal(deleteMutate, loading)}
                                </Form>
                            )
                        }}
                    </EditFeed>
                )}
            </DeleteFeed>
        )
    }

    private renderModal(deleteMutate: DeleteFeedMutationFN, loading: boolean) {
        const { onRequestClose, customerFeed } = this.props
        const disabledFields = customerFeed.customer.deletedAt
            ? Object.values(CustomerFeedField)
            : [CustomerFeedField.customer, CustomerFeedField.type]

        return (
            <Modal
                title={this.loc(t => t.editTitle)}
                requestClose={onRequestClose}
                loading={loading}
                submitForm={true}
                onDelete={this.handleDelete(deleteMutate)}
                confirmButtonLabel={this.loc(t => t.createModalSave)}
                disabled={!!customerFeed.customer.deletedAt}
            >
                <ErrorMessage path="editCustomerSubscribedToFeed" />
                <CustomerFeedFormFields disabledFields={disabledFields} defaultValues={customerFeed} />
            </Modal>
        )
    }

    private handleEdit = (mutate: EditFeedMutationFN) => async (formState: FormState) => {
        const { onRequestClose, customerFeed } = this.props

        const { type, id } = customerFeed
        const { frequency, frequencyType, recipientEmails, startDate, time, status } = formState

        const isActive = status?.value !== CustomerFeedStatusOption.disabled
        const parsedFrequency = frequency ? parseInt(frequency, 10) : undefined
        const updatedStartDate = this.getUpdatedStartDate(startDate, time)
        const settings = type ? getCustomerFeedSettingsForType(type, formState) : undefined

        const response = await mutate({
            variables: {
                id,
                isActive,
                settings,
                frequency: parsedFrequency,
                frequencyType: frequencyType?.value,
                startDate: updatedStartDate,
                recipientEmails,
            },
        })

        if (response && response.data && response.data.editCustomerSubscribedToFeed) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            onRequestClose()
        }
    }

    private handleDelete = (mutate: DeleteFeedMutationFN) => async () => {
        const { onRequestClose, customerFeed, onDelete } = this.props
        const { id } = customerFeed

        const response = await mutate({ variables: { id } })

        if (response && response.data && response.data.deleteCustomerSubscribedToFeed) {
            notification.success(localize.translate(t => t.Generic.successfullyDeleted))
            onRequestClose()
            onDelete()
        }
    }

    private getUpdatedStartDate(startDate?: string | Date, time?: { hours: number; minutes: number }) {
        if (!startDate && !time) {
            return
        }

        const { customerFeed } = this.props
        const defaultDate = new Date(customerFeed.startDate)

        if (!time) {
            const newDate = new Date(startDate!)
            newDate.setHours(getHours(defaultDate))
            newDate.setMinutes(getMinutes(defaultDate))
            newDate.setSeconds(0)
            newDate.setMilliseconds(0)

            return newDate
        }

        startDate = startDate ? new Date(startDate) : defaultDate

        startDate.setHours(time.hours)
        startDate.setMinutes(time.minutes)
        startDate.setSeconds(0)
        startDate.setMilliseconds(0)

        return startDate
    }
}
