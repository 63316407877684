import './ReleaseUpdateModal.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Row } from '~/components/Core/Layout/Row'
import { localize } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { WithNewlines } from '~/components/Core/Text/WithNewlines'

interface Props {
    className?: ClassValue
    steps: Step[]
    onClose: () => void
    onStepSeen: (seenStepIndex: number) => void
}

interface Step {
    title: string
    content: string
    media: any
    update: number
    version: string
}

interface State {
    activeStepIndex: number
}

export class ReleaseUpdateModal extends React.Component<Props, State> {
    public state: State = {
        activeStepIndex: 0,
    }

    private bem = new BEM('ReleaseUpdateModal')

    private containerRef = React.createRef<HTMLDivElement>()
    private width: number = 904

    public componentDidMount() {
        this.props.onStepSeen(0)

        if (this.containerRef.current) {
            this.width = this.containerRef.current.clientWidth
        }
    }

    public render() {
        const { className, onClose, steps } = this.props

        if (!steps.length) {
            return null
        }

        const showNextStep = this.state.activeStepIndex < this.props.steps.length - 1
        const showPreviousStep = this.state.activeStepIndex > 0
        const lastStepReached = this.state.activeStepIndex >= this.props.steps.length - 1

        return (
            <div className={this.bem.getClassName(className)} ref={this.containerRef}>
                <Row spaceBetween={true}>
                    <Paragraph className={this.bem.getElement('label')}>
                        {localize.translate(t => t.Generic.new)}
                    </Paragraph>
                    <Button type={ButtonType.noStyling} icon={IconType.closeModal} onClick={onClose} />
                </Row>
                <div
                    className={this.bem.getElement('list')}
                    style={{
                        transform: `translateX(-${this.width * this.state.activeStepIndex}px`,
                    }}
                >
                    {this.props.steps.map((step, i) => {
                        return (
                            <div
                                key={`${i}-${step.title}`}
                                style={{ width: `${this.width}px` }}
                                className={this.bem.getElement('block', () => ({
                                    'is-active': this.state.activeStepIndex === i,
                                }))}
                            >
                                <h1 className={this.bem.getElement('title')}>{step.title}</h1>
                                <Row className={this.bem.getElement('container')}>
                                    <Paragraph className={this.bem.getElement('content')}>
                                        <WithNewlines text={step.content} />
                                    </Paragraph>
                                    <img
                                        className={this.bem.getElement('img')}
                                        src={`/new-releases/${step.media[0].url}`}
                                    />
                                </Row>
                            </div>
                        )
                    })}
                </div>

                <Row alignRight={true} className={this.bem.getElement('buttons')}>
                    {showPreviousStep && (
                        <Button type={ButtonType.secondary} onClick={this.onPreviousClick}>
                            {localize.translate(t => t.Generic.previous)}
                        </Button>
                    )}
                    {showNextStep && (
                        <Button onClick={this.onNextClick} iconRight={IconType.nextArrow}>
                            {localize.translate(t => t.Generic.next)}
                        </Button>
                    )}
                    {lastStepReached && (
                        <Button onClick={this.props.onClose}>{localize.translate(t => t.Generic.close)}</Button>
                    )}
                </Row>
            </div>
        )
    }

    private onNextClick = () => {
        const { onStepSeen: onChange } = this.props

        this.setState(
            prevState => ({
                activeStepIndex: Math.min(this.props.steps.length - 1, prevState.activeStepIndex + 1),
            }),
            () => {
                onChange(this.state.activeStepIndex)
            }
        )
    }

    private onPreviousClick = () => {
        const { onStepSeen: onChange } = this.props

        this.setState(
            prevState => ({
                activeStepIndex: Math.max(0, prevState.activeStepIndex - 1),
            }),
            () => {
                onChange(this.state.activeStepIndex)
            }
        )
    }
}
