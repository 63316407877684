import React from 'react'
import gql from 'graphql-tag'
import { PaginationResult } from '~/components/Core/Pagination/InfiniteScrollQuery'
import Query from 'react-apollo/Query'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { GroupedTasksQueryFilters } from '~/views/Customer/Tasks/GroupedTasks/GroupedTasksQuery'

const GROUPED_TASKS_QUERY = gql`
    query itemsWithTasks($departmentId: Int!, $filters: ItemWithTaskFiltersInputType) {
        itemsWithTasks(take: 1, departmentId: $departmentId, filters: $filters) {
            totalCount
        }
    }
`
interface HasOverdueTaskQueryResponse {
    itemsWithTasks: PaginationResult<undefined>
}

interface Props {
    children: (options: { loading: boolean; hasOverdueTasks: boolean | null }) => JSX.Element
    filters?: Omit<GroupedTasksQueryFilters, 'includingOverdueTasks'>
}

export class OverdueGroupedTasksQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, filters } = this.props
        const departmentId = filters?.activeDepartmentOnly ? this.context.activeDepartmentId : undefined

        const queryFilters = {
            includingOverdueTasks: true,
            search: filters?.search,
            forEmployeeIds: filters?.forEmployeeIds,
            linkedItemType: filters?.linkedItemType,
            linkedItemInboxFromDate: filters?.linkedItemInboxFromDate,
            linkedItemInboxToDate: filters?.linkedItemInboxToDate,
            departmentId,
        }

        return (
            <Query<HasOverdueTaskQueryResponse>
                query={GROUPED_TASKS_QUERY}
                variables={{
                    filters: queryFilters,
                    departmentId: this.context.activeDepartmentId,
                }}
            >
                {({ data, loading }) => {
                    const hasOverdueTasks = data && data.itemsWithTasks ? data.itemsWithTasks.totalCount > 0 : null
                    return children({ hasOverdueTasks, loading })
                }}
            </Query>
        )
    }
}
