import React from 'react'
import { Query } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    TopicAssessmentDeLinkedItemType,
    TopicAssessmentDocument,
    TopicAssessmentQuery,
    TopicAssessmentQueryResult,
    TopicAssessmentQueryVariables,
} from '~/generated/graphql'

interface Props {
    id: number
    linkedItemId: number
    linkedItemType: TopicAssessmentDeLinkedItemType
    departmentId: number
    children: (data: TopicAssessmentQueryResult) => React.ReactNode
}

export class TopicAssessment extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, id, linkedItemId, linkedItemType, departmentId } = this.props

        return (
            <Query<TopicAssessmentQuery, TopicAssessmentQueryVariables>
                query={TopicAssessmentDocument}
                variables={{
                    id,
                    linkedItemId,
                    linkedItemType,
                    departmentId,
                }}
            >
                {children}
            </Query>
        )
    }
}
