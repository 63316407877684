import './MainProfileRow.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { CustomerConsumer } from '~/components/Providers/CustomerProvider'
import { FrameworkIcon } from '~/components/Domain/Customer/Framework/FrameworkIcon/FrameworkIcon'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'

interface Props {
    className?: ClassValue
}

interface State {
    activeIndex: number
    endReached: boolean
    startReached: boolean
}

export class MainProfileRow extends React.PureComponent<Props, State> {
    public state: State = {
        activeIndex: 0,
        endReached: false,
        startReached: true,
    }

    private bem = new BEM('MainProfileRow')

    public render() {
        const { activeIndex, endReached, startReached } = this.state

        return (
            <CustomerConsumer>
                {context => {
                    if (!context) {
                        return null
                    }

                    const profileLength = context.profiles.length
                    const profileCondition = profileLength > 6

                    return (
                        <div className={this.bem.getElement('row-wrapper')}>
                            {profileCondition && (
                                <Icon
                                    className={this.bem.getElement('arrow', () => ({
                                        'is-inactive': startReached,
                                    }))}
                                    type={IconType.arrowLeft}
                                    onClick={this.previousProfile}
                                />
                            )}
                            <div className={this.bem.getElement('header-wrapper')}>
                                <ol
                                    className={this.bem.getElement('header')}
                                    style={this.updateProfilePosition(this.state.activeIndex)}
                                >
                                    {context.profiles.map((profile, index) => {
                                        const inView = index >= activeIndex && index <= activeIndex + (6 - 1)

                                        return (
                                            <li
                                                className={this.bem.getElement('profile-icon', () => ({
                                                    'not-in-view': !inView,
                                                }))}
                                                key={profile.id}
                                                onClick={() => context.toggleProfile(profile.id)}
                                                onFocus={() => this.focusOnProfile(index, profileLength)}
                                            >
                                                <Tooltip content={profile.name}>
                                                    <button
                                                        type="button"
                                                        className={this.bem.getElement('button')}
                                                        aria-label={`Toggle filter ${profile.name}`}
                                                    >
                                                        <FrameworkIcon
                                                            frameworkType={profile.icon}
                                                            isInactive={!profile.isActive}
                                                            color={profile.color}
                                                        />
                                                    </button>
                                                </Tooltip>
                                            </li>
                                        )
                                    })}
                                </ol>
                            </div>
                            {profileCondition && (
                                <Icon
                                    className={this.bem.getElement('arrow', () => ({
                                        'is-inactive': endReached,
                                    }))}
                                    type={IconType.arrowRight}
                                    onClick={() => this.nextProfile(profileLength)}
                                />
                            )}
                        </div>
                    )
                }}
            </CustomerConsumer>
        )
    }

    private focusOnProfile = (index: number, profileLength: number) => {
        const outOfLeftView = index < this.state.activeIndex
        const outOfRightView = index > this.state.activeIndex + (6 - 1)
        const outOfView = outOfLeftView || outOfRightView

        if (!outOfView) {
            return
        }

        const delta = outOfLeftView ? -1 : 1

        this.setState(
            prevState => ({
                activeIndex: prevState.activeIndex + delta,
                endReached: false,
                startReached: false,
            }),
            () => {
                this.setEndReached(profileLength)
                this.setStartReached()
            }
        )
    }

    private previousProfile = () => {
        // We check for the value before and after incrementing, hence no variable was used
        if (this.state.activeIndex === 0) {
            return
        }

        this.setState(
            prevState => ({
                activeIndex: prevState.activeIndex - 1,
                endReached: false,
                startReached: false,
            }),
            () => {
                this.setStartReached()
            }
        )
    }

    private nextProfile = (profileLength: number) => {
        if (this.state.activeIndex === profileLength - 6) {
            return
        }

        this.setState(
            prevState => ({
                activeIndex: prevState.activeIndex + 1,
                endReached: false,
                startReached: false,
            }),
            () => {
                this.setEndReached(profileLength)
            }
        )
    }

    private updateProfilePosition = (index: number) => {
        const activeIndex = index
        const newPosition = activeIndex * 32
        const style = { transform: `translateX(${-newPosition}px)` }
        return style
    }

    private setEndReached(profileLength: number) {
        if (this.state.activeIndex === profileLength - 6) {
            this.setState({
                endReached: true,
                startReached: false,
            })
        }
    }

    private setStartReached() {
        if (this.state.activeIndex === 0) {
            this.setState({
                endReached: false,
                startReached: true,
            })
        }
    }
}
