import React from 'react'

import { DrawerModal } from '~/components/Core/Feedback/Modal/DrawerModal'
import { localize, notification } from '~/bootstrap'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationFn } from 'react-apollo'
import { AssessmentControlView } from './AssessmentControlView'
import gql from 'graphql-tag'
import { SeverityLevel } from '../Risks/RiskIndicator'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { RiskControlType } from '~/generated/graphql'
import { ASSESSMENT_SECTIONS_QUERY } from './AssessmentSections/AssessmentSectionsInfiniteScrollQuery'

interface Props {
    assessmentId: number
    assessmentSectionId: number
    controlId: number
    requestClose: () => void
    linkedRisks: RiskControlType[]
    onClickRisk: (riskId: number) => void
}

const DELETE_CONTROL_FROM_ASSESSMENT_MUTATION = gql`
    mutation deleteControlFromAssessmentSection($assessmentSectionId: Int!, $controlId: Int!) {
        deleteControlFromAssessmentSection(assessmentSectionId: $assessmentSectionId, controlId: $controlId) {
            id
        }
    }
`

export interface LinkedRisk {
    id: number
    description: string
    risk: {
        id: number
        name: string
        severity: SeverityLevel
        severityBruto: SeverityLevel
        isAccepted: boolean
    }
}

interface DeleteControlFromAssessmentResponse {
    deleteControlFromAssessmentSection: {
        id: number
    }
}

interface DeleteControlFromAssessmentVariables {
    assessmentSectionId: number
    controlId: number
}

export class AssessmentViewControlModal extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Assessments.AssessmentViewControlModal)

    public render() {
        const { assessmentId, requestClose, controlId, linkedRisks, onClickRisk } = this.props

        const refetchQueries = [
            {
                query: ASSESSMENT_SECTIONS_QUERY,
                variables: {
                    assessmentId,
                    departmentId: this.context.activeDepartmentId,
                },
            },
        ]

        return (
            <DrawerModal title={this.loc(t => t.title)} requestClose={requestClose} hideButtons={true}>
                <GQLMutation<DeleteControlFromAssessmentResponse, DeleteControlFromAssessmentVariables>
                    mutation={DELETE_CONTROL_FROM_ASSESSMENT_MUTATION}
                    refetchQueries={refetchQueries}
                >
                    {deleteMutate => (
                        <AssessmentControlView
                            selectedControlId={controlId}
                            onDelete={() => this.deleteControlFromAssessmentSection(deleteMutate)}
                            linkedRisks={linkedRisks}
                            onClickRisk={onClickRisk}
                            activeDepartmentId={this.context.activeDepartmentId}
                        />
                    )}
                </GQLMutation>
            </DrawerModal>
        )
    }

    private deleteControlFromAssessmentSection = async (
        mutate: MutationFn<DeleteControlFromAssessmentResponse, DeleteControlFromAssessmentVariables>
    ) => {
        const { requestClose, assessmentSectionId, controlId } = this.props

        const response = await mutate({
            variables: {
                assessmentSectionId,
                controlId,
            },
        })

        if (response && response.data && response.data.deleteControlFromAssessmentSection) {
            if (requestClose) {
                requestClose()
                notification.success(this.loc(t => t.deleteSuccess))
            }
        }
    }
}
