import './ExpandableRow.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '../../Icon/Icon'
import { IconType } from '../../Icon/IconType'
import { CardRow } from '~/components/Core/DataDisplay/CardRow/CardRow'

interface Props {
    className?: ClassValue
    renderOpenContent: () => React.ReactNode
    children: (toggleOpen: () => void) => React.ReactNode
}

interface State {
    isOpen: boolean
}

export class ExpandableRow extends React.Component<Props, State> {
    public state: State = {
        isOpen: false,
    }

    private bem = new BEM('ExpandableRow', () => ({ 'is-open': this.state.isOpen }))

    public render() {
        const { children, className, renderOpenContent } = this.props
        const { isOpen } = this.state

        return (
            <CardRow className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('row')}>
                    {isOpen ? (
                        <Icon
                            className={this.bem.getElement('icon')}
                            type={IconType.arrowUp}
                            onClick={this.toggleVisibility}
                        />
                    ) : (
                        <Icon
                            className={this.bem.getElement('icon')}
                            type={IconType.arrowDown}
                            onClick={this.toggleVisibility}
                        />
                    )}
                    <div className={this.bem.getElement('row-content')}>{children(this.toggleVisibility)}</div>
                </div>
                {isOpen && <div className={this.bem.getElement('content')}>{renderOpenContent()}</div>}
            </CardRow>
        )
    }

    private toggleVisibility = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }
}
