import './TaskTags.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { TaskTagFields } from '~/graphql/types/Task'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { TaskTag } from './TaskTag'
import { TaskTagType } from '~/generated/graphql'

interface Props {
    className?: ClassValue
    tags: TaskTagFields[] | TaskTagType[]
    showAsTooltip?: boolean
}

interface State {}

export class TaskTags extends React.PureComponent<Props, State> {
    private bem = new BEM('TaskTags', () => ({
        'has-tooltip': !!this.props.showAsTooltip,
        'is-single': this.props.tags.length === 1,
    }))

    public render() {
        const { tags, className, showAsTooltip } = this.props

        if (!tags.length) {
            return null
        }

        if (tags.length === 1) {
            const tag = tags[0]

            if (!tag.name) {
                return null
            }

            return <TaskTag className={this.bem.getClassName(className)} name={tag.name} />
        }

        if (showAsTooltip) {
            return (
                <Tooltip className={this.bem.getClassName(className)} distance={7} content={this.renderPlainTextTags()}>
                    <TaskTag name={`${tags.length} tags`} />
                </Tooltip>
            )
        }

        return (
            <div className={this.bem.getClassName(className)}>
                {(tags as any).map((tag: TaskTagFields) => (
                    <TaskTag className={this.bem.getElement('tag')} key={tag.id} name={tag.name} />
                ))}
            </div>
        )
    }

    private renderPlainTextTags() {
        const { tags } = this.props

        return (
            <div className={this.bem.getElement('list')}>
                {(tags as any).map((tag: TaskTagFields) => (
                    <TaskTag className={this.bem.getElement('tag')} key={tag.id} name={tag.name} />
                ))}
            </div>
        )
    }
}
