import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { EditFeedDocument, EditFeedMutation, EditFeedMutationVariables } from '~/generated/graphql'

interface Props {
    children: (mutation: EditFeedMutationFN, options: MutationResult) => JSX.Element
}

export type EditFeedMutationFN = MutationFn<EditFeedMutation, EditFeedMutationVariables>

export class EditFeed extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<EditFeedMutation, EditFeedMutationVariables> mutation={EditFeedDocument}>
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
