import './NotificationBubble.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    amount: number
}

interface State {
    isLargeNumber: boolean
}

export class NotificationBubble extends React.PureComponent<Props, State> {

    public state: State = {
        isLargeNumber: this.props.amount ? this.props.amount >= 100 : false,
    }

    private bem = new BEM('NotificationBubble', () => ({
        'is-large-number': this.state.isLargeNumber,
    }))

    public render() {
        const { className, amount } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <p className={this.bem.getElement('amount')}>{this.renderCounter(amount)}</p>
            </div>
        )
    }

    private renderCounter(amount: number) {

        const label = amount && amount > 99 ? '99+' : amount

        return label
    }
}
