import React from 'react'

import { NotFound } from '~/components/Chrome/NotFound/NotFound'
import { RouteComponentProps } from 'react-router'

interface Props extends RouteComponentProps<{}> {}
interface State {}

export class AppNotFoundView extends React.PureComponent<Props, State> {
    public render() {
        return (
            <NotFound />
        )
    }
}
