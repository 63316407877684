import './InlineTextIcon.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconType } from '../IconType'
import { Icon } from '../Icon'

interface Props {
    className?: ClassValue
    type: IconType
}

interface State {}

export class InlineTextIcon extends React.PureComponent<Props, State> {
    private bem = new BEM('InlineTextIcon')

    public render() {
        const { type, className } = this.props

        return (
            <span className={this.bem.getClassName(className)}>
                {/* tslint:disable-next-line:jsx-use-translation-function */}
                &#xfeff;
                <Icon className={this.bem.getElement('icon')} type={type} />
            </span>
        )
    }
}
