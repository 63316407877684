import './AttentionIcon.scss'

import React from 'react'
import { Tooltip } from '../../Feedback/Tooltip/Tooltip'
import { BEM, ClassValue } from '~/services/BEMService'
import { IconType } from '../IconType'
import { Icon } from '../Icon'

interface Props {
    className?: ClassValue
    tooltipContent?: React.ReactNode
    label?: string
    roundIcon?: boolean
}

const bem = new BEM('AttentionIcon')

const renderIcon = (label?: string, roundIcon?: boolean) => {
    if (roundIcon) {
        return (
            <>
                <i className={bem.getElement('round-icon')} />
                {label && <span className={bem.getElement('label')}>{label}</span>}
            </>
        )
    }

    return (
        <>
            <Icon className={bem.getElement('attention-icon')} type={IconType.attention} />
            {label && <span className={bem.getElement('label')}>{label}</span>}
        </>
    )
}

const renderContent = (tooltipContent?: React.ReactNode, label?: string, roundIcon?: boolean) => {
    if (tooltipContent) {
        return <Tooltip content={tooltipContent}>{renderIcon(label, roundIcon)}</Tooltip>
    }

    return renderIcon(label, roundIcon)
}

export const AttentionIcon: React.SFC<Props> = props => {
    const { className, tooltipContent, label, roundIcon } = props

    return <span className={bem.getClassName(className)}>{renderContent(tooltipContent, label, roundIcon)}</span>
}
