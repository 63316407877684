import './CreateCustomerView.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { localize, notification } from '~/bootstrap'
import { Page } from '~/components/Core/Layout/Page'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { routes } from '~/views/routes'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { RouteComponentProps } from 'react-router'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { breadcrumbs } from '~/views/breadcrumbs'
import { CustomerFormFields } from '~/components/Domain/Consultants/Customer/CustomerFormFields'
import {
    CreateCustomer,
    CreateCustomerMutationFN,
} from '~/components/Domain/Consultants/Customer/mutations/CreateCustomer'

interface Props extends RouteComponentProps<{}> {
    className?: ClassValue
}
export class CreateCustomerView extends React.PureComponent<Props> {
    private bem = new BEM('CreateCustomerView')

    private loc = localize.namespaceTranslate(t => t.Consultant.CreateCustomerView)
    private genericLoc = localize.namespaceTranslate(t => t.Generic)

    public render() {
        const { className } = this.props
        const defaultAvailableSeats = 1

        return (
            <Page className={this.bem.getClassName(className)}>
                <PageHeader
                    breadCrumbs={[breadcrumbs.consultant().customers.index]}
                    largerTitle={true}
                    title={this.loc(t => t.title)}
                />
                <CreateCustomer>
                    {(mutate, { loading }) => (
                        <Form
                            onSubmit={this.handleOnSubmit(mutate)}
                            defaultState={{ availableSeats: defaultAvailableSeats }}
                        >
                            <Column className={this.bem.getElement('form-container')}>
                                <ErrorMessage path={'createCustomer'} />
                                <CustomerFormFields defaultAvailableSeats={defaultAvailableSeats} />
                                <Row smallSpacing={true} alignRight={true}>
                                    <Button
                                        disabled={loading}
                                        to={routes.consultant.customers.index}
                                        type={ButtonType.secondary}
                                    >
                                        {this.genericLoc(t => t.cancel)}
                                    </Button>
                                    <Button loading={loading} submit={true}>
                                        {this.genericLoc(t => t.save)}
                                    </Button>
                                </Row>
                            </Column>
                        </Form>
                    )}
                </CreateCustomer>
            </Page>
        )
    }

    private handleOnSubmit = (mutate: CreateCustomerMutationFN) => async (formState: FormState) => {
        const customerGroupId = formState.customerGroupId ? formState.customerGroupId.value : undefined
        const consultantContactId = formState.consultantContactId ? formState.consultantContactId.value : undefined
        const ssoLoginEnabled = formState.customerGroupId ? false : formState.ssoLoginEnabled
        const createUserOnSSOLogin = customerGroupId ? false : formState.createUserOnSSOLogin

        const fields = {
            ...formState,
            consultantContactId,
            customerGroupId,
            ssoLoginEnabled,
            createUserOnSSOLogin
        }

        const response = await mutate({ variables: { fields } })

        if (response && response?.data?.createCustomer) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))

            const { id } = response.data.createCustomer
            this.props.history.push(routes.consultant.customers.view(id))
        }
    }
}
