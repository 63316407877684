import './DepartmentsTableHeader.scss'

import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { DepartmentType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { DirectionEnum } from '../CustomerFramework/CustomerFrameworksTable/CustomerFrameworksTableHeader'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { MultiDepthExpandableTableHeader } from '~/components/Core/DataDisplay/Table/MultiDepthExpandableTableHeader'
import { Column } from '~/components/Core/Layout/Column'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Truncate } from '~/components/Core/Text/Truncate'
import { localize, permissions } from '~/bootstrap'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { DepartmentEmployeesOverviewModal } from './DepartmentModals/DepartmentEmployeesOverviewModal'
import { DepartmentEmployeesEditModal } from './DepartmentModals/DepartmentEmployeesEditModal'

interface Props {
    departments: DepartmentType[]
    currentPage: number
    pageItemCount: number
    onPageChange: (direction: DirectionEnum) => void
}

export class DepartmentsTableHeader extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('DepartmentsTableHeader')
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.DepartmentsOverview)

    public render() {
        return (
            <Row alignRight={true} className={this.bem.getClassName()}>
                {this.renderButton(DirectionEnum.left)}
                <MultiDepthExpandableTableHeader headerItems={this.getHeaderItems()} />
                {this.renderButton(DirectionEnum.right)}
            </Row>
        )
    }

    private renderButton(direction: DirectionEnum) {
        const { onPageChange } = this.props

        if (direction === DirectionEnum.right) {
            const isRightDisabled = this.isButtonDisabled(DirectionEnum.right)

            return (
                <Button
                    className={this.bem.getElement('right-button')}
                    onClick={() => onPageChange(DirectionEnum.right)}
                    disabled={isRightDisabled}
                    icon={IconType.nextArrow}
                    type={ButtonType.secondary}
                />
            )
        }

        const isLeftDisabled = this.isButtonDisabled(DirectionEnum.left)
        return (
            <Button
                className={this.bem.getElement('left-button')}
                onClick={() => onPageChange(DirectionEnum.left)}
                disabled={isLeftDisabled}
                icon={IconType.backArrow}
                type={ButtonType.secondary}
            />
        )
    }

    private getHeaderItems() {
        const currentPageItems = this.getCurrentPageItems()

        return currentPageItems.map(customerFramework => ({
            id: customerFramework.id,
            render: () => this.renderHeaderItem(customerFramework),
        }))
    }

    private isButtonDisabled(direction: DirectionEnum) {
        const { departments, currentPage, pageItemCount } = this.props

        if (direction === DirectionEnum.right) {
            const totalCount = departments.length
            return totalCount <= currentPage * pageItemCount
        }

        return currentPage <= 1
    }

    private getCurrentPageItems() {
        const { currentPage, departments, pageItemCount } = this.props

        const totalRange = departments.length
        const index = (currentPage - 1) * pageItemCount
        const range = currentPage * pageItemCount

        return departments.slice(index, range > totalRange ? totalRange : range)
    }

    private renderHeaderItem(department: DepartmentType) {
        const canEdit = permissions.isAllowedToEditDepartments()

        return (
            <ModalManager
                render={openOverviewModal => (
                    <Column smallSpacing={true} className={this.bem.getElement('header-item')}>
                        <Button type={ButtonType.noStyling} onClick={openOverviewModal}>
                            <Tooltip
                                direction={ToolTipDirection.down}
                                content={this.getTooltipContent(department.name)}
                            >
                                <Column extraSmallSpacing={true}>
                                    <Paragraph bold={true} className={this.bem.getElement('department-name')}>
                                        <Truncate
                                            multiLineTruncate={department.name}
                                            maxCharPerMultiLine={11}
                                            noTitle={true}
                                        />
                                    </Paragraph>
                                    <Paragraph
                                        small={true}
                                        subtle={true}
                                        className={this.bem.getElement('employee-count')}
                                    >
                                        {this.loc(t => t.employeeCount, {
                                            count: department.amountOfEmployees,
                                        })}
                                    </Paragraph>
                                </Column>
                            </Tooltip>
                        </Button>
                        {canEdit && (
                            <ModalManager
                                render={openEditModal => (
                                    <Button
                                        type={ButtonType.subtleSecondary}
                                        icon={IconType.edit}
                                        onClick={openEditModal}
                                        className={this.bem.getElement('edit-button')}
                                    />
                                )}
                                renderModal={closeEditModal => (
                                    <DepartmentEmployeesEditModal department={department} closeModal={closeEditModal} />
                                )}
                            />
                        )}
                    </Column>
                )}
                renderModal={closeModal => (
                    <DepartmentEmployeesOverviewModal department={department} closeModal={closeModal} />
                )}
            />
        )
    }

    private getTooltipContent(departmentName: string) {
        return <Paragraph bold={true}>{departmentName}</Paragraph>
    }
}
