import './PinnableCard.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { Pin } from '../Pin'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Column } from '~/components/Core/Layout/Column'
import { IconType } from '~/components/Core/Icon/IconType'
import { ActionButton } from '~/components/Core/ActionButton/ActionButton'

interface Props {
    className?: ClassValue
    isPinned?: boolean
    togglePinned: () => void
    to: string
    title: string | JSX.Element
    labelTag?: React.ReactNode
    sidebar?: React.ReactNode
    notPermittedToPin?: boolean
    bottomComponent?: React.ReactNode
    hidePin?: boolean
    onInfoButtonClick?: () => void
}

interface State {}

export class PinnableCard extends React.PureComponent<Props, State> {
    private bem = new BEM('PinnableCard')

    public render() {
        const { to, labelTag, className, title, sidebar, bottomComponent, onInfoButtonClick } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <Column>
                    <Card to={to} className={this.bem.getElement('card')}>
                        <Row flexStart={true}>
                            <Column className={this.bem.getElement('header-container')}>
                                {typeof title === 'string' ? <Paragraph emphasis={true}>{title}</Paragraph> : title}
                                {labelTag}
                            </Column>
                            {sidebar}
                        </Row>
                    </Card>
                    {onInfoButtonClick && this.renderInfoButton(onInfoButtonClick)}
                </Column>
                {bottomComponent}
                {this.renderPin()}
            </div>
        )
    }

    private renderPin() {
        const { hidePin, isPinned, togglePinned, notPermittedToPin } = this.props

        if (hidePin) {
            return
        }

        return (
            <Pin
                togglePinned={() => togglePinned()}
                isPinned={isPinned}
                disabled={notPermittedToPin}
                className={this.bem.getElement('pin', () => ({
                    pinned: isPinned,
                }))}
            />
        )
    }

    private renderInfoButton(onClick: () => void) {
        return <ActionButton secondary onClick={onClick} className={this.bem.getElement('info')} icon={IconType.info} />
    }
}
