import React from 'react'
import gql from 'graphql-tag'
import { PaginationResult } from '~/components/Core/Pagination/InfiniteScrollQuery'
import Query from 'react-apollo/Query'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TasksQueryFilters } from '~/views/Customer/Tasks/TasksQuery/TaskQuery'

const TASKS_QUERY = gql`
    query tasks($filters: TaskFiltersInputType, $sort: TaskSortInputType) {
        tasks(filters: $filters, sort: $sort, take: 1) {
            totalCount
        }
    }
`
interface HasOverdueTaskQueryResponse {
    tasks: PaginationResult<undefined>
}

interface Props {
    children: (options: { loading: boolean; hasOverdueTasks: boolean | null; refetch: () => void }) => JSX.Element
    filters?: Omit<Omit<TasksQueryFilters, 'onlyOverdueTasks'>, 'completedOnly'>
}

export class OverdueTaskQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, filters } = this.props
        const departmentIds = filters?.activeDepartmentOnly ? [this.context.activeDepartmentId] : undefined
        const queryFilters = {
            onlyOverdueTasks: true,
            completedOnly: false,
            activeDepartmentOnly: undefined,
            departmentIds,
            search: filters?.search,
            forEmployeeIds: filters?.forEmployeeIds,
            forMonitoring: filters?.forMonitoring,
            forLinkedItem: filters?.forLinkedItem,
            tagIds: filters?.tagIds,
            linkedItemInboxFromDate: filters?.linkedItemInboxFromDate,
            linkedItemInboxToDate: filters?.linkedItemInboxToDate,
            linkedItemType: filters?.linkedItemType,
        }

        return (
            <Query<HasOverdueTaskQueryResponse> query={TASKS_QUERY} variables={{ filters: queryFilters }}>
                {({ data, loading, refetch }) => {
                    const hasOverdueTasks = data && data.tasks ? data.tasks.totalCount > 0 : null

                    return children({ hasOverdueTasks, loading, refetch })
                }}
            </Query>
        )
    }
}
