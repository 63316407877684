import './AnnotatedCheckbox.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Checkbox } from './Checkbox'
import { Input } from './Input'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    name: string
    onChange?: (value: AnnotatedCheckboxValue, name: string) => void
    label: string | JSX.Element
    labelIcon?: JSX.Element
    labelPlaceholder?: string
    indeterminate?: boolean
    defaultChecked?: boolean
    defaultAnnotationValue?: string | null
    isDisabled?: boolean
    singleLine?: boolean
}

interface State {
    isChecked: boolean
    annotation?: string | null
}

export interface AnnotatedCheckboxValue {
    isChecked: boolean
    annotation?: string | null
}

export class AnnotatedCheckbox extends React.PureComponent<Props, State> {
    public state: State = {
        isChecked: !!this.props.defaultChecked,
    }

    private bem = new BEM('AnnotatedCheckbox', () => ({
        isDisabled: !!this.props.isDisabled,
    }))

    public render() {
        const {
            name,
            className,
            label,
            labelIcon,
            labelPlaceholder,
            indeterminate,
            defaultChecked,
            defaultAnnotationValue,
            isDisabled,
            singleLine,
        } = this.props

        const { isChecked } = this.state

        const placeholder = labelPlaceholder || localize.translate(t => t.Core.AnnotatedCheckbox.placeholder)

        return (
            <div className={this.bem.getClassName(className)}>
                <label className={this.bem.getElement('label-container')}>
                    <span className={this.bem.getElement('label')}>
                        {labelIcon && <span className={this.bem.getElement('label-icon')}>{labelIcon}</span>}
                        {label}
                    </span>
                    <Checkbox
                        className={this.bem.getElement('checkbox')}
                        name={name}
                        indeterminate={indeterminate}
                        defaultChecked={defaultChecked}
                        onChange={this.handeCheckboxChange}
                        disabled={isDisabled}
                    />
                </label>
                {isChecked && (
                    <Input
                        type={singleLine ? 'text' : 'textarea'}
                        onChange={this.handleInputChange}
                        name={`${name}-annotation`}
                        className={this.bem.getElement('annotation')}
                        placeholder={placeholder}
                        defaultValue={defaultAnnotationValue}
                        disabled={isDisabled}
                    />
                )}
            </div>
        )
    }

    private handeCheckboxChange = (isChecked: boolean) => {
        this.setState(
            {
                isChecked,
                annotation: null,
            },
            this.handleOnChange
        )
    }

    private handleInputChange = (annotation: string | null) => {
        this.setState({ annotation }, this.handleOnChange)
    }

    private handleOnChange = () => {
        const { onChange, name } = this.props
        const { isChecked, annotation } = this.state

        if (!onChange) {
            return
        }

        onChange(
            {
                isChecked,
                annotation: isChecked ? annotation : null,
            },
            name
        )
    }
}
