import './DashboardView.scss'
import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { Page } from '~/components/Core/Layout/Page'
import { LogOutButton } from '~/components/Chrome/LogOutButton/LogOutButton'
import { Row } from '~/components/Core/Layout/Row'
import { PageTitle } from '~/components/Core/Text/PageTitle'

import { localize } from '~/bootstrap'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerDomainItemListLayout } from '~/components/Domain/Consultants/Customer/CustomerDomainItemListLayout'
import { CustomerAvatar } from '~/components/Domain/Consultants/Customer/CustomerDomainItem'
import { MediaQuery, Screensize } from '~/components/Core/Layout/MediaQuery'
import { getGreetingForTimeOfDay } from '~/utils/greetUser'
import { Center } from '~/components/Core/Layout/Center'
import ConsultantMainIllustration from './ConsultantMainIllustration.svg'
import { Button } from '~/components/Core/Button/Button'
import { Column } from '~/components/Core/Layout/Column'
import { routes } from '~/views/routes'
import { Query } from 'react-apollo'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import gql from 'graphql-tag'
import { UserContext, UserContextValue } from '~/components/Providers/UserProvider'

interface Props {
    className?: ClassValue
}

interface State {}

interface Customer {
    id: number
    slug: string
    name: string
    logo: string | null
}

interface LastCustomersQueryResponse {
    customers: {
        nodes: Customer[]
    } | null
}

const LAST_CUSTOMERS_QUERY = gql`
    query customers($take: Int, $lastConsultantLogin: Int!) {
        customers(take: $take, sort: { lastConsultantLogin: $lastConsultantLogin }) {
            nodes {
                id
                slug
                name
                logo
            }
        }
    }
`

export class DashboardView extends React.PureComponent<Props, State> {
    public static contextType = UserContext
    public context: UserContextValue

    private bem = new BEM('DashboardView')

    private pageLoc = localize.namespaceTranslate(t => t.Consultant.DashboardView)

    public render() {
        const { className } = this.props

        return (
            <Page className={this.bem.getClassName(className)}>
                <MediaQuery breakpoint={Screensize.tablet}>{this.renderLogoutButton()}</MediaQuery>

                <Query<LastCustomersQueryResponse>
                    query={LAST_CUSTOMERS_QUERY}
                    variables={{ take: 5, lastConsultantLogin: this.context!.consultant!.id }}
                >
                    {({ data, loading }) => {
                        if (loading) {
                            return (
                                <>
                                    <Spinner delayed={true} />
                                </>
                            )
                        }

                        const customers = data && data.customers ? data.customers.nodes || [] : []
                        return (
                            <>
                                <Row className={this.bem.getElement('initial-band')} alignCenter={true}>
                                    <div className={this.bem.getElement('title-container')}>
                                        <PageTitle
                                            className={this.bem.getElement('title')}
                                            title={getGreetingForTimeOfDay()}
                                        />

                                        <Paragraph className={this.bem.getElement('suffix')}>
                                            {customers.length
                                                ? this.pageLoc(t => t.goToEnvironmentMessage)
                                                : this.pageLoc(t => t.noCustomersErrorMessage)}
                                        </Paragraph>
                                    </div>
                                </Row>
                                {customers.length ? this.renderCustomerItems(customers) : this.renderEmptyState()}
                            </>
                        )
                    }}
                </Query>
            </Page>
        )
    }

    private renderLogoutButton() {
        return (
            <div className={this.bem.getElement('button-container')}>
                <LogOutButton />
            </div>
        )
    }

    private renderEmptyState(): React.ReactNode {
        return (
            <Center>
                <Column>
                    <figure>
                        <img className={this.bem.getElement('illustration')} src={ConsultantMainIllustration} />
                    </figure>
                    <Row alignCenter={true}>
                        <Button className={this.bem.getElement('button')} to={routes.consultant.customers.index}>
                            {this.pageLoc(t => t.goToCustomerOverview)}
                        </Button>
                    </Row>
                </Column>
            </Center>
        )
    }

    private renderCustomerItems(customers: Customer[]): React.ReactNode {
        return (
            <Row alignCenter={true} className={this.bem.getElement('customer-container')}>
                <CustomerDomainItemListLayout>
                    {customers.map((customer, index) => (
                        <li key={`${customer.name}-${index}`}>
                            <CustomerAvatar
                                name={customer.name}
                                uri={customer.logo}
                                to={`/${customer.slug}/legal-framework`}
                                suffix={this.pageLoc(t => t.goToEnvironment)}
                            />
                        </li>
                    ))}
                </CustomerDomainItemListLayout>
            </Row>
        )
    }
}
