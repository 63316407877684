import './TaskCounter.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { PriorityIndicator } from '~/components/Domain/Task/PriorityIcon/PriorityIndicator'
import { LinkedTaskItem } from '~/graphql/types/Task'
import { Row } from '~/components/Core/Layout/Row'
import { Column } from '~/components/Core/Layout/Column'

interface Props {
    className?: ClassValue
    openAmount: number
    closedAmount: number
    overDueAmount?: number
    linkedItem?: LinkedTaskItem
}

export class TaskCounter extends React.Component<Props> {
    private loc = localize.namespaceTranslate(t => t.Customer.Task)
    private bem = new BEM('TaskCounter')

    public render() {
        const { openAmount, closedAmount, overDueAmount } = this.props

        if (!openAmount && !closedAmount) {
            return null
        }

        return (
            <Tooltip
                className={this.bem.getClassName()}
                disabled={!overDueAmount}
                content={
                    <Paragraph small emphasis>
                        {this.loc(t => t.TaskWidget.overDueTasks, { smart_count: overDueAmount })}
                    </Paragraph>
                }
            >
                <Row fullWidth noSpacing>
                    {!!overDueAmount && (
                        <Icon className={this.bem.getElement('attention-icon')} type={IconType.attention} />
                    )}
                    {this.renderTaskCounts()}
                </Row>
            </Tooltip>
        )
    }

    private renderTaskCounts() {
        const { openAmount, closedAmount } = this.props
        const { priority, dueAt } = this.getNextDeadlineAndPriority()

        return (
            <Row noSpacing>
                {priority && (
                    <div className={this.bem.getElement('icon-container')}>
                        <PriorityIndicator isTooltip priority={priority} />
                    </div>
                )}

                <Column noSpacing>
                    <Row extraSmallSpacing>
                        <Paragraph bold className={this.bem.getElement('count')}>
                            {this.loc(t => t.TaskWidget.task, { smart_count: openAmount })}
                        </Paragraph>
                        <Paragraph className={this.bem.getElement('icon')}>
                            {this.loc(t => t.TaskWidget.closedTasks, { closedAmount })}
                        </Paragraph>
                    </Row>
                    <Paragraph subtle className={this.bem.getElement('count')}>
                        {dueAt
                            ? this.loc(t => t.TaskWidget.firstDeadline, {
                                  date: localize.dateFormat(new Date(dueAt), { noWeekday: true }),
                              })
                            : this.loc(t => t.TaskWidget.noDeadline)}
                    </Paragraph>
                </Column>
            </Row>
        )
    }

    private getNextDeadlineAndPriority() {
        const { linkedItem } = this.props

        if (linkedItem?.nextTask) {
            return {
                dueAt: linkedItem.nextTask.dueAt,
                priority: linkedItem.nextTask.priority,
            }
        }

        if (linkedItem?.highestPrioTask) {
            return {
                priority: linkedItem.highestPrioTask?.priority,
            }
        }

        return {}
    }
}
