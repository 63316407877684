import './ItemListSpacer.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

interface State {}

export class ItemListSpacer extends React.Component<Props, State> {
    private bem = new BEM('ItemListSpacer')

    public render() {
        const { children, className } = this.props

        return <ul className={this.bem.getClassName(className)}>{children}</ul>
    }
}
