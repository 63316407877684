import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { PageQuery, PageQueryOptions } from '../../../PageQuery/PageQuery'
import { ControlDocument, ControlQuery as ControlQueryData } from '~/generated/graphql'

interface Props {
    children: (control: Control, options: PageQueryOptions) => React.ReactNode
    controlId: number
}

interface State {}

export const CONTROL_DETAIL_QUERY = ControlDocument
export type ControlDocument = NonNullable<Control['controlDocuments']>[0]
export type Control = NonNullable<ControlQueryData['control']>
export type ControlVisibleToDepartment = NonNullable<Control['visibleToDepartments']>[0]

export class ControlQuery extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, controlId } = this.props

        return (
            <PageQuery<Control>
                query={CONTROL_DETAIL_QUERY}
                variables={{
                    id: controlId,
                    customerSlug: this.context.customer.slug,
                    departmentId: this.context.activeDepartmentId,
                }}
            >
                {(control, options) => children(control, options)}
            </PageQuery>
        )
    }
}
