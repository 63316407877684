import React from 'react'

import { ToolbarItem } from '~/components/Chrome/Navigation/Tool/ToolbarItem'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { IconType } from '~/components/Core/Icon/IconType'
import { CreateTaskModalContainer } from '~/components/Domain/Task/CreateTaskModalContainer'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'

interface Props {
    className?: string
}

export class CreateGenericTaskToolBarItem extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { className } = this.props
        const url = routes.customer(this.context.customer.slug).monitoring.agenda

        const onMonitoringPage = window.location.href.includes(url)

        return (
            <ModalManager
                render={openCreateTaskModal => {
                    return <ToolbarItem icon={IconType.taskAdd} onClick={openCreateTaskModal} className={className} />
                }}
                renderModal={closeModal => (
                    <CreateTaskModalContainer requestClose={closeModal} forMonitoring={onMonitoringPage} />
                )}
            />
        )
    }
}
