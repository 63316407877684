import React from 'react'

import { ViewTaskModal } from './ViewTaskModal'
import { GetTaskQuery } from './GetTaskQuery'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'

interface Props {
    taskId: number
    onCompleteOrReopen?: () => void
    onChangeTask?: () => void
    onArchiveTask?: () => void
    requestClose: () => void
    showDepartment?: boolean
}

interface State {}

export class ViewTaskModalContainer extends React.PureComponent<Props, State> {
    public render() {
        const { taskId, requestClose, onCompleteOrReopen, onChangeTask, onArchiveTask, showDepartment } = this.props

        return (
            <GetTaskQuery taskId={taskId}>
                {({ data, loading, refetch }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    const task = data && data.task

                    return (
                        <ViewTaskModal
                            showDepartment={showDepartment}
                            loading={loading}
                            task={task}
                            requestClose={requestClose}
                            onCompleteOrReopen={
                                onCompleteOrReopen
                                    ? () => {
                                          onCompleteOrReopen()
                                          refetch()
                                      }
                                    : undefined
                            }
                            onChangeTask={
                                onChangeTask
                                    ? () => {
                                          onChangeTask()
                                          refetch()
                                      }
                                    : undefined
                            }
                            onArchiveTask={
                                onArchiveTask
                                    ? () => {
                                          onArchiveTask()
                                          refetch()
                                      }
                                    : undefined
                            }
                        />
                    )
                }}
            </GetTaskQuery>
        )
    }
}
