import React from 'react'

interface Props {
    breakpoint: string
}

interface State {
    matches: boolean
}

export enum Screensize {
    mobile = '(max-width: 767px)',
    tablet = '(min-width: 768px)',
    desktop = '(min-width: 1024px)',
    betweenTabletAndDesktop = '(min-width: 768px) and (max-width: 1023px)',
}

export class MediaQuery extends React.Component<Props, State> {

    public state: State = {
        matches: this.matchesRule(),
    }

    private mediaQuery = window.matchMedia(this.props.breakpoint)

    public componentDidMount() {
        this.mediaQuery.addListener(this.mediaQueryListener)

        const matches = this.matchesRule()

        if (matches !== this.state.matches) {
            this.setState({ matches })
        }
    }

    public componentWillUnmount() {
        this.mediaQuery.removeListener(this.mediaQueryListener)
    }

    public render() {
        const { children } = this.props
        const { matches } = this.state

        if (!matches) {
            return null
        }

        return children
    }

    private mediaQueryListener = ({ matches }: MediaQueryListEvent) => {
        this.setState({ matches })
    }

    private matchesRule() {
        if (!this.mediaQuery) {
            this.mediaQuery = window.matchMedia(this.props.breakpoint)
        }
        return this.mediaQuery.matches
    }
}
