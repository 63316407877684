import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Modal, ModalSize } from '~/components/Core/Feedback/Modal/Modal'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Bold } from '~/components/Core/Typography/Bold'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    TopicAssessmentDesignOrEffectiveNessType,
    TopicAssessmentGradeType,
    TopicControlMeasureType,
} from '~/generated/graphql'
import {
    SetGradeForLinkedTopicControls,
    SetGradeForLinkedTopicControlsFN,
} from '../../mutations/SetGradeForLinkedTopicControls'
import { TopicAssessmentLinkedControlsTable } from './TopicAssessmentLinkedControlsTable'

interface Props {
    topicId: number
    topicControlMeasure: TopicControlMeasureType
    topicAssessmentType: TopicAssessmentDesignOrEffectiveNessType
    grade: TopicAssessmentGradeType
    previousGrade: TopicAssessmentGradeType
    requestClose: () => void
}

export class TopicAssessmentForLinkedControlsModal extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicAssessmentForLinkedControlsModal)

    public render() {
        const { requestClose, topicId } = this.props
        const { title, descriptionStart } = this.getTitleAndDescription()

        return (
            <SetGradeForLinkedTopicControls topicId={topicId}>
                {(mutate, { loading }) => (
                    <Modal
                        modalSize={ModalSize.small}
                        title={title}
                        requestClose={requestClose}
                        actions={() => this.renderActions(mutate, loading)}
                    >
                        <Column bigSpacing={true}>
                            {this.renderInfo(descriptionStart)}
                            {this.renderTable()}
                        </Column>
                    </Modal>
                )}
            </SetGradeForLinkedTopicControls>
        )
    }

    private getTitleAndDescription() {
        const { topicAssessmentType } = this.props

        if (topicAssessmentType === TopicAssessmentDesignOrEffectiveNessType.design) {
            return {
                title: this.loc(t => t.designTitle),
                descriptionStart: this.loc(t => t.designDescriptionStart),
            }
        }

        return {
            title: this.loc(t => t.effectivenessTitle),
            descriptionStart: this.loc(t => t.effectivenessDescriptionStart),
        }
    }

    private renderActions(mutate: SetGradeForLinkedTopicControlsFN, loading: boolean) {
        const { requestClose } = this.props

        return (
            <Row alignRight={true}>
                <Row smallSpacing={true}>
                    <Button type={ButtonType.tertiary} onClick={requestClose} disabled={loading}>
                        {this.loc(t => t.dontApply)}
                    </Button>
                    <Button loading={loading} onClick={this.handleSubmit(mutate)}>
                        {this.loc(t => t.apply)}
                    </Button>
                </Row>
            </Row>
        )
    }

    private renderInfo(descriptionStart: string) {
        const { name } = this.props.topicControlMeasure.control

        return (
            <Column>
                <Paragraph>
                    {descriptionStart}
                    <Bold inline={true}>{name}</Bold>
                    {this.loc(t => t.descriptionEnd)}
                </Paragraph>
                <Paragraph>{this.loc(t => t.question)}</Paragraph>
            </Column>
        )
    }

    private renderTable() {
        const { topicAssessmentType, topicControlMeasure, grade, previousGrade } = this.props

        return (
            <TopicAssessmentLinkedControlsTable
                topicAssessmentType={topicAssessmentType}
                topicControlMeasure={topicControlMeasure}
                newGrade={grade}
                previousGrade={previousGrade}
            />
        )
    }

    private handleSubmit = (mutate: SetGradeForLinkedTopicControlsFN) => async () => {
        const { topicControlMeasure, topicAssessmentType, grade, requestClose } = this.props
        const { activeDepartmentId } = this.context

        const response = await mutate({
            variables: {
                currentTopicControlMeasureId: topicControlMeasure.id,
                linkedItemType: topicAssessmentType,
                grade,
                departmentId: activeDepartmentId,
            },
        })

        if (response && response.data?.setGradeForInheritedTopicControlMeasures) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            requestClose()
        }
    }
}
