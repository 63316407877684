import React from 'react'
import './ControlsOverviewView.scss'
import { Page } from '~/components/Core/Layout/Page'
import { RouteComponentProps } from 'react-router'
import { ControlsContainer } from '~/components/Domain/Compliance/Controls/ControlsContainer'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { localize, permissions } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { AssessmentSelect } from '~/components/Domain/Compliance/Assessments/AssessmentSelect'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Button } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { AddControlModal } from '~/components/Domain/Compliance/Controls/AddControlModal'
import { ControlStatus, ControlTypeType } from '~/generated/graphql'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { TopicSelect } from '~/components/Domain/Compliance/Norms/TopicSelect'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { ControlTypeCheckboxes } from '~/components/Domain/Compliance/Controls/ControlTypeCheckboxes'
import { breadcrumbs } from '~/views/breadcrumbs'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import { ParamManager, SetParamStateFn } from '~/components/Core/ParamManager/ParamManager'
import { BEM, ClassValue } from '~/services/BEMService'
import { Column } from '~/components/Core/Layout/Column'
import { ControlStatusSelect } from '~/components/Domain/Compliance/Norms/ControlStatusSelect'

interface Props extends RouteComponentProps<{}> {
    className?: ClassValue
}

interface Filters {
    search: string | null
    controlTypesFilters: ControlTypeType[]
    assessmentFilterOption?: SelectOption<string> | null
    topicFilterOption?: SelectOption<string> | null
    archiveFilterOption: ControlStatus
}

export class ControlsOverviewView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private static defaultState: Filters = {
        search: '',
        controlTypesFilters: [],
        assessmentFilterOption: null,
        topicFilterOption: null,
        archiveFilterOption: ControlStatus.open,
    }

    private bem = new BEM('ControlsOverviewView')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.Overview)

    public render() {
        const { className } = this.props
        const canCreateControl = permissions.canUpdateComplianceAndDocuments(this.context.activeDepartmentId)

        return (
            <Page className={this.bem.getClassName(className)}>
                <ParamManager<Filters> defaultState={ControlsOverviewView.defaultState}>
                    {({ paramState, setParamState }) => {
                        const { search, topicFilterOption, assessmentFilterOption, controlTypesFilters } = paramState
                        const { archiveFilterOption } = paramState

                        const filterActive = this.isFilterActive(paramState)

                        const filterByTopicId = topicFilterOption ? parseInt(topicFilterOption.value, 10) : undefined
                        const filterByAssessmentId = assessmentFilterOption
                            ? parseInt(assessmentFilterOption.value, 10)
                            : undefined

                        return (
                            <>
                                <PageHeader
                                    title={this.loc(t => t.title)}
                                    breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).compliance.index]}
                                    actionComponent={
                                        <Row>
                                            <Search
                                                placeholder={this.loc(t => t.searchPlaceholder)}
                                                defaultValue={search}
                                                onChange={search => setParamState({ search: search || '' })}
                                            />
                                            {this.renderFilters(filterActive, paramState, setParamState)}
                                            {canCreateControl && (
                                                <ModalManager
                                                    render={openModal => (
                                                        <Button icon={IconType.add} onClick={openModal} rounded={true}>
                                                            {this.loc(t => t.addControl)}
                                                        </Button>
                                                    )}
                                                    renderModal={closeModal => (
                                                        <AddControlModal requestClose={closeModal} />
                                                    )}
                                                />
                                            )}
                                        </Row>
                                    }
                                />
                                <Column className={this.bem.getElement('control-container')}>
                                    <ControlsContainer
                                        search={search}
                                        types={controlTypesFilters}
                                        assessmentId={filterByAssessmentId}
                                        topicId={filterByTopicId}
                                        isFilterActive={filterActive}
                                        status={archiveFilterOption}
                                    />
                                </Column>
                            </>
                        )
                    }}
                </ParamManager>
            </Page>
        )
    }

    private isFilterActive(paramState: Filters) {
        const { controlTypesFilters, topicFilterOption, assessmentFilterOption, archiveFilterOption } = paramState

        return !!(
            controlTypesFilters?.length > 0 ||
            topicFilterOption ||
            assessmentFilterOption ||
            archiveFilterOption === ControlStatus.archived
        )
    }

    private renderFilters(filterActive: boolean, paramState: Filters, setParamState: SetParamStateFn<Filters>) {
        const { archiveFilterOption, topicFilterOption, assessmentFilterOption, controlTypesFilters } = paramState

        return (
            <FilterDropdown hasActiveFilter={filterActive}>
                <FilterOption label={this.loc(t => t.filters.status)} forInputName="status">
                    <ControlStatusSelect
                        isFilter={true}
                        name={'status'}
                        defaultValue={archiveFilterOption}
                        onChange={status => setParamState({ archiveFilterOption: status })}
                    />
                </FilterOption>
                <FilterOption label={this.loc(t => t.filters.topics)} forInputName="topic">
                    <TopicSelect
                        isFilter={true}
                        placeholder={this.loc(t => t.topicsPlaceholder)}
                        onChange={option => {
                            setParamState({ topicFilterOption: option })
                        }}
                        selectedOptions={topicFilterOption ? [topicFilterOption] : undefined}
                    />
                </FilterOption>
                <FilterOption label={this.loc(t => t.filters.assessment)} forInputName="assessment">
                    <AssessmentSelect
                        isFilter={true}
                        placeholder={this.loc(t => t.assessmentsPlaceholder)}
                        onChange={option => {
                            setParamState({ assessmentFilterOption: option })
                        }}
                        selectedOptions={assessmentFilterOption ? [assessmentFilterOption] : undefined}
                    />
                </FilterOption>
                <FilterOption label={this.loc(t => t.filters.controlType)} forInputName="controlType">
                    <ControlTypeCheckboxes
                        onChange={types => {
                            setParamState({ controlTypesFilters: types })
                        }}
                        defaultSelectedControlTypes={controlTypesFilters}
                    />
                </FilterOption>
            </FilterDropdown>
        )
    }
}
