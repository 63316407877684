import React from 'react'
import { localize } from '~/bootstrap'
import { BEM, ClassValue } from '~/services/BEMService'
import './AssessmentCoverageBarSmall.scss'
import { AssessmentCoverageBarType, getAssessmentPercentages } from './AssessmentCoverageBarService'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Row } from '~/components/Core/Layout/Row'
import { AssessmentCoverageType } from '~/generated/graphql'

interface Props {
    className?: ClassValue
    assessmentCoverage?: AssessmentCoverageType
    loadingCoverage?: boolean
    renderLabel?: () => JSX.Element
}

export class AsssessmentCoverageBarSmall extends React.PureComponent<Props> {
    private bem = new BEM('AsssessmentCoverageBarSmall')

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.AssessmentCoverageBar)

    public render() {
        const { className, renderLabel, loadingCoverage } = this.props

        if (loadingCoverage) {
            return (
                <Row alignRight={true} className={this.bem.getElement('loading-wrapper')}>
                    <Spinner slow={true} small={true} />
                </Row>
            )
        }

        const assessmentValues = getAssessmentPercentages(this.props.assessmentCoverage)

        return (
            <div className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('assessment-coverage-bar-container')}>
                    {renderLabel && renderLabel()}
                    <div className={this.bem.getElement('assessment-coverage-bar')}>
                        <div
                            style={{ width: `${assessmentValues.assessed.amount}%` }}
                            className={this.bem.getElement('assessment-coverage-bar-item', () => ({
                                [AssessmentCoverageBarType.assessed]: true,
                            }))}
                        />
                        <div
                            style={{ width: `${assessmentValues.noted.amount}%` }}
                            className={this.bem.getElement('assessment-coverage-bar-item', () => ({
                                [AssessmentCoverageBarType.noted]: true,
                            }))}
                        />
                    </div>
                    <div className={this.bem.getElement('assessment-coverage-details')}>
                        <p>
                            {/* tslint:disable-next-line:jsx-use-translation-function */}
                            {this.loc(t => t.percentage, { percentage: assessmentValues.noted.amount })}{' '}
                            {this.loc(t => t.assessmentType[AssessmentCoverageBarType.noted])}
                        </p>
                        <p>
                            {/* tslint:disable-next-line:jsx-use-translation-function */}
                            {this.loc(t => t.percentage, { percentage: assessmentValues.assessed.amount })}{' '}
                            {this.loc(t => t.assessmentType[AssessmentCoverageBarType.assessed])}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
