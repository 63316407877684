import React from 'react'
import gql from 'graphql-tag'
import { Session } from '~/graphql/types/Session'
import { Mutation, MutationFn, MutationResult } from 'react-apollo'
import { ErrorProvider } from '~/components/Providers/ErrorProvider'

export interface LoginMutationResponse {
    loginWithCredentials: Session
}

export interface LoginMutationVariables {
    email: string | null
    password: string | null
    rememberMe?: boolean
}

export const loginMutation = gql`
    mutation loginWithCredentials($email: String, $password: String, $rememberMe: Boolean) {
        loginWithCredentials(email: $email, password: $password, rememberMe: $rememberMe) {
            token
            accessGroup
            user {
                id
            }
        }
    }
`

interface MutationProps {
    children?: (
        mutateFn: MutationFn<LoginMutationResponse, LoginMutationVariables>,
        result: MutationResult<LoginMutationResponse>
    ) => React.ReactNode
}

export type LoginMutationFn = MutationFn<LoginMutationResponse, LoginMutationVariables>

export const LoginMutation: React.SFC<MutationProps> = ({ children }) => (
    <Mutation<any> mutation={loginMutation}>
        {(login, state) => <ErrorProvider error={state.error}>{children && children(login, state)}</ErrorProvider>}
    </Mutation>
)
