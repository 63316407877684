import './PageTitle.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '../Icon/Icon'
import { IconType } from '../Icon/IconType'

export interface PageTitleProps {
    className?: ClassValue
    title: string
    icon?: IconType
    largerTitle?: boolean
    subtle?: boolean
    documentTitle?: string
    subTitle?: string
    suffix?: React.ReactNode
    subTitleSuffix?: React.ReactNode
}

interface State {}

export class PageTitle extends React.PureComponent<PageTitleProps, State> {
    private bem = new BEM('PageTitle', () => ({
        'larger-title': this.props.largerTitle,
        subtle: this.props.subtle,
    }))

    public componentDidMount() {
        document.title = this.props.documentTitle ? this.props.documentTitle : this.props.title
    }

    public render() {
        const { suffix, className, subTitle, subTitleSuffix } = this.props

        return (
            <div>
                {!suffix ? (
                    <h1 className={this.bem.getClassName(className)}>{this.renderTitle()}</h1>
                ) : (
                    <h1 className={this.bem.getClassName(className)}>
                        {this.renderTitle()}
                        <span className={this.bem.getElement('suffix')}>{suffix}</span>
                    </h1>
                )}

                {subTitle && (
                    <h2 className={this.bem.getElement('sub-title')}>
                        {subTitle}
                        {subTitleSuffix && (
                            <div className={this.bem.getElement('sub-title-suffix')}>{subTitleSuffix}</div>
                        )}
                    </h2>
                )}
            </div>
        )
    }

    private renderTitle() {
        const { title, icon } = this.props

        return (
            <>
                {icon && <Icon type={icon} className={this.bem.getElement('icon')} />}
                <span className={this.bem.getElement('title-text')}>{title}</span>
            </>
        )
    }
}
