import React from 'react'

import { RouteComponentProps, Switch, Route, Redirect } from 'react-router'
import { PageContainer } from '~/components/Core/Layout/PageContainer'
import { routes } from '~/views/routes'
import { DashboardView } from '../Consultant/Dashboard/DashboardView'
import { AppNotFoundView } from '../AppNotFoundView'
import { AppLayout } from '~/components/Core/Layout/AppLayout'
import { OrganizationView } from './Organization/OrganizationView'
import { CustomersView } from './Customers/CustomersView'
import { errorClient, sessionClient, notification } from '~/bootstrap'
import { ErrorType } from '~/services/ErrorService'
import { apolloClient } from '~/services/ApolloService'
import { SessionManager } from '../SessionManager'
import { ConsultantNavigation } from '~/components/Chrome/Navigation/ConsultantNavigation'
import { CustomerFeedsView } from './CustomerFeeds/CustomerFeedsView'
import { CustomerGroupsView } from './CustomerGroups/CustomerGroupsView'
import { ConsultantTaskTemplatesOverview } from './ConsultantTaskTemplates/ConsultantTaskTemplatesOverview'

interface RouteParams {}

interface Props extends RouteComponentProps<RouteParams> {}

export class ConsultantView extends React.PureComponent<Props> {
    public componentDidMount() {
        errorClient.subscribeToType(ErrorType.authentication, this.handleAuthenticationError)
    }

    public componentWillUnmount() {
        errorClient.unsubscribeToType(ErrorType.authentication, this.handleAuthenticationError)
    }

    public handleAuthenticationError = () => {
        sessionClient.removeSession()
        apolloClient.resetStore().then(() => {
            notification.info('Sessie verlopen')
            this.props.history.push(routes.cover.login)
        })
    }

    public render() {
        return (
            <SessionManager isConsultant>
                <AppLayout isConsultantLayout={true}>
                    <ConsultantNavigation />
                    <PageContainer>
                        <Switch>
                            <Route path={routes.consultant.dashboard.index} component={DashboardView} />
                            <Route path={routes.consultant.customerGroups.index} component={CustomerGroupsView} />
                            <Route path={routes.consultant.customers.index} component={CustomersView} />
                            <Route path={routes.consultant.customerfeeds.index} component={CustomerFeedsView} />
                            <Route path={routes.consultant.organization.index} component={OrganizationView} />
                            <Route
                                path={routes.consultant.taskTemplates.index}
                                component={ConsultantTaskTemplatesOverview}
                            />
                            <Redirect
                                exact={true}
                                path={routes.consultant.index}
                                to={routes.consultant.dashboard.index}
                            />
                            <Route component={AppNotFoundView} />
                        </Switch>
                    </PageContainer>
                </AppLayout>
            </SessionManager>
        )
    }
}
