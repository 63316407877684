import './Input.scss'

import React from 'react'
import { ChangeEventHandler } from 'react'
import { BEM } from '~/services/BEMService'
import { IconType } from '~/components/Core/Icon/IconType'
import { Icon } from '~/components/Core/Icon/Icon'

export interface InputProps<TValue = string> {
    value?: TValue
    className?: string
    placeholder?: string
    type?: string
    defaultValue?: TValue | null
    disabled?: boolean
    onChange?: (value: TValue | null, name: string) => void
    onBlur?: (value: TValue | null, name: string) => void
    onFocus?: () => void
    name: string
    autoComplete?: boolean
    icon?: IconType | null
    suffix?: JSX.Element
    raised?: boolean
    ariaLabel?: string
    required?: boolean
    autoFocus?: boolean
    readonly?: boolean
}

interface State {
    value: string
    offsetPadding: number
}

export class Input extends React.PureComponent<InputProps, State> {
    public state: State = {
        value: this.props.defaultValue ? this.props.defaultValue : this.props.value ? this.props.value : '',
        offsetPadding: 0,
    }

    private bem = new BEM('Input', params => ({
        [`is-type-${this.props.type}`]: !!this.props.type,
        'has-icon': !!this.props.icon,
        'is-raised': !!this.props.raised,
        'is-disabled': !!this.props.disabled,
    }))

    private suffixRef = React.createRef<HTMLDivElement>()

    public componentDidMount() {
        if (this.suffixRef.current) {
            const width = this.suffixRef.current.offsetWidth
            this.setState({ offsetPadding: width + 12 })
        }
    }

    public componentWillReceiveProps(nextProps: InputProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({ value: nextProps.value || '' })
        }
    }

    public render() {
        const {
            type,
            name,
            placeholder,
            autoComplete,
            disabled,
            className,
            icon,
            suffix,
            onFocus,
            ariaLabel,
            required,
            autoFocus,
            readonly,
        } = this.props

        const { value, offsetPadding } = this.state

        if (type === 'textarea') {
            return (
                <div className={this.bem.getClassName(className)}>
                    <textarea
                        id={`input-${name}`}
                        name={name}
                        placeholder={placeholder}
                        onChange={this.handleOnChange}
                        className={this.bem.getElement('input')}
                        value={value}
                        disabled={disabled}
                        autoComplete={autoComplete ? 'on' : 'off'}
                        autoFocus={autoFocus}
                        readOnly={readonly}
                    />
                    {icon && <Icon className={this.bem.getElement('icon')} type={icon} />}
                    {suffix && (
                        <div ref={this.suffixRef} className={this.bem.getElement('suffix')}>
                            {suffix}
                        </div>
                    )}
                </div>
            )
        }

        return (
            <div className={this.bem.getClassName(className)}>
                <input
                    id={`input-${name}`}
                    type={type}
                    name={name}
                    onChange={this.handleOnChange}
                    value={value}
                    className={this.bem.getElement('input')}
                    placeholder={placeholder}
                    disabled={disabled}
                    autoComplete={autoComplete ? 'on' : 'off'}
                    style={{ paddingRight: offsetPadding || undefined }}
                    onBlur={this.handleOnBlur}
                    onFocus={onFocus}
                    required={required}
                    aria-label={ariaLabel}
                    aria-required={required}
                    aria-placeholder={ariaLabel}
                    autoFocus={autoFocus}
                    readOnly={readonly}
                />
                {icon && <Icon className={this.bem.getElement('icon')} type={icon} />}
                {suffix && (
                    <div ref={this.suffixRef} className={this.bem.getElement('suffix')}>
                        {suffix}
                    </div>
                )}
            </div>
        )
    }

    private handleOnChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = ({ currentTarget }) => {
        const value = currentTarget.value
        const { onChange, name, readonly } = this.props

        if (readonly) {
            return
        }

        this.setState({ value }, () => {
            const formattedValue = this.formatValue(value)
            const stringOrNull = formattedValue !== '' ? formattedValue : null

            if (onChange) {
                onChange(stringOrNull, name)
            }
        })
    }

    private handleOnBlur: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = ({ currentTarget }) => {
        const value = currentTarget.value
        const { onBlur, name, readonly } = this.props

        if (readonly) {
            return
        }

        this.setState({ value }, () => {
            const formattedValue = this.formatValue(value)
            const stringOrNull = formattedValue !== '' ? formattedValue : null

            if (onBlur) {
                onBlur(stringOrNull, name)
            }
        })
    }

    private formatValue(value: string) {
        return value && value.trim()
    }
}
