import React from 'react'
import { GroupedTasksQuery, GroupedTasksQueryFilters } from '~/views/Customer/Tasks/GroupedTasks/GroupedTasksQuery'
import { GroupedTasksTable } from '~/views/Customer/Tasks/GroupedTasks/GroupedTasksTable'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { SortDirection } from '~/components/Core/DataDisplay/Table/Table'
import { localize } from '~/bootstrap'

interface Props {
    filters?: Omit<GroupedTasksQueryFilters, 'includingOverdueTasks'>
}

interface State {
    sort?: {
        [field: string]: SortDirection
    }
}

export class OverDueGroupedTasksContainer extends React.Component<Props, State> {
    public state: State = {
        sort: {
            firstDueAt: 'DESC',
        },
    }

    public render() {
        const { filters } = this.props
        const { sort } = this.state

        return (
            <GroupedTasksQuery sort={sort} filters={{ includingOverdueTasks: true, ...filters }}>
                {({ data, loading, loadingMore, fetchMore, canFetchMore, refetch }) => {
                    const groupedTasks = data && data.nodes ? data.nodes : []
                    const amountLeft = Math.min(data ? data.totalCount - groupedTasks.length : 0, 10)

                    return (
                        <>
                            <GroupedTasksTable
                                loading={loading}
                                loadingMore={loadingMore}
                                groupedTasks={groupedTasks}
                                onSortDirectionChange={this.handleOnSortDirectionChange}
                                defaultSortDirection={{ field: 'firstDueAt', direction: 'DESC' }}
                                completedOnly={filters?.completedOnly}
                                refetch={refetch}
                            />
                            {!loadingMore && canFetchMore && (
                                <Row alignCenter={true}>
                                    <Button icon={IconType.arrowDown} type={ButtonType.actionLink} onClick={fetchMore}>
                                        {localize.translate(t => t.Customer.Planning.Tasks.showAmountMoreTasks, {
                                            amount: amountLeft,
                                        })}
                                    </Button>
                                </Row>
                            )}
                        </>
                    )
                }}
            </GroupedTasksQuery>
        )
    }

    private handleOnSortDirectionChange = (field: string, direction: SortDirection) => {
        this.setState({
            sort: {
                [field]: direction,
            },
        })
    }
}
