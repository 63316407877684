import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { MonitoringReportType } from '~/generated/graphql'
import { ArchiveMonitoringReports, ArchiveMonitoringReportsMutationFN } from '../ArchiveMonitoringReports'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'

interface Props {
    monitoringReport: Pick<MonitoringReportType, 'name' | 'id'>
    requestClose: () => void
}

export class ArchiveMonitoringReportModal extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.MonitoringReportsArchiveModal)

    public render() {
        const { requestClose } = this.props

        return (
            <ArchiveMonitoringReports>
                {(mutate, { loading }) => (
                    <Form onSubmit={this.handleSubmit(mutate)}>
                        <Modal
                            requestClose={requestClose}
                            submitForm={true}
                            title={this.loc(t => t.monitoringReportModalTitle)}
                            loading={loading}
                            confirmButtonLabel={localize.translate(t => t.Generic.archive)}
                        >
                            <ErrorMessage path="archiveMonitoringReports" />
                            <Column>{this.renderContent()}</Column>
                        </Modal>
                    </Form>
                )}
            </ArchiveMonitoringReports>
        )
    }

    private renderContent() {
        const { name } = this.props.monitoringReport

        return (
            <Column>
                <Paragraph>
                    {this.loc(t => t.monitoringReportArchiveMessagePart1)}
                    <strong>{name}</strong>
                    {this.loc(t => t.monitoringReportArchiveMessagePart2)}
                </Paragraph>
                <Field label={this.loc(t => t.monitoringReportReasonInputLabel)} compact={true}>
                    <Form.Input
                        type="textarea"
                        placeholder={this.loc(t => t.monitoringReportReasonInputPlaceholder)}
                        name="reason"
                        onChange={archivedReason => this.setState({ archivedReason })}
                    />
                </Field>
            </Column>
        )
    }

    private handleSubmit = (mutate: ArchiveMonitoringReportsMutationFN) => async (formState: FormState) => {
        const response = await mutate({
            variables: {
                ids: [this.props.monitoringReport.id],
                reason: formState.reason,
            },
        })

        if (response && response.data && response.data.archiveMonitoringReports) {
            notification.success(localize.translate(t => t.Generic.successfullyArchived))
            this.props.requestClose()
        }
    }
}
