import React from 'react'
import { Query, QueryResult } from 'react-apollo'
import { TaskDetailFields } from '~/graphql/types/Task'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { GetTaskDocument } from '~/generated/graphql'

export interface GetTaskQueryResponse {
    task?: TaskDetailFields
}

export type LinkedTaskItemStatusQueryResult = QueryResult<GetTaskQueryResponse>

interface Props {
    taskId: number
    children: (result: LinkedTaskItemStatusQueryResult) => JSX.Element
}

export class GetTaskQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public render() {
        const { children, taskId } = this.props

        return (
            <Query<GetTaskQueryResponse>
                query={GetTaskDocument}
                variables={{ taskId, departmentId: this.context.activeDepartmentId }}
            >
                {children}
            </Query>
        )
    }
}
