import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'

const INBOX_COUNT_QUERY = gql`
    query inboxCount($filters: AlertFilters, $customerSlug: String) {
        alerts(take: 1, skip: 0, filters: $filters, customerSlug: $customerSlug) {
            totalCount
        }
    }
`

interface InboxCountQueryResponse {
    alerts?: {
        totalCount: number
    }
}

interface Props {
    children: (count: number) => React.ReactNode
}

export class InboxCountQuery extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <Query<InboxCountQueryResponse>
                query={INBOX_COUNT_QUERY}
                variables={{
                    filters: {
                        hasTasksForDepartment: false,
                        isArchivedFromInboxForDepartment: false,
                        customerFrameworkIds: this.context.activeProfiles,
                        departmentId: this.context.activeDepartmentId,
                    },
                }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return <Spinner />
                    }

                    const count = data && data.alerts ? data.alerts.totalCount : 0
                    return children(count)
                }}
            </Query>
        )
    }
}
