import React from 'react'
import { Query } from 'react-apollo'
import { localize } from '~/bootstrap'
import { ColumnOptions, Table } from '~/components/Core/DataDisplay/Table/Table'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { AttentionIcon } from '~/components/Core/Icon/AttentionIcon/AttentionIcon'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

import { AllRiskType, GetAllRisksQueryResponse, GET_ALL_RISKS } from '~/views/Customer/Risks/HeatmapView'
import { routes } from '~/views/routes'

import { RiskIndicator } from '../Compliance/Risks/RiskIndicator'

interface Props {
    topicId: number
}

export class TopicRisksTable extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Risks)

    public render() {
        const tableHeaders: ColumnOptions[] = [{ field: 'name', headerLabel: this.loc(t => t.riskName) }]

        return (
            <Query<GetAllRisksQueryResponse>
                query={GET_ALL_RISKS}
                variables={{
                    filters: {
                        topicId: this.props.topicId,
                        departmentId: this.context.activeDepartmentId,
                    },
                }}
            >
                {({ loading, data }) => {
                    const risks = data && data.allRisks ? data.allRisks : []

                    return (
                        <Column>
                            <SectionTitle>{this.loc(t => t.title)}</SectionTitle>
                            <Table loading={loading} columns={tableHeaders} data={this.getTableData(risks)} />
                        </Column>
                    )
                }}
            </Query>
        )
    }

    private getTableData(risks: AllRiskType[]) {
        return risks.map(risk => {
            return {
                id: risk.id,
                columns: {
                    name: (
                        <Row smallSpacing={true}>
                            <RiskIndicator larger={true} severity={risk.severity} />
                            {risk.status && (
                                <AttentionIcon
                                    tooltipContent={localize.translate(
                                        t => t.Customer.Compliance.Risks.status[risk.status]
                                    )}
                                />
                            )}
                            <TableLink
                                to={routes
                                    .customer(this.context.customer.slug)
                                    .risks.assessments.view(risk.assessment?.id)}
                            >
                                {risk.name}
                            </TableLink>
                        </Row>
                    ),
                },
            }
        })
    }
}
