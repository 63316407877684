import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { ArchiveCustomerNewsDocument } from '~/generated/graphql'

interface ArchiveCustomerNewsMutationResponse {
    archiveCustomerNews: boolean
}

interface ArchiveCustomerNewsVariables {
    newsIds: number[]
    archivedReason: string | null
}

export type ArchiveNewsItemsMutationFN = MutationFn<ArchiveCustomerNewsMutationResponse, ArchiveCustomerNewsVariables>

interface Props {
    children: (
        mutation: MutationFn<ArchiveCustomerNewsMutationResponse, ArchiveCustomerNewsVariables>,
        options: MutationResult
    ) => JSX.Element
}

export const ArchiveNewsItemsMutation: React.SFC<Props> = ({ children }) => (
    <GQLMutation<ArchiveCustomerNewsMutationResponse, ArchiveCustomerNewsVariables>
        mutation={ArchiveCustomerNewsDocument}
    >
        {(mutate, options) => children(mutate, options)}
    </GQLMutation>
)
