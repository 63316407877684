import React from 'react'

import { RouteComponentProps, Redirect } from 'react-router'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { Center } from '~/components/Core/Layout/Center'
import { routes } from '../routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props extends RouteComponentProps<{ id?: string }> {}
interface State {}

const LAW_ARTICLE_QUERY = gql`
    query lawArticle($id: Int!) {
        lawArticle(id: $id) {
            id
            abstractLawArticleId
            law {
                id
                abstractLawId
            }
        }
    }
`

interface LawArticleQueryResponse {
    lawArticle?: {
        id: number
        abstractLawArticleId: number
        law: {
            id: number
            abstractLawId: number
        } | null
    }
}

interface LawArticleQueryVariables {
    id: number
}

export class LawArticleRedirectView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { id } = this.props.match.params

        if (!id) {
            return null
        }

        return (
            <Query<LawArticleQueryResponse, LawArticleQueryVariables>
                query={LAW_ARTICLE_QUERY}
                variables={{ id: parseInt(id, 10) }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return (
                            <Center>
                                <Spinner delayed={true} />
                            </Center>
                        )
                    }

                    if (!data || !data.lawArticle) {
                        return <NoResults />
                    }

                    const article = data.lawArticle
                    const customerSlug = this.context.customer.slug

                    if (article.law) {
                        return (
                            <Redirect
                                to={routes
                                    .customer(customerSlug)
                                    .lawAndRegulation.detail.article(
                                        article.law.abstractLawId,
                                        article.abstractLawArticleId
                                    )}
                            />
                        )
                    }

                    return (
                        <Redirect
                            to={routes.customer(customerSlug).lawAndRegulation.detail.singleArticle(data.lawArticle.id)}
                        />
                    )
                }}
            </Query>
        )
    }
}
