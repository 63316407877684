import './CustomerDetailEditView.scss'

import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Row } from '~/components/Core/Layout/Row'
import { Column } from '~/components/Core/Layout/Column'
import { RouteComponentProps } from 'react-router'
import { routes } from '~/views/routes'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { PageTitle } from '~/components/Core/Text/PageTitle'
import { CustomerFormFields } from '~/components/Domain/Consultants/Customer/CustomerFormFields'
import { BEM } from '~/services/BEMService'
import { Page } from '~/components/Core/Layout/Page'
import { CustomerDocument, CustomerQueryVariables, CustomerType, CustomerQuery } from '~/generated/graphql'
import { EditCustomer, EditCustomerMutationFN } from '~/components/Domain/Consultants/Customer/mutations/EditCustomer'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

export class CustomerDetailEditView extends React.PureComponent<Props> {
    private bem = new BEM('CustomerDetailEditView')
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerDetailEditView)
    private genericLoc = localize.namespaceTranslate(t => t.Generic)

    public render() {
        const { id } = this.props.match.params

        if (!id) {
            return null
        }

        return (
            <Page className={this.bem.getClassName()}>
                <PageTitle title={this.loc(t => t.title)} />
                <PageQuery<CustomerQuery['customer'], CustomerQueryVariables>
                    query={CustomerDocument}
                    variables={{ id: parseInt(id, 10) }}
                >
                    {customer => {
                        if (!customer) {
                            return null
                        }

                        return (
                            <EditCustomer>
                                {(mutate, { loading }) => this.renderForm(customer, mutate, loading)}
                            </EditCustomer>
                        )
                    }}
                </PageQuery>
            </Page>
        )
    }

    private renderForm(customer: CustomerQuery['customer'], mutate: EditCustomerMutationFN, loading: boolean) {
        const { id } = this.props.match.params

        return (
            <Form onSubmit={this.handleOnSubmit(mutate)}>
                <ErrorMessage path={'editCustomer'} />
                <Column className={this.bem.getElement('form-container')}>
                    <CustomerFormFields customer={customer as CustomerType} />
                    <Row alignRight={true}>
                        <Button
                            disabled={loading}
                            to={routes.consultant.customers.profile(id)}
                            type={ButtonType.secondary}
                        >
                            {this.genericLoc(t => t.cancel)}
                        </Button>
                        <Button loading={loading} submit={true}>
                            {this.genericLoc(t => t.save)}
                        </Button>
                    </Row>
                </Column>
            </Form>
        )
    }

    private handleOnSubmit = (mutate: EditCustomerMutationFN) => async (formState: FormState) => {
        const { id } = this.props.match.params

        const customerGroupId = formState.customerGroupId ? formState.customerGroupId.value : undefined
        const consultantContactId = formState.consultantContactId ? formState.consultantContactId.value : undefined
        const ssoLoginEnabled = customerGroupId ? false : formState.ssoLoginEnabled
        const createUserOnSSOLogin = customerGroupId ? false : formState.createUserOnSSOLogin

        const fields = {
            ...formState,
            consultantContactId,
            customerGroupId,
            ssoLoginEnabled,
            createUserOnSSOLogin,
        }

        const response = await mutate({
            variables: {
                customerId: parseInt(id!, 10),
                fields,
            },
        })

        if (response && response.data && response.data.editCustomer) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            this.props.history.push(routes.consultant.customers.view(id))
        }
    }
}
