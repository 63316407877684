import React from 'react'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { LinkedControls } from '../../LinkedCompliance/LinkedControls'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize } from '~/bootstrap'
import { TransformedControl } from './DeleteRiskModalContainer'

interface Props {
    onConfirm: (closemodal: () => void) => void
    name: string
    controls: TransformedControl[]
    loading: boolean
}

export const DeleteRiskModal: React.SFC<Props> = props => {
    const { onConfirm, loading } = props

    return (
        <ConfirmModal
            onConfirm={onConfirm}
            loading={loading}
            renderContent={() => <LinkedControls name={props.name} controls={props.controls} />}
        >
            {openModal => (
                <Button type={ButtonType.secondary} danger={true} onClick={openModal}>
                    {localize.translate(t => t.Generic.delete)}
                </Button>
            )}
        </ConfirmModal>
    )
}
