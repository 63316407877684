import './CustomerDomainItemListLayout.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Center } from '~/components/Core/Layout/Center'

interface Props {
    className?: ClassValue
    loading?: boolean
}

interface State { }

export class CustomerDomainItemListLayout extends React.PureComponent<Props, State> {
    private bem = new BEM('CustomerDomainItemListLayout')

    public render() {
        const { children, className, loading } = this.props

        return (
            <nav className={this.bem.getElement('nav')}>
                {loading && <Center> <Spinner delayed={true} /></Center>}
                <ol className={this.bem.getClassName(className)}>
                    {children}
                </ol>
            </nav>
        )
    }
}
