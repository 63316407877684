import React from 'react'
import { LocalStorageService, LocalStorageKeys } from '~/services/LocalStorageService'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { permissions } from '~/bootstrap'
import { apolloClient } from '~/services/ApolloService'
import { Center } from '../Core/Layout/Center'
import { Redirect } from 'react-router'
import { routes } from '~/views/routes'
import { UserContext, UserContextValue } from '~/components/Providers/UserProvider'
import { Employee, Consultant } from '~/graphql/types/User'
import { CustomerFrameworkIconEnumType, CustomerFrameworkColorEnumType, DepartmentType } from '~/generated/graphql'

interface Customer {
    id: number
    name: string
    logo: string
    slug: string
    availableSeats: number
    filledSeats: number
    ssoLoginEnabled: boolean
    flowAIChat: boolean
    editorialAccess: boolean
    departments: {
        id: number
        name: string
    }[]
    customerGroup?: {
        id: number
        name: string
        ssoLoginEnabled: boolean
    }
    isConsultantTaskTemplatesHidden: boolean
}

interface CustomerFramework {
    id: number
    icon: CustomerFrameworkIconEnumType
    color: CustomerFrameworkColorEnumType
    isActive: boolean
    name: string
    framework: {
        id: number
        name: string
    }
}

export interface CustomerDepartment {
    id: number
    name: string
}
interface State {
    inactive: number[]
    activeDepartment?: number
}

interface Props {
    customerSlug: string
}

export const CUSTOMER_PROVIDER_CUSTOMER_QUERY = gql`
    query customer($customerSlug: String) {
        customer(customerSlug: $customerSlug) {
            id
            name
            logo
            slug
            flowAIChat
            availableSeats
            filledSeats
            ssoLoginEnabled
            editorialAccess
            departments {
                id
                name
            }
            customerGroup {
                id
                name
                ssoLoginEnabled
            }
            isConsultantTaskTemplatesHidden
        }
    }
`

export const CUSTOMER_PROVIDER_CUSTOMER_FRAMEWORKS_QUERY = gql`
    query customerFrameworks($customerSlug: String, $departmentId: Int) {
        customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
            id
            name
            icon
            color
            framework {
                id
                name
            }
        }
    }
`

interface CustomerQueryResponse {
    customer?: Customer
}

interface CustomerFrameworksQueryResponse {
    customerFrameworks?: CustomerFramework[]
}

export interface CustomerContextValue {
    customer: Customer
    employee?: Employee
    consultant?: Consultant
    customerDepartments: Pick<DepartmentType, 'id' | 'name'>[]
    profiles: CustomerFramework[]
    activeDepartment: CustomerDepartment
    activeDepartmentId: number
    departments: CustomerDepartment[]
    toggleProfile: (id: number) => void
    toggleDepartment: (id: number) => void
    activeProfiles: number[]
    refetch: Function
    refetchUser: Function
    hasCustomers: boolean
    isConsultantTaskTemplatesHidden: boolean
}

export const CustomerContext = React.createContext<CustomerContextValue | undefined>(undefined)

export const CustomerConsumer = CustomerContext.Consumer

export class CustomerProvider extends React.Component<Props, State> {
    public static contextType = UserContext
    public context: UserContextValue

    public state: State = this.getInitialState()

    private markedAsRead: boolean = false

    /** We use initial loading for  */
    private initialLoading: boolean = true

    public render() {
        const { children, customerSlug } = this.props
        const { activeDepartment } = this.state
        const departmentsForEmployee = this.context.employee ? this.context.employee.departments : null
        const hasCustomers = this.context.userEmployees?.length ? this.context.userEmployees.length > 1 : false

        return (
            <Query<CustomerQueryResponse> query={CUSTOMER_PROVIDER_CUSTOMER_QUERY} variables={{ customerSlug }}>
                {({ data, loading, refetch }) => {
                    const customer = data && data.customer ? data.customer : null

                    if (this.initialLoading === true && loading) {
                        return (
                            <Center>
                                <Spinner />
                            </Center>
                        )
                    }

                    if (!customer) {
                        return <Redirect to={routes.cover.login} />
                    }

                    this.markLastLoginForConsultant(customer)

                    const getActiveDepartment = () => {
                        if (activeDepartment) {
                            const existsInEmployee = departmentsForEmployee
                                ? departmentsForEmployee.find(({ id }) => id === activeDepartment)
                                : null
                            if (existsInEmployee) {
                                return existsInEmployee
                            }

                            const existsInCustomer = customer.departments.find(({ id }) => id === activeDepartment)

                            if (existsInCustomer) {
                                return existsInCustomer
                            }
                        }

                        if (departmentsForEmployee) {
                            return departmentsForEmployee[0]
                        }

                        return customer.departments[0]
                    }

                    const department = getActiveDepartment()

                    return (
                        <Query<CustomerFrameworksQueryResponse>
                            query={CUSTOMER_PROVIDER_CUSTOMER_FRAMEWORKS_QUERY}
                            variables={{ customerSlug, departmentId: department ? department.id : undefined }}
                        >
                            {({ data, loading }) => {
                                if (this.initialLoading === true && loading) {
                                    return (
                                        <Center>
                                            <Spinner />
                                        </Center>
                                    )
                                }

                                const customerFrameworks =
                                    data && data.customerFrameworks ? data.customerFrameworks : []

                                const profiles = this.getProfiles(customerFrameworks)

                                const departments = departmentsForEmployee
                                    ? departmentsForEmployee
                                    : customer.departments

                                this.initialLoading = false

                                return (
                                    <CustomerContext.Provider
                                        value={
                                            {
                                                refetch,
                                                refetchUser: this.context.refetch,
                                                customer,
                                                employee: this.context.employee,
                                                consultant: this.context.consultant,
                                                profiles,
                                                departments,
                                                customerDepartments: customer.departments,
                                                activeDepartmentId: department ? department.id : undefined,
                                                activeDepartment: department,
                                                toggleProfile: this.toggleProfile(profiles),
                                                toggleDepartment: this.toggleDepartment,
                                                activeProfiles: this.getActiveProfiles(customerFrameworks),
                                                hasCustomers,
                                                isConsultantTaskTemplatesHidden:
                                                    customer.isConsultantTaskTemplatesHidden,
                                            } as any
                                        }
                                    >
                                        {children}
                                    </CustomerContext.Provider>
                                )
                            }}
                        </Query>
                    )
                }}
            </Query>
        )
    }

    private getInitialState(): State {
        let inactive: number[] = []
        try {
            inactive = JSON.parse(LocalStorageService.getItem(LocalStorageKeys.CustomerFrameworkFilter) || '[]')
        } catch (e) {}

        const cachedDepartment = LocalStorageService.getItem(LocalStorageKeys.DepartmentFilter)

        let activeDepartment: number | undefined = undefined
        try {
            activeDepartment = cachedDepartment ? parseInt(cachedDepartment, 10) : undefined
        } catch (e) {}

        return {
            inactive,
            activeDepartment,
        }
    }

    private async markLastLoginForConsultant(customer: Customer) {
        if (this.markedAsRead) {
            return
        }

        this.markedAsRead = true

        if (!permissions.isConsultantUser()) {
            return
        }

        try {
            await apolloClient.mutate({
                mutation: gql`
                    mutation markLoginToCustomer($customerSlug: String!) {
                        markLoginToCustomer(customerSlug: $customerSlug)
                    }
                `,
                variables: {
                    customerSlug: customer.slug,
                },
            })
        } finally {
            // noop
        }
    }

    private getActiveProfiles(profiles: CustomerFramework[]) {
        const { inactive } = this.state
        const filteredOutInvalidCachedIds = inactive.filter(id => profiles.find(profile => profile.id === id))
        return profiles.map(({ id }) => id).filter(id => !filteredOutInvalidCachedIds.includes(id))
    }

    private getProfiles(profiles: CustomerFramework[]) {
        const activeProfiles = this.getActiveProfiles(profiles)
        return profiles.map(profile => {
            return {
                ...profile,
                isActive: activeProfiles.includes(profile.id),
            }
        })
    }

    private toggleProfile = (profiles: CustomerFramework[]) => (idToToggle: number) => {
        const { inactive } = this.state

        if (inactive.includes(idToToggle)) {
            this.setState(
                {
                    inactive: inactive.filter(id => id !== idToToggle),
                },
                () => {
                    this.saveToggledFiltersToLocalStorage()
                }
            )
            return
        }

        const relevantIds = inactive.filter(id => profiles.find(profile => profile.id === id))

        if (relevantIds.length === profiles.length - 1) {
            return
        }

        this.setState(
            {
                inactive: [...relevantIds, idToToggle],
            },
            () => {
                this.saveToggledFiltersToLocalStorage()
            }
        )
    }

    private saveToggledFiltersToLocalStorage() {
        LocalStorageService.setItem(LocalStorageKeys.CustomerFrameworkFilter, JSON.stringify(this.state.inactive))
    }

    private toggleDepartment = (id: number) => {
        this.setState({ activeDepartment: id })
        LocalStorageService.setItem(LocalStorageKeys.DepartmentFilter, `${id}`)
    }
}
