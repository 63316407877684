import React from 'react'
import gql from 'graphql-tag'
import { RouteComponentProps } from 'react-router'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { ArticleRenderer } from '~/components/Domain/LawAndRegulation/ArticleRenderer'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Markdown } from '~/components/Core/Text/Markdown'
import { ArticleLinkedTopicList } from '~/components/Domain/LawAndRegulation/ArticleLinkedTopicList'

const GET_LAW_ARTICLE_QUERY = gql`
    query lawArticle($articleId: Int!) {
        lawArticle(id: $articleId) {
            id
            abstractLawArticleId
            hasStructuredContent
            content
            title
            type
            law {
                id
                lawSource {
                    id
                    regulationId
                    metaData
                }
            }
        }
    }
`

interface LawArticle {
    id: number
    abstractLawArticleId: number
    hasStructuredContent: boolean
    content: string
    title: string
    type: string
    law: {
        id: number
        lawSource: {
            id: number
            regulationId: string
            metaData: {
                label: string | null
            } | null
        } | null
    }
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {}

interface RouteParams {
    articleId?: string
    abstractId?: string
}

export class LawAndRegulationArticleView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const articleId = parseInt(this.props.match.params.articleId!, 10)

        return (
            <PageQuery<LawArticle>
                query={GET_LAW_ARTICLE_QUERY}
                variables={{
                    articleId,
                }}
            >
                {article => {
                    const useRenderer = article.hasStructuredContent
                    const regulationId = article.law.lawSource ? article.law.lawSource.regulationId : undefined
                    const lawLabel =
                        article.law.lawSource && article.law.lawSource.metaData
                            ? article.law.lawSource.metaData.label || undefined
                            : undefined

                    return (
                        <>
                            <ComponentTitle title={article.title} />
                            <ArticleLinkedTopicList
                                articleId={article.id}
                                abstractLawArticleId={article.abstractLawArticleId}
                            />
                            {useRenderer ? (
                                <ArticleRenderer
                                    content={article.content}
                                    regulationId={regulationId}
                                    lawLabel={lawLabel}
                                    redirectLinksTo={(link: string) =>
                                        routes.customer(this.context.customer.slug).lawAndRegulation.redirect(link)
                                    }
                                />
                            ) : (
                                <Markdown source={article.content} />
                            )}
                        </>
                    )
                }}
            </PageQuery>
        )
    }
}
