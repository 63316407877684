import './VariableMatrixLines.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { animations } from '~/animations'

interface Props {
    className?: ClassValue
    lines: VariableMatrixLine[]
    svgWidth: number
    activeSourceItemIds?: (number | string)[]
    activeTargetItemIds?: (number | string)[]
    itemMargin: number
}

interface State {}

export interface VariableMatrixLine {
    // sourceColumnName: string
    // targetColumnName: string
    sourceItemId: number | string
    targetItemId: number | string
    sourceItemIndex: number
    targetItemIndex: number
    sourceItemOffsetTop: number
    targetItemOffsetTop: number
    sourceHeight: number
    targetHeight: number
}

export class VariableMatrixLines extends React.Component<Props, State> {
    private bem = new BEM('VariableMatrixLines')

    public render() {
        const { className, svgWidth } = this.props

        return (
            <svg
                style={{
                    width: svgWidth,
                    height: this.calculateSvgHeight(),
                }}
                className={this.bem.getClassName(className)}
            >
                <TransitionGroup component={null}>{this.renderLinks()}</TransitionGroup>
            </svg>
        )
    }

    private getYPosition(itemYPosition: number, itemHeight: number) {
        const lineMargin = itemHeight < 40 ? itemHeight / 2 : 20

        return itemYPosition + lineMargin
    }

    private renderLinks() {
        const { lines } = this.props

        if (!lines.length) {
            return null
        }

        return lines.map(line => {
            const { activeSourceItemIds, activeTargetItemIds, svgWidth } = this.props

            // Skip line if it's not active (if there are active items being passed, otherwise all items are active)
            if (activeSourceItemIds && !activeSourceItemIds.includes(line.sourceItemId)) {
                return null
            }
            if (activeTargetItemIds && !activeTargetItemIds.includes(line.targetItemId)) {
                return null
            }

            const sourceYPosition = this.getYPosition(line.sourceItemOffsetTop, line.sourceHeight)
            const targetYPosition = this.getYPosition(line.targetItemOffsetTop, line.targetHeight)

            return (
                <CSSTransition
                    key={`${line.sourceItemId}-${line.targetItemId}-animation-wrapper`}
                    classNames={animations.fadeIn}
                    timeout={225}
                    in={true}
                >
                    <g
                        id={`${line.sourceItemId}-${line.targetItemId}-line`}
                        key={`${line.sourceItemId}-${line.targetItemId}-line`}
                    >
                        <path
                            className={this.bem.getElement('line')}
                            // M stands for move to, H is a horizontal move to starting from the last x, V is a vertical move to starting from the last y
                            d={`M 0,${sourceYPosition} H ${svgWidth / 2} V ${targetYPosition} H ${svgWidth}`}
                        />
                        <path
                            className={this.bem.getElement('line', () => ({ isDashed: true }))}
                            d={`M 0,${sourceYPosition} H ${svgWidth / 2} V ${targetYPosition} H ${svgWidth}`}
                        />
                    </g>
                </CSSTransition>
            )
        })
    }

    private calculateSvgHeight() {
        const { lines } = this.props

        if (!lines.length) {
            return 0
        }

        const sourceHeights = lines.map(line => line.sourceItemOffsetTop + line.sourceHeight)
        const targetHeights = lines.map(line => line.targetItemOffsetTop + line.targetHeight)

        const totalSvgHeight = Math.max(...sourceHeights, ...targetHeights)

        return totalSvgHeight
    }
}
