// tslint:disable
import React from 'react'
import './StyleguideView.scss'

import { BEM } from '~/services/BEMService'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { Page } from '~/components/Core/Layout/Page'
import { RouteComponentProps } from 'react-router'
import { Section } from '~/components/Core/Layout/Section'
import { IconType } from '~/components/Core/Icon/IconType'
import { ColorSchemeGrid } from '../Kitchensink/ColorScheme/ColorSchemeGrid'
import { ColorSchemeGroup } from '../Kitchensink/ColorScheme/ColorSchemeGroup'
import { ColorSchemeCard } from '../Kitchensink/ColorScheme/ColorSchemeCard'
import { StyleguideComponentTable } from './StyleguideComponentTable'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { Column } from '~/components/Core/Layout/Column'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import { AssessmentLabel } from '~/components/Domain/Compliance/Assessments/AssessmentLabel'
import { ControlLabel } from '~/components/Domain/Compliance/Controls/ControlLabel'
import { RiskLabel } from '~/components/Domain/Compliance/Risks/RiskLabel'
import { MetaCard } from '~/components/Core/DataDisplay/MetaCard/MetaCard'
import { SeverityLevel } from '~/components/Domain/Compliance/Risks/RiskIndicator'
import { TopicLabel } from '~/components/Domain/Compliance/Norms/TopicLabel'
import { InboxLabel } from '~/components/Domain/Inbox/InboxLabel'
import { CappedProfileList } from '~/components/Core/DataDisplay/CappedUserList/CappedProfileList'
import { UserProfileList } from '~/components/Core/DataDisplay/UserProfileList/UserProfileList'
import { UserProfilePickerList } from '~/components/Core/DataEntry/UserProfilePicker/UserProfilePickerList/UserProfilePickerList'
import { MonitoringReportLabel } from '~/components/Domain/Monitoring/MonitoringReportLabel'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { ViewTaskModalContainer } from '~/components/Domain/Task/ViewTaskModalContainer'
import { ControlTypeType } from '~/generated/graphql'

interface RouteParams {}

interface Props extends RouteComponentProps<RouteParams> {}

export class StyleguideView extends React.PureComponent<Props> {
    private bem = new BEM('StyleguideView')

    public render() {
        return (
            <Page className={this.bem.getClassName()}>
                <PageHeader title={'Styleguide'} />
                <Section title={'Swatches'}>
                    <ComponentTitle title="Main colors" />
                    <ColorSchemeGrid>
                        <ColorSchemeGroup>
                            <ColorSchemeCard
                                colorHex={'#1d55ff'}
                                colorName={'Ruler Blue'}
                                colorVariable={'$color-ruler-blue'}
                            />
                            <ColorSchemeCard
                                colorHex={'#003ae9'}
                                colorName={'Ruler Blue Darken 10'}
                                colorVariable={'$color-ruler-blue-darken-10'}
                            />
                            <ColorSchemeCard
                                colorHex={'#0033d0'}
                                colorName={'Ruler Blue Darken 15'}
                                colorVariable={'$color-ruler-blue-darken-15'}
                            />
                        </ColorSchemeGroup>

                        <ColorSchemeGroup>
                            <ColorSchemeCard
                                colorHex={'#f3f6ff'}
                                colorName={'Ruler Pale'}
                                colorVariable={'$color-ruler-pale'}
                                invertTextColor={true}
                            />
                            <ColorSchemeCard
                                colorHex={'#e6ecff'}
                                colorName={'Ruler Pale Dark'}
                                colorVariable={'$color-ruler-pale-dark'}
                                invertTextColor={true}
                            />
                        </ColorSchemeGroup>
                    </ColorSchemeGrid>

                    <ColorSchemeGrid>
                        <ColorSchemeGroup>
                            <ColorSchemeCard
                                colorHex={'#fff'}
                                colorName={'White'}
                                colorVariable={'$color-white'}
                                invertTextColor={true}
                            />
                            <ColorSchemeCard
                                colorHex={'#f7f7fa'}
                                colorName={'Grey 10'}
                                colorVariable={'$color-grey-10'}
                                invertTextColor={true}
                            />
                            <ColorSchemeCard
                                colorHex={'#ededf2'}
                                colorName={'Grey 20'}
                                colorVariable={'$color-grey-20'}
                                invertTextColor={true}
                            />
                            <ColorSchemeCard
                                colorHex={'#e0e1e4'}
                                colorName={'Grey 40'}
                                colorVariable={'$color-grey-40'}
                                invertTextColor={true}
                            />
                            <ColorSchemeCard
                                colorHex={'#bfbfc2'}
                                colorName={'Grey 65'}
                                colorVariable={'$color-grey-65'}
                                invertTextColor={true}
                            />
                            <ColorSchemeCard
                                colorHex={'#87878a'}
                                colorName={'Grey 80'}
                                colorVariable={'$color-grey-80'}
                            />
                            <ColorSchemeCard
                                colorHex={'#3d3d40'}
                                colorName={'Grey 95'}
                                colorVariable={'$color-grey-95'}
                            />
                        </ColorSchemeGroup>

                        <ColorSchemeGroup>
                            <ColorSchemeCard
                                colorHex={'#e4003a'}
                                colorName={'UI Red'}
                                colorVariable={'$color-ui-red'}
                            />
                            <ColorSchemeCard
                                colorHex={'#ff9100'}
                                colorName={'UI Orange'}
                                colorVariable={'$color-ui-orange'}
                            />
                            <ColorSchemeCard
                                colorHex={'#00e981'}
                                colorName={'UI Green'}
                                colorVariable={'$color-ui-green'}
                                invertTextColor={true}
                            />
                        </ColorSchemeGroup>

                        <ColorSchemeGroup>
                            <ColorSchemeCard
                                colorHex={'#991b82'}
                                colorName={'Profile Purple'}
                                colorVariable={'$color-profile-purple'}
                            />
                            <ColorSchemeCard
                                colorHex={'#1f2769'}
                                colorName={'Profile Dark Blue'}
                                colorVariable={'$color-profile-dark-blue'}
                            />
                            <ColorSchemeCard
                                colorHex={'#0f8ab2'}
                                colorName={'Profile Blue'}
                                colorVariable={'$color-profile-blue'}
                            />
                            <ColorSchemeCard
                                colorHex={'#62e7f7'}
                                colorName={'Profile Cyan'}
                                colorVariable={'$color-profile-cyan'}
                            />
                            <ColorSchemeCard
                                colorHex={'#5dd39e'}
                                colorName={'Profile Green'}
                                colorVariable={'$color-profile-green'}
                            />
                            <ColorSchemeCard
                                colorHex={'#ffd46f'}
                                colorName={'Profile Yellow'}
                                colorVariable={'$color-profile-yellow'}
                            />
                            <ColorSchemeCard
                                colorHex={'#f89c3d'}
                                colorName={'Profile Orange'}
                                colorVariable={'$color-profile-orange'}
                            />
                            <ColorSchemeCard
                                colorHex={'#f95858'}
                                colorName={'Profile Red'}
                                colorVariable={'$color-profile-red'}
                            />
                            <ColorSchemeCard
                                colorHex={'#f67695'}
                                colorName={'Profile Pink'}
                                colorVariable={'$color-profile-pink'}
                            />
                            <ColorSchemeCard
                                colorHex={'#000000'}
                                colorName={'Profile Black'}
                                colorVariable={'$color-profile-black'}
                            />
                            <ColorSchemeCard
                                colorHex={'#d8d8d8'}
                                colorName={'Profile Grey'}
                                colorVariable={'$color-profile-grey'}
                                invertTextColor={true}
                            />
                        </ColorSchemeGroup>
                    </ColorSchemeGrid>
                </Section>
                <Section title={'Buttons'}>
                    <StyleguideComponentTable
                        headers={[
                            { field: 'defaultState', label: 'Default' },
                            { field: 'disabledState', label: 'Disabled' },
                            { field: 'loadingState', label: 'Loading' },
                        ]}
                        data={sampleTableData}
                    />
                </Section>
                <Section title={'Inputs'}>
                    <StyleguideComponentTable
                        headers={[
                            { field: 'defaultState', label: 'Default' },
                            { field: 'disabledState', label: 'Disabled' },
                            { field: 'loadingState', label: 'Loading' },
                            { field: 'errorState', label: 'Error' },
                        ]}
                        data={inputsTableData}
                    />
                </Section>

                <Section title={'Task user icon'}>
                    <StyleguideComponentTable
                        headers={[
                            { field: 'defaultState', label: 'Default' },
                            { field: 'cappedList', label: 'Capped List' },
                            { field: 'List', label: 'List' },
                            { field: 'UserPicker', label: 'UserPicker' },
                        ]}
                        data={taskUserData}
                    />
                </Section>

                <Section title={'User Picker'}>
                    <StyleguideComponentTable
                        headers={[{ field: 'default', label: 'User Picker' }]}
                        data={userPickerTableData}
                    />
                </Section>

                <Section title={'Compliance labels'}>
                    <StyleguideComponentTable
                        headers={[{ field: 'default', label: 'Default' }]}
                        data={complianceLabelData}
                    />
                </Section>

                <Section title={'Linked Task Card'}>
                    <StyleguideComponentTable
                        headers={[{ field: 'default', label: 'Default' }]}
                        data={linkedTaskCardData}
                    />
                </Section>

                <Section title={'Linked Task Card'}>
                    <ModalManager
                        render={openModal => <Button onClick={openModal}>{'Taak bekijken'}</Button>}
                        renderModal={closeModal => (
                            <ViewTaskModalContainer
                                taskId={1}
                                requestClose={closeModal}
                                onCompleteOrReopen={() => {}}
                                onChangeTask={() => {}}
                            />
                        )}
                    />
                </Section>
            </Page>
        )
    }
}

const sampleTableData = [
    {
        id: 1,
        label: 'Primary',
        rows: {
            defaultState: <Button>Primary</Button>,
            disabledState: <Button disabled={true}>Primary</Button>,
            loadingState: <Button loading={true}>Primary</Button>,
        },
    },
    {
        id: 2,
        label: 'Secondary',
        rows: {
            defaultState: <Button type={ButtonType.secondary}>Secondary</Button>,
            disabledState: (
                <Button type={ButtonType.secondary} disabled={true}>
                    Secondary
                </Button>
            ),
            loadingState: (
                <Button type={ButtonType.secondary} loading={true}>
                    Secondary
                </Button>
            ),
        },
    },
    {
        id: 3,
        label: 'Tertiary',
        rows: {
            defaultState: <Button type={ButtonType.tertiary}>Tertiary</Button>,
            disabledState: (
                <Button type={ButtonType.tertiary} disabled={true}>
                    Tertiary
                </Button>
            ),
            loadingState: (
                <Button type={ButtonType.tertiary} loading={true}>
                    Tertiary
                </Button>
            ),
        },
    },
    {
        id: 4,
        label: 'Primary (rounded + icon)',
        rows: {
            defaultState: (
                <Button rounded={true} icon={IconType.add}>
                    Primary
                </Button>
            ),
            disabledState: (
                <Button rounded={true} disabled={true} icon={IconType.add}>
                    Primary
                </Button>
            ),
            loadingState: (
                <Button rounded={true} loading={true} icon={IconType.add}>
                    Primary
                </Button>
            ),
        },
    },
    {
        id: 5,
        label: 'Secondary (rounded + icon)',
        rows: {
            defaultState: (
                <Button type={ButtonType.secondary} rounded={true} icon={IconType.assessment}>
                    Secondary
                </Button>
            ),
            disabledState: (
                <Button type={ButtonType.secondary} rounded={true} icon={IconType.assessment} disabled={true}>
                    Secondary
                </Button>
            ),
            loadingState: (
                <Button type={ButtonType.secondary} rounded={true} icon={IconType.assessment} loading={true}>
                    Secondary
                </Button>
            ),
        },
    },
    {
        id: 6,
        label: 'Action link',
        rows: {
            defaultState: (
                <Button type={ButtonType.actionLink} icon={IconType.addCircle}>
                    Action link
                </Button>
            ),
            disabledState: (
                <Button type={ButtonType.actionLink} icon={IconType.addCircle} disabled={true}>
                    Action link
                </Button>
            ),
            loadingState: (
                <Button type={ButtonType.actionLink} icon={IconType.addCircle} loading={true}>
                    Action link
                </Button>
            ),
        },
    },
    {
        id: 7,
        label: 'Primary (danger) NEW',
        rows: {
            defaultState: <Button danger={true}>Primary</Button>,
            disabledState: (
                <Button danger={true} disabled={true}>
                    Primary
                </Button>
            ),
            loadingState: (
                <Button danger={true} loading={true}>
                    Primary
                </Button>
            ),
        },
    },
    {
        id: 8,
        label: 'Secondary (danger) NEW',
        rows: {
            defaultState: (
                <Button danger={true} type={ButtonType.secondary}>
                    Secondary
                </Button>
            ),
            disabledState: (
                <Button danger={true} type={ButtonType.secondary} disabled={true}>
                    Secondary
                </Button>
            ),
            loadingState: (
                <Button danger={true} type={ButtonType.secondary} loading={true}>
                    Secondary
                </Button>
            ),
        },
    },
    {
        id: 9,
        label: 'No styling',
        rows: {
            defaultState: <Button type={ButtonType.noStyling}>No styling</Button>,
            disabledState: (
                <Button type={ButtonType.noStyling} disabled={true}>
                    No styling
                </Button>
            ),
            loadingState: (
                <Button type={ButtonType.noStyling} loading={true}>
                    No styling
                </Button>
            ),
        },
    },
]

const inputsTableData = [
    {
        id: 1,
        label: 'Input',
        rows: {
            defaultState: (
                <Column>
                    <Input type={'text'} name={'input'} />
                    <Input type={'text'} name={'input'} defaultValue={'Some text'} />
                </Column>
            ),
            disabledState: (
                <Column>
                    <Input type={'text'} name={'input'} disabled={true} />
                    <Input type={'text'} name={'input'} defaultValue={'Some text'} disabled={true} />
                </Column>
            ),
            loadingState: (
                <Column>
                    <Input type={'text'} name={'input'} />
                    <Input type={'text'} name={'input'} defaultValue={'Some text'} />
                </Column>
            ),
            errorState: (
                <Column>
                    <Input type={'text'} name={'input'} />
                    <Input type={'text'} name={'input'} defaultValue={'Some text'} />
                </Column>
            ),
        },
    },
    {
        id: 2,
        label: 'Search',
        rows: {
            defaultState: (
                <Column>
                    <Search onChange={() => console.log()} />
                    <Search onChange={() => console.log()} defaultValue={'Some text'} />
                </Column>
            ),
            disabledState: (
                <Column>
                    <Search onChange={() => console.log()} disabled={true} />
                    <Search onChange={() => console.log()} defaultValue={'Some text'} disabled={true} />
                </Column>
            ),
            loadingState: (
                <Column>
                    <Search onChange={() => console.log()} />
                    <Search onChange={() => console.log()} defaultValue={'Some text'} />
                </Column>
            ),
            errorState: (
                <Column>
                    <Search onChange={() => console.log()} />
                    <Search onChange={() => console.log()} defaultValue={'Some text'} />
                </Column>
            ),
        },
    },
    {
        id: 2,
        label: 'Date Picker',
        rows: {
            defaultState: (
                <Column>
                    <Search onChange={() => console.log()} />
                    <Search onChange={() => console.log()} defaultValue={'Some text'} />
                </Column>
            ),
            disabledState: (
                <Column>
                    <Search onChange={() => console.log()} disabled={true} />
                    <Search onChange={() => console.log()} defaultValue={'Some text'} disabled={true} />
                </Column>
            ),
            loadingState: (
                <Column>
                    <Search onChange={() => console.log()} />
                    <Search onChange={() => console.log()} defaultValue={'Some text'} />
                </Column>
            ),
            errorState: (
                <Column>
                    <Search onChange={() => console.log()} />
                    <Search onChange={() => console.log()} defaultValue={'Some text'} />
                </Column>
            ),
        },
    },
]

const taskUserData = [
    {
        id: 1,
        label: 'Task user icons',
        rows: {
            defaultState: (
                <Column>
                    <UserProfileIcon fullName={'Mohammed Mulazada'} showTooltip={true} />
                    <UserProfileIcon
                        fullName={'Mohammed Mulazada'}
                        showTooltip={true}
                        uri={'https://lifely.nl/app/uploads/fly-images/433/DSF2638-96x96-ct.jpg'}
                    />
                </Column>
            ),
            cappedList: (
                <Column>
                    <CappedProfileList
                        users={[
                            { id: 1, fullName: 'Mohammed Mulazada' },
                            { id: 2, fullName: 'Rick Lancee' },
                            { id: 3, fullName: 'John Doe' },
                            { id: 4, fullName: 'Fred Wilson' },
                            { id: 5, fullName: 'Wes Bos' },
                        ]}
                    />
                </Column>
            ),
            List: (
                <Column>
                    <UserProfileList>
                        <UserProfileIcon
                            fullName={'Mohammed Mulazada'}
                            uri={'https://lifely.nl/app/uploads/fly-images/433/DSF2638-96x96-ct.jpg'}
                            email={'mo@test.nl'}
                            phone={'9124124'}
                        />
                        <UserProfileIcon
                            fullName={'Mohammed Mulazada'}
                            uri={'https://lifely.nl/app/uploads/fly-images/433/DSF2638-96x96-ct.jpg'}
                            email={'mo@test.nl'}
                            phone={'9124124'}
                        />
                        <UserProfileIcon
                            fullName={'Mohammed Mulazada'}
                            uri={'https://lifely.nl/app/uploads/fly-images/433/DSF2638-96x96-ct.jpg'}
                            email={'mo@test.nl'}
                            phone={'9124124'}
                            action={() => console.log('weee')}
                        />
                    </UserProfileList>
                </Column>
            ),
        },
    },
]

const userPickerTableData = [
    {
        id: 1,
        label: 'User Picker',
        rows: {
            default: (
                <Column>
                    <UserProfilePickerList
                        name="bob"
                        defaultUsers={[
                            {
                                id: 5,
                                fullName: 'Mohammed Mulazada',
                                uri: 'https://lifely.nl/app/uploads/fly-images/433/DSF2638-96x96-ct.jpg',
                                email: 'mo@test.nl',
                                phone: '06234',
                            },
                        ]}
                    />
                </Column>
            ),
        },
    },
]

const complianceLabelData = [
    {
        id: 1,
        label: 'Assessment Label',
        rows: {
            default: <AssessmentLabel assessment={{ id: 1, name: 'WWFT Risk Assessment' }} />,
        },
    },
    {
        id: 2,
        label: 'Control Label',
        rows: {
            default: (
                <ControlLabel
                    control={{
                        id: 1,
                        name: 'CDD beleid',
                        type: ControlTypeType.keyControl,
                        revisionDate: Date(),
                    }}
                />
            ),
        },
    },
    {
        id: 3,
        label: 'Risk Label',
        rows: {
            default: (
                <RiskLabel
                    risk={{
                        id: 1,
                        name: 'Buiten het mandaat beleggen',
                        severity: SeverityLevel.low,
                        isAccepted: false,
                    }}
                />
            ),
        },
    },
    {
        id: 4,
        label: 'Topic Label',
        rows: {
            default: (
                <TopicLabel
                    topic={{
                        id: 1,
                        name: 'Bedrijfsvoering financiële groepen',
                    }}
                />
            ),
        },
    },
    {
        id: 5,
        label: 'Inbox Label',
        rows: {
            default: (
                <InboxLabel
                    inboxItem={{
                        id: 1,
                        name: "EBA publiceert verduidelijkingen over vijfde reeks kwesties inzake API's onder PSD2",
                        impactLevel: null,
                    }}
                />
            ),
        },
    },
    {
        id: 6,
        label: 'Monitoring Report Label',
        rows: {
            default: (
                <MonitoringReportLabel
                    monitoringReport={{
                        id: 1,
                        name: 'Trainingen en opleidingen',
                    }}
                />
            ),
        },
    },
]

const linkedTaskCardData = [
    {
        id: 1,
        label: 'Control',
        rows: {
            default: (
                <MetaCard title={'Taak gekoppeld aan'}>
                    <ControlLabel
                        control={{
                            id: 1,
                            name: 'CDD beleid',
                            type: ControlTypeType.policy,
                            revisionDate: new Date().setUTCDate(1).toString(),
                        }}
                    />
                </MetaCard>
            ),
        },
    },
    {
        id: 2,
        label: 'Beheersmaatregel (document verlopen)',
        rows: {
            default: (
                <MetaCard title={'Taak gekoppeld aan'}>
                    <ControlLabel
                        control={{
                            id: 1,
                            name: 'CDD beleid',
                            type: ControlTypeType.procedure,
                            revisionDate: Date(),
                        }}
                    />
                </MetaCard>
            ),
        },
    },
    {
        id: 3,
        label: 'Risk',
        rows: {
            default: (
                <MetaCard title={'Taak gekoppeld aan'}>
                    <RiskLabel
                        risk={{
                            id: 1,
                            name: 'Buiten het mandaat beleggen',
                            severity: SeverityLevel.low,
                            isAccepted: false,
                        }}
                    />
                </MetaCard>
            ),
        },
    },
    {
        id: 4,
        label: 'Risk (geaccepteerd)',
        rows: {
            default: (
                <MetaCard title={'Taak gekoppeld aan'}>
                    <RiskLabel
                        risk={{
                            id: 1,
                            name: 'Buiten het mandaat beleggen',
                            severity: SeverityLevel.low,
                            isAccepted: true,
                        }}
                    />
                </MetaCard>
            ),
        },
    },
    {
        id: 5,
        label: 'Assessment',
        rows: {
            default: (
                <MetaCard title={'Taak gekoppeld aan'}>
                    <AssessmentLabel assessment={{ id: 1, name: 'WWFT Risk Assessment' }} />
                </MetaCard>
            ),
        },
    },
    {
        id: 6,
        label: 'Topic',
        rows: {
            default: (
                <MetaCard title={'Taak gekoppeld aan'}>
                    <TopicLabel
                        topic={{
                            id: 1,
                            name: 'Bedrijfsvoering financiële groepen',
                        }}
                    />
                </MetaCard>
            ),
        },
    },
    {
        id: 7,
        label: 'Inbox item',
        rows: {
            default: (
                <MetaCard title={'Taak gekoppeld aan'}>
                    <InboxLabel
                        inboxItem={{
                            id: 1,
                            name: "EBA publiceert verduidelijkingen over vijfde reeks kwesties inzake API's onder PSD2",
                            impactLevel: null,
                        }}
                    />
                </MetaCard>
            ),
        },
    },
    {
        id: 8,
        label: 'Monitoring Report',
        rows: {
            default: (
                <MetaCard title={'Taak gekoppeld aan'}>
                    <MonitoringReportLabel
                        monitoringReport={{
                            id: 1,
                            name: 'Trainingen en opleidingen',
                        }}
                    />
                </MetaCard>
            ),
        },
    },
    {
        id: 9,
        label: 'Generic task',
        rows: {
            default: <MetaCard title={'Generieke taak'} />,
        },
    },
]
