import React from 'react'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { localize, notification } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { GQLMutation } from '~/graphql/Mutation'
import {
    ArchiveInternalAlertDocument,
    ArchiveInternalAlertMutation,
    ArchiveInternalAlertMutationVariables,
    InternalAlertType,
    NewsItemSourceType,
    NewsItemType,
} from '~/generated/graphql'
import { MutationFn } from 'react-apollo'
import { Row } from '~/components/Core/Layout/Row'
import { NewsItemTag, NewsItemTagEnum } from '../../Signaling/NewsItemTag'

interface Props {
    internalAlert: InternalAlert
    requestClose: () => void
    onArchive: () => void
}

type InternalAlert = Pick<InternalAlertType, 'id'> & { news: News }
type News = Pick<NewsItemType, 'id' | 'title' | 'publicationDate'> & { sources?: Source[] | null }
type Source = Pick<NewsItemSourceType, 'id' | 'name'>

interface State {
    archivedReason: string | null
}

type ArchiveFn = MutationFn<ArchiveInternalAlertMutation, ArchiveInternalAlertMutationVariables>

export class ArchiveInternalAlertModal extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        archivedReason: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.AlertsView.ArchiveAlertModal)

    public render() {
        const { requestClose, internalAlert } = this.props

        return (
            <GQLMutation<ArchiveInternalAlertMutation, ArchiveInternalAlertMutationVariables>
                mutation={ArchiveInternalAlertDocument}
            >
                {(mutate, { loading }) => {
                    return (
                        <Modal
                            requestClose={requestClose}
                            onAction={() => this.handleSubmit(mutate)}
                            title={internalAlert.news.title}
                            loading={loading}
                            confirmButtonLabel={localize.translate(t => t.Generic.archive)}
                        >
                            <Column>{this.renderContent()}</Column>
                        </Modal>
                    )
                }}
            </GQLMutation>
        )
    }

    private renderContent() {
        const { publicationDate } = this.props.internalAlert.news

        return (
            <Column bigSpacing>
                <Row smallSpacing>
                    {this.renderSources()}
                    {publicationDate && (
                        <Paragraph subtle>
                            {localize.dateFormat(new Date(publicationDate), {
                                readable: true,
                                noWeekday: true,
                                includeTime: true,
                            })}
                        </Paragraph>
                    )}
                </Row>
                <Field label={localize.translate(t => t.Customer.Inbox.InboxOverview.archivedReason)} compact={true}>
                    <Input
                        type="textarea"
                        placeholder={this.loc(t => t.reasonInputPlaceholder)}
                        name="archivedReason"
                        onChange={archivedReason => this.setState({ archivedReason })}
                    />
                </Field>
            </Column>
        )
    }

    private async handleSubmit(mutate: ArchiveFn) {
        const { internalAlert, requestClose, onArchive } = this.props
        const { archivedReason } = this.state

        const response = await mutate({
            variables: {
                departmentId: this.context.activeDepartmentId,
                internalAlertId: internalAlert.id,
                archivedReason,
            },
        })

        if (response && response.data && response.data.archiveDepartmentInternalAlert) {
            onArchive()
            requestClose()
            notification.success(localize.translate(t => t.Generic.successfullyArchived))
        }
    }

    private renderSources() {
        const { sources } = this.props.internalAlert.news

        if (!sources?.length) {
            return null
        }

        if (sources.length === 1) {
            return (
                <NewsItemTag
                    type={NewsItemTagEnum.internalAlert}
                    key={`source-${sources[0].id}`}
                    label={sources[0].name || ''}
                />
            )
        }

        return (
            <NewsItemTag
                type={NewsItemTagEnum.internalAlert}
                key={`source-${sources[0].id}`}
                label={localize.translate(t => t.News.Attributes.sources, { count: sources.length })}
                tooltipContent={sources.map(s => (
                    <div key={s.id}>
                        <Paragraph>{s.name}</Paragraph>
                    </div>
                ))}
            />
        )
    }
}
