import './Field.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { ErrorProvider, ErrorProviderValue } from '~/components/Providers/ErrorProvider'
import { InputError, ErrorType } from '~/services/ErrorService'
import get from 'lodash-es/get'
import { GraphQLError } from 'graphql'
import { localize } from '~/bootstrap'
import isValid from 'date-fns/is_valid'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Paragraph } from '../../Typography/Paragraph'
import { Row } from '../../Layout/Row'

interface Props {
    className?: ClassValue
    label?: string | JSX.Element
    labelString?: string
    forInput?: string
    compact?: boolean
    errorMessage?: string
    isNested?: boolean
    hint?: string
    tooltip?: string
    hasSmallLabelWidth?: boolean
    suffix?: JSX.Element
}

interface ModifierParams {
    hasError: boolean
}

export class Field extends React.PureComponent<Props> {
    private bem = new BEM<ModifierParams>('Field', params => ({
        'is-compact': this.props.compact,
        'has-error': params.hasError,
        'for-heading': !!(this.props.label && !this.props.forInput),
        'for-label': !!(this.props.label && this.props.forInput),
        'is-nested': !!this.props.isNested,
        'has-small-label': !!this.props.hasSmallLabelWidth,
    }))

    constructor(props: Props) {
        super(props)

        if (props.label && typeof props.label !== 'string' && !props.labelString) {
            throw new Error(
                'Invalid props in Field: When providing JSX.Element in `label`, you must provide a `labelString` as well'
            )
        }
    }

    public render() {
        const { children, className, label, forInput, errorMessage, hint, tooltip, suffix } = this.props

        return (
            <ErrorProvider.Consumer>
                {errors => {
                    const error = this.getErrorForInput(errors) || errorMessage

                    return (
                        <div className={this.bem.getClassName(className, { hasError: !!error })}>
                            {label &&
                                (forInput ? (
                                    <label
                                        className={this.bem.getElement('label-input')}
                                        htmlFor={forInput ? `input-${forInput}` : undefined}
                                    >
                                        <Row spaceBetween fullWidth flexStart>
                                            {label}
                                            {tooltip && (
                                                <Tooltip content={<Paragraph small>{tooltip}</Paragraph>}>
                                                    <Icon
                                                        type={IconType.info}
                                                        className={this.bem.getElement('tooltip-icon')}
                                                    />
                                                </Tooltip>
                                            )}
                                        </Row>
                                        {suffix !== undefined ? (
                                            <span className={this.bem.getElement('suffix')}>{suffix}</span>
                                        ) : null}
                                    </label>
                                ) : (
                                    <h4 className={this.bem.getElement('label-heading')}>
                                        <Row spaceBetween fullWidth flexStart>
                                            {label}
                                            {tooltip && (
                                                <Tooltip content={<Paragraph small>{tooltip}</Paragraph>}>
                                                    <Icon
                                                        type={IconType.info}
                                                        className={this.bem.getElement('tooltip-icon')}
                                                    />
                                                </Tooltip>
                                            )}
                                        </Row>
                                        {suffix !== undefined ? (
                                            <span className={this.bem.getElement('suffix')}>{suffix}</span>
                                        ) : null}
                                    </h4>
                                ))}

                            <div className={this.bem.getElement('content')}>
                                {children}
                                {hint && <span className={this.bem.getElement('hint')}>{hint}</span>}
                                {error && <div className={this.bem.getElement('error')}>{error}</div>}
                            </div>
                        </div>
                    )
                }}
            </ErrorProvider.Consumer>
        )
    }

    private getErrorForInput(errors: ErrorProviderValue) {
        const { forInput } = this.props

        if (!forInput || !errors) {
            return null
        }

        const partialInputPath = forInput.indexOf('.') !== -1 ? forInput.substring(forInput.indexOf('.') + 1) : forInput
        const errorForInput = this.getInputError(partialInputPath, forInput, errors as any)

        if (!errorForInput) {
            return null
        }

        const context = (errorForInput.context || {}) as Record<string, any>

        // If the type is an date type, we want to format the dates in the context.
        if (errorForInput.type.includes('date') && 'limit' in context) {
            context.limit = isValid(new Date(context.limit))
                ? localize.dateFormat(new Date(context.limit))
                : context.limit
        }

        const translation = localize.translate(t => get(t.Errors.InputValidation, errorForInput.type), {
            ...context,
            label: this.getLabelAsString(),
        })

        if (!translation) {
            return errorForInput.message
        }

        return translation
    }

    // tslint:disable-next-line:typedef
    private getInputError(partialInputPath: string, inputPath: string, errors: GraphQLError[]) {
        for (const error of errors) {
            const code = get(error, 'extensions.code')

            if (code !== ErrorType.validation) {
                return undefined
            }

            const inputErrors: InputError[] = get(error, 'extensions.inputErrors') || []

            return inputErrors.find(inputError => {
                if (inputError.path.includes(partialInputPath)) {
                    return true
                }

                if (inputError.path.join('.') === inputPath) {
                    return true
                }

                if (inputError.context.peers && inputError.context.peers.includes(inputPath)) {
                    return true
                }

                return false
            })
        }

        return undefined
    }

    private getLabelAsString(): string {
        const { labelString, label } = this.props

        if (labelString) {
            return labelString
        }

        if (typeof label === 'string') {
            return label
        }

        return localize.translate(t => t.Errors.InputValidation.unlabeledField)
    }
}
