import './CustomerFeedExportsTable.scss'

import React from 'react'
import { fileService, localize, notification } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ColumnOptions, RowData, Table } from '~/components/Core/DataDisplay/Table/Table'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import {
    CustomerFeedExportsDocument,
    CustomerFeedExportsQueryVariables,
    CustomerSubscribedToFeedExport,
} from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { FileType } from '~/services/FileService'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    customerFeedId: number
}

export class CustomerFeedExportsTable extends React.PureComponent<Props> {
    private bem = new BEM('CustomerFeedExportsTable')
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerFeeds.CustomerFeedExportsTable)

    private tableColumns: ColumnOptions[] = [
        { field: 'name', headerLabel: this.loc(t => t.file), maxWidth: 300 },
        { field: 'startDate', headerLabel: this.loc(t => t.startDate) },
        { field: 'endDate', headerLabel: this.loc(t => t.endDate) },
        { field: 'metadata', headerLabel: this.loc(t => t.metadata) },
        { field: 'sentTo', headerLabel: this.loc(t => t.sentTo) },
    ]

    public render() {
        const { customerFeedId } = this.props

        return (
            <InfiniteScrollQuery<CustomerSubscribedToFeedExport, CustomerFeedExportsQueryVariables>
                query={CustomerFeedExportsDocument}
                variables={{ customerFeedId }}
            >
                {({ data, loading, loadingMore }) => {
                    const exports = data ? data.nodes : []

                    return (
                        <Table
                            className={this.bem.getClassName()}
                            columns={this.tableColumns}
                            data={this.getTableData(exports)}
                            loading={loading}
                            loadingMore={loadingMore}
                        />
                    )
                }}
            </InfiniteScrollQuery>
        )
    }

    private getTableData(feedExports: CustomerSubscribedToFeedExport[]): RowData[] {
        const dateProps = { noWeekday: true, readable: true }

        return feedExports.map(feedExport => {
            const startDate = feedExport.startDate
                ? localize.dateFormat(new Date(feedExport.startDate), dateProps)
                : null

            return {
                id: feedExport.id,
                columns: {
                    name: this.renderName(feedExport.file.id, feedExport.file.name),
                    startDate: <Paragraph>{startDate}</Paragraph>,
                    endDate: <Paragraph>{localize.dateFormat(new Date(feedExport.endDate), dateProps)}</Paragraph>,
                    metadata: this.renderMetadata(feedExport.settings), // TODO
                    sentTo: this.renderSentTo(feedExport.recipientEmails), // TOOD
                },
            }
        })
    }

    private renderName(fileId: number, fileName: string) {
        return (
            <Tooltip message={fileName} className={this.bem.getElement('file-tooltip')}>
                <Button
                    type={ButtonType.actionLink}
                    onClick={this.handleDownload(fileId, fileName)}
                    className={this.bem.getElement('file')}
                >
                    {fileName}
                </Button>
            </Tooltip>
        )
    }

    private renderMetadata(settings?: string | null) {
        const parsedSettings = settings ? JSON.parse(settings) : {}

        const settingCount = Object.keys(parsedSettings).length ?? 0
        if (!settingCount) {
            return <Paragraph>{this.loc(t => t.property, { smart_count: 0 })}</Paragraph>
        }

        const tooltipContent = Object.entries(parsedSettings).map(([s, v], i) => (
            <Row extraSmallSpacing={true} key={`recipient-${i}`}>
                <Paragraph bold={true} small={true}>
                    {/* tslint:disable-next-line:jsx-use-translation-function */}
                    {s}:
                </Paragraph>
                <Paragraph bold={true} small={true}>
                    {v as string}
                </Paragraph>
            </Row>
        ))

        return (
            <Tooltip content={tooltipContent}>
                <Paragraph>{this.loc(t => t.property, { smart_count: settingCount })}</Paragraph>
            </Tooltip>
        )
    }

    private renderSentTo(recipients?: string[] | null) {
        const recipientCount = recipients?.length ?? 0
        if (!recipientCount) {
            return <Paragraph>{this.loc(t => t.emails, { smart_count: 0 })}</Paragraph>
        }

        const tooltipContent = recipients?.map((recipient, i) => (
            <Paragraph small={true} key={`recipient-${i}`}>
                {recipient}
            </Paragraph>
        ))

        return (
            <Tooltip content={tooltipContent}>
                <Paragraph>{this.loc(t => t.emails, { smart_count: recipientCount })}</Paragraph>
            </Tooltip>
        )
    }

    private handleDownload = (fileId: number, fileName: string) => async () => {
        const url = await fileService.createFileUrl(fileId, fileName, FileType.radarChangeExport)
        if (!url) {
            notification.error(localize.translate(t => t.Errors.failedToLoad))
            return
        }

        window.open(url, '_blank')
    }
}
