import './RelevantMatrix.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { DepartmentType } from '~/generated/graphql'
import { flatten, uniqBy } from 'lodash-es'
import { Topic } from '../../Topic/TopicsQuery'
import { Matrix, MatrixItem } from '~/components/Core/DataDisplay/Matrix/Matrix'
import { RelevantMatrixCard } from './RelevantMatrixCard'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'

interface Props {
    className?: ClassValue
    defaultTopics?: Topic[]
    defaultDepartments?: DepartmentType[]
    topics?: Topic[]
    departments?: DepartmentType[]
    suggestedTopics?: Topic[]
    selectedTopics?: Topic[]
    onAddTopic: () => void
    onAddDepartment: () => void
    onRemoveTopic: (id: number) => void
    onRemoveDepartment: (id: number) => void
    hideDepartments?: boolean
}

interface State {}

export class RelevantMatrix extends React.PureComponent<Props, State> {
    private bem = new BEM('RelevantMatrix')
    private loc = localize.namespaceTranslate(t => t.Customer.Signaling.RelevantModal.matrix)

    public render() {
        const { className, onAddTopic, onAddDepartment, hideDepartments } = this.props

        const topicItems = this.getTopicItems()
        const departmentItems = this.getDeparmentItems()

        return (
            <div className={this.bem.getClassName(className)}>
                <Matrix
                    itemHeight={topicItems.length === 0 ? 30 : undefined}
                    hideRightGroup={hideDepartments}
                    leftItems={topicItems}
                    rightItems={departmentItems}
                    leftListTitle={this.loc(t => t.topicListTitle)}
                    renderLeftEmptyState={() => (
                        <Button
                            icon={IconType.add}
                            type={ButtonType.secondary}
                            className={this.bem.getElement('add-button')}
                            onClick={onAddTopic}
                        >
                            {this.loc(t => t.addTopic)}
                        </Button>
                    )}
                    renderLeftAddButton={() =>
                        !!topicItems.length && (
                            <Button
                                type={ButtonType.noStyling}
                                icon={IconType.add}
                                className={this.bem.getElement('add-icon')}
                                onClick={onAddTopic}
                            />
                        )
                    }
                    rightListTitle={this.loc(t => t.departmentListTitle)}
                    renderRightEmptyState={() => (
                        <Button
                            icon={IconType.add}
                            type={ButtonType.secondary}
                            className={this.bem.getElement('add-button')}
                            onClick={onAddDepartment}
                        >
                            {this.loc(t => t.addDepartment)}
                        </Button>
                    )}
                    renderRightAddButton={() =>
                        !!departmentItems.length && (
                            <Button
                                type={ButtonType.noStyling}
                                icon={IconType.add}
                                className={this.bem.getElement('add-icon')}
                                onClick={onAddDepartment}
                            />
                        )
                    }
                />
            </div>
        )
    }

    private getTopicItems(): MatrixItem[] {
        const { onRemoveTopic, topics = [], suggestedTopics, defaultTopics = [] } = this.props

        const defaultTopicsItems = defaultTopics.map(topic => ({
            id: topic.id,
            name: topic.name,
            linkIds: (topic.departments || []).map(department => department.id),
            content: (
                <RelevantMatrixCard
                    name={topic.name}
                    isSuggested={!!suggestedTopics?.find(({ id }) => id === topic.id)}
                />
            ),
        }))

        const topicItems = topics.map(topic => ({
            id: topic.id,
            name: topic.name,
            linkIds: (topic.departments || []).map(department => department.id),
            content: (
                <RelevantMatrixCard
                    name={topic.name}
                    onDelete={() => onRemoveTopic(topic.id)}
                    isSuggested={!!suggestedTopics?.find(({ id }) => id === topic.id)}
                />
            ),
        }))

        return [...defaultTopicsItems, ...topicItems]
    }

    private getDeparmentItems(): MatrixItem[] {
        const { onRemoveDepartment, topics, departments = [], defaultDepartments = [] } = this.props

        const defaultDepartmentItems = defaultDepartments.map(department => ({
            id: department.id,
            name: department.name,
            content: <RelevantMatrixCard name={department.name} />,
        }))

        // Get all the deparments from topic
        const topicDepartments = topics ? flatten(topics.map(topic => topic.departments || [])) : []

        // Get only unique departments and map them to items
        const topicDepartmentItems = uniqBy(topicDepartments, 'id').map(department => ({
            id: department.id,
            name: department.name,
            content: (
                <RelevantMatrixCard name={department.name} isSuggested={this.isSuggestedDepartment(department.id)} />
            ),
        }))

        // these are the departments without existing topics
        // Only extra departments can be removed
        const extraDepartmentItems = departments.map(department => ({
            id: department.id,
            name: department.name,
            content: <RelevantMatrixCard name={department.name} onDelete={() => onRemoveDepartment(department.id)} />,
        }))

        return uniqBy([...defaultDepartmentItems, ...topicDepartmentItems, ...extraDepartmentItems], 'id')
    }

    private isSuggestedDepartment(departmentId: number) {
        const { suggestedTopics, selectedTopics } = this.props

        if (!selectedTopics?.length || !suggestedTopics?.length) {
            return false
        }

        const suggestedTopicIds = suggestedTopics.map(({ id }) => id)
        const selectedSuggestedTopics = selectedTopics.filter(({ id }) => suggestedTopicIds.includes(id))

        return selectedSuggestedTopics.some(topic => topic.departments?.some(({ id }) => id === departmentId))
    }
}
