import './CustomerFrameworkSelect.scss'

import React from 'react'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import gql from 'graphql-tag'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import { CustomerFrameworkColorEnumType, CustomerFrameworkIconEnumType } from '~/generated/graphql'
import { Query } from 'react-apollo'
import { BEM } from '~/services/BEMService'
import { FrameworkIcon } from '../../Customer/Framework/FrameworkIcon/FrameworkIcon'

interface Props {
    name?: string
    onChange: (option: SelectOption | null) => void
    selectedOptions?: SelectOption[] | null
    placeholder?: string
    isFilter?: boolean
    externallyCuratedOnly?: boolean
}

interface State {}

const QUERY = gql`
    query allCustomerFrameworks($customerSlug: String, $externallyCuratedOnly: Boolean) {
        customerFrameworks(customerSlug: $customerSlug, externallyCuratedOnly: $externallyCuratedOnly) {
            id
            name
            color
            icon
        }
    }
`

interface CustomerFramework {
    id: number
    name: string
    color: CustomerFrameworkColorEnumType
    icon: CustomerFrameworkIconEnumType
}

interface CustomerFrameworksQueryResponse {
    customerFrameworks?: CustomerFramework[]
}

export class CustomerFrameworkSelect extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public bem = new BEM('CustomerFrameworkSelect')

    public render() {
        const { name, onChange, selectedOptions, placeholder, isFilter } = this.props

        return (
            <Query<CustomerFrameworksQueryResponse>
                query={QUERY}
                variables={{
                    customerSlug: this.context.customer.slug,
                    externallyCuratedOnly: this.props.externallyCuratedOnly,
                }}
            >
                {({ data, loading, fetchMore }) => {
                    if (loading) {
                        // key property is necessary to make sure that
                        // <Form.Select> is fully remounted when loading=false
                        return <Form.Select key="loading" name={'loading'} loading={true} />
                    }

                    const options =
                        data && data.customerFrameworks
                            ? data.customerFrameworks.map(framework => ({
                                  label: this.renderLabel(framework),
                                  value: framework.id,
                              }))
                            : []

                    return (
                        <Form.Select
                            placeholder={placeholder}
                            name={name || 'customerFramework'}
                            defaultValue={selectedOptions || undefined}
                            options={options}
                            clearable={true}
                            loading={loading}
                            onChange={(option: SelectOption) => {
                                onChange(option)
                            }}
                            onSearch={query => this.setState({ search: query })}
                            isFilter={isFilter}
                        />
                    )
                }}
            </Query>
        )
    }

    private renderLabel(customerFramework: CustomerFramework) {
        return (
            <div className={this.bem.getElement('select-option')}>
                <FrameworkIcon frameworkType={customerFramework.icon} color={customerFramework.color} />
                <span>{customerFramework.name}</span>
            </div>
        )
    }
}
