import './TableList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'

interface Props {
    className?: ClassValue
    columns?: 2 | 3 | 4
    loadingMore?: boolean
    loading?: boolean
}

interface State {}

export class TableList extends React.PureComponent<Props, State> {
    private bem = new BEM('TableList', () => ({
        'multi-column': !!this.props.columns,
        [`${this.props.columns}-columns`]: !!this.props.columns,
        'is-loading': !!this.props.loading,
        'is-loading-more': !!this.props.loadingMore,
    }))

    public render() {
        const { children, className, loadingMore, loading } = this.props

        return (
            <ul className={this.bem.getClassName(className)}>
                {loading && <Spinner delayed={true} className={this.bem.getElement('loading')} />}
                {children}
                {loadingMore && <Spinner className={this.bem.getElement('loading-more')} />}
            </ul>
        )
    }
}
