import './Action.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import isString from 'lodash-es/isString'
import { MediaQuery, Screensize } from '../Layout/MediaQuery'

interface Props {
    className?: ClassValue
    title: string
    button?: JSX.Element
    description?: string | JSX.Element | JSX.Element[]
}

export const Action: React.SFC<Props> = props => {
    const { className, title, button, description } = props
    const bem = new BEM('Action')

    return (
        <div className={bem.getClassName(className)}>
            <div className={bem.getElement('action-header')}>
                <ComponentTitle title={title} />
                {button && <MediaQuery breakpoint={Screensize.tablet}>{button}</MediaQuery>}
            </div>
            <div className={bem.getElement('action-content')}>
                {description &&
                    (isString(description) ? <Paragraph subtle={true}>{description}</Paragraph> : description)}
            </div>
            {button && (
                <div>
                    <MediaQuery breakpoint={Screensize.mobile}>{button}</MediaQuery>
                </div>
            )}
        </div>
    )
}
