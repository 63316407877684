import React from 'react'
import { userClient } from '~/bootstrap'
import { UserStatus } from '~/graphql/types/User'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { DateFormat } from '~/components/Core/Date/DateFormat'

interface Props {
    user: {
        status: UserStatus
        invitedAt: string | null
    }
}

interface State {}

export class UserStatusDisplay extends React.Component<Props, State> {
    public render() {
        const { user } = this.props
        const status = userClient.getReadableStatus(user.status)

        if (user.status !== UserStatus.invited || !user.invitedAt) {
            return <Paragraph>{status}</Paragraph>
        }

        return (
            <Tooltip
                content={
                    <DateFormat readable={true} noWeekday={true} includeTime={true} date={new Date(user.invitedAt)} />
                }
            >
                <div>{status}</div>
            </Tooltip>
        )
    }
}
