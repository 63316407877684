import './CustomerFormFields.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Column } from '~/components/Core/Layout/Column'
import { ConsultantSelect } from '~/components/Domain/Consultants/ConsultantSelect'
import { RadioGroup } from '~/components/Core/DataEntry/Form/RadioGroup'
import { CustomerGroupType, CustomerType } from '~/generated/graphql'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM } from '~/services/BEMService'
import { CustomerGroupSelect } from '../CustomerGroup/CustomerGroupSelect'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { Attention } from '~/components/Core/Feedback/Attention/Attention'

interface Props {
    defaultAvailableSeats?: number
    defaultCustomerGroup?: CustomerGroupType
    customer?: CustomerType
    onCustomerGroupChange?: (selectOption: SelectOption | null) => void
}

interface State {
    customerGroupName?: string
    ssoEnabled?: boolean
    autoCreate?: boolean
}

export class CustomerFormFields extends React.PureComponent<Props, State> {
    public state: State = {
        customerGroupName: this.props.defaultCustomerGroup?.name || this.props.customer?.customerGroup?.name,
        ssoEnabled: !!this.props.customer?.ssoLoginEnabled,
        autoCreate: !!this.props.customer?.createUserOnSSOLogin,
    }

    private customerLoc = localize.namespaceTranslate(t => t.Customer.Attributes)
    private addressLoc = localize.namespaceTranslate(t => t.Address.Attributes)
    private genericLoc = localize.namespaceTranslate(t => t.Generic)

    private bem = new BEM('CustomerFormFields')

    private formNames = {
        customerGroup: 'customerGroupId',
        logo: 'logo',
        name: 'name',
        contactName: 'contactName',
        contactEmail: 'contactEmail',
        addressLine: 'address.addressLine',
        postalCode: 'address.postalCode',
        city: 'address.city',
        consultantContactId: 'consultantContactId',
        availableSeats: 'availableSeats',
        editorialAccess: 'editorialAccess',
        ssoLoginEnabled: 'ssoLoginEnabled',
        createUserOnSSOLogin: 'createUserOnSSOLogin',
        defaultDepartmentId: 'defaultDepartmentId',
    }

    public render() {
        return (
            <Column className={this.bem.getClassName()}>
                {this.renderCustomerGroupSelect()}
                {this.renderLogoField()}
                {this.renderCustomerFields()}
                <FieldSet noMarginOnLastChild={true}>
                    {this.renderConsultantFields()}
                    {this.renderAccessFields()}
                </FieldSet>
            </Column>
        )
    }

    private renderCustomerGroupSelect() {
        const { customer, onCustomerGroupChange, defaultCustomerGroup } = this.props

        const shouldShowWarning = customer?.ssoLoginEnabled && this.state.customerGroupName
        const attentionText = localize.translate(t => t.Consultant.CustomerDetailEditView.customerGroupWarning, {
            customerGroup: this.state.customerGroupName,
        })

        return (
            <Column smallSpacing={true}>
                <Field
                    forInput={this.formNames.customerGroup}
                    label={localize.translate(t => t.Entities.CustomerGroup)}
                >
                    <CustomerGroupSelect
                        name={this.formNames.customerGroup}
                        defaultCustomerGroup={defaultCustomerGroup || customer?.customerGroup || undefined}
                        onChange={(selectOption: SelectOption | null) => {
                            if (onCustomerGroupChange) {
                                onCustomerGroupChange(selectOption)
                            }

                            if (selectOption?.label !== this.state.customerGroupName) {
                                this.setState({
                                    customerGroupName: selectOption?.value
                                        ? (selectOption?.label as string)
                                        : undefined,
                                })
                            }
                        }}
                    />
                </Field>
                {shouldShowWarning && <Attention attentionText={attentionText} />}
            </Column>
        )
    }

    private renderLogoField() {
        const { customer } = this.props

        return (
            <FieldSet>
                <Field
                    forInput={this.formNames.logo}
                    label={this.genericLoc(t => t.logo)}
                    className={this.bem.getElement('logo')}
                >
                    <Form.AvatarUpload
                        name={this.formNames.logo}
                        uri={customer?.logo || undefined}
                        label={customer?.name}
                    />
                </Field>
            </FieldSet>
        )
    }

    private renderCustomerFields() {
        const { customer } = this.props

        return (
            <FieldSet>
                <Field forInput={this.formNames.name} label={this.customerLoc(t => t.name)}>
                    <Form.Input
                        name={this.formNames.name}
                        defaultValue={customer?.name}
                        placeholder={this.customerLoc(t => t.name)}
                    />
                </Field>
                {this.renderAddressFields()}
                {this.renderContactFields()}
            </FieldSet>
        )
    }

    private renderConsultantFields() {
        const { customer } = this.props
        const defaultConsultant =
            customer?.consultantContact?.id && customer.consultantContact.user
                ? [
                      {
                          label: customer.consultantContact.user.profile.fullName,
                          value: customer.consultantContact.id,
                      },
                  ]
                : undefined

        return (
            <Field
                forInput={this.formNames.consultantContactId}
                label={this.customerLoc(t => t.contactPersonForConsult)}
            >
                <ConsultantSelect
                    name={this.formNames.consultantContactId}
                    placeholder={this.customerLoc(t => t.contactPersonForConsult)}
                    defaultValue={defaultConsultant}
                />
            </Field>
        )
    }

    private renderAccessFields() {
        const { customer, defaultAvailableSeats } = this.props
        const { customerGroupName } = this.state

        return (
            <>
                <Field forInput={this.formNames.availableSeats} label={this.customerLoc(t => t.maxAmountOfEmployees)}>
                    <Form.Stepper
                        defaultValue={customer?.availableSeats ?? 0}
                        min={defaultAvailableSeats}
                        name={this.formNames.availableSeats}
                    />
                </Field>
                {!!customer?.filledSeats && (
                    <Row>
                        <Paragraph>
                            {localize.translate(t => t.Consultant.CustomerDetailEditView.alreadyActiveUsers, {
                                users: customer.filledSeats,
                            })}
                        </Paragraph>
                    </Row>
                )}
                <Field forInput={this.formNames.editorialAccess} label={this.customerLoc(t => t.editorialAccess)}>
                    <RadioGroup className={this.bem.getElement('radio-group')}>
                        <Form.Radio
                            name={this.formNames.editorialAccess}
                            label={this.genericLoc(t => t.yes)}
                            value={true}
                            defaultChecked={customer?.editorialAccess}
                        />
                        <Form.Radio
                            name={this.formNames.editorialAccess}
                            label={this.genericLoc(t => t.no)}
                            value={false}
                            defaultChecked={!customer?.editorialAccess}
                        />
                    </RadioGroup>
                </Field>
                {!customerGroupName && this.renderSSOFields()}
            </>
        )
    }

    private renderAddressFields() {
        const { customer } = this.props

        return (
            <>
                <Field forInput={this.formNames.addressLine} label={this.addressLoc(t => t.streetNameAndHouseNumber)}>
                    <Form.Input
                        name={this.formNames.addressLine}
                        placeholder={this.addressLoc(t => t.streetNameAndHouseNumber)}
                        defaultValue={customer?.address?.addressLine}
                    />
                </Field>
                <Field forInput={this.formNames.postalCode} label={this.addressLoc(t => t.zipcode)}>
                    <Form.Input
                        name={this.formNames.postalCode}
                        defaultValue={customer?.address?.postalCode}
                        placeholder={this.addressLoc(t => t.zipcode)}
                    />
                </Field>
                <Field forInput={this.formNames.city} label={this.addressLoc(t => t.place)}>
                    <Form.Input
                        name={this.formNames.city}
                        defaultValue={customer?.address?.city}
                        placeholder={this.addressLoc(t => t.place)}
                    />
                </Field>
            </>
        )
    }

    private renderContactFields() {
        const { customer } = this.props

        return (
            <>
                <Field forInput={this.formNames.contactName} label={this.customerLoc(t => t.contactPerson)}>
                    <Form.Input
                        name={this.formNames.contactName}
                        placeholder={this.customerLoc(t => t.contactPerson)}
                        defaultValue={customer?.contactName}
                    />
                </Field>
                <Field forInput={this.formNames.contactEmail} label={this.customerLoc(t => t.emailContactPerson)}>
                    <Form.Input
                        name={this.formNames.contactEmail}
                        placeholder={this.customerLoc(t => t.emailContactPerson)}
                        defaultValue={customer?.contactEmail}
                    />
                </Field>
            </>
        )
    }

    private renderSSOFields() {
        const { customer } = this.props
        const { customerGroupName, ssoEnabled } = this.state

        return (
            <>
                <Field forInput={this.formNames.ssoLoginEnabled} label={this.customerLoc(t => t.ssoLoginEnabled)}>
                    <RadioGroup className={this.bem.getElement('radio-group')}>
                        <Form.Radio
                            name={this.formNames.ssoLoginEnabled}
                            value={true}
                            label={this.customerLoc(t => t.ssoLoginEnabledYes)}
                            defaultChecked={customer?.ssoLoginEnabled}
                            disabled={!!customerGroupName}
                            onChange={checked => this.setState({ ssoEnabled: checked })}
                        />
                        <Form.Radio
                            name={this.formNames.ssoLoginEnabled}
                            value={false}
                            checked={customerGroupName ? !!customerGroupName : undefined}
                            disabled={!!customerGroupName}
                            defaultChecked={!customer?.ssoLoginEnabled}
                            label={this.customerLoc(t => t.ssoLoginEnabledNo)}
                            onChange={checked => this.setState({ ssoEnabled: checked })}
                        />
                    </RadioGroup>
                </Field>
                {ssoEnabled && (
                    <Field
                        forInput={this.formNames.createUserOnSSOLogin}
                        label={this.customerLoc(t => t.createUserOnSSOLogin)}
                    >
                        <RadioGroup className={this.bem.getElement('radio-group')}>
                            <Form.Radio
                                name={this.formNames.createUserOnSSOLogin}
                                value={true}
                                label={this.customerLoc(t => t.ssoLoginEnabledYes)}
                                defaultChecked={!!customer?.createUserOnSSOLogin}
                                onChange={checked => this.setState({ autoCreate: checked })}
                            />
                            <Form.Radio
                                name={this.formNames.createUserOnSSOLogin}
                                value={false}
                                checked={customerGroupName ? !!customerGroupName : undefined}
                                defaultChecked={!customer?.createUserOnSSOLogin}
                                label={this.customerLoc(t => t.ssoLoginEnabledNo)}
                                onChange={checked => this.setState({ autoCreate: checked })}
                            />
                        </RadioGroup>
                    </Field>
                )}
            </>
        )
    }
}
