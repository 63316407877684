import React from 'react'
import { EmployeePermissionEnum, NewsItemType } from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ArchiveNewsItemsContainer } from '~/components/Domain/Signaling/ArchiveNewsItems/ArchiveNewsItemsContainer'
import { permissions } from '~/bootstrap'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { ItemListSpacer } from '~/components/Core/DataDisplay/ItemListSpacer/ItemListSpacer'
import { SignalingItemCard } from '~/components/Domain/Signaling/SignalingItemCard'
import { ArchiveNewsItemButtonContainer } from '~/components/Domain/Signaling/ArchiveNewsItems/ArchiveNewsItemButtonContainer'

interface Props {
    data: NewsItemType[]
    refetch: () => void
    loadingMore: boolean
    forArchived?: boolean
}

interface State {
    selectedNewsItems: NewsItemType[]
}

export class SignalingItemContainer extends React.Component<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        selectedNewsItems: [],
    }

    public render() {
        const { data, refetch, loadingMore, forArchived } = this.props
        const { selectedNewsItems } = this.state

        const hasPermissionToArchive = permissions.hasPermission(EmployeePermissionEnum.canMonitorSignaling)
        const hasPermissionToMarkRelevant = permissions.canMarkRelevantNews()

        return (
            <>
                {this.renderContainer()}
                <ItemListSpacer>
                    {data.map((newsItem, index) => (
                        <li key={newsItem.id}>
                            <ArchiveNewsItemButtonContainer
                                onArchive={refetch}
                                newsId={newsItem.id}
                                newsTitle={newsItem.title}
                            >
                                {openArchiveModal => (
                                    <SignalingItemCard
                                        isFirstItem={index === 0}
                                        isCheckboxActive={this.state.selectedNewsItems.length > 0}
                                        hasPermissionToArchive={!forArchived && hasPermissionToArchive}
                                        hasPermissionToMarkRelevant={hasPermissionToMarkRelevant}
                                        checked={selectedNewsItems.includes(newsItem)}
                                        newsItem={newsItem}
                                        handleOnCardCheck={this.handleOnCheck(newsItem)}
                                        handleOnArchive={openArchiveModal}
                                        onMarkRelevant={refetch}
                                    />
                                )}
                            </ArchiveNewsItemButtonContainer>
                        </li>
                    ))}
                    {loadingMore && (
                        <li>
                            <Spinner />
                        </li>
                    )}
                </ItemListSpacer>
            </>
        )
    }

    private renderContainer = () => {
        const { forArchived, refetch } = this.props
        const { selectedNewsItems } = this.state

        const newsIds = selectedNewsItems.map(({ id }) => id)

        if (forArchived) {
            return
        }

        return (
            <ArchiveNewsItemsContainer
                toBeArchivedIds={newsIds}
                toBeArchivedTitle={selectedNewsItems.length === 1 ? selectedNewsItems[0].title : undefined}
                onClearSelection={this.onClearSelection}
                onArchive={refetch}
            />
        )
    }

    private handleOnCheck = (item: NewsItemType) => (checked: boolean) => {
        const { selectedNewsItems } = this.state

        if (checked) {
            this.setState({ selectedNewsItems: [...selectedNewsItems, item] })
        } else {
            const filteredSelectedNewsItems = selectedNewsItems.filter(({ id }) => id !== item.id)
            this.setState({ selectedNewsItems: filteredSelectedNewsItems })
        }

        return
    }

    private onClearSelection = () => {
        this.setState({ selectedNewsItems: [] })
    }
}
