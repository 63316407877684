export enum IconType {
    add = 'add',
    addCircle = 'addCircle',
    addSmall = 'addSmall',
    ai = 'ai',
    airplane = 'airplane',
    alert = 'alert',
    archive = 'archive',
    arrowDown = 'arrowDown',
    arrowLeft = 'arrowLeft',
    arrowRight = 'arrowRight',
    arrowUp = 'arrowUp',
    assessment = 'assessment',
    assessmentSatisfies = 'assessmentSatisfies',
    assessmentSatisfiesPartly = 'assessmentSatisfiesPartly',
    assessmentScribble = 'assessmentScribble',
    assessmentAlmostSatisfies = 'assessmentAlmostSatisfies',
    assessmentAlmostSatisfiesPartly = 'assessmentAlmostSatisfiesPartly',
    assessmentNotSatisfies = 'assessmentNotSatisfies',
    assessmentNotSatisfiesPartly = 'assessmentNotSatisfiesPartly',
    assessmentNotGiven = 'assessmentNotGiven',
    assessmentWidget = 'assessmentWidget',
    attachment = 'attachment',
    attention = 'attention',
    back = 'back',
    backArrow = 'backArrow',
    bank = 'bank',
    breadcrumb = 'breadcrumb',
    brokenvase = 'brokenvase',
    calendar = 'calendar',
    car = 'car',
    cardCheck = 'cardCheck',
    caretDown = 'caretDown',
    caretUp = 'caretUp',
    chatBubble = 'chatBubble',
    check = 'check',
    child = 'child',
    clock = 'clock',
    close = 'close',
    closeModal = 'closeModal',
    company = 'company',
    complianceAlmostSatisfied = 'complianceAlmostSatisfied',
    complianceDoesNotApply = 'complianceDoesNotApply',
    complianceNotChecked = 'complianceNotChecked',
    complianceNotSatisfied = 'complianceNotSatisfied',
    complianceSatisfied = 'complianceSatisfied',
    consult = 'consult',
    consultation = 'consultation',
    control = 'control',
    controlControl = 'controlControl',
    controlKeyControl = 'controlKeyControl',
    controlPolicy = 'controlPolicy',
    controlProcedure = 'controlProcedure',
    controlTask = 'controlTask',
    controlTaskWidget = 'controlTaskWidget',
    controlTaskWidgetContent = 'controlTaskWidgetContent',
    copy = 'copy',
    createControlTask = 'createControlTask',
    creditcard = 'creditcard',
    customerfeeds = 'customerfeeds',
    customerFeedStatusDisabled = 'customerFeedStatusDisabled',
    customerFeedStatusEnabled = 'customerFeedStatusEnabled',
    customerGroup = 'customerGroup',
    customers = 'customers',
    dashboard = 'dashboard',
    deeplLogo = 'deeplLogo',
    departments = 'departments',
    dot = 'dot',
    downArrow = 'downArrow',
    edit = 'edit',
    en = 'en',
    envelope = 'envelope',
    equals = 'equals',
    export = 'export',
    external = 'external',
    eye = 'eye',
    file = 'file',
    filter = 'filter',
    flower = 'flower',
    following = 'following',
    graphs = 'graphs',
    groupView = 'groupView',
    headphones = 'headphones',
    heart = 'heart',
    help = 'help',
    history = 'history',
    ignoreControlTask = 'ignoreControlTask',
    inbox = 'inbox',
    inboxWidget = 'inboxWidget',
    inboxItemImpact1 = 'inboxItemImpact1',
    inboxItemImpact2 = 'inboxItemImpact2',
    inboxItemImpact3 = 'inboxItemImpact3',
    inboxItemImpactWidget = 'inboxItemImpactWidget',
    info = 'info',
    keyControl = 'keyControl',
    legalFramework = 'legalFramework',
    link = 'link',
    linkedin = 'linkedin',
    listView = 'listView',
    log = 'log',
    logo = 'logo',
    logOut = 'logout',
    manage = 'manage',
    menu = 'menu',
    menuAdmin = 'menuAdmin',
    menuContent = 'menuContent',
    menuDashboard = 'menuDashboard',
    menuProperties = 'menuProperties',
    menuWorkflow = 'menuWorkflow',
    monitoringReport = 'monitoringReport',
    monitoringReportWidget = 'monitoringReportWidget',
    newTab = 'newTab',
    nextArrow = 'nextArrow',
    nextArrow2 = 'nextArrow2',
    nl = 'nl',
    norm = 'norm',
    notApplicable = 'assessmentNotApplicable',
    nvtAssessment = 'nvtAssessment',
    ok = 'ok',
    options = 'options',
    paperclip = 'paperclip',
    paperPlane = 'paperPlane',
    parachute = 'parachute',
    parentTask = 'parentTask',
    paw = 'paw',
    pin = 'pin',
    pinRelease = 'pinRelease',
    placeholder = 'placeholder',
    plus = 'plus',
    policy = 'policy',
    prioHigh = 'prioHigh',
    prioLow = 'prioLow',
    prioMid = 'prioMid',
    procedure = 'procedure',
    profiles = 'profiles',
    questionmark = 'questionmark',
    quickNote = 'quickNote',
    radar = 'radar',
    radarWidget = 'radarWidget',
    remove = 'remove',
    repeat = 'repeat',
    revert = 'revert',
    risk = 'risk',
    riskHighChange = 'riskHighChange',
    riskHighImpact = 'riskHighImpact',
    riskLowChange = 'riskLowChange',
    riskLowImpact = 'riskLowImpact',
    riskWidget = 'riskWidget',
    screenshot = 'screenshot',
    search = 'search',
    settings = 'settings',
    shared = 'shared',
    shield = 'shield',
    signaling = 'signaling',
    signalingWidget = 'signalingWidget',
    sources = 'sources',
    switch = 'switch',
    task = 'task',
    taskAdd = 'taskAdd',
    taskCheckmark = 'taskCheckmark',
    taskOpen = 'taskOpen',
    taskReopen = 'taskReopen',
    taskTemplate = 'taskTemplate',
    taskWidget = 'taskWidget',
    textLink = 'textLink',
    theme = 'theme',
    themeWidget = 'themeWidget',
    thumbsUp = 'thumbsUp',
    trash = 'trash',
    trashBin = 'trashBin',
    twitter = 'twitter',
    umbrella = 'umbrella',
    unarchive = 'unarchive',
    undo = 'undo',
    unfollowed = 'unfollowed',
    unlink = 'unlink',
    user = 'user',
    userIcon = 'userIcon',
    verificationCheck = 'verificationCheck',
    verificationQuestion = 'verificationQuestion',
    walkingstick = 'walkingstick',
    wallet = 'wallet',
    warning = 'warning',
    web = 'web',
    navigationControlMeasures = 'navigationControlMeasures',
    navigationInbox = 'navigationInbox',
    navigationControl = 'navigationControl',
    navigationLegalFramework = 'navigationLegalFramework',
    navigationRisks = 'navigationRisks',
    navigationSettings = 'navigationSettings',
    navigationSignaling = 'navigationSignaling',
    navigationSource = 'navigationSource',
    navigationTasks = 'navigationTasks',
    navigationUnion = 'navigationUnion',
    navigationMonitoring = 'navigationMonitoring',
}
