import React from 'react'
import { PaginatableOptions, PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    InternalAlertStatusState,
    InternalAlertsForCustomerDocument,
    InternalAlertsForCustomerQuery,
    InternalAlertsForCustomerQueryVariables,
} from '~/generated/graphql'

interface Props {
    search?: string
    groupIds?: number[]
    sourceIds?: number[]
    children: (
        options: PaginatableOptions<SentBackInternalAlert, InternalAlertsForCustomerQueryVariables>
    ) => JSX.Element
}

export type SentBackInternalAlert = NonNullable<
    NonNullable<InternalAlertsForCustomerQuery['internalAlertsForCustomer']>['nodes']
>[0]

export class PaginatedSentBackInternalAlertsQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, search, groupIds, sourceIds } = this.props

        return (
            <PaginatableQuery<SentBackInternalAlert, InternalAlertsForCustomerQueryVariables>
                take={3}
                variables={{
                    filters: {
                        search,
                        groupIds,
                        sourceIds,
                        status: InternalAlertStatusState.returned,
                    },
                }}
                query={InternalAlertsForCustomerDocument}
            >
                {children}
            </PaginatableQuery>
        )
    }
}
