import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Button } from '~/components/Core/Button/Button'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import {
    DeleteEmployeeDocument,
    DeleteEmployeeMutation,
    DeleteEmployeeMutationVariables,
    EmployeeType,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'
import { CUSTOMER_EMPLOYEES_QUERY } from '~/views/Customer/Settings/Employees/EmployeeOverviewView'

interface Props {
    onDelete?: () => void
    customerSlug: string
    employee: EmployeeType
}

export const DeleteEmployeeButton: React.SFC<Props> = ({ employee: data, customerSlug, onDelete }) => {
    const employee = data || undefined
    const profile = (data.user && data.user.profile) || undefined

    if (!employee.id) {
        return null
    }

    return (
        <GQLMutation<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>
            mutation={DeleteEmployeeDocument}
            variables={{ id: employee.id, customerSlug }}
            refetchQueries={() => [{ query: CUSTOMER_EMPLOYEES_QUERY, variables: { customerSlug } }]}
        >
            {(mutate, { loading }) => (
                <ConfirmModal
                    onConfirm={async closeModal => {
                        const response = await mutate()
                        if (response && response.data?.deleteEmployee) {
                            closeModal()
                            notification.success('Gebruiker verwijderd')

                            if (onDelete) {
                                onDelete()
                            }
                        }
                    }}
                    loading={loading}
                    message={localize.translate(t => t.Customer.Settings.SettingsEmployeeDetailView.confirmDeleteUser, {
                        name: (profile && profile.fullName) || '',
                    })}
                >
                    {openModal => (
                        <Button onClick={openModal} danger={true}>
                            {localize.translate(t => t.Customer.Settings.SettingsEmployeeDetailView.deleteUser)}
                        </Button>
                    )}
                </ConfirmModal>
            )}
        </GQLMutation>
    )
}
