import gql from 'graphql-tag'
import { SeverityLevel } from '~/components/Domain/Compliance/Risks/RiskIndicator'
import { AlertImpactLevel, TaskRepeatFrequency, AlertType, ControlType, TaskType } from '~/generated/graphql'

export enum LinkedTaskItemType {
    alert = 'ALERT',
    control = 'CONTROL',
    risk = 'RISK',
    assessment = 'ASSESSMENT',
    monitoringRapport = 'MONITORING_RAPPORT',
    topic = 'TOPIC',
    radar = 'RADAR',
    consultation = 'CONSULTATION',
}

export enum TaskPriority {
    low = 'LOW',
    medium = 'MEDIUM',
    high = 'HIGH',
}

export interface NewOrExistingTag {
    tagName?: string
    tagId?: number
}

type HighestPrioTask = Pick<TaskType, 'id' | 'priority'>
type NextTask = Pick<TaskType, 'id' | 'dueAt' | 'priority'>

export interface LinkedAlertTask extends BaseTaskItem {
    __typename?: 'AlertType'
    impactLevel?: AlertImpactLevel | null
}

export interface TaskTagFields {
    id: number
    name: string
}

export const TaskTagFields = gql`
    fragment TaskTagFields on TaskTagType {
        id
        name
    }
`

export const TaskEmployeeFields = gql`
    fragment TaskEmployeeFields on EmployeeType {
        id
        user {
            id
            email
            profile {
                id
                fullName
                phoneNumber
                avatar
            }
        }
    }
`

export interface TaskEmployeeFields {
    id: number
    user: {
        id: number
        email: string
        profile: {
            id: number
            fullName: string
            avatar: string | null
            phoneNumber: string | null
        }
    }
}

interface LinkedRiskTask extends BaseTaskItem {
    __typename: 'RiskType'
    severity: SeverityLevel
    isAccepted: boolean
}

interface LinkedAssessmentTask extends BaseTaskItem {
    __typename: 'AssessmentType'
}

interface LinkedMonitoringReportTask extends BaseTaskItem {
    __typename: 'MonitoringReportType'
}

interface LinkedTopicTask extends BaseTaskItem {
    __typename: 'TopicType'
}

type LinkedControlTask = BaseTaskItem & Pick<ControlType, '__typename' | 'revisionDate' | 'type'>

interface LinkedRadarTask extends BaseTaskItem {
    __typename: 'RadarItemType'
    isDeleted: boolean
}

interface LinkedConsultationTask extends BaseTaskItem {
    __typename: 'ConsultationType'
}

export type LinkedTaskItem =
    | LinkedAlertTask
    | LinkedRiskTask
    | LinkedAssessmentTask
    | LinkedMonitoringReportTask
    | LinkedTopicTask
    | LinkedControlTask
    | LinkedRadarTask
    | LinkedConsultationTask

interface BaseTaskItem {
    id: number
    name: string
    highestPrioTask?: HighestPrioTask | null
    nextTask?: NextTask | null
    department?: {
        id: number
    }
}

export interface TaskDetailFields {
    id: number
    name: string
    description: string
    priority: TaskPriority
    startAt?: string
    dueAt?: string
    completedAt: string
    reopenedAt: string
    isReviewTask: boolean
    linkedTasksToReview: TaskDetailFields[]
    linkedAlertsToReview: AlertType[]
    linkedItem: LinkedTaskItem | null
    repeats?: TaskRepeatFrequency | null
    department: {
        id: number
        name: string
    }
    tags: TaskTagFields[]
    employees: TaskEmployeeFields[]
    forMonitoring: boolean
    isSubTask: boolean
    isParentTask: boolean
    totalSubTaskCount: number
    completedSubTaskCount: number
    parentTask?: TaskDetailFields
    subTasks?: TaskDetailFields[]
}

export const getTypenameFromLinkedItemType = (
    linkedItem?: LinkedTaskItem
): { linkedItemId?: number; linkedItemType?: LinkedTaskItemType } => {
    if (!linkedItem) {
        return { linkedItemId: undefined, linkedItemType: undefined }
    }

    if (linkedItem.__typename === 'ControlType') {
        return { linkedItemId: linkedItem.id, linkedItemType: LinkedTaskItemType.control }
    }

    if (linkedItem.__typename === 'AlertType') {
        return { linkedItemId: linkedItem.id, linkedItemType: LinkedTaskItemType.alert }
    }

    if (linkedItem.__typename === 'AssessmentType') {
        return { linkedItemId: linkedItem.id, linkedItemType: LinkedTaskItemType.assessment }
    }

    if (linkedItem.__typename === 'MonitoringReportType') {
        return { linkedItemId: linkedItem.id, linkedItemType: LinkedTaskItemType.monitoringRapport }
    }

    if (linkedItem.__typename === 'RiskType') {
        return { linkedItemId: linkedItem.id, linkedItemType: LinkedTaskItemType.risk }
    }

    if (linkedItem.__typename === 'TopicType') {
        return { linkedItemId: linkedItem.id, linkedItemType: LinkedTaskItemType.topic }
    }

    if (linkedItem.__typename === 'RadarItemType') {
        return { linkedItemId: linkedItem.id, linkedItemType: LinkedTaskItemType.radar }
    }

    if (linkedItem.__typename === 'ConsultationType') {
        return { linkedItemId: linkedItem.id, linkedItemType: LinkedTaskItemType.consultation }
    }

    return { linkedItemId: undefined, linkedItemType: undefined }
}
