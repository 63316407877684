import React from 'react'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { BEM, ClassValue } from '~/services/BEMService'
import './SortHeader.scss'
import { SortDirection } from './Table'

interface Props {
    className?: ClassValue
    sortDirection?: SortDirection
    onSort?: (nextDirection: SortDirection) => void
}

interface State {
    direction: SortDirection
}

export class SortHeader extends React.PureComponent<Props, State> {
    public state: State = {
        direction: this.props.sortDirection ? this.props.sortDirection : null,
    }

    private bem = new BEM('SortHeader', () => ({
        sorted: !!this.state.direction,
        'sorted-asc': this.state.direction === 'ASC',
    }))

    public render() {
        const { children, className } = this.props

        return (
            <button type="button" className={this.bem.getClassName(className)} onClick={this.handleOnButtonClick}>
                {children}
                <div className={this.bem.getElement('sort-direction')}>
                    <Icon type={IconType.arrowDown} className={this.bem.getElement('icon')} />
                </div>
            </button>
        )
    }

    public reset() {
        this.setState({ direction: null })
    }

    private handleOnButtonClick = () => {
        const { onSort } = this.props

        this.setState(
            state => ({
                direction: this.getNextSortDirection(state.direction),
            }),
            () => {
                if (onSort) {
                    onSort(this.state.direction)
                }
            }
        )
    }

    private getNextSortDirection(direction: SortDirection) {
        if (direction === 'ASC' || direction === null) {
            return 'DESC'
        }

        return 'ASC'
    }
}
