import React from 'react'
import { MutationFn } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    DeleteTopicNoteMutationResult,
    DeleteTopicNoteDocument,
    DeleteTopicNoteMutation,
    DeleteTopicNoteMutationVariables,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

export type DeleteTopicNoteMutationFN = MutationFn<DeleteTopicNoteMutation, DeleteTopicNoteMutationVariables>

interface Props {
    topicId: number
    children: (mutate: DeleteTopicNoteMutationFN, result: DeleteTopicNoteMutationResult) => React.ReactNode
}

export class DeleteTopicNote extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<DeleteTopicNoteMutation, DeleteTopicNoteMutationVariables> mutation={DeleteTopicNoteDocument}>
                {(mutate, result) => children(mutate, result)}
            </GQLMutation>
        )
    }
}
