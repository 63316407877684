import React from 'react'
import { MutationFn } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    SetGradeForLinkedTopicControlsMutationResult,
    SetGradeForLinkedTopicControlsDocument,
    SetGradeForLinkedTopicControlsMutation,
    SetGradeForLinkedTopicControlsMutationVariables,
    TopicDesignAndEffectivenessDocument,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

export type SetGradeForLinkedTopicControlsFN = MutationFn<
    SetGradeForLinkedTopicControlsMutation,
    SetGradeForLinkedTopicControlsMutationVariables
>

interface Props {
    topicId: number
    children: (
        mutate: SetGradeForLinkedTopicControlsFN,
        result: SetGradeForLinkedTopicControlsMutationResult
    ) => React.ReactNode
}

export class SetGradeForLinkedTopicControls extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, topicId } = this.props
        const { customer, activeDepartmentId } = this.context

        const refetchVariables = {
            id: topicId,
            customerSlug: customer.slug,
            departmentId: activeDepartmentId,
        }

        return (
            <GQLMutation<SetGradeForLinkedTopicControlsMutation, SetGradeForLinkedTopicControlsMutationVariables>
                mutation={SetGradeForLinkedTopicControlsDocument}
                refetchQueries={[
                    {
                        query: TopicDesignAndEffectivenessDocument,
                        variables: refetchVariables,
                    },
                ]}
            >
                {(mutate, result) => children(mutate, result)}
            </GQLMutation>
        )
    }
}
