import './OptionsButton.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconType } from '../Icon/IconType'
import { Button } from '../Button/Button'

interface Props {
    className?: ClassValue
    onClick: () => void
}

interface State {}

export class OptionsButton extends React.PureComponent<Props, State> {
    private bem = new BEM('OptionsButton')

    public render() {
        const { className, onClick } = this.props

        return <Button icon={IconType.options} className={this.bem.getClassName(className)} onClick={onClick} />
    }
}
