import './TopicAssessmentLinkedControlsTable.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { ColumnOptions, RowData, Table } from '~/components/Core/DataDisplay/Table/Table'
import { ControlLabel } from '~/components/Domain/Compliance/Controls/ControlLabel'
import {
    TopicAssessmentDesignOrEffectiveNessType,
    TopicAssessmentGradeType,
    TopicControlMeasureType,
} from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { TopicAssessmentIcon } from './TopicAssessmentIcon'
import { Row } from '~/components/Core/Layout/Row'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'

interface Props {
    topicAssessmentType: TopicAssessmentDesignOrEffectiveNessType
    topicControlMeasure: TopicControlMeasureType
    newGrade: TopicAssessmentGradeType
    previousGrade: TopicAssessmentGradeType
}

export class TopicAssessmentLinkedControlsTable extends React.PureComponent<Props> {
    private bem = new BEM('TopicAssessmentLinkedControlsTable')
    private columns: ColumnOptions[] = [
        { field: 'control', headerLabel: localize.translate(t => t.Entities.Control) },
        {
            field: 'current',
            headerLabel: localize.translate(
                t => t.Customer.LegalFrameworkView.TopicAssessmentForLinkedControlsModal.current
            ),
            align: 'center',
        },
        { field: 'icon', headerLabel: '', align: 'center' },
        { field: 'new', headerLabel: localize.translate(t => t.Generic.new), align: 'center' },
    ]

    public render() {
        return <Table className={this.bem.getClassName()} columns={this.columns} data={this.getData()} />
    }

    private getData(): RowData[] {
        const { topicControlMeasure } = this.props
        const { linkedChildTopicControlMeasures, linkedParentTopicControlMeasures } = topicControlMeasure
        const { linkedNestedChildTopicControlMeasures, linkedNestedParentTopicControlMeasures } = topicControlMeasure

        const topLevelControls: TopicControlMeasureType[] = []
        const midLevelControls: TopicControlMeasureType[] = []
        const bottomLevelControls: TopicControlMeasureType[] = []

        if (linkedParentTopicControlMeasures?.length) {
            topLevelControls.push(...linkedParentTopicControlMeasures)
            midLevelControls.push(topicControlMeasure)

            if (linkedChildTopicControlMeasures?.length) {
                bottomLevelControls.push(...linkedChildTopicControlMeasures)
            }
        } else if (linkedNestedParentTopicControlMeasures?.length) {
            topLevelControls.push(...linkedNestedParentTopicControlMeasures)
            midLevelControls.push(topicControlMeasure)
        } else {
            topLevelControls.push(topicControlMeasure)

            if (linkedChildTopicControlMeasures?.length) {
                midLevelControls.push(...linkedChildTopicControlMeasures)
            } else if (linkedNestedChildTopicControlMeasures?.length) {
                midLevelControls.push(...linkedNestedChildTopicControlMeasures)
            }
        }

        return [
            ...this.getRowDataForControls(topLevelControls),
            ...this.getRowDataForControls(midLevelControls, 1),
            ...this.getRowDataForControls(bottomLevelControls, 2),
        ]
    }

    private getRowDataForControls(topicControls: TopicControlMeasureType[], indentLevel?: 1 | 2) {
        const { newGrade, topicControlMeasure, topicAssessmentType, previousGrade } = this.props
        const { id } = topicControlMeasure

        return topicControls.map(topicControl => ({
            id: topicControl.id,
            inactive: topicControl.id === id,
            columns: {
                control: (
                    <ControlLabel
                        className={this.bem.getElement('label', () => ({
                            singleIndent: indentLevel === 1,
                            doubleIndent: indentLevel === 2,
                        }))}
                        control={topicControl.control}
                        indentLevel={indentLevel}
                        externalLink={true}
                    />
                ),
                current: (
                    <Row alignCenter={true}>
                        <TopicAssessmentIcon
                            status={
                                topicControl.id === id
                                    ? previousGrade
                                    : topicControl.assessments?.find(
                                          a => a.topicAssessmentDesignOrEffectiveNessType === topicAssessmentType
                                      )?.grade || undefined
                            }
                        />
                    </Row>
                ),
                icon: (
                    <Row alignCenter={true}>
                        <Icon
                            type={IconType.nextArrow}
                            className={this.bem.getElement('icon', () => ({ current: topicControl.id === id }))}
                        />
                    </Row>
                ),
                new: (
                    <Row alignCenter={true}>
                        <TopicAssessmentIcon status={newGrade} />
                    </Row>
                ),
            },
        }))
    }
}
