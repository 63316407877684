import React from 'react'
import { ContextOpenTasksTableContainer } from './ContextOpenTasksTableContainer'
import { ContextClosedTasksTableContainer } from './ContextClosedTasksTableContainer'
import { LinkedTaskItemType } from '~/graphql/types/Task'

interface Props {
    linkedItemId: number
    linkedItemType: LinkedTaskItemType
    linkedItemDepartmentId?: number
    forCustomer?: boolean
    onChange?: () => void
    registerRefetchFunction?: (refetch: any) => void
}

interface State {}

export class ContextTasksContainer extends React.PureComponent<Props, State> {
    public state: State = {
        refetch: false,
    }

    private refetchOpenTasks?: () => void
    private refetchCompletedTasks?: () => void

    constructor(props: Props) {
        super(props)

        if (this.props.registerRefetchFunction) {
            /**
             * This function 'passes' the refetch function to the parent.
             * The way the components are tied together requires us to do this.
             */
            // eslint-disable-next-line no-console
            this.props.registerRefetchFunction(this.refetchAllTasks) // TODO refactor
        }
    }

    public render() {
        const { linkedItemId, linkedItemType, onChange, forCustomer } = this.props

        return (
            <>
                <ContextOpenTasksTableContainer
                    forCustomer={forCustomer}
                    forDepartment={this.props.linkedItemDepartmentId}
                    filters={{
                        completedOnly: false,
                        forLinkedItem: {
                            itemId: linkedItemId,
                            itemType: linkedItemType,
                        },
                    }}
                    onChange={() => {
                        this.refetchCompletedTasks?.()
                        onChange?.()
                    }}
                    registerRefetchFunction={refetch => {
                        this.refetchOpenTasks = refetch
                    }}
                />
                <ContextClosedTasksTableContainer
                    forCustomer={forCustomer}
                    forDepartment={this.props.linkedItemDepartmentId}
                    filters={{
                        completedOnly: true,
                        forLinkedItem: {
                            itemId: linkedItemId,
                            itemType: linkedItemType,
                        },
                    }}
                    onChange={() => {
                        this.refetchOpenTasks?.()
                        onChange?.()
                    }}
                    registerRefetchFunction={refetch => {
                        this.refetchCompletedTasks = refetch
                    }}
                />
            </>
        )
    }

    private refetchAllTasks = () => {
        this.refetchCompletedTasks?.()
        this.refetchOpenTasks?.()
    }
}
