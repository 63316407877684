import React from 'react'
import { MutationFn } from 'react-apollo'
import { localize, notification } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { CreateSubTaskMutation, CreateSubTaskMutationVariables, GetTaskDocument } from '~/generated/graphql'
import { getTypenameFromLinkedItemType, LinkedTaskItem } from '~/graphql/types/Task'
import { EmployeeQuery } from '../../Customer/EmployeePageQuery'
import { CreateSubTask } from '../Mutations/CreateSubTask'
import { CreateTaskFormValues, TaskModal } from '../TaskModal'

interface Props {
    linkedItem?: LinkedTaskItem
    parentTaskName: string
    parentTaskId: number
    requestClose: () => void
    onCreate?: () => void
}

export class CreateSubTaskModalContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { linkedItem, parentTaskName, parentTaskId, requestClose } = this.props
        const title = localize.translate(t => t.Customer.Planning.Tasks.newSubTask)
        const employee = this.context.employee

        if (!employee) {
            return null
        }

        return (
            <EmployeeQuery id={employee.id}>
                {employee => {
                    return (
                        <CreateSubTask parentTaskId={parentTaskId}>
                            {(mutate, { loading }) => (
                                <TaskModal
                                    title={title}
                                    submitLoading={loading}
                                    onSubmit={this.handleOnSubmit(mutate)}
                                    errorPath="createTask"
                                    requestClose={requestClose}
                                    linkedItem={linkedItem}
                                    defaultSelectedEmployee={employee}
                                    isForSubTask={true}
                                    subTaskParentName={parentTaskName}
                                    isNew={true}
                                />
                            )}
                        </CreateSubTask>
                    )
                }}
            </EmployeeQuery>
        )
    }

    private handleOnSubmit = (mutate: MutationFn<CreateSubTaskMutation, CreateSubTaskMutationVariables>) => async (
        values: CreateTaskFormValues
    ) => {
        const { parentTaskId, requestClose, onCreate, linkedItem } = this.props
        const departmentId = this.context.activeDepartmentId
        const { linkedItemId, linkedItemType } = getTypenameFromLinkedItemType(linkedItem)

        const response = await mutate({
            refetchQueries: [
                {
                    query: GetTaskDocument,
                    variables: { taskId: parentTaskId, departmentId },
                },
            ],
            variables: {
                departmentId,
                fields: { ...values, parentId: parentTaskId, linkedItemId, linkedItemType: linkedItemType as any },
            },
        })

        if (response && response.data && response.data.createTask) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))

            if (onCreate) {
                onCreate()
            }

            requestClose()
        }
    }
}
