import './ColorSchemeGrid.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Grid } from '~/components/Core/Layout/Grid'

interface Props {
    className?: ClassValue
}

interface State {}

export class ColorSchemeGrid extends React.PureComponent<Props, State> {
    private bem = new BEM('ColorSchemeGrid')

    public render() {
        const { children, className } = this.props

        return (
            <Grid className={this.bem.getClassName(className)}>
                {children}
            </Grid>
        )
    }
}
