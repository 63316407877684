import React from 'react'

import { ModalManager } from './ModalManager'
import { ButtonType, Button } from '../../Button/Button'
import { Modal } from './Modal'
import { Paragraph } from '../../Typography/Paragraph'
import { localize } from '~/bootstrap'
import { ErrorMessage } from '../Error/ErrorMessage'
import { Column } from '~/components/Core/Layout/Column'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    children: (openModal: () => void) => React.ReactNode
    onCancel?: () => void
    onConfirm?: (closemodal: () => void) => void
    title?: string
    message?: string
    renderContent?: () => React.ReactNode
    loading?: boolean
    confirmButtonType?: ButtonType
    confirmButtonLabel?: string
    errorPath?: string
    className?: ClassValue
}

interface State {}

export class ConfirmModal extends React.Component<Props, State> {
    private loc = localize.namespaceTranslate(t => t.Cover.ConfirmModal)

    private closeButton = React.createRef<Button>()
    private bem = new BEM('ConfirmModal')

    public componentDidMount() {
        document.addEventListener('keyup', this.handleKeyUp)

        if (this.closeButton.current) {
            this.closeButton.current.focus()
        }
    }

    public componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyUp)
    }

    public render() {
        const {
            children,
            renderContent,
            onConfirm,
            onCancel,
            title,
            message,
            loading,
            confirmButtonLabel,
            errorPath,
            className,
            confirmButtonType,
        } = this.props

        return (
            <ModalManager
                render={children}
                renderModal={closeModal => (
                    <Modal
                        requestClose={this.handleClose(closeModal, onCancel)}
                        onAction={onConfirm ? () => onConfirm(closeModal) : undefined}
                        title={title ? (title as string) : this.loc(t => t.title)}
                        loading={loading}
                        confirmButtonLabel={confirmButtonLabel}
                        className={this.bem.getClassName(className)}
                        actionType={confirmButtonType}
                    >
                        <Column>
                            {errorPath && <ErrorMessage path={errorPath} />}
                            {renderContent ? (
                                renderContent()
                            ) : (
                                <Paragraph>{message ? message : this.loc(t => t.areYouSure)}</Paragraph>
                            )}
                        </Column>
                    </Modal>
                )}
            />
        )
    }

    private handleKeyUp = (event: KeyboardEvent): void => {
        // 27 = ESC
        if (event.keyCode !== 27) {
            return
        }

        if (this.props.onCancel) {
            this.props.onCancel()
        }
    }

    private handleClose = (requestClose: () => void, onCancel?: () => void) => () => {
        requestClose()

        if (onCancel) {
            onCancel()
        }
    }
}
