import './TopicDesignAndEffectivenessTableSummary.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { RowData, Table } from '~/components/Core/DataDisplay/Table/Table'
import { BEM } from '~/services/BEMService'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    ControlType,
    LawArticleType,
    TopicAssessmentDesignOrEffectiveNessType,
    TopicAssessmentType,
    TopicControlMeasureType,
    TopicNoteType,
} from '~/generated/graphql'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { TopicAssessmentIcon } from './modals/TopicAssessment/TopicAssessmentIcon'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { getIconTypeForControlType } from '~/utils/controls'
import { routes } from '~/views/routes'

interface Props {
    topicId: number
    items: ItemType[]
}

type ItemType = TopicNote | TopicControlMeasure
type TopicNote = Pick<TopicNoteType, 'id' | 'title'> & {
    assessments?: Assessment[] | null
    articles?: Article[] | null
}
type TopicControlMeasure = Pick<TopicControlMeasureType, 'id'> & {
    control: TopicControl
    assessments?: Assessment[] | null
    articles?: Article[] | null
}
type TopicControl = Pick<ControlType, 'id' | 'name' | 'type'>
type Assessment = Pick<TopicAssessmentType, 'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType'>
type Article = Pick<LawArticleType, 'id' | 'title'>

export class TopicDesignAndEffectivenessTableSummary extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('TopicDesignAndEffectivenessTableSummary')
    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessTable)
    private tableLoc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicOverviewSummaryModal)

    public render() {
        const columns = [
            { field: 'name', headerLabel: this.renderMainHeader() },
            { field: 'articles', headerLabel: this.renderHeader(this.loc(t => t.articlesColumn)) },
            { field: 'design', headerLabel: this.renderHeader(this.loc(t => t.design)) },
            { field: 'effectiveness', headerLabel: this.renderHeader(this.loc(t => t.effectiveness)) },
        ]

        return <Table className={this.bem.getClassName()} columns={columns} data={this.getTableData()} />
    }

    private renderMainHeader() {
        return (
            <Row smallSpacing>
                <Paragraph bold>{this.tableLoc(t => t.dne)}</Paragraph>
                {this.renderShowAllButton()}
            </Row>
        )
    }

    private renderHeader(title: string) {
        return (
            <Paragraph small emphasis>
                {title}
            </Paragraph>
        )
    }

    private getTableData(): RowData[] {
        return this.props.items.map(item => ({
            id: item.id,
            columns: {
                name: this.renderItemName(item),
                articles: this.renderArticleCount(item),
                design: this.renderAssessmentIcon(item, TopicAssessmentDesignOrEffectiveNessType.design),
                effectiveness: this.renderAssessmentIcon(item, TopicAssessmentDesignOrEffectiveNessType.effectiveness),
            },
        }))
    }

    private renderShowAllButton() {
        const topicLink = routes
            .customer(this.context.customer.slug)
            .legalFramework.topic.designAndEffectiveness(this.props.topicId)

        return (
            <Button to={topicLink} type={ButtonType.noStyling}>
                <Paragraph subtle small>
                    {this.tableLoc(t => t.showAll)}
                </Paragraph>
            </Button>
        )
    }

    private renderAssessmentIcon(rowItem: ItemType, dneType: TopicAssessmentDesignOrEffectiveNessType) {
        const assessment = rowItem.assessments?.find(a => a.topicAssessmentDesignOrEffectiveNessType === dneType)?.grade

        return <TopicAssessmentIcon status={assessment || undefined} />
    }

    private renderArticleCount(item: ItemType) {
        const { articles } = item

        return (
            <Paragraph small subtle>
                {this.loc(t => t.articles, { smart_count: articles?.length })}
            </Paragraph>
        )
    }

    private renderItemName(item: ItemType) {
        if ('title' in item) {
            return this.renderIconAndName(IconType.assessmentScribble, item.title)
        }

        return this.renderIconAndName(getIconTypeForControlType(item.control.type), item.control.name)
    }

    private renderIconAndName(icon: IconType, name: string) {
        return (
            <Row smallSpacing>
                <Icon type={icon} />
                <Paragraph small emphasis className={this.bem.getElement('name')}>
                    {name}
                </Paragraph>
            </Row>
        )
    }
}
