import './VariableMatrix.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { flatten, compact, uniqWith, isEqual, debounce } from 'lodash-es'
import { VariableMatrixLines, VariableMatrixLine } from './VariableMatrixLines/VariableMatrixLines'
import Measure, { ContentRect } from 'react-measure'

interface Props {
    className?: ClassValue
    linkWidth?: number
    /** Columns should be in the order you want them displayed */
    columns: VariableMatrixColumn[]
    linesWidth?: number
    itemMargin?: number
}

export interface VariableMatrixColumn {
    name: string
    title: string
    items: VariableMatrixItem[]
    renderEmptyState?: () => React.ReactNode
    renderAddButton?: () => React.ReactNode
}

export interface VariableMatrixItem {
    name: string
    id: number
    renderContent: () => React.ReactNode
    /** Target id's of the items in the next column that this item is connected to */
    targetIds?: number[]
}

interface State {
    itemPosition: {
        columnName: string
        itemId: number
        offsetTop?: number
        height: number
    }[]
    activeColumns?: ActiveColumn[]
}

interface ActiveColumn {
    name: string
    activeItems: VariableMatrixItem[]
}

const LINES_WIDTH = 72
const ITEM_MARGIN = 8

export class VariableMatrix extends React.Component<Props, State> {
    public state: State = {
        itemPosition: [],
        activeColumns: undefined,
    }

    private bem = new BEM('VariableMatrix', () => ({
        'is-hovered': !!this.state.activeColumns?.length,
    }))

    private matrixRef = React.createRef<HTMLDivElement>()

    public render() {
        const { className, columns } = this.props

        return (
            <div ref={this.matrixRef} className={this.bem.getClassName(className)}>
                {columns.map((column, i) => this.renderColumn(column, i))}
            </div>
        )
    }

    private renderColumn(column: VariableMatrixColumn, i: number) {
        const { linesWidth, columns, itemMargin } = this.props

        const shouldRenderLines = columns.length !== i + 1

        if (column.items.length === 0) {
            return (
                <div
                    id={column.name}
                    className={this.bem.getElement('column')}
                    key={`${column.name}-${i}-column-empty`}
                >
                    <div className={this.bem.getElement('column-title')}>{column.title}</div>
                    <div className={this.bem.getElement('column-content-wrapper')}>
                        <div className={this.bem.getElement('item-wrapper')}>
                            <div id={`${column.name}-empty`} className={this.bem.getElement('item')}>
                                {column.renderEmptyState && column.renderEmptyState()}
                            </div>
                        </div>
                            <div
                                style={{ width: linesWidth || LINES_WIDTH }}
                                className={this.bem.getElement('lines-placeholder')}
                                key={`${column.name}-${i}-lines`}
                            />
                    </div>
                </div>
            )
        }

        const lines = this.getLines(column)
        const activeSourceItemIds = this.getActiveColumnIds(column)
        const activeTargetItemIds = this.getActiveTargetColumnIds(i)

        return (
            <div id={column.name} className={this.bem.getElement('column')} key={`${column.name}-${i}-column`}>
                <div className={this.bem.getElement('column-title')}>{column.title}</div>
                <div className={this.bem.getElement('column-content-wrapper')}>
                    <div className={this.bem.getElement('item-wrapper')} onMouseLeave={() => this.handleItemLeave()}>
                        {column.items.map((item, i) => (
                            <Measure
                                key={`${column.name}-${item.name}-${item.id}-${i}-measure`}
                                bounds={true}
                                onResize={contentRect => this.handleItemResize(item, column, contentRect)}
                            >
                                {({ measureRef }) => (
                                    <div
                                        ref={measureRef}
                                        data-itemid={`${item.id}`}
                                        className={this.bem.getElement('item', () => ({
                                            active: !!activeSourceItemIds?.includes(item.id),
                                        }))}
                                        onMouseEnter={() => this.handleItemHover(item, column)}
                                    >
                                        {item.renderContent()}
                                    </div>
                                )}
                            </Measure>
                        ))}
                        {column.renderAddButton && (
                            <div className={this.bem.getElement('add-button')}>{column.renderAddButton()}</div>
                        )}
                    </div>
                    {shouldRenderLines ? (
                        <VariableMatrixLines
                            key={`${column.name}-${i}-lines`}
                            svgWidth={linesWidth || LINES_WIDTH}
                            itemMargin={itemMargin !== undefined ? itemMargin : ITEM_MARGIN}
                            lines={lines}
                            className={this.bem.getElement('lines')}
                            activeSourceItemIds={activeSourceItemIds}
                            activeTargetItemIds={activeTargetItemIds}
                        />
                    ) : (
                        <div
                            style={{ width: linesWidth || LINES_WIDTH }}
                            className={this.bem.getElement('lines-placeholder')}
                            key={`${column.name}-${i}-lines`}
                        />
                    )}
                </div>
            </div>
        )
    }

    private getLines(sourceColumn: VariableMatrixColumn): VariableMatrixLine[] {
        const { columns } = this.props

        const sourceIndex = columns.findIndex(column => column.name === sourceColumn.name)
        const targetColumn = columns[sourceIndex + 1]

        if (!sourceColumn.items.length || !targetColumn?.items.length) {
            return []
        }

        const sourceLines = flatten(
            sourceColumn.items.map((sourceItem, i) => {
                if (!sourceItem.targetIds) {
                    return null
                }

                const sourceItemPosition = this.getItemOffsetTop(sourceColumn.name, sourceItem.id)
                const sourceItemHeight = this.getItemHeight(sourceColumn.name, sourceItem.id)

                // Specifically check for undefined, because the number might be 0
                if (sourceItemPosition === undefined || sourceItemHeight === undefined) {
                    return null
                }

                return sourceItem.targetIds.map(targetId => {
                    const targetItem = targetColumn.items.find(targetItem => targetItem.id === targetId)

                    if (!targetItem) {
                        return null
                    }

                    const targetItemIndex = targetColumn.items.findIndex(item => item.id === targetItem.id)
                    const targetItemPosition = this.getItemOffsetTop(targetColumn.name, targetItem.id)
                    const targetItemHeight = this.getItemHeight(targetColumn.name, targetItem.id)

                    if (targetItemPosition === undefined || targetItemHeight === undefined) {
                        return null
                    }

                    return {
                        sourceItemId: sourceItem.id,
                        sourceItemIndex: i,
                        sourceItemOffsetTop: sourceItemPosition,
                        sourceHeight: sourceItemHeight,
                        targetItemId: targetItem.id,
                        targetItemIndex: targetItemIndex,
                        targetItemOffsetTop: targetItemPosition,
                        targetHeight: targetItemHeight,
                    }
                })
            })
        )

        const allLines = compact(sourceLines)

        // Filter all the identical objects
        return uniqWith(allLines, isEqual)
    }

    private getItemOffsetTop(columnName: string, itemId: number) {
        const { itemPosition } = this.state

        return itemPosition.find(pos => pos.columnName === columnName && pos.itemId === itemId)?.offsetTop
    }

    private getItemHeight(columnName: string, itemId: number) {
        const { itemPosition } = this.state

        return itemPosition.find(pos => pos.columnName === columnName && pos.itemId === itemId)?.height
    }

    private handleItemResize(item: VariableMatrixItem, column: VariableMatrixColumn, contentRect: ContentRect) {
        const updatedItemPositions = this.state.itemPosition.slice()

        const updatedItemIndex = updatedItemPositions.findIndex(
            itemPosition => itemPosition.itemId === item.id && itemPosition.columnName === column.name
        )

        const oldItemPosition = this.state.itemPosition[updatedItemIndex]

        if (oldItemPosition) {
            updatedItemPositions[updatedItemIndex].height = contentRect.bounds?.height || 0
            updatedItemPositions[updatedItemIndex].offsetTop = contentRect.offset?.top || 0
        } else {
            updatedItemPositions.push({
                columnName: column.name,
                itemId: item.id,
                height: contentRect.bounds?.height || 0,
            })
        }

        this.setState(
            {
                itemPosition: updatedItemPositions,
            },
            () => this.recalculateColumnItemPositions(column.name)
        )
    }

    // tslint:disable-next-line:member-ordering
    private handleItemHover = debounce((hoveredItem: VariableMatrixItem, hoveredColumn: VariableMatrixColumn) => {
        const { columns } = this.props

        const currentColomnIndex = columns.findIndex(column => hoveredColumn.name === column.name)

        const activeColumns: ActiveColumn[] = [
            {
                name: hoveredColumn.name,
                activeItems: [hoveredItem],
            },
        ]

        const previousColumns = [...columns.slice(0, currentColomnIndex)].reverse()

        previousColumns.forEach((prevColumn, i) => {
            const targetColumn = i === 0 ? hoveredColumn : previousColumns[i - 1]

            const activeTargetItems = activeColumns.find(activeColumn => activeColumn.name === targetColumn.name)
                ?.activeItems

            if (!activeTargetItems) {
                return
            }

            const activeItems: VariableMatrixItem[] = []

            prevColumn.items.forEach(prevItem => {
                if (!prevItem.targetIds) {
                    return
                }

                const itemIsSource = prevItem.targetIds.some(targetId =>
                    activeTargetItems.find(activeItem => activeItem.id === targetId)
                )

                if (itemIsSource) {
                    activeItems.push(prevItem)
                }
            })

            activeColumns.push({
                name: prevColumn.name,
                activeItems,
            })
        })

        // The columns after the column of the hovered item
        const nextColumns = columns.slice(currentColomnIndex + 1)

        nextColumns.forEach((nextColumn, i) => {
            const sourceColumn = i === 0 ? hoveredColumn : nextColumns[i - 1]

            const activeSourceItems = activeColumns.find(activeColumn => activeColumn.name === sourceColumn.name)
                ?.activeItems

            if (!activeSourceItems) {
                return
            }

            const activeItems: VariableMatrixItem[] = []
            activeSourceItems.forEach(activeSourceItem => {
                activeSourceItem.targetIds?.forEach(targetId => {
                    const activeNextItem = nextColumn.items.find(nextItem => targetId === nextItem.id)

                    if (activeNextItem) {
                        activeItems.push(activeNextItem)
                    }
                })
            })

            activeColumns.push({
                name: nextColumn.name,
                activeItems,
            })
        })

        this.setState({
            activeColumns,
        })
    }, 100)

    // tslint:disable-next-line:member-ordering
    private handleItemLeave = debounce(() => {
        this.setState({
            activeColumns: undefined,
        })
    }, 100)

    private getActiveTargetColumnIds = (columnIndex: number) => {
        const { activeColumns } = this.state

        if (!activeColumns) {
            return undefined
        }

        const { columns } = this.props
        const targetColumn = columns[columnIndex + 1]

        if (!targetColumn) {
            return undefined
        }

        return this.getActiveColumnIds(targetColumn)
    }

    private getActiveColumnIds = (column: VariableMatrixColumn) => {
        const { activeColumns } = this.state

        const activeColumn = activeColumns?.find(activeColumn => activeColumn.name === column.name)

        if (!activeColumn) {
            return undefined
        }

        return activeColumn.activeItems.map(activeItem => activeItem.id)
    }

    private recalculateColumnItemPositions(columnName: string) {
        const { itemPosition } = this.state

        const columnItems = document.querySelectorAll(`#${columnName} .rf-VariableMatrix__item`)
        const updatedItemPositions = itemPosition.slice()

        columnItems.forEach(item => {
            const offset = (item as HTMLDivElement).offsetTop

            const dataAttributeItemId = item.getAttribute('data-itemId')

            if (!dataAttributeItemId) {
                return
            }

            const itemId = parseInt(dataAttributeItemId, 10)
            const updatedItemIndex = updatedItemPositions.findIndex(
                pos => pos.columnName === columnName && pos.itemId === itemId
            )

            if (updatedItemIndex === -1) {
                return
            }

            updatedItemPositions[updatedItemIndex].offsetTop = offset
        })

        this.setState({
            itemPosition: updatedItemPositions,
        })
    }
}
