import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { NewsItemType, SignalingDetailQueryDocument } from '~/generated/graphql'
import { PageQuery, PageQueryOptions } from '../PageQuery/PageQuery'

interface Props {
    newsItemId: number
    children: (newsItem: NewsItemType, options: PageQueryOptions) => React.ReactNode
}

export class SignalingDetailQuery extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, newsItemId } = this.props
        return (
            <PageQuery<NewsItemType>
                query={SignalingDetailQueryDocument}
                variables={{
                    id: newsItemId,
                    customerSlug: this.context.customer.slug,
                }}
            >
                {(data, options) => children(data, options)}
            </PageQuery>
        )
    }
}
