import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import {
    EditConsultantTaskTemplateMutation,
    EditConsultantTaskTemplateMutationVariables,
    EditConsultantTaskTemplateDocument,
} from '~/generated/graphql'

interface Props {
    children: (mutation: EditConsultantTaskTemplateMutationFN, options: MutationResult) => JSX.Element
}

export type EditConsultantTaskTemplateMutationFN = MutationFn<
    EditConsultantTaskTemplateMutation,
    EditConsultantTaskTemplateMutationVariables
>

export class EditConsultantTaskTemplate extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<EditConsultantTaskTemplateMutation, EditConsultantTaskTemplateMutationVariables>
                mutation={EditConsultantTaskTemplateDocument}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
