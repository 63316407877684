import ApolloClient from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import gql from 'graphql-tag'

interface RequestFileDownloadTokenQueryResponse {
    requestFileDownloadToken: string
}

const REQUEST_FILE_DOWNLOAD_TOKEN = gql`
    mutation requestFileDownloadToken($fileId: Int!, $type: String!) {
        requestFileDownloadToken(fileId: $fileId, type: $type)
    }
`

export enum FileType {
    monitoringReport = 'MonitoringReportFile',
    controlDocument = 'ControlDocumentFile',
    taskMotivation = 'TaskMotivationFile',
    assessmentNoteFile = 'AssessmentNoteFile',
    radarChangeExport = 'RadarChangeExportFile',
    topicNoteFile = 'TopicNoteFile',
    topicAssessmentFile = 'TopicAssessmentFile',
}

export class FileService {
    private apolloClient: ApolloClient<NormalizedCacheObject>
    private baseurl = window.ENVIRONMENT.NAME !== 'development' ? '' : `http://${window.location.hostname}:5000`

    constructor(apolloClient: ApolloClient<NormalizedCacheObject>) {
        this.apolloClient = apolloClient
    }

    public async viewFile(fileId: number, filename: string, type: FileType): Promise<void> {
        const token = await this.requestFileToken(fileId, type)

        if (!token) {
            return
        }

        window.open(this.createFileUrlFromToken(token, filename), '_blank')
    }

    public async createFileUrl(fileId: number, filename: string, type: FileType): Promise<string | void> {
        const token = await this.requestFileToken(fileId, type)

        if (!token) {
            return
        }

        return this.createFileUrlFromToken(token, filename)
    }

    public createFileUrlFromToken(token: string, filename: string) {
        return `${this.baseurl}/file/${token}/${filename}`
    }

    public async downloadFile(fileId: number, filename: string, type: FileType): Promise<void> {
        const token = await this.requestFileToken(fileId, type)

        if (!token) {
            return
        }

        window.location.href = `${this.createFileUrlFromToken(token, filename)}?asDownload=true`
    }

    private async requestFileToken(fileId: number, type: FileType): Promise<string | null> {
        try {
            const response = await this.apolloClient.mutate<RequestFileDownloadTokenQueryResponse>({
                mutation: REQUEST_FILE_DOWNLOAD_TOKEN,
                variables: { fileId, type },
            })

            if (!response || !response.data || !response.data.requestFileDownloadToken) {
                return null
            }

            return response.data.requestFileDownloadToken
        } catch (err) {
            return null
        }
    }
}
