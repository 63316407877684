import React from 'react'

import { RouteComponentProps, Switch, Route, Redirect } from 'react-router'
import { routes } from '~/views/routes'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { Page } from '~/components/Core/Layout/Page'
import { CustomerDetailView } from './CustomerDetailView'
import { CustomerDetailEditView } from './CustomerDetailEditView'
import { ConsultantOverviewView } from '../Consultant/ConsultantOverviewView'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import gql from 'graphql-tag'
import { LoginToEnvironmentButton } from '~/components/Chrome/LoginToEnvironmentButton/LoginToEnvironmentButton'
import { PageNavigation } from '~/components/Core/Layout/PageNavigation'
import { breadcrumbs } from '~/views/breadcrumbs'

interface Props extends RouteComponentProps<{ id?: string }> {}

const CUSTOMER_QUERY = gql`
    query customer($id: Int!) {
        customer(id: $id) {
            id
            name
            slug
        }
    }
`

export class CustomerDetailOverviewView extends React.PureComponent<Props> {
    public render() {
        const id = parseInt(this.props.match.params.id!, 10)

        return (
            <PageQuery query={CUSTOMER_QUERY} variables={{ id }}>
                {customer => {
                    const navlinks = [
                        {
                            label: 'Gegevens',
                            route: routes.consultant.customers.profile(customer.id),
                        },
                        {
                            label: 'Consultants',
                            route: routes.consultant.customers.consultants(customer.id),
                        },
                    ]

                    return (
                        <Page>
                            <PageNavigation
                                links={navlinks}
                                renderAction={() => <LoginToEnvironmentButton slug={customer.slug} />}
                                breadCrumbs={[breadcrumbs.consultant().customers.index]}
                            />
                            <Switch>
                                <Route
                                    path={routes.consultant.customers.profile()}
                                    component={CustomerDetailView}
                                    exact={true}
                                />
                                <Route
                                    path={routes.consultant.customers.edit()}
                                    component={CustomerDetailEditView}
                                    exact={true}
                                />
                                <Route
                                    path={routes.consultant.customers.consultants()}
                                    component={ConsultantOverviewView}
                                    exact={true}
                                />
                                <Redirect
                                    path={routes.consultant.customers.view()}
                                    to={routes.consultant.customers.profile()}
                                    exact={true}
                                />
                                <Route component={AppNotFoundView} />
                            </Switch>
                        </Page>
                    )
                }}
            </PageQuery>
        )
    }
}
