import React from 'react'
import { Query } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    TopicControlMeasureArticlesDocument,
    TopicControlMeasureArticlesQuery,
    TopicControlMeasureArticlesQueryResult,
    TopicControlMeasureArticlesQueryVariables,
} from '~/generated/graphql'

interface Props {
    id: number
    departmentId: number
    children: (data: TopicControlMeasureArticlesQueryResult) => React.ReactNode
}

export class TopicControlMeasureArticles extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, id, departmentId } = this.props

        return (
            <Query<TopicControlMeasureArticlesQuery, TopicControlMeasureArticlesQueryVariables>
                query={TopicControlMeasureArticlesDocument}
                variables={{ id, departmentId }}
            >
                {children}
            </Query>
        )
    }
}
