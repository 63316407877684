import './RulerEmployeeWarning.scss'

import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { Logo } from '~/components/Chrome/Logo/Logo'
import { Attention } from '~/components/Core/Feedback/Attention/Attention'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export class RulerEmployeeWarning extends React.PureComponent<Props> {
    private bem = new BEM('RulerEmployeeWarning')
    private loc = localize.namespaceTranslate(t => t.User)

    public render() {
        if (permissions.isConsultantUser() || permissions.isCustomerConsultant()) {
            return null
        }

        const { className } = this.props

        return (
            <Attention
                attentionText={this.renderText()}
                noIcon={true}
                blueBackground={true}
                className={this.bem.getClassName(className)}
            />
        )
    }

    private renderText() {
        return (
            <Column smallSpacing={true} className={this.bem.getElement('text-container')}>
                <Row smallSpacing={true}>
                    <Logo iconOnly={true} className={this.bem.getElement('logo')} />
                    <Paragraph bold={true}>{this.loc(t => t.rulerEmployee)}</Paragraph>
                </Row>
                <Paragraph>{this.loc(t => t.rulerEmployeeWarning)}</Paragraph>
            </Column>
        )
    }
}
