import React from 'react'
import { localize } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Control } from '~/components/Domain/Compliance/Controls/ControlDetail/ControlQuery'
import { ControlVisibility } from '~/generated/graphql'
import forEach from 'lodash-es/forEach'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Column } from '~/components/Core/Layout/Column'
import { Markdown } from '~/components/Core/Text/Markdown'

interface Props {
    control: Control
    activeDepartmentId: number
}

interface State {}

export class ControlDetailGeneralFields extends React.PureComponent<Props, State> {
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.DetailView)

    public render() {
        const { control } = this.props

        return (
            <Column>
                <Field label={this.loc(t => t.description)}>
                    <Markdown paragraphLike source={control.description} />
                </Field>
                <Field label={this.loc(t => t.responsibleEmployees)}>
                    {this.getEmployeeAndShadowEmployeeNames(control)}
                </Field>
                <Field label={this.loc(t => t.controlType)}>
                    <Paragraph>{localize.translate(t => t.Control.type[control.type])}</Paragraph>
                </Field>
                <Field label={localize.translate(t => t.Entities.Department)}>
                    <Paragraph>{control.department.name}</Paragraph>
                </Field>
                {this.isControlOwnedByActiveDepartment() && this.renderVisibilityInformation()}
            </Column>
        )
    }

    private isControlOwnedByActiveDepartment() {
        const { control, activeDepartmentId } = this.props

        return control.department.id === activeDepartmentId
    }

    private renderVisibilityInformation() {
        const { control } = this.props

        return (
            <>
                <Field label={this.loc(t => t.visibility)}>
                    <Paragraph>{localize.translate(t => t.Control.Visibility[control.visibility])}</Paragraph>
                </Field>

                {control.visibility === ControlVisibility.selectionOfDepartments && (
                    <Field label={this.loc(t => t.visibleToDepartments)}>
                        <Paragraph>
                            {control.visibleToDepartments?.map(department => department.name).join(', ')}
                        </Paragraph>
                    </Field>
                )}
            </>
        )
    }

    private getEmployeeAndShadowEmployeeNames(control: Control) {
        const names: string[] = []

        if (control.responsibleEmployees) {
            forEach(control.responsibleEmployees, employee => {
                names.push(employee.user.profile.fullName)
            })
        }

        if (control.responsibleShadowEmployees) {
            forEach(control.responsibleShadowEmployees, employee => {
                names.push(employee.name || '')
            })
        }

        if (names.length === 1) {
            return names[0]
        }

        return (
            <Tooltip content={`${names.join(', ')}`}>
                <span>{this.loc(t => t.amountOfResponsibleEmployees, { amount: names.length })}</span>
            </Tooltip>
        )
    }
}
