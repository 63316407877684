import React from 'react'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize, notification } from '~/bootstrap'
import gql from 'graphql-tag'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationFn } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ASSESSMENT_SECTIONS_QUERY } from './AssessmentSections/AssessmentSectionsInfiniteScrollQuery'

interface Props {
    assessmentId: number
    assessmentSectionId: number
    normId: number
    afterDelete?: () => void
}

interface State {}

const DELETE_NORM_FROM_ASSESSMENT_MUTATION = gql`
    mutation deleteNormFromAssessmentSection($assessmentSectionId: Int!, $normId: Int!) {
        deleteNormFromAssessmentSection(assessmentSectionId: $assessmentSectionId, normId: $normId) {
            id
        }
    }
`

interface DeleteNormFromAssessmentResponse {
    deleteNormFromAssessmentSection: {
        id: number
    }
}

interface DeleteNormFromAssessmentVariables {
    normId: number
    assessmentSectionId: number
}

export class UnlinkNormFromAssessmentButton extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public loc = localize.namespaceTranslate(t => t.Customer.Compliance.Assessments.UnlinkNormFormAssessmentButton)

    public render() {
        const { assessmentId } = this.props

        const refetchQueries = [
            {
                query: ASSESSMENT_SECTIONS_QUERY,
                variables: {
                    assessmentId,
                    departmentId: this.context.activeDepartmentId,
                },
            },
        ]

        return (
            <GQLMutation<DeleteNormFromAssessmentResponse, DeleteNormFromAssessmentVariables>
                mutation={DELETE_NORM_FROM_ASSESSMENT_MUTATION}
                refetchQueries={refetchQueries}
            >
                {deleteMutate => (
                    <ConfirmModal
                        onConfirm={() => this.onDelete(deleteMutate)}
                        title={this.loc(t => t.delete)}
                        message={this.loc(t => t.deleteConfirmMessage)}
                    >
                        {openModal => (
                            <Button danger={true} type={ButtonType.secondary} onClick={openModal}>
                                {this.loc(t => t.delete)}
                            </Button>
                        )}
                    </ConfirmModal>
                )}
            </GQLMutation>
        )
    }

    private onDelete = async (
        mutate: MutationFn<DeleteNormFromAssessmentResponse, DeleteNormFromAssessmentVariables>
    ) => {
        const { afterDelete, assessmentSectionId, normId } = this.props

        const response = await mutate({
            variables: {
                assessmentSectionId,
                normId,
            },
        })

        if (response && response.data && response.data.deleteNormFromAssessmentSection) {
            if (afterDelete) {
                afterDelete()
            }
            notification.success(this.loc(t => t.deleteSuccess))
        }
    }
}
