import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import {
    CreateConsultantTaskTemplateMutation,
    CreateConsultantTaskTemplateMutationVariables,
    CreateConsultantTaskTemplateDocument,
} from '~/generated/graphql'

interface Props {
    children: (
        mutation: MutationFn<CreateConsultantTaskTemplateMutation, CreateConsultantTaskTemplateMutationVariables>,
        options: MutationResult
    ) => JSX.Element
}

export type CreateConsultantTaskTemplateMutationFN = MutationFn<
    CreateConsultantTaskTemplateMutation,
    CreateConsultantTaskTemplateMutationVariables
>

export class CreateConsultantTaskTemplate extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<CreateConsultantTaskTemplateMutation, CreateConsultantTaskTemplateMutationVariables>
                mutation={CreateConsultantTaskTemplateDocument}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
