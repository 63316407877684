import React from 'react'
import { localize } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { DepartmentType, InboxStatusType, TaskType } from '~/generated/graphql'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import { ItemCustomerInfoSummary } from '../Customer/ItemCustomerInfoSummary'

interface Props {
    alert: InboxSummaryItemType
}

export interface InboxSummaryItemType {
    customerFrameworks: CustomerFramework[]
    allPresentDepartments: Pick<DepartmentType, 'id' | 'name'>[]
    inboxStatus?: InboxStatus | null
    firstTask?: Task | null
}

type InboxStatus = Pick<InboxStatusType, 'archivedAt' | 'archivedBy'>
type Task = Pick<TaskType, 'createdAt' | 'createdBy'>

export class InboxItemInfoSummary extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Customer.Inbox.InboxOverview)

    public render() {
        const { inboxStatus, firstTask, customerFrameworks, allPresentDepartments } = this.props.alert

        return (
            <ItemCustomerInfoSummary
                item={{ customerFrameworks, departments: allPresentDepartments }}
                metadata={inboxStatus?.archivedAt || firstTask ? [this.renderTaskAndArchivedInfo()] : undefined}
            />
        )
    }

    private renderTaskAndArchivedInfo() {
        const { inboxStatus, firstTask } = this.props.alert

        return (
            <Column>
                {firstTask && this.renderTaskInfo(firstTask)}
                {inboxStatus?.archivedAt && this.renderArchiveInfo(inboxStatus)}
            </Column>
        )
    }

    private renderTaskInfo(firstTask: Task) {
        const firstTaskDate = firstTask.createdAt
        const firstTaskBy = firstTask.createdBy?.user.profile.fullName
        const firstTaskText = this.loc(t => (firstTaskBy ? t.firstTaskBy : t.firstTaskOn))

        return this.renderMetadata(firstTaskText, firstTaskDate, firstTaskBy)
    }

    private renderArchiveInfo(inboxStatus: InboxStatus) {
        const archivedAt = inboxStatus.archivedAt!
        const archivedByName = inboxStatus?.archivedBy?.user.profile.fullName
        const archivedByText = this.loc(t => (archivedByName ? t.archivedBy : t.archivedOn))

        return this.renderMetadata(archivedByText, archivedAt, archivedByName)
    }

    private renderMetadata(text: string, date: Date, userName: string | undefined) {
        return (
            <Row extraSmallSpacing={true}>
                <Paragraph>{text}</Paragraph>
                <UserProfileIcon small={true} fullName={userName || ''} />
                <Paragraph subtle={true}>
                    {localize.dateFormat(new Date(date), { includeTime: true, noWeekday: true })}
                </Paragraph>
            </Row>
        )
    }
}
