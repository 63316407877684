import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    CreateTaskTemplateMutation,
    CreateTaskTemplateMutationVariables,
    CreateTaskTemplateDocument,
} from '~/generated/graphql'

interface Props {
    children: (
        mutation: MutationFn<CreateTaskTemplateMutation, CreateTaskTemplateMutationVariables>,
        options: MutationResult
    ) => JSX.Element
}

export type CreateTaskTemplateMutationFN = MutationFn<CreateTaskTemplateMutation, CreateTaskTemplateMutationVariables>

export class CreateTaskTemplate extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<CreateTaskTemplateMutation, CreateTaskTemplateMutationVariables>
                mutation={CreateTaskTemplateDocument}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
