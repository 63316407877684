import React from 'react'
import { MutationFn } from 'react-apollo'
import {
    CreateCustomerDocument,
    CreateCustomerMutation,
    CreateCustomerMutationResult,
    CreateCustomerMutationVariables,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

export type CreateCustomerMutationFN = MutationFn<CreateCustomerMutation, CreateCustomerMutationVariables>

interface Props {
    children: (mutate: CreateCustomerMutationFN, result: CreateCustomerMutationResult) => JSX.Element
}

export class CreateCustomer extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<CreateCustomerMutation, CreateCustomerMutationVariables> mutation={CreateCustomerDocument}>
                {(mutate, result) => children(mutate, result)}
            </GQLMutation>
        )
    }
}
