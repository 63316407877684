import './TopicDesignAndEffectivenessSummaryTooltip.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { DepartmentTopicDataMetadataType, TopicAssessmentGradeType } from '~/generated/graphql'
import { TopicAssessmentIcon } from './modals/TopicAssessment/TopicAssessmentIcon'
import { BEM } from '~/services/BEMService'

interface Props {
    title: string
    data?: DepartmentTopicDataMetadataType | null
    children: JSX.Element
}

export class TopicDesignAndEffectivenessSummaryTooltip extends React.PureComponent<Props> {
    private bem = new BEM('TopicDesignAndEffectivenessSummaryTooltip')
    private loc = localize.namespaceTranslate(
        t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessSummaryTooltip
    )
    public render() {
        const { children } = this.props

        return (
            <Tooltip content={this.renderContent()} direction={ToolTipDirection.down}>
                {children}
            </Tooltip>
        )
    }

    private renderContent() {
        const { title } = this.props

        return (
            <Column className={this.bem.getClassName()}>
                <Paragraph small={true} bold={true}>
                    {title}
                </Paragraph>
                {this.renderAssessmentCounts()}
                {this.renderLinkedItemCounts()}
                {this.renderLastUpdatedDate()}
            </Column>
        )
    }

    private renderAssessmentCounts() {
        const assessmentCounts = this.getAssessmentCounts()
        const { assessmentAlmostSatisfies, assessmentNotGiven, assessmentNotSatisfies } = assessmentCounts
        const { assessmentSatisfies } = assessmentCounts

        return (
            <Row fullWidth={true} spaceBetween={true} smallSpacing={true}>
                {this.renderAssessmentCount(TopicAssessmentGradeType.assessmentSatisfies, assessmentSatisfies)}
                {this.renderAssessmentCount(
                    TopicAssessmentGradeType.assessmentAlmostSatisfies,
                    assessmentAlmostSatisfies
                )}
                {this.renderAssessmentCount(TopicAssessmentGradeType.assessmentNotSatisfies, assessmentNotSatisfies)}
                {this.renderAssessmentCount(TopicAssessmentGradeType.assessmentNotGiven, assessmentNotGiven)}
            </Row>
        )
    }

    private renderLinkedItemCounts() {
        const { data } = this.props

        return (
            <Column extraSmallSpacing={true}>
                <Paragraph small={true} bold={true}>
                    {this.loc(t => t.linkedControls, { smart_count: data?.linkedControlMeasureCount ?? 0 })}
                </Paragraph>
                <Paragraph small={true} bold={true}>
                    {this.loc(t => t.linkedNotes, { smart_count: data?.linkedNoteCount ?? 0 })}
                </Paragraph>
            </Column>
        )
    }

    private renderLastUpdatedDate() {
        const { data } = this.props

        if (!data?.lastUpdatedAt) {
            return
        }

        const lastUpdated = localize.translate(t => t.Customer.LegalFrameworkView.TopicAssessmentModal.lastUpdatedAt, {
            date: localize.dateFormat(new Date(data.lastUpdatedAt), { noWeekday: true }),
        })

        return (
            <Paragraph small={true} subtle={true}>
                {lastUpdated}
            </Paragraph>
        )
    }

    private renderAssessmentCount(grade: TopicAssessmentGradeType, count: number) {
        return (
            <Row smallSpacing={true}>
                <TopicAssessmentIcon status={grade} />
                {/* tslint:disable jsx-use-translation-function */}
                <Paragraph small={true}>{count}x</Paragraph>
            </Row>
        )
    }

    private getAssessmentCounts() {
        const { data } = this.props

        if (!data?.assessmentCounts) {
            return {
                assessmentSatisfies: 0,
                assessmentAlmostSatisfies: 0,
                assessmentNotSatisfies: 0,
                assessmentNotGiven: 0,
            }
        }

        return data.assessmentCounts
    }
}
