import React from 'react'
import { PaginatableOptions, PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    DepartmentInternalAlertLogsDocument,
    DepartmentInternalAlertLogsQueryVariables,
    DepartmentInternalAlertLogsQuery,
} from '~/generated/graphql'

interface Props {
    internalAlertId: number
    take?: number
    children: (
        options: PaginatableOptions<DepartmentInternalAlertLogsType, DepartmentInternalAlertLogsQueryVariables>
    ) => JSX.Element
}

type DepartmentInternalAlertLogsType = NonNullable<
    NonNullable<DepartmentInternalAlertLogsQuery['departmentInternalAlertLogs']>['nodes']
>[0]

export class DepartmentInternalAlertLogs extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, take, internalAlertId } = this.props

        return (
            <PaginatableQuery<DepartmentInternalAlertLogsType, DepartmentInternalAlertLogsQueryVariables>
                path="departmentInternalAlertLogs"
                take={take}
                query={DepartmentInternalAlertLogsDocument}
                variables={{
                    internalAlertId,
                    departmentId: this.context.activeDepartmentId,
                }}
            >
                {options => children(options)}
            </PaginatableQuery>
        )
    }
}
