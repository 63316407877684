import React from 'react'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { Form } from '~/components/Core/DataEntry/Form/Form'

interface Props {
    onChange: (option: SelectOption) => void
    placeholder?: string
    defaultValue?: SelectOption[]
    isFilter?: boolean
    name?: string
    options?: SelectOption[]
}

export class TaskControlLinkedTypeSelect extends React.Component<Props> {
    public render() {
        const { onChange, placeholder, defaultValue, isFilter, name, options } = this.props

        return (
            <Form.Select
                name={name || 'linkedType'}
                defaultValue={defaultValue}
                searchable={false}
                options={options}
                placeholder={placeholder}
                clearable={false}
                onChange={onChange}
                isFilter={isFilter}
            />
        )
    }
}
