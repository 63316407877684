import './ActionBar.scss'

import React from 'react'
import { MakeRoomForChat } from '~/components/Chrome/ChatPlaceholder/MakeRoomForChat'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

interface State {}

export class ActionBar extends React.PureComponent<Props, State> {
    private bem = new BEM('ActionBar')

    public render() {
        const { children, className } = this.props

        return (
            <>
                <div className={this.bem.getElement('spacer')} />
                <MakeRoomForChat>
                    {heightMeasurementRef => (
                        <div ref={heightMeasurementRef} className={this.bem.getClassName(className)}>
                            {children}
                        </div>
                    )}
                </MakeRoomForChat>
            </>
        )
    }
}
