import React from 'react'
import gql from 'graphql-tag'
import { TaskEmployeeFields, LinkedTaskItem, LinkedTaskItemType } from '~/graphql/types/Task'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { PaginatableQuery, PaginatableOptions } from '~/components/Core/Pagination/PaginatableQuery'
import { SortDirection } from '~/components/Core/DataDisplay/Table/Table'

export interface GroupedTask {
    id: string
    open: number
    closed: number
    overDue: number
    firstDueAt?: string
    linkedItem?: LinkedTaskItem
    employees: TaskEmployeeFields[]
}

export interface GroupedTasksQueryVariables {
    departmentId: number
}

const GROUPED_TASKS_QUERY = gql`
    query itemsWithTasks(
        $skip: Int
        $take: Int
        $departmentId: Int!
        $filters: ItemWithTaskFiltersInputType
        $sort: ItemWithTaskSortInputType
    ) {
        itemsWithTasks(skip: $skip, take: $take, departmentId: $departmentId, filters: $filters, sort: $sort) {
            count
            totalCount
            hasNextPage
            nodes {
                id
                open
                closed
                overDue
                firstDueAt
                linkedItem {
                    ... on AlertType {
                        __typename
                        id
                        name
                        impactLevel(departmentId: $departmentId)
                    }
                    ... on ControlType {
                        __typename
                        id
                        name
                        type
                        revisionDate
                    }
                    ... on RiskType {
                        __typename
                        id
                        name
                        severity
                        isAccepted
                    }
                    ... on AssessmentType {
                        __typename
                        id
                        name
                    }
                    ... on MonitoringReportType {
                        __typename
                        id
                        name
                    }
                    ... on TopicType {
                        __typename
                        id
                        name
                    }
                    ... on RadarItemType {
                        __typename
                        id
                        name
                        isDeleted
                    }
                    ... on ConsultationType {
                        __typename
                        id
                        name
                    }
                }
                employees {
                    ...TaskEmployeeFields
                }
            }
        }
    }
    ${TaskEmployeeFields}
`

export interface GroupedTasksQueryFilters {
    includingOverdueTasks?: boolean
    search?: string
    forEmployeeIds?: number[]
    linkedItemType?: LinkedTaskItemType
    activeDepartmentOnly?: boolean
    linkedItemInboxFromDate?: Date
    linkedItemInboxToDate?: Date
    completedOnly?: boolean
}

export interface GroupedTasksQuerySort {
    openTasks?: SortDirection
    firstDueAt?: SortDirection
}

interface Props {
    children: (options: PaginatableOptions<GroupedTask>) => JSX.Element
    filters?: GroupedTasksQueryFilters
    sort?: GroupedTasksQuerySort
}

export class GroupedTasksQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, filters, sort } = this.props
        const departmentId = filters?.activeDepartmentOnly ? this.context.activeDepartmentId : undefined

        const queryFilters = {
            includingOverdueTasks: filters?.includingOverdueTasks,
            search: filters?.search,
            forEmployeeIds: filters?.forEmployeeIds,
            linkedItemType: filters?.linkedItemType,
            linkedItemInboxFromDate: filters?.linkedItemInboxFromDate,
            linkedItemInboxToDate: filters?.linkedItemInboxToDate,
            showCompletedOnly: filters?.completedOnly,
            departmentId,
        }

        return (
            <PaginatableQuery
                query={GROUPED_TASKS_QUERY}
                variables={{
                    departmentId: this.context.activeDepartmentId,
                    filters: queryFilters,
                    sort,
                }}
            >
                {options => children(options)}
            </PaginatableQuery>
        )
    }
}
