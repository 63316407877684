import './TextEditor.scss'
import React, { Component } from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js'
import List from '@ckeditor/ckeditor5-list/src/list.js'
import ListProperties from '@ckeditor/ckeditor5-list/src/listproperties'
import Link from '@ckeditor/ckeditor5-link/src/link.js'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js'
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript.js'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript.js'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js'
import { localize } from '~/bootstrap'
import Table from '@ckeditor/ckeditor5-table/src/table.js'
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import { marked } from 'marked'

interface Props {
    className?: ClassValue
    onChange?: (value: string | null, name: string) => void
    name: string
    defaultValue?: string | null
    placeholder?: string
}

interface State {
    editorState: string
}

export class TextEditor extends Component<Props, State> {
    public state: State = {
        editorState: marked(this.props.defaultValue || ''),
    }

    private bem = new BEM('TextEditor')
    private builtinPlugins = [
        Bold,
        Essentials,
        Italic,
        List,
        Paragraph,
        Subscript,
        Superscript,
        Underline,
        Table,
        ListProperties,
        Autoformat,
        Link,
    ]
    private toolbarItems = [
        'bold',
        'italic',
        'underline',
        'subscript',
        'superscript',
        '|',
        'numberedList',
        'bulletedList',
        '|',
        'link',
    ]

    public render() {
        const { className, placeholder } = this.props

        ClassicEditor.builtinPlugins = this.builtinPlugins
        ClassicEditor.defaultConfig = {
            toolbar: { items: this.toolbarItems },
            // to change plugin labels, use window.CKEDITOR_TRANSLATIONS.<language>.dictionary.<plugin>
            // i.e. window.CKEDITOR_TRANSLATIONS.nl.dictionary.Superscript = 'Some label'
            language: localize.getCurrentLocale(),
            link: {
                // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                addTargetToExternalLinks: true,
            },
            placeholder,
        }

        return (
            <div className={this.bem.getClassName(className)}>
                <CKEditor
                    editor={ClassicEditor}
                    data={this.state.editorState}
                    onBlur={(_event: Event, editor: any) => {
                        this.handleChange(editor.getData())
                    }}
                />
            </div>
        )
    }

    private handleChange = (editorState: string) => {
        const { name, onChange } = this.props

        this.setState({ editorState })

        if (onChange) {
            return onChange(editorState !== '' ? editorState : null, name)
        }
    }
}
