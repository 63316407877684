import React from 'react'

import { RouteComponentProps, Switch, Route, Redirect } from 'react-router'
import { routes } from '~/views/routes'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { Page } from '~/components/Core/Layout/Page'
import { CustomerFrameworkOverviewView } from './CustomerFramework/CustomerFrameworkOverviewView'
import { EmployeeOverviewView } from './Employees/EmployeeOverviewView'
import { EmployeeDetailView } from './Employees/EmployeeDetailView'
import { EmployeeDetailEditView } from './Employees/EmployeeDetailEditView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { DepartmentsOverview } from './Departments/DepartmentsOverview'
import { ComplianceSettingsView } from './ComplianceSettings/ComplianceSettingsView'
import { ComplianceSettingsEditView } from './ComplianceSettings/ComplianceSettingsEditView'
import { SAMLSettingsDetailView } from '~/views/Customer/Settings/SAML/SAMLSettingsDetailView'
import { NewsSourcesOverviewView } from './NewsSources/NewsSourcesOverviewView'
import { NewsSourcesEditView } from './NewsSources/NewsSourcesEditView'
import { TaskTemplatesOverviewView } from './TaskTemplates/TaskTemplatesOverviewView'

interface RouteParams {
    customer: string
}

interface Props extends RouteComponentProps<RouteParams> {}

export class SettingsView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const slug = this.context.customer.slug

        return (
            <Page>
                <Switch>
                    <Route
                        path={routes.customer(slug).settings.news.index}
                        component={NewsSourcesOverviewView}
                        exact={true}
                    />
                    <Route
                        path={routes.customer(slug).settings.news.edit}
                        component={NewsSourcesEditView}
                        exact={true}
                    />
                    <Route
                        path={routes.customer(slug).settings.employees.index}
                        component={EmployeeOverviewView}
                        exact={true}
                    />
                    <Route
                        path={routes.customer(slug).settings.compliance.index}
                        component={ComplianceSettingsView}
                        exact={true}
                    />
                    <Route
                        path={routes.customer(slug).settings.compliance.edit}
                        component={ComplianceSettingsEditView}
                        exact={true}
                    />
                    <Route
                        path={routes.customer(slug).settings.employees.detail()}
                        component={EmployeeDetailView}
                        exact={true}
                    />
                    <Route
                        path={routes.customer(slug).settings.employees.edit()}
                        component={EmployeeDetailEditView}
                        exact={true}
                    />
                    <Route
                        path={routes.customer(slug).settings.frameworks.index}
                        component={CustomerFrameworkOverviewView}
                        exact={true}
                    />
                    <Route
                        path={routes.customer(slug).settings.departments.index}
                        component={DepartmentsOverview}
                        exact={true}
                    />
                    <Route
                        path={routes.customer(slug).settings.taskTemplates.index}
                        component={TaskTemplatesOverviewView}
                        exact={true}
                    />
                    <Route
                        path={routes.customer(slug).settings.saml.index}
                        component={SAMLSettingsDetailView}
                        exact={true}
                    />
                    <Redirect
                        exact={true}
                        path={routes.customer(slug).settings.index}
                        to={routes.customer(slug).settings.employees.index}
                    />
                    <Route component={AppNotFoundView} />
                </Switch>
            </Page>
        )
    }
}
