import React from 'react'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { localize } from '~/bootstrap'
import { ControlVisibility } from '~/generated/graphql'

interface Props {
    name?: string
    defaultVisibility?: ControlVisibility
    onChange?: (option: SelectOption | null) => void
    clearable?: boolean
}

export class ControlVisibilitySelect extends React.PureComponent<Props> {
    public static defaultProps: Partial<Props> = {
        clearable: true,
    }

    public render() {
        const { name, onChange, defaultVisibility } = this.props

        return (
            <Form.Select
                name={name || 'visibility'}
                defaultValue={
                    defaultVisibility ? [this.getControlVisibilitySelectOptionByValue(defaultVisibility)] : []
                }
                options={this.getControlVisibilitySelectOptions()}
                onChange={(option: SelectOption) => {
                    if (onChange) {
                        onChange(option)
                    }
                }}
            />
        )
    }

    private getControlVisibilitySelectOptions(): SelectOption[] {
        return Object.values(ControlVisibility).map(controlVisibility => ({
            value: controlVisibility,
            label: localize.translate(t => t.Control.Visibility[controlVisibility]),
        }))
    }

    private getControlVisibilitySelectOptionByValue(value: ControlVisibility): SelectOption {
        return this.getControlVisibilitySelectOptions().find(selectOption => selectOption.value === value)!
    }
}
