import React from 'react'

import gql from 'graphql-tag'
import { CustomerFrameworkFragment, CustomerFramework } from '~/graphql/types/CustomerFramework'
import { InfiniteScrollQuery, InfiniteScrollOptions } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AlertImpactLevel, AlertImpactLevelFilter } from '~/generated/graphql'

const INBOX_ITEMS_QUERY = gql`
    query alerts($take: Int, $skip: Int, $filters: AlertFilters, $customerSlug: String, $departmentId: Int!) {
        alerts(take: $take, skip: $skip, filters: $filters, customerSlug: $customerSlug) {
            totalCount
            hasNextPage
            nodes {
                __typename
                id
                name
                publishedAt
                isPublishedFromCustomerNews
                impactLevel(departmentId: $departmentId)
                inboxStatus(departmentId: $departmentId) {
                    id
                    archivedAt
                    archivedBy {
                        id
                        user {
                            id
                            profile {
                                id
                                fullName
                            }
                        }
                    }
                }
                allPresentDepartments {
                    id
                    name
                }
                customerFrameworks(departmentId: $departmentId) {
                    ...CustomerFrameworkFields
                }
            }
        }
    }
    ${CustomerFrameworkFragment}
`

export interface InboxAlert {
    __typename: string
    id: number
    name: string
    publishedAt: string
    inboxStatus: InboxStatus | null
    customerFrameworks: CustomerFramework[]
    isPublishedFromCustomerNews?: boolean | null
    impactLevel: AlertImpactLevel | null
    allPresentDepartments: {
        id: number
        name: string
    }[]
}

export interface FirstTask {
    id: number
    name: string
    createdAt: string
    createdBy?: {
        id: number
        user: User
    }
}

export interface Item {
    type: string
    item: {
        __typename: string
        id: number
        source?: Source
    }
}

interface User {
    id: number
    profile: {
        id: number
        fullName: string
        avatar: string | null
    }
}

interface Source {
    id: number
    group: {
        id: number
        name: string
    }
}

export interface InboxStatus {
    archivedAt: string | null
    unarchivedAt: string | null
    archivedBy?: {
        id: number
        user: User
    }
}
export interface InboxAlertFilters {
    search?: string | null
    hasTasksForDepartment?: boolean
    newsGroupIds?: number[]
    isArchivedFromInboxForDepartment?: boolean
    impactLevel?: AlertImpactLevelFilter
}

interface Props {
    children: (options: InfiniteScrollOptions<InboxAlert>) => JSX.Element
    filters?: InboxAlertFilters
}

interface State {}

export class InboxItemQuery extends React.Component<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, filters = {} } = this.props
        const key = filters.hasTasksForDepartment
            ? 'has-tags'
            : filters.isArchivedFromInboxForDepartment
            ? 'is-archived'
            : 'inbox-items'

        const queryFilters = {
            search: filters?.search,
            hasTasksForDepartment: filters?.hasTasksForDepartment,
            newsGroupIds: filters?.newsGroupIds,
            isArchivedFromInboxForDepartment: filters?.isArchivedFromInboxForDepartment,
            impactLevel: filters?.impactLevel,
            customerFrameworkIds: this.context.activeProfiles,
            departmentId: this.context.activeDepartmentId,
        }

        return (
            <InfiniteScrollQuery
                key={key}
                take={20}
                query={INBOX_ITEMS_QUERY}
                variables={{
                    filters: queryFilters,
                    departmentId: this.context.activeDepartmentId,
                }}
            >
                {options => children(options)}
            </InfiniteScrollQuery>
        )
    }
}
