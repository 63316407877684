import './FilterOption.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Field } from '../../DataEntry/Form/Field'

interface Props {
    className?: ClassValue
    label: string
    children: React.ReactNode
    forInputName: string
    isSubFilter?: boolean
}

interface State {}

export class FilterOption extends React.PureComponent<Props, State> {
    private bem = new BEM('FilterOption', () => ({
        'is-sub': !!this.props.isSubFilter,
    }))

    public render() {
        const { className, label, forInputName, children } = this.props

        return (
            <Field forInput={forInputName} label={label} className={this.bem.getClassName(className)}>
                {children}
            </Field>
        )
    }
}
