import Polyglot from 'node-polyglot'
import { translationKeys, Locale } from '~/i18n/TranslationKeys'
import { LocalStorageService, LocalStorageKeys } from '~/services/LocalStorageService'
import distanceInWords from 'date-fns/distance_in_words'
import isAfter from 'date-fns/is_after'
import { Language } from '~/generated/graphql'

interface InterpolationKeys {
    [key: string]: string | number | undefined
}

type TranslationKeys = typeof translationKeys
type TGetFunction = (t: TranslationKeys) => string

interface DateFormatOptions {
    locale?: string
    includeTime?: boolean
    includeSeconds?: boolean
    readable?: boolean
    short?: boolean
    shortYear?: boolean
    noWeekday?: boolean
    noDay?: boolean
    noMonth?: boolean
    noYear?: boolean
}

interface NumberFormatOptions extends Intl.ResolvedNumberFormatOptions {}

export class LocalizationService {
    private static defaultLocale: Locale = Language.nl

    private polyglot: Polyglot
    private locale: Locale
    private dateLocale: any
    public constructor(locale: Locale = LocalizationService.defaultLocale) {
        this.polyglot = new Polyglot()
        this.locale = locale
    }

    public async initialize() {
        if (!(window as any).Intl) {
            // eslint-disable-next-line no-console
            console.log('🇪🇺 Intl not in window; Polyfilling package...')
            await import('intl')
        }

        await this.changeLocale(this.getCurrentLocale())

        // eslint-disable-next-line no-console
        console.log(`🇪🇺 initialized localization with locale: "${this.getCurrentLocale()}"`)
        return this
    }

    public translate(getKey: TGetFunction, interpolation?: InterpolationKeys | number): string {
        const key = getKey(translationKeys)

        if (interpolation) {
            return this.polyglot.t(key, interpolation as InterpolationKeys)
        }

        return this.polyglot.t(key)
    }

    public namespaceTranslate<TNamespace>(getNamespace: (t: TranslationKeys) => TNamespace) {
        return (getKey: (keys: TNamespace) => string, options?: InterpolationKeys) =>
            this.translate(t => getKey(getNamespace(t)), options)
    }

    public async changeLocale(language: Locale) {
        try {
            LocalStorageService.setItem(LocalStorageKeys.Language, language)
            this.locale = language
            this.polyglot.locale(language)
            const languageResource = await import(`../i18n/${language}.json`)
            this.dateLocale = await import(`date-fns/locale/${this.locale}`)

            this.polyglot.replace(languageResource)

            if (!(window as any).Intl) {
                // eslint-disable-next-line no-console
                console.log(`🇪🇺 loading Intl locale pack "${language}"...`)
                await import(`intl/locale-data/jsonp/${language}.js`)
            }

            const htmlElement = document.querySelector('html') as HTMLElement
            htmlElement.lang = language

            return true
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Failed to change locale', err)
            return false
        }
    }

    // tslint:disable-next-line:cyclomatic-complexity
    public dateFormat(date: Date, options: DateFormatOptions = {}) {
        const { short, noWeekday, includeTime, includeSeconds, readable, locale, noDay, noYear, noMonth, shortYear } =
            options

        const inLocale = locale ? locale : this.getCurrentLocale()

        const dateOptions: Intl.DateTimeFormatOptions = {
            weekday: noWeekday ? undefined : readable ? (short ? 'short' : 'long') : undefined,
            year: noYear ? undefined : shortYear ? '2-digit' : 'numeric',
            month: noMonth ? undefined : readable ? (short ? 'short' : 'long') : '2-digit',
            day: noDay ? undefined : readable ? 'numeric' : '2-digit',
            hour: includeTime ? 'numeric' : undefined,
            minute: includeTime ? 'numeric' : undefined,
            second: includeSeconds ? 'numeric' : undefined,
        }

        return new Intl.DateTimeFormat(inLocale, dateOptions).format(date)
    }

    public numberFormat(num: number, options?: NumberFormatOptions) {
        const inLocale = options && options.locale ? options.locale : this.locale

        return new Intl.NumberFormat(inLocale, options).format(num)
    }

    public sort<TArray extends Array<any> = any>(
        array: TArray,
        sortFn: (a: TArray, b: TArray) => [string, string],
        locale: Locale = this.locale
    ) {
        return array.slice().sort((a, b) => {
            const [stringA, stringB] = sortFn(a, b)
            return new Intl.Collator(locale).compare(stringA, stringB)
        })
    }

    public dateTime(date: Date, locale: Locale = this.locale) {
        const dateOptions: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            year: 'numeric',
            month: 'long',
        }

        const timeOptions: Intl.DateTimeFormatOptions = {
            hour: 'numeric',
            minute: 'numeric',
        }

        const dateManipulated = new Intl.DateTimeFormat(locale, dateOptions).format(date)
        const timeManipulated = new Intl.DateTimeFormat(locale, timeOptions).format(date)

        return this.translate(t => t.Generic.dateInDigitAndTime, { date: dateManipulated, time: timeManipulated })
    }

    public distanceInWords(dateToCompare: Date, date: Date) {
        const inWords = distanceInWords(dateToCompare, date, { locale: this.dateLocale })

        if (isAfter(dateToCompare, date)) {
            return this.translate(t => t.Generic.dateInPast, { inWords })
        }

        return this.translate(t => t.Generic.dateInFuture, { inWords })
    }

    public getCurrentLocale(): Locale {
        const cachedLocale = LocalStorageService.getItem(LocalStorageKeys.Language)

        const localeIsNotSet = !cachedLocale || cachedLocale === 'null'
        if (localeIsNotSet) {
            return this.locale
        }

        return cachedLocale as Locale
    }
}
