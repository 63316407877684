import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    EditTaskTemplateMutation,
    EditTaskTemplateMutationVariables,
    EditTaskTemplateDocument,
} from '~/generated/graphql'

interface Props {
    children: (mutation: EditTaskTemplateMutationFN, options: MutationResult) => JSX.Element
}

export type EditTaskTemplateMutationFN = MutationFn<EditTaskTemplateMutation, EditTaskTemplateMutationVariables>

export class EditTaskTemplate extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<EditTaskTemplateMutation, EditTaskTemplateMutationVariables>
                mutation={EditTaskTemplateDocument}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
