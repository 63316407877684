import React from 'react'
import { InfiniteScrollOptions, InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AllFrameworksQueryVariables, AllFrameworksDocument, FrameworkType } from '~/generated/graphql'

interface Props {
    children: (options: InfiniteScrollOptions<FrameworkType, AllFrameworksQueryVariables>) => React.ReactNode
}

export class AllFrameworksQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <InfiniteScrollQuery<FrameworkType, AllFrameworksQueryVariables> query={AllFrameworksDocument}>
                {options => children(options)}
            </InfiniteScrollQuery>
        )
    }
}
