import './AssessmentsList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { ExpandableList } from '~/components/Core/DataDisplay/ExpandableList/ExpandableList'
import { IconLabel } from '~/components/Core/Text/IconLabel'
import { IconType } from '~/components/Core/Icon/IconType'
import { localize } from '~/bootstrap'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    className?: ClassValue
    assessments: { id: number; name: string }[]
}

interface State {
    isOpen: boolean
}

export class AssessmentsList extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        isOpen: false,
    }

    private bem = new BEM('AssessmentsList')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.AssessmentList)

    public render() {
        const { className, assessments } = this.props
        const { isOpen } = this.state

        return (
            <ExpandableList
                className={this.bem.getClassName(className)}
                label={this.loc(t => (isOpen ? t.openLabel : t.closedLabel), { count: assessments.length })}
                onToggle={isOpen => this.setState(state => ({ isOpen }))}
                renderList={() => (
                    <>
                        {assessments.map(assessment => (
                            <IconLabel
                                key={assessment.id}
                                className={this.bem.getElement('item')}
                                iconType={IconType.assessment}
                                to={routes.customer(this.context.customer.slug).risks.assessments.view(assessment.id)}
                            >
                                {assessment.name}
                            </IconLabel>
                        ))}
                    </>
                )}
            />
        )
    }
}
