import React from 'react'
import { TaskDetailFields } from '~/graphql/types/Task'
import { InfiniteScrollQuery, InfiniteScrollOptions } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TasksQueryFilters } from '~/views/Customer/Tasks/TasksQuery/TaskQuery'
import { TasksDocument } from '~/generated/graphql'

export interface Task extends TaskDetailFields {}

const TASKS_QUERY = TasksDocument

interface Props {
    forCustomer?: boolean
    forDepartment?: number
    filters?: TasksQueryFilters
    children: (options: InfiniteScrollOptions<Task>) => JSX.Element
}

export class ContextClosedTasksTableQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, filters, forCustomer, forDepartment } = this.props

        let departmentIds: number[] | undefined
        if (forDepartment) {
            departmentIds = [forDepartment]
        } else if (forCustomer) {
            departmentIds = undefined
        } else {
            departmentIds = [this.context.activeDepartmentId]
        }

        return (
            <InfiniteScrollQuery
                query={TASKS_QUERY}
                variables={{
                    filters: { ...filters, departmentIds, forCustomer: forCustomer || !!forDepartment },
                    departmentId: this.context.activeDepartmentId,
                }}
                useWindow={false}
            >
                {options => children(options)}
            </InfiniteScrollQuery>
        )
    }
}
