import React from 'react'
import gql from 'graphql-tag'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Query } from 'react-apollo'
import { SeverityLevel } from '../Risks/RiskIndicator'
import { ControlTypeType } from '~/generated/graphql'

interface Props {
    children: (control: AssessmentResponse | null, loading: boolean) => React.ReactNode
    assessmentId: number
}

interface State {}

const GET_ASSESSMENT = gql`
    query assessment($id: Int!) {
        assessment(id: $id) {
            id
            name
            description
            risks {
                id
                name
                severity
                severityBruto
                isAccepted
            }
            controls {
                id
                name
                type
                revisionDate
            }
        }
    }
`

interface AssessmentResponse {
    id: number
    name: string
    description: string
    risks:
        | {
              id: number
              name: string
              severity: SeverityLevel
              severityBruto: SeverityLevel
              isAccepted: boolean
          }[]
        | null
    controls:
        | {
              id: number
              name: string
              revisionDate: string | null
              type: ControlTypeType
          }[]
        | null
}

export class AssessmentRisksAndControlsQuery extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, assessmentId } = this.props

        return (
            <Query<{ assessment: AssessmentResponse }> query={GET_ASSESSMENT} variables={{ id: assessmentId }}>
                {({ data, loading }) => children(data ? data.assessment : null, loading)}
            </Query>
        )
    }
}
