import './DashboardWidgetContainer.scss'

import React from 'react'
import { BEM } from '~/services/BEMService'
import { CreateWidgetWidget } from './CreateWidgetWidget/CreateWidgetWidget'
import { EmptyWidgetState } from './WidgetStates/EmptyWidgetState'
import { DashboardWidget, gridBreakpoints, gridColumns, WidgetKey, widgets } from './CreateWidgetWidget/widgets'
import { LayoutInfo, ResponsiveGrid, ResponsiveGridLayoutItem } from '~/components/Core/Layout/ResponsiveGrid'
import { DashboardWidgetLayoutTypeEnum } from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    isEditing: boolean
    layout: DashboardWidget[]
    currentBreakpoint: DashboardWidgetLayoutTypeEnum | undefined
    widgetIdsToDelete: number[]
    onWidgetClick: (widgetId: number, type: WidgetKey) => void
    onDeleteIconClick: (widgetId: number) => void
    onLayoutChange: (newLayout: LayoutInfo[]) => void
    onCreateWidget: () => void
    afterOnCreateWidget?: () => void
    onModalClose: Function
}

interface State {
    isDragging: boolean
    isModalOpen: boolean
}

export class DashboardWidgetContainer extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        isDragging: false,
        isModalOpen: false,
    }

    private bem = new BEM('DashboardWidgetContainer')

    public render() {
        const { isEditing, currentBreakpoint, onCreateWidget, afterOnCreateWidget } = this.props
        const { isDragging } = this.state

        return (
            <div className={this.bem.getClassName()}>
                {this.renderLayout()}
                {isEditing && (
                    <CreateWidgetWidget
                        layout={currentBreakpoint}
                        fadeOut={isDragging}
                        onCreateWidget={onCreateWidget}
                        afterOnCreateWidget={afterOnCreateWidget}
                        onModalStateChange={this.handleModalStateChange}
                    />
                )}
            </div>
        )
    }

    private renderLayout() {
        const { layout, isEditing, onLayoutChange } = this.props

        if (!layout.length && !isEditing) {
            return <EmptyWidgetState />
        }

        const { isModalOpen } = this.state
        const isDraggable = !isModalOpen && isEditing

        return (
            <ResponsiveGrid
                items={this.getItems()}
                rowHeight={1}
                breakpoints={gridBreakpoints}
                columns={gridColumns}
                isDraggable={isDraggable}
                onDrag={({ starting }) => this.setState({ isDragging: starting })}
                onLayoutChange={onLayoutChange}
            />
        )
    }

    private getItems() {
        const { layout, widgetIdsToDelete } = this.props
        const layoutItems: ResponsiveGridLayoutItem[] = []

        for (const widget of layout) {
            const { id, type } = widget

            const willBeDeleted = widgetIdsToDelete.includes(id)
            if (willBeDeleted) {
                continue
            }

            const widgetInfo = widgets.find(({ widgetKey }) => widgetKey === type)
            if (!widgetInfo) {
                continue
            }

            const { x, y } = widget
            const { height, component } = widgetInfo
            const props = this.getWidgetProps(widget)

            layoutItems.push({ id, x, y, w: 1, h: height, render: () => component(props) })
        }

        return layoutItems
    }

    private getWidgetProps(widget: DashboardWidget) {
        const { id, metadata, content } = widget
        const { isEditing, onWidgetClick, onDeleteIconClick, currentBreakpoint } = this.props

        return {
            id,
            isEditing,
            onWidgetClick,
            metadata,
            content,
            currentBreakpoint,
            onDeleteIconClick,
            onModalStateChange: this.handleModalStateChange,
        }
    }

    private handleModalStateChange = ({ isOpening }: { isOpening: boolean }) => {
        const { onModalClose } = this.props

        this.setState({ isModalOpen: isOpening }, () => {
            if (isOpening === false) {
                onModalClose()
            }
        })
    }
}
