import gql from 'graphql-tag'
import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'

const SET_TOPIC_APPLICABLE_STATUS_MUTATION = gql`
    mutation setTopicApplicableStatus(
        $markedAsApplicable: [Int!]
        $markedAsNonApplicable: [TopicNonApplicableInputType!]
    ) {
        setTopicApplicableStatus(markedAsApplicable: $markedAsApplicable, markedAsNonApplicable: $markedAsNonApplicable)
    }
`

export type MarkTopicAsNonApplicableFormMutationFN = MutationFn<
    MarkTopicAsNonApplicableFormMutationResponse,
    MarkTopicAsNonApplicableFormMutationVariables
>

export interface MarkTopicAsNonApplicableFormMutationResponse {
    markTopicAsNonApplicableForm: { id: string }
}

export interface MarkTopicAsNonApplicableFormMutationVariables {
    markedAsApplicable: number[]
    markedAsNonApplicable: { reason: string | null; topicId: number }[]
}

interface Props {
    children: (mutation: MarkTopicAsNonApplicableFormMutationFN, options: MutationResult) => JSX.Element
}

export const MarkTopicAsNonApplicableFormMutation: React.SFC<Props> = ({ children }) => (
    <GQLMutation<MarkTopicAsNonApplicableFormMutationResponse, MarkTopicAsNonApplicableFormMutationVariables>
        mutation={SET_TOPIC_APPLICABLE_STATUS_MUTATION}
    >
        {(mutate, options) => children(mutate, options)}
    </GQLMutation>
)
