import React from 'react'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { localize } from '~/bootstrap'
import { PageDetailContent } from '~/components/Core/Layout/PageDetail/PageDetailContent'
import { PageDetailMeta, PageDetailMetaItem } from '~/components/Core/Layout/PageDetail/PageDetailMeta'
import { RouteComponentProps } from 'react-router'
import gql from 'graphql-tag'
import { PageDetail } from '~/components/Core/Layout/PageDetail/PageDetail'
import { Markdown } from '~/components/Core/Text/Markdown'
import { PageQuery, PageQueryOptions } from '~/components/Domain/PageQuery/PageQuery'
import { GroupedItem, LinkedItemsFields } from '~/graphql/types/LinkedItem'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Query } from 'react-apollo'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { breadcrumbs } from '~/views/breadcrumbs'
import { RadarItemContent } from '~/components/Domain/Radar/RadarItemContent'
import { RadarDetailDocument, RadarDetailQuery, RadarDetailQueryVariables } from '~/generated/graphql'
import { Column } from '~/components/Core/Layout/Column'
import { SortedItemsList } from '~/components/Domain/LinkedList/SortedItemsList'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { RadarDetailActionButtons } from '~/components/Domain/Radar/RadarDetailActionButtons'
import { SidebarTaskWidgetContainer } from '~/components/Domain/Task/SidebarTaskWidget/SidebarTaskWidgetContainer'
import { LinkedTaskItem } from '~/graphql/types/Task'
import { RadarTopicLinkedItemsContainer } from '~/components/Domain/Radar/RadarTopicLinkedItemsContainer'
import { routes } from '~/views/routes'
import { IconType } from '~/components/Core/Icon/IconType'
import { Page } from '~/components/Core/Layout/Page'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

interface RadarItemLinkedItems {
    radarItem?: {
        id: number
        linkedItems: GroupedItem[]
    }
}

const LINKED_RADAR_ITEMS = gql`
    query radarItem($id: Int!, $customerSlug: String) {
        radarItem(id: $id, customerSlug: $customerSlug) {
            id
            linkedItems(customerSlug: $customerSlug) {
                ...LinkedItemsFields
            }
        }
    }
    ${LinkedItemsFields}
`

export class RadarDetailView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.RadarView)

    public render() {
        const { id } = this.props.match.params
        if (!id) {
            return null
        }

        return (
            <PageQuery<RadarDetailQuery['radarItem'], RadarDetailQueryVariables>
                query={RadarDetailDocument}
                variables={{
                    id: parseInt(id, 10),
                    customerSlug: this.context.customer.slug,
                    departmentId: this.context.activeDepartmentId,
                }}
            >
                {this.renderPageDetail}
            </PageQuery>
        )
    }

    private renderPageDetail = (radarItem: RadarDetailQuery['radarItem'], { refetch }: PageQueryOptions) => {
        if (!radarItem) {
            return null
        }

        const { name, content, following } = radarItem

        return (
            <Page>
                <PageHeader
                    breadCrumbs={[
                        breadcrumbs.customer(this.context.customer.slug).legalFramework.index,
                        breadcrumbs.customer(this.context.customer.slug).legalFramework.radar.index,
                    ]}
                    unfollowed={!following}
                    title={name}
                    actionButtons={<RadarDetailActionButtons radar={radarItem} />}
                />
                <PageDetail renderSidebar={() => this.renderSideBar(radarItem, () => refetch())} smallBottomSpacing>
                    <Column extraBigSpacing>
                        <PageDetailMeta items={this.getMetaItems(radarItem)} />
                        <PageDetailContent>
                            <Markdown source={content} />
                        </PageDetailContent>
                    </Column>
                </PageDetail>
            </Page>
        )
    }

    private renderSideBar(radarItem: RadarDetailQuery['radarItem'], refetch: () => void) {
        const { id } = this.props.match.params
        const linkedTopics = this.getLinkedTopicsWithImpacts(radarItem)

        return (
            <>
                <SidebarTaskWidgetContainer linkedItem={radarItem as LinkedTaskItem} refetch={refetch} />
                {linkedTopics && (
                    <RadarTopicLinkedItemsContainer
                        title={localize.translate(t => t.Generic.topics)}
                        items={linkedTopics.map(t => ({
                            label: t.name,
                            route: routes.customer(this.context.customer.slug).legalFramework.topic.view(t.id),
                            icon: IconType.legalFramework,
                        }))}
                    />
                )}
                <ComponentTitle title={localize.translate(t => t.Generic.documents)} />
                <Query<RadarItemLinkedItems>
                    query={LINKED_RADAR_ITEMS}
                    variables={{
                        id: parseInt(id!, 10),
                        customerSlug: this.context.customer.slug,
                        departmentId: this.context.activeDepartmentId,
                    }}
                >
                    {({ data, loading }) => {
                        const radar = data && data.radarItem ? data.radarItem : null

                        if (loading) {
                            return <Spinner />
                        }

                        if (!radar || radar.linkedItems.length === 0) {
                            return <Paragraph subtle={true}>{this.loc(t => t.noItemsLinked)}</Paragraph>
                        }

                        return <SortedItemsList linkedItems={radar.linkedItems} />
                    }}
                </Query>
            </>
        )
    }

    private getMetaItems(radarItem: RadarDetailQuery['radarItem']) {
        const { expectedDate, lastEditorialChangeAt, phase } = radarItem!

        const items: PageDetailMetaItem[] = []

        if (expectedDate) {
            items.push({
                label: localize.translate(t => t.Radar.Attributes.expectedAt),
                value: (
                    <Paragraph>
                        <DateFormat readable={true} noWeekday={true} date={new Date(expectedDate)} />
                    </Paragraph>
                ),
            })
        }

        if (lastEditorialChangeAt) {
            items.push({
                label: localize.translate(t => t.Radar.Attributes.lastEditorialChange),
                value: (
                    <Paragraph>
                        <DateFormat readable={true} noWeekday={true} date={new Date(lastEditorialChangeAt)} />
                    </Paragraph>
                ),
            })
        }

        if (phase?.name) {
            items.push({
                label: localize.translate(t => t.Radar.Attributes.Phase),
                value: <Paragraph>{RadarItemContent.translatePhaseByName(phase.name)}</Paragraph>,
            })
        }

        return items
    }

    private getLinkedTopicsWithImpacts(radarItem: RadarDetailQuery['radarItem']) {
        if (!radarItem?.linkedTopics?.length) {
            return
        }

        return radarItem.linkedTopics.map(t => ({
            ...t,
            impact: radarItem.itemImpacts?.find(i => i.impactedEntity.id === t.id)?.impact ?? null,
        }))
    }
}
