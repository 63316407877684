import './CustomerDepartmentContextSwitch.scss'

import React from 'react'

import { PopperButtonItem } from '~/components/Core/Popper/PopperButtonItem'
import { CustomerConsumer, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM, ClassValue } from '~/services/BEMService'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'

interface Props {
    className?: ClassValue
    requestClose?: () => void
}

interface State {}

export class CustomerDepartmentContextSwitch extends React.PureComponent<Props, State> {
    private bem = new BEM('CustomerDepartmentContextSwitch')

    public render() {
        const { className } = this.props

        return (
            <CustomerConsumer>
                {context => {
                    if (!context) {
                        return null
                    }

                    return <div className={this.bem.getClassName(className)}>{this.renderDepartments(context)}</div>
                }}
            </CustomerConsumer>
        )
    }

    private renderDepartments(context: CustomerContextValue) {
        const customerDepartments = context.departments
        const activeDepartment = context.activeDepartment

        return customerDepartments.map((department, index) => {
            return (
                <Tooltip message={department.name} key={department.id} className={this.bem.getElement('tooltip')}>
                    <PopperButtonItem
                        className={this.bem.getElement('department')}
                        onClick={() => this.handleOnClick(context, department.id)}
                        label={department.name}
                        active={activeDepartment.id === department.id}
                    />
                </Tooltip>
            )
        })
    }

    private handleOnClick(context: CustomerContextValue, departmentId: number) {
        const { requestClose } = this.props

        context.toggleDepartment(departmentId)
        requestClose?.()
    }
}
