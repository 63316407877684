import './AddWidgetModal.scss'

import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { WidgetItem } from '../CreateWidgetWidget/widgets'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Column } from '~/components/Core/Layout/Column'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM, ClassValue } from '~/services/BEMService'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { isArray, isNumber } from 'lodash-es'
import { AddWidget } from '../Mutations/AddWidget'
import { MutationFn } from 'react-apollo'
import {
    AddWidgetMutation,
    AddWidgetMutationVariables,
    DashboardWidgetEnum,
    DashboardWidgetLayoutTypeEnum,
} from '~/generated/graphql'

interface Props {
    widget: WidgetItem
    layout: DashboardWidgetLayoutTypeEnum | undefined
    closeModal: () => void
    onCreateWidget: () => void
    afterOnCreateWidget?: () => void
    className?: ClassValue
}

interface State {
    selectedDepartmentOptions: SelectOption<DepartmentOptionValueType>[]
}

type DepartmentOptionValueType = { allDepartments: boolean } | number

enum FormFields {
    name = 'NAME',
    departments = 'DEPARTMENTS',
}

export class AddWidgetModal extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        selectedDepartmentOptions: AddWidgetModal.defaultDepartmentOption,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView.WidgetModal)
    private bem = new BEM('AddWidgetModal')

    public render() {
        const { closeModal, className, layout } = this.props

        return (
            <AddWidget layout={layout}>
                {(mutate, { loading }) => (
                    <Form onSubmit={state => this.handleSubmit(state, mutate)}>
                        <Modal
                            className={this.bem.getClassName(className)}
                            title={this.loc(t => t.createTitle)}
                            confirmButtonLabel={localize.translate(t => t.Generic.save)}
                            requestClose={closeModal}
                            loading={loading}
                            submitForm={true}
                        >
                            {this.renderModalContent()}
                        </Modal>
                    </Form>
                )}
            </AddWidget>
        )
    }

    private renderModalContent() {
        const { widget } = this.props
        const { widgetKey, imageSource } = widget

        const subTitle = this.loc(t => t.SubTitles[widgetKey])
        const description = this.loc(t => t.Descriptions[widgetKey])

        return (
            <Column bigSpacing={true} className={this.bem.getElement('container')}>
                <div className={this.bem.getElement('section-container')}>
                    <div className={this.bem.getElement('image-container')}>
                        <img className={this.bem.getElement('image')} src={imageSource} />
                    </div>
                    <Column className={this.bem.getElement('text-container')}>
                        <SectionTitle>{subTitle}</SectionTitle>
                        <Paragraph className={this.bem.getElement('description')}>{description}</Paragraph>
                    </Column>
                </div>
                {this.renderForm()}
            </Column>
        )
    }

    private renderForm() {
        const { widget } = this.props
        const { widgetKey } = widget

        const nameInputLabel = this.loc(t => t.nameInputLabel)
        const defaultName = localize.translate(t => t.Customer.DashboardView.CreateWidgetWidget[widgetKey])

        return (
            <Column>
                <Column extraSmallSpacing={true}>
                    <Paragraph>{nameInputLabel}</Paragraph>
                    <Form.Input defaultValue={defaultName} name={FormFields.name} />
                </Column>
                {this.renderDepartmentSelect()}
            </Column>
        )
    }

    private renderDepartmentSelect() {
        const { widget } = this.props
        const { hasNoDepartmentContext } = widget

        if (hasNoDepartmentContext) {
            return
        }

        const departmentInputLabel = this.loc(t => t.departmentInputLabel)
        const departmentOptions = this.getDepartmentOptions()

        const hasAllDepartmentsSelected = this.hasAllDepartmentsSelected()

        return (
            <Column extraSmallSpacing={true}>
                <Paragraph>{departmentInputLabel}</Paragraph>
                <Form.Select
                    options={departmentOptions}
                    value={this.state.selectedDepartmentOptions}
                    onChange={this.handleOnDepartmentChange}
                    name={FormFields.departments}
                    multi={!hasAllDepartmentsSelected}
                    clearable={!hasAllDepartmentsSelected}
                />
            </Column>
        )
    }

    private getDepartmentOptions() {
        const defaultOption = AddWidgetModal.defaultDepartmentOption

        const departments = this.context.employee?.departments
        if (!departments || !departments.length) {
            return defaultOption
        }

        const departmentOptions = departments.map(({ id, name }) => ({ label: name, value: id }))

        return [...defaultOption, ...departmentOptions]
    }

    private static get defaultDepartmentOption() {
        const defaultLabel = localize.translate(t => t.Customer.DashboardView.WidgetModal.allDepartments)
        const defaultOption = [{ label: defaultLabel, value: { allDepartments: true } }]

        return defaultOption
    }

    private async handleSubmit(
        formState: FormState,
        mutate: MutationFn<AddWidgetMutation, AddWidgetMutationVariables>
    ) {
        const { widget, layout, closeModal, onCreateWidget, afterOnCreateWidget } = this.props
        const { widgetKey } = widget

        await onCreateWidget() // do not remove await

        const { departmentIds, hasAllDepartmentsSelected } = this.getDepartmentMetadata()

        const response = await mutate({
            variables: {
                widgetType: DashboardWidgetEnum[widgetKey],
                layout: layout || DashboardWidgetLayoutTypeEnum.threecolumn,
                metadata: {
                    name: formState[FormFields.name],
                    departmentIds,
                    allDepartments: hasAllDepartmentsSelected,
                },
            },
        })

        if (response && response.data?.addWidget) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))
            afterOnCreateWidget?.()
            closeModal()
        }
    }

    private handleOnDepartmentChange = (
        selectedOptions: SelectOption<DepartmentOptionValueType> | SelectOption<DepartmentOptionValueType>[]
    ) => {
        if (!selectedOptions) {
            this.setState({ selectedDepartmentOptions: [] })
            return
        }

        if (!isArray(selectedOptions)) {
            this.setState({ selectedDepartmentOptions: [selectedOptions] })
            return
        }

        if (!selectedOptions.length) {
            this.setState({ selectedDepartmentOptions: [] })
            return
        }

        const hasAllDepartmentsSelected = selectedOptions.some(
            ({ value }) => !isNumber(value) && value.allDepartments === true
        )
        if (hasAllDepartmentsSelected) {
            this.setState({ selectedDepartmentOptions: AddWidgetModal.defaultDepartmentOption })
            return
        }

        this.setState({ selectedDepartmentOptions: selectedOptions })
    }

    private hasAllDepartmentsSelected() {
        const { selectedDepartmentOptions } = this.state

        const hasAllDepartmentsOption = selectedDepartmentOptions.some(
            ({ value }) => !isNumber(value) && value.allDepartments === true
        )
        if (hasAllDepartmentsOption) {
            return true
        }

        return false
    }

    private getDepartmentMetadata() {
        const { widget } = this.props
        const { hasNoDepartmentContext } = widget
        const { selectedDepartmentOptions } = this.state

        let departmentIds: number[] | undefined
        let hasAllDepartmentsSelected: boolean | undefined

        if (hasNoDepartmentContext) {
            return {}
        }

        hasAllDepartmentsSelected = this.hasAllDepartmentsSelected()
        if (hasAllDepartmentsSelected) {
            return { departmentIds, hasAllDepartmentsSelected }
        }

        departmentIds = []
        for (const { value } of selectedDepartmentOptions) {
            if (isNumber(value)) {
                departmentIds.push(value)
            }
        }

        return { departmentIds, hasAllDepartmentsSelected }
    }
}
