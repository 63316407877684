import React from 'react'

import { ApolloError } from 'apollo-client'
import { GraphQLError } from 'graphql'

// tslint:disable-next-line:no-bitwise no-unused-expression
export type ErrorProviderValue = readonly GraphQLError[] | undefined

interface Props {
    error: ApolloError | undefined
}

interface State {}

export class ErrorProvider extends React.Component<Props, State> {
    private static Context = React.createContext<ErrorProviderValue>(undefined)

    public static Consumer = ErrorProvider.Context.Consumer

    public render() {
        const { children, error } = this.props

        const errors = error ? error.graphQLErrors : undefined

        return <ErrorProvider.Context.Provider value={errors}>{children}</ErrorProvider.Context.Provider>
    }
}
