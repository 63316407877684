import './InlineTaskWidget.scss'

import React from 'react'
import { BEM } from '~/services/BEMService'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { TaskOpenClosedCounter } from '~/components/Domain/Task/TaskOpenClosedCounter'

interface Props {
    loading?: boolean
    openAmount: number
    closedAmount: number
    overDueAmount: number
    onContainerClick?: () => void
}

export class InlineTaskWidget extends React.Component<Props> {
    public static defaultProps: Partial<Props> = {
        openAmount: 0,
        closedAmount: 0,
        overDueAmount: 0,
    }

    private bem = new BEM('InlineTaskWidget')

    public render() {
        const { loading } = this.props
        const { onContainerClick, openAmount, closedAmount, overDueAmount } = this.props

        if (loading) {
            return (
                <div className={this.bem.getElement('container')}>
                    <Spinner delayed={true} />
                </div>
            )
        }

        return (
            <div className={this.bem.getElement('container')} onClick={onContainerClick}>
                <TaskOpenClosedCounter
                    openAmount={openAmount}
                    closedAmount={closedAmount}
                    overDueAmount={overDueAmount}
                />
            </div>
        )
    }
}
