import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import './AssessmentScoreBarTooltipContent.scss'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Row } from '~/components/Core/Layout/Row'
import { Column } from '~/components/Core/Layout/Column'
import { DepartmentTopicDataGradeTypeEnum, ThemeTopicAssessmentCoverageScoresType } from '~/generated/graphql'
import { TopicAssessmentIcon } from '~/components/Domain/Topic/TopicDesignAndEffectiveness/modals/TopicAssessment/TopicAssessmentIcon'

interface Props {
    assessments?: ThemeTopicAssessmentCoverageScoresType | null
    title: string
    className?: ClassValue
}

export class AssessmentScoreBarTooltipContent extends React.PureComponent<Props> {
    private bem = new BEM('AssessmentScoreBarTooltipContent')

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.AssessmentCoverageBar)

    public render() {
        const { title, assessments, className } = this.props

        if (!assessments) {
            return null
        }

        const { lastUpdatedAt } = assessments

        return (
            <Column bigSpacing className={this.bem.getClassName(className)}>
                <Paragraph bold={true}>{title}</Paragraph>
                <Row noSpacing={true} className={this.bem.getElement('assessments-icon-container')}>
                    {this.renderAssessmentIcons(assessments)}
                </Row>
                {lastUpdatedAt && (
                    <Paragraph small={true} subtle={true}>
                        {this.loc(t => t.lastReview, { date: localize.dateFormat(new Date(lastUpdatedAt)) })}
                    </Paragraph>
                )}
            </Column>
        )
    }

    private renderAssessmentIcons(assessments: ThemeTopicAssessmentCoverageScoresType) {
        const orderedAssessments = this.getOrderedAssessment(assessments)

        return orderedAssessments.flatMap(a =>
            Object.entries(a).map(([gradeType, count], i) => (
                <React.Fragment key={`${gradeType}-${i}`}>
                    <Row className={this.bem.getElement('icon-container')}>
                        <TopicAssessmentIcon status={gradeType as DepartmentTopicDataGradeTypeEnum} />
                        <Paragraph className={this.bem.getElement('counts')} small={true}>
                            {/* tslint:disable-next-line: jsx-use-translation-function */}
                            {count}x
                        </Paragraph>
                    </Row>
                </React.Fragment>
            ))
        )
    }

    private getOrderedAssessment(assessments: ThemeTopicAssessmentCoverageScoresType) {
        const result = [
            this.getAssessmentResult(DepartmentTopicDataGradeTypeEnum.assessmentSatisfies, assessments),
            this.getAssessmentResult(DepartmentTopicDataGradeTypeEnum.assessmentAlmostSatisfies, assessments),
            this.getAssessmentResult(DepartmentTopicDataGradeTypeEnum.assessmentNotSatisfies, assessments),
            this.getAssessmentResult(DepartmentTopicDataGradeTypeEnum.notApplicable, assessments),
            this.getAssessmentResult(DepartmentTopicDataGradeTypeEnum.assessmentSatisfiesPartly, assessments),
            this.getAssessmentResult(DepartmentTopicDataGradeTypeEnum.assessmentAlmostSatisfiesPartly, assessments),
            this.getAssessmentResult(DepartmentTopicDataGradeTypeEnum.assessmentNotSatisfiesPartly, assessments),
            this.getAssessmentResult(DepartmentTopicDataGradeTypeEnum.assessmentNotGiven, assessments),
        ] as Record<string, number>[]

        return result
    }

    private getAssessmentResult(
        grade: DepartmentTopicDataGradeTypeEnum,
        assessments: ThemeTopicAssessmentCoverageScoresType
    ) {
        return { [grade]: assessments[grade] }
    }
}
