import React from 'react'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { ControlTypeType } from '~/generated/graphql'
import { localize } from '~/bootstrap'

interface Props {
    name?: string
    onChange?: (option: SelectOption | null) => void
    defaultSelected?: SelectOption[] | null
    placeholder?: string
    isFilter?: boolean
    allowedControlTypes?: ControlTypeType[]
}

interface State {
    search: string | null
}

export class ControlTypeSelect extends React.PureComponent<Props, State> {
    public render() {
        const { name, defaultSelected, placeholder, isFilter, onChange, allowedControlTypes } = this.props

        const controlTypeOptions = Object.values(ControlTypeType).map(value => ({
            label: localize.translate(t => t.Control.type[value]),
            value: value,
            isDisabled: allowedControlTypes ? !allowedControlTypes.includes(value) : false,
        }))

        return (
            <Form.Select
                placeholder={placeholder}
                name={name || 'controlType'}
                defaultValue={defaultSelected || undefined}
                options={controlTypeOptions}
                onChange={(option: SelectOption) => {
                    if (onChange) {
                        onChange(option)
                    }
                }}
                isFilter={isFilter}
                clearable={true}
            />
        )
    }
}
