import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import gql from 'graphql-tag'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationFn } from 'react-apollo'
import { ASSESSMENT_SECTIONS_QUERY } from '../Assessments/AssessmentSections/AssessmentSectionsInfiniteScrollQuery'

interface Props {
    className?: ClassValue
    requestClose: () => void
    assessmentId: number
    assessmentSectionId: number
    onSubmit: (riskId: number) => void
}

interface State {
    title: string
}

const CREATE_RISK = gql`
    mutation createRiskForAssessmentSection($assessmentSectionId: Int!, $name: String) {
        createRiskForAssessmentSection(assessmentSectionId: $assessmentSectionId, name: $name) {
            id
            name
        }
    }
`

interface CreateRiskResponse {
    createRiskForAssessmentSection: {
        id: number
        name: string
    }
}

interface CreateRiskVariables {
    assessmentSectionId: number
    name: string
}

export class AddRiskModal extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        title: '',
    }

    private bem = new BEM('AddRiskModal')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Risks)

    public render() {
        const { title } = this.state
        const { className, requestClose, assessmentId } = this.props

        return (
            <GQLMutation<CreateRiskResponse, CreateRiskVariables>
                mutation={CREATE_RISK}
                refetchQueries={[
                    {
                        query: ASSESSMENT_SECTIONS_QUERY,
                        variables: {
                            assessmentId,
                            departmentId: this.context.activeDepartmentId,
                        },
                    },
                ]}
            >
                {(mutate, { loading }) => (
                    <Form isCompact={true} onSubmit={this.handleSubmit(mutate)}>
                        <Modal
                            title={this.loc(t => t.createRisk)}
                            className={this.bem.getClassName(className)}
                            requestClose={requestClose}
                            loading={loading}
                            actions={closeModal => (
                                <Row alignRight={true}>
                                    <Button type={ButtonType.tertiary} onClick={closeModal}>
                                        {localize.translate(t => t.Generic.cancel)}
                                    </Button>
                                    <Button loading={loading} submit={true} disabled={!title}>
                                        {localize.translate(t => t.Generic.save)}
                                    </Button>
                                </Row>
                            )}
                        >
                            <Field label={this.loc(t => t.riskName)} forInput="name">
                                <Form.Input
                                    onChange={value => this.setState({ title: value ? value : '' })}
                                    name={'name'}
                                    placeholder={this.loc(t => t.createRiskPlaceholder)}
                                />
                            </Field>
                        </Modal>
                    </Form>
                )}
            </GQLMutation>
        )
    }

    private handleSubmit = (mutate: MutationFn<CreateRiskResponse, CreateRiskVariables>) => async (
        state: FormState
    ) => {
        const { assessmentSectionId, onSubmit } = this.props

        const response = await mutate({
            variables: {
                assessmentSectionId,
                name: state.name,
            },
        })

        if (response && response.data && response.data.createRiskForAssessmentSection) {
            onSubmit(response.data.createRiskForAssessmentSection.id)
        }
    }
}
