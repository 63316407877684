import React from 'react'

import { Page } from '~/components/Core/Layout/Page'
import { PageActions } from '~/components/Core/Layout/PageActions'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { localize, permissions } from '~/bootstrap'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { TableList } from '~/components/Core/DataDisplay/TableList/TableList'
import { TableListItem } from '~/components/Core/DataDisplay/TableList/TableListItem'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import gql from 'graphql-tag'
import { TextLink } from '~/components/Core/Text/TextLink'
import { routes } from '~/views/routes'
import { PinnableCard } from '~/components/Chrome/Pinnable/PinnableCard/PinnableCard'
import { Grid } from '~/components/Core/Layout/Grid'
import { Pin } from '~/components/Chrome/Pinnable/Pin'
import { Row } from '~/components/Core/Layout/Row'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { PinnedLawsQuery } from '~/components/Domain/Pinned/PinnedLawsQuery'
import { Query } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { FilterTags } from '~/components/Core/DataEntry/FilterTags/FilterTags'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { ParamManager } from '~/components/Core/ParamManager/ParamManager'
import { Guard } from '~/components/Core/Guard/Guard'

interface Props {}

export const LIST_LAWS_QUERY = gql`
    query laws($skip: Int, $take: Int, $sort: LawSort, $filters: LawFilters) {
        laws(skip: $skip, take: $take, sort: $sort, filters: $filters) @connection(key: "laws") {
            count
            totalCount
            hasNextPage
            nodes {
                id
                abstractLawId
                name
                isPinned
            }
        }
    }
`

export const LIST_LAW_GROUPS_QUERY = gql`
    query lawGroups {
        lawGroups {
            id
            name
        }
    }
`

interface LawGroupResponse {
    lawGroups: LawGroup[]
}

interface LawGroup {
    id: number
    name: string
}

interface Law {
    id: number
    abstractLawId: number
    name: string
    isPinned: boolean
}

interface Filters {
    search: string | null
    activeLawGroups: number[]
}

export class LawAndRegulationOverviewView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.LawAndRegulation.LawAndRegulationOverview)

    public render() {
        const isConsultant = permissions.isConsultant()

        return (
            <Page>
                <ParamManager<Filters>
                    defaultState={{
                        search: '',
                        activeLawGroups: [],
                    }}
                >
                    {({ paramState, setParamState }) => {
                        const { search, activeLawGroups } = paramState
                        return (
                            <>
                                <PageHeader title={this.loc(t => t.title)} />
                                <PageActions
                                    primaryComponent={
                                        <Search
                                            placeholder={localize.translate(t => t.Generic.search.byName)}
                                            onChange={search => setParamState({ search })}
                                            defaultValue={search}
                                        />
                                    }
                                />
                                <Row>
                                    <Query<LawGroupResponse> query={LIST_LAW_GROUPS_QUERY}>
                                        {({ data, loading }) => {
                                            if (loading) {
                                                return <Spinner small={true} />
                                            }

                                            return (
                                                <FilterTags
                                                    filters={data ? this.translateTitles(data.lawGroups) : []}
                                                    defaultActive={activeLawGroups}
                                                    onChange={filters => setParamState({ activeLawGroups: filters })}
                                                />
                                            )
                                        }}
                                    </Query>
                                </Row>
                                <PinnedLawsQuery>
                                    {({ laws, pin, unpin }) => (
                                        <>
                                            <Grid gridColumns={3}>
                                                {laws.map(law => (
                                                    <PinnableCard
                                                        key={law.id}
                                                        to={routes
                                                            .customer(this.context.customer.slug)
                                                            .lawAndRegulation.detail.view(law.abstractLawId)}
                                                        title={law.name}
                                                        isPinned={true}
                                                        togglePinned={() => !isConsultant && unpin(law.abstractLawId)}
                                                    />
                                                ))}
                                            </Grid>
                                            <InfiniteScrollQuery<Law>
                                                query={LIST_LAWS_QUERY}
                                                variables={{
                                                    filters: {
                                                        search: search,
                                                        groupIds: activeLawGroups,
                                                    },
                                                    sort: {
                                                        name: 'ASC',
                                                    },
                                                }}
                                            >
                                                {({ data, loading, loadingMore }) => {
                                                    const nodes = data ? data.nodes : []

                                                    if (!loading && !nodes.length) {
                                                        return <NoResults label={this.loc(t => t.noResults)} />
                                                    }

                                                    return (
                                                        <TableList
                                                            loading={loading}
                                                            loadingMore={loadingMore}
                                                            columns={3}
                                                        >
                                                            {nodes.map(law => (
                                                                <TableListItem key={law.abstractLawId}>
                                                                    <Row stretchChildren={true} smallSpacing={true}>
                                                                        <TextLink
                                                                            to={routes
                                                                                .customer(this.context.customer.slug)
                                                                                .lawAndRegulation.detail.view(
                                                                                    law.abstractLawId
                                                                                )}
                                                                        >
                                                                            {law.name}
                                                                        </TextLink>
                                                                        <Guard condition={isConsultant}>
                                                                            <Pin
                                                                                togglePinned={() =>
                                                                                    law.isPinned
                                                                                        ? unpin(law.abstractLawId)
                                                                                        : pin(law.abstractLawId)
                                                                                }
                                                                                isPinned={law.isPinned}
                                                                            />
                                                                        </Guard>
                                                                    </Row>
                                                                </TableListItem>
                                                            ))}
                                                        </TableList>
                                                    )
                                                }}
                                            </InfiniteScrollQuery>
                                        </>
                                    )}
                                </PinnedLawsQuery>
                            </>
                        )
                    }}
                </ParamManager>
            </Page>
        )
    }

    private translateTitles(input: LawGroup[]) {
        if (!input || !input.length) {
            return input
        }

        return input.map(o => {
            return {
                id: o.id,
                name: this.translateTitleByName(o.name),
            }
        })
    }

    private translateTitleByName(title: string) {
        switch (title) {
            case 'Europa (Basel etc.)': {
                return this.loc(t => t.filterTitles.Europe)
            }
            case 'EU verordeningen': {
                return this.loc(t => t.filterTitles.EUVerordeningen)
            }
            case 'EU richtlijnen': {
                return this.loc(t => t.filterTitles.EUrichtlijnen)
            }
            case 'EU Technische Standaarden': {
                return this.loc(t => t.filterTitles.EUTechnischeStandaarden)
            }
            case 'EU Overig': {
                return this.loc(t => t.filterTitles.EUOverig)
            }
            case 'Wet': {
                return this.loc(t => t.filterTitles.Wet)
            }
            case 'Besluit': {
                return this.loc(t => t.filterTitles.Besluit)
            }
            case 'Regeling': {
                return this.loc(t => t.filterTitles.Regeling)
            }
            case 'DNB/AFM Overig': {
                return this.loc(t => t.filterTitles.DNBAFMOverig)
            }
            case 'Overig': {
                return this.loc(t => t.filterTitles.Overig)
            }
            default: {
                return title
            }
        }
    }
}
