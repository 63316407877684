import './ExportWidget.scss'

import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { WidgetKey } from '../../CreateWidgetWidget/widgets'
import { WidgetContainer } from '../components/WidgetContainer'
import { Select, SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { fileService, localize, permissions } from '~/bootstrap'
import { Button } from '~/components/Core/Button/Button'
import { SelectDepartmentForExportModal } from './SelectDepartmentForExportModal'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { FormState } from '~/components/Core/DataEntry/Form/Form'
import {
    ExportWidgetMutationsContainer,
    ExportWidgetMutations,
    ControlExportMutation,
    TaskExportMutation,
    ComplianceExportMutation,
    RadarExportMutation,
    TopicExportMutation,
    RiskExportMutation,
    SignalingExportMutation,
    InboxExportMutation,
} from './ExportWidgetMutationsContainer'
import {
    DashboardCustomerSpecificWidgetMetadataType,
    DashboardWidgetContentFragmentFragment,
    DashboardWidgetLayoutTypeEnum,
    DashboardWidgetMetadataFragmentFragment,
    EmployeePermissionEnum,
} from '~/generated/graphql'
import { sortBy } from 'lodash-es'

interface Props {
    isEditing: boolean
    currentBreakpoint: DashboardWidgetLayoutTypeEnum | undefined
    onDeleteIconClick?: (widgetId: number) => void
    onModalStateChange?: ({ isOpening }: { isOpening: boolean }) => void
    id: number
    metadata?: DashboardWidgetMetadataFragmentFragment
    content?: DashboardWidgetContentFragmentFragment
}

interface State {
    selectedExportType: ExportTypes | null
}

enum ExportTypes {
    control = 'control',
    task = 'task',
    compliance = 'compliance',
    radar = 'radar',
    topic = 'topic',
    risk = 'risk',
    signaling = 'signaling',
    inbox = 'inbox',
}

interface ExportOption {
    value: ExportTypes
    label: string
}

interface ModalExportArgs {
    mutations: ExportWidgetMutations
    requestClose: () => void
    formState: FormState
}

export class ExportWidget extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        selectedExportType: null,
    }

    private bem = new BEM('ExportWidget')
    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView.Widgets.ExportWidget)
    private errorPaths: Record<ExportTypes, string> = {
        [ExportTypes.control]: 'createControlExport',
        [ExportTypes.task]: 'createTasksExport',
        [ExportTypes.compliance]: 'createComplianceExport',
        [ExportTypes.radar]: 'exportRadarItems',
        [ExportTypes.topic]: 'createNonApplicableTopicsExport',
        [ExportTypes.risk]: 'createRisksExport',
        [ExportTypes.signaling]: 'createCustomerEnabledNewsSourcesExport',
        [ExportTypes.inbox]: 'createInboxExport',
    }

    public render() {
        const { isEditing, currentBreakpoint, onModalStateChange, onDeleteIconClick } = this.props

        return (
            <WidgetContainer
                widgetInfo={this.getHeaderInfo()}
                isEditing={isEditing}
                className={this.bem.getClassName()}
                currentBreakpoint={currentBreakpoint}
                onDeleteIconClick={onDeleteIconClick}
                onModalStateChange={onModalStateChange}
            >
                <Column bigSpacing={true}>{this.renderContent()}</Column>
            </WidgetContainer>
        )
    }

    private getHeaderInfo() {
        const { id } = this.props
        const customName = this.getCustomName()

        return {
            widgetId: id,
            customName,
            widgetKey: WidgetKey.export,
            departments: [],
            hideDepartments: true,
        }
    }

    private renderContent() {
        return (
            <ExportWidgetMutationsContainer>
                {mutations => (
                    <Column>
                        <Select name="export" options={this.getExportOptions()} onChange={this.handleOnChange} />
                        {this.renderExportButton(mutations)}
                    </Column>
                )}
            </ExportWidgetMutationsContainer>
        )
    }

    private getCustomName() {
        const { metadata } = this.props

        if (!metadata) {
            return
        }

        const { name } = metadata as DashboardCustomerSpecificWidgetMetadataType

        if (!name) {
            return
        }

        return name
    }

    private getExportOptions() {
        const options = sortBy(
            Object.values(ExportTypes).map(value => ({ value, label: this.loc(t => t[value]) })),
            'label'
        )

        if (!permissions.hasPermission(EmployeePermissionEnum.canMonitorSignaling)) {
            return options.filter(o => o.value !== ExportTypes.signaling)
        }

        return options
    }

    private handleOnChange = (selectedOption: SelectOption<ExportOption>) => {
        this.setState({ selectedExportType: selectedOption.value as any })
    }

    private renderExportButton(mutations: ExportWidgetMutations) {
        const { selectedExportType } = this.state
        const isLoading = this.checkIfLoading(mutations)

        if (selectedExportType === ExportTypes.topic) {
            const action = () => this.handleDirectTopicExport(mutations.topicExport)
            return this.renderButton(action, isLoading)
        }

        if (selectedExportType === ExportTypes.signaling) {
            const action = () => this.handleDirectSignalingExport(mutations.signalingExport)
            return this.renderButton(action, isLoading)
        }

        const title = localize.translate(t => t.Customer.DashboardView.Widgets.DefaultNames.export)
        const selectedType = selectedExportType ? this.loc(t => t[selectedExportType]) : 'selected option'
        const modalDescription = this.loc(t => t.modalDescription, { selectedOption: selectedType })

        return (
            <ModalManager
                render={openModal => this.renderButton(openModal, isLoading)}
                renderModal={requestClose => (
                    <SelectDepartmentForExportModal
                        requestClose={requestClose}
                        title={title}
                        description={modalDescription}
                        loading={isLoading}
                        errorPath={selectedExportType ? this.errorPaths[selectedExportType] : ''}
                        onSubmit={formState => this.handleModalExport({ requestClose, mutations, formState })}
                        showYearSelection={selectedExportType === ExportTypes.inbox}
                    />
                )}
            />
        )
    }

    private checkIfLoading(mutations: ExportWidgetMutations) {
        const { controlExport, taskExport, complianceExport, radarExport, topicExport, riskExport } = mutations
        const { signalingExport } = mutations

        const isLoading =
            controlExport.options.loading ||
            taskExport.options.loading ||
            complianceExport.options.loading ||
            radarExport.options.loading ||
            topicExport.options.loading ||
            riskExport.options.loading ||
            signalingExport.options.loading

        return isLoading
    }

    private renderButton(action: () => void, isLoading: boolean) {
        const { selectedExportType } = this.state
        const isDisabled = !selectedExportType
        const title = localize.translate(t => t.Customer.DashboardView.Widgets.DefaultNames.export)

        return (
            <Button
                disabled={isDisabled}
                loading={isLoading}
                onClick={action}
                className={this.bem.getElement('button')}
            >
                {title}
            </Button>
        )
    }

    private handleDirectTopicExport = async (topicExport: TopicExportMutation) => {
        const { selectedExportType } = this.state

        if (selectedExportType !== ExportTypes.topic) {
            return
        }

        const { mutate } = topicExport
        const response = await mutate({ variables: { departmentId: this.context.activeDepartmentId } })

        if (response && response.data?.createNonApplicableTopicsExport) {
            const { token, filename } = response.data.createNonApplicableTopicsExport
            this.handleOnSuccess(token, filename)
        }
    }

    private handleDirectSignalingExport = async (signalingExport: SignalingExportMutation) => {
        const { selectedExportType } = this.state

        if (selectedExportType !== ExportTypes.signaling) {
            return
        }

        const { mutate } = signalingExport
        const response = await mutate()

        if (response && response.data?.createCustomerEnabledNewsSourcesExport) {
            const { token, filename } = response.data.createCustomerEnabledNewsSourcesExport
            this.handleOnSuccess(token, filename)
        }
    }

    private handleModalExport = ({ formState, requestClose, mutations }: ModalExportArgs) => {
        const { activeDepartmentId, departments } = this.context
        const departmentIds = formState && formState.exportAll ? departments.map(({ id }) => id) : [activeDepartmentId]

        const { complianceExport, controlExport, radarExport, riskExport, taskExport, inboxExport } = mutations
        const { selectedExportType } = this.state

        switch (selectedExportType) {
            case ExportTypes.compliance:
                return this.handleComplianceExport(complianceExport, departmentIds, requestClose)
            case ExportTypes.control:
                return this.handleControlExport(controlExport, departmentIds, requestClose)
            case ExportTypes.radar:
                return this.handleRadarExport(radarExport, departmentIds, requestClose)
            case ExportTypes.risk:
                return this.handleRiskExport(riskExport, departmentIds, requestClose)
            case ExportTypes.task:
                return this.handleTaskExport(taskExport, departmentIds, requestClose)
            case ExportTypes.inbox:
                return this.handleInboxExport(inboxExport, departmentIds, requestClose, formState?.year?.value)
            default:
                throw new Error('type not implemented')
        }
    }

    private async handleComplianceExport(
        { mutate }: ComplianceExportMutation,
        departmentIds: number[],
        requestClose: () => void
    ) {
        const response = await mutate({
            variables: {
                departmentIds,
            },
        })

        if (response && response.data?.createComplianceExport) {
            const { token, filename } = response.data.createComplianceExport
            this.handleOnSuccess(token, filename, requestClose)
        }
    }

    private async handleControlExport(
        { mutate }: ControlExportMutation,
        departmentIds: number[],
        requestClose: () => void
    ) {
        const response = await mutate({ variables: { departmentIds } })

        if (response && response.data?.createControlExport) {
            const { token, filename } = response.data.createControlExport
            this.handleOnSuccess(token, filename, requestClose)
        }
    }

    private async handleRadarExport(
        { mutate }: RadarExportMutation,
        departmentIds: number[],
        requestClose: () => void
    ) {
        const response = await mutate({ variables: { filters: { departmentIds } } })

        if (response && response.data?.exportRadarItems) {
            const { token, filename } = response.data.exportRadarItems
            this.handleOnSuccess(token, filename, requestClose)
        }
    }

    private async handleRiskExport({ mutate }: RiskExportMutation, departmentIds: number[], requestClose: () => void) {
        const response = await mutate({
            variables: { departmentIdsFilter: departmentIds, currentDepartmentId: this.context.activeDepartmentId },
        })

        if (response && response.data?.createRisksExport) {
            const { token, filename } = response.data.createRisksExport
            this.handleOnSuccess(token, filename, requestClose)
        }
    }

    private async handleTaskExport({ mutate }: TaskExportMutation, departmentIds: number[], requestClose: () => void) {
        const response = await mutate({ variables: { filters: { departmentIds } } })

        if (response && response.data?.createTasksExport) {
            const { token, filename } = response.data.createTasksExport
            this.handleOnSuccess(token, filename, requestClose)
        }
    }

    private async handleInboxExport(
        { mutate }: InboxExportMutation,
        departmentIds: number[],
        requestClose: () => void,
        year?: number
    ) {
        const response = await mutate({ variables: { departmentIds, year } })

        if (response && response.data?.createInboxExport) {
            const { token, filename } = response.data.createInboxExport
            this.handleOnSuccess(token, filename, requestClose)
        }
    }

    private handleOnSuccess(token: string, filename: string, requestClose?: () => void) {
        const url = fileService.createFileUrlFromToken(token, filename)
        window.open(url, '_blank')

        if (requestClose) {
            requestClose()
        }
    }
}
