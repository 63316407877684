import './SignalingOverviewView.scss'
import React from 'react'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { RouteComponentProps } from 'react-router-dom'
import { Page } from '~/components/Core/Layout/Page'
import { ParamManager, NewParamState } from '~/components/Core/ParamManager/ParamManager'
import { InternalAlertStatusState, NewsStatusEnum } from '~/generated/graphql'
import { localize } from '~/bootstrap'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { NewsGroupSelect } from '~/components/Domain/News/NewsGroupSelect'
import { NewsSourceSelect } from '~/components/Domain/News/NewsSourceSelect'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { Row } from '~/components/Core/Layout/Row'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SignalingItemContainer } from './SignalingItemContainer'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { isNumber, range } from 'lodash-es'
import { Table } from '~/components/Core/DataDisplay/Table/Table'
import { SignalingOverviewQuery } from '~/components/Domain/Signaling/SignalingOverviewQuery'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { BEM } from '~/services/BEMService'
import { Column } from '~/components/Core/Layout/Column'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { breadcrumbs } from '~/views/breadcrumbs'
import { PaginatedSentBackInternalAlertsContainer } from '~/components/Domain/Signaling/SentBackInternalAlerts/PaginatedSentBackInternalAlertsContainer'
import { SentBackInternalAlertsContainer } from '~/components/Domain/Signaling/SentBackInternalAlerts/SentBackInternalAlertsContainer'

interface Props extends RouteComponentProps<{}> {}

interface State {}

export interface SignalingFilters {
    groupIds?: SelectOption
    sourceIds?: SelectOption
    status: InternalAlertStatusState | 'markedRelevant'
    publishedForDepartmentIds?: number[]
    archivedYear?: number
    search?: string
}

export class SignalingOverviewView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Signaling.SignalingOverview)
    private bem = new BEM('SignalingOverviewView')

    private statusSelectOptions = [
        InternalAlertStatusState.new,
        InternalAlertStatusState.returned,
        InternalAlertStatusState.archived,
        'markedRelevant',
    ].map(value => ({ label: this.loc(t => t.filters.statusOptions[value]), value }))

    private yearOptions = this.getYearOptions()

    public render() {
        return (
            <ParamManager<SignalingFilters>
                defaultState={{
                    status: InternalAlertStatusState.new,
                }}
            >
                {({ paramState, setParamState }) => (
                    <Page forOverview className={this.bem.getClassName()}>
                        <PageHeader
                            title={this.loc(t => t.title)}
                            actionComponent={this.renderFilters(paramState, setParamState)}
                            breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).signaling.index]}
                        />
                        {this.renderItems(paramState)}
                    </Page>
                )}
            </ParamManager>
        )
    }

    private renderItems(paramState: SignalingFilters) {
        return (
            <Column extraBigSpacing className={this.bem.getElement('container')}>
                {paramState.status === InternalAlertStatusState.new && this.renderPaginatedSentBackItems(paramState)}
                {(paramState.status === InternalAlertStatusState.new ||
                    paramState.status === InternalAlertStatusState.archived) &&
                    this.renderSignalingItems(paramState, paramState.status)}
                {paramState.status === InternalAlertStatusState.returned && this.renderSentBackItems(paramState)}
                {paramState.status === 'markedRelevant' && this.renderMarkedRelevantItems(paramState)}
            </Column>
        )
    }

    private renderPaginatedSentBackItems({ search, sourceIds, groupIds }: SignalingFilters) {
        const sources = sourceIds && isNumber(sourceIds.value) ? [sourceIds.value] : undefined
        const groups = groupIds?.value ? [groupIds?.value as number] : undefined

        return (
            <Column>
                <ComponentTitle
                    title={this.loc(t => t.sentBackItems)}
                    bold={true}
                    className={this.bem.getElement('title')}
                />
                <PaginatedSentBackInternalAlertsContainer search={search} sourceIds={sources} groupIds={groups} />
            </Column>
        )
    }

    private renderSentBackItems({ search, sourceIds, groupIds }: SignalingFilters) {
        const sources = sourceIds && isNumber(sourceIds.value) ? [sourceIds.value] : undefined
        const groups = groupIds?.value ? [groupIds?.value as number] : undefined

        return (
            <Column>
                <ComponentTitle
                    title={this.loc(t => t.sentBackItems)}
                    bold={true}
                    className={this.bem.getElement('title')}
                />
                <SentBackInternalAlertsContainer search={search} sourceIds={sources} groupIds={groups} />
            </Column>
        )
    }

    private renderSignalingItems(
        paramState: Omit<SignalingFilters, 'status'>,
        status: InternalAlertStatusState.archived | InternalAlertStatusState.new
    ) {
        const { search, groupIds, publishedForDepartmentIds, archivedYear, sourceIds } = paramState
        const sort =
            status === InternalAlertStatusState.archived ? { archivedDate: 'DESC' } : { publicationDate: 'DESC' }
        const title =
            status === InternalAlertStatusState.archived
                ? this.loc(t => t.archivedItemsSubtitle)
                : this.loc(t => t.newItemsSubtitle)

        return (
            <Column>
                <ComponentTitle title={title} bold={true} className={this.bem.getElement('title')} />
                <SignalingOverviewQuery
                    filters={{
                        search: search,
                        status:
                            status === InternalAlertStatusState.archived ? NewsStatusEnum.archived : NewsStatusEnum.new,
                        groupIds: groupIds?.value ? [groupIds?.value as number] : undefined,
                        publishedForDepartmentIds: publishedForDepartmentIds,
                        archivedYear: archivedYear,
                        excludeDisabledNewsSources: true, // should always remain true for signaling
                        sourceIds: sourceIds && isNumber(sourceIds.value) ? [sourceIds.value] : undefined,
                    }}
                    sort={sort}
                >
                    {({ data, loading, loadingMore, refetch }) => {
                        if (loading) {
                            return <Spinner />
                        }

                        if (!data?.nodes?.length) {
                            return <Table data={[]} columns={[]} hideHeaders whiteBackground />
                        }

                        return (
                            <SignalingItemContainer
                                key={status}
                                data={data.nodes}
                                refetch={refetch}
                                loadingMore={loadingMore}
                                forArchived={status === InternalAlertStatusState.archived}
                            />
                        )
                    }}
                </SignalingOverviewQuery>
            </Column>
        )
    }

    private renderMarkedRelevantItems({ search, sourceIds, groupIds }: SignalingFilters) {
        const sources = sourceIds && isNumber(sourceIds.value) ? [sourceIds.value] : undefined
        const groups = groupIds?.value ? [groupIds?.value as number] : undefined

        return (
            <Column>
                <ComponentTitle
                    title={this.loc(t => t.relevantItemsSubtitle)}
                    bold={true}
                    className={this.bem.getElement('title')}
                />
                <SentBackInternalAlertsContainer
                    includePendingAndAccepted
                    search={search}
                    sourceIds={sources}
                    groupIds={groups}
                />
            </Column>
        )
    }

    private isFilterActive(paramState: SignalingFilters) {
        if (paramState.archivedYear || paramState.publishedForDepartmentIds || paramState.groupIds) {
            return true
        }

        if (paramState.status && paramState.status !== InternalAlertStatusState.new) {
            return true
        }

        return false
    }

    private renderFilters(
        paramState: SignalingFilters,
        setParamState: (newsState: NewParamState<SignalingFilters>) => void
    ) {
        return (
            <Row>
                <Search
                    defaultValue={paramState.search}
                    onChange={search => setParamState({ search: search || undefined })}
                    placeholder={localize.translate(t => t.Generic.search.search)}
                />
                <FilterDropdown hasActiveFilter={this.isFilterActive(paramState)}>
                    <FilterOption label={this.loc(t => t.filters.groupSource)} forInputName="groupSource">
                        <NewsGroupSelect
                            name={'groupSource'}
                            isFilter={true}
                            defaultValue={paramState.groupIds ? [paramState.groupIds] : undefined}
                            customerEnabledGroupsOnly={true}
                            onChange={option => {
                                if (!option.value) {
                                    setParamState({
                                        groupIds: undefined,
                                    })
                                    return
                                }
                                setParamState({
                                    groupIds: option,
                                })
                            }}
                        />
                    </FilterOption>
                    {paramState.groupIds && isNumber(paramState.groupIds.value) && (
                        <FilterOption label={this.loc(t => t.filters.source)} forInputName="source" isSubFilter={true}>
                            <NewsSourceSelect
                                name={'source'}
                                isFilter={true}
                                defaultValue={paramState.sourceIds ? [paramState.sourceIds] : undefined}
                                newsGroupId={paramState.groupIds.value}
                                onChange={option => {
                                    if (!option.value) {
                                        setParamState({ sourceIds: undefined })
                                        return
                                    }

                                    setParamState({ sourceIds: option })
                                }}
                            />
                        </FilterOption>
                    )}
                    <FilterOption label={this.loc(t => t.filters.status)} forInputName={'status'}>
                        <Form.Select
                            name={'status'}
                            isFilter={true}
                            options={this.statusSelectOptions}
                            defaultValue={this.getStatusFilterDefaultValue(paramState)}
                            onChange={(option: SelectOption) => this.handleStatusSelect(option, setParamState)}
                        />
                    </FilterOption>
                    {paramState.status === InternalAlertStatusState.archived && (
                        <FilterOption
                            label={this.loc(t => t.filters.archivedYear)}
                            forInputName={'archivedYear'}
                            isSubFilter={true}
                        >
                            <Form.Select
                                name={'archivedYear'}
                                isFilter={true}
                                options={this.yearOptions}
                                defaultValue={this.getYearDefaultValue(paramState)}
                                onChange={(option: SelectOption) => this.handleYearSelect(option, setParamState)}
                            />
                        </FilterOption>
                    )}
                </FilterDropdown>
            </Row>
        )
    }

    private handleStatusSelect(
        selectedOption: SelectOption,
        setParamState: (newsState: NewParamState<SignalingFilters>) => void
    ) {
        setParamState({
            status: selectedOption.value as InternalAlertStatusState,
            archivedYear: undefined,
        })
    }

    private getStatusFilterDefaultValue(paramState: SignalingFilters) {
        let selectedOption

        if (paramState.status) {
            selectedOption = this.statusSelectOptions.find(option => option.value === paramState.status)
        }

        if (!selectedOption) {
            return undefined
        }

        return [selectedOption]
    }

    private handleYearSelect(
        selectedOption: SelectOption,
        setParamState: (newsState: NewParamState<SignalingFilters>) => void
    ) {
        setParamState({
            archivedYear: selectedOption.value as number,
        })
    }

    private getYearDefaultValue(paramState: SignalingFilters) {
        if (!paramState.archivedYear) {
            return undefined
        }

        const selectedOption = this.yearOptions.filter(option => option.value === paramState.archivedYear)

        return selectedOption
    }

    private getYearOptions() {
        const currentYear = new Date().getFullYear()

        // will result in [currentYear, currentYear-1, currentYear-2, ..., 2017]
        const yearRange = range(currentYear, 2016, -1)

        return yearRange.map(year => ({ label: year.toString(), value: year }))
    }
}
