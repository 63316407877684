import React from 'react'

import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize, notification } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import gql from 'graphql-tag'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationFn } from 'react-apollo'
import { LIST_RISK_TYPES } from '~/components/Domain/Compliance/Risks/RiskTypesTable'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'

interface Props {
    requestClose: () => void
}

const CREATE_RISK_TYPE = gql`
    mutation createRiskType($name: String) {
        createRiskType(name: $name) {
            id
            name
        }
    }
`

export class AddRiskTypeModal extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { requestClose } = this.props

        return (
            <GQLMutation
                mutation={CREATE_RISK_TYPE}
                refetchQueries={[
                    {
                        query: LIST_RISK_TYPES,
                    },
                ]}
            >
                {(mutate, { loading }) => (
                    <Form isCompact={true} onSubmit={this.handleSubmit(mutate)}>
                        <Modal
                            title={localize.translate(t => t.Customer.Compliance.Risks.RiskTypeModal.title)}
                            requestClose={requestClose}
                            loading={loading}
                            actions={closeModal => (
                                <Row alignRight={true}>
                                    <Button type={ButtonType.tertiary} onClick={closeModal}>
                                        {localize.translate(t => t.Generic.cancel)}
                                    </Button>
                                    <Button loading={loading} submit={true}>
                                        {localize.translate(t => t.Generic.save)}
                                    </Button>
                                </Row>
                            )}
                        >
                            <ErrorMessage path={'createRiskType'} />
                            <Field label={localize.translate(t => t.Customer.Compliance.Risks.RiskTypeModal.name)} forInput="name">
                                <Form.Input name={'name'} />
                            </Field>
                        </Modal>
                    </Form>
                )}
            </GQLMutation>
        )
    }

    private handleSubmit = (mutate: MutationFn) => async (state: FormState) => {
        const { requestClose } = this.props

        const response = await mutate({
            variables: {
                name: state.name,
            },
        })

        if (response && response.data && response.data.createRiskType) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))
            requestClose()
        }
    }
}
