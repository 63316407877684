import './Sidebar.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconType } from '~/components/Core/Icon/IconType'
import { NavLink } from 'react-router-dom'
import { Icon } from '~/components/Core/Icon/Icon'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { LogOutButton } from '../LogOutButton/LogOutButton'
import { TextLink } from '~/components/Core/Text/TextLink'
import { ReleaseNotesManager } from '~/components/Domain/ReleaseNotes/ReleaseNotesManager'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Column } from '~/components/Core/Layout/Column'
import { localize } from '~/bootstrap'

interface NavItem {
    label: string
    route: string
    routeIsSectionId?: boolean
    icon: IconType
}

interface Props {
    className?: ClassValue
    heading?: string
    navigation: NavItem[]
    hideLogoutButton?: boolean
    renderBottom?: JSX.Element
}

interface State {}

export class Sidebar extends React.PureComponent<Props, State> {
    private bem = new BEM('Sidebar')

    public render() {
        const { heading, navigation, className, hideLogoutButton, renderBottom } = this.props

        return (
            <nav className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('container')}>
                    <div>
                        {heading && (
                            <div className={this.bem.getElement('heading-container')}>
                                <ComponentTitle title={heading} />
                            </div>
                        )}
                        <ul className={this.bem.getElement('nav-links')}>
                            {navigation.map((item, index) => (
                                <li key={index} className={this.bem.getElement('nav-link-wrapper')}>
                                    {item.routeIsSectionId ? (
                                        <TextLink href={item.route} className={this.bem.getElement('text-link')}>
                                            <Icon className={this.bem.getElement('icon')} type={item.icon} />
                                            <p>{item.label}</p>
                                        </TextLink>
                                    ) : (
                                        <NavLink to={item.route} className={this.bem.getElement('nav-link')}>
                                            <Icon className={this.bem.getElement('icon')} type={item.icon} />
                                            <p>{item.label}</p>
                                        </NavLink>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                    {renderBottom && (
                        <Column className={this.bem.getElement('logout-container')}>{renderBottom}</Column>
                    )}
                    {!hideLogoutButton && (
                        <Column className={this.bem.getElement('logout-container')}>
                            <LogOutButton className={this.bem.getElement('logout-button')} />
                            <ReleaseNotesManager
                                render={(open, version) => (
                                    <Button
                                        type={ButtonType.noStyling}
                                        onClick={open}
                                        className={this.bem.getElement('version')}
                                    >
                                        {localize.translate(t => t.Generic.version)} {version}
                                    </Button>
                                )}
                            />
                        </Column>
                    )}
                </div>
            </nav>
        )
    }
}
