import './TopicWidget.scss'

import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { WidgetKey } from '../../CreateWidgetWidget/widgets'
import { WidgetContainer } from '../components/WidgetContainer'
import { localize } from '~/bootstrap'
import { Bold } from '~/components/Core/Typography/Bold'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import {
    DashboardTopicWidgetFragmentFragment,
    DashboardDepartmentSpecificWidgetMetadataType,
    DashboardWidgetContentFragmentFragment,
    DashboardWidgetLayoutTypeEnum,
    DashboardWidgetMetadataFragmentFragment,
} from '~/generated/graphql'
import { WidgetInfoLink } from '../components/WidgetInfoLink'
import { routes } from '~/views/routes'

interface Props {
    isEditing: boolean
    currentBreakpoint: DashboardWidgetLayoutTypeEnum | undefined
    onWidgetClick?: (widgetId: number, type: WidgetKey) => void
    onDeleteIconClick?: (widgetId: number) => void
    onModalStateChange?: ({ isOpening }: { isOpening: boolean }) => void
    isDetailWidget?: boolean
    id: number
    metadata?: DashboardWidgetMetadataFragmentFragment
    content?: DashboardWidgetContentFragmentFragment
    isForEffectiveness?: boolean
}

enum AssessmentNoteLevel {
    notAssessed = 'notAssessed',
    insufficient = 'insufficient',
    almostSufficient = 'almostSufficient',
    sufficient = 'sufficient',
}

export class TopicWidget extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('TopicWidget')
    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView.Widgets.TopicWidget)

    public render() {
        const { isEditing, onWidgetClick, onDeleteIconClick, isDetailWidget, currentBreakpoint } = this.props
        const { onModalStateChange } = this.props

        return (
            <WidgetContainer
                widgetInfo={this.getHeaderInfo()}
                isEditing={isEditing}
                className={this.bem.getClassName()}
                onWidgetClick={onWidgetClick}
                isDetailWidget={isDetailWidget}
                currentBreakpoint={currentBreakpoint}
                onDeleteIconClick={onDeleteIconClick}
                onModalStateChange={onModalStateChange}
            >
                <Column bigSpacing={true}>{this.renderContent()}</Column>
            </WidgetContainer>
        )
    }

    private getHeaderInfo() {
        const { id, isDetailWidget, isForEffectiveness } = this.props
        const { customName, departments, allDepartments } = this.getMetadata()
        const widgetKey = isForEffectiveness ? WidgetKey.topicEffectiveness : WidgetKey.topic

        if (isDetailWidget) {
            return {
                widgetId: id,
                widgetKey,
                departments,
                allDepartments: false,
            }
        }

        return {
            widgetId: id,
            customName,
            widgetKey,
            departments,
            allDepartments,
        }
    }

    private renderContent() {
        const { notAssessedCount, insufficientCount, almostSufficientCount, sufficientCount } = this.getCounts()
        const normsPageLink = routes.customer(this.context.customer.slug).legalFramework.index

        return (
            <Column>
                {this.renderRow(notAssessedCount, AssessmentNoteLevel.notAssessed, normsPageLink)}
                {this.renderRow(insufficientCount, AssessmentNoteLevel.insufficient, normsPageLink)}
                {this.renderRow(almostSufficientCount, AssessmentNoteLevel.almostSufficient, normsPageLink)}
                {this.renderRow(sufficientCount, AssessmentNoteLevel.sufficient, normsPageLink)}
            </Column>
        )
    }

    private getMetadata() {
        const { metadata } = this.props

        if (!metadata) {
            return {
                departments: [],
                allDepartments: false,
            }
        }

        const { name, departments, allDepartments } = metadata as DashboardDepartmentSpecificWidgetMetadataType

        return {
            customName: name ? name : undefined,
            departments: departments?.length ? departments.map(({ id, name }) => ({ id, name })) : [],
            allDepartments,
        }
    }

    private getCounts() {
        let notAssessedCount = 0
        let insufficientCount = 0
        let almostSufficientCount = 0
        let sufficientCount = 0

        const { content } = this.props
        if (content) {
            const {
                compliantCount,
                almostCompliantCount,
                notCompliantCount,
                notAssessedCount: notRatedCount,
            } = content as DashboardTopicWidgetFragmentFragment

            sufficientCount = compliantCount
            almostSufficientCount = almostCompliantCount
            insufficientCount = notCompliantCount
            notAssessedCount = notRatedCount
        }

        return { notAssessedCount, insufficientCount, almostSufficientCount, sufficientCount }
    }

    private renderRow(count: number, assessmentNoteLevel: AssessmentNoteLevel, link: string) {
        const metadata = this.props.metadata as DashboardDepartmentSpecificWidgetMetadataType | undefined
        const text = this.loc(t => t[assessmentNoteLevel])
        const containerClassName = this.bem.getElement('row-container', () => ({
            'is-notassessed': assessmentNoteLevel === AssessmentNoteLevel.notAssessed,
            'is-insufficient': assessmentNoteLevel === AssessmentNoteLevel.insufficient,
        }))

        return (
            <WidgetInfoLink departmentSpecificMetadata={metadata} to={link}>
                <Row className={containerClassName}>
                    <Bold className={this.bem.getElement('count')}>{count}</Bold>
                    <Paragraph subtle={true} className={this.bem.getElement('text')}>
                        {text}
                    </Paragraph>
                </Row>
            </WidgetInfoLink>
        )
    }
}
