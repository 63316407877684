import './CustomerFrameworkIconList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import {
    AlignIconList,
    FrameworkIconList,
} from '~/components/Domain/Customer/Framework/FrameworkIconList/FrameworkIconList'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { FrameworkIcon } from '~/components/Domain/Customer/Framework/FrameworkIcon/FrameworkIcon'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { CustomerFrameworkList } from './CustomerFrameworkList'

interface Props {
    className?: ClassValue
    customerFrameworks: CustomerFramework[]
    small?: boolean
    align?: AlignIconList
    maxVisibleFrameworks: number
    showAllActive: boolean
}

interface State {}

export class CustomerFrameworkIconList extends React.Component<Props, State> {
    public static defaultProps: Partial<Props> = {
        maxVisibleFrameworks: 3,
    }

    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('CustomerFrameworkIconList')

    public render() {
        const { className, customerFrameworks, align } = this.props

        if (!customerFrameworks) {
            return null
        }

        return (
            <FrameworkIconList align={align} className={this.bem.getClassName(className)}>
                {this.renderFrameworks()}
                {this.renderCounter()}
            </FrameworkIconList>
        )
    }

    private isInactive(customerFramework: CustomerFramework) {
        if (this.props.showAllActive === true) {
            return false
        }

        return this.context.activeProfiles.includes(customerFramework.id) === false
    }

    private getVisableFrameworkCount() {
        const { maxVisibleFrameworks, customerFrameworks } = this.props

        const fitsInMax = customerFrameworks.length <= maxVisibleFrameworks
        const visableFrameworks = fitsInMax ? maxVisibleFrameworks : maxVisibleFrameworks - 1

        return visableFrameworks
    }

    private renderFrameworks() {
        const { small, customerFrameworks } = this.props

        const visableFrameworks = this.getVisableFrameworkCount()

        const customerFrameworksToShow = customerFrameworks.slice(0, visableFrameworks)

        return (
            <ol className={this.bem.getElement('active-frameworks')}>
                {customerFrameworksToShow.map(customerFramework => (
                    <li className={this.bem.getElement('framework-icon')} key={customerFramework.id}>
                        <FrameworkIcon
                            frameworkType={customerFramework.icon}
                            color={customerFramework.color}
                            isInactive={this.isInactive(customerFramework)}
                            tooltip={customerFramework.name}
                            isSmall={small}
                        />
                    </li>
                ))}
            </ol>
        )
    }

    private renderCounter() {
        const { customerFrameworks } = this.props

        const visableFrameworks = this.getVisableFrameworkCount()

        const counterValue = customerFrameworks.length - visableFrameworks

        if (counterValue <= 0) {
            return
        }

        return (
            <Tooltip content={this.renderRestOfFrameworks()}>
                <span className={this.bem.getElement('counter')}>{`+${counterValue}`}</span>
            </Tooltip>
        )
    }

    private renderRestOfFrameworks() {
        const { maxVisibleFrameworks, customerFrameworks } = this.props

        const frameworksToShow = customerFrameworks.slice(maxVisibleFrameworks - 1)

        return (
            <CustomerFrameworkList
                className={this.bem.getElement('tooltip-list')}
                customerFrameworks={frameworksToShow}
            />
        )
    }
}
