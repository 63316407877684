import React from 'react'
import { TopicArticlesQuery } from '~/components/Domain/Compliance/Norms/TopicArticlesQuery'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { SelectableLawArticlesTable } from '~/components/Domain/Compliance/Norms/SelectableLawArticlesTable'

interface Props {
    topicId: number
    defaultCheckedArticleIds?: number[]
    checkedArticleIds?: number[]
    onToggleArticle?: (abstractLawArticleId: number, allIds: number[]) => void
    allChecked?: boolean
}

interface State {}

export class TopicWithArticlesContainer extends React.Component<Props, State> {
    public render() {
        const { defaultCheckedArticleIds = [], allChecked, topicId, checkedArticleIds, onToggleArticle } = this.props

        return (
            <TopicArticlesQuery topicId={topicId}>
                {({ data, loading }) => {
                    const topicWithArticles = data && data.topic ? data.topic : null

                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    if (!topicWithArticles) {
                        return <NoResults />
                    }

                    return (
                        <SelectableLawArticlesTable
                            linkedLawArticles={topicWithArticles.linkedLawArticles.filter(article => {
                                if (defaultCheckedArticleIds.includes(article.abstractLawArticleId)) {
                                    return true
                                }

                                return !article.expired
                            })}
                            checkedArticleIds={checkedArticleIds}
                            onToggleArticle={onToggleArticle}
                            allChecked={allChecked}
                        />
                    )
                }}
            </TopicArticlesQuery>
        )
    }
}
