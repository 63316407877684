import React from 'react'
import { MonitoringReportsDetail } from './MonitoringReportDetail'
import { MonitoringReportQuery } from './MonitoringReportQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { permissions } from '~/bootstrap'

interface Props {
    monitoringReportId: number
}

export class MonitoringReportsDetailContainer extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { monitoringReportId } = this.props

        if (!permissions.canViewMonitoringReports()) {
            return null
        }

        return (
            <MonitoringReportQuery monitoringReportId={monitoringReportId}>
                {(data, { refetch }) => <MonitoringReportsDetail monitoringReport={data} refetch={() => refetch()} />}
            </MonitoringReportQuery>
        )
    }
}
