import './LinkedItemCard.scss'

import React from 'react'

import { BEM } from '~/services/BEMService'
import { Link } from 'react-router-dom'
import { CustomerFrameworkIconList } from '../CustomerFramework/CustomerFrameworkIconList'
import { CustomerFrameworkType, ItemImpactLevel } from '~/generated/graphql'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize } from '~/bootstrap'

interface Props {
    itemData: LinkedItemData
}

export interface LinkedItemData {
    name: string
    to: string
    customerFrameworks?: CustomerFrameworkType[]
    impact?: ItemImpactLevel | null
}

interface State {}

export class LinkedItemCard extends React.PureComponent<Props, State> {
    private bem = new BEM('LinkedItemCard')
    private loc = localize.namespaceTranslate(t => t.ItemImpact)

    public render() {
        const { to, name, customerFrameworks, impact } = this.props.itemData

        return (
            <Link to={to} className={this.bem.getClassName()}>
                <div className={this.bem.getElement('card-header')}>
                    <Paragraph className={this.bem.getElement('title')} bold={true}>
                        {name}
                    </Paragraph>
                </div>
                <div className={this.bem.getElement('card-footer')}>
                    <CustomerFrameworkIconList customerFrameworks={customerFrameworks} />
                    {impact && this.renderImpact(impact)}
                </div>
            </Link>
        )
    }

    private renderImpact(impact: ItemImpactLevel) {
        return <Paragraph>{this.loc(t => t.impact, { impact: this.loc(t => t[impact]) })}</Paragraph>
    }
}
