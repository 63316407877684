import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { IgnoreNewTopicMutationVariables, IgnoreNewTopicDocument, IgnoreNewTopicMutation } from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    children: (
        mutation: MutationFn<IgnoreNewTopicMutation, IgnoreNewTopicMutationVariables>,
        options: MutationResult
    ) => JSX.Element
}

export class IgnoreNewTopic extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<IgnoreNewTopicMutation, IgnoreNewTopicMutationVariables> mutation={IgnoreNewTopicDocument}>
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
