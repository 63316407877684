import { localize } from '~/bootstrap'
import './ReviewInternalAlertModal.scss'
import React from 'react'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM } from '~/services/BEMService'
import { Row } from '~/components/Core/Layout/Row'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { NewsItemTag, NewsItemTagEnum } from '~/components/Domain/Signaling/NewsItemTag'
import { NewsUrlButton } from '~/components/Domain/News/NewsUrlButton'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { InboxInternalAlertQueryType } from './InboxInternalAlertsContainer'
import { ArchiveInternalAlertModal } from '../InternalAlert/ArchiveInternalAlertModal'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { ReturnInternalAlertModal } from '../InternalAlert/ReturnInternalAlertModal'
import { AcceptInternalAlertModal } from '../InternalAlert/AcceptInternalAlertModal'

interface Props {
    requestClose: () => void
    internalAlert: InboxInternalAlertQueryType
    refetch: () => void
}

interface State {}

export class ReviewInternalAlertModal extends React.Component<Props, State> {
    private bem = new BEM('ReviewInternalAlertModal')
    private loc = localize.namespaceTranslate(t => t.Customer.Inbox.InboxOverview.modal)

    public render() {
        const { requestClose, internalAlert } = this.props
        const { note } = internalAlert
        const { title, publicationDate, sources, originalSource, customSourceUrl } = internalAlert.news

        const content = internalAlert.news.translatedContent ? internalAlert.news.translatedContent : undefined

        return (
            <Modal
                title={title}
                requestClose={requestClose}
                className={this.bem.getClassName()}
                hideButtons
                actions={() => this.renderButtons()}
            >
                <Row className={this.bem.getElement('date-source-container')}>
                    {sources && sources?.length > 0 && this.renderSources(sources)}
                    {publicationDate && (
                        <Paragraph verySubtle>
                            <DateFormat date={new Date(publicationDate)} readable includeTime={true} noWeekday />
                        </Paragraph>
                    )}
                </Row>
                <div className={this.bem.getElement('url')}>
                    <NewsUrlButton news={{ originalSource, customSourceUrl }} />
                </div>
                <Paragraph className={this.bem.getElement('content')}>{content}</Paragraph>
                {note && (
                    <div className={this.bem.getElement('note-container')}>
                        <Paragraph bold className={this.bem.getElement('note-title')}>
                            {this.loc(t => t.noteTitle)}
                        </Paragraph>
                        <Paragraph>{note}</Paragraph>
                    </div>
                )}
            </Modal>
        )
    }

    private renderSources(sources: NonNullable<InboxInternalAlertQueryType['news']['sources']>) {
        if (sources.length === 1 && sources[0].name) {
            return (
                <NewsItemTag
                    type={NewsItemTagEnum.internalAlert}
                    key={`source-${sources[0].id}`}
                    label={sources[0].name}
                />
            )
        }
        return (
            <NewsItemTag
                type={NewsItemTagEnum.internalAlert}
                key={`source-${sources[0].id}`}
                label={localize.translate(t => t.News.Attributes.sources, { count: sources.length })}
                tooltipContent={sources.map(s => (
                    <div key={s.id}>
                        <Paragraph>{s.name}</Paragraph>
                    </div>
                ))}
            />
        )
    }

    private renderButtons() {
        return (
            <Row spaceBetween={true} alignRight={true}>
                {this.renderArchiveButton()}
                {this.renderQuestionButton()}
                {this.renderAcceptButton()}
            </Row>
        )
    }

    private renderArchiveButton() {
        const { refetch, internalAlert } = this.props

        return (
            <ModalManager
                renderModal={closeModal => (
                    <ArchiveInternalAlertModal
                        internalAlert={internalAlert}
                        requestClose={closeModal}
                        onArchive={refetch}
                    />
                )}
                render={openModal => (
                    <Button
                        icon={IconType.archive}
                        type={ButtonType.secondary}
                        className={this.bem.getElement('icon-button')}
                        onClick={openModal}
                    />
                )}
            />
        )
    }

    private renderQuestionButton() {
        const { refetch, internalAlert } = this.props

        return (
            <ModalManager
                renderModal={closeModal => (
                    <ReturnInternalAlertModal
                        internalAlert={internalAlert}
                        requestClose={closeModal}
                        onReturn={refetch}
                    />
                )}
                render={openModal => (
                    <Button
                        icon={IconType.questionmark}
                        type={ButtonType.secondary}
                        className={this.bem.getElement('icon-button')}
                        onClick={openModal}
                    />
                )}
            />
        )
    }

    private renderAcceptButton() {
        const { refetch, internalAlert } = this.props

        return (
            <ModalManager
                renderModal={closeModal => (
                    <AcceptInternalAlertModal
                        internalAlert={internalAlert}
                        requestClose={closeModal}
                        onAccept={refetch}
                    />
                )}
                render={openModal => (
                    <Button onClick={openModal}>
                        {localize.translate(t => t.Customer.Inbox.InboxOverview.modal.link)}
                    </Button>
                )}
            />
        )
    }
}
