import React from 'react'
import { Select, SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { localize } from '~/bootstrap'

export enum RiskFilterType {
    netto = 'netto',
    nettoAccepted = 'nettoAccepted',
    bruto = 'bruto',
}

interface Props {
    onChange: (option: SelectOption | null) => void
    defaultValue?: RiskFilterType
}

interface State {}

export class RiskFilter extends React.PureComponent<Props, State> {
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Risks)

    private options = [
        { label: this.loc(t => t.netRisk), value: RiskFilterType.netto },
        { label: this.loc(t => t.grossRisk), value: RiskFilterType.bruto },
        { label: this.loc(t => t.netAcceptedRisk), value: RiskFilterType.nettoAccepted },
    ]

    public render() {
        const { onChange } = this.props

        return (
            <Select
                name={'riskFilter'}
                isFilter={true}
                searchable={false}
                onChange={(option: SelectOption) => {
                    onChange(option)
                }}
                placeholder={this.loc(t => t.netRisks)}
                options={this.options}
                defaultValue={this.getDefaultValue()}
            />
        )
    }

    private getDefaultValue() {
        const { defaultValue } = this.props

        if (!defaultValue) {
            return undefined
        }

        const optionForDefaultValue = this.options.find(option => option.value === defaultValue)

        return optionForDefaultValue ? [optionForDefaultValue] : undefined
    }
}
