import './NewsSourcesEditListItem.scss'

import React from 'react'
import { NewsGroupType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ExpandableTableListItem } from '~/components/Core/DataDisplay/TableList/ExpandableTableListItem'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { DisabledGroupSourceMap } from '../NewsSourcesEditView'

interface Props {
    onChangeGroup: (checked: boolean, groupId: number) => void
    onChangeSource: (checked: boolean, sourceId: number, groupId: number) => void
    sourcesToDisable: DisabledGroupSourceMap
    newsGroup: NewsGroupType
}

export class NewsSourcesEditListItem extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('NewsSourcesEditListItem')

    public render() {
        const { newsGroup, sourcesToDisable, onChangeGroup } = this.props

        const newsSourcesCount = newsGroup.sources.length
        const disabledSourceCount = sourcesToDisable.get(newsGroup.id)?.size || 0
        const isIndeterminate = disabledSourceCount > 0 && disabledSourceCount < newsSourcesCount

        return (
            <ExpandableTableListItem
                className={this.bem.getClassName()}
                key={`group-${newsGroup.name}`}
                renderExpanded={() => this.renderSources(newsGroup)}
            >
                <Checkbox
                    className={this.bem.getElement('group-checkbox')}
                    name={`${newsGroup.id}`}
                    indeterminate={isIndeterminate}
                    checked={disabledSourceCount === 0}
                    onChange={checked => onChangeGroup(checked, newsGroup.id)}
                    label={newsGroup.name}
                />
            </ExpandableTableListItem>
        )
    }

    private renderSources(newsGroup: NewsGroupType) {
        const { sourcesToDisable, onChangeSource } = this.props
        const groupSourcesToDisable = sourcesToDisable.get(newsGroup.id)

        return newsGroup.sources.map(newsSource => (
            <Form.Checkbox
                className={this.bem.getElement('source-item')}
                key={`source-${newsSource.id}`}
                name={`${newsSource.id}`}
                checked={!groupSourcesToDisable?.has(newsSource.id)}
                onChange={(checked: boolean) => onChangeSource(checked, newsSource.id, newsGroup.id)}
                label={newsSource.name}
            />
        ))
    }
}
