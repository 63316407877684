import './RiskGraphIndicator.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'

interface Props {
    className?: ClassValue
    style?: React.CSSProperties
    tooltip?: React.ReactNode
    foreground?: boolean
}

interface State {}

export class RiskGraphIndicator extends React.PureComponent<Props, State> {
    private bem = new BEM('RiskGraphIndicator', () => ({
        foreground: !!this.props.foreground,
    }))

    public render() {
        const { children, className, tooltip, style } = this.props

        const renderTooltip = (
            <Tooltip content={tooltip}>
                <React.Fragment>{children}</React.Fragment>
            </Tooltip>
        )

        return (
            <div style={style} className={this.bem.getClassName(className)}>
                {tooltip ? renderTooltip : children}
            </div>
        )
    }
}
