import './DashboardTaskWidget.scss'

import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { WidgetKey } from '../../CreateWidgetWidget/widgets'
import { WidgetContainer } from '../components/WidgetContainer'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize } from '~/bootstrap'
import { Bold } from '~/components/Core/Typography/Bold'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import {
    DashboardTaskWidgetFragmentFragment,
    DashboardDepartmentSpecificWidgetMetadataType,
    DashboardWidgetContentFragmentFragment,
    DashboardWidgetMetadataFragmentFragment,
    DashboardWidgetLayoutTypeEnum,
} from '~/generated/graphql'
import { WidgetInfoLink } from '../components/WidgetInfoLink'
import { routes } from '~/views/routes'

interface Props {
    isEditing: boolean
    onWidgetClick?: (widgetId: number, type: WidgetKey) => void
    onDeleteIconClick?: (widgetId: number) => void
    onModalStateChange?: ({ isOpening }: { isOpening: boolean }) => void
    isDetailWidget?: boolean
    id: number
    currentBreakpoint: DashboardWidgetLayoutTypeEnum | undefined
    metadata?: DashboardWidgetMetadataFragmentFragment
    content?: DashboardWidgetContentFragmentFragment
}

interface RowInfo {
    total: number
    overdue: number
    highPriority: number
    translationKey: string
    link: string
}

export class DashboardTaskWidget extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private planningAllTasksPageLink = routes.customer(this.context.customer.slug).tasks.index
    private employeeId = this.context.employee?.id

    private bem = new BEM('DashboardTaskWidget', () => ({
        'is-detail': this.props.isDetailWidget,
    }))
    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView.Widgets.TaskWidget)

    public render() {
        const { isEditing, onWidgetClick, onDeleteIconClick, isDetailWidget, currentBreakpoint } = this.props
        const { onModalStateChange } = this.props

        return (
            <WidgetContainer
                widgetInfo={this.getHeaderInfo()}
                isEditing={isEditing}
                className={this.bem.getClassName()}
                onWidgetClick={onWidgetClick}
                isDetailWidget={isDetailWidget}
                currentBreakpoint={currentBreakpoint}
                onDeleteIconClick={onDeleteIconClick}
                onModalStateChange={onModalStateChange}
            >
                <Column>{this.renderContent()}</Column>
            </WidgetContainer>
        )
    }

    private getHeaderInfo() {
        const { id, isDetailWidget } = this.props
        const { customName, departments, allDepartments } = this.getMetadata()

        if (isDetailWidget) {
            return {
                widgetId: id,
                widgetKey: WidgetKey.tasks,
                departments,
                allDepartments: false,
            }
        }

        return {
            widgetId: id,
            customName,
            widgetKey: WidgetKey.tasks,
            departments,
            allDepartments,
        }
    }

    private renderContent() {
        return (
            <Column className={this.bem.getElement('content-container')}>
                {this.renderInboxTasksInfo()}
                {this.renderControlTasksInfo()}
                {this.renderTopicTasksInfo()}
                {this.renderGenericTasksInfo()}
                {this.renderOtherTasksInfo()}
            </Column>
        )
    }

    private getMetadata() {
        const { metadata } = this.props

        if (!metadata) {
            return { departments: [], allDepartments: false }
        }

        const { name, departments, allDepartments } = metadata as DashboardDepartmentSpecificWidgetMetadataType

        return {
            customName: name ? name : undefined,
            departments: departments?.length ? departments.map(({ id, name }) => ({ id, name })) : [],
            allDepartments,
        }
    }

    private renderInboxTasksInfo() {
        const link = `${this.planningAllTasksPageLink}?tableView=groupView&forEmployeeIds%5B0%5D=${this.employeeId}&activeDepartmentOnly=true&linkedItemType=ALERT`
        let total = 0
        let overdue = 0
        let highPriority = 0

        const { content } = this.props
        if (content) {
            const { inboxCount } = content as DashboardTaskWidgetFragmentFragment
            total = inboxCount.total
            overdue = inboxCount.overdue
            highPriority = inboxCount.highPrio
        }

        return this.renderInfo({ total, overdue, highPriority, translationKey: 'inbox', link })
    }

    private renderControlTasksInfo() {
        const link = `${this.planningAllTasksPageLink}?tableView=groupView&forEmployeeIds%5B0%5D=${this.employeeId}&activeDepartmentOnly=true&linkedItemType=CONTROL`
        let total = 0
        let overdue = 0
        let highPriority = 0

        const { content } = this.props
        if (content) {
            const { controlCount } = content as DashboardTaskWidgetFragmentFragment
            total = controlCount.total
            overdue = controlCount.overdue
            highPriority = controlCount.highPrio
        }

        return this.renderInfo({ total, overdue, highPriority, translationKey: 'control', link })
    }

    private renderTopicTasksInfo() {
        const link = `${this.planningAllTasksPageLink}?tableView=groupView&forEmployeeIds%5B0%5D=${this.employeeId}&activeDepartmentOnly=true&linkedItemType=TOPIC`
        let total = 0
        let overdue = 0
        let highPriority = 0

        const { content } = this.props
        if (content) {
            const { topicCount } = content as DashboardTaskWidgetFragmentFragment
            total = topicCount.total
            overdue = topicCount.overdue
            highPriority = topicCount.highPrio
        }

        return this.renderInfo({ total, overdue, highPriority, translationKey: 'topic', link })
    }

    private renderGenericTasksInfo() {
        const link = this.planningAllTasksPageLink
        let total = 0
        let overdue = 0
        let highPriority = 0

        const { content } = this.props
        if (content) {
            const { genericCount } = content as DashboardTaskWidgetFragmentFragment
            total = genericCount.total
            overdue = genericCount.overdue
            highPriority = genericCount.highPrio
        }

        return this.renderInfo({ total, overdue, highPriority, translationKey: 'generic', link })
    }

    private renderOtherTasksInfo() {
        const link = `${this.planningAllTasksPageLink}?tableView=groupView`
        let total = 0
        let overdue = 0
        let highPriority = 0

        const { content } = this.props
        if (content) {
            const { otherCount } = content as DashboardTaskWidgetFragmentFragment
            total = otherCount.total
            overdue = otherCount.overdue
            highPriority = otherCount.highPrio
        }

        return this.renderInfo({ total, overdue, highPriority, translationKey: 'other', link })
    }

    private renderInfo({ total, overdue, highPriority, translationKey, link }: RowInfo) {
        const metadata = this.props.metadata as DashboardDepartmentSpecificWidgetMetadataType | undefined
        const label = this.loc(t => t[translationKey])

        return (
            <WidgetInfoLink departmentSpecificMetadata={metadata} to={link}>
                <Row>
                    {this.renderCount({ total, overdue })}
                    <Column extraSmallSpacing={true} className={this.bem.getElement('info-container')}>
                        <Paragraph bold={true}>{label}</Paragraph>
                        {this.renderSubText({ overdue, highPriority })}
                    </Column>
                </Row>
            </WidgetInfoLink>
        )
    }

    private renderCount({ total, overdue }: { total: number; overdue: number }) {
        const danger = overdue > 0

        return (
            <div className={this.bem.getElement('count-container', () => ({ danger }))}>
                <Paragraph className={this.bem.getElement('count', () => ({ danger, 'is-zero': total === 0 }))}>
                    {total}
                </Paragraph>
            </div>
        )
    }

    private renderSubText({ overdue, highPriority }: { overdue: number; highPriority: number }) {
        if (overdue === 0 && highPriority === 0) {
            return
        }

        return (
            <Row className={this.bem.getElement('sub-container')}>
                {this.renderOverdueText(overdue)}
                {this.renderHighPriorityText(highPriority)}
            </Row>
        )
    }

    private renderOverdueText(overdue: number) {
        if (overdue === 0) {
            return
        }

        return (
            <Row extraSmallSpacing={true} className={this.bem.getElement('sub-text-container')}>
                <Bold className={this.bem.getElement('sub-count')}>{overdue}</Bold>
                <Paragraph subtle={true} className={this.bem.getElement('sub-text')}>
                    {this.loc(t => t.overdue)}
                </Paragraph>
            </Row>
        )
    }

    private renderHighPriorityText(highPriority: number) {
        if (highPriority === 0) {
            return
        }

        return (
            <Row extraSmallSpacing={true} className={this.bem.getElement('sub-text-container')}>
                <Icon type={IconType.prioHigh} className={this.bem.getElement('flag')} />
                <Bold className={this.bem.getElement('sub-count')}>{highPriority}</Bold>
                <Paragraph subtle={true} className={this.bem.getElement('sub-text')}>
                    {this.loc(t => t.highPriority)}
                </Paragraph>
            </Row>
        )
    }
}
