import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { ArchiveControlsDocument, ArchiveControlsMutation, ArchiveControlsMutationVariables } from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

interface Props {
    children: (mutation: ArchiveControlsMutationFN, options: MutationResult) => JSX.Element
}

export type ArchiveControlsMutationFN = MutationFn<ArchiveControlsMutation, ArchiveControlsMutationVariables>

export class ArchiveControls extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<ArchiveControlsMutation, ArchiveControlsMutationVariables> mutation={ArchiveControlsDocument}>
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
