import React from 'react'
import { SelectionBox } from '~/components/Core/Feedback/SelectionBox/SelectionBox'
import { IconType } from '~/components/Core/Icon/IconType'
import { localize } from '~/bootstrap'
import { DepartmentAlertDataType, TaskType } from '~/generated/graphql'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { CreateControlTaskModal } from '../../CreateControlTaskModal/CreateControlTaskModal'
import { IgnoreTaskControlModal } from '../IgnoreTaskControlModal/IgnoreTaskControlModal'
import { IgnoreControlAlertModal } from '../../InboxControl/IgnoreControlAlertModal'

interface Props {
    tasks?: TaskType[]
    alertDatas?: DepartmentAlertDataType[]
    onSelectionClose?: () => void
    refetch?: Function
    isOpen: boolean
}

interface State {}

export class TaskControlSelectionBox extends React.PureComponent<Props, State> {
    public render() {
        const { onSelectionClose, tasks, alertDatas, refetch } = this.props

        if (tasks?.length) {
            return (
                <IgnoreTaskControlModal onIgnore={onSelectionClose} tasks={tasks} refetch={refetch}>
                    {this.renderModalManager}
                </IgnoreTaskControlModal>
            )
        }

        if (alertDatas?.length) {
            return (
                <IgnoreControlAlertModal alertDatas={alertDatas} onIgnore={this.handleCreate}>
                    {this.renderModalManager}
                </IgnoreControlAlertModal>
            )
        }

        return null
    }

    private renderModalManager = (openIgnoreModal: () => void) => {
        const { onSelectionClose, tasks, alertDatas, isOpen } = this.props
        const selectedAmount = tasks?.length || alertDatas?.length || 0

        return (
            <ModalManager
                render={openCreateModal => (
                    <SelectionBox
                        buttons={this.getButtons(openIgnoreModal, openCreateModal)}
                        selectedAmount={selectedAmount}
                        onClose={onSelectionClose}
                        isOpen={isOpen}
                    />
                )}
                renderModal={requestClose => (
                    <CreateControlTaskModal
                        onCreate={this.handleCreate}
                        requestClose={requestClose}
                        tasks={tasks}
                        alertDatas={alertDatas}
                    />
                )}
            />
        )
    }

    private getButtons(openIgnoreModal: () => void, openCreateModal: () => void) {
        return [
            {
                icon: IconType.ignoreControlTask,
                label: localize.translate(t => t.Generic.archive),
                onClick: openIgnoreModal,
            },
            {
                icon: IconType.createControlTask,
                label: localize.translate(t => t.Entities.ReviewTask),
                onClick: openCreateModal,
            },
        ]
    }

    private handleCreate = () => {
        const { refetch, onSelectionClose } = this.props

        if (refetch) {
            refetch()
        }

        if (onSelectionClose) {
            onSelectionClose()
        }
    }
}
