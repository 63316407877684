import './EditRiskModalHeaderAction.scss'

import React from 'react'
import { Button } from '~/components/Core/Button/Button'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { CreateTaskModalContainer } from '~/components/Domain/Task/CreateTaskModalContainer'
import { InlineTaskWidgetContainer } from '~/components/Domain/Task/InlineTaskWidget/InlineTaskWidgetContainer'
import { BEM } from '~/services/BEMService'
import { Risk } from './EditRiskModal'
import { Guard } from '~/components/Core/Guard/Guard'
import { permissions } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    risk: Risk
}

export class EditRiskModalHeaderAction extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('EditRiskModalHeaderAction')
    public render() {
        const { risk } = this.props
        return (
            <Row alignRight={true}>
                <InlineTaskWidgetContainer linkedItem={risk} />
                <Guard condition={permissions.canCreateTasks(this.context.activeDepartmentId)}>
                    <ModalManager
                        renderModal={closeModal => (
                            <CreateTaskModalContainer linkedItem={risk} requestClose={closeModal} />
                        )}
                        render={openModal => (
                            <Button
                                rounded={true}
                                icon={IconType.taskAdd}
                                onClick={openModal}
                                className={this.bem.getElement('button')}
                            />
                        )}
                    />
                </Guard>
            </Row>
        )
    }
}
