import './CreateTaskTemplateModalContainer.scss'

import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Column } from '~/components/Core/Layout/Column'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { CreateTaskTemplate, CreateTaskTemplateMutationFN } from '../Mutations/CreateTaskTemplate'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { isNumber } from 'lodash-es'
import {
    CreateConsultantTaskTemplate,
    CreateConsultantTaskTemplateMutationFN,
} from '../ConsultantTaskTemplate/mutations/CreateConsultantTaskTemplate'
import { FrameworkSelect } from '../../Framework/FrameworkSelect'
import { ConsultantTaskTemplateTypeEnum } from '~/generated/graphql'

interface Props {
    requestClose: () => void
    onCreate?: () => void
    isSubTask?: boolean
    parentTaskTemplateName?: string
    parentTaskTemplateId?: number
    forConsultantTemplate?: boolean
}

type MutationFN = CreateConsultantTaskTemplateMutationFN | CreateTaskTemplateMutationFN

export class CreateTaskTemplateModalContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('CreateTaskTemplateModalContainer')
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.TaskTemplatesOverviewView.TaskTemplateModal)
    private formLoc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks.TaskFormFields)

    public render() {
        const { requestClose, isSubTask, forConsultantTemplate } = this.props
        const title = isSubTask ? this.loc(t => t.newSubTaskTitle) : this.loc(t => t.newTitle)

        if (forConsultantTemplate) {
            return (
                <CreateConsultantTaskTemplate>
                    {(mutate, { loading }) => (
                        <Form onSubmit={this.handleSubmit(mutate)}>
                            <Modal
                                title={title}
                                loading={loading}
                                submitForm={true}
                                confirmButtonLabel={localize.translate(t => t.Generic.save)}
                                requestClose={requestClose}
                                className={this.bem.getClassName()}
                            >
                                <ErrorMessage path="createConsultantTaskTemplate" />
                                {this.renderModalContent()}
                            </Modal>
                        </Form>
                    )}
                </CreateConsultantTaskTemplate>
            )
        }

        return (
            <CreateTaskTemplate>
                {(mutate, { loading }) => (
                    <Form onSubmit={this.handleSubmit(mutate)}>
                        <Modal
                            title={title}
                            loading={loading}
                            submitForm={true}
                            confirmButtonLabel={localize.translate(t => t.Generic.save)}
                            requestClose={requestClose}
                            className={this.bem.getClassName()}
                        >
                            <ErrorMessage path="createTaskTemplate" />
                            {this.renderModalContent()}
                        </Modal>
                    </Form>
                )}
            </CreateTaskTemplate>
        )
    }

    private renderModalContent() {
        return (
            <Column>
                {this.renderParentName()}
                {this.renderFormFields()}
                {this.renderSubTasks()}
            </Column>
        )
    }

    private renderParentName() {
        const { isSubTask, parentTaskTemplateName } = this.props

        if (!isSubTask || !parentTaskTemplateName) {
            return
        }

        return (
            <Column smallSpacing={true} className={this.bem.getElement('subtask-name-container')}>
                <Field forInput="parentName" label={this.formLoc(t => t.parentTask)} />
                <Row smallSpacing={true}>
                    <Icon type={IconType.parentTask} />
                    <Paragraph bold={true}>{parentTaskTemplateName}</Paragraph>
                </Row>
            </Column>
        )
    }

    private renderFormFields() {
        const { forConsultantTemplate, isSubTask } = this.props

        return (
            <Column smallSpacing={true}>
                {forConsultantTemplate && !isSubTask && this.renderConsultantFields()}
                <Paragraph bold={true}>{this.formLoc(t => t.taak)}</Paragraph>
                <Field forInput="name">
                    <Form.Input name="name" placeholder={this.formLoc(t => t.taskPlaceholder)} />
                </Field>
                <Field forInput="description">
                    <Form.Input
                        type="textarea"
                        name="description"
                        placeholder={this.formLoc(t => t.descriptionPlaceholder)}
                    />
                </Field>
                {!forConsultantTemplate && (
                    <>
                        <Paragraph bold={true}>{this.formLoc(t => t.tags)}</Paragraph>
                        <Field forInput="tags">
                            <Form.TaskTagsSelect name="tags" />
                        </Field>
                    </>
                )}
            </Column>
        )
    }

    private renderSubTasks() {
        const { isSubTask } = this.props

        if (isSubTask) {
            return
        }

        return (
            <Column className={this.bem.getElement('subtasks-container')}>
                <SectionTitle bold={true}>
                    {localize.translate(t => t.Customer.Task.viewTaskModal.subTasks)}
                </SectionTitle>
                <Row alignCenter={true}>
                    <SectionTitle bold={true} subtle={true} className={this.bem.getElement('subtasks-message')}>
                        {localize.translate(t => t.Customer.Task.viewTaskModal.noSubTasksMessage)}
                    </SectionTitle>
                </Row>
            </Column>
        )
    }

    private handleSubmit = (mutate: MutationFN) => async (formState: FormState) => {
        const { forConsultantTemplate } = this.props

        const customerId = forConsultantTemplate ? undefined : this.context.customer.id
        const fields = this.getFormFields(formState)

        const response = await mutate({ variables: { customerId: customerId!, fields } })

        if (
            response &&
            response.data &&
            ('createTaskTemplate' in response.data || 'createConsultantTaskTemplate' in response.data)
        ) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))

            const { requestClose, onCreate } = this.props
            requestClose()

            if (onCreate) {
                onCreate()
            }
        }
    }

    private renderConsultantFields() {
        const typeOptions = Object.values(ConsultantTaskTemplateTypeEnum).map(v => ({
            label: this.loc(t => t[v]),
            value: v,
        }))
        const defaultOption = typeOptions.find(t => t.value === ConsultantTaskTemplateTypeEnum.checklist)

        return (
            <Row fullWidth={true} flexStart={true} className={this.bem.getElement('consultant-fields-container')}>
                <Column extraSmallSpacing={true}>
                    <Paragraph bold={true}>{this.loc(t => t.type)}</Paragraph>
                    <Field forInput="type">
                        <Form.Select
                            name="type"
                            options={typeOptions}
                            defaultValue={defaultOption ? [defaultOption] : undefined}
                        />
                    </Field>
                </Column>
                <Column extraSmallSpacing={true}>
                    <Paragraph bold={true}>{this.loc(t => t.frameworks)}</Paragraph>
                    <Field forInput="frameworkIds">
                        <FrameworkSelect multi={true} placeholder={this.loc(t => t.frameworksPlaceholder)} />
                    </Field>
                </Column>
            </Row>
        )
    }

    private getFormFields(formState: FormState) {
        const { parentTaskTemplateId, isSubTask, forConsultantTemplate } = this.props

        const name = formState.name ? formState.name : undefined
        const description = formState.description ? formState.description : undefined
        const tags = this.transformTagOptions(formState.tags)
        const parentId = isSubTask && isNumber(parentTaskTemplateId) ? parentTaskTemplateId : undefined

        let type: ConsultantTaskTemplateTypeEnum | undefined
        let frameworkIds: number[] | undefined
        if (forConsultantTemplate && !isSubTask) {
            type = formState.type ? formState.type.value : ConsultantTaskTemplateTypeEnum.checklist
            frameworkIds = formState.frameworkIds?.length
                ? formState.frameworkIds.map((f: SelectOption) => f.value)
                : undefined
        }

        return { name, description, tags, parentId, type, frameworkIds }
    }

    private transformTagOptions(tags?: SelectOption[] | null) {
        if (tags === undefined) {
            return
        }

        if (tags === null) {
            return []
        }

        return tags
            .filter(tag => tag.value)
            .map(tag => {
                if (tag.__isNew__ && typeof tag.value === 'string') {
                    return { tagName: tag.value }
                }

                return { tagId: tag.value as number }
            })
    }
}
