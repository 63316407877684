import React from 'react'
import { MutationFn } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    RemoveControlFromTopicMutationResult,
    RemoveControlFromTopicDocument,
    RemoveControlFromTopicMutation,
    RemoveControlFromTopicMutationVariables,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

export type RemoveControlFromTopicFN = MutationFn<
    RemoveControlFromTopicMutation,
    RemoveControlFromTopicMutationVariables
>

interface Props {
    children: (mutate: RemoveControlFromTopicFN, result: RemoveControlFromTopicMutationResult) => React.ReactNode
}

export class RemoveControlFromTopic extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<RemoveControlFromTopicMutation, RemoveControlFromTopicMutationVariables>
                mutation={RemoveControlFromTopicDocument}
            >
                {(mutate, result) => children(mutate, result)}
            </GQLMutation>
        )
    }
}
