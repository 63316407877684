import React from 'react'
import { LinkList, LinkListItem } from '~/components/Core/Layout/LinkList'
import { GroupedItem } from '~/graphql/types/LinkedItem'
import { IconType } from '~/components/Core/Icon/IconType'
import { routes } from '~/views/routes'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    linkedItems: GroupedItem[]
}

interface State {}

export class SortedItemsList extends React.Component<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const sortedItems = this.getSortedLinkedItems(this.props.linkedItems)
        return <LinkList items={sortedItems} />
    }

    private getSortedLinkedItems(linkedItems: GroupedItem[]): LinkListItem[] {
        const ungroupedItems: any[] = []

        for (const group of linkedItems) {
            ungroupedItems.push(...group.items)
        }
        ungroupedItems.sort((item1, item2) => {
            const start1 = 'publicationDate' in item1 ? new Date(item1.publicationDate) : new Date(item1.createdAt)
            const start2 = 'publicationDate' in item2 ? new Date(item2.publicationDate) : new Date(item2.createdAt)

            return start2.getTime() - start1.getTime()
        })
        return ungroupedItems.map(this.getFormattedItem)
    }

    private getFormattedItem = (item: any) => {
        if (this.isLeadingAttachment(item)) {
            const routeOrHrefOrPdf = item.attachments[0].mimetype === 'application/pdf' ? 'pdf' : 'href'

            return {
                label: this.getNameForItem(item),
                icon: item.attachmentIsLeading ? IconType.attachment : IconType.file,
                [routeOrHrefOrPdf]: this.getRouteForItem(item),
            }
        }

        return {
            label: this.getNameForItem(item),
            icon: item.attachmentIsLeading ? IconType.attachment : IconType.file,
            route: this.getRouteForItem(item),
        }
    }

    private isLeadingAttachment(item: any) {
        return item.attachmentIsLeading && item.attachments && item.attachments[0]
    }

    private getNameForItem(item: any) {
        if (item.__typename === 'LawArticleType' && item.law) {
            return `${item.law.name} - ${item.title}`
        }

        if (item.__typename === 'NewsItemType') {
            return (
                <>
                    {item.title}
                    <br />
                    <DateFormat noWeekday={true} date={new Date(item.publicationDate)} readable={true} />
                </>
            )
        }

        return item.name || item.title || '-'
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private getRouteForItem(item: any) {
        if (item.attachmentIsLeading) {
            const attachmentPath = item.attachments && item.attachments.length > 0 ? item.attachments[0].path : null
            if (attachmentPath) {
                return attachmentPath
            }
        }

        if (item.__typename === 'RadarItemType') {
            return routes.customer(this.context.customer.slug).radar.detail.view(item.id)
        }

        if (item.__typename === 'ConsultationType') {
            return routes.customer(this.context.customer.slug).consultations.detail.view(item.id)
        }

        if (item.__typename === 'NewsItemType') {
            return routes.customer(this.context.customer.slug).news.detail.view(item.id)
        }

        if (item.__typename === 'TopicType') {
            return routes.customer(this.context.customer.slug).legalFramework.topic.view(item.id)
        }

        if (item.__typename === 'LawArticleType' && item.isSingleArticle) {
            return routes.customer(this.context.customer.slug).lawAndRegulation.detail.singleArticle(item.id)
        }

        if (item.__typename === 'LawArticleType') {
            return routes
                .customer(this.context.customer.slug)
                .lawAndRegulation.detail.article(item.law.abstractLawId, item.id)
        }

        return '/'
    }
}
