import './NoResults.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Center } from '~/components/Core/Layout/Center'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import illustration from './no-results.svg'
import { localize } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'

interface Props {
    className?: ClassValue
    label?: string
    showButton?: boolean
}

export class NoResults extends React.PureComponent<Props> {
    private bem = new BEM('NoResults')

    private loc = localize.namespaceTranslate(t => t.Customer.PageQuery)

    public render() {
        const { className, label } = this.props

        return (
            <Center>
                <div className={this.bem.getClassName(className)}>
                    <img src={illustration} alt={this.loc(t => t.notFoundImgAlt)} />
                    {!label && <h3 className={this.bem.getElement('title')}>{this.loc(t => t.notFoundTitle)}</h3>}
                    <Column smallSpacing={true}>
                        <Paragraph className={this.bem.getElement('text')}>
                            {!label ? this.loc(t => t.notFoundDescription) : label}
                        </Paragraph>
                    </Column>
                </div>
            </Center>
        )
    }
}
