import './Card.scss'

import React from 'react'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { LocalStorageService, LocalStorageKeys } from '~/services/LocalStorageService'

interface Props {
    className?: ClassValue
    selected?: boolean
    onCheckChange?: (checked: boolean) => void
    checked?: boolean
    disabled?: boolean
    small?: boolean
    to?: string
    onClick?: () => void
    isFirstCardInList?: boolean
    isCheckboxActive?: boolean
}

interface State {}

export class Card extends React.PureComponent<Props, State> {
    private bem = new BEM('Card', () => ({
        'is-selected': this.props.selected,
        'is-disabled': this.props.disabled,
        'is-button': !!this.props.onClick,
        'is-small': this.props.small,
        'is-checked': this.props.checked,
        'is-checkbox-active': this.props.isCheckboxActive,
    }))

    public componentWillUnmount() {
        const { isFirstCardInList, onCheckChange } = this.props
        if (isFirstCardInList && onCheckChange && !this.hasSeenCardAnimation()) {
            LocalStorageService.setItem(LocalStorageKeys.SawCardAnimation, 'true')
        }
    }

    public render() {
        const { children, className, onCheckChange, to, onClick } = this.props

        const content = (
            <>
                {onCheckChange && this.renderCheckbox()}
                {children}
            </>
        )

        if (to) {
            return (
                <Link to={to} className={this.bem.getClassName(className)}>
                    {content}
                </Link>
            )
        }

        if (onClick) {
            return (
                // TODO: refactor to <Button> (as of now, <Button> doesn't support unstyled version)
                <div className={this.bem.getClassName(className)} onClick={onClick}>
                    {content}
                </div>
            )
        }

        return <div className={this.bem.getClassName(className)}>{content}</div>
    }

    private renderCheckbox() {
        if (this.props.isFirstCardInList && !this.hasSeenCardAnimation()) {
            return (
                <CSSTransition in={true} timeout={1000} classNames={'rf-Card'} appear={true} unmountOnExit={true}>
                    {this.renderCheckboxContainer()}
                </CSSTransition>
            )
        }

        return this.renderCheckboxContainer()
    }

    private renderCheckboxContainer() {
        const { onCheckChange, selected, checked } = this.props

        return (
            <div className={this.bem.getElement('checkbox-container-outer')}>
                <div className={this.bem.getElement('checkbox-container-inner')}>
                    {selected && (
                        <div className={this.bem.getElement('selected-icon')}>
                            <Icon type={IconType.cardCheck} />
                        </div>
                    )}
                    <Checkbox checked={checked} name={'check'} onChange={onCheckChange} />
                    <div className={this.bem.getElement('border-container')}></div>
                </div>
            </div>
        )
    }

    private hasSeenCardAnimation() {
        return LocalStorageService.getItem(LocalStorageKeys.SawCardAnimation) === 'true'
    }
}
