import React from 'react'
import Query from 'react-apollo/Query'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Content } from '~/components/Core/Layout/Content'
import { localize } from '~/bootstrap'
import {
    GeneratedSummaryByParentQuery,
    GeneratedSummaryByParentDocument,
    GeneratedSummaryByParentQueryVariables,
    GeneratedSummaryStatusEnum,
} from '~/generated/graphql'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    variables: GeneratedSummaryByParentQueryVariables
    children: (data: GeneratedSummaryQueryResultType) => JSX.Element
}

export type GeneratedSummaryQueryResultType = NonNullable<GeneratedSummaryByParentQuery['generatedSummaryByParent']>

export const GeneratedSummaryQuery: React.FunctionComponent<Props> = props => {
    return (
        <Query<GeneratedSummaryByParentQuery, GeneratedSummaryByParentQueryVariables>
            query={GeneratedSummaryByParentDocument}
            variables={props.variables}
        >
            {({ loading, data, startPolling, stopPolling }) => {
                if (loading) {
                    return (
                        <Content>
                            <Spinner delayed={true} />
                        </Content>
                    )
                }

                if (!data || !data.generatedSummaryByParent) {
                    return (
                        <Content>
                            <Paragraph>{localize.translate(t => t.Generic.documentNotFound)}</Paragraph>
                        </Content>
                    )
                }

                if (data.generatedSummaryByParent.status === GeneratedSummaryStatusEnum.generating) {
                    startPolling(2 * 1000) // 2s
                } else if (data.generatedSummaryByParent.status === GeneratedSummaryStatusEnum.done) {
                    stopPolling()
                }

                return props.children(data.generatedSummaryByParent)
            }}
        </Query>
    )
}
