import React from 'react'
import { localize } from '~/bootstrap'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import { Column } from '~/components/Core/Layout/Column'
import { PageDetailMetaItem, PageDetailMeta } from '~/components/Core/Layout/PageDetail/PageDetailMeta'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { InboxInternalAlertQuery } from '~/generated/graphql'
import { NewsUrlButton } from '../../News/NewsUrlButton'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    internalAlert: NonNullable<InboxInternalAlertQuery['internalAlert']>
}

export class InboxInternalAlertDetailMeta extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const items = [this.getPublishedMetaItem(), this.getSourceMetaItem(), this.getEditorNoteMetaItem()].filter(
            i => !!i
        ) as PageDetailMetaItem[]

        return <PageDetailMeta items={items} />
    }

    private getPublishedMetaItem(): PageDetailMetaItem | null {
        const { publishedAt, publishedBy } = this.props.internalAlert
        if (!publishedAt) {
            return null
        }

        return {
            label: localize.translate(t => t.Generic.sentAt),
            value: (
                <Column extraSmallSpacing>
                    <Row extraSmallSpacing>
                        <UserProfileIcon small={true} fullName={publishedBy?.user.profile.fullName || ''} />
                        <Paragraph>{publishedBy?.user.profile.fullName}</Paragraph>
                    </Row>
                    <Paragraph noWrap>
                        {localize.dateFormat(new Date(this.props.internalAlert.publishedAt), {
                            noWeekday: true,
                            readable: true,
                        })}
                    </Paragraph>
                </Column>
            ),
        }
    }

    private getSourceMetaItem(): PageDetailMetaItem | null {
        const { news } = this.props.internalAlert

        return {
            label: localize.translate(t => t.Entities.Source),
            value: <NewsUrlButton news={news} />,
        }
    }

    private getEditorNoteMetaItem(): PageDetailMetaItem | null {
        const { note } = this.props.internalAlert

        if (!note) {
            return null
        }

        return {
            label: localize.translate(t => t.Generic.note),
            value: <Paragraph truncateEllipsis>{note}</Paragraph>,
        }
    }
}
