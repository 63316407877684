import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { InfiniteScrollQuery, InfiniteScrollOptions } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { NewsFilters, NewsItemType, SignalingOverviewQueryDocument, NewsSort } from '~/generated/graphql'

interface Props {
    children: (options: InfiniteScrollOptions<NewsItemType>) => React.ReactNode
    filters?: NewsFilters
    sort?: NewsSort
}

export class SignalingOverviewQuery extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, filters, sort } = this.props
        return (
            <InfiniteScrollQuery<NewsItemType>
                query={SignalingOverviewQueryDocument}
                variables={{
                    filters,
                    sort,
                }}
            >
                {options => children(options)}
            </InfiniteScrollQuery>
        )
    }
}
