import React from 'react'
import { Query, QueryResult } from 'react-apollo'

import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    CustomerEnabledNewsSourceDocument,
    CustomerEnabledNewsSourceQuery,
    CustomerEnabledNewsSourceQueryVariables,
} from '~/generated/graphql'

interface Props {
    children: (
        options: QueryResult<CustomerEnabledNewsSourceQuery, CustomerEnabledNewsSourceQueryVariables>
    ) => React.ReactNode
}

export class CustomerEnabledNewsSource extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <Query<CustomerEnabledNewsSourceQuery, CustomerEnabledNewsSourceQueryVariables>
                query={CustomerEnabledNewsSourceDocument}
                variables={{ customerId: this.context.customer.id }}
            >
                {options => children(options)}
            </Query>
        )
    }
}
