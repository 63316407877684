import React from 'react'
import { permissions } from '~/bootstrap'
import { ActionButton } from '~/components/Core/ActionButton/ActionButton'
import { Guard } from '~/components/Core/Guard/Guard'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ConsultationType } from '~/generated/graphql'
import { LinkedTaskItem } from '~/graphql/types/Task'
import { CustomerItemType, ItemCustomerInfoSummary } from '../Customer/ItemCustomerInfoSummary'
import { CreateTaskActionButton } from '../Task/CreateTaskActionButton'

interface Props {
    consultation: Consultation
}

type Consultation = Pick<ConsultationType, 'id' | '__typename' | 'name'> & {
    customerFrameworks?: CustomerItemType['customerFrameworks'] | null
}

export class ConsultationDetailActionButtons extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { consultation } = this.props

        return (
            <Row>
                <Guard condition={permissions.canCreateTasks(this.context.activeDepartmentId)}>
                    <CreateTaskActionButton linkedItem={consultation as LinkedTaskItem} />
                </Guard>
                <ActionButton
                    tooltipContent={
                        <ItemCustomerInfoSummary item={{ customerFrameworks: consultation.customerFrameworks || [] }} />
                    }
                    icon={IconType.info}
                />
            </Row>
        )
    }
}
