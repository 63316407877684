import React from 'react'

import { RouteComponentProps, Switch, Route } from 'react-router'
import { routes } from '~/views/routes'
import { AssessmentsOverviewView } from './AssessmentsOverviewView'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AssessmentsDetailView } from './AssessmentsDetailView'
import { AssessmentsPropertiesView } from './AssessmentsPropertiesDetailView'
import { AssessmentsEditView } from './AssessmentsEditView'
import { AssessmentsLogView } from './AssessmentsLogView'

interface Props extends RouteComponentProps<{}> {}

export class AssessmentsView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public render() {
        return (
            <Switch>
                <Route
                    path={routes.customer(this.context.customer.slug).risks.assessments.index}
                    component={AssessmentsOverviewView}
                    exact={true}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).risks.assessments.view()}
                    component={AssessmentsDetailView}
                    exact={true}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).risks.assessments.properties()}
                    component={AssessmentsPropertiesView}
                    exact={true}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).risks.assessments.log()}
                    component={AssessmentsLogView}
                    exact={true}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).risks.assessments.edit()}
                    component={AssessmentsEditView}
                    exact={true}
                />
                <Route component={AppNotFoundView} />
            </Switch>
        )
    }
}
