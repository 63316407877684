if (typeof Promise === 'undefined') {
    // Rejection tracking prevents a common issue where React gets into an
    // inconsistent state due to an error, but it gets swallowed by a Promise,
    // and the user has no idea what causes React's erratic future behavior.
    require('promise/lib/rejection-tracking').enable()
    window.Promise = require('promise/lib/es6-extensions.js')
}

// fetch() polyfill for making API calls.
require('whatwg-fetch')

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign')

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === 'test') {
    require('raf').polyfill(global)
}

require('classlist-polyfill')

require('core-js/fn/symbol')
require('core-js/fn/array/from')
require('core-js/fn/array/includes')
require('core-js/fn/string/includes')
require('core-js/fn/string/starts-with')
require('core-js/fn/array/find')
require('core-js/fn/array/flat-map')
require('core-js/fn/object/entries')
require('core-js/fn/object/values')
require('core-js/fn/array/flat-map')
require('core-js/fn/array/flatten')
require('core-js/fn/array/find-index')
require('core-js/es6/map')
require('core-js/es6/set')
require('core-js/shim')
require('core-js/es6/string')
require('core-js/es6/array')

require('focus-visible')
require('intersection-observer')
require('url-polyfill')

// Polyfill from MDN @https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill for element.closest()
if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this
        if (!document.documentElement.contains(el)) return null
        do {
            if (el.matches(s)) return el
            el = el.parentElement || el.parentNode
        } while (el !== null && el.nodeType === 1)
        return null
    }
}

if (!Number.MAX_SAFE_INTEGER) {
    Number.MAX_SAFE_INTEGER = Math.pow(2, 53) - 1 // 9007199254740991
}

if (!Number.MIN_SAFE_INTEGER) {
    Number.MIN_SAFE_INTEGER = -(Math.pow(2, 53) - 1) // -9007199254740991
}
