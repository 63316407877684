import React from 'react'
import { Task } from '../TaskCompleteButtonContainer'
import { Modal, ModalSize } from '~/components/Core/Feedback/Modal/Modal'
import { ReopenTaskMutation, ReopenTaskMutationResponse, ReopenTaskVariables } from '../ReopenTaskMutation'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { MutationFn } from 'react-apollo'
import { notification } from '~/bootstrap'
import { localize } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    task: Task
    onReopen?: () => void
    requestClose?: () => void
}

export class TaskReopenModal extends React.Component<Props> {
    private loc = localize.namespaceTranslate(t => t.Customer.Task.reopenTaskModal)

    public render() {
        const { requestClose, task } = this.props

        return (
            <ReopenTaskMutation>
                {(reopenTask, { loading }) => (
                    <Form onSubmit={this.getSubmitHandler(reopenTask)}>
                        <Modal
                            title={this.loc(t => t.title)}
                            submitForm={true}
                            requestClose={requestClose}
                            modalSize={ModalSize.small}
                            actions={requestModalClose => (
                                <Row alignRight={true}>
                                    <Button type={ButtonType.secondary} onClick={requestModalClose}>
                                        {localize.translate(t => t.Generic.cancel)}
                                    </Button>
                                    <Button loading={loading} submit={true}>
                                        {this.loc(t => t.reopen)}
                                    </Button>
                                </Row>
                            )}
                        >
                            <Field label={this.loc(t => t.motivation)} compact={true}>
                                <Form.Input
                                    type={'textarea'}
                                    name={'description'}
                                    placeholder={this.loc(t => t.motivationPlaceholder)}
                                />
                            </Field>
                            {!task.forMonitoring && (
                                <Field label={localize.translate(t => t.Generic.file)} compact={true}>
                                    <Form.FileInput name={'file'} />
                                </Field>
                            )}
                        </Modal>
                    </Form>
                )}
            </ReopenTaskMutation>
        )
    }

    private getSubmitHandler = (mutate: MutationFn<ReopenTaskMutationResponse, ReopenTaskVariables>) => async (
        formState: FormState
    ) => {
        const { task, onReopen, requestClose } = this.props

        const response = await mutate({
            variables: {
                taskId: task.id,
                motivation: {
                    description: formState.description,
                    files: formState.file,
                },
            },
        })

        if (response && response.data) {
            notification.success(this.loc(t => t.reopened))

            if (onReopen) {
                onReopen()
            }

            if (requestClose) {
                requestClose()
            }
        }
    }
}
