import React from 'react'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { localize, notification } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { ControlType } from '~/generated/graphql'
import { ArchiveControls, ArchiveControlsMutationFN } from './Mutations/ArchiveControls'
import { Attention } from '~/components/Core/Feedback/Attention/Attention'

interface Props {
    control: Pick<ControlType, 'id' | 'name'>
    requestClose: () => void
}

interface State {
    reason: string | null
}

export class ArchiveControlModal extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        reason: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.ArchiveControlModal)

    public render() {
        const { requestClose } = this.props

        return (
            <ArchiveControls>
                {(mutate, { loading }) => {
                    return (
                        <Modal
                            requestClose={requestClose}
                            onAction={() => this.handleSubmit(mutate)}
                            title={this.loc(t => t.modalTitle)}
                            loading={loading}
                            confirmButtonLabel={localize.translate(t => t.Generic.archive)}
                        >
                            <Column>{this.renderContent()}</Column>
                        </Modal>
                    )
                }}
            </ArchiveControls>
        )
    }

    private renderContent() {
        const { name } = this.props.control

        return (
            <Column>
                <Paragraph>
                    {this.loc(t => t.archiveMessagePart1)}
                    <strong>{name}</strong>
                    {this.loc(t => t.archiveMessagePart2)}
                </Paragraph>
                <Attention noIcon={true} attentionText={<Paragraph>{this.loc(t => t.archiveWarning)}</Paragraph>} />
                <Field label={this.loc(t => t.reasonInputLabel)} compact={true}>
                    <Input
                        type="textarea"
                        placeholder={this.loc(t => t.reasonInputPlaceholder)}
                        name="reason"
                        onChange={reason => this.setState({ reason })}
                    />
                </Field>
            </Column>
        )
    }

    private async handleSubmit(mutate: ArchiveControlsMutationFN) {
        const { control, requestClose } = this.props
        const { reason } = this.state

        const response = await mutate({
            variables: {
                ids: [control.id],
                departmentId: this.context.activeDepartmentId,
                reason,
            },
        })

        if (response && response.data && response.data.archiveControls) {
            requestClose()
            notification.success(localize.translate(t => t.Generic.successfullyArchived))
        }
    }
}
