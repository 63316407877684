import React from 'react'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { AllFrameworksDocument, AllFrameworksQueryVariables, FrameworkType } from '~/generated/graphql'

interface Props {
    name?: string
    defaultSelected?: SelectOption[] | null
    multi?: boolean
    placeholder?: string
}

export class FrameworkSelect extends React.PureComponent<Props> {
    private defaultName = this.props.multi ? 'frameworkIds' : 'frameworkId'

    public render() {
        const { name, defaultSelected, multi, placeholder } = this.props

        return (
            <PaginatableQuery<FrameworkType, AllFrameworksQueryVariables>
                query={AllFrameworksDocument}
                path="frameworks"
            >
                {({ data, loading, fetchMore }) => {
                    if (!data) {
                        return null
                    }

                    const options = data.nodes
                        .map(({ id, name }) => ({
                            label: name || '',
                            value: id,
                        }))
                        .filter(n => !defaultSelected?.some(ds => ds.value === n.value))

                    return (
                        <Form.Select
                            name={name || this.defaultName}
                            loading={loading}
                            defaultValue={defaultSelected || undefined}
                            options={options}
                            clearable={true}
                            onEndReached={fetchMore}
                            multi={multi}
                            placeholder={placeholder}
                        />
                    )
                }}
            </PaginatableQuery>
        )
    }
}
