import './SelectDepartmentForExportModal.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { localize } from '~/bootstrap'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Column } from '~/components/Core/Layout/Column'
import { Field } from '~/components/Core/DataEntry/Form/Field'

interface Props {
    className?: ClassValue
    title: string
    description?: string
    errorPath?: string
    requestClose: () => void
    loading?: boolean
    onSubmit?: (formState: FormState) => void
    confirmButtonLabel?: string
    showYearSelection?: boolean
}

interface State {}

export class SelectDepartmentForExportModal extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('SelectDepartmentForExportModal')

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.SelectDepartmentForExportModal)

    public render() {
        const { className, title, requestClose, loading, description, onSubmit, confirmButtonLabel } = this.props
        const { errorPath, showYearSelection } = this.props

        return (
            <Form className={this.bem.getClassName(className)} onSubmit={onSubmit}>
                <Modal
                    title={title}
                    confirmButtonLabel={confirmButtonLabel || 'Exporteren'}
                    requestClose={requestClose}
                    className={this.bem.getClassName(className)}
                    loading={loading}
                    submitForm={true}
                >
                    <Paragraph>{description}</Paragraph>
                    <ErrorMessage path={errorPath} />
                    <Column bigSpacing={true}>
                        {this.renderDepartmentSelection()}
                        {showYearSelection && this.renderYearSelection()}
                    </Column>
                </Modal>
            </Form>
        )
    }

    private renderDepartmentSelection() {
        return (
            <div className={this.bem.getElement('radio-container')}>
                <Form.Radio
                    name={'exportAll'}
                    value={false}
                    label={
                        <div className={this.bem.getElement('radio-label-wrapper')}>
                            <span className={this.bem.getElement('radio-label')}>
                                {this.loc(t => t.currentDepartment)}
                            </span>
                            <span className={this.bem.getElement('departments')}>
                                {this.context.activeDepartment.name}
                            </span>
                        </div>
                    }
                    defaultChecked={true}
                    className={this.bem.getElement('radio')}
                />
                <Form.Radio
                    name={'exportAll'}
                    value={true}
                    className={this.bem.getElement('radio')}
                    label={
                        <div className={this.bem.getElement('radio-label-wrapper')}>
                            <span className={this.bem.getElement('radio-label')}>
                                {this.loc(t => t.allDepartments)}
                            </span>
                            <span className={this.bem.getElement('departments')}>
                                {this.context.departments.map(({ name }) => name).join(', ')}
                            </span>
                        </div>
                    }
                />
            </div>
        )
    }

    private renderYearSelection() {
        const currentYear = new Date().getFullYear()
        const yearOptions = Array.from([...new Array(5)]).map((_, i) => ({
            label: (currentYear - i).toString(),
            value: currentYear - i,
        }))

        return (
            <Column smallSpacing={true}>
                <Field forInput="year">
                    <Paragraph>{this.loc(t => t.period)}</Paragraph>
                    <Form.Select name="year" placeholder={this.loc(t => t.periodPlaceholder)} options={yearOptions} />
                </Field>
            </Column>
        )
    }
}
