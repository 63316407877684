import './StickyFooter.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Column } from './Column'

interface Props {
    className?: ClassValue
}

interface State { }

export class StickyFooter extends React.PureComponent<Props, State> {
    private bem = new BEM('StickyFooter')

    public render() {
        const { className, children } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('fixed-button')}>
                    <Column>
                        {children}
                    </Column>
                </div>
            </div>
        )
    }
}
