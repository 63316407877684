import gql from 'graphql-tag'
import React from 'react'
import { InfiniteScrollOptions, InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ThemesDetailCardListDocument, ThemesDetailCardListQueryVariables } from '~/generated/graphql'
import { CustomerFramework, CustomerFrameworkFragment } from '~/graphql/types/CustomerFramework'
import { ThemesDetailCardTopicType } from '../ThemesDetailCardList/ThemesDetailCardListQuery'

export interface MarkTopicsAsNonApplicableFormListTopicType {
    id: number
    name: string
    isPinned: boolean
    customerFrameworks: CustomerFramework[]
    isApplicable: boolean
    nonApplicable?: {
        id: number
        reason: string
    }
    hasCompliance: boolean
    departmentTopicData: {
        id: number
        topicAssessmentDesignMetadata?: {
            linkedControlMeasureCount: number
            linkedNoteCount: number
        }
        topicAssessmentEffectivenessMetadata?: {
            linkedControlMeasureCount: number
            linkedNoteCount: number
        }
    }
}

export const LIST_TOPICS_QUERY = gql`
    query topics(
        $skip: Int
        $take: Int
        $customerSlug: String!
        $departmentId: Int!
        $customerFrameworkIds: [Int!]!
        $themeId: Int!
    ) {
        topicsForTheme(
            skip: $skip
            take: $take
            customerSlug: $customerSlug
            departmentId: $departmentId
            customerFrameworkIds: $customerFrameworkIds
            themeId: $themeId
        ) {
            hasNextPage
            nodes {
                id
                name
                isPinned
                isApplicable
                nonApplicable {
                    id
                    reason
                }
                hasCompliance
                customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                    ...CustomerFrameworkFields
                }
                departmentTopicData(departmentId: $departmentId) {
                    id
                    topicAssessmentDesignMetadata {
                        linkedControlMeasureCount
                        linkedNoteCount
                    }
                    topicAssessmentEffectivenessMetadata {
                        linkedControlMeasureCount
                        linkedNoteCount
                    }
                }
            }
        }
    }
    ${CustomerFrameworkFragment}
`

interface Props {
    themeId: number
    children: (
        options: InfiniteScrollOptions<ThemesDetailCardTopicType, ThemesDetailCardListQueryVariables>
    ) => JSX.Element
}

export class MarkTopicsAsNonApplicableFormListQuery extends React.PureComponent<Props, {}> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, themeId } = this.props
        const departmentId = this.context.activeDepartmentId

        return (
            <InfiniteScrollQuery<ThemesDetailCardTopicType, ThemesDetailCardListQueryVariables>
                query={ThemesDetailCardListDocument}
                variables={{
                    customerSlug: this.context.customer.slug,
                    departmentId,
                    customerFrameworkIds: this.context.activeProfiles,
                    themeId,
                    filters: { departmentId },
                }}
            >
                {options => children(options)}
            </InfiniteScrollQuery>
        )
    }
}
