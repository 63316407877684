import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    ArchiveTaskTemplateDocument,
    ArchiveTaskTemplateMutation,
    ArchiveTaskTemplateMutationVariables,
} from '~/generated/graphql'

interface Props {
    children: (
        mutation: MutationFn<ArchiveTaskTemplateMutation, ArchiveTaskTemplateMutationVariables>,
        options: MutationResult
    ) => JSX.Element
}

export type ArchiveTaskTemplateFN = MutationFn<ArchiveTaskTemplateMutation, ArchiveTaskTemplateMutationVariables>

export class ArchiveTaskTemplate extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<ArchiveTaskTemplateMutation, ArchiveTaskTemplateMutationVariables>
                mutation={ArchiveTaskTemplateDocument}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
