import React from 'react'
import { PaginatableOptions, PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    CustomerTaskTemplatesDocument,
    CustomerTaskTemplatesQueryVariables,
    CustomerTaskTemplateType,
} from '~/generated/graphql'

interface Props {
    children: (
        options: PaginatableOptions<CustomerTaskTemplateType, CustomerTaskTemplatesQueryVariables>
    ) => JSX.Element
}

export class CustomerTaskTemplatesPaginatableQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props
        const customerId = this.context.customer.id

        return (
            <PaginatableQuery<CustomerTaskTemplateType, CustomerTaskTemplatesQueryVariables>
                query={CustomerTaskTemplatesDocument}
                variables={{ customerId, customerFrameworkIds: this.context.activeProfiles }}
                path="customerTaskTemplates"
            >
                {options => children(options)}
            </PaginatableQuery>
        )
    }
}
