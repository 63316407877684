import React from 'react'
import { TaskCompleteButton } from './TaskCompleteButton'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { TaskReopenModal } from './Motivation/TaskReopenModal'
import { TaskCompleteModal } from './Motivation/TaskCompleteModal'

export interface Task {
    id: number
    completedAt: string | null
    name: string
    forMonitoring: boolean
}

interface Props {
    task: Task
    onChange: () => void
    isDisabled?: boolean
}

export class TaskCompleteButtonContainer extends React.Component<Props, {}> {
    public render() {
        const { task } = this.props

        if (task.completedAt) {
            return this.renderReopenModal()
        } else {
            return this.renderCompleteModal()
        }
    }

    private renderReopenModal() {
        const { onChange, task, isDisabled } = this.props

        return (
            <ModalManager
                render={openModal => <TaskCompleteButton isCompleted={true} onClick={openModal} isDisabled={isDisabled} />}
                renderModal={closeModal => (
                    <TaskReopenModal task={task} onReopen={onChange} requestClose={closeModal} />
                )}
            />
        )
    }

    private renderCompleteModal() {
        const { onChange, task, isDisabled } = this.props

        return (
            <ModalManager
                render={openModal => <TaskCompleteButton isCompleted={false} onClick={openModal} isDisabled={isDisabled} />}
                renderModal={closeModal => (
                    <TaskCompleteModal task={task} onComplete={onChange} requestClose={closeModal} />
                )}
            />
        )
    }
}
