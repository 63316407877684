import './ExpiredArticleWarning.scss'

import React from 'react'
import { localize, notification, permissions } from '~/bootstrap'
import { ExpandableList } from '~/components/Core/DataDisplay/ExpandableList/ExpandableList'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM, ClassValue } from '~/services/BEMService'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Mutation, MutationFn } from 'react-apollo'
import {
    DeleteExpiredArticleMutation,
    DeleteExpiredArticleMutationVariables,
    DeleteExpiredArticleDocument,
    LawArticleExpiredObjectType,
} from '~/generated/graphql'
import { Column } from '~/components/Core/Layout/Column'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Guard } from '~/components/Core/Guard/Guard'
import { differenceInCalendarDays } from 'date-fns'

interface Props {
    className?: ClassValue
    expired?: LawArticleExpiredObjectType
    topicId?: number
    abstractLawArticleId?: number
    showDelete?: boolean
    onDelete?: () => void
}

export class ExpiredArticleWarning extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.LawAndRegulation.ExpiredArticleWarning)
    private bem = new BEM('ExpiredArticleWarning', () => ({
        noDelete: !this.props.showDelete,
    }))

    public render() {
        const { expired, className, showDelete } = this.props

        if (!expired) {
            return null
        }

        const formattedDate = localize.dateFormat(new Date(expired.expiredAt), { noWeekday: true, readable: true })
        const labelMessage = localize.translate(t => t.Customer.Compliance.TopicCard.expiredAt, { date: formattedDate })

        if (!showDelete) {
            return (
                <Row smallSpacing={true} className={this.bem.getClassName(className)}>
                    <Icon type={IconType.attention} className={this.bem.getElement('icon')} />
                    <Paragraph emphasis={true} className={this.bem.getElement('label')}>
                        {labelMessage}
                    </Paragraph>
                </Row>
            )
        }

        return (
            <ExpandableList
                className={this.bem.getClassName(className)}
                label={
                    <Row smallSpacing={true}>
                        <Icon type={IconType.attention} className={this.bem.getElement('icon')} />
                        <Paragraph emphasis={true} className={this.bem.getElement('label')}>
                            {labelMessage}
                        </Paragraph>
                    </Row>
                }
                renderList={this.renderDelete}
            />
        )
    }

    private renderDelete = () => {
        const { expired } = this.props
        const daysFromDeletion = differenceInCalendarDays(new Date(expired!.willBeDeletedAt), new Date())

        return (
            <Mutation<DeleteExpiredArticleMutation, DeleteExpiredArticleMutationVariables>
                mutation={DeleteExpiredArticleDocument}
            >
                {(mutate, { loading }) => (
                    <Column smallSpacing={true} className={this.bem.getElement('delete-container')}>
                        <Paragraph>{this.loc(t => t.info, { smart_count: daysFromDeletion })}</Paragraph>
                        <Guard condition={permissions.canDeleteArticle(this.context.activeDepartmentId)}>
                            <Button
                                loading={loading}
                                type={ButtonType.subtleItemLink}
                                onClick={this.handleDelete(mutate)}
                            >
                                {this.loc(t => t.buttonLabel)}
                            </Button>
                        </Guard>
                    </Column>
                )}
            </Mutation>
        )
    }

    private handleDelete = (
        mutate: MutationFn<DeleteExpiredArticleMutation, DeleteExpiredArticleMutationVariables>
    ) => async () => {
        const { abstractLawArticleId, topicId, onDelete } = this.props
        if (!abstractLawArticleId || !topicId) {
            return
        }

        const response = await mutate({ variables: { abstractLawArticleId, topicId } })

        if (response && response.data) {
            notification.success(localize.translate(t => t.Generic.successfullyDeleted))

            if (onDelete) {
                onDelete()
            }
        }
    }
}
