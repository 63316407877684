import './SubTaskTemplatesTableContainer.scss'

import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { Table } from '~/components/Core/DataDisplay/Table/Table'
import { BEM } from '~/services/BEMService'
import { TaskTags } from '../TaskTags/TaskTags'
import { Truncate } from '~/components/Core/Text/Truncate'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { IconType } from '~/components/Core/Icon/IconType'
import { CreateTaskTemplateModalContainer } from './CreateTaskTemplateModalContainer'
import { TaskTagType, TaskTemplateType } from '~/generated/graphql'
import { TaskTemplateViewModalContainer } from './TaskTemplateViewModalContainer'

interface Props {
    subTasks: TaskTemplateType[]
    parentTaskTemplateName: string
    parentTaskTemplateId: number
    refetch: () => void
    forConsultantTemplate?: boolean
    forSuggestedTemplate?: boolean
}

export class SubTaskTemplatesTableContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('SubTaskTemplatesTableContainer', () => ({
        forConsultant: this.props.forConsultantTemplate,
    }))
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.TaskTemplatesOverviewView.TableHeaders)

    public render() {
        return (
            <Column bigSpacing={true} className={this.bem.getClassName()}>
                {this.renderHeader()}
                {this.renderContent()}
            </Column>
        )
    }

    private renderHeader() {
        return (
            <Row spaceBetween={true}>
                <SectionTitle bold={true}>{this.loc(t => t.subTasks)}</SectionTitle>
                {this.renderCreateSubTaskButton()}
            </Row>
        )
    }

    private renderContent() {
        const { subTasks, forConsultantTemplate } = this.props

        if (!subTasks.length) {
            return this.renderNoSubTaskMessage()
        }

        return <Table columns={this.getTableColumns()} data={this.getTableData()} hideHeaders={forConsultantTemplate} />
    }

    private renderCreateSubTaskButton() {
        const { parentTaskTemplateId, parentTaskTemplateName, refetch } = this.props
        const { forConsultantTemplate, forSuggestedTemplate } = this.props

        if (forSuggestedTemplate) {
            return
        }

        const hasNoPermission = forConsultantTemplate
            ? !permissions.isConsultantUser()
            : !permissions.isCustomerAdministrator()

        return (
            <ModalManager
                render={openModal => (
                    <Button
                        type={ButtonType.actionLink}
                        disabled={hasNoPermission}
                        icon={IconType.addCircle}
                        onClick={openModal}
                    >
                        {localize.translate(t => t.Customer.Task.viewTaskModal.newSubTask)}
                    </Button>
                )}
                renderModal={closeModal => (
                    <CreateTaskTemplateModalContainer
                        requestClose={closeModal}
                        isSubTask={true}
                        parentTaskTemplateId={parentTaskTemplateId}
                        parentTaskTemplateName={parentTaskTemplateName}
                        onCreate={refetch}
                        forConsultantTemplate={forConsultantTemplate}
                    />
                )}
            />
        )
    }

    private renderNoSubTaskMessage() {
        return (
            <Row fullWidth={true} alignCenter={true} className={this.bem.getElement('no-sub-task-message')}>
                <SectionTitle bold={true} subtle={true}>
                    {localize.translate(t => t.Customer.Task.viewTaskModal.noSubTasksMessage)}
                </SectionTitle>
            </Row>
        )
    }

    private getTableColumns() {
        const { forConsultantTemplate } = this.props

        if (forConsultantTemplate) {
            return [{ field: 'name', headerLabel: this.loc(t => t.subTasks), truncateField: true }]
        }

        return [
            { field: 'name', headerLabel: this.loc(t => t.subTasks), truncateField: true },
            { field: 'tags', headerLabel: this.loc(t => t.tags) },
        ]
    }

    private getTableData() {
        const { subTasks, forConsultantTemplate } = this.props

        if (forConsultantTemplate) {
            return subTasks.map(subTask => ({
                id: subTask.id,
                columns: { name: this.renderName(subTask.name, subTask.id) },
            }))
        }

        return subTasks.map(subTask => ({
            id: subTask.id,
            columns: {
                name: this.renderName(subTask.name, subTask.id),
                tags: this.renderTags(subTask.tags),
            },
        }))
    }

    private renderName(name: string, id: number) {
        const { parentTaskTemplateId, parentTaskTemplateName, refetch } = this.props
        const { forConsultantTemplate, forSuggestedTemplate } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button type={ButtonType.noStyling} onClick={() => openModal()}>
                        <Truncate className={this.bem.getElement('name')}>{name}</Truncate>
                    </Button>
                )}
                renderModal={closeModal => (
                    <TaskTemplateViewModalContainer
                        requestClose={closeModal}
                        taskTemplateId={id}
                        isSubTask={true}
                        onMutateTaskTemplate={refetch}
                        parentTaskTemplateId={parentTaskTemplateId}
                        parentTaskTemplateName={parentTaskTemplateName}
                        forConsultantTemplate={forConsultantTemplate}
                        forSuggestedTemplate={forSuggestedTemplate}
                    />
                )}
            />
        )
    }

    private renderTags(tags?: TaskTagType[] | null) {
        return (
            <div className={this.bem.getElement('tag-container')}>
                <TaskTags tags={tags || []} showAsTooltip={true} />
            </div>
        )
    }
}
