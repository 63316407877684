import './ThemesDetailPinnedItemsContainer.scss'

import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { animations } from '~/animations'
import { CardList } from '~/components/Core/DataDisplay/CardList/CardList'
import { PinnedTopicsOptions } from '../../Pinned/PinnedTopicsQuery'
import { ThemesDetailCardTopicType } from './ThemesDetailCardListQuery'
import { BEM } from '~/services/BEMService'
import { ThemesDetailCard } from './ThemesDetailCard'
import { permissions } from '~/bootstrap'

interface Props {
    topicsOptions: PinnedTopicsOptions
    themeId: number
    onUnpin?: () => void
}

export class ThemesDetailPinnedItemsContainer extends React.PureComponent<Props> {
    private bem = new BEM('ThemesDetailPinnedItemsContainer')

    public render() {
        const { topicsOptions, themeId, onUnpin } = this.props
        const { loading, topics, unpin } = topicsOptions

        if (topics.length === 0 || loading) {
            return null
        }

        const notAllowedToPin = !permissions.isCustomerUser()

        return (
            <CardList className={this.bem.getClassName()} columns={3}>
                <TransitionGroup component={null}>
                    {topics.map(topic => (
                        <CSSTransition key={topic.id} classNames={animations.popIn} timeout={225} in={true}>
                            <ThemesDetailCard
                                topic={topic as ThemesDetailCardTopicType}
                                togglePinned={() => {
                                    unpin(topic.id, themeId)

                                    if (onUnpin) {
                                        onUnpin()
                                    }
                                }}
                                isPinned={true}
                                notAllowedToPin={notAllowedToPin}
                            />
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </CardList>
        )
    }
}
