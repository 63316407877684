import './Pin.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Button, ButtonType } from '~/components/Core/Button/Button'

interface Props {
    className?: ClassValue
    isPinned?: boolean
    togglePinned: () => void
    disabled?: boolean
}

interface State {}

export class Pin extends React.PureComponent<Props, State> {
    private bem = new BEM('Pin', () => ({
        'is-pinned': this.props.isPinned,
    }))

    public render() {
        const { className, togglePinned, isPinned, disabled } = this.props

        return (
            <Button
                className={this.bem.getClassName(className)}
                disabled={disabled}
                type={ButtonType.itemLink}
                onClick={togglePinned}
            >
                <Icon className={this.bem.getElement('pin')} type={isPinned ? IconType.pinRelease : IconType.pin} />
            </Button>
        )
    }
}
