import './ControlDetailMeta.scss'
import React from 'react'
import { localize } from '~/bootstrap'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { PageDetailMetaItem, PageDetailMeta } from '~/components/Core/Layout/PageDetail/PageDetailMeta'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ControlType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'

type Control = Pick<
    ControlType,
    'startDate' | 'revisionDate' | 'createdAt' | 'updatedAt' | 'archivedAt' | 'archivedReason'
>

interface Props {
    control: Control
}

interface DateProps {
    warnIfPast?: boolean
    subtle?: boolean
}

export class ControlDetailMeta extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('ControlDetailMeta')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.DetailView)

    public render() {
        const { startDate, revisionDate, createdAt } = this.props.control
        const items: PageDetailMetaItem[] = [
            { label: this.loc(t => t.startDate), value: this.renderDate(startDate) },
            { label: this.loc(t => t.revisionDate), value: this.renderDate(revisionDate, { warnIfPast: true }) },
            { label: this.loc(t => t.creationDate), value: this.renderDate(createdAt) },
            this.getUpdateOrArchiveDate(),
        ]

        return <PageDetailMeta className={this.bem.getClassName()} items={items} />
    }

    private getUpdateOrArchiveDate(): PageDetailMetaItem {
        const { updatedAt, archivedAt, archivedReason } = this.props.control

        if (archivedAt) {
            if (archivedReason) {
                return {
                    label: this.loc(t => t.archived),
                    value: (
                        <Tooltip
                            message={archivedReason || ''}
                            disabled={!archivedReason}
                            className={this.bem.getElement('archived-reason')}
                        >
                            <Paragraph truncateEllipsis>{archivedReason}</Paragraph>
                        </Tooltip>
                    ),
                    subValue: this.renderDate(archivedAt, { subtle: true }),
                }
            }

            return { label: this.loc(t => t.archived), value: this.renderDate(archivedAt) }
        }

        return { label: this.loc(t => t.editDate), value: this.renderDate(updatedAt) }
    }

    private renderDate(date?: Date | null, options?: DateProps) {
        if (!date) {
            return
        }

        return (
            <Paragraph subtle={options?.subtle}>
                <DateFormat
                    readable={true}
                    noWeekday={true}
                    date={new Date(date)}
                    className={this.bem.getElement('date', () => ({
                        warn: options?.warnIfPast && new Date(date) < new Date(),
                    }))}
                />
            </Paragraph>
        )
    }
}
