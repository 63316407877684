import './DateFormat.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    date: Date
    includeTime?: boolean
    includeSeconds?: boolean
    readable?: boolean
    short?: boolean
    locale?: string
    distanceInWordsFrom?: Date
    noWeekday?: boolean
}

const bem = new BEM('DateFormat')

export const DateFormat: React.SFC<Props> = props => {
    const { className, date, distanceInWordsFrom, ...restProps } = props

    if (distanceInWordsFrom) {
        return (
            <time dateTime={date.toISOString()} className={bem.getClassName(className)}>
                {localize.distanceInWords(distanceInWordsFrom, date)}
            </time>
        )
    }

    return (
        <time dateTime={date.toISOString()} className={bem.getClassName(className)}>
            {localize.dateFormat(date, restProps)}
        </time>
    )
}
