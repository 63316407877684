import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { permissions } from '~/bootstrap'
import { AttachmentList } from '~/components/Core/DataDisplay/AttachmentList/AttachmentList'
import { Column } from '~/components/Core/Layout/Column'
import { Page } from '~/components/Core/Layout/Page'
import { PageDetail } from '~/components/Core/Layout/PageDetail/PageDetail'
import { PageDetailContent } from '~/components/Core/Layout/PageDetail/PageDetailContent'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Markdown } from '~/components/Core/Text/Markdown'
import { InboxInternalAlertDetailMeta } from '~/components/Domain/Inbox/InternalAlert/InboxInternalAlertDetailMeta'
import { InboxLinkedItems } from '~/components/Domain/Inbox/InboxLinkedItems'
import { InternalAlertDetailActionButtons } from '~/components/Domain/Inbox/InternalAlert/InternalAlertDetailActionButtons'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    InboxInternalAlertDocument,
    InboxInternalAlertQuery,
    InboxInternalAlertQueryVariables,
    InternalAlertStatusState,
} from '~/generated/graphql'
import { Attachment } from '~/graphql/types/Attachment'
import { breadcrumbs } from '~/views/breadcrumbs'
import { InternalAlertTimelineLog } from '~/components/Domain/Signaling/InternalAlert/InternalAlertTimelineLog'
import { DepartmentInternalAlertLogs } from '~/components/Domain/Inbox/InternalAlert/DepartmentInteralAlertLogs'

interface Props extends RouteComponentProps<{ id?: string }> {}

type InternalAlert = NonNullable<InboxInternalAlertQuery['internalAlert']>

export class InboxInternalAlertDetailView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const id = this.props.match.params.id
        const internalAlertId = id ? parseInt(id, 10) : null

        if (!internalAlertId || !permissions.canAccessInternalAlerts(this.context.activeDepartmentId)) {
            return null
        }

        return (
            <PageQuery<InternalAlert, InboxInternalAlertQueryVariables>
                query={InboxInternalAlertDocument}
                variables={{
                    id: internalAlertId,
                    departmentId: this.context.activeDepartmentId,
                    customerSlug: this.context.customer.slug,
                }}
            >
                {(internalAlert, { refetch }) => (
                    <Page>
                        <PageHeader
                            title={internalAlert.news.title}
                            subtle={internalAlert.departmentStatus?.state !== InternalAlertStatusState.new}
                            actionButtons={
                                <InternalAlertDetailActionButtons
                                    refetch={() => refetch()}
                                    internalAlert={internalAlert}
                                />
                            }
                            breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).inbox.index]}
                        />
                        <PageDetail
                            smallBottomSpacing
                            center={false}
                            renderSidebar={() => this.renderSidebar(internalAlert)}
                        >
                            <Column extraBigSpacing>
                                <InboxInternalAlertDetailMeta internalAlert={internalAlert} />
                                <PageDetailContent>
                                    <Markdown source={internalAlert.news.abstract} />
                                    <Markdown source={internalAlert.news.content} />
                                    {internalAlert.news.attachments && internalAlert.news.attachments.length > 0 && (
                                        <AttachmentList attachments={internalAlert.news.attachments as Attachment[]} />
                                    )}
                                </PageDetailContent>
                            </Column>
                        </PageDetail>
                    </Page>
                )}
            </PageQuery>
        )
    }

    private renderSidebar(internalAlert: InternalAlert) {
        return (
            <Column bigSpacing>
                <InboxLinkedItems
                    alert={{
                        publishedFromCustomerNews: { id: internalAlert.id, news: internalAlert.news },
                        item: null,
                    }}
                />
                <DepartmentInternalAlertLogs internalAlertId={internalAlert.id} take={3}>
                    {({ data, loading, loadingMore, fetchMore, canFetchMore }) => (
                        <InternalAlertTimelineLog
                            internalAlert={internalAlert}
                            loading={loading}
                            loadingMore={loadingMore}
                            fetchMore={fetchMore}
                            canFetchMore={canFetchMore}
                            logs={data?.nodes || []}
                            totalCount={data?.totalCount || 0}
                        />
                    )}
                </DepartmentInternalAlertLogs>
            </Column>
        )
    }
}
