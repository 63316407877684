import './RowFraction.scss'

import * as React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    amount: number
    alignStart?: boolean
    style?: React.CSSProperties
}

interface State {}

export class RowFraction extends React.PureComponent<Props, State> {
    private bem = new BEM('RowFraction')

    public render() {
        const { children, style, className, amount, alignStart } = this.props

        return (
            <div className={this.bem.getClassName(className)} style={{ flex: amount, alignSelf: alignStart ? 'flex-start' : undefined, ...style }}>
                {children}
            </div>
        )
    }
}
