import React from 'react'
import { Query, Mutation, MutationFn } from 'react-apollo'
import gql from 'graphql-tag'
import { apolloClient } from '~/services/ApolloService'
import { LIST_LAWS_QUERY } from '~/views/Customer/lawAndRegulation/LawAndRegulationOverviewView'

interface Props {
    children: (context: Context) => React.ReactNode
}

interface Context {
    laws: PinnedLaw[]
    loading: boolean
    pin: PinFN
    unpin: PinFN
}

export type PinFN = (id: number) => void

const GET_PINNED_QUERY = gql`
    query pinnedLaws {
        pinned {
            laws @connection(key: "pinnedLaws") {
                id
                abstractLawId
                name
            }
        }
    }
`

const PIN_MUTATION = gql`
    mutation pinLaw($id: Int!) {
        pinItem(id: $id, type: LAW)
    }
`

const UNPIN_MUTATION = gql`
    mutation unpinLaw($id: Int!) {
        unpinItem(id: $id, type: LAW)
    }
`

export interface PinnedLaw {
    id: number
    abstractLawId: number
    name: string
}

interface PinnedLawsResponse {
    pinned: {
        laws: PinnedLaw[]
    }
}

export class PinnedLawsQuery extends React.Component<Props> {
    public render() {
        const { children } = this.props

        return (
            <Mutation<any> mutation={UNPIN_MUTATION} refetchQueries={[{ query: GET_PINNED_QUERY }]}>
                {unpinLaw => (
                    <Mutation<any> mutation={PIN_MUTATION} refetchQueries={[{ query: GET_PINNED_QUERY }]}>
                        {pinLaw => (
                            <Query<PinnedLawsResponse> query={GET_PINNED_QUERY}>
                                {({ data, loading }) =>
                                    children({
                                        laws: this.getLaws(data),
                                        loading,
                                        pin: id => this.handlePinning(id, pinLaw, true),
                                        unpin: id => this.handlePinning(id, unpinLaw, false),
                                    })
                                }
                            </Query>
                        )}
                    </Mutation>
                )}
            </Mutation>
        )
    }

    private async handlePinning(id: number, mutate: MutationFn, isPinned: boolean) {
        await mutate({ variables: { id } })

        // Update apollo cache; this will update the up for LIST_LAWS_QUERY
        const data = apolloClient.readQuery({ query: LIST_LAWS_QUERY }) as { laws: any }
        apolloClient.writeQuery({
            query: LIST_LAWS_QUERY,
            data: {
                laws: {
                    ...data.laws,
                    nodes: data.laws.nodes.map((law: any) => ({
                        ...law,
                        isPinned: id === law.abstractLawId ? isPinned : law.isPinned,
                    })),
                },
            },
        })
    }

    private getLaws(data?: PinnedLawsResponse) {
        if (data && data.pinned && data.pinned.laws) {
            return data.pinned.laws
        }

        return []
    }
}
