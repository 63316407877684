import React from 'react'
import { RouteComponentProps } from 'react-router'
import { localize, permissions } from '~/bootstrap'
import { Button } from '~/components/Core/Button/Button'
import { ActionBar } from '~/components/Core/Layout/ActionBar'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { RiskSettingsFieldsContainer } from '~/components/Domain/Settings/Control/ControlRiskFields/RiskSettingsFieldsContainer'
import { routes } from '~/views/routes'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { Column } from '~/components/Core/Layout/Column'
import { Guard } from '~/components/Core/Guard/Guard'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { RiskTypesTable } from '~/components/Domain/Compliance/Risks/RiskTypesTable'
import { breadcrumbs } from '~/views/breadcrumbs'

interface RouteParams {}

interface Props extends RouteComponentProps<RouteParams> {}

export class ComplianceSettingsView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Settings.Attributes)
    public render() {
        return (
            <Column smallSpacing={true}>
                <PageHeader
                    title={this.loc(t => t.ControlSettings.RiskHeatmap.control)}
                    breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).settings.index]}
                />
                <Column smallSpacing={true}>
                    <ComponentTitle title={this.loc(t => t.ControlSettings.RiskHeatmap.title)} bold={true} />
                    <Paragraph wrap={true}>{this.loc(t => t.ControlSettings.RiskHeatmap.description)}</Paragraph>
                </Column>
                <Column bigSpacing={true}>
                    <RiskSettingsFieldsContainer />
                    <RiskTypesTable />
                </Column>
                <Guard condition={permissions.isCustomerAdministrator(true, this.context.activeDepartmentId)}>
                    <ActionBar>
                        <Row alignRight={true}>
                            <Button to={routes.customer(this.context.customer.slug).settings.compliance.edit}>
                                {localize.translate(t => t.Generic.edit)}
                            </Button>
                        </Row>
                    </ActionBar>
                </Guard>
            </Column>
        )
    }
}
