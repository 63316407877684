import React from 'react'

import { TaskType, IgnoreTasksDocument, IgnoreTasksMutation, IgnoreTasksMutationVariables } from '~/generated/graphql'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { localize, notification } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { GQLMutation } from '~/graphql/Mutation'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { MutationFn } from 'react-apollo'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'

interface Props {
    tasks: TaskType[]
    children: (openModal: () => void) => React.ReactNode
    refetch?: Function
    onIgnore?: () => void
}

interface State {
    ignoredReason: string | null
}

export class IgnoreTaskControlModal extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        ignoredReason: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.TaskControl.IgnoreTaskControlModal)

    public render() {
        const { children, tasks } = this.props

        const title =
            tasks.length === 1 ? this.loc(t => t.titleSingle) : this.loc(t => t.title, { count: tasks.length })

        const confirmLabel =
            tasks.length === 1 ? this.loc(t => t.confirmSingle) : this.loc(t => t.confirm, { count: tasks.length })

        return (
            <GQLMutation<IgnoreTasksMutation> mutation={IgnoreTasksDocument}>
                {(mutate, options) => (
                    <ConfirmModal
                        title={title}
                        confirmButtonLabel={confirmLabel}
                        renderContent={this.renderContent}
                        onConfirm={closeModal => this.handleSubmit(mutate, closeModal)}
                    >
                        {children}
                    </ConfirmModal>
                )}
            </GQLMutation>
        )
    }

    private renderContent = () => {
        const { tasks = [] } = this.props

        return (
            <Form>
                <ErrorMessage path={'ignoreTasks'} />
                <Column>
                    <Paragraph>
                        {this.loc(t => t.description.first)}
                        <strong>
                            {tasks.length === 1
                                ? tasks[0].name
                                : this.loc(t => t.description.middle, { count: tasks.length })}
                        </strong>
                        {this.loc(t => t.description.last)}
                    </Paragraph>
                    <Field label={this.loc(t => t.fieldLabel)} forInput={'reason'} compact={true}>
                        <Form.Input
                            type="textarea"
                            placeholder={this.loc(t => t.placeholder)}
                            name="reason"
                            onChange={ignoredReason => this.setState({ ignoredReason })}
                        />
                    </Field>
                </Column>
            </Form>
        )
    }

    private async handleSubmit(
        mutate: MutationFn<IgnoreTasksMutation, IgnoreTasksMutationVariables>,
        closeModal: () => void
    ) {
        const { tasks, refetch, onIgnore } = this.props
        const { ignoredReason } = this.state

        if (!tasks) {
            return
        }

        const response = await mutate({
            variables: {
                departmentId: this.context.activeDepartmentId,
                reason: ignoredReason,
                taskIdsToIgnore: tasks.map(task => task.id),
            },
        })

        if (response && response.data && response.data.ignoreTasks) {
            notification.success(this.loc(t => t.successfullyIgnored))

            closeModal()

            if (refetch) {
                refetch()
            }

            if (onIgnore) {
                onIgnore()
            }

            this.state.ignoredReason = null
        }
    }
}
