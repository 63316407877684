import './NavigationTab.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'

interface Props {
    className?: ClassValue
    isOpen: boolean
    label: string
    switchComponent: JSX.Element
    onClick?: () => void
}

interface State {}

export class NavigationTab extends React.PureComponent<Props, State> {
    private bem = new BEM('NavigationTab', () => ({
        'is-open': this.props.isOpen,
    }))

    public render() {
        const { className, label, switchComponent, onClick } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <Button onClick={onClick} type={ButtonType.noStyling}>
                    <div className={this.bem.getElement('toggle-content')}>
                        <Paragraph className={this.bem.getElement('label')}>{label}</Paragraph>
                        <div className={this.bem.getElement('switch-component-container')}>
                            <div className={this.bem.getElement('switch-component')}>{switchComponent}</div>
                            <div className={this.bem.getElement('toggle-arrow')}>
                                <Icon type={IconType.arrowDown} />
                            </div>
                        </div>
                    </div>
                </Button>
            </div>
        )
    }
}
