import React from 'react'

import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { localize, permissions } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Row } from '~/components/Core/Layout/Row'
import gql from 'graphql-tag'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationFn } from 'react-apollo'
import { CUSTOMER_EMPLOYEES_QUERY } from '~/views/Customer/Settings/Employees/EmployeeOverviewView'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { CUSTOMER_QUERY } from '~/components/Domain/Customer/CustomerQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { CustomerRoleSelect } from '~/components/Domain/Customer/CustomerRoleSelect'
import { DepartmentSelect, DepartmentStatus } from '../Departments/DepartmentSelect'
import { startsWith } from 'lodash-es'
import { EmployeeRole, Language } from '~/generated/graphql'
import { Guard } from '~/components/Core/Guard/Guard'

interface Props {
    requestClose: () => void
    seatsAreFilled: boolean
    onAdd?: () => void
}

interface State {
    isSubmitted: boolean
    addedUserFullname: string
    addedUserEmail: string
}

const CREATE_EMPLOYEE_MUTATION = gql`
    mutation createEmployee($fields: CreateEmployeeFieldsInputType!, $customerSlug: String) {
        createEmployee(fields: $fields, customerSlug: $customerSlug) {
            id
            user {
                id
                email
                ssoLoginEnabled
                language
                profile {
                    id
                    firstName
                    lastName
                    fullName
                }
            }
        }
    }
`

export class AddEmployeeModal extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        isSubmitted: false,
        addedUserFullname: '',
        addedUserEmail: '',
    }

    private formRef = React.createRef<Form>()
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.AddEmployeeModal)
    private settingsLoc = localize.namespaceTranslate(t => t.Settings)
    private languageLoc = localize.getCurrentLocale()

    public render() {
        const { requestClose } = this.props
        const { isSubmitted } = this.state

        return (
            <GQLMutation
                mutation={CREATE_EMPLOYEE_MUTATION}
                refetchQueries={[
                    { query: CUSTOMER_QUERY, variables: { customerSlug: this.context.customer.slug } },
                    { query: CUSTOMER_EMPLOYEES_QUERY, variables: { customerSlug: this.context.customer.slug } },
                ]}
            >
                {(mutate, { loading }) => (
                    <Form
                        ref={this.formRef}
                        onSubmit={this.handleSubmitForm(mutate)}
                        defaultState={{
                            role: EmployeeRole.customer,
                        }}
                    >
                        <Modal
                            requestClose={requestClose}
                            title={
                                isSubmitted
                                    ? this.settingsLoc(t => t.inviteSentTitle)
                                    : this.settingsLoc(t => t.Attributes.addUser)
                            }
                            submitForm={true}
                            confirmButtonLabel={localize.translate(t => t.Generic.saveAndInvite)}
                            hideButtons={isSubmitted}
                            loading={loading}
                        >
                            {isSubmitted ? this.renderSuccess(requestClose) : this.renderForm()}
                        </Modal>
                    </Form>
                )}
            </GQLMutation>
        )
    }

    private renderSuccess(requestClose: () => void) {
        const { addedUserFullname, addedUserEmail } = this.state
        const { seatsAreFilled } = this.props
        return (
            <Column>
                <Paragraph>
                    {this.settingsLoc(t => t.inviteSentMessage, { fullName: addedUserFullname, email: addedUserEmail })}
                </Paragraph>
                <Row>
                    <Button disabled={seatsAreFilled} onClick={this.handleAddNewUser} type={ButtonType.secondary}>
                        {seatsAreFilled
                            ? localize.translate(
                                  t => t.Customer.Settings.SettingsEmployeeOverviewView.maximumAmountOfUsersReached
                              )
                            : localize.translate(t => t.Customer.Settings.SettingsEmployeeOverviewView.addAnotherUser)}
                    </Button>
                    <Button onClick={this.handleCloseModal(requestClose)}>
                        {localize.translate(t => t.Customer.Settings.SettingsEmployeeOverviewView.complete)}
                    </Button>
                </Row>
            </Column>
        )
    }

    private renderForm() {
        return (
            <>
                <ErrorMessage path={'createEmployee'} />
                <Field label={this.settingsLoc(t => t.Attributes.firstName)} forInput={'firstName'}>
                    <Form.Input
                        name={'firstName'}
                        placeholder={this.settingsLoc(t => t.Attributes.firstName)}
                        autoComplete={false}
                    />
                </Field>
                <Field label={this.settingsLoc(t => t.Attributes.lastName)} forInput={'lastName'}>
                    <Form.Input
                        name={'lastName'}
                        placeholder={this.settingsLoc(t => t.Attributes.lastName)}
                        autoComplete={false}
                    />
                </Field>
                <Field label={this.settingsLoc(t => t.Attributes.email)} forInput={'email'}>
                    <Form.Input
                        name={'email'}
                        placeholder={this.settingsLoc(t => t.Attributes.email)}
                        autoComplete={false}
                    />
                </Field>
                <Field label={this.settingsLoc(t => t.Attributes.language)} forInput={'language'}>
                    <Row>
                        <Form.Radio
                            name={'language'}
                            value={Language.nl}
                            label={this.settingsLoc(t => t.Attributes.languageDutch)}
                            defaultChecked={this.languageLoc === Language.nl}
                        />
                        <Form.Radio
                            name={'language'}
                            value={Language.en}
                            label={this.settingsLoc(t => t.Attributes.languageEnglish)}
                            defaultChecked={this.languageLoc !== Language.nl}
                        />
                    </Row>
                </Field>
                <Guard condition={permissions.isConsultantUser()}>
                    <Field label={this.loc(t => t.customerConsultant)} forInput={'isCustomerConsultant'}>
                        <Form.Checkbox
                            name={'isCustomerConsultant'}
                            label={this.loc(t => t.isCustomerConsultant)}
                            defaultChecked={false}
                            large={true}
                        />
                    </Field>
                </Guard>
                <Field label={this.settingsLoc(t => t.Attributes.role)} forInput={'role'}>
                    <CustomerRoleSelect name={'role'} defaultValue={EmployeeRole.customer} />
                </Field>
                <Field label={this.settingsLoc(t => t.Attributes.departments)} forInput={'departments'}>
                    <DepartmentSelect />
                </Field>
                {this.context.customer.ssoLoginEnabled && (
                    <Field label={this.settingsLoc(t => t.Attributes.sso)}>
                        <Form.Radio
                            name={'ssoLoginEnabled'}
                            value={true}
                            label={this.settingsLoc(t => t.Attributes.ssoEnabledYes)}
                        />
                        <Form.Radio
                            name={'ssoLoginEnabled'}
                            value={false}
                            label={this.settingsLoc(t => t.Attributes.ssoEnabledNo)}
                        />
                    </Field>
                )}
            </>
        )
    }

    private handleSubmitForm = (mutate: MutationFn) => async (formState: FormState) => {
        const { firstName, lastName, email, role, ssoLoginEnabled, isCustomerConsultant, language } = formState

        const departments = this.getDepartmentStatuses(formState)
        const customerSlug = this.context.customer.slug

        const fields = {
            firstName,
            lastName,
            email,
            role: role.value || EmployeeRole.customer,
            departments,
            ssoLoginEnabled,
            isCustomerConsultant,
            language: language || this.languageLoc,
        }

        const response = await mutate({ variables: { customerSlug, fields } })

        if (response && response?.data && response?.data.createEmployee) {
            this.setState({
                isSubmitted: true,
                addedUserFullname: `${formState.firstName} ${formState.lastName}`,
                addedUserEmail: formState.email,
            })

            const { onAdd } = this.props
            if (onAdd) {
                onAdd()
            }
        }
    }

    private handleCloseModal = (requestClose: () => void) => () => {
        requestClose()

        this.setState({
            isSubmitted: false,
        })
    }

    private handleAddNewUser = () => {
        this.formRef.current?.clear()
        this.setState({
            isSubmitted: false,
        })
    }

    private getDepartmentStatuses(state: FormState) {
        const departments = Object.keys(state).filter(key => startsWith(key, 'department-status-'))

        if (!departments.length) {
            return {
                activeDepartmentIds: [],
                passiveDepartmentIds: [],
            }
        }

        let activeDepartmentIds: number[] = []
        let passiveDepartmentIds: number[] = []

        Object.entries(state).forEach(entry => {
            const key = entry[0]
            const val = entry[1]
            const departmentId = parseInt(key.split('-')[2], 10)

            if (val === DepartmentStatus.active) {
                activeDepartmentIds.push(departmentId)
                passiveDepartmentIds = passiveDepartmentIds.filter(id => id !== departmentId)
                return
            }

            if (val === DepartmentStatus.passive) {
                passiveDepartmentIds.push(departmentId)
                activeDepartmentIds = activeDepartmentIds.filter(id => id !== departmentId)
                return
            }

            if (val === DepartmentStatus.unauthorized) {
                activeDepartmentIds = activeDepartmentIds.filter(id => id !== departmentId)
                passiveDepartmentIds = passiveDepartmentIds.filter(id => id !== departmentId)
            }
        })

        return {
            activeDepartmentIds: Array.from(new Set(activeDepartmentIds)),
            passiveDepartmentIds: Array.from(new Set(passiveDepartmentIds)),
        }
    }
}
