import React from 'react'
import { ArticleTitle } from '~/components/Core/Text/ArticleTitle'
import { localize } from '~/bootstrap'
import { PageDetailContent } from '~/components/Core/Layout/PageDetail/PageDetailContent'
import { RouteComponentProps } from 'react-router'
import { PageDetail } from '~/components/Core/Layout/PageDetail/PageDetail'
import gql from 'graphql-tag'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { PageDetailMeta } from '~/components/Core/Layout/PageDetail/PageDetailMeta'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Markdown } from '~/components/Core/Text/Markdown'
import { LinkedItemsFields, GroupedItem } from '~/graphql/types/LinkedItem'
import { GroupedLinkedItemsLists } from '~/components/Domain/LinkedList/GroupedLinkedItemsLists'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Attachment, AttachmentFields } from '~/graphql/types/Attachment'
import { AttachmentList } from '~/components/Core/DataDisplay/AttachmentList/AttachmentList'
import { breadcrumbs } from '~/views/breadcrumbs'
import { Query } from 'react-apollo'
import { LocalStorageKeys, LocalStorageService } from '~/services/LocalStorageService'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { NewsDetailTranslationTombstonesContainer } from '~/components/Domain/News/NewsDetailTranslationTombstonesContainer'
import {
    TranslatedNewsItemQuery,
    TranslatedNewsItemDocument,
    TranslatedNewsItemQueryVariables,
    Language,
} from '~/generated/graphql'
import { NewsUrlButton } from '~/components/Domain/News/NewsUrlButton'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface RouteParams {
    id?: string
}

const GET_NEWS_QUERY = gql`
    query newsItem($id: Int!) {
        newsItem(id: $id) {
            id
            title
            publicationDate
            content
            customSourceUrl
            originalSource
            attachments {
                ...AttachmentFields
            }
            linkedItems {
                ...LinkedItemsFields
            }
        }
    }
    ${LinkedItemsFields}
    ${AttachmentFields}
`

interface News {
    id: number
    title: string
    content: string
    customSourceUrl?: string
    originalSource?: string
    publicationDate: string | Date
    linkedItems: GroupedItem[]
    attachments: Attachment[]
}

interface Props extends RouteComponentProps<RouteParams> {}

export class NewsDetailView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const shouldRenderTranslatedContent = LocalStorageService.getItem(LocalStorageKeys.Language) !== Language.nl

        if (shouldRenderTranslatedContent) {
            return this.renderTranslatedContent()
        }

        return this.renderOriginalContent()
    }

    private renderOriginalContent() {
        const id = parseInt(this.props.match.params.id!, 10)

        return (
            <PageQuery<News> query={GET_NEWS_QUERY} variables={{ id }}>
                {news => this.renderPageDetail(news)}
            </PageQuery>
        )
    }

    private renderTranslatedContent() {
        const { id } = this.props.match.params

        if (!id) {
            return null
        }

        return (
            <Query<TranslatedNewsItemQuery, TranslatedNewsItemQueryVariables>
                query={TranslatedNewsItemDocument}
                variables={{ id: parseInt(id, 10) }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return <NewsDetailTranslationTombstonesContainer delayed={true} />
                    }

                    if (!data || !data.newsItem) {
                        return <NoResults />
                    }

                    const news = { ...data.newsItem, content: data.newsItem.translatedContent } as News

                    return this.renderPageDetail(news)
                }}
            </Query>
        )
    }

    private renderPageDetail(news: News) {
        const { title, content, linkedItems } = news
        const hasSidebar = linkedItems.length !== 0

        return (
            <PageDetail
                center={!hasSidebar}
                renderTitle={() => <ArticleTitle title={title} />}
                renderSidebar={() => (hasSidebar ? <GroupedLinkedItemsLists linkedItems={linkedItems} /> : null)}
                breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).news.index]}
            >
                <PageDetailMeta items={this.getPageDetailMetaItems(news)} />
                <PageDetailContent>
                    <Markdown source={content} />
                    {news.attachments && news.attachments.length > 0 && (
                        <AttachmentList attachments={news.attachments} />
                    )}
                </PageDetailContent>
            </PageDetail>
        )
    }

    private getPageDetailMetaItems({ publicationDate, customSourceUrl, originalSource }: News) {
        const publicationItem = {
            label: localize.translate(t => t.News.Attributes.publicationDate),
            value: (
                <Paragraph>
                    <DateFormat readable={true} noWeekday={true} date={new Date(publicationDate)} />
                </Paragraph>
            ),
        }

        const sourceUrl = customSourceUrl || originalSource
        if (!sourceUrl) {
            return [publicationItem]
        }

        return [
            publicationItem,
            {
                label: localize.translate(t => t.News.Attributes.source),
                value: <NewsUrlButton autoTrim={true} news={{ customSourceUrl, originalSource }} />,
            },
        ]
    }
}
