import './MainProfile.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { CSSTransition } from 'react-transition-group'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { MainProfileRow } from '~/components/Chrome/MainHeader/Desktop/MainProfileRow/MainProfileRow'
import { MediaQuery, Screensize } from '~/components/Core/Layout/MediaQuery'
import { CustomerConsumer } from '~/components/Providers/CustomerProvider'
import { FrameworkIcon } from '~/components/Domain/Customer/Framework/FrameworkIcon/FrameworkIcon'
import { TabletProfileDropdown } from '~/components/Chrome/MainHeader/Tablet/TabletProfileDropdown/TabletProfileDropdown'
import { animations } from '~/animations'

interface Props {
    className?: ClassValue
    compact?: boolean
}

interface State {
    isOpen: boolean
}

export class MainProfile extends React.Component<Props, State> {
    public state: State = {
        isOpen: false,
    }

    private containerRef = React.createRef<HTMLDivElement>()

    private bem = new BEM('MainProfile', () => ({
        'is-compact': this.props.compact,
    }))

    public render() {
        const { className } = this.props
        const { isOpen } = this.state
        return (
            <div className={this.bem.getClassName(className)} ref={this.containerRef}>
                <MediaQuery breakpoint={Screensize.desktop}>
                    <MainProfileRow />
                </MediaQuery>

                <MediaQuery breakpoint={Screensize.betweenTabletAndDesktop}>
                    <CustomerConsumer>
                        {context => {
                            if (!context) {
                                return null
                            }

                            const activeProfiles = context.profiles.filter(({ isActive }) => isActive)

                            const amountOfColumns = Math.ceil(activeProfiles.length / 2)
                            const width = amountOfColumns * 12 + amountOfColumns * 6

                            return (
                                <>
                                    <button
                                        className={this.bem.getElement('button')}
                                        type="button"
                                        onClick={this.toggleOpen}
                                    >
                                        <div className={this.bem.getElement('profile-container')}>
                                            <ol className={this.bem.getElement('header')} style={{ width }}>
                                                {activeProfiles.map(profile => {
                                                    return (
                                                        <CSSTransition
                                                            in={true}
                                                            key={profile.id}
                                                            timeout={225}
                                                            classNames={animations.popIn}
                                                            unmountOnExit={false}
                                                            appear={true}
                                                        >
                                                            <li className={this.bem.getElement('profile-icon')}>
                                                                <FrameworkIcon
                                                                    frameworkType={profile.icon}
                                                                    color={profile.color}
                                                                    isDot={true}
                                                                />
                                                            </li>
                                                        </CSSTransition>
                                                    )
                                                })}
                                            </ol>
                                        </div>
                                        <CSSTransition
                                            appear={true}
                                            in={isOpen}
                                            timeout={225}
                                            classNames={animations.rotate180}
                                        >
                                            <Icon type={IconType.arrowDown} />
                                        </CSSTransition>
                                    </button>

                                    <CSSTransition
                                        unmountOnExit={true}
                                        in={isOpen}
                                        timeout={150}
                                        classNames={animations.fadeIn}
                                    >
                                        <TabletProfileDropdown
                                            toggleOpen={this.toggleOpen}
                                            parentRef={this.containerRef}
                                        />
                                    </CSSTransition>
                                </>
                            )
                        }}
                    </CustomerConsumer>
                </MediaQuery>
            </div>
        )
    }

    private toggleOpen = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }))
    }
}
