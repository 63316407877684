import './TopicDesignAndEffectivenessEventCard.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { EventLogType, TopicAssessmentDesignOrEffectiveNessType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'

interface Props {
    event: EventLogType
    content: JSX.Element
    assessmentType?: TopicAssessmentDesignOrEffectiveNessType
}

export class TopicDesignAndEffectivenessEventCard extends React.PureComponent<Props> {
    private bem = new BEM('TopicDesignAndEffectivenessEventCard')
    private tableLoc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessTable)

    public render() {
        const { content } = this.props

        return (
            <Row fullWidth={true} noSpacing={true} className={this.bem.getClassName()}>
                {this.renderInfo()}
                <div className={this.bem.getElement('content-container')}>{content}</div>
            </Row>
        )
    }

    private renderInfo() {
        const { event, assessmentType } = this.props
        const eventTime = localize.dateFormat(new Date(event.occuredOn), {
            noDay: true,
            noWeekday: true,
            noYear: true,
            noMonth: true,
            includeTime: true,
            short: true,
        })

        const typeLiteral = assessmentType ? { type: this.tableLoc(t => t[assessmentType]) } : undefined

        return (
            <Column extraSmallSpacing={true}>
                <Row extraSmallSpacing={true}>
                    <Paragraph subtle={true} bold={true} small={true}>
                        {eventTime}
                    </Paragraph>
                    <Paragraph subtle={true} small={true}>
                        {event.user?.profile.fullName}
                    </Paragraph>
                </Row>
                <Paragraph bold={true}>{localize.translate(t => t.Events[event.type], typeLiteral)}</Paragraph>
            </Column>
        )
    }
}
