import React from 'react'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import gql from 'graphql-tag'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import { RadarPhaseEnumType } from '~/generated/graphql'
import { localize } from '~/bootstrap'

interface Props {
    name?: string
    onChange: (option: SelectOption<RadarPhaseEnumType> | null) => void
    selectedOption?: SelectOption<RadarPhaseEnumType> | null
    placeholder?: string
    isFilter?: boolean
}

interface State {
    search: string | null
}

const QUERY = gql`
    query radarPhases($take: Int, $skip: Int, $filters: RadarPhaseFilters, $sort: RadarPhaseSort) {
        radarPhases(take: $take, skip: $skip, filters: $filters, sort: $sort) {
            totalCount
            hasNextPage
            nodes {
                id
                name
                type
            }
        }
    }
`

interface Assessment {
    id: number
    name: string
    type: RadarPhaseEnumType
}

export class RadarPhaseSelect extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        search: '',
    }

    public render() {
        const { name, onChange, selectedOption, placeholder, isFilter } = this.props
        const { search } = this.state

        return (
            <PaginatableQuery<Assessment>
                query={QUERY}
                variables={{
                    filters: {
                        search: search,
                    },
                    sort: {
                        order: 'ASC',
                    },
                }}
            >
                {({ data, loading, loadingMore, fetchMore }) => {
                    if (!data) {
                        // key property is necessary to make sure that
                        // <Form.Select> is fully remounted when loading=false
                        return <Form.Select key={'loading'} name={'loading'} loading={true} />
                    }

                    const phases = data.nodes.map(source => ({ label: localize.translate(t => t.Customer.RadarView.RadarStages[source.type]), value: source.type }))

                    return (
                        <Form.Select
                            placeholder={placeholder}
                            name={name || 'phase'}
                            defaultValue={selectedOption ? [selectedOption] : undefined}
                            searchable={true}
                            options={phases}
                            onEndReached={fetchMore}
                            clearable={true}
                            loading={loading || loadingMore}
                            onChange={(option: SelectOption<RadarPhaseEnumType>) => {
                                onChange(option)
                            }}
                            onSearch={query => this.setState({ search: query })}
                            isFilter={isFilter}
                        />
                    )
                }}
            </PaginatableQuery>
        )
    }
}
