import React from 'react'
import { MutationFn } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    EditTopicNoteMutationResult,
    EditTopicNoteDocument,
    EditTopicNoteMutation,
    EditTopicNoteMutationVariables,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

export type EditTopicNoteMutationFN = MutationFn<EditTopicNoteMutation, EditTopicNoteMutationVariables>

interface Props {
    children: (mutate: EditTopicNoteMutationFN, result: EditTopicNoteMutationResult) => React.ReactNode
}

export class EditTopicNote extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<EditTopicNoteMutation, EditTopicNoteMutationVariables> mutation={EditTopicNoteDocument}>
                {(mutate, result) => children(mutate, result)}
            </GQLMutation>
        )
    }
}
