import './CreateNewCustomerInGroupView.scss'

import React from 'react'
import { RouteComponentProps } from 'react-router'
import { localize, notification } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Column } from '~/components/Core/Layout/Column'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerFormFields } from '~/components/Domain/Consultants/Customer/CustomerFormFields'
import { BEM } from '~/services/BEMService'
import { breadcrumbs } from '~/views/breadcrumbs'
import { routes } from '~/views/routes'
import {
    CreateCustomer,
    CreateCustomerMutationFN,
} from '~/components/Domain/Consultants/Customer/mutations/CreateCustomer'
import { CustomerGroupType } from '~/generated/graphql'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'

interface Props extends RouteComponentProps<{ id: string; name: string }> {}

export class CreateNewCustomerInGroupView extends React.PureComponent<Props> {
    private bem = new BEM('CreateNewCustomerInGroupView')

    public render() {
        const { id, name } = this.props.match.params
        const parsedId = parseInt(id, 10)

        const defaultState = { availableSeats: 1, customerGroupId: parsedId }
        const title = localize.translate(t => t.Consultant.CustomerGroupsView.CustomerGroupDetailView.newCustomer)
        const pageBreadcrumbs = [
            breadcrumbs.consultant().customerGroups.index,
            breadcrumbs.consultant().customerGroups.detail(parsedId, name),
        ]

        return (
            <Page className={this.bem.getClassName()}>
                <PageHeader title={title} breadCrumbs={pageBreadcrumbs} />
                <CreateCustomer>
                    {(mutate, { loading }) => (
                        <Form onSubmit={this.handleSubmit(mutate)} defaultState={defaultState}>
                            <Column extraBigSpacing={true} className={this.bem.getElement('form-container')}>
                                <ErrorMessage path="createCustomer" />
                                <CustomerFormFields
                                    defaultAvailableSeats={1}
                                    defaultCustomerGroup={{ id: parsedId, name } as CustomerGroupType}
                                    onCustomerGroupChange={this.handleCustomerGroupChange}
                                />
                                {this.renderAction(loading)}
                            </Column>
                        </Form>
                    )}
                </CreateCustomer>
            </Page>
        )
    }

    private handleSubmit = (mutate: CreateCustomerMutationFN) => async (formState: FormState) => {
        const { id } = this.props.match.params
        const consultantContactId = formState.consultantContactId ? formState.consultantContactId.value : undefined
        const customerGroupId = id === 'null' ? undefined : parseInt(id, 10)
        const fields = { ...formState, consultantContactId, customerGroupId }

        const response = await mutate({ variables: { fields } })

        if (response && response.data && response.data.createCustomer) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))

            if (!customerGroupId) {
                this.props.history.push(routes.consultant.customers.index)
                return
            }

            this.props.history.push(routes.consultant.customerGroups.detail(id).view)
        }
    }

    private handleCustomerGroupChange = (selectedOption: SelectOption | null) => {
        if (!selectedOption) {
            return
        }

        const { value, label } = selectedOption

        this.props.history.replace(
            routes.consultant.customerGroups.detail(value as number).newCustomer(label as string)
        )
    }

    private renderAction(loading: boolean) {
        const { id } = this.props.match.params

        return (
            <Row alignRight={true}>
                <Button
                    type={ButtonType.secondary}
                    disabled={loading}
                    to={routes.consultant.customerGroups.detail(id).view}
                >
                    {localize.translate(t => t.Generic.cancel)}
                </Button>
                <Button submit={true} loading={loading}>
                    {localize.translate(t => t.Generic.save)}
                </Button>
            </Row>
        )
    }
}
