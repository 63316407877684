import React from 'react'
import { localize } from '~/bootstrap'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { MediaQuery, Screensize } from '~/components/Core/Layout/MediaQuery'
import { ClassValue } from '~/services/BEMService'
import { routes } from '~/views/routes'
import { MainNavigation } from './MainNavigation/MainNavigation'

interface Props {
    className?: ClassValue
}

export class ConsultantNavigation extends React.PureComponent<Props, {}> {
    public render() {
        return <>{this.renderDesktopNavigation()}</>
    }

    private getNavigationLinks = () => {
        return [
            {
                label: localize.translate(t => t.Consultant.routes[routes.consultant.customerGroups.index]),
                route: routes.consultant.customerGroups.index,
                icon: <Icon type={IconType.customerGroup} />,
            },
            {
                label: localize.translate(t => t.Consultant.routes[routes.consultant.customers.index]),
                route: routes.consultant.customers.index,
                icon: <Icon type={IconType.customers} />,
            },
            {
                label: localize.translate(t => t.Consultant.routes[routes.consultant.customerfeeds.index]),
                route: routes.consultant.customerfeeds.index,
                icon: <Icon type={IconType.customerfeeds} />,
            },
            {
                label: localize.translate(t => t.Consultant.routes[routes.consultant.organization.index]),
                icon: <Icon type={IconType.company} />,
                children: [
                    {
                        label: localize.translate(t => t.Consultant.OrganizationEmployeesOverviewView.title),
                        route: routes.consultant.organization.employees.index,
                    },
                    {
                        label: localize.translate(t => t.Consultant.OrganizationProfileView.title),
                        route: routes.consultant.organization.profile.index,
                    },
                ],
            },
            {
                label: localize.translate(t => t.Consultant.routes[routes.consultant.taskTemplates.index]),
                route: routes.consultant.taskTemplates.index,
                icon: <Icon type={IconType.taskTemplate} />,
            },
        ]
    }

    private renderDesktopNavigation() {
        return (
            <MediaQuery breakpoint={Screensize.tablet}>
                <MainNavigation navigation={this.getNavigationLinks()} />
            </MediaQuery>
        )
    }
}
