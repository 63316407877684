import React from 'react'
import './CustomerNavigation.scss'
import { BEM, ClassValue } from '~/services/BEMService'
import { permissions, localize, userClient } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Toolbar } from '../ToolbarItem/Toolbar'
import { EmployeePermissionEnum } from '~/generated/graphql'
import { CustomerNavigationItem, CustomerNavigationItemProps } from './CustomerNavigationItem'
import { Logo } from '../../Logo/Logo'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { LanguageSwitchModal } from '../LanguageSwitchModal/LanguageSwitchModal'
import { ReleaseNotesManager } from '~/components/Domain/ReleaseNotes/ReleaseNotesManager'

interface Props {
    className?: ClassValue
    isConsultant: boolean
    isCustomerPlanner: boolean
    hasBanner?: boolean
}

interface State {
    shouldHideContextSwitch: boolean
    hideNavigationMenuBorder: boolean
}

export class CustomerNavigation extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {
        shouldHideContextSwitch: this.checkIfShouldHideContextSwitch(),
        hideNavigationMenuBorder: this.checkIfShouldHideBorder(),
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const shouldHideContextSwitch = this.checkIfShouldHideContextSwitch()
        const hideNavigationMenuBorder = this.checkIfShouldHideBorder()

        if (shouldHideContextSwitch !== prevState.shouldHideContextSwitch) {
            this.setState({ shouldHideContextSwitch })
        }
        if (hideNavigationMenuBorder !== prevState.hideNavigationMenuBorder) {
            this.setState({ hideNavigationMenuBorder })
        }
    }

    private bem = new BEM('CustomerNavigation', () => ({
        hasBanner: !!this.props.hasBanner,
        hideBorder: this.state.hideNavigationMenuBorder,
    }))

    public render() {
        const { hasBanner } = this.props

        const topNavigationItems = this.getTopNavigation().filter(n => !!n) as CustomerNavigationItemProps[]

        return (
            <div className={this.bem.getClassName()}>
                <div className={this.bem.getElement('top-group')}>
                    {this.renderLogo()}
                    {topNavigationItems.map((n, k) => (
                        <CustomerNavigationItem {...n} key={k} className={this.bem.getElement('top-item')} />
                    ))}
                </div>
                <div className={this.bem.getElement('bottom-group')}>
                    <ReleaseNotesManager
                        render={(openReleaseNotes, version) => (
                            <ModalManager
                                renderModal={closeModal => <LanguageSwitchModal closeModal={closeModal} />}
                                render={openLanguageSwitch => {
                                    const bottomNavigationItems = this.getBottomNavigation(
                                        openLanguageSwitch,
                                        openReleaseNotes,
                                        version
                                    ).filter(n => !!n) as CustomerNavigationItemProps[]

                                    return bottomNavigationItems.map((n, k) => (
                                        <CustomerNavigationItem
                                            {...n}
                                            inversePopperPlacement={true}
                                            key={k}
                                            className={this.bem.getElement('bottom-item')}
                                        />
                                    ))
                                }}
                            />
                        )}
                    />
                </div>
                <Toolbar hasBanner={hasBanner} hideContextSwitch={this.state.shouldHideContextSwitch} />
            </div>
        )
    }

    private renderLogo() {
        if (this.props.isConsultant) {
            return (
                <div className={this.bem.getElement('logo-container')}>
                    <Logo iconOnly={true} />
                </div>
            )
        }

        return (
            <div className={this.bem.getElement('logo-container')}>
                <CustomerNavigationItem
                    className={this.bem.getElement('logo-item')}
                    noHover={true}
                    icon={IconType.logo}
                    subMenuItems={[
                        {
                            to: routes.customer(this.context.customer.slug).dashboard.index,
                            label: localize.translate(t => t.Customer.routes[routes.customer().dashboard.index]),
                        },
                    ]}
                />
            </div>
        )
    }

    private getTopNavigation() {
        return [
            this.getInboxNavigationItem(),
            this.getLegalFrameworkNavigationItem(),
            this.getControlMeasuresNavigationItem(),
            this.getRisksNavigationItem(),
            this.getTasksNavigationItem(),
            this.getMonitoringNavigationItem(),
            this.getControlNavigationItem(),
            this.getSignalingNavigationItem(),
        ]
    }

    private getBottomNavigation(openLanguageModal: () => void, openReleaseNotes: () => void, version?: string) {
        return [
            this.getSourcesNavigationItem(),
            this.getEnvironmentNavigationItem(),
            this.getSettingsNavigation(openLanguageModal, openReleaseNotes, version),
        ]
    }

    private getEnvironmentNavigationItem(): CustomerNavigationItemProps | null {
        if (!this.context?.hasCustomers) {
            return null
        }

        const { slug } = this.context.customer

        return {
            icon: IconType.navigationUnion,
            popperOptions: { modifiers: [{ name: 'offset', options: { offset: [-19, 0] } }] },
            subMenuItems: [
                {
                    to: routes.customer(slug).switch,
                    label: localize.translate(t => t.User.CurrentUser.switch),
                },
            ],
        }
    }

    private getSettingsNavigation(
        openLanguageModal: () => void,
        openReleaseNotes: () => void,
        version?: string
    ): CustomerNavigationItemProps | null {
        const { isConsultant } = this.props
        const showCompliance = !isConsultant && permissions.canViewComplianceAndDocuments()
        const showNews = !isConsultant && permissions.hasPermission(EmployeePermissionEnum.canMonitorSignaling)
        const languageIcon = userClient.getCurrentUserLanguage() === 'en' ? IconType.en : IconType.nl

        return {
            subMenuTitle: localize.translate(t => t.Customer.routes[routes.customer().settings.index]),
            icon: IconType.navigationSettings,
            subMenuItems: [
                {
                    label: localize.translate(t => t.User.CurrentUser.language),
                    onClick: openLanguageModal,
                    meta: <Icon large={true} type={languageIcon} />,
                },
                {
                    label: localize.translate(t => t.Customer.routes[routes.customer().settings.index]),
                    popperOptions: { modifiers: [{ name: 'offset', options: { offset: [17, 0] } }] },
                    items: [
                        {
                            to: routes.customer(this.context.customer.slug).settings.employees.index,
                            label: localize.translate(
                                t => t.Customer.routes[routes.customer().settings.employees.index]
                            ),
                        },
                        {
                            to: routes.customer(this.context.customer.slug).settings.frameworks.index,
                            label: localize.translate(
                                t => t.Customer.routes[routes.customer().settings.frameworks.index]
                            ),
                        },
                        {
                            to: routes.customer(this.context.customer.slug).settings.departments.index,
                            label: localize.translate(
                                t => t.Customer.routes[routes.customer().settings.departments.index]
                            ),
                        },
                        ...(showCompliance
                            ? [
                                  {
                                      to: routes.customer(this.context.customer.slug).settings.compliance.index,
                                      label: localize.translate(
                                          t => t.Customer.routes[routes.customer().settings.compliance.index]
                                      ),
                                  },
                              ]
                            : []),
                        ...(showNews
                            ? [
                                  {
                                      to: routes.customer(this.context.customer.slug).settings.news.index,
                                      label: localize.translate(
                                          t => t.Customer.routes[routes.customer().settings.news.index]
                                      ),
                                  },
                              ]
                            : []),
                        {
                            to: routes.customer(this.context.customer.slug).settings.taskTemplates.index,
                            label: localize.translate(
                                t => t.Customer.routes[routes.customer().settings.taskTemplates.index]
                            ),
                        },
                        ...(permissions.canViewSAMLSettings() && this.context.customer.ssoLoginEnabled
                            ? [
                                  {
                                      to: routes.customer(this.context.customer.slug).settings.saml.index,
                                      label: localize.translate(
                                          t => t.Customer.routes[routes.customer().settings.saml.index]
                                      ),
                                  },
                              ]
                            : []),
                    ],
                },
                {
                    label: localize.translate(t => t.User.CurrentUser.Help),
                    onClick: () => window.open('https://ruler.nl/help', '_blank'),
                },
                {
                    label: localize.translate(t => t.User.CurrentUser.SignOut),
                    onClick: () => userClient.logout(),
                },
                {
                    label: `${localize.translate(t => t.Generic.version)} ${version || ''}`,
                    onClick: openReleaseNotes,
                },
            ],
        }
    }

    private getInboxNavigationItem(): CustomerNavigationItemProps | null {
        const { isConsultant, isCustomerPlanner } = this.props
        const showCompliance = !isConsultant && permissions.canViewComplianceAndDocuments()
        const isDisabled = !showCompliance || isCustomerPlanner

        if (isDisabled) {
            return null
        }

        return {
            icon: IconType.navigationInbox,
            subMenuItems: [
                {
                    label: localize.translate(t => t.Customer.routes[routes.customer().inbox.index]),
                    to: routes.customer(this.context.customer.slug).inbox.index,
                },
            ],
            onboardingStep: {
                order: 3,
                title: localize.translate(t => t.Customer.Onboarding.steps.inbox.title),
                description: localize.translate(t => t.Customer.Onboarding.steps.inbox.description),
            },
        }
    }

    private getLegalFrameworkNavigationItem(): CustomerNavigationItemProps | null {
        const { isCustomerPlanner } = this.props
        const isDisabled = isCustomerPlanner

        if (isDisabled) {
            return null
        }

        return {
            icon: IconType.navigationLegalFramework,
            subMenuTitle: localize.translate(t => t.Customer.navigation.LegalFramework),
            onboardingStep: {
                order: 4,
                title: localize.translate(t => t.Customer.Onboarding.steps.legalFramework.title),
                description: localize.translate(t => t.Customer.Onboarding.steps.legalFramework.description),
            },
            subMenuItems: [
                {
                    label: localize.translate(t => t.Customer.routes[routes.customer().legalFramework.index]),
                    to: routes.customer(this.context.customer.slug).legalFramework.index,
                },
                {
                    label: localize.translate(t => t.Customer.routes[routes.customer().radar.index]),
                    to: routes.customer(this.context.customer.slug).radar.index,
                },
                {
                    label: localize.translate(t => t.Customer.routes[routes.customer().consultations.index]),
                    to: routes.customer(this.context.customer.slug).consultations.index,
                },
            ],
        }
    }

    private getControlMeasuresNavigationItem(): CustomerNavigationItemProps | null {
        const { isConsultant, isCustomerPlanner } = this.props
        const showCompliance = !isConsultant && permissions.canViewComplianceAndDocuments()
        const isDisabled = !showCompliance || isCustomerPlanner

        if (isDisabled) {
            return null
        }

        return {
            icon: IconType.navigationControlMeasures,
            onboardingStep: {
                order: 5,
                title: localize.translate(t => t.Customer.Onboarding.steps.compliance.title),
                description: localize.translate(t => t.Customer.Onboarding.steps.compliance.description),
            },
            subMenuItems: [
                {
                    label: localize.translate(t => t.Customer.routes[routes.customer().compliance.controls.index]),
                    to: routes.customer(this.context.customer.slug).compliance.controls.index,
                },
            ],
        }
    }

    private getRisksNavigationItem(): CustomerNavigationItemProps | null {
        const { isConsultant, isCustomerPlanner } = this.props
        const showCompliance = !isConsultant && permissions.canViewComplianceAndDocuments()
        const isDisabled = !showCompliance || isCustomerPlanner

        if (isDisabled) {
            return null
        }

        return {
            icon: IconType.navigationRisks,
            subMenuTitle: localize.translate(t => t.Customer.routes[routes.customer().risks.index]),
            subMenuItems: [
                {
                    label: localize.translate(t => t.Customer.routes[routes.customer().risks.heatmap.index]),
                    to: routes.customer(this.context.customer.slug).risks.heatmap.index,
                },
                {
                    label: localize.translate(t => t.Customer.routes[routes.customer().risks.assessments.index]),
                    to: routes.customer(this.context.customer.slug).risks.assessments.index,
                },
            ],
        }
    }

    private getTasksNavigationItem(): CustomerNavigationItemProps | null {
        const { isConsultant, isCustomerPlanner } = this.props
        const showCompliance = !isConsultant && permissions.canViewComplianceAndDocuments()
        const isDisabled = isCustomerPlanner ? false : !showCompliance

        if (isDisabled) {
            return null
        }

        return {
            icon: IconType.navigationTasks,
            onboardingStep: {
                order: 5,
                title: localize.translate(t => t.Customer.Onboarding.steps.planning.title),
                description: localize.translate(t => t.Customer.Onboarding.steps.planning.description),
            },
            subMenuItems: [
                {
                    label: localize.translate(t => t.Customer.routes[routes.customer().tasks.index]),
                    to: routes.customer(this.context.customer.slug).tasks.index,
                },
            ],
        }
    }

    private getMonitoringNavigationItem(): CustomerNavigationItemProps | null {
        const { isConsultant, isCustomerPlanner } = this.props
        const showCompliance = !isConsultant && permissions.canViewComplianceAndDocuments()
        const isDisabled = isCustomerPlanner ? false : !showCompliance

        if (isDisabled) {
            return null
        }

        const subMenuItems: CustomerNavigationItemProps['subMenuItems'] = [
            {
                label: localize.translate(t => t.Customer.routes[routes.customer().monitoring.agenda]),
                to: routes.customer(this.context.customer.slug).monitoring.agenda,
            },
        ]

        if (permissions.canViewMonitoringReports()) {
            subMenuItems.push({
                label: localize.translate(t => t.Customer.routes[routes.customer().monitoring.reports.index]),
                to: routes.customer(this.context.customer.slug).monitoring.reports.index,
            })
        }

        return {
            icon: IconType.navigationMonitoring,
            subMenuTitle: localize.translate(t => t.Customer.routes[routes.customer().monitoring.index]),
            subMenuItems,
        }
    }

    private getControlNavigationItem(): CustomerNavigationItemProps | null {
        const isTaskControlDisabled = !permissions.canAccessTaskControlPage()
        const isInboxControlDisabled = !permissions.canAccessInboxControlPage()
        const isDisabled = isTaskControlDisabled && isInboxControlDisabled

        if (isDisabled) {
            return null
        }

        const subMenuItems: CustomerNavigationItemProps['subMenuItems'] = []
        if (!isTaskControlDisabled) {
            subMenuItems.push({
                label: localize.translate(t => t.Customer.routes[routes.customer().control.taskControl.index]),
                to: routes.customer(this.context.customer.slug).control.taskControl.index,
            })
        }

        if (!isInboxControlDisabled) {
            subMenuItems.push({
                label: localize.translate(t => t.Customer.routes[routes.customer().control.inboxControl]),
                to: routes.customer(this.context.customer.slug).control.inboxControl,
            })
        }

        return {
            subMenuTitle: localize.translate(t => t.Customer.routes[routes.customer().control.index]),
            icon: IconType.navigationControl,
            subMenuItems,
        }
    }

    private getSignalingNavigationItem(): CustomerNavigationItemProps | null {
        const isDisabled = !permissions.hasPermission(EmployeePermissionEnum.canMonitorSignaling)

        if (isDisabled) {
            return null
        }

        return {
            subMenuItems: [
                {
                    to: routes.customer(this.context.customer.slug).signaling.index,
                    label: localize.translate(t => t.Customer.routes[routes.customer().signaling.index]),
                },
            ],
            icon: IconType.navigationSignaling,
        }
    }

    private getSourcesNavigationItem(): CustomerNavigationItemProps | null {
        const popperOptions = this.context.hasCustomers
            ? { modifiers: [{ name: 'offset', options: { offset: [-106, 0] } }] }
            : undefined

        return {
            subMenuTitle: localize.translate(t => t.Customer.navigation.Sources),
            icon: IconType.navigationSource,
            popperOptions,
            subMenuItems: [
                {
                    label: localize.translate(t => t.Customer.routes[routes.customer().lawAndRegulation.index]),
                    to: routes.customer(this.context.customer.slug).lawAndRegulation.index,
                },
                {
                    label: localize.translate(t => t.Customer.routes[routes.customer().news.index]),
                    to: routes.customer(this.context.customer.slug).news.index,
                },
            ],
        }
    }

    private checkIfShouldHideBorder() {
        const currentPath = window.location.pathname

        if (!currentPath) {
            return false
        }

        const customerBasePath = routes.customer(this.context.customer.slug)

        return [
            customerBasePath.dashboard.index,
            customerBasePath.inbox.index,
            customerBasePath.tasks.index,
            customerBasePath.monitoring.agenda,
            customerBasePath.compliance.controls.index,
            customerBasePath.monitoring.index,
            customerBasePath.control.taskControl.index,
            customerBasePath.control.inboxControl,
            customerBasePath.legalFramework.themes.index,
            customerBasePath.signaling.index,
        ].some(path => currentPath.startsWith(path))
    }

    private checkIfShouldHideContextSwitch() {
        const currentPath = window.location.pathname

        if (!currentPath) {
            return false
        }

        const customerBasePath = routes.customer(this.context.customer.slug)
        const customerHome = customerBasePath.index

        const dashboardPath = customerBasePath.dashboard.index
        const isOnDashboardPage = currentPath === customerHome || currentPath === dashboardPath

        if (isOnDashboardPage) {
            return true
        }

        return [
            customerBasePath.control.index,
            customerBasePath.monitoring.reports.index,
            customerBasePath.settings.taskTemplates.index,
            customerBasePath.signaling.index,
        ].some(path => currentPath.startsWith(path))
    }
}
