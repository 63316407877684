import React from 'react'
import { ControlLabel } from '~/components/Domain/Compliance/Controls/ControlLabel'
import { EventLogType } from '~/generated/graphql'
import { TopicAssessmentEventType, TopicAssessmentEvent } from './TopicAssessmentEvent'
import { TopicDesignAndEffectivenessEventCard } from './TopicDesignAndEffectivenessEventCard'

interface Props {
    event: EventLogType
    deletedFilePaths?: string[]
}

enum Event {
    wasCreated = 'TOPIC_ASSESSMENT_CONTROL_MEASURE_WAS_CREATED',
}

export class TopicControlMeasureEvent extends React.PureComponent<Props> {
    public render() {
        const { event, deletedFilePaths } = this.props
        const { type } = event

        const isAssessmentEvent = Object.values(TopicAssessmentEventType).includes(type as TopicAssessmentEventType)
        if (isAssessmentEvent) {
            return <TopicAssessmentEvent event={event} deletedFilePaths={deletedFilePaths} />
        }

        return <TopicDesignAndEffectivenessEventCard event={event} content={this.renderContent()} />
    }

    private renderContent() {
        const { event } = this.props
        const { type, metaData } = event

        if (type === Event.wasCreated && metaData) {
            const control = {
                id: metaData.controlId,
                type: metaData.controlType,
                name: metaData.controlName,
            }

            return <ControlLabel externalLink={true} control={control} />
        }

        return <></>
    }
}
