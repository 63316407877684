import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'
import { UserProfileList } from '~/components/Core/DataDisplay/UserProfileList/UserProfileList'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import { UserProfilePicker } from '../UserProfilePicker'
import { CappedProfileList } from '~/components/Core/DataDisplay/CappedUserList/CappedProfileList'

interface Props {
    className?: ClassValue
    defaultUsers?: UserProfilePickerListUser[]
    name: string
    onChange?: (selectedUsers: UserProfilePickerListUser[], name: string) => void
    cappedList?: boolean
}

export interface UserProfilePickerListUser {
    id: number
    fullName: string
    uri: string | null
    email?: string
    phone: string | null
}

interface State {
    activeUsers: UserProfilePickerListUser[]
}

export class UserProfilePickerList extends React.Component<Props, State> {
    public state: State = {
        activeUsers: this.getActiveUsers(),
    }
    private bem = new BEM('UserProfilePickerList')

    public render() {
        const { className } = this.props

        return (
            <UserProfileList className={this.bem.getClassName(className)}>
                {this.renderProfileIcons()}
                {this.renderProfilePicker()}
            </UserProfileList>
        )
    }

    private renderProfilePicker() {
        const { activeUsers } = this.state

        return (
            <UserProfilePicker
                defaultSelectedUsers={activeUsers}
                onChange={addedUser =>
                    this.setState(
                        prevState => ({
                            activeUsers: [...prevState.activeUsers, addedUser],
                        }),
                        this.triggerOnChange
                    )
                }
            />
        )
    }

    private renderProfileIcons() {
        const { cappedList } = this.props
        const { activeUsers } = this.state

        if (cappedList) {
            return <CappedProfileList users={activeUsers} action={this.removeUserFromTask} />
        }

        return activeUsers.map(user => (
            <UserProfileIcon
                key={user.fullName}
                fullName={user.fullName}
                showTooltip={false}
                email={user.email}
                phone={user.phone}
                uri={user.uri}
                action={() => this.removeUserFromTask(user.id)}
            />
        ))
    }

    private getActiveUsers(): UserProfilePickerListUser[] {
        const { defaultUsers } = this.props
        if (defaultUsers && defaultUsers.length) {
            return defaultUsers
        }

        return []
    }

    private removeUserFromTask = (userId: number) => {
        const { activeUsers } = this.state
        const newUsers = activeUsers.filter(user => user.id !== userId)

        this.setState({ activeUsers: newUsers }, this.triggerOnChange)
    }

    private triggerOnChange = () => {
        const { onChange, name } = this.props
        if (onChange) {
            onChange(this.state.activeUsers, name)
        }
    }
}
