import './InboxInternalAlertItem.scss'
import React from 'react'
import { InboxInternalAlertQueryType } from './InboxInternalAlertsContainer'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { Row } from '~/components/Core/Layout/Row'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { BEM, ClassValue } from '~/services/BEMService'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { Button } from '~/components/Core/Button/Button'
import { localize } from '~/bootstrap'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { ReviewInternalAlertModal } from './ReviewInternalAlertModal'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    internalAlert: InboxInternalAlertQueryType
    refetch: () => void
    onAccept: () => void
    className?: ClassValue
}

export class InboxInternalAlertItem extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Inbox.InboxOverview)
    private bem = new BEM('InboxInternalAlertItem')

    public render() {
        const { internalAlert, refetch, onAccept } = this.props

        const detailPath = routes.customer(this.context.customer.slug).inbox.internalAlert.view(internalAlert.id)
        const currentLabel = internalAlert.publishedBy?.user.profile.fullName
            ? internalAlert.publishedBy?.user.profile.fullName
            : '?'

        return (
            <Card className={this.bem.getClassName(this.props.className)}>
                <Row spaceBetween>
                    <Column extraSmallSpacing={true}>
                        <div className={this.bem.getElement('container')}>
                            <ComponentTitle
                                title={internalAlert.news.title}
                                bold={true}
                                to={detailPath}
                                className={this.bem.getElement('title')}
                            />
                            <Icon type={IconType.signaling} large className={this.bem.getElement('icon-styling')} />
                        </div>
                        {internalAlert.news.publicationDate && this.renderDate(internalAlert.news.publicationDate)}
                    </Column>
                    <Column extraSmallSpacing={true}>
                        <Row>
                            <Column extraSmallSpacing={true} className={this.bem.getElement('avatar-container')}>
                                <div className={this.bem.getElement('user-icon-container')}>
                                    <Paragraph className={this.bem.getElement('userprofile')}>
                                        {this.loc(t => t.sentBy)}
                                    </Paragraph>
                                    <UserProfileIcon small={true} fullName={currentLabel} showTooltip={true} />
                                </div>
                                {this.renderDate(internalAlert.publishedAt)}
                            </Column>
                            <ModalManager
                                render={openModal => <Button onClick={openModal}>{this.loc(t => t.judge)}</Button>}
                                renderModal={closeModal => (
                                    <ReviewInternalAlertModal
                                        refetch={() => {
                                            onAccept()
                                            refetch()
                                        }}
                                        requestClose={closeModal}
                                        internalAlert={internalAlert}
                                    />
                                )}
                            />
                        </Row>
                    </Column>
                </Row>
            </Card>
        )
    }

    private renderDate(date: Date) {
        return (
            <Paragraph subtle className={this.bem.getElement('date-container')}>
                <DateFormat date={new Date(date)} readable includeTime={true} noWeekday />
            </Paragraph>
        )
    }
}
