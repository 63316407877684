import './OnboardingStepInstructions.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Clip } from './OnboardingOverlay'
import { OnboardingStepItem } from './OnboardingManager'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    clip: Clip
    step: OnboardingStepItem
    currentStep: number
    totalSteps: number
    handleOnPrev: () => void
    handleOnNext: () => void
    handleOnFinish: () => void
    finishLoading: boolean
}

interface State {}

export enum OnboardingInstructionPosition {
    above = 'above',
    below = 'below',
}

export class OnboardingStepInstructions extends React.PureComponent<Props, State> {
    private bem = new BEM('OnboardingStepInstructions', () => ({
        'position-above': this.props.step.position === OnboardingInstructionPosition.above,
    }))

    public render() {
        const { className, step, totalSteps, handleOnPrev, currentStep, handleOnFinish, finishLoading } = this.props

        return (
            <>
                <div
                    className={this.bem.getClassName(className)}
                    style={this.getStepInstructionPosition(step.position)}
                >
                    <Row spaceBetween={true}>
                        <h4 className={this.bem.getElement('title')}>
                            {localize.translate(t => t.Customer.Onboarding.stepIndex, {
                                currentStep: currentStep,
                                totalSteps,
                            })}
                            {step.title}
                        </h4>
                        <Button
                            type={ButtonType.noStyling}
                            icon={IconType.close}
                            onClick={handleOnFinish}
                            className={this.bem.getElement('close-button')}
                            loading={finishLoading}
                        />
                    </Row>
                    <p>{step.description}</p>
                    <Row spaceBetween={true} className={this.bem.getElement('buttons')}>
                        <Button
                            type={ButtonType.secondary}
                            onClick={handleOnPrev}
                            className={this.bem.getElement('prev-button')}
                        >
                            {localize.translate(t => t.Generic.previous)}
                        </Button>
                        {this.renderNextButton()}
                    </Row>
                </div>
                <div className={this.bem.getElement('arrow')} style={this.getArrowPosition(step.position)} />
            </>
        )
    }

    private getStepInstructionPosition(position: OnboardingInstructionPosition) {
        const { clip } = this.props

        const offset = 8

        const rectY = ((clip.y + offset) / window.innerHeight) * 100
        const rectX = ((clip.x - offset) / window.innerWidth) * 100
        const rectWidth = ((clip.width + offset * 2) / window.innerWidth) * 100
        const rectHeight = ((clip.height + offset * 2) / window.innerHeight) * 100

        const style = {
            top: 0,
            left: rectX + rectWidth / 2,
        }

        if (position === OnboardingInstructionPosition.above) {
            style.top = ((clip.y - offset * 3) / window.innerHeight) * 100
        } else if (position === OnboardingInstructionPosition.below) {
            style.top = rectY + rectHeight
        }

        // if outside view
        const boxWidth = (336 / window.innerWidth) * 100
        const outOfView = style.left + boxWidth - 100

        if (style.left + boxWidth > 100) {
            style.left = style.left - outOfView + boxWidth / 2 - (offset / window.innerHeight) * 100
        }

        if (style.left - boxWidth / 2 < 0) {
            style.left = boxWidth / 2 + (offset / window.innerHeight) * 100
        }

        return {
            top: `${style.top}%`,
            left: `${style.left}%`,
        }
    }

    private getArrowPosition(position: OnboardingInstructionPosition) {
        const { clip } = this.props

        const offset = 8

        const rectY = ((clip.y + offset) / window.innerHeight) * 100
        const rectX = ((clip.x - offset) / window.innerWidth) * 100
        const rectWidth = ((clip.width + offset * 2) / window.innerWidth) * 100
        const rectHeight = ((clip.height + offset * 2) / window.innerHeight) * 100

        const arrowY = rectY + rectHeight
        const arrowX = rectX + rectWidth / 2

        const style = {
            top: '',
            left: `${arrowX}%`,
            transform: 'rotate(0deg)',
        }

        if (position === OnboardingInstructionPosition.above) {
            style.top = `${((clip.y - offset * 2) / window.innerHeight) * 100}%`
            style.left = `${arrowX}%`
            style.transform = 'rotate(0deg)'
        } else if (position === OnboardingInstructionPosition.below) {
            style.top = `${arrowY}%`
            style.left = `${arrowX}%`
            style.transform = 'rotate(180deg)'
        }

        return style
    }

    private renderNextButton() {
        const { currentStep, totalSteps, handleOnNext, handleOnFinish } = this.props
        const isLastStep = currentStep >= totalSteps

        if (isLastStep) {
            return (
                <Button onClick={handleOnFinish} className={this.bem.getElement('next-button')}>
                    {localize.translate(t => t.Generic.close)}
                </Button>
            )
        }

        return (
            <Button onClick={handleOnNext} className={this.bem.getElement('next-button')}>
                {localize.translate(t => t.Generic.next)}
            </Button>
        )
    }
}
