import './Cover.scss'

import React from 'react'

import { BEM } from '~/services/BEMService'
import { RouteComponentProps, NavLink } from 'react-router-dom'
import { Logo } from '~/components/Chrome/Logo/Logo'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Column } from '~/components/Core/Layout/Column'
import { Center } from '~/components/Core/Layout/Center'
import { localize } from '~/bootstrap'
import { routes } from '../routes'

interface Props extends RouteComponentProps<{}> {}

export class RedirectView extends React.PureComponent<Props> {
    private bem = new BEM('Cover')

    private timer: number

    public componentDidMount() {
        this.timer = window.setTimeout(() => {
            const { token } = this.props.location.state as any
            // Set the token in the session key for the admin environment.
            window.localStorage.setItem('rl-SessionToken', token)
            window.location.href = `/admin`
        }, 2500)
    }

    public componentWillUnmount() {
        window.clearTimeout(this.timer)
    }

    public render() {
        const { accessGroup } = this.props.location.state as any

        return (
            <div className={this.bem.getClassName()}>
                <div className={this.bem.getElement('brand')}>
                    <NavLink to={routes.cover.login}>
                        <Logo />
                    </NavLink>
                </div>
                <Column>
                    <Center>
                        <Paragraph>
                            {localize.translate(t => t.Cover.RedirectView.message, {
                                env: localize.translate(t => t.Cover.RedirectView.env[accessGroup]),
                            })}
                        </Paragraph>
                        <Spinner />
                    </Center>
                </Column>
            </div>
        )
    }
}
