import React from 'react'

interface Props {
    condition: boolean
}

export const Guard: React.SFC<Props> = ({ condition, children }) => {

    if (condition) {
        return <>{children}</>
    }

    return null
}
