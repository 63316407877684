import React from 'react'
import { Mutation, MutationFn, MutationProps, MutationResult, OperationVariables } from 'react-apollo'
import { ErrorProvider } from '~/components/Providers/ErrorProvider'

type Mutator<TData, TVariables = OperationVariables> = MutationProps<TData, TVariables>

// This wraps apollo mutation so that it always providers errors
// to it's consumers.
export class GQLMutation<TData, TVariables = OperationVariables> extends React.Component<Mutator<TData, TVariables>> {
    public render() {
        return (
            <Mutation {...this.props}>
                {(mutate: MutationFn<TData, TVariables>, mutationState: MutationResult<TData>) => {
                    return (
                        <ErrorProvider error={mutationState.error}>
                            {this.props.children(mutate, mutationState)}
                        </ErrorProvider>
                    )
                }}
            </Mutation>
        )
    }
}
