import React from 'react'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ExpandableRow } from '~/components/Core/DataDisplay/ExpandableRow/ExpandableRow'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { IconType } from '~/components/Core/Icon/IconType'
import { NormArticleCount } from '~/components/Domain/Compliance/Norms/NormArticleCount'
import { TopicWithArticlesContainer } from '~/components/Domain/Compliance/Norms/TopicWithArticlesContainer'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AssessmentCoverageType } from '~/generated/graphql'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { BEM, ClassValue } from '~/services/BEMService'
import { routes } from '~/views/routes'
import { CustomerFrameworkIconList } from '../../CustomerFramework/CustomerFrameworkIconList'
import { AsssessmentCoverageBarSmall } from '../Assessments/AssessmentCoverageBar/AssessmentCoverageBarSmall'
import './ExpandableTopicCardRow.scss'

interface NormTopic {
    id: number
    name: string
    customerFrameworks: CustomerFramework[]
    assessmentCoverage: AssessmentCoverageType
}

export interface ExpandableTopicCardRowChanges {
    topicId: number
    removeTopic: boolean
    allTopicsSelected: boolean
    selectedArticleIds: number[]
}

interface Props {
    className?: ClassValue
    topic: NormTopic
    defaultCheckedArticleIds?: number[]
    isPartialTopic?: boolean
    onChange?: (changes: ExpandableTopicCardRowChanges) => void
}

interface State {
    topicIsSelected: boolean
    selectedArticleIds: number[]
    isIndetermined: boolean
}

export class ExpandableTopicCardRow extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        topicIsSelected: this.props.defaultCheckedArticleIds ? this.props.defaultCheckedArticleIds.length > 0 : false,
        selectedArticleIds: this.props.defaultCheckedArticleIds ? this.props.defaultCheckedArticleIds : [],
        isIndetermined: this.props.isPartialTopic ? true : false,
    }

    private bem = new BEM('ExpandableTopicCardRow')

    public render() {
        const { topic, className } = this.props
        const { topicIsSelected, selectedArticleIds, isIndetermined } = this.state

        return (
            <ExpandableRow
                className={this.bem.getClassName(className)}
                renderOpenContent={() => (
                    <TopicWithArticlesContainer
                        topicId={topic.id}
                        checkedArticleIds={selectedArticleIds}
                        defaultCheckedArticleIds={this.props.defaultCheckedArticleIds}
                        onToggleArticle={this.handleToggleArticle}
                        allChecked={!isIndetermined && topicIsSelected}
                    />
                )}
            >
                {toggleOpen => (
                    <div className={this.bem.getElement('preview')}>
                        <div className={this.bem.getElement('name')} onClick={toggleOpen}>
                            {topic.name}
                            <CustomerFrameworkIconList customerFrameworks={topic.customerFrameworks} />
                            <Button
                                className={this.bem.getElement('external-link')}
                                type={ButtonType.noStyling}
                                icon={IconType.external}
                                to={routes.customer(this.context.customer.slug).legalFramework.topic.view(topic.id)}
                                external={true}
                            />
                        </div>

                        <div className={this.bem.getElement('footer')}>
                            <AsssessmentCoverageBarSmall
                                renderLabel={() => (
                                    <NormArticleCount
                                        total={topic.assessmentCoverage.total ?? 0}
                                        count={
                                            (topic.assessmentCoverage.noted ?? 0) +
                                            (topic.assessmentCoverage.assessed ?? 0)
                                        }
                                    />
                                )}
                                assessmentCoverage={topic.assessmentCoverage}
                            />

                            <Checkbox
                                name={`checkbox-${topic.id}`}
                                className={this.bem.getElement('checkbox')}
                                checked={topicIsSelected}
                                onChange={this.handleOnTopicSelect}
                                indeterminate={isIndetermined}
                                disabled={!topic.assessmentCoverage.total}
                            />
                        </div>
                    </div>
                )}
            </ExpandableRow>
        )
    }

    private handleOnTopicSelect = (checked: boolean) => {
        this.setState(
            {
                selectedArticleIds: [],
                topicIsSelected: checked,
                isIndetermined: false,
            },
            () => {
                this.checkedArticlesHaveChanged()
            }
        )
    }

    private handleToggleArticle = (abstractLawArticleId: number, allIds: number[]) => {
        this.setState(
            prev => {
                if (this.state.topicIsSelected && !this.state.isIndetermined) {
                    const selectedArticleIds = allIds.filter(id => id !== abstractLawArticleId)

                    return {
                        selectedArticleIds,
                        isIndetermined: selectedArticleIds.length === 0 ? false : true,
                        topicIsSelected: selectedArticleIds.length === 0 ? false : true,
                    }
                }

                const selectedArticleIds = prev.selectedArticleIds.includes(abstractLawArticleId)
                    ? prev.selectedArticleIds.filter(id => id !== abstractLawArticleId)
                    : [...prev.selectedArticleIds, abstractLawArticleId]

                return {
                    selectedArticleIds: selectedArticleIds.length === allIds.length ? [] : selectedArticleIds,
                    isIndetermined: selectedArticleIds.length === allIds.length ? false : selectedArticleIds.length > 0,
                    topicIsSelected: selectedArticleIds.length === allIds.length ? true : selectedArticleIds.length > 0,
                }
            },
            () => {
                this.checkedArticlesHaveChanged()
            }
        )
    }

    private checkedArticlesHaveChanged = () => {
        const { onChange, topic, defaultCheckedArticleIds = [] } = this.props
        const { selectedArticleIds, topicIsSelected, isIndetermined } = this.state

        if (onChange) {
            const hadCheckedArticles = defaultCheckedArticleIds.length > 0
            const topicShouldBeRemoved = hadCheckedArticles ? topicIsSelected === false : false

            onChange({
                topicId: topic.id,
                allTopicsSelected: topicIsSelected && !isIndetermined,
                removeTopic: topicShouldBeRemoved,
                selectedArticleIds,
            })
        }
    }
}
