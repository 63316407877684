import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { UnarchiveCustomerNewsDocument } from '~/generated/graphql'

interface UnarchiveCustomerNewsMutationResponse {
    unarchiveCustomerNews: boolean
}

interface UnarchiveCustomerNewsVariables {
    newsIds: number[]
}

export type UnarchiveNewsItemsMutationFN = MutationFn<
    UnarchiveCustomerNewsMutationResponse,
    UnarchiveCustomerNewsVariables
>

interface Props {
    children: (
        mutation: MutationFn<UnarchiveCustomerNewsMutationResponse, UnarchiveCustomerNewsVariables>,
        options: MutationResult
    ) => JSX.Element
}

export const UnarchiveNewsItemsMutation: React.SFC<Props> = ({ children }) => (
    <GQLMutation<UnarchiveCustomerNewsMutationResponse, UnarchiveCustomerNewsVariables>
        mutation={UnarchiveCustomerNewsDocument}
    >
        {(mutate, options) => children(mutate, options)}
    </GQLMutation>
)
