import React from 'react'
import { InfiniteScrollOptions, InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ListThemesDocument, ThemeTopicAssessmentCoverageType, ListThemesQueryVariables } from '~/generated/graphql'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'

export interface ThemesOverviewFilters {
    search: string | null
}

export interface ThemesOverviewThemeType {
    id: number
    name: string
    isPinned: boolean
    customerFrameworks: CustomerFramework[]
    topicAssessmentCoverage?: ThemeTopicAssessmentCoverageType
}

interface Props {
    children: (options: InfiniteScrollOptions<ThemesOverviewThemeType>) => JSX.Element
    filters: ThemesOverviewFilters
}
export class ThemesOverviewTableQuery extends React.PureComponent<Props, {}> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public render() {
        const { children, filters } = this.props
        return (
            <InfiniteScrollQuery<ThemesOverviewThemeType, ListThemesQueryVariables>
                query={ListThemesDocument}
                path="themes"
                variables={{
                    filters: {
                        customerFrameworkIds: this.context.activeProfiles,
                        departmentId: this.context.activeDepartmentId,
                        filterPinned: true,
                        search: filters.search,
                    },
                    departmentId: this.context.activeDepartmentId,
                    customerSlug: this.context.customer.slug,
                    customerFrameworkIds: this.context.activeProfiles,
                }}
            >
                {options => children(options)}
            </InfiniteScrollQuery>
        )
    }
}
