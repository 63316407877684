import './Cover.scss'

import React from 'react'
import { NavLink, RouteComponentProps } from 'react-router-dom'
import { Mutation, MutationFn, Query } from 'react-apollo'
import { BEM } from '~/services/BEMService'
import { Logo } from '~/components/Chrome/Logo/Logo'
import { routes } from '../routes'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize, notification } from '~/bootstrap'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Row } from '~/components/Core/Layout/Row'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { PageTitle } from '~/components/Core/Text/PageTitle'
import {
    SetUserMergeRequestResponseDocument,
    SetUserMergeRequestResponseMutation,
    SetUserMergeRequestResponseMutationVariables,
    UserMergeRequestDocument,
    UserMergeRequestQuery,
    UserMergeRequestQueryVariables,
} from '~/generated/graphql'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'

interface Props extends RouteComponentProps<{ token: string }> {}

type SetUserMutationFN = MutationFn<SetUserMergeRequestResponseMutation, SetUserMergeRequestResponseMutationVariables>

export class ConfirmMergeUser extends React.PureComponent<Props> {
    private bem = new BEM('Cover')
    private loc = localize.namespaceTranslate(t => t.Cover.ConfirmMergeUser)

    public render() {
        return (
            <Mutation<SetUserMergeRequestResponseMutation, SetUserMergeRequestResponseMutationVariables>
                mutation={SetUserMergeRequestResponseDocument}
            >
                {(mutate, { loading }) => (
                    <div className={this.bem.getClassName()}>
                        <div className={this.bem.getElement('brand')}>
                            <NavLink to={routes.cover.index}>
                                <Logo />
                            </NavLink>
                        </div>
                        <div className={this.bem.getElement('line')} />
                        <div className={this.bem.getElement('content')}>
                            <PageTitle title={this.loc(t => t.title)} />
                            {this.renderContent(mutate, loading)}
                        </div>
                    </div>
                )}
            </Mutation>
        )
    }

    private renderContent(mutate: SetUserMutationFN, mutateLoading: boolean) {
        const { token } = this.props.match.params

        return (
            <Query<UserMergeRequestQuery, UserMergeRequestQueryVariables>
                variables={{ token }}
                query={UserMergeRequestDocument}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    if (!data || !data.userMergeRequest) {
                        return <NoResults />
                    }

                    const descriptionLabels = this.getDescriptionLabels(data.userMergeRequest)

                    return (
                        <>
                            <Paragraph>{this.loc(t => t.description, descriptionLabels)}</Paragraph>
                            <Column noSpacing={true}>
                                <ErrorMessage path="sendUserMergeRequestResponse" />
                                <Row evenSpace={true}>
                                    <Button
                                        onClick={this.handleSubmit(mutate, true)}
                                        loading={mutateLoading}
                                        className={this.bem.getElement('submit-button')}
                                    >
                                        {this.loc(t => t.acceptLabel)}
                                    </Button>
                                </Row>
                                <Row evenSpace={true}>
                                    <Button
                                        onClick={this.handleSubmit(mutate, false)}
                                        loading={mutateLoading}
                                        type={ButtonType.secondary}
                                        className={this.bem.getElement('submit-button')}
                                    >
                                        {this.loc(t => t.declineLabel)}
                                    </Button>
                                </Row>
                            </Column>
                        </>
                    )
                }}
            </Query>
        )
    }

    private handleSubmit = (mutate: SetUserMutationFN, accepted: boolean) => async () => {
        const { token } = this.props.match.params

        const response = await mutate({ variables: { token, accepted } })

        if (response && response.data?.setUserMergeRequestResponse) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            this.props.history.push(routes.cover.login)
        }
    }

    private getDescriptionLabels(userMergeRequest: UserMergeRequestQuery['userMergeRequest']) {
        const { requestedBy, baseUser, mergingUserCustomerNames } = userMergeRequest!

        return {
            requesterName: requestedBy.profile.fullName,
            replaceeCustomerName: mergingUserCustomerNames?.join(', '),
            replacerName: baseUser.profile.fullName,
            replacerEmail: baseUser.email,
        }
    }
}
