import './ControlSummaryList.scss'

import React from 'react'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Column } from '~/components/Core/Layout/Column'
import { Section } from '~/components/Core/Layout/Section'
import { DepartmentAlertDataType, TaskType } from '~/generated/graphql'
import { TaskControlSelectionBox } from './TaskControl/TaskControlSelectionBox/TaskControlSelectionBox'
import { BEM, ClassValue } from '~/services/BEMService'

interface ControlSummary {
    sectionTitle: React.ReactNode
    children: React.ReactNode
}

interface Props {
    summaries: ControlSummary[]
    controlSummaryType: 'inbox' | 'task'
    loadingMore: boolean
    selectedItems: TaskType[] | DepartmentAlertDataType[]
    refetch: () => void
    onClearSelection: () => void
    className?: ClassValue
}

export class ControlSummaryList extends React.PureComponent<Props> {
    private bem = new BEM('ControlSummaryList')

    public render() {
        const { className, summaries, loadingMore } = this.props

        return (
            <Column className={this.bem.getClassName(className)}>
                {this.renderSelectionBox()}
                {summaries.map((summary, i) => (
                    <Section
                        key={`control-summary-${i}`}
                        title={summary.sectionTitle}
                        className={this.bem.getElement('summary')}
                    >
                        {summary.children}
                    </Section>
                ))}
                {loadingMore && <Spinner delayed={true} />}
            </Column>
        )
    }

    private renderSelectionBox() {
        const { selectedItems, controlSummaryType, onClearSelection, refetch } = this.props

        const isOpen = selectedItems.length > 0

        return (
            <TaskControlSelectionBox
                isOpen={isOpen}
                onSelectionClose={onClearSelection}
                tasks={controlSummaryType === 'task' ? (selectedItems as TaskType[]) : undefined}
                alertDatas={controlSummaryType === 'inbox' ? (selectedItems as DepartmentAlertDataType[]) : undefined}
                refetch={refetch}
            />
        )
    }
}
