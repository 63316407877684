import './RiskWidget.scss'

import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { WidgetKey } from '../../CreateWidgetWidget/widgets'
import { WidgetContainer } from '../components/WidgetContainer'
import { localize } from '~/bootstrap'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import {
    DashboardDepartmentSpecificWidgetMetadataType,
    DashboardWidgetContentFragmentFragment,
    DashboardWidgetLayoutTypeEnum,
    DashboardWidgetMetadataFragmentFragment,
    DashboardRiskWidgetFragmentFragment,
} from '~/generated/graphql'
import { routes } from '~/views/routes'
import { WidgetInfoLink } from '../components/WidgetInfoLink'

interface Props {
    isEditing: boolean
    currentBreakpoint: DashboardWidgetLayoutTypeEnum | undefined
    onWidgetClick?: (widgetId: number, type: WidgetKey) => void
    onDeleteIconClick?: (widgetId: number) => void
    onModalStateChange?: ({ isOpening }: { isOpening: boolean }) => void
    isDetailWidget?: boolean
    id: number
    metadata?: DashboardWidgetMetadataFragmentFragment
    content?: DashboardWidgetContentFragmentFragment
}

enum RiskLevel {
    high = 'high',
    medium = 'medium',
    low = 'low',
}

export class RiskWidget extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('RiskWidget')
    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView.Widgets.RiskWidget)

    public render() {
        const { isEditing, onWidgetClick, onDeleteIconClick, isDetailWidget, currentBreakpoint } = this.props
        const { onModalStateChange } = this.props

        return (
            <WidgetContainer
                widgetInfo={this.getHeaderInfo()}
                isEditing={isEditing}
                className={this.bem.getClassName()}
                onWidgetClick={onWidgetClick}
                isDetailWidget={isDetailWidget}
                currentBreakpoint={currentBreakpoint}
                onDeleteIconClick={onDeleteIconClick}
                onModalStateChange={onModalStateChange}
            >
                <Column bigSpacing={true}>{this.renderContent()}</Column>
            </WidgetContainer>
        )
    }

    private getHeaderInfo() {
        const { id, isDetailWidget } = this.props
        const { customName, departments, allDepartments } = this.getMetadata()

        if (isDetailWidget) {
            return {
                widgetId: id,
                widgetKey: WidgetKey.risk,
                departments,
                allDepartments: false,
            }
        }

        return {
            widgetId: id,
            customName,
            widgetKey: WidgetKey.risk,
            departments,
            allDepartments,
        }
    }

    private renderContent() {
        const { highRiskCount, mediumRiskCount, lowRiskCount } = this.getCounts()

        const riskIndexPageLink = routes.customer(this.context.customer.slug).risks.heatmap.index
        const highMediumRiskLink = `${riskIndexPageLink}?sortDirection%5Bfield%5D=severity&sortDirection%5Bdirection%5D=ASC&riskFilter=netto`
        const lowRiskLink = `${riskIndexPageLink}?sortDirection%5Bfield%5D=severity&sortDirection%5Bdirection%5D=DESC&riskFilter=netto`

        return (
            <Row className={this.bem.getElement('content-container')}>
                {this.renderCount({ count: highRiskCount, riskLevel: RiskLevel.high, link: highMediumRiskLink })}
                {this.renderCount({ count: mediumRiskCount, riskLevel: RiskLevel.medium, link: highMediumRiskLink })}
                {this.renderCount({ count: lowRiskCount, riskLevel: RiskLevel.low, link: lowRiskLink })}
            </Row>
        )
    }

    private getMetadata() {
        const { metadata } = this.props

        if (!metadata) {
            return {
                departments: [],
                allDepartments: false,
            }
        }

        const { name, departments, allDepartments } = metadata as DashboardDepartmentSpecificWidgetMetadataType

        return {
            customName: name ? name : undefined,
            departments: departments?.length ? departments.map(({ id, name }) => ({ id, name })) : [],
            allDepartments,
        }
    }

    private getCounts() {
        let highRiskCount = 0
        let mediumRiskCount = 0
        let lowRiskCount = 0

        const { content } = this.props
        if (content) {
            const { highCount, mediumCount, lowCount } = content as DashboardRiskWidgetFragmentFragment
            highRiskCount = highCount
            mediumRiskCount = mediumCount
            lowRiskCount = lowCount
        }

        return { highRiskCount, mediumRiskCount, lowRiskCount }
    }

    private renderCount({ count, riskLevel, link }: { count: number; riskLevel: RiskLevel; link: string }) {
        const metadata = this.props.metadata as DashboardDepartmentSpecificWidgetMetadataType | undefined
        const infoLinkClassName = this.bem.getElement('count-link')

        return (
            <WidgetInfoLink to={link} departmentSpecificMetadata={metadata} className={infoLinkClassName}>
                <Column extraSmallSpacing={true} className={this.bem.getElement('box-container')}>
                    <Paragraph className={this.bem.getElement('count')}>{count}</Paragraph>
                    <Row extraSmallSpacing={true} className={this.bem.getElement('level-container')}>
                        <Icon
                            type={IconType.risk}
                            className={this.bem.getElement('icon', () => ({ [`is-${riskLevel}`]: !!riskLevel }))}
                        />
                        <Paragraph subtle={true}>{this.loc(t => t[riskLevel])}</Paragraph>
                    </Row>
                </Column>
            </WidgetInfoLink>
        )
    }
}
