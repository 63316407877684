import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { LinkedTaskItemType } from '~/graphql/types/Task'

export const LINKED_TASK_ITEM_STATUS_QUERY = gql`
    query minimalLinkedTaskItemStatus(
        $departmentId: Int
        $customerId: Int
        $linkedItemId: Int!
        $linkedItemType: LinkedTaskItem!
        $filterForDepartment: Int
    ) {
        linkedTaskItemStatus(
            departmentId: $departmentId
            customerId: $customerId
            linkedItemId: $linkedItemId
            linkedItemType: $linkedItemType
            filterForDepartment: $filterForDepartment
        ) {
            open
            closed
            overDue
        }
    }
`

// Defined the response from graphql the field should be possibly undefined by either marking it with a `?` or with null
export interface LinkedTaskItemStatusQueryResponse {
    linkedTaskItemStatus?: {
        open: number
        closed: number
        overDue: number
    }
}

// If the query has variables defined the query variables. example:
export interface LinkedTaskItemStatusQueryVariables {
    departmentId?: number
    customerId?: number
    linkedItemId: number
    linkedItemType: LinkedTaskItemType
    filterForDepartment?: number
}

// Defined and export the Result so it can be used in other components
export type LinkedTaskItemStatusQueryResult = QueryResult<LinkedTaskItemStatusQueryResponse>

interface Props extends LinkedTaskItemStatusQueryVariables {
    // Children should always be one result object, like the Query component
    children: (result: LinkedTaskItemStatusQueryResult) => JSX.Element
}

export class MinimalLinkedTaskItemStatusQuery extends React.PureComponent<Props> {
    public render() {
        const { children, departmentId, customerId, linkedItemId, linkedItemType, filterForDepartment } = this.props

        return (
            <Query<LinkedTaskItemStatusQueryResponse>
                query={LINKED_TASK_ITEM_STATUS_QUERY}
                variables={{
                    departmentId,
                    customerId,
                    linkedItemId,
                    linkedItemType,
                    filterForDepartment,
                }}
            >
                {children}
            </Query>
        )
    }
}
