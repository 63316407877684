import React from 'react'
import { Query, QueryResult } from 'react-apollo'
import { User } from '~/graphql/types/User'
import { File } from '~/graphql/types/File'
import { GetTaskLogsDocument } from '~/generated/graphql'

export interface GetTaskLogsQueryResponse {
    taskLogs?: PossibleTaskEventType[]
}

export type PossibleTaskEventType = TaskMotivatedEvent | TaskEvent

export interface TaskMotivatedEvent {
    id: number
    type: TaskEventType.wasCompleted | TaskEventType.wasReopened
    metaData: {
        files?: File[]
        taskId: number
        reports?: { name: string; id: number }[]
        taskName: string
        description?: string
        motivationId?: number
    }
    occuredOn: string
    user: User
}

export interface TaskEvent {
    id: number
    type: TaskEventType.wasCreated | TaskEventType.wasEdited
    metaData: {
        taskId: number
        taskName: string
    }
    occuredOn: string
    user: User
}

export enum TaskEventType {
    wasCreated = 'TASK_WAS_CREATED',
    wasEdited = 'TASK_WAS_EDITED',
    wasCompleted = 'TASK_WAS_COMPLETED',
    wasReopened = 'TASK_WAS_REOPENED',
    subTaskWasCreated = 'SUBTASK_WAS_CREATED',
    subTaskWasArchived = 'SUBTASK_WAS_ARCHIVED',
}

export type GetTaskLogsQueryResult = QueryResult<GetTaskLogsQueryResponse>

interface Props {
    taskId: number
    children: (result: GetTaskLogsQueryResult) => JSX.Element
}

export class GetTaskLogsQuery extends React.PureComponent<Props> {
    public render() {
        const { children, taskId } = this.props

        return (
            <Query<GetTaskLogsQueryResponse> query={GetTaskLogsDocument} variables={{ taskId }}>
                {children}
            </Query>
        )
    }
}
