import React from 'react'
import gql from 'graphql-tag'
import { CustomerFrameworkFragment, CustomerFramework } from '~/graphql/types/CustomerFramework'
import { InfiniteScrollQuery, InfiniteScrollOptions } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AssessmentCoverageType } from '~/generated/graphql'

const LIST_TOPICS_QUERY = gql`
    query topics(
        $skip: Int
        $take: Int
        $filters: TopicFilters
        $customerSlug: String
        $departmentId: Int!
        $sort: TopicSort
    ) {
        topics(filters: $filters, skip: $skip, take: $take, customerSlug: $customerSlug, sort: $sort) {
            hasNextPage
            nodes {
                id
                name
                customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                    ...CustomerFrameworkFields
                }
                assessmentCoverage(departmentId: $departmentId) {
                    noted
                    assessed
                    total
                }
            }
        }
    }
    ${CustomerFrameworkFragment}
`

export interface NormTopic {
    id: number
    name: string
    customerFrameworks: CustomerFramework[]
    assessmentCoverage: AssessmentCoverageType
}

interface NormTopicsInfiniteScrollQueryVariables {
    themeId: number
    search?: string | null
    sort?: {
        [key: string]: string
    }
}

interface Props extends NormTopicsInfiniteScrollQueryVariables {
    children: (options: InfiniteScrollOptions<NormTopic, NormTopicsInfiniteScrollQueryVariables>) => React.ReactNode
}

export class NormTopicsInfiniteScrollQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, search, themeId, sort } = this.props

        return (
            <InfiniteScrollQuery<NormTopic>
                query={LIST_TOPICS_QUERY}
                variables={{
                    filters: {
                        search,
                        themeIds: [themeId],
                        customerFrameworkIds: this.context.activeProfiles,
                        departmentId: this.context.activeDepartmentId,
                        excludeNonApplicableTopics: true,
                    },
                    sort,
                    customerSlug: this.context.customer.slug,
                    departmentId: this.context.activeDepartmentId,
                }}
            >
                {children}
            </InfiniteScrollQuery>
        )
    }
}
