import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { RepeatableFileInput } from '~/components/Core/DataEntry/RepeatableFileInput/RepeatableFileInput'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Markdown } from '~/components/Core/Text/Markdown'
import { FilePreviewModal } from '~/components/Domain/FilePreview/FilePreviewModal'
import {
    MonitoringReportTopicAssessmentGrade,
    TopicAssessmentDocumentType,
    TopicAssessmentGradeType,
    TopicAssessmentType,
} from '~/generated/graphql'
import { File as FileType } from '~/graphql/types/File'
import { FileType as FileTypes } from '~/services/FileService'
import { TopicAssessmentLinkedArticlesTable } from './TopicAssessmentLinkedArticlesTable'
import { TopicAssessmentSelect } from './TopicAssessmentSelect'
import { MonitoringReportLabel } from '~/components/Domain/Monitoring/MonitoringReportLabel'
import { MonitoringSelect } from '~/components/Domain/Monitoring/MonitoringSelect'

interface Props {
    topicId: number
    selectedArticleIds: number[]
    allArticles?: boolean
    label: React.ReactNode
    onChangeFiles: (files: File[]) => void
    isEditing?: boolean
    defaultValues?: DefaultValues
    hideNotAssessedOption?: boolean
}

type DefaultValues = Pick<TopicAssessmentType, 'description'> & {
    grade?: TopicAssessmentGradeType | MonitoringReportTopicAssessmentGrade | null
    monitoringReport?: { id: number; name: string } | null
    topicAssessmentDocuments?: Pick<TopicAssessmentDocumentType, 'id' | 'file'>[] | null
}

export class TopicAssessmentFields extends React.PureComponent<Props> {
    public render() {
        return (
            <Column>
                <Column extraSmallSpacing={true}>
                    {this.renderTitleField()}
                    {this.renderArticlesField()}
                </Column>
                {this.renderMonitoringReportField()}
                {this.renderDocumentsField()}
                {this.renderDescriptionField()}
            </Column>
        )
    }

    private renderTitleField() {
        const { label, isEditing, defaultValues, hideNotAssessedOption } = this.props

        return (
            <TopicAssessmentSelect
                defaultOption={defaultValues?.grade || undefined}
                isEditing={isEditing}
                label={label}
                name="grade"
                hideNotAssessedOption={hideNotAssessedOption}
            />
        )
    }

    private renderArticlesField() {
        const { topicId, selectedArticleIds, allArticles } = this.props

        return (
            <TopicAssessmentLinkedArticlesTable
                topicId={topicId}
                linkedArticleIds={selectedArticleIds}
                allArticles={allArticles}
            />
        )
    }

    private renderMonitoringReportField() {
        const { isEditing, defaultValues } = this.props

        const monitoringReport = defaultValues?.monitoringReport
            ? { id: defaultValues.monitoringReport.id, name: defaultValues.monitoringReport.name }
            : undefined

        if (!monitoringReport) {
            return
        }

        if (isEditing) {
            const defaultOption = monitoringReport
                ? { label: monitoringReport.name, value: monitoringReport.id }
                : undefined

            return (
                <Column smallSpacing={true}>
                    <Field label={localize.translate(t => t.Entities.Monitoring)} />
                    <MonitoringSelect disabled name="monitoringReport" defaultValue={defaultOption} />
                </Column>
            )
        }

        return (
            <Column smallSpacing={true}>
                <Field label={localize.translate(t => t.Entities.Monitoring)} />
                <MonitoringReportLabel monitoringReport={monitoringReport} />
            </Column>
        )
    }

    private renderDocumentsField() {
        const { onChangeFiles, isEditing, defaultValues } = this.props

        const defaultFiles: File[] = []
        const files: FileType[] = []

        if (defaultValues?.topicAssessmentDocuments) {
            for (const { file } of defaultValues.topicAssessmentDocuments) {
                if (file) {
                    defaultFiles.push({ name: file.name } as File)
                    files.push(file as FileType)
                }
            }
        }

        if (isEditing) {
            return (
                <Column smallSpacing={true}>
                    <Field label={localize.translate(t => t.Generic.documents)} />
                    <RepeatableFileInput name="documents" defaultFiles={defaultFiles} onChange={onChangeFiles} />
                </Column>
            )
        }

        if (!files.length) {
            return
        }

        return (
            <Column smallSpacing={true}>
                <Field forInput="documents" label={localize.translate(t => t.Generic.documents)} />
                {files.map(this.renderFile)}
            </Column>
        )
    }

    private renderDescriptionField() {
        const { defaultValues, isEditing } = this.props

        if (isEditing) {
            return (
                <Column smallSpacing={true}>
                    <Field forInput="description" label={localize.translate(t => t.Generic.explanation)} />
                    <Form.TextEditor name="description" defaultValue={defaultValues?.description} />
                </Column>
            )
        }

        if (!defaultValues?.description) {
            return
        }

        return (
            <Column smallSpacing={true}>
                <Field forInput="description" label={localize.translate(t => t.Generic.explanation)} />
                <Markdown paragraphLike={true} source={defaultValues.description} />
            </Column>
        )
    }

    private renderFile(file: FileType) {
        return (
            <FilePreviewModal key={file.id} file={file} fileType={FileTypes.topicAssessmentFile}>
                {previewFile => (
                    <Row>
                        <Button onClick={() => previewFile()} icon={IconType.attachment} type={ButtonType.actionLink}>
                            {file.name}
                        </Button>
                    </Row>
                )}
            </FilePreviewModal>
        )
    }
}
