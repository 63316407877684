import React from 'react'

import { Form } from '~/components/Core/DataEntry/Form/Form'
import { localize } from '~/bootstrap'
import { TaskRepeatFrequency } from '~/generated/graphql'

interface Props {
    name: string
    defaultValue?: TaskRepeatFrequency
}

interface State {}

export class TaskRepeatFrequencySelect extends React.Component<Props, State> {
    public render() {
        const { name, defaultValue } = this.props

        const options = Object.values(TaskRepeatFrequency).map(frequency => ({
            label: localize.translate(t => t.Customer.Task.frequency[frequency]) || frequency,
            value: frequency,
        }))

        const defaultSelected = defaultValue
            ? [
                  {
                      label: localize.translate(t => t.Customer.Task.frequency[defaultValue]),
                      value: defaultValue,
                  },
              ]
            : undefined

        return <Form.Select name={name} options={options} clearable={true} defaultValue={defaultSelected} />
    }
}
