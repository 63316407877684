import gql from 'graphql-tag'
import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ASSESSMENT_SECTIONS_QUERY } from '../../Assessments/AssessmentSections/AssessmentSectionsInfiniteScrollQuery'

interface Props {
    assessmentId: number
    riskId: number
    children: (
        mutate: MutationFn<DeleteRiskModalResponse, DeleteRiskModalVariables>,
        result: MutationResult<DeleteRiskModalResponse>
    ) => JSX.Element
}

interface State {}

const GET_RISK = gql`
    query risk($id: Int!, $departmentId: Int!) {
        risk(id: $id) {
            id
            name
            description
            createdAt
            brutoImpact
            brutoProbability
            nettoImpact
            nettoProbability
            isAccepted
            severity
            types {
                id
                name
            }
            assessment {
                id
                name
            }
            linkedControls(departmentId: $departmentId) {
                id
                control {
                    id
                    name
                    type
                    revisionDate
                }
                description
            }
        }
    }
`

const DELETE_RISK = gql`
    mutation deleteRisk($riskId: Int!) {
        deleteRisk(riskId: $riskId)
    }
`
export type DeleteRiskMutationType = MutationFn<DeleteRiskModalResponse, DeleteRiskModalVariables>
interface DeleteRiskModalResponse {
    deleteRisk: boolean
}

interface DeleteRiskModalVariables {
    riskId: number
}

export class DeleteRiskModalMutation extends React.Component<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { riskId, assessmentId, children } = this.props
        return (
            <GQLMutation<DeleteRiskModalResponse, DeleteRiskModalVariables>
                mutation={DELETE_RISK}
                variables={{ riskId }}
                refetchQueries={[
                    {
                        query: ASSESSMENT_SECTIONS_QUERY,
                        variables: {
                            assessmentId,
                            departmentId: this.context.activeDepartmentId,
                        },
                    },
                    { query: GET_RISK, variables: { id: riskId, departmentId: this.context.activeDepartmentId } },
                ]}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
