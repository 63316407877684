import React from 'react'
import { FetchMoreButton } from './FetchMoreButton'
import { localize } from '~/bootstrap'

interface Props {
    loadingMore: boolean
    fetchMore: () => void
    alignLeft?: boolean
    canFetchMore: boolean
    totalCount: number
    currentCount: number
}

export class FetchMore extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Generic)

    public render() {
        const { canFetchMore, fetchMore, totalCount, currentCount, loadingMore } = this.props

        if (!canFetchMore) {
            return null
        }

        const nextItemsCount = this.getLoadMoreCount(currentCount, totalCount)

        return (
            <FetchMoreButton
                label={this.loc(t => t.showMoreLabel, { nextItemsCount, totalCount })}
                fetchMore={fetchMore}
                loadingMore={loadingMore}
            />
        )
    }

    private getLoadMoreCount(nodeCount: number, totalCount: number = 0) {
        const leftOverNodes = totalCount - nodeCount

        if (leftOverNodes <= 0) {
            return 0
        }

        if (leftOverNodes > 3) {
            return 3
        }

        return leftOverNodes
    }
}
