import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Address } from '~/graphql/types/Address'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    address: Address
    hideCountry?: boolean
}

interface State {}

export class AddressFormat extends React.PureComponent<Props, State> {
    private bem = new BEM('AddressFormat')
    private loc = localize.namespaceTranslate(t => t.CountryCodes)

    public render() {
        const { className, hideCountry } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {this.ifIncludesAddressLine()}
                {this.ifIncludesPostalCodeOrCity()}
                {!hideCountry && this.ifIncludesCountry()}
            </div>
        )
    }

    private ifIncludesAddressLine() {
        const { address } = this.props

        if (address.addressLine) {
            return <p>{address.addressLine}</p>
        }

        return
    }

    private ifIncludesPostalCodeOrCity() {
        const { address } = this.props

        if (address.postalCode && address.city) {
            return <p>{`${address.postalCode}, ${address.city}`}</p>
        }

        if (address.postalCode) {
            return <p>{address.postalCode}</p>
        }

        if (address.city) {
            return <p>{address.city}</p>
        }

        return
    }

    private ifIncludesCountry() {
        const { address } = this.props

        if (address.country) {
            return <p>{this.loc(t => t[address!.country!])}</p>
        }

        return
    }
}
