import React from 'react'

import { RouteComponentProps, Redirect } from 'react-router'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Center } from '~/components/Core/Layout/Center'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { TextLink } from '~/components/Core/Text/TextLink'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { routes } from '~/views/routes'
import { localize } from '~/bootstrap'
import { UserContext, UserContextValue } from '~/components/Providers/UserProvider'

interface Props
    extends RouteComponentProps<{
        identifier: string
    }> {}

const IDENTIFY_REGULATION = gql`
    query identifyLawRegulation($identifier: String) {
        identifyLawRegulation(identifier: $identifier) {
            id
            type
            parentId
        }
    }
`

interface Response {
    identifyLawRegulation: {
        id: number
        type: string
        parentId: number
    }
}

export class LawAndRegulationRedirectView extends React.PureComponent<Props> {
    public static contextType = UserContext
    public context: UserContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.LawAndRegulationRedirectView)

    public render() {
        const identifier = decodeURIComponent(this.props.match.params.identifier)
        const params = new URLSearchParams(this.props.location.search)

        const link = params.get('orginal')

        return (
            <Query<Response> query={IDENTIFY_REGULATION} variables={{ identifier }}>
                {({ data, loading }) => {
                    if (loading) {
                        return (
                            <Center>
                                <div style={{ textAlign: 'center', marginTop: '100px' }}>
                                    <Paragraph>{this.loc(t => t.searching)}</Paragraph>
                                    <br />
                                    <Spinner />
                                </div>
                            </Center>
                        )
                    }

                    const result = data ? data.identifyLawRegulation : null

                    if (result) {
                        if (result.type === 'AbstractLawArticle') {
                            return (
                                <Redirect
                                    to={routes
                                        .customer(this.context.employee?.customer.slug)
                                        .lawAndRegulation.detail.article(result.parentId, result.id)}
                                />
                            )
                        }

                        if (result.type === 'Law') {
                            return (
                                <Redirect
                                    to={routes
                                        .customer(this.context.employee?.customer.slug)
                                        .lawAndRegulation.detail.view(result.parentId)}
                                />
                            )
                        }

                        if (result.type === 'NotFound') {
                            return (
                                <Center>
                                    <div style={{ textAlign: 'center', marginTop: '100px' }}>
                                        <ComponentTitle title={this.loc(t => t.lawFound)} />
                                        <Paragraph>
                                            {this.loc(t => t.lookupLaw)}
                                            <br />
                                            <TextLink
                                                to={routes
                                                    .customer(this.context.employee?.customer.slug)
                                                    .lawAndRegulation.detail.view(result.parentId)}
                                            >
                                                {link}
                                            </TextLink>
                                        </Paragraph>
                                    </div>
                                </Center>
                            )
                        }
                    }

                    if (link) {
                        return (
                            <Center>
                                <div style={{ textAlign: 'center', marginTop: '100px' }}>
                                    <ComponentTitle title={this.loc(t => t.notFound)} />
                                    <Paragraph>
                                        {this.loc(t => t.goToLink)}
                                        <br />
                                        <TextLink external={true} href={link}>
                                            {link}
                                        </TextLink>
                                    </Paragraph>
                                </div>
                            </Center>
                        )
                    }

                    return <NoResults />
                }}
            </Query>
        )
    }
}
