import './EditInboxItemImpactActionButton.scss'
import React from 'react'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Popper, PopperContentItem } from '~/components/Core/Popper/Popper'
import { BEM } from '~/services/BEMService'
import { InboxItemImpactIcon } from '../InboxItemImpactIcon'
import { InboxItemImpactLevelMutation, InboxItemImpactLevelMutationFN } from './InboxItemImpactLevelMutation'
import { AlertImpactLevel } from '~/generated/graphql'
import { InboxItemImpactLabel } from '../InboxItemImpactLabel'
import { localize, notification, permissions } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'
import { PopperUnstyledProps } from '@mui/base'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    alertIds: number[]
    defaultImpact?: AlertImpactLevel | null
    popperOptions?: PopperUnstyledProps['popperOptions']
    actionButton?: boolean
}

export class EditInboxItemImpactActionButton extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('EditInboxItemImpactActionButton')

    public render() {
        const { actionButton } = this.props
        const disabled = !permissions.canSetInboxImpact(this.context.activeDepartmentId)

        return (
            <InboxItemImpactLevelMutation>
                {(mutate, { loading }) => (
                    <Popper
                        className={this.bem.getClassName()}
                        disabled={disabled}
                        item={
                            <Button
                                className={this.bem.getElement('icon', () => ({ actionButton }))}
                                type={ButtonType.subtleIcon}
                                disabled={disabled}
                            >
                                <InboxItemImpactIcon impact={this.props.defaultImpact} />
                            </Button>
                        }
                        contents={this.renderImpactOptions(loading, mutate)}
                        position="top"
                        popperOptions={this.props.popperOptions}
                    />
                )}
            </InboxItemImpactLevelMutation>
        )
    }

    private renderImpactOptions(loading: boolean, setImpact: InboxItemImpactLevelMutationFN): PopperContentItem[] {
        return [
            { item: this.renderImpactOptionButton(loading, setImpact, null) },
            { item: this.renderImpactOptionButton(loading, setImpact, AlertImpactLevel.low) },
            { item: this.renderImpactOptionButton(loading, setImpact, AlertImpactLevel.medium) },
            { item: this.renderImpactOptionButton(loading, setImpact, AlertImpactLevel.high) },
        ]
    }

    private renderImpactOptionButton(
        loading: boolean,
        setImpact: InboxItemImpactLevelMutationFN,
        impact: AlertImpactLevel | null
    ) {
        const active = impact === this.props.defaultImpact

        return (
            <Button
                className={this.bem.getElement('impact-option', () => ({ active }))}
                type={ButtonType.tertiary}
                loading={loading}
                disabled={active}
                onClick={() => this.handleSetImpact(setImpact, impact)}
            >
                <InboxItemImpactLabel impactLevel={impact} />
            </Button>
        )
    }

    private async handleSetImpact(setImpact: InboxItemImpactLevelMutationFN, impactLevel: AlertImpactLevel | null) {
        const { alertIds } = this.props
        const departmentId = this.context.activeDepartmentId

        const response = await setImpact({ variables: { alertIds, impactLevel, departmentId } })

        if (response && response.data?.editAlertImpactLevels) {
            const message = localize.translate(t => t.Generic.successfullyEdited)

            notification.success(message, IconType.inboxItemImpact2)
        }
    }
}
