import React from 'react'
import { localize, notification } from '~/bootstrap'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Button } from '~/components/Core/Button/Button'
import { Row } from '~/components/Core/Layout/Row'
import { Column } from '~/components/Core/Layout/Column'
import { RouteComponentProps } from 'react-router'
import { routes } from '~/views/routes'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import gql from 'graphql-tag'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { Avatar, AvatarSize } from '~/components/Core/DataDisplay/Avatar/Avatar'
import { GQLMutation } from '~/graphql/Mutation'
import { PageTitle } from '~/components/Core/Text/PageTitle'
import { ConsultantType, FrameworkType } from '~/generated/graphql'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {}

export interface Customer {
    logo: string
    name: string
    address: string
    zipcode: string
    place: string
    maxAmountOfEmployees: number
    contactPersonForConsult: string
    alreadyActiveEmployees: number
    ssoLoginEnabled?: boolean
    editorialAccess: boolean
    availableSeats: number
    filledSeats?: number
    contactName?: string
    contactEmail?: string
    consultantContact?: ConsultantType
    customerGroup?: {
        id: number
        name: string
        ssoLoginEnabled?: boolean
        createUserOnSSOLogin?: boolean
    }
    frameworks: FrameworkType[]
    createUserOnSSOLogin?: boolean
    defaultDepartment?: {
        id: number
        name: string
    }
}

const CUSTOMER_QUERY = gql`
    query customer($id: Int!) {
        customer(id: $id) {
            id
            name
            logo
            availableSeats
            filledSeats
            contactName
            contactEmail
            ssoLoginEnabled
            createUserOnSSOLogin
            defaultDepartment {
                id
                name
            }
            editorialAccess
            address {
                addressLine
                postalCode
                city
                stateOrProvince
                country
            }
            consultantContact {
                id
                user {
                    id
                    email
                    profile {
                        id
                        fullName
                    }
                }
            }
            customerGroup {
                id
                name
                ssoLoginEnabled
                createUserOnSSOLogin
            }
            frameworks {
                id
                abbreviation
            }
        }
    }
`

const DELETE_CUSTOMER_MUTATION = gql`
    mutation deleteCustomer($id: Int!) {
        deleteCustomer(customerId: $id)
    }
`

interface DeleteCustomerResponse {
    deleteCustomer: boolean
}

export class CustomerDetailView extends React.PureComponent<Props, State> {
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerDetailView)
    private customerLoc = localize.namespaceTranslate(t => t.Customer.Attributes)
    private addressLoc = localize.namespaceTranslate(t => t.Address.Attributes)
    private genericLoc = localize.namespaceTranslate(t => t.Generic)

    private formNames = {
        avatar: 'avatar',
    }

    public render() {
        const id = parseInt(this.props.match.params.id!, 10)

        if (!id) {
            return null
        }

        return (
            <PageQuery<Customer> query={CUSTOMER_QUERY} variables={{ id }}>
                {customer => (
                    <>
                        <PageTitle title={this.loc(t => t.title)} />

                        <Column>
                            {customer.customerGroup?.name && (
                                <FieldSet>
                                    <Field label={localize.translate(t => t.Entities.CustomerGroup)}>
                                        <p>{customer.customerGroup.name}</p>
                                    </Field>
                                </FieldSet>
                            )}

                            <FieldSet>
                                <Field forInput={this.formNames.avatar} label={this.genericLoc(t => t.logo)}>
                                    <Avatar size={AvatarSize.large} uri={customer.logo} label={customer.name} />
                                </Field>
                            </FieldSet>

                            <FieldSet>
                                <Field label={this.customerLoc(t => t.name)}>
                                    <p>{customer.name}</p>
                                </Field>

                                <Field label={this.addressLoc(t => t.address)}>
                                    {customer.address ? this.renderCustomerAddress(customer) : ''}
                                </Field>

                                <Field label={this.customerLoc(t => t.contactPerson)}>
                                    <p>{customer.contactName}</p>
                                </Field>

                                <Field label={this.customerLoc(t => t.emailContactPerson)}>
                                    {customer.contactName && <p>{customer.contactEmail}</p>}
                                </Field>

                                <Field label={this.customerLoc(t => t.maxAmountOfEmployees)}>
                                    <p>{customer.availableSeats}</p>
                                </Field>

                                <FieldSet>
                                    <Field label={this.customerLoc(t => t.contactPersonForConsult)}>
                                        {customer.consultantContact && (
                                            <p>{customer.consultantContact.user?.profile.fullName}</p>
                                        )}
                                    </Field>
                                </FieldSet>

                                <Field label={this.customerLoc(t => t.frameworks)}>
                                    {customer.frameworks && this.renderFrameworks(customer)}
                                </Field>
                                <Field label={this.customerLoc(t => t.editorialAccess)}>
                                    {customer.editorialAccess
                                        ? localize.translate(t => t.Generic.yes)
                                        : localize.translate(t => t.Generic.no)}
                                </Field>
                                {!customer.customerGroup && this.renderSSOFields(customer)}
                                <Row smallSpacing={true}>
                                    <GQLMutation<DeleteCustomerResponse, { id: number }>
                                        mutation={DELETE_CUSTOMER_MUTATION}
                                    >
                                        {(mutate, { loading }) => (
                                            <ConfirmModal
                                                onConfirm={async closeModal => {
                                                    const response = await mutate({
                                                        variables: { id },
                                                    })

                                                    if (response && response.data && response.data.deleteCustomer) {
                                                        notification.success('Klant verwijdert')
                                                        this.props.history.push(routes.consultant.customers.index)
                                                        closeModal()
                                                    }
                                                }}
                                                loading={loading}
                                                title={this.loc(t => t.confirmDeleteUserModalTitle)}
                                                confirmButtonLabel={this.loc(t => t.deleteCustomerConfirm)}
                                                message={this.loc(t => t.confirmDeleteCustomerWarning, {
                                                    customer: customer.name,
                                                })}
                                            >
                                                {openModal => (
                                                    <Button onClick={openModal} danger={true}>
                                                        {this.loc(t => t.deleteCustomer)}
                                                    </Button>
                                                )}
                                            </ConfirmModal>
                                        )}
                                    </GQLMutation>

                                    <Button to={routes.consultant.customers.edit(id)}>
                                        {this.loc(t => t.changeDetails)}
                                    </Button>
                                </Row>
                            </FieldSet>
                        </Column>
                    </>
                )}
            </PageQuery>
        )
    }

    private renderCustomerAddress(customer: any) {
        return `${customer.address.addressLine}, ${customer.address.postalCode} ${customer.address.city}`
    }

    private renderFrameworks(customer: any) {
        const frameworks = customer.frameworks as FrameworkType[]

        if (!frameworks) {
            return null
        }

        const abbreviations = frameworks.map(framework => framework.abbreviation)

        return abbreviations.join(', ')
    }

    private renderSSOFields(customer: Customer) {
        const showDefaultDepartment =
            (customer.createUserOnSSOLogin || customer.customerGroup?.createUserOnSSOLogin) &&
            customer.defaultDepartment?.id

        return (
            <>
                {!customer.customerGroup && (
                    <>
                        <Field label={this.customerLoc(t => t.ssoLoginEnabled)}>
                            {customer.ssoLoginEnabled
                                ? localize.translate(t => t.Generic.yes)
                                : localize.translate(t => t.Generic.no)}
                        </Field>
                        {customer.ssoLoginEnabled && (
                            <Field label={this.customerLoc(t => t.createUserOnSSOLogin)}>
                                {localize.translate(t =>
                                    customer.createUserOnSSOLogin ? t.Generic.yes : t.Generic.no
                                )}
                            </Field>
                        )}
                    </>
                )}
                {showDefaultDepartment && (
                    <Field label={this.customerLoc(t => t.defaultDepartment)}>{customer.defaultDepartment?.name}</Field>
                )}
            </>
        )
    }
}
