import gql from 'graphql-tag'
import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'

const FOLLOW_RADAR_ITEM_MUTATION = gql`
    mutation followRadarItem($radarItemId: Int!, $departmentId: Int!) {
        followRadarItem(radarItemId: $radarItemId, departmentId: $departmentId) {
            id
            following(departmentId: $departmentId)
        }
    }
`

export interface FollowRadarItemMutationResponse {
    followRadarItem: { id: string }
}

export interface FollowRadarItemVariables {
    radarItemId: number
    departmentId: number
}

interface Props {
    children: (
        mutation: MutationFn<FollowRadarItemMutationResponse, FollowRadarItemVariables>,
        options: MutationResult
    ) => JSX.Element
    radarItemId: number
    departmentId: number
}

export const FollowRadarItemMutation: React.SFC<Props> = ({ children }) => (
    <GQLMutation<FollowRadarItemMutationResponse, FollowRadarItemVariables> mutation={FOLLOW_RADAR_ITEM_MUTATION}>
        {(mutate, options) => children(mutate, options)}
    </GQLMutation>
)
