import React from 'react'
import { permissions } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'
import { BreakpointEnum } from '~/components/Core/Layout/ResponsiveGrid'
import {
    DashboardWidgetContentFragmentFragment,
    DashboardWidgetEnum,
    DashboardWidgetLayoutTypeEnum,
    DashboardWidgetMetadataFragmentFragment,
    EmployeePermissionEnum,
} from '~/generated/graphql'

import ControlmeasurementsThumbnail from '../WidgetModals/thumbnails/ControlmeasurementsThumbnail.png'
import ControlThumbnail from '../WidgetModals/thumbnails/ControlThumbnail.png'
import InboxThumbnail from '../WidgetModals/thumbnails/InboxThumbnail.png'
import InboxItemImpactThumbnail from '../WidgetModals/thumbnails/InboxItemImpactThumbnail.png'
import RiskThumbnail from '../WidgetModals/thumbnails/RiskThumbnail.png'
import SignalingThumbnail from '../WidgetModals/thumbnails/SignalingThumbnail.png'
import TaskThumbnail from '../WidgetModals/thumbnails/TaskThumbnail.png'
import TopicsThumbnail from '../WidgetModals/thumbnails/TopicsThumbnail.png'
import ConsultationsThumbnail from '../WidgetModals/thumbnails/ConsultationsThumbnail.png'
import RadarThumbnail from '../WidgetModals/thumbnails/RadarThumbnail.png'
import MonitoringrappotThumbnail from '../WidgetModals/thumbnails/MonitoringrappotThumbnail.png'
import ExportThumbnail from '../WidgetModals/thumbnails/ExportThumbnail.png'

import { InboxWidget } from '../Widgets/InboxWidget/InboxWidget'
import { ControlTaskWidget } from '../Widgets/ControlTaskWidget/ControlTaskWidget'
import { SignalingWidget } from '../Widgets/SignalingWidget/SignalingWidget'
import { DashboardTaskWidget } from '../Widgets/TaskWidget/DashboardTaskWidget'
import { RiskWidget } from '../Widgets/RiskWidget/RiskWidget'
import { TopicWidget } from '../Widgets/TopicWidget/TopicWidget'
import { ConsultationsWidget } from '../Widgets/ConsultationsWidget/ConsultationsWidget'
import { RadarWidget } from '../Widgets/RadarWidget/RadarWidget'
import { MonitoringReportWidget } from '../Widgets/MonitoringReportWidget/MonitoringReportWidget'
import { ExportWidget } from '../Widgets/ExportWidget/ExportWidget'
import { ControlsWidget } from '../Widgets/ControlsWidget/ControlsWidget'
import { InboxItemImpactWidget } from '../Widgets/InboxItemImpactWidget/InboxItemImpactWidget'

export const gridColumns = { lg: 3, md: 3, sm: 2, xs: 1 }
export const gridBreakpoints = { lg: 2400, md: 1100, sm: 768, xs: 480 }

export interface WidgetItem {
    widgetKey: WidgetKey
    iconType: IconType
    checkPermission: () => boolean
    imageSource: string
    height: number
    component: (props: WidgetComponentProps) => React.ReactNode
    hasNoDepartmentContext?: boolean
}

export enum WidgetKey {
    inbox = 'inbox',
    inboxItemImpact = 'inboxItemImpact',
    controlTasks = 'controlTasks',
    signaling = 'signaling',
    tasks = 'tasks',
    risk = 'risk',
    topic = 'topic',
    topicEffectiveness = 'topicEffectiveness',
    controls = 'controls',
    radar = 'radar',
    consultations = 'consultations',
    export = 'export',
    monitoringReport = 'monitoringReport',
}

export function getWidgetKeyFromDashboardWidgetType(type: DashboardWidgetEnum): WidgetKey | undefined {
    const widgets = Object.values(WidgetKey)
    const widget = widgets.find(wi => wi === (type as string))

    if (!widget) {
        return
    }

    return widget
}

export interface DashboardWidget {
    id: number
    x: Record<BreakpointEnum, number>
    y: Record<BreakpointEnum, number>
    type: WidgetKey
    metadata: DashboardWidgetMetadataFragmentFragment
    content?: DashboardWidgetContentFragmentFragment
}

export interface WidgetComponentProps {
    id: number
    isEditing: boolean
    currentBreakpoint: DashboardWidgetLayoutTypeEnum | undefined
    onWidgetClick?: (widgetId: number, type: WidgetKey) => void
    onDeleteIconClick?: (widgetId: number) => void
    onModalStateChange?: ({ isOpening }: { isOpening: boolean }) => void
    isDetailWidget?: boolean
    metadata?: DashboardWidgetMetadataFragmentFragment
    content?: DashboardWidgetContentFragmentFragment
}

export const widgets: WidgetItem[] = [
    {
        widgetKey: WidgetKey.inbox,
        iconType: IconType.inboxWidget,
        checkPermission: () => permissions.employeeCanUseWidget(WidgetKey.inbox),
        imageSource: InboxThumbnail,
        height: 192,
        component: (props: WidgetComponentProps) => <InboxWidget {...props} />,
    },
    {
        widgetKey: WidgetKey.inboxItemImpact,
        iconType: IconType.inboxItemImpactWidget,
        checkPermission: () => permissions.employeeCanUseWidget(WidgetKey.inboxItemImpact),
        imageSource: InboxItemImpactThumbnail,
        height: 277,
        component: (props: WidgetComponentProps) => <InboxItemImpactWidget {...props} />,
    },
    {
        widgetKey: WidgetKey.controlTasks,
        iconType: IconType.controlTaskWidget,
        checkPermission: () => permissions.canAccessTaskControlPage(),
        imageSource: ControlThumbnail,
        height: 192,
        component: (props: WidgetComponentProps) => <ControlTaskWidget {...props} />,
    },
    {
        widgetKey: WidgetKey.signaling,
        iconType: IconType.signalingWidget,
        checkPermission: () => permissions.hasPermission(EmployeePermissionEnum.canMonitorSignaling),
        imageSource: SignalingThumbnail,
        height: 192,
        component: (props: WidgetComponentProps) => <SignalingWidget {...props} />,
        hasNoDepartmentContext: true,
    },
    {
        widgetKey: WidgetKey.tasks,
        iconType: IconType.taskWidget,
        checkPermission: () => permissions.employeeCanUseWidget(WidgetKey.tasks),
        imageSource: TaskThumbnail,
        height: 361,
        component: (props: WidgetComponentProps) => <DashboardTaskWidget {...props} />,
    },
    {
        widgetKey: WidgetKey.risk,
        iconType: IconType.riskWidget,
        checkPermission: () => permissions.employeeCanUseWidget(WidgetKey.risk),
        imageSource: RiskThumbnail,
        height: 192,
        component: (props: WidgetComponentProps) => <RiskWidget {...props} />,
    },
    {
        widgetKey: WidgetKey.topic,
        iconType: IconType.themeWidget,
        checkPermission: () => permissions.employeeCanUseWidget(WidgetKey.topic),
        imageSource: TopicsThumbnail,
        height: 277,
        component: (props: WidgetComponentProps) => <TopicWidget {...props} />,
    },
    {
        widgetKey: WidgetKey.topicEffectiveness,
        iconType: IconType.themeWidget,
        checkPermission: () => permissions.employeeCanUseWidget(WidgetKey.topicEffectiveness),
        imageSource: TopicsThumbnail,
        height: 277,
        component: (props: WidgetComponentProps) => <TopicWidget {...props} isForEffectiveness={true} />,
    },
    {
        widgetKey: WidgetKey.controls,
        iconType: IconType.assessmentWidget,
        checkPermission: () => permissions.employeeCanUseWidget(WidgetKey.controls),
        imageSource: ControlmeasurementsThumbnail,
        height: 192,
        component: (props: WidgetComponentProps) => <ControlsWidget {...props} />,
    },
    {
        widgetKey: WidgetKey.radar,
        iconType: IconType.radarWidget,
        checkPermission: () => permissions.employeeCanUseWidget(WidgetKey.radar),
        imageSource: RadarThumbnail,
        height: 277,
        component: (props: WidgetComponentProps) => <RadarWidget {...props} />,
    },
    {
        widgetKey: WidgetKey.consultations,
        iconType: IconType.consultation,
        checkPermission: () => permissions.employeeCanUseWidget(WidgetKey.consultations),
        imageSource: ConsultationsThumbnail,
        height: 192,
        component: (props: WidgetComponentProps) => <ConsultationsWidget {...props} />,
    },
    {
        widgetKey: WidgetKey.monitoringReport,
        iconType: IconType.monitoringReportWidget,
        checkPermission: () => permissions.canEditMonitoringReport(),
        hasNoDepartmentContext: true,
        imageSource: MonitoringrappotThumbnail,
        height: 262,
        component: (props: WidgetComponentProps) => <MonitoringReportWidget {...props} />,
    },
    {
        widgetKey: WidgetKey.export,
        iconType: IconType.export,
        checkPermission: () => permissions.employeeCanUseWidget(WidgetKey.export),
        imageSource: ExportThumbnail,
        height: 192,
        hasNoDepartmentContext: true,
        component: (props: WidgetComponentProps) => <ExportWidget {...props} />,
    },
]
