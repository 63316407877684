import gql from 'graphql-tag'
import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import { File } from '~/graphql/types/File'

interface Props {
    children: (
        editMonitoringRapport: MutationFn<EditMonitoringReportType, EditMonitoringReportVariables>,
        mutateState: MutationResult<any>
    ) => React.ReactNode
}

interface State {}

export interface EditMonitoringReportVariables {
    monitoringReportId: number
    fields: EditMonitoringReportFields
}

interface EditMonitoringReportFields {
    name: string
    file: File
}

export interface EditMonitoringReportType {
    id: string
}

const EDIT_MONITORING_RAPPORT = gql`
    mutation editMonitoringReport($fields: EditMonitoringReportFieldsInputType!, $monitoringReportId: Int!) {
        editMonitoringReport(fields: $fields, monitoringReportId: $monitoringReportId) {
            id
        }
    }
`

export class MonitoringRapportEditMutation extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<EditMonitoringReportType, EditMonitoringReportVariables> mutation={EDIT_MONITORING_RAPPORT}>
                {(mutate, mutateState) => children(mutate, mutateState)}
            </GQLMutation>
        )
    }
}
