import './FetchMoreButton.scss'

import React from 'react'
import { BEM } from '~/services/BEMService'
import { Button, ButtonType } from '../Button/Button'
import { IconType } from '../Icon/IconType'

interface Props {
    loadingMore: boolean
    fetchMore: () => void
    label: string
    alignLeft?: boolean
}

export class FetchMoreButton extends React.PureComponent<Props> {
    private bem = new BEM('FetchMoreButton', () => ({
        alignedLeft: this.props.alignLeft,
    }))

    public render() {
        const { fetchMore, label, loadingMore } = this.props
        return (
            <div className={this.bem.getClassName()}>
                <Button
                    icon={IconType.arrowDown}
                    onClick={() => fetchMore()}
                    type={ButtonType.actionLink}
                    className={this.bem.getElement('button')}
                    loading={loadingMore}
                >
                    {label}
                </Button>
            </div>
        )
    }
}
