import './GroupedTasksTable.scss'

import React from 'react'
import { Table, SortDirectionChangeHandler, SortOption } from '~/components/Core/DataDisplay/Table/Table'
import { CappedProfileList } from '~/components/Core/DataDisplay/CappedUserList/CappedProfileList'
import { LinkedTaskLabel } from '~/components/Domain/Task/LinkedTaskLabel'
import { RevisionDateFormat } from '~/components/Domain/Compliance/Controls/RevisionDateFormat'
import addDays from 'date-fns/add_days'
import { TaskOpenClosedCounter } from '~/components/Domain/Task/TaskOpenClosedCounter'
import { TasksForLinkedItemTableContainer } from '~/views/Customer/Tasks/GroupedTasks/TasksForLinkedItemTableContainer'
import { GroupedTask } from '~/views/Customer/Tasks/GroupedTasks/GroupedTasksQuery'
import { localize } from '~/bootstrap'
import { BEM } from '~/services/BEMService'

interface Props {
    loading?: boolean
    loadingMore?: boolean
    groupedTasks: GroupedTask[]
    onSortDirectionChange?: SortDirectionChangeHandler
    refetch?: () => void
    defaultSortDirection?: SortOption
    completedOnly?: boolean
}

interface State {}

export class GroupedTasksTable extends React.Component<Props, State> {
    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks)
    private bem = new BEM('GroupedTasksTable')

    public render() {
        const { loading, loadingMore, onSortDirectionChange, defaultSortDirection } = this.props

        const columns = this.getColumnOptions()
        const data = this.getTableData()

        return (
            <Table
                className={this.bem.getClassName()}
                loading={loading}
                loadingMore={loadingMore}
                columns={columns}
                data={data}
                onSortDirectionChange={onSortDirectionChange}
                defaultSortDirection={defaultSortDirection}
            />
        )
    }

    private getColumnOptions() {
        return [
            {
                field: 'linkedItem',
                headerLabel: this.loc(t => t.GroupedTasksTable.headers.linkedItem),
                sortable: false,
            },
            {
                field: 'employees',
                headerLabel: '',
                sortable: false,
            },
            {
                field: 'openTasks',
                headerLabel: this.loc(t => t.GroupedTasksTable.headers.tasks),
                sortable: true,
            },
            {
                field: 'firstDueAt',
                headerLabel: this.loc(t => t.GroupedTasksTable.headers.dueAt),
                sortable: true,
            },
        ]
    }

    private getTableData() {
        const { groupedTasks } = this.props

        return groupedTasks.map(group => {
            return {
                id: group.id,
                columns: {
                    linkedItem: <LinkedTaskLabel linkedItem={group.linkedItem} tooltip={true} />,
                    employees: this.renderEmployees(group),
                    openTasks: <TaskOpenClosedCounter openAmount={group.open} closedAmount={group.closed} />,
                    firstDueAt: this.renderFirstDueAt(group),
                },
                children: this.renderGroupChildren(group),
            }
        })
    }

    private renderEmployees({ employees }: GroupedTask) {
        return (
            <CappedProfileList
                users={employees.map(({ user: { profile, id } }) => ({
                    id,
                    fullName: profile.fullName,
                    avatar: profile.avatar,
                }))}
            />
        )
    }

    private renderFirstDueAt({ firstDueAt }: GroupedTask) {
        if (!firstDueAt) {
            return null
        }

        return <RevisionDateFormat date={new Date(firstDueAt)} thresholdDate={addDays(new Date(firstDueAt), 1)} />
    }

    private renderGroupChildren({ linkedItem }: GroupedTask) {
        if (!linkedItem) {
            return
        }

        return () => (
            <TasksForLinkedItemTableContainer
                refetch={this.props.refetch}
                linkedItem={linkedItem!}
                completedOnly={this.props.completedOnly}
            />
        )
    }
}
