import React from 'react'
import { BEM } from '~/services/BEMService'
import { Button, ButtonType } from '../Button/Button'
import { IconType } from '../Icon/IconType'
import { Paragraph } from '../Typography/Paragraph'
import './PopperButtonItem.scss'

interface Props {
    className?: string
    label: string
    meta?: JSX.Element
    onClick?: () => void
    to?: string
    hasNestedItems?: boolean
    active?: boolean
    singleChild?: boolean
}

export class PopperButtonItem extends React.PureComponent<Props> {
    private bem = new BEM('PopperButtonItem', () => ({
        onlyChild: this.props.singleChild,
        wider: this.props.hasNestedItems || !!this.props.meta,
        hasNestedItems: this.props.hasNestedItems,
    }))

    public render() {
        const { onClick, label, hasNestedItems, active, to, meta, singleChild, className } = this.props
        const rightIcon = hasNestedItems ? IconType.arrowRight : undefined

        return (
            <Button
                iconRight={rightIcon}
                className={this.bem.getClassName(className)}
                onClick={onClick}
                to={to}
                type={ButtonType.noStyling}
            >
                <Paragraph bold={active || singleChild} blue={active} className={this.bem.getElement('label')}>
                    {label}
                </Paragraph>
                {meta}
            </Button>
        )
    }
}
