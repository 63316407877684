import React from 'react'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Markdown } from '~/components/Core/Text/Markdown'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { FilePreviewModal } from '~/components/Domain/FilePreview/FilePreviewModal'
import { EventLogType } from '~/generated/graphql'
import { FileMetaData, TopicNoteNewDataMetaData } from '~/graphql/types/EventMetadata'
import { FileType as FileTypes } from '~/services/FileService'
import { TopicAssessmentEvent, TopicAssessmentEventType } from './TopicAssessmentEvent'
import { TopicDesignAndEffectivenessEventCard } from './TopicDesignAndEffectivenessEventCard'

interface Props {
    event: EventLogType
    deletedFilePaths?: string[]
}

export enum TopicNoteEventType {
    wasCreated = 'TOPIC_ASSESSMENT_NOTE_WAS_CREATED',
    wasUpdated = 'TOPIC_ASSESSMENT_NOTE_WAS_UPDATED',
}

export class TopicNoteEvent extends React.PureComponent<Props> {
    public render() {
        const { event, deletedFilePaths } = this.props
        const { type } = event

        const isAssessmentEvent = Object.values(TopicAssessmentEventType).includes(type as TopicAssessmentEventType)
        if (isAssessmentEvent) {
            return <TopicAssessmentEvent deletedFilePaths={deletedFilePaths} event={event} />
        }

        return <TopicDesignAndEffectivenessEventCard event={event} content={this.renderContent()} />
    }

    private renderContent() {
        const { event } = this.props
        const { type, metaData } = event
        const newData = metaData?.newData as TopicNoteNewDataMetaData | undefined

        if (type === TopicNoteEventType.wasCreated || type === TopicNoteEventType.wasUpdated) {
            return (
                <Column>
                    {this.renderTitle(metaData?.newData?.title)}
                    {this.renderDescription(newData?.description)}
                    {newData?.addedDocumentFiles?.map((d, n) => this.renderDocument(d, n))}
                    {newData?.removedDocumentFiles?.map((d, n) => this.renderDocument(d, n, true))}
                </Column>
            )
        }

        return <></>
    }

    private renderTitle(title?: string) {
        if (!title) {
            return
        }

        return (
            <Row extraSmallSpacing={true}>
                <Icon type={IconType.assessmentScribble} />
                <Paragraph bold={true}>{title}</Paragraph>
            </Row>
        )
    }

    private renderDescription(description?: string) {
        if (!description) {
            return
        }

        return <Markdown source={description} paragraphLike={true} />
    }

    private renderDocument(fileMetadata: FileMetaData, key: number, removed?: boolean) {
        const { event, deletedFilePaths } = this.props
        const { metaData } = event
        const deleted = deletedFilePaths?.includes(fileMetadata.path)

        return (
            <TopicDesignAndEffectivenessEventCard
                key={key}
                event={{ ...event, type: TopicAssessmentEventType.wasUpdated }}
                assessmentType={metaData?.linkedItemDesignOrEffectiveness}
                content={
                    (removed || deleted) && !fileMetadata.filePersisted
                        ? this.renderName(fileMetadata.name, IconType.attachment, true)
                        : this.renderFile(fileMetadata, removed)
                }
            />
        )
    }

    private renderFile(inputFile: FileMetaData, removed?: boolean) {
        const file = {
            id: inputFile.id,
            name: inputFile.name,
            path: inputFile.path,
            mimetype: inputFile.mimetype || 'application/pdf',
        }

        const buttonType = removed ? ButtonType.subtleItemLink : ButtonType.actionLink

        return (
            <FilePreviewModal key={file.id} file={file} fileType={FileTypes.topicAssessmentFile}>
                {previewFile => (
                    <Button onClick={() => previewFile()} icon={IconType.attachment} type={buttonType}>
                        {removed ? <Paragraph strikethrough>{file.name}</Paragraph> : file.name}
                    </Button>
                )}
            </FilePreviewModal>
        )
    }

    private renderName(name: string, icon: IconType, subtleAndStrikethrough?: boolean) {
        return (
            <Row extraSmallSpacing>
                <Icon subtle={subtleAndStrikethrough} type={icon} />
                <Paragraph subtle={subtleAndStrikethrough} strikethrough={subtleAndStrikethrough} bold={true}>
                    {name}
                </Paragraph>
            </Row>
        )
    }
}
