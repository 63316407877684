import './MonitoringReportTopicSelectionTableContainer.scss'
import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { MonitoringReportTopicSelectionHeader } from './MonitoringReportTopicSelectionHeader'
import { CheckedDepartmentTopics, MonitoringReportTopicSelectionTable } from './MonitoringReportTopicSelectionTable'
import { MonitoringReportTopicsQuery, MonitoringReportTopicsQueryType } from './MonitoringReportTopicsQuery'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Row } from '~/components/Core/Layout/Row'
import { BEM } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { GroupedSelectOption, Select, SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import isEmpty from 'lodash/isEmpty'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface State {
    currentPage: number
    checkedDepartmentTopics: CheckedDepartmentTopics
    selectedTopicId: number | null
    selectedThemeId: number | null
    expandedRowsIds: number[]
}

interface Props {
    errorPath: string
}

type ThemeWithTopics = NonNullable<MonitoringReportTopicsQueryType[0]['themesWithTopics']>[0]
type Topic = NonNullable<ThemeWithTopics['topics']>[0]

interface TopicOptionValue {
    id: number
    parentThemeId: number
}

export class MonitoringReportTopicSelectionTableContainer extends React.PureComponent<Props, State> {
    public state: State = {
        currentPage: 0,
        checkedDepartmentTopics: {},
        selectedTopicId: null,
        selectedThemeId: null,
        expandedRowsIds: [],
    }

    private shownColumnCount = 7
    private allDepartmentTopics: Record<number, Record<number, Topic[]>> = {}
    private bem = new BEM('MonitoringReportTopicSelectionTableContainer')
    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.AddMonitoringRapportModal)
    private tableRef = React.createRef<MonitoringReportTopicSelectionTable>()

    public render() {
        const { errorPath } = this.props

        return (
            <MonitoringReportTopicsQuery>
                {data => {
                    this.setAllDepartmentTopics(data)
                    const filteredData = this.getFilteredData(data)

                    return (
                        <Column className={this.bem.getClassName()}>
                            {this.renderTitle()}
                            {this.renderFilters(data)}
                            <Field className={this.bem.getElement('topic-field')} forInput="checkedDepartmentTopics" />
                            <ErrorMessage path={errorPath} />
                            <MonitoringReportTopicSelectionHeader
                                shownDepartmentCount={this.shownColumnCount}
                                departments={filteredData}
                                currentPage={this.state.currentPage}
                                onClickNext={this.handleClickNext}
                                onClickPrevious={this.handleClickPrevious}
                            />
                            <MonitoringReportTopicSelectionTable
                                ref={this.tableRef}
                                shownDepartmentCount={this.shownColumnCount}
                                currentPage={this.state.currentPage}
                                departments={filteredData}
                                onChange={checkedDepartmentTopics => this.setState({ checkedDepartmentTopics })}
                                allDepartmentTopics={this.allDepartmentTopics}
                                expandedIds={this.state.expandedRowsIds}
                            />
                        </Column>
                    )
                }}
            </MonitoringReportTopicsQuery>
        )
    }

    private renderTitle() {
        return (
            <Row smallSpacing className={this.bem.getElement('title-container')}>
                <SectionTitle>{this.loc(t => t.selectTopics)}</SectionTitle>
                <Tooltip
                    content={
                        <Column className={this.bem.getElement('warning-content')}>
                            <Paragraph small>{this.loc(t => t.warningText1)}</Paragraph>
                            <Paragraph small>
                                <strong>{this.loc(t => t.warningText2Part1)}</strong>
                                {this.loc(t => t.warningText2Part2)}
                            </Paragraph>
                        </Column>
                    }
                >
                    <Icon className={this.bem.getElement('warning')} type={IconType.warning} />
                </Tooltip>
            </Row>
        )
    }

    private renderFilters(data: MonitoringReportTopicsQueryType) {
        const { topicOptions, themeOptions } = this.filterAssessedOptions(data)

        return (
            <Row flexStart fullWidth className={this.bem.getElement('filters')}>
                <Select
                    disabled={!!this.state.selectedTopicId}
                    name="themeId"
                    onChange={this.handleOnThemeChange}
                    placeholder={this.loc(t => t.selectTheme)}
                    options={themeOptions}
                    searchable
                    clearable
                    openMenuOnClick={false}
                    openMenuOnFocus={false}
                />
                <Select
                    name="topicId"
                    placeholder={this.loc(t => t.selectTopic)}
                    disabled={!!this.state.selectedThemeId}
                    options={topicOptions}
                    onChange={this.handleOnTopicChange}
                    searchable
                    clearable
                    openMenuOnClick={false}
                    openMenuOnFocus={false}
                />
            </Row>
        )
    }

    private getFilteredData(data: MonitoringReportTopicsQueryType) {
        const { selectedThemeId, selectedTopicId } = this.state
        if (!selectedThemeId && !selectedTopicId) {
            return data
        }

        if (selectedThemeId) {
            return data.map(d => ({
                ...d,
                themesWithTopics: d.themesWithTopics?.filter(t => selectedThemeId === t.id),
            }))
        }

        return data.map(d => ({
            ...d,
            themesWithTopics: d.themesWithTopics
                ?.map(t => ({
                    ...t,
                    topics: t.topics?.filter(t => selectedTopicId === t.id),
                }))
                .filter(t => !!t.topics?.length),
        }))
    }

    private filterAssessedOptions(departments: MonitoringReportTopicsQueryType) {
        if (!departments) {
            return {
                topicOptions: [],
                themeOptions: [],
            }
        }

        const themesWithTopics: Record<number, Record<number, string>> = {}

        Object.values(this.allDepartmentTopics).forEach(department => {
            Object.entries(department).forEach(([themeId, topics]) => {
                if (!themesWithTopics[themeId]) {
                    themesWithTopics[themeId] = {}
                }

                topics.forEach(t => {
                    if (themesWithTopics[themeId][t.id]) {
                        return
                    }

                    themesWithTopics[themeId][t.id] = t.name
                })
            })
        })

        const themeOptions: SelectOption<number>[] = []
        const topicOptions: GroupedSelectOption<TopicOptionValue>[] = []
        const allThemesWithTopics = departments.flatMap(d => d.themesWithTopics ?? [])

        Object.entries(themesWithTopics).forEach(([themeId, topics]) => {
            if (isEmpty(topics)) {
                return
            }

            const id = parseInt(themeId)
            const themeName = allThemesWithTopics.find(t => t.id === id)?.name ?? ''

            themeOptions.push({ label: themeName, value: id })
            topicOptions.push({
                label: themeName,
                options: Object.entries(topics)
                    .map(([k, v]) => ({
                        label: v,
                        value: { id: parseInt(k), parentThemeId: id },
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label)),
            })
        })

        themeOptions.sort((a, b) => (a.label as string).localeCompare(b.label as string))

        return { topicOptions, themeOptions }
    }

    private setAllDepartmentTopics(data: MonitoringReportTopicsQueryType) {
        data.forEach(department => {
            if (!department.themesWithTopics?.length) {
                return
            }

            this.allDepartmentTopics[department.id] = {}
            department.themesWithTopics.forEach(theme => {
                this.allDepartmentTopics[department.id][theme.id] = theme.topics || []
            })
        })
    }

    private handleOnTopicChange = (selectedTopic: SelectOption<TopicOptionValue>) => {
        if (!selectedTopic) {
            this.setState({ selectedTopicId: null, expandedRowsIds: [] })
            return
        }
        const newState = {
            expandedRowsIds: [selectedTopic.value.parentThemeId],
            selectedTopicId: selectedTopic.value.id,
        }
        this.setState(newState, () => this.tableRef.current?.scrollToRow(selectedTopic.value.parentThemeId))
    }

    private handleOnThemeChange = (selectedTheme: SelectOption<number>) => {
        if (!selectedTheme) {
            this.setState({ selectedThemeId: null, expandedRowsIds: [] })
            return
        }

        const newState = { expandedRowsIds: [selectedTheme.value], selectedThemeId: selectedTheme.value }
        this.setState(newState, () => this.tableRef.current?.scrollToRow(selectedTheme.value))
    }

    private handleClickNext = () => {
        this.setState({ currentPage: this.state.currentPage + 1 })
    }

    private handleClickPrevious = () => {
        this.setState({ currentPage: this.state.currentPage - 1 })
    }
}
