import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import {
    SetConsultantTaskTemplateVisibilityMutation,
    SetConsultantTaskTemplateVisibilityMutationVariables,
    SetConsultantTaskTemplateVisibilityDocument,
} from '~/generated/graphql'

interface Props {
    children: (mutation: SetConsultantTaskTemplateVisibilityMutationFN, options: MutationResult) => JSX.Element
}

export type SetConsultantTaskTemplateVisibilityMutationFN = MutationFn<
    SetConsultantTaskTemplateVisibilityMutation,
    SetConsultantTaskTemplateVisibilityMutationVariables
>

export class SetConsultantTaskTemplateVisibility extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<
                SetConsultantTaskTemplateVisibilityMutation,
                SetConsultantTaskTemplateVisibilityMutationVariables
            >
                mutation={SetConsultantTaskTemplateVisibilityDocument}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
