import React from 'react'
import { MutationFn } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    CreateTopicNoteMutationResult,
    CreateTopicNoteDocument,
    CreateTopicNoteMutation,
    CreateTopicNoteMutationVariables,
    TopicDesignAndEffectivenessDocument,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

export type CreateTopicNoteMutationFN = MutationFn<CreateTopicNoteMutation, CreateTopicNoteMutationVariables>

interface Props {
    children: (mutate: CreateTopicNoteMutationFN, result: CreateTopicNoteMutationResult) => React.ReactNode
    topicId: number
}

export class CreateTopicNote extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, topicId } = this.props

        return (
            <GQLMutation<CreateTopicNoteMutation, CreateTopicNoteMutationVariables>
                mutation={CreateTopicNoteDocument}
                refetchQueries={[
                    {
                        query: TopicDesignAndEffectivenessDocument,
                        variables: {
                            departmentId: this.context.activeDepartmentId,
                            customerSlug: this.context.customer.slug,
                            id: topicId,
                        },
                    },
                ]}
            >
                {(mutate, result) => children(mutate, result)}
            </GQLMutation>
        )
    }
}
