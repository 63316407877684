import gql from 'graphql-tag'
import * as ApolloReactCommon from '@apollo/react-common'
import * as React from 'react'
import * as ApolloReactComponents from '@apollo/react-components'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    DateTime: Date
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: any
    /** Date custom scalar type */
    Date: Date
    /** The `Upload` scalar type represents a file upload promise that resolves an object containing `stream`, `filename`, `mimetype` and `encoding`. */
    Upload: File
}

export type AbstractLawArticleType = {
    __typename?: 'AbstractLawArticleType'
    id: Scalars['Int']
    abstractLawId?: Maybe<Scalars['Int']>
}

export enum AccessGroup {
    editor = 'editor',
    customer = 'customer',
    consultant = 'consultant',
    prospect = 'prospect',
}

export type AddressInputType = {
    addressLine?: Maybe<Scalars['String']>
    postalCode?: Maybe<Scalars['String']>
    city?: Maybe<Scalars['String']>
    stateOrProvince?: Maybe<Scalars['String']>
    country?: Maybe<Scalars['String']>
}

export type AddressType = {
    __typename?: 'AddressType'
    addressLine?: Maybe<Scalars['String']>
    postalCode?: Maybe<Scalars['String']>
    city?: Maybe<Scalars['String']>
    stateOrProvince?: Maybe<Scalars['String']>
    country?: Maybe<Scalars['String']>
}

export type AlertFilters = {
    search?: Maybe<Scalars['String']>
    customerFrameworkIds?: Maybe<Array<Scalars['Int']>>
    departmentId?: Maybe<Scalars['Int']>
    hasTasksForDepartment?: Maybe<Scalars['Boolean']>
    isArchivedFromInboxForDepartment?: Maybe<Scalars['Boolean']>
    newsGroupIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    impactLevel?: Maybe<AlertImpactLevelFilter>
    dateFrom?: Maybe<Scalars['DateTime']>
    dateTo?: Maybe<Scalars['DateTime']>
    isIgnored?: Maybe<Scalars['Boolean']>
    hasControls?: Maybe<Scalars['Boolean']>
    allItems?: Maybe<Scalars['Boolean']>
}

export type AlertIdWithDepartmentIdInputType = {
    alertId: Scalars['Int']
    departmentId: Scalars['Int']
}

export enum AlertImpactLevel {
    low = 'LOW',
    medium = 'MEDIUM',
    high = 'HIGH',
}

export enum AlertImpactLevelFilter {
    low = 'LOW',
    medium = 'MEDIUM',
    high = 'HIGH',
    none = 'NONE',
}

export type AlertImpactLevelType = {
    __typename?: 'AlertImpactLevelType'
    id: Scalars['Int']
    alert: AlertType
    alertId: Scalars['Int']
    department: DepartmentType
    departmentId: Scalars['Int']
    alertLevelSetBy?: Maybe<EmployeeType>
    alertLevelSetById: Scalars['Int']
    impactLevel: AlertImpactLevel
}

export type AlertsForMonthSort = {
    name?: Maybe<Scalars['String']>
    publishedAt?: Maybe<Scalars['String']>
}

export type AlertType = {
    __typename?: 'AlertType'
    id: Scalars['Int']
    updatedAt: Scalars['DateTime']
    editorsNote?: Maybe<Scalars['String']>
    name: Scalars['String']
    abstract?: Maybe<Scalars['String']>
    publishedAt?: Maybe<Scalars['DateTime']>
    impactLevel?: Maybe<AlertImpactLevel>
    isRead?: Maybe<Scalars['Boolean']>
    customerFrameworks?: Maybe<Array<CustomerFrameworkType>>
    item?: Maybe<SingleLinkedItemType>
    firstTask?: Maybe<TaskType>
    /** open task with the closest deadline */
    nextTask?: Maybe<TaskType>
    /** open task with the highest prio */
    highestPrioTask?: Maybe<TaskType>
    tasks?: Maybe<Array<TaskType>>
    inboxStatus?: Maybe<InboxStatusType>
    allPresentDepartments: Array<Maybe<DepartmentType>>
    publishedFromCustomerNews?: Maybe<PublishedCustomerNewsType>
    isPublishedFromCustomerNews?: Maybe<Scalars['Boolean']>
}

export type AlertTypeImpactLevelArgs = {
    departmentId?: Maybe<Scalars['Int']>
}

export type AlertTypeCustomerFrameworksArgs = {
    customerSlug?: Maybe<Scalars['String']>
    departmentId: Scalars['Int']
}

export type AlertTypeItemArgs = {
    customerSlug?: Maybe<Scalars['String']>
}

export type AlertTypeFirstTaskArgs = {
    departmentId: Scalars['Int']
}

export type AlertTypeNextTaskArgs = {
    departmentId: Scalars['Int']
}

export type AlertTypeHighestPrioTaskArgs = {
    departmentId: Scalars['Int']
}

export type AlertTypeTasksArgs = {
    departmentId: Scalars['Int']
}

export type AlertTypeInboxStatusArgs = {
    departmentId: Scalars['Int']
}

export type AllTopicFilters = {
    departmentId?: Maybe<Scalars['Int']>
    excludeNonApplicableTopics?: Maybe<Scalars['Boolean']>
    excludeLinkedToControl?: Maybe<Scalars['Int']>
}

export type AssessmentArticleType = {
    __typename?: 'AssessmentArticleType'
    id?: Maybe<Scalars['Int']>
    assessmentTopic?: Maybe<AssessmentTopicType>
    linkedLawArticles: LawArticleType
}

export type AssessmentCoverageType = {
    __typename?: 'AssessmentCoverageType'
    assessed?: Maybe<Scalars['Int']>
    noted?: Maybe<Scalars['Int']>
    total?: Maybe<Scalars['Int']>
}

export type AssessmentLinkedToTopicInputType = {
    topicId?: Maybe<Scalars['Int']>
    selectedArticleIds?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export type AssessmentSectionTopicType = {
    __typename?: 'AssessmentSectionTopicType'
    norm?: Maybe<AssessmentTopicType>
    selectedCount: Scalars['Int']
    totalCount: Scalars['Int']
}

export type AssessmentSectionType = {
    __typename?: 'AssessmentSectionType'
    id: Scalars['Int']
    order: Scalars['Int']
    assessment: AssessmentType
    riskCount: Scalars['Int']
    controlCount: Scalars['Int']
    normCount: Scalars['Int']
    risks?: Maybe<Array<RiskType>>
    norms?: Maybe<Array<AssessmentSectionTopicType>>
    controls?: Maybe<Array<ControlType>>
}

export type AssessmentsFilters = {
    name?: Maybe<Scalars['String']>
    departmentId?: Maybe<Scalars['Int']>
}

export type AssessmentTopicType = {
    __typename?: 'AssessmentTopicType'
    id: Scalars['Int']
    topic?: Maybe<TopicType>
    assessmentArticles?: Maybe<Array<AssessmentArticleType>>
    assessmentArticlesCount?: Maybe<Scalars['Int']>
    assessment?: Maybe<AssessmentType>
    assessmentSections?: Maybe<Array<AssessmentSectionType>>
}

export type AssessmentType = {
    __typename?: 'AssessmentType'
    id: Scalars['Int']
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    useDistinctBrutoRiskValues: Scalars['Boolean']
    riskCount?: Maybe<Scalars['Int']>
    controlCount?: Maybe<Scalars['Int']>
    normCount?: Maybe<Scalars['Int']>
    department?: Maybe<DepartmentType>
    departmentId?: Maybe<Scalars['Int']>
    risks?: Maybe<Array<RiskType>>
    norms?: Maybe<Array<AssessmentTopicType>>
    controls?: Maybe<Array<ControlType>>
    assessmentSections?: Maybe<Array<AssessmentSectionType>>
    /** open task with the closest deadline */
    nextTask?: Maybe<TaskType>
    /** open task with the highest prio */
    highestPrioTask?: Maybe<TaskType>
}

export type AssessmentTypeNextTaskArgs = {
    departmentId: Scalars['Int']
}

export type AssessmentTypeHighestPrioTaskArgs = {
    departmentId: Scalars['Int']
}

export type AssignableEmployeeType = {
    __typename?: 'AssignableEmployeeType'
    id?: Maybe<Scalars['String']>
    employee?: Maybe<EmployeeType>
    shadowEmployee?: Maybe<ShadowEmployeeType>
}

export type AttachmentType = {
    __typename?: 'AttachmentType'
    id?: Maybe<Scalars['Int']>
    filename?: Maybe<Scalars['String']>
    mimetype?: Maybe<Scalars['String']>
    path?: Maybe<Scalars['String']>
}

export type ChangedTopicType = {
    topicId?: Maybe<Scalars['Int']>
    addAllArticles?: Maybe<Scalars['Boolean']>
    selectedArticleIds?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export type CheckedDepartmentTopicsInputType = {
    departmentId?: Maybe<Scalars['Int']>
    topicIds?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export type ComplianceExportType = {
    __typename?: 'ComplianceExportType'
    token: Scalars['String']
    filename: Scalars['String']
}

export type ConsultantsFiltersType = {
    search?: Maybe<Scalars['String']>
    linkedCustomerIds?: Maybe<Array<Scalars['Int']>>
}

export type ConsultantTaskTemplateType = {
    __typename?: 'ConsultantTaskTemplateType'
    id: Scalars['Int']
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    type?: Maybe<ConsultantTaskTemplateTypeEnum>
    totalTaskCount: Scalars['Int']
    isSubTaskTemplate: Scalars['Boolean']
    subTaskTemplates?: Maybe<Array<ConsultantTaskTemplateType>>
    parentTaskTemplate?: Maybe<ConsultantTaskTemplateType>
    createdBy: UserType
    frameworks: Array<FrameworkType>
}

export type ConsultantTaskTemplateTypeTotalTaskCountArgs = {
    customerId?: Maybe<Scalars['Int']>
}

export enum ConsultantTaskTemplateTypeEnum {
    checklist = 'CHECKLIST',
    workingfile = 'WORKINGFILE',
}

export type ConsultantType = {
    __typename?: 'ConsultantType'
    id?: Maybe<Scalars['Int']>
    customerCount?: Maybe<Scalars['Int']>
    organization?: Maybe<OrganizationType>
    user?: Maybe<UserType>
    customers?: Maybe<Array<Maybe<CustomerType>>>
}

export type ConsultationFilters = {
    search?: Maybe<Scalars['String']>
    status?: Maybe<Scalars['String']>
    customerFrameworkIds?: Maybe<Array<Scalars['Int']>>
    departmentId?: Maybe<Scalars['Int']>
}

export type ConsultationSort = {
    newsSource?: Maybe<Scalars['String']>
    startsAt?: Maybe<Scalars['String']>
    endsAt?: Maybe<Scalars['String']>
    nulls?: Maybe<NullsSortEnumType>
}

export type ConsultationType = {
    __typename?: 'ConsultationType'
    id: Scalars['Int']
    createdAt: Scalars['DateTime']
    updatedAt: Scalars['DateTime']
    name: Scalars['String']
    startsAt?: Maybe<Scalars['DateTime']>
    endsAt?: Maybe<Scalars['DateTime']>
    abstract?: Maybe<Scalars['String']>
    linkedItems?: Maybe<Array<GroupedItemType>>
    source?: Maybe<Scalars['String']>
    customerFrameworks?: Maybe<Array<CustomerFrameworkType>>
    /** open task with the closest deadline */
    nextTask?: Maybe<TaskType>
    /** open task with the highest prio */
    highestPrioTask?: Maybe<TaskType>
}

export type ConsultationTypeLinkedItemsArgs = {
    customerSlug?: Maybe<Scalars['String']>
    lawArticlesOnly?: Maybe<Scalars['Boolean']>
    filterDeletedArticles?: Maybe<Scalars['Boolean']>
}

export type ConsultationTypeCustomerFrameworksArgs = {
    customerSlug?: Maybe<Scalars['String']>
    departmentId?: Maybe<Scalars['Int']>
    all?: Maybe<Scalars['Boolean']>
    externallyCuratedOnly?: Maybe<Scalars['Boolean']>
}

export type ConsultationTypeNextTaskArgs = {
    departmentId: Scalars['Int']
}

export type ConsultationTypeHighestPrioTaskArgs = {
    departmentId: Scalars['Int']
}

export type ControlDocumentType = {
    __typename?: 'ControlDocumentType'
    id: Scalars['Int']
    file?: Maybe<FileType>
    archivedAt?: Maybe<Scalars['DateTime']>
}

export type ControlExportType = {
    __typename?: 'ControlExportType'
    token: Scalars['String']
    filename: Scalars['String']
}

export type ControlFilters = {
    name?: Maybe<Scalars['String']>
    types?: Maybe<Array<ControlTypeType>>
    assessmentIds?: Maybe<Array<Scalars['Int']>>
    topicIds?: Maybe<Array<Scalars['Int']>>
    departmentId?: Maybe<Scalars['Int']>
    linkableToControlId?: Maybe<Scalars['Int']>
    parentControlsOnly?: Maybe<Scalars['Boolean']>
    status?: Maybe<ControlStatus>
}

export type ControlOrNoteType = TopicControlMeasureType | TopicNoteType

export type ControlSort = {
    name?: Maybe<SortDirectionEnumType>
    type?: Maybe<SortDirectionEnumType>
    createdAt?: Maybe<SortDirectionEnumType>
    revisionDate?: Maybe<SortDirectionEnumType>
}

export enum ControlStatus {
    open = 'open',
    archived = 'archived',
}

export type ControlType = {
    __typename?: 'ControlType'
    id: Scalars['Int']
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    createdAt: Scalars['DateTime']
    updatedAt: Scalars['DateTime']
    visibility: ControlVisibility
    visibleToDepartments?: Maybe<Array<DepartmentType>>
    controlDocuments?: Maybe<Array<ControlDocumentType>>
    type: ControlTypeType
    hasControlLinkedControls?: Maybe<Scalars['Boolean']>
    departmentId: Scalars['Int']
    department: DepartmentType
    linkedRisks?: Maybe<Array<RiskControlType>>
    assessments?: Maybe<Array<AssessmentType>>
    assessmentSections?: Maybe<Array<AssessmentSectionType>>
    parentControl?: Maybe<ControlType>
    childControls?: Maybe<Array<ControlType>>
    linkedControlsCount?: Maybe<Scalars['Int']>
    departmentsUsingControl?: Maybe<Array<DepartmentType>>
    startDate?: Maybe<Scalars['DateTime']>
    revisionDate?: Maybe<Scalars['DateTime']>
    responsibleEmployees?: Maybe<Array<EmployeeType>>
    responsibleShadowEmployees?: Maybe<Array<ShadowEmployeeType>>
    amountOfLinkedTopics?: Maybe<Scalars['Int']>
    /** open task with the closest deadline */
    nextTask?: Maybe<TaskType>
    /** open task with the highest prio */
    highestPrioTask?: Maybe<TaskType>
    openTasks?: Maybe<Array<TaskType>>
    archivedAt?: Maybe<Scalars['DateTime']>
    unarchivedAt?: Maybe<Scalars['DateTime']>
    archivedReason?: Maybe<Scalars['String']>
    archivedBy?: Maybe<EmployeeType>
    unarchivedBy?: Maybe<EmployeeType>
}

export type ControlTypeControlDocumentsArgs = {
    controlDocumentId?: Maybe<Scalars['Int']>
}

export type ControlTypeLinkedRisksArgs = {
    assessmentId?: Maybe<Scalars['Int']>
    departmentId: Scalars['Int']
}

export type ControlTypeAssessmentsArgs = {
    departmentId: Scalars['Int']
}

export type ControlTypeParentControlArgs = {
    activeDepartmentId?: Maybe<Scalars['Int']>
}

export type ControlTypeChildControlsArgs = {
    activeDepartmentId?: Maybe<Scalars['Int']>
}

export type ControlTypeLinkedControlsCountArgs = {
    activeDepartmentId: Scalars['Int']
}

export type ControlTypeNextTaskArgs = {
    departmentId: Scalars['Int']
}

export type ControlTypeHighestPrioTaskArgs = {
    departmentId: Scalars['Int']
}

export type ControlTypeOpenTasksArgs = {
    departmentId: Scalars['Int']
}

export enum ControlTypeType {
    policy = 'POLICY',
    procedure = 'PROCEDURE',
    control = 'CONTROL',
    keyControl = 'KEY_CONTROL',
}

export enum ControlVisibility {
    ownerDepartment = 'OWNER_DEPARTMENT',
    allDepartments = 'ALL_DEPARTMENTS',
    selectionOfDepartments = 'SELECTION_OF_DEPARTMENTS',
}

export type CreateConsultantFieldsType = {
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    email?: Maybe<Scalars['String']>
}

export type CreateConsultantTaskTemplateFieldsInputType = {
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    parentId?: Maybe<Scalars['Int']>
    type?: Maybe<ConsultantTaskTemplateTypeEnum>
    frameworkIds?: Maybe<Array<Scalars['Int']>>
}

export type CreateControlFieldsType = {
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    type?: Maybe<ControlTypeType>
    visibility?: Maybe<ControlVisibility>
    visibleToDepartmentIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    linkToAssessmentSectionId?: Maybe<Scalars['Int']>
    document?: Maybe<UploadControlDocumentFields>
    startDate?: Maybe<Scalars['DateTime']>
    revisionDate?: Maybe<Scalars['DateTime']>
    responsibleEmployeeIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    responsibleShadowEmployeeIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    responsibleEmployees?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CreateDepartmentExport = {
    __typename?: 'createDepartmentExport'
    token: Scalars['String']
    filename: Scalars['String']
}

export type CreateDepartmentFieldsInputType = {
    name?: Maybe<Scalars['String']>
    employees?: Maybe<DepartmentEmployeesFieldInputType>
    receiveAlertsForConsultationsEnabled?: Maybe<Scalars['Boolean']>
}

export type CreateEmployeeDepartmentsInputType = {
    activeDepartmentIds?: Maybe<Array<Scalars['Int']>>
    passiveDepartmentIds?: Maybe<Array<Scalars['Int']>>
}

export type CreateEmployeeFieldsInputType = {
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    email?: Maybe<Scalars['String']>
    role?: Maybe<EmployeeRole>
    permissions?: Maybe<Array<EmployeePermissionEnum>>
    departments?: Maybe<CreateEmployeeDepartmentsInputType>
    ssoLoginEnabled?: Maybe<Scalars['Boolean']>
    isCustomerConsultant?: Maybe<Scalars['Boolean']>
    language?: Maybe<Language>
}

export type CreateMonitoringReportFieldsInputType = {
    name?: Maybe<Scalars['String']>
    file?: Maybe<Scalars['Upload']>
    assessedAt?: Maybe<Scalars['Date']>
    checkedDepartmentTopics?: Maybe<Array<Maybe<CheckedDepartmentTopicsInputType>>>
}

export type CreateTaskFieldsInputType = {
    linkedItemId?: Maybe<Scalars['Int']>
    linkedItemType?: Maybe<LinkedTaskItem>
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    dueAt?: Maybe<Scalars['Date']>
    startAt?: Maybe<Scalars['Date']>
    employeeIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    tags?: Maybe<Array<Maybe<NewOrExistingTaskTagInputType>>>
    priority?: Maybe<TaskPriority>
    forMonitoring?: Maybe<Scalars['Boolean']>
    repeats?: Maybe<TaskRepeatFrequency>
    isReviewTask?: Maybe<Scalars['Boolean']>
    taskIdsToReview?: Maybe<Array<Maybe<Scalars['Int']>>>
    alertIdsWithDepartmentIdsToReview?: Maybe<Array<AlertIdWithDepartmentIdInputType>>
    parentId?: Maybe<Scalars['Int']>
    taskTemplateId?: Maybe<Scalars['Int']>
    consultantTaskTemplateId?: Maybe<Scalars['Int']>
    consultantTaskTemplateCustomerFrameworkId?: Maybe<Scalars['Int']>
    subTasks?: Maybe<Array<Maybe<SubTaskFieldsInputType>>>
    linkedItemDepartmentId?: Maybe<Scalars['Int']>
}

export type CreateTaskTemplateFieldsInputType = {
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    parentId?: Maybe<Scalars['Int']>
    tags?: Maybe<Array<Maybe<NewOrExistingTaskTagInputType>>>
}

export type CreateTopicNoteFieldsType = {
    title?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    documents?: Maybe<Array<Maybe<Scalars['Upload']>>>
}

export type CustomerAlertSummaryPerMonthType = {
    __typename?: 'CustomerAlertSummaryPerMonthType'
    id: Scalars['ID']
    alertCount: Scalars['Int']
    month: Scalars['Int']
    year: Scalars['Int']
}

export type CustomerComplianceSettingsType = {
    __typename?: 'CustomerComplianceSettingsType'
    riskGraph?: Maybe<CustomerRiskGraphSettingsType>
}

export type CustomerEnabledNewsSourcesExportType = {
    __typename?: 'CustomerEnabledNewsSourcesExportType'
    token: Scalars['String']
    filename: Scalars['String']
}

export type CustomerEnabledNewsSourceType = {
    __typename?: 'CustomerEnabledNewsSourceType'
    id: Scalars['Int']
    customerId: Scalars['Int']
    newsSourceIds: Array<Maybe<Scalars['Int']>>
    showFrom?: Maybe<Scalars['DateTime']>
}

export type CustomerFiltersType = {
    name?: Maybe<Scalars['String']>
    linkedConsultantIds?: Maybe<Array<Scalars['Int']>>
    excludedLinkedConsultantIds?: Maybe<Array<Scalars['Int']>>
}

export enum CustomerFrameworkColorEnumType {
    black = 'BLACK',
    darkBlue = 'DARK_BLUE',
    blue = 'BLUE',
    lightBlue = 'LIGHT_BLUE',
    green = 'GREEN',
    yellow = 'YELLOW',
    orange = 'ORANGE',
    red = 'RED',
    pink = 'PINK',
    purple = 'PURPLE',
}

export type CustomerFrameworkFieldsInputType = {
    name?: Maybe<Scalars['String']>
    icon?: Maybe<CustomerFrameworkIconEnumType>
    color?: Maybe<CustomerFrameworkColorEnumType>
}

export type CustomerFrameworkFrameworkThemeType = {
    __typename?: 'CustomerFrameworkFrameworkThemeType'
    id: Scalars['String']
    idn: Scalars['Int']
    name: Scalars['String']
    topics?: Maybe<Array<Maybe<CustomerFrameworkFrameworkTopicType>>>
}

export type CustomerFrameworkFrameworkTopicType = {
    __typename?: 'CustomerFrameworkFrameworkTopicType'
    id: Scalars['String']
    idn: Scalars['Int']
    name?: Maybe<Scalars['String']>
    isChecked: Scalars['Boolean']
    isNew?: Maybe<Scalars['Boolean']>
    addedAt?: Maybe<Scalars['DateTime']>
}

export type CustomerFrameworkFrameworkType = {
    __typename?: 'CustomerFrameworkFrameworkType'
    id: Scalars['Int']
    name: Scalars['String']
    abbreviation: Scalars['String']
    curatedBy: LegalFrameworkCuratedByEnum
    themes?: Maybe<Array<CustomerFrameworkFrameworkThemeType>>
}

export enum CustomerFrameworkIconEnumType {
    algemeen1 = 'Algemeen1',
    algemeen10 = 'Algemeen10',
    algemeen11 = 'Algemeen11',
    algemeen12 = 'Algemeen12',
    algemeen13 = 'Algemeen13',
    algemeen2 = 'Algemeen2',
    algemeen3 = 'Algemeen3',
    algemeen4 = 'Algemeen4',
    algemeen5 = 'Algemeen5',
    algemeen6 = 'Algemeen6',
    algemeen7 = 'Algemeen7',
    algemeen8 = 'Algemeen8',
    algemeen9 = 'Algemeen9',
    bankbeleggingsonderneming = 'Bankbeleggingsonderneming',
    beheerIcbe = 'BeheerICBE',
    beheerderAifm = 'BeheerderAIFM',
    beleggingsondernemingAdvies = 'BeleggingsondernemingAdvies',
    beleggingsondernemingBroker = 'BeleggingsondernemingBroker',
    betaalDiensVerlener = 'BetaalDiensVerlener',
    dealer = 'Dealer',
    financieelDienstverlener = 'FinancieelDienstverlener',
    financieelRiskManagement = 'FinancieelRiskManagement',
    levensverzekeraar = 'Levensverzekeraar',
    pensioenbewaarder = 'Pensioenbewaarder',
    pensioenfonds = 'Pensioenfonds',
    premiePensioenInstelling = 'PremiePensioenInstelling',
    schadeverzekeraar = 'Schadeverzekeraar',
    trustkantoor = 'Trustkantoor',
    uitgevendeInstelling = 'UitgevendeInstelling',
    vermogensbeheerder = 'Vermogensbeheerder',
    wisselinstelling = 'Wisselinstelling',
}

export type CustomerFrameworkInputType = {
    departmentId: Scalars['Int']
    customerFrameworks: Array<CustomerFrameworkTopicsField>
}

export type CustomerFrameworkTopicsField = {
    customerFrameworkId: Scalars['Int']
    topicIds?: Maybe<Array<Scalars['Int']>>
}

export type CustomerFrameworkType = {
    __typename?: 'CustomerFrameworkType'
    id: Scalars['Int']
    name: Scalars['String']
    icon: CustomerFrameworkIconEnumType
    iconUrl?: Maybe<Scalars['String']>
    hexColor?: Maybe<Scalars['String']>
    color: CustomerFrameworkColorEnumType
    topics?: Maybe<Array<Maybe<TopicType>>>
    framework: CustomerFrameworkFrameworkType
    departmentFrameworks?: Maybe<Array<Maybe<DepartmentFrameworkType>>>
}

export type CustomerFrameworkTypeIconUrlArgs = {
    density?: Maybe<Scalars['String']>
}

export type CustomerFrameworkTypeDepartmentFrameworksArgs = {
    customerSlug?: Maybe<Scalars['String']>
}

export type CustomerGroupInputFieldsType = {
    name?: Maybe<Scalars['String']>
    slug?: Maybe<Scalars['String']>
    ssoLoginEnabled?: Maybe<Scalars['Boolean']>
    createUserOnSSOLogin?: Maybe<Scalars['Boolean']>
    ssoLoginURL?: Maybe<Scalars['String']>
    ssoLogoutURL?: Maybe<Scalars['String']>
    ssoCertificate?: Maybe<Scalars['String']>
}

export type CustomerGroupType = {
    __typename?: 'CustomerGroupType'
    id: Scalars['Int']
    name: Scalars['String']
    slug: Scalars['String']
    createUserOnSSOLogin?: Maybe<Scalars['Boolean']>
    ssoLoginEnabled: Scalars['Boolean']
    ssoLoginURL?: Maybe<Scalars['String']>
    ssoLogoutURL?: Maybe<Scalars['String']>
    ssoCertificate?: Maybe<Scalars['String']>
    customers?: Maybe<Array<CustomerType>>
    totalUserCount: Scalars['Int']
}

export type CustomerGroupTypeCustomersArgs = {
    search?: Maybe<Scalars['String']>
}

export type CustomerInputFieldsType = {
    name?: Maybe<Scalars['String']>
    address?: Maybe<AddressInputType>
    contactName?: Maybe<Scalars['String']>
    contactEmail?: Maybe<Scalars['String']>
    consultantContactId?: Maybe<Scalars['Int']>
    availableSeats?: Maybe<Scalars['Int']>
    logo?: Maybe<Scalars['Upload']>
    ssoLoginEnabled?: Maybe<Scalars['Boolean']>
    createUserOnSSOLogin?: Maybe<Scalars['Boolean']>
    editorialAccess?: Maybe<Scalars['Boolean']>
    customerGroupId?: Maybe<Scalars['Int']>
}

export type CustomerRiskGraphSettingsType = {
    __typename?: 'CustomerRiskGraphSettingsType'
    lowImpactDescription?: Maybe<Scalars['String']>
    highImpactDescription?: Maybe<Scalars['String']>
    lowPropbabiltyDescription?: Maybe<Scalars['String']>
    highPropbabiltyDescription?: Maybe<Scalars['String']>
}

export type CustomerSamlConfigurationFields = {
    ssoLoginURL?: Maybe<Scalars['String']>
    ssoLogoutURL?: Maybe<Scalars['String']>
    ssoCertificate?: Maybe<Scalars['String']>
}

export type CustomerSettingsInputFieldsType = {
    lowImpactDescription?: Maybe<Scalars['String']>
    highImpactDescription?: Maybe<Scalars['String']>
    lowPropbabiltyDescription?: Maybe<Scalars['String']>
    highPropbabiltyDescription?: Maybe<Scalars['String']>
}

export type CustomerSettingsType = {
    __typename?: 'CustomerSettingsType'
    compliance?: Maybe<CustomerComplianceSettingsType>
}

export type CustomersExportType = {
    __typename?: 'CustomersExportType'
    token: Scalars['String']
    filename: Scalars['String']
}

export type CustomerSortType = {
    lastConsultantLogin: Scalars['Int']
}

export type CustomerSubscribedToFeedExport = {
    __typename?: 'CustomerSubscribedToFeedExport'
    id: Scalars['Int']
    file: FileType
    rowCount: Scalars['Int']
    startDate?: Maybe<Scalars['DateTime']>
    endDate: Scalars['DateTime']
    subscription: CustomerSubscribedToFeedType
    recipientEmails?: Maybe<Array<Scalars['String']>>
    settings?: Maybe<Scalars['String']>
}

export type CustomerSubscribedToFeedType = {
    __typename?: 'CustomerSubscribedToFeedType'
    id: Scalars['Int']
    isActive: Scalars['Boolean']
    customer: CustomerType
    type: CustomerSubscribedToFeedTypeTypesEnumType
    settings?: Maybe<Scalars['String']>
    frequency: Scalars['Int']
    frequencyType: CustomerSubscribedToFeedTypeFrequencyTypeEnumType
    startDate: Scalars['DateTime']
    recipientEmails: Array<Scalars['String']>
    exports?: Maybe<PaginatedCustomerSubscribedToFeedExport>
}

export type CustomerSubscribedToFeedTypeExportsArgs = {
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export enum CustomerSubscribedToFeedTypeFrequencyTypeEnumType {
    days = 'days',
    weeks = 'weeks',
    months = 'months',
}

export enum CustomerSubscribedToFeedTypeTypesEnumType {
    aegonLawChanges = 'AegonLawChanges',
    aegonSignaling = 'AegonSignaling',
    apgRadarAlerts = 'APGRadarAlerts',
    apgAllAlerts = 'APGAllAlerts',
}

export type CustomerTaskTemplateType = TaskTemplateType | ConsultantTaskTemplateType

export type CustomerType = {
    __typename?: 'CustomerType'
    id: Scalars['Int']
    name: Scalars['String']
    slug: Scalars['String']
    availableSeats: Scalars['Int']
    contactName?: Maybe<Scalars['String']>
    contactEmail?: Maybe<Scalars['String']>
    settings?: Maybe<CustomerSettingsType>
    deletedAt?: Maybe<Scalars['DateTime']>
    frameworks: Array<FrameworkType>
    flowAIChat: Scalars['Boolean']
    editorialAccess: Scalars['Boolean']
    defaultDepartment?: Maybe<DepartmentType>
    createUserOnSSOLogin?: Maybe<Scalars['Boolean']>
    logo?: Maybe<Scalars['String']>
    filledSeats?: Maybe<Scalars['Int']>
    address?: Maybe<AddressType>
    consultantContact?: Maybe<ConsultantType>
    departments?: Maybe<Array<Maybe<DepartmentType>>>
    customerSubscribedToFeeds?: Maybe<Array<Maybe<CustomerSubscribedToFeedType>>>
    customerSubscribedToFeedsExports: PaginatedCustomerSubscribedToFeedExport
    ssoLoginEnabled: Scalars['Boolean']
    ssoLoginURL?: Maybe<Scalars['String']>
    ssoLogoutURL?: Maybe<Scalars['String']>
    ssoCertificate?: Maybe<Scalars['String']>
    customerGroup?: Maybe<CustomerGroupType>
    isConsultantTaskTemplatesHidden: Scalars['Boolean']
}

export type CustomerTypeCustomerSubscribedToFeedsExportsArgs = {
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type CustomerUsersExportType = {
    __typename?: 'CustomerUsersExportType'
    token: Scalars['String']
    filename: Scalars['String']
}

export type DashboardConsultationDetailFieldsType = {
    __typename?: 'DashboardConsultationDetailFieldsType'
    department: DepartmentType
    content: DashboardConsultationWidgetType
}

export type DashboardConsultationDetailType = {
    __typename?: 'DashboardConsultationDetailType'
    id: Scalars['Int']
    contents?: Maybe<Array<DashboardConsultationDetailFieldsType>>
}

export type DashboardConsultationWidgetContentItem = {
    __typename?: 'DashboardConsultationWidgetContentItem'
    id: Scalars['Int']
    name: Scalars['String']
    source: Scalars['String']
}

export type DashboardConsultationWidgetType = {
    __typename?: 'DashboardConsultationWidgetType'
    consultations?: Maybe<Array<DashboardConsultationWidgetContentItem>>
}

export type DashboardControlDetailFieldsType = {
    __typename?: 'DashboardControlDetailFieldsType'
    department: DepartmentType
    content: DashboardControlWidgetType
}

export type DashboardControlDetailType = {
    __typename?: 'DashboardControlDetailType'
    id: Scalars['Int']
    contents?: Maybe<Array<DashboardControlDetailFieldsType>>
}

export type DashboardControlWidgetType = {
    __typename?: 'DashboardControlWidgetType'
    oldCount: Scalars['Int']
    activeCount: Scalars['Int']
}

export type DashboardCustomerSpecificWidgetMetadataType = {
    __typename?: 'DashboardCustomerSpecificWidgetMetadataType'
    name?: Maybe<Scalars['String']>
}

export type DashboardDepartmentSpecificWidgetMetadataType = {
    __typename?: 'DashboardDepartmentSpecificWidgetMetadataType'
    name?: Maybe<Scalars['String']>
    departments?: Maybe<Array<DepartmentType>>
    allDepartments: Scalars['Boolean']
}

export type DashboardDetailContentType =
    | DashboardTasksDetailType
    | DashboardRadarDetailType
    | DashboardInboxDetailType
    | DashboardControlDetailType
    | DashboardTaskControlsDetailType
    | DashboardRisksDetailType
    | DashboardTopicDetailType
    | DashboardConsultationDetailType
    | DashboardInboxItemImpactDetailType

export type DashboardInboxDetailFieldsType = {
    __typename?: 'DashboardInboxDetailFieldsType'
    department: DepartmentType
    content: DashboardInboxWidgetType
}

export type DashboardInboxDetailType = {
    __typename?: 'DashboardInboxDetailType'
    id: Scalars['Int']
    contents?: Maybe<Array<DashboardInboxDetailFieldsType>>
}

export type DashboardInboxItemImpactDetailFieldsType = {
    __typename?: 'DashboardInboxItemImpactDetailFieldsType'
    department: DepartmentType
    content: DashboardInboxItemImpactWidgetType
}

export type DashboardInboxItemImpactDetailType = {
    __typename?: 'DashboardInboxItemImpactDetailType'
    id: Scalars['Int']
    contents?: Maybe<Array<DashboardInboxItemImpactDetailFieldsType>>
}

export type DashboardInboxItemImpactWidgetType = {
    __typename?: 'DashboardInboxItemImpactWidgetType'
    noImpactCount: Scalars['Int']
    lowImpactCount: Scalars['Int']
    mediumImpactCount: Scalars['Int']
    highImpactCount: Scalars['Int']
}

export type DashboardInboxWidgetType = {
    __typename?: 'DashboardInboxWidgetType'
    lastWeekCount: Scalars['Int']
    totalCount: Scalars['Int']
}

export type DashboardMonitoringReportWidgetContentItem = {
    __typename?: 'DashboardMonitoringReportWidgetContentItem'
    id: Scalars['Int']
    name: Scalars['String']
    createdAt: Scalars['DateTime']
    overdueTaskCount: Scalars['Int']
}

export type DashboardMonitoringReportWidgetType = {
    __typename?: 'DashboardMonitoringReportWidgetType'
    monitoringReports?: Maybe<Array<DashboardMonitoringReportWidgetContentItem>>
}

export type DashboardRadarDetailFieldsType = {
    __typename?: 'DashboardRadarDetailFieldsType'
    department: DepartmentType
    content: DashboardRadarWidgetType
}

export type DashboardRadarDetailType = {
    __typename?: 'DashboardRadarDetailType'
    id: Scalars['Int']
    contents?: Maybe<Array<DashboardRadarDetailFieldsType>>
}

export type DashboardRadarWidgetContentItem = {
    __typename?: 'DashboardRadarWidgetContentItem'
    id: Scalars['Int']
    name: Scalars['String']
    expectedIn: Scalars['Int']
}

export type DashboardRadarWidgetType = {
    __typename?: 'DashboardRadarWidgetType'
    radarItems?: Maybe<Array<DashboardRadarWidgetContentItem>>
}

export type DashboardRisksDetailFieldsType = {
    __typename?: 'DashboardRisksDetailFieldsType'
    department: DepartmentType
    content: DashboardRisksWidgetType
}

export type DashboardRisksDetailType = {
    __typename?: 'DashboardRisksDetailType'
    id: Scalars['Int']
    contents?: Maybe<Array<DashboardRisksDetailFieldsType>>
}

export type DashboardRisksWidgetType = {
    __typename?: 'DashboardRisksWidgetType'
    highCount: Scalars['Int']
    mediumCount: Scalars['Int']
    lowCount: Scalars['Int']
}

export type DashboardSignalingWidgetType = {
    __typename?: 'DashboardSignalingWidgetType'
    openCount: Scalars['Int']
    sentCount: Scalars['Int']
    totalCount: Scalars['Int']
}

export type DashboardTaskControlsDetailFieldsType = {
    __typename?: 'DashboardTaskControlsDetailFieldsType'
    department: DepartmentType
    content: DashboardTaskControlsWidgetType
}

export type DashboardTaskControlsDetailType = {
    __typename?: 'DashboardTaskControlsDetailType'
    id: Scalars['Int']
    contents?: Maybe<Array<DashboardTaskControlsDetailFieldsType>>
}

export type DashboardTaskControlsWidgetType = {
    __typename?: 'DashboardTaskControlsWidgetType'
    lastWeekCount: Scalars['Int']
    totalCount: Scalars['Int']
}

export type DashboardTasksDetailFieldsType = {
    __typename?: 'DashboardTasksDetailFieldsType'
    department: DepartmentType
    content: DashboardTasksWidgetType
}

export type DashboardTasksDetailType = {
    __typename?: 'DashboardTasksDetailType'
    id: Scalars['Int']
    contents?: Maybe<Array<DashboardTasksDetailFieldsType>>
}

export type DashboardTasksWidgetCountType = {
    __typename?: 'DashboardTasksWidgetCountType'
    total: Scalars['Int']
    highPrio: Scalars['Int']
    overdue: Scalars['Int']
}

export type DashboardTasksWidgetType = {
    __typename?: 'DashboardTasksWidgetType'
    inboxCount: DashboardTasksWidgetCountType
    controlCount: DashboardTasksWidgetCountType
    topicCount: DashboardTasksWidgetCountType
    genericCount: DashboardTasksWidgetCountType
    otherCount: DashboardTasksWidgetCountType
}

export type DashboardTopicDetailFieldsType = {
    __typename?: 'DashboardTopicDetailFieldsType'
    department: DepartmentType
    content: DashboardTopicWidgetType
}

export type DashboardTopicDetailType = {
    __typename?: 'DashboardTopicDetailType'
    id: Scalars['Int']
    contents?: Maybe<Array<DashboardTopicDetailFieldsType>>
}

export type DashboardTopicWidgetType = {
    __typename?: 'DashboardTopicWidgetType'
    compliantCount: Scalars['Int']
    almostCompliantCount: Scalars['Int']
    notCompliantCount: Scalars['Int']
    notAssessedCount: Scalars['Int']
}

export type DashboardWidgetContentType =
    | DashboardRadarWidgetType
    | DashboardSignalingWidgetType
    | DashboardInboxWidgetType
    | DashboardTasksWidgetType
    | DashboardControlWidgetType
    | DashboardTaskControlsWidgetType
    | DashboardRisksWidgetType
    | DashboardTopicWidgetType
    | DashboardConsultationWidgetType
    | DashboardMonitoringReportWidgetType
    | DashboardInboxItemImpactWidgetType

export enum DashboardWidgetEnum {
    signaling = 'signaling',
    inbox = 'inbox',
    tasks = 'tasks',
    controls = 'controls',
    controlTasks = 'controlTasks',
    risk = 'risk',
    topic = 'topic',
    topicEffectiveness = 'topicEffectiveness',
    radar = 'radar',
    export = 'export',
    consultations = 'consultations',
    monitoringReport = 'monitoringReport',
    inboxItemImpact = 'inboxItemImpact',
}

export type DashboardWidgetLayoutType = {
    __typename?: 'DashboardWidgetLayoutType'
    id: Scalars['Int']
    x: Scalars['Int']
    y: Scalars['Int']
    employee: EmployeeType
    metadata: DashboardWidgetMetadataType
    widgetType: DashboardWidgetEnum
    layoutType: DashboardWidgetLayoutTypeEnum
    content?: Maybe<DashboardWidgetContentType>
}

export enum DashboardWidgetLayoutTypeEnum {
    onecolumn = 'ONECOLUMN',
    twocolumn = 'TWOCOLUMN',
    threecolumn = 'THREECOLUMN',
}

export type DashboardWidgetMetadataType =
    | DashboardCustomerSpecificWidgetMetadataType
    | DashboardDepartmentSpecificWidgetMetadataType

export type DepartmentAlertDataType = {
    __typename?: 'DepartmentAlertDataType'
    id: Scalars['String']
    alert: AlertType
    department: DepartmentType
    impactLevel?: Maybe<AlertImpactLevelType>
    inboxStatus?: Maybe<InboxStatusType>
    hasLinkedTask: Scalars['Boolean']
    openTaskCount: Scalars['Int']
    completedTaskCount: Scalars['Int']
    overdueTaskCount: Scalars['Int']
}

export type DepartmentCustomerFrameworkFrameworkThemeType = {
    __typename?: 'DepartmentCustomerFrameworkFrameworkThemeType'
    id: Scalars['String']
    idn: Scalars['Int']
    name: Scalars['String']
    topics?: Maybe<Array<Maybe<CustomerFrameworkFrameworkTopicType>>>
}

export type DepartmentCustomerFrameworkFrameworkType = {
    __typename?: 'DepartmentCustomerFrameworkFrameworkType'
    id: Scalars['String']
    idn: Scalars['Int']
    name: Scalars['String']
    abbreviation: Scalars['String']
    curatedBy: LegalFrameworkCuratedByEnum
    themes?: Maybe<Array<DepartmentCustomerFrameworkFrameworkThemeType>>
}

export type DepartmentCustomerFrameworkType = {
    __typename?: 'DepartmentCustomerFrameworkType'
    id: Scalars['String']
    idn: Scalars['Int']
    name: Scalars['String']
    icon: CustomerFrameworkIconEnumType
    color: CustomerFrameworkColorEnumType
    framework: DepartmentCustomerFrameworkFrameworkType
    iconUrl?: Maybe<Scalars['String']>
    hexColor?: Maybe<Scalars['String']>
}

export type DepartmentCustomerFrameworkTypeIconUrlArgs = {
    density?: Maybe<Scalars['String']>
}

export type DepartmentEmployeesFieldInputType = {
    activeEmployeeIds?: Maybe<Array<Scalars['Int']>>
    passiveEmployeeIds?: Maybe<Array<Scalars['Int']>>
}

export type DepartmentFilters = {
    search?: Maybe<Scalars['String']>
    customerFrameworkIds?: Maybe<Array<Scalars['Int']>>
    includeIfHasExternalTopic?: Maybe<Scalars['Boolean']>
}

export type DepartmentFrameworkType = {
    __typename?: 'DepartmentFrameworkType'
    id: Scalars['Int']
    topics: Array<TopicType>
    topicCount: Scalars['Int']
    department: DepartmentType
    customerFramework: DepartmentCustomerFrameworkType
}

export type DepartmentThemeTopicType = {
    __typename?: 'DepartmentThemeTopicType'
    id: Scalars['Int']
    /** Translated name with fallback to the original name */
    name: Scalars['String']
}

export type DepartmentThemeWithTopicsType = {
    __typename?: 'DepartmentThemeWithTopicsType'
    id: Scalars['Int']
    /** Translated name with fallback to the original name */
    name: Scalars['String']
    topics?: Maybe<Array<DepartmentThemeTopicType>>
}

export type DepartmentTopicAssessmentCountsType = {
    __typename?: 'DepartmentTopicAssessmentCountsType'
    assessmentSatisfies: Scalars['Int']
    assessmentAlmostSatisfies: Scalars['Int']
    assessmentNotSatisfies: Scalars['Int']
    assessmentNotGiven: Scalars['Int']
}

export enum DepartmentTopicDataGradeTypeEnum {
    assessmentSatisfies = 'assessmentSatisfies',
    assessmentSatisfiesPartly = 'assessmentSatisfiesPartly',
    assessmentAlmostSatisfies = 'assessmentAlmostSatisfies',
    assessmentAlmostSatisfiesPartly = 'assessmentAlmostSatisfiesPartly',
    assessmentNotSatisfies = 'assessmentNotSatisfies',
    assessmentNotSatisfiesPartly = 'assessmentNotSatisfiesPartly',
    assessmentNotGiven = 'assessmentNotGiven',
    notApplicable = 'notApplicable',
}

export type DepartmentTopicDataMetadataType = {
    __typename?: 'DepartmentTopicDataMetadataType'
    grade: DepartmentTopicDataGradeTypeEnum
    linkedControlMeasureCount: Scalars['Int']
    linkedNoteCount: Scalars['Int']
    lastUpdatedAt: Scalars['DateTime']
    assessmentCounts: DepartmentTopicAssessmentCountsType
}

export type DepartmentTopicDataType = {
    __typename?: 'DepartmentTopicDataType'
    id: Scalars['Int']
    topic: TopicType
    department: DepartmentType
    topicAssessmentDesignMetadata?: Maybe<DepartmentTopicDataMetadataType>
    topicAssessmentEffectivenessMetadata?: Maybe<DepartmentTopicDataMetadataType>
}

export type DepartmentType = {
    __typename?: 'DepartmentType'
    id: Scalars['Int']
    name: Scalars['String']
    receiveAlertsForConsultationsEnabled: Scalars['Boolean']
    amountOfEmployees: Scalars['Int']
    employees: Array<EmployeeType>
    departmentFrameworks: Array<DepartmentFrameworkType>
    /** shorthand for departmentFrameworks.topics grouped under their themes with only the names and ids */
    themesWithTopics?: Maybe<Array<DepartmentThemeWithTopicsType>>
    passiveEmployees: Array<EmployeeType>
}

export type DeviceInputType = {
    token: Scalars['String']
    deviceName?: Maybe<Scalars['String']>
    os?: Maybe<Scalars['String']>
    osVersion?: Maybe<Scalars['String']>
    type?: Maybe<Scalars['String']>
}

export type DeviceType = {
    __typename?: 'DeviceType'
    id?: Maybe<Scalars['Int']>
    token?: Maybe<Scalars['String']>
    deviceName?: Maybe<Scalars['String']>
    os?: Maybe<Scalars['String']>
    osVersion?: Maybe<Scalars['String']>
    type?: Maybe<Scalars['String']>
}

export enum DigestFrequencyEnumType {
    daily = 'DAILY',
    weekly = 'WEEKLY',
    never = 'NEVER',
}

export type EditAssessmentFieldsType = {
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    useDistinctBrutoRiskValues?: Maybe<Scalars['Boolean']>
}

export type EditAssessmentTopicsFieldsType = {
    changedTopics?: Maybe<Array<Maybe<ChangedTopicType>>>
    removeTopicIds?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export type EditConsultantFieldsType = {
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    email?: Maybe<Scalars['String']>
    phoneNumber?: Maybe<Scalars['String']>
    avatar?: Maybe<Scalars['Upload']>
}

export type EditConsultantTaskTemplateFieldsInputType = {
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    type?: Maybe<ConsultantTaskTemplateTypeEnum>
    frameworkIds?: Maybe<Array<Scalars['Int']>>
}

export type EditControlDocumentFields = {
    file?: Maybe<Scalars['Upload']>
}

export type EditControlFieldsType = {
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    type?: Maybe<ControlTypeType>
    visibility?: Maybe<ControlVisibility>
    visibleToDepartmentIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    startDate?: Maybe<Scalars['DateTime']>
    revisionDate?: Maybe<Scalars['DateTime']>
    responsibleEmployeeIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    responsibleShadowEmployeeIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    responsibleEmployees?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type EditCustomerFrameworkTopicsField = {
    customerFrameworkId: Scalars['Int']
    themeIds?: Maybe<Array<Scalars['Int']>>
    topicIds?: Maybe<Array<Scalars['Int']>>
}

export type EditDashboardLayoutInputType = {
    id: Scalars['Int']
    x: Scalars['Int']
    y: Scalars['Int']
    layoutType: DashboardWidgetLayoutTypeEnum
    metadata?: Maybe<WidgetMetadataInputType>
}

export type EditEmployeeFieldsInputType = {
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    phoneNumber?: Maybe<Scalars['String']>
    email?: Maybe<Scalars['String']>
    role?: Maybe<EmployeeRole>
    permissions?: Maybe<Array<EmployeePermissionEnum>>
    operations?: Maybe<Array<EmployeeOperationEnum>>
    newTaskNotificationEmail?: Maybe<Scalars['String']>
    alertDigestFrequency?: Maybe<DigestFrequencyEnumType>
    upcomingTasksDigestFrequency?: Maybe<DigestFrequencyEnumType>
    myCompletedTasksDigestFrequency?: Maybe<DigestFrequencyEnumType>
    standardTasksDigestFrequency?: Maybe<DigestFrequencyEnumType>
    ssoLoginEnabled?: Maybe<Scalars['Boolean']>
    language?: Maybe<Language>
    applyToAllUsers?: Maybe<Scalars['Boolean']>
}

export type EditMonitoringReportFieldsInputType = {
    name?: Maybe<Scalars['String']>
    file?: Maybe<Scalars['Upload']>
}

export type EditRiskFieldsInputType = {
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    riskTypes?: Maybe<Array<RiskTypesInputType>>
    brutoImpact?: Maybe<Scalars['Int']>
    brutoProbability?: Maybe<Scalars['Int']>
    nettoImpact?: Maybe<Scalars['Int']>
    nettoProbability?: Maybe<Scalars['Int']>
    isAccepted?: Maybe<Scalars['Boolean']>
    linkedControls?: Maybe<Array<LinkedControlsInputType>>
}

export type EditTaskFieldsInputType = {
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    dueAt?: Maybe<Scalars['DateTime']>
    startAt?: Maybe<Scalars['DateTime']>
    employeeIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    tags?: Maybe<Array<Maybe<NewOrExistingTaskTagInputType>>>
    priority?: Maybe<TaskPriority>
    forMonitoring?: Maybe<Scalars['Boolean']>
    repeats?: Maybe<TaskRepeatFrequency>
}

export type EditTaskTemplateFieldsInputType = {
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    tags?: Maybe<Array<Maybe<NewOrExistingTaskTagInputType>>>
}

export type EditTopicAssessmentFieldsType = {
    description?: Maybe<Scalars['String']>
    grade?: Maybe<TopicAssessmentGradeType>
    documents?: Maybe<Array<Maybe<Scalars['Upload']>>>
    topicAssessmentDocumentIdsToRemove?: Maybe<Array<Scalars['Int']>>
}

export type EditTopicNoteFieldsType = {
    title?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    documents?: Maybe<Array<Maybe<Scalars['Upload']>>>
    topicNoteDocumentIdsToRemove?: Maybe<Array<Scalars['Int']>>
}

export type EditWidgetMetadataInputType = {
    name?: Maybe<Scalars['String']>
    departmentIds?: Maybe<Array<Scalars['Int']>>
    allDepartments?: Maybe<Scalars['Boolean']>
}

export enum EmployeeOperationEnum {
    canOperateControls = 'CAN_OPERATE_CONTROLS',
    canOperateInternalAlerts = 'CAN_OPERATE_INTERNAL_ALERTS',
}

export enum EmployeePermissionEnum {
    canArchiveInboxItems = 'CAN_ARCHIVE_INBOX_ITEMS',
    canDeleteCompliance = 'CAN_DELETE_COMPLIANCE',
    canMonitorSignaling = 'CAN_MONITOR_SIGNALING',
    canSetControlOperations = 'CAN_SET_CONTROL_OPERATIONS',
    canAccessMonitoringControls = 'CAN_ACCESS_MONITORING_CONTROLS',
}

export enum EmployeePinEnumType {
    theme = 'THEME',
    topic = 'TOPIC',
    newsGroup = 'NEWS_GROUP',
    law = 'LAW',
}

export type EmployeePinnedType = {
    __typename?: 'EmployeePinnedType'
    themes?: Maybe<Array<ThemeType>>
    topics?: Maybe<Array<TopicType>>
    laws?: Maybe<Array<LawType>>
    newsGroups?: Maybe<Array<NewsGroupType>>
}

export type EmployeePinnedTypeThemesArgs = {
    customerFrameworkIds?: Maybe<Array<Scalars['Int']>>
    departmentId?: Maybe<Scalars['Int']>
    search?: Maybe<Scalars['String']>
}

export type EmployeePinnedTypeTopicsArgs = {
    themeIds?: Maybe<Array<Scalars['Int']>>
    customerFrameworkIds?: Maybe<Array<Scalars['Int']>>
    departmentId?: Maybe<Scalars['Int']>
    topicDnEFilters?: Maybe<TopicsForThemeFilters>
}

export enum EmployeeRole {
    customer = 'CUSTOMER',
    customerReader = 'CUSTOMER_READER',
    customerReaderWithCompliance = 'CUSTOMER_READER_WITH_COMPLIANCE',
    customerAdministrator = 'CUSTOMER_ADMINISTRATOR',
    customerPlanner = 'CUSTOMER_PLANNER',
}

export type EmployeeType = {
    __typename?: 'EmployeeType'
    id: Scalars['Int']
    newTaskNotificationEmail: Scalars['Boolean']
    alertDigestFrequency: DigestFrequencyEnumType
    upcomingTasksDigestFrequency: DigestFrequencyEnumType
    myCompletedTasksDigestFrequency: DigestFrequencyEnumType
    standardTasksDigestFrequency: DigestFrequencyEnumType
    role: EmployeeRole
    permissions?: Maybe<Array<EmployeePermissionEnum>>
    operations?: Maybe<Array<EmployeeOperationEnum>>
    departments: Array<DepartmentType>
    customer: CustomerType
    user: UserType
    userEmployees?: Maybe<Array<EmployeeType>>
    onboardedAt?: Maybe<Scalars['DateTime']>
    canEditPermissions: Scalars['Boolean']
}

export type EventLogType = {
    __typename?: 'EventLogType'
    id: Scalars['Int']
    type: Scalars['String']
    metaData?: Maybe<Scalars['JSON']>
    occuredOn: Scalars['DateTime']
    user?: Maybe<UserType>
}

export type FileType = {
    __typename?: 'FileType'
    id: Scalars['Int']
    name: Scalars['String']
    mimetype?: Maybe<Scalars['String']>
    path: Scalars['String']
}

export type ForLinkedItemFilterInputType = {
    itemId: Scalars['Int']
    itemType: LinkedTaskItem
}

export type FrameworkThemeType = {
    __typename?: 'FrameworkThemeType'
    id?: Maybe<Scalars['Int']>
    name?: Maybe<Scalars['String']>
    topics?: Maybe<Array<Maybe<FrameworkTopicType>>>
}

export type FrameworkThemeTypeTopicsArgs = {
    checkedForCustomerFrameworkId?: Maybe<Scalars['Int']>
    frameworkId: Scalars['Int']
}

export type FrameworkTopicType = {
    __typename?: 'FrameworkTopicType'
    id?: Maybe<Scalars['Int']>
    name?: Maybe<Scalars['String']>
    isChecked?: Maybe<Scalars['Boolean']>
}

export type FrameworkType = {
    __typename?: 'FrameworkType'
    id: Scalars['Int']
    name?: Maybe<Scalars['String']>
    abbreviation?: Maybe<Scalars['String']>
    curatedBy: LegalFrameworkCuratedByEnum
    themes?: Maybe<Array<Maybe<FrameworkThemeType>>>
}

export enum GeneratedSummaryAppliedParentTypeEnum {
    alert = 'ALERT',
}

export enum GeneratedSummaryParentTypeEnum {
    news = 'NEWS',
}

export enum GeneratedSummaryStatusEnum {
    generating = 'GENERATING',
    done = 'DONE',
    failed = 'FAILED',
}

export type GeneratedSummaryType = {
    __typename?: 'GeneratedSummaryType'
    id: Scalars['Int']
    status: GeneratedSummaryStatusEnum
    summary?: Maybe<Scalars['String']>
    userVote?: Maybe<GeneratedSummaryVoteType>
    isAppliedToEntity: Scalars['Boolean']
}

export type GeneratedSummaryTypeIsAppliedToEntityArgs = {
    parentId?: Maybe<Scalars['Int']>
    parentType?: Maybe<GeneratedSummaryAppliedParentTypeEnum>
}

export enum GeneratedSummaryUserVoteEnum {
    upvote = 'UPVOTE',
    downvote = 'DOWNVOTE',
}

export type GeneratedSummaryVoteType = {
    __typename?: 'GeneratedSummaryVoteType'
    id: Scalars['Int']
    changedVote: Scalars['Boolean']
    vote?: Maybe<GeneratedSummaryUserVoteEnum>
}

export type GenericCustomerType = {
    __typename?: 'GenericCustomerType'
    id: Scalars['Int']
    name: Scalars['String']
    slug: Scalars['String']
}

export type GenericEmployeeType = {
    __typename?: 'GenericEmployeeType'
    id: Scalars['Int']
    customer: GenericCustomerType
}

export type GroupedDepartmentAlertDataType = {
    __typename?: 'GroupedDepartmentAlertDataType'
    id: Scalars['Int']
    alert: AlertType
    departmentDatas?: Maybe<Array<DepartmentAlertDataType>>
}

export type GroupedItemType = {
    __typename?: 'GroupedItemType'
    type?: Maybe<Scalars['String']>
    entityType?: Maybe<Scalars['String']>
    items?: Maybe<Array<LinkedItemType>>
}

export type GroupFilters = {
    search?: Maybe<Scalars['String']>
    customerId?: Maybe<Scalars['Int']>
}

export type GroupSort = {
    createdAt?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
}

export type IdentifiedRegulationType = {
    __typename?: 'IdentifiedRegulationType'
    id?: Maybe<Scalars['Int']>
    parentId?: Maybe<Scalars['Int']>
    type?: Maybe<Scalars['String']>
}

export type ImpactedEntityType = TopicType

export type InboxExportType = {
    __typename?: 'InboxExportType'
    token: Scalars['String']
    filename: Scalars['String']
}

export type InboxStatusType = {
    __typename?: 'InboxStatusType'
    id?: Maybe<Scalars['Int']>
    archivedAt?: Maybe<Scalars['DateTime']>
    unarchivedAt?: Maybe<Scalars['DateTime']>
    archivedReason?: Maybe<Scalars['String']>
    archivedBy?: Maybe<EmployeeType>
    unarchivedBy?: Maybe<EmployeeType>
    ignoredAt?: Maybe<Scalars['DateTime']>
    ignoredReason?: Maybe<Scalars['String']>
    ignoredBy?: Maybe<EmployeeType>
}

export type InternalAlertFiltersForCustomerType = {
    status?: Maybe<InternalAlertStatusState>
    search?: Maybe<Scalars['String']>
    groupIds?: Maybe<Array<Scalars['Int']>>
    sourceIds?: Maybe<Array<Scalars['Int']>>
    includePending?: Maybe<Scalars['Boolean']>
}

export type InternalAlertFiltersType = {
    customerFrameworkIds?: Maybe<Array<Scalars['Int']>>
    status?: Maybe<InternalAlertStatusState>
    groupIds?: Maybe<Array<Scalars['Int']>>
    search?: Maybe<Scalars['String']>
}

export enum InternalAlertStatusState {
    new = 'NEW',
    accepted = 'ACCEPTED',
    archived = 'ARCHIVED',
    returned = 'RETURNED',
}

export type InternalAlertStatusType = {
    __typename?: 'InternalAlertStatusType'
    id: Scalars['Int']
    department: DepartmentType
    internalAlert: InternalAlertType
    returnedBy?: Maybe<EmployeeType>
    returnedAt?: Maybe<Scalars['DateTime']>
    returnNote?: Maybe<Scalars['String']>
    acceptedBy?: Maybe<EmployeeType>
    acceptedAt?: Maybe<Scalars['DateTime']>
    archivedReason?: Maybe<Scalars['String']>
    archivedAt?: Maybe<Scalars['DateTime']>
    archivedBy?: Maybe<EmployeeType>
    state: InternalAlertStatusState
}

export type InternalAlertType = {
    __typename?: 'InternalAlertType'
    id: Scalars['Int']
    note?: Maybe<Scalars['String']>
    customer: CustomerType
    topics: Array<TopicType>
    departments: Array<DepartmentType>
    news: NewsItemType
    statuses?: Maybe<Array<InternalAlertStatusType>>
    departmentStatus?: Maybe<InternalAlertStatusType>
    publishedBy?: Maybe<EmployeeType>
    publishedAt: Scalars['DateTime']
}

export type InternalAlertTypeDepartmentStatusArgs = {
    departmentId: Scalars['Int']
}

export enum ItemImpactLevel {
    low = 'LOW',
    average = 'AVERAGE',
    high = 'HIGH',
}

export type ItemImpactType = {
    __typename?: 'ItemImpactType'
    id: Scalars['Int']
    impact: ItemImpactLevel
    impactedEntity: ImpactedEntityType
}

export type ItemWithTaskFiltersInputType = {
    search?: Maybe<Scalars['String']>
    forEmployeeIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    linkedItemType?: Maybe<LinkedTaskItem>
    includingOverdueTasks?: Maybe<Scalars['Boolean']>
    dueDateFrom?: Maybe<Scalars['DateTime']>
    dueDateTo?: Maybe<Scalars['DateTime']>
    linkedItemInboxFromDate?: Maybe<Scalars['DateTime']>
    linkedItemInboxToDate?: Maybe<Scalars['DateTime']>
    departmentId?: Maybe<Scalars['Int']>
    showCompletedOnly?: Maybe<Scalars['Boolean']>
    tagIds?: Maybe<Array<Scalars['Int']>>
}

export type ItemWithTaskSortInputType = {
    openTasks?: Maybe<SortDirectionEnumType>
    firstDueAt?: Maybe<SortDirectionEnumType>
}

export type ItemWithTasksType = {
    __typename?: 'ItemWithTasksType'
    id?: Maybe<Scalars['String']>
    linkedItem?: Maybe<LinkedTaskItemType>
    open?: Maybe<Scalars['Int']>
    closed?: Maybe<Scalars['Int']>
    overDue?: Maybe<Scalars['Int']>
    firstDueAt?: Maybe<Scalars['DateTime']>
    employeeIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    employees?: Maybe<Array<Maybe<EmployeeType>>>
}

export enum Language {
    en = 'en',
    nl = 'nl',
}

export type LastAppUpdateSeenType = {
    __typename?: 'LastAppUpdateSeenType'
    date?: Maybe<Scalars['DateTime']>
    version?: Maybe<Scalars['String']>
    update?: Maybe<Scalars['Int']>
    skip?: Maybe<Scalars['Boolean']>
}

export enum LawArticleEnumType {
    nlLawArticle = 'NL_LAW_ARTICLE',
    nlLawAttachment = 'NL_LAW_ATTACHMENT',
    article = 'ARTICLE',
    attachment = 'ATTACHMENT',
}

export type LawArticleExpiredObjectType = {
    __typename?: 'LawArticleExpiredObjectType'
    expiredAt: Scalars['DateTime']
    willBeDeletedAt: Scalars['DateTime']
}

export type LawArticleSubparagraphType = {
    __typename?: 'LawArticleSubparagraphType'
    id: Scalars['Int']
    order: Scalars['Int']
    lawArticle: LawArticleType
    content?: Maybe<Scalars['String']>
}

export type LawArticleType = {
    __typename?: 'LawArticleType'
    id: Scalars['Int']
    title: Scalars['String']
    expired?: Maybe<LawArticleExpiredObjectType>
    topics: Array<TopicType>
    assessments: Array<AssessmentType>
    type: LawArticleEnumType
    hasStructuredContent: Scalars['Boolean']
    content?: Maybe<Scalars['String']>
    createdAt: Scalars['DateTime']
    updatedAt: Scalars['DateTime']
    abstractLawArticleId: Scalars['Int']
    abstractLawArticle: AbstractLawArticleType
    attachmentIsLeading?: Maybe<Scalars['Boolean']>
    sectionParents?: Maybe<Array<LawSectionParentType>>
    linkedItems?: Maybe<Array<GroupedItemType>>
    law?: Maybe<LawType>
    lawId?: Maybe<Scalars['Int']>
    lawGroupId: Scalars['Int']
    lawGroup: LawGroupType
    isSingleArticle?: Maybe<Scalars['Boolean']>
    attachments?: Maybe<Array<Maybe<AttachmentType>>>
    isOldRevision?: Maybe<Scalars['Boolean']>
    subparagraphs?: Maybe<Array<LawArticleSubparagraphType>>
}

export type LawArticleTypeExpiredArgs = {
    topicId: Scalars['Int']
}

export type LawArticleTypeTopicsArgs = {
    filters?: Maybe<TopicFilters>
    sort?: Maybe<TopicSort>
    customerSlug?: Maybe<Scalars['String']>
}

export type LawArticleTypeAssessmentsArgs = {
    topicId?: Maybe<Scalars['Int']>
    departmentId: Scalars['Int']
}

export type LawArticleTypeLinkedItemsArgs = {
    customerSlug?: Maybe<Scalars['String']>
    lawArticlesOnly?: Maybe<Scalars['Boolean']>
    filterDeletedArticles?: Maybe<Scalars['Boolean']>
}

export type LawFilters = {
    search?: Maybe<Scalars['String']>
    groupIds?: Maybe<Array<Scalars['Int']>>
}

export type LawGroupType = {
    __typename?: 'LawGroupType'
    id: Scalars['Int']
    name: Scalars['String']
    order: Scalars['Int']
}

export type LawSectionParentType = {
    __typename?: 'LawSectionParentType'
    id: Scalars['Int']
    parentId?: Maybe<Scalars['Int']>
    name: Scalars['String']
    depth: Scalars['Int']
}

export type LawSectionType = {
    __typename?: 'LawSectionType'
    id: Scalars['Int']
    name?: Maybe<Scalars['String']>
    articleId?: Maybe<Scalars['Int']>
    article?: Maybe<LawArticleType>
    parentId?: Maybe<Scalars['Int']>
    order?: Maybe<Scalars['Int']>
}

export type LawSort = {
    createdAt?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
}

export type LawSourceType = {
    __typename?: 'LawSourceType'
    id?: Maybe<Scalars['Int']>
    regulationId?: Maybe<Scalars['String']>
    entryIntoForce?: Maybe<Scalars['DateTime']>
    lastModified?: Maybe<Scalars['DateTime']>
    metaData?: Maybe<Scalars['JSON']>
    shortname?: Maybe<Scalars['String']>
}

export type LawType = {
    __typename?: 'LawType'
    id?: Maybe<Scalars['Int']>
    abstractLawId?: Maybe<Scalars['Int']>
    name?: Maybe<Scalars['String']>
    sections?: Maybe<Array<LawSectionType>>
    lawSource?: Maybe<LawSourceType>
    isPinned?: Maybe<Scalars['Boolean']>
}

export type LegacyRedirectType = {
    __typename?: 'LegacyRedirectType'
    type?: Maybe<Scalars['String']>
    id?: Maybe<Scalars['Int']>
}

export enum LegalFrameworkCuratedByEnum {
    internal = 'INTERNAL',
    external = 'EXTERNAL',
}

export type LinkedControlsInputType = {
    controlId?: Maybe<Scalars['Int']>
    description?: Maybe<Scalars['String']>
}

export type LinkedItemType = TopicType | RadarItemType | LawArticleType | ConsultationType | NewsItemType

export enum LinkedTaskItem {
    alert = 'ALERT',
    control = 'CONTROL',
    risk = 'RISK',
    assessment = 'ASSESSMENT',
    monitoringRapport = 'MONITORING_RAPPORT',
    topic = 'TOPIC',
    radar = 'RADAR',
    consultation = 'CONSULTATION',
}

export type LinkedTaskItemStatusType = {
    __typename?: 'linkedTaskItemStatusType'
    open?: Maybe<Scalars['Int']>
    closed?: Maybe<Scalars['Int']>
    overDue?: Maybe<Scalars['Int']>
    employees?: Maybe<Array<Maybe<EmployeeType>>>
}

export type LinkedTaskItemType =
    | AlertType
    | ControlType
    | RiskType
    | AssessmentType
    | MonitoringReportType
    | TopicType
    | RadarItemType
    | ConsultationType

export type MonitoringReportFilters = {
    name?: Maybe<Scalars['String']>
    topicId?: Maybe<Scalars['Int']>
    controlId?: Maybe<Scalars['Int']>
    archived?: Maybe<Scalars['Boolean']>
}

export type MonitoringReportSort = {
    name?: Maybe<SortDirectionEnumType>
    createdAt?: Maybe<SortDirectionEnumType>
    assessedAt?: Maybe<SortDirectionEnumType>
}

export type MonitoringReportTopicAssessmentDocumentType = {
    __typename?: 'MonitoringReportTopicAssessmentDocumentType'
    id: Scalars['Int']
    monitoringReportTopicAssessment: MonitoringReportTopicAssessmentType
    file?: Maybe<FileType>
    createdByEmployee?: Maybe<EmployeeType>
}

export enum MonitoringReportTopicAssessmentGrade {
    assessmentSatisfies = 'assessmentSatisfies',
    assessmentAlmostSatisfies = 'assessmentAlmostSatisfies',
    assessmentNotSatisfies = 'assessmentNotSatisfies',
}

export type MonitoringReportTopicAssessmentInputType = {
    documents?: Maybe<Array<Maybe<Scalars['Upload']>>>
    description?: Maybe<Scalars['String']>
    grade?: Maybe<MonitoringReportTopicAssessmentGrade>
    existingTopicAssessmentDocumentIds?: Maybe<Array<Scalars['Int']>>
}

export type MonitoringReportTopicAssessmentType = {
    __typename?: 'MonitoringReportTopicAssessmentType'
    id: Scalars['Int']
    updatedAt: Scalars['DateTime']
    monitoringReportTopic: MonitoringReportTopicType
    grade: MonitoringReportTopicAssessmentGrade
    initialGrade: TopicAssessmentGradeType
    description?: Maybe<Scalars['String']>
    documentsCount: Scalars['Int']
    documents?: Maybe<Array<MonitoringReportTopicAssessmentDocumentType>>
    assessmentType: TopicAssessmentDesignOrEffectiveNessType
    linkedItemId: Scalars['Int']
    topicAssessmentLinkedItemType: TopicAssessmentDeLinkedItemType
    linkedItem: TopicAssessmentLinkedItemType
}

export type MonitoringReportTopicType = {
    __typename?: 'MonitoringReportTopicType'
    id: Scalars['Int']
    department: DepartmentType
    topic: TopicType
    controlsAndNotes?: Maybe<Array<ControlOrNoteType>>
    departmentTopicData?: Maybe<DepartmentTopicDataType>
    assessments?: Maybe<Array<MonitoringReportTopicAssessmentType>>
    isRemoved: Scalars['Boolean']
    notApplicable: Scalars['Boolean']
    tasks?: Maybe<Array<TaskType>>
}

export type MonitoringReportType = {
    __typename?: 'MonitoringReportType'
    id: Scalars['Int']
    name: Scalars['String']
    createdAt: Scalars['DateTime']
    assessedAt: Scalars['DateTime']
    updatedAt: Scalars['DateTime']
    archivedAt?: Maybe<Scalars['DateTime']>
    unarchivedAt?: Maybe<Scalars['DateTime']>
    archivedReason?: Maybe<Scalars['String']>
    archivedBy?: Maybe<EmployeeType>
    unarchivedBy?: Maybe<EmployeeType>
    file?: Maybe<FileType>
    customer: CustomerType
    departments?: Maybe<Array<DepartmentType>>
    /** open task with the closest deadline */
    nextTask?: Maybe<TaskType>
    /** open task with the highest prio */
    highestPrioTask?: Maybe<TaskType>
    monitoringReportTopics?: Maybe<Array<MonitoringReportTopicType>>
}

export type Mutation = {
    __typename?: 'Mutation'
    loginWithCredentials?: Maybe<TokenResponseType>
    requestSAMLRedirectURL?: Maybe<SamlRedirectUrlResponseType>
    requestSAMLRedirectURLForEmail: SamlRedirectUrlResponseType
    logoutSession?: Maybe<Scalars['Boolean']>
    requestNewPasswordReset?: Maybe<Scalars['Boolean']>
    resetPassword?: Maybe<Scalars['Boolean']>
    registerUser?: Maybe<Scalars['Boolean']>
    registerProspect?: Maybe<TokenResponseType>
    confirmAccountEmail?: Maybe<Scalars['Boolean']>
    setUserMergeRequestResponse: Scalars['Boolean']
    refreshSession?: Maybe<Scalars['Boolean']>
    editProspectNewsGroups?: Maybe<ProspectType>
    completeEmployeeOnboarding?: Maybe<EmployeeType>
    createTasksExport?: Maybe<TaskExportType>
    switchEmployee?: Maybe<Scalars['Boolean']>
    createCustomerEnabledNewsSourcesExport?: Maybe<CustomerEnabledNewsSourcesExportType>
    addWidget: Array<DashboardWidgetLayoutType>
    deleteWidgets?: Maybe<Array<DashboardWidgetLayoutType>>
    editWidget: DashboardWidgetLayoutType
    editDashboardWidgets?: Maybe<Array<DashboardWidgetLayoutType>>
    setSeenAppVersion?: Maybe<UserType>
    setLastAppUpdateSeen?: Maybe<UserType>
    changePassword?: Maybe<Scalars['Boolean']>
    pinItem?: Maybe<Scalars['Boolean']>
    unpinItem?: Maybe<Scalars['Boolean']>
    markAlertAsRead?: Maybe<AlertType>
    requestFileDownloadToken?: Maybe<Scalars['String']>
    createEmployee?: Maybe<EmployeeType>
    editCustomerSettings?: Maybe<CustomerType>
    editEmployee?: Maybe<EmployeeType>
    deleteEmployee?: Maybe<Scalars['Boolean']>
    editCustomerFramework?: Maybe<CustomerFrameworkType>
    exportRadarItems?: Maybe<RadarItemsExportType>
    registerDevice?: Maybe<Scalars['Boolean']>
    unregisterDevice?: Maybe<Scalars['Boolean']>
    editCustomerEnabledNewsSource?: Maybe<CustomerEnabledNewsSourceType>
    createCustomerUsersExport?: Maybe<CustomerUsersExportType>
    castGeneratedSummaryVote: GeneratedSummaryVoteType
    createComplianceExport?: Maybe<ComplianceExportType>
    createInboxExport?: Maybe<InboxExportType>
    createControlExport?: Maybe<ControlExportType>
    createRisksExport?: Maybe<RiskExportType>
    createNonApplicableTopicsExport?: Maybe<NonApplicableTopicsExportType>
    createDepartmentExport?: Maybe<CreateDepartmentExport>
    deleteExpiredArticle: Scalars['Boolean']
    createRiskType?: Maybe<RiskTypeType>
    deleteRiskType?: Maybe<Scalars['Boolean']>
    editRiskType?: Maybe<RiskTypeType>
    editSAMLConfiguration: CustomerType
    createTaskTemplate?: Maybe<TaskTemplateType>
    archiveTaskTemplate: Scalars['Boolean']
    editTaskTemplate?: Maybe<TaskTemplateType>
    ignoreCustomerFrameworkNewTopic?: Maybe<CustomerFrameworkType>
    setIsConsultantTaskTemplateHidden?: Maybe<CustomerType>
    createMonitoringReportTopicAssessment?: Maybe<MonitoringReportTopicAssessmentType>
    followRadarItem: RadarItemType
    unfollowRadarItem: RadarItemType
    createAssessment?: Maybe<AssessmentType>
    editAssessment?: Maybe<AssessmentType>
    deleteAssessment?: Maybe<Scalars['Boolean']>
    createAssessmentSection?: Maybe<AssessmentSectionType>
    deleteAssessmentSection?: Maybe<AssessmentType>
    archiveControls?: Maybe<Array<ControlType>>
    unarchiveControl?: Maybe<ControlType>
    createRiskForAssessmentSection?: Maybe<RiskType>
    editRisk?: Maybe<RiskType>
    deleteRisk?: Maybe<Scalars['Boolean']>
    createControl?: Maybe<ControlType>
    editControl?: Maybe<ControlType>
    editControlDocument?: Maybe<Scalars['Boolean']>
    archiveControlDocument?: Maybe<Scalars['Boolean']>
    unarchiveControlDocument?: Maybe<Scalars['Boolean']>
    deleteControlDocument?: Maybe<Scalars['Boolean']>
    uploadControlDocument?: Maybe<Scalars['Boolean']>
    linkNestedControls?: Maybe<ControlType>
    unlinkNestedControls?: Maybe<ControlType>
    editAssessmentSectionTopics?: Maybe<AssessmentSectionType>
    deleteNormFromAssessmentSection?: Maybe<AssessmentSectionType>
    addControlToAssessmentSection?: Maybe<AssessmentSectionType>
    deleteControlFromAssessmentSection?: Maybe<AssessmentType>
    setTopicApplicableStatus?: Maybe<Scalars['Boolean']>
    archiveAlertsOnInbox: Array<AlertType>
    unarchiveAlertOnInbox?: Maybe<AlertType>
    archiveCustomerNews: Array<NewsItemType>
    unarchiveCustomerNews: Array<NewsItemType>
    editAlertImpactLevels: Array<AlertType>
    createMonitoringReport?: Maybe<MonitoringReportType>
    editMonitoringReport?: Maybe<MonitoringReportType>
    archiveMonitoringReports: Array<MonitoringReportType>
    unarchiveMonitoringReports: Array<MonitoringReportType>
    createTask?: Maybe<TaskType>
    editTask?: Maybe<TaskType>
    archiveTask: Scalars['Boolean']
    ignoreTasks: Array<TaskType>
    undoIgnoreTask: TaskType
    completeTask?: Maybe<TaskType>
    reopenTask?: Maybe<TaskType>
    addControlsToTopic?: Maybe<TopicType>
    addControlToTopics?: Maybe<ControlType>
    removeControlFromTopic?: Maybe<TopicType>
    createTopicNote?: Maybe<TopicNoteType>
    deleteTopicNote?: Maybe<TopicType>
    editTopicNote?: Maybe<TopicNoteType>
    setArticlesForTopicControlMeasureOrNote?: Maybe<TopicType>
    createTopicAssessment?: Maybe<TopicType>
    editTopicAssessment?: Maybe<TopicType>
    setGradeForInheritedTopicControlMeasures?: Maybe<TopicType>
    ignoreAlerts?: Maybe<Scalars['Boolean']>
    revertIgnoreAlert?: Maybe<Scalars['Boolean']>
    createInternalAlert: InternalAlertType
    editInternalAlert: InternalAlertType
    archiveDepartmentInternalAlert: Scalars['Boolean']
    returnDepartmentInternalAlert: Scalars['Boolean']
    acceptDepartmentInternalAlert: AlertType
    createDepartment?: Maybe<DepartmentType>
    editDepartmentNameAndEmployees?: Maybe<DepartmentType>
    editDepartmentCustomerFrameworks?: Maybe<Array<Maybe<DepartmentType>>>
    deleteDepartment?: Maybe<Scalars['Boolean']>
    editOrganization?: Maybe<OrganizationType>
    createCustomer?: Maybe<CustomerType>
    editCustomer?: Maybe<CustomerType>
    deleteCustomer?: Maybe<Scalars['Boolean']>
    createConsultant?: Maybe<ConsultantType>
    editConsultant?: Maybe<ConsultantType>
    deleteConsultant?: Maybe<Scalars['Boolean']>
    linkConsultantToCustomer?: Maybe<Scalars['Boolean']>
    unlinkConsultantFromCustomer?: Maybe<Scalars['Boolean']>
    createCustomerFramework?: Maybe<CustomerFrameworkType>
    deleteCustomerFramework?: Maybe<Scalars['Boolean']>
    editCustomerFrameworksTopics?: Maybe<Array<Maybe<CustomerFrameworkType>>>
    markLoginToCustomer?: Maybe<Scalars['Boolean']>
    createCustomerSubscribedToFeed?: Maybe<CustomerSubscribedToFeedType>
    editCustomerSubscribedToFeed?: Maybe<CustomerSubscribedToFeedType>
    deleteCustomerSubscribedToFeed: Scalars['Boolean']
    createCustomerGroup: CustomerGroupType
    editCustomerGroup: CustomerGroupType
    deleteCustomerGroup?: Maybe<Scalars['Boolean']>
    createConsultantTaskTemplate?: Maybe<ConsultantTaskTemplateType>
    editConsultantTaskTemplate?: Maybe<ConsultantTaskTemplateType>
    archiveConsultantTaskTemplate: Scalars['Boolean']
    createCustomersExport?: Maybe<CustomersExportType>
}

export type MutationLoginWithCredentialsArgs = {
    email?: Maybe<Scalars['String']>
    password?: Maybe<Scalars['String']>
    rememberMe?: Maybe<Scalars['Boolean']>
}

export type MutationRequestSamlRedirectUrlArgs = {
    organizationNameOrEmail?: Maybe<Scalars['String']>
}

export type MutationRequestSamlRedirectUrlForEmailArgs = {
    email?: Maybe<Scalars['String']>
}

export type MutationLogoutSessionArgs = {
    token?: Maybe<Scalars['String']>
}

export type MutationRequestNewPasswordResetArgs = {
    email?: Maybe<Scalars['String']>
}

export type MutationResetPasswordArgs = {
    token?: Maybe<Scalars['String']>
    password?: Maybe<Scalars['String']>
}

export type MutationRegisterUserArgs = {
    token?: Maybe<Scalars['String']>
    email?: Maybe<Scalars['String']>
    password?: Maybe<Scalars['String']>
}

export type MutationRegisterProspectArgs = {
    firstName?: Maybe<Scalars['String']>
    lastName?: Maybe<Scalars['String']>
    email?: Maybe<Scalars['String']>
    password?: Maybe<Scalars['String']>
}

export type MutationConfirmAccountEmailArgs = {
    token?: Maybe<Scalars['String']>
}

export type MutationSetUserMergeRequestResponseArgs = {
    token: Scalars['String']
    accepted: Scalars['Boolean']
}

export type MutationRefreshSessionArgs = {
    token?: Maybe<Scalars['String']>
}

export type MutationEditProspectNewsGroupsArgs = {
    newsGroupIds?: Maybe<Array<Scalars['Int']>>
}

export type MutationCreateTasksExportArgs = {
    filters?: Maybe<TaskFiltersInputType>
}

export type MutationSwitchEmployeeArgs = {
    employeeId: Scalars['Int']
    token: Scalars['String']
}

export type MutationAddWidgetArgs = {
    widgetType: DashboardWidgetEnum
    layout: DashboardWidgetLayoutTypeEnum
    metadata: WidgetMetadataInputType
}

export type MutationDeleteWidgetsArgs = {
    widgetIds: Array<Scalars['Int']>
    layout: DashboardWidgetLayoutTypeEnum
}

export type MutationEditWidgetArgs = {
    id: Scalars['Int']
    metadata: EditWidgetMetadataInputType
}

export type MutationEditDashboardWidgetsArgs = {
    widgets: Array<EditDashboardLayoutInputType>
}

export type MutationSetLastAppUpdateSeenArgs = {
    version?: Maybe<Scalars['String']>
    update?: Maybe<Scalars['Int']>
}

export type MutationChangePasswordArgs = {
    oldPassword?: Maybe<Scalars['String']>
    newPassword?: Maybe<Scalars['String']>
}

export type MutationPinItemArgs = {
    id?: Maybe<Scalars['Int']>
    type?: Maybe<EmployeePinEnumType>
}

export type MutationUnpinItemArgs = {
    id?: Maybe<Scalars['Int']>
    type?: Maybe<EmployeePinEnumType>
}

export type MutationMarkAlertAsReadArgs = {
    id: Scalars['Int']
    departmentId: Scalars['Int']
}

export type MutationRequestFileDownloadTokenArgs = {
    fileId: Scalars['Int']
    type: Scalars['String']
}

export type MutationCreateEmployeeArgs = {
    fields: CreateEmployeeFieldsInputType
    customerSlug?: Maybe<Scalars['String']>
}

export type MutationEditCustomerSettingsArgs = {
    fields?: Maybe<CustomerSettingsInputFieldsType>
}

export type MutationEditEmployeeArgs = {
    id: Scalars['Int']
    fields: EditEmployeeFieldsInputType
    customerSlug?: Maybe<Scalars['String']>
}

export type MutationDeleteEmployeeArgs = {
    id: Scalars['Int']
    customerSlug?: Maybe<Scalars['String']>
}

export type MutationEditCustomerFrameworkArgs = {
    id: Scalars['Int']
    fields: CustomerFrameworkFieldsInputType
    customerSlug?: Maybe<Scalars['String']>
}

export type MutationExportRadarItemsArgs = {
    filters?: Maybe<RadarItemFilters>
    customerSlug?: Maybe<Scalars['String']>
}

export type MutationRegisterDeviceArgs = {
    fields?: Maybe<DeviceInputType>
}

export type MutationUnregisterDeviceArgs = {
    deviceId?: Maybe<Scalars['Int']>
    deviceToken?: Maybe<Scalars['String']>
}

export type MutationEditCustomerEnabledNewsSourceArgs = {
    newsSourceIdsToDisable: Array<Scalars['Int']>
    newsSourceIdsToEnable: Array<Scalars['Int']>
    showFrom?: Maybe<Scalars['Date']>
}

export type MutationCreateCustomerUsersExportArgs = {
    filters?: Maybe<CustomerFiltersType>
    sort?: Maybe<CustomerSortType>
    forCustomerId?: Maybe<Scalars['Int']>
}

export type MutationCastGeneratedSummaryVoteArgs = {
    generatedSummaryId: Scalars['Int']
    vote?: Maybe<GeneratedSummaryUserVoteEnum>
}

export type MutationCreateComplianceExportArgs = {
    customerFrameworkIds?: Maybe<Array<Scalars['Int']>>
    departmentIds: Array<Scalars['Int']>
}

export type MutationCreateInboxExportArgs = {
    year?: Maybe<Scalars['Int']>
    departmentIds: Array<Scalars['Int']>
}

export type MutationCreateControlExportArgs = {
    departmentIds: Array<Scalars['Int']>
    filters?: Maybe<ControlFilters>
}

export type MutationCreateRisksExportArgs = {
    currentDepartmentId: Scalars['Int']
    departmentIdsFilter?: Maybe<Array<Scalars['Int']>>
}

export type MutationCreateNonApplicableTopicsExportArgs = {
    filters?: Maybe<TopicFilters>
    departmentId: Scalars['Int']
}

export type MutationDeleteExpiredArticleArgs = {
    abstractLawArticleId: Scalars['Int']
    topicId: Scalars['Int']
}

export type MutationCreateRiskTypeArgs = {
    name?: Maybe<Scalars['String']>
}

export type MutationDeleteRiskTypeArgs = {
    riskTypeId: Scalars['Int']
}

export type MutationEditRiskTypeArgs = {
    riskTypeId: Scalars['Int']
    name?: Maybe<Scalars['String']>
}

export type MutationEditSamlConfigurationArgs = {
    fields: CustomerSamlConfigurationFields
}

export type MutationCreateTaskTemplateArgs = {
    customerId: Scalars['Int']
    fields: CreateTaskTemplateFieldsInputType
}

export type MutationArchiveTaskTemplateArgs = {
    taskTemplateId: Scalars['Int']
    customerId: Scalars['Int']
}

export type MutationEditTaskTemplateArgs = {
    customerId: Scalars['Int']
    taskTemplateId: Scalars['Int']
    fields: EditTaskTemplateFieldsInputType
}

export type MutationIgnoreCustomerFrameworkNewTopicArgs = {
    customerFrameworkId: Scalars['Int']
    topicId: Scalars['Int']
}

export type MutationSetIsConsultantTaskTemplateHiddenArgs = {
    isHidden: Scalars['Boolean']
}

export type MutationCreateMonitoringReportTopicAssessmentArgs = {
    monitoringReportTopicId: Scalars['Int']
    linkedItemId: Scalars['Int']
    linkedItemType: TopicAssessmentDeLinkedItemType
    designOrEffectiveness: TopicAssessmentDesignOrEffectiveNessType
    fields: MonitoringReportTopicAssessmentInputType
}

export type MutationFollowRadarItemArgs = {
    radarItemId: Scalars['Int']
    departmentId: Scalars['Int']
}

export type MutationUnfollowRadarItemArgs = {
    radarItemId: Scalars['Int']
    departmentId: Scalars['Int']
}

export type MutationCreateAssessmentArgs = {
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    departmentId?: Maybe<Scalars['Int']>
    useDistinctBrutoRiskValues?: Maybe<Scalars['Boolean']>
    linkedToTopic?: Maybe<AssessmentLinkedToTopicInputType>
}

export type MutationEditAssessmentArgs = {
    assessmentId: Scalars['Int']
    fields: EditAssessmentFieldsType
}

export type MutationDeleteAssessmentArgs = {
    assessmentId: Scalars['Int']
}

export type MutationCreateAssessmentSectionArgs = {
    assessmentId?: Maybe<Scalars['Int']>
    departmentId?: Maybe<Scalars['Int']>
}

export type MutationDeleteAssessmentSectionArgs = {
    assessmentSectionId?: Maybe<Scalars['Int']>
    departmentId?: Maybe<Scalars['Int']>
}

export type MutationArchiveControlsArgs = {
    ids: Array<Scalars['Int']>
    reason?: Maybe<Scalars['String']>
}

export type MutationUnarchiveControlArgs = {
    id: Scalars['Int']
}

export type MutationCreateRiskForAssessmentSectionArgs = {
    assessmentSectionId: Scalars['Int']
    name?: Maybe<Scalars['String']>
}

export type MutationEditRiskArgs = {
    riskId: Scalars['Int']
    fields: EditRiskFieldsInputType
}

export type MutationDeleteRiskArgs = {
    riskId: Scalars['Int']
}

export type MutationCreateControlArgs = {
    fields: CreateControlFieldsType
    departmentId: Scalars['Int']
}

export type MutationEditControlArgs = {
    fields: EditControlFieldsType
    controlId: Scalars['Int']
}

export type MutationEditControlDocumentArgs = {
    documentId: Scalars['Int']
    fields: EditControlDocumentFields
}

export type MutationArchiveControlDocumentArgs = {
    documentId: Scalars['Int']
}

export type MutationUnarchiveControlDocumentArgs = {
    documentId: Scalars['Int']
}

export type MutationDeleteControlDocumentArgs = {
    documentId: Scalars['Int']
}

export type MutationUploadControlDocumentArgs = {
    controlId: Scalars['Int']
    fields: UploadControlDocumentFields
}

export type MutationLinkNestedControlsArgs = {
    controlId: Scalars['Int']
    linkControlIds: Array<Scalars['Int']>
}

export type MutationUnlinkNestedControlsArgs = {
    controlId: Scalars['Int']
    unlinkControlIds: Array<Scalars['Int']>
}

export type MutationEditAssessmentSectionTopicsArgs = {
    fields: EditAssessmentTopicsFieldsType
    assessmentSectionId: Scalars['Int']
}

export type MutationDeleteNormFromAssessmentSectionArgs = {
    assessmentSectionId: Scalars['Int']
    normId: Scalars['Int']
}

export type MutationAddControlToAssessmentSectionArgs = {
    assessmentSectionId: Scalars['Int']
    controlId: Scalars['Int']
}

export type MutationDeleteControlFromAssessmentSectionArgs = {
    assessmentSectionId: Scalars['Int']
    controlId: Scalars['Int']
}

export type MutationSetTopicApplicableStatusArgs = {
    markedAsApplicable?: Maybe<Array<Scalars['Int']>>
    markedAsNonApplicable?: Maybe<Array<TopicNonApplicableInputType>>
}

export type MutationArchiveAlertsOnInboxArgs = {
    departmentId: Scalars['Int']
    alertIds: Array<Scalars['Int']>
    archivedReason?: Maybe<Scalars['String']>
}

export type MutationUnarchiveAlertOnInboxArgs = {
    departmentId: Scalars['Int']
    alertId: Scalars['Int']
}

export type MutationArchiveCustomerNewsArgs = {
    newsIds: Array<Scalars['Int']>
    archivedReason?: Maybe<Scalars['String']>
}

export type MutationUnarchiveCustomerNewsArgs = {
    newsIds: Array<Scalars['Int']>
}

export type MutationEditAlertImpactLevelsArgs = {
    alertIds: Array<Scalars['Int']>
    impactLevel?: Maybe<AlertImpactLevel>
    departmentId: Scalars['Int']
}

export type MutationCreateMonitoringReportArgs = {
    fields: CreateMonitoringReportFieldsInputType
}

export type MutationEditMonitoringReportArgs = {
    monitoringReportId: Scalars['Int']
    fields: EditMonitoringReportFieldsInputType
}

export type MutationArchiveMonitoringReportsArgs = {
    monitoringReportIds: Array<Scalars['Int']>
    archivedReason?: Maybe<Scalars['String']>
}

export type MutationUnarchiveMonitoringReportsArgs = {
    monitoringReportIds: Array<Scalars['Int']>
}

export type MutationCreateTaskArgs = {
    fields: CreateTaskFieldsInputType
    departmentId?: Maybe<Scalars['Int']>
}

export type MutationEditTaskArgs = {
    fields?: Maybe<EditTaskFieldsInputType>
    taskId: Scalars['Int']
}

export type MutationArchiveTaskArgs = {
    taskId: Scalars['Int']
    departmentId: Scalars['Int']
}

export type MutationIgnoreTasksArgs = {
    reason?: Maybe<Scalars['String']>
    taskIdsToIgnore?: Maybe<Array<Maybe<Scalars['Int']>>>
    departmentId: Scalars['Int']
}

export type MutationUndoIgnoreTaskArgs = {
    taskId: Scalars['Int']
    departmentId: Scalars['Int']
}

export type MutationCompleteTaskArgs = {
    taskId: Scalars['Int']
    fields?: Maybe<TaskMotivationFieldsInputType>
}

export type MutationReopenTaskArgs = {
    fields?: Maybe<TaskMotivationFieldsInputType>
    taskId: Scalars['Int']
}

export type MutationAddControlsToTopicArgs = {
    topicId: Scalars['Int']
    controlIds: Array<Scalars['Int']>
    departmentId: Scalars['Int']
}

export type MutationAddControlToTopicsArgs = {
    controlId: Scalars['Int']
    topicIds: Array<Scalars['Int']>
    departmentId: Scalars['Int']
}

export type MutationRemoveControlFromTopicArgs = {
    topicId: Scalars['Int']
    controlId: Scalars['Int']
    departmentId: Scalars['Int']
}

export type MutationCreateTopicNoteArgs = {
    topicId: Scalars['Int']
    departmentId: Scalars['Int']
    fields?: Maybe<CreateTopicNoteFieldsType>
}

export type MutationDeleteTopicNoteArgs = {
    topicNoteId: Scalars['Int']
    departmentId: Scalars['Int']
    topicId: Scalars['Int']
}

export type MutationEditTopicNoteArgs = {
    topicNoteId: Scalars['Int']
    departmentId: Scalars['Int']
    fields?: Maybe<EditTopicNoteFieldsType>
}

export type MutationSetArticlesForTopicControlMeasureOrNoteArgs = {
    topicId: Scalars['Int']
    linkedItemId: Scalars['Int']
    linkedItemType: TopicAssessmentDeLinkedItemType
    lawArticleIds: Array<Scalars['Int']>
    departmentId: Scalars['Int']
}

export type MutationCreateTopicAssessmentArgs = {
    topicId: Scalars['Int']
    departmentId: Scalars['Int']
    linkedItemId: Scalars['Int']
    linkedItemType: TopicAssessmentDeLinkedItemType
    designOrEffectiveness: TopicAssessmentDesignOrEffectiveNessType
    documents?: Maybe<Array<Maybe<Scalars['Upload']>>>
    description?: Maybe<Scalars['String']>
    grade?: Maybe<TopicAssessmentGradeType>
}

export type MutationEditTopicAssessmentArgs = {
    topicAssessmentId: Scalars['Int']
    departmentId: Scalars['Int']
    linkedItemId: Scalars['Int']
    fields?: Maybe<EditTopicAssessmentFieldsType>
}

export type MutationSetGradeForInheritedTopicControlMeasuresArgs = {
    currentTopicControlMeasureId: Scalars['Int']
    linkedItemType: TopicAssessmentDesignOrEffectiveNessType
    grade: TopicAssessmentGradeType
    departmentId: Scalars['Int']
}

export type MutationIgnoreAlertsArgs = {
    reason?: Maybe<Scalars['String']>
    alertIdWithDepartmentIds?: Maybe<Array<AlertIdWithDepartmentIdInputType>>
}

export type MutationRevertIgnoreAlertArgs = {
    alertId: Scalars['Int']
    departmentId: Scalars['Int']
}

export type MutationCreateInternalAlertArgs = {
    departmentIds?: Maybe<Array<Scalars['Int']>>
    topicIds?: Maybe<Array<Scalars['Int']>>
    newsId: Scalars['Int']
    note?: Maybe<Scalars['String']>
}

export type MutationEditInternalAlertArgs = {
    id: Scalars['Int']
    extraTopicIds?: Maybe<Array<Scalars['Int']>>
    extraDepartmentIds?: Maybe<Array<Scalars['Int']>>
    note?: Maybe<Scalars['String']>
}

export type MutationArchiveDepartmentInternalAlertArgs = {
    departmentId: Scalars['Int']
    internalAlertId: Scalars['Int']
    archivedReason?: Maybe<Scalars['String']>
}

export type MutationReturnDepartmentInternalAlertArgs = {
    departmentId: Scalars['Int']
    internalAlertId: Scalars['Int']
    returnNote?: Maybe<Scalars['String']>
}

export type MutationAcceptDepartmentInternalAlertArgs = {
    departmentId: Scalars['Int']
    internalAlertId: Scalars['Int']
    extraTopicIds?: Maybe<Array<Scalars['Int']>>
    note?: Maybe<Scalars['String']>
}

export type MutationCreateDepartmentArgs = {
    customerId?: Maybe<Scalars['Int']>
    customerSlug?: Maybe<Scalars['String']>
    fields: CreateDepartmentFieldsInputType
}

export type MutationEditDepartmentNameAndEmployeesArgs = {
    departmentId: Scalars['Int']
    customerId: Scalars['Int']
    name?: Maybe<Scalars['String']>
    receiveAlertsForConsultationsEnabled?: Maybe<Scalars['Boolean']>
    employees?: Maybe<DepartmentEmployeesFieldInputType>
}

export type MutationEditDepartmentCustomerFrameworksArgs = {
    customerId: Scalars['Int']
    departmentCustomerFrameworks: Array<CustomerFrameworkInputType>
}

export type MutationDeleteDepartmentArgs = {
    departmentId: Scalars['Int']
    customerId?: Maybe<Scalars['Int']>
    customerSlug?: Maybe<Scalars['String']>
}

export type MutationEditOrganizationArgs = {
    fields: OrganizationFieldsInputType
}

export type MutationCreateCustomerArgs = {
    fields?: Maybe<CustomerInputFieldsType>
}

export type MutationEditCustomerArgs = {
    customerId?: Maybe<Scalars['Int']>
    fields?: Maybe<CustomerInputFieldsType>
}

export type MutationDeleteCustomerArgs = {
    customerId: Scalars['Int']
}

export type MutationCreateConsultantArgs = {
    fields: CreateConsultantFieldsType
}

export type MutationEditConsultantArgs = {
    consultantId: Scalars['Int']
    fields: EditConsultantFieldsType
}

export type MutationDeleteConsultantArgs = {
    consultantId: Scalars['Int']
}

export type MutationLinkConsultantToCustomerArgs = {
    consultantIds?: Maybe<Array<Scalars['Int']>>
    customerId: Scalars['Int']
}

export type MutationUnlinkConsultantFromCustomerArgs = {
    consultantIds?: Maybe<Array<Scalars['Int']>>
    customerId: Scalars['Int']
}

export type MutationCreateCustomerFrameworkArgs = {
    customerId: Scalars['Int']
    frameworkId: Scalars['Int']
    customerFramework: CustomerFrameworkFieldsInputType
    themeIds?: Maybe<Array<Scalars['Int']>>
    topicIds?: Maybe<Array<Scalars['Int']>>
}

export type MutationDeleteCustomerFrameworkArgs = {
    customerFrameworkId: Scalars['Int']
}

export type MutationEditCustomerFrameworksTopicsArgs = {
    customerFrameworksWithThemesAndTopics: Array<EditCustomerFrameworkTopicsField>
}

export type MutationMarkLoginToCustomerArgs = {
    customerSlug: Scalars['String']
}

export type MutationCreateCustomerSubscribedToFeedArgs = {
    isActive?: Maybe<Scalars['Boolean']>
    customerId?: Maybe<Scalars['Int']>
    type?: Maybe<CustomerSubscribedToFeedTypeTypesEnumType>
    settings?: Maybe<Scalars['String']>
    frequency?: Maybe<Scalars['Int']>
    frequencyType?: Maybe<CustomerSubscribedToFeedTypeFrequencyTypeEnumType>
    startDate?: Maybe<Scalars['Date']>
    recipientEmails?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type MutationEditCustomerSubscribedToFeedArgs = {
    id: Scalars['Int']
    isActive?: Maybe<Scalars['Boolean']>
    settings?: Maybe<Scalars['String']>
    frequency?: Maybe<Scalars['Int']>
    frequencyType?: Maybe<CustomerSubscribedToFeedTypeFrequencyTypeEnumType>
    startDate?: Maybe<Scalars['Date']>
    recipientEmails?: Maybe<Array<Scalars['String']>>
}

export type MutationDeleteCustomerSubscribedToFeedArgs = {
    id: Scalars['Int']
}

export type MutationCreateCustomerGroupArgs = {
    fields?: Maybe<CustomerGroupInputFieldsType>
}

export type MutationEditCustomerGroupArgs = {
    customerGroupId: Scalars['Int']
    fields: CustomerGroupInputFieldsType
}

export type MutationDeleteCustomerGroupArgs = {
    customerGroupId: Scalars['Int']
}

export type MutationCreateConsultantTaskTemplateArgs = {
    fields: CreateConsultantTaskTemplateFieldsInputType
}

export type MutationEditConsultantTaskTemplateArgs = {
    taskTemplateId: Scalars['Int']
    fields: EditConsultantTaskTemplateFieldsInputType
}

export type MutationArchiveConsultantTaskTemplateArgs = {
    taskTemplateId: Scalars['Int']
}

export type MutationCreateCustomersExportArgs = {
    search?: Maybe<Scalars['String']>
}

export type NewOrExistingTaskTagInputType = {
    tagId?: Maybe<Scalars['Int']>
    tagName?: Maybe<Scalars['String']>
}

export type NewsFilters = {
    search?: Maybe<Scalars['String']>
    groupIds?: Maybe<Array<Scalars['Int']>>
    status?: Maybe<NewsStatusEnum>
    internalAlertState?: Maybe<InternalAlertStatusState>
    publishedForDepartmentIds?: Maybe<Array<Scalars['Int']>>
    archivedYear?: Maybe<Scalars['Int']>
    excludeDisabledNewsSources?: Maybe<Scalars['Boolean']>
    sourceIds?: Maybe<Array<Scalars['Int']>>
}

export type NewsGroupType = {
    __typename?: 'NewsGroupType'
    id: Scalars['Int']
    name: Scalars['String']
    isPinned?: Maybe<Scalars['Boolean']>
    sources: Array<NewsItemSourceType>
}

export type NewsItemArchivedStatusType = {
    __typename?: 'NewsItemArchivedStatusType'
    id: Scalars['Int']
    archivedBy?: Maybe<EmployeeType>
    archivedAt?: Maybe<Scalars['DateTime']>
    unarchivedBy?: Maybe<EmployeeType>
    unarchivedAt?: Maybe<Scalars['DateTime']>
    archivedReason?: Maybe<Scalars['String']>
}

export type NewsItemSourceType = {
    __typename?: 'NewsItemSourceType'
    id: Scalars['Int']
    name?: Maybe<Scalars['String']>
    group?: Maybe<NewsGroupType>
    isEnabledByCustomer: Scalars['Boolean']
}

export type NewsItemType = {
    __typename?: 'NewsItemType'
    id: Scalars['Int']
    title: Scalars['String']
    content?: Maybe<Scalars['String']>
    translatedContent?: Maybe<Scalars['String']>
    /** @deprecated Never used */
    abstract?: Maybe<Scalars['String']>
    createdAt: Scalars['DateTime']
    presentationSetting: NewsPresentationSetting
    customSourceUrl?: Maybe<Scalars['String']>
    originalSource?: Maybe<Scalars['String']>
    linkedItems?: Maybe<Array<GroupedItemType>>
    publishedStatus?: Maybe<PublishedCustomerNewsType>
    publicationDate?: Maybe<Scalars['DateTime']>
    sources?: Maybe<Array<NewsItemSourceType>>
    attachments?: Maybe<Array<Maybe<AttachmentType>>>
    archivedStatus?: Maybe<NewsItemArchivedStatusType>
    suggestedTopics: Array<TopicType>
    generatedSummary?: Maybe<GeneratedSummaryType>
    internalAlert?: Maybe<InternalAlertType>
}

export type NewsItemTypeLinkedItemsArgs = {
    customerSlug?: Maybe<Scalars['String']>
    lawArticlesOnly?: Maybe<Scalars['Boolean']>
    filterDeletedArticles?: Maybe<Scalars['Boolean']>
}

export enum NewsPresentationSetting {
    detail = 'DETAIL',
    source = 'SOURCE',
    attachment = 'ATTACHMENT',
}

export type NewsSort = {
    title?: Maybe<Scalars['String']>
    source?: Maybe<Scalars['String']>
    publicationDate?: Maybe<Scalars['String']>
    archivedDate?: Maybe<Scalars['String']>
}

export enum NewsStatusEnum {
    new = 'New',
    archived = 'Archived',
    published = 'Published',
    rejected = 'Rejected',
}

export type NonApplicableTopicsExportType = {
    __typename?: 'NonApplicableTopicsExportType'
    token: Scalars['String']
    filename: Scalars['String']
}

export type NonApplicableTopicType = {
    __typename?: 'NonApplicableTopicType'
    id: Scalars['Int']
    createdAt: Scalars['DateTime']
    reason?: Maybe<Scalars['String']>
}

export enum NullsSortEnumType {
    nullFirst = 'NULL_FIRST',
    nullLast = 'NULL_LAST',
}

export type OldOldLawSectionType = {
    __typename?: 'OldOldLawSectionType'
    id?: Maybe<Scalars['Int']>
    name?: Maybe<Scalars['String']>
    lawId?: Maybe<Scalars['Int']>
    order?: Maybe<Scalars['Int']>
    articleId?: Maybe<Scalars['Int']>
    children?: Maybe<Scalars['JSON']>
}

export type OrganizationFieldsInputType = {
    name?: Maybe<Scalars['String']>
    address?: Maybe<AddressInputType>
    phoneNumber?: Maybe<Scalars['String']>
    email?: Maybe<Scalars['String']>
    contactId?: Maybe<Scalars['Int']>
}

export type OrganizationType = {
    __typename?: 'OrganizationType'
    id?: Maybe<Scalars['Int']>
    name?: Maybe<Scalars['String']>
    address?: Maybe<AddressType>
    email?: Maybe<Scalars['String']>
    phoneNumber?: Maybe<Scalars['String']>
    contact?: Maybe<ConsultantType>
}

export type PaginatedAlertType = {
    __typename?: 'PaginatedAlertType'
    nodes?: Maybe<Array<AlertType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedAssessmentSectionType = {
    __typename?: 'PaginatedAssessmentSectionType'
    nodes?: Maybe<Array<AssessmentSectionType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedAssessmentType = {
    __typename?: 'PaginatedAssessmentType'
    nodes?: Maybe<Array<AssessmentType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedAssignableEmployeeType = {
    __typename?: 'PaginatedAssignableEmployeeType'
    nodes?: Maybe<Array<AssignableEmployeeType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedConsultantTaskTemplateType = {
    __typename?: 'PaginatedConsultantTaskTemplateType'
    nodes?: Maybe<Array<ConsultantTaskTemplateType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedConsultantType = {
    __typename?: 'PaginatedConsultantType'
    nodes?: Maybe<Array<ConsultantType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedConsultationType = {
    __typename?: 'PaginatedConsultationType'
    nodes?: Maybe<Array<ConsultationType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedControlType = {
    __typename?: 'PaginatedControlType'
    nodes?: Maybe<Array<ControlType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedCustomerAlertSummaryPerMonthType = {
    __typename?: 'PaginatedCustomerAlertSummaryPerMonthType'
    nodes?: Maybe<Array<CustomerAlertSummaryPerMonthType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedCustomerGroupType = {
    __typename?: 'PaginatedCustomerGroupType'
    nodes?: Maybe<Array<CustomerGroupType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedCustomerSubscribedToFeedExport = {
    __typename?: 'PaginatedCustomerSubscribedToFeedExport'
    nodes?: Maybe<Array<CustomerSubscribedToFeedExport>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedCustomerTaskTemplateType = {
    __typename?: 'PaginatedCustomerTaskTemplateType'
    nodes?: Maybe<Array<CustomerTaskTemplateType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedCustomerType = {
    __typename?: 'PaginatedCustomerType'
    nodes?: Maybe<Array<CustomerType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedDepartmentType = {
    __typename?: 'PaginatedDepartmentType'
    nodes?: Maybe<Array<DepartmentType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedDeviceType = {
    __typename?: 'PaginatedDeviceType'
    nodes?: Maybe<Array<DeviceType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedEmployeeType = {
    __typename?: 'PaginatedEmployeeType'
    nodes?: Maybe<Array<EmployeeType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedEventLogType = {
    __typename?: 'PaginatedEventLogType'
    nodes?: Maybe<Array<EventLogType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedFrameworkType = {
    __typename?: 'PaginatedFrameworkType'
    nodes?: Maybe<Array<FrameworkType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedGroupedDepartmentAlertDataType = {
    __typename?: 'PaginatedGroupedDepartmentAlertDataType'
    nodes?: Maybe<Array<GroupedDepartmentAlertDataType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedInternalAlertType = {
    __typename?: 'PaginatedInternalAlertType'
    nodes?: Maybe<Array<InternalAlertType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedItemWithTasksType = {
    __typename?: 'PaginatedItemWithTasksType'
    nodes?: Maybe<Array<ItemWithTasksType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedLawType = {
    __typename?: 'PaginatedLawType'
    nodes?: Maybe<Array<LawType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedMonitoringReportType = {
    __typename?: 'PaginatedMonitoringReportType'
    nodes?: Maybe<Array<MonitoringReportType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedNewsGroupType = {
    __typename?: 'PaginatedNewsGroupType'
    nodes?: Maybe<Array<NewsGroupType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedNewsItemType = {
    __typename?: 'PaginatedNewsItemType'
    nodes?: Maybe<Array<NewsItemType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedPublishedCustomerNewsType = {
    __typename?: 'PaginatedPublishedCustomerNewsType'
    nodes?: Maybe<Array<PublishedCustomerNewsType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedRadarItemType = {
    __typename?: 'PaginatedRadarItemType'
    nodes?: Maybe<Array<RadarItemType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedRadarPhaseType = {
    __typename?: 'PaginatedRadarPhaseType'
    nodes?: Maybe<Array<RadarPhaseType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedRiskType = {
    __typename?: 'PaginatedRiskType'
    nodes?: Maybe<Array<RiskType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedRiskTypeType = {
    __typename?: 'PaginatedRiskTypeType'
    nodes?: Maybe<Array<RiskTypeType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedSearchItemType = {
    __typename?: 'PaginatedSearchItemType'
    nodes?: Maybe<Array<SearchItemType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedTaskSummaryPerMonthType = {
    __typename?: 'PaginatedTaskSummaryPerMonthType'
    nodes?: Maybe<Array<TaskSummaryPerMonthType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedTaskTagType = {
    __typename?: 'PaginatedTaskTagType'
    nodes?: Maybe<Array<TaskTagType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedTaskTemplateType = {
    __typename?: 'PaginatedTaskTemplateType'
    nodes?: Maybe<Array<TaskTemplateType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedTaskType = {
    __typename?: 'PaginatedTaskType'
    nodes?: Maybe<Array<TaskType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedThemeType = {
    __typename?: 'PaginatedThemeType'
    nodes?: Maybe<Array<ThemeType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedTopicControlMeasureType = {
    __typename?: 'PaginatedTopicControlMeasureType'
    nodes?: Maybe<Array<TopicControlMeasureType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type PaginatedTopicType = {
    __typename?: 'PaginatedTopicType'
    nodes?: Maybe<Array<TopicType>>
    count: Scalars['Int']
    totalCount: Scalars['Int']
    page: Scalars['Int']
    totalPages: Scalars['Int']
    hasNextPage: Scalars['Boolean']
}

export type ProfileType = {
    __typename?: 'ProfileType'
    id: Scalars['Int']
    firstName: Scalars['String']
    lastName?: Maybe<Scalars['String']>
    fullName: Scalars['String']
    phoneNumber?: Maybe<Scalars['String']>
    avatar?: Maybe<Scalars['String']>
}

export type ProspectType = {
    __typename?: 'ProspectType'
    id?: Maybe<Scalars['Int']>
    newsGroups?: Maybe<Array<Maybe<NewsGroupType>>>
    user?: Maybe<UserType>
}

export type PublishedCustomerNewsFiltersType = {
    sharesNewsWithAlertId?: Maybe<Scalars['Int']>
}

export type PublishedCustomerNewsType = {
    __typename?: 'PublishedCustomerNewsType'
    id: Scalars['Int']
    alert: AlertType
    publishedAt: Scalars['DateTime']
    publishedBy?: Maybe<EmployeeType>
    topics: Array<TopicType>
    departments?: Maybe<Array<DepartmentType>>
    news: NewsItemType
}

export type Query = {
    __typename?: 'Query'
    getAuthenticatedUser?: Maybe<UserType>
    employee?: Maybe<EmployeeType>
    consultant?: Maybe<ConsultantType>
    userMergeRequest?: Maybe<UserMergeRequestType>
    userEmployees?: Maybe<Array<EmployeeType>>
    generatedSummaryByParent?: Maybe<GeneratedSummaryType>
    prospect?: Maybe<ProspectType>
    newsGroups?: Maybe<PaginatedNewsGroupType>
    newsGroup?: Maybe<NewsGroupType>
    newsItems?: Maybe<PaginatedNewsItemType>
    newsItem?: Maybe<NewsItemType>
    laws?: Maybe<PaginatedLawType>
    law?: Maybe<LawType>
    lawArticle?: Maybe<LawArticleType>
    lawAttachments?: Maybe<Array<Maybe<LawArticleType>>>
    lawGroups?: Maybe<Array<LawGroupType>>
    /** @deprecated Use sections field on lawsections */
    lawSections?: Maybe<Array<Maybe<OldOldLawSectionType>>>
    listTopicsOutsideDepartments?: Maybe<PaginatedTopicType>
    customerFrameworks?: Maybe<Array<CustomerFrameworkType>>
    customerFramework?: Maybe<CustomerFrameworkType>
    themes?: Maybe<PaginatedThemeType>
    theme?: Maybe<ThemeType>
    topics?: Maybe<PaginatedTopicType>
    topicsForTheme?: Maybe<PaginatedTopicType>
    topic?: Maybe<TopicType>
    minimalAllTopics?: Maybe<Array<TopicType>>
    topicControlMeasure?: Maybe<TopicControlMeasureType>
    topicControlMeasures?: Maybe<PaginatedTopicControlMeasureType>
    topicNote?: Maybe<TopicNoteType>
    topicNoteDocument?: Maybe<TopicNoteDocumentType>
    topicAssessment?: Maybe<TopicAssessmentType>
    alerts?: Maybe<PaginatedAlertType>
    alert?: Maybe<AlertType>
    alertsForMonth?: Maybe<PaginatedGroupedDepartmentAlertDataType>
    alertsSummaryPerMonth?: Maybe<PaginatedCustomerAlertSummaryPerMonthType>
    unreadAlertCount?: Maybe<Scalars['Int']>
    publishedCustomerNews?: Maybe<PaginatedPublishedCustomerNewsType>
    consultations?: Maybe<PaginatedConsultationType>
    consultation?: Maybe<ConsultationType>
    radarItems?: Maybe<PaginatedRadarItemType>
    radarItem?: Maybe<RadarItemType>
    radarPhases?: Maybe<PaginatedRadarPhaseType>
    upcomingRadarItems?: Maybe<Array<RadarItemType>>
    pinned?: Maybe<EmployeePinnedType>
    latestNews?: Maybe<Array<NewsItemType>>
    identifyLawRegulation?: Maybe<IdentifiedRegulationType>
    customer?: Maybe<CustomerType>
    employees?: Maybe<PaginatedEmployeeType>
    allEmployees?: Maybe<Array<Maybe<EmployeeType>>>
    assignableEmployees?: Maybe<PaginatedAssignableEmployeeType>
    search?: Maybe<PaginatedSearchItemType>
    legacyRedirect?: Maybe<LegacyRedirectType>
    listDepartments?: Maybe<PaginatedDepartmentType>
    allDepartments?: Maybe<Array<DepartmentType>>
    getDepartment?: Maybe<DepartmentType>
    devices?: Maybe<PaginatedDeviceType>
    taskTemplate?: Maybe<TaskTemplateType>
    taskTemplates?: Maybe<PaginatedTaskTemplateType>
    checkEmailExistsInCustomerGroup: Scalars['Boolean']
    customerEnabledNewsSource?: Maybe<CustomerEnabledNewsSourceType>
    consultantTaskTemplate?: Maybe<ConsultantTaskTemplateType>
    consultantTaskTemplates?: Maybe<PaginatedConsultantTaskTemplateType>
    dashboardWidgets?: Maybe<Array<DashboardWidgetLayoutType>>
    dashboardWidgetContent?: Maybe<DashboardWidgetContentType>
    dashboardDetailContent?: Maybe<DashboardDetailContentType>
    allRisks?: Maybe<Array<Maybe<RiskType>>>
    risks?: Maybe<PaginatedRiskType>
    risk?: Maybe<RiskType>
    riskTypes?: Maybe<PaginatedRiskTypeType>
    riskType?: Maybe<RiskTypeType>
    controls?: Maybe<PaginatedControlType>
    control?: Maybe<ControlType>
    assessments?: Maybe<PaginatedAssessmentType>
    assessment?: Maybe<AssessmentType>
    assessmentSections?: Maybe<PaginatedAssessmentSectionType>
    assessmentSection?: Maybe<AssessmentSectionType>
    assessmentLogs?: Maybe<Array<Maybe<EventLogType>>>
    topicLogs?: Maybe<Array<Maybe<EventLogType>>>
    tasks?: Maybe<PaginatedTaskType>
    tasksForMonth?: Maybe<PaginatedTaskType>
    tasksSummaryPerMonth?: Maybe<PaginatedTaskSummaryPerMonthType>
    itemsWithTasks?: Maybe<PaginatedItemWithTasksType>
    task?: Maybe<TaskType>
    taskTags?: Maybe<PaginatedTaskTagType>
    taskLogs?: Maybe<Array<Maybe<EventLogType>>>
    linkedTaskItemStatus?: Maybe<LinkedTaskItemStatusType>
    customerTaskTemplates?: Maybe<PaginatedCustomerTaskTemplateType>
    monitoringReports?: Maybe<PaginatedMonitoringReportType>
    monitoringReport?: Maybe<MonitoringReportType>
    internalAlerts?: Maybe<PaginatedInternalAlertType>
    internalAlert?: Maybe<InternalAlertType>
    /** for the EventLogType.metadata, see InternalAlertWasEditedForDepartmentMetaData */
    departmentInternalAlertLogs?: Maybe<PaginatedEventLogType>
    internalAlertsForCustomer?: Maybe<PaginatedInternalAlertType>
    /** for the EventLogType.metadata, see InternalAlertWasEditedForDepartmentMetaData */
    internalAlertLogs?: Maybe<PaginatedEventLogType>
    organization?: Maybe<OrganizationType>
    customers?: Maybe<PaginatedCustomerType>
    consultants?: Maybe<PaginatedConsultantType>
    frameworks?: Maybe<PaginatedFrameworkType>
    framework?: Maybe<FrameworkType>
    customerSubscribedToFeeds?: Maybe<Array<Maybe<CustomerSubscribedToFeedType>>>
    customerSubscribedToFeed?: Maybe<CustomerSubscribedToFeedType>
    customerSubscribedToFeedExports?: Maybe<PaginatedCustomerSubscribedToFeedExport>
    customerGroup?: Maybe<CustomerGroupType>
    customerGroups?: Maybe<PaginatedCustomerGroupType>
}

export type QueryEmployeeArgs = {
    id?: Maybe<Scalars['Int']>
    customerSlug?: Maybe<Scalars['String']>
}

export type QueryConsultantArgs = {
    consultantId?: Maybe<Scalars['Int']>
}

export type QueryUserMergeRequestArgs = {
    token: Scalars['String']
}

export type QueryGeneratedSummaryByParentArgs = {
    parentId: Scalars['Int']
    parentType: GeneratedSummaryParentTypeEnum
}

export type QueryProspectArgs = {
    id?: Maybe<Scalars['Int']>
}

export type QueryNewsGroupsArgs = {
    filters?: Maybe<GroupFilters>
    sort?: Maybe<GroupSort>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryNewsGroupArgs = {
    id: Scalars['Int']
}

export type QueryNewsItemsArgs = {
    filters?: Maybe<NewsFilters>
    sort?: Maybe<NewsSort>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryNewsItemArgs = {
    id: Scalars['Int']
}

export type QueryLawsArgs = {
    filters?: Maybe<LawFilters>
    sort?: Maybe<LawSort>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryLawArgs = {
    abstractLawId: Scalars['Int']
}

export type QueryLawArticleArgs = {
    id?: Maybe<Scalars['Int']>
    byAbstractId?: Maybe<Scalars['Int']>
}

export type QueryLawAttachmentsArgs = {
    lawId: Scalars['Int']
}

export type QueryLawSectionsArgs = {
    lawId: Scalars['Int']
}

export type QueryListTopicsOutsideDepartmentsArgs = {
    customerSlug?: Maybe<Scalars['String']>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryCustomerFrameworksArgs = {
    customerSlug?: Maybe<Scalars['String']>
    departmentId?: Maybe<Scalars['Int']>
    all?: Maybe<Scalars['Boolean']>
    externallyCuratedOnly?: Maybe<Scalars['Boolean']>
}

export type QueryCustomerFrameworkArgs = {
    id: Scalars['Int']
    customerSlug?: Maybe<Scalars['String']>
}

export type QueryThemesArgs = {
    filters?: Maybe<ThemeFilters>
    sort?: Maybe<ThemeSort>
    customerSlug?: Maybe<Scalars['String']>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryThemeArgs = {
    id: Scalars['Int']
    filters?: Maybe<ThemeFilters>
    customerSlug?: Maybe<Scalars['String']>
}

export type QueryTopicsArgs = {
    filters?: Maybe<TopicFilters>
    sort?: Maybe<TopicSort>
    customerSlug?: Maybe<Scalars['String']>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryTopicsForThemeArgs = {
    themeId: Scalars['Int']
    customerFrameworkIds: Array<Scalars['Int']>
    departmentId: Scalars['Int']
    customerSlug: Scalars['String']
    filters?: Maybe<TopicsForThemeFilters>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryTopicArgs = {
    id: Scalars['Int']
    filters?: Maybe<TopicFilters>
    customerSlug?: Maybe<Scalars['String']>
}

export type QueryMinimalAllTopicsArgs = {
    filters?: Maybe<AllTopicFilters>
    customerSlug: Scalars['String']
}

export type QueryTopicControlMeasureArgs = {
    id: Scalars['Int']
    departmentId: Scalars['Int']
}

export type QueryTopicControlMeasuresArgs = {
    departmentId: Scalars['Int']
    filters?: Maybe<TopicControlMeasureFilters>
    sort?: Maybe<TopicControlMeasureSort>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryTopicNoteArgs = {
    id: Scalars['Int']
    departmentId: Scalars['Int']
}

export type QueryTopicNoteDocumentArgs = {
    id: Scalars['Int']
    departmentId: Scalars['Int']
}

export type QueryTopicAssessmentArgs = {
    id: Scalars['Int']
    linkedItemId: Scalars['Int']
    linkedItemType: TopicAssessmentDeLinkedItemType
    departmentId: Scalars['Int']
}

export type QueryAlertsArgs = {
    filters?: Maybe<AlertFilters>
    customerSlug?: Maybe<Scalars['String']>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryAlertArgs = {
    id: Scalars['Int']
    filters?: Maybe<AlertFilters>
    departmentId?: Maybe<Scalars['Int']>
    customerSlug?: Maybe<Scalars['String']>
}

export type QueryAlertsForMonthArgs = {
    filters?: Maybe<AlertFilters>
    sort?: Maybe<AlertsForMonthSort>
    month: Scalars['Int']
    year: Scalars['Int']
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryAlertsSummaryPerMonthArgs = {
    filters?: Maybe<AlertFilters>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryUnreadAlertCountArgs = {
    filters?: Maybe<AlertFilters>
}

export type QueryPublishedCustomerNewsArgs = {
    filters?: Maybe<PublishedCustomerNewsFiltersType>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryConsultationsArgs = {
    filters?: Maybe<ConsultationFilters>
    sort?: Maybe<ConsultationSort>
    customerSlug?: Maybe<Scalars['String']>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryConsultationArgs = {
    id: Scalars['Int']
    filters?: Maybe<ConsultationFilters>
    customerSlug?: Maybe<Scalars['String']>
}

export type QueryRadarItemsArgs = {
    filters?: Maybe<RadarItemFilters>
    customerSlug?: Maybe<Scalars['String']>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryRadarItemArgs = {
    id: Scalars['Int']
    filters?: Maybe<RadarItemFilters>
    customerSlug?: Maybe<Scalars['String']>
}

export type QueryRadarPhasesArgs = {
    filters?: Maybe<RadarPhaseFilters>
    sort?: Maybe<RadarPhaseSort>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryUpcomingRadarItemsArgs = {
    take?: Maybe<Scalars['Int']>
    customerSlug?: Maybe<Scalars['String']>
}

export type QueryLatestNewsArgs = {
    take?: Maybe<Scalars['Int']>
}

export type QueryIdentifyLawRegulationArgs = {
    identifier?: Maybe<Scalars['String']>
}

export type QueryCustomerArgs = {
    id?: Maybe<Scalars['Int']>
    customerSlug?: Maybe<Scalars['String']>
}

export type QueryEmployeesArgs = {
    customerSlug?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['String']>
    departmentId?: Maybe<Scalars['Int']>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryAllEmployeesArgs = {
    customerId: Scalars['Int']
}

export type QueryAssignableEmployeesArgs = {
    customerSlug?: Maybe<Scalars['String']>
    departmentId?: Maybe<Scalars['Int']>
    name?: Maybe<Scalars['String']>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QuerySearchArgs = {
    query?: Maybe<Scalars['String']>
    filters?: Maybe<SearchFiltersInputType>
    customerSlug?: Maybe<Scalars['String']>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryLegacyRedirectArgs = {
    legacyDocumentKey: Scalars['String']
}

export type QueryListDepartmentsArgs = {
    customerSlug?: Maybe<Scalars['String']>
    filters?: Maybe<DepartmentFilters>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryAllDepartmentsArgs = {
    customerSlug?: Maybe<Scalars['String']>
}

export type QueryGetDepartmentArgs = {
    id: Scalars['Int']
    customerSlug?: Maybe<Scalars['String']>
    customerId?: Maybe<Scalars['Int']>
}

export type QueryDevicesArgs = {
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryTaskTemplateArgs = {
    taskTemplateId: Scalars['Int']
    customerId: Scalars['Int']
}

export type QueryTaskTemplatesArgs = {
    customerId: Scalars['Int']
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryCheckEmailExistsInCustomerGroupArgs = {
    forEmployeeId: Scalars['Int']
    email: Scalars['String']
}

export type QueryCustomerEnabledNewsSourceArgs = {
    customerId: Scalars['Int']
}

export type QueryConsultantTaskTemplateArgs = {
    consultantTaskTemplateId: Scalars['Int']
}

export type QueryConsultantTaskTemplatesArgs = {
    customerId?: Maybe<Scalars['Int']>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryDashboardWidgetsArgs = {
    layout?: Maybe<DashboardWidgetLayoutTypeEnum>
}

export type QueryDashboardWidgetContentArgs = {
    widgetId: Scalars['Int']
}

export type QueryDashboardDetailContentArgs = {
    widgetId: Scalars['Int']
}

export type QueryAllRisksArgs = {
    filters?: Maybe<RiskFilters>
    sort?: Maybe<RiskSort>
}

export type QueryRisksArgs = {
    filters?: Maybe<RiskFilters>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryRiskArgs = {
    id: Scalars['Int']
}

export type QueryRiskTypesArgs = {
    filters?: Maybe<RiskTypesFilters>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryRiskTypeArgs = {
    riskTypeId: Scalars['Int']
}

export type QueryControlsArgs = {
    sort?: Maybe<ControlSort>
    filters?: Maybe<ControlFilters>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryControlArgs = {
    id: Scalars['Int']
}

export type QueryAssessmentsArgs = {
    filters?: Maybe<AssessmentsFilters>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryAssessmentArgs = {
    id: Scalars['Int']
}

export type QueryAssessmentSectionsArgs = {
    assessmentId: Scalars['Int']
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryAssessmentSectionArgs = {
    assessmentSectionId: Scalars['Int']
}

export type QueryAssessmentLogsArgs = {
    assessmentId: Scalars['Int']
}

export type QueryTopicLogsArgs = {
    departmentId: Scalars['Int']
    linkedItemId: Scalars['Int']
    linkedItemType: TopicAssessmentDeLinkedItemType
}

export type QueryTasksArgs = {
    filters?: Maybe<TaskFiltersInputType>
    sort?: Maybe<TaskSortInputType>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryTasksForMonthArgs = {
    filters?: Maybe<TaskForMonthFilterInputType>
    sort?: Maybe<TaskForMonthSortInputType>
    month: Scalars['Int']
    year: Scalars['Int']
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryTasksSummaryPerMonthArgs = {
    filters?: Maybe<TaskForMonthFilterInputType>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryItemsWithTasksArgs = {
    departmentId: Scalars['Int']
    sort?: Maybe<ItemWithTaskSortInputType>
    filters?: Maybe<ItemWithTaskFiltersInputType>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryTaskArgs = {
    taskId: Scalars['Int']
}

export type QueryTaskTagsArgs = {
    filters?: Maybe<TaskTagFiltersInputType>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryTaskLogsArgs = {
    taskId: Scalars['Int']
}

export type QueryLinkedTaskItemStatusArgs = {
    departmentId?: Maybe<Scalars['Int']>
    customerId?: Maybe<Scalars['Int']>
    linkedItemId: Scalars['Int']
    linkedItemType: LinkedTaskItem
    filterForDepartment?: Maybe<Scalars['Int']>
}

export type QueryCustomerTaskTemplatesArgs = {
    customerId: Scalars['Int']
    customerFrameworkIds?: Maybe<Array<Scalars['Int']>>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryMonitoringReportsArgs = {
    sort?: Maybe<MonitoringReportSort>
    filters?: Maybe<MonitoringReportFilters>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryMonitoringReportArgs = {
    monitoringReportId: Scalars['Int']
}

export type QueryInternalAlertsArgs = {
    filters?: Maybe<InternalAlertFiltersType>
    departmentId: Scalars['Int']
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryInternalAlertArgs = {
    id: Scalars['Int']
    departmentId: Scalars['Int']
}

export type QueryDepartmentInternalAlertLogsArgs = {
    internalAlertId: Scalars['Int']
    departmentId: Scalars['Int']
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryInternalAlertsForCustomerArgs = {
    filters?: Maybe<InternalAlertFiltersForCustomerType>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryInternalAlertLogsArgs = {
    internalAlertId: Scalars['Int']
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryCustomersArgs = {
    filters?: Maybe<CustomerFiltersType>
    sort?: Maybe<CustomerSortType>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryConsultantsArgs = {
    filters?: Maybe<ConsultantsFiltersType>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryFrameworksArgs = {
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryFrameworkArgs = {
    frameworkId?: Maybe<Scalars['Int']>
}

export type QueryCustomerSubscribedToFeedArgs = {
    id: Scalars['Int']
}

export type QueryCustomerSubscribedToFeedExportsArgs = {
    customerFeedId: Scalars['Int']
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type QueryCustomerGroupArgs = {
    id: Scalars['Int']
}

export type QueryCustomerGroupsArgs = {
    search?: Maybe<Scalars['String']>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}

export type RadarItemFilters = {
    search?: Maybe<Scalars['String']>
    customerFrameworkIds?: Maybe<Array<Scalars['Int']>>
    departmentIds?: Maybe<Array<Scalars['Int']>>
    inYear?: Maybe<Scalars['Int']>
    phase?: Maybe<RadarPhaseEnumType>
    followingInDepartment?: Maybe<Scalars['Int']>
}

export enum RadarItemImpact {
    low = 'LOW',
    average = 'AVERAGE',
    high = 'HIGH',
    highest = 'HIGHEST',
}

export type RadarItemsExportType = {
    __typename?: 'RadarItemsExportType'
    token: Scalars['String']
    filename: Scalars['String']
}

export type RadarItemType = {
    __typename?: 'RadarItemType'
    id: Scalars['Int']
    createdAt: Scalars['DateTime']
    name: Scalars['String']
    expectedDate?: Maybe<Scalars['DateTime']>
    content?: Maybe<Scalars['String']>
    following: Scalars['Boolean']
    impact?: Maybe<RadarItemImpact>
    lastEditorialChangeAt: Scalars['DateTime']
    linkedItems?: Maybe<Array<GroupedItemType>>
    isDeleted: Scalars['Boolean']
    phase?: Maybe<RadarPhaseType>
    customerFrameworks?: Maybe<Array<CustomerFrameworkType>>
    linkedTopics?: Maybe<Array<TopicType>>
    itemImpacts?: Maybe<Array<ItemImpactType>>
    /** open task with the closest deadline */
    nextTask?: Maybe<TaskType>
    /** open task with the highest prio */
    highestPrioTask?: Maybe<TaskType>
}

export type RadarItemTypeFollowingArgs = {
    departmentId: Scalars['Int']
}

export type RadarItemTypeLinkedItemsArgs = {
    customerSlug?: Maybe<Scalars['String']>
    lawArticlesOnly?: Maybe<Scalars['Boolean']>
    filterDeletedArticles?: Maybe<Scalars['Boolean']>
}

export type RadarItemTypeCustomerFrameworksArgs = {
    customerSlug?: Maybe<Scalars['String']>
    departmentId?: Maybe<Scalars['Int']>
    all?: Maybe<Scalars['Boolean']>
    externallyCuratedOnly?: Maybe<Scalars['Boolean']>
}

export type RadarItemTypeLinkedTopicsArgs = {
    departmentId: Scalars['Int']
}

export type RadarItemTypeNextTaskArgs = {
    departmentId: Scalars['Int']
}

export type RadarItemTypeHighestPrioTaskArgs = {
    departmentId: Scalars['Int']
}

export enum RadarPhaseEnumType {
    proposalEuropeanRegulation = 'PROPOSAL_EUROPEAN_REGULATION',
    definitiveEuropeanRegulation = 'DEFINITIVE_EUROPEAN_REGULATION',
    proposalDutchRegulation = 'PROPOSAL_DUTCH_REGULATION',
    definitiveDutchRegulation = 'DEFINITIVE_DUTCH_REGULATION',
    regulationInForce = 'REGULATION_IN_FORCE',
}

export type RadarPhaseFilters = {
    search?: Maybe<Scalars['String']>
}

export type RadarPhaseSort = {
    name?: Maybe<Scalars['String']>
    order?: Maybe<Scalars['String']>
}

export type RadarPhaseType = {
    __typename?: 'RadarPhaseType'
    id?: Maybe<Scalars['Int']>
    name?: Maybe<Scalars['String']>
    type?: Maybe<RadarPhaseEnumType>
    order?: Maybe<Scalars['Int']>
}

export enum ReviewTaskStatus {
    ignored = 'IGNORED',
    tobereviewed = 'TOBEREVIEWED',
    new = 'NEW',
}

export type RiskControlType = {
    __typename?: 'RiskControlType'
    id?: Maybe<Scalars['Int']>
    risk?: Maybe<RiskType>
    control?: Maybe<ControlType>
    description?: Maybe<Scalars['String']>
}

export type RiskExportType = {
    __typename?: 'RiskExportType'
    token: Scalars['String']
    filename: Scalars['String']
}

export type RiskFilters = {
    search?: Maybe<Scalars['String']>
    topicId?: Maybe<Scalars['Int']>
    controlId?: Maybe<Scalars['Int']>
    riskTypeIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    assessmentId?: Maybe<Scalars['Int']>
    departmentId?: Maybe<Scalars['Int']>
    brutoOnly?: Maybe<Scalars['Boolean']>
    acceptedOnly?: Maybe<Scalars['Boolean']>
}

export enum RiskSeverity {
    low = 'LOW',
    medium = 'MEDIUM',
    high = 'HIGH',
}

export type RiskSort = {
    name?: Maybe<SortDirectionEnumType>
    createdAt?: Maybe<SortDirectionEnumType>
    assessmentName?: Maybe<SortDirectionEnumType>
    severity?: Maybe<SortDirectionEnumType>
}

export enum RiskStatus {
    noControlsWhileNettoSeverityIsSet = 'NO_CONTROLS_WHILE_NETTO_SEVERITY_IS_SET',
    noNettoSeverityWhileControlsAreSet = 'NO_NETTO_SEVERITY_WHILE_CONTROLS_ARE_SET',
    severityNotSet = 'SEVERITY_NOT_SET',
    brutoSeverityNotSet = 'BRUTO_SEVERITY_NOT_SET',
}

export type RiskType = {
    __typename?: 'RiskType'
    id: Scalars['Int']
    createdAt: Scalars['DateTime']
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    types?: Maybe<Array<RiskTypeType>>
    brutoImpact?: Maybe<Scalars['Int']>
    nettoImpact?: Maybe<Scalars['Int']>
    brutoProbability?: Maybe<Scalars['Int']>
    nettoProbability?: Maybe<Scalars['Int']>
    isAccepted?: Maybe<Scalars['Boolean']>
    status?: Maybe<RiskStatus>
    assessmentId?: Maybe<Scalars['Int']>
    assessment?: Maybe<AssessmentType>
    assessmentSectionId?: Maybe<Scalars['Int']>
    assessmentSection?: Maybe<AssessmentSectionType>
    linkedControls?: Maybe<Array<RiskControlType>>
    severity?: Maybe<RiskSeverity>
    severityBruto?: Maybe<RiskSeverity>
    /** open task with the closest deadline */
    nextTask?: Maybe<TaskType>
    /** open task with the highest prio */
    highestPrioTask?: Maybe<TaskType>
}

export type RiskTypeLinkedControlsArgs = {
    departmentId?: Maybe<Scalars['Int']>
}

export type RiskTypeNextTaskArgs = {
    departmentId: Scalars['Int']
}

export type RiskTypeHighestPrioTaskArgs = {
    departmentId: Scalars['Int']
}

export type RiskTypesFilters = {
    name?: Maybe<Scalars['String']>
    usedInDepartmentId?: Maybe<Scalars['Int']>
}

export type RiskTypesInputType = {
    typeId?: Maybe<Scalars['Int']>
    newTypeName?: Maybe<Scalars['String']>
}

export type RiskTypeType = {
    __typename?: 'RiskTypeType'
    id?: Maybe<Scalars['Int']>
    name?: Maybe<Scalars['String']>
}

export type SamlRedirectUrlResponseType = {
    __typename?: 'SAMLRedirectURLResponseType'
    redirectURL?: Maybe<Scalars['String']>
}

export type SearchFiltersInputType = {
    searchIn?: Maybe<SearchFiltersSearchInInputType>
    frameworkIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    newsGroupId?: Maybe<Scalars['Int']>
    newsSourceId?: Maybe<Scalars['Int']>
    startDate?: Maybe<Scalars['DateTime']>
    endDate?: Maybe<Scalars['DateTime']>
}

export type SearchFiltersSearchInInputType = {
    alert?: Maybe<Scalars['Boolean']>
    consultation?: Maybe<Scalars['Boolean']>
    law?: Maybe<Scalars['Boolean']>
    lawarticle?: Maybe<Scalars['Boolean']>
    newsitem?: Maybe<Scalars['Boolean']>
    radar?: Maybe<Scalars['Boolean']>
    theme?: Maybe<Scalars['Boolean']>
    topic?: Maybe<Scalars['Boolean']>
}

export type SearchItemHighLightsType = {
    __typename?: 'SearchItemHighLightsType'
    title?: Maybe<Array<Maybe<Scalars['String']>>>
    content?: Maybe<Array<Maybe<Scalars['String']>>>
    keywords?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SearchItemType = {
    __typename?: 'SearchItemType'
    id?: Maybe<Scalars['String']>
    entityId?: Maybe<Scalars['Int']>
    type?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    date?: Maybe<Scalars['DateTime']>
    highlights?: Maybe<SearchItemHighLightsType>
    meta?: Maybe<Scalars['JSON']>
    customerFrameworks?: Maybe<Array<CustomerFrameworkType>>
}

export type ShadowEmployeeType = {
    __typename?: 'ShadowEmployeeType'
    id?: Maybe<Scalars['Int']>
    name?: Maybe<Scalars['String']>
    customer?: Maybe<CustomerType>
}

export type SingleLinkedItemType = {
    __typename?: 'SingleLinkedItemType'
    type?: Maybe<Scalars['String']>
    entityType?: Maybe<Scalars['String']>
    item?: Maybe<LinkedItemType>
}

export enum SortDirectionEnumType {
    asc = 'ASC',
    desc = 'DESC',
}

export type SubTaskFieldsInputType = {
    name?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    tagIds?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export type TaskExportType = {
    __typename?: 'TaskExportType'
    token: Scalars['String']
    filename: Scalars['String']
}

export type TaskFiltersInputType = {
    departmentIds?: Maybe<Array<Scalars['Int']>>
    forCustomer?: Maybe<Scalars['Boolean']>
    search?: Maybe<Scalars['String']>
    forEmployeeIds?: Maybe<Array<Maybe<Scalars['Int']>>>
    completedOnly?: Maybe<Scalars['Boolean']>
    onlyOverdueTasks?: Maybe<Scalars['Boolean']>
    forLinkedItem?: Maybe<ForLinkedItemFilterInputType>
    linkedItemType?: Maybe<LinkedTaskItem>
    dueDateFrom?: Maybe<Scalars['DateTime']>
    dueDateTo?: Maybe<Scalars['DateTime']>
    forMonitoring?: Maybe<Scalars['Boolean']>
    linkedItemInboxFromDate?: Maybe<Scalars['DateTime']>
    linkedItemInboxToDate?: Maybe<Scalars['DateTime']>
    tagIds?: Maybe<Array<Scalars['Int']>>
}

export type TaskForMonthFilterInputType = {
    status?: Maybe<ReviewTaskStatus>
    linkType?: Maybe<LinkedTaskItem>
    isGenericType?: Maybe<Scalars['Boolean']>
    departmentId?: Maybe<Scalars['Int']>
}

export type TaskForMonthSortInputType = {
    createdAt?: Maybe<SortDirectionEnumType>
    priority?: Maybe<SortDirectionEnumType>
}

export type TaskMotivationFieldsInputType = {
    description?: Maybe<Scalars['String']>
    files?: Maybe<Array<Scalars['Upload']>>
    monitoringReportIds?: Maybe<Array<Scalars['Int']>>
}

export enum TaskPriority {
    low = 'LOW',
    medium = 'MEDIUM',
    high = 'HIGH',
}

export enum TaskRepeatFrequency {
    weekly = 'WEEKLY',
    biWeekly = 'BI_WEEKLY',
    monthly = 'MONTHLY',
    quarterly = 'QUARTERLY',
    halfyearly = 'HALFYEARLY',
    yearly = 'YEARLY',
}

export type TaskSortInputType = {
    name?: Maybe<SortDirectionEnumType>
    dueAt?: Maybe<SortDirectionEnumType>
    startAt?: Maybe<SortDirectionEnumType>
    priority?: Maybe<SortDirectionEnumType>
}

export type TaskSummaryPerMonthType = {
    __typename?: 'TaskSummaryPerMonthType'
    id: Scalars['ID']
    month: Scalars['Int']
    year: Scalars['Int']
    openTaskCount: Scalars['Int']
    completedTaskCount: Scalars['Int']
}

export type TaskTagFiltersInputType = {
    search?: Maybe<Scalars['String']>
}

export type TaskTagType = {
    __typename?: 'TaskTagType'
    id: Scalars['Int']
    name: Scalars['String']
}

export type TaskTemplateType = {
    __typename?: 'TaskTemplateType'
    id: Scalars['Int']
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    tags?: Maybe<Array<TaskTagType>>
    totalTaskCount: Scalars['Int']
    isSubTaskTemplate: Scalars['Boolean']
    subTaskTemplates?: Maybe<Array<TaskTemplateType>>
    parentTaskTemplate?: Maybe<TaskTemplateType>
    customer: CustomerType
}

export type TaskType = {
    __typename?: 'TaskType'
    id: Scalars['Int']
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
    priority: TaskPriority
    startAt?: Maybe<Scalars['DateTime']>
    dueAt?: Maybe<Scalars['DateTime']>
    completedAt?: Maybe<Scalars['DateTime']>
    reopenedAt?: Maybe<Scalars['DateTime']>
    createdAt: Scalars['DateTime']
    ignoredAt?: Maybe<Scalars['DateTime']>
    ignoredBy?: Maybe<EmployeeType>
    ignoredReason?: Maybe<Scalars['String']>
    forMonitoring: Scalars['Boolean']
    subTasks?: Maybe<Array<TaskType>>
    isSubTask: Scalars['Boolean']
    isParentTask: Scalars['Boolean']
    parentTask?: Maybe<TaskType>
    totalSubTaskCount: Scalars['Int']
    completedSubTaskCount: Scalars['Int']
    isReviewTask: Scalars['Boolean']
    reviewTask?: Maybe<TaskType>
    repeats?: Maybe<TaskRepeatFrequency>
    createdBy?: Maybe<EmployeeType>
    department: DepartmentType
    linkedItem?: Maybe<LinkedTaskItemType>
    tags: Array<TaskTagType>
    employees: Array<EmployeeType>
    linkedTasksToReview: Array<TaskType>
    linkedAlertsToReview: Array<AlertType>
    taskTemplate?: Maybe<TaskTemplateType>
    consultantTaskTemplate?: Maybe<ConsultantTaskTemplateType>
}

export type ThemeFilters = {
    search?: Maybe<Scalars['String']>
    customerFrameworkIds?: Maybe<Array<Scalars['Int']>>
    departmentId?: Maybe<Scalars['Int']>
    externallyCuratedOnly?: Maybe<Scalars['Boolean']>
    filterPinned?: Maybe<Scalars['Boolean']>
}

export type ThemeSort = {
    name?: Maybe<SortDirectionEnumType>
    assessmentCoverage?: Maybe<SortDirectionEnumType>
}

export type ThemeTopicAssessmentCoverageScoresType = {
    __typename?: 'ThemeTopicAssessmentCoverageScoresType'
    assessmentSatisfies: Scalars['Int']
    assessmentSatisfiesPartly: Scalars['Int']
    assessmentAlmostSatisfies: Scalars['Int']
    assessmentAlmostSatisfiesPartly: Scalars['Int']
    assessmentNotSatisfies: Scalars['Int']
    assessmentNotSatisfiesPartly: Scalars['Int']
    assessmentNotGiven: Scalars['Int']
    notApplicable: Scalars['Int']
    total: Scalars['Int']
    lastUpdatedAt?: Maybe<Scalars['DateTime']>
}

export type ThemeTopicAssessmentCoverageType = {
    __typename?: 'ThemeTopicAssessmentCoverageType'
    design?: Maybe<ThemeTopicAssessmentCoverageScoresType>
    effectiveness?: Maybe<ThemeTopicAssessmentCoverageScoresType>
}

export type ThemeType = {
    __typename?: 'ThemeType'
    id: Scalars['Int']
    name?: Maybe<Scalars['String']>
    isPinned?: Maybe<Scalars['Boolean']>
    assessmentCoverage?: Maybe<AssessmentCoverageType>
    customerFrameworks: Array<CustomerFrameworkType>
    curatedBy: LegalFrameworkCuratedByEnum
    topicAssessmentCoverage?: Maybe<ThemeTopicAssessmentCoverageType>
}

export type ThemeTypeAssessmentCoverageArgs = {
    departmentId: Scalars['Int']
    customerFrameworkIds?: Maybe<Array<Scalars['Int']>>
}

export type ThemeTypeCustomerFrameworksArgs = {
    customerSlug?: Maybe<Scalars['String']>
    departmentId?: Maybe<Scalars['Int']>
}

export type ThemeTypeTopicAssessmentCoverageArgs = {
    departmentId: Scalars['Int']
    customerFrameworkIds?: Maybe<Array<Scalars['Int']>>
}

export type TokenResponseType = {
    __typename?: 'TokenResponseType'
    user?: Maybe<UserType>
    token?: Maybe<Scalars['String']>
    accessGroup?: Maybe<AccessGroup>
    hasCustomerGroup: Scalars['Boolean']
    employees?: Maybe<Array<GenericEmployeeType>>
}

export enum TopicAssessmentDeLinkedItemType {
    note = 'NOTE',
    controlmeasure = 'CONTROLMEASURE',
}

export enum TopicAssessmentDesignOrEffectiveNessType {
    design = 'design',
    effectiveness = 'effectiveness',
}

export type TopicAssessmentDocumentType = {
    __typename?: 'TopicAssessmentDocumentType'
    id: Scalars['Int']
    archivedAt?: Maybe<Scalars['DateTime']>
    topicAssessment: TopicAssessmentType
    file?: Maybe<FileType>
    createdByEmployee?: Maybe<EmployeeType>
}

export enum TopicAssessmentGradeType {
    assessmentSatisfies = 'assessmentSatisfies',
    assessmentAlmostSatisfies = 'assessmentAlmostSatisfies',
    assessmentNotSatisfies = 'assessmentNotSatisfies',
    assessmentNotGiven = 'assessmentNotGiven',
}

export type TopicAssessmentLinkedItemType = TopicNoteType | TopicControlMeasureType

export type TopicAssessmentType = {
    __typename?: 'TopicAssessmentType'
    id: Scalars['Int']
    description?: Maybe<Scalars['String']>
    grade?: Maybe<TopicAssessmentGradeType>
    updatedAt: Scalars['DateTime']
    topicAssessmentDesignOrEffectiveNessType: TopicAssessmentDesignOrEffectiveNessType
    topicAssessmentLinkedItemType: TopicAssessmentDesignOrEffectiveNessType
    topic: TopicType
    topicAssessmentDocuments?: Maybe<Array<TopicAssessmentDocumentType>>
    documentsCount: Scalars['Int']
    linkedItem: TopicAssessmentLinkedItemType
    monitoringReport?: Maybe<MonitoringReportType>
}

export type TopicControlMeasureFilters = {
    controlId?: Maybe<Scalars['Int']>
}

export type TopicControlMeasureSort = {
    topic?: Maybe<SortDirectionEnumType>
}

export type TopicControlMeasureType = {
    __typename?: 'TopicControlMeasureType'
    id: Scalars['Int']
    topic: TopicType
    control: ControlType
    department: DepartmentType
    articles: Array<LawArticleType>
    assessments?: Maybe<Array<TopicAssessmentType>>
    linkedNestedChildTopicControlMeasures?: Maybe<Array<TopicControlMeasureType>>
    linkedChildTopicControlMeasures?: Maybe<Array<TopicControlMeasureType>>
    linkedNestedParentTopicControlMeasures?: Maybe<Array<TopicControlMeasureType>>
    linkedParentTopicControlMeasures?: Maybe<Array<TopicControlMeasureType>>
    articlesAreAll: Scalars['Boolean']
}

export type TopicFilters = {
    search?: Maybe<Scalars['String']>
    themeIds?: Maybe<Array<Scalars['Int']>>
    customerFrameworkIds?: Maybe<Array<Scalars['Int']>>
    departmentId?: Maybe<Scalars['Int']>
    excludeNonApplicableTopics?: Maybe<Scalars['Boolean']>
    onlyUsedInAssessments?: Maybe<Scalars['Boolean']>
    forAbstractLawArticleId?: Maybe<Scalars['Int']>
    curatedBy?: Maybe<LegalFrameworkCuratedByEnum>
    designGrades?: Maybe<Array<DepartmentTopicDataGradeTypeEnum>>
    effectivenessGrades?: Maybe<Array<DepartmentTopicDataGradeTypeEnum>>
    noTopicAssessment?: Maybe<Scalars['Boolean']>
}

export type TopicNonApplicableInputType = {
    topicId: Scalars['Int']
    reason?: Maybe<Scalars['String']>
}

export type TopicNoteDocumentType = {
    __typename?: 'TopicNoteDocumentType'
    id: Scalars['Int']
    archivedAt?: Maybe<Scalars['DateTime']>
    topicNote: TopicNoteType
    file?: Maybe<FileType>
    createdByEmployee?: Maybe<EmployeeType>
}

export type TopicNoteType = {
    __typename?: 'TopicNoteType'
    id: Scalars['Int']
    title: Scalars['String']
    description?: Maybe<Scalars['String']>
    topic: TopicType
    department: DepartmentType
    topicNoteDocuments?: Maybe<Array<TopicNoteDocumentType>>
    articles: Array<LawArticleType>
    assessments?: Maybe<Array<TopicAssessmentType>>
    articlesAreAll: Scalars['Boolean']
}

export type TopicsForThemeFilters = {
    departmentId?: Maybe<Scalars['Int']>
    designGrades?: Maybe<Array<DepartmentTopicDataGradeTypeEnum>>
    effectivenessGrades?: Maybe<Array<DepartmentTopicDataGradeTypeEnum>>
    noTopicAssessment?: Maybe<Scalars['Boolean']>
    search?: Maybe<Scalars['String']>
    filterPinned?: Maybe<Scalars['Boolean']>
}

export type TopicSort = {
    name?: Maybe<Scalars['String']>
    assessmentCoverage?: Maybe<Scalars['String']>
    theme?: Maybe<Scalars['String']>
}

export type TopicType = {
    __typename?: 'TopicType'
    id: Scalars['Int']
    createdAt: Scalars['DateTime']
    name: Scalars['String']
    abstract?: Maybe<Scalars['String']>
    linkedItems?: Maybe<Array<GroupedItemType>>
    theme: ThemeType
    isPinned?: Maybe<Scalars['Boolean']>
    customerFrameworks: Array<CustomerFrameworkType>
    expiredLawArticleIds: Array<Scalars['Int']>
    linkedLawArticles: Array<LawArticleType>
    unassessedArticles: Array<LawArticleType>
    assessmentCoverage?: Maybe<AssessmentCoverageType>
    hasCompliance?: Maybe<Scalars['Boolean']>
    nonApplicable?: Maybe<NonApplicableTopicType>
    /** @deprecated Replaced by returning null or entity on field "applicable" */
    isApplicable?: Maybe<Scalars['Boolean']>
    assessments: Array<AssessmentType>
    curatedBy: LegalFrameworkCuratedByEnum
    departments?: Maybe<Array<DepartmentType>>
    linkedAlerts?: Maybe<Array<AlertType>>
    parentTopicControlMeasures?: Maybe<Array<TopicControlMeasureType>>
    topicControlMeasures?: Maybe<Array<TopicControlMeasureType>>
    topicNotes?: Maybe<Array<TopicNoteType>>
    departmentTopicData?: Maybe<DepartmentTopicDataType>
    linkedRadarItems?: Maybe<Array<RadarItemType>>
    /** open task with the closest deadline */
    nextTask?: Maybe<TaskType>
    /** open task with the highest prio */
    highestPrioTask?: Maybe<TaskType>
    highestRisk?: Maybe<RiskType>
}

export type TopicTypeLinkedItemsArgs = {
    customerSlug?: Maybe<Scalars['String']>
    lawArticlesOnly?: Maybe<Scalars['Boolean']>
    filterDeletedArticles?: Maybe<Scalars['Boolean']>
}

export type TopicTypeCustomerFrameworksArgs = {
    customerSlug?: Maybe<Scalars['String']>
    departmentId?: Maybe<Scalars['Int']>
}

export type TopicTypeUnassessedArticlesArgs = {
    departmentId: Scalars['Int']
}

export type TopicTypeAssessmentCoverageArgs = {
    departmentId: Scalars['Int']
}

export type TopicTypeAssessmentsArgs = {
    departmentId: Scalars['Int']
}

export type TopicTypeParentTopicControlMeasuresArgs = {
    departmentId: Scalars['Int']
}

export type TopicTypeTopicControlMeasuresArgs = {
    departmentId: Scalars['Int']
}

export type TopicTypeTopicNotesArgs = {
    departmentId: Scalars['Int']
}

export type TopicTypeDepartmentTopicDataArgs = {
    departmentId: Scalars['Int']
}

export type TopicTypeNextTaskArgs = {
    departmentId: Scalars['Int']
}

export type TopicTypeHighestPrioTaskArgs = {
    departmentId: Scalars['Int']
}

export type TopicTypeHighestRiskArgs = {
    departmentId: Scalars['Int']
}

export type UploadControlDocumentFields = {
    file?: Maybe<Scalars['Upload']>
}

export type UserMergeRequestType = {
    __typename?: 'UserMergeRequestType'
    id: Scalars['Int']
    requestedBy: UserType
    baseUser: UserType
    mergingUser: UserType
    mergingUserCustomerNames?: Maybe<Array<Scalars['String']>>
}

export enum UserRole {
    editorAdministrator = 'EDITOR_ADMINISTRATOR',
    editor = 'EDITOR',
    editorReader = 'EDITOR_READER',
    consultant = 'CONSULTANT',
    consultantAdministrator = 'CONSULTANT_ADMINISTRATOR',
    prospect = 'PROSPECT',
}

export enum UserStatus {
    active = 'ACTIVE',
    inactive = 'INACTIVE',
    invited = 'INVITED',
    passive = 'PASSIVE',
}

export type UserType = {
    __typename?: 'UserType'
    id: Scalars['Int']
    email: Scalars['String']
    status?: Maybe<UserStatus>
    invitedAt?: Maybe<Scalars['DateTime']>
    activatedAt?: Maybe<Scalars['DateTime']>
    lastLoginAt?: Maybe<Scalars['DateTime']>
    lastActiveAt?: Maybe<Scalars['DateTime']>
    lastAppVersionSeenAt?: Maybe<Scalars['DateTime']>
    lastAppUpdateSeen?: Maybe<LastAppUpdateSeenType>
    language: Language
    accessGroup: AccessGroup
    role?: Maybe<UserRole>
    profile: ProfileType
    loggedInWithSSO: Scalars['Boolean']
    ssoLoginEnabled: Scalars['Boolean']
    hasActiveMergeRequest: Scalars['Boolean']
    isCustomerConsultant: Scalars['Boolean']
}

export type WidgetMetadataInputType = {
    name?: Maybe<Scalars['String']>
    departmentIds?: Maybe<Array<Scalars['Int']>>
    allDepartments?: Maybe<Scalars['Boolean']>
}

export type EditLanguageMutationVariables = Exact<{
    id: Scalars['Int']
    fields: EditEmployeeFieldsInputType
}>

export type EditLanguageMutation = { __typename?: 'Mutation' } & {
    editEmployee?: Maybe<
        { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                user: { __typename?: 'UserType' } & Pick<UserType, 'id' | 'language'>
            }
    >
}

export type DeleteExpiredArticleMutationVariables = Exact<{
    abstractLawArticleId: Scalars['Int']
    topicId: Scalars['Int']
}>

export type DeleteExpiredArticleMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteExpiredArticle'>

export type TopicWithLawArticlesQueryVariables = Exact<{
    topicId: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type TopicWithLawArticlesQuery = { __typename?: 'Query' } & {
    topic?: Maybe<
        { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'> & {
                linkedLawArticles: Array<
                    { __typename?: 'LawArticleType' } & Pick<
                        LawArticleType,
                        'id' | 'abstractLawArticleId' | 'title' | 'isSingleArticle' | 'updatedAt'
                    > & {
                            expired?: Maybe<
                                { __typename?: 'LawArticleExpiredObjectType' } & Pick<
                                    LawArticleExpiredObjectType,
                                    'expiredAt' | 'willBeDeletedAt'
                                >
                            >
                            assessments: Array<{ __typename?: 'AssessmentType' } & Pick<AssessmentType, 'id' | 'name'>>
                            sectionParents?: Maybe<
                                Array<
                                    { __typename?: 'LawSectionParentType' } & Pick<
                                        LawSectionParentType,
                                        'id' | 'name' | 'depth'
                                    >
                                >
                            >
                            lawGroup: { __typename?: 'LawGroupType' } & Pick<LawGroupType, 'id' | 'name' | 'order'>
                            law?: Maybe<{ __typename?: 'LawType' } & Pick<LawType, 'id' | 'abstractLawId' | 'name'>>
                        }
                >
            }
    >
}

export type DeleteAssessmentSectionInAssessmentMutationVariables = Exact<{
    assessmentSectionId: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type DeleteAssessmentSectionInAssessmentMutation = { __typename?: 'Mutation' } & {
    deleteAssessmentSection?: Maybe<
        { __typename?: 'AssessmentType' } & Pick<AssessmentType, 'id' | 'name'> & {
                assessmentSections?: Maybe<
                    Array<{ __typename?: 'AssessmentSectionType' } & Pick<AssessmentSectionType, 'id'>>
                >
            }
    >
}

export type AssessmentSectionsQueryVariables = Exact<{
    assessmentId: Scalars['Int']
    departmentId: Scalars['Int']
    take?: Maybe<Scalars['Int']>
    skip?: Maybe<Scalars['Int']>
}>

export type AssessmentSectionsQuery = { __typename?: 'Query' } & {
    assessmentSections?: Maybe<
        { __typename?: 'PaginatedAssessmentSectionType' } & Pick<
            PaginatedAssessmentSectionType,
            'count' | 'totalCount' | 'hasNextPage'
        > & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'AssessmentSectionType' } & Pick<AssessmentSectionType, 'id' | 'order'> & {
                                norms?: Maybe<
                                    Array<
                                        { __typename?: 'AssessmentSectionTopicType' } & Pick<
                                            AssessmentSectionTopicType,
                                            'selectedCount' | 'totalCount'
                                        > & {
                                                norm?: Maybe<
                                                    { __typename?: 'AssessmentTopicType' } & Pick<
                                                        AssessmentTopicType,
                                                        'id'
                                                    > & {
                                                            assessmentArticles?: Maybe<
                                                                Array<
                                                                    { __typename?: 'AssessmentArticleType' } & Pick<
                                                                        AssessmentArticleType,
                                                                        'id'
                                                                    > & {
                                                                            linkedLawArticles: {
                                                                                __typename?: 'LawArticleType'
                                                                            } & Pick<
                                                                                LawArticleType,
                                                                                'id' | 'abstractLawArticleId'
                                                                            >
                                                                            assessmentTopic?: Maybe<
                                                                                {
                                                                                    __typename?: 'AssessmentTopicType'
                                                                                } & Pick<AssessmentTopicType, 'id'> & {
                                                                                        topic?: Maybe<
                                                                                            {
                                                                                                __typename?: 'TopicType'
                                                                                            } & Pick<TopicType, 'id'>
                                                                                        >
                                                                                    }
                                                                            >
                                                                        }
                                                                >
                                                            >
                                                            topic?: Maybe<
                                                                { __typename?: 'TopicType' } & Pick<
                                                                    TopicType,
                                                                    'id' | 'name' | 'expiredLawArticleIds'
                                                                > & {
                                                                        theme: { __typename?: 'ThemeType' } & Pick<
                                                                            ThemeType,
                                                                            'id' | 'name'
                                                                        >
                                                                    }
                                                            >
                                                        }
                                                >
                                            }
                                    >
                                >
                                risks?: Maybe<
                                    Array<
                                        { __typename?: 'RiskType' } & Pick<
                                            RiskType,
                                            | 'id'
                                            | 'name'
                                            | 'description'
                                            | 'isAccepted'
                                            | 'severity'
                                            | 'severityBruto'
                                            | 'status'
                                        > & {
                                                linkedControls?: Maybe<
                                                    Array<
                                                        { __typename?: 'RiskControlType' } & Pick<
                                                            RiskControlType,
                                                            'id'
                                                        > & {
                                                                control?: Maybe<
                                                                    { __typename?: 'ControlType' } & Pick<
                                                                        ControlType,
                                                                        'id' | 'name' | 'type' | 'revisionDate'
                                                                    >
                                                                >
                                                            }
                                                    >
                                                >
                                            }
                                    >
                                >
                                controls?: Maybe<
                                    Array<
                                        { __typename?: 'ControlType' } & Pick<
                                            ControlType,
                                            'id' | 'name' | 'type' | 'description' | 'createdAt' | 'revisionDate'
                                        > & {
                                                linkedRisks?: Maybe<
                                                    Array<
                                                        { __typename?: 'RiskControlType' } & Pick<
                                                            RiskControlType,
                                                            'id' | 'description'
                                                        > & {
                                                                risk?: Maybe<
                                                                    { __typename?: 'RiskType' } & Pick<
                                                                        RiskType,
                                                                        | 'id'
                                                                        | 'name'
                                                                        | 'severity'
                                                                        | 'severityBruto'
                                                                        | 'isAccepted'
                                                                    >
                                                                >
                                                            }
                                                    >
                                                >
                                            }
                                    >
                                >
                            }
                    >
                >
            }
    >
}

export type CreateAssessmentSectionMutationVariables = Exact<{
    assessmentId: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type CreateAssessmentSectionMutation = { __typename?: 'Mutation' } & {
    createAssessmentSection?: Maybe<
        { __typename?: 'AssessmentSectionType' } & Pick<AssessmentSectionType, 'id' | 'order'>
    >
}

export type CreateComplianceExportMutationVariables = Exact<{
    departmentIds: Array<Scalars['Int']> | Scalars['Int']
    customerFrameworkIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
}>

export type CreateComplianceExportMutation = { __typename?: 'Mutation' } & {
    createComplianceExport?: Maybe<
        { __typename?: 'ComplianceExportType' } & Pick<ComplianceExportType, 'token' | 'filename'>
    >
}

export type CreateControlMutationVariables = Exact<{
    fields: CreateControlFieldsType
    departmentId: Scalars['Int']
}>

export type CreateControlMutation = { __typename?: 'Mutation' } & {
    createControl?: Maybe<
        { __typename?: 'ControlType' } & Pick<ControlType, 'id' | 'name' | 'type' | 'createdAt'> & {
                assessments?: Maybe<Array<{ __typename?: 'AssessmentType' } & Pick<AssessmentType, 'id' | 'name'>>>
            }
    >
}

export type ControlQueryVariables = Exact<{
    id: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type ControlQuery = { __typename?: 'Query' } & {
    control?: Maybe<
        { __typename: 'ControlType' } & Pick<
            ControlType,
            | 'id'
            | 'name'
            | 'type'
            | 'hasControlLinkedControls'
            | 'description'
            | 'visibility'
            | 'startDate'
            | 'revisionDate'
            | 'createdAt'
            | 'updatedAt'
            | 'archivedAt'
            | 'archivedReason'
        > & {
                nextTask?: Maybe<{ __typename?: 'TaskType' } & Pick<TaskType, 'id' | 'dueAt' | 'priority'>>
                highestPrioTask?: Maybe<{ __typename?: 'TaskType' } & Pick<TaskType, 'id' | 'priority'>>
                visibleToDepartments?: Maybe<
                    Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>
                >
                department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                departmentsUsingControl?: Maybe<
                    Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>
                >
                controlDocuments?: Maybe<
                    Array<
                        { __typename?: 'ControlDocumentType' } & Pick<ControlDocumentType, 'id' | 'archivedAt'> & {
                                file?: Maybe<
                                    { __typename?: 'FileType' } & Pick<FileType, 'id' | 'name' | 'path' | 'mimetype'>
                                >
                            }
                    >
                >
                responsibleEmployees?: Maybe<
                    Array<
                        { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                                user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                        profile: { __typename?: 'ProfileType' } & Pick<ProfileType, 'id' | 'fullName'>
                                    }
                            }
                    >
                >
                responsibleShadowEmployees?: Maybe<
                    Array<{ __typename?: 'ShadowEmployeeType' } & Pick<ShadowEmployeeType, 'id' | 'name'>>
                >
                parentControl?: Maybe<{ __typename?: 'ControlType' } & Pick<ControlType, 'id' | 'name' | 'type'>>
                childControls?: Maybe<Array<{ __typename?: 'ControlType' } & Pick<ControlType, 'id' | 'name' | 'type'>>>
            }
    >
}

export type CreateControlExportMutationVariables = Exact<{
    departmentIds: Array<Scalars['Int']> | Scalars['Int']
    filters?: Maybe<ControlFilters>
}>

export type CreateControlExportMutation = { __typename?: 'Mutation' } & {
    createControlExport?: Maybe<{ __typename?: 'ControlExportType' } & Pick<ControlExportType, 'token' | 'filename'>>
}

export type ControlTableFragmentFragment = { __typename?: 'ControlType' } & Pick<
    ControlType,
    'id' | 'name' | 'type' | 'visibility' | 'createdAt' | 'revisionDate' | 'archivedAt' | 'amountOfLinkedTopics'
> & {
        visibleToDepartments?: Maybe<Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>>
        department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
        assessments?: Maybe<Array<{ __typename?: 'AssessmentType' } & Pick<AssessmentType, 'id' | 'name'>>>
        archivedBy?: Maybe<
            { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                    user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                            profile: { __typename?: 'ProfileType' } & Pick<ProfileType, 'id' | 'fullName' | 'avatar'>
                        }
                }
        >
        parentControl?: Maybe<{ __typename?: 'ControlType' } & Pick<ControlType, 'id' | 'name' | 'type'>>
        openTasks?: Maybe<Array<{ __typename?: 'TaskType' } & Pick<TaskType, 'id'>>>
    }

export type ControlTopicsQueryVariables = Exact<{
    departmentId: Scalars['Int']
    filters?: Maybe<TopicControlMeasureFilters>
    skip: Scalars['Int']
    take: Scalars['Int']
    sort?: Maybe<TopicControlMeasureSort>
}>

export type ControlTopicsQuery = { __typename?: 'Query' } & {
    topicControlMeasures?: Maybe<
        { __typename?: 'PaginatedTopicControlMeasureType' } & Pick<
            PaginatedTopicControlMeasureType,
            'count' | 'totalCount' | 'hasNextPage'
        > & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'TopicControlMeasureType' } & Pick<
                            TopicControlMeasureType,
                            'id' | 'articlesAreAll'
                        > & {
                                topic: { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'> & {
                                        linkedLawArticles: Array<
                                            { __typename?: 'LawArticleType' } & Pick<LawArticleType, 'id'>
                                        >
                                    }
                                articles: Array<{ __typename?: 'LawArticleType' } & Pick<LawArticleType, 'id'>>
                                assessments?: Maybe<
                                    Array<
                                        {
                                            __typename?: 'TopicAssessmentType'
                                        } & TopicAssessmentIconContainerAssessmentFragment
                                    >
                                >
                                linkedChildTopicControlMeasures?: Maybe<
                                    Array<
                                        { __typename?: 'TopicControlMeasureType' } & Pick<
                                            TopicControlMeasureType,
                                            'id'
                                        > & {
                                                control: { __typename?: 'ControlType' } & Pick<
                                                    ControlType,
                                                    'id' | 'name' | 'type'
                                                >
                                                assessments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                                            TopicAssessmentType,
                                                            'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                                linkedParentTopicControlMeasures?: Maybe<
                                    Array<
                                        { __typename?: 'TopicControlMeasureType' } & Pick<
                                            TopicControlMeasureType,
                                            'id'
                                        > & {
                                                control: { __typename?: 'ControlType' } & Pick<
                                                    ControlType,
                                                    'id' | 'name' | 'type'
                                                >
                                                assessments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                                            TopicAssessmentType,
                                                            'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                                linkedNestedChildTopicControlMeasures?: Maybe<
                                    Array<
                                        { __typename?: 'TopicControlMeasureType' } & Pick<
                                            TopicControlMeasureType,
                                            'id'
                                        > & {
                                                control: { __typename?: 'ControlType' } & Pick<
                                                    ControlType,
                                                    'id' | 'name' | 'type'
                                                >
                                                assessments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                                            TopicAssessmentType,
                                                            'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                                linkedNestedParentTopicControlMeasures?: Maybe<
                                    Array<
                                        { __typename?: 'TopicControlMeasureType' } & Pick<
                                            TopicControlMeasureType,
                                            'id'
                                        > & {
                                                control: { __typename?: 'ControlType' } & Pick<
                                                    ControlType,
                                                    'id' | 'name' | 'type'
                                                >
                                                assessments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                                            TopicAssessmentType,
                                                            'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                            }
                    >
                >
            }
    >
}

export type UnlinkNestedControlsMutationVariables = Exact<{
    controlId: Scalars['Int']
    unlinkControlIds: Array<Scalars['Int']> | Scalars['Int']
    departmentId: Scalars['Int']
}>

export type UnlinkNestedControlsMutation = { __typename?: 'Mutation' } & {
    unlinkNestedControls?: Maybe<
        { __typename?: 'ControlType' } & Pick<ControlType, 'id' | 'linkedControlsCount'> & {
                parentControl?: Maybe<{ __typename?: 'ControlType' } & Pick<ControlType, 'id' | 'name' | 'type'>>
                childControls?: Maybe<Array<{ __typename?: 'ControlType' } & Pick<ControlType, 'id' | 'name' | 'type'>>>
            }
    >
}

export type LinkableControlsQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    sort?: Maybe<ControlSort>
    filters?: Maybe<ControlFilters>
    activeDepartmentId: Scalars['Int']
}>

export type LinkableControlsQuery = { __typename?: 'Query' } & {
    controls?: Maybe<
        { __typename?: 'PaginatedControlType' } & Pick<PaginatedControlType, 'count' | 'totalCount' | 'hasNextPage'> & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'ControlType' } & Pick<
                            ControlType,
                            'id' | 'name' | 'type' | 'linkedControlsCount'
                        >
                    >
                >
            }
    >
}

export type ArchiveControlsMutationVariables = Exact<{
    ids: Array<Scalars['Int']> | Scalars['Int']
    reason?: Maybe<Scalars['String']>
    departmentId: Scalars['Int']
}>

export type ArchiveControlsMutation = { __typename?: 'Mutation' } & {
    archiveControls?: Maybe<
        Array<
            { __typename?: 'ControlType' } & Pick<ControlType, 'id' | 'archivedAt' | 'archivedReason'> & {
                    parentControl?: Maybe<{ __typename?: 'ControlType' } & Pick<ControlType, 'id'>>
                    childControls?: Maybe<Array<{ __typename?: 'ControlType' } & Pick<ControlType, 'id'>>>
                }
        >
    >
}

export type UnarchiveControlMutationVariables = Exact<{
    id: Scalars['Int']
}>

export type UnarchiveControlMutation = { __typename?: 'Mutation' } & {
    unarchiveControl?: Maybe<{ __typename?: 'ControlType' } & Pick<ControlType, 'id' | 'archivedAt'>>
}

export type AddControlToTopicsMutationVariables = Exact<{
    controlId: Scalars['Int']
    topicIds: Array<Scalars['Int']> | Scalars['Int']
    departmentId: Scalars['Int']
}>

export type AddControlToTopicsMutation = { __typename?: 'Mutation' } & {
    addControlToTopics?: Maybe<{ __typename?: 'ControlType' } & Pick<ControlType, 'id'>>
}

export type AllSelectableTopicsQueryVariables = Exact<{
    filters?: Maybe<AllTopicFilters>
    customerSlug: Scalars['String']
}>

export type AllSelectableTopicsQuery = { __typename?: 'Query' } & {
    minimalAllTopics?: Maybe<
        Array<
            { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'> & {
                    theme: { __typename?: 'ThemeType' } & Pick<ThemeType, 'id' | 'name'>
                }
        >
    >
}

export type AssessmentsForControlQueryVariables = Exact<{
    id: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type AssessmentsForControlQuery = { __typename?: 'Query' } & {
    control?: Maybe<
        { __typename?: 'ControlType' } & Pick<ControlType, 'id'> & {
                assessments?: Maybe<
                    Array<
                        { __typename?: 'AssessmentType' } & Pick<AssessmentType, 'id' | 'name'> & {
                                norms?: Maybe<
                                    Array<
                                        { __typename?: 'AssessmentTopicType' } & Pick<AssessmentTopicType, 'id'> & {
                                                topic?: Maybe<
                                                    { __typename?: 'TopicType' } & Pick<
                                                        TopicType,
                                                        'id' | 'name' | 'expiredLawArticleIds'
                                                    > & {
                                                            assessmentCoverage?: Maybe<
                                                                { __typename?: 'AssessmentCoverageType' } & Pick<
                                                                    AssessmentCoverageType,
                                                                    'assessed' | 'noted' | 'total'
                                                                >
                                                            >
                                                            theme: { __typename?: 'ThemeType' } & Pick<
                                                                ThemeType,
                                                                'id' | 'name'
                                                            >
                                                        }
                                                >
                                            }
                                    >
                                >
                            }
                    >
                >
                linkedRisks?: Maybe<
                    Array<
                        { __typename?: 'RiskControlType' } & Pick<RiskControlType, 'id' | 'description'> & {
                                risk?: Maybe<
                                    { __typename?: 'RiskType' } & Pick<
                                        RiskType,
                                        'id' | 'name' | 'isAccepted' | 'severity' | 'severityBruto'
                                    > & {
                                            assessment?: Maybe<
                                                { __typename?: 'AssessmentType' } & Pick<AssessmentType, 'id'>
                                            >
                                        }
                                >
                            }
                    >
                >
            }
    >
}

export type GetControlsQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    sort?: Maybe<ControlSort>
    filters?: Maybe<ControlFilters>
    departmentId: Scalars['Int']
    withChildControls?: Scalars['Boolean']
}>

export type GetControlsQuery = { __typename?: 'Query' } & {
    controls?: Maybe<
        { __typename?: 'PaginatedControlType' } & Pick<PaginatedControlType, 'count' | 'totalCount' | 'hasNextPage'> & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'ControlType' } & {
                            childControls?: Maybe<
                                Array<
                                    { __typename?: 'ControlType' } & {
                                        childControls?: Maybe<
                                            Array<{ __typename?: 'ControlType' } & ControlTableFragmentFragment>
                                        >
                                    } & ControlTableFragmentFragment
                                >
                            >
                        } & ControlTableFragmentFragment
                    >
                >
            }
    >
}

export type ControlsQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    sort?: Maybe<ControlSort>
    filters?: Maybe<ControlFilters>
    departmentId: Scalars['Int']
}>

export type ControlsQuery = { __typename?: 'Query' } & {
    controls?: Maybe<
        { __typename?: 'PaginatedControlType' } & Pick<PaginatedControlType, 'count' | 'totalCount' | 'hasNextPage'> & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'ControlType' } & Pick<ControlType, 'id' | 'name' | 'type'> & {
                                assessments?: Maybe<
                                    Array<{ __typename?: 'AssessmentType' } & Pick<AssessmentType, 'id' | 'name'>>
                                >
                                assessmentSections?: Maybe<
                                    Array<{ __typename?: 'AssessmentSectionType' } & Pick<AssessmentSectionType, 'id'>>
                                >
                            }
                    >
                >
            }
    >
}

export type CreateRisksExportMutationVariables = Exact<{
    currentDepartmentId: Scalars['Int']
    departmentIdsFilter?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
}>

export type CreateRisksExportMutation = { __typename?: 'Mutation' } & {
    createRisksExport?: Maybe<{ __typename?: 'RiskExportType' } & Pick<RiskExportType, 'token' | 'filename'>>
}

export type CreateCustomerMutationVariables = Exact<{
    fields: CustomerInputFieldsType
}>

export type CreateCustomerMutation = { __typename?: 'Mutation' } & {
    createCustomer?: Maybe<{ __typename?: 'CustomerType' } & CustomerFragmentFragment>
}

export type CreateCustomersExportMutationVariables = Exact<{
    search?: Maybe<Scalars['String']>
}>

export type CreateCustomersExportMutation = { __typename?: 'Mutation' } & {
    createCustomersExport?: Maybe<
        { __typename?: 'CustomersExportType' } & Pick<CustomersExportType, 'token' | 'filename'>
    >
}

export type EditCustomerMutationVariables = Exact<{
    customerId: Scalars['Int']
    fields: CustomerInputFieldsType
}>

export type EditCustomerMutation = { __typename?: 'Mutation' } & {
    editCustomer?: Maybe<{ __typename?: 'CustomerType' } & CustomerFragmentFragment>
}

export type CustomerFragmentFragment = { __typename?: 'CustomerType' } & Pick<
    CustomerType,
    | 'id'
    | 'name'
    | 'logo'
    | 'slug'
    | 'availableSeats'
    | 'filledSeats'
    | 'contactName'
    | 'contactEmail'
    | 'ssoLoginEnabled'
    | 'editorialAccess'
> & {
        address?: Maybe<
            { __typename?: 'AddressType' } & Pick<
                AddressType,
                'addressLine' | 'postalCode' | 'city' | 'stateOrProvince' | 'country'
            >
        >
        consultantContact?: Maybe<
            { __typename?: 'ConsultantType' } & Pick<ConsultantType, 'id'> & {
                    user?: Maybe<
                        { __typename?: 'UserType' } & Pick<UserType, 'id' | 'email'> & {
                                profile: { __typename?: 'ProfileType' } & Pick<ProfileType, 'id' | 'fullName'>
                            }
                    >
                }
        >
        customerGroup?: Maybe<{ __typename?: 'CustomerGroupType' } & Pick<CustomerGroupType, 'id' | 'name'>>
    }

export type CustomerQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type CustomerQuery = { __typename?: 'Query' } & {
    customer?: Maybe<{ __typename?: 'CustomerType' } & CustomerFragmentFragment>
}

export type CreateCustomerGroupMutationVariables = Exact<{
    fields?: Maybe<CustomerGroupInputFieldsType>
}>

export type CreateCustomerGroupMutation = { __typename?: 'Mutation' } & {
    createCustomerGroup: { __typename?: 'CustomerGroupType' } & Pick<CustomerGroupType, 'id'>
}

export type DeleteCustomerGroupMutationVariables = Exact<{
    customerGroupId: Scalars['Int']
}>

export type DeleteCustomerGroupMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteCustomerGroup'>

export type EditCustomerGroupMutationVariables = Exact<{
    customerGroupId: Scalars['Int']
    fields: CustomerGroupInputFieldsType
}>

export type EditCustomerGroupMutation = { __typename?: 'Mutation' } & {
    editCustomerGroup: { __typename?: 'CustomerGroupType' } & Pick<CustomerGroupType, 'id'>
}

export type CustomerGroupQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type CustomerGroupQuery = { __typename?: 'Query' } & {
    customerGroup?: Maybe<
        { __typename?: 'CustomerGroupType' } & Pick<
            CustomerGroupType,
            | 'id'
            | 'name'
            | 'ssoLoginEnabled'
            | 'createUserOnSSOLogin'
            | 'ssoLoginURL'
            | 'ssoLogoutURL'
            | 'ssoCertificate'
            | 'totalUserCount'
        > & { customers?: Maybe<Array<{ __typename?: 'CustomerType' } & Pick<CustomerType, 'id'>>> }
    >
}

export type CustomerGroupSelectQueryVariables = Exact<{
    skip: Scalars['Int']
    take: Scalars['Int']
    search?: Maybe<Scalars['String']>
}>

export type CustomerGroupSelectQuery = { __typename?: 'Query' } & {
    customerGroups?: Maybe<
        { __typename?: 'PaginatedCustomerGroupType' } & Pick<
            PaginatedCustomerGroupType,
            'totalCount' | 'hasNextPage'
        > & { nodes?: Maybe<Array<{ __typename?: 'CustomerGroupType' } & Pick<CustomerGroupType, 'id' | 'name'>>> }
    >
}

export type CustomerGroupsQueryVariables = Exact<{
    skip: Scalars['Int']
    take: Scalars['Int']
    search?: Maybe<Scalars['String']>
}>

export type CustomerGroupsQuery = { __typename?: 'Query' } & {
    customerGroups?: Maybe<
        { __typename?: 'PaginatedCustomerGroupType' } & Pick<
            PaginatedCustomerGroupType,
            'totalCount' | 'hasNextPage'
        > & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'CustomerGroupType' } & Pick<
                            CustomerGroupType,
                            'id' | 'name' | 'totalUserCount'
                        > & { customers?: Maybe<Array<{ __typename?: 'CustomerType' } & Pick<CustomerType, 'id'>>> }
                    >
                >
            }
    >
}

export type CustomersInCustomerGroupQueryVariables = Exact<{
    id: Scalars['Int']
    search?: Maybe<Scalars['String']>
}>

export type CustomersInCustomerGroupQuery = { __typename?: 'Query' } & {
    customerGroup?: Maybe<
        { __typename?: 'CustomerGroupType' } & Pick<CustomerGroupType, 'id' | 'name'> & {
                customers?: Maybe<
                    Array<
                        { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name' | 'filledSeats'> & {
                                frameworks: Array<
                                    { __typename?: 'FrameworkType' } & Pick<FrameworkType, 'id' | 'abbreviation'>
                                >
                            }
                    >
                >
            }
    >
}

export type CreateTaskMutationVariables = Exact<{
    departmentId: Scalars['Int']
    fields: CreateTaskFieldsInputType
}>

export type CreateTaskMutation = { __typename?: 'Mutation' } & {
    createTask?: Maybe<{ __typename?: 'TaskType' } & TaskDetailFieldsFragmentFragment>
}

export type TaskDetailFieldsFragmentFragment = { __typename?: 'TaskType' } & Pick<
    TaskType,
    | 'id'
    | 'name'
    | 'description'
    | 'priority'
    | 'startAt'
    | 'dueAt'
    | 'completedAt'
    | 'forMonitoring'
    | 'reopenedAt'
    | 'repeats'
    | 'isSubTask'
    | 'isParentTask'
    | 'isReviewTask'
    | 'totalSubTaskCount'
    | 'completedSubTaskCount'
> & {
        linkedTasksToReview: Array<
            { __typename?: 'TaskType' } & Pick<TaskType, 'id' | 'name'> & {
                    linkedItem?: Maybe<
                        | ({ __typename?: 'AlertType' } & LinkedTaskItemsFields_AlertType_Fragment)
                        | ({ __typename?: 'ControlType' } & LinkedTaskItemsFields_ControlType_Fragment)
                        | ({ __typename?: 'RiskType' } & LinkedTaskItemsFields_RiskType_Fragment)
                        | ({ __typename?: 'AssessmentType' } & LinkedTaskItemsFields_AssessmentType_Fragment)
                        | ({
                              __typename?: 'MonitoringReportType'
                          } & LinkedTaskItemsFields_MonitoringReportType_Fragment)
                        | ({ __typename?: 'TopicType' } & LinkedTaskItemsFields_TopicType_Fragment)
                        | ({ __typename?: 'RadarItemType' } & LinkedTaskItemsFields_RadarItemType_Fragment)
                        | ({ __typename?: 'ConsultationType' } & LinkedTaskItemsFields_ConsultationType_Fragment)
                    >
                    department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                }
        >
        linkedAlertsToReview: Array<{ __typename?: 'AlertType' } & Pick<AlertType, 'id' | 'name' | 'impactLevel'>>
        parentTask?: Maybe<{ __typename?: 'TaskType' } & ParentTaskFieldsFragmentFragment>
        subTasks?: Maybe<Array<{ __typename?: 'TaskType' } & SubTaskFieldsFragmentFragment>>
        linkedItem?: Maybe<
            | ({ __typename?: 'AlertType' } & LinkedTaskItemsFields_AlertType_Fragment)
            | ({ __typename?: 'ControlType' } & LinkedTaskItemsFields_ControlType_Fragment)
            | ({ __typename?: 'RiskType' } & LinkedTaskItemsFields_RiskType_Fragment)
            | ({ __typename?: 'AssessmentType' } & LinkedTaskItemsFields_AssessmentType_Fragment)
            | ({ __typename?: 'MonitoringReportType' } & LinkedTaskItemsFields_MonitoringReportType_Fragment)
            | ({ __typename?: 'TopicType' } & LinkedTaskItemsFields_TopicType_Fragment)
            | ({ __typename?: 'RadarItemType' } & LinkedTaskItemsFields_RadarItemType_Fragment)
            | ({ __typename?: 'ConsultationType' } & LinkedTaskItemsFields_ConsultationType_Fragment)
        >
        department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
        tags: Array<{ __typename?: 'TaskTagType' } & TaskTagFieldsFragment>
        employees: Array<{ __typename?: 'EmployeeType' } & TaskEmployeeFieldsFragment>
    }

export type IgnoreAlertsMutationVariables = Exact<{
    reason?: Maybe<Scalars['String']>
    alertIdWithDepartmentIds?: Maybe<Array<AlertIdWithDepartmentIdInputType> | AlertIdWithDepartmentIdInputType>
}>

export type IgnoreAlertsMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'ignoreAlerts'>

export type RevertIgnoreAlertMutationVariables = Exact<{
    alertId: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type RevertIgnoreAlertMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'revertIgnoreAlert'>

export type AlertsSummaryPerMonthQueryVariables = Exact<{
    filters?: Maybe<AlertFilters>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}>

export type AlertsSummaryPerMonthQuery = { __typename?: 'Query' } & {
    alertsSummaryPerMonth?: Maybe<
        { __typename?: 'PaginatedCustomerAlertSummaryPerMonthType' } & Pick<
            PaginatedCustomerAlertSummaryPerMonthType,
            'hasNextPage'
        > & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'CustomerAlertSummaryPerMonthType' } & Pick<
                            CustomerAlertSummaryPerMonthType,
                            'id' | 'alertCount' | 'month' | 'year'
                        >
                    >
                >
            }
    >
}

export type DepartmentAlertsForMonthQueryVariables = Exact<{
    filters?: Maybe<AlertFilters>
    sort?: Maybe<AlertsForMonthSort>
    month: Scalars['Int']
    year: Scalars['Int']
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}>

export type DepartmentAlertsForMonthQuery = { __typename?: 'Query' } & {
    alertsForMonth?: Maybe<
        { __typename?: 'PaginatedGroupedDepartmentAlertDataType' } & Pick<
            PaginatedGroupedDepartmentAlertDataType,
            'count' | 'totalCount' | 'hasNextPage'
        > & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'GroupedDepartmentAlertDataType' } & Pick<
                            GroupedDepartmentAlertDataType,
                            'id'
                        > & {
                                alert: { __typename?: 'AlertType' } & Pick<
                                    AlertType,
                                    'id' | 'name' | 'publishedAt' | 'isPublishedFromCustomerNews'
                                >
                                departmentDatas?: Maybe<
                                    Array<
                                        { __typename?: 'DepartmentAlertDataType' } & Pick<
                                            DepartmentAlertDataType,
                                            | 'id'
                                            | 'hasLinkedTask'
                                            | 'openTaskCount'
                                            | 'completedTaskCount'
                                            | 'overdueTaskCount'
                                        > & {
                                                department: { __typename?: 'DepartmentType' } & Pick<
                                                    DepartmentType,
                                                    'id' | 'name'
                                                >
                                                impactLevel?: Maybe<
                                                    { __typename?: 'AlertImpactLevelType' } & Pick<
                                                        AlertImpactLevelType,
                                                        'id' | 'impactLevel'
                                                    >
                                                >
                                                inboxStatus?: Maybe<
                                                    { __typename?: 'InboxStatusType' } & Pick<
                                                        InboxStatusType,
                                                        'id' | 'archivedAt' | 'ignoredAt'
                                                    > & {
                                                            ignoredBy?: Maybe<
                                                                { __typename?: 'EmployeeType' } & Pick<
                                                                    EmployeeType,
                                                                    'id'
                                                                > & {
                                                                        user: { __typename?: 'UserType' } & Pick<
                                                                            UserType,
                                                                            'id'
                                                                        > & {
                                                                                profile: {
                                                                                    __typename?: 'ProfileType'
                                                                                } & Pick<ProfileType, 'id' | 'fullName'>
                                                                            }
                                                                    }
                                                            >
                                                        }
                                                >
                                                alert: { __typename?: 'AlertType' } & Pick<
                                                    AlertType,
                                                    'id' | 'name' | 'publishedAt' | 'isPublishedFromCustomerNews'
                                                >
                                            }
                                    >
                                >
                            }
                    >
                >
            }
    >
}

export type IgnoreTasksMutationVariables = Exact<{
    reason?: Maybe<Scalars['String']>
    taskIdsToIgnore?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>
    departmentId: Scalars['Int']
}>

export type IgnoreTasksMutation = { __typename?: 'Mutation' } & {
    ignoreTasks: Array<
        { __typename?: 'TaskType' } & Pick<
            TaskType,
            'id' | 'name' | 'priority' | 'createdAt' | 'completedAt' | 'repeats' | 'forMonitoring' | 'reopenedAt'
        > & {
                tags: Array<{ __typename?: 'TaskTagType' } & TaskTagFieldsFragment>
                employees: Array<{ __typename?: 'EmployeeType' } & TaskEmployeeFieldsFragment>
                linkedItem?: Maybe<
                    | ({ __typename: 'AlertType' } & Pick<AlertType, 'id' | 'name'>)
                    | ({ __typename: 'ControlType' } & Pick<ControlType, 'id' | 'name' | 'type' | 'revisionDate'>)
                    | ({ __typename: 'RiskType' } & Pick<RiskType, 'id' | 'name' | 'severity' | 'isAccepted'>)
                    | ({ __typename: 'AssessmentType' } & Pick<AssessmentType, 'id' | 'name'>)
                    | ({ __typename: 'MonitoringReportType' } & Pick<MonitoringReportType, 'id' | 'name'>)
                    | ({ __typename: 'TopicType' } & Pick<TopicType, 'id' | 'name'>)
                    | ({ __typename: 'RadarItemType' } & Pick<RadarItemType, 'id' | 'name'>)
                    | ({ __typename: 'ConsultationType' } & Pick<ConsultationType, 'id' | 'name'>)
                >
            }
    >
}

export type RevertIgnoreTaskMutationVariables = Exact<{
    taskId: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type RevertIgnoreTaskMutation = { __typename?: 'Mutation' } & {
    undoIgnoreTask: { __typename?: 'TaskType' } & Pick<
        TaskType,
        'id' | 'name' | 'priority' | 'createdAt' | 'completedAt' | 'repeats' | 'forMonitoring' | 'reopenedAt'
    > & {
            tags: Array<{ __typename?: 'TaskTagType' } & TaskTagFieldsFragment>
            employees: Array<{ __typename?: 'EmployeeType' } & TaskEmployeeFieldsFragment>
            linkedItem?: Maybe<
                | ({ __typename?: 'AlertType' } & LinkedTaskItemsFields_AlertType_Fragment)
                | ({ __typename?: 'ControlType' } & LinkedTaskItemsFields_ControlType_Fragment)
                | ({ __typename?: 'RiskType' } & LinkedTaskItemsFields_RiskType_Fragment)
                | ({ __typename?: 'AssessmentType' } & LinkedTaskItemsFields_AssessmentType_Fragment)
                | ({ __typename?: 'MonitoringReportType' } & LinkedTaskItemsFields_MonitoringReportType_Fragment)
                | ({ __typename?: 'TopicType' } & LinkedTaskItemsFields_TopicType_Fragment)
                | ({ __typename?: 'RadarItemType' } & LinkedTaskItemsFields_RadarItemType_Fragment)
                | ({ __typename?: 'ConsultationType' } & LinkedTaskItemsFields_ConsultationType_Fragment)
            >
        }
}

export type TasksForMonthQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    filters?: Maybe<TaskForMonthFilterInputType>
    sort?: Maybe<TaskForMonthSortInputType>
    month: Scalars['Int']
    year: Scalars['Int']
}>

export type TasksForMonthQuery = { __typename?: 'Query' } & {
    tasksForMonth?: Maybe<
        { __typename?: 'PaginatedTaskType' } & Pick<PaginatedTaskType, 'count' | 'totalCount' | 'hasNextPage'> & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'TaskType' } & Pick<
                            TaskType,
                            | 'id'
                            | 'name'
                            | 'priority'
                            | 'createdAt'
                            | 'completedAt'
                            | 'repeats'
                            | 'forMonitoring'
                            | 'reopenedAt'
                            | 'ignoredAt'
                            | 'ignoredReason'
                            | 'isSubTask'
                            | 'isParentTask'
                            | 'totalSubTaskCount'
                            | 'completedSubTaskCount'
                        > & {
                                tags: Array<{ __typename?: 'TaskTagType' } & TaskTagFieldsFragment>
                                employees: Array<{ __typename?: 'EmployeeType' } & TaskEmployeeFieldsFragment>
                                ignoredBy?: Maybe<{ __typename?: 'EmployeeType' } & TaskEmployeeFieldsFragment>
                                reviewTask?: Maybe<
                                    { __typename?: 'TaskType' } & Pick<TaskType, 'id' | 'name' | 'createdAt'> & {
                                            createdBy?: Maybe<
                                                { __typename?: 'EmployeeType' } & TaskEmployeeFieldsFragment
                                            >
                                        }
                                >
                                linkedItem?: Maybe<
                                    | ({ __typename: 'AlertType' } & Pick<AlertType, 'id' | 'name'>)
                                    | ({ __typename: 'ControlType' } & Pick<
                                          ControlType,
                                          'id' | 'name' | 'type' | 'revisionDate'
                                      >)
                                    | ({ __typename: 'RiskType' } & Pick<
                                          RiskType,
                                          'id' | 'name' | 'severity' | 'isAccepted'
                                      >)
                                    | ({ __typename: 'AssessmentType' } & Pick<AssessmentType, 'id' | 'name'>)
                                    | ({ __typename: 'MonitoringReportType' } & Pick<
                                          MonitoringReportType,
                                          'id' | 'name'
                                      >)
                                    | ({ __typename: 'TopicType' } & Pick<TopicType, 'id' | 'name'>)
                                    | ({ __typename: 'RadarItemType' } & Pick<
                                          RadarItemType,
                                          'id' | 'name' | 'isDeleted'
                                      >)
                                    | ({ __typename: 'ConsultationType' } & Pick<ConsultationType, 'id' | 'name'>)
                                >
                                parentTask?: Maybe<{ __typename?: 'TaskType' } & Pick<TaskType, 'id' | 'name'>>
                                subTasks?: Maybe<Array<{ __typename?: 'TaskType' } & Pick<TaskType, 'id' | 'name'>>>
                                department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                            }
                    >
                >
            }
    >
}

export type TaskTagFieldsFragment = { __typename?: 'TaskTagType' } & Pick<TaskTagType, 'id' | 'name'>

export type TaskEmployeeFieldsFragment = { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
        user: { __typename?: 'UserType' } & Pick<UserType, 'id' | 'email'> & {
                profile: { __typename?: 'ProfileType' } & Pick<
                    ProfileType,
                    'id' | 'fullName' | 'phoneNumber' | 'avatar'
                >
            }
    }

export type TasksSummaryPerMonthQueryVariables = Exact<{
    filters?: Maybe<TaskForMonthFilterInputType>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}>

export type TasksSummaryPerMonthQuery = { __typename?: 'Query' } & {
    tasksSummaryPerMonth?: Maybe<
        { __typename?: 'PaginatedTaskSummaryPerMonthType' } & Pick<
            PaginatedTaskSummaryPerMonthType,
            'count' | 'totalCount' | 'hasNextPage'
        > & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'TaskSummaryPerMonthType' } & Pick<
                            TaskSummaryPerMonthType,
                            'id' | 'month' | 'year' | 'openTaskCount' | 'completedTaskCount'
                        >
                    >
                >
            }
    >
}

export type ControlsToLinkQueryVariables = Exact<{
    filters?: Maybe<ControlFilters>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    departmentId: Scalars['Int']
}>

export type ControlsToLinkQuery = { __typename?: 'Query' } & {
    controls?: Maybe<
        { __typename?: 'PaginatedControlType' } & Pick<PaginatedControlType, 'hasNextPage'> & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'ControlType' } & Pick<ControlType, 'id' | 'name' | 'type' | 'revisionDate'> & {
                                childControls?: Maybe<
                                    Array<
                                        { __typename?: 'ControlType' } & Pick<
                                            ControlType,
                                            'id' | 'name' | 'type' | 'revisionDate'
                                        > & {
                                                childControls?: Maybe<
                                                    Array<
                                                        { __typename?: 'ControlType' } & Pick<
                                                            ControlType,
                                                            'id' | 'name' | 'type' | 'revisionDate'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                            }
                    >
                >
            }
    >
}

export type CreateCustomerUsersExportMutationVariables = Exact<{
    filters?: Maybe<CustomerFiltersType>
    sort?: Maybe<CustomerSortType>
    forCustomerId?: Maybe<Scalars['Int']>
}>

export type CreateCustomerUsersExportMutation = { __typename?: 'Mutation' } & {
    createCustomerUsersExport?: Maybe<
        { __typename?: 'CustomerUsersExportType' } & Pick<CustomerUsersExportType, 'token' | 'filename'>
    >
}

export type CreateFeedMutationVariables = Exact<{
    isActive?: Maybe<Scalars['Boolean']>
    customerId?: Maybe<Scalars['Int']>
    type?: Maybe<CustomerSubscribedToFeedTypeTypesEnumType>
    settings?: Maybe<Scalars['String']>
    frequency?: Maybe<Scalars['Int']>
    frequencyType?: Maybe<CustomerSubscribedToFeedTypeFrequencyTypeEnumType>
    startDate?: Maybe<Scalars['Date']>
    recipientEmails?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>
}>

export type CreateFeedMutation = { __typename?: 'Mutation' } & {
    createCustomerSubscribedToFeed?: Maybe<
        { __typename?: 'CustomerSubscribedToFeedType' } & Pick<CustomerSubscribedToFeedType, 'id'>
    >
}

export type DeleteFeedMutationVariables = Exact<{
    id: Scalars['Int']
}>

export type DeleteFeedMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteCustomerSubscribedToFeed'>

export type EditFeedMutationVariables = Exact<{
    id: Scalars['Int']
    isActive?: Maybe<Scalars['Boolean']>
    settings?: Maybe<Scalars['String']>
    frequency?: Maybe<Scalars['Int']>
    frequencyType?: Maybe<CustomerSubscribedToFeedTypeFrequencyTypeEnumType>
    startDate?: Maybe<Scalars['Date']>
    recipientEmails?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type EditFeedMutation = { __typename?: 'Mutation' } & {
    editCustomerSubscribedToFeed?: Maybe<
        { __typename?: 'CustomerSubscribedToFeedType' } & Pick<
            CustomerSubscribedToFeedType,
            'id' | 'isActive' | 'settings' | 'frequency' | 'frequencyType' | 'startDate' | 'recipientEmails'
        >
    >
}

export type CustomerFeedQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type CustomerFeedQuery = { __typename?: 'Query' } & {
    customerSubscribedToFeed?: Maybe<
        { __typename?: 'CustomerSubscribedToFeedType' } & Pick<
            CustomerSubscribedToFeedType,
            'id' | 'isActive' | 'type' | 'settings' | 'frequency' | 'frequencyType' | 'startDate' | 'recipientEmails'
        > & { customer: { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name' | 'deletedAt'> }
    >
}

export type CustomerFeedExportsQueryVariables = Exact<{
    customerFeedId: Scalars['Int']
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}>

export type CustomerFeedExportsQuery = { __typename?: 'Query' } & {
    customerSubscribedToFeedExports?: Maybe<
        { __typename?: 'PaginatedCustomerSubscribedToFeedExport' } & Pick<
            PaginatedCustomerSubscribedToFeedExport,
            'hasNextPage'
        > & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'CustomerSubscribedToFeedExport' } & Pick<
                            CustomerSubscribedToFeedExport,
                            'id' | 'startDate' | 'endDate' | 'recipientEmails' | 'settings'
                        > & { file: { __typename?: 'FileType' } & Pick<FileType, 'id' | 'name'> }
                    >
                >
            }
    >
}

export type CustomerFeedsQueryVariables = Exact<{ [key: string]: never }>

export type CustomerFeedsQuery = { __typename?: 'Query' } & {
    customerSubscribedToFeeds?: Maybe<
        Array<
            Maybe<
                { __typename?: 'CustomerSubscribedToFeedType' } & Pick<
                    CustomerSubscribedToFeedType,
                    'id' | 'type' | 'frequency' | 'frequencyType' | 'isActive'
                > & {
                        customer: { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name' | 'deletedAt'>
                        exports?: Maybe<
                            { __typename?: 'PaginatedCustomerSubscribedToFeedExport' } & Pick<
                                PaginatedCustomerSubscribedToFeedExport,
                                'totalCount'
                            >
                        >
                    }
            >
        >
    >
}

export type AllFrameworksQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}>

export type AllFrameworksQuery = { __typename?: 'Query' } & {
    frameworks?: Maybe<
        { __typename?: 'PaginatedFrameworkType' } & Pick<
            PaginatedFrameworkType,
            'count' | 'totalCount' | 'hasNextPage'
        > & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'FrameworkType' } & Pick<FrameworkType, 'id' | 'name' | 'curatedBy'> & {
                                themes?: Maybe<
                                    Array<Maybe<{ __typename?: 'FrameworkThemeType' } & Pick<FrameworkThemeType, 'id'>>>
                                >
                            }
                    >
                >
            }
    >
}

export type CustomersQueryVariables = Exact<{
    take?: Maybe<Scalars['Int']>
    skip?: Maybe<Scalars['Int']>
}>

export type CustomersQuery = { __typename?: 'Query' } & {
    customers?: Maybe<
        { __typename?: 'PaginatedCustomerType' } & Pick<PaginatedCustomerType, 'hasNextPage'> & {
                nodes?: Maybe<Array<{ __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name'>>>
            }
    >
}

export type CustomerFrameworkFrameworkFieldsFragment = { __typename?: 'CustomerFrameworkFrameworkType' } & Pick<
    CustomerFrameworkFrameworkType,
    'id' | 'name'
> & {
        themes?: Maybe<
            Array<
                { __typename?: 'CustomerFrameworkFrameworkThemeType' } & Pick<
                    CustomerFrameworkFrameworkThemeType,
                    'id' | 'idn' | 'name'
                > & {
                        topics?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'CustomerFrameworkFrameworkTopicType' } & Pick<
                                        CustomerFrameworkFrameworkTopicType,
                                        'id' | 'idn' | 'name' | 'isChecked' | 'isNew' | 'addedAt'
                                    >
                                >
                            >
                        >
                    }
            >
        >
    }

export type CreateCustomerFrameworkMutationVariables = Exact<{
    customerId: Scalars['Int']
    frameworkId: Scalars['Int']
    themeIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
    topicIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
    customerFramework: CustomerFrameworkFieldsInputType
}>

export type CreateCustomerFrameworkMutation = { __typename?: 'Mutation' } & {
    createCustomerFramework?: Maybe<
        { __typename?: 'CustomerFrameworkType' } & Pick<CustomerFrameworkType, 'id' | 'name' | 'color' | 'icon'> & {
                framework: { __typename?: 'CustomerFrameworkFrameworkType' } & CustomerFrameworkFrameworkFieldsFragment
            }
    >
}

export type DeleteCustomerFrameworkMutationVariables = Exact<{
    id: Scalars['Int']
}>

export type DeleteCustomerFrameworkMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteCustomerFramework'>

export type EditCustomerFrameworksTopicsMutationVariables = Exact<{
    fields: Array<EditCustomerFrameworkTopicsField> | EditCustomerFrameworkTopicsField
}>

export type EditCustomerFrameworksTopicsMutation = { __typename?: 'Mutation' } & {
    editCustomerFrameworksTopics?: Maybe<
        Array<
            Maybe<
                { __typename?: 'CustomerFrameworkType' } & Pick<
                    CustomerFrameworkType,
                    'id' | 'name' | 'color' | 'icon'
                > & {
                        framework: {
                            __typename?: 'CustomerFrameworkFrameworkType'
                        } & CustomerFrameworkFrameworkFieldsFragment
                    }
            >
        >
    >
}

export type AddWidgetMutationVariables = Exact<{
    widgetType: DashboardWidgetEnum
    layout: DashboardWidgetLayoutTypeEnum
    metadata: WidgetMetadataInputType
}>

export type AddWidgetMutation = { __typename?: 'Mutation' } & {
    addWidget: Array<{ __typename?: 'DashboardWidgetLayoutType' } & DashboardWidgetFragmentFragment>
}

export type DeleteWidgetMutationVariables = Exact<{
    widgetIds: Array<Scalars['Int']> | Scalars['Int']
    layout: DashboardWidgetLayoutTypeEnum
}>

export type DeleteWidgetMutation = { __typename?: 'Mutation' } & {
    deleteWidgets?: Maybe<Array<{ __typename?: 'DashboardWidgetLayoutType' } & DashboardWidgetFragmentFragment>>
}

export type EditDashboardLayoutMutationVariables = Exact<{
    widgets: Array<EditDashboardLayoutInputType> | EditDashboardLayoutInputType
}>

export type EditDashboardLayoutMutation = { __typename?: 'Mutation' } & {
    editDashboardWidgets?: Maybe<Array<{ __typename?: 'DashboardWidgetLayoutType' } & DashboardWidgetFragmentFragment>>
}

export type EditWidgetMutationVariables = Exact<{
    id: Scalars['Int']
    metadata: EditWidgetMetadataInputType
}>

export type EditWidgetMutation = { __typename?: 'Mutation' } & {
    editWidget: { __typename?: 'DashboardWidgetLayoutType' } & DashboardWidgetFragmentFragment
}

export type DashboardConsultationWidgetFragmentFragment = { __typename?: 'DashboardConsultationWidgetType' } & {
    consultations?: Maybe<
        Array<
            { __typename?: 'DashboardConsultationWidgetContentItem' } & Pick<
                DashboardConsultationWidgetContentItem,
                'id' | 'name' | 'source'
            >
        >
    >
}

export type DashboardControlWidgetFragmentFragment = { __typename?: 'DashboardControlWidgetType' } & Pick<
    DashboardControlWidgetType,
    'activeCount' | 'oldCount'
>

export type DashboardInboxItemImpactWidgetFragmentFragment = {
    __typename?: 'DashboardInboxItemImpactWidgetType'
} & Pick<
    DashboardInboxItemImpactWidgetType,
    'lowImpactCount' | 'noImpactCount' | 'mediumImpactCount' | 'highImpactCount'
>

export type DashboardInboxWidgetFragmentFragment = { __typename?: 'DashboardInboxWidgetType' } & Pick<
    DashboardInboxWidgetType,
    'lastWeekCount' | 'totalCount'
>

export type DashboardMonitoringReportWidgetFragmentFragment = { __typename?: 'DashboardMonitoringReportWidgetType' } & {
    monitoringReports?: Maybe<
        Array<
            { __typename?: 'DashboardMonitoringReportWidgetContentItem' } & Pick<
                DashboardMonitoringReportWidgetContentItem,
                'id' | 'name' | 'createdAt' | 'overdueTaskCount'
            >
        >
    >
}

export type DashboardRadarWidgetFragmentFragment = { __typename?: 'DashboardRadarWidgetType' } & {
    radarItems?: Maybe<
        Array<
            { __typename?: 'DashboardRadarWidgetContentItem' } & Pick<
                DashboardRadarWidgetContentItem,
                'id' | 'name' | 'expectedIn'
            >
        >
    >
}

export type DashboardRiskWidgetFragmentFragment = { __typename?: 'DashboardRisksWidgetType' } & Pick<
    DashboardRisksWidgetType,
    'highCount' | 'mediumCount' | 'lowCount'
>

export type DashboardSignalingWidgetFragmentFragment = { __typename?: 'DashboardSignalingWidgetType' } & Pick<
    DashboardSignalingWidgetType,
    'openCount' | 'sentCount' | 'totalCount'
>

export type DashboardTaskControlsWidgetFragmentFragment = { __typename?: 'DashboardTaskControlsWidgetType' } & Pick<
    DashboardTaskControlsWidgetType,
    'lastWeekCount' | 'totalCount'
>

export type DashboardTaskWidgetFragmentFragment = { __typename?: 'DashboardTasksWidgetType' } & {
    inboxCount: { __typename?: 'DashboardTasksWidgetCountType' } & Pick<
        DashboardTasksWidgetCountType,
        'total' | 'highPrio' | 'overdue'
    >
    controlCount: { __typename?: 'DashboardTasksWidgetCountType' } & Pick<
        DashboardTasksWidgetCountType,
        'total' | 'highPrio' | 'overdue'
    >
    topicCount: { __typename?: 'DashboardTasksWidgetCountType' } & Pick<
        DashboardTasksWidgetCountType,
        'total' | 'highPrio' | 'overdue'
    >
    genericCount: { __typename?: 'DashboardTasksWidgetCountType' } & Pick<
        DashboardTasksWidgetCountType,
        'total' | 'highPrio' | 'overdue'
    >
    otherCount: { __typename?: 'DashboardTasksWidgetCountType' } & Pick<
        DashboardTasksWidgetCountType,
        'total' | 'highPrio' | 'overdue'
    >
}

export type DashboardTopicWidgetFragmentFragment = { __typename?: 'DashboardTopicWidgetType' } & Pick<
    DashboardTopicWidgetType,
    'compliantCount' | 'almostCompliantCount' | 'notCompliantCount' | 'notAssessedCount'
>

type DashboardWidgetContentFragment_DashboardRadarWidgetType_Fragment = {
    __typename?: 'DashboardRadarWidgetType'
} & DashboardRadarWidgetFragmentFragment

type DashboardWidgetContentFragment_DashboardSignalingWidgetType_Fragment = {
    __typename?: 'DashboardSignalingWidgetType'
} & DashboardSignalingWidgetFragmentFragment

type DashboardWidgetContentFragment_DashboardInboxWidgetType_Fragment = {
    __typename?: 'DashboardInboxWidgetType'
} & DashboardInboxWidgetFragmentFragment

type DashboardWidgetContentFragment_DashboardTasksWidgetType_Fragment = {
    __typename?: 'DashboardTasksWidgetType'
} & DashboardTaskWidgetFragmentFragment

type DashboardWidgetContentFragment_DashboardControlWidgetType_Fragment = {
    __typename?: 'DashboardControlWidgetType'
} & DashboardControlWidgetFragmentFragment

type DashboardWidgetContentFragment_DashboardTaskControlsWidgetType_Fragment = {
    __typename?: 'DashboardTaskControlsWidgetType'
} & DashboardTaskControlsWidgetFragmentFragment

type DashboardWidgetContentFragment_DashboardRisksWidgetType_Fragment = {
    __typename?: 'DashboardRisksWidgetType'
} & DashboardRiskWidgetFragmentFragment

type DashboardWidgetContentFragment_DashboardTopicWidgetType_Fragment = {
    __typename?: 'DashboardTopicWidgetType'
} & DashboardTopicWidgetFragmentFragment

type DashboardWidgetContentFragment_DashboardConsultationWidgetType_Fragment = {
    __typename?: 'DashboardConsultationWidgetType'
} & DashboardConsultationWidgetFragmentFragment

type DashboardWidgetContentFragment_DashboardMonitoringReportWidgetType_Fragment = {
    __typename?: 'DashboardMonitoringReportWidgetType'
} & DashboardMonitoringReportWidgetFragmentFragment

type DashboardWidgetContentFragment_DashboardInboxItemImpactWidgetType_Fragment = {
    __typename?: 'DashboardInboxItemImpactWidgetType'
} & DashboardInboxItemImpactWidgetFragmentFragment

export type DashboardWidgetContentFragmentFragment =
    | DashboardWidgetContentFragment_DashboardRadarWidgetType_Fragment
    | DashboardWidgetContentFragment_DashboardSignalingWidgetType_Fragment
    | DashboardWidgetContentFragment_DashboardInboxWidgetType_Fragment
    | DashboardWidgetContentFragment_DashboardTasksWidgetType_Fragment
    | DashboardWidgetContentFragment_DashboardControlWidgetType_Fragment
    | DashboardWidgetContentFragment_DashboardTaskControlsWidgetType_Fragment
    | DashboardWidgetContentFragment_DashboardRisksWidgetType_Fragment
    | DashboardWidgetContentFragment_DashboardTopicWidgetType_Fragment
    | DashboardWidgetContentFragment_DashboardConsultationWidgetType_Fragment
    | DashboardWidgetContentFragment_DashboardMonitoringReportWidgetType_Fragment
    | DashboardWidgetContentFragment_DashboardInboxItemImpactWidgetType_Fragment

type DashboardWidgetDetailContentFragment_DashboardTasksDetailType_Fragment = {
    __typename?: 'DashboardTasksDetailType'
} & Pick<DashboardTasksDetailType, 'id'> & {
        contents?: Maybe<
            Array<
                { __typename?: 'DashboardTasksDetailFieldsType' } & {
                    department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                    content: { __typename?: 'DashboardTasksWidgetType' } & DashboardTaskWidgetFragmentFragment
                }
            >
        >
    }

type DashboardWidgetDetailContentFragment_DashboardRadarDetailType_Fragment = {
    __typename?: 'DashboardRadarDetailType'
} & Pick<DashboardRadarDetailType, 'id'> & {
        contents?: Maybe<
            Array<
                { __typename?: 'DashboardRadarDetailFieldsType' } & {
                    department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                    content: { __typename?: 'DashboardRadarWidgetType' } & DashboardRadarWidgetFragmentFragment
                }
            >
        >
    }

type DashboardWidgetDetailContentFragment_DashboardInboxDetailType_Fragment = {
    __typename?: 'DashboardInboxDetailType'
} & Pick<DashboardInboxDetailType, 'id'> & {
        contents?: Maybe<
            Array<
                { __typename?: 'DashboardInboxDetailFieldsType' } & {
                    department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                    content: { __typename?: 'DashboardInboxWidgetType' } & DashboardInboxWidgetFragmentFragment
                }
            >
        >
    }

type DashboardWidgetDetailContentFragment_DashboardControlDetailType_Fragment = {
    __typename?: 'DashboardControlDetailType'
} & Pick<DashboardControlDetailType, 'id'> & {
        contents?: Maybe<
            Array<
                { __typename?: 'DashboardControlDetailFieldsType' } & {
                    department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                    content: { __typename?: 'DashboardControlWidgetType' } & DashboardControlWidgetFragmentFragment
                }
            >
        >
    }

type DashboardWidgetDetailContentFragment_DashboardTaskControlsDetailType_Fragment = {
    __typename?: 'DashboardTaskControlsDetailType'
} & Pick<DashboardTaskControlsDetailType, 'id'> & {
        contents?: Maybe<
            Array<
                { __typename?: 'DashboardTaskControlsDetailFieldsType' } & {
                    department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                    content: {
                        __typename?: 'DashboardTaskControlsWidgetType'
                    } & DashboardTaskControlsWidgetFragmentFragment
                }
            >
        >
    }

type DashboardWidgetDetailContentFragment_DashboardRisksDetailType_Fragment = {
    __typename?: 'DashboardRisksDetailType'
} & Pick<DashboardRisksDetailType, 'id'> & {
        contents?: Maybe<
            Array<
                { __typename?: 'DashboardRisksDetailFieldsType' } & {
                    department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                    content: { __typename?: 'DashboardRisksWidgetType' } & DashboardRiskWidgetFragmentFragment
                }
            >
        >
    }

type DashboardWidgetDetailContentFragment_DashboardTopicDetailType_Fragment = {
    __typename?: 'DashboardTopicDetailType'
} & Pick<DashboardTopicDetailType, 'id'> & {
        contents?: Maybe<
            Array<
                { __typename?: 'DashboardTopicDetailFieldsType' } & {
                    department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                    content: { __typename?: 'DashboardTopicWidgetType' } & DashboardTopicWidgetFragmentFragment
                }
            >
        >
    }

type DashboardWidgetDetailContentFragment_DashboardConsultationDetailType_Fragment = {
    __typename?: 'DashboardConsultationDetailType'
} & Pick<DashboardConsultationDetailType, 'id'> & {
        contents?: Maybe<
            Array<
                { __typename?: 'DashboardConsultationDetailFieldsType' } & {
                    department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                    content: {
                        __typename?: 'DashboardConsultationWidgetType'
                    } & DashboardConsultationWidgetFragmentFragment
                }
            >
        >
    }

type DashboardWidgetDetailContentFragment_DashboardInboxItemImpactDetailType_Fragment = {
    __typename?: 'DashboardInboxItemImpactDetailType'
} & Pick<DashboardInboxItemImpactDetailType, 'id'> & {
        contents?: Maybe<
            Array<
                { __typename?: 'DashboardInboxItemImpactDetailFieldsType' } & {
                    department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                    content: {
                        __typename?: 'DashboardInboxItemImpactWidgetType'
                    } & DashboardInboxItemImpactWidgetFragmentFragment
                }
            >
        >
    }

export type DashboardWidgetDetailContentFragmentFragment =
    | DashboardWidgetDetailContentFragment_DashboardTasksDetailType_Fragment
    | DashboardWidgetDetailContentFragment_DashboardRadarDetailType_Fragment
    | DashboardWidgetDetailContentFragment_DashboardInboxDetailType_Fragment
    | DashboardWidgetDetailContentFragment_DashboardControlDetailType_Fragment
    | DashboardWidgetDetailContentFragment_DashboardTaskControlsDetailType_Fragment
    | DashboardWidgetDetailContentFragment_DashboardRisksDetailType_Fragment
    | DashboardWidgetDetailContentFragment_DashboardTopicDetailType_Fragment
    | DashboardWidgetDetailContentFragment_DashboardConsultationDetailType_Fragment
    | DashboardWidgetDetailContentFragment_DashboardInboxItemImpactDetailType_Fragment

export type DashboardWidgetFragmentFragment = { __typename?: 'DashboardWidgetLayoutType' } & Pick<
    DashboardWidgetLayoutType,
    'id' | 'x' | 'y' | 'widgetType' | 'layoutType'
> & {
        content?: Maybe<
            | ({
                  __typename?: 'DashboardRadarWidgetType'
              } & DashboardWidgetContentFragment_DashboardRadarWidgetType_Fragment)
            | ({
                  __typename?: 'DashboardSignalingWidgetType'
              } & DashboardWidgetContentFragment_DashboardSignalingWidgetType_Fragment)
            | ({
                  __typename?: 'DashboardInboxWidgetType'
              } & DashboardWidgetContentFragment_DashboardInboxWidgetType_Fragment)
            | ({
                  __typename?: 'DashboardTasksWidgetType'
              } & DashboardWidgetContentFragment_DashboardTasksWidgetType_Fragment)
            | ({
                  __typename?: 'DashboardControlWidgetType'
              } & DashboardWidgetContentFragment_DashboardControlWidgetType_Fragment)
            | ({
                  __typename?: 'DashboardTaskControlsWidgetType'
              } & DashboardWidgetContentFragment_DashboardTaskControlsWidgetType_Fragment)
            | ({
                  __typename?: 'DashboardRisksWidgetType'
              } & DashboardWidgetContentFragment_DashboardRisksWidgetType_Fragment)
            | ({
                  __typename?: 'DashboardTopicWidgetType'
              } & DashboardWidgetContentFragment_DashboardTopicWidgetType_Fragment)
            | ({
                  __typename?: 'DashboardConsultationWidgetType'
              } & DashboardWidgetContentFragment_DashboardConsultationWidgetType_Fragment)
            | ({
                  __typename?: 'DashboardMonitoringReportWidgetType'
              } & DashboardWidgetContentFragment_DashboardMonitoringReportWidgetType_Fragment)
            | ({
                  __typename?: 'DashboardInboxItemImpactWidgetType'
              } & DashboardWidgetContentFragment_DashboardInboxItemImpactWidgetType_Fragment)
        >
        metadata:
            | ({
                  __typename?: 'DashboardCustomerSpecificWidgetMetadataType'
              } & DashboardWidgetMetadataFragment_DashboardCustomerSpecificWidgetMetadataType_Fragment)
            | ({
                  __typename?: 'DashboardDepartmentSpecificWidgetMetadataType'
              } & DashboardWidgetMetadataFragment_DashboardDepartmentSpecificWidgetMetadataType_Fragment)
    }

type DashboardWidgetMetadataFragment_DashboardCustomerSpecificWidgetMetadataType_Fragment = {
    __typename?: 'DashboardCustomerSpecificWidgetMetadataType'
} & Pick<DashboardCustomerSpecificWidgetMetadataType, 'name'>

type DashboardWidgetMetadataFragment_DashboardDepartmentSpecificWidgetMetadataType_Fragment = {
    __typename?: 'DashboardDepartmentSpecificWidgetMetadataType'
} & Pick<DashboardDepartmentSpecificWidgetMetadataType, 'name' | 'allDepartments'> & {
        departments?: Maybe<Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>>
    }

export type DashboardWidgetMetadataFragmentFragment =
    | DashboardWidgetMetadataFragment_DashboardCustomerSpecificWidgetMetadataType_Fragment
    | DashboardWidgetMetadataFragment_DashboardDepartmentSpecificWidgetMetadataType_Fragment

export type DashboardLayoutQueryVariables = Exact<{
    layout?: Maybe<DashboardWidgetLayoutTypeEnum>
}>

export type DashboardLayoutQuery = { __typename?: 'Query' } & {
    dashboardWidgets?: Maybe<Array<{ __typename?: 'DashboardWidgetLayoutType' } & DashboardWidgetFragmentFragment>>
}

export type DashboardWidgetDetailQueryVariables = Exact<{
    widgetId: Scalars['Int']
}>

export type DashboardWidgetDetailQuery = { __typename?: 'Query' } & {
    dashboardDetailContent?: Maybe<
        | ({
              __typename?: 'DashboardTasksDetailType'
          } & DashboardWidgetDetailContentFragment_DashboardTasksDetailType_Fragment)
        | ({
              __typename?: 'DashboardRadarDetailType'
          } & DashboardWidgetDetailContentFragment_DashboardRadarDetailType_Fragment)
        | ({
              __typename?: 'DashboardInboxDetailType'
          } & DashboardWidgetDetailContentFragment_DashboardInboxDetailType_Fragment)
        | ({
              __typename?: 'DashboardControlDetailType'
          } & DashboardWidgetDetailContentFragment_DashboardControlDetailType_Fragment)
        | ({
              __typename?: 'DashboardTaskControlsDetailType'
          } & DashboardWidgetDetailContentFragment_DashboardTaskControlsDetailType_Fragment)
        | ({
              __typename?: 'DashboardRisksDetailType'
          } & DashboardWidgetDetailContentFragment_DashboardRisksDetailType_Fragment)
        | ({
              __typename?: 'DashboardTopicDetailType'
          } & DashboardWidgetDetailContentFragment_DashboardTopicDetailType_Fragment)
        | ({
              __typename?: 'DashboardConsultationDetailType'
          } & DashboardWidgetDetailContentFragment_DashboardConsultationDetailType_Fragment)
        | ({
              __typename?: 'DashboardInboxItemImpactDetailType'
          } & DashboardWidgetDetailContentFragment_DashboardInboxItemImpactDetailType_Fragment)
    >
}

export type CreateDepartmentMutationVariables = Exact<{
    customerId?: Maybe<Scalars['Int']>
    customerSlug?: Maybe<Scalars['String']>
    fields: CreateDepartmentFieldsInputType
}>

export type CreateDepartmentMutation = { __typename?: 'Mutation' } & {
    createDepartment?: Maybe<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id'>>
}

export type CreateDepartmentExportMutationVariables = Exact<{ [key: string]: never }>

export type CreateDepartmentExportMutation = { __typename?: 'Mutation' } & {
    createDepartmentExport?: Maybe<
        { __typename?: 'createDepartmentExport' } & Pick<CreateDepartmentExport, 'token' | 'filename'>
    >
}

export type DeleteDepartmentMutationVariables = Exact<{
    id: Scalars['Int']
    customerId?: Maybe<Scalars['Int']>
    customerSlug?: Maybe<Scalars['String']>
}>

export type DeleteDepartmentMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteDepartment'>

export type EditDepartmentCustomerFrameworksMutationVariables = Exact<{
    customerId: Scalars['Int']
    fields: Array<CustomerFrameworkInputType> | CustomerFrameworkInputType
}>

export type EditDepartmentCustomerFrameworksMutation = { __typename?: 'Mutation' } & {
    editDepartmentCustomerFrameworks?: Maybe<
        Array<
            Maybe<
                { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name' | 'amountOfEmployees'> & {
                        departmentFrameworks: Array<
                            { __typename?: 'DepartmentFrameworkType' } & Pick<DepartmentFrameworkType, 'id'> & {
                                    customerFramework: { __typename?: 'DepartmentCustomerFrameworkType' } & Pick<
                                        DepartmentCustomerFrameworkType,
                                        'id' | 'idn' | 'name' | 'color' | 'icon'
                                    > & {
                                            framework: {
                                                __typename?: 'DepartmentCustomerFrameworkFrameworkType'
                                            } & DepartmentFrameworkFrameworkFieldsFragment
                                        }
                                }
                        >
                    }
            >
        >
    >
}

export type EditDepartmentNameAndEmployeesMutationVariables = Exact<{
    departmentId: Scalars['Int']
    customerId: Scalars['Int']
    name?: Maybe<Scalars['String']>
    receiveAlertsForConsultationsEnabled?: Maybe<Scalars['Boolean']>
    employees?: Maybe<DepartmentEmployeesFieldInputType>
}>

export type EditDepartmentNameAndEmployeesMutation = { __typename?: 'Mutation' } & {
    editDepartmentNameAndEmployees?: Maybe<
        { __typename?: 'DepartmentType' } & Pick<
            DepartmentType,
            'id' | 'name' | 'receiveAlertsForConsultationsEnabled' | 'amountOfEmployees'
        > & {
                employees: Array<
                    { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                            user: { __typename?: 'UserType' } & Pick<UserType, 'id' | 'status'> & {
                                    profile: { __typename?: 'ProfileType' } & Pick<ProfileType, 'id' | 'fullName'>
                                }
                        }
                >
            }
    >
}

export type IgnoreNewTopicMutationVariables = Exact<{
    customerFrameworkId: Scalars['Int']
    topicId: Scalars['Int']
}>

export type IgnoreNewTopicMutation = { __typename?: 'Mutation' } & {
    ignoreCustomerFrameworkNewTopic?: Maybe<
        { __typename?: 'CustomerFrameworkType' } & Pick<CustomerFrameworkType, 'id' | 'name' | 'color' | 'icon'> & {
                framework: { __typename?: 'CustomerFrameworkFrameworkType' } & CustomerFrameworkFrameworkFieldsFragment
            }
    >
}

export type DepartmentFrameworkFrameworkFieldsFragment = {
    __typename?: 'DepartmentCustomerFrameworkFrameworkType'
} & Pick<DepartmentCustomerFrameworkFrameworkType, 'id' | 'name'> & {
        themes?: Maybe<
            Array<
                { __typename?: 'DepartmentCustomerFrameworkFrameworkThemeType' } & Pick<
                    DepartmentCustomerFrameworkFrameworkThemeType,
                    'id' | 'idn' | 'name'
                > & {
                        topics?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'CustomerFrameworkFrameworkTopicType' } & Pick<
                                        CustomerFrameworkFrameworkTopicType,
                                        'id' | 'idn' | 'name' | 'isChecked'
                                    >
                                >
                            >
                        >
                    }
            >
        >
    }

export type AllEmployeesQueryVariables = Exact<{
    customerId: Scalars['Int']
}>

export type AllEmployeesQuery = { __typename?: 'Query' } & {
    allEmployees?: Maybe<
        Array<
            Maybe<
                { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                        user: { __typename?: 'UserType' } & Pick<UserType, 'id' | 'status'> & {
                                profile: { __typename?: 'ProfileType' } & Pick<ProfileType, 'id' | 'fullName'>
                            }
                    }
            >
        >
    >
}

export type DepartmentEmployeesQueryVariables = Exact<{
    id: Scalars['Int']
    customerId: Scalars['Int']
}>

export type DepartmentEmployeesQuery = { __typename?: 'Query' } & {
    getDepartment?: Maybe<
        { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id'> & {
                employees: Array<
                    { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                            user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                    profile: { __typename?: 'ProfileType' } & Pick<ProfileType, 'id' | 'fullName'>
                                }
                        }
                >
                passiveEmployees: Array<
                    { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                            user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                    profile: { __typename?: 'ProfileType' } & Pick<ProfileType, 'id' | 'fullName'>
                                }
                        }
                >
            }
    >
}

export type CreateInboxExportMutationVariables = Exact<{
    year?: Maybe<Scalars['Int']>
    departmentIds: Array<Scalars['Int']> | Scalars['Int']
}>

export type CreateInboxExportMutation = { __typename?: 'Mutation' } & {
    createInboxExport?: Maybe<{ __typename?: 'InboxExportType' } & Pick<InboxExportType, 'token' | 'filename'>>
}

export type EditInboxItemImpactLevelMutationVariables = Exact<{
    alertIds: Array<Scalars['Int']> | Scalars['Int']
    departmentId: Scalars['Int']
    impactLevel?: Maybe<AlertImpactLevel>
}>

export type EditInboxItemImpactLevelMutation = { __typename?: 'Mutation' } & {
    editAlertImpactLevels: Array<{ __typename?: 'AlertType' } & Pick<AlertType, 'id' | 'impactLevel'>>
}

export type InboxInternalAlertsQueryVariables = Exact<{
    filters?: Maybe<InternalAlertFiltersType>
    departmentId: Scalars['Int']
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}>

export type InboxInternalAlertsQuery = { __typename?: 'Query' } & {
    internalAlerts?: Maybe<
        { __typename?: 'PaginatedInternalAlertType' } & Pick<
            PaginatedInternalAlertType,
            'count' | 'totalCount' | 'hasNextPage' | 'page'
        > & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'InternalAlertType' } & Pick<
                            InternalAlertType,
                            'id' | 'note' | 'publishedAt'
                        > & {
                                publishedBy?: Maybe<
                                    { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                                            user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                                    profile: { __typename?: 'ProfileType' } & Pick<
                                                        ProfileType,
                                                        'id' | 'fullName'
                                                    >
                                                }
                                        }
                                >
                                departments: Array<
                                    { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                                >
                                news: { __typename?: 'NewsItemType' } & Pick<
                                    NewsItemType,
                                    | 'id'
                                    | 'title'
                                    | 'publicationDate'
                                    | 'translatedContent'
                                    | 'customSourceUrl'
                                    | 'originalSource'
                                > & {
                                        sources?: Maybe<
                                            Array<
                                                { __typename?: 'NewsItemSourceType' } & Pick<
                                                    NewsItemSourceType,
                                                    'id' | 'name'
                                                >
                                            >
                                        >
                                    }
                                topics: Array<
                                    { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'> & {
                                            theme: { __typename?: 'ThemeType' } & Pick<ThemeType, 'id' | 'name'>
                                            departments?: Maybe<
                                                Array<
                                                    { __typename?: 'DepartmentType' } & Pick<
                                                        DepartmentType,
                                                        'id' | 'name'
                                                    >
                                                >
                                            >
                                            customerFrameworks: Array<
                                                { __typename?: 'CustomerFrameworkType' } & Pick<
                                                    CustomerFrameworkType,
                                                    'id' | 'name' | 'icon' | 'color'
                                                >
                                            >
                                        }
                                >
                            }
                    >
                >
            }
    >
}

export type AcceptInternalAlertMutationVariables = Exact<{
    departmentId: Scalars['Int']
    internalAlertId: Scalars['Int']
    extraTopicIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
    note?: Maybe<Scalars['String']>
}>

export type AcceptInternalAlertMutation = { __typename?: 'Mutation' } & {
    acceptDepartmentInternalAlert: { __typename?: 'AlertType' } & Pick<AlertType, 'id'>
}

export type ArchiveInternalAlertMutationVariables = Exact<{
    departmentId: Scalars['Int']
    internalAlertId: Scalars['Int']
    archivedReason?: Maybe<Scalars['String']>
}>

export type ArchiveInternalAlertMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'archiveDepartmentInternalAlert'
>

export type DepartmentInternalAlertLogsQueryVariables = Exact<{
    internalAlertId: Scalars['Int']
    departmentId: Scalars['Int']
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}>

export type DepartmentInternalAlertLogsQuery = { __typename?: 'Query' } & {
    departmentInternalAlertLogs?: Maybe<
        { __typename?: 'PaginatedEventLogType' } & Pick<
            PaginatedEventLogType,
            'count' | 'totalCount' | 'hasNextPage'
        > & { nodes?: Maybe<Array<{ __typename?: 'EventLogType' } & InternalAlertLogFragmentFragment>> }
    >
}

export type InboxInternalAlertQueryVariables = Exact<{
    id: Scalars['Int']
    departmentId: Scalars['Int']
    customerSlug: Scalars['String']
}>

export type InboxInternalAlertQuery = { __typename?: 'Query' } & {
    internalAlert?: Maybe<
        { __typename?: 'InternalAlertType' } & Pick<InternalAlertType, 'id' | 'note' | 'publishedAt'> & {
                publishedBy?: Maybe<
                    { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                            user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                    profile: { __typename?: 'ProfileType' } & Pick<ProfileType, 'id' | 'fullName'>
                                }
                        }
                >
                news: { __typename: 'NewsItemType' } & Pick<
                    NewsItemType,
                    | 'id'
                    | 'title'
                    | 'content'
                    | 'abstract'
                    | 'publicationDate'
                    | 'presentationSetting'
                    | 'originalSource'
                    | 'customSourceUrl'
                > & {
                        sources?: Maybe<
                            Array<
                                { __typename?: 'NewsItemSourceType' } & Pick<NewsItemSourceType, 'id' | 'name'> & {
                                        group?: Maybe<
                                            { __typename?: 'NewsGroupType' } & Pick<
                                                NewsGroupType,
                                                'id' | 'name' | 'isPinned'
                                            >
                                        >
                                    }
                            >
                        >
                        attachments?: Maybe<Array<Maybe<{ __typename?: 'AttachmentType' } & AttachmentFieldsFragment>>>
                        linkedItems?: Maybe<Array<{ __typename?: 'GroupedItemType' } & LinkedItemsFieldsFragment>>
                    }
                departmentStatus?: Maybe<
                    { __typename?: 'InternalAlertStatusType' } & Pick<InternalAlertStatusType, 'id' | 'state'>
                >
                topics: Array<
                    { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'> & {
                            theme: { __typename?: 'ThemeType' } & Pick<ThemeType, 'id' | 'name'>
                            departments?: Maybe<
                                Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>
                            >
                            customerFrameworks: Array<
                                { __typename?: 'CustomerFrameworkType' } & Pick<
                                    CustomerFrameworkType,
                                    'id' | 'name' | 'icon' | 'color'
                                >
                            >
                        }
                >
            }
    >
}

export type ReturnInternalAlertMutationVariables = Exact<{
    departmentId: Scalars['Int']
    internalAlertId: Scalars['Int']
    returnNote?: Maybe<Scalars['String']>
}>

export type ReturnInternalAlertMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'returnDepartmentInternalAlert'>

export type MonitoringReportTopicsQueryVariables = Exact<{ [key: string]: never }>

export type MonitoringReportTopicsQuery = { __typename?: 'Query' } & {
    allDepartments?: Maybe<
        Array<
            { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'> & {
                    themesWithTopics?: Maybe<
                        Array<
                            { __typename?: 'DepartmentThemeWithTopicsType' } & Pick<
                                DepartmentThemeWithTopicsType,
                                'id' | 'name'
                            > & {
                                    topics?: Maybe<
                                        Array<
                                            { __typename?: 'DepartmentThemeTopicType' } & Pick<
                                                DepartmentThemeTopicType,
                                                'id' | 'name'
                                            >
                                        >
                                    >
                                }
                        >
                    >
                }
        >
    >
}

export type CreateMonitoringReportMutationVariables = Exact<{
    fields: CreateMonitoringReportFieldsInputType
}>

export type CreateMonitoringReportMutation = { __typename?: 'Mutation' } & {
    createMonitoringReport?: Maybe<{ __typename?: 'MonitoringReportType' } & Pick<MonitoringReportType, 'id'>>
}

export type CreateMonitoringReportTopicAssessmentMutationVariables = Exact<{
    monitoringReportTopicId: Scalars['Int']
    linkedItemId: Scalars['Int']
    linkedItemType: TopicAssessmentDeLinkedItemType
    designOrEffectiveness: TopicAssessmentDesignOrEffectiveNessType
    fields: MonitoringReportTopicAssessmentInputType
}>

export type CreateMonitoringReportTopicAssessmentMutation = { __typename?: 'Mutation' } & {
    createMonitoringReportTopicAssessment?: Maybe<
        { __typename?: 'MonitoringReportTopicAssessmentType' } & Pick<
            MonitoringReportTopicAssessmentType,
            'id' | 'grade' | 'initialGrade' | 'linkedItemId' | 'topicAssessmentLinkedItemType' | 'assessmentType'
        >
    >
}

export type MonitoringReportsQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    filters?: Maybe<MonitoringReportFilters>
    sort?: Maybe<MonitoringReportSort>
}>

export type MonitoringReportsQuery = { __typename?: 'Query' } & {
    monitoringReports?: Maybe<
        { __typename?: 'PaginatedMonitoringReportType' } & Pick<PaginatedMonitoringReportType, 'hasNextPage'> & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'MonitoringReportType' } & Pick<
                            MonitoringReportType,
                            'id' | 'name' | 'assessedAt' | 'updatedAt' | 'archivedAt'
                        > & {
                                departments?: Maybe<
                                    Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>
                                >
                                archivedBy?: Maybe<
                                    { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                                            user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                                    profile: { __typename?: 'ProfileType' } & Pick<
                                                        ProfileType,
                                                        'id' | 'fullName' | 'avatar'
                                                    >
                                                }
                                        }
                                >
                                file?: Maybe<
                                    { __typename?: 'FileType' } & Pick<FileType, 'id' | 'path' | 'name' | 'mimetype'>
                                >
                            }
                    >
                >
            }
    >
}

export type ArchiveMonitoringReportsMutationVariables = Exact<{
    ids: Array<Scalars['Int']> | Scalars['Int']
    reason?: Maybe<Scalars['String']>
}>

export type ArchiveMonitoringReportsMutation = { __typename?: 'Mutation' } & {
    archiveMonitoringReports: Array<
        { __typename?: 'MonitoringReportType' } & Pick<MonitoringReportType, 'id' | 'archivedAt' | 'archivedReason'> & {
                archivedBy?: Maybe<{ __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'>>
            }
    >
}

export type MonitoringReportQueryVariables = Exact<{
    monitoringReportId: Scalars['Int']
}>

export type MonitoringReportQuery = { __typename?: 'Query' } & {
    monitoringReport?: Maybe<
        { __typename: 'MonitoringReportType' } & Pick<
            MonitoringReportType,
            'id' | 'name' | 'assessedAt' | 'updatedAt' | 'archivedAt' | 'archivedReason' | 'unarchivedAt'
        > & {
                nextTask?: Maybe<{ __typename?: 'TaskType' } & Pick<TaskType, 'id' | 'dueAt' | 'priority'>>
                highestPrioTask?: Maybe<{ __typename?: 'TaskType' } & Pick<TaskType, 'id' | 'priority'>>
                file?: Maybe<{ __typename?: 'FileType' } & Pick<FileType, 'id' | 'path' | 'name' | 'mimetype'>>
                archivedBy?: Maybe<{ __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'>>
                unarchivedBy?: Maybe<{ __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'>>
                departments?: Maybe<Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>>
                monitoringReportTopics?: Maybe<
                    Array<
                        { __typename?: 'MonitoringReportTopicType' } & Pick<
                            MonitoringReportTopicType,
                            'id' | 'isRemoved' | 'notApplicable'
                        > & {
                                tasks?: Maybe<Array<{ __typename?: 'TaskType' } & Pick<TaskType, 'id'>>>
                                department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                                topic: { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'>
                                controlsAndNotes?: Maybe<
                                    Array<
                                        | ({ __typename: 'TopicControlMeasureType' } & Pick<
                                              TopicControlMeasureType,
                                              'id'
                                          > & {
                                                  control: { __typename?: 'ControlType' } & Pick<
                                                      ControlType,
                                                      'id' | 'name' | 'type'
                                                  >
                                                  assessments?: Maybe<
                                                      Array<
                                                          { __typename?: 'TopicAssessmentType' } & Pick<
                                                              TopicAssessmentType,
                                                              | 'id'
                                                              | 'grade'
                                                              | 'topicAssessmentDesignOrEffectiveNessType'
                                                              | 'updatedAt'
                                                              | 'description'
                                                              | 'documentsCount'
                                                          >
                                                      >
                                                  >
                                              })
                                        | ({ __typename: 'TopicNoteType' } & Pick<TopicNoteType, 'id' | 'title'> & {
                                                  assessments?: Maybe<
                                                      Array<
                                                          { __typename?: 'TopicAssessmentType' } & Pick<
                                                              TopicAssessmentType,
                                                              | 'id'
                                                              | 'grade'
                                                              | 'topicAssessmentDesignOrEffectiveNessType'
                                                              | 'updatedAt'
                                                              | 'description'
                                                              | 'documentsCount'
                                                          >
                                                      >
                                                  >
                                              })
                                    >
                                >
                                assessments?: Maybe<
                                    Array<
                                        { __typename?: 'MonitoringReportTopicAssessmentType' } & Pick<
                                            MonitoringReportTopicAssessmentType,
                                            | 'id'
                                            | 'updatedAt'
                                            | 'grade'
                                            | 'initialGrade'
                                            | 'linkedItemId'
                                            | 'topicAssessmentLinkedItemType'
                                            | 'assessmentType'
                                            | 'documentsCount'
                                            | 'description'
                                        >
                                    >
                                >
                            }
                    >
                >
            }
    >
}

export type MonitoringSelectQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    filters?: Maybe<MonitoringReportFilters>
    sort?: Maybe<MonitoringReportSort>
}>

export type MonitoringSelectQuery = { __typename?: 'Query' } & {
    monitoringReports?: Maybe<
        { __typename?: 'PaginatedMonitoringReportType' } & Pick<PaginatedMonitoringReportType, 'hasNextPage'> & {
                nodes?: Maybe<
                    Array<{ __typename?: 'MonitoringReportType' } & Pick<MonitoringReportType, 'id' | 'name'>>
                >
            }
    >
}

export type UnarchiveMonitoringReportsMutationVariables = Exact<{
    ids: Array<Scalars['Int']> | Scalars['Int']
}>

export type UnarchiveMonitoringReportsMutation = { __typename?: 'Mutation' } & {
    unarchiveMonitoringReports: Array<
        { __typename?: 'MonitoringReportType' } & Pick<
            MonitoringReportType,
            'id' | 'archivedAt' | 'archivedReason' | 'unarchivedAt'
        > & {
                archivedBy?: Maybe<{ __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'>>
                unarchivedBy?: Maybe<{ __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'>>
            }
    >
}

export type NewsSourceSelectQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type NewsSourceSelectQuery = { __typename?: 'Query' } & {
    newsGroup?: Maybe<
        { __typename?: 'NewsGroupType' } & Pick<NewsGroupType, 'id' | 'name'> & {
                sources: Array<
                    { __typename?: 'NewsItemSourceType' } & Pick<
                        NewsItemSourceType,
                        'id' | 'name' | 'isEnabledByCustomer'
                    >
                >
            }
    >
}

export type TranslatedNewsItemQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type TranslatedNewsItemQuery = { __typename?: 'Query' } & {
    newsItem?: Maybe<
        { __typename?: 'NewsItemType' } & Pick<
            NewsItemType,
            'id' | 'title' | 'publicationDate' | 'translatedContent'
        > & {
                attachments?: Maybe<Array<Maybe<{ __typename?: 'AttachmentType' } & AttachmentFieldsFragment>>>
                sources?: Maybe<
                    Array<
                        { __typename?: 'NewsItemSourceType' } & Pick<NewsItemSourceType, 'id'> & {
                                group?: Maybe<{ __typename?: 'NewsGroupType' } & Pick<NewsGroupType, 'id' | 'name'>>
                            }
                    >
                >
                linkedItems?: Maybe<Array<{ __typename?: 'GroupedItemType' } & LinkedItemsFieldsFragment>>
            }
    >
}

export type PinnedThemesQueryVariables = Exact<{
    customerFrameworkIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
    departmentId: Scalars['Int']
    search?: Maybe<Scalars['String']>
}>

export type PinnedThemesQuery = { __typename?: 'Query' } & {
    pinned?: Maybe<
        { __typename?: 'EmployeePinnedType' } & {
            themes?: Maybe<
                Array<
                    { __typename?: 'ThemeType' } & Pick<ThemeType, 'id' | 'name'> & {
                            customerFrameworks: Array<
                                { __typename?: 'CustomerFrameworkType' } & CustomerFrameworkFieldsFragment
                            >
                            topicAssessmentCoverage?: Maybe<
                                { __typename?: 'ThemeTopicAssessmentCoverageType' } & {
                                    design?: Maybe<
                                        {
                                            __typename?: 'ThemeTopicAssessmentCoverageScoresType'
                                        } & ThemeTopicAssessmentCoverageScoresFragmentFragment
                                    >
                                    effectiveness?: Maybe<
                                        {
                                            __typename?: 'ThemeTopicAssessmentCoverageScoresType'
                                        } & ThemeTopicAssessmentCoverageScoresFragmentFragment
                                    >
                                }
                            >
                        }
                >
            >
        }
    >
}

export type PinnedTopicsQueryVariables = Exact<{
    themeIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
    customerFrameworkIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
    departmentId: Scalars['Int']
    topicDnEFilters?: Maybe<TopicsForThemeFilters>
}>

export type PinnedTopicsQuery = { __typename?: 'Query' } & {
    pinned?: Maybe<
        { __typename?: 'EmployeePinnedType' } & {
            topics?: Maybe<
                Array<
                    { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'> & {
                            highestRisk?: Maybe<
                                { __typename?: 'RiskType' } & Pick<
                                    RiskType,
                                    'id' | 'name' | 'severity' | 'severityBruto' | 'isAccepted'
                                >
                            >
                            nonApplicable?: Maybe<
                                { __typename?: 'NonApplicableTopicType' } & Pick<
                                    NonApplicableTopicType,
                                    'id' | 'reason'
                                >
                            >
                            customerFrameworks: Array<
                                { __typename?: 'CustomerFrameworkType' } & CustomerFrameworkFieldsFragment
                            >
                            departmentTopicData?: Maybe<
                                { __typename?: 'DepartmentTopicDataType' } & Pick<DepartmentTopicDataType, 'id'> & {
                                        topicAssessmentDesignMetadata?: Maybe<
                                            {
                                                __typename?: 'DepartmentTopicDataMetadataType'
                                            } & DepartmentTopicDataMetadataFragmentFragment
                                        >
                                        topicAssessmentEffectivenessMetadata?: Maybe<
                                            {
                                                __typename?: 'DepartmentTopicDataMetadataType'
                                            } & DepartmentTopicDataMetadataFragmentFragment
                                        >
                                    }
                            >
                        }
                >
            >
        }
    >
}

export type AllCustomerDepartmentsQueryVariables = Exact<{
    customerSlug?: Maybe<Scalars['String']>
}>

export type AllCustomerDepartmentsQuery = { __typename?: 'Query' } & {
    allDepartments?: Maybe<Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>>
}

export type DeleteEmployeeMutationVariables = Exact<{
    id: Scalars['Int']
    customerSlug?: Maybe<Scalars['String']>
}>

export type DeleteEmployeeMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteEmployee'>

export type GeneratedSummaryByParentQueryVariables = Exact<{
    parentId: Scalars['Int']
    parentType: GeneratedSummaryParentTypeEnum
}>

export type GeneratedSummaryByParentQuery = { __typename?: 'Query' } & {
    generatedSummaryByParent?: Maybe<
        { __typename?: 'GeneratedSummaryType' } & Pick<GeneratedSummaryType, 'id' | 'status' | 'summary'> & {
                userVote?: Maybe<
                    { __typename?: 'GeneratedSummaryVoteType' } & Pick<
                        GeneratedSummaryVoteType,
                        'id' | 'vote' | 'changedVote'
                    >
                >
            }
    >
}

export type ArchiveCustomerNewsMutationVariables = Exact<{
    newsIds: Array<Scalars['Int']> | Scalars['Int']
    archivedReason?: Maybe<Scalars['String']>
}>

export type ArchiveCustomerNewsMutation = { __typename?: 'Mutation' } & {
    archiveCustomerNews: Array<{ __typename?: 'NewsItemType' } & SignalingNewsItemFieldsFragment>
}

export type CreateCustomerEnabledNewsSourcesExportMutationVariables = Exact<{ [key: string]: never }>

export type CreateCustomerEnabledNewsSourcesExportMutation = { __typename?: 'Mutation' } & {
    createCustomerEnabledNewsSourcesExport?: Maybe<
        { __typename?: 'CustomerEnabledNewsSourcesExportType' } & Pick<
            CustomerEnabledNewsSourcesExportType,
            'token' | 'filename'
        >
    >
}

export type EditPublishedCustomerNewsMutationVariables = Exact<{
    id: Scalars['Int']
    extraDepartmentIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
    extraTopicIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
    note?: Maybe<Scalars['String']>
}>

export type EditPublishedCustomerNewsMutation = { __typename?: 'Mutation' } & {
    editInternalAlert: { __typename?: 'InternalAlertType' } & Pick<InternalAlertType, 'id'>
}

export type CreateInternalAlertMutationVariables = Exact<{
    departmentIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
    topicIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
    newsId: Scalars['Int']
    note?: Maybe<Scalars['String']>
}>

export type CreateInternalAlertMutation = { __typename?: 'Mutation' } & {
    createInternalAlert: { __typename?: 'InternalAlertType' } & Pick<InternalAlertType, 'id'>
}

export type InternalAlertLogFragmentFragment = { __typename?: 'EventLogType' } & Pick<
    EventLogType,
    'id' | 'type' | 'occuredOn' | 'metaData'
> & {
        user?: Maybe<
            { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                    profile: { __typename?: 'ProfileType' } & Pick<ProfileType, 'id' | 'fullName'>
                }
        >
    }

export type InternalAlertLogsQueryQueryVariables = Exact<{
    internalAlertId: Scalars['Int']
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}>

export type InternalAlertLogsQueryQuery = { __typename?: 'Query' } & {
    internalAlertLogs?: Maybe<
        { __typename?: 'PaginatedEventLogType' } & Pick<
            PaginatedEventLogType,
            'count' | 'totalCount' | 'hasNextPage'
        > & { nodes?: Maybe<Array<{ __typename?: 'EventLogType' } & InternalAlertLogFragmentFragment>> }
    >
}

export type InternalAlertsForCustomerQueryVariables = Exact<{
    filters?: Maybe<InternalAlertFiltersForCustomerType>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}>

export type InternalAlertsForCustomerQuery = { __typename?: 'Query' } & {
    internalAlertsForCustomer?: Maybe<
        { __typename?: 'PaginatedInternalAlertType' } & Pick<
            PaginatedInternalAlertType,
            'count' | 'totalCount' | 'hasNextPage'
        > & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'InternalAlertType' } & Pick<InternalAlertType, 'id' | 'publishedAt'> & {
                                news: { __typename?: 'NewsItemType' } & Pick<
                                    NewsItemType,
                                    | 'id'
                                    | 'title'
                                    | 'publicationDate'
                                    | 'presentationSetting'
                                    | 'originalSource'
                                    | 'customSourceUrl'
                                >
                                statuses?: Maybe<
                                    Array<
                                        { __typename?: 'InternalAlertStatusType' } & Pick<
                                            InternalAlertStatusType,
                                            'id' | 'state' | 'returnedAt'
                                        > & {
                                                department: { __typename?: 'DepartmentType' } & Pick<
                                                    DepartmentType,
                                                    'id' | 'name'
                                                >
                                                returnedBy?: Maybe<
                                                    { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                                                            user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                                                    profile: { __typename?: 'ProfileType' } & Pick<
                                                                        ProfileType,
                                                                        'id' | 'fullName'
                                                                    >
                                                                }
                                                        }
                                                >
                                            }
                                    >
                                >
                            }
                    >
                >
            }
    >
}

export type SignalingNewsItemFieldsFragment = { __typename?: 'NewsItemType' } & Pick<
    NewsItemType,
    'id' | 'title' | 'publicationDate' | 'presentationSetting' | 'originalSource' | 'customSourceUrl'
> & {
        internalAlert?: Maybe<
            { __typename?: 'InternalAlertType' } & Pick<InternalAlertType, 'id' | 'publishedAt' | 'note'> & {
                    publishedBy?: Maybe<
                        { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                                user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                        profile: { __typename?: 'ProfileType' } & Pick<ProfileType, 'id' | 'fullName'>
                                    }
                            }
                    >
                    topics: Array<
                        { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'> & {
                                theme: { __typename?: 'ThemeType' } & Pick<ThemeType, 'id' | 'name'>
                                departments?: Maybe<
                                    Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id'>>
                                >
                                customerFrameworks: Array<
                                    { __typename?: 'CustomerFrameworkType' } & Pick<
                                        CustomerFrameworkType,
                                        'id' | 'name' | 'icon' | 'color'
                                    >
                                >
                            }
                    >
                    departments: Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>
                    statuses?: Maybe<
                        Array<
                            { __typename?: 'InternalAlertStatusType' } & Pick<
                                InternalAlertStatusType,
                                'id' | 'state' | 'returnedAt'
                            > & {
                                    department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
                                    returnedBy?: Maybe<
                                        { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                                                user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                                        profile: { __typename?: 'ProfileType' } & Pick<
                                                            ProfileType,
                                                            'id' | 'fullName'
                                                        >
                                                    }
                                            }
                                    >
                                }
                        >
                    >
                }
        >
        suggestedTopics: Array<
            { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'> & {
                    theme: { __typename?: 'ThemeType' } & Pick<ThemeType, 'id' | 'name'>
                    departments?: Maybe<Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>>
                }
        >
        sources?: Maybe<
            Array<
                { __typename?: 'NewsItemSourceType' } & Pick<NewsItemSourceType, 'id' | 'name'> & {
                        group?: Maybe<
                            { __typename?: 'NewsGroupType' } & Pick<NewsGroupType, 'id' | 'name' | 'isPinned'>
                        >
                    }
            >
        >
        archivedStatus?: Maybe<
            { __typename?: 'NewsItemArchivedStatusType' } & Pick<
                NewsItemArchivedStatusType,
                'id' | 'archivedAt' | 'archivedReason' | 'unarchivedAt'
            > & {
                    archivedBy?: Maybe<
                        { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                                user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                        profile: { __typename?: 'ProfileType' } & Pick<
                                            ProfileType,
                                            'id' | 'fullName' | 'avatar'
                                        >
                                    }
                            }
                    >
                    unarchivedBy?: Maybe<
                        { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                                user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                        profile: { __typename?: 'ProfileType' } & Pick<
                                            ProfileType,
                                            'id' | 'fullName' | 'avatar'
                                        >
                                    }
                            }
                    >
                }
        >
        publishedStatus?: Maybe<
            { __typename?: 'PublishedCustomerNewsType' } & Pick<PublishedCustomerNewsType, 'id' | 'publishedAt'> & {
                    publishedBy?: Maybe<
                        { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                                user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                        profile: { __typename?: 'ProfileType' } & Pick<
                                            ProfileType,
                                            'id' | 'fullName' | 'avatar'
                                        >
                                    }
                            }
                    >
                    topics: Array<
                        { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'> & {
                                departments?: Maybe<
                                    Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id'>>
                                >
                            }
                    >
                    departments?: Maybe<Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>>
                }
        >
    }

export type UnarchiveCustomerNewsMutationVariables = Exact<{
    newsIds: Array<Scalars['Int']> | Scalars['Int']
}>

export type UnarchiveCustomerNewsMutation = { __typename?: 'Mutation' } & {
    unarchiveCustomerNews: Array<{ __typename?: 'NewsItemType' } & SignalingNewsItemFieldsFragment>
}

export type CastVoteMutationVariables = Exact<{
    generatedSummaryId: Scalars['Int']
    vote?: Maybe<GeneratedSummaryUserVoteEnum>
}>

export type CastVoteMutation = { __typename?: 'Mutation' } & {
    castGeneratedSummaryVote: { __typename?: 'GeneratedSummaryVoteType' } & Pick<
        GeneratedSummaryVoteType,
        'id' | 'changedVote' | 'vote'
    >
}

export type PublishedCustomerNewsQueryVariables = Exact<{
    filters?: Maybe<PublishedCustomerNewsFiltersType>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}>

export type PublishedCustomerNewsQuery = { __typename?: 'Query' } & {
    publishedCustomerNews?: Maybe<
        { __typename?: 'PaginatedPublishedCustomerNewsType' } & Pick<
            PaginatedPublishedCustomerNewsType,
            'count' | 'totalCount' | 'hasNextPage' | 'page'
        > & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'PublishedCustomerNewsType' } & Pick<PublishedCustomerNewsType, 'id'> & {
                                departments?: Maybe<
                                    Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>
                                >
                                alert: { __typename?: 'AlertType' } & Pick<AlertType, 'id' | 'name'>
                            }
                    >
                >
            }
    >
}

export type SignalingDetailQueryQueryVariables = Exact<{
    id: Scalars['Int']
    customerSlug?: Maybe<Scalars['String']>
}>

export type SignalingDetailQueryQuery = { __typename?: 'Query' } & {
    newsItem?: Maybe<
        { __typename?: 'NewsItemType' } & Pick<NewsItemType, 'content' | 'abstract'> & {
                attachments?: Maybe<Array<Maybe<{ __typename?: 'AttachmentType' } & AttachmentFieldsFragment>>>
                linkedItems?: Maybe<Array<{ __typename?: 'GroupedItemType' } & LinkedItemsFieldsFragment>>
            } & SignalingNewsItemFieldsFragment
    >
}

export type SignalingOverviewQueryQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    sort?: Maybe<NewsSort>
    filters?: Maybe<NewsFilters>
}>

export type SignalingOverviewQueryQuery = { __typename?: 'Query' } & {
    newsItems?: Maybe<
        { __typename?: 'PaginatedNewsItemType' } & Pick<
            PaginatedNewsItemType,
            'count' | 'totalCount' | 'hasNextPage'
        > & { nodes?: Maybe<Array<{ __typename?: 'NewsItemType' } & SignalingNewsItemFieldsFragment>> }
    >
}

export type ConsultantTaskTemplateOverviewFragmentFragment = { __typename?: 'ConsultantTaskTemplateType' } & Pick<
    ConsultantTaskTemplateType,
    'id' | 'name' | 'description' | 'type'
> & {
        subTaskTemplates?: Maybe<
            Array<
                { __typename?: 'ConsultantTaskTemplateType' } & Pick<
                    ConsultantTaskTemplateType,
                    'id' | 'name' | 'description'
                >
            >
        >
        frameworks: Array<{ __typename?: 'FrameworkType' } & Pick<FrameworkType, 'id' | 'abbreviation' | 'name'>>
    }

export type ArchiveConsultantTaskTemplateMutationVariables = Exact<{
    taskTemplateId: Scalars['Int']
}>

export type ArchiveConsultantTaskTemplateMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'archiveConsultantTaskTemplate'
>

export type CreateConsultantTaskTemplateMutationVariables = Exact<{
    fields: CreateConsultantTaskTemplateFieldsInputType
}>

export type CreateConsultantTaskTemplateMutation = { __typename?: 'Mutation' } & {
    createConsultantTaskTemplate?: Maybe<
        { __typename?: 'ConsultantTaskTemplateType' } & ConsultantTaskTemplateOverviewFragmentFragment
    >
}

export type EditConsultantTaskTemplateMutationVariables = Exact<{
    taskTemplateId: Scalars['Int']
    fields: EditConsultantTaskTemplateFieldsInputType
}>

export type EditConsultantTaskTemplateMutation = { __typename?: 'Mutation' } & {
    editConsultantTaskTemplate?: Maybe<
        { __typename?: 'ConsultantTaskTemplateType' } & ConsultantTaskTemplateOverviewFragmentFragment
    >
}

export type SetConsultantTaskTemplateVisibilityMutationVariables = Exact<{
    isHidden: Scalars['Boolean']
}>

export type SetConsultantTaskTemplateVisibilityMutation = { __typename?: 'Mutation' } & {
    setIsConsultantTaskTemplateHidden?: Maybe<
        { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'isConsultantTaskTemplatesHidden'>
    >
}

export type ConsultantTaskTemplatesQueryVariables = Exact<{
    take?: Maybe<Scalars['Int']>
    skip?: Maybe<Scalars['Int']>
    customerId?: Maybe<Scalars['Int']>
}>

export type ConsultantTaskTemplatesQuery = { __typename?: 'Query' } & {
    consultantTaskTemplates?: Maybe<
        { __typename?: 'PaginatedConsultantTaskTemplateType' } & Pick<
            PaginatedConsultantTaskTemplateType,
            'count' | 'totalCount' | 'hasNextPage'
        > & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'ConsultantTaskTemplateType' } & Pick<
                            ConsultantTaskTemplateType,
                            'totalTaskCount'
                        > &
                            ConsultantTaskTemplateOverviewFragmentFragment
                    >
                >
            }
    >
}

export type ConsultantTaskTemplateQueryVariables = Exact<{
    consultantTaskTemplateId: Scalars['Int']
}>

export type ConsultantTaskTemplateQuery = { __typename?: 'Query' } & {
    consultantTaskTemplate?: Maybe<
        { __typename?: 'ConsultantTaskTemplateType' } & ConsultantTaskTemplateOverviewFragmentFragment
    >
}

export type ArchiveTaskMutationVariables = Exact<{
    taskId: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type ArchiveTaskMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'archiveTask'>

export type ArchiveTaskTemplateMutationVariables = Exact<{
    customerId: Scalars['Int']
    taskTemplateId: Scalars['Int']
}>

export type ArchiveTaskTemplateMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'archiveTaskTemplate'>

export type CreateSubTaskMutationVariables = Exact<{
    departmentId: Scalars['Int']
    fields: CreateTaskFieldsInputType
}>

export type CreateSubTaskMutation = { __typename?: 'Mutation' } & {
    createTask?: Maybe<{ __typename?: 'TaskType' } & TaskDetailFieldsFragmentFragment>
}

export type CreateTaskTemplateMutationVariables = Exact<{
    customerId: Scalars['Int']
    fields: CreateTaskTemplateFieldsInputType
}>

export type CreateTaskTemplateMutation = { __typename?: 'Mutation' } & {
    createTaskTemplate?: Maybe<{ __typename?: 'TaskTemplateType' } & TaskTemplateOverviewFragmentFragment>
}

export type EditTaskMutationVariables = Exact<{
    taskId: Scalars['Int']
    departmentId: Scalars['Int']
    fields: EditTaskFieldsInputType
}>

export type EditTaskMutation = { __typename?: 'Mutation' } & {
    editTask?: Maybe<{ __typename?: 'TaskType' } & TaskDetailFieldsFragmentFragment>
}

export type EditTaskTemplateMutationVariables = Exact<{
    customerId: Scalars['Int']
    taskTemplateId: Scalars['Int']
    fields: EditTaskTemplateFieldsInputType
}>

export type EditTaskTemplateMutation = { __typename?: 'Mutation' } & {
    editTaskTemplate?: Maybe<{ __typename?: 'TaskTemplateType' } & TaskTemplateOverviewFragmentFragment>
}

export type ParentTaskFieldsFragmentFragment = { __typename?: 'TaskType' } & Pick<
    TaskType,
    | 'id'
    | 'name'
    | 'description'
    | 'priority'
    | 'startAt'
    | 'dueAt'
    | 'completedAt'
    | 'forMonitoring'
    | 'reopenedAt'
    | 'repeats'
    | 'totalSubTaskCount'
    | 'completedSubTaskCount'
> & {
        linkedItem?: Maybe<
            | ({ __typename?: 'AlertType' } & LinkedTaskItemsFields_AlertType_Fragment)
            | ({ __typename?: 'ControlType' } & LinkedTaskItemsFields_ControlType_Fragment)
            | ({ __typename?: 'RiskType' } & LinkedTaskItemsFields_RiskType_Fragment)
            | ({ __typename?: 'AssessmentType' } & LinkedTaskItemsFields_AssessmentType_Fragment)
            | ({ __typename?: 'MonitoringReportType' } & LinkedTaskItemsFields_MonitoringReportType_Fragment)
            | ({ __typename?: 'TopicType' } & LinkedTaskItemsFields_TopicType_Fragment)
            | ({ __typename?: 'RadarItemType' } & LinkedTaskItemsFields_RadarItemType_Fragment)
            | ({ __typename?: 'ConsultationType' } & LinkedTaskItemsFields_ConsultationType_Fragment)
        >
        department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
        tags: Array<{ __typename?: 'TaskTagType' } & TaskTagFieldsFragment>
        employees: Array<{ __typename?: 'EmployeeType' } & TaskEmployeeFieldsFragment>
    }

export type SubTaskFieldsFragmentFragment = { __typename?: 'TaskType' } & Pick<
    TaskType,
    | 'id'
    | 'name'
    | 'description'
    | 'priority'
    | 'startAt'
    | 'dueAt'
    | 'completedAt'
    | 'forMonitoring'
    | 'reopenedAt'
    | 'repeats'
> & {
        linkedItem?: Maybe<
            | ({ __typename?: 'AlertType' } & LinkedTaskItemsFields_AlertType_Fragment)
            | ({ __typename?: 'ControlType' } & LinkedTaskItemsFields_ControlType_Fragment)
            | ({ __typename?: 'RiskType' } & LinkedTaskItemsFields_RiskType_Fragment)
            | ({ __typename?: 'AssessmentType' } & LinkedTaskItemsFields_AssessmentType_Fragment)
            | ({ __typename?: 'MonitoringReportType' } & LinkedTaskItemsFields_MonitoringReportType_Fragment)
            | ({ __typename?: 'TopicType' } & LinkedTaskItemsFields_TopicType_Fragment)
            | ({ __typename?: 'RadarItemType' } & LinkedTaskItemsFields_RadarItemType_Fragment)
            | ({ __typename?: 'ConsultationType' } & LinkedTaskItemsFields_ConsultationType_Fragment)
        >
        department: { __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>
        tags: Array<{ __typename?: 'TaskTagType' } & TaskTagFieldsFragment>
        employees: Array<{ __typename?: 'EmployeeType' } & TaskEmployeeFieldsFragment>
    }

export type TaskTemplateOverviewFragmentFragment = { __typename?: 'TaskTemplateType' } & Pick<
    TaskTemplateType,
    'id' | 'name' | 'description' | 'totalTaskCount'
> & {
        tags?: Maybe<Array<{ __typename?: 'TaskTagType' } & Pick<TaskTagType, 'id' | 'name'>>>
        subTaskTemplates?: Maybe<
            Array<
                { __typename?: 'TaskTemplateType' } & Pick<TaskTemplateType, 'id' | 'name' | 'description'> & {
                        tags?: Maybe<Array<{ __typename?: 'TaskTagType' } & Pick<TaskTagType, 'id' | 'name'>>>
                    }
            >
        >
    }

export type CustomerTaskTemplatesQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    customerId: Scalars['Int']
    customerFrameworkIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
}>

export type CustomerTaskTemplatesQuery = { __typename?: 'Query' } & {
    customerTaskTemplates?: Maybe<
        { __typename?: 'PaginatedCustomerTaskTemplateType' } & Pick<
            PaginatedCustomerTaskTemplateType,
            'count' | 'totalCount' | 'hasNextPage'
        > & {
                nodes?: Maybe<
                    Array<
                        | ({ __typename: 'TaskTemplateType' } & Pick<
                              TaskTemplateType,
                              'id' | 'name' | 'description'
                          > & {
                                  tags?: Maybe<Array<{ __typename?: 'TaskTagType' } & Pick<TaskTagType, 'id' | 'name'>>>
                                  subTaskTemplates?: Maybe<
                                      Array<
                                          { __typename?: 'TaskTemplateType' } & Pick<
                                              TaskTemplateType,
                                              'id' | 'name' | 'description'
                                          > & {
                                                  tags?: Maybe<
                                                      Array<
                                                          { __typename?: 'TaskTagType' } & Pick<
                                                              TaskTagType,
                                                              'id' | 'name'
                                                          >
                                                      >
                                                  >
                                              }
                                      >
                                  >
                              })
                        | ({
                              __typename: 'ConsultantTaskTemplateType'
                          } & ConsultantTaskTemplateOverviewFragmentFragment)
                    >
                >
            }
    >
}

export type GetTaskQueryVariables = Exact<{
    taskId: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type GetTaskQuery = { __typename?: 'Query' } & {
    task?: Maybe<{ __typename?: 'TaskType' } & TaskDetailFieldsFragmentFragment>
}

export type TaskTemplateQueryVariables = Exact<{
    taskTemplateId: Scalars['Int']
    customerId: Scalars['Int']
}>

export type TaskTemplateQuery = { __typename?: 'Query' } & {
    taskTemplate?: Maybe<{ __typename?: 'TaskTemplateType' } & TaskTemplateOverviewFragmentFragment>
}

export type TaskTemplatesOverviewQueryVariables = Exact<{
    customerId: Scalars['Int']
    take?: Maybe<Scalars['Int']>
    skip?: Maybe<Scalars['Int']>
}>

export type TaskTemplatesOverviewQuery = { __typename?: 'Query' } & {
    taskTemplates?: Maybe<
        { __typename?: 'PaginatedTaskTemplateType' } & Pick<
            PaginatedTaskTemplateType,
            'count' | 'totalCount' | 'hasNextPage'
        > & { nodes?: Maybe<Array<{ __typename?: 'TaskTemplateType' } & TaskTemplateOverviewFragmentFragment>> }
    >
}

export type CreateTasksExportMutationVariables = Exact<{
    filters?: Maybe<TaskFiltersInputType>
}>

export type CreateTasksExportMutation = { __typename?: 'Mutation' } & {
    createTasksExport?: Maybe<{ __typename?: 'TaskExportType' } & Pick<TaskExportType, 'token' | 'filename'>>
}

export type GetTaskLogsQueryVariables = Exact<{
    taskId: Scalars['Int']
}>

export type GetTaskLogsQuery = { __typename?: 'Query' } & {
    taskLogs?: Maybe<
        Array<
            Maybe<
                { __typename?: 'EventLogType' } & Pick<EventLogType, 'id' | 'type' | 'metaData' | 'occuredOn'> & {
                        user?: Maybe<
                            { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                    profile: { __typename?: 'ProfileType' } & Pick<ProfileType, 'id' | 'fullName'>
                                }
                        >
                    }
            >
        >
    >
}

export type CreateNonApplicableTopicsExportMutationVariables = Exact<{
    filters?: Maybe<TopicFilters>
    departmentId: Scalars['Int']
}>

export type CreateNonApplicableTopicsExportMutation = { __typename?: 'Mutation' } & {
    createNonApplicableTopicsExport?: Maybe<
        { __typename?: 'NonApplicableTopicsExportType' } & Pick<NonApplicableTopicsExportType, 'token' | 'filename'>
    >
}

export type ThemesForSelectQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    filters?: Maybe<ThemeFilters>
    sort?: Maybe<ThemeSort>
    customerSlug?: Maybe<Scalars['String']>
}>

export type ThemesForSelectQuery = { __typename?: 'Query' } & {
    themesForSelect?: Maybe<
        { __typename?: 'PaginatedThemeType' } & Pick<PaginatedThemeType, 'hasNextPage'> & {
                nodes?: Maybe<Array<{ __typename?: 'ThemeType' } & Pick<ThemeType, 'id' | 'name'>>>
            }
    >
}

export type ThemeTopicAssessmentCoverageScoresFragmentFragment = {
    __typename?: 'ThemeTopicAssessmentCoverageScoresType'
} & Pick<
    ThemeTopicAssessmentCoverageScoresType,
    | 'assessmentSatisfies'
    | 'assessmentSatisfiesPartly'
    | 'assessmentAlmostSatisfies'
    | 'assessmentAlmostSatisfiesPartly'
    | 'assessmentNotSatisfies'
    | 'assessmentNotSatisfiesPartly'
    | 'assessmentNotGiven'
    | 'notApplicable'
    | 'total'
    | 'lastUpdatedAt'
>

export type ThemesDetailCardListQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    customerSlug: Scalars['String']
    departmentId: Scalars['Int']
    customerFrameworkIds: Array<Scalars['Int']> | Scalars['Int']
    themeId: Scalars['Int']
    filters?: Maybe<TopicsForThemeFilters>
}>

export type ThemesDetailCardListQuery = { __typename?: 'Query' } & {
    topicsForTheme?: Maybe<
        { __typename?: 'PaginatedTopicType' } & Pick<PaginatedTopicType, 'hasNextPage' | 'totalCount'> & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'TopicType' } & Pick<
                            TopicType,
                            'id' | 'name' | 'isPinned' | 'isApplicable' | 'hasCompliance'
                        > & {
                                nonApplicable?: Maybe<
                                    { __typename?: 'NonApplicableTopicType' } & Pick<
                                        NonApplicableTopicType,
                                        'id' | 'reason'
                                    >
                                >
                                highestRisk?: Maybe<
                                    { __typename?: 'RiskType' } & Pick<
                                        RiskType,
                                        'id' | 'name' | 'severity' | 'severityBruto' | 'isAccepted'
                                    >
                                >
                                customerFrameworks: Array<
                                    { __typename?: 'CustomerFrameworkType' } & CustomerFrameworkFieldsFragment
                                >
                                departmentTopicData?: Maybe<
                                    { __typename?: 'DepartmentTopicDataType' } & Pick<DepartmentTopicDataType, 'id'> & {
                                            topicAssessmentDesignMetadata?: Maybe<
                                                {
                                                    __typename?: 'DepartmentTopicDataMetadataType'
                                                } & DepartmentTopicDataMetadataFragmentFragment
                                            >
                                            topicAssessmentEffectivenessMetadata?: Maybe<
                                                {
                                                    __typename?: 'DepartmentTopicDataMetadataType'
                                                } & DepartmentTopicDataMetadataFragmentFragment
                                            >
                                        }
                                >
                            }
                    >
                >
            }
    >
}

export type ListThemesQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    filters?: Maybe<ThemeFilters>
    customerSlug?: Maybe<Scalars['String']>
    departmentId: Scalars['Int']
    customerFrameworkIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
}>

export type ListThemesQuery = { __typename?: 'Query' } & {
    themes?: Maybe<
        { __typename?: 'PaginatedThemeType' } & Pick<PaginatedThemeType, 'hasNextPage' | 'totalCount'> & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'ThemeType' } & Pick<ThemeType, 'id' | 'name' | 'isPinned'> & {
                                topicAssessmentCoverage?: Maybe<
                                    { __typename?: 'ThemeTopicAssessmentCoverageType' } & {
                                        design?: Maybe<
                                            {
                                                __typename?: 'ThemeTopicAssessmentCoverageScoresType'
                                            } & ThemeTopicAssessmentCoverageScoresFragmentFragment
                                        >
                                        effectiveness?: Maybe<
                                            {
                                                __typename?: 'ThemeTopicAssessmentCoverageScoresType'
                                            } & ThemeTopicAssessmentCoverageScoresFragmentFragment
                                        >
                                    }
                                >
                                customerFrameworks: Array<
                                    { __typename?: 'CustomerFrameworkType' } & CustomerFrameworkFieldsFragment
                                >
                            }
                    >
                >
            }
    >
}

export type AssessmentsForTopicQueryVariables = Exact<{
    topicId: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type AssessmentsForTopicQuery = { __typename?: 'Query' } & {
    topic?: Maybe<
        { __typename?: 'TopicType' } & Pick<TopicType, 'id'> & {
                assessments: Array<{ __typename?: 'AssessmentType' } & Pick<AssessmentType, 'id' | 'name'>>
                departmentTopicData?: Maybe<
                    { __typename?: 'DepartmentTopicDataType' } & Pick<DepartmentTopicDataType, 'id'> & {
                            topicAssessmentDesignMetadata?: Maybe<
                                { __typename?: 'DepartmentTopicDataMetadataType' } & Pick<
                                    DepartmentTopicDataMetadataType,
                                    'linkedNoteCount' | 'linkedControlMeasureCount'
                                >
                            >
                            topicAssessmentEffectivenessMetadata?: Maybe<
                                { __typename?: 'DepartmentTopicDataMetadataType' } & Pick<
                                    DepartmentTopicDataMetadataType,
                                    'linkedNoteCount' | 'linkedControlMeasureCount'
                                >
                            >
                        }
                >
            }
    >
}

export type TopicAssessmentIconContainerAssessmentFragment = { __typename?: 'TopicAssessmentType' } & Pick<
    TopicAssessmentType,
    'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType' | 'description' | 'updatedAt'
> & {
        topicAssessmentDocuments?: Maybe<
            Array<
                { __typename?: 'TopicAssessmentDocumentType' } & Pick<TopicAssessmentDocumentType, 'id'> & {
                        file?: Maybe<{ __typename?: 'FileType' } & Pick<FileType, 'id' | 'name' | 'path' | 'mimetype'>>
                    }
            >
        >
        monitoringReport?: Maybe<{ __typename?: 'MonitoringReportType' } & Pick<MonitoringReportType, 'id' | 'name'>>
    }

export type TopicAssessmentIconContainerNoteFragment = { __typename?: 'TopicNoteType' } & Pick<
    TopicNoteType,
    'id' | 'title' | 'articlesAreAll'
> & { articles: Array<{ __typename?: 'LawArticleType' } & Pick<LawArticleType, 'id'>> }

export type TopicAssessmentIconContainerControlFragment = { __typename?: 'TopicControlMeasureType' } & Pick<
    TopicControlMeasureType,
    'id' | 'articlesAreAll'
> & {
        control: { __typename?: 'ControlType' } & Pick<ControlType, 'id' | 'name'>
        articles: Array<{ __typename?: 'LawArticleType' } & Pick<LawArticleType, 'id'>>
    }

export type CreateTopicAssessmentMutationVariables = Exact<{
    topicId: Scalars['Int']
    departmentId: Scalars['Int']
    linkedItemId: Scalars['Int']
    linkedItemType: TopicAssessmentDeLinkedItemType
    designOrEffectiveness: TopicAssessmentDesignOrEffectiveNessType
    documents?: Maybe<Array<Maybe<Scalars['Upload']>> | Maybe<Scalars['Upload']>>
    description?: Maybe<Scalars['String']>
    grade?: Maybe<TopicAssessmentGradeType>
}>

export type CreateTopicAssessmentMutation = { __typename?: 'Mutation' } & {
    createTopicAssessment?: Maybe<
        { __typename?: 'TopicType' } & Pick<TopicType, 'id'> & {
                topicControlMeasures?: Maybe<
                    Array<
                        { __typename?: 'TopicControlMeasureType' } & Pick<TopicControlMeasureType, 'id'> & {
                                assessments?: Maybe<
                                    Array<
                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                            TopicAssessmentType,
                                            | 'id'
                                            | 'grade'
                                            | 'description'
                                            | 'topicAssessmentDesignOrEffectiveNessType'
                                            | 'updatedAt'
                                        > & {
                                                monitoringReport?: Maybe<
                                                    { __typename?: 'MonitoringReportType' } & Pick<
                                                        MonitoringReportType,
                                                        'id' | 'name'
                                                    >
                                                >
                                                topicAssessmentDocuments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentDocumentType' } & Pick<
                                                            TopicAssessmentDocumentType,
                                                            'id'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                            }
                    >
                >
                topicNotes?: Maybe<
                    Array<
                        { __typename?: 'TopicNoteType' } & Pick<TopicNoteType, 'id'> & {
                                assessments?: Maybe<
                                    Array<
                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                            TopicAssessmentType,
                                            | 'id'
                                            | 'grade'
                                            | 'description'
                                            | 'topicAssessmentDesignOrEffectiveNessType'
                                            | 'updatedAt'
                                        > & {
                                                monitoringReport?: Maybe<
                                                    { __typename?: 'MonitoringReportType' } & Pick<
                                                        MonitoringReportType,
                                                        'id' | 'name'
                                                    >
                                                >
                                                topicAssessmentDocuments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentDocumentType' } & Pick<
                                                            TopicAssessmentDocumentType,
                                                            'id'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                            }
                    >
                >
            }
    >
}

export type CreateTopicNoteMutationVariables = Exact<{
    topicId: Scalars['Int']
    departmentId: Scalars['Int']
    fields?: Maybe<CreateTopicNoteFieldsType>
}>

export type CreateTopicNoteMutation = { __typename?: 'Mutation' } & {
    createTopicNote?: Maybe<
        { __typename?: 'TopicNoteType' } & Pick<TopicNoteType, 'id' | 'title' | 'description'> & {
                topicNoteDocuments?: Maybe<
                    Array<
                        { __typename?: 'TopicNoteDocumentType' } & Pick<TopicNoteDocumentType, 'id'> & {
                                file?: Maybe<
                                    { __typename?: 'FileType' } & Pick<FileType, 'id' | 'name' | 'path' | 'mimetype'>
                                >
                            }
                    >
                >
            }
    >
}

export type DeleteTopicNoteMutationVariables = Exact<{
    topicId: Scalars['Int']
    topicNoteId: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type DeleteTopicNoteMutation = { __typename?: 'Mutation' } & {
    deleteTopicNote?: Maybe<
        { __typename?: 'TopicType' } & Pick<TopicType, 'id'> & {
                topicNotes?: Maybe<Array<{ __typename?: 'TopicNoteType' } & Pick<TopicNoteType, 'id'>>>
            }
    >
}

export type EditTopicAssessmentMutationVariables = Exact<{
    topicAssessmentId: Scalars['Int']
    departmentId: Scalars['Int']
    linkedItemId: Scalars['Int']
    fields?: Maybe<EditTopicAssessmentFieldsType>
}>

export type EditTopicAssessmentMutation = { __typename?: 'Mutation' } & {
    editTopicAssessment?: Maybe<
        { __typename?: 'TopicType' } & Pick<TopicType, 'id'> & {
                topicControlMeasures?: Maybe<
                    Array<
                        { __typename?: 'TopicControlMeasureType' } & Pick<TopicControlMeasureType, 'id'> & {
                                assessments?: Maybe<
                                    Array<
                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                            TopicAssessmentType,
                                            | 'id'
                                            | 'grade'
                                            | 'description'
                                            | 'topicAssessmentDesignOrEffectiveNessType'
                                            | 'updatedAt'
                                        > & {
                                                monitoringReport?: Maybe<
                                                    { __typename?: 'MonitoringReportType' } & Pick<
                                                        MonitoringReportType,
                                                        'id' | 'name'
                                                    >
                                                >
                                                topicAssessmentDocuments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentDocumentType' } & Pick<
                                                            TopicAssessmentDocumentType,
                                                            'id'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                            }
                    >
                >
                topicNotes?: Maybe<
                    Array<
                        { __typename?: 'TopicNoteType' } & Pick<TopicNoteType, 'id'> & {
                                assessments?: Maybe<
                                    Array<
                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                            TopicAssessmentType,
                                            | 'id'
                                            | 'grade'
                                            | 'description'
                                            | 'topicAssessmentDesignOrEffectiveNessType'
                                            | 'updatedAt'
                                        > & {
                                                monitoringReport?: Maybe<
                                                    { __typename?: 'MonitoringReportType' } & Pick<
                                                        MonitoringReportType,
                                                        'id' | 'name'
                                                    >
                                                >
                                                topicAssessmentDocuments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentDocumentType' } & Pick<
                                                            TopicAssessmentDocumentType,
                                                            'id'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                            }
                    >
                >
            }
    >
}

export type EditTopicNoteMutationVariables = Exact<{
    topicNoteId: Scalars['Int']
    departmentId: Scalars['Int']
    fields?: Maybe<EditTopicNoteFieldsType>
}>

export type EditTopicNoteMutation = { __typename?: 'Mutation' } & {
    editTopicNote?: Maybe<
        { __typename?: 'TopicNoteType' } & Pick<TopicNoteType, 'id' | 'title' | 'description'> & {
                topicNoteDocuments?: Maybe<
                    Array<
                        { __typename?: 'TopicNoteDocumentType' } & Pick<TopicNoteDocumentType, 'id'> & {
                                file?: Maybe<
                                    { __typename?: 'FileType' } & Pick<FileType, 'id' | 'name' | 'path' | 'mimetype'>
                                >
                            }
                    >
                >
            }
    >
}

export type LinkControlsToTopicMutationVariables = Exact<{
    topicId: Scalars['Int']
    controlIds: Array<Scalars['Int']> | Scalars['Int']
    departmentId: Scalars['Int']
}>

export type LinkControlsToTopicMutation = { __typename?: 'Mutation' } & {
    addControlsToTopic?: Maybe<
        { __typename?: 'TopicType' } & Pick<TopicType, 'id'> & {
                topicControlMeasures?: Maybe<
                    Array<
                        { __typename?: 'TopicControlMeasureType' } & Pick<
                            TopicControlMeasureType,
                            'id' | 'articlesAreAll'
                        > & {
                                control: { __typename?: 'ControlType' } & Pick<
                                    ControlType,
                                    'id' | 'name' | 'type' | 'revisionDate'
                                >
                                assessments?: Maybe<
                                    Array<
                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                            TopicAssessmentType,
                                            'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType' | 'updatedAt'
                                        > & {
                                                topicAssessmentDocuments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentDocumentType' } & Pick<
                                                            TopicAssessmentDocumentType,
                                                            'id'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                                linkedChildTopicControlMeasures?: Maybe<
                                    Array<
                                        { __typename?: 'TopicControlMeasureType' } & Pick<
                                            TopicControlMeasureType,
                                            'id'
                                        > & {
                                                control: { __typename?: 'ControlType' } & Pick<
                                                    ControlType,
                                                    'id' | 'name' | 'type'
                                                >
                                                assessments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                                            TopicAssessmentType,
                                                            'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                                linkedParentTopicControlMeasures?: Maybe<
                                    Array<
                                        { __typename?: 'TopicControlMeasureType' } & Pick<
                                            TopicControlMeasureType,
                                            'id'
                                        > & {
                                                control: { __typename?: 'ControlType' } & Pick<
                                                    ControlType,
                                                    'id' | 'name' | 'type'
                                                >
                                                assessments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                                            TopicAssessmentType,
                                                            'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                                articles: Array<{ __typename?: 'LawArticleType' } & Pick<LawArticleType, 'id'>>
                            }
                    >
                >
            }
    >
}

export type RemoveControlFromTopicMutationVariables = Exact<{
    topicId: Scalars['Int']
    controlId: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type RemoveControlFromTopicMutation = { __typename?: 'Mutation' } & {
    removeControlFromTopic?: Maybe<
        { __typename?: 'TopicType' } & Pick<TopicType, 'id'> & {
                topicControlMeasures?: Maybe<
                    Array<
                        { __typename?: 'TopicControlMeasureType' } & Pick<TopicControlMeasureType, 'id'> & {
                                control: { __typename?: 'ControlType' } & Pick<ControlType, 'id'>
                            }
                    >
                >
            }
    >
}

export type SetGradeForLinkedTopicControlsMutationVariables = Exact<{
    currentTopicControlMeasureId: Scalars['Int']
    linkedItemType: TopicAssessmentDesignOrEffectiveNessType
    grade: TopicAssessmentGradeType
    departmentId: Scalars['Int']
}>

export type SetGradeForLinkedTopicControlsMutation = { __typename?: 'Mutation' } & {
    setGradeForInheritedTopicControlMeasures?: Maybe<
        { __typename?: 'TopicType' } & Pick<TopicType, 'id'> & {
                topicControlMeasures?: Maybe<
                    Array<
                        { __typename?: 'TopicControlMeasureType' } & Pick<TopicControlMeasureType, 'id'> & {
                                assessments?: Maybe<
                                    Array<
                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                            TopicAssessmentType,
                                            'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType' | 'updatedAt'
                                        > & {
                                                topicAssessmentDocuments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentDocumentType' } & Pick<
                                                            TopicAssessmentDocumentType,
                                                            'id'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                                linkedChildTopicControlMeasures?: Maybe<
                                    Array<
                                        { __typename?: 'TopicControlMeasureType' } & Pick<
                                            TopicControlMeasureType,
                                            'id'
                                        > & {
                                                assessments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                                            TopicAssessmentType,
                                                            | 'id'
                                                            | 'grade'
                                                            | 'updatedAt'
                                                            | 'topicAssessmentDesignOrEffectiveNessType'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                                linkedParentTopicControlMeasures?: Maybe<
                                    Array<
                                        { __typename?: 'TopicControlMeasureType' } & Pick<
                                            TopicControlMeasureType,
                                            'id'
                                        > & {
                                                assessments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                                            TopicAssessmentType,
                                                            | 'id'
                                                            | 'grade'
                                                            | 'updatedAt'
                                                            | 'topicAssessmentDesignOrEffectiveNessType'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                            }
                    >
                >
            }
    >
}

export type SetTopicDnEArticlesMutationVariables = Exact<{
    topicId: Scalars['Int']
    linkedItemId: Scalars['Int']
    linkedItemType: TopicAssessmentDeLinkedItemType
    lawArticleIds: Array<Scalars['Int']> | Scalars['Int']
    departmentId: Scalars['Int']
}>

export type SetTopicDnEArticlesMutation = { __typename?: 'Mutation' } & {
    setArticlesForTopicControlMeasureOrNote?: Maybe<
        { __typename?: 'TopicType' } & Pick<TopicType, 'id'> & {
                topicControlMeasures?: Maybe<
                    Array<
                        { __typename?: 'TopicControlMeasureType' } & Pick<
                            TopicControlMeasureType,
                            'id' | 'articlesAreAll'
                        > & { articles: Array<{ __typename?: 'LawArticleType' } & Pick<LawArticleType, 'id'>> }
                    >
                >
                topicNotes?: Maybe<
                    Array<
                        { __typename?: 'TopicNoteType' } & Pick<TopicNoteType, 'id' | 'articlesAreAll'> & {
                                articles: Array<{ __typename?: 'LawArticleType' } & Pick<LawArticleType, 'id'>>
                            }
                    >
                >
            }
    >
}

export type DepartmentTopicDataMetadataFragmentFragment = { __typename?: 'DepartmentTopicDataMetadataType' } & Pick<
    DepartmentTopicDataMetadataType,
    'grade' | 'linkedControlMeasureCount' | 'linkedNoteCount' | 'lastUpdatedAt'
> & {
        assessmentCounts: { __typename?: 'DepartmentTopicAssessmentCountsType' } & Pick<
            DepartmentTopicAssessmentCountsType,
            'assessmentSatisfies' | 'assessmentAlmostSatisfies' | 'assessmentNotSatisfies' | 'assessmentNotGiven'
        >
    }

export type TopicAssessmentQueryVariables = Exact<{
    id: Scalars['Int']
    linkedItemId: Scalars['Int']
    linkedItemType: TopicAssessmentDeLinkedItemType
    departmentId: Scalars['Int']
}>

export type TopicAssessmentQuery = { __typename?: 'Query' } & {
    topicAssessment?: Maybe<
        { __typename?: 'TopicAssessmentType' } & Pick<
            TopicAssessmentType,
            'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType' | 'description'
        > & {
                topicAssessmentDocuments?: Maybe<
                    Array<
                        { __typename?: 'TopicAssessmentDocumentType' } & Pick<TopicAssessmentDocumentType, 'id'> & {
                                file?: Maybe<
                                    { __typename?: 'FileType' } & Pick<FileType, 'id' | 'name' | 'path' | 'mimetype'>
                                >
                            }
                    >
                >
                monitoringReport?: Maybe<
                    { __typename?: 'MonitoringReportType' } & Pick<MonitoringReportType, 'id' | 'name'>
                >
            }
    >
}

export type TopicControlMeasureArticlesQueryVariables = Exact<{
    id: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type TopicControlMeasureArticlesQuery = { __typename?: 'Query' } & {
    topicControlMeasure?: Maybe<
        { __typename?: 'TopicControlMeasureType' } & Pick<TopicControlMeasureType, 'id' | 'articlesAreAll'> & {
                articles: Array<{ __typename?: 'LawArticleType' } & Pick<LawArticleType, 'id'>>
            }
    >
}

export type TopicDesignAndEffectivenessSummaryQueryVariables = Exact<{
    id: Scalars['Int']
    customerSlug?: Maybe<Scalars['String']>
    departmentId: Scalars['Int']
}>

export type TopicDesignAndEffectivenessSummaryQuery = { __typename?: 'Query' } & {
    topic?: Maybe<
        { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'> & {
                theme: { __typename?: 'ThemeType' } & Pick<ThemeType, 'id' | 'name'>
                topicControlMeasures?: Maybe<
                    Array<
                        { __typename?: 'TopicControlMeasureType' } & Pick<TopicControlMeasureType, 'id'> & {
                                control: { __typename?: 'ControlType' } & Pick<
                                    ControlType,
                                    'id' | 'name' | 'type' | 'revisionDate'
                                >
                                assessments?: Maybe<
                                    Array<
                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                            TopicAssessmentType,
                                            | 'id'
                                            | 'grade'
                                            | 'description'
                                            | 'topicAssessmentDesignOrEffectiveNessType'
                                            | 'updatedAt'
                                        > & {
                                                monitoringReport?: Maybe<
                                                    { __typename?: 'MonitoringReportType' } & Pick<
                                                        MonitoringReportType,
                                                        'id' | 'name'
                                                    >
                                                >
                                                topicAssessmentDocuments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentDocumentType' } & Pick<
                                                            TopicAssessmentDocumentType,
                                                            'id'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                                articles: Array<{ __typename?: 'LawArticleType' } & Pick<LawArticleType, 'id'>>
                            }
                    >
                >
                topicNotes?: Maybe<
                    Array<
                        { __typename?: 'TopicNoteType' } & Pick<TopicNoteType, 'id' | 'title'> & {
                                articles: Array<{ __typename?: 'LawArticleType' } & Pick<LawArticleType, 'id'>>
                                assessments?: Maybe<
                                    Array<
                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                            TopicAssessmentType,
                                            | 'id'
                                            | 'grade'
                                            | 'description'
                                            | 'topicAssessmentDesignOrEffectiveNessType'
                                            | 'updatedAt'
                                        > & {
                                                monitoringReport?: Maybe<
                                                    { __typename?: 'MonitoringReportType' } & Pick<
                                                        MonitoringReportType,
                                                        'id' | 'name'
                                                    >
                                                >
                                                topicAssessmentDocuments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentDocumentType' } & Pick<
                                                            TopicAssessmentDocumentType,
                                                            'id'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                            }
                    >
                >
            }
    >
}

export type TopicLogsQueryVariables = Exact<{
    departmentId: Scalars['Int']
    linkedItemId: Scalars['Int']
    linkedItemType: TopicAssessmentDeLinkedItemType
}>

export type TopicLogsQuery = { __typename?: 'Query' } & {
    topicLogs?: Maybe<
        Array<
            Maybe<
                { __typename?: 'EventLogType' } & Pick<EventLogType, 'id' | 'type' | 'metaData' | 'occuredOn'> & {
                        user?: Maybe<
                            { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                    profile: { __typename?: 'ProfileType' } & Pick<ProfileType, 'id' | 'fullName'>
                                }
                        >
                    }
            >
        >
    >
}

export type TopicNoteQueryVariables = Exact<{
    id: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type TopicNoteQuery = { __typename?: 'Query' } & {
    topicNote?: Maybe<
        { __typename?: 'TopicNoteType' } & Pick<TopicNoteType, 'id' | 'title' | 'description'> & {
                topicNoteDocuments?: Maybe<
                    Array<
                        { __typename?: 'TopicNoteDocumentType' } & Pick<TopicNoteDocumentType, 'id'> & {
                                file?: Maybe<
                                    { __typename?: 'FileType' } & Pick<FileType, 'id' | 'name' | 'path' | 'mimetype'>
                                >
                            }
                    >
                >
            }
    >
}

export type TopicNoteArticlesQueryVariables = Exact<{
    id: Scalars['Int']
    departmentId: Scalars['Int']
}>

export type TopicNoteArticlesQuery = { __typename?: 'Query' } & {
    topicNote?: Maybe<
        { __typename?: 'TopicNoteType' } & Pick<TopicNoteType, 'id' | 'articlesAreAll'> & {
                articles: Array<{ __typename?: 'LawArticleType' } & Pick<LawArticleType, 'id'>>
            }
    >
}

export type TopicUnassessedArticlesQueryVariables = Exact<{
    id: Scalars['Int']
    customerSlug?: Maybe<Scalars['String']>
    departmentId: Scalars['Int']
}>

export type TopicUnassessedArticlesQuery = { __typename?: 'Query' } & {
    topic?: Maybe<
        { __typename?: 'TopicType' } & Pick<TopicType, 'id'> & {
                unassessedArticles: Array<
                    { __typename?: 'LawArticleType' } & Pick<LawArticleType, 'id' | 'attachmentIsLeading' | 'title'> & {
                            expired?: Maybe<
                                { __typename?: 'LawArticleExpiredObjectType' } & Pick<
                                    LawArticleExpiredObjectType,
                                    'expiredAt' | 'willBeDeletedAt'
                                >
                            >
                            abstractLawArticle: { __typename?: 'AbstractLawArticleType' } & Pick<
                                AbstractLawArticleType,
                                'id' | 'abstractLawId'
                            >
                            attachments?: Maybe<
                                Array<
                                    Maybe<
                                        { __typename?: 'AttachmentType' } & Pick<
                                            AttachmentType,
                                            'id' | 'mimetype' | 'path' | 'filename'
                                        >
                                    >
                                >
                            >
                        }
                >
            }
    >
}

export type MinimalTopicQueryVariables = Exact<{
    id: Scalars['Int']
    customerSlug?: Maybe<Scalars['String']>
    departmentId?: Maybe<Scalars['Int']>
}>

export type MinimalTopicQuery = { __typename?: 'Query' } & {
    topic?: Maybe<
        { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name' | 'abstract'> & {
                customerFrameworks: Array<{ __typename?: 'CustomerFrameworkType' } & CustomerFrameworkFieldsFragment>
            }
    >
}

export type TopicQueryVariables = Exact<{
    id: Scalars['Int']
    customerSlug?: Maybe<Scalars['String']>
    departmentId?: Maybe<Scalars['Int']>
}>

export type TopicQuery = { __typename?: 'Query' } & {
    topic?: Maybe<
        { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name' | 'abstract'> & {
                customerFrameworks: Array<{ __typename?: 'CustomerFrameworkType' } & CustomerFrameworkFieldsFragment>
                theme: { __typename?: 'ThemeType' } & Pick<ThemeType, 'id' | 'name'>
                linkedItems?: Maybe<Array<{ __typename?: 'GroupedItemType' } & LinkedItemsFieldsFragment>>
            }
    >
}

export type TopicsQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    sort?: Maybe<TopicSort>
    filters?: Maybe<TopicFilters>
    customerSlug?: Maybe<Scalars['String']>
    departmentId?: Maybe<Scalars['Int']>
}>

export type TopicsQuery = { __typename?: 'Query' } & {
    topics?: Maybe<
        { __typename?: 'PaginatedTopicType' } & Pick<PaginatedTopicType, 'count' | 'totalCount' | 'hasNextPage'> & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'> & {
                                theme: { __typename?: 'ThemeType' } & Pick<ThemeType, 'id' | 'name'>
                                customerFrameworks: Array<
                                    { __typename?: 'CustomerFrameworkType' } & CustomerFrameworkFieldsFragment
                                >
                                departments?: Maybe<
                                    Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>
                                >
                            }
                    >
                >
            }
    >
}

export type RequestSamlRedirectUrlForEmailMutationVariables = Exact<{
    email?: Maybe<Scalars['String']>
}>

export type RequestSamlRedirectUrlForEmailMutation = { __typename?: 'Mutation' } & {
    requestSAMLRedirectURLForEmail: { __typename?: 'SAMLRedirectURLResponseType' } & Pick<
        SamlRedirectUrlResponseType,
        'redirectURL'
    >
}

export type AttachmentFieldsFragment = { __typename?: 'AttachmentType' } & Pick<
    AttachmentType,
    'id' | 'path' | 'filename' | 'mimetype'
>

export type CustomerFrameworkFieldsFragment = { __typename?: 'CustomerFrameworkType' } & Pick<
    CustomerFrameworkType,
    'id' | 'name' | 'icon' | 'color'
>

type LinkedItemFragment_TopicType_Fragment = { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'createdAt' | 'name'>

type LinkedItemFragment_RadarItemType_Fragment = { __typename?: 'RadarItemType' } & Pick<
    RadarItemType,
    'id' | 'createdAt' | 'name' | 'expectedDate'
> & { phase?: Maybe<{ __typename?: 'RadarPhaseType' } & Pick<RadarPhaseType, 'id' | 'name'>> }

type LinkedItemFragment_LawArticleType_Fragment = { __typename?: 'LawArticleType' } & Pick<
    LawArticleType,
    | 'id'
    | 'createdAt'
    | 'title'
    | 'isSingleArticle'
    | 'updatedAt'
    | 'lawId'
    | 'lawGroupId'
    | 'abstractLawArticleId'
    | 'attachmentIsLeading'
> & {
        law?: Maybe<
            { __typename?: 'LawType' } & Pick<LawType, 'id' | 'name' | 'abstractLawId'> & {
                    lawSource?: Maybe<{ __typename?: 'LawSourceType' } & Pick<LawSourceType, 'id' | 'entryIntoForce'>>
                }
        >
        attachments?: Maybe<
            Array<
                Maybe<{ __typename?: 'AttachmentType' } & Pick<AttachmentType, 'id' | 'path' | 'filename' | 'mimetype'>>
            >
        >
    }

type LinkedItemFragment_ConsultationType_Fragment = { __typename?: 'ConsultationType' } & Pick<
    ConsultationType,
    'id' | 'createdAt' | 'name'
>

type LinkedItemFragment_NewsItemType_Fragment = { __typename: 'NewsItemType' } & Pick<
    NewsItemType,
    'id' | 'title' | 'publicationDate' | 'presentationSetting' | 'originalSource' | 'customSourceUrl'
> & {
        attachments?: Maybe<
            Array<
                Maybe<{ __typename?: 'AttachmentType' } & Pick<AttachmentType, 'id' | 'path' | 'filename' | 'mimetype'>>
            >
        >
        sources?: Maybe<Array<{ __typename?: 'NewsItemSourceType' } & Pick<NewsItemSourceType, 'id' | 'name'>>>
    }

export type LinkedItemFragmentFragment =
    | LinkedItemFragment_TopicType_Fragment
    | LinkedItemFragment_RadarItemType_Fragment
    | LinkedItemFragment_LawArticleType_Fragment
    | LinkedItemFragment_ConsultationType_Fragment
    | LinkedItemFragment_NewsItemType_Fragment

export type LinkedItemsFieldsFragment = { __typename?: 'GroupedItemType' } & Pick<
    GroupedItemType,
    'type' | 'entityType'
> & {
        items?: Maybe<
            Array<
                | ({ __typename?: 'TopicType' } & LinkedItemFragment_TopicType_Fragment)
                | ({ __typename?: 'RadarItemType' } & LinkedItemFragment_RadarItemType_Fragment)
                | ({ __typename?: 'LawArticleType' } & LinkedItemFragment_LawArticleType_Fragment)
                | ({ __typename?: 'ConsultationType' } & LinkedItemFragment_ConsultationType_Fragment)
                | ({ __typename?: 'NewsItemType' } & LinkedItemFragment_NewsItemType_Fragment)
            >
        >
    }

type LinkedTaskItemsFields_AlertType_Fragment = { __typename: 'AlertType' } & Pick<
    AlertType,
    'id' | 'name' | 'impactLevel'
>

type LinkedTaskItemsFields_ControlType_Fragment = { __typename: 'ControlType' } & Pick<
    ControlType,
    'id' | 'name' | 'type' | 'revisionDate'
>

type LinkedTaskItemsFields_RiskType_Fragment = { __typename: 'RiskType' } & Pick<
    RiskType,
    'id' | 'name' | 'severity' | 'isAccepted'
>

type LinkedTaskItemsFields_AssessmentType_Fragment = { __typename: 'AssessmentType' } & Pick<
    AssessmentType,
    'id' | 'name'
>

type LinkedTaskItemsFields_MonitoringReportType_Fragment = { __typename: 'MonitoringReportType' } & Pick<
    MonitoringReportType,
    'id' | 'name'
>

type LinkedTaskItemsFields_TopicType_Fragment = { __typename: 'TopicType' } & Pick<TopicType, 'id' | 'name'>

type LinkedTaskItemsFields_RadarItemType_Fragment = { __typename: 'RadarItemType' } & Pick<
    RadarItemType,
    'id' | 'name' | 'isDeleted'
>

type LinkedTaskItemsFields_ConsultationType_Fragment = { __typename: 'ConsultationType' } & Pick<
    ConsultationType,
    'id' | 'name'
>

export type LinkedTaskItemsFieldsFragment =
    | LinkedTaskItemsFields_AlertType_Fragment
    | LinkedTaskItemsFields_ControlType_Fragment
    | LinkedTaskItemsFields_RiskType_Fragment
    | LinkedTaskItemsFields_AssessmentType_Fragment
    | LinkedTaskItemsFields_MonitoringReportType_Fragment
    | LinkedTaskItemsFields_TopicType_Fragment
    | LinkedTaskItemsFields_RadarItemType_Fragment
    | LinkedTaskItemsFields_ConsultationType_Fragment

export type SetUserMergeRequestResponseMutationVariables = Exact<{
    token: Scalars['String']
    accepted: Scalars['Boolean']
}>

export type SetUserMergeRequestResponseMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'setUserMergeRequestResponse'
>

export type UserMergeRequestQueryVariables = Exact<{
    token: Scalars['String']
}>

export type UserMergeRequestQuery = { __typename?: 'Query' } & {
    userMergeRequest?: Maybe<
        { __typename?: 'UserMergeRequestType' } & Pick<UserMergeRequestType, 'id' | 'mergingUserCustomerNames'> & {
                requestedBy: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                        profile: { __typename?: 'ProfileType' } & Pick<ProfileType, 'id' | 'fullName'>
                    }
                baseUser: { __typename?: 'UserType' } & Pick<UserType, 'id' | 'email'> & {
                        profile: { __typename?: 'ProfileType' } & Pick<ProfileType, 'id' | 'fullName'>
                    }
            }
    >
}

export type SwitchCustomerMutationVariables = Exact<{
    employeeId: Scalars['Int']
    token: Scalars['String']
}>

export type SwitchCustomerMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'switchEmployee'>

export type TopicDesignAndEffectivenessQueryVariables = Exact<{
    id: Scalars['Int']
    customerSlug?: Maybe<Scalars['String']>
    departmentId: Scalars['Int']
}>

export type TopicDesignAndEffectivenessQuery = { __typename?: 'Query' } & {
    topic?: Maybe<
        { __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'> & {
                theme: { __typename?: 'ThemeType' } & Pick<ThemeType, 'id' | 'name'>
                topicControlMeasures?: Maybe<
                    Array<
                        { __typename: 'TopicControlMeasureType' } & Pick<
                            TopicControlMeasureType,
                            'id' | 'articlesAreAll'
                        > & {
                                control: { __typename?: 'ControlType' } & Pick<
                                    ControlType,
                                    'id' | 'name' | 'type' | 'revisionDate'
                                >
                                assessments?: Maybe<
                                    Array<
                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                            TopicAssessmentType,
                                            | 'id'
                                            | 'grade'
                                            | 'description'
                                            | 'topicAssessmentDesignOrEffectiveNessType'
                                            | 'updatedAt'
                                        > & {
                                                monitoringReport?: Maybe<
                                                    { __typename?: 'MonitoringReportType' } & Pick<
                                                        MonitoringReportType,
                                                        'id' | 'name'
                                                    >
                                                >
                                                topicAssessmentDocuments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentDocumentType' } & Pick<
                                                            TopicAssessmentDocumentType,
                                                            'id'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                                linkedChildTopicControlMeasures?: Maybe<
                                    Array<
                                        { __typename?: 'TopicControlMeasureType' } & Pick<
                                            TopicControlMeasureType,
                                            'id'
                                        > & {
                                                control: { __typename?: 'ControlType' } & Pick<
                                                    ControlType,
                                                    'id' | 'name' | 'type'
                                                >
                                                assessments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                                            TopicAssessmentType,
                                                            'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                                linkedNestedChildTopicControlMeasures?: Maybe<
                                    Array<
                                        { __typename?: 'TopicControlMeasureType' } & Pick<
                                            TopicControlMeasureType,
                                            'id'
                                        > & {
                                                control: { __typename?: 'ControlType' } & Pick<
                                                    ControlType,
                                                    'id' | 'name' | 'type'
                                                >
                                                assessments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                                            TopicAssessmentType,
                                                            'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                                linkedParentTopicControlMeasures?: Maybe<
                                    Array<
                                        { __typename?: 'TopicControlMeasureType' } & Pick<
                                            TopicControlMeasureType,
                                            'id'
                                        > & {
                                                control: { __typename?: 'ControlType' } & Pick<
                                                    ControlType,
                                                    'id' | 'name' | 'type'
                                                >
                                                assessments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                                            TopicAssessmentType,
                                                            'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                                linkedNestedParentTopicControlMeasures?: Maybe<
                                    Array<
                                        { __typename?: 'TopicControlMeasureType' } & Pick<
                                            TopicControlMeasureType,
                                            'id'
                                        > & {
                                                control: { __typename?: 'ControlType' } & Pick<
                                                    ControlType,
                                                    'id' | 'name' | 'type'
                                                >
                                                assessments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                                            TopicAssessmentType,
                                                            'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                                articles: Array<
                                    { __typename?: 'LawArticleType' } & Pick<LawArticleType, 'id'> & {
                                            expired?: Maybe<
                                                { __typename?: 'LawArticleExpiredObjectType' } & Pick<
                                                    LawArticleExpiredObjectType,
                                                    'expiredAt' | 'willBeDeletedAt'
                                                >
                                            >
                                        }
                                >
                            }
                    >
                >
                topicNotes?: Maybe<
                    Array<
                        { __typename: 'TopicNoteType' } & Pick<TopicNoteType, 'id' | 'title' | 'articlesAreAll'> & {
                                articles: Array<
                                    { __typename?: 'LawArticleType' } & Pick<LawArticleType, 'id'> & {
                                            expired?: Maybe<
                                                { __typename?: 'LawArticleExpiredObjectType' } & Pick<
                                                    LawArticleExpiredObjectType,
                                                    'expiredAt' | 'willBeDeletedAt'
                                                >
                                            >
                                        }
                                >
                                assessments?: Maybe<
                                    Array<
                                        { __typename?: 'TopicAssessmentType' } & Pick<
                                            TopicAssessmentType,
                                            | 'id'
                                            | 'grade'
                                            | 'description'
                                            | 'topicAssessmentDesignOrEffectiveNessType'
                                            | 'updatedAt'
                                        > & {
                                                monitoringReport?: Maybe<
                                                    { __typename?: 'MonitoringReportType' } & Pick<
                                                        MonitoringReportType,
                                                        'id' | 'name'
                                                    >
                                                >
                                                topicAssessmentDocuments?: Maybe<
                                                    Array<
                                                        { __typename?: 'TopicAssessmentDocumentType' } & Pick<
                                                            TopicAssessmentDocumentType,
                                                            'id'
                                                        >
                                                    >
                                                >
                                            }
                                    >
                                >
                            }
                    >
                >
            }
    >
}

export type TopicDetailQueryVariables = Exact<{
    id: Scalars['Int']
    customerSlug?: Maybe<Scalars['String']>
    departmentId: Scalars['Int']
}>

export type TopicDetailQuery = { __typename?: 'Query' } & {
    topic?: Maybe<
        { __typename: 'TopicType' } & Pick<TopicType, 'id' | 'name' | 'abstract'> & {
                nextTask?: Maybe<{ __typename?: 'TaskType' } & Pick<TaskType, 'id' | 'dueAt' | 'priority'>>
                highestPrioTask?: Maybe<{ __typename?: 'TaskType' } & Pick<TaskType, 'id' | 'priority'>>
                customerFrameworks: Array<{ __typename?: 'CustomerFrameworkType' } & CustomerFrameworkFieldsFragment>
                theme: { __typename?: 'ThemeType' } & Pick<ThemeType, 'id' | 'name'>
                nonApplicable?: Maybe<
                    { __typename?: 'NonApplicableTopicType' } & Pick<
                        NonApplicableTopicType,
                        'id' | 'createdAt' | 'reason'
                    >
                >
                linkedAlerts?: Maybe<
                    Array<
                        { __typename?: 'AlertType' } & Pick<
                            AlertType,
                            'id' | 'name' | 'publishedAt' | 'impactLevel'
                        > & {
                                tasks?: Maybe<
                                    Array<
                                        { __typename?: 'TaskType' } & Pick<
                                            TaskType,
                                            'id' | 'completedAt' | 'dueAt' | 'ignoredAt'
                                        > & {
                                                employees: Array<
                                                    { __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'> & {
                                                            user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
                                                                    profile: { __typename?: 'ProfileType' } & Pick<
                                                                        ProfileType,
                                                                        'id' | 'fullName' | 'avatar'
                                                                    >
                                                                }
                                                        }
                                                >
                                            }
                                    >
                                >
                            }
                    >
                >
                linkedRadarItems?: Maybe<
                    Array<
                        { __typename?: 'RadarItemType' } & Pick<RadarItemType, 'id' | 'name'> & {
                                itemImpacts?: Maybe<
                                    Array<
                                        { __typename?: 'ItemImpactType' } & Pick<ItemImpactType, 'id' | 'impact'> & {
                                                impactedEntity: { __typename: 'TopicType' } & Pick<TopicType, 'id'>
                                            }
                                    >
                                >
                            }
                    >
                >
                departmentTopicData?: Maybe<
                    { __typename?: 'DepartmentTopicDataType' } & Pick<DepartmentTopicDataType, 'id'> & {
                            topicAssessmentDesignMetadata?: Maybe<
                                {
                                    __typename?: 'DepartmentTopicDataMetadataType'
                                } & DepartmentTopicDataMetadataFragmentFragment
                            >
                            topicAssessmentEffectivenessMetadata?: Maybe<
                                {
                                    __typename?: 'DepartmentTopicDataMetadataType'
                                } & DepartmentTopicDataMetadataFragmentFragment
                            >
                        }
                >
                departments?: Maybe<Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>>
            }
    >
}

export type TopicLinkedItemsQueryVariables = Exact<{
    id: Scalars['Int']
    customerSlug?: Maybe<Scalars['String']>
    lawArticlesOnly?: Maybe<Scalars['Boolean']>
    getExpiredStatus: Scalars['Boolean']
    filterDeletedArticles?: Maybe<Scalars['Boolean']>
}>

export type TopicLinkedItemsQuery = { __typename?: 'Query' } & {
    topic?: Maybe<
        { __typename: 'TopicType' } & Pick<TopicType, 'id'> & {
                linkedItems?: Maybe<
                    Array<
                        { __typename?: 'GroupedItemType' } & Pick<GroupedItemType, 'type' | 'entityType'> & {
                                items?: Maybe<
                                    Array<
                                        | ({ __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'>)
                                        | ({ __typename?: 'RadarItemType' } & Pick<
                                              RadarItemType,
                                              'id' | 'name' | 'expectedDate'
                                          >)
                                        | ({ __typename?: 'LawArticleType' } & Pick<
                                              LawArticleType,
                                              | 'id'
                                              | 'title'
                                              | 'isSingleArticle'
                                              | 'updatedAt'
                                              | 'lawId'
                                              | 'lawGroupId'
                                              | 'abstractLawArticleId'
                                              | 'attachmentIsLeading'
                                          > & {
                                                  law?: Maybe<
                                                      { __typename?: 'LawType' } & Pick<
                                                          LawType,
                                                          'id' | 'name' | 'abstractLawId'
                                                      > & {
                                                              lawSource?: Maybe<
                                                                  { __typename?: 'LawSourceType' } & Pick<
                                                                      LawSourceType,
                                                                      'id' | 'entryIntoForce'
                                                                  >
                                                              >
                                                          }
                                                  >
                                                  attachments?: Maybe<
                                                      Array<
                                                          Maybe<
                                                              { __typename?: 'AttachmentType' } & Pick<
                                                                  AttachmentType,
                                                                  'id' | 'path' | 'filename' | 'mimetype'
                                                              >
                                                          >
                                                      >
                                                  >
                                                  expired?: Maybe<
                                                      { __typename?: 'LawArticleExpiredObjectType' } & Pick<
                                                          LawArticleExpiredObjectType,
                                                          'expiredAt' | 'willBeDeletedAt'
                                                      >
                                                  >
                                              })
                                        | ({ __typename?: 'ConsultationType' } & Pick<ConsultationType, 'id' | 'name'>)
                                        | ({ __typename?: 'NewsItemType' } & Pick<
                                              NewsItemType,
                                              | 'id'
                                              | 'title'
                                              | 'publicationDate'
                                              | 'presentationSetting'
                                              | 'originalSource'
                                              | 'customSourceUrl'
                                          > & {
                                                  attachments?: Maybe<
                                                      Array<
                                                          Maybe<
                                                              { __typename?: 'AttachmentType' } & Pick<
                                                                  AttachmentType,
                                                                  'id' | 'path' | 'filename' | 'mimetype'
                                                              >
                                                          >
                                                      >
                                                  >
                                              })
                                    >
                                >
                            }
                    >
                >
            }
    >
}

export type ExportRadarItemsMutationVariables = Exact<{
    filters?: Maybe<RadarItemFilters>
    customerSlug?: Maybe<Scalars['String']>
}>

export type ExportRadarItemsMutation = { __typename?: 'Mutation' } & {
    exportRadarItems?: Maybe<{ __typename?: 'RadarItemsExportType' } & Pick<RadarItemsExportType, 'token' | 'filename'>>
}

export type RadarDetailQueryVariables = Exact<{
    id: Scalars['Int']
    customerSlug?: Maybe<Scalars['String']>
    departmentId: Scalars['Int']
}>

export type RadarDetailQuery = { __typename?: 'Query' } & {
    radarItem?: Maybe<
        { __typename: 'RadarItemType' } & Pick<
            RadarItemType,
            'id' | 'name' | 'content' | 'expectedDate' | 'lastEditorialChangeAt' | 'isDeleted' | 'following'
        > & {
                nextTask?: Maybe<{ __typename?: 'TaskType' } & Pick<TaskType, 'id' | 'dueAt' | 'priority'>>
                highestPrioTask?: Maybe<{ __typename?: 'TaskType' } & Pick<TaskType, 'id' | 'priority'>>
                phase?: Maybe<{ __typename?: 'RadarPhaseType' } & Pick<RadarPhaseType, 'id' | 'name'>>
                customerFrameworks?: Maybe<
                    Array<{ __typename?: 'CustomerFrameworkType' } & CustomerFrameworkFieldsFragment>
                >
                linkedTopics?: Maybe<Array<{ __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'>>>
                itemImpacts?: Maybe<
                    Array<
                        { __typename?: 'ItemImpactType' } & Pick<ItemImpactType, 'id' | 'impact'> & {
                                impactedEntity: { __typename: 'TopicType' } & Pick<TopicType, 'id'>
                            }
                    >
                >
            }
    >
}

export type RadarItemsQueryVariables = Exact<{
    filters?: Maybe<RadarItemFilters>
    customerSlug?: Maybe<Scalars['String']>
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    departmentId: Scalars['Int']
}>

export type RadarItemsQuery = { __typename?: 'Query' } & {
    radarItems?: Maybe<
        { __typename?: 'PaginatedRadarItemType' } & Pick<PaginatedRadarItemType, 'hasNextPage'> & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'RadarItemType' } & Pick<
                            RadarItemType,
                            'id' | 'name' | 'expectedDate' | 'impact' | 'lastEditorialChangeAt' | 'following'
                        > & {
                                phase?: Maybe<
                                    { __typename?: 'RadarPhaseType' } & Pick<RadarPhaseType, 'id' | 'order' | 'name'>
                                >
                                customerFrameworks?: Maybe<
                                    Array<{ __typename?: 'CustomerFrameworkType' } & CustomerFrameworkFieldsFragment>
                                >
                            }
                    >
                >
            }
    >
}

export type CustomerFrameworkOverviewQueryVariables = Exact<{
    customerSlug?: Maybe<Scalars['String']>
}>

export type CustomerFrameworkOverviewQuery = { __typename?: 'Query' } & {
    customerFrameworks?: Maybe<
        Array<
            { __typename?: 'CustomerFrameworkType' } & Pick<CustomerFrameworkType, 'id' | 'name' | 'color' | 'icon'> & {
                    framework: {
                        __typename?: 'CustomerFrameworkFrameworkType'
                    } & CustomerFrameworkFrameworkFieldsFragment
                }
        >
    >
}

export type AllDepartmentsQueryVariables = Exact<{
    customerSlug?: Maybe<Scalars['String']>
}>

export type AllDepartmentsQuery = { __typename?: 'Query' } & {
    allDepartments?: Maybe<
        Array<
            { __typename?: 'DepartmentType' } & Pick<
                DepartmentType,
                'id' | 'name' | 'amountOfEmployees' | 'receiveAlertsForConsultationsEnabled'
            > & {
                    departmentFrameworks: Array<
                        { __typename?: 'DepartmentFrameworkType' } & Pick<DepartmentFrameworkType, 'id'> & {
                                customerFramework: { __typename?: 'DepartmentCustomerFrameworkType' } & Pick<
                                    DepartmentCustomerFrameworkType,
                                    'id' | 'idn' | 'name' | 'color' | 'icon'
                                > & {
                                        framework: {
                                            __typename?: 'DepartmentCustomerFrameworkFrameworkType'
                                        } & DepartmentFrameworkFrameworkFieldsFragment
                                    }
                            }
                    >
                }
        >
    >
    customerFrameworks?: Maybe<
        Array<
            { __typename?: 'CustomerFrameworkType' } & Pick<CustomerFrameworkType, 'id' | 'name' | 'color' | 'icon'> & {
                    framework: {
                        __typename?: 'CustomerFrameworkFrameworkType'
                    } & CustomerFrameworkFrameworkFieldsFragment
                }
        >
    >
}

export type EmployeeQueryVariables = Exact<{
    id: Scalars['Int']
    customerSlug?: Maybe<Scalars['String']>
}>

export type EmployeeQuery = { __typename?: 'Query' } & {
    employee?: Maybe<
        { __typename?: 'EmployeeType' } & Pick<
            EmployeeType,
            | 'id'
            | 'alertDigestFrequency'
            | 'newTaskNotificationEmail'
            | 'upcomingTasksDigestFrequency'
            | 'myCompletedTasksDigestFrequency'
            | 'standardTasksDigestFrequency'
            | 'permissions'
            | 'operations'
            | 'role'
            | 'canEditPermissions'
        > & {
                user: { __typename?: 'UserType' } & Pick<
                    UserType,
                    'id' | 'email' | 'ssoLoginEnabled' | 'hasActiveMergeRequest' | 'isCustomerConsultant'
                > & {
                        profile: { __typename?: 'ProfileType' } & Pick<
                            ProfileType,
                            'id' | 'fullName' | 'firstName' | 'lastName' | 'phoneNumber' | 'avatar'
                        >
                    }
                departments: Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>
                userEmployees?: Maybe<Array<{ __typename?: 'EmployeeType' } & Pick<EmployeeType, 'id'>>>
            }
    >
}

export type CheckEmailExistsInCustomerGroupQueryVariables = Exact<{
    forEmployeeId: Scalars['Int']
    email: Scalars['String']
}>

export type CheckEmailExistsInCustomerGroupQuery = { __typename?: 'Query' } & Pick<
    Query,
    'checkEmailExistsInCustomerGroup'
>

export type EditEmployeeMutationMutationVariables = Exact<{
    id: Scalars['Int']
    fields: EditEmployeeFieldsInputType
    customerSlug?: Maybe<Scalars['String']>
}>

export type EditEmployeeMutationMutation = { __typename?: 'Mutation' } & {
    editEmployee?: Maybe<
        { __typename?: 'EmployeeType' } & Pick<
            EmployeeType,
            | 'id'
            | 'alertDigestFrequency'
            | 'newTaskNotificationEmail'
            | 'upcomingTasksDigestFrequency'
            | 'myCompletedTasksDigestFrequency'
            | 'standardTasksDigestFrequency'
            | 'permissions'
            | 'operations'
            | 'role'
            | 'canEditPermissions'
        > & {
                user: { __typename?: 'UserType' } & Pick<
                    UserType,
                    'id' | 'email' | 'ssoLoginEnabled' | 'hasActiveMergeRequest'
                > & {
                        profile: { __typename?: 'ProfileType' } & Pick<
                            ProfileType,
                            'id' | 'firstName' | 'lastName' | 'fullName' | 'phoneNumber'
                        >
                    }
                departments: Array<{ __typename?: 'DepartmentType' } & Pick<DepartmentType, 'id' | 'name'>>
            }
    >
}

export type EditCustomerEnabledNewsSourceMutationVariables = Exact<{
    newsSourceIdsToDisable: Array<Scalars['Int']> | Scalars['Int']
    newsSourceIdsToEnable: Array<Scalars['Int']> | Scalars['Int']
    showFrom?: Maybe<Scalars['Date']>
}>

export type EditCustomerEnabledNewsSourceMutation = { __typename?: 'Mutation' } & {
    editCustomerEnabledNewsSource?: Maybe<
        { __typename?: 'CustomerEnabledNewsSourceType' } & Pick<CustomerEnabledNewsSourceType, 'id' | 'newsSourceIds'>
    >
}

export type NewsGroupsSourcesQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
}>

export type NewsGroupsSourcesQuery = { __typename?: 'Query' } & {
    newsGroups?: Maybe<
        { __typename?: 'PaginatedNewsGroupType' } & Pick<PaginatedNewsGroupType, 'totalCount' | 'hasNextPage'> & {
                nodes?: Maybe<
                    Array<
                        { __typename?: 'NewsGroupType' } & Pick<NewsGroupType, 'id' | 'name'> & {
                                sources: Array<
                                    { __typename?: 'NewsItemSourceType' } & Pick<
                                        NewsItemSourceType,
                                        'id' | 'name' | 'isEnabledByCustomer'
                                    >
                                >
                            }
                    >
                >
            }
    >
}

export type CustomerEnabledNewsSourceQueryVariables = Exact<{
    customerId: Scalars['Int']
}>

export type CustomerEnabledNewsSourceQuery = { __typename?: 'Query' } & {
    customerEnabledNewsSource?: Maybe<
        { __typename?: 'CustomerEnabledNewsSourceType' } & Pick<CustomerEnabledNewsSourceType, 'id' | 'showFrom'>
    >
}

export type CustomerSamlConfigurationQueryVariables = Exact<{ [key: string]: never }>

export type CustomerSamlConfigurationQuery = { __typename?: 'Query' } & {
    customer?: Maybe<
        { __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'ssoLoginURL' | 'ssoLogoutURL' | 'ssoCertificate'>
    >
}

export type EditSamlConfigurationMutationVariables = Exact<{
    fields: CustomerSamlConfigurationFields
}>

export type EditSamlConfigurationMutation = { __typename?: 'Mutation' } & {
    editSAMLConfiguration: { __typename?: 'CustomerType' } & Pick<
        CustomerType,
        'id' | 'ssoLoginURL' | 'ssoLogoutURL' | 'ssoCertificate'
    >
}

export type TasksQueryVariables = Exact<{
    skip?: Maybe<Scalars['Int']>
    take?: Maybe<Scalars['Int']>
    filters?: Maybe<TaskFiltersInputType>
    sort?: Maybe<TaskSortInputType>
    departmentId: Scalars['Int']
}>

export type TasksQuery = { __typename?: 'Query' } & {
    tasks?: Maybe<
        { __typename?: 'PaginatedTaskType' } & Pick<PaginatedTaskType, 'count' | 'totalCount' | 'hasNextPage'> & {
                nodes?: Maybe<Array<{ __typename?: 'TaskType' } & TaskDetailFieldsFragmentFragment>>
            }
    >
}

export type SessionCustomerQueryVariables = Exact<{
    slug?: Maybe<Scalars['String']>
}>

export type SessionCustomerQuery = { __typename?: 'Query' } & {
    customer?: Maybe<{ __typename?: 'CustomerType' } & Pick<CustomerType, 'id' | 'name' | 'slug'>>
}

export const ControlTableFragmentFragmentDoc = gql`
    fragment ControlTableFragment on ControlType {
        id
        name
        type
        visibility
        visibleToDepartments {
            id
            name
        }
        department {
            id
            name
        }
        type
        createdAt
        assessments(departmentId: $departmentId) {
            id
            name
        }
        revisionDate
        archivedAt
        archivedBy {
            id
            user {
                id
                profile {
                    id
                    fullName
                    avatar
                }
            }
        }
        amountOfLinkedTopics
        parentControl(activeDepartmentId: $departmentId) {
            id
            name
            type
        }
        openTasks(departmentId: $departmentId) {
            id
        }
    }
`
export const CustomerFragmentFragmentDoc = gql`
    fragment CustomerFragment on CustomerType {
        id
        name
        logo
        slug
        availableSeats
        filledSeats
        contactName
        contactEmail
        ssoLoginEnabled
        editorialAccess
        address {
            addressLine
            postalCode
            city
            stateOrProvince
            country
        }
        consultantContact {
            id
            user {
                id
                email
                profile {
                    id
                    fullName
                }
            }
        }
        customerGroup {
            id
            name
        }
    }
`
export const LinkedTaskItemsFieldsFragmentDoc = gql`
    fragment LinkedTaskItemsFields on LinkedTaskItemType {
        ... on AlertType {
            __typename
            id
            name
            impactLevel(departmentId: $departmentId)
        }
        ... on ControlType {
            __typename
            id
            name
            type
            revisionDate
        }
        ... on RiskType {
            __typename
            id
            name
            severity
            isAccepted
        }
        ... on AssessmentType {
            __typename
            id
            name
        }
        ... on MonitoringReportType {
            __typename
            id
            name
        }
        ... on TopicType {
            __typename
            id
            name
        }
        ... on RadarItemType {
            __typename
            id
            name
            isDeleted
        }
        ... on ConsultationType {
            __typename
            id
            name
        }
    }
`
export const TaskTagFieldsFragmentDoc = gql`
    fragment TaskTagFields on TaskTagType {
        id
        name
    }
`
export const TaskEmployeeFieldsFragmentDoc = gql`
    fragment TaskEmployeeFields on EmployeeType {
        id
        user {
            id
            email
            profile {
                id
                fullName
                phoneNumber
                avatar
            }
        }
    }
`
export const ParentTaskFieldsFragmentFragmentDoc = gql`
    fragment ParentTaskFieldsFragment on TaskType {
        id
        name
        description
        priority
        startAt
        dueAt
        completedAt
        forMonitoring
        reopenedAt
        repeats
        totalSubTaskCount
        completedSubTaskCount
        linkedItem {
            ...LinkedTaskItemsFields
        }
        department {
            id
            name
        }
        tags {
            ...TaskTagFields
        }
        employees {
            ...TaskEmployeeFields
        }
    }
    ${LinkedTaskItemsFieldsFragmentDoc}
    ${TaskTagFieldsFragmentDoc}
    ${TaskEmployeeFieldsFragmentDoc}
`
export const SubTaskFieldsFragmentFragmentDoc = gql`
    fragment SubTaskFieldsFragment on TaskType {
        id
        name
        description
        priority
        startAt
        dueAt
        completedAt
        forMonitoring
        reopenedAt
        repeats
        linkedItem {
            ...LinkedTaskItemsFields
        }
        department {
            id
            name
        }
        tags {
            ...TaskTagFields
        }
        employees {
            ...TaskEmployeeFields
        }
    }
    ${LinkedTaskItemsFieldsFragmentDoc}
    ${TaskTagFieldsFragmentDoc}
    ${TaskEmployeeFieldsFragmentDoc}
`
export const TaskDetailFieldsFragmentFragmentDoc = gql`
    fragment TaskDetailFieldsFragment on TaskType {
        id
        name
        description
        priority
        startAt
        dueAt
        completedAt
        forMonitoring
        reopenedAt
        repeats
        isSubTask
        isParentTask
        isReviewTask
        totalSubTaskCount
        completedSubTaskCount
        linkedTasksToReview {
            id
            name
            linkedItem {
                ...LinkedTaskItemsFields
            }
            department {
                id
                name
            }
        }
        linkedAlertsToReview {
            id
            name
            impactLevel(departmentId: $departmentId)
        }
        parentTask {
            ...ParentTaskFieldsFragment
        }
        subTasks {
            ...SubTaskFieldsFragment
        }
        linkedItem {
            ...LinkedTaskItemsFields
        }
        department {
            id
            name
        }
        tags {
            ...TaskTagFields
        }
        employees {
            ...TaskEmployeeFields
        }
    }
    ${LinkedTaskItemsFieldsFragmentDoc}
    ${ParentTaskFieldsFragmentFragmentDoc}
    ${SubTaskFieldsFragmentFragmentDoc}
    ${TaskTagFieldsFragmentDoc}
    ${TaskEmployeeFieldsFragmentDoc}
`
export const CustomerFrameworkFrameworkFieldsFragmentDoc = gql`
    fragment CustomerFrameworkFrameworkFields on CustomerFrameworkFrameworkType {
        id
        name
        themes {
            id
            idn
            name
            topics {
                id
                idn
                name
                isChecked
                isNew
                addedAt
            }
        }
    }
`
export const DashboardTaskWidgetFragmentFragmentDoc = gql`
    fragment DashboardTaskWidgetFragment on DashboardTasksWidgetType {
        inboxCount {
            total
            highPrio
            overdue
        }
        controlCount {
            total
            highPrio
            overdue
        }
        topicCount {
            total
            highPrio
            overdue
        }
        genericCount {
            total
            highPrio
            overdue
        }
        otherCount {
            total
            highPrio
            overdue
        }
    }
`
export const DashboardInboxWidgetFragmentFragmentDoc = gql`
    fragment DashboardInboxWidgetFragment on DashboardInboxWidgetType {
        lastWeekCount
        totalCount
    }
`
export const DashboardTaskControlsWidgetFragmentFragmentDoc = gql`
    fragment DashboardTaskControlsWidgetFragment on DashboardTaskControlsWidgetType {
        lastWeekCount
        totalCount
    }
`
export const DashboardRiskWidgetFragmentFragmentDoc = gql`
    fragment DashboardRiskWidgetFragment on DashboardRisksWidgetType {
        highCount
        mediumCount
        lowCount
    }
`
export const DashboardTopicWidgetFragmentFragmentDoc = gql`
    fragment DashboardTopicWidgetFragment on DashboardTopicWidgetType {
        compliantCount
        almostCompliantCount
        notCompliantCount
        notAssessedCount
    }
`
export const DashboardControlWidgetFragmentFragmentDoc = gql`
    fragment DashboardControlWidgetFragment on DashboardControlWidgetType {
        activeCount
        oldCount
    }
`
export const DashboardRadarWidgetFragmentFragmentDoc = gql`
    fragment DashboardRadarWidgetFragment on DashboardRadarWidgetType {
        radarItems {
            id
            name
            expectedIn
        }
    }
`
export const DashboardConsultationWidgetFragmentFragmentDoc = gql`
    fragment DashboardConsultationWidgetFragment on DashboardConsultationWidgetType {
        consultations {
            id
            name
            source
        }
    }
`
export const DashboardInboxItemImpactWidgetFragmentFragmentDoc = gql`
    fragment DashboardInboxItemImpactWidgetFragment on DashboardInboxItemImpactWidgetType {
        lowImpactCount
        noImpactCount
        mediumImpactCount
        highImpactCount
    }
`
export const DashboardWidgetDetailContentFragmentFragmentDoc = gql`
    fragment DashboardWidgetDetailContentFragment on DashboardDetailContentType {
        ... on DashboardTasksDetailType {
            id
            contents {
                department {
                    id
                    name
                }
                content {
                    ...DashboardTaskWidgetFragment
                }
            }
        }
        ... on DashboardInboxDetailType {
            id
            contents {
                department {
                    id
                    name
                }
                content {
                    ...DashboardInboxWidgetFragment
                }
            }
        }
        ... on DashboardTaskControlsDetailType {
            id
            contents {
                department {
                    id
                    name
                }
                content {
                    ...DashboardTaskControlsWidgetFragment
                }
            }
        }
        ... on DashboardRisksDetailType {
            id
            contents {
                department {
                    id
                    name
                }
                content {
                    ...DashboardRiskWidgetFragment
                }
            }
        }
        ... on DashboardTopicDetailType {
            id
            contents {
                department {
                    id
                    name
                }
                content {
                    ...DashboardTopicWidgetFragment
                }
            }
        }
        ... on DashboardControlDetailType {
            id
            contents {
                department {
                    id
                    name
                }
                content {
                    ...DashboardControlWidgetFragment
                }
            }
        }
        ... on DashboardRadarDetailType {
            id
            contents {
                department {
                    id
                    name
                }
                content {
                    ...DashboardRadarWidgetFragment
                }
            }
        }
        ... on DashboardConsultationDetailType {
            id
            contents {
                department {
                    id
                    name
                }
                content {
                    ...DashboardConsultationWidgetFragment
                }
            }
        }
        ... on DashboardInboxItemImpactDetailType {
            id
            contents {
                department {
                    id
                    name
                }
                content {
                    ...DashboardInboxItemImpactWidgetFragment
                }
            }
        }
    }
    ${DashboardTaskWidgetFragmentFragmentDoc}
    ${DashboardInboxWidgetFragmentFragmentDoc}
    ${DashboardTaskControlsWidgetFragmentFragmentDoc}
    ${DashboardRiskWidgetFragmentFragmentDoc}
    ${DashboardTopicWidgetFragmentFragmentDoc}
    ${DashboardControlWidgetFragmentFragmentDoc}
    ${DashboardRadarWidgetFragmentFragmentDoc}
    ${DashboardConsultationWidgetFragmentFragmentDoc}
    ${DashboardInboxItemImpactWidgetFragmentFragmentDoc}
`
export const DashboardSignalingWidgetFragmentFragmentDoc = gql`
    fragment DashboardSignalingWidgetFragment on DashboardSignalingWidgetType {
        openCount
        sentCount
        totalCount
    }
`
export const DashboardMonitoringReportWidgetFragmentFragmentDoc = gql`
    fragment DashboardMonitoringReportWidgetFragment on DashboardMonitoringReportWidgetType {
        monitoringReports {
            id
            name
            createdAt
            overdueTaskCount
        }
    }
`
export const DashboardWidgetContentFragmentFragmentDoc = gql`
    fragment DashboardWidgetContentFragment on DashboardWidgetContentType {
        ...DashboardTaskWidgetFragment
        ...DashboardInboxWidgetFragment
        ...DashboardTaskControlsWidgetFragment
        ...DashboardSignalingWidgetFragment
        ...DashboardRiskWidgetFragment
        ...DashboardTopicWidgetFragment
        ...DashboardControlWidgetFragment
        ...DashboardRadarWidgetFragment
        ...DashboardMonitoringReportWidgetFragment
        ...DashboardConsultationWidgetFragment
        ...DashboardInboxItemImpactWidgetFragment
    }
    ${DashboardTaskWidgetFragmentFragmentDoc}
    ${DashboardInboxWidgetFragmentFragmentDoc}
    ${DashboardTaskControlsWidgetFragmentFragmentDoc}
    ${DashboardSignalingWidgetFragmentFragmentDoc}
    ${DashboardRiskWidgetFragmentFragmentDoc}
    ${DashboardTopicWidgetFragmentFragmentDoc}
    ${DashboardControlWidgetFragmentFragmentDoc}
    ${DashboardRadarWidgetFragmentFragmentDoc}
    ${DashboardMonitoringReportWidgetFragmentFragmentDoc}
    ${DashboardConsultationWidgetFragmentFragmentDoc}
    ${DashboardInboxItemImpactWidgetFragmentFragmentDoc}
`
export const DashboardWidgetMetadataFragmentFragmentDoc = gql`
    fragment DashboardWidgetMetadataFragment on DashboardWidgetMetadataType {
        ... on DashboardDepartmentSpecificWidgetMetadataType {
            name
            allDepartments
            departments {
                id
                name
            }
        }
        ... on DashboardCustomerSpecificWidgetMetadataType {
            name
        }
    }
`
export const DashboardWidgetFragmentFragmentDoc = gql`
    fragment DashboardWidgetFragment on DashboardWidgetLayoutType {
        id
        x
        y
        widgetType
        layoutType
        content {
            ...DashboardWidgetContentFragment
        }
        metadata {
            ...DashboardWidgetMetadataFragment
        }
    }
    ${DashboardWidgetContentFragmentFragmentDoc}
    ${DashboardWidgetMetadataFragmentFragmentDoc}
`
export const DepartmentFrameworkFrameworkFieldsFragmentDoc = gql`
    fragment DepartmentFrameworkFrameworkFields on DepartmentCustomerFrameworkFrameworkType {
        id
        name
        themes {
            id
            idn
            name
            topics {
                id
                idn
                name
                isChecked
            }
        }
    }
`
export const InternalAlertLogFragmentFragmentDoc = gql`
    fragment internalAlertLogFragment on EventLogType {
        id
        type
        occuredOn
        metaData
        user {
            id
            profile {
                id
                fullName
            }
        }
    }
`
export const SignalingNewsItemFieldsFragmentDoc = gql`
    fragment SignalingNewsItemFields on NewsItemType {
        id
        title
        publicationDate
        presentationSetting
        originalSource
        customSourceUrl
        internalAlert {
            id
            publishedAt
            note
            publishedBy {
                id
                user {
                    id
                    profile {
                        id
                        fullName
                    }
                }
            }
            topics {
                id
                name
                theme {
                    id
                    name
                }
                departments {
                    id
                }
                customerFrameworks {
                    id
                    name
                    icon
                    color
                }
            }
            departments {
                id
                name
            }
            statuses {
                id
                state
                department {
                    id
                    name
                }
                returnedAt
                returnedBy {
                    id
                    user {
                        id
                        profile {
                            id
                            fullName
                        }
                    }
                }
            }
        }
        suggestedTopics {
            id
            name
            theme {
                id
                name
            }
            departments {
                id
                name
            }
        }
        sources {
            id
            name
            group {
                id
                name
                isPinned
            }
        }
        archivedStatus {
            id
            archivedAt
            archivedReason
            archivedBy {
                id
                user {
                    id
                    profile {
                        id
                        fullName
                        avatar
                    }
                }
            }
            unarchivedAt
            unarchivedBy {
                id
                user {
                    id
                    profile {
                        id
                        fullName
                        avatar
                    }
                }
            }
        }
        publishedStatus {
            id
            publishedAt
            publishedBy {
                id
                user {
                    id
                    profile {
                        id
                        fullName
                        avatar
                    }
                }
            }
            topics {
                id
                name
                departments {
                    id
                }
            }
            departments {
                id
                name
            }
        }
    }
`
export const ConsultantTaskTemplateOverviewFragmentFragmentDoc = gql`
    fragment ConsultantTaskTemplateOverviewFragment on ConsultantTaskTemplateType {
        id
        name
        description
        type
        subTaskTemplates {
            id
            name
            description
        }
        frameworks {
            id
            abbreviation
            name
        }
    }
`
export const TaskTemplateOverviewFragmentFragmentDoc = gql`
    fragment TaskTemplateOverviewFragment on TaskTemplateType {
        id
        name
        description
        tags {
            id
            name
        }
        subTaskTemplates {
            id
            name
            description
            tags {
                id
                name
            }
        }
        totalTaskCount
    }
`
export const ThemeTopicAssessmentCoverageScoresFragmentFragmentDoc = gql`
    fragment ThemeTopicAssessmentCoverageScoresFragment on ThemeTopicAssessmentCoverageScoresType {
        assessmentSatisfies
        assessmentSatisfiesPartly
        assessmentAlmostSatisfies
        assessmentAlmostSatisfiesPartly
        assessmentNotSatisfies
        assessmentNotSatisfiesPartly
        assessmentNotGiven
        notApplicable
        total
        lastUpdatedAt
    }
`
export const TopicAssessmentIconContainerAssessmentFragmentDoc = gql`
    fragment TopicAssessmentIconContainerAssessment on TopicAssessmentType {
        id
        grade
        topicAssessmentDesignOrEffectiveNessType
        description
        updatedAt
        topicAssessmentDocuments {
            id
            file {
                id
                name
                path
                mimetype
            }
        }
        monitoringReport {
            id
            name
        }
    }
`
export const TopicAssessmentIconContainerNoteFragmentDoc = gql`
    fragment TopicAssessmentIconContainerNote on TopicNoteType {
        id
        title
        articlesAreAll
        articles {
            id
        }
    }
`
export const TopicAssessmentIconContainerControlFragmentDoc = gql`
    fragment TopicAssessmentIconContainerControl on TopicControlMeasureType {
        id
        control {
            id
            name
        }
        articlesAreAll
        articles {
            id
        }
    }
`
export const DepartmentTopicDataMetadataFragmentFragmentDoc = gql`
    fragment DepartmentTopicDataMetadataFragment on DepartmentTopicDataMetadataType {
        grade
        linkedControlMeasureCount
        linkedNoteCount
        lastUpdatedAt
        assessmentCounts {
            assessmentSatisfies
            assessmentAlmostSatisfies
            assessmentNotSatisfies
            assessmentNotGiven
        }
    }
`
export const AttachmentFieldsFragmentDoc = gql`
    fragment AttachmentFields on AttachmentType {
        id
        path
        filename
        mimetype
    }
`
export const CustomerFrameworkFieldsFragmentDoc = gql`
    fragment CustomerFrameworkFields on CustomerFrameworkType {
        id
        name
        icon
        color
    }
`
export const LinkedItemFragmentFragmentDoc = gql`
    fragment LinkedItemFragment on LinkedItemType {
        ... on RadarItemType {
            id
            createdAt
            name
            expectedDate
            phase {
                id
                name
            }
        }
        ... on LawArticleType {
            id
            createdAt
            title
            isSingleArticle
            updatedAt
            lawId
            law {
                id
                name
                abstractLawId
                lawSource {
                    id
                    entryIntoForce
                }
            }
            lawGroupId
            abstractLawArticleId
            attachmentIsLeading
            attachments {
                id
                path
                filename
                mimetype
            }
        }
        ... on TopicType {
            id
            createdAt
            name
        }
        ... on ConsultationType {
            id
            createdAt
            name
        }
        ... on NewsItemType {
            id
            __typename
            title
            publicationDate
            presentationSetting
            originalSource
            customSourceUrl
            attachments {
                id
                path
                filename
                mimetype
            }
            sources {
                id
                name
            }
        }
    }
`
export const LinkedItemsFieldsFragmentDoc = gql`
    fragment LinkedItemsFields on GroupedItemType {
        type
        entityType
        items {
            ...LinkedItemFragment
        }
    }
    ${LinkedItemFragmentFragmentDoc}
`
export const EditLanguageDocument = gql`
    mutation editLanguage($id: Int!, $fields: EditEmployeeFieldsInputType!) {
        editEmployee(id: $id, fields: $fields) {
            id
            user {
                id
                language
            }
        }
    }
`
export type EditLanguageMutationFn = ApolloReactCommon.MutationFunction<
    EditLanguageMutation,
    EditLanguageMutationVariables
>
export type EditLanguageComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<EditLanguageMutation, EditLanguageMutationVariables>,
    'mutation'
>

export const EditLanguageComponent = (props: EditLanguageComponentProps) => (
    <ApolloReactComponents.Mutation<EditLanguageMutation, EditLanguageMutationVariables>
        mutation={EditLanguageDocument}
        {...props}
    />
)

export type EditLanguageMutationResult = ApolloReactCommon.MutationResult<EditLanguageMutation>
export type EditLanguageMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditLanguageMutation,
    EditLanguageMutationVariables
>
export const DeleteExpiredArticleDocument = gql`
    mutation deleteExpiredArticle($abstractLawArticleId: Int!, $topicId: Int!) {
        deleteExpiredArticle(abstractLawArticleId: $abstractLawArticleId, topicId: $topicId)
    }
`
export type DeleteExpiredArticleMutationFn = ApolloReactCommon.MutationFunction<
    DeleteExpiredArticleMutation,
    DeleteExpiredArticleMutationVariables
>
export type DeleteExpiredArticleComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<DeleteExpiredArticleMutation, DeleteExpiredArticleMutationVariables>,
    'mutation'
>

export const DeleteExpiredArticleComponent = (props: DeleteExpiredArticleComponentProps) => (
    <ApolloReactComponents.Mutation<DeleteExpiredArticleMutation, DeleteExpiredArticleMutationVariables>
        mutation={DeleteExpiredArticleDocument}
        {...props}
    />
)

export type DeleteExpiredArticleMutationResult = ApolloReactCommon.MutationResult<DeleteExpiredArticleMutation>
export type DeleteExpiredArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteExpiredArticleMutation,
    DeleteExpiredArticleMutationVariables
>
export const TopicWithLawArticlesDocument = gql`
    query topicWithLawArticles($topicId: Int!, $departmentId: Int!) {
        topic(id: $topicId) {
            id
            name
            linkedLawArticles {
                id
                abstractLawArticleId
                title
                isSingleArticle
                updatedAt
                expired(topicId: $topicId) {
                    expiredAt
                    willBeDeletedAt
                }
                assessments(departmentId: $departmentId, topicId: $topicId) {
                    id
                    name
                }
                sectionParents {
                    id
                    name
                    depth
                }
                lawGroup {
                    id
                    name
                    order
                }
                law {
                    id
                    abstractLawId
                    name
                }
            }
        }
    }
`
export type TopicWithLawArticlesComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TopicWithLawArticlesQuery, TopicWithLawArticlesQueryVariables>,
    'query'
> &
    ({ variables: TopicWithLawArticlesQueryVariables; skip?: boolean } | { skip: boolean })

export const TopicWithLawArticlesComponent = (props: TopicWithLawArticlesComponentProps) => (
    <ApolloReactComponents.Query<TopicWithLawArticlesQuery, TopicWithLawArticlesQueryVariables>
        query={TopicWithLawArticlesDocument}
        {...props}
    />
)

export type TopicWithLawArticlesQueryResult = ApolloReactCommon.QueryResult<
    TopicWithLawArticlesQuery,
    TopicWithLawArticlesQueryVariables
>
export const DeleteAssessmentSectionInAssessmentDocument = gql`
    mutation deleteAssessmentSectionInAssessment($assessmentSectionId: Int!, $departmentId: Int!) {
        deleteAssessmentSection(assessmentSectionId: $assessmentSectionId, departmentId: $departmentId) {
            id
            name
            assessmentSections {
                id
            }
        }
    }
`
export type DeleteAssessmentSectionInAssessmentMutationFn = ApolloReactCommon.MutationFunction<
    DeleteAssessmentSectionInAssessmentMutation,
    DeleteAssessmentSectionInAssessmentMutationVariables
>
export type DeleteAssessmentSectionInAssessmentComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        DeleteAssessmentSectionInAssessmentMutation,
        DeleteAssessmentSectionInAssessmentMutationVariables
    >,
    'mutation'
>

export const DeleteAssessmentSectionInAssessmentComponent = (
    props: DeleteAssessmentSectionInAssessmentComponentProps
) => (
    <ApolloReactComponents.Mutation<
        DeleteAssessmentSectionInAssessmentMutation,
        DeleteAssessmentSectionInAssessmentMutationVariables
    >
        mutation={DeleteAssessmentSectionInAssessmentDocument}
        {...props}
    />
)

export type DeleteAssessmentSectionInAssessmentMutationResult =
    ApolloReactCommon.MutationResult<DeleteAssessmentSectionInAssessmentMutation>
export type DeleteAssessmentSectionInAssessmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteAssessmentSectionInAssessmentMutation,
    DeleteAssessmentSectionInAssessmentMutationVariables
>
export const AssessmentSectionsDocument = gql`
    query assessmentSections($assessmentId: Int!, $departmentId: Int!, $take: Int, $skip: Int) {
        assessmentSections(assessmentId: $assessmentId, take: $take, skip: $skip) {
            count
            totalCount
            hasNextPage
            nodes {
                id
                order
                norms {
                    selectedCount
                    totalCount
                    norm {
                        id
                        assessmentArticles {
                            id
                            linkedLawArticles {
                                id
                                abstractLawArticleId
                            }
                            assessmentTopic {
                                id
                                topic {
                                    id
                                }
                            }
                        }
                        topic {
                            id
                            name
                            expiredLawArticleIds
                            theme {
                                id
                                name
                            }
                        }
                    }
                }
                risks {
                    id
                    name
                    description
                    isAccepted
                    severity
                    severityBruto
                    status
                    linkedControls(departmentId: $departmentId) {
                        id
                        control {
                            id
                            name
                            type
                            revisionDate
                        }
                    }
                }
                controls {
                    id
                    name
                    type
                    description
                    createdAt
                    revisionDate
                    linkedRisks(assessmentId: $assessmentId, departmentId: $departmentId) {
                        id
                        description
                        risk {
                            id
                            name
                            severity
                            severityBruto
                            isAccepted
                        }
                    }
                }
            }
        }
    }
`
export type AssessmentSectionsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<AssessmentSectionsQuery, AssessmentSectionsQueryVariables>,
    'query'
> &
    ({ variables: AssessmentSectionsQueryVariables; skip?: boolean } | { skip: boolean })

export const AssessmentSectionsComponent = (props: AssessmentSectionsComponentProps) => (
    <ApolloReactComponents.Query<AssessmentSectionsQuery, AssessmentSectionsQueryVariables>
        query={AssessmentSectionsDocument}
        {...props}
    />
)

export type AssessmentSectionsQueryResult = ApolloReactCommon.QueryResult<
    AssessmentSectionsQuery,
    AssessmentSectionsQueryVariables
>
export const CreateAssessmentSectionDocument = gql`
    mutation createAssessmentSection($assessmentId: Int!, $departmentId: Int!) {
        createAssessmentSection(assessmentId: $assessmentId, departmentId: $departmentId) {
            id
            order
        }
    }
`
export type CreateAssessmentSectionMutationFn = ApolloReactCommon.MutationFunction<
    CreateAssessmentSectionMutation,
    CreateAssessmentSectionMutationVariables
>
export type CreateAssessmentSectionComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        CreateAssessmentSectionMutation,
        CreateAssessmentSectionMutationVariables
    >,
    'mutation'
>

export const CreateAssessmentSectionComponent = (props: CreateAssessmentSectionComponentProps) => (
    <ApolloReactComponents.Mutation<CreateAssessmentSectionMutation, CreateAssessmentSectionMutationVariables>
        mutation={CreateAssessmentSectionDocument}
        {...props}
    />
)

export type CreateAssessmentSectionMutationResult = ApolloReactCommon.MutationResult<CreateAssessmentSectionMutation>
export type CreateAssessmentSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateAssessmentSectionMutation,
    CreateAssessmentSectionMutationVariables
>
export const CreateComplianceExportDocument = gql`
    mutation createComplianceExport($departmentIds: [Int!]!, $customerFrameworkIds: [Int!]) {
        createComplianceExport(departmentIds: $departmentIds, customerFrameworkIds: $customerFrameworkIds) {
            token
            filename
        }
    }
`
export type CreateComplianceExportMutationFn = ApolloReactCommon.MutationFunction<
    CreateComplianceExportMutation,
    CreateComplianceExportMutationVariables
>
export type CreateComplianceExportComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        CreateComplianceExportMutation,
        CreateComplianceExportMutationVariables
    >,
    'mutation'
>

export const CreateComplianceExportComponent = (props: CreateComplianceExportComponentProps) => (
    <ApolloReactComponents.Mutation<CreateComplianceExportMutation, CreateComplianceExportMutationVariables>
        mutation={CreateComplianceExportDocument}
        {...props}
    />
)

export type CreateComplianceExportMutationResult = ApolloReactCommon.MutationResult<CreateComplianceExportMutation>
export type CreateComplianceExportMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateComplianceExportMutation,
    CreateComplianceExportMutationVariables
>
export const CreateControlDocument = gql`
    mutation createControl($fields: CreateControlFieldsType!, $departmentId: Int!) {
        createControl(fields: $fields, departmentId: $departmentId) {
            id
            name
            type
            createdAt
            assessments(departmentId: $departmentId) {
                id
                name
            }
        }
    }
`
export type CreateControlMutationFn = ApolloReactCommon.MutationFunction<
    CreateControlMutation,
    CreateControlMutationVariables
>
export type CreateControlComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CreateControlMutation, CreateControlMutationVariables>,
    'mutation'
>

export const CreateControlComponent = (props: CreateControlComponentProps) => (
    <ApolloReactComponents.Mutation<CreateControlMutation, CreateControlMutationVariables>
        mutation={CreateControlDocument}
        {...props}
    />
)

export type CreateControlMutationResult = ApolloReactCommon.MutationResult<CreateControlMutation>
export type CreateControlMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateControlMutation,
    CreateControlMutationVariables
>
export const ControlDocument = gql`
    query control($id: Int!, $departmentId: Int!) {
        control(id: $id) {
            __typename
            id
            name
            type
            hasControlLinkedControls
            description
            visibility
            startDate
            revisionDate
            createdAt
            updatedAt
            archivedAt
            archivedReason
            nextTask(departmentId: $departmentId) {
                id
                dueAt
                priority
            }
            highestPrioTask(departmentId: $departmentId) {
                id
                priority
            }
            visibleToDepartments {
                id
                name
            }
            department {
                id
                name
            }
            departmentsUsingControl {
                id
                name
            }
            controlDocuments {
                id
                archivedAt
                file {
                    id
                    name
                    path
                    mimetype
                }
            }
            responsibleEmployees {
                id
                user {
                    id
                    profile {
                        id
                        fullName
                    }
                }
            }
            responsibleShadowEmployees {
                id
                name
            }
            parentControl(activeDepartmentId: $departmentId) {
                id
                name
                type
            }
            childControls(activeDepartmentId: $departmentId) {
                id
                name
                type
            }
        }
    }
`
export type ControlComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<ControlQuery, ControlQueryVariables>,
    'query'
> &
    ({ variables: ControlQueryVariables; skip?: boolean } | { skip: boolean })

export const ControlComponent = (props: ControlComponentProps) => (
    <ApolloReactComponents.Query<ControlQuery, ControlQueryVariables> query={ControlDocument} {...props} />
)

export type ControlQueryResult = ApolloReactCommon.QueryResult<ControlQuery, ControlQueryVariables>
export const CreateControlExportDocument = gql`
    mutation createControlExport($departmentIds: [Int!]!, $filters: ControlFilters) {
        createControlExport(departmentIds: $departmentIds, filters: $filters) {
            token
            filename
        }
    }
`
export type CreateControlExportMutationFn = ApolloReactCommon.MutationFunction<
    CreateControlExportMutation,
    CreateControlExportMutationVariables
>
export type CreateControlExportComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CreateControlExportMutation, CreateControlExportMutationVariables>,
    'mutation'
>

export const CreateControlExportComponent = (props: CreateControlExportComponentProps) => (
    <ApolloReactComponents.Mutation<CreateControlExportMutation, CreateControlExportMutationVariables>
        mutation={CreateControlExportDocument}
        {...props}
    />
)

export type CreateControlExportMutationResult = ApolloReactCommon.MutationResult<CreateControlExportMutation>
export type CreateControlExportMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateControlExportMutation,
    CreateControlExportMutationVariables
>
export const ControlTopicsDocument = gql`
    query controlTopics(
        $departmentId: Int!
        $filters: TopicControlMeasureFilters
        $skip: Int!
        $take: Int!
        $sort: TopicControlMeasureSort
    ) {
        topicControlMeasures(departmentId: $departmentId, filters: $filters, skip: $skip, take: $take, sort: $sort) {
            count
            totalCount
            hasNextPage
            nodes {
                id
                topic {
                    id
                    name
                    linkedLawArticles {
                        id
                    }
                }
                articlesAreAll
                articles {
                    id
                }
                assessments {
                    ...TopicAssessmentIconContainerAssessment
                }
                linkedChildTopicControlMeasures {
                    id
                    control {
                        id
                        name
                        type
                    }
                    assessments {
                        id
                        grade
                        topicAssessmentDesignOrEffectiveNessType
                    }
                }
                linkedParentTopicControlMeasures {
                    id
                    control {
                        id
                        name
                        type
                    }
                    assessments {
                        id
                        grade
                        topicAssessmentDesignOrEffectiveNessType
                    }
                }
                linkedNestedChildTopicControlMeasures {
                    id
                    control {
                        id
                        name
                        type
                    }
                    assessments {
                        id
                        grade
                        topicAssessmentDesignOrEffectiveNessType
                    }
                }
                linkedNestedParentTopicControlMeasures {
                    id
                    control {
                        id
                        name
                        type
                    }
                    assessments {
                        id
                        grade
                        topicAssessmentDesignOrEffectiveNessType
                    }
                }
            }
        }
    }
    ${TopicAssessmentIconContainerAssessmentFragmentDoc}
`
export type ControlTopicsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<ControlTopicsQuery, ControlTopicsQueryVariables>,
    'query'
> &
    ({ variables: ControlTopicsQueryVariables; skip?: boolean } | { skip: boolean })

export const ControlTopicsComponent = (props: ControlTopicsComponentProps) => (
    <ApolloReactComponents.Query<ControlTopicsQuery, ControlTopicsQueryVariables>
        query={ControlTopicsDocument}
        {...props}
    />
)

export type ControlTopicsQueryResult = ApolloReactCommon.QueryResult<ControlTopicsQuery, ControlTopicsQueryVariables>
export const UnlinkNestedControlsDocument = gql`
    mutation unlinkNestedControls($controlId: Int!, $unlinkControlIds: [Int!]!, $departmentId: Int!) {
        unlinkNestedControls(controlId: $controlId, unlinkControlIds: $unlinkControlIds) {
            id
            parentControl(activeDepartmentId: $departmentId) {
                id
                name
                type
            }
            childControls(activeDepartmentId: $departmentId) {
                id
                name
                type
            }
            linkedControlsCount(activeDepartmentId: $departmentId)
        }
    }
`
export type UnlinkNestedControlsMutationFn = ApolloReactCommon.MutationFunction<
    UnlinkNestedControlsMutation,
    UnlinkNestedControlsMutationVariables
>
export type UnlinkNestedControlsComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<UnlinkNestedControlsMutation, UnlinkNestedControlsMutationVariables>,
    'mutation'
>

export const UnlinkNestedControlsComponent = (props: UnlinkNestedControlsComponentProps) => (
    <ApolloReactComponents.Mutation<UnlinkNestedControlsMutation, UnlinkNestedControlsMutationVariables>
        mutation={UnlinkNestedControlsDocument}
        {...props}
    />
)

export type UnlinkNestedControlsMutationResult = ApolloReactCommon.MutationResult<UnlinkNestedControlsMutation>
export type UnlinkNestedControlsMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UnlinkNestedControlsMutation,
    UnlinkNestedControlsMutationVariables
>
export const LinkableControlsDocument = gql`
    query linkableControls(
        $skip: Int
        $take: Int
        $sort: ControlSort
        $filters: ControlFilters
        $activeDepartmentId: Int!
    ) {
        controls(skip: $skip, take: $take, sort: $sort, filters: $filters) {
            count
            totalCount
            hasNextPage
            nodes {
                id
                name
                type
                linkedControlsCount(activeDepartmentId: $activeDepartmentId)
            }
        }
    }
`
export type LinkableControlsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<LinkableControlsQuery, LinkableControlsQueryVariables>,
    'query'
> &
    ({ variables: LinkableControlsQueryVariables; skip?: boolean } | { skip: boolean })

export const LinkableControlsComponent = (props: LinkableControlsComponentProps) => (
    <ApolloReactComponents.Query<LinkableControlsQuery, LinkableControlsQueryVariables>
        query={LinkableControlsDocument}
        {...props}
    />
)

export type LinkableControlsQueryResult = ApolloReactCommon.QueryResult<
    LinkableControlsQuery,
    LinkableControlsQueryVariables
>
export const ArchiveControlsDocument = gql`
    mutation archiveControls($ids: [Int!]!, $reason: String, $departmentId: Int!) {
        archiveControls(ids: $ids, reason: $reason) {
            id
            archivedAt
            archivedReason
            parentControl(activeDepartmentId: $departmentId) {
                id
            }
            childControls(activeDepartmentId: $departmentId) {
                id
            }
        }
    }
`
export type ArchiveControlsMutationFn = ApolloReactCommon.MutationFunction<
    ArchiveControlsMutation,
    ArchiveControlsMutationVariables
>
export type ArchiveControlsComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<ArchiveControlsMutation, ArchiveControlsMutationVariables>,
    'mutation'
>

export const ArchiveControlsComponent = (props: ArchiveControlsComponentProps) => (
    <ApolloReactComponents.Mutation<ArchiveControlsMutation, ArchiveControlsMutationVariables>
        mutation={ArchiveControlsDocument}
        {...props}
    />
)

export type ArchiveControlsMutationResult = ApolloReactCommon.MutationResult<ArchiveControlsMutation>
export type ArchiveControlsMutationOptions = ApolloReactCommon.BaseMutationOptions<
    ArchiveControlsMutation,
    ArchiveControlsMutationVariables
>
export const UnarchiveControlDocument = gql`
    mutation unarchiveControl($id: Int!) {
        unarchiveControl(id: $id) {
            id
            archivedAt
        }
    }
`
export type UnarchiveControlMutationFn = ApolloReactCommon.MutationFunction<
    UnarchiveControlMutation,
    UnarchiveControlMutationVariables
>
export type UnarchiveControlComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<UnarchiveControlMutation, UnarchiveControlMutationVariables>,
    'mutation'
>

export const UnarchiveControlComponent = (props: UnarchiveControlComponentProps) => (
    <ApolloReactComponents.Mutation<UnarchiveControlMutation, UnarchiveControlMutationVariables>
        mutation={UnarchiveControlDocument}
        {...props}
    />
)

export type UnarchiveControlMutationResult = ApolloReactCommon.MutationResult<UnarchiveControlMutation>
export type UnarchiveControlMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UnarchiveControlMutation,
    UnarchiveControlMutationVariables
>
export const AddControlToTopicsDocument = gql`
    mutation addControlToTopics($controlId: Int!, $topicIds: [Int!]!, $departmentId: Int!) {
        addControlToTopics(controlId: $controlId, topicIds: $topicIds, departmentId: $departmentId) {
            id
        }
    }
`
export type AddControlToTopicsMutationFn = ApolloReactCommon.MutationFunction<
    AddControlToTopicsMutation,
    AddControlToTopicsMutationVariables
>
export type AddControlToTopicsComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<AddControlToTopicsMutation, AddControlToTopicsMutationVariables>,
    'mutation'
>

export const AddControlToTopicsComponent = (props: AddControlToTopicsComponentProps) => (
    <ApolloReactComponents.Mutation<AddControlToTopicsMutation, AddControlToTopicsMutationVariables>
        mutation={AddControlToTopicsDocument}
        {...props}
    />
)

export type AddControlToTopicsMutationResult = ApolloReactCommon.MutationResult<AddControlToTopicsMutation>
export type AddControlToTopicsMutationOptions = ApolloReactCommon.BaseMutationOptions<
    AddControlToTopicsMutation,
    AddControlToTopicsMutationVariables
>
export const AllSelectableTopicsDocument = gql`
    query allSelectableTopics($filters: AllTopicFilters, $customerSlug: String!) {
        minimalAllTopics(customerSlug: $customerSlug, filters: $filters) {
            id
            name
            theme {
                id
                name
            }
        }
    }
`
export type AllSelectableTopicsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<AllSelectableTopicsQuery, AllSelectableTopicsQueryVariables>,
    'query'
> &
    ({ variables: AllSelectableTopicsQueryVariables; skip?: boolean } | { skip: boolean })

export const AllSelectableTopicsComponent = (props: AllSelectableTopicsComponentProps) => (
    <ApolloReactComponents.Query<AllSelectableTopicsQuery, AllSelectableTopicsQueryVariables>
        query={AllSelectableTopicsDocument}
        {...props}
    />
)

export type AllSelectableTopicsQueryResult = ApolloReactCommon.QueryResult<
    AllSelectableTopicsQuery,
    AllSelectableTopicsQueryVariables
>
export const AssessmentsForControlDocument = gql`
    query assessmentsForControl($id: Int!, $departmentId: Int!) {
        control(id: $id) {
            id
            assessments(departmentId: $departmentId) {
                id
                name
                norms {
                    id
                    topic {
                        id
                        name
                        expiredLawArticleIds
                        assessmentCoverage(departmentId: $departmentId) {
                            assessed
                            noted
                            total
                        }
                        theme {
                            id
                            name
                        }
                    }
                }
            }
            linkedRisks(departmentId: $departmentId) {
                id
                description
                risk {
                    id
                    name
                    isAccepted
                    severity
                    severityBruto
                    assessment {
                        id
                    }
                }
            }
        }
    }
`
export type AssessmentsForControlComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<AssessmentsForControlQuery, AssessmentsForControlQueryVariables>,
    'query'
> &
    ({ variables: AssessmentsForControlQueryVariables; skip?: boolean } | { skip: boolean })

export const AssessmentsForControlComponent = (props: AssessmentsForControlComponentProps) => (
    <ApolloReactComponents.Query<AssessmentsForControlQuery, AssessmentsForControlQueryVariables>
        query={AssessmentsForControlDocument}
        {...props}
    />
)

export type AssessmentsForControlQueryResult = ApolloReactCommon.QueryResult<
    AssessmentsForControlQuery,
    AssessmentsForControlQueryVariables
>
export const GetControlsDocument = gql`
    query getControls(
        $skip: Int
        $take: Int
        $sort: ControlSort
        $filters: ControlFilters
        $departmentId: Int!
        $withChildControls: Boolean! = false
    ) {
        controls(skip: $skip, take: $take, sort: $sort, filters: $filters) {
            count
            totalCount
            hasNextPage
            nodes {
                ...ControlTableFragment
                childControls(activeDepartmentId: $departmentId) @include(if: $withChildControls) {
                    ...ControlTableFragment
                    childControls(activeDepartmentId: $departmentId) {
                        ...ControlTableFragment
                    }
                }
            }
        }
    }
    ${ControlTableFragmentFragmentDoc}
`
export type GetControlsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<GetControlsQuery, GetControlsQueryVariables>,
    'query'
> &
    ({ variables: GetControlsQueryVariables; skip?: boolean } | { skip: boolean })

export const GetControlsComponent = (props: GetControlsComponentProps) => (
    <ApolloReactComponents.Query<GetControlsQuery, GetControlsQueryVariables> query={GetControlsDocument} {...props} />
)

export type GetControlsQueryResult = ApolloReactCommon.QueryResult<GetControlsQuery, GetControlsQueryVariables>
export const ControlsDocument = gql`
    query controls($skip: Int, $take: Int, $sort: ControlSort, $filters: ControlFilters, $departmentId: Int!) {
        controls(skip: $skip, take: $take, sort: $sort, filters: $filters) {
            count
            totalCount
            hasNextPage
            nodes {
                id
                name
                type
                assessments(departmentId: $departmentId) {
                    id
                    name
                }
                assessmentSections {
                    id
                }
            }
        }
    }
`
export type ControlsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<ControlsQuery, ControlsQueryVariables>,
    'query'
> &
    ({ variables: ControlsQueryVariables; skip?: boolean } | { skip: boolean })

export const ControlsComponent = (props: ControlsComponentProps) => (
    <ApolloReactComponents.Query<ControlsQuery, ControlsQueryVariables> query={ControlsDocument} {...props} />
)

export type ControlsQueryResult = ApolloReactCommon.QueryResult<ControlsQuery, ControlsQueryVariables>
export const CreateRisksExportDocument = gql`
    mutation createRisksExport($currentDepartmentId: Int!, $departmentIdsFilter: [Int!]) {
        createRisksExport(currentDepartmentId: $currentDepartmentId, departmentIdsFilter: $departmentIdsFilter) {
            token
            filename
        }
    }
`
export type CreateRisksExportMutationFn = ApolloReactCommon.MutationFunction<
    CreateRisksExportMutation,
    CreateRisksExportMutationVariables
>
export type CreateRisksExportComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CreateRisksExportMutation, CreateRisksExportMutationVariables>,
    'mutation'
>

export const CreateRisksExportComponent = (props: CreateRisksExportComponentProps) => (
    <ApolloReactComponents.Mutation<CreateRisksExportMutation, CreateRisksExportMutationVariables>
        mutation={CreateRisksExportDocument}
        {...props}
    />
)

export type CreateRisksExportMutationResult = ApolloReactCommon.MutationResult<CreateRisksExportMutation>
export type CreateRisksExportMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateRisksExportMutation,
    CreateRisksExportMutationVariables
>
export const CreateCustomerDocument = gql`
    mutation createCustomer($fields: CustomerInputFieldsType!) {
        createCustomer(fields: $fields) {
            ...CustomerFragment
        }
    }
    ${CustomerFragmentFragmentDoc}
`
export type CreateCustomerMutationFn = ApolloReactCommon.MutationFunction<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
>
export type CreateCustomerComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CreateCustomerMutation, CreateCustomerMutationVariables>,
    'mutation'
>

export const CreateCustomerComponent = (props: CreateCustomerComponentProps) => (
    <ApolloReactComponents.Mutation<CreateCustomerMutation, CreateCustomerMutationVariables>
        mutation={CreateCustomerDocument}
        {...props}
    />
)

export type CreateCustomerMutationResult = ApolloReactCommon.MutationResult<CreateCustomerMutation>
export type CreateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
>
export const CreateCustomersExportDocument = gql`
    mutation createCustomersExport($search: String) {
        createCustomersExport(search: $search) {
            token
            filename
        }
    }
`
export type CreateCustomersExportMutationFn = ApolloReactCommon.MutationFunction<
    CreateCustomersExportMutation,
    CreateCustomersExportMutationVariables
>
export type CreateCustomersExportComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        CreateCustomersExportMutation,
        CreateCustomersExportMutationVariables
    >,
    'mutation'
>

export const CreateCustomersExportComponent = (props: CreateCustomersExportComponentProps) => (
    <ApolloReactComponents.Mutation<CreateCustomersExportMutation, CreateCustomersExportMutationVariables>
        mutation={CreateCustomersExportDocument}
        {...props}
    />
)

export type CreateCustomersExportMutationResult = ApolloReactCommon.MutationResult<CreateCustomersExportMutation>
export type CreateCustomersExportMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateCustomersExportMutation,
    CreateCustomersExportMutationVariables
>
export const EditCustomerDocument = gql`
    mutation editCustomer($customerId: Int!, $fields: CustomerInputFieldsType!) {
        editCustomer(fields: $fields, customerId: $customerId) {
            ...CustomerFragment
        }
    }
    ${CustomerFragmentFragmentDoc}
`
export type EditCustomerMutationFn = ApolloReactCommon.MutationFunction<
    EditCustomerMutation,
    EditCustomerMutationVariables
>
export type EditCustomerComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<EditCustomerMutation, EditCustomerMutationVariables>,
    'mutation'
>

export const EditCustomerComponent = (props: EditCustomerComponentProps) => (
    <ApolloReactComponents.Mutation<EditCustomerMutation, EditCustomerMutationVariables>
        mutation={EditCustomerDocument}
        {...props}
    />
)

export type EditCustomerMutationResult = ApolloReactCommon.MutationResult<EditCustomerMutation>
export type EditCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditCustomerMutation,
    EditCustomerMutationVariables
>
export const CustomerDocument = gql`
    query customer($id: Int!) {
        customer(id: $id) {
            ...CustomerFragment
        }
    }
    ${CustomerFragmentFragmentDoc}
`
export type CustomerComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<CustomerQuery, CustomerQueryVariables>,
    'query'
> &
    ({ variables: CustomerQueryVariables; skip?: boolean } | { skip: boolean })

export const CustomerComponent = (props: CustomerComponentProps) => (
    <ApolloReactComponents.Query<CustomerQuery, CustomerQueryVariables> query={CustomerDocument} {...props} />
)

export type CustomerQueryResult = ApolloReactCommon.QueryResult<CustomerQuery, CustomerQueryVariables>
export const CreateCustomerGroupDocument = gql`
    mutation createCustomerGroup($fields: CustomerGroupInputFieldsType) {
        createCustomerGroup(fields: $fields) {
            id
        }
    }
`
export type CreateCustomerGroupMutationFn = ApolloReactCommon.MutationFunction<
    CreateCustomerGroupMutation,
    CreateCustomerGroupMutationVariables
>
export type CreateCustomerGroupComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CreateCustomerGroupMutation, CreateCustomerGroupMutationVariables>,
    'mutation'
>

export const CreateCustomerGroupComponent = (props: CreateCustomerGroupComponentProps) => (
    <ApolloReactComponents.Mutation<CreateCustomerGroupMutation, CreateCustomerGroupMutationVariables>
        mutation={CreateCustomerGroupDocument}
        {...props}
    />
)

export type CreateCustomerGroupMutationResult = ApolloReactCommon.MutationResult<CreateCustomerGroupMutation>
export type CreateCustomerGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateCustomerGroupMutation,
    CreateCustomerGroupMutationVariables
>
export const DeleteCustomerGroupDocument = gql`
    mutation deleteCustomerGroup($customerGroupId: Int!) {
        deleteCustomerGroup(customerGroupId: $customerGroupId)
    }
`
export type DeleteCustomerGroupMutationFn = ApolloReactCommon.MutationFunction<
    DeleteCustomerGroupMutation,
    DeleteCustomerGroupMutationVariables
>
export type DeleteCustomerGroupComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<DeleteCustomerGroupMutation, DeleteCustomerGroupMutationVariables>,
    'mutation'
>

export const DeleteCustomerGroupComponent = (props: DeleteCustomerGroupComponentProps) => (
    <ApolloReactComponents.Mutation<DeleteCustomerGroupMutation, DeleteCustomerGroupMutationVariables>
        mutation={DeleteCustomerGroupDocument}
        {...props}
    />
)

export type DeleteCustomerGroupMutationResult = ApolloReactCommon.MutationResult<DeleteCustomerGroupMutation>
export type DeleteCustomerGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteCustomerGroupMutation,
    DeleteCustomerGroupMutationVariables
>
export const EditCustomerGroupDocument = gql`
    mutation editCustomerGroup($customerGroupId: Int!, $fields: CustomerGroupInputFieldsType!) {
        editCustomerGroup(customerGroupId: $customerGroupId, fields: $fields) {
            id
        }
    }
`
export type EditCustomerGroupMutationFn = ApolloReactCommon.MutationFunction<
    EditCustomerGroupMutation,
    EditCustomerGroupMutationVariables
>
export type EditCustomerGroupComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<EditCustomerGroupMutation, EditCustomerGroupMutationVariables>,
    'mutation'
>

export const EditCustomerGroupComponent = (props: EditCustomerGroupComponentProps) => (
    <ApolloReactComponents.Mutation<EditCustomerGroupMutation, EditCustomerGroupMutationVariables>
        mutation={EditCustomerGroupDocument}
        {...props}
    />
)

export type EditCustomerGroupMutationResult = ApolloReactCommon.MutationResult<EditCustomerGroupMutation>
export type EditCustomerGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditCustomerGroupMutation,
    EditCustomerGroupMutationVariables
>
export const CustomerGroupDocument = gql`
    query customerGroup($id: Int!) {
        customerGroup(id: $id) {
            id
            name
            ssoLoginEnabled
            createUserOnSSOLogin
            ssoLoginURL
            ssoLogoutURL
            ssoCertificate
            totalUserCount
            customers {
                id
            }
        }
    }
`
export type CustomerGroupComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<CustomerGroupQuery, CustomerGroupQueryVariables>,
    'query'
> &
    ({ variables: CustomerGroupQueryVariables; skip?: boolean } | { skip: boolean })

export const CustomerGroupComponent = (props: CustomerGroupComponentProps) => (
    <ApolloReactComponents.Query<CustomerGroupQuery, CustomerGroupQueryVariables>
        query={CustomerGroupDocument}
        {...props}
    />
)

export type CustomerGroupQueryResult = ApolloReactCommon.QueryResult<CustomerGroupQuery, CustomerGroupQueryVariables>
export const CustomerGroupSelectDocument = gql`
    query customerGroupSelect($skip: Int!, $take: Int!, $search: String) {
        customerGroups(skip: $skip, take: $take, search: $search) {
            totalCount
            hasNextPage
            nodes {
                id
                name
            }
        }
    }
`
export type CustomerGroupSelectComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<CustomerGroupSelectQuery, CustomerGroupSelectQueryVariables>,
    'query'
> &
    ({ variables: CustomerGroupSelectQueryVariables; skip?: boolean } | { skip: boolean })

export const CustomerGroupSelectComponent = (props: CustomerGroupSelectComponentProps) => (
    <ApolloReactComponents.Query<CustomerGroupSelectQuery, CustomerGroupSelectQueryVariables>
        query={CustomerGroupSelectDocument}
        {...props}
    />
)

export type CustomerGroupSelectQueryResult = ApolloReactCommon.QueryResult<
    CustomerGroupSelectQuery,
    CustomerGroupSelectQueryVariables
>
export const CustomerGroupsDocument = gql`
    query customerGroups($skip: Int!, $take: Int!, $search: String) {
        customerGroups(skip: $skip, take: $take, search: $search) {
            totalCount
            hasNextPage
            nodes {
                id
                name
                totalUserCount
                customers {
                    id
                }
            }
        }
    }
`
export type CustomerGroupsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<CustomerGroupsQuery, CustomerGroupsQueryVariables>,
    'query'
> &
    ({ variables: CustomerGroupsQueryVariables; skip?: boolean } | { skip: boolean })

export const CustomerGroupsComponent = (props: CustomerGroupsComponentProps) => (
    <ApolloReactComponents.Query<CustomerGroupsQuery, CustomerGroupsQueryVariables>
        query={CustomerGroupsDocument}
        {...props}
    />
)

export type CustomerGroupsQueryResult = ApolloReactCommon.QueryResult<CustomerGroupsQuery, CustomerGroupsQueryVariables>
export const CustomersInCustomerGroupDocument = gql`
    query customersInCustomerGroup($id: Int!, $search: String) {
        customerGroup(id: $id) {
            id
            name
            customers(search: $search) {
                id
                name
                filledSeats
                frameworks {
                    id
                    abbreviation
                }
            }
        }
    }
`
export type CustomersInCustomerGroupComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<CustomersInCustomerGroupQuery, CustomersInCustomerGroupQueryVariables>,
    'query'
> &
    ({ variables: CustomersInCustomerGroupQueryVariables; skip?: boolean } | { skip: boolean })

export const CustomersInCustomerGroupComponent = (props: CustomersInCustomerGroupComponentProps) => (
    <ApolloReactComponents.Query<CustomersInCustomerGroupQuery, CustomersInCustomerGroupQueryVariables>
        query={CustomersInCustomerGroupDocument}
        {...props}
    />
)

export type CustomersInCustomerGroupQueryResult = ApolloReactCommon.QueryResult<
    CustomersInCustomerGroupQuery,
    CustomersInCustomerGroupQueryVariables
>
export const CreateTaskDocument = gql`
    mutation createTask($departmentId: Int!, $fields: CreateTaskFieldsInputType!) {
        createTask(departmentId: $departmentId, fields: $fields) {
            ...TaskDetailFieldsFragment
        }
    }
    ${TaskDetailFieldsFragmentFragmentDoc}
`
export type CreateTaskMutationFn = ApolloReactCommon.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>
export type CreateTaskComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CreateTaskMutation, CreateTaskMutationVariables>,
    'mutation'
>

export const CreateTaskComponent = (props: CreateTaskComponentProps) => (
    <ApolloReactComponents.Mutation<CreateTaskMutation, CreateTaskMutationVariables>
        mutation={CreateTaskDocument}
        {...props}
    />
)

export type CreateTaskMutationResult = ApolloReactCommon.MutationResult<CreateTaskMutation>
export type CreateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateTaskMutation,
    CreateTaskMutationVariables
>
export const IgnoreAlertsDocument = gql`
    mutation ignoreAlerts($reason: String, $alertIdWithDepartmentIds: [AlertIdWithDepartmentIdInputType!]) {
        ignoreAlerts(reason: $reason, alertIdWithDepartmentIds: $alertIdWithDepartmentIds)
    }
`
export type IgnoreAlertsMutationFn = ApolloReactCommon.MutationFunction<
    IgnoreAlertsMutation,
    IgnoreAlertsMutationVariables
>
export type IgnoreAlertsComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<IgnoreAlertsMutation, IgnoreAlertsMutationVariables>,
    'mutation'
>

export const IgnoreAlertsComponent = (props: IgnoreAlertsComponentProps) => (
    <ApolloReactComponents.Mutation<IgnoreAlertsMutation, IgnoreAlertsMutationVariables>
        mutation={IgnoreAlertsDocument}
        {...props}
    />
)

export type IgnoreAlertsMutationResult = ApolloReactCommon.MutationResult<IgnoreAlertsMutation>
export type IgnoreAlertsMutationOptions = ApolloReactCommon.BaseMutationOptions<
    IgnoreAlertsMutation,
    IgnoreAlertsMutationVariables
>
export const RevertIgnoreAlertDocument = gql`
    mutation revertIgnoreAlert($alertId: Int!, $departmentId: Int!) {
        revertIgnoreAlert(alertId: $alertId, departmentId: $departmentId)
    }
`
export type RevertIgnoreAlertMutationFn = ApolloReactCommon.MutationFunction<
    RevertIgnoreAlertMutation,
    RevertIgnoreAlertMutationVariables
>
export type RevertIgnoreAlertComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<RevertIgnoreAlertMutation, RevertIgnoreAlertMutationVariables>,
    'mutation'
>

export const RevertIgnoreAlertComponent = (props: RevertIgnoreAlertComponentProps) => (
    <ApolloReactComponents.Mutation<RevertIgnoreAlertMutation, RevertIgnoreAlertMutationVariables>
        mutation={RevertIgnoreAlertDocument}
        {...props}
    />
)

export type RevertIgnoreAlertMutationResult = ApolloReactCommon.MutationResult<RevertIgnoreAlertMutation>
export type RevertIgnoreAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<
    RevertIgnoreAlertMutation,
    RevertIgnoreAlertMutationVariables
>
export const AlertsSummaryPerMonthDocument = gql`
    query alertsSummaryPerMonth($filters: AlertFilters, $skip: Int, $take: Int) {
        alertsSummaryPerMonth(filters: $filters, skip: $skip, take: $take) {
            hasNextPage
            nodes {
                id
                alertCount
                month
                year
            }
        }
    }
`
export type AlertsSummaryPerMonthComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<AlertsSummaryPerMonthQuery, AlertsSummaryPerMonthQueryVariables>,
    'query'
>

export const AlertsSummaryPerMonthComponent = (props: AlertsSummaryPerMonthComponentProps) => (
    <ApolloReactComponents.Query<AlertsSummaryPerMonthQuery, AlertsSummaryPerMonthQueryVariables>
        query={AlertsSummaryPerMonthDocument}
        {...props}
    />
)

export type AlertsSummaryPerMonthQueryResult = ApolloReactCommon.QueryResult<
    AlertsSummaryPerMonthQuery,
    AlertsSummaryPerMonthQueryVariables
>
export const DepartmentAlertsForMonthDocument = gql`
    query departmentAlertsForMonth(
        $filters: AlertFilters
        $sort: AlertsForMonthSort
        $month: Int!
        $year: Int!
        $skip: Int
        $take: Int
    ) {
        alertsForMonth(filters: $filters, sort: $sort, month: $month, year: $year, skip: $skip, take: $take) {
            count
            totalCount
            hasNextPage
            nodes {
                id
                alert {
                    id
                    name
                    publishedAt
                    isPublishedFromCustomerNews
                }
                departmentDatas {
                    id
                    department {
                        id
                        name
                    }
                    impactLevel {
                        id
                        impactLevel
                    }
                    inboxStatus {
                        id
                        archivedAt
                        ignoredAt
                        ignoredBy {
                            id
                            user {
                                id
                                profile {
                                    id
                                    fullName
                                }
                            }
                        }
                    }
                    alert {
                        id
                        name
                        publishedAt
                        isPublishedFromCustomerNews
                    }
                    hasLinkedTask
                    openTaskCount
                    completedTaskCount
                    overdueTaskCount
                }
            }
        }
    }
`
export type DepartmentAlertsForMonthComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<DepartmentAlertsForMonthQuery, DepartmentAlertsForMonthQueryVariables>,
    'query'
> &
    ({ variables: DepartmentAlertsForMonthQueryVariables; skip?: boolean } | { skip: boolean })

export const DepartmentAlertsForMonthComponent = (props: DepartmentAlertsForMonthComponentProps) => (
    <ApolloReactComponents.Query<DepartmentAlertsForMonthQuery, DepartmentAlertsForMonthQueryVariables>
        query={DepartmentAlertsForMonthDocument}
        {...props}
    />
)

export type DepartmentAlertsForMonthQueryResult = ApolloReactCommon.QueryResult<
    DepartmentAlertsForMonthQuery,
    DepartmentAlertsForMonthQueryVariables
>
export const IgnoreTasksDocument = gql`
    mutation ignoreTasks($reason: String, $taskIdsToIgnore: [Int], $departmentId: Int!) {
        ignoreTasks(reason: $reason, taskIdsToIgnore: $taskIdsToIgnore, departmentId: $departmentId) {
            id
            name
            tags {
                ...TaskTagFields
            }
            employees {
                ...TaskEmployeeFields
            }
            priority
            createdAt
            completedAt
            repeats
            forMonitoring
            reopenedAt
            linkedItem {
                ... on AlertType {
                    __typename
                    id
                    name
                }
                ... on ControlType {
                    __typename
                    id
                    name
                    type
                    revisionDate
                }
                ... on RiskType {
                    __typename
                    id
                    name
                    severity
                    isAccepted
                }
                ... on AssessmentType {
                    __typename
                    id
                    name
                }
                ... on MonitoringReportType {
                    __typename
                    id
                    name
                }
                ... on TopicType {
                    __typename
                    id
                    name
                }
                ... on RadarItemType {
                    __typename
                    id
                    name
                }
                ... on ConsultationType {
                    __typename
                    id
                    name
                }
            }
        }
    }
    ${TaskTagFieldsFragmentDoc}
    ${TaskEmployeeFieldsFragmentDoc}
`
export type IgnoreTasksMutationFn = ApolloReactCommon.MutationFunction<
    IgnoreTasksMutation,
    IgnoreTasksMutationVariables
>
export type IgnoreTasksComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<IgnoreTasksMutation, IgnoreTasksMutationVariables>,
    'mutation'
>

export const IgnoreTasksComponent = (props: IgnoreTasksComponentProps) => (
    <ApolloReactComponents.Mutation<IgnoreTasksMutation, IgnoreTasksMutationVariables>
        mutation={IgnoreTasksDocument}
        {...props}
    />
)

export type IgnoreTasksMutationResult = ApolloReactCommon.MutationResult<IgnoreTasksMutation>
export type IgnoreTasksMutationOptions = ApolloReactCommon.BaseMutationOptions<
    IgnoreTasksMutation,
    IgnoreTasksMutationVariables
>
export const RevertIgnoreTaskDocument = gql`
    mutation revertIgnoreTask($taskId: Int!, $departmentId: Int!) {
        undoIgnoreTask(taskId: $taskId, departmentId: $departmentId) {
            id
            name
            tags {
                ...TaskTagFields
            }
            employees {
                ...TaskEmployeeFields
            }
            priority
            createdAt
            completedAt
            repeats
            forMonitoring
            reopenedAt
            linkedItem {
                ...LinkedTaskItemsFields
            }
        }
    }
    ${TaskTagFieldsFragmentDoc}
    ${TaskEmployeeFieldsFragmentDoc}
    ${LinkedTaskItemsFieldsFragmentDoc}
`
export type RevertIgnoreTaskMutationFn = ApolloReactCommon.MutationFunction<
    RevertIgnoreTaskMutation,
    RevertIgnoreTaskMutationVariables
>
export type RevertIgnoreTaskComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<RevertIgnoreTaskMutation, RevertIgnoreTaskMutationVariables>,
    'mutation'
>

export const RevertIgnoreTaskComponent = (props: RevertIgnoreTaskComponentProps) => (
    <ApolloReactComponents.Mutation<RevertIgnoreTaskMutation, RevertIgnoreTaskMutationVariables>
        mutation={RevertIgnoreTaskDocument}
        {...props}
    />
)

export type RevertIgnoreTaskMutationResult = ApolloReactCommon.MutationResult<RevertIgnoreTaskMutation>
export type RevertIgnoreTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
    RevertIgnoreTaskMutation,
    RevertIgnoreTaskMutationVariables
>
export const TasksForMonthDocument = gql`
    query tasksForMonth(
        $skip: Int
        $take: Int
        $filters: TaskForMonthFilterInputType
        $sort: TaskForMonthSortInputType
        $month: Int!
        $year: Int!
    ) {
        tasksForMonth(filters: $filters, sort: $sort, skip: $skip, take: $take, month: $month, year: $year) {
            count
            totalCount
            hasNextPage
            nodes {
                id
                name
                priority
                createdAt
                completedAt
                repeats
                forMonitoring
                reopenedAt
                ignoredAt
                ignoredReason
                isSubTask
                isParentTask
                totalSubTaskCount
                completedSubTaskCount
                tags {
                    ...TaskTagFields
                }
                employees {
                    ...TaskEmployeeFields
                }
                ignoredBy {
                    ...TaskEmployeeFields
                }
                reviewTask {
                    id
                    name
                    createdAt
                    createdBy {
                        ...TaskEmployeeFields
                    }
                }
                linkedItem {
                    ... on AlertType {
                        __typename
                        id
                        name
                    }
                    ... on ControlType {
                        __typename
                        id
                        name
                        type
                        revisionDate
                    }
                    ... on RiskType {
                        __typename
                        id
                        name
                        severity
                        isAccepted
                    }
                    ... on AssessmentType {
                        __typename
                        id
                        name
                    }
                    ... on MonitoringReportType {
                        __typename
                        id
                        name
                    }
                    ... on TopicType {
                        __typename
                        id
                        name
                    }
                    ... on RadarItemType {
                        __typename
                        id
                        name
                        isDeleted
                    }
                    ... on ConsultationType {
                        __typename
                        id
                        name
                    }
                }
                parentTask {
                    id
                    name
                }
                subTasks {
                    id
                    name
                }
                department {
                    id
                    name
                }
            }
        }
    }
    ${TaskTagFieldsFragmentDoc}
    ${TaskEmployeeFieldsFragmentDoc}
`
export type TasksForMonthComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TasksForMonthQuery, TasksForMonthQueryVariables>,
    'query'
> &
    ({ variables: TasksForMonthQueryVariables; skip?: boolean } | { skip: boolean })

export const TasksForMonthComponent = (props: TasksForMonthComponentProps) => (
    <ApolloReactComponents.Query<TasksForMonthQuery, TasksForMonthQueryVariables>
        query={TasksForMonthDocument}
        {...props}
    />
)

export type TasksForMonthQueryResult = ApolloReactCommon.QueryResult<TasksForMonthQuery, TasksForMonthQueryVariables>
export const TasksSummaryPerMonthDocument = gql`
    query tasksSummaryPerMonth($filters: TaskForMonthFilterInputType, $skip: Int, $take: Int) {
        tasksSummaryPerMonth(filters: $filters, skip: $skip, take: $take) {
            count
            totalCount
            hasNextPage
            nodes {
                id
                month
                year
                openTaskCount
                completedTaskCount
            }
        }
    }
`
export type TasksSummaryPerMonthComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TasksSummaryPerMonthQuery, TasksSummaryPerMonthQueryVariables>,
    'query'
>

export const TasksSummaryPerMonthComponent = (props: TasksSummaryPerMonthComponentProps) => (
    <ApolloReactComponents.Query<TasksSummaryPerMonthQuery, TasksSummaryPerMonthQueryVariables>
        query={TasksSummaryPerMonthDocument}
        {...props}
    />
)

export type TasksSummaryPerMonthQueryResult = ApolloReactCommon.QueryResult<
    TasksSummaryPerMonthQuery,
    TasksSummaryPerMonthQueryVariables
>
export const ControlsToLinkDocument = gql`
    query controlsToLink($filters: ControlFilters, $skip: Int, $take: Int, $departmentId: Int!) {
        controls(skip: $skip, take: $take, filters: $filters) {
            hasNextPage
            nodes {
                id
                name
                type
                revisionDate
                childControls(activeDepartmentId: $departmentId) {
                    id
                    name
                    type
                    revisionDate
                    childControls(activeDepartmentId: $departmentId) {
                        id
                        name
                        type
                        revisionDate
                    }
                }
            }
        }
    }
`
export type ControlsToLinkComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<ControlsToLinkQuery, ControlsToLinkQueryVariables>,
    'query'
> &
    ({ variables: ControlsToLinkQueryVariables; skip?: boolean } | { skip: boolean })

export const ControlsToLinkComponent = (props: ControlsToLinkComponentProps) => (
    <ApolloReactComponents.Query<ControlsToLinkQuery, ControlsToLinkQueryVariables>
        query={ControlsToLinkDocument}
        {...props}
    />
)

export type ControlsToLinkQueryResult = ApolloReactCommon.QueryResult<ControlsToLinkQuery, ControlsToLinkQueryVariables>
export const CreateCustomerUsersExportDocument = gql`
    mutation CreateCustomerUsersExport($filters: CustomerFiltersType, $sort: CustomerSortType, $forCustomerId: Int) {
        createCustomerUsersExport(filters: $filters, sort: $sort, forCustomerId: $forCustomerId) {
            token
            filename
        }
    }
`
export type CreateCustomerUsersExportMutationFn = ApolloReactCommon.MutationFunction<
    CreateCustomerUsersExportMutation,
    CreateCustomerUsersExportMutationVariables
>
export type CreateCustomerUsersExportComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        CreateCustomerUsersExportMutation,
        CreateCustomerUsersExportMutationVariables
    >,
    'mutation'
>

export const CreateCustomerUsersExportComponent = (props: CreateCustomerUsersExportComponentProps) => (
    <ApolloReactComponents.Mutation<CreateCustomerUsersExportMutation, CreateCustomerUsersExportMutationVariables>
        mutation={CreateCustomerUsersExportDocument}
        {...props}
    />
)

export type CreateCustomerUsersExportMutationResult =
    ApolloReactCommon.MutationResult<CreateCustomerUsersExportMutation>
export type CreateCustomerUsersExportMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateCustomerUsersExportMutation,
    CreateCustomerUsersExportMutationVariables
>
export const CreateFeedDocument = gql`
    mutation createFeed(
        $isActive: Boolean
        $customerId: Int
        $type: CustomerSubscribedToFeedTypeTypesEnumType
        $settings: String
        $frequency: Int
        $frequencyType: CustomerSubscribedToFeedTypeFrequencyTypeEnumType
        $startDate: Date
        $recipientEmails: [String]
    ) {
        createCustomerSubscribedToFeed(
            isActive: $isActive
            customerId: $customerId
            type: $type
            settings: $settings
            frequency: $frequency
            frequencyType: $frequencyType
            startDate: $startDate
            recipientEmails: $recipientEmails
        ) {
            id
        }
    }
`
export type CreateFeedMutationFn = ApolloReactCommon.MutationFunction<CreateFeedMutation, CreateFeedMutationVariables>
export type CreateFeedComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CreateFeedMutation, CreateFeedMutationVariables>,
    'mutation'
>

export const CreateFeedComponent = (props: CreateFeedComponentProps) => (
    <ApolloReactComponents.Mutation<CreateFeedMutation, CreateFeedMutationVariables>
        mutation={CreateFeedDocument}
        {...props}
    />
)

export type CreateFeedMutationResult = ApolloReactCommon.MutationResult<CreateFeedMutation>
export type CreateFeedMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateFeedMutation,
    CreateFeedMutationVariables
>
export const DeleteFeedDocument = gql`
    mutation deleteFeed($id: Int!) {
        deleteCustomerSubscribedToFeed(id: $id)
    }
`
export type DeleteFeedMutationFn = ApolloReactCommon.MutationFunction<DeleteFeedMutation, DeleteFeedMutationVariables>
export type DeleteFeedComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<DeleteFeedMutation, DeleteFeedMutationVariables>,
    'mutation'
>

export const DeleteFeedComponent = (props: DeleteFeedComponentProps) => (
    <ApolloReactComponents.Mutation<DeleteFeedMutation, DeleteFeedMutationVariables>
        mutation={DeleteFeedDocument}
        {...props}
    />
)

export type DeleteFeedMutationResult = ApolloReactCommon.MutationResult<DeleteFeedMutation>
export type DeleteFeedMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteFeedMutation,
    DeleteFeedMutationVariables
>
export const EditFeedDocument = gql`
    mutation editFeed(
        $id: Int!
        $isActive: Boolean
        $settings: String
        $frequency: Int
        $frequencyType: CustomerSubscribedToFeedTypeFrequencyTypeEnumType
        $startDate: Date
        $recipientEmails: [String!]
    ) {
        editCustomerSubscribedToFeed(
            id: $id
            isActive: $isActive
            settings: $settings
            frequency: $frequency
            frequencyType: $frequencyType
            startDate: $startDate
            recipientEmails: $recipientEmails
        ) {
            id
            isActive
            settings
            frequency
            frequencyType
            startDate
            recipientEmails
        }
    }
`
export type EditFeedMutationFn = ApolloReactCommon.MutationFunction<EditFeedMutation, EditFeedMutationVariables>
export type EditFeedComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<EditFeedMutation, EditFeedMutationVariables>,
    'mutation'
>

export const EditFeedComponent = (props: EditFeedComponentProps) => (
    <ApolloReactComponents.Mutation<EditFeedMutation, EditFeedMutationVariables>
        mutation={EditFeedDocument}
        {...props}
    />
)

export type EditFeedMutationResult = ApolloReactCommon.MutationResult<EditFeedMutation>
export type EditFeedMutationOptions = ApolloReactCommon.BaseMutationOptions<EditFeedMutation, EditFeedMutationVariables>
export const CustomerFeedDocument = gql`
    query customerFeed($id: Int!) {
        customerSubscribedToFeed(id: $id) {
            id
            isActive
            customer {
                id
                name
                deletedAt
            }
            type
            settings
            frequency
            frequencyType
            startDate
            recipientEmails
        }
    }
`
export type CustomerFeedComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<CustomerFeedQuery, CustomerFeedQueryVariables>,
    'query'
> &
    ({ variables: CustomerFeedQueryVariables; skip?: boolean } | { skip: boolean })

export const CustomerFeedComponent = (props: CustomerFeedComponentProps) => (
    <ApolloReactComponents.Query<CustomerFeedQuery, CustomerFeedQueryVariables>
        query={CustomerFeedDocument}
        {...props}
    />
)

export type CustomerFeedQueryResult = ApolloReactCommon.QueryResult<CustomerFeedQuery, CustomerFeedQueryVariables>
export const CustomerFeedExportsDocument = gql`
    query customerFeedExports($customerFeedId: Int!, $skip: Int, $take: Int) {
        customerSubscribedToFeedExports(customerFeedId: $customerFeedId, skip: $skip, take: $take) {
            hasNextPage
            nodes {
                id
                file {
                    id
                    name
                }
                startDate
                endDate
                recipientEmails
                settings
            }
        }
    }
`
export type CustomerFeedExportsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<CustomerFeedExportsQuery, CustomerFeedExportsQueryVariables>,
    'query'
> &
    ({ variables: CustomerFeedExportsQueryVariables; skip?: boolean } | { skip: boolean })

export const CustomerFeedExportsComponent = (props: CustomerFeedExportsComponentProps) => (
    <ApolloReactComponents.Query<CustomerFeedExportsQuery, CustomerFeedExportsQueryVariables>
        query={CustomerFeedExportsDocument}
        {...props}
    />
)

export type CustomerFeedExportsQueryResult = ApolloReactCommon.QueryResult<
    CustomerFeedExportsQuery,
    CustomerFeedExportsQueryVariables
>
export const CustomerFeedsDocument = gql`
    query customerFeeds {
        customerSubscribedToFeeds {
            id
            type
            frequency
            frequencyType
            customer {
                id
                name
                deletedAt
            }
            exports {
                totalCount
            }
            isActive
        }
    }
`
export type CustomerFeedsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<CustomerFeedsQuery, CustomerFeedsQueryVariables>,
    'query'
>

export const CustomerFeedsComponent = (props: CustomerFeedsComponentProps) => (
    <ApolloReactComponents.Query<CustomerFeedsQuery, CustomerFeedsQueryVariables>
        query={CustomerFeedsDocument}
        {...props}
    />
)

export type CustomerFeedsQueryResult = ApolloReactCommon.QueryResult<CustomerFeedsQuery, CustomerFeedsQueryVariables>
export const AllFrameworksDocument = gql`
    query allFrameworks($skip: Int, $take: Int) {
        frameworks(skip: $skip, take: $take) {
            count
            totalCount
            hasNextPage
            nodes {
                id
                name
                curatedBy
                themes {
                    id
                }
            }
        }
    }
`
export type AllFrameworksComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<AllFrameworksQuery, AllFrameworksQueryVariables>,
    'query'
>

export const AllFrameworksComponent = (props: AllFrameworksComponentProps) => (
    <ApolloReactComponents.Query<AllFrameworksQuery, AllFrameworksQueryVariables>
        query={AllFrameworksDocument}
        {...props}
    />
)

export type AllFrameworksQueryResult = ApolloReactCommon.QueryResult<AllFrameworksQuery, AllFrameworksQueryVariables>
export const CustomersDocument = gql`
    query customers($take: Int, $skip: Int) {
        customers(take: $take, skip: $skip) {
            hasNextPage
            nodes {
                id
                name
            }
        }
    }
`
export type CustomersComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<CustomersQuery, CustomersQueryVariables>,
    'query'
>

export const CustomersComponent = (props: CustomersComponentProps) => (
    <ApolloReactComponents.Query<CustomersQuery, CustomersQueryVariables> query={CustomersDocument} {...props} />
)

export type CustomersQueryResult = ApolloReactCommon.QueryResult<CustomersQuery, CustomersQueryVariables>
export const CreateCustomerFrameworkDocument = gql`
    mutation createCustomerFramework(
        $customerId: Int!
        $frameworkId: Int!
        $themeIds: [Int!]
        $topicIds: [Int!]
        $customerFramework: CustomerFrameworkFieldsInputType!
    ) {
        createCustomerFramework(
            customerId: $customerId
            frameworkId: $frameworkId
            themeIds: $themeIds
            topicIds: $topicIds
            customerFramework: $customerFramework
        ) {
            id
            name
            color
            icon
            framework {
                ...CustomerFrameworkFrameworkFields
            }
        }
    }
    ${CustomerFrameworkFrameworkFieldsFragmentDoc}
`
export type CreateCustomerFrameworkMutationFn = ApolloReactCommon.MutationFunction<
    CreateCustomerFrameworkMutation,
    CreateCustomerFrameworkMutationVariables
>
export type CreateCustomerFrameworkComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        CreateCustomerFrameworkMutation,
        CreateCustomerFrameworkMutationVariables
    >,
    'mutation'
>

export const CreateCustomerFrameworkComponent = (props: CreateCustomerFrameworkComponentProps) => (
    <ApolloReactComponents.Mutation<CreateCustomerFrameworkMutation, CreateCustomerFrameworkMutationVariables>
        mutation={CreateCustomerFrameworkDocument}
        {...props}
    />
)

export type CreateCustomerFrameworkMutationResult = ApolloReactCommon.MutationResult<CreateCustomerFrameworkMutation>
export type CreateCustomerFrameworkMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateCustomerFrameworkMutation,
    CreateCustomerFrameworkMutationVariables
>
export const DeleteCustomerFrameworkDocument = gql`
    mutation deleteCustomerFramework($id: Int!) {
        deleteCustomerFramework(customerFrameworkId: $id)
    }
`
export type DeleteCustomerFrameworkMutationFn = ApolloReactCommon.MutationFunction<
    DeleteCustomerFrameworkMutation,
    DeleteCustomerFrameworkMutationVariables
>
export type DeleteCustomerFrameworkComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        DeleteCustomerFrameworkMutation,
        DeleteCustomerFrameworkMutationVariables
    >,
    'mutation'
>

export const DeleteCustomerFrameworkComponent = (props: DeleteCustomerFrameworkComponentProps) => (
    <ApolloReactComponents.Mutation<DeleteCustomerFrameworkMutation, DeleteCustomerFrameworkMutationVariables>
        mutation={DeleteCustomerFrameworkDocument}
        {...props}
    />
)

export type DeleteCustomerFrameworkMutationResult = ApolloReactCommon.MutationResult<DeleteCustomerFrameworkMutation>
export type DeleteCustomerFrameworkMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteCustomerFrameworkMutation,
    DeleteCustomerFrameworkMutationVariables
>
export const EditCustomerFrameworksTopicsDocument = gql`
    mutation editCustomerFrameworksTopics($fields: [EditCustomerFrameworkTopicsField!]!) {
        editCustomerFrameworksTopics(customerFrameworksWithThemesAndTopics: $fields) {
            id
            name
            color
            icon
            framework {
                ...CustomerFrameworkFrameworkFields
            }
        }
    }
    ${CustomerFrameworkFrameworkFieldsFragmentDoc}
`
export type EditCustomerFrameworksTopicsMutationFn = ApolloReactCommon.MutationFunction<
    EditCustomerFrameworksTopicsMutation,
    EditCustomerFrameworksTopicsMutationVariables
>
export type EditCustomerFrameworksTopicsComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        EditCustomerFrameworksTopicsMutation,
        EditCustomerFrameworksTopicsMutationVariables
    >,
    'mutation'
>

export const EditCustomerFrameworksTopicsComponent = (props: EditCustomerFrameworksTopicsComponentProps) => (
    <ApolloReactComponents.Mutation<EditCustomerFrameworksTopicsMutation, EditCustomerFrameworksTopicsMutationVariables>
        mutation={EditCustomerFrameworksTopicsDocument}
        {...props}
    />
)

export type EditCustomerFrameworksTopicsMutationResult =
    ApolloReactCommon.MutationResult<EditCustomerFrameworksTopicsMutation>
export type EditCustomerFrameworksTopicsMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditCustomerFrameworksTopicsMutation,
    EditCustomerFrameworksTopicsMutationVariables
>
export const AddWidgetDocument = gql`
    mutation addWidget(
        $widgetType: DashboardWidgetEnum!
        $layout: DashboardWidgetLayoutTypeEnum!
        $metadata: WidgetMetadataInputType!
    ) {
        addWidget(widgetType: $widgetType, layout: $layout, metadata: $metadata) {
            ...DashboardWidgetFragment
        }
    }
    ${DashboardWidgetFragmentFragmentDoc}
`
export type AddWidgetMutationFn = ApolloReactCommon.MutationFunction<AddWidgetMutation, AddWidgetMutationVariables>
export type AddWidgetComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<AddWidgetMutation, AddWidgetMutationVariables>,
    'mutation'
>

export const AddWidgetComponent = (props: AddWidgetComponentProps) => (
    <ApolloReactComponents.Mutation<AddWidgetMutation, AddWidgetMutationVariables>
        mutation={AddWidgetDocument}
        {...props}
    />
)

export type AddWidgetMutationResult = ApolloReactCommon.MutationResult<AddWidgetMutation>
export type AddWidgetMutationOptions = ApolloReactCommon.BaseMutationOptions<
    AddWidgetMutation,
    AddWidgetMutationVariables
>
export const DeleteWidgetDocument = gql`
    mutation deleteWidget($widgetIds: [Int!]!, $layout: DashboardWidgetLayoutTypeEnum!) {
        deleteWidgets(widgetIds: $widgetIds, layout: $layout) {
            ...DashboardWidgetFragment
        }
    }
    ${DashboardWidgetFragmentFragmentDoc}
`
export type DeleteWidgetMutationFn = ApolloReactCommon.MutationFunction<
    DeleteWidgetMutation,
    DeleteWidgetMutationVariables
>
export type DeleteWidgetComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<DeleteWidgetMutation, DeleteWidgetMutationVariables>,
    'mutation'
>

export const DeleteWidgetComponent = (props: DeleteWidgetComponentProps) => (
    <ApolloReactComponents.Mutation<DeleteWidgetMutation, DeleteWidgetMutationVariables>
        mutation={DeleteWidgetDocument}
        {...props}
    />
)

export type DeleteWidgetMutationResult = ApolloReactCommon.MutationResult<DeleteWidgetMutation>
export type DeleteWidgetMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteWidgetMutation,
    DeleteWidgetMutationVariables
>
export const EditDashboardLayoutDocument = gql`
    mutation editDashboardLayout($widgets: [EditDashboardLayoutInputType!]!) {
        editDashboardWidgets(widgets: $widgets) {
            ...DashboardWidgetFragment
        }
    }
    ${DashboardWidgetFragmentFragmentDoc}
`
export type EditDashboardLayoutMutationFn = ApolloReactCommon.MutationFunction<
    EditDashboardLayoutMutation,
    EditDashboardLayoutMutationVariables
>
export type EditDashboardLayoutComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<EditDashboardLayoutMutation, EditDashboardLayoutMutationVariables>,
    'mutation'
>

export const EditDashboardLayoutComponent = (props: EditDashboardLayoutComponentProps) => (
    <ApolloReactComponents.Mutation<EditDashboardLayoutMutation, EditDashboardLayoutMutationVariables>
        mutation={EditDashboardLayoutDocument}
        {...props}
    />
)

export type EditDashboardLayoutMutationResult = ApolloReactCommon.MutationResult<EditDashboardLayoutMutation>
export type EditDashboardLayoutMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditDashboardLayoutMutation,
    EditDashboardLayoutMutationVariables
>
export const EditWidgetDocument = gql`
    mutation editWidget($id: Int!, $metadata: EditWidgetMetadataInputType!) {
        editWidget(id: $id, metadata: $metadata) {
            ...DashboardWidgetFragment
        }
    }
    ${DashboardWidgetFragmentFragmentDoc}
`
export type EditWidgetMutationFn = ApolloReactCommon.MutationFunction<EditWidgetMutation, EditWidgetMutationVariables>
export type EditWidgetComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<EditWidgetMutation, EditWidgetMutationVariables>,
    'mutation'
>

export const EditWidgetComponent = (props: EditWidgetComponentProps) => (
    <ApolloReactComponents.Mutation<EditWidgetMutation, EditWidgetMutationVariables>
        mutation={EditWidgetDocument}
        {...props}
    />
)

export type EditWidgetMutationResult = ApolloReactCommon.MutationResult<EditWidgetMutation>
export type EditWidgetMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditWidgetMutation,
    EditWidgetMutationVariables
>
export const DashboardLayoutDocument = gql`
    query dashboardLayout($layout: DashboardWidgetLayoutTypeEnum) {
        dashboardWidgets(layout: $layout) {
            ...DashboardWidgetFragment
        }
    }
    ${DashboardWidgetFragmentFragmentDoc}
`
export type DashboardLayoutComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<DashboardLayoutQuery, DashboardLayoutQueryVariables>,
    'query'
>

export const DashboardLayoutComponent = (props: DashboardLayoutComponentProps) => (
    <ApolloReactComponents.Query<DashboardLayoutQuery, DashboardLayoutQueryVariables>
        query={DashboardLayoutDocument}
        {...props}
    />
)

export type DashboardLayoutQueryResult = ApolloReactCommon.QueryResult<
    DashboardLayoutQuery,
    DashboardLayoutQueryVariables
>
export const DashboardWidgetDetailDocument = gql`
    query dashboardWidgetDetail($widgetId: Int!) {
        dashboardDetailContent(widgetId: $widgetId) {
            ...DashboardWidgetDetailContentFragment
        }
    }
    ${DashboardWidgetDetailContentFragmentFragmentDoc}
`
export type DashboardWidgetDetailComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<DashboardWidgetDetailQuery, DashboardWidgetDetailQueryVariables>,
    'query'
> &
    ({ variables: DashboardWidgetDetailQueryVariables; skip?: boolean } | { skip: boolean })

export const DashboardWidgetDetailComponent = (props: DashboardWidgetDetailComponentProps) => (
    <ApolloReactComponents.Query<DashboardWidgetDetailQuery, DashboardWidgetDetailQueryVariables>
        query={DashboardWidgetDetailDocument}
        {...props}
    />
)

export type DashboardWidgetDetailQueryResult = ApolloReactCommon.QueryResult<
    DashboardWidgetDetailQuery,
    DashboardWidgetDetailQueryVariables
>
export const CreateDepartmentDocument = gql`
    mutation createDepartment($customerId: Int, $customerSlug: String, $fields: CreateDepartmentFieldsInputType!) {
        createDepartment(customerId: $customerId, customerSlug: $customerSlug, fields: $fields) {
            id
        }
    }
`
export type CreateDepartmentMutationFn = ApolloReactCommon.MutationFunction<
    CreateDepartmentMutation,
    CreateDepartmentMutationVariables
>
export type CreateDepartmentComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CreateDepartmentMutation, CreateDepartmentMutationVariables>,
    'mutation'
>

export const CreateDepartmentComponent = (props: CreateDepartmentComponentProps) => (
    <ApolloReactComponents.Mutation<CreateDepartmentMutation, CreateDepartmentMutationVariables>
        mutation={CreateDepartmentDocument}
        {...props}
    />
)

export type CreateDepartmentMutationResult = ApolloReactCommon.MutationResult<CreateDepartmentMutation>
export type CreateDepartmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateDepartmentMutation,
    CreateDepartmentMutationVariables
>
export const CreateDepartmentExportDocument = gql`
    mutation createDepartmentExport {
        createDepartmentExport {
            token
            filename
        }
    }
`
export type CreateDepartmentExportMutationFn = ApolloReactCommon.MutationFunction<
    CreateDepartmentExportMutation,
    CreateDepartmentExportMutationVariables
>
export type CreateDepartmentExportComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        CreateDepartmentExportMutation,
        CreateDepartmentExportMutationVariables
    >,
    'mutation'
>

export const CreateDepartmentExportComponent = (props: CreateDepartmentExportComponentProps) => (
    <ApolloReactComponents.Mutation<CreateDepartmentExportMutation, CreateDepartmentExportMutationVariables>
        mutation={CreateDepartmentExportDocument}
        {...props}
    />
)

export type CreateDepartmentExportMutationResult = ApolloReactCommon.MutationResult<CreateDepartmentExportMutation>
export type CreateDepartmentExportMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateDepartmentExportMutation,
    CreateDepartmentExportMutationVariables
>
export const DeleteDepartmentDocument = gql`
    mutation deleteDepartment($id: Int!, $customerId: Int, $customerSlug: String) {
        deleteDepartment(departmentId: $id, customerId: $customerId, customerSlug: $customerSlug)
    }
`
export type DeleteDepartmentMutationFn = ApolloReactCommon.MutationFunction<
    DeleteDepartmentMutation,
    DeleteDepartmentMutationVariables
>
export type DeleteDepartmentComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>,
    'mutation'
>

export const DeleteDepartmentComponent = (props: DeleteDepartmentComponentProps) => (
    <ApolloReactComponents.Mutation<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>
        mutation={DeleteDepartmentDocument}
        {...props}
    />
)

export type DeleteDepartmentMutationResult = ApolloReactCommon.MutationResult<DeleteDepartmentMutation>
export type DeleteDepartmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteDepartmentMutation,
    DeleteDepartmentMutationVariables
>
export const EditDepartmentCustomerFrameworksDocument = gql`
    mutation editDepartmentCustomerFrameworks($customerId: Int!, $fields: [CustomerFrameworkInputType!]!) {
        editDepartmentCustomerFrameworks(customerId: $customerId, departmentCustomerFrameworks: $fields) {
            id
            name
            amountOfEmployees
            departmentFrameworks {
                id
                customerFramework {
                    id
                    idn
                    name
                    color
                    icon
                    framework {
                        ...DepartmentFrameworkFrameworkFields
                    }
                }
            }
        }
    }
    ${DepartmentFrameworkFrameworkFieldsFragmentDoc}
`
export type EditDepartmentCustomerFrameworksMutationFn = ApolloReactCommon.MutationFunction<
    EditDepartmentCustomerFrameworksMutation,
    EditDepartmentCustomerFrameworksMutationVariables
>
export type EditDepartmentCustomerFrameworksComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        EditDepartmentCustomerFrameworksMutation,
        EditDepartmentCustomerFrameworksMutationVariables
    >,
    'mutation'
>

export const EditDepartmentCustomerFrameworksComponent = (props: EditDepartmentCustomerFrameworksComponentProps) => (
    <ApolloReactComponents.Mutation<
        EditDepartmentCustomerFrameworksMutation,
        EditDepartmentCustomerFrameworksMutationVariables
    >
        mutation={EditDepartmentCustomerFrameworksDocument}
        {...props}
    />
)

export type EditDepartmentCustomerFrameworksMutationResult =
    ApolloReactCommon.MutationResult<EditDepartmentCustomerFrameworksMutation>
export type EditDepartmentCustomerFrameworksMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditDepartmentCustomerFrameworksMutation,
    EditDepartmentCustomerFrameworksMutationVariables
>
export const EditDepartmentNameAndEmployeesDocument = gql`
    mutation editDepartmentNameAndEmployees(
        $departmentId: Int!
        $customerId: Int!
        $name: String
        $receiveAlertsForConsultationsEnabled: Boolean
        $employees: DepartmentEmployeesFieldInputType
    ) {
        editDepartmentNameAndEmployees(
            departmentId: $departmentId
            customerId: $customerId
            name: $name
            receiveAlertsForConsultationsEnabled: $receiveAlertsForConsultationsEnabled
            employees: $employees
        ) {
            id
            name
            receiveAlertsForConsultationsEnabled
            amountOfEmployees
            employees {
                id
                user {
                    id
                    status
                    profile {
                        id
                        fullName
                    }
                }
            }
        }
    }
`
export type EditDepartmentNameAndEmployeesMutationFn = ApolloReactCommon.MutationFunction<
    EditDepartmentNameAndEmployeesMutation,
    EditDepartmentNameAndEmployeesMutationVariables
>
export type EditDepartmentNameAndEmployeesComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        EditDepartmentNameAndEmployeesMutation,
        EditDepartmentNameAndEmployeesMutationVariables
    >,
    'mutation'
>

export const EditDepartmentNameAndEmployeesComponent = (props: EditDepartmentNameAndEmployeesComponentProps) => (
    <ApolloReactComponents.Mutation<
        EditDepartmentNameAndEmployeesMutation,
        EditDepartmentNameAndEmployeesMutationVariables
    >
        mutation={EditDepartmentNameAndEmployeesDocument}
        {...props}
    />
)

export type EditDepartmentNameAndEmployeesMutationResult =
    ApolloReactCommon.MutationResult<EditDepartmentNameAndEmployeesMutation>
export type EditDepartmentNameAndEmployeesMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditDepartmentNameAndEmployeesMutation,
    EditDepartmentNameAndEmployeesMutationVariables
>
export const IgnoreNewTopicDocument = gql`
    mutation ignoreNewTopic($customerFrameworkId: Int!, $topicId: Int!) {
        ignoreCustomerFrameworkNewTopic(customerFrameworkId: $customerFrameworkId, topicId: $topicId) {
            id
            name
            color
            icon
            framework {
                ...CustomerFrameworkFrameworkFields
            }
        }
    }
    ${CustomerFrameworkFrameworkFieldsFragmentDoc}
`
export type IgnoreNewTopicMutationFn = ApolloReactCommon.MutationFunction<
    IgnoreNewTopicMutation,
    IgnoreNewTopicMutationVariables
>
export type IgnoreNewTopicComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<IgnoreNewTopicMutation, IgnoreNewTopicMutationVariables>,
    'mutation'
>

export const IgnoreNewTopicComponent = (props: IgnoreNewTopicComponentProps) => (
    <ApolloReactComponents.Mutation<IgnoreNewTopicMutation, IgnoreNewTopicMutationVariables>
        mutation={IgnoreNewTopicDocument}
        {...props}
    />
)

export type IgnoreNewTopicMutationResult = ApolloReactCommon.MutationResult<IgnoreNewTopicMutation>
export type IgnoreNewTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<
    IgnoreNewTopicMutation,
    IgnoreNewTopicMutationVariables
>
export const AllEmployeesDocument = gql`
    query allEmployees($customerId: Int!) {
        allEmployees(customerId: $customerId) {
            id
            user {
                id
                status
                profile {
                    id
                    fullName
                }
            }
        }
    }
`
export type AllEmployeesComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<AllEmployeesQuery, AllEmployeesQueryVariables>,
    'query'
> &
    ({ variables: AllEmployeesQueryVariables; skip?: boolean } | { skip: boolean })

export const AllEmployeesComponent = (props: AllEmployeesComponentProps) => (
    <ApolloReactComponents.Query<AllEmployeesQuery, AllEmployeesQueryVariables>
        query={AllEmployeesDocument}
        {...props}
    />
)

export type AllEmployeesQueryResult = ApolloReactCommon.QueryResult<AllEmployeesQuery, AllEmployeesQueryVariables>
export const DepartmentEmployeesDocument = gql`
    query departmentEmployees($id: Int!, $customerId: Int!) {
        getDepartment(id: $id, customerId: $customerId) {
            id
            employees {
                id
                user {
                    id
                    profile {
                        id
                        fullName
                    }
                }
            }
            passiveEmployees {
                id
                user {
                    id
                    profile {
                        id
                        fullName
                    }
                }
            }
        }
    }
`
export type DepartmentEmployeesComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<DepartmentEmployeesQuery, DepartmentEmployeesQueryVariables>,
    'query'
> &
    ({ variables: DepartmentEmployeesQueryVariables; skip?: boolean } | { skip: boolean })

export const DepartmentEmployeesComponent = (props: DepartmentEmployeesComponentProps) => (
    <ApolloReactComponents.Query<DepartmentEmployeesQuery, DepartmentEmployeesQueryVariables>
        query={DepartmentEmployeesDocument}
        {...props}
    />
)

export type DepartmentEmployeesQueryResult = ApolloReactCommon.QueryResult<
    DepartmentEmployeesQuery,
    DepartmentEmployeesQueryVariables
>
export const CreateInboxExportDocument = gql`
    mutation CreateInboxExport($year: Int, $departmentIds: [Int!]!) {
        createInboxExport(year: $year, departmentIds: $departmentIds) {
            token
            filename
        }
    }
`
export type CreateInboxExportMutationFn = ApolloReactCommon.MutationFunction<
    CreateInboxExportMutation,
    CreateInboxExportMutationVariables
>
export type CreateInboxExportComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CreateInboxExportMutation, CreateInboxExportMutationVariables>,
    'mutation'
>

export const CreateInboxExportComponent = (props: CreateInboxExportComponentProps) => (
    <ApolloReactComponents.Mutation<CreateInboxExportMutation, CreateInboxExportMutationVariables>
        mutation={CreateInboxExportDocument}
        {...props}
    />
)

export type CreateInboxExportMutationResult = ApolloReactCommon.MutationResult<CreateInboxExportMutation>
export type CreateInboxExportMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateInboxExportMutation,
    CreateInboxExportMutationVariables
>
export const EditInboxItemImpactLevelDocument = gql`
    mutation editInboxItemImpactLevel($alertIds: [Int!]!, $departmentId: Int!, $impactLevel: AlertImpactLevel) {
        editAlertImpactLevels(alertIds: $alertIds, departmentId: $departmentId, impactLevel: $impactLevel) {
            id
            impactLevel(departmentId: $departmentId)
        }
    }
`
export type EditInboxItemImpactLevelMutationFn = ApolloReactCommon.MutationFunction<
    EditInboxItemImpactLevelMutation,
    EditInboxItemImpactLevelMutationVariables
>
export type EditInboxItemImpactLevelComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        EditInboxItemImpactLevelMutation,
        EditInboxItemImpactLevelMutationVariables
    >,
    'mutation'
>

export const EditInboxItemImpactLevelComponent = (props: EditInboxItemImpactLevelComponentProps) => (
    <ApolloReactComponents.Mutation<EditInboxItemImpactLevelMutation, EditInboxItemImpactLevelMutationVariables>
        mutation={EditInboxItemImpactLevelDocument}
        {...props}
    />
)

export type EditInboxItemImpactLevelMutationResult = ApolloReactCommon.MutationResult<EditInboxItemImpactLevelMutation>
export type EditInboxItemImpactLevelMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditInboxItemImpactLevelMutation,
    EditInboxItemImpactLevelMutationVariables
>
export const InboxInternalAlertsDocument = gql`
    query inboxInternalAlerts($filters: InternalAlertFiltersType, $departmentId: Int!, $skip: Int, $take: Int) {
        internalAlerts(filters: $filters, departmentId: $departmentId, skip: $skip, take: $take) {
            count
            totalCount
            hasNextPage
            page
            nodes {
                id
                note
                publishedAt
                publishedBy {
                    id
                    user {
                        id
                        profile {
                            id
                            fullName
                        }
                    }
                }
                departments {
                    id
                    name
                }
                news {
                    id
                    title
                    publicationDate
                    translatedContent
                    sources {
                        id
                        name
                    }
                    customSourceUrl
                    originalSource
                }
                topics {
                    id
                    name
                    theme {
                        id
                        name
                    }
                    departments {
                        id
                        name
                    }
                    customerFrameworks {
                        id
                        name
                        icon
                        color
                    }
                }
            }
        }
    }
`
export type InboxInternalAlertsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<InboxInternalAlertsQuery, InboxInternalAlertsQueryVariables>,
    'query'
> &
    ({ variables: InboxInternalAlertsQueryVariables; skip?: boolean } | { skip: boolean })

export const InboxInternalAlertsComponent = (props: InboxInternalAlertsComponentProps) => (
    <ApolloReactComponents.Query<InboxInternalAlertsQuery, InboxInternalAlertsQueryVariables>
        query={InboxInternalAlertsDocument}
        {...props}
    />
)

export type InboxInternalAlertsQueryResult = ApolloReactCommon.QueryResult<
    InboxInternalAlertsQuery,
    InboxInternalAlertsQueryVariables
>
export const AcceptInternalAlertDocument = gql`
    mutation acceptInternalAlert($departmentId: Int!, $internalAlertId: Int!, $extraTopicIds: [Int!], $note: String) {
        acceptDepartmentInternalAlert(
            departmentId: $departmentId
            internalAlertId: $internalAlertId
            extraTopicIds: $extraTopicIds
            note: $note
        ) {
            id
        }
    }
`
export type AcceptInternalAlertMutationFn = ApolloReactCommon.MutationFunction<
    AcceptInternalAlertMutation,
    AcceptInternalAlertMutationVariables
>
export type AcceptInternalAlertComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<AcceptInternalAlertMutation, AcceptInternalAlertMutationVariables>,
    'mutation'
>

export const AcceptInternalAlertComponent = (props: AcceptInternalAlertComponentProps) => (
    <ApolloReactComponents.Mutation<AcceptInternalAlertMutation, AcceptInternalAlertMutationVariables>
        mutation={AcceptInternalAlertDocument}
        {...props}
    />
)

export type AcceptInternalAlertMutationResult = ApolloReactCommon.MutationResult<AcceptInternalAlertMutation>
export type AcceptInternalAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<
    AcceptInternalAlertMutation,
    AcceptInternalAlertMutationVariables
>
export const ArchiveInternalAlertDocument = gql`
    mutation archiveInternalAlert($departmentId: Int!, $internalAlertId: Int!, $archivedReason: String) {
        archiveDepartmentInternalAlert(
            departmentId: $departmentId
            internalAlertId: $internalAlertId
            archivedReason: $archivedReason
        )
    }
`
export type ArchiveInternalAlertMutationFn = ApolloReactCommon.MutationFunction<
    ArchiveInternalAlertMutation,
    ArchiveInternalAlertMutationVariables
>
export type ArchiveInternalAlertComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<ArchiveInternalAlertMutation, ArchiveInternalAlertMutationVariables>,
    'mutation'
>

export const ArchiveInternalAlertComponent = (props: ArchiveInternalAlertComponentProps) => (
    <ApolloReactComponents.Mutation<ArchiveInternalAlertMutation, ArchiveInternalAlertMutationVariables>
        mutation={ArchiveInternalAlertDocument}
        {...props}
    />
)

export type ArchiveInternalAlertMutationResult = ApolloReactCommon.MutationResult<ArchiveInternalAlertMutation>
export type ArchiveInternalAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<
    ArchiveInternalAlertMutation,
    ArchiveInternalAlertMutationVariables
>
export const DepartmentInternalAlertLogsDocument = gql`
    query departmentInternalAlertLogs($internalAlertId: Int!, $departmentId: Int!, $skip: Int, $take: Int) {
        departmentInternalAlertLogs(
            internalAlertId: $internalAlertId
            departmentId: $departmentId
            skip: $skip
            take: $take
        ) {
            count
            totalCount
            hasNextPage
            nodes {
                ...internalAlertLogFragment
            }
        }
    }
    ${InternalAlertLogFragmentFragmentDoc}
`
export type DepartmentInternalAlertLogsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<
        DepartmentInternalAlertLogsQuery,
        DepartmentInternalAlertLogsQueryVariables
    >,
    'query'
> &
    ({ variables: DepartmentInternalAlertLogsQueryVariables; skip?: boolean } | { skip: boolean })

export const DepartmentInternalAlertLogsComponent = (props: DepartmentInternalAlertLogsComponentProps) => (
    <ApolloReactComponents.Query<DepartmentInternalAlertLogsQuery, DepartmentInternalAlertLogsQueryVariables>
        query={DepartmentInternalAlertLogsDocument}
        {...props}
    />
)

export type DepartmentInternalAlertLogsQueryResult = ApolloReactCommon.QueryResult<
    DepartmentInternalAlertLogsQuery,
    DepartmentInternalAlertLogsQueryVariables
>
export const InboxInternalAlertDocument = gql`
    query inboxInternalAlert($id: Int!, $departmentId: Int!, $customerSlug: String!) {
        internalAlert(id: $id, departmentId: $departmentId) {
            id
            note
            publishedAt
            publishedBy {
                id
                user {
                    id
                    profile {
                        id
                        fullName
                    }
                }
            }
            news {
                __typename
                id
                title
                content
                abstract
                publicationDate
                presentationSetting
                originalSource
                customSourceUrl
                sources {
                    id
                    name
                    group {
                        id
                        name
                        isPinned
                    }
                }
                attachments {
                    ...AttachmentFields
                }
                linkedItems(customerSlug: $customerSlug) {
                    ...LinkedItemsFields
                }
            }
            departmentStatus(departmentId: $departmentId) {
                id
                state
            }
            topics {
                id
                name
                theme {
                    id
                    name
                }
                departments {
                    id
                    name
                }
                customerFrameworks {
                    id
                    name
                    icon
                    color
                }
            }
        }
    }
    ${AttachmentFieldsFragmentDoc}
    ${LinkedItemsFieldsFragmentDoc}
`
export type InboxInternalAlertComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<InboxInternalAlertQuery, InboxInternalAlertQueryVariables>,
    'query'
> &
    ({ variables: InboxInternalAlertQueryVariables; skip?: boolean } | { skip: boolean })

export const InboxInternalAlertComponent = (props: InboxInternalAlertComponentProps) => (
    <ApolloReactComponents.Query<InboxInternalAlertQuery, InboxInternalAlertQueryVariables>
        query={InboxInternalAlertDocument}
        {...props}
    />
)

export type InboxInternalAlertQueryResult = ApolloReactCommon.QueryResult<
    InboxInternalAlertQuery,
    InboxInternalAlertQueryVariables
>
export const ReturnInternalAlertDocument = gql`
    mutation returnInternalAlert($departmentId: Int!, $internalAlertId: Int!, $returnNote: String) {
        returnDepartmentInternalAlert(
            departmentId: $departmentId
            internalAlertId: $internalAlertId
            returnNote: $returnNote
        )
    }
`
export type ReturnInternalAlertMutationFn = ApolloReactCommon.MutationFunction<
    ReturnInternalAlertMutation,
    ReturnInternalAlertMutationVariables
>
export type ReturnInternalAlertComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<ReturnInternalAlertMutation, ReturnInternalAlertMutationVariables>,
    'mutation'
>

export const ReturnInternalAlertComponent = (props: ReturnInternalAlertComponentProps) => (
    <ApolloReactComponents.Mutation<ReturnInternalAlertMutation, ReturnInternalAlertMutationVariables>
        mutation={ReturnInternalAlertDocument}
        {...props}
    />
)

export type ReturnInternalAlertMutationResult = ApolloReactCommon.MutationResult<ReturnInternalAlertMutation>
export type ReturnInternalAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<
    ReturnInternalAlertMutation,
    ReturnInternalAlertMutationVariables
>
export const MonitoringReportTopicsDocument = gql`
    query monitoringReportTopics {
        allDepartments {
            id
            name
            themesWithTopics {
                id
                name
                topics {
                    id
                    name
                }
            }
        }
    }
`
export type MonitoringReportTopicsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<MonitoringReportTopicsQuery, MonitoringReportTopicsQueryVariables>,
    'query'
>

export const MonitoringReportTopicsComponent = (props: MonitoringReportTopicsComponentProps) => (
    <ApolloReactComponents.Query<MonitoringReportTopicsQuery, MonitoringReportTopicsQueryVariables>
        query={MonitoringReportTopicsDocument}
        {...props}
    />
)

export type MonitoringReportTopicsQueryResult = ApolloReactCommon.QueryResult<
    MonitoringReportTopicsQuery,
    MonitoringReportTopicsQueryVariables
>
export const CreateMonitoringReportDocument = gql`
    mutation createMonitoringReport($fields: CreateMonitoringReportFieldsInputType!) {
        createMonitoringReport(fields: $fields) {
            id
        }
    }
`
export type CreateMonitoringReportMutationFn = ApolloReactCommon.MutationFunction<
    CreateMonitoringReportMutation,
    CreateMonitoringReportMutationVariables
>
export type CreateMonitoringReportComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        CreateMonitoringReportMutation,
        CreateMonitoringReportMutationVariables
    >,
    'mutation'
>

export const CreateMonitoringReportComponent = (props: CreateMonitoringReportComponentProps) => (
    <ApolloReactComponents.Mutation<CreateMonitoringReportMutation, CreateMonitoringReportMutationVariables>
        mutation={CreateMonitoringReportDocument}
        {...props}
    />
)

export type CreateMonitoringReportMutationResult = ApolloReactCommon.MutationResult<CreateMonitoringReportMutation>
export type CreateMonitoringReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateMonitoringReportMutation,
    CreateMonitoringReportMutationVariables
>
export const CreateMonitoringReportTopicAssessmentDocument = gql`
    mutation createMonitoringReportTopicAssessment(
        $monitoringReportTopicId: Int!
        $linkedItemId: Int!
        $linkedItemType: TopicAssessmentDELinkedItemType!
        $designOrEffectiveness: TopicAssessmentDesignOrEffectiveNessType!
        $fields: MonitoringReportTopicAssessmentInputType!
    ) {
        createMonitoringReportTopicAssessment(
            monitoringReportTopicId: $monitoringReportTopicId
            linkedItemId: $linkedItemId
            linkedItemType: $linkedItemType
            designOrEffectiveness: $designOrEffectiveness
            fields: $fields
        ) {
            id
            grade
            initialGrade
            linkedItemId
            topicAssessmentLinkedItemType
            assessmentType
        }
    }
`
export type CreateMonitoringReportTopicAssessmentMutationFn = ApolloReactCommon.MutationFunction<
    CreateMonitoringReportTopicAssessmentMutation,
    CreateMonitoringReportTopicAssessmentMutationVariables
>
export type CreateMonitoringReportTopicAssessmentComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        CreateMonitoringReportTopicAssessmentMutation,
        CreateMonitoringReportTopicAssessmentMutationVariables
    >,
    'mutation'
>

export const CreateMonitoringReportTopicAssessmentComponent = (
    props: CreateMonitoringReportTopicAssessmentComponentProps
) => (
    <ApolloReactComponents.Mutation<
        CreateMonitoringReportTopicAssessmentMutation,
        CreateMonitoringReportTopicAssessmentMutationVariables
    >
        mutation={CreateMonitoringReportTopicAssessmentDocument}
        {...props}
    />
)

export type CreateMonitoringReportTopicAssessmentMutationResult =
    ApolloReactCommon.MutationResult<CreateMonitoringReportTopicAssessmentMutation>
export type CreateMonitoringReportTopicAssessmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateMonitoringReportTopicAssessmentMutation,
    CreateMonitoringReportTopicAssessmentMutationVariables
>
export const MonitoringReportsDocument = gql`
    query monitoringReports($skip: Int, $take: Int, $filters: MonitoringReportFilters, $sort: MonitoringReportSort) {
        monitoringReports(skip: $skip, take: $take, filters: $filters, sort: $sort) {
            hasNextPage
            nodes {
                id
                name
                assessedAt
                updatedAt
                archivedAt
                departments {
                    id
                    name
                }
                archivedBy {
                    id
                    user {
                        id
                        profile {
                            id
                            fullName
                            avatar
                        }
                    }
                }
                file {
                    id
                    path
                    name
                    mimetype
                }
            }
        }
    }
`
export type MonitoringReportsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<MonitoringReportsQuery, MonitoringReportsQueryVariables>,
    'query'
>

export const MonitoringReportsComponent = (props: MonitoringReportsComponentProps) => (
    <ApolloReactComponents.Query<MonitoringReportsQuery, MonitoringReportsQueryVariables>
        query={MonitoringReportsDocument}
        {...props}
    />
)

export type MonitoringReportsQueryResult = ApolloReactCommon.QueryResult<
    MonitoringReportsQuery,
    MonitoringReportsQueryVariables
>
export const ArchiveMonitoringReportsDocument = gql`
    mutation archiveMonitoringReports($ids: [Int!]!, $reason: String) {
        archiveMonitoringReports(monitoringReportIds: $ids, archivedReason: $reason) {
            id
            archivedAt
            archivedReason
            archivedBy {
                id
            }
        }
    }
`
export type ArchiveMonitoringReportsMutationFn = ApolloReactCommon.MutationFunction<
    ArchiveMonitoringReportsMutation,
    ArchiveMonitoringReportsMutationVariables
>
export type ArchiveMonitoringReportsComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        ArchiveMonitoringReportsMutation,
        ArchiveMonitoringReportsMutationVariables
    >,
    'mutation'
>

export const ArchiveMonitoringReportsComponent = (props: ArchiveMonitoringReportsComponentProps) => (
    <ApolloReactComponents.Mutation<ArchiveMonitoringReportsMutation, ArchiveMonitoringReportsMutationVariables>
        mutation={ArchiveMonitoringReportsDocument}
        {...props}
    />
)

export type ArchiveMonitoringReportsMutationResult = ApolloReactCommon.MutationResult<ArchiveMonitoringReportsMutation>
export type ArchiveMonitoringReportsMutationOptions = ApolloReactCommon.BaseMutationOptions<
    ArchiveMonitoringReportsMutation,
    ArchiveMonitoringReportsMutationVariables
>
export const MonitoringReportDocument = gql`
    query monitoringReport($monitoringReportId: Int!) {
        monitoringReport(monitoringReportId: $monitoringReportId) {
            __typename
            id
            name
            assessedAt
            updatedAt
            nextTask {
                id
                dueAt
                priority
            }
            highestPrioTask {
                id
                priority
            }
            file {
                id
                path
                name
                mimetype
            }
            archivedAt
            archivedReason
            archivedBy {
                id
            }
            unarchivedAt
            unarchivedBy {
                id
            }
            departments {
                id
                name
            }
            monitoringReportTopics {
                id
                isRemoved
                notApplicable
                tasks {
                    id
                }
                department {
                    id
                    name
                }
                topic {
                    id
                    name
                }
                controlsAndNotes {
                    __typename
                    ... on TopicControlMeasureType {
                        id
                        control {
                            id
                            name
                            type
                        }
                        assessments {
                            id
                            grade
                            topicAssessmentDesignOrEffectiveNessType
                            updatedAt
                            description
                            documentsCount
                        }
                    }
                    ... on TopicNoteType {
                        id
                        title
                        assessments {
                            id
                            grade
                            topicAssessmentDesignOrEffectiveNessType
                            updatedAt
                            description
                            documentsCount
                        }
                    }
                }
                assessments {
                    id
                    updatedAt
                    grade
                    initialGrade
                    linkedItemId
                    topicAssessmentLinkedItemType
                    assessmentType
                    documentsCount
                    description
                }
            }
        }
    }
`
export type MonitoringReportComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<MonitoringReportQuery, MonitoringReportQueryVariables>,
    'query'
> &
    ({ variables: MonitoringReportQueryVariables; skip?: boolean } | { skip: boolean })

export const MonitoringReportComponent = (props: MonitoringReportComponentProps) => (
    <ApolloReactComponents.Query<MonitoringReportQuery, MonitoringReportQueryVariables>
        query={MonitoringReportDocument}
        {...props}
    />
)

export type MonitoringReportQueryResult = ApolloReactCommon.QueryResult<
    MonitoringReportQuery,
    MonitoringReportQueryVariables
>
export const MonitoringSelectDocument = gql`
    query monitoringSelect($skip: Int, $take: Int, $filters: MonitoringReportFilters, $sort: MonitoringReportSort) {
        monitoringReports(skip: $skip, take: $take, filters: $filters, sort: $sort) {
            hasNextPage
            nodes {
                id
                name
            }
        }
    }
`
export type MonitoringSelectComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<MonitoringSelectQuery, MonitoringSelectQueryVariables>,
    'query'
>

export const MonitoringSelectComponent = (props: MonitoringSelectComponentProps) => (
    <ApolloReactComponents.Query<MonitoringSelectQuery, MonitoringSelectQueryVariables>
        query={MonitoringSelectDocument}
        {...props}
    />
)

export type MonitoringSelectQueryResult = ApolloReactCommon.QueryResult<
    MonitoringSelectQuery,
    MonitoringSelectQueryVariables
>
export const UnarchiveMonitoringReportsDocument = gql`
    mutation unarchiveMonitoringReports($ids: [Int!]!) {
        unarchiveMonitoringReports(monitoringReportIds: $ids) {
            id
            archivedAt
            archivedReason
            archivedBy {
                id
            }
            unarchivedAt
            unarchivedBy {
                id
            }
        }
    }
`
export type UnarchiveMonitoringReportsMutationFn = ApolloReactCommon.MutationFunction<
    UnarchiveMonitoringReportsMutation,
    UnarchiveMonitoringReportsMutationVariables
>
export type UnarchiveMonitoringReportsComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        UnarchiveMonitoringReportsMutation,
        UnarchiveMonitoringReportsMutationVariables
    >,
    'mutation'
>

export const UnarchiveMonitoringReportsComponent = (props: UnarchiveMonitoringReportsComponentProps) => (
    <ApolloReactComponents.Mutation<UnarchiveMonitoringReportsMutation, UnarchiveMonitoringReportsMutationVariables>
        mutation={UnarchiveMonitoringReportsDocument}
        {...props}
    />
)

export type UnarchiveMonitoringReportsMutationResult =
    ApolloReactCommon.MutationResult<UnarchiveMonitoringReportsMutation>
export type UnarchiveMonitoringReportsMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UnarchiveMonitoringReportsMutation,
    UnarchiveMonitoringReportsMutationVariables
>
export const NewsSourceSelectDocument = gql`
    query newsSourceSelect($id: Int!) {
        newsGroup(id: $id) {
            id
            name
            sources {
                id
                name
                isEnabledByCustomer
            }
        }
    }
`
export type NewsSourceSelectComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<NewsSourceSelectQuery, NewsSourceSelectQueryVariables>,
    'query'
> &
    ({ variables: NewsSourceSelectQueryVariables; skip?: boolean } | { skip: boolean })

export const NewsSourceSelectComponent = (props: NewsSourceSelectComponentProps) => (
    <ApolloReactComponents.Query<NewsSourceSelectQuery, NewsSourceSelectQueryVariables>
        query={NewsSourceSelectDocument}
        {...props}
    />
)

export type NewsSourceSelectQueryResult = ApolloReactCommon.QueryResult<
    NewsSourceSelectQuery,
    NewsSourceSelectQueryVariables
>
export const TranslatedNewsItemDocument = gql`
    query translatedNewsItem($id: Int!) {
        newsItem(id: $id) {
            id
            title
            publicationDate
            translatedContent
            attachments {
                ...AttachmentFields
            }
            sources {
                id
                group {
                    id
                    name
                }
            }
            linkedItems {
                ...LinkedItemsFields
            }
        }
    }
    ${AttachmentFieldsFragmentDoc}
    ${LinkedItemsFieldsFragmentDoc}
`
export type TranslatedNewsItemComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TranslatedNewsItemQuery, TranslatedNewsItemQueryVariables>,
    'query'
> &
    ({ variables: TranslatedNewsItemQueryVariables; skip?: boolean } | { skip: boolean })

export const TranslatedNewsItemComponent = (props: TranslatedNewsItemComponentProps) => (
    <ApolloReactComponents.Query<TranslatedNewsItemQuery, TranslatedNewsItemQueryVariables>
        query={TranslatedNewsItemDocument}
        {...props}
    />
)

export type TranslatedNewsItemQueryResult = ApolloReactCommon.QueryResult<
    TranslatedNewsItemQuery,
    TranslatedNewsItemQueryVariables
>
export const PinnedThemesDocument = gql`
    query pinnedThemes($customerFrameworkIds: [Int!], $departmentId: Int!, $search: String) {
        pinned {
            themes(customerFrameworkIds: $customerFrameworkIds, departmentId: $departmentId, search: $search) {
                id
                name
                customerFrameworks(departmentId: $departmentId) {
                    ...CustomerFrameworkFields
                }
                topicAssessmentCoverage(departmentId: $departmentId, customerFrameworkIds: $customerFrameworkIds) {
                    design {
                        ...ThemeTopicAssessmentCoverageScoresFragment
                    }
                    effectiveness {
                        ...ThemeTopicAssessmentCoverageScoresFragment
                    }
                }
            }
        }
    }
    ${CustomerFrameworkFieldsFragmentDoc}
    ${ThemeTopicAssessmentCoverageScoresFragmentFragmentDoc}
`
export type PinnedThemesComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<PinnedThemesQuery, PinnedThemesQueryVariables>,
    'query'
> &
    ({ variables: PinnedThemesQueryVariables; skip?: boolean } | { skip: boolean })

export const PinnedThemesComponent = (props: PinnedThemesComponentProps) => (
    <ApolloReactComponents.Query<PinnedThemesQuery, PinnedThemesQueryVariables>
        query={PinnedThemesDocument}
        {...props}
    />
)

export type PinnedThemesQueryResult = ApolloReactCommon.QueryResult<PinnedThemesQuery, PinnedThemesQueryVariables>
export const PinnedTopicsDocument = gql`
    query pinnedTopics(
        $themeIds: [Int!]
        $customerFrameworkIds: [Int!]
        $departmentId: Int!
        $topicDnEFilters: TopicsForThemeFilters
    ) {
        pinned {
            topics(
                customerFrameworkIds: $customerFrameworkIds
                themeIds: $themeIds
                departmentId: $departmentId
                topicDnEFilters: $topicDnEFilters
            ) {
                id
                name
                highestRisk(departmentId: $departmentId) {
                    id
                    name
                    severity
                    severityBruto
                    isAccepted
                }
                nonApplicable {
                    id
                    reason
                }
                customerFrameworks(departmentId: $departmentId) {
                    ...CustomerFrameworkFields
                }
                departmentTopicData(departmentId: $departmentId) {
                    id
                    topicAssessmentDesignMetadata {
                        ...DepartmentTopicDataMetadataFragment
                    }
                    topicAssessmentEffectivenessMetadata {
                        ...DepartmentTopicDataMetadataFragment
                    }
                }
            }
        }
    }
    ${CustomerFrameworkFieldsFragmentDoc}
    ${DepartmentTopicDataMetadataFragmentFragmentDoc}
`
export type PinnedTopicsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<PinnedTopicsQuery, PinnedTopicsQueryVariables>,
    'query'
> &
    ({ variables: PinnedTopicsQueryVariables; skip?: boolean } | { skip: boolean })

export const PinnedTopicsComponent = (props: PinnedTopicsComponentProps) => (
    <ApolloReactComponents.Query<PinnedTopicsQuery, PinnedTopicsQueryVariables>
        query={PinnedTopicsDocument}
        {...props}
    />
)

export type PinnedTopicsQueryResult = ApolloReactCommon.QueryResult<PinnedTopicsQuery, PinnedTopicsQueryVariables>
export const AllCustomerDepartmentsDocument = gql`
    query allCustomerDepartments($customerSlug: String) {
        allDepartments(customerSlug: $customerSlug) {
            id
            name
        }
    }
`
export type AllCustomerDepartmentsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<AllCustomerDepartmentsQuery, AllCustomerDepartmentsQueryVariables>,
    'query'
>

export const AllCustomerDepartmentsComponent = (props: AllCustomerDepartmentsComponentProps) => (
    <ApolloReactComponents.Query<AllCustomerDepartmentsQuery, AllCustomerDepartmentsQueryVariables>
        query={AllCustomerDepartmentsDocument}
        {...props}
    />
)

export type AllCustomerDepartmentsQueryResult = ApolloReactCommon.QueryResult<
    AllCustomerDepartmentsQuery,
    AllCustomerDepartmentsQueryVariables
>
export const DeleteEmployeeDocument = gql`
    mutation deleteEmployee($id: Int!, $customerSlug: String) {
        deleteEmployee(id: $id, customerSlug: $customerSlug)
    }
`
export type DeleteEmployeeMutationFn = ApolloReactCommon.MutationFunction<
    DeleteEmployeeMutation,
    DeleteEmployeeMutationVariables
>
export type DeleteEmployeeComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>,
    'mutation'
>

export const DeleteEmployeeComponent = (props: DeleteEmployeeComponentProps) => (
    <ApolloReactComponents.Mutation<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>
        mutation={DeleteEmployeeDocument}
        {...props}
    />
)

export type DeleteEmployeeMutationResult = ApolloReactCommon.MutationResult<DeleteEmployeeMutation>
export type DeleteEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteEmployeeMutation,
    DeleteEmployeeMutationVariables
>
export const GeneratedSummaryByParentDocument = gql`
    query generatedSummaryByParent($parentId: Int!, $parentType: GeneratedSummaryParentTypeEnum!) {
        generatedSummaryByParent(parentId: $parentId, parentType: $parentType) {
            id
            status
            summary
            userVote {
                id
                vote
                changedVote
            }
        }
    }
`
export type GeneratedSummaryByParentComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<GeneratedSummaryByParentQuery, GeneratedSummaryByParentQueryVariables>,
    'query'
> &
    ({ variables: GeneratedSummaryByParentQueryVariables; skip?: boolean } | { skip: boolean })

export const GeneratedSummaryByParentComponent = (props: GeneratedSummaryByParentComponentProps) => (
    <ApolloReactComponents.Query<GeneratedSummaryByParentQuery, GeneratedSummaryByParentQueryVariables>
        query={GeneratedSummaryByParentDocument}
        {...props}
    />
)

export type GeneratedSummaryByParentQueryResult = ApolloReactCommon.QueryResult<
    GeneratedSummaryByParentQuery,
    GeneratedSummaryByParentQueryVariables
>
export const ArchiveCustomerNewsDocument = gql`
    mutation archiveCustomerNews($newsIds: [Int!]!, $archivedReason: String) {
        archiveCustomerNews(newsIds: $newsIds, archivedReason: $archivedReason) {
            ...SignalingNewsItemFields
        }
    }
    ${SignalingNewsItemFieldsFragmentDoc}
`
export type ArchiveCustomerNewsMutationFn = ApolloReactCommon.MutationFunction<
    ArchiveCustomerNewsMutation,
    ArchiveCustomerNewsMutationVariables
>
export type ArchiveCustomerNewsComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<ArchiveCustomerNewsMutation, ArchiveCustomerNewsMutationVariables>,
    'mutation'
>

export const ArchiveCustomerNewsComponent = (props: ArchiveCustomerNewsComponentProps) => (
    <ApolloReactComponents.Mutation<ArchiveCustomerNewsMutation, ArchiveCustomerNewsMutationVariables>
        mutation={ArchiveCustomerNewsDocument}
        {...props}
    />
)

export type ArchiveCustomerNewsMutationResult = ApolloReactCommon.MutationResult<ArchiveCustomerNewsMutation>
export type ArchiveCustomerNewsMutationOptions = ApolloReactCommon.BaseMutationOptions<
    ArchiveCustomerNewsMutation,
    ArchiveCustomerNewsMutationVariables
>
export const CreateCustomerEnabledNewsSourcesExportDocument = gql`
    mutation CreateCustomerEnabledNewsSourcesExport {
        createCustomerEnabledNewsSourcesExport {
            token
            filename
        }
    }
`
export type CreateCustomerEnabledNewsSourcesExportMutationFn = ApolloReactCommon.MutationFunction<
    CreateCustomerEnabledNewsSourcesExportMutation,
    CreateCustomerEnabledNewsSourcesExportMutationVariables
>
export type CreateCustomerEnabledNewsSourcesExportComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        CreateCustomerEnabledNewsSourcesExportMutation,
        CreateCustomerEnabledNewsSourcesExportMutationVariables
    >,
    'mutation'
>

export const CreateCustomerEnabledNewsSourcesExportComponent = (
    props: CreateCustomerEnabledNewsSourcesExportComponentProps
) => (
    <ApolloReactComponents.Mutation<
        CreateCustomerEnabledNewsSourcesExportMutation,
        CreateCustomerEnabledNewsSourcesExportMutationVariables
    >
        mutation={CreateCustomerEnabledNewsSourcesExportDocument}
        {...props}
    />
)

export type CreateCustomerEnabledNewsSourcesExportMutationResult =
    ApolloReactCommon.MutationResult<CreateCustomerEnabledNewsSourcesExportMutation>
export type CreateCustomerEnabledNewsSourcesExportMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateCustomerEnabledNewsSourcesExportMutation,
    CreateCustomerEnabledNewsSourcesExportMutationVariables
>
export const EditPublishedCustomerNewsDocument = gql`
    mutation editPublishedCustomerNews($id: Int!, $extraDepartmentIds: [Int!], $extraTopicIds: [Int!], $note: String) {
        editInternalAlert(
            id: $id
            extraDepartmentIds: $extraDepartmentIds
            extraTopicIds: $extraTopicIds
            note: $note
        ) {
            id
        }
    }
`
export type EditPublishedCustomerNewsMutationFn = ApolloReactCommon.MutationFunction<
    EditPublishedCustomerNewsMutation,
    EditPublishedCustomerNewsMutationVariables
>
export type EditPublishedCustomerNewsComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        EditPublishedCustomerNewsMutation,
        EditPublishedCustomerNewsMutationVariables
    >,
    'mutation'
>

export const EditPublishedCustomerNewsComponent = (props: EditPublishedCustomerNewsComponentProps) => (
    <ApolloReactComponents.Mutation<EditPublishedCustomerNewsMutation, EditPublishedCustomerNewsMutationVariables>
        mutation={EditPublishedCustomerNewsDocument}
        {...props}
    />
)

export type EditPublishedCustomerNewsMutationResult =
    ApolloReactCommon.MutationResult<EditPublishedCustomerNewsMutation>
export type EditPublishedCustomerNewsMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditPublishedCustomerNewsMutation,
    EditPublishedCustomerNewsMutationVariables
>
export const CreateInternalAlertDocument = gql`
    mutation createInternalAlert($departmentIds: [Int!], $topicIds: [Int!], $newsId: Int!, $note: String) {
        createInternalAlert(departmentIds: $departmentIds, topicIds: $topicIds, newsId: $newsId, note: $note) {
            id
        }
    }
`
export type CreateInternalAlertMutationFn = ApolloReactCommon.MutationFunction<
    CreateInternalAlertMutation,
    CreateInternalAlertMutationVariables
>
export type CreateInternalAlertComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CreateInternalAlertMutation, CreateInternalAlertMutationVariables>,
    'mutation'
>

export const CreateInternalAlertComponent = (props: CreateInternalAlertComponentProps) => (
    <ApolloReactComponents.Mutation<CreateInternalAlertMutation, CreateInternalAlertMutationVariables>
        mutation={CreateInternalAlertDocument}
        {...props}
    />
)

export type CreateInternalAlertMutationResult = ApolloReactCommon.MutationResult<CreateInternalAlertMutation>
export type CreateInternalAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateInternalAlertMutation,
    CreateInternalAlertMutationVariables
>
export const InternalAlertLogsQueryDocument = gql`
    query internalAlertLogsQuery($internalAlertId: Int!, $skip: Int, $take: Int) {
        internalAlertLogs(internalAlertId: $internalAlertId, skip: $skip, take: $take) {
            count
            totalCount
            hasNextPage
            nodes {
                ...internalAlertLogFragment
            }
        }
    }
    ${InternalAlertLogFragmentFragmentDoc}
`
export type InternalAlertLogsQueryComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<InternalAlertLogsQueryQuery, InternalAlertLogsQueryQueryVariables>,
    'query'
> &
    ({ variables: InternalAlertLogsQueryQueryVariables; skip?: boolean } | { skip: boolean })

export const InternalAlertLogsQueryComponent = (props: InternalAlertLogsQueryComponentProps) => (
    <ApolloReactComponents.Query<InternalAlertLogsQueryQuery, InternalAlertLogsQueryQueryVariables>
        query={InternalAlertLogsQueryDocument}
        {...props}
    />
)

export type InternalAlertLogsQueryQueryResult = ApolloReactCommon.QueryResult<
    InternalAlertLogsQueryQuery,
    InternalAlertLogsQueryQueryVariables
>
export const InternalAlertsForCustomerDocument = gql`
    query internalAlertsForCustomer($filters: InternalAlertFiltersForCustomerType, $skip: Int, $take: Int) {
        internalAlertsForCustomer(filters: $filters, skip: $skip, take: $take) {
            count
            totalCount
            hasNextPage
            nodes {
                id
                publishedAt
                news {
                    id
                    title
                    publicationDate
                    presentationSetting
                    originalSource
                    customSourceUrl
                }
                statuses {
                    id
                    state
                    department {
                        id
                        name
                    }
                    returnedAt
                    returnedBy {
                        id
                        user {
                            id
                            profile {
                                id
                                fullName
                            }
                        }
                    }
                }
            }
        }
    }
`
export type InternalAlertsForCustomerComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<
        InternalAlertsForCustomerQuery,
        InternalAlertsForCustomerQueryVariables
    >,
    'query'
>

export const InternalAlertsForCustomerComponent = (props: InternalAlertsForCustomerComponentProps) => (
    <ApolloReactComponents.Query<InternalAlertsForCustomerQuery, InternalAlertsForCustomerQueryVariables>
        query={InternalAlertsForCustomerDocument}
        {...props}
    />
)

export type InternalAlertsForCustomerQueryResult = ApolloReactCommon.QueryResult<
    InternalAlertsForCustomerQuery,
    InternalAlertsForCustomerQueryVariables
>
export const UnarchiveCustomerNewsDocument = gql`
    mutation unarchiveCustomerNews($newsIds: [Int!]!) {
        unarchiveCustomerNews(newsIds: $newsIds) {
            ...SignalingNewsItemFields
        }
    }
    ${SignalingNewsItemFieldsFragmentDoc}
`
export type UnarchiveCustomerNewsMutationFn = ApolloReactCommon.MutationFunction<
    UnarchiveCustomerNewsMutation,
    UnarchiveCustomerNewsMutationVariables
>
export type UnarchiveCustomerNewsComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        UnarchiveCustomerNewsMutation,
        UnarchiveCustomerNewsMutationVariables
    >,
    'mutation'
>

export const UnarchiveCustomerNewsComponent = (props: UnarchiveCustomerNewsComponentProps) => (
    <ApolloReactComponents.Mutation<UnarchiveCustomerNewsMutation, UnarchiveCustomerNewsMutationVariables>
        mutation={UnarchiveCustomerNewsDocument}
        {...props}
    />
)

export type UnarchiveCustomerNewsMutationResult = ApolloReactCommon.MutationResult<UnarchiveCustomerNewsMutation>
export type UnarchiveCustomerNewsMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UnarchiveCustomerNewsMutation,
    UnarchiveCustomerNewsMutationVariables
>
export const CastVoteDocument = gql`
    mutation castVote($generatedSummaryId: Int!, $vote: GeneratedSummaryUserVoteEnum) {
        castGeneratedSummaryVote(generatedSummaryId: $generatedSummaryId, vote: $vote) {
            id
            changedVote
            vote
        }
    }
`
export type CastVoteMutationFn = ApolloReactCommon.MutationFunction<CastVoteMutation, CastVoteMutationVariables>
export type CastVoteComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CastVoteMutation, CastVoteMutationVariables>,
    'mutation'
>

export const CastVoteComponent = (props: CastVoteComponentProps) => (
    <ApolloReactComponents.Mutation<CastVoteMutation, CastVoteMutationVariables>
        mutation={CastVoteDocument}
        {...props}
    />
)

export type CastVoteMutationResult = ApolloReactCommon.MutationResult<CastVoteMutation>
export type CastVoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CastVoteMutation, CastVoteMutationVariables>
export const PublishedCustomerNewsDocument = gql`
    query publishedCustomerNews($filters: PublishedCustomerNewsFiltersType, $skip: Int, $take: Int) {
        publishedCustomerNews(filters: $filters, skip: $skip, take: $take) {
            count
            totalCount
            hasNextPage
            page
            nodes {
                id
                departments {
                    id
                    name
                }
                alert {
                    id
                    name
                }
            }
        }
    }
`
export type PublishedCustomerNewsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<PublishedCustomerNewsQuery, PublishedCustomerNewsQueryVariables>,
    'query'
>

export const PublishedCustomerNewsComponent = (props: PublishedCustomerNewsComponentProps) => (
    <ApolloReactComponents.Query<PublishedCustomerNewsQuery, PublishedCustomerNewsQueryVariables>
        query={PublishedCustomerNewsDocument}
        {...props}
    />
)

export type PublishedCustomerNewsQueryResult = ApolloReactCommon.QueryResult<
    PublishedCustomerNewsQuery,
    PublishedCustomerNewsQueryVariables
>
export const SignalingDetailQueryDocument = gql`
    query signalingDetailQuery($id: Int!, $customerSlug: String) {
        newsItem(id: $id) {
            ...SignalingNewsItemFields
            content
            abstract
            attachments {
                ...AttachmentFields
            }
            linkedItems(customerSlug: $customerSlug) {
                ...LinkedItemsFields
            }
        }
    }
    ${SignalingNewsItemFieldsFragmentDoc}
    ${AttachmentFieldsFragmentDoc}
    ${LinkedItemsFieldsFragmentDoc}
`
export type SignalingDetailQueryComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<SignalingDetailQueryQuery, SignalingDetailQueryQueryVariables>,
    'query'
> &
    ({ variables: SignalingDetailQueryQueryVariables; skip?: boolean } | { skip: boolean })

export const SignalingDetailQueryComponent = (props: SignalingDetailQueryComponentProps) => (
    <ApolloReactComponents.Query<SignalingDetailQueryQuery, SignalingDetailQueryQueryVariables>
        query={SignalingDetailQueryDocument}
        {...props}
    />
)

export type SignalingDetailQueryQueryResult = ApolloReactCommon.QueryResult<
    SignalingDetailQueryQuery,
    SignalingDetailQueryQueryVariables
>
export const SignalingOverviewQueryDocument = gql`
    query signalingOverviewQuery($skip: Int, $take: Int, $sort: NewsSort, $filters: NewsFilters) {
        newsItems(skip: $skip, take: $take, sort: $sort, filters: $filters) {
            count
            totalCount
            hasNextPage
            nodes {
                ...SignalingNewsItemFields
            }
        }
    }
    ${SignalingNewsItemFieldsFragmentDoc}
`
export type SignalingOverviewQueryComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<SignalingOverviewQueryQuery, SignalingOverviewQueryQueryVariables>,
    'query'
>

export const SignalingOverviewQueryComponent = (props: SignalingOverviewQueryComponentProps) => (
    <ApolloReactComponents.Query<SignalingOverviewQueryQuery, SignalingOverviewQueryQueryVariables>
        query={SignalingOverviewQueryDocument}
        {...props}
    />
)

export type SignalingOverviewQueryQueryResult = ApolloReactCommon.QueryResult<
    SignalingOverviewQueryQuery,
    SignalingOverviewQueryQueryVariables
>
export const ArchiveConsultantTaskTemplateDocument = gql`
    mutation archiveConsultantTaskTemplate($taskTemplateId: Int!) {
        archiveConsultantTaskTemplate(taskTemplateId: $taskTemplateId)
    }
`
export type ArchiveConsultantTaskTemplateMutationFn = ApolloReactCommon.MutationFunction<
    ArchiveConsultantTaskTemplateMutation,
    ArchiveConsultantTaskTemplateMutationVariables
>
export type ArchiveConsultantTaskTemplateComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        ArchiveConsultantTaskTemplateMutation,
        ArchiveConsultantTaskTemplateMutationVariables
    >,
    'mutation'
>

export const ArchiveConsultantTaskTemplateComponent = (props: ArchiveConsultantTaskTemplateComponentProps) => (
    <ApolloReactComponents.Mutation<
        ArchiveConsultantTaskTemplateMutation,
        ArchiveConsultantTaskTemplateMutationVariables
    >
        mutation={ArchiveConsultantTaskTemplateDocument}
        {...props}
    />
)

export type ArchiveConsultantTaskTemplateMutationResult =
    ApolloReactCommon.MutationResult<ArchiveConsultantTaskTemplateMutation>
export type ArchiveConsultantTaskTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
    ArchiveConsultantTaskTemplateMutation,
    ArchiveConsultantTaskTemplateMutationVariables
>
export const CreateConsultantTaskTemplateDocument = gql`
    mutation createConsultantTaskTemplate($fields: CreateConsultantTaskTemplateFieldsInputType!) {
        createConsultantTaskTemplate(fields: $fields) {
            ...ConsultantTaskTemplateOverviewFragment
        }
    }
    ${ConsultantTaskTemplateOverviewFragmentFragmentDoc}
`
export type CreateConsultantTaskTemplateMutationFn = ApolloReactCommon.MutationFunction<
    CreateConsultantTaskTemplateMutation,
    CreateConsultantTaskTemplateMutationVariables
>
export type CreateConsultantTaskTemplateComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        CreateConsultantTaskTemplateMutation,
        CreateConsultantTaskTemplateMutationVariables
    >,
    'mutation'
>

export const CreateConsultantTaskTemplateComponent = (props: CreateConsultantTaskTemplateComponentProps) => (
    <ApolloReactComponents.Mutation<CreateConsultantTaskTemplateMutation, CreateConsultantTaskTemplateMutationVariables>
        mutation={CreateConsultantTaskTemplateDocument}
        {...props}
    />
)

export type CreateConsultantTaskTemplateMutationResult =
    ApolloReactCommon.MutationResult<CreateConsultantTaskTemplateMutation>
export type CreateConsultantTaskTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateConsultantTaskTemplateMutation,
    CreateConsultantTaskTemplateMutationVariables
>
export const EditConsultantTaskTemplateDocument = gql`
    mutation editConsultantTaskTemplate($taskTemplateId: Int!, $fields: EditConsultantTaskTemplateFieldsInputType!) {
        editConsultantTaskTemplate(taskTemplateId: $taskTemplateId, fields: $fields) {
            ...ConsultantTaskTemplateOverviewFragment
        }
    }
    ${ConsultantTaskTemplateOverviewFragmentFragmentDoc}
`
export type EditConsultantTaskTemplateMutationFn = ApolloReactCommon.MutationFunction<
    EditConsultantTaskTemplateMutation,
    EditConsultantTaskTemplateMutationVariables
>
export type EditConsultantTaskTemplateComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        EditConsultantTaskTemplateMutation,
        EditConsultantTaskTemplateMutationVariables
    >,
    'mutation'
>

export const EditConsultantTaskTemplateComponent = (props: EditConsultantTaskTemplateComponentProps) => (
    <ApolloReactComponents.Mutation<EditConsultantTaskTemplateMutation, EditConsultantTaskTemplateMutationVariables>
        mutation={EditConsultantTaskTemplateDocument}
        {...props}
    />
)

export type EditConsultantTaskTemplateMutationResult =
    ApolloReactCommon.MutationResult<EditConsultantTaskTemplateMutation>
export type EditConsultantTaskTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditConsultantTaskTemplateMutation,
    EditConsultantTaskTemplateMutationVariables
>
export const SetConsultantTaskTemplateVisibilityDocument = gql`
    mutation setConsultantTaskTemplateVisibility($isHidden: Boolean!) {
        setIsConsultantTaskTemplateHidden(isHidden: $isHidden) {
            id
            isConsultantTaskTemplatesHidden
        }
    }
`
export type SetConsultantTaskTemplateVisibilityMutationFn = ApolloReactCommon.MutationFunction<
    SetConsultantTaskTemplateVisibilityMutation,
    SetConsultantTaskTemplateVisibilityMutationVariables
>
export type SetConsultantTaskTemplateVisibilityComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        SetConsultantTaskTemplateVisibilityMutation,
        SetConsultantTaskTemplateVisibilityMutationVariables
    >,
    'mutation'
>

export const SetConsultantTaskTemplateVisibilityComponent = (
    props: SetConsultantTaskTemplateVisibilityComponentProps
) => (
    <ApolloReactComponents.Mutation<
        SetConsultantTaskTemplateVisibilityMutation,
        SetConsultantTaskTemplateVisibilityMutationVariables
    >
        mutation={SetConsultantTaskTemplateVisibilityDocument}
        {...props}
    />
)

export type SetConsultantTaskTemplateVisibilityMutationResult =
    ApolloReactCommon.MutationResult<SetConsultantTaskTemplateVisibilityMutation>
export type SetConsultantTaskTemplateVisibilityMutationOptions = ApolloReactCommon.BaseMutationOptions<
    SetConsultantTaskTemplateVisibilityMutation,
    SetConsultantTaskTemplateVisibilityMutationVariables
>
export const ConsultantTaskTemplatesDocument = gql`
    query consultantTaskTemplates($take: Int, $skip: Int, $customerId: Int) {
        consultantTaskTemplates(take: $take, skip: $skip, customerId: $customerId) {
            count
            totalCount
            hasNextPage
            nodes {
                ...ConsultantTaskTemplateOverviewFragment
                totalTaskCount(customerId: $customerId)
            }
        }
    }
    ${ConsultantTaskTemplateOverviewFragmentFragmentDoc}
`
export type ConsultantTaskTemplatesComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<ConsultantTaskTemplatesQuery, ConsultantTaskTemplatesQueryVariables>,
    'query'
>

export const ConsultantTaskTemplatesComponent = (props: ConsultantTaskTemplatesComponentProps) => (
    <ApolloReactComponents.Query<ConsultantTaskTemplatesQuery, ConsultantTaskTemplatesQueryVariables>
        query={ConsultantTaskTemplatesDocument}
        {...props}
    />
)

export type ConsultantTaskTemplatesQueryResult = ApolloReactCommon.QueryResult<
    ConsultantTaskTemplatesQuery,
    ConsultantTaskTemplatesQueryVariables
>
export const ConsultantTaskTemplateDocument = gql`
    query consultantTaskTemplate($consultantTaskTemplateId: Int!) {
        consultantTaskTemplate(consultantTaskTemplateId: $consultantTaskTemplateId) {
            ...ConsultantTaskTemplateOverviewFragment
        }
    }
    ${ConsultantTaskTemplateOverviewFragmentFragmentDoc}
`
export type ConsultantTaskTemplateComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<ConsultantTaskTemplateQuery, ConsultantTaskTemplateQueryVariables>,
    'query'
> &
    ({ variables: ConsultantTaskTemplateQueryVariables; skip?: boolean } | { skip: boolean })

export const ConsultantTaskTemplateComponent = (props: ConsultantTaskTemplateComponentProps) => (
    <ApolloReactComponents.Query<ConsultantTaskTemplateQuery, ConsultantTaskTemplateQueryVariables>
        query={ConsultantTaskTemplateDocument}
        {...props}
    />
)

export type ConsultantTaskTemplateQueryResult = ApolloReactCommon.QueryResult<
    ConsultantTaskTemplateQuery,
    ConsultantTaskTemplateQueryVariables
>
export const ArchiveTaskDocument = gql`
    mutation ArchiveTask($taskId: Int!, $departmentId: Int!) {
        archiveTask(taskId: $taskId, departmentId: $departmentId)
    }
`
export type ArchiveTaskMutationFn = ApolloReactCommon.MutationFunction<
    ArchiveTaskMutation,
    ArchiveTaskMutationVariables
>
export type ArchiveTaskComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<ArchiveTaskMutation, ArchiveTaskMutationVariables>,
    'mutation'
>

export const ArchiveTaskComponent = (props: ArchiveTaskComponentProps) => (
    <ApolloReactComponents.Mutation<ArchiveTaskMutation, ArchiveTaskMutationVariables>
        mutation={ArchiveTaskDocument}
        {...props}
    />
)

export type ArchiveTaskMutationResult = ApolloReactCommon.MutationResult<ArchiveTaskMutation>
export type ArchiveTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
    ArchiveTaskMutation,
    ArchiveTaskMutationVariables
>
export const ArchiveTaskTemplateDocument = gql`
    mutation archiveTaskTemplate($customerId: Int!, $taskTemplateId: Int!) {
        archiveTaskTemplate(customerId: $customerId, taskTemplateId: $taskTemplateId)
    }
`
export type ArchiveTaskTemplateMutationFn = ApolloReactCommon.MutationFunction<
    ArchiveTaskTemplateMutation,
    ArchiveTaskTemplateMutationVariables
>
export type ArchiveTaskTemplateComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<ArchiveTaskTemplateMutation, ArchiveTaskTemplateMutationVariables>,
    'mutation'
>

export const ArchiveTaskTemplateComponent = (props: ArchiveTaskTemplateComponentProps) => (
    <ApolloReactComponents.Mutation<ArchiveTaskTemplateMutation, ArchiveTaskTemplateMutationVariables>
        mutation={ArchiveTaskTemplateDocument}
        {...props}
    />
)

export type ArchiveTaskTemplateMutationResult = ApolloReactCommon.MutationResult<ArchiveTaskTemplateMutation>
export type ArchiveTaskTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
    ArchiveTaskTemplateMutation,
    ArchiveTaskTemplateMutationVariables
>
export const CreateSubTaskDocument = gql`
    mutation createSubTask($departmentId: Int!, $fields: CreateTaskFieldsInputType!) {
        createTask(departmentId: $departmentId, fields: $fields) {
            ...TaskDetailFieldsFragment
        }
    }
    ${TaskDetailFieldsFragmentFragmentDoc}
`
export type CreateSubTaskMutationFn = ApolloReactCommon.MutationFunction<
    CreateSubTaskMutation,
    CreateSubTaskMutationVariables
>
export type CreateSubTaskComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CreateSubTaskMutation, CreateSubTaskMutationVariables>,
    'mutation'
>

export const CreateSubTaskComponent = (props: CreateSubTaskComponentProps) => (
    <ApolloReactComponents.Mutation<CreateSubTaskMutation, CreateSubTaskMutationVariables>
        mutation={CreateSubTaskDocument}
        {...props}
    />
)

export type CreateSubTaskMutationResult = ApolloReactCommon.MutationResult<CreateSubTaskMutation>
export type CreateSubTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateSubTaskMutation,
    CreateSubTaskMutationVariables
>
export const CreateTaskTemplateDocument = gql`
    mutation createTaskTemplate($customerId: Int!, $fields: CreateTaskTemplateFieldsInputType!) {
        createTaskTemplate(customerId: $customerId, fields: $fields) {
            ...TaskTemplateOverviewFragment
        }
    }
    ${TaskTemplateOverviewFragmentFragmentDoc}
`
export type CreateTaskTemplateMutationFn = ApolloReactCommon.MutationFunction<
    CreateTaskTemplateMutation,
    CreateTaskTemplateMutationVariables
>
export type CreateTaskTemplateComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CreateTaskTemplateMutation, CreateTaskTemplateMutationVariables>,
    'mutation'
>

export const CreateTaskTemplateComponent = (props: CreateTaskTemplateComponentProps) => (
    <ApolloReactComponents.Mutation<CreateTaskTemplateMutation, CreateTaskTemplateMutationVariables>
        mutation={CreateTaskTemplateDocument}
        {...props}
    />
)

export type CreateTaskTemplateMutationResult = ApolloReactCommon.MutationResult<CreateTaskTemplateMutation>
export type CreateTaskTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateTaskTemplateMutation,
    CreateTaskTemplateMutationVariables
>
export const EditTaskDocument = gql`
    mutation EditTask($taskId: Int!, $departmentId: Int!, $fields: EditTaskFieldsInputType!) {
        editTask(taskId: $taskId, fields: $fields) {
            ...TaskDetailFieldsFragment
        }
    }
    ${TaskDetailFieldsFragmentFragmentDoc}
`
export type EditTaskMutationFn = ApolloReactCommon.MutationFunction<EditTaskMutation, EditTaskMutationVariables>
export type EditTaskComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<EditTaskMutation, EditTaskMutationVariables>,
    'mutation'
>

export const EditTaskComponent = (props: EditTaskComponentProps) => (
    <ApolloReactComponents.Mutation<EditTaskMutation, EditTaskMutationVariables>
        mutation={EditTaskDocument}
        {...props}
    />
)

export type EditTaskMutationResult = ApolloReactCommon.MutationResult<EditTaskMutation>
export type EditTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<EditTaskMutation, EditTaskMutationVariables>
export const EditTaskTemplateDocument = gql`
    mutation editTaskTemplate($customerId: Int!, $taskTemplateId: Int!, $fields: EditTaskTemplateFieldsInputType!) {
        editTaskTemplate(customerId: $customerId, taskTemplateId: $taskTemplateId, fields: $fields) {
            ...TaskTemplateOverviewFragment
        }
    }
    ${TaskTemplateOverviewFragmentFragmentDoc}
`
export type EditTaskTemplateMutationFn = ApolloReactCommon.MutationFunction<
    EditTaskTemplateMutation,
    EditTaskTemplateMutationVariables
>
export type EditTaskTemplateComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<EditTaskTemplateMutation, EditTaskTemplateMutationVariables>,
    'mutation'
>

export const EditTaskTemplateComponent = (props: EditTaskTemplateComponentProps) => (
    <ApolloReactComponents.Mutation<EditTaskTemplateMutation, EditTaskTemplateMutationVariables>
        mutation={EditTaskTemplateDocument}
        {...props}
    />
)

export type EditTaskTemplateMutationResult = ApolloReactCommon.MutationResult<EditTaskTemplateMutation>
export type EditTaskTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditTaskTemplateMutation,
    EditTaskTemplateMutationVariables
>
export const CustomerTaskTemplatesDocument = gql`
    query customerTaskTemplates($skip: Int, $take: Int, $customerId: Int!, $customerFrameworkIds: [Int!]) {
        customerTaskTemplates(
            skip: $skip
            take: $take
            customerId: $customerId
            customerFrameworkIds: $customerFrameworkIds
        ) {
            count
            totalCount
            hasNextPage
            nodes {
                ... on TaskTemplateType {
                    __typename
                    id
                    name
                    description
                    tags {
                        id
                        name
                    }
                    subTaskTemplates {
                        id
                        name
                        description
                        tags {
                            id
                            name
                        }
                    }
                }
                ... on ConsultantTaskTemplateType {
                    __typename
                    ...ConsultantTaskTemplateOverviewFragment
                }
            }
        }
    }
    ${ConsultantTaskTemplateOverviewFragmentFragmentDoc}
`
export type CustomerTaskTemplatesComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<CustomerTaskTemplatesQuery, CustomerTaskTemplatesQueryVariables>,
    'query'
> &
    ({ variables: CustomerTaskTemplatesQueryVariables; skip?: boolean } | { skip: boolean })

export const CustomerTaskTemplatesComponent = (props: CustomerTaskTemplatesComponentProps) => (
    <ApolloReactComponents.Query<CustomerTaskTemplatesQuery, CustomerTaskTemplatesQueryVariables>
        query={CustomerTaskTemplatesDocument}
        {...props}
    />
)

export type CustomerTaskTemplatesQueryResult = ApolloReactCommon.QueryResult<
    CustomerTaskTemplatesQuery,
    CustomerTaskTemplatesQueryVariables
>
export const GetTaskDocument = gql`
    query getTask($taskId: Int!, $departmentId: Int!) {
        task(taskId: $taskId) {
            ...TaskDetailFieldsFragment
        }
    }
    ${TaskDetailFieldsFragmentFragmentDoc}
`
export type GetTaskComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<GetTaskQuery, GetTaskQueryVariables>,
    'query'
> &
    ({ variables: GetTaskQueryVariables; skip?: boolean } | { skip: boolean })

export const GetTaskComponent = (props: GetTaskComponentProps) => (
    <ApolloReactComponents.Query<GetTaskQuery, GetTaskQueryVariables> query={GetTaskDocument} {...props} />
)

export type GetTaskQueryResult = ApolloReactCommon.QueryResult<GetTaskQuery, GetTaskQueryVariables>
export const TaskTemplateDocument = gql`
    query taskTemplate($taskTemplateId: Int!, $customerId: Int!) {
        taskTemplate(taskTemplateId: $taskTemplateId, customerId: $customerId) {
            ...TaskTemplateOverviewFragment
        }
    }
    ${TaskTemplateOverviewFragmentFragmentDoc}
`
export type TaskTemplateComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TaskTemplateQuery, TaskTemplateQueryVariables>,
    'query'
> &
    ({ variables: TaskTemplateQueryVariables; skip?: boolean } | { skip: boolean })

export const TaskTemplateComponent = (props: TaskTemplateComponentProps) => (
    <ApolloReactComponents.Query<TaskTemplateQuery, TaskTemplateQueryVariables>
        query={TaskTemplateDocument}
        {...props}
    />
)

export type TaskTemplateQueryResult = ApolloReactCommon.QueryResult<TaskTemplateQuery, TaskTemplateQueryVariables>
export const TaskTemplatesOverviewDocument = gql`
    query taskTemplatesOverview($customerId: Int!, $take: Int, $skip: Int) {
        taskTemplates(customerId: $customerId, take: $take, skip: $skip) {
            count
            totalCount
            hasNextPage
            nodes {
                ...TaskTemplateOverviewFragment
            }
        }
    }
    ${TaskTemplateOverviewFragmentFragmentDoc}
`
export type TaskTemplatesOverviewComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TaskTemplatesOverviewQuery, TaskTemplatesOverviewQueryVariables>,
    'query'
> &
    ({ variables: TaskTemplatesOverviewQueryVariables; skip?: boolean } | { skip: boolean })

export const TaskTemplatesOverviewComponent = (props: TaskTemplatesOverviewComponentProps) => (
    <ApolloReactComponents.Query<TaskTemplatesOverviewQuery, TaskTemplatesOverviewQueryVariables>
        query={TaskTemplatesOverviewDocument}
        {...props}
    />
)

export type TaskTemplatesOverviewQueryResult = ApolloReactCommon.QueryResult<
    TaskTemplatesOverviewQuery,
    TaskTemplatesOverviewQueryVariables
>
export const CreateTasksExportDocument = gql`
    mutation createTasksExport($filters: TaskFiltersInputType) {
        createTasksExport(filters: $filters) {
            token
            filename
        }
    }
`
export type CreateTasksExportMutationFn = ApolloReactCommon.MutationFunction<
    CreateTasksExportMutation,
    CreateTasksExportMutationVariables
>
export type CreateTasksExportComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CreateTasksExportMutation, CreateTasksExportMutationVariables>,
    'mutation'
>

export const CreateTasksExportComponent = (props: CreateTasksExportComponentProps) => (
    <ApolloReactComponents.Mutation<CreateTasksExportMutation, CreateTasksExportMutationVariables>
        mutation={CreateTasksExportDocument}
        {...props}
    />
)

export type CreateTasksExportMutationResult = ApolloReactCommon.MutationResult<CreateTasksExportMutation>
export type CreateTasksExportMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateTasksExportMutation,
    CreateTasksExportMutationVariables
>
export const GetTaskLogsDocument = gql`
    query getTaskLogs($taskId: Int!) {
        taskLogs(taskId: $taskId) {
            id
            type
            metaData
            occuredOn
            user {
                id
                profile {
                    id
                    fullName
                }
            }
        }
    }
`
export type GetTaskLogsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<GetTaskLogsQuery, GetTaskLogsQueryVariables>,
    'query'
> &
    ({ variables: GetTaskLogsQueryVariables; skip?: boolean } | { skip: boolean })

export const GetTaskLogsComponent = (props: GetTaskLogsComponentProps) => (
    <ApolloReactComponents.Query<GetTaskLogsQuery, GetTaskLogsQueryVariables> query={GetTaskLogsDocument} {...props} />
)

export type GetTaskLogsQueryResult = ApolloReactCommon.QueryResult<GetTaskLogsQuery, GetTaskLogsQueryVariables>
export const CreateNonApplicableTopicsExportDocument = gql`
    mutation createNonApplicableTopicsExport($filters: TopicFilters, $departmentId: Int!) {
        createNonApplicableTopicsExport(filters: $filters, departmentId: $departmentId) {
            token
            filename
        }
    }
`
export type CreateNonApplicableTopicsExportMutationFn = ApolloReactCommon.MutationFunction<
    CreateNonApplicableTopicsExportMutation,
    CreateNonApplicableTopicsExportMutationVariables
>
export type CreateNonApplicableTopicsExportComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        CreateNonApplicableTopicsExportMutation,
        CreateNonApplicableTopicsExportMutationVariables
    >,
    'mutation'
>

export const CreateNonApplicableTopicsExportComponent = (props: CreateNonApplicableTopicsExportComponentProps) => (
    <ApolloReactComponents.Mutation<
        CreateNonApplicableTopicsExportMutation,
        CreateNonApplicableTopicsExportMutationVariables
    >
        mutation={CreateNonApplicableTopicsExportDocument}
        {...props}
    />
)

export type CreateNonApplicableTopicsExportMutationResult =
    ApolloReactCommon.MutationResult<CreateNonApplicableTopicsExportMutation>
export type CreateNonApplicableTopicsExportMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateNonApplicableTopicsExportMutation,
    CreateNonApplicableTopicsExportMutationVariables
>
export const ThemesForSelectDocument = gql`
    query themesForSelect($skip: Int, $take: Int, $filters: ThemeFilters, $sort: ThemeSort, $customerSlug: String) {
        themesForSelect: themes(filters: $filters, skip: $skip, take: $take, sort: $sort, customerSlug: $customerSlug) {
            hasNextPage
            nodes {
                id
                name
            }
        }
    }
`
export type ThemesForSelectComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<ThemesForSelectQuery, ThemesForSelectQueryVariables>,
    'query'
>

export const ThemesForSelectComponent = (props: ThemesForSelectComponentProps) => (
    <ApolloReactComponents.Query<ThemesForSelectQuery, ThemesForSelectQueryVariables>
        query={ThemesForSelectDocument}
        {...props}
    />
)

export type ThemesForSelectQueryResult = ApolloReactCommon.QueryResult<
    ThemesForSelectQuery,
    ThemesForSelectQueryVariables
>
export const ThemesDetailCardListDocument = gql`
    query themesDetailCardList(
        $skip: Int
        $take: Int
        $customerSlug: String!
        $departmentId: Int!
        $customerFrameworkIds: [Int!]!
        $themeId: Int!
        $filters: TopicsForThemeFilters
    ) {
        topicsForTheme(
            skip: $skip
            take: $take
            customerSlug: $customerSlug
            departmentId: $departmentId
            customerFrameworkIds: $customerFrameworkIds
            themeId: $themeId
            filters: $filters
        ) {
            hasNextPage
            totalCount
            nodes {
                id
                name
                isPinned
                isApplicable
                hasCompliance
                nonApplicable {
                    id
                    reason
                }
                highestRisk(departmentId: $departmentId) {
                    id
                    name
                    severity
                    severityBruto
                    isAccepted
                }
                customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                    ...CustomerFrameworkFields
                }
                departmentTopicData(departmentId: $departmentId) {
                    id
                    topicAssessmentDesignMetadata {
                        ...DepartmentTopicDataMetadataFragment
                    }
                    topicAssessmentEffectivenessMetadata {
                        ...DepartmentTopicDataMetadataFragment
                    }
                }
            }
        }
    }
    ${CustomerFrameworkFieldsFragmentDoc}
    ${DepartmentTopicDataMetadataFragmentFragmentDoc}
`
export type ThemesDetailCardListComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<ThemesDetailCardListQuery, ThemesDetailCardListQueryVariables>,
    'query'
> &
    ({ variables: ThemesDetailCardListQueryVariables; skip?: boolean } | { skip: boolean })

export const ThemesDetailCardListComponent = (props: ThemesDetailCardListComponentProps) => (
    <ApolloReactComponents.Query<ThemesDetailCardListQuery, ThemesDetailCardListQueryVariables>
        query={ThemesDetailCardListDocument}
        {...props}
    />
)

export type ThemesDetailCardListQueryResult = ApolloReactCommon.QueryResult<
    ThemesDetailCardListQuery,
    ThemesDetailCardListQueryVariables
>
export const ListThemesDocument = gql`
    query listThemes(
        $skip: Int
        $take: Int
        $filters: ThemeFilters
        $customerSlug: String
        $departmentId: Int!
        $customerFrameworkIds: [Int!]
    ) {
        themes(skip: $skip, take: $take, filters: $filters, customerSlug: $customerSlug) {
            hasNextPage
            totalCount
            nodes {
                id
                name
                isPinned
                topicAssessmentCoverage(departmentId: $departmentId, customerFrameworkIds: $customerFrameworkIds) {
                    design {
                        ...ThemeTopicAssessmentCoverageScoresFragment
                    }
                    effectiveness {
                        ...ThemeTopicAssessmentCoverageScoresFragment
                    }
                }
                customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                    ...CustomerFrameworkFields
                }
            }
        }
    }
    ${ThemeTopicAssessmentCoverageScoresFragmentFragmentDoc}
    ${CustomerFrameworkFieldsFragmentDoc}
`
export type ListThemesComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<ListThemesQuery, ListThemesQueryVariables>,
    'query'
> &
    ({ variables: ListThemesQueryVariables; skip?: boolean } | { skip: boolean })

export const ListThemesComponent = (props: ListThemesComponentProps) => (
    <ApolloReactComponents.Query<ListThemesQuery, ListThemesQueryVariables> query={ListThemesDocument} {...props} />
)

export type ListThemesQueryResult = ApolloReactCommon.QueryResult<ListThemesQuery, ListThemesQueryVariables>
export const AssessmentsForTopicDocument = gql`
    query assessmentsForTopic($topicId: Int!, $departmentId: Int!) {
        topic(id: $topicId) {
            id
            assessments(departmentId: $departmentId) {
                id
                name
            }
            departmentTopicData(departmentId: $departmentId) {
                id
                topicAssessmentDesignMetadata {
                    linkedNoteCount
                    linkedControlMeasureCount
                }
                topicAssessmentEffectivenessMetadata {
                    linkedNoteCount
                    linkedControlMeasureCount
                }
            }
        }
    }
`
export type AssessmentsForTopicComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<AssessmentsForTopicQuery, AssessmentsForTopicQueryVariables>,
    'query'
> &
    ({ variables: AssessmentsForTopicQueryVariables; skip?: boolean } | { skip: boolean })

export const AssessmentsForTopicComponent = (props: AssessmentsForTopicComponentProps) => (
    <ApolloReactComponents.Query<AssessmentsForTopicQuery, AssessmentsForTopicQueryVariables>
        query={AssessmentsForTopicDocument}
        {...props}
    />
)

export type AssessmentsForTopicQueryResult = ApolloReactCommon.QueryResult<
    AssessmentsForTopicQuery,
    AssessmentsForTopicQueryVariables
>
export const CreateTopicAssessmentDocument = gql`
    mutation createTopicAssessment(
        $topicId: Int!
        $departmentId: Int!
        $linkedItemId: Int!
        $linkedItemType: TopicAssessmentDELinkedItemType!
        $designOrEffectiveness: TopicAssessmentDesignOrEffectiveNessType!
        $documents: [Upload]
        $description: String
        $grade: TopicAssessmentGradeType
    ) {
        createTopicAssessment(
            topicId: $topicId
            departmentId: $departmentId
            linkedItemId: $linkedItemId
            linkedItemType: $linkedItemType
            designOrEffectiveness: $designOrEffectiveness
            documents: $documents
            description: $description
            grade: $grade
        ) {
            id
            topicControlMeasures(departmentId: $departmentId) {
                id
                assessments {
                    id
                    grade
                    description
                    topicAssessmentDesignOrEffectiveNessType
                    updatedAt
                    monitoringReport {
                        id
                        name
                    }
                    topicAssessmentDocuments {
                        id
                    }
                }
            }
            topicNotes(departmentId: $departmentId) {
                id
                assessments {
                    id
                    grade
                    description
                    topicAssessmentDesignOrEffectiveNessType
                    updatedAt
                    monitoringReport {
                        id
                        name
                    }
                    topicAssessmentDocuments {
                        id
                    }
                }
            }
        }
    }
`
export type CreateTopicAssessmentMutationFn = ApolloReactCommon.MutationFunction<
    CreateTopicAssessmentMutation,
    CreateTopicAssessmentMutationVariables
>
export type CreateTopicAssessmentComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        CreateTopicAssessmentMutation,
        CreateTopicAssessmentMutationVariables
    >,
    'mutation'
>

export const CreateTopicAssessmentComponent = (props: CreateTopicAssessmentComponentProps) => (
    <ApolloReactComponents.Mutation<CreateTopicAssessmentMutation, CreateTopicAssessmentMutationVariables>
        mutation={CreateTopicAssessmentDocument}
        {...props}
    />
)

export type CreateTopicAssessmentMutationResult = ApolloReactCommon.MutationResult<CreateTopicAssessmentMutation>
export type CreateTopicAssessmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateTopicAssessmentMutation,
    CreateTopicAssessmentMutationVariables
>
export const CreateTopicNoteDocument = gql`
    mutation createTopicNote($topicId: Int!, $departmentId: Int!, $fields: CreateTopicNoteFieldsType) {
        createTopicNote(topicId: $topicId, departmentId: $departmentId, fields: $fields) {
            id
            title
            description
            topicNoteDocuments {
                id
                file {
                    id
                    name
                    path
                    mimetype
                }
            }
        }
    }
`
export type CreateTopicNoteMutationFn = ApolloReactCommon.MutationFunction<
    CreateTopicNoteMutation,
    CreateTopicNoteMutationVariables
>
export type CreateTopicNoteComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<CreateTopicNoteMutation, CreateTopicNoteMutationVariables>,
    'mutation'
>

export const CreateTopicNoteComponent = (props: CreateTopicNoteComponentProps) => (
    <ApolloReactComponents.Mutation<CreateTopicNoteMutation, CreateTopicNoteMutationVariables>
        mutation={CreateTopicNoteDocument}
        {...props}
    />
)

export type CreateTopicNoteMutationResult = ApolloReactCommon.MutationResult<CreateTopicNoteMutation>
export type CreateTopicNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
    CreateTopicNoteMutation,
    CreateTopicNoteMutationVariables
>
export const DeleteTopicNoteDocument = gql`
    mutation deleteTopicNote($topicId: Int!, $topicNoteId: Int!, $departmentId: Int!) {
        deleteTopicNote(topicId: $topicId, topicNoteId: $topicNoteId, departmentId: $departmentId) {
            id
            topicNotes(departmentId: $departmentId) {
                id
            }
        }
    }
`
export type DeleteTopicNoteMutationFn = ApolloReactCommon.MutationFunction<
    DeleteTopicNoteMutation,
    DeleteTopicNoteMutationVariables
>
export type DeleteTopicNoteComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<DeleteTopicNoteMutation, DeleteTopicNoteMutationVariables>,
    'mutation'
>

export const DeleteTopicNoteComponent = (props: DeleteTopicNoteComponentProps) => (
    <ApolloReactComponents.Mutation<DeleteTopicNoteMutation, DeleteTopicNoteMutationVariables>
        mutation={DeleteTopicNoteDocument}
        {...props}
    />
)

export type DeleteTopicNoteMutationResult = ApolloReactCommon.MutationResult<DeleteTopicNoteMutation>
export type DeleteTopicNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
    DeleteTopicNoteMutation,
    DeleteTopicNoteMutationVariables
>
export const EditTopicAssessmentDocument = gql`
    mutation editTopicAssessment(
        $topicAssessmentId: Int!
        $departmentId: Int!
        $linkedItemId: Int!
        $fields: EditTopicAssessmentFieldsType
    ) {
        editTopicAssessment(
            topicAssessmentId: $topicAssessmentId
            linkedItemId: $linkedItemId
            departmentId: $departmentId
            fields: $fields
        ) {
            id
            topicControlMeasures(departmentId: $departmentId) {
                id
                assessments {
                    id
                    grade
                    description
                    topicAssessmentDesignOrEffectiveNessType
                    updatedAt
                    monitoringReport {
                        id
                        name
                    }
                    topicAssessmentDocuments {
                        id
                    }
                }
            }
            topicNotes(departmentId: $departmentId) {
                id
                assessments {
                    id
                    grade
                    description
                    topicAssessmentDesignOrEffectiveNessType
                    updatedAt
                    monitoringReport {
                        id
                        name
                    }
                    topicAssessmentDocuments {
                        id
                    }
                }
            }
        }
    }
`
export type EditTopicAssessmentMutationFn = ApolloReactCommon.MutationFunction<
    EditTopicAssessmentMutation,
    EditTopicAssessmentMutationVariables
>
export type EditTopicAssessmentComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<EditTopicAssessmentMutation, EditTopicAssessmentMutationVariables>,
    'mutation'
>

export const EditTopicAssessmentComponent = (props: EditTopicAssessmentComponentProps) => (
    <ApolloReactComponents.Mutation<EditTopicAssessmentMutation, EditTopicAssessmentMutationVariables>
        mutation={EditTopicAssessmentDocument}
        {...props}
    />
)

export type EditTopicAssessmentMutationResult = ApolloReactCommon.MutationResult<EditTopicAssessmentMutation>
export type EditTopicAssessmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditTopicAssessmentMutation,
    EditTopicAssessmentMutationVariables
>
export const EditTopicNoteDocument = gql`
    mutation editTopicNote($topicNoteId: Int!, $departmentId: Int!, $fields: EditTopicNoteFieldsType) {
        editTopicNote(topicNoteId: $topicNoteId, departmentId: $departmentId, fields: $fields) {
            id
            title
            description
            topicNoteDocuments {
                id
                file {
                    id
                    name
                    path
                    mimetype
                }
            }
        }
    }
`
export type EditTopicNoteMutationFn = ApolloReactCommon.MutationFunction<
    EditTopicNoteMutation,
    EditTopicNoteMutationVariables
>
export type EditTopicNoteComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<EditTopicNoteMutation, EditTopicNoteMutationVariables>,
    'mutation'
>

export const EditTopicNoteComponent = (props: EditTopicNoteComponentProps) => (
    <ApolloReactComponents.Mutation<EditTopicNoteMutation, EditTopicNoteMutationVariables>
        mutation={EditTopicNoteDocument}
        {...props}
    />
)

export type EditTopicNoteMutationResult = ApolloReactCommon.MutationResult<EditTopicNoteMutation>
export type EditTopicNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditTopicNoteMutation,
    EditTopicNoteMutationVariables
>
export const LinkControlsToTopicDocument = gql`
    mutation linkControlsToTopic($topicId: Int!, $controlIds: [Int!]!, $departmentId: Int!) {
        addControlsToTopic(topicId: $topicId, controlIds: $controlIds, departmentId: $departmentId) {
            id
            topicControlMeasures(departmentId: $departmentId) {
                id
                control {
                    id
                    name
                    type
                    revisionDate
                }
                assessments {
                    id
                    grade
                    topicAssessmentDesignOrEffectiveNessType
                    updatedAt
                    topicAssessmentDocuments {
                        id
                    }
                }
                linkedChildTopicControlMeasures {
                    id
                    control {
                        id
                        name
                        type
                    }
                    assessments {
                        id
                        grade
                        topicAssessmentDesignOrEffectiveNessType
                    }
                }
                linkedParentTopicControlMeasures {
                    id
                    control {
                        id
                        name
                        type
                    }
                    assessments {
                        id
                        grade
                        topicAssessmentDesignOrEffectiveNessType
                    }
                }
                articlesAreAll
                articles {
                    id
                }
            }
        }
    }
`
export type LinkControlsToTopicMutationFn = ApolloReactCommon.MutationFunction<
    LinkControlsToTopicMutation,
    LinkControlsToTopicMutationVariables
>
export type LinkControlsToTopicComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<LinkControlsToTopicMutation, LinkControlsToTopicMutationVariables>,
    'mutation'
>

export const LinkControlsToTopicComponent = (props: LinkControlsToTopicComponentProps) => (
    <ApolloReactComponents.Mutation<LinkControlsToTopicMutation, LinkControlsToTopicMutationVariables>
        mutation={LinkControlsToTopicDocument}
        {...props}
    />
)

export type LinkControlsToTopicMutationResult = ApolloReactCommon.MutationResult<LinkControlsToTopicMutation>
export type LinkControlsToTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<
    LinkControlsToTopicMutation,
    LinkControlsToTopicMutationVariables
>
export const RemoveControlFromTopicDocument = gql`
    mutation removeControlFromTopic($topicId: Int!, $controlId: Int!, $departmentId: Int!) {
        removeControlFromTopic(topicId: $topicId, controlId: $controlId, departmentId: $departmentId) {
            id
            topicControlMeasures(departmentId: $departmentId) {
                id
                control {
                    id
                }
            }
        }
    }
`
export type RemoveControlFromTopicMutationFn = ApolloReactCommon.MutationFunction<
    RemoveControlFromTopicMutation,
    RemoveControlFromTopicMutationVariables
>
export type RemoveControlFromTopicComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        RemoveControlFromTopicMutation,
        RemoveControlFromTopicMutationVariables
    >,
    'mutation'
>

export const RemoveControlFromTopicComponent = (props: RemoveControlFromTopicComponentProps) => (
    <ApolloReactComponents.Mutation<RemoveControlFromTopicMutation, RemoveControlFromTopicMutationVariables>
        mutation={RemoveControlFromTopicDocument}
        {...props}
    />
)

export type RemoveControlFromTopicMutationResult = ApolloReactCommon.MutationResult<RemoveControlFromTopicMutation>
export type RemoveControlFromTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<
    RemoveControlFromTopicMutation,
    RemoveControlFromTopicMutationVariables
>
export const SetGradeForLinkedTopicControlsDocument = gql`
    mutation setGradeForLinkedTopicControls(
        $currentTopicControlMeasureId: Int!
        $linkedItemType: TopicAssessmentDesignOrEffectiveNessType!
        $grade: TopicAssessmentGradeType!
        $departmentId: Int!
    ) {
        setGradeForInheritedTopicControlMeasures(
            currentTopicControlMeasureId: $currentTopicControlMeasureId
            linkedItemType: $linkedItemType
            grade: $grade
            departmentId: $departmentId
        ) {
            id
            topicControlMeasures(departmentId: $departmentId) {
                id
                assessments {
                    id
                    grade
                    topicAssessmentDesignOrEffectiveNessType
                    updatedAt
                    topicAssessmentDocuments {
                        id
                    }
                }
                linkedChildTopicControlMeasures {
                    id
                    assessments {
                        id
                        grade
                        updatedAt
                        topicAssessmentDesignOrEffectiveNessType
                    }
                }
                linkedParentTopicControlMeasures {
                    id
                    assessments {
                        id
                        grade
                        updatedAt
                        topicAssessmentDesignOrEffectiveNessType
                    }
                }
            }
        }
    }
`
export type SetGradeForLinkedTopicControlsMutationFn = ApolloReactCommon.MutationFunction<
    SetGradeForLinkedTopicControlsMutation,
    SetGradeForLinkedTopicControlsMutationVariables
>
export type SetGradeForLinkedTopicControlsComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        SetGradeForLinkedTopicControlsMutation,
        SetGradeForLinkedTopicControlsMutationVariables
    >,
    'mutation'
>

export const SetGradeForLinkedTopicControlsComponent = (props: SetGradeForLinkedTopicControlsComponentProps) => (
    <ApolloReactComponents.Mutation<
        SetGradeForLinkedTopicControlsMutation,
        SetGradeForLinkedTopicControlsMutationVariables
    >
        mutation={SetGradeForLinkedTopicControlsDocument}
        {...props}
    />
)

export type SetGradeForLinkedTopicControlsMutationResult =
    ApolloReactCommon.MutationResult<SetGradeForLinkedTopicControlsMutation>
export type SetGradeForLinkedTopicControlsMutationOptions = ApolloReactCommon.BaseMutationOptions<
    SetGradeForLinkedTopicControlsMutation,
    SetGradeForLinkedTopicControlsMutationVariables
>
export const SetTopicDnEArticlesDocument = gql`
    mutation setTopicDnEArticles(
        $topicId: Int!
        $linkedItemId: Int!
        $linkedItemType: TopicAssessmentDELinkedItemType!
        $lawArticleIds: [Int!]!
        $departmentId: Int!
    ) {
        setArticlesForTopicControlMeasureOrNote(
            topicId: $topicId
            linkedItemId: $linkedItemId
            linkedItemType: $linkedItemType
            lawArticleIds: $lawArticleIds
            departmentId: $departmentId
        ) {
            id
            topicControlMeasures(departmentId: $departmentId) {
                id
                articlesAreAll
                articles {
                    id
                }
            }
            topicNotes(departmentId: $departmentId) {
                id
                articlesAreAll
                articles {
                    id
                }
            }
        }
    }
`
export type SetTopicDnEArticlesMutationFn = ApolloReactCommon.MutationFunction<
    SetTopicDnEArticlesMutation,
    SetTopicDnEArticlesMutationVariables
>
export type SetTopicDnEArticlesComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<SetTopicDnEArticlesMutation, SetTopicDnEArticlesMutationVariables>,
    'mutation'
>

export const SetTopicDnEArticlesComponent = (props: SetTopicDnEArticlesComponentProps) => (
    <ApolloReactComponents.Mutation<SetTopicDnEArticlesMutation, SetTopicDnEArticlesMutationVariables>
        mutation={SetTopicDnEArticlesDocument}
        {...props}
    />
)

export type SetTopicDnEArticlesMutationResult = ApolloReactCommon.MutationResult<SetTopicDnEArticlesMutation>
export type SetTopicDnEArticlesMutationOptions = ApolloReactCommon.BaseMutationOptions<
    SetTopicDnEArticlesMutation,
    SetTopicDnEArticlesMutationVariables
>
export const TopicAssessmentDocument = gql`
    query topicAssessment(
        $id: Int!
        $linkedItemId: Int!
        $linkedItemType: TopicAssessmentDELinkedItemType!
        $departmentId: Int!
    ) {
        topicAssessment(
            id: $id
            linkedItemId: $linkedItemId
            linkedItemType: $linkedItemType
            departmentId: $departmentId
        ) {
            id
            grade
            topicAssessmentDesignOrEffectiveNessType
            description
            topicAssessmentDocuments {
                id
                file {
                    id
                    name
                    path
                    mimetype
                }
            }
            monitoringReport {
                id
                name
            }
        }
    }
`
export type TopicAssessmentComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TopicAssessmentQuery, TopicAssessmentQueryVariables>,
    'query'
> &
    ({ variables: TopicAssessmentQueryVariables; skip?: boolean } | { skip: boolean })

export const TopicAssessmentComponent = (props: TopicAssessmentComponentProps) => (
    <ApolloReactComponents.Query<TopicAssessmentQuery, TopicAssessmentQueryVariables>
        query={TopicAssessmentDocument}
        {...props}
    />
)

export type TopicAssessmentQueryResult = ApolloReactCommon.QueryResult<
    TopicAssessmentQuery,
    TopicAssessmentQueryVariables
>
export const TopicControlMeasureArticlesDocument = gql`
    query topicControlMeasureArticles($id: Int!, $departmentId: Int!) {
        topicControlMeasure(id: $id, departmentId: $departmentId) {
            id
            articlesAreAll
            articles {
                id
            }
        }
    }
`
export type TopicControlMeasureArticlesComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<
        TopicControlMeasureArticlesQuery,
        TopicControlMeasureArticlesQueryVariables
    >,
    'query'
> &
    ({ variables: TopicControlMeasureArticlesQueryVariables; skip?: boolean } | { skip: boolean })

export const TopicControlMeasureArticlesComponent = (props: TopicControlMeasureArticlesComponentProps) => (
    <ApolloReactComponents.Query<TopicControlMeasureArticlesQuery, TopicControlMeasureArticlesQueryVariables>
        query={TopicControlMeasureArticlesDocument}
        {...props}
    />
)

export type TopicControlMeasureArticlesQueryResult = ApolloReactCommon.QueryResult<
    TopicControlMeasureArticlesQuery,
    TopicControlMeasureArticlesQueryVariables
>
export const TopicDesignAndEffectivenessSummaryDocument = gql`
    query topicDesignAndEffectivenessSummary($id: Int!, $customerSlug: String, $departmentId: Int!) {
        topic(id: $id, customerSlug: $customerSlug) {
            id
            name
            theme {
                id
                name
            }
            topicControlMeasures(departmentId: $departmentId) {
                id
                control {
                    id
                    name
                    type
                    revisionDate
                }
                assessments {
                    id
                    grade
                    description
                    topicAssessmentDesignOrEffectiveNessType
                    updatedAt
                    monitoringReport {
                        id
                        name
                    }
                    topicAssessmentDocuments {
                        id
                    }
                }
                articles {
                    id
                }
            }
            topicNotes(departmentId: $departmentId) {
                id
                title
                articles {
                    id
                }
                assessments {
                    id
                    grade
                    description
                    topicAssessmentDesignOrEffectiveNessType
                    updatedAt
                    monitoringReport {
                        id
                        name
                    }
                    topicAssessmentDocuments {
                        id
                    }
                }
            }
        }
    }
`
export type TopicDesignAndEffectivenessSummaryComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<
        TopicDesignAndEffectivenessSummaryQuery,
        TopicDesignAndEffectivenessSummaryQueryVariables
    >,
    'query'
> &
    ({ variables: TopicDesignAndEffectivenessSummaryQueryVariables; skip?: boolean } | { skip: boolean })

export const TopicDesignAndEffectivenessSummaryComponent = (
    props: TopicDesignAndEffectivenessSummaryComponentProps
) => (
    <ApolloReactComponents.Query<
        TopicDesignAndEffectivenessSummaryQuery,
        TopicDesignAndEffectivenessSummaryQueryVariables
    >
        query={TopicDesignAndEffectivenessSummaryDocument}
        {...props}
    />
)

export type TopicDesignAndEffectivenessSummaryQueryResult = ApolloReactCommon.QueryResult<
    TopicDesignAndEffectivenessSummaryQuery,
    TopicDesignAndEffectivenessSummaryQueryVariables
>
export const TopicLogsDocument = gql`
    query topicLogs($departmentId: Int!, $linkedItemId: Int!, $linkedItemType: TopicAssessmentDELinkedItemType!) {
        topicLogs(departmentId: $departmentId, linkedItemId: $linkedItemId, linkedItemType: $linkedItemType) {
            id
            type
            metaData
            occuredOn
            user {
                id
                profile {
                    id
                    fullName
                }
            }
        }
    }
`
export type TopicLogsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TopicLogsQuery, TopicLogsQueryVariables>,
    'query'
> &
    ({ variables: TopicLogsQueryVariables; skip?: boolean } | { skip: boolean })

export const TopicLogsComponent = (props: TopicLogsComponentProps) => (
    <ApolloReactComponents.Query<TopicLogsQuery, TopicLogsQueryVariables> query={TopicLogsDocument} {...props} />
)

export type TopicLogsQueryResult = ApolloReactCommon.QueryResult<TopicLogsQuery, TopicLogsQueryVariables>
export const TopicNoteDocument = gql`
    query topicNote($id: Int!, $departmentId: Int!) {
        topicNote(id: $id, departmentId: $departmentId) {
            id
            title
            description
            topicNoteDocuments {
                id
                file {
                    id
                    name
                    path
                    mimetype
                }
            }
        }
    }
`
export type TopicNoteComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TopicNoteQuery, TopicNoteQueryVariables>,
    'query'
> &
    ({ variables: TopicNoteQueryVariables; skip?: boolean } | { skip: boolean })

export const TopicNoteComponent = (props: TopicNoteComponentProps) => (
    <ApolloReactComponents.Query<TopicNoteQuery, TopicNoteQueryVariables> query={TopicNoteDocument} {...props} />
)

export type TopicNoteQueryResult = ApolloReactCommon.QueryResult<TopicNoteQuery, TopicNoteQueryVariables>
export const TopicNoteArticlesDocument = gql`
    query topicNoteArticles($id: Int!, $departmentId: Int!) {
        topicNote(id: $id, departmentId: $departmentId) {
            id
            articlesAreAll
            articles {
                id
            }
        }
    }
`
export type TopicNoteArticlesComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TopicNoteArticlesQuery, TopicNoteArticlesQueryVariables>,
    'query'
> &
    ({ variables: TopicNoteArticlesQueryVariables; skip?: boolean } | { skip: boolean })

export const TopicNoteArticlesComponent = (props: TopicNoteArticlesComponentProps) => (
    <ApolloReactComponents.Query<TopicNoteArticlesQuery, TopicNoteArticlesQueryVariables>
        query={TopicNoteArticlesDocument}
        {...props}
    />
)

export type TopicNoteArticlesQueryResult = ApolloReactCommon.QueryResult<
    TopicNoteArticlesQuery,
    TopicNoteArticlesQueryVariables
>
export const TopicUnassessedArticlesDocument = gql`
    query topicUnassessedArticles($id: Int!, $customerSlug: String, $departmentId: Int!) {
        topic(id: $id, customerSlug: $customerSlug) {
            id
            unassessedArticles(departmentId: $departmentId) {
                id
                expired(topicId: $id) {
                    expiredAt
                    willBeDeletedAt
                }
                abstractLawArticle {
                    id
                    abstractLawId
                }
                attachmentIsLeading
                attachments {
                    id
                    mimetype
                    path
                    filename
                }
                title
            }
        }
    }
`
export type TopicUnassessedArticlesComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TopicUnassessedArticlesQuery, TopicUnassessedArticlesQueryVariables>,
    'query'
> &
    ({ variables: TopicUnassessedArticlesQueryVariables; skip?: boolean } | { skip: boolean })

export const TopicUnassessedArticlesComponent = (props: TopicUnassessedArticlesComponentProps) => (
    <ApolloReactComponents.Query<TopicUnassessedArticlesQuery, TopicUnassessedArticlesQueryVariables>
        query={TopicUnassessedArticlesDocument}
        {...props}
    />
)

export type TopicUnassessedArticlesQueryResult = ApolloReactCommon.QueryResult<
    TopicUnassessedArticlesQuery,
    TopicUnassessedArticlesQueryVariables
>
export const MinimalTopicDocument = gql`
    query minimalTopic($id: Int!, $customerSlug: String, $departmentId: Int) {
        topic(id: $id, customerSlug: $customerSlug) {
            id
            name
            abstract
            customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                ...CustomerFrameworkFields
            }
        }
    }
    ${CustomerFrameworkFieldsFragmentDoc}
`
export type MinimalTopicComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<MinimalTopicQuery, MinimalTopicQueryVariables>,
    'query'
> &
    ({ variables: MinimalTopicQueryVariables; skip?: boolean } | { skip: boolean })

export const MinimalTopicComponent = (props: MinimalTopicComponentProps) => (
    <ApolloReactComponents.Query<MinimalTopicQuery, MinimalTopicQueryVariables>
        query={MinimalTopicDocument}
        {...props}
    />
)

export type MinimalTopicQueryResult = ApolloReactCommon.QueryResult<MinimalTopicQuery, MinimalTopicQueryVariables>
export const TopicDocument = gql`
    query topic($id: Int!, $customerSlug: String, $departmentId: Int) {
        topic(id: $id, customerSlug: $customerSlug) {
            id
            name
            abstract
            customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                ...CustomerFrameworkFields
            }
            theme {
                id
                name
            }
            linkedItems(customerSlug: $customerSlug) {
                ...LinkedItemsFields
            }
        }
    }
    ${CustomerFrameworkFieldsFragmentDoc}
    ${LinkedItemsFieldsFragmentDoc}
`
export type TopicComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TopicQuery, TopicQueryVariables>,
    'query'
> &
    ({ variables: TopicQueryVariables; skip?: boolean } | { skip: boolean })

export const TopicComponent = (props: TopicComponentProps) => (
    <ApolloReactComponents.Query<TopicQuery, TopicQueryVariables> query={TopicDocument} {...props} />
)

export type TopicQueryResult = ApolloReactCommon.QueryResult<TopicQuery, TopicQueryVariables>
export const TopicsDocument = gql`
    query topics(
        $skip: Int
        $take: Int
        $sort: TopicSort
        $filters: TopicFilters
        $customerSlug: String
        $departmentId: Int
    ) {
        topics(skip: $skip, take: $take, sort: $sort, filters: $filters) {
            count
            totalCount
            hasNextPage
            nodes {
                id
                name
                theme {
                    id
                    name
                }
                customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                    ...CustomerFrameworkFields
                }
                departments {
                    id
                    name
                }
            }
        }
    }
    ${CustomerFrameworkFieldsFragmentDoc}
`
export type TopicsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TopicsQuery, TopicsQueryVariables>,
    'query'
>

export const TopicsComponent = (props: TopicsComponentProps) => (
    <ApolloReactComponents.Query<TopicsQuery, TopicsQueryVariables> query={TopicsDocument} {...props} />
)

export type TopicsQueryResult = ApolloReactCommon.QueryResult<TopicsQuery, TopicsQueryVariables>
export const RequestSamlRedirectUrlForEmailDocument = gql`
    mutation requestSAMLRedirectURLForEmail($email: String) {
        requestSAMLRedirectURLForEmail(email: $email) {
            redirectURL
        }
    }
`
export type RequestSamlRedirectUrlForEmailMutationFn = ApolloReactCommon.MutationFunction<
    RequestSamlRedirectUrlForEmailMutation,
    RequestSamlRedirectUrlForEmailMutationVariables
>
export type RequestSamlRedirectUrlForEmailComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        RequestSamlRedirectUrlForEmailMutation,
        RequestSamlRedirectUrlForEmailMutationVariables
    >,
    'mutation'
>

export const RequestSamlRedirectUrlForEmailComponent = (props: RequestSamlRedirectUrlForEmailComponentProps) => (
    <ApolloReactComponents.Mutation<
        RequestSamlRedirectUrlForEmailMutation,
        RequestSamlRedirectUrlForEmailMutationVariables
    >
        mutation={RequestSamlRedirectUrlForEmailDocument}
        {...props}
    />
)

export type RequestSamlRedirectUrlForEmailMutationResult =
    ApolloReactCommon.MutationResult<RequestSamlRedirectUrlForEmailMutation>
export type RequestSamlRedirectUrlForEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
    RequestSamlRedirectUrlForEmailMutation,
    RequestSamlRedirectUrlForEmailMutationVariables
>
export const SetUserMergeRequestResponseDocument = gql`
    mutation setUserMergeRequestResponse($token: String!, $accepted: Boolean!) {
        setUserMergeRequestResponse(token: $token, accepted: $accepted)
    }
`
export type SetUserMergeRequestResponseMutationFn = ApolloReactCommon.MutationFunction<
    SetUserMergeRequestResponseMutation,
    SetUserMergeRequestResponseMutationVariables
>
export type SetUserMergeRequestResponseComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        SetUserMergeRequestResponseMutation,
        SetUserMergeRequestResponseMutationVariables
    >,
    'mutation'
>

export const SetUserMergeRequestResponseComponent = (props: SetUserMergeRequestResponseComponentProps) => (
    <ApolloReactComponents.Mutation<SetUserMergeRequestResponseMutation, SetUserMergeRequestResponseMutationVariables>
        mutation={SetUserMergeRequestResponseDocument}
        {...props}
    />
)

export type SetUserMergeRequestResponseMutationResult =
    ApolloReactCommon.MutationResult<SetUserMergeRequestResponseMutation>
export type SetUserMergeRequestResponseMutationOptions = ApolloReactCommon.BaseMutationOptions<
    SetUserMergeRequestResponseMutation,
    SetUserMergeRequestResponseMutationVariables
>
export const UserMergeRequestDocument = gql`
    query userMergeRequest($token: String!) {
        userMergeRequest(token: $token) {
            id
            mergingUserCustomerNames
            requestedBy {
                id
                profile {
                    id
                    fullName
                }
            }
            baseUser {
                id
                email
                profile {
                    id
                    fullName
                }
            }
        }
    }
`
export type UserMergeRequestComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<UserMergeRequestQuery, UserMergeRequestQueryVariables>,
    'query'
> &
    ({ variables: UserMergeRequestQueryVariables; skip?: boolean } | { skip: boolean })

export const UserMergeRequestComponent = (props: UserMergeRequestComponentProps) => (
    <ApolloReactComponents.Query<UserMergeRequestQuery, UserMergeRequestQueryVariables>
        query={UserMergeRequestDocument}
        {...props}
    />
)

export type UserMergeRequestQueryResult = ApolloReactCommon.QueryResult<
    UserMergeRequestQuery,
    UserMergeRequestQueryVariables
>
export const SwitchCustomerDocument = gql`
    mutation switchCustomer($employeeId: Int!, $token: String!) {
        switchEmployee(employeeId: $employeeId, token: $token)
    }
`
export type SwitchCustomerMutationFn = ApolloReactCommon.MutationFunction<
    SwitchCustomerMutation,
    SwitchCustomerMutationVariables
>
export type SwitchCustomerComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<SwitchCustomerMutation, SwitchCustomerMutationVariables>,
    'mutation'
>

export const SwitchCustomerComponent = (props: SwitchCustomerComponentProps) => (
    <ApolloReactComponents.Mutation<SwitchCustomerMutation, SwitchCustomerMutationVariables>
        mutation={SwitchCustomerDocument}
        {...props}
    />
)

export type SwitchCustomerMutationResult = ApolloReactCommon.MutationResult<SwitchCustomerMutation>
export type SwitchCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
    SwitchCustomerMutation,
    SwitchCustomerMutationVariables
>
export const TopicDesignAndEffectivenessDocument = gql`
    query topicDesignAndEffectiveness($id: Int!, $customerSlug: String, $departmentId: Int!) {
        topic(id: $id, customerSlug: $customerSlug) {
            id
            name
            theme {
                id
                name
            }
            topicControlMeasures(departmentId: $departmentId) {
                __typename
                id
                control {
                    id
                    name
                    type
                    revisionDate
                }
                assessments {
                    id
                    grade
                    description
                    topicAssessmentDesignOrEffectiveNessType
                    updatedAt
                    monitoringReport {
                        id
                        name
                    }
                    topicAssessmentDocuments {
                        id
                    }
                }
                linkedChildTopicControlMeasures {
                    id
                    control {
                        id
                        name
                        type
                    }
                    assessments {
                        id
                        grade
                        topicAssessmentDesignOrEffectiveNessType
                    }
                }
                linkedNestedChildTopicControlMeasures {
                    id
                    control {
                        id
                        name
                        type
                    }
                    assessments {
                        id
                        grade
                        topicAssessmentDesignOrEffectiveNessType
                    }
                }
                linkedParentTopicControlMeasures {
                    id
                    control {
                        id
                        name
                        type
                    }
                    assessments {
                        id
                        grade
                        topicAssessmentDesignOrEffectiveNessType
                    }
                }
                linkedNestedParentTopicControlMeasures {
                    id
                    control {
                        id
                        name
                        type
                    }
                    assessments {
                        id
                        grade
                        topicAssessmentDesignOrEffectiveNessType
                    }
                }
                articlesAreAll
                articles {
                    id
                    expired(topicId: $id) {
                        expiredAt
                        willBeDeletedAt
                    }
                }
            }
            topicNotes(departmentId: $departmentId) {
                __typename
                id
                title
                articlesAreAll
                articles {
                    id
                    expired(topicId: $id) {
                        expiredAt
                        willBeDeletedAt
                    }
                }
                assessments {
                    id
                    grade
                    description
                    topicAssessmentDesignOrEffectiveNessType
                    updatedAt
                    monitoringReport {
                        id
                        name
                    }
                    topicAssessmentDocuments {
                        id
                    }
                }
            }
        }
    }
`
export type TopicDesignAndEffectivenessComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<
        TopicDesignAndEffectivenessQuery,
        TopicDesignAndEffectivenessQueryVariables
    >,
    'query'
> &
    ({ variables: TopicDesignAndEffectivenessQueryVariables; skip?: boolean } | { skip: boolean })

export const TopicDesignAndEffectivenessComponent = (props: TopicDesignAndEffectivenessComponentProps) => (
    <ApolloReactComponents.Query<TopicDesignAndEffectivenessQuery, TopicDesignAndEffectivenessQueryVariables>
        query={TopicDesignAndEffectivenessDocument}
        {...props}
    />
)

export type TopicDesignAndEffectivenessQueryResult = ApolloReactCommon.QueryResult<
    TopicDesignAndEffectivenessQuery,
    TopicDesignAndEffectivenessQueryVariables
>
export const TopicDetailDocument = gql`
    query topicDetail($id: Int!, $customerSlug: String, $departmentId: Int!) {
        topic(id: $id, customerSlug: $customerSlug) {
            __typename
            id
            name
            abstract
            nextTask(departmentId: $departmentId) {
                id
                dueAt
                priority
            }
            highestPrioTask(departmentId: $departmentId) {
                id
                priority
            }
            customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                ...CustomerFrameworkFields
            }
            theme {
                id
                name
            }
            nonApplicable {
                id
                createdAt
                reason
            }
            linkedAlerts {
                id
                name
                publishedAt
                impactLevel(departmentId: $departmentId)
                tasks(departmentId: $departmentId) {
                    id
                    completedAt
                    dueAt
                    ignoredAt
                    employees {
                        id
                        user {
                            id
                            profile {
                                id
                                fullName
                                avatar
                            }
                        }
                    }
                }
            }
            linkedRadarItems {
                id
                name
                itemImpacts {
                    id
                    impact
                    impactedEntity {
                        ... on TopicType {
                            __typename
                            id
                        }
                    }
                }
            }
            departmentTopicData(departmentId: $departmentId) {
                id
                topicAssessmentDesignMetadata {
                    ...DepartmentTopicDataMetadataFragment
                }
                topicAssessmentEffectivenessMetadata {
                    ...DepartmentTopicDataMetadataFragment
                }
            }
            departments {
                id
                name
            }
        }
    }
    ${CustomerFrameworkFieldsFragmentDoc}
    ${DepartmentTopicDataMetadataFragmentFragmentDoc}
`
export type TopicDetailComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TopicDetailQuery, TopicDetailQueryVariables>,
    'query'
> &
    ({ variables: TopicDetailQueryVariables; skip?: boolean } | { skip: boolean })

export const TopicDetailComponent = (props: TopicDetailComponentProps) => (
    <ApolloReactComponents.Query<TopicDetailQuery, TopicDetailQueryVariables> query={TopicDetailDocument} {...props} />
)

export type TopicDetailQueryResult = ApolloReactCommon.QueryResult<TopicDetailQuery, TopicDetailQueryVariables>
export const TopicLinkedItemsDocument = gql`
    query topicLinkedItems(
        $id: Int!
        $customerSlug: String
        $lawArticlesOnly: Boolean
        $getExpiredStatus: Boolean!
        $filterDeletedArticles: Boolean
    ) {
        topic(id: $id, customerSlug: $customerSlug) {
            __typename
            id
            linkedItems(
                customerSlug: $customerSlug
                lawArticlesOnly: $lawArticlesOnly
                filterDeletedArticles: $filterDeletedArticles
            ) {
                type
                entityType
                items {
                    ... on RadarItemType {
                        id
                        name
                        expectedDate
                    }
                    ... on LawArticleType {
                        id
                        title
                        isSingleArticle
                        updatedAt
                        lawId
                        law {
                            id
                            name
                            abstractLawId
                            lawSource {
                                id
                                entryIntoForce
                            }
                        }
                        lawGroupId
                        abstractLawArticleId
                        attachmentIsLeading
                        attachments {
                            id
                            path
                            filename
                            mimetype
                        }
                        expired(topicId: $id) @include(if: $getExpiredStatus) {
                            expiredAt
                            willBeDeletedAt
                        }
                    }
                    ... on TopicType {
                        id
                        name
                    }
                    ... on ConsultationType {
                        id
                        name
                    }
                    ... on NewsItemType {
                        id
                        title
                        publicationDate
                        presentationSetting
                        originalSource
                        customSourceUrl
                        attachments {
                            id
                            path
                            filename
                            mimetype
                        }
                    }
                }
            }
        }
    }
`
export type TopicLinkedItemsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TopicLinkedItemsQuery, TopicLinkedItemsQueryVariables>,
    'query'
> &
    ({ variables: TopicLinkedItemsQueryVariables; skip?: boolean } | { skip: boolean })

export const TopicLinkedItemsComponent = (props: TopicLinkedItemsComponentProps) => (
    <ApolloReactComponents.Query<TopicLinkedItemsQuery, TopicLinkedItemsQueryVariables>
        query={TopicLinkedItemsDocument}
        {...props}
    />
)

export type TopicLinkedItemsQueryResult = ApolloReactCommon.QueryResult<
    TopicLinkedItemsQuery,
    TopicLinkedItemsQueryVariables
>
export const ExportRadarItemsDocument = gql`
    mutation exportRadarItems($filters: RadarItemFilters, $customerSlug: String) {
        exportRadarItems(filters: $filters, customerSlug: $customerSlug) {
            token
            filename
        }
    }
`
export type ExportRadarItemsMutationFn = ApolloReactCommon.MutationFunction<
    ExportRadarItemsMutation,
    ExportRadarItemsMutationVariables
>
export type ExportRadarItemsComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<ExportRadarItemsMutation, ExportRadarItemsMutationVariables>,
    'mutation'
>

export const ExportRadarItemsComponent = (props: ExportRadarItemsComponentProps) => (
    <ApolloReactComponents.Mutation<ExportRadarItemsMutation, ExportRadarItemsMutationVariables>
        mutation={ExportRadarItemsDocument}
        {...props}
    />
)

export type ExportRadarItemsMutationResult = ApolloReactCommon.MutationResult<ExportRadarItemsMutation>
export type ExportRadarItemsMutationOptions = ApolloReactCommon.BaseMutationOptions<
    ExportRadarItemsMutation,
    ExportRadarItemsMutationVariables
>
export const RadarDetailDocument = gql`
    query radarDetail($id: Int!, $customerSlug: String, $departmentId: Int!) {
        radarItem(id: $id, customerSlug: $customerSlug) {
            __typename
            id
            name
            content
            expectedDate
            lastEditorialChangeAt
            isDeleted
            nextTask(departmentId: $departmentId) {
                id
                dueAt
                priority
            }
            highestPrioTask(departmentId: $departmentId) {
                id
                priority
            }
            phase {
                id
                name
            }
            customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                ...CustomerFrameworkFields
            }
            following(departmentId: $departmentId)
            linkedTopics(departmentId: $departmentId) {
                id
                name
            }
            itemImpacts {
                id
                impact
                impactedEntity {
                    ... on TopicType {
                        __typename
                        id
                    }
                }
            }
        }
    }
    ${CustomerFrameworkFieldsFragmentDoc}
`
export type RadarDetailComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<RadarDetailQuery, RadarDetailQueryVariables>,
    'query'
> &
    ({ variables: RadarDetailQueryVariables; skip?: boolean } | { skip: boolean })

export const RadarDetailComponent = (props: RadarDetailComponentProps) => (
    <ApolloReactComponents.Query<RadarDetailQuery, RadarDetailQueryVariables> query={RadarDetailDocument} {...props} />
)

export type RadarDetailQueryResult = ApolloReactCommon.QueryResult<RadarDetailQuery, RadarDetailQueryVariables>
export const RadarItemsDocument = gql`
    query radarItems($filters: RadarItemFilters, $customerSlug: String, $skip: Int, $take: Int, $departmentId: Int!) {
        radarItems(skip: $skip, take: $take, filters: $filters, customerSlug: $customerSlug) {
            hasNextPage
            nodes {
                id
                name
                expectedDate
                impact
                lastEditorialChangeAt
                phase {
                    id
                    order
                    name
                }
                customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                    ...CustomerFrameworkFields
                }
                following(departmentId: $departmentId)
            }
        }
    }
    ${CustomerFrameworkFieldsFragmentDoc}
`
export type RadarItemsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<RadarItemsQuery, RadarItemsQueryVariables>,
    'query'
> &
    ({ variables: RadarItemsQueryVariables; skip?: boolean } | { skip: boolean })

export const RadarItemsComponent = (props: RadarItemsComponentProps) => (
    <ApolloReactComponents.Query<RadarItemsQuery, RadarItemsQueryVariables> query={RadarItemsDocument} {...props} />
)

export type RadarItemsQueryResult = ApolloReactCommon.QueryResult<RadarItemsQuery, RadarItemsQueryVariables>
export const CustomerFrameworkOverviewDocument = gql`
    query customerFrameworkOverview($customerSlug: String) {
        customerFrameworks(customerSlug: $customerSlug, all: true) {
            id
            name
            color
            icon
            framework {
                ...CustomerFrameworkFrameworkFields
            }
        }
    }
    ${CustomerFrameworkFrameworkFieldsFragmentDoc}
`
export type CustomerFrameworkOverviewComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<
        CustomerFrameworkOverviewQuery,
        CustomerFrameworkOverviewQueryVariables
    >,
    'query'
>

export const CustomerFrameworkOverviewComponent = (props: CustomerFrameworkOverviewComponentProps) => (
    <ApolloReactComponents.Query<CustomerFrameworkOverviewQuery, CustomerFrameworkOverviewQueryVariables>
        query={CustomerFrameworkOverviewDocument}
        {...props}
    />
)

export type CustomerFrameworkOverviewQueryResult = ApolloReactCommon.QueryResult<
    CustomerFrameworkOverviewQuery,
    CustomerFrameworkOverviewQueryVariables
>
export const AllDepartmentsDocument = gql`
    query allDepartments($customerSlug: String) {
        allDepartments(customerSlug: $customerSlug) {
            id
            name
            amountOfEmployees
            receiveAlertsForConsultationsEnabled
            departmentFrameworks {
                id
                customerFramework {
                    id
                    idn
                    name
                    color
                    icon
                    framework {
                        ...DepartmentFrameworkFrameworkFields
                    }
                }
            }
        }
        customerFrameworks(customerSlug: $customerSlug, all: true) {
            id
            name
            color
            icon
            framework {
                ...CustomerFrameworkFrameworkFields
            }
        }
    }
    ${DepartmentFrameworkFrameworkFieldsFragmentDoc}
    ${CustomerFrameworkFrameworkFieldsFragmentDoc}
`
export type AllDepartmentsComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<AllDepartmentsQuery, AllDepartmentsQueryVariables>,
    'query'
>

export const AllDepartmentsComponent = (props: AllDepartmentsComponentProps) => (
    <ApolloReactComponents.Query<AllDepartmentsQuery, AllDepartmentsQueryVariables>
        query={AllDepartmentsDocument}
        {...props}
    />
)

export type AllDepartmentsQueryResult = ApolloReactCommon.QueryResult<AllDepartmentsQuery, AllDepartmentsQueryVariables>
export const EmployeeDocument = gql`
    query employee($id: Int!, $customerSlug: String) {
        employee(id: $id, customerSlug: $customerSlug) {
            id
            alertDigestFrequency
            newTaskNotificationEmail
            upcomingTasksDigestFrequency
            myCompletedTasksDigestFrequency
            standardTasksDigestFrequency
            permissions
            operations
            role
            canEditPermissions
            user {
                id
                email
                ssoLoginEnabled
                hasActiveMergeRequest
                isCustomerConsultant
                profile {
                    id
                    fullName
                    firstName
                    lastName
                    phoneNumber
                    avatar
                }
            }
            departments {
                id
                name
            }
            userEmployees {
                id
            }
        }
    }
`
export type EmployeeComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<EmployeeQuery, EmployeeQueryVariables>,
    'query'
> &
    ({ variables: EmployeeQueryVariables; skip?: boolean } | { skip: boolean })

export const EmployeeComponent = (props: EmployeeComponentProps) => (
    <ApolloReactComponents.Query<EmployeeQuery, EmployeeQueryVariables> query={EmployeeDocument} {...props} />
)

export type EmployeeQueryResult = ApolloReactCommon.QueryResult<EmployeeQuery, EmployeeQueryVariables>
export const CheckEmailExistsInCustomerGroupDocument = gql`
    query checkEmailExistsInCustomerGroup($forEmployeeId: Int!, $email: String!) {
        checkEmailExistsInCustomerGroup(forEmployeeId: $forEmployeeId, email: $email)
    }
`
export type CheckEmailExistsInCustomerGroupComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<
        CheckEmailExistsInCustomerGroupQuery,
        CheckEmailExistsInCustomerGroupQueryVariables
    >,
    'query'
> &
    ({ variables: CheckEmailExistsInCustomerGroupQueryVariables; skip?: boolean } | { skip: boolean })

export const CheckEmailExistsInCustomerGroupComponent = (props: CheckEmailExistsInCustomerGroupComponentProps) => (
    <ApolloReactComponents.Query<CheckEmailExistsInCustomerGroupQuery, CheckEmailExistsInCustomerGroupQueryVariables>
        query={CheckEmailExistsInCustomerGroupDocument}
        {...props}
    />
)

export type CheckEmailExistsInCustomerGroupQueryResult = ApolloReactCommon.QueryResult<
    CheckEmailExistsInCustomerGroupQuery,
    CheckEmailExistsInCustomerGroupQueryVariables
>
export const EditEmployeeMutationDocument = gql`
    mutation editEmployeeMutation($id: Int!, $fields: EditEmployeeFieldsInputType!, $customerSlug: String) {
        editEmployee(id: $id, fields: $fields, customerSlug: $customerSlug) {
            id
            alertDigestFrequency
            newTaskNotificationEmail
            upcomingTasksDigestFrequency
            myCompletedTasksDigestFrequency
            standardTasksDigestFrequency
            permissions
            operations
            role
            canEditPermissions
            user {
                id
                email
                ssoLoginEnabled
                hasActiveMergeRequest
                profile {
                    id
                    firstName
                    lastName
                    fullName
                    phoneNumber
                }
            }
            departments {
                id
                name
            }
        }
    }
`
export type EditEmployeeMutationMutationFn = ApolloReactCommon.MutationFunction<
    EditEmployeeMutationMutation,
    EditEmployeeMutationMutationVariables
>
export type EditEmployeeMutationComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<EditEmployeeMutationMutation, EditEmployeeMutationMutationVariables>,
    'mutation'
>

export const EditEmployeeMutationComponent = (props: EditEmployeeMutationComponentProps) => (
    <ApolloReactComponents.Mutation<EditEmployeeMutationMutation, EditEmployeeMutationMutationVariables>
        mutation={EditEmployeeMutationDocument}
        {...props}
    />
)

export type EditEmployeeMutationMutationResult = ApolloReactCommon.MutationResult<EditEmployeeMutationMutation>
export type EditEmployeeMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditEmployeeMutationMutation,
    EditEmployeeMutationMutationVariables
>
export const EditCustomerEnabledNewsSourceDocument = gql`
    mutation editCustomerEnabledNewsSource(
        $newsSourceIdsToDisable: [Int!]!
        $newsSourceIdsToEnable: [Int!]!
        $showFrom: Date
    ) {
        editCustomerEnabledNewsSource(
            newsSourceIdsToDisable: $newsSourceIdsToDisable
            newsSourceIdsToEnable: $newsSourceIdsToEnable
            showFrom: $showFrom
        ) {
            id
            newsSourceIds
        }
    }
`
export type EditCustomerEnabledNewsSourceMutationFn = ApolloReactCommon.MutationFunction<
    EditCustomerEnabledNewsSourceMutation,
    EditCustomerEnabledNewsSourceMutationVariables
>
export type EditCustomerEnabledNewsSourceComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        EditCustomerEnabledNewsSourceMutation,
        EditCustomerEnabledNewsSourceMutationVariables
    >,
    'mutation'
>

export const EditCustomerEnabledNewsSourceComponent = (props: EditCustomerEnabledNewsSourceComponentProps) => (
    <ApolloReactComponents.Mutation<
        EditCustomerEnabledNewsSourceMutation,
        EditCustomerEnabledNewsSourceMutationVariables
    >
        mutation={EditCustomerEnabledNewsSourceDocument}
        {...props}
    />
)

export type EditCustomerEnabledNewsSourceMutationResult =
    ApolloReactCommon.MutationResult<EditCustomerEnabledNewsSourceMutation>
export type EditCustomerEnabledNewsSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditCustomerEnabledNewsSourceMutation,
    EditCustomerEnabledNewsSourceMutationVariables
>
export const NewsGroupsSourcesDocument = gql`
    query newsGroupsSources($skip: Int, $take: Int) {
        newsGroups(skip: $skip, take: $take) {
            totalCount
            hasNextPage
            nodes {
                id
                name
                sources {
                    id
                    name
                    isEnabledByCustomer
                }
            }
        }
    }
`
export type NewsGroupsSourcesComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<NewsGroupsSourcesQuery, NewsGroupsSourcesQueryVariables>,
    'query'
>

export const NewsGroupsSourcesComponent = (props: NewsGroupsSourcesComponentProps) => (
    <ApolloReactComponents.Query<NewsGroupsSourcesQuery, NewsGroupsSourcesQueryVariables>
        query={NewsGroupsSourcesDocument}
        {...props}
    />
)

export type NewsGroupsSourcesQueryResult = ApolloReactCommon.QueryResult<
    NewsGroupsSourcesQuery,
    NewsGroupsSourcesQueryVariables
>
export const CustomerEnabledNewsSourceDocument = gql`
    query customerEnabledNewsSource($customerId: Int!) {
        customerEnabledNewsSource(customerId: $customerId) {
            id
            showFrom
        }
    }
`
export type CustomerEnabledNewsSourceComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<
        CustomerEnabledNewsSourceQuery,
        CustomerEnabledNewsSourceQueryVariables
    >,
    'query'
> &
    ({ variables: CustomerEnabledNewsSourceQueryVariables; skip?: boolean } | { skip: boolean })

export const CustomerEnabledNewsSourceComponent = (props: CustomerEnabledNewsSourceComponentProps) => (
    <ApolloReactComponents.Query<CustomerEnabledNewsSourceQuery, CustomerEnabledNewsSourceQueryVariables>
        query={CustomerEnabledNewsSourceDocument}
        {...props}
    />
)

export type CustomerEnabledNewsSourceQueryResult = ApolloReactCommon.QueryResult<
    CustomerEnabledNewsSourceQuery,
    CustomerEnabledNewsSourceQueryVariables
>
export const CustomerSamlConfigurationDocument = gql`
    query customerSAMLConfiguration {
        customer {
            id
            ssoLoginURL
            ssoLogoutURL
            ssoCertificate
        }
    }
`
export type CustomerSamlConfigurationComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<
        CustomerSamlConfigurationQuery,
        CustomerSamlConfigurationQueryVariables
    >,
    'query'
>

export const CustomerSamlConfigurationComponent = (props: CustomerSamlConfigurationComponentProps) => (
    <ApolloReactComponents.Query<CustomerSamlConfigurationQuery, CustomerSamlConfigurationQueryVariables>
        query={CustomerSamlConfigurationDocument}
        {...props}
    />
)

export type CustomerSamlConfigurationQueryResult = ApolloReactCommon.QueryResult<
    CustomerSamlConfigurationQuery,
    CustomerSamlConfigurationQueryVariables
>
export const EditSamlConfigurationDocument = gql`
    mutation editSAMLConfiguration($fields: CustomerSAMLConfigurationFields!) {
        editSAMLConfiguration(fields: $fields) {
            id
            ssoLoginURL
            ssoLogoutURL
            ssoCertificate
        }
    }
`
export type EditSamlConfigurationMutationFn = ApolloReactCommon.MutationFunction<
    EditSamlConfigurationMutation,
    EditSamlConfigurationMutationVariables
>
export type EditSamlConfigurationComponentProps = Omit<
    ApolloReactComponents.MutationComponentOptions<
        EditSamlConfigurationMutation,
        EditSamlConfigurationMutationVariables
    >,
    'mutation'
>

export const EditSamlConfigurationComponent = (props: EditSamlConfigurationComponentProps) => (
    <ApolloReactComponents.Mutation<EditSamlConfigurationMutation, EditSamlConfigurationMutationVariables>
        mutation={EditSamlConfigurationDocument}
        {...props}
    />
)

export type EditSamlConfigurationMutationResult = ApolloReactCommon.MutationResult<EditSamlConfigurationMutation>
export type EditSamlConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<
    EditSamlConfigurationMutation,
    EditSamlConfigurationMutationVariables
>
export const TasksDocument = gql`
    query tasks($skip: Int, $take: Int, $filters: TaskFiltersInputType, $sort: TaskSortInputType, $departmentId: Int!) {
        tasks(filters: $filters, sort: $sort, skip: $skip, take: $take) {
            count
            totalCount
            hasNextPage
            nodes {
                ...TaskDetailFieldsFragment
            }
        }
    }
    ${TaskDetailFieldsFragmentFragmentDoc}
`
export type TasksComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<TasksQuery, TasksQueryVariables>,
    'query'
> &
    ({ variables: TasksQueryVariables; skip?: boolean } | { skip: boolean })

export const TasksComponent = (props: TasksComponentProps) => (
    <ApolloReactComponents.Query<TasksQuery, TasksQueryVariables> query={TasksDocument} {...props} />
)

export type TasksQueryResult = ApolloReactCommon.QueryResult<TasksQuery, TasksQueryVariables>
export const SessionCustomerDocument = gql`
    query sessionCustomer($slug: String) {
        customer(customerSlug: $slug) {
            id
            name
            slug
        }
    }
`
export type SessionCustomerComponentProps = Omit<
    ApolloReactComponents.QueryComponentOptions<SessionCustomerQuery, SessionCustomerQueryVariables>,
    'query'
>

export const SessionCustomerComponent = (props: SessionCustomerComponentProps) => (
    <ApolloReactComponents.Query<SessionCustomerQuery, SessionCustomerQueryVariables>
        query={SessionCustomerDocument}
        {...props}
    />
)

export type SessionCustomerQueryResult = ApolloReactCommon.QueryResult<
    SessionCustomerQuery,
    SessionCustomerQueryVariables
>
