'import ./EditControlDocumentButton.scss'

import React from 'react'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    onClick?: () => void
    className?: ClassValue
}

export const EditControlDocumentButton: React.SFC<Props> = props => {
    const { onClick, className } = props
    const bem = new BEM('EditControlDocumentButton')

    return (
        <Button
            className={bem.getClassName(className)}
            type={ButtonType.subtle}
            icon={IconType.edit}
            onClick={onClick}
        />
    )
}
