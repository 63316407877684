import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { localize, notification, permissions } from '~/bootstrap'
import { DepartmentAlertDataType, EmployeePermissionEnum } from '~/generated/graphql'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { IgnoreAlerts, IgnoreAlertsMutationFN } from './mutations/IgnoreAlerts'

interface Props {
    alertDatas: DepartmentAlertDataType[]
    onIgnore: () => void
    children: (openModal: () => void) => JSX.Element
}

interface State {
    reason?: string
}

export class IgnoreControlAlertModal extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {}

    private loc = localize.namespaceTranslate(t => t.Customer.InboxControl.IgnoreAlertModal)

    public render() {
        if (!permissions.hasPermission(EmployeePermissionEnum.canArchiveInboxItems)) {
            return null
        }

        const { alertDatas, children } = this.props

        const alertName =
            alertDatas.length === 1
                ? alertDatas[0].alert.name
                : this.loc(t => t.description.middle, { count: alertDatas.length })

        return (
            <IgnoreAlerts>
                {(mutate, { loading }) => {
                    return (
                        <ConfirmModal
                            title={this.loc(t => t.modalTitle)}
                            confirmButtonLabel={localize.translate(t => t.Generic.ignore)}
                            loading={loading}
                            onConfirm={() => this.handleSubmit(mutate)}
                            renderContent={() => (
                                <Column>
                                    <Paragraph>
                                        {this.loc(t => t.description.first)}
                                        <strong>{alertName}</strong>
                                        {this.loc(t => t.description.last)}
                                    </Paragraph>
                                    <Field label={this.loc(t => t.reasonInputLabel)} compact={true}>
                                        <Input
                                            type="textarea"
                                            placeholder={this.loc(t => t.reasonInputPlaceholder)}
                                            name="reason"
                                            onChange={reason => this.setState({ reason: reason || undefined })}
                                        />
                                    </Field>
                                </Column>
                            )}
                        >
                            {children}
                        </ConfirmModal>
                    )
                }}
            </IgnoreAlerts>
        )
    }

    private async handleSubmit(mutate: IgnoreAlertsMutationFN) {
        const { alertDatas, onIgnore } = this.props

        const { reason } = this.state
        const alertIdWithDepartmentIds = alertDatas.map(a => ({ alertId: a.alert.id, departmentId: a.department.id }))

        const response = await mutate({ variables: { alertIdWithDepartmentIds, reason } })

        if (response && response.data && response.data.ignoreAlerts) {
            notification.success(localize.translate(t => t.Generic.successfullyArchived))
            onIgnore()
        }
    }
}
