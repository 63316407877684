import './InboxLinkedItems.scss'
import React from 'react'
import { localize } from '~/bootstrap'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Column } from '~/components/Core/Layout/Column'
import { LinkList } from '~/components/Core/Layout/LinkList'
import { PageDetailMeta, PageDetailMetaItem } from '~/components/Core/Layout/PageDetail/PageDetailMeta'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { LawArticleType, NewsItemSourceType, NewsItemType, RadarItemType, TopicType } from '~/generated/graphql'
import { LinkedItemsTypeMap } from '~/graphql/types/Item'
import { LinkedItem } from '~/graphql/types/LinkedItem'
import { BEM } from '~/services/BEMService'
import { transformItemForLinkList } from '~/utils/linkedItemNaming'
import { Alert } from '~/views/Customer/Inbox/InboxDetailContainer'
import { NewsSourcesLabel } from '../News/NewsSourcesLabel'
import { nl2br } from '~/utils/nl2br'

interface Props {
    alert: Pick<Alert, 'publishedFromCustomerNews' | 'item' | 'editorsNote'>
}

export class InboxLinkedItems extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Inbox.InboxDetailView.InboxDetailMeta)
    private bem = new BEM('InboxLinkedItems')
    private linkedItemType = this.getLinkedItemType()

    public render() {
        const items = [
            this.getPublishedNewsMetaItem(),
            this.getLinkedItemMetaItem(),
            this.getEditorsNoteMetaItem(),
        ].filter(i => !!i) as PageDetailMetaItem[]

        return <PageDetailMeta className={this.bem.getClassName()} items={items} />
    }

    private getPublishedNewsMetaItem(): PageDetailMetaItem | null {
        const news = this.props.alert.publishedFromCustomerNews?.news
        if (!news) {
            return null
        }

        return {
            label: this.loc(t => t.News),
            value: this.renderLinkedItemValue({ type: 'News', entityType: LinkedItemsTypeMap.news, item: news }),
            subValue: this.renderSubValueForNewsItem(news),
        }
    }

    private getLinkedItemMetaItem(): PageDetailMetaItem | null {
        const linkedItem = this.props.alert.item
        if (!linkedItem) {
            return null
        }

        return {
            label: this.loc(t => t[this.linkedItemType!]),
            value: this.renderLinkedItemValue(linkedItem),
            subValue: this.getLinkedItemSubValue(linkedItem),
        }
    }

    private renderLinkedItemValue(linkedItem: LinkedItem) {
        if (!linkedItem) {
            return null
        }

        return <LinkList items={[transformItemForLinkList(linkedItem, linkedItem.item, this.context.customer.slug)]} />
    }

    private getLinkedItemSubValue(linkedItem: LinkedItem) {
        switch (this.linkedItemType) {
            case LinkedItemsTypeMap.news:
                return this.renderSubValueForNewsItem(linkedItem.item)
            case LinkedItemsTypeMap.radarItem:
                return this.renderSubValueForRadarItem(linkedItem.item)
            case LinkedItemsTypeMap.abstractLawArticle:
                return this.renderSubValueForAbstractLawArticleItem(linkedItem.item)
            default:
                throw new Error(`type ${this.linkedItemType} not implemented for sub meta`)
        }
    }

    private renderSubValueForNewsItem(
        item: Pick<NewsItemType, 'id' | 'publicationDate'> & {
            sources?: Pick<NewsItemSourceType, 'id' | 'name'>[] | null
        }
    ) {
        return (
            <Row smallSpacing={true} className={this.bem.getElement('news')}>
                <Paragraph subtle={true} className={this.bem.getElement('news-date')}>
                    {this.loc(t => t.published)}
                    {this.getFormattedDate(item.publicationDate)}
                </Paragraph>
                <Row extraSmallSpacing={true} className={this.bem.getElement('news-sources')}>
                    <Paragraph subtle={true} className={this.bem.getElement('source-label')}>
                        {this.loc(t => t.sources)}
                        <NewsSourcesLabel subtle={true} sources={item.sources || []} />
                    </Paragraph>
                </Row>
            </Row>
        )
    }

    private renderSubValueForRadarItem(item: Pick<RadarItemType, 'id' | 'expectedDate' | 'phase' | 'linkedTopics'>) {
        return (
            <Row className={this.bem.getElement('radar-sub-container')} smallSpacing={true}>
                <Paragraph subtle={true} className={this.bem.getElement('radar-date')}>
                    {this.loc(t => t.expectedImplementation)}
                    {this.getFormattedDate(item.expectedDate)}
                </Paragraph>
                <Row extraSmallSpacing={true} className={this.bem.getElement('radar-phase')}>
                    <Paragraph subtle={true}>
                        {this.loc(t => t.fase)} {item.phase?.name}
                        {!!item.linkedTopics?.length && this.renderRadarLinkedTopics(item.linkedTopics)}
                    </Paragraph>
                </Row>
            </Row>
        )
    }

    private renderSubValueForAbstractLawArticleItem(item: Pick<LawArticleType, 'id' | 'updatedAt'>) {
        return (
            <Paragraph subtle={true}>
                {this.loc(t => t.lastUpdated)}
                {this.getFormattedDate(item.updatedAt)}
            </Paragraph>
        )
    }

    private getLinkedItemType() {
        const { item } = this.props.alert

        if (!item) {
            return
        }

        if (item.entityType) {
            return item.entityType
        }

        return item.type
    }

    private renderRadarLinkedTopics(linkedRadarTopics: Pick<TopicType, 'id' | 'name'>[]) {
        const label = localize.translate(t => t.Entities.Topics, { smart_count: linkedRadarTopics.length })

        return (
            <>
                <Tooltip content={this.renderLinkedTopicsTooltipContent(linkedRadarTopics)}>
                    <Paragraph subtle={true}>{label}</Paragraph>
                </Tooltip>
            </>
        )
    }

    private renderLinkedTopicsTooltipContent(linkedRadarTopics: Pick<TopicType, 'id' | 'name'>[]) {
        return (
            <Column extraSmallSpacing={true}>
                {linkedRadarTopics.map(t => (
                    <Paragraph key={t.id} small={true}>
                        {t.name}
                    </Paragraph>
                ))}
            </Column>
        )
    }

    private getEditorsNoteMetaItem(): PageDetailMetaItem | null {
        const { editorsNote } = this.props.alert
        if (!editorsNote) {
            return null
        }

        return {
            label: this.loc(t => t.editorsNote),
            value: <Paragraph>{nl2br(editorsNote)}</Paragraph>,
            elementName: 'editors-note',
        }
    }

    private getFormattedDate(date?: string | Date | null) {
        if (!date) {
            return '-'
        }

        return localize.dateFormat(new Date(date), { readable: true, noWeekday: true })
    }
}
