import './NewsSourcesEditContainer.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { NewsSourcesEditListItem } from '~/views/Customer/Settings/NewsSources/NewsSourcesItemList/NewsSourcesEditListItem'
import { BEM } from '~/services/BEMService'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Column } from '~/components/Core/Layout/Column'
import { NewsGroupType } from '~/generated/graphql'
import { ExpandableNewsGroupSourcesListHeader } from '~/components/Domain/Settings/NewsSources/ExpandableNewsGroupSourcesListHeader'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Row } from '~/components/Core/Layout/Row'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { DisabledGroupSourceMap } from './NewsSourcesEditView'

interface Props {
    newsGroups: NewsGroupType[]
    showFrom?: Date | null
    onChangeGroup: (checked: boolean, groupId: number) => void
    onChangeSource: (checked: boolean, sourceId: number, groupId: number) => void
    sourcesToDisable: DisabledGroupSourceMap
}

export class NewsSourcesEditContainer extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('NewsSourcesEditContainer')
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.NewsSourcesEditView)

    public render() {
        const { showFrom, onChangeGroup, onChangeSource, sourcesToDisable } = this.props

        return (
            <Column className={this.bem.getClassName()}>
                <Column extraSmallSpacing={true}>
                    <SectionTitle>{this.loc(t => t.sources)}</SectionTitle>
                    <Row fullWidth={true} className={this.bem.getElement('show-from')}>
                        <Paragraph bold={true}>{this.loc(t => t.articlesFrom)}</Paragraph>
                        <Form.DatePicker
                            defaultValue={showFrom ? new Date(showFrom) : undefined}
                            name="showFrom"
                            placeholder={localize.translate(t => t.Generic.dateFrom)}
                        />
                    </Row>
                </Column>
                <ExpandableNewsGroupSourcesListHeader text={this.loc(t => t.source)} />
                {this.props.newsGroups.map(newsGroup => (
                    <NewsSourcesEditListItem
                        key={`list-item-${newsGroup.id}`}
                        newsGroup={newsGroup}
                        onChangeGroup={onChangeGroup}
                        onChangeSource={onChangeSource}
                        sourcesToDisable={sourcesToDisable}
                    />
                ))}
            </Column>
        )
    }
}
