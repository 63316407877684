import React from 'react'
import { localize } from '~/bootstrap'
import { Button } from '~/components/Core/Button/Button'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { IconType } from '~/components/Core/Icon/IconType'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { ConsultantTaskTemplatesInfiniteScrollQuery } from '~/components/Domain/Task/ConsultantTaskTemplate/queries/ConsultantTaskTemplatesInfiniteScrollQuery'
import { CreateTaskTemplateModalContainer } from '~/components/Domain/Task/TaskTemplates/CreateTaskTemplateModalContainer'
import { TaskTemplatesTableContainer } from '~/components/Domain/Task/TaskTemplates/TaskTemplatesTableContainer'

export class ConsultantTaskTemplatesOverview extends React.PureComponent {
    private refetchTaskTemplates: Function | undefined
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.TaskTemplatesOverviewView)

    public render() {
        return (
            <Page>
                <PageHeader title={this.loc(t => t.pageTitle)} actionComponent={this.renderAction()} />
                {this.renderTable()}
            </Page>
        )
    }

    private renderAction = () => {
        return (
            <ModalManager
                render={openModal => (
                    <Row alignRight={true}>
                        <Button rounded={true} icon={IconType.addSmall} onClick={openModal}>
                            {this.loc(t => t.createTemplate)}
                        </Button>
                    </Row>
                )}
                renderModal={closeModal => (
                    <CreateTaskTemplateModalContainer
                        requestClose={closeModal}
                        onCreate={this.handleOnCreate}
                        forConsultantTemplate={true}
                    />
                )}
            />
        )
    }

    private renderTable() {
        return (
            <ConsultantTaskTemplatesInfiniteScrollQuery>
                {({ data, loading, loadingMore, refetch }) => {
                    this.refetchTaskTemplates = refetch

                    return (
                        <TaskTemplatesTableContainer
                            data={data}
                            queryLoading={loading}
                            queryLoadingMore={loadingMore}
                            refetch={this.handleOnCreate}
                            forConsultantTemplate={true}
                        />
                    )
                }}
            </ConsultantTaskTemplatesInfiniteScrollQuery>
        )
    }

    private handleOnCreate = () => {
        if (!this.refetchTaskTemplates) {
            return
        }

        this.refetchTaskTemplates()
    }
}
