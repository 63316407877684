import './ActionButton.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { Button, ButtonType } from '../Button/Button'
import { Tooltip } from '../Feedback/Tooltip/Tooltip'
import { IconType } from '../Icon/IconType'

interface Props {
    icon: IconType
    className?: ClassValue
    loading?: boolean
    disabled?: boolean
    onClick?: () => void
    tooltipContent?: React.ReactNode
    secondary?: boolean
    tertiary?: boolean
}

export class ActionButton extends React.PureComponent<Props> {
    private bem = new BEM('ActionButton', () => ({
        secondary: this.props.secondary,
        tertiary: this.props.tertiary,
    }))

    public render() {
        const { tooltipContent, className } = this.props

        if (tooltipContent) {
            return (
                <Tooltip content={tooltipContent} openOnClick className={this.bem.getClassName(className)}>
                    {this.renderButton()}
                </Tooltip>
            )
        }

        return <div className={this.bem.getClassName(className)}>{this.renderButton()}</div>
    }

    private renderButton() {
        const { icon, loading, disabled, onClick } = this.props

        return (
            <Button
                icon={icon}
                className={this.bem.getElement('button')}
                loading={loading}
                disabled={disabled}
                onClick={onClick}
                type={ButtonType.subtleIcon}
            />
        )
    }
}
