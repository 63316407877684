import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { ActionButton } from '~/components/Core/ActionButton/ActionButton'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Guard } from '~/components/Core/Guard/Guard'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { InboxInternalAlertQuery, InternalAlertStatusState } from '~/generated/graphql'
import { ArchiveInternalAlertModal } from './ArchiveInternalAlertModal'
import { ReturnInternalAlertModal } from './ReturnInternalAlertModal'
import { AcceptInternalAlertModal } from './AcceptInternalAlertModal'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { routes } from '~/views/routes'

interface Props extends RouteComponentProps {
    internalAlert: NonNullable<InboxInternalAlertQuery['internalAlert']>
    refetch: () => void
}

class InternalAlertDetailActionButtonsComponent extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { internalAlert } = this.props
        const canEdit =
            permissions.canAccessInternalAlerts(this.context.activeDepartmentId) &&
            internalAlert.departmentStatus?.state === InternalAlertStatusState.new

        return (
            <Guard condition={canEdit}>
                <Row>
                    {this.renderArchiveButton()}
                    {this.renderQuestionButton()}
                    {this.renderAcceptButton()}
                </Row>
            </Guard>
        )
    }

    private renderArchiveButton() {
        return (
            <ModalManager
                renderModal={closeModal => (
                    <ArchiveInternalAlertModal
                        internalAlert={this.props.internalAlert}
                        requestClose={closeModal}
                        onArchive={this.props.refetch}
                    />
                )}
                render={openModal => (
                    <ActionButton
                        icon={IconType.archive}
                        onClick={openModal}
                        tooltipContent={
                            <Paragraph small emphasis>
                                {localize.translate(t => t.Generic.archive)}
                            </Paragraph>
                        }
                    />
                )}
            />
        )
    }

    private renderQuestionButton() {
        return (
            <ModalManager
                renderModal={closeModal => (
                    <ReturnInternalAlertModal
                        internalAlert={this.props.internalAlert}
                        requestClose={closeModal}
                        onReturn={this.props.refetch}
                    />
                )}
                render={openModal => (
                    <ActionButton
                        icon={IconType.questionmark}
                        onClick={openModal}
                        tooltipContent={
                            <Paragraph small emphasis>
                                {localize.translate(t => t.Customer.Inbox.InboxOverview.modal.sendBack)}
                            </Paragraph>
                        }
                    />
                )}
            />
        )
    }

    private renderAcceptButton() {
        return (
            <ModalManager
                renderModal={closeModal => (
                    <AcceptInternalAlertModal
                        internalAlert={this.props.internalAlert}
                        requestClose={closeModal}
                        onAccept={id =>
                            this.props.history.push(routes.customer(this.context.customer.slug).inbox.detail.view(id))
                        }
                    />
                )}
                render={openModal => (
                    <ActionButton
                        icon={IconType.link}
                        onClick={openModal}
                        tooltipContent={
                            <Paragraph small emphasis>
                                {localize.translate(t => t.Customer.Inbox.InboxOverview.modal.link)}
                            </Paragraph>
                        }
                    />
                )}
            />
        )
    }
}

export const InternalAlertDetailActionButtons = withRouter(InternalAlertDetailActionButtonsComponent)
