import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Page } from '~/components/Core/Layout/Page'
import { MonitoringReportEditFormContainer } from '~/components/Domain/Monitoring/MonitoringReportEditForm/MonitoringReportEditFormContainer'
import { MonitoringReportQuery } from '~/components/Domain/Monitoring/MonitoringReportsDetail/MonitoringReportQuery'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { breadcrumbs } from '~/views/breadcrumbs'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

export class MonitoringRapportEditView extends React.PureComponent<Props, {}> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { id } = this.props.match.params
        const { history } = this.props

        if (!id) {
            return null
        }

        return (
            <Page contained={true}>
                <MonitoringReportQuery monitoringReportId={parseInt(id, 10)}>
                    {monitoringReport => (
                        <React.Fragment>
                            <PageHeader
                                title={monitoringReport.name}
                                breadCrumbs={[
                                    breadcrumbs.customer(this.context.customer.slug).monitoring.index,
                                    breadcrumbs.customer(this.context.customer.slug).monitoring.reports.index,
                                    breadcrumbs
                                        .customer(this.context.customer.slug)
                                        .monitoring.reports.view(monitoringReport.name, monitoringReport.id),
                                ]}
                            />
                            <MonitoringReportEditFormContainer
                                monitoringReport={monitoringReport}
                                onSubmitSuccess={() =>
                                    history.push(
                                        routes
                                            .customer(this.context.customer.slug)
                                            .monitoring.reports.detail.view(monitoringReport.id)
                                    )
                                }
                            />
                        </React.Fragment>
                    )}
                </MonitoringReportQuery>
            </Page>
        )
    }
}
