import React from 'react'

import { AddRiskModal } from './AddRiskModal'
import { EditRiskModal } from './EditRiskModal/EditRiskModal'

interface Props {
    requestClose: () => void
    assessmentId: number
    assessmentSectionId: number
    showDistinctBrutoRiskValues: boolean
    isForBruto?: boolean
    onChangeRisks?: () => void
}

interface State {
    riskId?: number
}

export class AddThenEditRiskModal extends React.PureComponent<Props, State> {
    public state: State = {
        riskId: undefined,
    }

    public render() {
        const { riskId } = this.state
        const {
            requestClose,
            assessmentId,
            assessmentSectionId,
            showDistinctBrutoRiskValues,
            isForBruto,
            onChangeRisks,
        } = this.props

        if (riskId) {
            return (
                <EditRiskModal
                    assessmentId={assessmentId}
                    assessmentSectionId={assessmentSectionId}
                    showDistinctBrutoRiskValues={showDistinctBrutoRiskValues}
                    riskId={riskId}
                    requestClose={requestClose}
                    isForBruto={isForBruto}
                />
            )
        }

        return (
            <AddRiskModal
                assessmentId={assessmentId}
                assessmentSectionId={assessmentSectionId}
                requestClose={requestClose}
                onSubmit={riskId => {
                    this.setState({ riskId })

                    if (onChangeRisks) {
                        onChangeRisks()
                    }
                }}
            />
        )
    }
}
