import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { EditWidgetMutationVariables, EditWidgetDocument, EditWidgetMutation } from '~/generated/graphql'

interface Props {
    children: (
        mutation: MutationFn<EditWidgetMutation, EditWidgetMutationVariables>,
        options: MutationResult
    ) => JSX.Element
}

export class EditWidget extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<EditWidgetMutation, EditWidgetMutationVariables> mutation={EditWidgetDocument}>
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
