import './Checkbox.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'

interface Props<TValue> {
    className?: ClassValue
    name: string
    onChange?: (checked: TValue, name: string) => void
    defaultChecked?: boolean
    checked?: boolean
    label?: string | React.ReactNode
    disabled?: boolean
    loading?: boolean
    indeterminate?: boolean
    value?: TValue
    large?: boolean
    animationDisabled?: boolean
}

interface State {
    isChecked?: boolean
}

export class Checkbox<TValue = boolean> extends React.PureComponent<Props<TValue>, State> {
    private isControlled = typeof this.props.checked === 'boolean'

    private bem = new BEM('Checkbox', () => ({
        'is-checked': this.getCheckedState(),
        'is-indeterminate': this.props.indeterminate,
        'is-loading': this.props.loading,
        'is-disabled': this.props.disabled ? this.props.disabled : this.props.loading,
        large: !!this.props.large,
        'is-animation-disabled': this.props.animationDisabled,
    }))

    constructor(props: Props<TValue>) {
        super(props)

        if (!this.isControlled) {
            this.state = {
                isChecked: !!this.props.defaultChecked,
            }
        }
    }

    public render() {
        const { label, className, name, disabled, loading } = this.props

        return (
            <label className={this.bem.getClassName(className)} onClick={e => e.stopPropagation()}>
                <input
                    onChange={this.handleOnChange}
                    type="checkbox"
                    name={name}
                    id={`input-${name}`}
                    checked={this.getCheckedState()}
                    className={this.bem.getElement('input')}
                    disabled={disabled ? disabled : loading}
                />
                <div className={this.bem.getElement('checkbox')}>
                    <div className={this.bem.getElement('mark-indeterminate')} />
                    <Icon type={IconType.check} className={this.bem.getElement('mark')} />
                </div>
                {label && <div className={this.bem.getElement('label')}>{label}</div>}
                <div className={this.bem.getElement('spinner')}>
                    <Spinner delayed={true} small={true} />
                </div>
            </label>
        )
    }

    private handleOnChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        const { onChange, name, value } = this.props

        const newChecked = event.target.checked

        if (!this.isControlled) {
            this.setState({ isChecked: newChecked })
        }

        if (onChange) {
            onChange(value !== undefined ? (newChecked ? value : null) : (newChecked as any), name)
        }
    }

    private getCheckedState() {
        if (this.isControlled) {
            return this.props.checked
        } else {
            return this.state.isChecked
        }
    }
}
