import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { PaginatableQuery, PaginatableOptions } from '~/components/Core/Pagination/PaginatableQuery'
import { TASKS_QUERY, Task, TasksQueryFilters, TaskQuerySort } from '~/views/Customer/Tasks/TasksQuery/TaskQuery'

interface Props {
    children: (options: PaginatableOptions<Task>) => JSX.Element
    filters?: TasksQueryFilters
    sort?: TaskQuerySort
}

export class TasksPaginatableQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, filters, sort } = this.props
        const departmentIds = filters?.activeDepartmentOnly ? [this.context.activeDepartmentId] : undefined

        const queryFilters = {
            search: filters?.search,
            forEmployeeIds: filters?.forEmployeeIds,
            completedOnly: filters?.completedOnly,
            onlyOverdueTasks: filters?.onlyOverdueTasks,
            forMonitoring: filters?.forMonitoring,
            forLinkedItem: filters?.forLinkedItem,
            tagIds: filters?.tagIds,
            linkedItemInboxFromDate: filters?.linkedItemInboxFromDate,
            linkedItemInboxToDate: filters?.linkedItemInboxToDate,
            departmentIds,
        }

        return (
            <PaginatableQuery
                query={TASKS_QUERY}
                variables={{
                    departmentId: this.context.activeDepartmentId,
                    filters: queryFilters,
                    sort,
                }}
            >
                {options => children(options)}
            </PaginatableQuery>
        )
    }
}
