import React from 'react'
import gql from 'graphql-tag'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { InfiniteScrollQuery, InfiniteScrollOptions } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { DepartmentCustomerFrameworkType } from '~/generated/graphql'

interface Props {
    children: (options: InfiniteScrollOptions<Department>) => React.ReactNode
    filters?: Filters
    forModal?: boolean
}

interface Filters {
    search?: string | null
    customerFrameworkIds?: number[]
    includeIfHasExternalTopic?: boolean
}

export interface Department {
    id: number
    name: string
    amountOfEmployees: number
    departmentFrameworks: {
        id: number
        customerFramework: DepartmentCustomerFrameworkType
    }[]
}

export const TOPICS_QUERY = gql`
    query listDepartments($skip: Int, $take: Int, $customerSlug: String, $filters: DepartmentFilters) {
        listDepartments(skip: $skip, take: $take, customerSlug: $customerSlug, filters: $filters) {
            count
            hasNextPage
            nodes {
                id
                name
                amountOfEmployees
                departmentFrameworks {
                    id
                    customerFramework {
                        id
                        idn
                        name
                        icon
                        color
                    }
                }
            }
        }
    }
`

export class DepartmentsQuery extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, forModal, filters } = this.props
        return (
            <InfiniteScrollQuery<Department>
                take={20}
                query={TOPICS_QUERY}
                variables={{
                    customerSlug: this.context.customer.slug,
                    filters,
                }}
                useWindow={!forModal}
            >
                {options => children(options)}
            </InfiniteScrollQuery>
        )
    }
}
