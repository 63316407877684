import './EditTaskTemplateModalContainer.scss'

import React from 'react'
import { localize, notification } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Column } from '~/components/Core/Layout/Column'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Row } from '~/components/Core/Layout/Row'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { BEM } from '~/services/BEMService'
import { EditTaskTemplate, EditTaskTemplateMutationFN } from '../Mutations/EditTaskTemplate'
import { ConsultantTaskTemplateType, ConsultantTaskTemplateTypeEnum, TaskTemplateType } from '~/generated/graphql'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import {
    EditConsultantTaskTemplate,
    EditConsultantTaskTemplateMutationFN,
} from '../ConsultantTaskTemplate/mutations/EditConsultantTaskTemplate'
import { FrameworkSelect } from '../../Framework/FrameworkSelect'

interface Props {
    requestClose: () => void
    taskTemplate: TaskTemplateType | ConsultantTaskTemplateType
    isSubTask?: boolean
    parentTaskName?: string
    forConsultantTemplate?: boolean
    refetch: () => void
}

type EditMutationFN = EditTaskTemplateMutationFN | EditConsultantTaskTemplateMutationFN

export class EditTaskTemplateModalContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('EditTaskTemplateModalContainer')
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.TaskTemplatesOverviewView.TaskTemplateModal)
    private formLoc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks.TaskFormFields)

    public render() {
        const { forConsultantTemplate } = this.props

        if (forConsultantTemplate) {
            return (
                <EditConsultantTaskTemplate>
                    {(mutate, { loading }) => this.renderModalContent(mutate, loading)}
                </EditConsultantTaskTemplate>
            )
        }

        return <EditTaskTemplate>{(mutate, { loading }) => this.renderModalContent(mutate, loading)}</EditTaskTemplate>
    }

    private renderModalContent(mutate: EditMutationFN, loading: boolean) {
        const { requestClose, isSubTask, taskTemplate, forConsultantTemplate } = this.props

        const title = isSubTask ? this.loc(t => t.editSubTaskTitle) : this.loc(t => t.editTitle)
        const confirmButtonLabel = localize.translate(t => t.Generic.save)
        const defaultFormState = {
            frameworkIds:
                'frameworks' in taskTemplate
                    ? taskTemplate.frameworks.map(f => ({ label: f.name || '', value: f.id }))
                    : [],
            type: 'type' in taskTemplate ? taskTemplate.type : null,
        }

        const path = forConsultantTemplate ? 'editConsultantTaskTemplate' : 'editTaskTemplate'

        return (
            <Form onSubmit={this.handleSubmit(mutate)} defaultState={defaultFormState}>
                <Modal
                    title={title}
                    loading={loading}
                    submitForm={true}
                    confirmButtonLabel={confirmButtonLabel}
                    requestClose={requestClose}
                    className={this.bem.getClassName()}
                >
                    <ErrorMessage path={path} />
                    <Column>
                        {this.renderParentName()}
                        {this.renderForm()}
                    </Column>
                </Modal>
            </Form>
        )
    }

    private renderParentName() {
        const { isSubTask, parentTaskName } = this.props

        if (!isSubTask || !parentTaskName) {
            return
        }

        return (
            <Column smallSpacing={true} className={this.bem.getElement('subtask-name-container')}>
                <Field forInput="parentName" label={this.formLoc(t => t.parentTask)} />
                <Row smallSpacing={true}>
                    <Icon type={IconType.parentTask} />
                    <Paragraph bold={true}>{parentTaskName}</Paragraph>
                </Row>
            </Column>
        )
    }

    private renderForm() {
        const { taskTemplate, forConsultantTemplate, isSubTask } = this.props
        const { name, description } = taskTemplate
        const defaultTags =
            'tags' in taskTemplate && taskTemplate.tags?.map(({ name, id }) => ({ label: name, value: id }))

        return (
            <Column smallSpacing={true}>
                {forConsultantTemplate &&
                    !isSubTask &&
                    this.renderConsultantFields(taskTemplate as ConsultantTaskTemplateType)}
                <Paragraph bold={true}>{this.formLoc(t => t.taak)}</Paragraph>
                <Field forInput="name">
                    <Form.Input name="name" defaultValue={name} placeholder={this.formLoc(t => t.taskPlaceholder)} />
                </Field>
                <Field forInput="description">
                    <Form.Input
                        type="textarea"
                        name="description"
                        defaultValue={description}
                        placeholder={this.formLoc(t => t.descriptionPlaceholder)}
                    />
                </Field>
                {!forConsultantTemplate && (
                    <>
                        <Paragraph bold={true}>{this.formLoc(t => t.tags)}</Paragraph>
                        <Field forInput="tags">
                            <Form.TaskTagsSelect name="tags" defaultValue={defaultTags || undefined} />
                        </Field>
                    </>
                )}
            </Column>
        )
    }

    private handleSubmit = (mutate: EditMutationFN) => async (formState: FormState) => {
        const { taskTemplate, requestClose, forConsultantTemplate, refetch } = this.props

        const customerId = forConsultantTemplate ? undefined : this.context.customer.id
        const taskTemplateId = taskTemplate.id
        const fields = this.getFormFields(formState)

        const response = await mutate({ variables: { customerId: customerId!, taskTemplateId, fields } })

        if (
            response &&
            response.data &&
            ('editTaskTemplate' in response.data || 'editConsultantTaskTemplate' in response.data)
        ) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))

            if (refetch) {
                refetch()
            }

            requestClose()
        }
    }

    private renderConsultantFields(taskTemplate: ConsultantTaskTemplateType) {
        const typeOptions = Object.values(ConsultantTaskTemplateTypeEnum).map(v => ({
            label: this.loc(t => t[v]),
            value: v,
        }))
        const defaultTypeOption = typeOptions.find(t => t.value === taskTemplate.type)
        const defaultFrameworkOptions = taskTemplate.frameworks.map(f => ({ label: f.name || '', value: f.id }))

        return (
            <Row fullWidth={true} flexStart={true} className={this.bem.getElement('consultant-fields-container')}>
                <Column extraSmallSpacing={true}>
                    <Paragraph bold={true}>{this.loc(t => t.type)}</Paragraph>
                    <Field forInput="type">
                        <Form.Select
                            name="type"
                            options={typeOptions}
                            defaultValue={defaultTypeOption ? [defaultTypeOption] : undefined}
                        />
                    </Field>
                </Column>
                <Column extraSmallSpacing={true}>
                    <Paragraph bold={true}>{this.loc(t => t.frameworks)}</Paragraph>
                    <Field forInput="frameworkIds">
                        <FrameworkSelect
                            multi={true}
                            placeholder={this.loc(t => t.frameworksPlaceholder)}
                            defaultSelected={defaultFrameworkOptions}
                        />
                    </Field>
                </Column>
            </Row>
        )
    }

    private getFormFields(formState: FormState) {
        const { isSubTask, forConsultantTemplate } = this.props

        const name = formState.name
        const description = formState.description
        const tags = this.transformTagOptions(formState.tags)

        let type: ConsultantTaskTemplateTypeEnum | undefined
        let frameworkIds: number[] | undefined
        if (forConsultantTemplate && !isSubTask) {
            type = formState.type
            frameworkIds = formState.frameworkIds?.length
                ? formState.frameworkIds.map((f: SelectOption) => f.value)
                : []
        }

        return { name, description, tags, type, frameworkIds }
    }

    private transformTagOptions(tags?: SelectOption[] | null) {
        if (tags === undefined) {
            return
        }

        if (tags === null) {
            return []
        }

        return tags
            .filter(tag => tag.value)
            .map(tag => {
                if (tag.__isNew__ && typeof tag.value === 'string') {
                    return { tagName: tag.value }
                }

                return { tagId: tag.value as number }
            })
    }
}
