import React from 'react'

import { OperationVariables, Query } from 'react-apollo'
import { InfiniteScroll, InfiniteScrollProps } from '~/components/Core/Pagination/InfiniteScroll'
import { getMergerByPath } from '~/utils/mergeByPath'
import { DocumentNode } from 'graphql'
import get from 'lodash-es/get'
import { ApolloQueryResult } from 'apollo-client'

export interface InfiniteScrollOptions<TData = any, TVariables = OperationVariables> {
    data: PaginationResult<TData> | null
    loadingMore: boolean
    loading: boolean
    canFetchMore: boolean
    hasPaginated: boolean
    refetch: (variables?: TVariables) => Promise<ApolloQueryResult<QueryData<TData>>>
}

interface Props<TData = any, TVariables = OperationVariables> extends InfiniteScrollProps {
    query: DocumentNode
    variables?: Omit<TVariables, 'skip' | 'take'>
    children: (options: InfiniteScrollOptions<TData, TVariables>) => React.ReactNode
    path?: string
    take?: number
}

export interface PaginationResult<TData = any> {
    totalCount: number
    hasNextPage: boolean
    nodes: TData[]
}

interface QueryData<TData = any> {
    [query: string]: PaginationResult<TData>
}

export class InfiniteScrollQuery<TData = any, TVariables = any> extends React.PureComponent<Props<TData, TVariables>> {
    private skip: number = 0
    private take: number = this.props.take ? this.props.take : 20
    private canFetchMore: boolean = true
    private fetchingMore: boolean = false
    private paginated: boolean = false

    private queryName: string | undefined = get(this.props.query, 'definitions.0.selectionSet.selections.0.name.value')

    public componentDidMount() {
        if (!this.queryName && !this.props.path) {
            throw new Error('Cannot get query name from query, please specify path property to define the query name')
        }
    }

    public componentDidUpdate() {
        // When the component updates it means the props variable were, for instance when filters or sorting changes on
        // a query. When that happens we want to make sure we reset the pagination.
        this.resetPagination()
    }

    public render() {
        const { variables, query, children, path, ...rest } = this.props

        const queryName = path ? path : (this.queryName as string)

        return (
            <Query<QueryData<TData>>
                query={query}
                notifyOnNetworkStatusChange={true}
                variables={{
                    ...variables,
                    skip: 0,
                    take: this.take,
                }}
            >
                {({ loading, data, fetchMore, refetch }) => {
                    const arrayOfData = (data && get(data, queryName)) || null

                    if (arrayOfData && arrayOfData.hasNextPage === undefined) {
                        throw new Error('hasNextPage is undefined please add this to the paginated query')
                    }

                    this.canFetchMore = !!(arrayOfData && arrayOfData.hasNextPage)

                    return (
                        <InfiniteScroll
                            hideLoadMore={!this.canFetchMore}
                            hasMore={this.canFetchMore}
                            onReachedEnd={() => {
                                if (loading) {
                                    return
                                }

                                if (!this.canFetchMore) {
                                    return
                                }

                                this.skip = this.skip + this.take

                                this.paginated = true
                                this.fetchingMore = true

                                fetchMore({
                                    variables: {
                                        skip: this.skip,
                                        ...variables,
                                    },
                                    updateQuery: (prev, { fetchMoreResult }) => {
                                        if (!fetchMoreResult) {
                                            return prev
                                        }

                                        return getMergerByPath(`${queryName}.nodes`)(prev, fetchMoreResult)
                                    },
                                })
                            }}
                            {...rest}
                        >
                            {children({
                                data: arrayOfData,
                                loading: !this.fetchingMore && loading,
                                loadingMore: this.fetchingMore && loading,
                                canFetchMore: this.canFetchMore,
                                refetch: refetch,
                                hasPaginated: this.paginated,
                            })}
                        </InfiniteScroll>
                    )
                }}
            </Query>
        )
    }

    private resetPagination() {
        this.skip = 0
        this.canFetchMore = true
        this.fetchingMore = false
    }
}
