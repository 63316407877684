import './RiskSettingsFields.scss'

import React, { Component } from 'react'
import { localize } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { RiskSettingsIndicatorLabel } from '../RiskSettingsIndicatorLabel'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { ClassValue, BEM } from '~/services/BEMService'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { RiskSettingsField } from '~/components/Domain/Settings/Control/ControlRiskFields/RiskSettingsFieldsContainer'

interface Props {
    className?: ClassValue
    riskFields: RiskSettingsField[]
    isEditable?: boolean
}

export class RiskSettingsFields extends Component<Props> {
    private bem = new BEM('RiskSettingsFields')
    private loc = localize.namespaceTranslate(t => t.Settings.Attributes)

    public render() {
        const { riskFields, className } = this.props
        return <FieldSet className={this.bem.getClassName(className)}>{riskFields.map(this.renderField)}</FieldSet>
    }

    private renderField = (riskField: RiskSettingsField) => {
        const { isEditable } = this.props

        if (isEditable) {
            return (
                <Field
                    forInput={riskField.type}
                    label={
                        <RiskSettingsIndicatorLabel
                            riskType={riskField.type}
                            title={this.getTitleTranslation(riskField)}
                        />
                    }
                    labelString={this.getTitleTranslation(riskField)}
                >
                    <Form.Input
                        defaultValue={riskField.description}
                        type={'textarea'}
                        placeholder={this.loc(t => t.ControlSettings.RiskHeatmap.placeholder, {
                            riskType: this.getTitleTranslation(riskField).toLocaleLowerCase(),
                        })}
                        name={riskField.type}
                    />
                </Field>
            )
        }

        return (
            <Field
                key={riskField.type}
                label={
                    <RiskSettingsIndicatorLabel riskType={riskField.type} title={this.getTitleTranslation(riskField)} />
                }
                labelString={riskField.type}
            >
                {riskField.description ? (
                    <Paragraph preserveLine={true}>{riskField.description}</Paragraph>
                ) : (
                    <Paragraph subtle={true}>{this.loc(t => t.noDescription)}</Paragraph>
                )}
            </Field>
        )
    }

    private getTitleTranslation(riskField: RiskSettingsField) {
        return this.loc(t => t.ControlSettings.RiskHeatmap.Fields[riskField.type])
    }
}
