import './SignalingDetailView.scss'
import React from 'react'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { RouteComponentProps } from 'react-router-dom'
import { Column } from '~/components/Core/Layout/Column'
import { breadcrumbs } from '~/views/breadcrumbs'
import { SignalingDetailQuery } from '~/components/Domain/Signaling/SignalingDetailQuery'
import { NewsItemTag, NewsItemTagEnum } from '~/components/Domain/Signaling/NewsItemTag'
import { BEM } from '~/services/BEMService'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize, notification, permissions } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'
import { PageDetail } from '~/components/Core/Layout/PageDetail/PageDetail'
import { PageDetailContent } from '~/components/Core/Layout/PageDetail/PageDetailContent'
import {
    NewsItemType,
    NewsItemArchivedStatusType,
    GeneratedSummaryParentTypeEnum,
    EmployeePermissionEnum,
} from '~/generated/graphql'
import { Markdown } from '~/components/Core/Text/Markdown'
import { ArchiveNewsItemButtonContainer } from '~/components/Domain/Signaling/ArchiveNewsItems/ArchiveNewsItemButtonContainer'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { RelevantModal } from './RelevantModal/RelevantModal'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import {
    UnarchiveNewsItemsMutation,
    UnarchiveNewsItemsMutationFN,
} from '~/components/Domain/Signaling/UnarchiveNewsItems/UnarchiveNewsItemsMutation'
import { AttachmentList } from '~/components/Core/DataDisplay/AttachmentList/AttachmentList'
import { Attachment } from '~/graphql/types/Attachment'
import { EditPublishedCustomerNewsModal } from '~/components/Domain/Signaling/EditPublishedCustomerNews/EditPublishedCustomerNewsModal'
import { NewsUrlButton } from '~/components/Domain/News/NewsUrlButton'
import { Page } from '~/components/Core/Layout/Page'
import { AiSummaryContainer } from '~/components/Domain/Signaling/AiSummary/AiSummaryContainer'
import { Guard } from '~/components/Core/Guard/Guard'
import { InternalAlertTimelineLog } from '~/components/Domain/Signaling/InternalAlert/InternalAlertTimelineLog'
import { InternalAlertLogsQuery } from '~/components/Domain/Signaling/InternalAlert/InteralAlertLogsQuery'
import { InternalAlertDepartmentLabelTagContainer } from '~/components/Domain/Signaling/InternalAlert/InternalAlertDepartmentLabelTagContainer'
import { nl2br } from '~/utils/nl2br'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {}

export class SignalingDetailView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('SignalingDetailView')
    private loc = localize.namespaceTranslate(t => t.Customer.Signaling.SignalingDetail)
    private refetch?: () => void
    private refetchLogs?: () => void

    public render() {
        const { id } = this.props.match.params
        const customer = this.context.customer.slug

        if (!id) {
            return null
        }

        return (
            <SignalingDetailQuery newsItemId={parseInt(id, 10)}>
                {(newsItem, { refetch }) => {
                    const { title, abstract, content } = newsItem
                    this.refetch = () => refetch()

                    return (
                        <Page className={this.bem.getClassName()}>
                            <PageHeader
                                title={newsItem.title}
                                actionButtons={this.renderMeta(newsItem)}
                                breadCrumbs={[
                                    breadcrumbs.customer(customer).signaling.index,
                                    breadcrumbs.customer(this.context.customer.slug).signaling.detail(id),
                                ]}
                            />
                            <PageDetail renderSidebar={() => this.renderSidebar(title, newsItem)}>
                                <AiSummaryContainer
                                    parentId={newsItem.id}
                                    parentType={GeneratedSummaryParentTypeEnum.news}
                                />
                                <PageDetailContent>
                                    <Markdown source={abstract} />
                                    <Markdown source={content} />
                                    {newsItem.attachments && newsItem.attachments.length > 0 && (
                                        <AttachmentList attachments={newsItem.attachments as Attachment[]} />
                                    )}
                                </PageDetailContent>
                            </PageDetail>
                        </Page>
                    )
                }}
            </SignalingDetailQuery>
        )
    }

    private renderMeta(newsItem: NewsItemType) {
        return (
            <Column>
                <Row wrap={true}>
                    {!!newsItem.internalAlert?.statuses?.length && (
                        <InternalAlertDepartmentLabelTagContainer
                            internalAlertStatuses={newsItem.internalAlert.statuses}
                        />
                    )}
                    {this.renderTags(newsItem)}
                    {this.renderDate(newsItem.publicationDate)}
                </Row>
                <NewsUrlButton news={newsItem} />
            </Column>
        )
    }

    private renderTags(newsItem: NewsItemType) {
        const tags = []
        const { publishedStatus, sources } = newsItem

        if (publishedStatus) {
            publishedStatus.topics.forEach(topic => {
                tags.push(
                    <NewsItemTag
                        key={`topic-${topic.id}`}
                        className={this.bem.getElement('tag')}
                        label={topic.name}
                        type={NewsItemTagEnum.topic}
                    />
                )
            })
        }

        if (sources?.length) {
            if (sources.length === 1 && sources[0].name) {
                tags.push(
                    <NewsItemTag
                        key={`source-${sources[0].id}`}
                        className={this.bem.getElement('tag')}
                        label={sources[0].name}
                    />
                )
            } else {
                tags.push(
                    <NewsItemTag
                        key={`source-${sources[0].id}`}
                        className={this.bem.getElement('tag')}
                        label={localize.translate(t => t.News.Attributes.sources, { count: sources.length })}
                        tooltipContent={sources.map(s => (
                            <div key={s.id}>
                                <Paragraph>{s.name}</Paragraph>
                            </div>
                        ))}
                    />
                )
            }
        }

        return tags
    }

    private renderSidebar(title: string, newsItem: NewsItemType) {
        return (
            <Column className={this.bem.getElement('sidebar-column')}>
                {this.getSidebarForStatus(newsItem, title)}
            </Column>
        )
    }

    private getSidebarForStatus(newsItem: NewsItemType, title: string) {
        if (newsItem.internalAlert) {
            // with only the add departments/topics button
            return this.renderPublishedSidebar(newsItem)
        }

        if (newsItem.archivedStatus && newsItem.archivedStatus.archivedAt) {
            // with the archived info & the unarchive button
            return this.renderArchivedSidebar(newsItem.archivedStatus)
        }

        // with mark relevant & archive buttons
        return this.renderNewItemSidebar(newsItem, title)
    }

    private renderPublishedSidebar(newsItem: NewsItemType) {
        return (
            <>
                <Guard condition={permissions.canMarkRelevantNews()}>
                    <ModalManager
                        render={openModal => (
                            <div>
                                <Button
                                    className={this.bem.getElement('change-topic-link-button')}
                                    type={ButtonType.secondary}
                                    onClick={openModal}
                                    icon={IconType.add}
                                    rounded={true}
                                >
                                    {this.loc(t => t.changeDepartmentsTopics)}
                                </Button>
                            </div>
                        )}
                        renderModal={closeModal => (
                            <EditPublishedCustomerNewsModal
                                onMarkRelevant={() => {
                                    this.refetch?.()
                                    this.refetchLogs?.()
                                }}
                                closeModal={closeModal}
                                newsItem={newsItem}
                                defaultDepartments={newsItem.internalAlert?.departments}
                                defaultTopics={newsItem.internalAlert?.topics}
                            />
                        )}
                    />
                </Guard>
                {newsItem.internalAlert?.note && (
                    <>
                        <Paragraph className={this.bem.getElement('label')} bold={true}>
                            {this.loc(t => t.note)}
                        </Paragraph>
                        <Paragraph className={this.bem.getElement('multi-line-truncate')}>
                            {nl2br(newsItem.internalAlert?.note)}
                        </Paragraph>
                    </>
                )}
                {this.renderLogs(newsItem.internalAlert)}
            </>
        )
    }

    private renderArchivedSidebar(archivedStatus: NewsItemArchivedStatusType) {
        const loc = localize.namespaceTranslate(t => t.Customer.Signaling.SignalingDetail.archiveInfo)

        const archivedBy = archivedStatus.archivedBy

        return (
            <>
                <Guard condition={permissions.hasPermission(EmployeePermissionEnum.canMonitorSignaling)}>
                    <UnarchiveNewsItemsMutation>
                        {(mutate, { loading }) => (
                            <div>
                                <Button
                                    type={ButtonType.secondary}
                                    onClick={() => this.unarchiveItem(mutate)}
                                    icon={IconType.inbox}
                                    rounded={true}
                                    loading={loading}
                                >
                                    {this.loc(t => t.unarchive)}
                                </Button>
                            </div>
                        )}
                    </UnarchiveNewsItemsMutation>
                </Guard>
                <Row>
                    {archivedBy && (
                        <div className={this.bem.getElement('past-action')}>
                            <UserProfileIcon
                                small={true}
                                fullName={archivedBy.user.profile.fullName}
                                uri={archivedBy.user.profile.avatar}
                                showTooltip={true}
                                className={this.bem.getElement('user-icon')}
                            />
                            {loc(t => t.archivedBy)} {archivedBy.user.profile.fullName}
                        </div>
                    )}
                </Row>
                <Paragraph className={this.bem.getElement('archived-reason')}>
                    {archivedStatus?.archivedReason || loc(t => t.noArchivedReason)}
                </Paragraph>
                <div className={this.bem.getElement('past-action-date')}>
                    {this.renderDate(archivedStatus.archivedAt)}
                </div>
            </>
        )
    }

    private renderNewItemSidebar(newsItem: NewsItemType, title: string) {
        const { id } = this.props.match.params
        const archiveEnabled =
            permissions.hasPermission(EmployeePermissionEnum.canMonitorSignaling) && !newsItem.publishedStatus?.id

        return (
            <>
                <Guard condition={permissions.canMarkRelevantNews()}>
                    <ModalManager
                        render={openRelevantModal => (
                            <div>
                                <Button
                                    className={this.bem.getElement('topic-link-button')}
                                    onClick={openRelevantModal}
                                    icon={IconType.link}
                                    rounded={true}
                                >
                                    {localize.translate(t => t.Generic.relevant)}
                                </Button>
                            </div>
                        )}
                        renderModal={closeModal => (
                            <RelevantModal
                                onMarkRelevant={() => {
                                    this.refetch?.()
                                    this.refetchLogs?.()
                                }}
                                closeModal={closeModal}
                                newsItem={newsItem}
                            />
                        )}
                    />
                </Guard>
                <Guard condition={archiveEnabled}>
                    <ArchiveNewsItemButtonContainer newsId={parseInt(id!, 10)} newsTitle={title}>
                        {openArchiveModal => (
                            <div>
                                <Button
                                    type={ButtonType.secondary}
                                    onClick={openArchiveModal}
                                    icon={IconType.archive}
                                    rounded={true}
                                >
                                    {localize.translate(t => t.Generic.archive)}
                                </Button>
                            </div>
                        )}
                    </ArchiveNewsItemButtonContainer>
                </Guard>
            </>
        )
    }

    private renderLogs(internalAlert?: NewsItemType['internalAlert']) {
        if (!internalAlert) {
            return null
        }

        return (
            <InternalAlertLogsQuery internalAlertId={internalAlert.id} take={3}>
                {({ data, loading, loadingMore, fetchMore, canFetchMore, refetch }) => {
                    this.refetchLogs = refetch

                    return (
                        <InternalAlertTimelineLog
                            internalAlert={internalAlert}
                            loadingMore={loadingMore}
                            fetchMore={fetchMore}
                            canFetchMore={canFetchMore}
                            logs={data?.nodes || []}
                            totalCount={data?.totalCount || 0}
                            loading={loading}
                        />
                    )
                }}
            </InternalAlertLogsQuery>
        )
    }

    private renderDate(date: Date | null | undefined, subtler?: boolean) {
        if (!date) {
            return
        }

        return (
            <Paragraph subtle={subtler ? false : true} verySubtle={subtler ? true : false}>
                <DateFormat date={new Date(date)} readable={true} includeTime={true} noWeekday={true} />
            </Paragraph>
        )
    }

    private unarchiveItem = async (mutate: UnarchiveNewsItemsMutationFN) => {
        const { id } = this.props.match.params

        const response = await mutate({
            variables: {
                newsIds: [parseInt(id!, 10)],
            },
        })

        if (response && response.data && response.data.unarchiveCustomerNews) {
            notification.success(this.loc(t => t.successfullyUnarchived))
        }
    }
}
