import './ActiveDepartment.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { CustomerConsumer, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    className?: ClassValue
}

interface State {}

export class ActiveDepartment extends React.PureComponent<Props, State> {
    private bem = new BEM('ActiveDepartment')

    public render() {
        const { className } = this.props

        return (
            <CustomerConsumer>
                {context => {
                    if (!context) {
                        return null
                    }

                    return (
                        <div className={this.bem.getClassName(className)}>{this.renderActiveDepartment(context)}</div>
                    )
                }}
            </CustomerConsumer>
        )
    }

    private renderActiveDepartment(context: CustomerContextValue) {
        const activeDepartment = context.departments.find(({ id }) => id === context.activeDepartmentId)

        if (!activeDepartment) {
            return
        }

        return activeDepartment.name
    }
}
