import React from 'react'

import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import debounce from 'lodash-es/debounce'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { CustomersDocument, CustomerType } from '~/generated/graphql'
import { localize } from '~/bootstrap'

interface Props {
    name: string
    onChange?: (value: any | any[], name: string) => void
    placeholder?: string
    multi?: boolean
    disabled?: boolean
    defaultCustomer?: {
        id: number
        name: string
        deletedAt?: Date | null
    }
}

interface State {
    search: string | null
}

export class CustomerSelect extends React.PureComponent<Props, State> {
    public state: State = {
        search: null,
    }

    public render() {
        const { name, multi, onChange, placeholder, disabled } = this.props

        return (
            <PaginatableQuery<CustomerType> query={CustomersDocument}>
                {({ data, loading, fetchMore }) => {
                    if (!data) {
                        return null
                    }

                    const customerOptions = this.getCustomerOptions(data.nodes)

                    return (
                        <Form.Select
                            name={name}
                            loading={loading}
                            multi={multi}
                            defaultValue={this.getDefaultOption()}
                            options={customerOptions}
                            placeholder={placeholder}
                            clearable={false}
                            disabled={disabled}
                            onSearch={this.handleOnSearch}
                            onChange={selectedOptions => {
                                if (onChange && selectedOptions) {
                                    onChange(selectedOptions, name)
                                    this.setState({ search: '' })
                                }
                            }}
                            onEndReached={fetchMore}
                        />
                    )
                }}
            </PaginatableQuery>
        )
    }

    private getDefaultOption() {
        const { defaultCustomer } = this.props
        if (!defaultCustomer) {
            return
        }

        const label = defaultCustomer.deletedAt
            ? `${defaultCustomer.name} - ${localize.translate(t => t.Generic.deleted)}`
            : defaultCustomer.name

        return [{ label, value: defaultCustomer.id }]
    }

    private getCustomerOptions(customers: CustomerType[]) {
        const options = customers.map(customer => ({
            label: customer.name,
            value: customer.id,
        }))

        const { defaultCustomer } = this.props
        if (!defaultCustomer) {
            return options
        }

        const notReturnedFromQuery = !customers.some(({ id }) => id === defaultCustomer.id)
        if (notReturnedFromQuery) {
            options.push({ label: defaultCustomer.name, value: defaultCustomer.id })
            return options
        }

        return options
    }

    private handleOnSearch = (search: string) => {
        debounce(() => this.setState({ search: search || null }), 250)
    }
}
