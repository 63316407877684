import './WidgetInfoLink.scss'

import React from 'react'
import { Link } from 'react-router-dom'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { DashboardDepartmentSpecificWidgetMetadataType } from '~/generated/graphql'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    to: string
    departmentSpecificMetadata?: DashboardDepartmentSpecificWidgetMetadataType
    className?: ClassValue
}

export class WidgetInfoLink extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('WidgetInfoLink')

    public render() {
        const { to, children, className } = this.props

        const shouldNotRenderLink = this.checkIfShouldNotRenderLink()
        if (shouldNotRenderLink) {
            return children
        }

        return (
            <Link
                onClick={this.handleClick}
                to={to}
                className={this.bem.getClassName(className)}
                style={{ textDecoration: 'none' }}
            >
                {children}
            </Link>
        )
    }

    private checkIfShouldNotRenderLink() {
        const { departmentSpecificMetadata } = this.props

        if (!departmentSpecificMetadata) {
            return false
        }

        const { departments, allDepartments } = departmentSpecificMetadata
        const hasMoreThanOneDepartment = allDepartments || (departments && departments.length > 1)

        if (hasMoreThanOneDepartment) {
            return true
        }

        return false
    }

    private handleClick = () => {
        const shouldSwitchDepartments = this.checkIfShouldSwitchDepartments()

        if (shouldSwitchDepartments) {
            const departmentId = this.getDepartmentIdToSwitchTo()
            this.context.toggleDepartment(departmentId)
        }
    }

    private checkIfShouldSwitchDepartments() {
        const { departmentSpecificMetadata } = this.props

        if (!departmentSpecificMetadata) {
            return false
        }

        const { departments, allDepartments } = departmentSpecificMetadata

        if (allDepartments) {
            return false
        }

        if (departments && departments.length > 1) {
            return false
        }

        return true
    }

    private getDepartmentIdToSwitchTo() {
        const { departmentSpecificMetadata } = this.props

        const hasNoDepartmentData =
            !departmentSpecificMetadata ||
            !departmentSpecificMetadata.departments ||
            !departmentSpecificMetadata.departments.length

        // shouldnt be possible -- sanity check
        if (hasNoDepartmentData) {
            return this.context.activeDepartmentId
        }

        return departmentSpecificMetadata!.departments![0].id!
    }
}
