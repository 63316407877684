import './InboxItemImpactIcon.scss'

import React from 'react'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { AlertImpactLevel } from '~/generated/graphql'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    impact?: AlertImpactLevel | null
}

export class InboxItemImpactIcon extends React.PureComponent<Props> {
    private bem = new BEM('InboxItemImpactIcon', () => ({
        [`${this.props.impact}`]: !!this.props.impact,
    }))

    public render() {
        return <Icon type={this.getIconType()} className={this.bem.getClassName(this.props.className)} />
    }

    private getIconType() {
        const { impact } = this.props

        let icon: IconType

        switch (impact) {
            case AlertImpactLevel.high:
                icon = IconType.inboxItemImpact3
                break
            case AlertImpactLevel.medium:
                icon = IconType.inboxItemImpact2
                break
            case AlertImpactLevel.low:
                icon = IconType.inboxItemImpact1
                break
            default:
                icon = IconType.inboxItemImpact2
        }

        return icon
    }
}
