import './SearchOverviewView.scss'

import React from 'react'
import { SearchField } from '~/components/Domain/Search/SearchField'
import { RouteComponentProps } from 'react-router'
import { ClassValue, BEM } from '~/services/BEMService'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { SearchResults } from '~/views/Customer/Search/SearchResults'
import { FilterTags } from '~/components/Core/DataEntry/FilterTags/FilterTags'
import { Row } from '~/components/Core/Layout/Row'
import { Column } from '~/components/Core/Layout/Column'
import { ParamManager } from '~/components/Core/ParamManager/ParamManager'
import { SearchOverviewFilterDropdown, SearchOverviewFilterOptions } from './SearchOverviewFilterDropdown'
import { localize } from '~/bootstrap'

interface Props extends RouteComponentProps<{}> {
    className?: ClassValue
}

export interface SearchOverviewFilters extends SearchOverviewFilterOptions {
    search?: string | null
    tags: number[]
}

export class SearchOverviewView extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.LinkedItemNaming)
    private bem = new BEM('SearchOverviewView')
    private filterTags = [
        {
            id: 1,
            name: this.loc(t => t.All),
        },
        {
            id: 2,
            name: this.loc(t => t.Topic),
        },
        {
            id: 3,
            name: this.loc(t => t.News),
        },
        {
            id: 4,
            name: this.loc(t => t.Consultation),
        },
        {
            id: 5,
            name: this.loc(t => t.Alert),
        },
        {
            id: 6,
            name: this.loc(t => t.RadarItem),
        },
        {
            id: 7,
            name: this.loc(t => t.LawAndRegulation),
        },
    ]

    public render() {
        return (
            <ParamManager<SearchOverviewFilters> defaultState={{ tags: [this.filterTags[0].id] }}>
                {({ paramState, setParamState }) => {
                    const { search } = paramState

                    return (
                        <Column bigSpacing={true} className={this.bem.getClassName()}>
                            <SearchField
                                onSearch={search => setParamState({ ...paramState, search })}
                                value={search || ''}
                            />
                            {this.renderFilters(paramState, setParamState)}
                            <SearchResults filters={paramState} />
                        </Column>
                    )
                }}
            </ParamManager>
        )
    }

    private renderFilters(paramState: SearchOverviewFilters, setParamState: (newState: SearchOverviewFilters) => void) {
        return (
            <Row spaceBetween={true}>
                <FilterTags
                    filters={this.filterTags}
                    defaultActive={paramState.tags || [this.filterTags[0].id]}
                    getUpdatedFilterIdsOnToggle={this.getUpdatedFilterIds}
                    onChange={tags => setParamState({ ...paramState, tags })}
                />
                <SearchOverviewFilterDropdown
                    onFilterChange={filters => setParamState({ ...paramState, ...filters })}
                    defaultFilters={paramState}
                />
            </Row>
        )
    }

    private getUpdatedFilterIds = (activeFilterIds: number[], filterId: number, isActive: boolean): number[] => {
        const showAllFilterId = this.filterTags[0].id

        const clickedOnShowAll = filterId === showAllFilterId
        if (clickedOnShowAll) {
            return [filterId]
        }

        if (isActive) {
            const showAllFilteredOut = activeFilterIds.filter(id => id !== showAllFilterId)
            return [...showAllFilteredOut, filterId]
        }

        const unselectedLastFilter = !isActive && activeFilterIds.length === 1
        if (unselectedLastFilter) {
            return [showAllFilterId]
        }

        if (!isActive) {
            return activeFilterIds.filter(id => id !== filterId)
        }

        return [...activeFilterIds, filterId]
    }
}
