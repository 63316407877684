import './Step.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Row } from './Row'
import { ActionBar } from './ActionBar'

interface Props {
    className?: ClassValue
    title: string
    titleActionComponent?: JSX.Element
    actionBar?: JSX.Element
    sidebarTitle?: string
    sidebar?: JSX.Element
}

interface State {}

export class Step extends React.PureComponent<Props, State> {
    private bem = new BEM('Step', () => ({
        'has-sidebar': !!this.props.sidebar,
    }))

    public render() {
        const { children, className, title, titleActionComponent, actionBar, sidebar, sidebarTitle } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('content-wrapper')}>
                    <div className={this.bem.getElement('main-content')}>
                        <Row className={this.bem.getElement('header')} spaceBetween={true}>
                            <h3 className={this.bem.getElement('title')}>{title}</h3>
                            {titleActionComponent && (
                                <div className={this.bem.getElement('action-component')}>{titleActionComponent}</div>
                            )}
                        </Row>
                        <div className={this.bem.getElement('content')}>{children}</div>
                    </div>
                    {sidebar && (
                        <>
                            <div className={this.bem.getElement('seperator')} />
                            <div className={this.bem.getElement('sidebar')}>
                                {sidebarTitle && (
                                    <h3 className={this.bem.getElement('sidebar-title')}>{sidebarTitle}</h3>
                                )}
                                {sidebar}
                            </div>
                        </>
                    )}
                </div>
                {actionBar && (
                    <ActionBar className={this.bem.getElement('action-bar')}>
                        <Row alignRight={true}>{actionBar}</Row>
                    </ActionBar>
                )}
            </div>
        )
    }
}
