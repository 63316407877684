import React from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'
import { MonitoringReportsOverviewView } from './MonitoringReportsOverviewView'
import { MonitoringReportDetailView } from './MonitoringReportsDetailView'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { MonitoringRapportEditView } from './MonitoringReportEditView'

interface Props extends RouteComponentProps<{}> {}

export class MonitoringReportsView extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Switch>
                <Route
                    path={routes.customer(this.context.customer.slug).monitoring.reports.index}
                    component={MonitoringReportsOverviewView}
                    exact={true}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).monitoring.reports.detail.view()}
                    component={MonitoringReportDetailView}
                    exact={true}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).monitoring.reports.detail.edit()}
                    component={MonitoringRapportEditView}
                    exact={true}
                />
                <Route component={AppNotFoundView} />
            </Switch>
        )
    }
}
