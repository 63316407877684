import './StepContainer.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    onStepChange?: (newStep: number) => void
    steps: {
        content: (nextStep: () => void, prevStep: () => void) => JSX.Element
    }[]
    hideStepCounter?: boolean
}

interface State {
    currentStep: number
}

export class StepContainer extends React.Component<Props, State> {
    public state: State = {
        currentStep: 0,
    }

    private bem = new BEM('StepContainer')

    public render() {
        const { className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {this.renderStepCounter()}
                {this.renderStep()}
            </div>
        )
    }

    private renderStepCounter() {
        const { hideStepCounter, steps } = this.props
        const { currentStep } = this.state

        if (hideStepCounter) {
            return null
        }

        return (
            <div className={this.bem.getElement('step-counter')}>
                {!hideStepCounter && (
                    <div className={this.bem.getElement('step-counter')}>
                        {localize.translate(t => t.Core.StepContainer.stepCounter, {
                            currentStepIndex: currentStep + 1,
                            amountOfSteps: steps.length,
                        })}
                    </div>
                )}
            </div>
        )
    }

    private renderStep() {
        const { steps } = this.props
        const { currentStep } = this.state

        return steps[currentStep].content(this.nextStep, this.prevStep)
    }

    private nextStep = () => {
        const { currentStep } = this.state
        const { steps, onStepChange } = this.props
        const newCurrentStep = this.state.currentStep + 1

        if (currentStep + 1 === steps.length) {
            return
        }
        this.setState({ currentStep: newCurrentStep })

        if (onStepChange) {
            onStepChange(newCurrentStep)
        }
    }

    private prevStep = () => {
        const { currentStep } = this.state
        const { onStepChange } = this.props
        const newCurrentStep = this.state.currentStep - 1

        if (currentStep === 0) {
            return
        }

        this.setState({ currentStep: newCurrentStep })

        if (onStepChange) {
            onStepChange(newCurrentStep)
        }
    }
}
