import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'

const CUSTOMER_SETTINGS_QUERY = gql`
    query customer {
        customer {
            id
            settings {
                compliance {
                    riskGraph {
                        lowImpactDescription
                        highImpactDescription
                        lowPropbabiltyDescription
                        highPropbabiltyDescription
                    }
                }
            }
        }
    }
`

export interface RiskSettingsFieldsQueryResponse {
    customer?: {
        id: number
        settings?: {
            compliance?: {
                riskGraph?: {
                    highImpactDescription?: string
                    lowImpactDescription?: string
                    highPropbabiltyDescription?: string
                    lowPropbabiltyDescription?: string
                }
            }
        }
    }
}

export type RiskSettingsFieldsQueryResult = QueryResult<RiskSettingsFieldsQueryResponse>

interface Props {
    children: (result: RiskSettingsFieldsQueryResult) => JSX.Element
}

export class RiskSettingsQuery extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return <Query<RiskSettingsFieldsQueryResponse> query={CUSTOMER_SETTINGS_QUERY}>{children}</Query>
    }
}
