import React from 'react'
import { localize } from '~/bootstrap'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Column } from '~/components/Core/Layout/Column'
import { Section } from '~/components/Core/Layout/Section'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { AssessmentLabel } from '../Compliance/Assessments/AssessmentLabel'
import { InsetCard } from '../Compliance/Norms/InsetCard'
import { MarkTopicsAsNonApplicableFormListTopicType } from '~/components/Domain/Themes/TopicNonApplicableFormList/MarkTopicsAsNonApplicableFormListQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AssessmentsForTopicsQuery } from './AssessmentsForTopicsQuery'
import { TopicType } from '~/generated/graphql'

interface Props {
    onConfirm: (closeModal: () => void) => void
    topic: MarkTopicsAsNonApplicableFormListTopicType | TopicType
    children: (openModal: () => void) => React.ReactNode
    confirmButtonLabel?: string
}

export class MarkTopicAsNonApplicableWarningModal extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDetailEditView)

    public render() {
        const { onConfirm, children, confirmButtonLabel } = this.props
        return (
            <ConfirmModal
                renderContent={this.renderModalContent}
                confirmButtonLabel={confirmButtonLabel ? confirmButtonLabel : this.loc(t => t.confirmButtonLabel)}
                title={this.loc(t => t.confirmTitle)}
                onConfirm={onConfirm}
                children={children}
            />
        )
    }

    private renderModalContent = () => {
        const { topic } = this.props

        return (
            <AssessmentsForTopicsQuery topicId={topic.id} departmentId={this.context.activeDepartmentId}>
                {({ data, loading }) => {
                    const topicWithCompliance = data && data.topic ? data.topic : null

                    const designMetadata = topicWithCompliance?.departmentTopicData?.topicAssessmentDesignMetadata
                    const designAssessmentsCount =
                        designMetadata && designMetadata?.linkedControlMeasureCount + designMetadata?.linkedNoteCount

                    const effectivenessMetadata =
                        topicWithCompliance?.departmentTopicData?.topicAssessmentEffectivenessMetadata

                    const effectivenessAssessmentsCount =
                        effectivenessMetadata &&
                        effectivenessMetadata?.linkedControlMeasureCount + effectivenessMetadata?.linkedNoteCount

                    const assessmentExists = !!(designAssessmentsCount || effectivenessAssessmentsCount)

                    return (
                        <Column bigSpacing={true}>
                            <Paragraph>
                                {this.loc(t => t.confirmMessagePart1)}
                                <strong>{topic.name}</strong>
                                {this.loc(t => t.confirmMessagePart2)}
                            </Paragraph>
                            {loading ? (
                                <Spinner />
                            ) : topicWithCompliance ? (
                                <InsetCard>
                                    {topicWithCompliance.assessments.length > 0 && (
                                        <Section
                                            title={this.loc(t => t.assessmentsLabel)}
                                            renderAction={() => (
                                                <Paragraph>{this.loc(t => t.assessmentsDescription)}</Paragraph>
                                            )}
                                        >
                                            <Column smallSpacing={true}>
                                                {topicWithCompliance.assessments.map(assessment => (
                                                    <Card small={true} key={assessment.id}>
                                                        <AssessmentLabel assessment={assessment} />
                                                    </Card>
                                                ))}
                                            </Column>
                                        </Section>
                                    )}
                                    {assessmentExists && (
                                        <Section
                                            title={this.loc(t => t.designAndEffectivenessLabel)}
                                            renderAction={() => (
                                                <Paragraph>{this.loc(t => t.assessmentNotesDescription)}</Paragraph>
                                            )}
                                        />
                                    )}
                                </InsetCard>
                            ) : null}
                        </Column>
                    )
                }}
            </AssessmentsForTopicsQuery>
        )
    }
}
