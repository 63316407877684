import './LinkControlToTopicModal.scss'

import { isEqual, uniq } from 'lodash'
import React from 'react'
import { localize, notification } from '~/bootstrap'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    ControlType,
    ControlsToLinkQueryVariables,
    ControlsToLinkDocument,
    ControlTypeType,
    GetControlsDocument,
} from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { ControlLabel } from '~/components/Domain/Compliance/Controls/ControlLabel'
import { ControlTypeCheckboxes } from '~/components/Domain/Compliance/Controls/ControlTypeCheckboxes'
import {
    LinkControlsToTopic,
    LinkControlsToTopicMutationFN,
} from '../../TopicDesignAndEffectiveness/mutations/LinkControlsToTopic'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'

interface Props {
    requestClose: () => void
    linkedControlIds: number[]
    topicId: number
    onCreate?: () => void
}

interface State {
    search?: string
    controlTypesFilters?: ControlTypeType[]
    selectedControlIds: number[]
}

export class LinkControlToTopicModal extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {
        selectedControlIds: this.props.linkedControlIds,
    }

    private bem = new BEM('LinkControlToTopicModal')

    public render() {
        const { selectedControlIds } = this.state
        const { requestClose, topicId } = this.props
        const confirmButtonLabel = localize.translate(
            t => t.Customer.LegalFrameworkView.TopicDetailView.TopicAttachments.button
        )

        const isPristine = !selectedControlIds.length || isEqual(selectedControlIds, this.props.linkedControlIds)

        return (
            <LinkControlsToTopic topicId={topicId}>
                {(mutate, { loading }) => (
                    <Modal
                        title={localize.translate(t => t.Control.SelectControlTable.addControl)}
                        requestClose={requestClose}
                        onAction={this.handleSubmit(mutate)}
                        loading={loading}
                        disabled={loading || isPristine}
                        className={this.bem.getClassName()}
                        confirmButtonLabel={confirmButtonLabel}
                    >
                        <Column>
                            {this.renderHeaderRow()}
                            <ErrorMessage path="addControlsToTopic" />
                            {this.renderContent()}
                        </Column>
                    </Modal>
                )}
            </LinkControlsToTopic>
        )
    }

    private handleSubmit = (mutate: LinkControlsToTopicMutationFN) => async () => {
        const { selectedControlIds } = this.state
        const { topicId, requestClose, linkedControlIds, onCreate } = this.props

        const newControlIds = selectedControlIds.filter(id => !linkedControlIds.includes(id))

        const response = await mutate({
            variables: {
                topicId,
                controlIds: newControlIds,
                departmentId: this.context.activeDepartmentId,
            },
        })

        if (response && response.data?.addControlsToTopic) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))

            if (onCreate) {
                onCreate()
            }

            requestClose()
        }
    }

    private renderHeaderRow() {
        const { linkedControlIds } = this.props
        const { selectedControlIds } = this.state

        const count = linkedControlIds.length
            ? selectedControlIds.filter(id => !linkedControlIds.includes(id)).length
            : selectedControlIds.length
        const selectedText = localize.translate(t => t.Generic.selected, { count })

        return (
            <Row spaceBetween={true}>
                <Paragraph bold={true} subtle={true}>
                    {selectedText}
                </Paragraph>
                {this.renderSearchAndFilters()}
            </Row>
        )
    }

    private renderContent() {
        const { search, controlTypesFilters } = this.state
        const isFilterActive = this.checkIfFilterIsActive()

        const queryDocument = isFilterActive || search ? GetControlsDocument : ControlsToLinkDocument

        return (
            <InfiniteScrollQuery<ControlType, ControlsToLinkQueryVariables>
                query={queryDocument}
                variables={{
                    departmentId: this.context.activeDepartmentId,
                    filters: {
                        name: search,
                        types: controlTypesFilters,
                        departmentId: this.context.activeDepartmentId,
                        parentControlsOnly: !isFilterActive && !search,
                    },
                }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return (
                            <Column className={this.bem.getElement('loading')}>
                                <Spinner delayed={true} />
                            </Column>
                        )
                    }

                    const controls = data?.nodes ? data.nodes : []

                    return this.renderControls(controls)
                }}
            </InfiniteScrollQuery>
        )
    }

    private renderSearchAndFilters() {
        const { search, controlTypesFilters } = this.state
        const searchPlaceholder = localize.translate(t => t.Generic.search.byName)
        const filterLabel = localize.translate(t => t.Control.attributes.type)

        return (
            <Row smallSpacing={true}>
                <Search
                    placeholder={searchPlaceholder}
                    defaultValue={search}
                    onChange={search => {
                        this.setState({ selectedControlIds: [], search: search || undefined })
                    }}
                />
                <FilterDropdown hasActiveFilter={this.checkIfFilterIsActive()}>
                    <FilterOption label={filterLabel} forInputName="controlType">
                        <ControlTypeCheckboxes
                            onChange={types => {
                                this.setState({ selectedControlIds: [], controlTypesFilters: types })
                            }}
                            defaultSelectedControlTypes={controlTypesFilters}
                        />
                    </FilterOption>
                </FilterDropdown>
            </Row>
        )
    }

    private checkIfFilterIsActive() {
        const { controlTypesFilters } = this.state
        if (controlTypesFilters?.length) {
            return true
        }

        return false
    }

    private renderControls(controls: ControlType[]) {
        if (!controls.length) {
            return <NoResults />
        }

        return <Column className={this.bem.getElement('container')}>{this.renderRows(controls, [])}</Column>
    }

    private renderRows(controls: ControlType[], acc: JSX.Element[], indentLevel?: 1 | 2) {
        for (const control of controls) {
            acc.push(this.renderRow(control, indentLevel))

            if (control.childControls?.length) {
                const nextIndent = indentLevel ? 2 : 1

                this.renderRows(control.childControls, acc, nextIndent)
            }
        }

        return acc
    }

    private renderRow = (control: ControlType, indentLevel?: 1 | 2) => {
        const { linkedControlIds } = this.props
        const defaultChecked = linkedControlIds.includes(control.id)
        const disabled = defaultChecked

        return (
            <React.Fragment key={`control-${control.id}`}>
                <Row className={this.bem.getElement('row')}>
                    <Checkbox
                        disabled={disabled}
                        defaultChecked={defaultChecked}
                        name={`control-${control.id}`}
                        onChange={checked => this.handleCheckboxChange(checked, control.id)}
                    />
                    <ControlLabel control={control} indentLevel={indentLevel} externalLink={true} />
                </Row>
            </React.Fragment>
        )
    }

    private handleCheckboxChange(checked: boolean, id: number) {
        const { selectedControlIds } = this.state

        if (checked) {
            this.setState({ selectedControlIds: uniq([...selectedControlIds, id]) })
            return
        }

        this.setState({ selectedControlIds: selectedControlIds.filter(controlId => controlId !== id) })
    }
}
