import './CreateWidgetWidget.scss'

import React from 'react'
import { BEM } from '~/services/BEMService'
import { Icon } from '~/components/Core/Icon/Icon'
import { Row } from '~/components/Core/Layout/Row'
import { Column } from '~/components/Core/Layout/Column'
import { widgets, WidgetItem } from './widgets'
import { IconType } from '~/components/Core/Icon/IconType'
import { localize } from '~/bootstrap'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { AddWidgetModal } from '../WidgetModals/AddWidgetModal'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { DashboardWidgetLayoutTypeEnum } from '~/generated/graphql'

interface Props {
    fadeOut: boolean
    layout: DashboardWidgetLayoutTypeEnum | undefined
    onCreateWidget: () => void
    afterOnCreateWidget?: () => void
    onModalStateChange: ({ isOpening }: { isOpening: boolean }) => void
}

export class CreateWidgetWidget extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView.CreateWidgetWidget)
    private bem = new BEM('CreateWidgetWidget', () => ({
        'is-faded': this.props.fadeOut,
    }))

    public render() {
        return (
            <div className={this.bem.getClassName()}>
                <div className={this.bem.getElement('scroll-container')}>{widgets.map(this.renderWidget)}</div>
            </div>
        )
    }

    private renderWidget = (widget: WidgetItem, i: number) => {
        const { checkPermission } = widget

        const hasPermission = checkPermission()
        if (!hasPermission) {
            return null
        }

        return (
            <ModalManager
                key={`widget-${i}`}
                render={openModal => this.renderWidgetButton(widget, openModal)}
                renderModal={closeModal => this.renderModal(closeModal, widget)}
            />
        )
    }

    private renderWidgetButton(widget: WidgetItem, openModal: () => void) {
        const { onModalStateChange } = this.props
        const { widgetKey, iconType } = widget

        const name = this.loc(t => t[widgetKey])
        const handleOnClick = () => {
            openModal()
            onModalStateChange({ isOpening: true })
        }

        return (
            <Button onClick={handleOnClick} type={ButtonType.noStyling}>
                <Column extraSmallSpacing={true} className={this.bem.getElement('widget-container')}>
                    <Row className={this.bem.getElement('new-widget-icon-container')}>
                        <Icon type={IconType.addCircle} className={this.bem.getElement('new-widget-icon')} />
                    </Row>
                    <Row alignCenter={true} className={this.bem.getElement('widget-icon')}>
                        <Icon type={iconType} />
                    </Row>
                    <Row alignCenter={true} className={this.bem.getElement('widget-text')}>
                        {name}
                    </Row>
                </Column>
            </Button>
        )
    }

    private renderModal(closeModal: () => void, widget: WidgetItem) {
        const { onModalStateChange, layout, onCreateWidget, afterOnCreateWidget } = this.props

        const handleCloseModal = () => {
            closeModal()
            onModalStateChange({ isOpening: false })
        }

        return (
            <AddWidgetModal
                className={this.bem.getElement('create-modal')}
                widget={widget}
                closeModal={handleCloseModal}
                layout={layout}
                onCreateWidget={onCreateWidget}
                afterOnCreateWidget={afterOnCreateWidget}
            />
        )
    }
}
