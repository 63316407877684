import { EmployeeSelectOption } from '~/components/Domain/Customer/EmployeeSelect'
import { ControlTypeType } from '~/generated/graphql'
import { IconType } from '~/components/Core/Icon/IconType'

export function getResponsibleEmployeeData(responsibleEmployeesData: EmployeeSelectOption[]) {
    let responsibleEmployeeIds: number[] | undefined = undefined
    let responsibleShadowEmployeeIds: number[] | undefined = undefined
    let responsibleEmployees: string[] | undefined = undefined

    if (responsibleEmployeesData && responsibleEmployeesData.some((option: any) => option.__isNew__)) {
        responsibleEmployees = responsibleEmployeesData
            .filter((employee: any) => employee.__isNew__)
            .map((employee: any) => employee.value.trim())
    }

    if (
        responsibleEmployeesData &&
        responsibleEmployeesData.some((option: any) => option.value.startsWith('employee-'))
    ) {
        responsibleEmployeeIds = responsibleEmployeesData
            .filter((option: any) => option.value.startsWith('employee-'))
            .map((option: any) => parseInt(option.value.replace('employee-', ''), 10))
    }

    if (
        responsibleEmployeesData &&
        responsibleEmployeesData.some((option: any) => option.value.startsWith('shadowEmployee-'))
    ) {
        responsibleShadowEmployeeIds = responsibleEmployeesData
            .filter((option: any) => option.value.startsWith('shadowEmployee-'))
            .map((option: any) => parseInt(option.value.replace('shadowEmployee-', ''), 10))
    }

    return { responsibleEmployeeIds, responsibleShadowEmployeeIds, responsibleEmployees }
}

/**
 * Get linkable control types by given control type,
 * based on fixed hierachy:
 *
 * - Policy
 *   - (Procedure)
 *     - Key Control / Control
 *
 * @param controlType
 */
export function getLinkableTypeForType(controlType: ControlTypeType): ControlTypeType[] {
    switch (controlType) {
        case ControlTypeType.policy:
            return [ControlTypeType.procedure, ControlTypeType.keyControl, ControlTypeType.control]
        case ControlTypeType.procedure:
            return [ControlTypeType.policy, ControlTypeType.keyControl, ControlTypeType.control]
        case ControlTypeType.keyControl:
            return [ControlTypeType.policy, ControlTypeType.procedure]
        case ControlTypeType.control:
            return [ControlTypeType.policy, ControlTypeType.procedure]
        default:
            return []
    }
}

/**
 * Get linkable parent control types by given control type,
 * based on fixed hierachy:
 *
 * - Policy
 *   - (Procedure)
 *     - Key Control / Control
 *
 * @param controlType
 */
export function getLinkableParentTypeForType(controlType: ControlTypeType): ControlTypeType[] {
    switch (controlType) {
        case ControlTypeType.procedure:
            return [ControlTypeType.policy]
        case ControlTypeType.keyControl:
            return [ControlTypeType.policy, ControlTypeType.procedure]
        case ControlTypeType.control:
            return [ControlTypeType.policy, ControlTypeType.procedure]
        default:
            return []
    }
}

/**
 * Get the possible type options while control is linked
 * (Because only type changes are alllowed within the same hierachical level)
 */
export function getPossibleTypesWhileControlIsLinked(currentControlType: ControlTypeType): ControlTypeType[] {
    switch (currentControlType) {
        case ControlTypeType.policy:
            return [ControlTypeType.policy]
        case ControlTypeType.procedure:
            return [ControlTypeType.procedure]
        case ControlTypeType.keyControl:
        case ControlTypeType.control:
            return [ControlTypeType.control, ControlTypeType.keyControl]
        default:
            return []
    }
}

/**
 * Get the icon type given a control type
 */
export function getIconTypeForControlType(controlType: ControlTypeType): IconType {
    switch (controlType) {
        case ControlTypeType.policy:
            return IconType.controlPolicy
        case ControlTypeType.procedure:
            return IconType.controlProcedure
        case ControlTypeType.keyControl:
            return IconType.controlKeyControl
        case ControlTypeType.control:
            return IconType.controlControl
        default:
            return IconType.control
    }
}
