import React from 'react'
import { localize } from '~/bootstrap'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { BEM, ClassValue } from '~/services/BEMService'
import './AddLinkedMonitoringReportButton.scss'
import { AddLinkedMonitoringReportListValue } from './AddLinkedMonitoringReportList'
import { SelectMonitoringReportModal } from '../LinkedMonitoringReportCard/SelectMonitoringReportModal'
import { MonitoringReportsQueryType } from '../MonitoringReportsTable/MonitoringReportsQuery'

interface Props {
    className?: ClassValue
    onSubmit: (monitoringReportType: MonitoringReportsQueryType) => void
    data: AddLinkedMonitoringReportListValue[]
}

export class AddLinkedMonitoringReportButton extends React.Component<Props, {}> {
    private bem = new BEM('AddLinkedMonitoringReportButton')
    private loc = localize.namespaceTranslate(t => t.Monitoring.SelectMonitoringReportTable)

    public render() {
        const { className } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <button
                        className={this.bem.getClassName(className)}
                        onClick={e => {
                            e.preventDefault()

                            return openModal()
                        }}
                    >
                        <Icon type={IconType.add} className={this.bem.getElement('icon')} />
                        {this.loc(t => t.addMonitoringReport)}
                    </button>
                )}
                renderModal={closeModal => (
                    <SelectMonitoringReportModal
                        onRequestClose={closeModal}
                        isMonitoringReportAdded={this.isMonitoringReportAdded}
                        onSubmit={monitoringReport => this.onCreateMonitoringReport(monitoringReport, closeModal)}
                    />
                )}
            />
        )
    }

    private onCreateMonitoringReport = (monitoringReport: MonitoringReportsQueryType, closeModal: () => void) => {
        const { onSubmit } = this.props

        if (onSubmit) {
            onSubmit(monitoringReport)
            setTimeout(() => closeModal(), 100)
        }
    }

    private isMonitoringReportAdded = (monitoringReport: MonitoringReportsQueryType) => {
        const { data } = this.props

        return data.some(item => item.id === monitoringReport.id)
    }
}
