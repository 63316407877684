import React from 'react'
import { RouteComponentProps } from 'react-router'
import { MonitoringReportsDetailContainer } from '~/components/Domain/Monitoring/MonitoringReportsDetail/MonitoringReportDetailContainer'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {}

export class MonitoringReportDetailView extends React.Component<Props, State> {
    public render() {
        const { id } = this.props.match.params

        if (!id) {
            return null
        }

        return <MonitoringReportsDetailContainer monitoringReportId={parseInt(id, 10)} />
    }
}
