import './RadarLabel.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconLabel } from '~/components/Core/Text/IconLabel'
import { IconType } from '~/components/Core/Icon/IconType'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { matchPath } from 'react-router'

interface Radar {
    id: number
    name: string
    isDeleted: boolean
}

interface Props {
    className?: ClassValue
    radarItem: Radar
    iconOnly?: boolean
}

interface State {}

export class RadarLabel extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('RadarLabel')

    public render() {
        const { className, radarItem, iconOnly } = this.props
        const { isDeleted, name } = radarItem

        return (
            <IconLabel
                className={this.bem.getClassName(className)}
                strikethrough={isDeleted}
                iconType={IconType.radar}
                to={this.getLink()}
            >
                {iconOnly ? null : name}
            </IconLabel>
        )
    }

    private getLink() {
        const { id, isDeleted } = this.props.radarItem

        if (isDeleted) {
            return
        }

        const link = routes.customer(this.context.customer.slug).radar.detail.view(id)
        const onSamePage = matchPath(window.location.pathname, {
            path: link,
        })

        if (onSamePage) {
            return
        }

        return link
    }
}
