import React from 'react'
import { MutationFn } from 'react-apollo'
import {
    EditCustomerDocument,
    EditCustomerMutation,
    EditCustomerMutationResult,
    EditCustomerMutationVariables,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

export type EditCustomerMutationFN = MutationFn<EditCustomerMutation, EditCustomerMutationVariables>

interface Props {
    children: (mutate: EditCustomerMutationFN, result: EditCustomerMutationResult) => JSX.Element
}

export class EditCustomer extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<EditCustomerMutation, EditCustomerMutationVariables> mutation={EditCustomerDocument}>
                {(mutate, result) => children(mutate, result)}
            </GQLMutation>
        )
    }
}
