import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { Column } from '~/components/Core/Layout/Column'
import { Section } from '~/components/Core/Layout/Section'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { RiskTypeSelect } from '../RiskTypeSelect'
import { Risk, RiskType } from './EditRiskModal'

export interface EditRiskGeneralFormDefaultValues {
    name: string
    description: string
    riskTypes: SelectOption[]
}

interface Props {
    risk: Risk
    handleOnInputChange: (value: string | null, name: string) => void
    handleOnRiskTypeChange: (option: SelectOption[]) => void
    defaultValues?: EditRiskGeneralFormDefaultValues
}

export class EditRiskGeneralFormFields extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Risks.editRisk)
    private formNames = {
        name: 'name',
        riskTypes: 'riskTypes',
        description: 'description',
    }

    public render() {
        const { handleOnInputChange, handleOnRiskTypeChange } = this.props
        const shouldDisableFormFields = !permissions.canEditAssessment(this.context.activeDepartmentId)
        const [name, description, riskTypes] = this.getDefaultValues()

        return (
            <Section title={localize.translate(t => t.Generic.general)}>
                <FieldSet>
                    <Column smallSpacing={true}>
                        <Field label={this.loc(t => t.name)} forInput={this.formNames.name}>
                            <Form.Input
                                defaultValue={name}
                                name={this.formNames.name}
                                placeholder={this.loc(t => t.namePlaceholder)}
                                disabled={shouldDisableFormFields}
                                onChange={handleOnInputChange}
                            />
                        </Field>
                        <Field label={this.loc(t => t.riskCategory)} forInput={this.formNames.riskTypes}>
                            <RiskTypeSelect
                                name={'riskTypes'}
                                creatable={true}
                                clearable={false}
                                defaultValue={riskTypes}
                                multi={true}
                                disabled={shouldDisableFormFields}
                                onChange={handleOnRiskTypeChange}
                            />
                        </Field>
                        <Field label={this.loc(t => t.description)} forInput={this.formNames.description}>
                            <Form.Input
                                type={'textarea'}
                                defaultValue={description}
                                placeholder={this.loc(t => t.descriptionPlaceholder)}
                                name={this.formNames.description}
                                disabled={shouldDisableFormFields}
                                onChange={handleOnInputChange}
                            />
                        </Field>
                    </Column>
                </FieldSet>
            </Section>
        )
    }

    private getDefaultValues(): [string, string, SelectOption[]] {
        const { defaultValues, risk } = this.props

        if (defaultValues) {
            const { name, description, riskTypes } = defaultValues

            return [name, description, riskTypes]
        }

        const { name, description } = risk
        const riskTypes = this.getDefaultRiskTypeValues(risk.types)

        return [name, description, riskTypes]
    }

    private getDefaultRiskTypeValues(riskTypes: RiskType[] | null): SelectOption[] {
        if (!riskTypes) {
            return []
        }

        return riskTypes.map(risk => {
            return { label: risk.name, value: risk.id }
        })
    }
}
