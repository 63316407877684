import './Cover.scss'

import React from 'react'

import { BEM } from '~/services/BEMService'
import { Button } from '~/components/Core/Button/Button'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Link, RouteComponentProps, NavLink } from 'react-router-dom'
import { Logo } from '~/components/Chrome/Logo/Logo'
import { PageTitle } from '~/components/Core/Text/PageTitle'
import { routes } from '~/views/routes'
import { localize } from '~/bootstrap'
import {
    RequestSAMLRedirectURLMutation,
    RequestSAMLRedirectURLMutationFn,
} from '~/graphql/mutations/RequestSAMLRedirectURLMutation'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Column } from '~/components/Core/Layout/Column'

interface Props extends RouteComponentProps<{}> {}

export class CoverSSOLoginView extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Cover.LoginWithSSO)
    private bem = new BEM('Cover')
    private formRef = React.createRef<Form>()

    public render() {
        const errorMessage = this.getErrorMessageFromUrl()

        return (
            <RequestSAMLRedirectURLMutation>
                {(requestSAMLRedirectURL, { loading }) => (
                    <div className={this.bem.getClassName()}>
                        <div className={this.bem.getElement('brand')}>
                            <NavLink to={routes.index}>
                                <Logo />
                            </NavLink>
                        </div>
                        <Form ref={this.formRef} onSubmit={this.handleSubmit(requestSAMLRedirectURL)}>
                            <div className={this.bem.getElement('content')}>
                                <PageTitle title={this.loc(t => t.title)} />
                                {errorMessage && <ErrorMessage message={errorMessage} />}
                                <Field compact={true} forInput="email" label={this.loc(t => t.organizationName)}>
                                    <Form.Input
                                        type="text"
                                        name="email"
                                        placeholder={this.loc(t => t.organizationNamePlaceholder)}
                                        onChange={email => this.setState({ email: email || '' })}
                                        ariaLabel="organization-name"
                                        required={true}
                                    />
                                </Field>
                            </div>

                            <Column noSpacing>
                                <SectionTitle>{this.loc(t => t.tipTitle)}</SectionTitle>
                                <Paragraph small>{this.loc(t => t.tip)}</Paragraph>
                            </Column>

                            <Button submit={true} className={this.bem.getElement('submit-button')} loading={loading}>
                                {this.loc(t => t.signInButton)}
                            </Button>

                            <Link to={routes.cover.login} className={this.bem.getElement('link')}>
                                {this.loc(t => t.backToLogin)}
                            </Link>
                        </Form>
                    </div>
                )}
            </RequestSAMLRedirectURLMutation>
        )
    }

    private handleSubmit =
        (requestSAMLRedirectURL: RequestSAMLRedirectURLMutationFn) => async (formState: FormState) => {
            const response = await requestSAMLRedirectURL({ variables: { email: formState.email } })

            if (response && response.data && response.data.requestSAMLRedirectURL) {
                const { redirectURL } = response.data.requestSAMLRedirectURL

                if (redirectURL) {
                    window.location.href = redirectURL
                    return
                }
            }

            throw new Error('Failed to request SAML redirect url')
        }

    private getErrorMessageFromUrl() {
        const searchParams = new URLSearchParams(this.props.location.search)
        const type = searchParams.get('error')

        if (!type) {
            return
        }

        if (type === 'assert') {
            return 'Er iets iets fout gegaan tijdens het verifiëren van het Ruler account, probeer het opnieuw. Blijft dit probleem voorkomen neem dan contact op.'
        }

        if (type === 'login') {
            return 'Het inloggen via de SSO provider is mislukt, probeer het opnieuw. Blijft dit probleem voorkomen neem dan contact op.'
        }

        if (type === 'logout') {
            return 'Het uitloggen via de SSO provider is mislukt, probeer het opnieuw. Blijft dit probleem voorkomen neem dan contact op.'
        }

        return 'Er is iets fout gegaan, probeer het opnieuw. Blijft dit probleem voorkomen neem dan contact op.'
    }
}
