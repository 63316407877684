import './TopicMonitoringReportsTable.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { ColumnOptions, Table } from '~/components/Core/DataDisplay/Table/Table'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Column } from '~/components/Core/Layout/Column'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'
import {
    MonitoringReportsQuery,
    MonitoringReportsQueryType,
} from '../Monitoring/MonitoringReportsTable/MonitoringReportsQuery'
import { BEM } from '~/services/BEMService'

interface Props {
    topicId: number
    setRefetch: (refetch: () => void) => void
}
export class TopicMonitoringReportsTable extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.MonitoringOverviewView)
    private bem = new BEM('TopicMonitoringReportsTable')

    private columnsConfiguration: ColumnOptions[] = [
        {
            field: 'name',
            headerLabel: this.loc(t => t.name),
        },
        {
            field: 'createdAt',
            headerLabel: this.loc(t => t.date),
        },
    ]

    public render() {
        const { setRefetch } = this.props

        return (
            <MonitoringReportsQuery topicId={this.props.topicId} sortDirection={{ field: 'name', direction: 'ASC' }}>
                {({ data, loading, loadingMore, refetch }) => {
                    const monitoringReports = data ? data.nodes : []
                    setRefetch(refetch)

                    return (
                        <Column className={this.bem.getElement('container')}>
                            <SectionTitle>{this.loc(t => t.title)}</SectionTitle>
                            <Table
                                loading={loading}
                                loadingMore={loadingMore}
                                emptyState={this.loc(t => t.emptyState.label)}
                                columns={this.columnsConfiguration}
                                data={this.getRowData(monitoringReports)}
                            />
                        </Column>
                    )
                }}
            </MonitoringReportsQuery>
        )
    }

    private getRowData(monitoringReports: MonitoringReportsQueryType[]) {
        return monitoringReports.map(monitoringReport => ({
            id: monitoringReport.id,
            columns: {
                name: (
                    <TableLink
                        to={routes
                            .customer(this.context.customer.slug)
                            .monitoring.reports.detail.view(monitoringReport.id)}
                    >
                        {monitoringReport.name}
                    </TableLink>
                ),
                createdAt: (
                    <Paragraph subtle={true}>
                        <DateFormat date={new Date(monitoringReport.assessedAt)} readable={true} noWeekday={true} />
                    </Paragraph>
                ),
            },
        }))
    }
}
