import React from 'react'

import { ConsultantAlertBanner } from '~/components/Chrome/ConsultantAlertBanner/ConsultantAlertBanner'
import { PageContainer } from '~/components/Core/Layout/PageContainer'
import { CustomerProvider } from '~/components/Providers/CustomerProvider'
import { RouteComponentProps } from 'react-router-dom'
import { AppLayout } from '~/components/Core/Layout/AppLayout'
import { permissions } from '~/bootstrap'
import { SessionManager } from '../SessionManager'
import { CustomerNavigation } from '~/components/Chrome/Navigation/CustomerNavigation/CustomerNavigation'
import { CustomerRoutes } from '~/views/Customer/CustomerRoutes'
import { UserContext, UserContextValue } from '~/components/Providers/UserProvider'
import { ChatPlaceholder } from '~/components/Chrome/ChatPlaceholder/ChatPlaceholder'

interface RouteParams {
    customer: string
}

interface Props extends RouteComponentProps<RouteParams> {}

export class CustomerView extends React.PureComponent<Props> {
    public static contextType = UserContext
    public context: UserContextValue

    public componentDidMount() {
        const locationState = this.props.location.state as any
        if (locationState && locationState.lastConsultantRoute) {
            window.sessionStorage.setItem('lastConsultantRoute', locationState.lastConsultantRoute)
        }
    }

    public render() {
        const { match } = this.props
        const { customer } = match.params

        const isConsultant = permissions.isConsultantUser()
        const isCustomerPlanner = permissions.isCustomerPlanner()
        const shouldShowBanner = isConsultant || this.context.user?.isCustomerConsultant

        return (
            <SessionManager>
                <CustomerProvider customerSlug={customer}>
                    <AppLayout hasConsultantHeader={isConsultant}>
                        {shouldShowBanner && (
                            <ConsultantAlertBanner isCustomerConsultant={this.context.user?.isCustomerConsultant} />
                        )}
                        <CustomerNavigation
                            hasBanner={shouldShowBanner}
                            isConsultant={isConsultant}
                            isCustomerPlanner={isCustomerPlanner}
                        />
                        <PageContainer>
                            <CustomerRoutes
                                customer={customer}
                                history={this.props.history}
                                location={this.props.location}
                            />
                        </PageContainer>
                    </AppLayout>
                    <ChatPlaceholder />
                </CustomerProvider>
            </SessionManager>
        )
    }
}
