import './CustomerFrameworksTableHeader.scss'

import React from 'react'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { MultiDepthExpandableTableHeader } from '~/components/Core/DataDisplay/Table/MultiDepthExpandableTableHeader'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Truncate } from '~/components/Core/Text/Truncate'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { CustomerFrameworkWithFramework } from '~/views/Customer/Settings/CustomerFramework/CustomerFrameworkOverviewView'
import { FrameworkIcon } from '../../Customer/Framework/FrameworkIcon/FrameworkIcon'
import { BEM } from '~/services/BEMService'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { CustomerFrameworkProfileModal } from '../CustomerFrameworkProfileModal'

interface Props {
    customerFrameworks: CustomerFrameworkWithFramework[]
    currentPage: number
    pageItemCount: number
    onPageChange: (direction: DirectionEnum) => void
}

export enum DirectionEnum {
    left = 'LEFT',
    right = 'RIGHT',
}

export class CustomerFrameworksTableHeader extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('CustomerFrameworksTableHeader')

    public render() {
        return (
            <Row alignRight={true} className={this.bem.getClassName()}>
                {this.renderButton(DirectionEnum.left)}
                <MultiDepthExpandableTableHeader headerItems={this.getHeaderItems()} />
                {this.renderButton(DirectionEnum.right)}
            </Row>
        )
    }

    private renderButton(direction: DirectionEnum) {
        const { onPageChange } = this.props

        if (direction === DirectionEnum.right) {
            const isRightDisabled = this.isButtonDisabled(DirectionEnum.right)

            return (
                <Button
                    className={this.bem.getElement('right-button')}
                    onClick={() => onPageChange(DirectionEnum.right)}
                    disabled={isRightDisabled}
                    icon={IconType.nextArrow}
                    type={ButtonType.secondary}
                />
            )
        }

        const isLeftDisabled = this.isButtonDisabled(DirectionEnum.left)
        return (
            <Button
                className={this.bem.getElement('left-button')}
                onClick={() => onPageChange(DirectionEnum.left)}
                disabled={isLeftDisabled}
                icon={IconType.backArrow}
                type={ButtonType.secondary}
            />
        )
    }

    private getHeaderItems() {
        const currentPageItems = this.getCurrentPageItems()

        return currentPageItems.map(customerFramework => ({
            id: customerFramework.id,
            render: () => this.renderHeaderItem(customerFramework),
        }))
    }

    private isButtonDisabled(direction: DirectionEnum) {
        const { customerFrameworks, currentPage, pageItemCount } = this.props

        if (direction === DirectionEnum.right) {
            const totalCount = customerFrameworks.length
            return totalCount <= currentPage * pageItemCount
        }

        return currentPage <= 1
    }

    private getCurrentPageItems() {
        const { currentPage, customerFrameworks, pageItemCount } = this.props

        const totalRange = customerFrameworks.length
        const index = (currentPage - 1) * pageItemCount
        const range = currentPage * pageItemCount

        return customerFrameworks.slice(index, range > totalRange ? totalRange : range)
    }

    private renderHeaderItem(customerFramework: CustomerFrameworkWithFramework) {
        const { customerFrameworks } = this.props

        return (
            <Column smallSpacing={true} className={this.bem.getElement('header-item')}>
                <FrameworkIcon frameworkType={customerFramework.icon} color={customerFramework.color} />
                <Tooltip direction={ToolTipDirection.down} content={this.getTooltipContent(customerFramework)}>
                    <Column extraSmallSpacing={true}>
                        <Paragraph bold={true} className={this.bem.getElement('customer-framework-name')}>
                            <Truncate
                                multiLineTruncate={customerFramework.name}
                                maxCharPerMultiLine={11}
                                noTitle={true}
                            />
                        </Paragraph>
                        <Paragraph small={true} subtle={true} className={this.bem.getElement('framework-name')}>
                            <Truncate
                                multiLineTruncate={customerFramework.framework.name}
                                maxCharPerMultiLine={12}
                                noTitle={true}
                            />
                        </Paragraph>
                    </Column>
                </Tooltip>
                <ModalManager
                    render={openModal => (
                        <Button
                            type={ButtonType.subtleSecondary}
                            icon={IconType.edit}
                            onClick={openModal}
                            className={this.bem.getElement('edit-button')}
                        />
                    )}
                    renderModal={closeModal => (
                        <CustomerFrameworkProfileModal
                            customerFramework={customerFramework}
                            requestClose={closeModal}
                            frameworks={customerFrameworks}
                        />
                    )}
                />
            </Column>
        )
    }

    private getTooltipContent(customerFramework: CustomerFrameworkWithFramework) {
        return (
            <>
                <Paragraph bold={true}>{customerFramework.name}</Paragraph>
                <Paragraph small={true}>{customerFramework.framework.name}</Paragraph>
            </>
        )
    }
}
