import './MonitoringReportDetailMeta.scss'
import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { IconType } from '~/components/Core/Icon/IconType'
import { PageDetailMetaItem, PageDetailMeta } from '~/components/Core/Layout/PageDetail/PageDetailMeta'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { MonitoringReportType } from '~/generated/graphql'
import { File } from '~/graphql/types/File'
import { BEM } from '~/services/BEMService'
import { FileType } from '~/services/FileService'
import { FilePreviewModal } from '../../FilePreview/FilePreviewModal'

interface Props {
    monitoringReport: Pick<
        MonitoringReportType,
        'id' | 'assessedAt' | 'updatedAt' | 'file' | 'archivedAt' | 'archivedReason'
    >
}

export class MonitoringReportDetailMeta extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('MonitoringReportDetailMeta')
    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.MonitoringDetailView)

    public render() {
        const items = [
            this.getPublishedMetaItem(),
            this.getLastUpdatedMetaItem(),
            this.getFileMetaItem(),
            this.renderArchivedInfoMetaItem(),
        ].filter(i => !!i) as PageDetailMetaItem[]

        return <PageDetailMeta className={this.bem.getClassName()} items={items} />
    }

    private getPublishedMetaItem(): PageDetailMetaItem {
        return {
            label: this.loc(t => t.GeneralInformation.Fields.creationDate),
            value: (
                <Paragraph>
                    <DateFormat readable noWeekday date={new Date(this.props.monitoringReport.assessedAt)} />
                </Paragraph>
            ),
        }
    }

    private getLastUpdatedMetaItem(): PageDetailMetaItem {
        return {
            label: this.loc(t => t.GeneralInformation.Fields.editDate),
            value: (
                <Paragraph>
                    <DateFormat readable noWeekday date={new Date(this.props.monitoringReport.updatedAt)} />
                </Paragraph>
            ),
        }
    }

    private getFileMetaItem(): PageDetailMetaItem | undefined {
        const { file } = this.props.monitoringReport
        if (!file) {
            return
        }

        return {
            label: this.loc(t => t.GeneralInformation.Fields.document),
            value: (
                <FilePreviewModal file={file as File} fileType={FileType.monitoringReport}>
                    {previewFile => (
                        <Button onClick={() => previewFile()} icon={IconType.attachment} type={ButtonType.actionLink}>
                            {file.name}
                        </Button>
                    )}
                </FilePreviewModal>
            ),
        }
    }

    private renderArchivedInfoMetaItem(): PageDetailMetaItem | undefined {
        if (!this.props.monitoringReport.archivedAt) {
            return
        }

        return {
            label: this.loc(t => t.GeneralInformation.Fields.archivedInfo),
            value: (
                <Tooltip
                    message={this.props.monitoringReport.archivedReason || ''}
                    disabled={!this.props.monitoringReport.archivedReason}
                    className={this.bem.getElement('archived-reason')}
                >
                    <Paragraph truncateEllipsis>{this.props.monitoringReport.archivedReason}</Paragraph>
                </Tooltip>
            ),
            subValue: (
                <Paragraph subtle>
                    <DateFormat readable noWeekday date={new Date(this.props.monitoringReport.archivedAt)} />
                </Paragraph>
            ),
        }
    }
}
