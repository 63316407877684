import React from 'react'
import { EditTaskMutation, EditTaskMutationFN } from './EditTaskMutation'
import { TaskModal, CreateTaskFormValues } from '~/components/Domain/Task/TaskModal'
import { localize, notification } from '~/bootstrap'
import { GetTaskQuery } from '~/components/Domain/Task/GetTaskQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    requestClose?: () => void
    onEdit?: () => void
    taskId: number
}

interface State {}

export class EditTaskModalContainer extends React.Component<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { requestClose, taskId } = this.props

        return (
            <GetTaskQuery taskId={taskId}>
                {({ data, loading }) => {
                    const task = data && data.task ? data.task : null
                    const linkedItem = task && task.linkedItem ? task.linkedItem : undefined
                    const title = task?.isSubTask
                        ? localize.translate(t => t.Customer.Planning.Tasks.editSubTask)
                        : localize.translate(t => t.Customer.Planning.Tasks.editTask)

                    return (
                        <EditTaskMutation>
                            {(mutate, { loading: submitLoading }) => {
                                return (
                                    <TaskModal
                                        title={title}
                                        loading={loading}
                                        submitLoading={submitLoading}
                                        onSubmit={this.handleOnSubmit(mutate)}
                                        errorPath={'editTask'}
                                        requestClose={requestClose}
                                        task={task}
                                        linkedItem={linkedItem}
                                    />
                                )
                            }}
                        </EditTaskMutation>
                    )
                }}
            </GetTaskQuery>
        )
    }

    private handleOnSubmit = (mutate: EditTaskMutationFN) => async (
        formValues: CreateTaskFormValues,
        closeModal?: () => void
    ) => {
        const { onEdit, taskId } = this.props

        const response = await mutate({
            variables: {
                taskId,
                departmentId: this.context.activeDepartmentId,
                fields: formValues,
            },
        })

        if (response && response.data && response.data.editTask) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))

            if (closeModal) {
                closeModal()
            }

            if (onEdit) {
                onEdit()
            }
        }
    }
}
