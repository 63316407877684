import React from 'react'
import { localize } from '~/bootstrap'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { ControlStatus } from '~/generated/graphql'

interface Props {
    name: string
    isFilter?: boolean
    defaultValue?: ControlStatus
    onChange: (option: ControlStatus) => void
    placeholder?: string
}

export class ControlStatusSelect extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.Overview)

    private statusOptions: SelectOption[] = [
        { label: this.loc(t => t.open), value: ControlStatus.open },
        { label: this.loc(t => t.archived), value: ControlStatus.archived },
    ]

    public render() {
        const { name, isFilter, defaultValue, onChange, placeholder } = this.props

        return (
            <Form.Select
                isFilter={isFilter}
                searchable={false}
                name={name}
                options={this.statusOptions}
                placeholder={placeholder}
                defaultValue={defaultValue === ControlStatus.open ? [this.statusOptions[0]] : [this.statusOptions[1]]}
                onChange={(option: SelectOption<ControlStatus>) => onChange(option.value)}
            />
        )
    }
}
