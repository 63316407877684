import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { IconType } from '~/components/Core/Icon/IconType'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TaskDetailFields } from '~/graphql/types/Task'
import { ArchiveTask, ArchiveTaskMutationFN } from './Mutations/ArchiveTask'

interface Props {
    task: TaskDetailFields
    isDisabled: boolean
    onArchive?: () => void
}

export class ArchiveTaskButtonContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Task.viewTaskModal.archiveConfirm)

    public render() {
        const { task, isDisabled } = this.props

        const title = this.loc(t => t.title)
        const message = task.isParentTask ? this.loc(t => t.messageWithSubtaks) : this.loc(t => t.message)

        return (
            <ArchiveTask>
                {(mutate, { loading }) => (
                    <ConfirmModal onConfirm={this.handleOnArchive(mutate)} title={title} message={message}>
                        {openModal => (
                            <Button
                                disabled={isDisabled}
                                loading={loading}
                                icon={IconType.archive}
                                type={ButtonType.secondary}
                                onClick={openModal}
                            >
                                {this.loc(t => t.buttonLabel)}
                            </Button>
                        )}
                    </ConfirmModal>
                )}
            </ArchiveTask>
        )
    }

    private handleOnArchive = (mutate: ArchiveTaskMutationFN) => async (close: () => void) => {
        const { task, onArchive } = this.props

        const response = await mutate({
            variables: {
                taskId: task.id,
                departmentId: this.context.activeDepartmentId,
            },
        })

        if (response && response.data && response.data.archiveTask) {
            close()

            if (onArchive) {
                onArchive()
            }
        }
    }
}
