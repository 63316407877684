import React from 'react'
import { MutationFn } from 'react-apollo'
import { localize, notification, permissions } from '~/bootstrap'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AssessmentMatrixLayout } from '../Matrix/AssessmentMatrixLayout'
import { AssessmentSectionItemCard } from './AssessmentSectionItemCard'
import { AssessmentSectionsInfiniteScrollQuery } from './AssessmentSectionsInfiniteScrollQuery'
import { CreateNewAssessmentSectionButtonContainer } from './CreateNewAssessmentSectionButtonContainer'
import { DeleteAssessmentSectionMutation } from './DeleteAssessmentSectionMutation'
import {
    DeleteAssessmentSectionInAssessmentMutation,
    DeleteAssessmentSectionInAssessmentMutationVariables,
} from '~/generated/graphql'
import { Guard } from '~/components/Core/Guard/Guard'

interface Props {
    assessmentId: number
    assessmentName: string
    showDistinctBrutoRiskValues: boolean
}

export class AssessmentSectionsContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { assessmentId, assessmentName, showDistinctBrutoRiskValues } = this.props

        return (
            <AssessmentSectionsInfiniteScrollQuery assessmentId={assessmentId}>
                {({ data, loading, loadingMore, refetch }) => {
                    const assessmentSections = data ? data.nodes : []

                    return (
                        <>
                            {assessmentSections.map(assessmentSection => (
                                <DeleteAssessmentSectionMutation key={`assessmentSection-${assessmentSection.id}`}>
                                    {(mutate, { loading: loadingMutation }) => (
                                        <AssessmentSectionItemCard
                                            handleOnClose={() =>
                                                this.handleOnClose(assessmentSection.id, mutate, refetch)
                                            }
                                            onCloseLoading={loadingMutation}
                                            assessmentSection={assessmentSection}
                                            assessmentName={assessmentName}
                                        >
                                            <AssessmentMatrixLayout
                                                assessmentId={assessmentId}
                                                assessmentSection={assessmentSection}
                                                showDistinctBrutoRiskValues={showDistinctBrutoRiskValues}
                                            />
                                        </AssessmentSectionItemCard>
                                    )}
                                </DeleteAssessmentSectionMutation>
                            ))}
                            <Guard condition={permissions.canEditAssessment(this.context.activeDepartmentId)}>
                                <CreateNewAssessmentSectionButtonContainer
                                    assessmentId={assessmentId}
                                    onSuccess={refetch}
                                    loading={loading}
                                />
                            </Guard>
                            {loadingMore && <Spinner delayed={true} />}
                        </>
                    )
                }}
            </AssessmentSectionsInfiniteScrollQuery>
        )
    }

    private async handleOnClose(
        assessmentSectionId: number,
        mutate: MutationFn<
            DeleteAssessmentSectionInAssessmentMutation,
            DeleteAssessmentSectionInAssessmentMutationVariables
        >,
        refetch: () => void
    ) {
        const response = await mutate({
            variables: { assessmentSectionId, departmentId: this.context.activeDepartmentId },
        })

        if (response && response.data && response.data.deleteAssessmentSection) {
            notification.success(localize.translate(t => t.Generic.successfullyDeleted))
            refetch()
        }
    }
}
