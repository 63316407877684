import './AssessmentDetailCard.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { Link } from 'react-router-dom'
import { Assessment } from './AssessmentDetailList'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { localize } from '~/bootstrap'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    className?: ClassValue
    assessment: Assessment
    to: string
}

interface State {}

export class AssessmentDetailCard extends React.PureComponent<Props, State> {
    private bem = new BEM('AssessmentDetailCard')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Assessments.Overview)

    public render() {
        const { className, to, assessment } = this.props
        const { name, description, normCount, riskCount, controlCount } = assessment

        return (
            <Card className={this.bem.getClassName(className)}>
                <Link to={to} className={this.bem.getElement('content')}>
                    <div className={this.bem.getElement('card-header')}>
                        <span className={this.bem.getElement('title')}>{name}</span>
                        <Paragraph className={this.bem.getElement('description')}>
                            {this.getExcerpt(description)}
                        </Paragraph>
                    </div>

                    <div className={this.bem.getElement('card-footer')}>
                        <Tooltip content={this.loc(t => t.norms)}>
                            <Row
                                className={this.bem.getElement('count-container', () => ({
                                    'at-zero': riskCount === null,
                                }))}
                                noSpacing={true}
                            >
                                {/* TODO:  Change this Icon into ComplianceIcon component + remove redundant icon CSS from this componenet */}
                                <Icon className={this.bem.getElement('icon')} type={IconType.norm} />
                                <div className={this.bem.getElement('count')}>
                                    {this.getFormattedCountValue(normCount)}
                                </div>
                            </Row>
                        </Tooltip>

                        <Tooltip content={this.loc(t => t.risks)}>
                            <Row
                                className={this.bem.getElement('count-container', () => ({
                                    'at-zero': riskCount === null,
                                }))}
                            >
                                {<Icon className={this.bem.getElement('icon')} type={IconType.risk} />}
                                <div className={this.bem.getElement('count')}>
                                    {this.getFormattedCountValue(riskCount)}
                                </div>
                            </Row>
                        </Tooltip>

                        <Tooltip content={this.loc(t => t.controls)}>
                            <Row
                                className={this.bem.getElement('count-container', () => ({
                                    'at-zero': riskCount === null,
                                }))}
                            >
                                {<Icon className={this.bem.getElement('icon')} type={IconType.control} />}
                                <div className={this.bem.getElement('count')}>
                                    {this.getFormattedCountValue(controlCount)}
                                </div>
                            </Row>
                        </Tooltip>
                    </div>
                </Link>
            </Card>
        )
    }

    public getExcerpt(description: string): React.ReactNode {
        if (description.length < 125) {
            return description
        }

        return `${description.substr(0, 125)}...`
    }

    private getFormattedCountValue(count: number | null) {
        return count === null ? '0' : count
    }
}
