import './TopicAssessmentSelect.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { Row } from '~/components/Core/Layout/Row'
import { BEM } from '~/services/BEMService'
import { TopicAssessmentIcon } from './TopicAssessmentIcon'
import { MonitoringReportTopicAssessmentGrade, TopicAssessmentGradeType } from '~/generated/graphql'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { TopicAssessmentLabel } from './TopicAssessmentLabel'

interface Props {
    label: React.ReactNode
    name: string
    defaultOption?: TopicAssessmentGradeType | MonitoringReportTopicAssessmentGrade
    isEditing?: boolean
    hideNotAssessedOption?: boolean
}

export class TopicAssessmentSelect extends React.PureComponent<Props> {
    private bem = new BEM('TopicAssessmentSelect')

    private options = [
        this.getOption(TopicAssessmentGradeType.assessmentSatisfies),
        this.getOption(TopicAssessmentGradeType.assessmentAlmostSatisfies),
        this.getOption(TopicAssessmentGradeType.assessmentNotSatisfies),
        this.getOption(TopicAssessmentGradeType.assessmentNotGiven),
    ]

    public render() {
        const { label } = this.props

        return (
            <Row spaceBetween={true} className={this.bem.getClassName()}>
                <div className={this.bem.getElement('label')}>{label}</div>
                {this.renderSelect()}
            </Row>
        )
    }

    private getOption(grade: TopicAssessmentGradeType) {
        return {
            label: <TopicAssessmentLabel status={grade} />,
            value: grade,
        }
    }

    private renderSelect() {
        const { isEditing, name, defaultOption, hideNotAssessedOption } = this.props

        if (isEditing) {
            const defaultValue = [this.options.find(opt => opt.value === defaultOption) as SelectOption] || undefined
            const placeholder = localize.translate(
                t => t.Customer.LegalFrameworkView.TopicAssessmentModal.scorePlaceholder
            )

            return (
                <Field forInput={name} className={this.bem.getElement('select-container')}>
                    <Form.Select
                        name={name}
                        options={hideNotAssessedOption ? this.options.slice(0, 3) : this.options}
                        placeholder={placeholder}
                        defaultValue={defaultValue}
                    />
                </Field>
            )
        }

        return <TopicAssessmentIcon status={defaultOption} />
    }
}
