import React from 'react'
import { InfiniteScrollOptions, InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { NewsGroupType, NewsGroupsSourcesDocument, NewsItemSourceType } from '~/generated/graphql'

export type NewsSourcesQueryType = Pick<NewsGroupType, 'id' | 'name'> & { sources: NewsItemSourceType[] }

interface Props {
    children: (options: InfiniteScrollOptions<NewsSourcesQueryType>) => React.ReactNode
}

export class NewsSourcesQuery extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <InfiniteScrollQuery<NewsSourcesQueryType> query={NewsGroupsSourcesDocument}>
                {options => children(options)}
            </InfiniteScrollQuery>
        )
    }
}
