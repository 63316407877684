import React from 'react'
import { localize } from '~/bootstrap'
import { Tombstone } from '~/components/Core/Feedback/Tombstone/Tombstone'
import { TranslationInProgressLabel } from '~/components/Core/Feedback/TranslationInProgressLabel/TranslationInProgressLabel'
import { PageDetail } from '~/components/Core/Layout/PageDetail/PageDetail'
import { PageDetailContentTombstones } from '~/components/Core/Layout/PageDetail/PageDetailContentTombstones'
import { PageDetailMeta } from '~/components/Core/Layout/PageDetail/PageDetailMeta'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ClassValue } from '~/services/BEMService'
import { breadcrumbs } from '~/views/breadcrumbs'

interface Props {
    delayed?: boolean
    className?: ClassValue
}

interface State {
    hidden: boolean
}

export class NewsDetailTranslationTombstonesContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        hidden: !!this.props.delayed,
    }

    private timer: number

    public componentDidMount() {
        if (this.props.delayed) {
            this.timer = window.setTimeout(() => {
                this.setState({
                    hidden: false,
                })
            }, 300)
        }
    }

    public componentWillUnmount() {
        if (this.props.delayed) {
            window.clearTimeout(this.timer)
        }
    }

    public render() {
        const { hidden } = this.state
        const { className } = this.props

        if (hidden) {
            return null
        }

        return (
            <PageDetail
                renderTitle={() => <Tombstone width={640} height={30} />}
                breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).news.index]}
            >
                <PageDetailMeta
                    items={[
                        {
                            label: localize.translate(t => t.News.Attributes.publicationDate),
                            value: <Tombstone width={88} height={8} />,
                        },
                    ]}
                />
                <TranslationInProgressLabel />
                <PageDetailContentTombstones className={className} />
            </PageDetail>
        )
    }
}
