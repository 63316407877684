import React from 'react'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { localize } from '~/bootstrap'
import { DigestFrequencyEnumType } from '~/generated/graphql'

interface Props {
    defaultValue?: DigestFrequencyEnumType
    disabled?: boolean
    name?: string
}

interface State {}

export class EmployeeDigestFrequencySelect extends React.Component<Props, State> {
    public render() {
        const { disabled, name, defaultValue } = this.props
        return (
            <Form.Select
                name={name || 'alertDigestFrequency'}
                searchable={false}
                disabled={disabled}
                clearable={false}
                defaultValue={
                    defaultValue
                        ? [
                              {
                                  label: localize.translate(t => t.User.digestFrequency[defaultValue]),
                                  value: defaultValue,
                              },
                          ]
                        : undefined
                }
                options={Object.values(DigestFrequencyEnumType).map(role => ({
                    label: localize.translate(t => t.User.digestFrequency[role]),
                    value: role,
                }))}
            />
        )
    }
}
