import React from 'react'

import { OnboardingContext, OnboardingContextValue, OnboardingStepItem } from './OnboardingManager'
import ReactDOM from 'react-dom'
import { OnboardingInstructionPosition } from './OnboardingStepInstructions'

export interface OnboardingStepProps {
    order: number
    title: string
    description: string
    position?: OnboardingInstructionPosition
}

interface Props extends OnboardingStepProps {
    children?: (ref: React.RefObject<any>) => React.ReactNode
}

interface State {}

export class OnboardingStep extends React.PureComponent<Props, State> {
    public static contextType = OnboardingContext
    public context: OnboardingContextValue

    public childRef: React.RefObject<any> = React.createRef<any>()

    private step: OnboardingStepItem

    public componentDidMount() {
        const { order, title, description, position } = this.props

        if (!this.childRef.current) {
            return
        }

        const domNode = ReactDOM.findDOMNode(this.childRef.current) as Element | null

        if (!domNode) {
            return
        }

        this.step = {
            order,
            title,
            description,
            position: position ? position : OnboardingInstructionPosition.below,
            domNode,
        }

        this.context.addStep(this.step)
    }

    public componentWillUnmount() {
        this.context.removeStep(this.step)
    }

    public render() {
        const { children } = this.props

        if (!children) {
            return null
        }

        return children(this.childRef)
    }
}
