import React from 'react'
import { PaginatableOptions, PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    InternalAlertLogsQueryDocument,
    InternalAlertLogsQueryQueryVariables,
    InternalAlertLogsQueryQuery,
} from '~/generated/graphql'

interface Props {
    internalAlertId: number
    take?: number
    children: (
        options: PaginatableOptions<InternalAlertLogsQueryType, InternalAlertLogsQueryQueryVariables>
    ) => JSX.Element
}

type InternalAlertLogsQueryType = NonNullable<NonNullable<InternalAlertLogsQueryQuery['internalAlertLogs']>['nodes']>[0]

export class InternalAlertLogsQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, take, internalAlertId } = this.props

        return (
            <PaginatableQuery<InternalAlertLogsQueryType, InternalAlertLogsQueryQueryVariables>
                path="internalAlertLogs"
                take={take}
                query={InternalAlertLogsQueryDocument}
                variables={{
                    internalAlertId,
                }}
            >
                {options => children(options)}
            </PaginatableQuery>
        )
    }
}
