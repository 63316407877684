import './LinkedTaskCard.scss'
import React from 'react'

import { MetaCard } from '../../Core/DataDisplay/MetaCard/MetaCard'
import { LinkedTaskItem } from '~/graphql/types/Task'
import { LinkedTaskLabel } from './LinkedTaskLabel'
import { localize } from '~/bootstrap'
import { DepartmentType } from '~/generated/graphql'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { Row } from '~/components/Core/Layout/Row'
import { BEM } from '~/services/BEMService'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { DepartmentLabelTag } from '../Department/DepartmentLabelTag/DepartmentLabelTag'

interface Props {
    linkedItem?: LinkedTaskItem | null
    possibleDepartments?: Department[]
    showDepartment?: Department
}

type Department = Pick<DepartmentType, 'id' | 'name'>

interface State {}

export class LinkedTaskCard extends React.PureComponent<Props, State> {
    private bem = new BEM('LinkedTaskCard')

    public render() {
        const { linkedItem, possibleDepartments, showDepartment } = this.props

        const title = linkedItem
            ? localize.translate(t => t.Customer.Planning.Tasks.LinkedTaskCard.taskLinkedTo)
            : localize.translate(t => t.Customer.Planning.Tasks.LinkedTaskCard.genericTask)

        return (
            <MetaCard title="" className={this.bem.getClassName()}>
                <Row spaceBetween flexStart>
                    {linkedItem && (
                        <Column>
                            <Paragraph bold>{title}</Paragraph>
                            <LinkedTaskLabel linkedItem={linkedItem} />
                        </Column>
                    )}
                    {!!possibleDepartments?.length && (
                        <Field forInput="linkedItemDepartmentId" className={this.bem.getElement('department-select')}>
                            <Form.Select
                                name="linkedItemDepartmentId"
                                placeholder={localize.translate(t => t.Entities.Department)}
                                options={possibleDepartments.map(d => ({ label: d.name, value: d.id }))}
                            />
                        </Field>
                    )}
                    {showDepartment && (
                        <Column className={this.bem.getElement('department-container')}>
                            <Paragraph bold>{localize.translate(t => t.Entities.Department)}</Paragraph>
                            <DepartmentLabelTag departments={[showDepartment]} />
                        </Column>
                    )}
                </Row>
            </MetaCard>
        )
    }
}
