import './DepartmentLabelTag.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { LabelTag } from '~/components/Core/DataDisplay/LabelTag/LabelTag'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM, ClassValue } from '~/services/BEMService'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    className?: ClassValue
    departments: {
        id: number
        name: string
    }[]
    showInactiveDepartmentsAsInactive?: boolean
}

export class DepartmentLabelTag extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('DepartmentLabelTag')

    public render() {
        const { departments, className } = this.props

        if (!departments.length) {
            return (
                <div className={this.bem.getClassName(className)}>
                    <LabelTag
                        subtle={true}
                        className={this.bem.getElement('tag', () => ({
                            'is-current-department': true,
                            'show-inactive-departments-as-inactive': !!this.props.showInactiveDepartmentsAsInactive,
                        }))}
                        label={this.context.activeDepartment.name}
                    />
                </div>
            )
        }

        if (departments.length === 1) {
            return (
                <div className={this.bem.getClassName()}>
                    <LabelTag
                        subtle={true}
                        className={this.bem.getElement('tag', () => ({
                            'is-current-department': this.context.activeDepartment.id === departments[0].id,
                            'show-inactive-departments-as-inactive': !!this.props.showInactiveDepartmentsAsInactive,
                        }))}
                        label={departments[0].name}
                    />
                </div>
            )
        }

        const tooltipTitle = localize.translate(t => t.Customer.Inbox.departments, {
            count: departments.length,
        })
        const tooltipContent = departments.map(d => this.getTooltipContent(d.id, d.name))

        return (
            <Tooltip
                className={this.bem.getElement('all-departments-tooltip')}
                content={<Row className={this.bem.getElement('tooltip-content')}>{tooltipContent}</Row>}
            >
                <LabelTag subtle={true} label={tooltipTitle} />
            </Tooltip>
        )
    }

    private getTooltipContent(id: number, name: string) {
        const bemElement = this.bem.getElement('tag', () => ({
            'is-current-department': this.context.activeDepartment.id === id,
            'show-inactive-departments-as-inactive': !!this.props.showInactiveDepartmentsAsInactive,
            'prevent-ellipsis': true,
        }))

        return <LabelTag key={`${id}-department`} subtle={true} label={name} className={bemElement} />
    }
}
