import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import './AssessmentScoreBar.scss'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { ThemeTopicAssessmentCoverageScoresType, DepartmentTopicDataGradeTypeEnum } from '~/generated/graphql'
import { Column } from '~/components/Core/Layout/Column'

interface Props {
    className?: ClassValue
    assessments?: ThemeTopicAssessmentCoverageScoresType | null
    noTooltip?: boolean
    title: string
}

export class AssessmentScoreBar extends React.PureComponent<Props> {
    private bem = new BEM('AssessmentScoreBar')

    public render() {
        const { className, title, assessments } = this.props

        if (!assessments) {
            return null
        }

        const scoreBarData = this.getScoreBarData(assessments)

        return (
            <Column noSpacing={true} className={this.bem.getClassName(className)}>
                <Paragraph className={this.bem.getElement('label')} small={true} subtle={true}>
                    {title}
                </Paragraph>
                <div className={this.bem.getElement('assessment-score-bar')}>
                    {scoreBarData.flatMap(this.renderPercentage)}
                </div>
            </Column>
        )
    }

    private getPercentage(assessmentValue: number, totalCount: number) {
        return (assessmentValue / totalCount) * 100
    }

    private getScoreBarData(assessments: ThemeTopicAssessmentCoverageScoresType) {
        const { assessmentSatisfies, assessmentSatisfiesPartly, total, assessmentAlmostSatisfies } = assessments
        const { assessmentAlmostSatisfiesPartly, assessmentNotSatisfies, assessmentNotSatisfiesPartly } = assessments
        const { notApplicable } = assessments

        const satisfiesPercentage = this.getAssessmentPercentage(assessmentSatisfies, assessmentSatisfiesPartly, total)
        const almostSatisfiesPercentage = this.getAssessmentPercentage(
            assessmentAlmostSatisfies,
            assessmentAlmostSatisfiesPartly,
            total
        )
        const notSatisfiesPercentage = this.getAssessmentPercentage(
            assessmentNotSatisfies,
            assessmentNotSatisfiesPartly,
            total
        )

        return [
            { [DepartmentTopicDataGradeTypeEnum.assessmentSatisfies]: satisfiesPercentage },
            { [DepartmentTopicDataGradeTypeEnum.assessmentAlmostSatisfies]: almostSatisfiesPercentage },
            { [DepartmentTopicDataGradeTypeEnum.assessmentNotSatisfies]: notSatisfiesPercentage },
            { [DepartmentTopicDataGradeTypeEnum.assessmentNotGiven]: this.getNotAssessedPercentage(assessments) },
            { [DepartmentTopicDataGradeTypeEnum.notApplicable]: this.getPercentage(notApplicable, total) },
        ] as Record<string, number>[]
    }

    private renderPercentage = (percentageData: Record<string, number>) => {
        return Object.entries(percentageData).map(([gradeType, percentage], i) => {
            if (!percentage) {
                return null
            }
            return (
                <div
                    key={`${gradeType}-${i}`}
                    style={{ width: `${percentage}%` }}
                    className={this.bem.getElement('assessment-score-bar-item', () => ({
                        [`is-${gradeType}`]: !!gradeType,
                    }))}
                />
            )
        })
    }

    private getAssessmentPercentage(count: number, partlyCount: number, total: number) {
        return this.getPercentage(count, total) + this.getPercentage(partlyCount, total) / 2
    }

    private getNotAssessedPercentage(assessments: ThemeTopicAssessmentCoverageScoresType) {
        const { assessmentSatisfiesPartly, assessmentAlmostSatisfiesPartly, assessmentNotSatisfiesPartly } = assessments
        const { assessmentNotGiven, total } = assessments

        return (
            this.getPercentage(assessmentNotGiven, total) +
            this.getPercentage(assessmentSatisfiesPartly, total) / 2 +
            this.getPercentage(assessmentAlmostSatisfiesPartly, total) / 2 +
            this.getPercentage(assessmentNotSatisfiesPartly, total) / 2
        )
    }
}
