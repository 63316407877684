import React from 'react'
import { DrawerModal } from '~/components/Core/Feedback/Modal/DrawerModal'
import { StepContainer } from '~/components/Core/Layout/StepContainer'
import { Step } from '~/components/Core/Layout/Step'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize, notification } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { ControlSelectTable, Control } from '../Controls/ControlSelectTable'
import { GQLMutation } from '~/graphql/Mutation'
import gql from 'graphql-tag'
import { MutationFn } from 'react-apollo'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { AddLinkedControlModal } from '../Controls/AddLinkedControlModal/AddLinkedControlModal'
import { IconType } from '~/components/Core/Icon/IconType'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ASSESSMENT_SECTIONS_QUERY } from './AssessmentSections/AssessmentSectionsInfiniteScrollQuery'

interface Props {
    onClose: () => void
    assessmentId: number
    assessmentSectionId: number
}

interface State {
    search?: string | null
}

const ADD_CONTROL_MUTATION = gql`
    mutation addControlToAssessmentSection($assessmentSectionId: Int!, $controlId: Int!) {
        addControlToAssessmentSection(assessmentSectionId: $assessmentSectionId, controlId: $controlId) {
            id
        }
    }
`

interface AddControlResponse {
    addControlToAssessmentSection: {
        id: number
    }
}

interface AddControlVariables {
    assessmentSectionId: number
    controlId: number
}

export class AssessmentAddControlModal extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        search: null,
    }
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Assessments.AssessmentAddControlModal)

    public render() {
        const { onClose, assessmentId } = this.props
        const { search } = this.state

        return (
            <DrawerModal title={this.loc(t => t.title)} requestClose={onClose} hideButtons={true}>
                <GQLMutation<AddControlResponse, AddControlVariables>
                    mutation={ADD_CONTROL_MUTATION}
                    refetchQueries={[
                        {
                            query: ASSESSMENT_SECTIONS_QUERY,
                            variables: {
                                assessmentId,
                                departmentId: this.context.activeDepartmentId,
                            },
                        },
                    ]}
                >
                    {(mutate, { loading }) => (
                        <StepContainer
                            hideStepCounter={true}
                            steps={[
                                {
                                    content: nextStep => (
                                        <Step
                                            title={this.loc(t => t.steps.controlSelect)}
                                            titleActionComponent={
                                                <Row>
                                                    <Search onChange={search => this.setState({ search })} />
                                                    <ModalManager
                                                        render={openCreateControlModal => (
                                                            <Button
                                                                onClick={openCreateControlModal}
                                                                icon={IconType.add}
                                                                rounded={true}
                                                            >
                                                                {this.loc(t => t.addControl)}
                                                            </Button>
                                                        )}
                                                        renderModal={closeModal => (
                                                            <AddLinkedControlModal
                                                                title={this.loc(t => t.addControl)}
                                                                requestClose={closeModal}
                                                                onSubmit={selectedControl =>
                                                                    this.addControlToAssessmentSection(
                                                                        mutate,
                                                                        selectedControl.id
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </Row>
                                            }
                                            actionBar={
                                                <Button type={ButtonType.tertiary} onClick={onClose}>
                                                    {localize.translate(t => t.Core.DrawerModal.cancel)}
                                                </Button>
                                            }
                                        >
                                            <ControlSelectTable
                                                search={search}
                                                isControlAdded={this.isControlAdded}
                                                onControlSelect={(selectedControl: Control) =>
                                                    this.addControlToAssessmentSection(mutate, selectedControl.id)
                                                }
                                            />
                                        </Step>
                                    ),
                                },
                            ]}
                        />
                    )}
                </GQLMutation>
            </DrawerModal>
        )
    }

    private isControlAdded = (control: Control) => {
        const { assessmentSectionId } = this.props

        return control.assessmentSections.some(({ id }) => id === assessmentSectionId)
    }

    private addControlToAssessmentSection = async (
        mutate: MutationFn<AddControlResponse, AddControlVariables>,
        selectedControlId?: number
    ) => {
        const { assessmentSectionId, onClose } = this.props

        if (!selectedControlId) {
            return
        }

        const response = await mutate({
            variables: {
                assessmentSectionId,
                controlId: selectedControlId,
            },
        })

        if (response && response.data && response.data.addControlToAssessmentSection) {
            notification.success(this.loc(t => t.success))
            onClose()
        }
    }
}
