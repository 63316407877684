import React from 'react'
import { AlertImpactLevel } from '~/generated/graphql'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'
import { InboxItemImpactIcon } from './InboxItemImpactIcon'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    impactLevel: AlertImpactLevel | null
    className?: ClassValue
}

export class InboxItemImpactLabel extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Customer.Inbox.InboxOverview.impactLevel)
    private bem = new BEM('InboxItemImpactLabel')

    public render() {
        const { impactLevel, className } = this.props
        const labelText = this.loc(t => (impactLevel ? t[impactLevel] : t.noImpact))

        return (
            <Row smallSpacing={true} className={this.bem.getClassName(className)}>
                <InboxItemImpactIcon impact={impactLevel} />
                <Paragraph small={true}>{labelText}</Paragraph>
            </Row>
        )
    }
}
