import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { DeleteWidgetMutationVariables, DeleteWidgetDocument, DeleteWidgetMutation } from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    children: (
        mutation: MutationFn<DeleteWidgetMutation, DeleteWidgetMutationVariables>,
        options: MutationResult
    ) => JSX.Element
}

export class DeleteWidgets extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<DeleteWidgetMutation, DeleteWidgetMutationVariables> mutation={DeleteWidgetDocument}>
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
