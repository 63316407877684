import React from 'react'
import { Page } from '~/components/Core/Layout/Page'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Section } from '~/components/Core/Layout/Section'
import { localize } from '~/bootstrap'
import { User } from '~/graphql/types/User'
import { Timeline, TimelineEventType } from '~/components/Core/DataDisplay/Timeline/Timeline'
import {
    AssessmentEventType,
    AssessmentEventRenderer,
    AssessmentEventMetaData,
} from '~/components/Domain/Compliance/Assessments/Logs/AssessmentEventRenderer'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { breadcrumbs } from '~/views/breadcrumbs'
import { AssessmentDetailVariables, GET_ASSESSMENT_DETAIL, AssessmentDetailResponse } from './AssessmentsDetailView'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {}

const GET_ASSESSMENT_LOGS = gql`
    query assessmentLogs($assessmentId: Int!) {
        assessmentLogs(assessmentId: $assessmentId) {
            id
            type
            occuredOn
            metaData
            user {
                id
                profile {
                    id
                    fullName
                }
            }
        }
    }
`

interface AssessmentLogsVariables {
    assessmentId: number
}

interface AssessmentLogsResponse {
    assessmentLogs: {
        id: number
        type: AssessmentEventType
        user: User
        occuredOn: Date
        metaData: AssessmentEventMetaData
    }[]
}

export class AssessmentsLogView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private generalLoc = localize.namespaceTranslate(t => t.Customer.Compliance.Assessments.General)
    public render() {
        const id = this.props.match.params.id ? parseInt(this.props.match.params.id, 10) : undefined

        if (!id) {
            return null
        }

        return (
            <Query<AssessmentDetailResponse, AssessmentDetailVariables>
                query={GET_ASSESSMENT_DETAIL}
                variables={{ id }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    if (!data || !data.assessment) {
                        return <NoResults />
                    }

                    const { assessment } = data

                    return (
                        <Query<AssessmentLogsResponse, AssessmentLogsVariables>
                            query={GET_ASSESSMENT_LOGS}
                            variables={{ assessmentId: id }}
                        >
                            {({ data, loading }) => {
                                if (loading) {
                                    return <Spinner delayed={true} />
                                }

                                if (!data || !data.assessmentLogs) {
                                    return <NoResults />
                                }

                                const events: TimelineEventType[] = data.assessmentLogs.map(
                                    (log): TimelineEventType => ({
                                        date: log.occuredOn,
                                        content: () => (
                                            <AssessmentEventRenderer
                                                assessmentEvent={{
                                                    date: log.occuredOn,
                                                    type: log.type,
                                                    user: log.user,
                                                    metaData: { ...log.metaData },
                                                }}
                                            />
                                        ),
                                    })
                                )

                                return (
                                    <>
                                        <Page>
                                            <PageHeader
                                                title={assessment.name}
                                                breadCrumbs={[
                                                    breadcrumbs.customer(this.context.customer.slug).risks.index,
                                                    breadcrumbs.customer(this.context.customer.slug).risks.assessments
                                                        .index,
                                                    breadcrumbs
                                                        .customer(this.context.customer.slug)
                                                        .risks.assessments.view(assessment.name, id),
                                                ]}
                                            />
                                            <Section title={this.generalLoc(t => t.log)}>
                                                <Timeline events={events} />
                                            </Section>
                                        </Page>
                                    </>
                                )
                            }}
                        </Query>
                    )
                }}
            </Query>
        )
    }
}
