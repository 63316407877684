import './AnnotatedCheckboxList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { AnnotatedCheckboxValue, AnnotatedCheckbox } from './AnnotatedCheckbox'

interface Props {
    className?: ClassValue
    onChange?: (value: AnnotatedCheckboxValue, index: number, id?: number) => void
    items: {
        name?: string
        id?: number
        label: string | JSX.Element
        labelIcon?: JSX.Element
        labelPlaceholder?: string
        indeterminate?: boolean
        defaultChecked?: boolean
        defaultAnnotationValue?: string | null
        isDisabled?: boolean
        singleLine?: boolean
    }[]
}

interface State {}

export class AnnotatedCheckboxList extends React.PureComponent<Props, State> {
    private bem = new BEM('AnnotatedCheckboxList')

    public render() {
        const { items, onChange, className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {items.map((item, index) => {
                    const name = item.name || 'annotated-checkbox-list-item'

                    return (
                        <AnnotatedCheckbox
                            className={this.bem.getElement('item')}
                            key={`${index}-${name}`}
                            name={name}
                            label={item.label}
                            labelIcon={item.labelIcon}
                            labelPlaceholder={item.labelPlaceholder}
                            onChange={changeValue => onChange && onChange(changeValue, index, item.id)}
                            defaultChecked={item.defaultChecked}
                            defaultAnnotationValue={item.defaultAnnotationValue}
                            isDisabled={item.isDisabled}
                            singleLine={item.singleLine}
                        />
                    )
                })}
            </div>
        )
    }
}
