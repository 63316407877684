import React from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router'
import { routes } from '~/views/routes'
import { CustomersOverviewView } from './CustomersOverviewView'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { CustomerDetailOverviewView } from './Customer/CustomerDetailOverviewView'
import { CreateCustomerView } from './Customer/CreateCustomerView'

interface Props extends RouteComponentProps<{}> {}

export class CustomersView extends React.PureComponent<Props> {
    public render() {
        return (
            <Switch>
                <Route path={routes.consultant.customers.create} component={CreateCustomerView} />
                <Route path={routes.consultant.customers.index} component={CustomersOverviewView} exact={true} />
                <Route path={routes.consultant.customers.view()} component={CustomerDetailOverviewView} />
                <Route component={AppNotFoundView} />
            </Switch>
        )
    }
}
