import React from 'react'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { BEM, ClassValue } from '~/services/BEMService'
import './AddLinkedMonitoringReportList.scss'
import { AddLinkedMonitoringReportButton } from './AddLinkedMonitoringReportButton'
import { LinkedMonitoringReportCard } from '../LinkedMonitoringReportCard/LinkedMonitoringReportCard'
import { MonitoringReportsQueryType } from '../MonitoringReportsTable/MonitoringReportsQuery'

export interface AddLinkedMonitoringReportListValue {
    id: number
    label: string
}

interface Props {
    className?: ClassValue
    defaultData?: AddLinkedMonitoringReportListValue[]
    onChange?: (data: AddLinkedMonitoringReportListValue[]) => void
}

interface State {
    data: AddLinkedMonitoringReportListValue[]
}

export class AddLinkedMonitoringReportList extends React.Component<Props, State> {
    public state: State = {
        data: this.props.defaultData || [],
    }

    private bem = new BEM('AddLinkedMonitoringReportList')

    public componentDidMount() {
        const { onChange } = this.props

        if (onChange) {
            onChange(this.state.data)
        }
    }

    public render() {
        const { className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {this.renderList()}
                <AddLinkedMonitoringReportButton
                    className={this.bem.getElement('button')}
                    data={this.state.data}
                    onSubmit={this.onCreateMonitoringReport}
                />
            </div>
        )
    }

    private renderList() {
        const { data } = this.state

        return data.map(item => (
            <LinkedMonitoringReportCard
                key={item.id}
                className={this.bem.getElement('card')}
                monitoringReport={{ id: item.id, name: item.label } as MonitoringReportsQueryType}
                rightComponent={
                    <Button type={ButtonType.noStyling} onClick={() => this.handleOnRemoveMonitoringReport(item)}>
                        <Icon type={IconType.close} />
                    </Button>
                }
            />
        ))
    }

    private onCreateMonitoringReport = (monitoringReport: MonitoringReportsQueryType) => {
        const { onChange } = this.props
        const { data } = this.state

        const newListValue: AddLinkedMonitoringReportListValue = {
            id: monitoringReport.id,
            label: monitoringReport.name,
        }

        const newData = [...data, newListValue]

        this.setState({ data: newData }, () => {
            if (onChange) {
                onChange(newData)
            }
        })
    }

    private handleOnRemoveMonitoringReport = (monitoringReportItem: AddLinkedMonitoringReportListValue) => {
        const { onChange } = this.props
        const { data } = this.state
        const newData = data.filter(item => item.id !== monitoringReportItem.id)

        this.setState({ data: newData }, () => {
            if (onChange) {
                onChange(newData)
            }
        })
    }
}
