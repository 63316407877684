import './InboxItem.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { Row } from '~/components/Core/Layout/Row'
import { Column } from '~/components/Core/Layout/Column'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { InboxStatus, Item, FirstTask } from './InboxItemQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'
import { AlertImpactLevel } from '~/generated/graphql'
import { InboxItemActions, InboxItemActionsAlertType } from '~/components/Domain/Inbox/InboxItemActions'
import { IconType } from '~/components/Core/Icon/IconType'
import { Icon } from '~/components/Core/Icon/Icon'

interface Props {
    className?: ClassValue
    inboxItem: InboxCardItem
    infoActionIconOnly?: boolean
    checked?: boolean
    handleOnCardCheck?: (checked: boolean) => void
    isFirstItem?: boolean
    isCheckboxActive?: boolean
    refetch: () => void
}

interface InboxCardItem {
    id: number
    name: string
    inboxStatus: InboxStatus | null
    customerFrameworks: CustomerFramework[]
    isPublishedFromCustomerNews?: boolean | null
    publishedAt: string
    item?: Item | null
    firstTask?: FirstTask | null
    impactLevel: AlertImpactLevel | null
    allPresentDepartments: {
        id: number
        name: string
    }[]
}

interface State {}

export class InboxItem extends React.Component<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('InboxItem')

    public render() {
        const { className, handleOnCardCheck, checked, isFirstItem, isCheckboxActive, inboxItem } = this.props
        const { refetch, infoActionIconOnly } = this.props

        return (
            <Card
                isCheckboxActive={isCheckboxActive}
                isFirstCardInList={isFirstItem}
                className={this.bem.getClassName(className)}
                checked={checked}
                onCheckChange={!infoActionIconOnly ? handleOnCardCheck : undefined}
            >
                {this.renderTitle()}
                <InboxItemActions
                    alert={inboxItem as InboxItemActionsAlertType}
                    refetchOverview={refetch}
                    infoIconOnly={infoActionIconOnly}
                />
            </Card>
        )
    }

    private renderTitle() {
        const { name, publishedAt, id, isPublishedFromCustomerNews } = this.props.inboxItem

        return (
            <Column noSpacing={true} className={this.bem.getElement('title-container')}>
                <Row smallSpacing={true}>
                    <ComponentTitle
                        to={routes.customer(this.context.customer.slug).inbox.detail.view(id)}
                        title={name}
                        bold={true}
                        className={this.bem.getElement('title')}
                    />
                    {isPublishedFromCustomerNews && (
                        <Icon
                            large
                            subtler
                            type={IconType.signaling}
                            className={this.bem.getElement('signaling-icon')}
                        />
                    )}
                </Row>
                {this.renderDate(publishedAt)}
            </Column>
        )
    }

    private renderDate(date: string) {
        return (
            <Paragraph subtle={true}>
                <DateFormat date={new Date(date)} readable={true} includeTime={true} noWeekday={true} />
            </Paragraph>
        )
    }
}
