import React from 'react'

import { ContextTasksTable } from './ContextTasksTable'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ContextOpenTasksTableQuery } from './ContextOpenTasksTableQuery'
import { localize } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'
import { TasksQueryFilters } from '~/views/Customer/Tasks/TasksQuery/TaskQuery'

interface Props {
    filters?: TasksQueryFilters
    onChange?: () => void
    registerRefetchFunction?: (refetch: any) => void
    forCustomer?: boolean
    forDepartment?: number
}

export class ContextOpenTasksTableContainer extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Customer.Task.taskModalView)
    public render() {
        return this.renderTasksTable()
    }

    private renderTasksTable = () => {
        const { filters, onChange, registerRefetchFunction, forCustomer, forDepartment } = this.props

        return (
            <ContextOpenTasksTableQuery forCustomer={forCustomer} forDepartment={forDepartment} filters={filters}>
                {({ data, loading, loadingMore, fetchMore, refetch, canFetchMore, hasPaginated }) => {
                    const tasks = data ? data.nodes : []
                    const total = data ? data.totalCount - tasks.length : 0
                    const nextAmount = Math.min(total, 10)

                    /**
                     * This function 'passes' the refetch function to the parent.
                     * The way the components are tied together requires us to do this.
                     */
                    if (registerRefetchFunction) {
                        // TODO refactor
                        registerRefetchFunction(refetch)
                    }

                    return (
                        <>
                            <ContextTasksTable
                                showHeaders={forCustomer}
                                showDepartment={forCustomer}
                                tasks={tasks}
                                loading={loading}
                                loadingMore={loadingMore}
                                endReached={!canFetchMore && hasPaginated}
                                onChange={() => {
                                    if (onChange) {
                                        onChange()
                                    }

                                    refetch()
                                }}
                                emptyState={this.loc(t => t.emptyOpenTasks)}
                            />
                            {canFetchMore && (
                                <Button
                                    onClick={() => fetchMore()}
                                    type={ButtonType.actionLink}
                                    icon={IconType.arrowDown}
                                >
                                    {this.loc(t => t.showMoreOpenTasks, { smart_count: nextAmount })}
                                </Button>
                            )}
                        </>
                    )
                }}
            </ContextOpenTasksTableQuery>
        )
    }
}
