import './FilterButton.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Button, ButtonType } from '../../Button/Button'
import { IconType } from '../../Icon/IconType'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    onClick?: () => void
    isFilterOpen?: boolean
    isFilterActive?: boolean
}

interface State {}

export class FilterButton extends React.PureComponent<Props, State> {
    private bem = new BEM('FilterButton', () => ({
        'is-filter-open': this.props.isFilterOpen,
        'is-filter-active': this.props.isFilterActive,
    }))

    public render() {
        const { className, onClick } = this.props

        return (
            <Button
                icon={IconType.filter}
                type={ButtonType.inputLike}
                className={this.bem.getClassName(className)}
                onClick={onClick}
            >
                {localize.translate(t => t.Generic.filter)}
            </Button>
        )
    }
}
