import './MonitoringReportTopicAssessmentSummaryTooltip.scss'
import React from 'react'
import {
    MonitoringReportTopicAssessmentGrade,
    TopicAssessmentDesignOrEffectiveNessType,
    TopicAssessmentGradeType,
} from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize } from '~/bootstrap'
import { TopicAssessmentLabel } from '../../Topic/TopicDesignAndEffectiveness/modals/TopicAssessment/TopicAssessmentLabel'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Popper } from '~/components/Core/Popper/Popper'
import { Markdown } from '~/components/Core/Text/Markdown'

interface Props {
    topicId: number
    children: JSX.Element
    showWarningBanner?: boolean
    hideLink?: boolean
    assessmentType: TopicAssessmentDesignOrEffectiveNessType
    documentsCount: number
    grade: MonitoringReportTopicAssessmentGrade | TopicAssessmentGradeType
    description?: string | null
    assessedAt?: Date
}

export class MonitoringReportTopicAssessmentSummaryTooltip extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('MonitoringReportTopicAssessmentSummaryTooltip')
    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.MonitoringDetailView.SummaryTooltip)

    public render() {
        const { children } = this.props

        return (
            <Popper
                className={this.bem.getClassName()}
                contents={[{ item: this.renderContent() }]}
                item={children}
                position="top"
            />
        )
    }

    private renderContent() {
        const { showWarningBanner, description, assessedAt } = this.props

        return (
            <Column bigSpacing className={this.bem.getElement('container')}>
                {showWarningBanner && this.renderWarningBanner()}
                {this.renderTitle()}
                {description && this.renderDescription()}
                {assessedAt && this.renderAssessedAt(assessedAt)}
            </Column>
        )
    }

    private renderTitle() {
        const { assessmentType, documentsCount, grade } = this.props

        return (
            <Column smallSpacing>
                <Row spaceBetween>
                    <Paragraph small emphasis>
                        {this.loc(t => t[assessmentType])}
                    </Paragraph>
                    {!!documentsCount && this.renderDocumentsCount()}
                </Row>
                <TopicAssessmentLabel status={grade || undefined} />
            </Column>
        )
    }

    private renderWarningBanner() {
        const { topicId, hideLink } = this.props

        if (hideLink) {
            return (
                <Row extraSmallSpacing className={this.bem.getElement('warning-banner')}>
                    <Paragraph small>{this.loc(t => t.warningWithoutLink)}</Paragraph>
                </Row>
            )
        }

        const to = routes.customer(this.context.customer.slug).legalFramework.topic.designAndEffectiveness(topicId)

        return (
            <Row extraSmallSpacing className={this.bem.getElement('warning-banner')}>
                <Paragraph small>
                    {this.loc(t => t.warning1)}
                    <Button external to={to} type={ButtonType.actionLink} className={this.bem.getElement('link')}>
                        {this.loc(t => t.warning2)}
                    </Button>
                </Paragraph>
            </Row>
        )
    }

    private renderDocumentsCount() {
        const { documentsCount } = this.props

        return (
            <Row smallSpacing>
                <Icon type={IconType.attachment} />
                <Paragraph small bold>
                    {localize.translate(t => t.Generic.document, { smart_count: documentsCount })}
                </Paragraph>
            </Row>
        )
    }

    private renderDescription() {
        const { description } = this.props

        return (
            <Column smallSpacing>
                <Paragraph small emphasis>
                    {localize.translate(t => t.Generic.explanation)}
                </Paragraph>
                <Markdown className={this.bem.getElement('description')} paragraphLike={true} source={description} />
            </Column>
        )
    }

    private renderAssessedAt(assessedAt: Date) {
        const formattedDate = localize.dateFormat(new Date(assessedAt), { readable: true, noWeekday: true })

        return (
            <Paragraph subtle small emphasis>
                {this.loc(t => t.assessedAt, { date: formattedDate })}
            </Paragraph>
        )
    }
}
