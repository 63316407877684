import React from 'react'
import { TextLinkProps, TextLink } from '../../Text/TextLink'
import { Paragraph } from '../../Typography/Paragraph'

interface Props extends TextLinkProps {}

export const TableLink: React.SFC<Props> = props => {
    const { children, ...textLinkProps } = props

    return (
        <TextLink {...textLinkProps}>
            <Paragraph emphasis={true}>{children}</Paragraph>
        </TextLink>
    )
}
