import React from 'react'
import { localize } from '~/bootstrap'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { DatePicker } from '~/components/Core/Date/DatePicker'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { LinkedTaskItemType } from '~/graphql/types/Task'
import { LinkedTaskTypeFilter } from '../Task/LinkedTaskTypeFilter'
import { TaskAssigneesFilter } from '../Task/TaskAssigneesFilter'
import { TaskDepartmentFilter } from '../Task/TaskDepartmentFilter'
import { TaskStatusFilter } from '../Task/TaskStatusFilter'
import { TaskTagsSelect } from '../Task/TaskTagsSelect'

export interface AllTasksFilters {
    forEmployeeIds?: number[]
    completedOnly?: boolean
    tagIds?: SelectOption<number>[]
    activeDepartmentOnly?: boolean
    linkedItemType?: LinkedTaskItemType
    linkedItemInboxFromDate?: Date
    linkedItemInboxToDate?: Date
}

interface Props {
    defaultState: AllTasksFilters
    onChange: (newState: AllTasksFilters) => void
}

enum EmployeeOption {
    ownTasks = 'ownTasks',
    everyonesTasks = 'everyonesTasks',
}

enum StatusOption {
    completed = 'isCompleted',
    open = 'isOpen',
}

export class AllTasksViewFilterButtonContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks.filters)

    public render() {
        return (
            <FilterDropdown autoHeight={true} hasActiveFilter={this.checkIfFilterIsActive()}>
                {this.renderLinkedTypeOption()}
                {this.renderForEmployeeOption()}
                {this.renderStatusOption()}
                {this.renderTagsOption()}
                {this.renderDepartmentOption()}
            </FilterDropdown>
        )
    }

    private checkIfFilterIsActive() {
        const { forEmployeeIds, completedOnly, tagIds, activeDepartmentOnly, linkedItemType } = this.props.defaultState

        if (linkedItemType) {
            return true
        }

        if (!forEmployeeIds) {
            return true
        }

        if (completedOnly) {
            return true
        }

        if (tagIds && tagIds.length > 0) {
            return true
        }

        if (!activeDepartmentOnly) {
            return true
        }

        return false
    }

    private renderLinkedTypeOption() {
        const { linkedItemType, linkedItemInboxFromDate, linkedItemInboxToDate } = this.props.defaultState

        return (
            <>
                <FilterOption label={this.loc(t => t.taskLinkedTasks)} forInputName="linkedItemType">
                    <LinkedTaskTypeFilter
                        defaultValue={linkedItemType}
                        onChange={type => {
                            this.handleOnChange({ linkedItemType: type || undefined })
                        }}
                    />
                </FilterOption>
                {linkedItemType === LinkedTaskItemType.alert && (
                    <>
                        <FilterOption
                            label={this.loc(t => t.taskAlertsDateFrom)}
                            forInputName={'linkedItemInboxFromDate'}
                            isSubFilter={true}
                        >
                            <DatePicker
                                name={'linkedItemInboxFromDate'}
                                defaultValue={linkedItemInboxFromDate ? new Date(linkedItemInboxFromDate) : undefined}
                                onChange={(option: Date) =>
                                    this.handleOnChange({ linkedItemInboxFromDate: option || undefined })
                                }
                            />
                        </FilterOption>
                        <FilterOption
                            label={this.loc(t => t.taskAlertsDateTo)}
                            forInputName={'linkedItemInboxToDate'}
                            isSubFilter={true}
                        >
                            <DatePicker
                                name={'linkedItemInboxToDate'}
                                defaultValue={linkedItemInboxToDate ? new Date(linkedItemInboxToDate) : undefined}
                                onChange={(option: Date) =>
                                    this.handleOnChange({ linkedItemInboxToDate: option || undefined })
                                }
                            />
                        </FilterOption>
                    </>
                )}
            </>
        )
    }

    private renderForEmployeeOption() {
        const { forEmployeeIds } = this.props.defaultState

        return (
            <FilterOption label={this.loc(t => t.taskEmployees)} forInputName="taskEmployeesFilter">
                <TaskAssigneesFilter
                    defaultValue={forEmployeeIds ? EmployeeOption.ownTasks : EmployeeOption.everyonesTasks}
                    onChange={option => {
                        const ownTasksOnly = option?.value === EmployeeOption.ownTasks && this.context.employee
                        const forEmployeeIds = ownTasksOnly ? [this.context.employee!.id] : undefined

                        this.handleOnChange({ forEmployeeIds })
                    }}
                />
            </FilterOption>
        )
    }

    private renderStatusOption() {
        const { completedOnly } = this.props.defaultState

        return (
            <FilterOption label={this.loc(t => t.taskStatus)} forInputName="taskStatusFilter">
                <TaskStatusFilter
                    defaultValue={completedOnly ? StatusOption.completed : StatusOption.open}
                    onChange={option => {
                        const completedOnly = (option && option.value === StatusOption.completed) || false
                        this.handleOnChange({ completedOnly })
                    }}
                />
            </FilterOption>
        )
    }

    private renderTagsOption() {
        const { tagIds } = this.props.defaultState

        return (
            <FilterOption label={this.loc(t => t.taskTags)} forInputName="taskTags">
                <TaskTagsSelect
                    isFilter={true}
                    name={'taskTags'}
                    defaultValue={tagIds}
                    onChange={options => this.handleOnChange({ tagIds: options || [] })}
                />
            </FilterOption>
        )
    }

    private renderDepartmentOption() {
        const { activeDepartmentOnly } = this.props.defaultState

        return (
            <FilterOption label={this.loc(t => t.taskDepartments)} forInputName="taskDepartmentFilter">
                <TaskDepartmentFilter
                    defaultValue={activeDepartmentOnly ? 'true' : 'false'}
                    onChange={option => this.handleOnChange({ activeDepartmentOnly: option?.value === 'true' })}
                />
            </FilterOption>
        )
    }

    private handleOnChange(newStateOption: AllTasksFilters) {
        const { onChange, defaultState } = this.props
        const newState = { ...defaultState, ...newStateOption }

        if (newState.linkedItemType !== LinkedTaskItemType.alert) {
            newState.linkedItemInboxFromDate = undefined
            newState.linkedItemInboxToDate = undefined
        }

        this.setState(newState)
        onChange(newState)
    }
}
