import gql from 'graphql-tag'

export interface LogoutMutationResponse {
    logoutSession: boolean
}

export const logoutMutation = gql`
    mutation logoutSession ($token: String) {
        logoutSession(token: $token)
    }

`
