import './InboxGroupTable.scss'
import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { BEM } from '~/services/BEMService'
import { ColumnOptions, RowData, Table } from '~/components/Core/DataDisplay/Table/Table'
import { DepartmentAlertDataType } from '~/generated/graphql'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { DepartmentLabelTag } from '../../Department/DepartmentLabelTag/DepartmentLabelTag'
import { InboxControlAlertLabel } from './InboxControlAlertLabel'
import { TaskOpenClosedCounter } from '../../Task/TaskOpenClosedCounter'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { UndoIgnoreInboxControlButtonContainer } from './UndoIgnoreInboxControlButtonContainer'
import { TaskIgnoredForControlTooltip } from '../TaskControl/TaskControlTable/TaskIgnoredForControlTooltip'
import { IgnoreControlAlertModal } from './IgnoreControlAlertModal'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { CreateControlTaskModal } from '../CreateControlTaskModal/CreateControlTaskModal'
import { Guard } from '~/components/Core/Guard/Guard'

interface Props {
    isCheckboxActive: boolean
    bulkActionIds: string[]
    onCheck: (alertData: DepartmentAlertDataType, checked: boolean) => void
    hideCheckboxAnimation?: boolean
    departmentAlerts: DepartmentAlertDataType[]
    refetch: () => void
    hidePublishedDate?: boolean
}

export class InboxGroupTable extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Customer.InboxControl.InboxControlTable)
    private bem = new BEM('InboxGroupTable')

    public render() {
        const { hideCheckboxAnimation } = this.props

        return (
            <Table
                className={this.bem.getClassName()}
                hideHeaders={true}
                columns={this.getColumns()}
                data={this.getTableData()}
                rowAction={a => this.renderRowActions(a.row.id as string)}
                hideInitialCheckboxAnimation={hideCheckboxAnimation}
            />
        )
    }
    private getColumns(): ColumnOptions[] {
        return [
            { field: 'name', headerLabel: this.loc(t => t.name), truncateField: true, sortable: true },
            { field: 'department', headerLabel: localize.translate(t => t.Entities.Department), sortable: true },
            { field: 'status', headerLabel: this.loc(t => t.status) },
            { field: 'publishedAt', headerLabel: this.loc(t => t.date), sortable: true },
        ]
    }

    private getTableData(): RowData[] {
        const { departmentAlerts, onCheck, bulkActionIds, isCheckboxActive, hidePublishedDate } = this.props

        return departmentAlerts.map(d => {
            const { id, department, inboxStatus, alert } = d
            const hideArchive = !permissions.canArchiveInboxItems(department.id)
            const { publishedAt } = alert

            return {
                id: id,
                columns: {
                    name: this.renderName(d),
                    department: department?.name && (
                        <DepartmentLabelTag
                            showInactiveDepartmentsAsInactive={true}
                            departments={[department]}
                            className={this.bem.getElement('deparmtent')}
                        />
                    ),
                    status: this.getStatus(d),
                    publishedAt: !hidePublishedDate && publishedAt && (
                        <DateFormat date={new Date(publishedAt)} readable={true} noWeekday={true} />
                    ),
                },
                onCheckChange: hideArchive || inboxStatus?.archivedAt ? undefined : (_, checked) => onCheck(d, checked),
                isCheckboxChecked: bulkActionIds.includes(id),
                isCheckboxActive: isCheckboxActive,
            }
        })
    }

    private renderRowActions(rowItemId: string) {
        const { departmentAlerts, refetch } = this.props

        const alertData = departmentAlerts.find(d => d.id === rowItemId)
        if (!alertData) {
            return
        }

        const { inboxStatus, department, alert } = alertData
        const canArchive = permissions.canArchiveInboxItems(department.id)

        if (inboxStatus?.ignoredAt) {
            return (
                <div className={this.bem.getElement('actions')}>
                    <Guard condition={canArchive}>
                        <Tooltip direction={ToolTipDirection.downEnd} message={this.loc(t => t.revertTooltip)}>
                            <UndoIgnoreInboxControlButtonContainer
                                alertId={alert.id}
                                alertDepartmentId={department?.id}
                                onUnarchive={refetch}
                                className={this.bem.getElement('revert-ignore')}
                            />
                        </Tooltip>
                    </Guard>
                    <TaskIgnoredForControlTooltip
                        ignoredAt={inboxStatus.ignoredAt}
                        ignoredBy={inboxStatus.ignoredBy || undefined}
                        ignoredReason={inboxStatus.ignoredReason}
                    />
                </div>
            )
        }

        return (
            <div className={this.bem.getElement('actions')}>
                <div className={this.bem.getElement('hidden-actions')}>
                    <Guard condition={canArchive}>
                        <IgnoreControlAlertModal alertDatas={[alertData]} onIgnore={refetch}>
                            {openModal => (
                                <Button
                                    onClick={openModal}
                                    type={ButtonType.secondary}
                                    icon={IconType.ignoreControlTask}
                                />
                            )}
                        </IgnoreControlAlertModal>
                    </Guard>
                    <Guard condition={permissions.canCreateTasks(department.id)}>
                        <ModalManager
                            renderModal={closeModal => (
                                <CreateControlTaskModal
                                    requestClose={closeModal}
                                    alertDatas={[alertData]}
                                    onCreate={refetch}
                                />
                            )}
                            render={openModal => <Button onClick={openModal} icon={IconType.createControlTask} />}
                        />
                    </Guard>
                </div>
            </div>
        )
    }

    private renderName(departmentAlertData: DepartmentAlertDataType) {
        const { id, name, isPublishedFromCustomerNews } = departmentAlertData.alert

        return (
            <InboxControlAlertLabel
                className={this.bem.getElement('label')}
                alertId={id}
                alertName={name}
                alertDepartment={departmentAlertData.department}
                impactLevel={departmentAlertData?.impactLevel?.impactLevel}
                isPublishedFromCustomerNews={isPublishedFromCustomerNews}
            />
        )
    }

    private getStatus(alertData: DepartmentAlertDataType) {
        const { inboxStatus, hasLinkedTask, openTaskCount, overdueTaskCount, completedTaskCount } = alertData

        if (hasLinkedTask) {
            return (
                <TaskOpenClosedCounter
                    openAmount={openTaskCount}
                    overDueAmount={overdueTaskCount}
                    closedAmount={completedTaskCount}
                />
            )
        }

        if (inboxStatus?.archivedAt) {
            return <Paragraph>{localize.translate(t => t.Generic.archived)}</Paragraph>
        }

        return <Paragraph>{localize.translate(t => t.Generic.new)}</Paragraph>
    }
}
