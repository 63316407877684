import React from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router'
import { routes } from '~/views/routes'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { ThemesOverviewView } from '~/views/Customer/LegalFramework/Themes/ThemesOverviewView'
import { TopicDetailView } from '~/views/Customer/LegalFramework/Topics/TopicDetailView'
import { ThemeDetailView } from '~/views/Customer/LegalFramework/Themes/ThemeDetailView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TopicDetailEditView } from './Themes/ThemeDetailEditView'
import { TopicDesignAndEffectivenessView } from './Topics/TopicDesignAndEffectivenessView'

interface Props extends RouteComponentProps<{}> {}
interface State {}

export class LegalFrameworkView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Switch>
                {/* Themes */}
                <Route
                    path={routes.customer(this.context.customer.slug).legalFramework.index}
                    exact={true}
                    component={ThemesOverviewView}
                />

                {/* Topics */}
                <Route
                    path={routes.customer(this.context.customer.slug).legalFramework.themes.view()}
                    exact={true}
                    component={ThemeDetailView}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).legalFramework.themes.viewApplicable()}
                    exact={true}
                    component={TopicDetailEditView}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).legalFramework.topic.view()}
                    exact={true}
                    component={TopicDetailView}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).legalFramework.topic.designAndEffectiveness()}
                    exact={true}
                    component={TopicDesignAndEffectivenessView}
                />
                <Route component={AppNotFoundView} />
            </Switch>
        )
    }
}
