import './InboxControlOverviewView.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { DatePicker } from '~/components/Core/Date/DatePicker'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { ParamManager, SetParamStateFn } from '~/components/Core/ParamManager/ParamManager'
import { InboxDepartmentFilter, InboxDepartmentFilterOption } from '~/components/Domain/Inbox/InboxDepartmentFilter'
import { InboxStatus, InboxStatusSelect } from '~/components/Domain/Inbox/InboxStatusSelect'
import { InboxControlSummaryList } from '~/components/Domain/ControlViews/InboxControl/InboxControlSummaryList'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'

export interface InboxControlFilters {
    departmentId?: number
    isIgnored?: boolean
    hasControls?: boolean
    allItems?: boolean
    dateFrom?: string | Date
    dateTo?: string | Date
}

interface Props {}

interface State {
    stateUpdateCounter: number
}

export class InboxControlOverviewView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        stateUpdateCounter: 0,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.InboxControl.InboxControlOverview)
    private bem = new BEM('InboxControlOverviewView')

    public render() {
        return (
            <ParamManager<InboxControlFilters> defaultState={{}}>
                {({ paramState, setParamState }) => (
                    <Page className={this.bem.getClassName()}>
                        <PageHeader
                            className={this.bem.getElement('header')}
                            title={this.loc(t => t.title)}
                            actionComponent={this.renderFilters(paramState, setParamState)}
                        />
                        <div className={this.bem.getElement('table-container')}>
                            <InboxControlSummaryList
                                key={this.state.stateUpdateCounter}
                                className={this.bem.getElement('table')}
                                paramState={paramState}
                                isFilterActive={this.isFilterActive(paramState)}
                            />
                        </div>
                    </Page>
                )}
            </ParamManager>
        )
    }

    private renderFilters(paramState: InboxControlFilters, setParamState: SetParamStateFn<InboxControlFilters>) {
        const { departmentId, dateFrom, dateTo } = paramState
        const { stateUpdateCounter } = this.state

        const defaultDepartmentFilterOption = departmentId
            ? InboxDepartmentFilterOption.activeDepartment
            : InboxDepartmentFilterOption.allDepartments

        return (
            <Row>
                <FilterDropdown hasActiveFilter={this.isFilterActive(paramState)}>
                    <FilterOption label={this.loc(t => t.filters.status)} forInputName="status">
                        <InboxStatusSelect
                            isFilter={true}
                            name={'status'}
                            defaultValue={this.getDefaultValueForInboxStatusFilter(paramState)}
                            onChange={this.handleInboxStatusChange(paramState, setParamState)}
                            forInboxControl={true}
                        />
                    </FilterOption>
                    <FilterOption label={this.loc(t => t.filters.department)} forInputName="department">
                        <InboxDepartmentFilter
                            name="department"
                            defaultValue={defaultDepartmentFilterOption}
                            onChange={option => {
                                setParamState({
                                    ...paramState,
                                    departmentId:
                                        option?.value === 'true' ? this.context.activeDepartmentId : undefined,
                                })
                                this.setState({ stateUpdateCounter: stateUpdateCounter + 1 })
                            }}
                        />
                    </FilterOption>
                    <FilterOption label={this.loc(t => t.filters.dateFrom)} forInputName="dateFrom">
                        <DatePicker
                            name="dateFrom"
                            defaultValue={dateFrom ? new Date(dateFrom) : undefined}
                            onChange={(option: Date) => {
                                setParamState({ ...paramState, dateFrom: option || undefined })
                                this.setState({ stateUpdateCounter: stateUpdateCounter + 1 })
                            }}
                        />
                    </FilterOption>
                    <FilterOption label={this.loc(t => t.filters.dateTo)} forInputName="dateTo">
                        <DatePicker
                            name="dateTo"
                            defaultValue={dateTo ? new Date(dateTo) : undefined}
                            onChange={(option: Date) => {
                                setParamState({ ...paramState, dateTo: option || undefined })
                                this.setState({ stateUpdateCounter: stateUpdateCounter + 1 })
                            }}
                        />
                    </FilterOption>
                </FilterDropdown>
            </Row>
        )
    }

    private isFilterActive(paramState: InboxControlFilters) {
        const { hasControls, allItems, isIgnored, dateTo, dateFrom, departmentId } = paramState

        if (departmentId) {
            return true
        }

        if (hasControls) {
            return true
        }

        if (allItems) {
            return true
        }

        if (isIgnored) {
            return true
        }

        if (dateTo) {
            return true
        }

        if (dateFrom) {
            return true
        }

        return false
    }

    private getDefaultValueForInboxStatusFilter(paramState: InboxControlFilters) {
        const labelLoc = localize.namespaceTranslate(t => t.Customer.Inbox.InboxOverview)

        if (paramState.hasControls) {
            return [{ label: labelLoc(t => t.hasControls), value: InboxStatus.hasControls }]
        }

        if (paramState.allItems) {
            return [{ label: labelLoc(t => t.allItems), value: InboxStatus.allItems }]
        }

        if (paramState.isIgnored) {
            return [{ label: labelLoc(t => t.ignored), value: InboxStatus.isIgnored }]
        }

        return [{ label: labelLoc(t => t.newItems), value: InboxStatus.new }]
    }

    private handleInboxStatusChange =
        (paramState: InboxControlFilters, setParamState: SetParamStateFn<InboxControlFilters>) =>
        (option?: SelectOption) => {
            const newInboxStatusFilterState = {
                allItems: option?.value === InboxStatus.allItems,
                hasControls: option?.value === InboxStatus.hasControls,
                isIgnored: option?.value === InboxStatus.isIgnored,
            }

            setParamState({ ...paramState, ...newInboxStatusFilterState })
            this.setState({ stateUpdateCounter: this.state.stateUpdateCounter + 1 })
        }
}
