import { addDays, subDays } from 'date-fns'
import { isNumber } from 'lodash-es'
import React from 'react'
import { localize } from '~/bootstrap'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { DatePicker } from '~/components/Core/Date/DatePicker'
import { NewsGroupSelect } from '~/components/Domain/News/NewsGroupSelect'
import { NewsSourceSelect } from '~/components/Domain/News/NewsSourceSelect'

export interface SearchOverviewFilterOptions {
    newsGroupId?: SelectOption | null
    newsSourceId?: SelectOption | null
    startAt?: Date | null
    endAt?: Date | null
}

interface Props {
    defaultFilters: SearchOverviewFilterOptions
    onFilterChange: (state: SearchOverviewFilterOptions) => void
}

interface State extends SearchOverviewFilterOptions {}

export class SearchOverviewFilterDropdown extends React.PureComponent<Props, State> {
    public state: State = {
        newsGroupId: this.props.defaultFilters.newsGroupId,
        newsSourceId: this.props.defaultFilters.newsSourceId,
        startAt: this.props.defaultFilters.startAt,
        endAt: this.props.defaultFilters.endAt,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Search.SearchOverviewView.FilterDropdown)

    public render() {
        return (
            <FilterDropdown hasActiveFilter={this.checkIfFilterActive()}>
                {this.renderNewsGroupSelect()}
                {this.renderNewsSourceSelect()}
                {this.renderStartDatePicker()}
                {this.renderEndDatePicker()}
            </FilterDropdown>
        )
    }

    private checkIfFilterActive() {
        const { newsGroupId, startAt, endAt } = this.state

        if (newsGroupId) {
            return true
        }

        // because newsSourceId is a subFilter of newsGroupId, no need to check for it

        if (startAt) {
            return true
        }

        if (endAt) {
            return true
        }

        return false
    }

    private renderNewsGroupSelect() {
        const { onFilterChange } = this.props
        const { newsGroupId } = this.state

        return (
            <FilterOption label={localize.translate(t => t.Entities.Group)} forInputName="group">
                <NewsGroupSelect
                    name={'group'}
                    isFilter={true}
                    defaultValue={newsGroupId ? [newsGroupId] : undefined}
                    onChange={option => {
                        const newState = !option.value
                            ? { ...this.state, newsGroupId: null, newsSourceId: null }
                            : { ...this.state, newsGroupId: option, newsSourceId: null }

                        this.setState(newState, () => onFilterChange(newState))
                    }}
                />
            </FilterOption>
        )
    }

    private renderNewsSourceSelect() {
        const { newsGroupId, newsSourceId } = this.state
        if (!newsGroupId || !isNumber(newsGroupId.value)) {
            return
        }

        const { onFilterChange } = this.props

        return (
            <FilterOption label={localize.translate(t => t.Entities.Source)} forInputName="source" isSubFilter={true}>
                <NewsSourceSelect
                    name={'source'}
                    isFilter={true}
                    defaultValue={newsSourceId ? [newsSourceId] : undefined}
                    newsGroupId={newsGroupId.value}
                    showAllSources={true}
                    onChange={option => {
                        const newState = !option.value
                            ? { ...this.state, newsSourceId: null }
                            : { ...this.state, newsSourceId: option }

                        this.setState(newState, () => onFilterChange(newState))
                    }}
                />
            </FilterOption>
        )
    }

    private renderStartDatePicker() {
        const { onFilterChange } = this.props
        const { endAt, startAt } = this.state

        return (
            <FilterOption label={this.loc(t => t.startAt)} forInputName="startAt">
                <DatePicker
                    name={'startAt'}
                    defaultValue={startAt ? new Date(startAt) : undefined}
                    onChange={(startAt: Date | null) => {
                        const newState = { ...this.state, startAt }
                        this.setState(newState, () => onFilterChange(newState))
                    }}
                    maxDate={endAt ? subDays(endAt, 1) : undefined}
                    clearable={true}
                    closeOnSelect={true}
                />
            </FilterOption>
        )
    }

    private renderEndDatePicker() {
        const { onFilterChange } = this.props
        const { startAt, endAt } = this.state

        return (
            <FilterOption label={this.loc(t => t.endAt)} forInputName="endAt">
                <DatePicker
                    name={'endAt'}
                    defaultValue={endAt ? new Date(endAt) : undefined}
                    onChange={(endAt: Date | null) => {
                        const newState = { ...this.state, endAt }
                        this.setState(newState, () => onFilterChange(newState))
                    }}
                    minDate={startAt ? addDays(startAt, 1) : undefined}
                    clearable={true}
                    closeOnSelect={true}
                />
            </FilterOption>
        )
    }
}
