import './CustomerFrameworkList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import { FrameworkIcon } from '../Customer/Framework/FrameworkIcon/FrameworkIcon'
import { CustomerFrameworkType } from '~/generated/graphql'

interface Props {
    className?: ClassValue
    customerFrameworks: Pick<CustomerFrameworkType, 'id' | 'icon' | 'color' | 'name'>[]
    smallIcons?: boolean
}

interface State {}

export class CustomerFrameworkList extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('CustomerFrameworkList')

    public render() {
        const { className } = this.props

        return <ul className={this.bem.getClassName(className)}>{this.renderFrameworks()}</ul>
    }

    private isInactive(framework: CustomerFramework) {
        return this.context.activeProfiles.includes(framework.id) === false
    }

    private renderFrameworks() {
        const { customerFrameworks, smallIcons } = this.props

        return customerFrameworks.map(framework => (
            <li className={this.bem.getElement('framework-item')} key={framework.id}>
                <FrameworkIcon
                    frameworkType={framework.icon}
                    color={framework.color}
                    isInactive={this.isInactive(framework)}
                    isExtraSmall={smallIcons}
                />
                <span>{framework.name}</span>
            </li>
        ))
    }
}
