import './ControlsTopicSelectionTableContainer.scss'
import React from 'react'
import { localize } from '~/bootstrap'
import { GroupedSelectOption, Select, SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM } from '~/services/BEMService'
import { ControlTopicSelectionTable, ThemeWithTopics } from './ControlTopicSelectionTable'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import {
    AllSelectableTopicsDocument,
    AllSelectableTopicsQuery,
    AllSelectableTopicsQueryVariables,
} from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'

interface Props {
    onCheckedTopics: (checkedTopics: Record<number, number[]>) => void
    disabled?: boolean
    controlId: number
}

interface State {
    selectedTopicId: number | null
    selectedThemeId: number | null
    expandedRowsIds: number[]
}

interface TopicOptionValue {
    id: number
    parentThemeId: number
}

type QueryResult = NonNullable<AllSelectableTopicsQuery['minimalAllTopics']>[0]

export class ControlsTopicSelectionTableContainer extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        selectedTopicId: null,
        selectedThemeId: null,
        expandedRowsIds: [],
    }

    private bem = new BEM('ControlsTopicSelectionTableContainer')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.SelectThemesAndTopicsModal)
    private tableRef = React.createRef<ControlTopicSelectionTable>()

    public render() {
        return (
            <PageQuery<QueryResult[], AllSelectableTopicsQueryVariables>
                query={AllSelectableTopicsDocument}
                variables={{
                    customerSlug: this.context.customer.slug,
                    filters: {
                        departmentId: this.context.activeDepartmentId,
                        excludeNonApplicableTopics: true,
                        excludeLinkedToControl: this.props.controlId,
                    },
                }}
            >
                {data => {
                    const themesWithTopics = this.getThemesAndTopicsData(data)

                    return (
                        <>
                            {this.renderFilters(themesWithTopics)}
                            <Paragraph bold className={this.bem.getElement('header')}>
                                {this.loc(t => t.header)}
                            </Paragraph>
                            <ControlTopicSelectionTable
                                ref={this.tableRef}
                                themesWithTopics={themesWithTopics}
                                expandedIds={this.state.expandedRowsIds}
                                onChange={this.props.onCheckedTopics}
                                disabled={this.props.disabled}
                            />
                        </>
                    )
                }}
            </PageQuery>
        )
    }

    private renderFilters(themesWithTopics: ThemeWithTopics[]) {
        const themeOptions: SelectOption<number>[] = themesWithTopics.map(t => ({ label: t.name || '', value: t.id }))
        const topicOptions: GroupedSelectOption<TopicOptionValue>[] = themesWithTopics.map(th => ({
            label: th.name || '',
            options: th.topics.map(t => ({
                label: t.name,
                value: { id: t.id, parentThemeId: th.id },
            })),
        }))

        return (
            <Row flexStart fullWidth className={this.bem.getElement('filters')}>
                <Select
                    disabled={!!this.state.selectedTopicId}
                    name="themeId"
                    onChange={this.handleOnThemeChange}
                    placeholder={this.loc(t => t.selectTheme)}
                    options={themeOptions}
                    searchable
                    clearable
                    openMenuOnClick={false}
                    openMenuOnFocus={false}
                />
                <Select
                    name="topicId"
                    placeholder={this.loc(t => t.selectTopic)}
                    disabled={!!this.state.selectedThemeId}
                    options={topicOptions}
                    onChange={this.handleOnTopicChange}
                    searchable
                    clearable
                    openMenuOnClick={false}
                    openMenuOnFocus={false}
                />
            </Row>
        )
    }

    private handleOnTopicChange = (selectedTopic: SelectOption<TopicOptionValue>) => {
        if (!selectedTopic) {
            this.setState({ selectedTopicId: null, expandedRowsIds: [] })
            return
        }

        const newState = {
            expandedRowsIds: [selectedTopic.value.parentThemeId],
            selectedTopicId: selectedTopic.value.id,
        }
        this.setState(newState, () => this.tableRef.current?.scrollToRow(selectedTopic.value.parentThemeId))
    }

    private handleOnThemeChange = (selectedTheme: SelectOption<number>) => {
        if (!selectedTheme) {
            this.setState({ selectedThemeId: null, expandedRowsIds: [] })
            return
        }

        const newState = { expandedRowsIds: [selectedTheme.value], selectedThemeId: selectedTheme.value }
        this.setState(newState, () => this.tableRef.current?.scrollToRow(selectedTheme.value))
    }

    private getThemesAndTopicsData(topics: QueryResult[]) {
        const themesAndTopics = topics.reduce((acc, { theme, ...topic }) => {
            if (!acc[theme.id]) {
                acc[theme.id] = { ...theme, topics: [] }
            }

            acc[theme.id].topics.push(topic)

            return acc
        }, {} as Record<number, ThemeWithTopics>)

        return sortBy(
            Object.values(themesAndTopics).map(t => ({ ...t, topics: uniqBy(t.topics, 'id') })),
            'name'
        )
    }
}
