import './Cover.scss'

import React from 'react'
import gql from 'graphql-tag'

import { routes } from '~/views/routes'
import { BEM } from '~/services/BEMService'
import { RouteComponentProps, NavLink, Link } from 'react-router-dom'
import { localize } from '~/bootstrap'
import { Logo } from '~/components/Chrome/Logo/Logo'
import { PageTitle } from '~/components/Core/Text/PageTitle'
import { GQLMutation } from '~/graphql/Mutation'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'

interface Props extends RouteComponentProps<{ token: string }> {}

interface State {
    loading: boolean
    failed: boolean
    success: boolean
}

const CONFIRM_ACCOUNT_MUTATION = gql`
    mutation confirmAccountEmail($token: String) {
        confirmAccountEmail(token: $token)
    }
`

type MutationResponse = boolean

export class ConfirmAccountView extends React.PureComponent<Props, State> {
    public state: State = {
        loading: false,
        success: false,
        failed: false,
    }

    private loc = localize.namespaceTranslate(t => t.Cover.ConfirmAccount)
    private bem = new BEM('Cover')

    public render() {
        const { token: tokenFromURL } = this.props.match.params

        return (
            <div className={this.bem.getClassName()}>
                <form>
                    <div className={this.bem.getElement('brand')}>
                        <NavLink to={routes.cover.index}>
                            <Logo />
                        </NavLink>
                    </div>
                    <div className={this.bem.getElement('line')} />
                    <GQLMutation<MutationResponse, { token: string }> mutation={CONFIRM_ACCOUNT_MUTATION}>
                        {(mutate, { loading, data, error }) => {
                            if (!loading && !data && !error) {
                                mutate({ variables: { token: tokenFromURL } })
                                return
                            }

                            if (loading) {
                                return this.renderLoadingContent()
                            }

                            if (error) {
                                return this.renderErrorContent()
                            }

                            return this.renderSuccessContent()
                        }}
                    </GQLMutation>
                </form>
            </div>
        )
    }

    private renderLoadingContent = () => {
        return (
            <>
                <PageTitle title={this.loc(t => t.loading)} />

                <p className={this.bem.getElement('info')}>
                    <Spinner />
                </p>
            </>
        )
    }

    private renderErrorContent = () => {
        return (
            <>
                <PageTitle title={this.loc(t => t.failed.title)} />

                <p className={this.bem.getElement('info')}>{this.loc(t => t.failed.subtitle)}</p>
            </>
        )
    }

    private renderSuccessContent = () => {
        return (
            <>
                <PageTitle title={this.loc(t => t.success.title)} />

                <p className={this.bem.getElement('info')}>
                    {this.loc(t => t.success.subtitle)}
                    <br />
                    <br />
                    <Link to={routes.cover.login}>{this.loc(t => t.success.toLogin)}</Link>
                </p>
            </>
        )
    }
}
