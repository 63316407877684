// tslint:disable

import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Sidebar } from '~/components/Chrome/Sidebar/Sidebar'
import { Action } from '~/components/Core/Action/Action'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { Table } from '~/components/Core/DataDisplay/Table/Table'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { TableList } from '~/components/Core/DataDisplay/TableList/TableList'
import { TableListItem } from '~/components/Core/DataDisplay/TableList/TableListItem'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Page } from '~/components/Core/Layout/Page'
import { PageDetailMeta } from '~/components/Core/Layout/PageDetail/PageDetailMeta'
import { Row } from '~/components/Core/Layout/Row'
import { Section } from '~/components/Core/Layout/Section'
import { SidebarLayout } from '~/components/Core/Layout/SidebarLayout'
import { ArticleTitle } from '~/components/Core/Text/ArticleTitle'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { ModalTitle } from '~/components/Core/Text/ModalTitle'
import { PageTitle } from '~/components/Core/Text/PageTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { TextEditor } from '~/components/Core/Text/TextEditor/TextEditor'
import { FrameworkIcon } from '~/components/Domain/Customer/Framework/FrameworkIcon/FrameworkIcon'
import { FrameworkIconList } from '~/components/Domain/Customer/Framework/FrameworkIconList/FrameworkIconList'
import { BEM } from '~/services/BEMService'
import { ColorSchemeCard } from '~/views/Customer/Kitchensink/ColorScheme/ColorSchemeCard'
import { ColorSchemeGroup } from '~/views/Customer/Kitchensink/ColorScheme/ColorSchemeGroup'
import { ColorSchemeGrid } from './ColorScheme/ColorSchemeGrid'
import { IconList } from './Icons/IconList'
import { IconListItem } from './Icons/IconListItem'
import { ReleaseUpdateModalManager } from '~/components/Domain/ReleaseUpdate/ReleaseUpdateModalManager'
import { CustomerFrameworkIconEnumType, CustomerFrameworkColorEnumType } from '~/generated/graphql'

interface RouteParams {}

interface Props extends RouteComponentProps<RouteParams> {}

export class KitchensinkView extends React.PureComponent<Props> {
    private bem = new BEM('KitchensinkView')

    public render() {
        const navlinks = [
            {
                label: 'Color Scheme',
                route: '#Color%20Scheme',
                icon: IconType.eye,
                routeIsSectionId: true,
            },
            {
                label: 'Icons',
                route: '#Icons',
                icon: IconType.profiles,
                routeIsSectionId: true,
            },
            {
                label: 'Buttons',
                route: '#Buttons',
                icon: IconType.menuWorkflow,
                routeIsSectionId: true,
            },
            {
                label: 'Inputs',
                route: '#Inputs',
                icon: IconType.consult,
                routeIsSectionId: true,
            },
            {
                label: 'Typography',
                route: '#Typography',
                icon: IconType.edit,
                routeIsSectionId: true,
            },
            {
                label: 'Table',
                route: '#Table',
                icon: IconType.menuDashboard,
                routeIsSectionId: true,
            },
            {
                label: 'TableList',
                route: '#TableList',
                icon: IconType.menuWorkflow,
                routeIsSectionId: true,
            },
            {
                label: 'TextLinks',
                route: '#TextLinks',
                icon: IconType.textLink,
                routeIsSectionId: true,
            },
            {
                label: 'Cards',
                route: '#Cards',
                icon: IconType.creditcard,
                routeIsSectionId: true,
            },
            {
                label: 'Actions in page',
                route: '#Actions%20in%20page',
                icon: IconType.screenshot,
                routeIsSectionId: true,
            },
            {
                label: 'Meta items',
                route: '#Meta%20Items',
                icon: IconType.attachment,
                routeIsSectionId: true,
            },
        ]

        return (
            <SidebarLayout>
                <Sidebar heading={'Kitchensink'} navigation={navlinks} />

                <Page className={this.bem.getClassName()}>
                    <Section title={'Color Scheme'}>
                        <ComponentTitle title="Brand colors" />
                        <ColorSchemeGrid>
                            <ColorSchemeGroup>
                                <ColorSchemeCard
                                    colorHex={'#507bff'}
                                    colorName={'Ruler Light Blue'}
                                    colorVariable={'$color-ruler-light-blue'}
                                />
                                <ColorSchemeCard
                                    colorHex={'#1d55ff'}
                                    colorName={'Ruler Blue'}
                                    colorVariable={'$color-ruler-blue'}
                                    mainColor={true}
                                />
                                <ColorSchemeCard
                                    colorHex={'#003ae9'}
                                    colorName={'Ruler Dark Blue'}
                                    colorVariable={'$color-ruler-dark-blue'}
                                />
                            </ColorSchemeGroup>

                            <ColorSchemeGroup>
                                <ColorSchemeCard
                                    colorHex={'#575757'}
                                    colorName={'Ruler Light Black'}
                                    colorVariable={'$color-ruler-light-black'}
                                />
                                <ColorSchemeCard
                                    colorHex={'#3d3d3d'}
                                    colorName={'Ruler Black'}
                                    colorVariable={'$color-ruler-black'}
                                    mainColor={true}
                                />
                                <ColorSchemeCard
                                    colorHex={'#242424'}
                                    colorName={'Ruler Dark Black'}
                                    colorVariable={'$color-ruler-dark-black'}
                                />
                            </ColorSchemeGroup>

                            <ColorSchemeGroup>
                                <ColorSchemeCard
                                    colorHex={'#1dff9a'}
                                    colorName={'Ruler Light Green'}
                                    colorVariable={'$color-ruler-light-green'}
                                    invertTextColor={true}
                                />
                                <ColorSchemeCard
                                    colorHex={'#00e981'}
                                    colorName={'Ruler Green'}
                                    colorVariable={'$color-ruler-green'}
                                    mainColor={true}
                                />
                                <ColorSchemeCard
                                    colorHex={'#00b665'}
                                    colorName={'Ruler Dark Green'}
                                    colorVariable={'$color-ruler-dark-green'}
                                />
                            </ColorSchemeGroup>
                        </ColorSchemeGrid>

                        <ComponentTitle title="Grey colors" />
                        <ColorSchemeGrid>
                            <ColorSchemeCard
                                colorHex={'#f2f4f5'}
                                colorName={'Light Grey'}
                                colorVariable={'$color-grey-10'}
                                invertTextColor={true}
                            />
                            <ColorSchemeCard
                                colorHex={'#e0e1e5'}
                                colorName={'Medium Grey'}
                                colorVariable={'$color-medium-grey'}
                                invertTextColor={true}
                            />
                            <ColorSchemeCard colorHex={'#b1b1b1'} colorName={'Grey'} colorVariable={'$color-grey-65'} />
                            <ColorSchemeCard
                                colorHex={'#4a4948'}
                                colorName={'Dark Grey'}
                                colorVariable={'$color-dark-grey'}
                            />
                        </ColorSchemeGrid>
                        <ColorSchemeGrid>
                            <ColorSchemeCard
                                colorHex={'#f3f6ff'}
                                colorName={'Blue Grey'}
                                colorVariable={'$color-ruler-pale'}
                                invertTextColor={true}
                            />
                            <ColorSchemeCard
                                colorHex={'#828282'}
                                colorName={'Brown Grey'}
                                colorVariable={'$color-brown-grey'}
                            />
                        </ColorSchemeGrid>
                        <ComponentTitle title="UI colors" />
                        <ColorSchemeGrid>
                            <ColorSchemeCard
                                colorHex={'#e4003a'}
                                colorName={'Cherry'}
                                colorVariable={'$color-ui-red'}
                            />
                            <ColorSchemeCard
                                colorHex={'#ff9100'}
                                colorName={'Tangerine'}
                                colorVariable={'$color-ui-orange'}
                            />
                            <ColorSchemeCard
                                colorHex={'#00e981'}
                                colorName={'Turquoise'}
                                colorVariable={'$color-ui-green'}
                            />
                            <ColorSchemeCard
                                colorHex={'#6b94f9'}
                                colorName={'Faded Blue'}
                                colorVariable={'$color-faded-blue'}
                            />
                        </ColorSchemeGrid>

                        <ComponentTitle title="Profile colors" />
                        <ColorSchemeGrid>
                            <ColorSchemeCard
                                colorHex={'#991b82'}
                                colorName={'Profile Purple'}
                                colorVariable={'$color-profile-purple'}
                            />
                            <ColorSchemeCard
                                colorHex={'#1f2769'}
                                colorName={'Profile Dark Blue'}
                                colorVariable={'$color-profile-dark-blue'}
                            />
                            <ColorSchemeCard
                                colorHex={'#0f8ab2'}
                                colorName={'Profile Blue'}
                                colorVariable={'$color-profile-blue'}
                            />
                            <ColorSchemeCard
                                colorHex={'#62e7f7'}
                                colorName={'Profile Cyan'}
                                colorVariable={'$color-profile-cyan'}
                            />
                        </ColorSchemeGrid>

                        <ColorSchemeGrid>
                            <ColorSchemeCard
                                colorHex={'#5dd39e'}
                                colorName={'Profile Green'}
                                colorVariable={'$color-profile-green'}
                            />
                            <ColorSchemeCard
                                colorHex={'#ffd46f'}
                                colorName={'Profile Yellow'}
                                colorVariable={'$color-profile-yellow'}
                            />
                            <ColorSchemeCard
                                colorHex={'#f89c3d'}
                                colorName={'Profile Orange'}
                                colorVariable={'$color-profile-orange'}
                            />
                            <ColorSchemeCard
                                colorHex={'#f95858'}
                                colorName={'Profile Red'}
                                colorVariable={'$color-profile-red'}
                            />
                        </ColorSchemeGrid>

                        <ColorSchemeGrid>
                            <ColorSchemeCard
                                colorHex={'#f67695'}
                                colorName={'Profile Pink'}
                                colorVariable={'$color-profile-pink'}
                            />
                            <ColorSchemeCard
                                colorHex={'#000000'}
                                colorName={'Profile Black'}
                                colorVariable={'$color-profile-black'}
                            />
                            <ColorSchemeCard
                                colorHex={'#d8d8d8'}
                                colorName={'Profile Grey'}
                                colorVariable={'$color-profile-grey'}
                                invertTextColor={true}
                            />
                        </ColorSchemeGrid>
                    </Section>

                    <Section title={'Icons'}>
                        <ComponentTitle title="Icon Types" />
                        <IconList>{this.getIconTypes()}</IconList>

                        <ComponentTitle title="Profile Icon Types" />
                        <IconList>{this.getProfileIconTypes()}</IconList>
                    </Section>

                    <Section title={'Buttons'}>
                        <ComponentTitle title="Buttons" />
                        <Row>{this.getButtonTypes()}</Row>
                    </Section>

                    <Section title={'Inputs'}>
                        <ComponentTitle title="Markdown Editor" />
                        <TextEditor name={'editor'} />
                    </Section>

                    <Section title={'Typography'}>
                        <Row evenSpace={true}>
                            <ArticleTitle title="ArticleTitle" />
                            <ArticleTitle
                                title="ArticleTitle with inline Profiles"
                                suffix={
                                    <FrameworkIconList>
                                        <FrameworkIcon
                                            frameworkType={CustomerFrameworkIconEnumType.algemeen1}
                                            color={'blue'}
                                            tooltip={'Pensioenfonds'}
                                        />
                                        <FrameworkIcon
                                            frameworkType={CustomerFrameworkIconEnumType.algemeen10}
                                            color={'yellow'}
                                            tooltip={'Transport'}
                                        />
                                    </FrameworkIconList>
                                }
                            />
                        </Row>
                        <Row>
                            <PageTitle title="PageTitle" />
                        </Row>
                        <Row>
                            <ModalTitle title="ModalTitle" />
                        </Row>
                        <Row>
                            <ComponentTitle title="ComponentTitle" />
                        </Row>
                    </Section>

                    <Section title={'Table'}>
                        <ComponentTitle title="Table with sorting" />
                        <Table
                            defaultSortDirection={{ field: 'name', direction: 'DESC' }}
                            onSortDirectionChange={(field, dir) => console.log(field, dir)}
                            columns={[
                                { field: 'name', headerLabel: 'Naam', sortable: true },
                                { field: 'source', headerLabel: 'Bron', sortable: true },
                                { field: 'date', headerLabel: 'Datum' },
                            ]}
                            data={sampleTableData}
                        />

                        <ComponentTitle title="Table without headers" />
                        <Table
                            onSortDirectionChange={(field, dir) => console.log(field, dir)}
                            columns={[
                                { field: 'name', headerLabel: 'Naam' },
                                { field: 'source', headerLabel: 'Bron' },
                                { field: 'date', headerLabel: 'Datum' },
                            ]}
                            data={sampleTableData}
                            hideHeaders={true}
                        />
                    </Section>

                    <Section title={'TableList'}>
                        <ComponentTitle title="TableList (single column)" />
                        <TableList>
                            <TableListItem>{'Thema 1'}</TableListItem>
                            <TableListItem>{'Thema 2'}</TableListItem>
                            <TableListItem>{'Thema 3'}</TableListItem>
                            <TableListItem>{'Thema 4'}</TableListItem>
                            <TableListItem>{'Thema 5'}</TableListItem>
                            <TableListItem>{'Thema 6'}</TableListItem>
                            <TableListItem>{'Thema 7'}</TableListItem>
                            <TableListItem>{'Thema 8'}</TableListItem>
                            <TableListItem>{'Thema 9'}</TableListItem>
                            <TableListItem>{'Thema 10'}</TableListItem>
                        </TableList>

                        <ComponentTitle title="TableList (2 columns)" />
                        <TableList columns={2}>
                            <TableListItem>{'Thema 1'}</TableListItem>
                            <TableListItem>{'Thema 2'}</TableListItem>
                            <TableListItem>{'Thema 3'}</TableListItem>
                            <TableListItem>{'Thema 4'}</TableListItem>
                            <TableListItem>{'Thema 5'}</TableListItem>
                            <TableListItem>{'Thema 6'}</TableListItem>
                            <TableListItem>{'Thema 7'}</TableListItem>
                            <TableListItem>{'Thema 8'}</TableListItem>
                            <TableListItem>{'Thema 9'}</TableListItem>
                            <TableListItem>{'Thema 10'}</TableListItem>
                        </TableList>

                        <ComponentTitle title="TableList (3 columns)" />
                        <TableList columns={3}>
                            <TableListItem>{'Thema 1'}</TableListItem>
                            <TableListItem>{'Thema 2'}</TableListItem>
                            <TableListItem>{'Thema 3'}</TableListItem>
                            <TableListItem>{'Thema 4'}</TableListItem>
                            <TableListItem>{'Thema 5'}</TableListItem>
                            <TableListItem>{'Thema 6'}</TableListItem>
                            <TableListItem>{'Thema 7'}</TableListItem>
                            <TableListItem>{'Thema 8'}</TableListItem>
                            <TableListItem>{'Thema 9'}</TableListItem>
                            <TableListItem>{'Thema 10'}</TableListItem>
                        </TableList>

                        <ComponentTitle title="TableList (4 columns)" />
                        <TableList columns={4}>
                            <TableListItem>{'Thema 1'}</TableListItem>
                            <TableListItem>{'Thema 2'}</TableListItem>
                            <TableListItem>{'Thema 3'}</TableListItem>
                            <TableListItem>{'Thema 4'}</TableListItem>
                            <TableListItem>{'Thema 5'}</TableListItem>
                            <TableListItem>{'Thema 6'}</TableListItem>
                            <TableListItem>{'Thema 7'}</TableListItem>
                            <TableListItem>{'Thema 8'}</TableListItem>
                            <TableListItem>{'Thema 9'}</TableListItem>
                            <TableListItem>{'Thema 10'}</TableListItem>
                        </TableList>
                    </Section>

                    <Section title={'Cards'}>
                        <ComponentTitle title="Card" />
                        <Row>
                            <Card>
                                <Paragraph>Standard card</Paragraph>
                                <Paragraph>Test content</Paragraph>
                            </Card>
                            <Card selected={true}>
                                <Paragraph>Selected card</Paragraph>
                                <Paragraph>Test content</Paragraph>
                            </Card>
                        </Row>
                    </Section>

                    <Section title={'Actions in page'}>
                        <ComponentTitle title="Action in page" />
                        <Row>
                            <Action
                                title={'Mate van beheersing'}
                                description={'Nog geen geoordeling'}
                                button={<Button type={ButtonType.secondary}>Toelichting aanmaken</Button>}
                            />
                        </Row>
                    </Section>

                    <Section title={'Meta Items'}>
                        <PageDetailMeta
                            items={[
                                {
                                    label: 'Title',
                                    value: 'Text under title',
                                },
                                {
                                    label: 'Title',
                                    value: 'Text under title',
                                },
                            ]}
                        />
                    </Section>

                    <Section title={'New Releases'}>
                        <ReleaseUpdateModalManager />
                    </Section>
                </Page>
            </SidebarLayout>
        )
    }

    private getIconTypes() {
        return Object.keys(IconType).map((type: IconType, key) => {
            return (
                <IconListItem>
                    <Icon key={key} type={type} />
                    <p>{type}</p>
                </IconListItem>
            )
        })
    }

    private getProfileIconTypes() {
        return Object.keys(CustomerFrameworkIconEnumType).map((type: CustomerFrameworkIconEnumType, key) => {
            return (
                <IconListItem>
                    <FrameworkIcon
                        key={key}
                        frameworkType={type}
                        color={
                            Object.keys(CustomerFrameworkColorEnumType)[
                                key % Object.keys(CustomerFrameworkColorEnumType).length
                            ]
                        }
                    />
                    <p>{type}</p>
                </IconListItem>
            )
        })
    }

    private getButtonTypes() {
        return (
            <>
                <Button>Primary</Button>

                {Object.keys(ButtonType).map((type, key) => {
                    if (type === 'icon') {
                        return (
                            <>
                                <Button key={key} icon={IconType.add}>
                                    Icon Button
                                </Button>
                                <Button key={key} type={ButtonType[type]} icon={IconType.add} />
                            </>
                        )
                    }

                    return (
                        <Button key={key} type={ButtonType[type]}>
                            {type}
                        </Button>
                    )
                })}
            </>
        )
    }
}

const sampleTableData = [
    {
        id: 1,
        columns: {
            name: <TableLink>College van Beroep voor het bedrijfsleven</TableLink>,
            source: 'Rechtspraak',
            date: <DateFormat date={new Date()} />,
        },
    },
    {
        id: 2,
        columns: {
            name: <TableLink>College van Beroep voor het bedrijfsleven</TableLink>,
            source: 'Rechtspraak',
            date: <DateFormat date={new Date()} />,
        },
    },
    {
        id: 3,
        columns: {
            name: <TableLink>College van Beroep voor het bedrijfsleven</TableLink>,
            source: 'Rechtspraak',
            date: <DateFormat date={new Date()} />,
        },
    },
    {
        id: 4,
        columns: {
            name: <TableLink>College van Beroep voor het bedrijfsleven</TableLink>,
            source: 'Rechtspraak',
            date: <DateFormat date={new Date()} />,
        },
    },
    {
        id: 5,
        columns: {
            name: <TableLink>College van Beroep voor het bedrijfsleven</TableLink>,
            source: 'Rechtspraak',
            date: <DateFormat date={new Date()} />,
        },
    },
    {
        id: 6,
        columns: {
            name: <TableLink>College van Beroep voor het bedrijfsleven</TableLink>,
            source: 'Rechtspraak',
            date: <DateFormat date={new Date()} />,
        },
    },
]
