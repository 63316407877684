import './Markdown.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import showdown from 'showdown'

interface Props {
    className?: ClassValue
    source?: string | null
    paragraphLike?: boolean
}

interface State {}

export class Markdown extends React.PureComponent<Props, State> {
    private renderer = new showdown.Converter({
        tables: true,
        openLinksInNewWindow: true,
    })

    private html = this.renderer
        .makeHtml(this.props.source || '')
        .replace(/\n/g, '') // remove remaining newlines because they are already converted to <br> - the WYSIWYG also seem to do this
        .replace(/start="\d+"/g, '') // what's this for?

    private bem = new BEM('Markdown', () => ({
        paragraphLike: this.props.paragraphLike,
    }))

    public render() {
        const { className } = this.props

        return <div className={this.bem.getClassName(className)} dangerouslySetInnerHTML={{ __html: this.html }} />
    }
}
