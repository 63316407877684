import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import {
    ArchiveConsultantTaskTemplateDocument,
    ArchiveConsultantTaskTemplateMutation,
    ArchiveConsultantTaskTemplateMutationVariables,
} from '~/generated/graphql'

interface Props {
    children: (
        mutation: MutationFn<ArchiveConsultantTaskTemplateMutation, ArchiveConsultantTaskTemplateMutationVariables>,
        options: MutationResult
    ) => JSX.Element
}

export type ArchiveConsultantTaskTemplateFN = MutationFn<
    ArchiveConsultantTaskTemplateMutation,
    ArchiveConsultantTaskTemplateMutationVariables
>

export class ArchiveConsultantTaskTemplate extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<ArchiveConsultantTaskTemplateMutation, ArchiveConsultantTaskTemplateMutationVariables>
                mutation={ArchiveConsultantTaskTemplateDocument}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
