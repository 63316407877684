import './RadarWidget.scss'

import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { WidgetKey } from '../../CreateWidgetWidget/widgets'
import { WidgetContainer } from '../components/WidgetContainer'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize } from '~/bootstrap'
import { Bold } from '~/components/Core/Typography/Bold'
import {
    DashboardDepartmentSpecificWidgetMetadataType,
    DashboardRadarWidgetFragmentFragment,
    DashboardWidgetContentFragmentFragment,
    DashboardWidgetLayoutTypeEnum,
    DashboardWidgetMetadataFragmentFragment,
} from '~/generated/graphql'

interface Props {
    isEditing: boolean
    currentBreakpoint: DashboardWidgetLayoutTypeEnum | undefined
    onWidgetClick?: (widgetId: number, type: WidgetKey) => void
    onDeleteIconClick?: (widgetId: number) => void
    onModalStateChange?: ({ isOpening }: { isOpening: boolean }) => void
    isDetailWidget?: boolean
    id: number
    metadata?: DashboardWidgetMetadataFragmentFragment
    content?: DashboardWidgetContentFragmentFragment
}

export class RadarWidget extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('RadarWidget')
    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView.Widgets.RadarWidget)

    public render() {
        const { isEditing, onWidgetClick, onDeleteIconClick, isDetailWidget, currentBreakpoint } = this.props
        const { onModalStateChange } = this.props

        return (
            <WidgetContainer
                widgetInfo={this.getHeaderInfo()}
                isEditing={isEditing}
                className={this.bem.getClassName()}
                onWidgetClick={onWidgetClick}
                isDetailWidget={isDetailWidget}
                currentBreakpoint={currentBreakpoint}
                onDeleteIconClick={onDeleteIconClick}
                onModalStateChange={onModalStateChange}
            >
                <Column bigSpacing={true}>{this.renderContent()}</Column>
            </WidgetContainer>
        )
    }

    private getHeaderInfo() {
        const { id, isDetailWidget } = this.props
        const { customName, departments, allDepartments } = this.getMetadata()

        if (isDetailWidget) {
            return {
                widgetId: id,
                widgetKey: WidgetKey.radar,
                departments,
                allDepartments: false,
            }
        }

        return {
            widgetId: id,
            customName,
            widgetKey: WidgetKey.radar,
            departments,
            allDepartments,
        }
    }

    private renderContent() {
        const radarItems = this.getRadarData()

        return <Column bigSpacing={true}>{radarItems.map(radarItem => this.renderRadar(radarItem))}</Column>
    }

    private getMetadata() {
        const { metadata } = this.props

        if (!metadata) {
            return {
                departments: [],
                allDepartments: false,
            }
        }

        const { name, departments, allDepartments } = metadata as DashboardDepartmentSpecificWidgetMetadataType

        return {
            customName: name ? name : undefined,
            departments: departments?.length ? departments.map(({ id, name }) => ({ id, name })) : [],
            allDepartments,
        }
    }

    private getRadarData() {
        const { content } = this.props

        if (!content) {
            return []
        }

        const { radarItems } = content as DashboardRadarWidgetFragmentFragment
        if (!radarItems || !radarItems.length) {
            return []
        }

        // cannot handle more than 3 rows (more of a sanity check as the BE wont/shouldnt return more than 3)
        if (radarItems.length > 3) {
            return radarItems.slice(0, 3)
        }

        return radarItems
    }

    private renderRadar(radarItem: { id: number; name: string; expectedIn: number }) {
        const { id, name, expectedIn } = radarItem

        return (
            <Row key={`radar-row-${id}`} className={this.bem.getElement('row')}>
                <Column className={this.bem.getElement('left-column')}>
                    <div className={this.bem.getElement('circle')} />
                    <div className={this.bem.getElement('dots')} />
                </Column>
                <Column extraSmallSpacing={true}>
                    <Bold>{this.loc(t => t.expectedIn, { expectedDayCount: expectedIn })}</Bold>
                    <Paragraph className={this.bem.getElement('name')}>{name}</Paragraph>
                </Column>
            </Row>
        )
    }
}
