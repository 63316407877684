import './AssessmentAddNormArticlesAndTopicsSelect.scss'

import React from 'react'

import { Step } from '~/components/Core/Layout/Step'
import { localize } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ClassValue, BEM } from '~/services/BEMService'
import { NormsSort } from '../Norms/NormsSort'
import { UnlinkNormFromAssessmentButton } from './UnlinkNormFromAssessmentButton'
import { NormTopicsInfiniteScrollQuery } from '~/components/Domain/Compliance/Norms/NormTopicsInfiniteScrollQuery'
import {
    ExpandableTopicCardRow,
    ExpandableTopicCardRowChanges,
} from '~/components/Domain/Compliance/Norms/ExpandableTopicCardRow'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Column } from '~/components/Core/Layout/Column'
import { AssessmentCoverageType } from '~/generated/graphql'

interface Props {
    className?: ClassValue
    prevStep?: () => void
    onChange?: (changes: ExpandableTopicCardRowChanges) => void
    themeId: number
    requestClose?: () => void
    defaultSelectedTopicsAndArticles?: { topicId: number; articleIds: number[]; isPartialTopic: boolean }[]
    assessmentId?: number
    assessmentSectionId?: number
    onSaveLoading?: boolean
    onSubmit?: () => void
}

interface State {
    search?: string | null
    sort?: {
        [key: string]: string
    }
}

export interface Topic {
    id: number
    name: string
    customerFrameworks: CustomerFramework[]
    assessmentCoverage: AssessmentCoverageType
}

export class AssessmentAddNormArticlesAndTopicsSelect extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        search: null,
        sort: undefined,
    }

    private bem = new BEM('AssessmentAddNormArticlesAndTopicsSelect')

    public render() {
        const {
            prevStep,
            themeId,
            className,
            requestClose,
            assessmentId,
            assessmentSectionId,
            onSaveLoading,
            onChange,
            onSubmit,
            defaultSelectedTopicsAndArticles,
        } = this.props
        const { search, sort } = this.state
        const showDelete = themeId && assessmentId && assessmentSectionId ? true : false

        return (
            <Step
                className={this.bem.getClassName(className)}
                title={localize.translate(
                    t => t.Customer.Compliance.Assessments.AssesmentAddNormModal.steps.topicAndArticles
                )}
                titleActionComponent={
                    <Row>
                        <Search onChange={search => this.setState({ search })} />
                        <NormsSort onChange={sort => this.setState({ sort })} />
                    </Row>
                }
                actionBar={
                    <Row spaceBetween={showDelete} fullWidth={showDelete}>
                        {themeId && assessmentId && assessmentSectionId && (
                            <UnlinkNormFromAssessmentButton
                                normId={themeId}
                                assessmentId={assessmentId}
                                assessmentSectionId={assessmentSectionId}
                                afterDelete={requestClose}
                            />
                        )}
                        <Row>
                            {prevStep && (
                                <Button type={ButtonType.tertiary} onClick={prevStep}>
                                    {localize.translate(
                                        t => t.Customer.Compliance.Assessments.AssesmentAddNormModal.previous
                                    )}
                                </Button>
                            )}
                            {requestClose && (
                                <Button type={ButtonType.tertiary} onClick={requestClose}>
                                    {localize.translate(t => t.Generic.cancel)}
                                </Button>
                            )}
                            <Button loading={onSaveLoading} onClick={onSubmit}>
                                {localize.translate(t => t.Generic.save)}
                            </Button>
                        </Row>
                    </Row>
                }
            >
                <NormTopicsInfiniteScrollQuery search={search} themeId={themeId} sort={sort}>
                    {({ data, loading, loadingMore }) => {
                        const topics = data ? data.nodes : []

                        if (loading) {
                            return <Spinner delayed={true} />
                        }

                        if (!topics.length) {
                            return (
                                <NoResults
                                    label={localize.translate(
                                        t => t.Customer.Compliance.Assessments.AssesmentAddNormModal.noResult
                                    )}
                                />
                            )
                        }

                        return (
                            <Column>
                                {topics.map(topic => {
                                    const defaultTopic = defaultSelectedTopicsAndArticles
                                        ? defaultSelectedTopicsAndArticles.find(({ topicId }) => topicId === topic.id)
                                        : undefined
                                    const defaultCheckedArticleIds = defaultTopic ? defaultTopic.articleIds : undefined

                                    return (
                                        <ExpandableTopicCardRow
                                            key={topic.id}
                                            topic={topic}
                                            onChange={onChange}
                                            isPartialTopic={defaultTopic ? defaultTopic.isPartialTopic : undefined}
                                            defaultCheckedArticleIds={defaultCheckedArticleIds}
                                        />
                                    )
                                })}
                                {loadingMore && <Spinner delayed={true} />}
                            </Column>
                        )
                    }}
                </NormTopicsInfiniteScrollQuery>
            </Step>
        )
    }
}
