import './Timeline.scss'

import React from 'react'

import { ClassValue, BEM } from '~/services/BEMService'
import isSameDay from 'date-fns/is_same_day'
import startOfDay from 'date-fns/start_of_day'
import { TimelineGroup } from './TimelineGroup'

export interface TimelineEventType {
    date: Date
    content: () => JSX.Element
}

export enum TimelineLayout {
    alignLeft = 'align-left',
    distributeEvenly = 'distribute-evenly',
}

interface Props {
    className?: ClassValue
    events: TimelineEventType[]
    layout: TimelineLayout
}

export interface EventsGroupedByDay {
    day: Date
    events: TimelineEventType[]
}

export class Timeline extends React.PureComponent<Props> {
    public static defaultProps: Partial<Props> = {
        layout: TimelineLayout.alignLeft,
    }

    private bem = new BEM('Timeline', () => ({
        [this.props.layout]: !!this.props.layout,
    }))

    public render() {
        const { className, events } = this.props
        const groupedEvents = this.transformToGroupedByDay()

        if (events.length === 0) {
            // We want to return null here because assessment logs have no empty state.
            return null
        }

        return (
            <ul className={this.bem.getClassName(className)}>
                {this.renderTimeline()}
                {this.renderEvents(groupedEvents)}
            </ul>
        )
    }

    private renderEvents(groupedEvents: EventsGroupedByDay[]) {
        return groupedEvents.map((group, index) => {
            return <TimelineGroup key={index} group={group} className={this.bem.getElement('event')} />
        })
    }

    private renderTimeline() {
        return <div className={this.bem.getElement('line')} />
    }

    private transformToGroupedByDay() {
        const { events } = this.props
        const groupedEvents: EventsGroupedByDay[] = []

        events.forEach(event => {
            const groupedEvent = groupedEvents.find(({ day }) => {
                return isSameDay(event.date, day)
            })

            if (!groupedEvent) {
                groupedEvents.push({
                    day: startOfDay(event.date),
                    events: [event],
                })
            } else {
                groupedEvent.events.push(event)
            }
        })

        return groupedEvents
    }
}
