import './ModalManager.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import { BEM } from '~/services/BEMService'
import posed, { PoseGroup } from 'react-pose'

const Div = posed.div({
    enter: {
        opacity: 1,
        transition: {
            type: 'tween',
            ease: 'anticipate',
        },
    },
    exit: {
        opacity: 0,
        transition: {
            type: 'tween',
            ease: 'easeOut',
        },
    },
})

const Inner = posed.div({
    enter: {
        scale: 1,
        opacity: 1,
    },
    exit: {
        scale: 0.9,
        opacity: 0,
        transition: {
            type: 'tween',
            ease: 'easeOut',
        },
    },
})

export interface Props<OpenModalArg = undefined> {
    render?: (requestOpen: (openModalArg?: OpenModalArg) => void) => React.ReactNode
    renderModal: (requestClose: () => void, openModalArg: OpenModalArg) => React.ReactNode
    hasOpened?: () => void
    hasClosed?: () => void
    dismissable?: boolean
    dismissableByOverlay?: boolean
    forDrawerModal?: boolean
    openOnMount?: boolean
}

interface State<OpenModalArg = undefined> {
    modalIsShown: boolean
    openModalArg?: OpenModalArg
}

const modalRoot = document.getElementById('modal-root') as HTMLDivElement

export class ModalManager<OpenModalArg = undefined> extends React.Component<Props<OpenModalArg>, State<OpenModalArg>> {
    public static shownModalCount: number = 0

    public static defaultProps: Partial<Props> = {
        dismissable: true,
        dismissableByOverlay: false,
    }

    public state: State = {
        modalIsShown: false,
        openModalArg: undefined,
    }

    public id: string

    private bem = new BEM('ModalManager')

    private lastActiveElement: HTMLElement | undefined

    public componentDidMount() {
        if (this.props.openOnMount) {
            this.toggleModal()
        }
    }

    public componentWillUnmount() {
        if (this.state.modalIsShown) {
            if (--ModalManager.shownModalCount === 0) {
                document.body.style.overflow = ''
            }
        }
    }

    public render() {
        const { render, renderModal } = this.props
        const { modalIsShown, openModalArg } = this.state

        return (
            <>
                {render ? render(this.toggleModal) : null}
                {modalIsShown &&
                    ReactDOM.createPortal(
                        <PoseGroup animateOnMount={true}>
                            {
                                <Div
                                    key={`overlay-div-${this.id}`}
                                    className={this.bem.getElement('overlay', () => ({
                                        forDrawerModal: this.props.forDrawerModal,
                                    }))}
                                    onClick={this.handleOnOverlayClick}
                                >
                                    <Inner
                                        id={this.id}
                                        className={this.bem.getElement('modal-container', () => ({
                                            forDrawerModal: this.props.forDrawerModal,
                                        }))}
                                    >
                                        {renderModal(this.toggleModal, openModalArg as unknown as OpenModalArg)}
                                    </Inner>
                                </Div>
                            }
                        </PoseGroup>,
                        modalRoot
                    )}
            </>
        )
    }

    public toggleModal = (openModalArg?: OpenModalArg) => {
        const { dismissable, hasOpened, hasClosed } = this.props

        if (this.state.modalIsShown && !dismissable) {
            return
        }

        if (!this.state.modalIsShown) {
            this.id = `id-${Math.random().toString(36).substring(7)}`
            this.lastActiveElement = document.activeElement as HTMLElement
        }

        this.setState(
            (state: State) => ({
                modalIsShown: !state.modalIsShown,
                openModalArg: !state.modalIsShown ? openModalArg : undefined,
            }),
            () => {
                if (this.state.modalIsShown) {
                    ModalManager.shownModalCount += 1
                    document.body.style.overflow = 'hidden'
                    if (hasOpened) {
                        hasOpened()
                    }
                } else {
                    if (--ModalManager.shownModalCount === 0) {
                        document.body.style.overflow = ''
                    }

                    if (this.lastActiveElement) {
                        this.lastActiveElement.focus()
                    }

                    this.lastActiveElement = undefined

                    if (hasClosed) {
                        hasClosed()
                    }
                }
            }
        )
    }

    private handleOnOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        const { dismissableByOverlay } = this.props
        if (dismissableByOverlay === false) {
            return
        }

        const { modalIsShown } = this.state

        if (!modalIsShown) {
            return
        }

        const target = event.target as HTMLElement

        if (target.closest(`#${this.id} > *:first-child`)) {
            return
        }

        if (target.closest('#popover-root')) {
            return
        }

        if (target.closest('#taskwidget-root')) {
            return
        }

        this.toggleModal()
    }
}
