import './Notifications.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { errorClient, localize, notification } from '~/bootstrap'
import { ErrorCallbackHandler, ErrorType } from '~/services/ErrorService'
import { NotificationEventCallback, NotificationEventPayload } from '~/services/NotificationService'
import debounce from 'lodash-es/debounce'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { animationTiming } from '~/animations'
import { IconType } from '../../Icon/IconType'
import { Icon } from '../../Icon/Icon'
import { Button, ButtonType } from '../../Button/Button'
import { Row } from '../../Layout/Row'
import { Paragraph } from '../../Typography/Paragraph'

interface Props {
    className?: ClassValue
}

export enum NotificationSeverity {
    info = 'info',
    warning = 'warning',
    error = 'error',
    success = 'success',
    successWithUndo = 'successWithUndo',
}

interface Notification {
    id: string
    message: string
    type?: ErrorType
    severity: NotificationSeverity
    onUndo?: () => void
    undoState?: 'loading' | 'done'
    icon?: IconType
}

interface State {
    notifications: Notification[]
}

export class Notifications extends React.PureComponent<Props, State> {
    public state: State = {
        notifications: [],
    }

    private bem = new BEM('Notifications')
    private timers = new Map<string, number>()
    private notificationDurationConfig = {
        [NotificationSeverity.error]: 5000,
        [NotificationSeverity.info]: 5000,
        [NotificationSeverity.success]: 1000,
        [NotificationSeverity.successWithUndo]: 10000,
        [NotificationSeverity.warning]: 5000,
    }

    public componentDidMount() {
        errorClient.subscribeToType(ErrorType.internalServerError, this.handleError)
        errorClient.subscribeToType(ErrorType.network, this.handleError)
        errorClient.subscribeToType(ErrorType.authorization, this.handleError)
        errorClient.subscribeToType(ErrorType.graphql, this.handleError)
        notification.subscribe(this.handleNotification)
    }

    public componentWillUnmount() {
        errorClient.unsubscribeToType(ErrorType.internalServerError, this.handleError)
        errorClient.unsubscribeToType(ErrorType.network, this.handleError)
        errorClient.unsubscribeToType(ErrorType.authorization, this.handleError)
        errorClient.unsubscribeToType(ErrorType.graphql, this.handleError)
        notification.unsubscribe(this.handleNotification)
    }

    public render() {
        const { className } = this.props
        const { notifications } = this.state

        return (
            <div className={this.bem.getClassName(className)}>
                <TransitionGroup component={null}>
                    {notifications.map((notification, i) => (
                        <CSSTransition
                            in={true}
                            key={notification.id}
                            timeout={animationTiming.defaultTiming}
                            classNames={this.bem.getElement('notification-animation')}
                            appear={true}
                        >
                            <div
                                key={notification.id}
                                style={{ bottom: 59 * i }}
                                onMouseOver={() => this.haltTimer(notification.id)}
                                onMouseOut={() => this.startTimer(notification.id, notification.severity)}
                                className={this.bem.getElement('notification', () => ({
                                    [`severity-${notification.severity}`]: true,
                                    [`type-${notification.type}`]: !!notification.type,
                                }))}
                            >
                                <Row spaceBetween={true} className={this.bem.getElement('notification-container')}>
                                    <Row>
                                        {notification.icon && this.renderIcon(notification.icon)}
                                        <Paragraph white={true}>{notification.message}</Paragraph>
                                    </Row>
                                    <Row>
                                        {notification.onUndo && this.renderUndoButton(notification)}
                                        {this.notificationDurationConfig[notification.severity] > 5000 &&
                                            this.renderClearButton(notification.id)}
                                    </Row>
                                </Row>
                            </div>
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </div>
        )
    }

    // tslint:disable-next-line:member-ordering
    private handleError: ErrorCallbackHandler = debounce(({ type, error }) => {
        const notification = this.generateNotificationFromError(type, error as any)

        this.startTimer(notification.id, notification.severity)

        this.setState(state => ({
            notifications: [...state.notifications, notification],
        }))
    })

    private handleNotification: NotificationEventCallback = (payload: NotificationEventPayload) => {
        const notification: Notification = {
            id: this.generateId(),
            message: payload.message,
            severity: payload.type,
            icon: payload.icon,
            onUndo: payload.onUndo,
        }

        this.startTimer(notification.id, notification.severity)

        this.setState(state => ({
            notifications: [...state.notifications, notification],
        }))
    }

    private generateNotificationFromError(type: ErrorType, error: Error & { errorIdentifier: string }) {
        const id = this.generateId()

        if (type === ErrorType.network) {
            return {
                id,
                type,
                message: localize.translate(t => t.Errors.failedToReachServer),
                severity: NotificationSeverity.error,
            }
        }

        if (type === ErrorType.authentication) {
            return {
                id,
                type,
                message: localize.translate(t => t.Errors.failedToAuthenticate),
                severity: NotificationSeverity.error,
            }
        }

        if (type === ErrorType.authorization) {
            return {
                id,
                type,
                message: localize.translate(t => t.Errors.failedToAuthorize),
                severity: NotificationSeverity.error,
            }
        }

        if (type === ErrorType.internalServerError) {
            return {
                id,
                type,
                message: localize.translate(t => t.Errors.internalServerError, { code: error.errorIdentifier }),
                severity: NotificationSeverity.error,
            }
        }

        return {
            id,
            type,
            message: error.message,
            severity: NotificationSeverity.error,
        }
    }

    private generateId() {
        return Math.random().toString(36).substring(7)
    }

    private removeNotification(idToRemove: string) {
        this.setState(state => ({
            notifications: state.notifications.filter(({ id }) => id !== idToRemove),
        }))
    }

    private haltTimer(id: string) {
        const timer = this.timers.get(id)

        window.clearTimeout(timer)
    }

    private startTimer(id: string, severity: NotificationSeverity) {
        const removeTimout = window.setTimeout(() => {
            this.removeNotification(id)
        }, this.notificationDurationConfig[severity])

        this.timers.set(id, removeTimout)
    }

    private renderIcon(icon: IconType) {
        return <Icon type={icon} />
    }

    private renderUndoButton(notification: Notification) {
        return (
            <Button
                loading={notification.undoState === 'loading'}
                disabled={notification.undoState === 'done'}
                onClick={() => this.handleUndo(notification)}
                type={ButtonType.noStyling}
                className={this.bem.getElement('undo-button')}
            >
                {localize.translate(t => t.Generic.undo)}
            </Button>
        )
    }

    private renderClearButton(id: string) {
        const undoLoading = this.state.notifications.find(n => n.id === id)?.undoState === 'loading'

        return (
            <Button
                type={ButtonType.noStyling}
                icon={IconType.close}
                disabled={undoLoading}
                onClick={() => this.removeNotification(id)}
            />
        )
    }

    private async handleUndo(notification: Notification) {
        notification.undoState = 'loading'
        this.forceUpdate()

        await notification.onUndo?.()

        notification.undoState = 'done'
        this.forceUpdate()

        return
    }
}
