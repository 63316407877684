import React from 'react'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { DepartmentTopicDataGradeTypeEnum, TopicAssessmentGradeType, TopicsForThemeFilters } from '~/generated/graphql'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { Select, SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { localize } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'
import { TopicAssessmentIcon } from '../Topic/TopicDesignAndEffectiveness/modals/TopicAssessment/TopicAssessmentIcon'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    filters: ThemeDetailViewFilters
    onChange: (filters: ThemeDetailViewFilters) => void
}

export interface ThemeDetailViewFilters {
    search?: string | null
    integrality?: TopicAssessmentIntegrality
    designScore?: TopicAssessmentGradeType
    effectivenessScore?: TopicAssessmentGradeType
}

interface State {
    integrality: TopicAssessmentIntegrality
    designScore?: TopicAssessmentGradeType
    effectivenessScore?: TopicAssessmentGradeType
}

enum TopicAssessmentIntegrality {
    all = 'all',
    whole = 'whole',
    partly = 'partly',
    noAssessment = 'noAssessment',
    nonApplicable = 'nonApplicable',
}

export class ThemeDetailViewFilterOptionsButton extends React.PureComponent<Props, State> {
    public state: State = {
        integrality: this.props.filters.integrality || TopicAssessmentIntegrality.all,
        designScore: this.props.filters.designScore,
        effectivenessScore: this.props.filters.effectivenessScore,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.ThemeDetailViewFilterOptions)

    private integralityOptions: SelectOption[] = [
        this.getIntegralityOption(TopicAssessmentIntegrality.all),
        this.getIntegralityOption(TopicAssessmentIntegrality.whole),
        this.getIntegralityOption(TopicAssessmentIntegrality.partly),
        this.getIntegralityOption(TopicAssessmentIntegrality.noAssessment),
        this.getIntegralityOption(TopicAssessmentIntegrality.nonApplicable),
    ]

    private gradeOptions: SelectOption[] = [
        { label: this.loc(t => t.allGrades), value: null },
        this.getGradeOption(TopicAssessmentGradeType.assessmentSatisfies),
        this.getGradeOption(TopicAssessmentGradeType.assessmentAlmostSatisfies),
        this.getGradeOption(TopicAssessmentGradeType.assessmentNotSatisfies),
        this.getGradeOption(TopicAssessmentGradeType.assessmentNotGiven),
    ]

    public render() {
        return (
            <FilterDropdown hasActiveFilter={this.checkIfFilterIsActive()}>
                {this.renderIntegrityOption()}
                {this.renderDesignGradeOption()}
                {this.renderEffectivenessGradeOption()}
            </FilterDropdown>
        )
    }

    private checkIfFilterIsActive() {
        const { integrality, designScore, effectivenessScore } = this.state

        if (integrality !== TopicAssessmentIntegrality.all) {
            return true
        }

        if (designScore) {
            return true
        }

        if (effectivenessScore) {
            return true
        }

        return false
    }

    private renderIntegrityOption() {
        const { integrality } = this.state
        const value = this.integralityOptions.find(o => o.value === integrality) || this.integralityOptions[0]

        return (
            <FilterOption label={this.loc(t => t.integrality)} forInputName="integrality">
                <Select
                    name="integrality"
                    isFilter={true}
                    onChange={(option: SelectOption<TopicAssessmentIntegrality>) =>
                        this.handleChange({ integrality: option.value })
                    }
                    value={[value]}
                    options={this.integralityOptions}
                />
            </FilterOption>
        )
    }

    private renderDesignGradeOption() {
        const value = this.gradeOptions.find(o => o.value === this.state.designScore) || this.gradeOptions[0]

        return (
            <FilterOption label={this.loc(t => t.designGrade)} forInputName="designGrade">
                <Select
                    name="designGrade"
                    isFilter={true}
                    onChange={(option: SelectOption<TopicAssessmentGradeType>) =>
                        this.handleChange({ designScore: option.value })
                    }
                    value={[value]}
                    options={this.gradeOptions}
                />
            </FilterOption>
        )
    }

    private renderEffectivenessGradeOption() {
        const { effectivenessScore } = this.state
        const value = this.gradeOptions.find(o => o.value === effectivenessScore) || this.gradeOptions[0]

        return (
            <FilterOption label={this.loc(t => t.effectivenessGrade)} forInputName="effectivenessGrade">
                <Select
                    name="effectivenessGrade"
                    isFilter={true}
                    onChange={(option: SelectOption<TopicAssessmentGradeType>) =>
                        this.handleChange({ effectivenessScore: option.value })
                    }
                    value={[value]}
                    options={this.gradeOptions}
                />
            </FilterOption>
        )
    }

    private handleChange = (changedStatePart: Partial<State>) => {
        const { onChange } = this.props
        const { integrality, designScore, effectivenessScore } = changedStatePart

        const noAssessment = TopicAssessmentIntegrality.noAssessment
        const nonApplicable = TopicAssessmentIntegrality.nonApplicable

        if (integrality === noAssessment || integrality === nonApplicable) {
            this.setState({ designScore: undefined, effectivenessScore: undefined, integrality }, () =>
                onChange({ integrality })
            )

            return
        }

        const prevIntegrality = this.state.integrality
        const scoreOptionNotAllowedPreviously = prevIntegrality === noAssessment || prevIntegrality === nonApplicable

        if (scoreOptionNotAllowedPreviously && (designScore || effectivenessScore)) {
            const newState = { ...this.state, ...changedStatePart, integrality: TopicAssessmentIntegrality.all }

            this.setState(newState, () => onChange(newState))

            return
        }

        const newState = { ...this.state, ...changedStatePart }

        this.setState(newState, () => onChange(newState))
    }

    private getIntegralityOption(integrality: TopicAssessmentIntegrality) {
        return { label: this.loc(t => t.integralityOptions[integrality]), value: integrality }
    }

    private getGradeOption(grade: TopicAssessmentGradeType) {
        return { label: this.renderGradeOptionLabel(grade), value: grade }
    }

    private renderGradeOptionLabel(grade: TopicAssessmentGradeType) {
        return (
            <Row smallSpacing={true}>
                <TopicAssessmentIcon status={grade} />
                <Paragraph>{this.getGradeOptionText(grade)}</Paragraph>
            </Row>
        )
    }

    private getGradeOptionText(integrality: TopicAssessmentGradeType) {
        switch (integrality) {
            case TopicAssessmentGradeType.assessmentSatisfies:
                return localize.translate(t => t.Generic.AssessmentNoteStatus.COMPLIANT)
            case TopicAssessmentGradeType.assessmentAlmostSatisfies:
                return localize.translate(t => t.Generic.AssessmentNoteStatus.ALMOST_COMPLIANT)
            case TopicAssessmentGradeType.assessmentNotSatisfies:
                return localize.translate(t => t.Generic.AssessmentNoteStatus.NOT_COMPLIANT)
            default:
                return localize.translate(t => t.Generic.AssessmentNoteStatus.NOT_ASSESSED)
        }
    }
}

export function formatThemeDetailViewFilters(filters: ThemeDetailViewFilters): TopicsForThemeFilters {
    const { search, integrality, designScore, effectivenessScore } = filters

    const availableGrades = getAvailableGradesFromFilters(integrality)

    return {
        search,
        noTopicAssessment: integrality === TopicAssessmentIntegrality.noAssessment || undefined,
        designGrades: getGradesFromAvailableGrades(integrality, availableGrades, designScore),
        effectivenessGrades: getGradesFromAvailableGrades(integrality, availableGrades, effectivenessScore),
    }
}

function getAvailableGradesFromFilters(integrality?: TopicAssessmentIntegrality) {
    if (integrality === TopicAssessmentIntegrality.nonApplicable) {
        return [DepartmentTopicDataGradeTypeEnum.notApplicable]
    }

    if (integrality === TopicAssessmentIntegrality.partly) {
        return [
            DepartmentTopicDataGradeTypeEnum.assessmentSatisfiesPartly,
            DepartmentTopicDataGradeTypeEnum.assessmentAlmostSatisfiesPartly,
            DepartmentTopicDataGradeTypeEnum.assessmentNotSatisfiesPartly,
            DepartmentTopicDataGradeTypeEnum.assessmentNotGiven,
        ]
    }

    if (integrality === TopicAssessmentIntegrality.whole) {
        return [
            DepartmentTopicDataGradeTypeEnum.assessmentSatisfies,
            DepartmentTopicDataGradeTypeEnum.assessmentAlmostSatisfies,
            DepartmentTopicDataGradeTypeEnum.assessmentNotSatisfies,
            DepartmentTopicDataGradeTypeEnum.assessmentNotGiven,
        ]
    }

    if (integrality === TopicAssessmentIntegrality.all) {
        return Object.values(DepartmentTopicDataGradeTypeEnum)
    }

    return
}

function getGradesFromAvailableGrades(
    integrality?: TopicAssessmentIntegrality,
    availableGrades?: DepartmentTopicDataGradeTypeEnum[],
    score?: TopicAssessmentGradeType
) {
    if (!availableGrades?.length || !integrality || integrality === TopicAssessmentIntegrality.noAssessment) {
        return
    }

    if (!score && integrality === TopicAssessmentIntegrality.all) {
        return
    }

    if (!score) {
        return availableGrades
    }

    return getWholeAndPartialGradesForGradeInAvailable(availableGrades, score)
}

function getWholeAndPartialGradesForGradeInAvailable(
    availableGrades: DepartmentTopicDataGradeTypeEnum[],
    score: TopicAssessmentGradeType
) {
    const satisfiesPartly = DepartmentTopicDataGradeTypeEnum.assessmentSatisfiesPartly
    const almostSatisfiesPartly = DepartmentTopicDataGradeTypeEnum.assessmentAlmostSatisfiesPartly
    const notSatisfiesPartly = DepartmentTopicDataGradeTypeEnum.assessmentNotSatisfiesPartly

    const availableScore = availableGrades.filter(g => g === (score as any))

    switch (score) {
        case TopicAssessmentGradeType.assessmentSatisfies:
            return availableGrades.includes(satisfiesPartly) ? [...availableScore, satisfiesPartly] : availableScore
        case TopicAssessmentGradeType.assessmentAlmostSatisfies:
            return availableGrades.includes(almostSatisfiesPartly)
                ? [...availableScore, almostSatisfiesPartly]
                : availableScore
        case TopicAssessmentGradeType.assessmentNotSatisfies:
            return availableGrades.includes(notSatisfiesPartly)
                ? [...availableScore, notSatisfiesPartly]
                : availableScore
        default:
            return [DepartmentTopicDataGradeTypeEnum.assessmentNotGiven]
    }
}
