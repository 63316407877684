import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'

interface Props {
    children: (data: Customer) => React.ReactNode
}

export interface Customer {
    id: number
    name: string
    slug: string
    availableSeats: number
    filledSeats: number
}

export const CUSTOMER_QUERY = gql`
    query customer($customerSlug: String) {
        customer(customerSlug: $customerSlug) {
            id
            name
            slug
            availableSeats
            filledSeats
        }
    }
`

export class CustomerQuery extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <Query<{ customer: Customer | null }>
                query={CUSTOMER_QUERY}
                variables={{ customerSlug: this.context.customer.slug }}
            >
                {({ data, loading }) => {
                    const customer = data ? data.customer : null

                    if (loading) {
                        return <Spinner />
                    }

                    if (!customer) {
                        return <NoResults />
                    }

                    return children(customer)
                }}
            </Query>
        )
    }
}
