import React from 'react'
import { DrawerModal } from '~/components/Core/Feedback/Modal/DrawerModal'
import { localize, notification } from '~/bootstrap'
import { AssessmentAddNormArticlesAndTopicsSelect } from './AssessmentAddNormArticlesAndTopicsSelect'
import { MainProfile } from '~/components/Chrome/MainHeader/Desktop/MainProfile/MainProfile'
import gql from 'graphql-tag'
import { isNumber } from 'lodash-es'
import { GQLMutation } from '~/graphql/Mutation'
import { ExpandableTopicCardRowChanges } from '~/components/Domain/Compliance/Norms/ExpandableTopicCardRow'
import { MutationFn, Query } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AssessmentSectionType } from '~/generated/graphql'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { ASSESSMENT_SECTIONS_QUERY } from './AssessmentSections/AssessmentSectionsInfiniteScrollQuery'

interface SelectedTopic {
    normId: number
    topicId: number
    isPartialTopic: boolean
}

interface Props {
    onClose?: () => void
    themeId: number
    assessmentId: number
    assessmentSectionId: number
    selectedTopics: SelectedTopic[]
}

const EDIT_NORM_MUTATION = gql`
    mutation editAssessmentSectionTopics($assessmentSectionId: Int!, $fields: EditAssessmentTopicsFieldsType!) {
        editAssessmentSectionTopics(assessmentSectionId: $assessmentSectionId, fields: $fields) {
            id
        }
    }
`

const SELECTED_ARTICLES_QUERY = gql`
    query assessmentSection($assessmentSectionId: Int!) {
        assessmentSection(assessmentSectionId: $assessmentSectionId) {
            id
            order
            norms {
                selectedCount
                totalCount
                norm {
                    id
                    assessmentArticles {
                        id
                        linkedLawArticles {
                            id
                            abstractLawArticleId
                        }
                        assessmentTopic {
                            id
                            topic {
                                id
                            }
                        }
                    }
                    topic {
                        id
                        name
                        expiredLawArticleIds
                        theme {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`

interface EditAssessmentForNormsResponse {
    editAssessmentSectionTopics: {
        id: number
    }
}

interface EditAssessmentForNomrsVariables {
    assessmentSectionId: number
    fields: {
        removeTopicIds?: number[]
        changedTopics?: {
            topicId: number
            addAllArticles?: boolean
            selectedArticleIds?: number[]
        }[]
    }
}

interface State {
    changes: ExpandableTopicCardRowChanges[]
}

export class AssessmentEditNormModal extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        changes: [],
    }

    public render() {
        const { onClose, themeId, assessmentId, assessmentSectionId, selectedTopics } = this.props

        return (
            <DrawerModal
                title={
                    <>
                        {localize.translate(t => t.Customer.Compliance.Assessments.AssessmentEditNormModal.title)}
                        <MainProfile compact={true} />
                    </>
                }
                requestClose={onClose}
                hideButtons={true}
            >
                <GQLMutation<EditAssessmentForNormsResponse, EditAssessmentForNomrsVariables>
                    mutation={EDIT_NORM_MUTATION}
                    refetchQueries={[
                        {
                            query: ASSESSMENT_SECTIONS_QUERY,
                            variables: {
                                assessmentId,
                                departmentId: this.context.activeDepartmentId,
                            },
                        },
                    ]}
                >
                    {(mutate, { loading: mutateLoading }) => (
                        <Query<any>
                            query={SELECTED_ARTICLES_QUERY}
                            variables={{ assessmentSectionId: assessmentSectionId }}
                        >
                            {({ data, loading }) => {
                                if (loading) {
                                    return <Spinner />
                                }

                                const selectedTopicsAndArticles = selectedTopics.map(selectedTopic => ({
                                    ...selectedTopic,
                                    articleIds: data
                                        ? this.getSelectedArticlesForTopic(selectedTopic, data.assessmentSection)
                                        : [],
                                }))

                                return (
                                    <AssessmentAddNormArticlesAndTopicsSelect
                                        themeId={themeId}
                                        requestClose={onClose}
                                        assessmentId={assessmentId}
                                        assessmentSectionId={assessmentSectionId}
                                        defaultSelectedTopicsAndArticles={selectedTopicsAndArticles}
                                        onSaveLoading={mutateLoading}
                                        onChange={this.handleOnChange}
                                        onSubmit={this.handleOnSubmit(mutate)}
                                    />
                                )
                            }}
                        </Query>
                    )}
                </GQLMutation>
            </DrawerModal>
        )
    }

    private handleOnChange = (changes: ExpandableTopicCardRowChanges) => {
        this.setState(prev => {
            const filterdOut = prev.changes.filter(({ topicId }) => changes.topicId !== topicId)

            return { changes: [...filterdOut, changes] }
        })
    }

    private handleOnSubmit =
        (mutate: MutationFn<EditAssessmentForNormsResponse, EditAssessmentForNomrsVariables>) => async () => {
            const { assessmentSectionId, onClose } = this.props
            const { changes } = this.state
            const removeTopicIds = changes.filter(change => change.removeTopic).map(change => change.topicId)
            const topicChanges = changes
                .filter(change => !change.removeTopic)
                .map(change => ({
                    topicId: change.topicId,
                    addAllArticles: change.allTopicsSelected,
                    selectedArticleIds: change.selectedArticleIds,
                }))

            const response = await mutate({
                variables: {
                    assessmentSectionId,
                    fields: {
                        removeTopicIds,
                        changedTopics: topicChanges,
                    },
                },
            })

            if (response && response.data && response.data.editAssessmentSectionTopics) {
                notification.success(localize.translate(t => t.Generic.successfullyEdited))
                if (onClose) {
                    onClose()
                }
            }
        }

    private getSelectedArticlesForTopic(selectedTopic: SelectedTopic, assessmentSection: AssessmentSectionType) {
        if (!assessmentSection.norms || assessmentSection.norms.length === 0) {
            return []
        }

        const assessmentSectionTopic = assessmentSection.norms.find(norm => norm.norm?.id === selectedTopic.normId)

        if (!assessmentSectionTopic) {
            return []
        }

        const { norm } = assessmentSectionTopic

        if (!norm || !norm.assessmentArticles || norm.assessmentArticles.length === 0) {
            return []
        }

        return norm.assessmentArticles
            .filter(article => {
                if (
                    isNumber(article.assessmentTopic?.topic?.id) &&
                    isNumber(norm.topic?.id) &&
                    article.assessmentTopic?.topic?.id === norm.topic?.id
                ) {
                    return article
                }
                return false
            })
            .map(article => article.linkedLawArticles.abstractLawArticleId)
    }
}
