import React from 'react'
import { PageQuery, PageQueryOptions } from '../../PageQuery/PageQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { permissions } from '~/bootstrap'
import {
    MonitoringReportDocument,
    MonitoringReportQuery as MonitoringReport,
    MonitoringReportQueryVariables,
} from '~/generated/graphql'

export type MonitoringReportDetailType = NonNullable<MonitoringReport['monitoringReport']>

interface Props {
    children: (monitoringReport: MonitoringReportDetailType, options: PageQueryOptions) => JSX.Element
    monitoringReportId: number
}

export class MonitoringReportQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, monitoringReportId } = this.props

        if (!permissions.canViewMonitoringReports()) {
            return null
        }

        return (
            <PageQuery<MonitoringReportDetailType, MonitoringReportQueryVariables>
                query={MonitoringReportDocument}
                variables={{ monitoringReportId }}
            >
                {(monitoringReport, options) => children(monitoringReport, options)}
            </PageQuery>
        )
    }
}
