import './CoverView.scss'

import React from 'react'

import { BEM } from '~/services/BEMService'
import { CoverLoginView } from '~/views/Cover/CoverLoginView'
import { CoverSamlView } from '~/views/Cover/CoverSamlView'
import { CoverNotFoundView } from '~/views/Cover/CoverNotFoundView'
import { ForgotPasswordView } from '~/views/Cover/ForgotPasswordView'
import { routes } from '~/views/routes'
import { SetPasswordView } from '~/views/Cover/SetPasswordView'
import { ConfirmAccountView } from '~/views/Cover/ConfirmAccountView'
import { Switch, RouteComponentProps, Route, Redirect } from 'react-router-dom'
import { RedirectView } from '~/views/Cover/RedirectView'
import { RegisterUser } from '~/views/Cover/RegisterUser'
import { Footer } from '~/components/Chrome/Footer/Footer'
import { ConfirmMergeUser } from './ConfirmMergeUser'
import { CoverSSOLoginView } from './CoverSSOLoginView'

interface Props extends RouteComponentProps<{ token?: string }> {}

export class CoverView extends React.PureComponent<Props> {
    private bem = new BEM('CoverView')

    public render() {
        const { token } = this.props.match.params

        return (
            <div className={this.bem.getClassName()}>
                <Switch>
                    <Route path={routes.cover.login} component={CoverLoginView} />
                    <Route path={routes.cover.loginWithSSO} component={CoverSSOLoginView} />
                    <Route path={routes.cover.saml} component={CoverSamlView} />
                    <Route path={routes.cover.forgotPassword} component={ForgotPasswordView} />
                    <Route path={routes.cover.setPassword} component={SetPasswordView} />
                    <Route path={routes.cover.confirmAccount} component={ConfirmAccountView} />
                    <Route path={routes.cover.registerUser()} component={RegisterUser} />
                    <Route path={routes.cover.userMergeRequest} component={ConfirmMergeUser} />
                    <Route path={routes.cover.redirect} component={RedirectView} />
                    <Redirect path={routes.cover.index} to={routes.cover.login} exact={true} />
                    <Redirect
                        path={routes.cover.registerUserLegacy}
                        to={routes.cover.registerUser(token, '-')}
                        exact={true}
                    />
                    <Route component={CoverNotFoundView} />
                </Switch>
                <Footer className={this.bem.getElement('footer')} />
            </div>
        )
    }
}
