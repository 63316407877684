import React from 'react'
import { MutationFn } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    LinkControlsToTopicMutationResult,
    LinkControlsToTopicDocument,
    LinkControlsToTopicMutation,
    LinkControlsToTopicMutationVariables,
    TopicDesignAndEffectivenessDocument,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

export type LinkControlsToTopicMutationFN = MutationFn<
    LinkControlsToTopicMutation,
    LinkControlsToTopicMutationVariables
>

interface Props {
    topicId: number
    children: (mutate: LinkControlsToTopicMutationFN, result: LinkControlsToTopicMutationResult) => React.ReactNode
}

export class LinkControlsToTopic extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, topicId } = this.props

        return (
            <GQLMutation<LinkControlsToTopicMutation, LinkControlsToTopicMutationVariables>
                mutation={LinkControlsToTopicDocument}
                refetchQueries={[
                    {
                        query: TopicDesignAndEffectivenessDocument,
                        variables: {
                            departmentId: this.context.activeDepartmentId,
                            customerSlug: this.context.customer.slug,
                            id: topicId,
                        },
                    },
                ]}
            >
                {(mutate, result) => children(mutate, result)}
            </GQLMutation>
        )
    }
}
