import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import {
    AddWidgetMutationVariables,
    AddWidgetDocument,
    AddWidgetMutation,
    DashboardLayoutDocument,
    DashboardWidgetLayoutTypeEnum,
} from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    layout?: DashboardWidgetLayoutTypeEnum
    children: (
        mutation: MutationFn<AddWidgetMutation, AddWidgetMutationVariables>,
        options: MutationResult
    ) => JSX.Element
}

export class AddWidget extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, layout } = this.props

        return (
            <GQLMutation<AddWidgetMutation, AddWidgetMutationVariables>
                mutation={AddWidgetDocument}
                refetchQueries={[
                    {
                        query: DashboardLayoutDocument,
                        variables: { layout },
                    },
                ]}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
