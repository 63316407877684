import React from 'react'
import { MutationFn } from 'react-apollo'
import {
    CreateTaskDocument,
    CreateTaskMutation,
    CreateTaskMutationVariables,
    DepartmentAlertDataType,
    TaskType,
} from '~/generated/graphql'
import { FormState, Form } from '~/components/Core/DataEntry/Form/Form'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { ReviewSubTasksTable } from '../../Task/ReviewSubTasksTable'
import { Task } from '~/views/Customer/Tasks/TasksQuery/TaskQuery'
import { TaskFormFields } from '../../Task/TaskFormFields'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { notification, localize } from '~/bootstrap'
import { NewOrExistingTag } from '~/graphql/types/Task'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { EmployeeQuery } from '../../Customer/EmployeePageQuery'
import { GQLMutation } from '~/graphql/Mutation'
import { LinkedInboxItemsTable } from '../InboxControl/LinkedInboxItemsTable'

interface Props {
    requestClose?: () => void
    tasks?: TaskType[]
    alertDatas?: DepartmentAlertDataType[]
    onCreate?: () => void
}

interface State {}

export class CreateControlTaskModal extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.TaskControl.CreateControlTaskModal)

    public render() {
        const { requestClose, tasks } = this.props
        const currentEmployee = this.context.employee

        if (!currentEmployee) {
            return null
        }

        const title = tasks?.length ? this.loc(t => t.title) : this.loc(t => t.inboxControlTitle)

        return (
            <EmployeeQuery id={currentEmployee.id}>
                {employee => (
                    <GQLMutation<CreateTaskMutation> mutation={CreateTaskDocument}>
                        {(mutate, { loading }) => (
                            <Form
                                onSubmit={this.handleFormSubmit(mutate)}
                                isCompact={true}
                                defaultState={employee ? { employeeIds: [employee] } : undefined}
                            >
                                <Modal
                                    title={title}
                                    requestClose={requestClose}
                                    submitForm={true}
                                    confirmButtonLabel={localize.translate(t => t.Generic.save)}
                                    loading={loading}
                                >
                                    {this.renderLinkedItems()}
                                    <ErrorMessage path={'createTask'} />
                                    <TaskFormFields isReviewTask={true} employees={employee ? [employee] : undefined} />
                                </Modal>
                            </Form>
                        )}
                    </GQLMutation>
                )}
            </EmployeeQuery>
        )
    }

    private handleFormSubmit =
        (mutate: MutationFn<CreateTaskMutation, CreateTaskMutationVariables>) => async (formState: FormState) => {
            const { tasks, requestClose, onCreate, alertDatas } = this.props

            const taskIds = tasks?.map(task => task.id)
            const alertIdsWithDepartmentIdsToReview = alertDatas?.map(a => ({
                alertId: a.alert.id,
                departmentId: a.department.id,
            }))

            const employeeIds = formState.employeeIds
                ? formState.employeeIds.map((employee: any) => employee.id)
                : undefined
            const tags = formState.tags !== undefined ? this.transformTagOptions(formState.tags) : undefined
            const priority = formState.priority ? formState.priority.value : undefined
            const forMonitoring = formState.forMonitoring
                ? formState.forMonitoring
                : formState.forMonitoring === null
                ? false
                : undefined

            const response = await mutate({
                variables: {
                    departmentId: this.context.activeDepartmentId,
                    fields: {
                        ...formState,
                        employeeIds,
                        tags,
                        priority,
                        forMonitoring,
                        isReviewTask: true,
                        taskIdsToReview: taskIds,
                        alertIdsWithDepartmentIdsToReview,
                    },
                },
            })

            if (response && response.data && response.data.createTask) {
                notification.success(localize.translate(t => t.Generic.successfullyCreated))

                if (requestClose) {
                    requestClose()
                }

                if (onCreate) {
                    onCreate()
                }
            }
        }

    private renderLinkedItems() {
        const { alertDatas, tasks } = this.props

        if (alertDatas?.length) {
            return <LinkedInboxItemsTable linkedAlerts={alertDatas} />
        }

        return <ReviewSubTasksTable linkedTasks={tasks as unknown as Task[]} />
    }

    private transformTagOptions(tags: SelectOption[] | null): NewOrExistingTag[] {
        if (tags === null) {
            return []
        }

        return tags
            .filter(tag => tag.value)
            .map(tag => {
                if (tag.__isNew__ && typeof tag.value === 'string') {
                    return { tagName: tag.value }
                }

                return { tagId: tag.value as number }
            })
    }
}
