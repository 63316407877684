import React from 'react'
import { localize } from '~/bootstrap'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Section } from '~/components/Core/Layout/Section'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM, ClassValue } from '~/services/BEMService'
import { OverdueTasksTableContainer } from '~/views/Customer/Tasks/AllTasks/OverdueTasksTableContainer'
import { TasksTableContainer } from '~/views/Customer/Tasks/AllTasks/TasksTableContainer'
import { AllTasksParams } from '~/views/Customer/Tasks/AllTasksView'
import { OverdueTaskQuery } from '~/views/Customer/Tasks/TasksQuery/OverdueTaskQuery'

interface Props {
    paramState: Omit<AllTasksParams, 'tableView'>
    setRefetchFunction: (refetch: () => void) => void
    refetch: () => void
    className?: ClassValue
}

export class AllTasksListViewContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks)
    private bem = new BEM('AllTasksListViewContainer')

    public render() {
        const { paramState, className } = this.props
        const { completedOnly } = paramState

        return (
            <div className={this.bem.getClassName(className)}>
                {completedOnly ? this.renderCompletedTasks() : this.renderTasks()}
            </div>
        )
    }

    private renderCompletedTasks() {
        const { setRefetchFunction, refetch } = this.props
        const mappedFilters = this.getMappedFilters()

        return (
            <TasksTableContainer
                setRefetchFunction={setRefetchFunction}
                includeOverdue={true}
                filters={mappedFilters}
                refetch={refetch}
            />
        )
    }

    private renderTasks() {
        const { setRefetchFunction, refetch } = this.props
        const mappedFilters = this.getMappedFilters()

        return (
            <OverdueTaskQuery filters={mappedFilters}>
                {({ hasOverdueTasks, loading, refetch: refetchOverdueTasks }) => {
                    setRefetchFunction(refetchOverdueTasks)

                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    if (hasOverdueTasks) {
                        return (
                            <>
                                <Section title={this.loc(t => t.overdueTitle)}>
                                    <OverdueTasksTableContainer
                                        filters={mappedFilters}
                                        setRefetchFunction={setRefetchFunction}
                                        refetch={refetch}
                                    />
                                </Section>
                                <Section title={this.loc(t => t.futureTitle)}>
                                    <TasksTableContainer
                                        filters={mappedFilters}
                                        setRefetchFunction={setRefetchFunction}
                                        refetch={refetch}
                                    />
                                </Section>
                            </>
                        )
                    }

                    return (
                        <TasksTableContainer
                            filters={mappedFilters}
                            setRefetchFunction={setRefetchFunction}
                            refetch={refetch}
                        />
                    )
                }}
            </OverdueTaskQuery>
        )
    }

    private getMappedFilters() {
        const { paramState } = this.props
        const mappedFilters = {
            ...paramState,
            tagIds: paramState.tagIds?.map(tag => tag.value),
        }

        return mappedFilters
    }
}
