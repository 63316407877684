import { Locale } from '~/i18n/TranslationKeys'
import '~/services/LocalStorageService'
import { LocalStorageKeys, LocalStorageService } from '~/services/LocalStorageService'

export interface StoredSession {
    token: string
    userId: number
    language: Locale
}

export class SessionService {
    /**
     * Remember the session
     */
    public setSession(session: StoredSession) {
        LocalStorageService.setItem(LocalStorageKeys.SessionToken, session.token)
        LocalStorageService.setItem(LocalStorageKeys.SessionUserId, String(session.userId))
        LocalStorageService.setItem(LocalStorageKeys.Language, session.language)
    }

    /**
     * Forget the session
     */
    public removeSession() {
        LocalStorageService.removeItem(LocalStorageKeys.SessionToken)
        LocalStorageService.removeItem(LocalStorageKeys.SessionUserId)
        LocalStorageService.removeItem(LocalStorageKeys.Language)
    }

    /**
     * Get the session
     */
    public getSession(): StoredSession | null {
        const language = LocalStorageService.getItem(LocalStorageKeys.Language)
        const token = LocalStorageService.getItem(LocalStorageKeys.SessionToken)
        const userIdString = LocalStorageService.getItem(LocalStorageKeys.SessionUserId)
        const userId = userIdString && parseInt(userIdString, 10)

        if (!language || !token || !userId) {
            this.removeSession()
            return null
        }

        return { token, userId, language: language as Locale }
    }
}
