import React from 'react'
import { Query, Mutation, MutationFn } from 'react-apollo'
import gql from 'graphql-tag'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { PinnedThemesDocument, ThemeTopicAssessmentCoverageType } from '~/generated/graphql'
import { ThemesOverviewFilters } from '../Themes/ThemesOverviewTable/ThemesOverviewTableQuery'

interface Props {
    children: (context: PinnedThemeOptionsType) => React.ReactNode
    filters: ThemesOverviewFilters
}

export interface PinnedThemeOptionsType {
    themes: PinnedTheme[]
    loading: boolean
    pin: PinFN
    unpin: PinFN
}

export type PinFN = (id: number) => void

const PIN_THEME_MUTATION = gql`
    mutation pinTheme($id: Int!) {
        pinItem(id: $id, type: THEME)
    }
`

const UNPIN_THEME_MUTATION = gql`
    mutation unpinTheme($id: Int!) {
        unpinItem(id: $id, type: THEME)
    }
`

export interface PinnedTheme {
    id: number
    name: string
    customerFrameworks: CustomerFramework[]
    topicAssessmentCoverage: ThemeTopicAssessmentCoverageType
}

interface PinnedThemesResponse {
    pinned: {
        themes: PinnedTheme[]
    }
}

export class PinnedThemesQuery extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, filters } = this.props
        const customerFrameworkIds = this.context.activeProfiles
        const refetchQueries = [
            {
                query: PinnedThemesDocument,
                variables: {
                    customerFrameworkIds,
                    departmentId: this.context.activeDepartmentId,
                },
            },
        ]

        return (
            <Mutation<any> mutation={UNPIN_THEME_MUTATION} refetchQueries={refetchQueries}>
                {unpinTheme => (
                    <Mutation<any> mutation={PIN_THEME_MUTATION} refetchQueries={refetchQueries}>
                        {pinTheme => (
                            <Query<PinnedThemesResponse>
                                query={PinnedThemesDocument}
                                variables={{
                                    customerFrameworkIds: this.context.activeProfiles,
                                    departmentId: this.context.activeDepartmentId,
                                    search: filters.search,
                                }}
                            >
                                {({ data, loading, refetch }) =>
                                    children({
                                        themes: this.getThemes(data),
                                        loading,
                                        pin: id => this.handlePinning(id, pinTheme, refetch),
                                        unpin: id => this.handlePinning(id, unpinTheme, refetch),
                                    })
                                }
                            </Query>
                        )}
                    </Mutation>
                )}
            </Mutation>
        )
    }

    private async handlePinning(id: number, mutate: MutationFn, refetch: Function) {
        await mutate({ variables: { id } })

        refetch()
    }

    private getThemes(data?: PinnedThemesResponse) {
        if (data && data.pinned && data.pinned.themes) {
            return data.pinned.themes
        }

        return []
    }
}
