import React from 'react'

import { AppNotFoundView } from '~/views/AppNotFoundView'
import { RouteComponentProps, Route, Switch } from 'react-router-dom'
import { routes } from '~/views/routes'
import { NewsOverviewView } from './NewsOverviewView'
import { NewsDetailView } from './NewsDetailView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props extends RouteComponentProps<{}> {}

export class NewsView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Switch>
                <Route
                    path={routes.customer(this.context.customer.slug).news.index}
                    component={NewsOverviewView}
                    exact={true}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).news.detail.view()}
                    component={NewsDetailView}
                    exact={true}
                />
                <Route component={AppNotFoundView} />
            </Switch>
        )
    }
}
