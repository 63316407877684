import './ArticleTitleBreadCrumbs.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Row } from '~/components/Core/Layout/Row'

export interface ArticleBreadCrumbsArticle {
    id: number
    title: string
    isSingleArticle: boolean
    sectionParents?: { id: number; name: string; depth: number }[]
    law?: {
        id: number
        abstractLawId: number
        name: string
    }
    lawGroup: {
        id: number
        name: string
    }
}
interface Props {
    className?: ClassValue
    article: ArticleBreadCrumbsArticle
}

interface State {}

export class ArticleTitleBreadCrumbs extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('ArticleTitleBreadCrumbs')

    public render() {
        const { className, article } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {!article.law ? this.renderTitle() : this.renderWithBreadCrumbs()}
            </div>
        )
    }

    private renderWithBreadCrumbs() {
        const { article } = this.props
        const { sectionParents, title } = article

        if (!sectionParents) {
            return this.renderTitle()
        }

        return (
            <Tooltip
                className={this.bem.getElement('tooltip')}
                content={this.getArticleDepthBreadcrumb(sectionParents)}
                children={
                    <Row alignCenter={true} smallSpacing={true}>
                        {this.renderTitle()}
                        <div className={this.bem.getElement('separator')}>
                            {this.renderArticleLevel(sectionParents)}
                        </div>
                        <p className={this.bem.getElement('name')}>{title}</p>
                    </Row>
                }
            />
        )
    }

    private renderTitle() {
        const { article } = this.props
        const { law, title } = article
        return <p className={this.bem.getElement('name')}>{law && law.name ? law.name : title}</p>
    }

    private getArticleDepthBreadcrumb(sectionParents: { id: number; name: string; depth: number }[]) {
        const reversedSectionParents = sectionParents.slice().sort((a, b) => {
            return a.depth - b.depth
        })

        const addLevels = reversedSectionParents.map(parent => `${parent.name} /`)
        const breadcrumb = addLevels.join(' ')
        const breadCrumbWithoutLastSeparator = breadcrumb.substring(0, breadcrumb.length - 1)

        return breadCrumbWithoutLastSeparator
    }

    private renderArticleLevel(sectionParents: { id: number; name: string }[]) {
        return sectionParents.length > 2 ? '/.../' : '/'
    }

    // private getRouteForArticle(abstractLawId: number | null, lawArticleId: number, isSingleArticle: boolean) {
    //     if (isSingleArticle && !abstractLawId) {
    //         return routes.customer(this.context.customer.slug).lawAndRegulation.detail.singleArticle(lawArticleId)
    //     }

    //     return routes.customer(this.context.customer.slug).lawAndRegulation.detail.article(abstractLawId!, lawArticleId)
    // }
}
