import './DashboardView.scss'

import React from 'react'
import { localize, notification } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { DeleteWidgets } from '~/components/Domain/Dashboard/Mutations/DeleteWidgets'
import { MutationFn } from 'react-apollo'
import { EditDashboardLayout } from '~/components/Domain/Dashboard/Mutations/EditDashboardLayout'
import { WidgetKey } from '~/components/Domain/Dashboard/CreateWidgetWidget/widgets'
import { LayoutInfo } from '~/components/Core/Layout/ResponsiveGrid'
import { DashboardViewContainer } from './DashboardViewContainer'
import {
    DashboardWidgetLayoutTypeEnum,
    DeleteWidgetMutation,
    DeleteWidgetMutationVariables,
    EditDashboardLayoutMutation,
    EditDashboardLayoutMutationVariables,
} from '~/generated/graphql'
import { CustomerGreeting } from '~/components/Domain/Customer/CustomerGreeting'

interface Props {}

interface State {
    isEditing: boolean
    isOnDetailView: boolean
    widgetIdsToDelete: number[]
    newLayout: LayoutInfo[]
    divKeyCounter: number
}

export interface DetailViewWidgetInfo {
    id: number
    type: WidgetKey
}

interface Mutations {
    editMutate: MutationFn<EditDashboardLayoutMutation, EditDashboardLayoutMutationVariables>
    editLoading: boolean
    deleteMutate: MutationFn<DeleteWidgetMutation, DeleteWidgetMutationVariables>
    deleteLoading: boolean
}

export class DashboardView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {
        isEditing: false,
        isOnDetailView: false,
        widgetIdsToDelete: [],
        newLayout: [],
        divKeyCounter: 0,
    }

    private bem = new BEM('DashboardView')

    private containerRef = React.createRef<HTMLDivElement>()
    private detailViewWidgetInfo: DetailViewWidgetInfo
    private currentBreakpoint = this.getCurrentBreakpoint()

    public componentDidMount() {
        window.addEventListener('resize', this.updateComponent)
        this.updateComponent()
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.isEditing && !this.state.isEditing) {
            this.setState({ divKeyCounter: this.state.divKeyCounter + 1, widgetIdsToDelete: [], newLayout: [] })
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.updateComponent)
    }

    public render() {
        const { divKeyCounter } = this.state
        const divKey = `dashboard-view-${this.currentBreakpoint}-${divKeyCounter}`

        return (
            <div ref={this.containerRef} key={divKey}>
                <EditDashboardLayout>
                    {(editMutate, { loading: editLoading }) => (
                        <DeleteWidgets>
                            {(deleteMutate, { loading: deleteLoading }) => {
                                const mutations = { editMutate, editLoading, deleteMutate, deleteLoading }

                                return this.renderContent(mutations)
                            }}
                        </DeleteWidgets>
                    )}
                </EditDashboardLayout>
            </div>
        )
    }

    private updateComponent = () => {
        const nextBreakpoint = this.getCurrentBreakpoint()

        if (this.currentBreakpoint !== nextBreakpoint) {
            this.currentBreakpoint = nextBreakpoint
        }
    }

    private renderContent(mutations: Mutations) {
        const { isEditing, isOnDetailView, widgetIdsToDelete } = this.state
        const onCreateReturn = () => this.setState({ widgetIdsToDelete: [], newLayout: [] })

        return (
            <Column className={this.bem.getClassName()}>
                <Row spaceBetween={true} className={this.bem.getElement('header')}>
                    <CustomerGreeting />
                    {this.renderAction(mutations)}
                </Row>
                <DashboardViewContainer
                    isEditing={isEditing}
                    isOnDetailView={isOnDetailView}
                    widgetIdsToDelete={widgetIdsToDelete}
                    currentBreakpoint={this.currentBreakpoint}
                    detailViewWidgetInfo={this.detailViewWidgetInfo}
                    handleWidgetClick={this.handleWidgetClick}
                    handleDeleteIconClick={this.handleDeleteIconClick}
                    handleLayoutChange={this.handleLayoutChange}
                    onNavigateBack={() => this.setState({ isOnDetailView: false })}
                    onCreateWidget={() => this.handleSubmit(mutations, onCreateReturn)}
                />
            </Column>
        )
    }

    private renderAction(mutations: Mutations) {
        const { isEditing, isOnDetailView } = this.state

        if (isOnDetailView) {
            return
        }

        const { deleteLoading, editLoading } = mutations
        const loading = deleteLoading || editLoading

        if (isEditing) {
            const { widgetIdsToDelete, newLayout } = this.state
            const isSubmitDisabled = !widgetIdsToDelete.length && !newLayout.length
            const onReturn = () => this.setState({ isEditing: false, widgetIdsToDelete: [], newLayout: [] })

            return (
                <Row smallSpacing={true} className={this.bem.getElement('buttons')}>
                    <Button
                        type={ButtonType.tertiary}
                        onClick={() => this.setState({ isEditing: false, widgetIdsToDelete: [] })}
                        disabled={loading}
                    >
                        {localize.translate(t => t.Generic.cancel)}
                    </Button>
                    <Button
                        onClick={() => this.handleSubmit(mutations, onReturn)}
                        disabled={isSubmitDisabled}
                        loading={loading}
                    >
                        {localize.translate(t => t.Generic.save)}
                    </Button>
                </Row>
            )
        }

        return (
            <div className={this.bem.getElement('buttons')}>
                <Button
                    className={this.bem.getElement('edit-button')}
                    icon={IconType.edit}
                    largeRounded={true}
                    onClick={() => this.setState({ isEditing: true })}
                />
            </div>
        )
    }

    private async handleSubmit(mutations: Mutations, onReturn: () => void) {
        const { widgetIdsToDelete, newLayout } = this.state

        if (!newLayout.length && !widgetIdsToDelete.length) {
            onReturn()
            return
        }

        const { deleteMutate, editMutate } = mutations
        const layout = this.currentBreakpoint || DashboardWidgetLayoutTypeEnum.threecolumn

        const deleteResponse = await deleteMutate({
            variables: { widgetIds: widgetIdsToDelete, layout },
        })

        const isDeleteSuccessful = deleteResponse && deleteResponse.data?.deleteWidgets
        if (!isDeleteSuccessful) {
            onReturn()
            return
        }

        if (!newLayout.length) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            onReturn()
            return
        }

        const widgets = newLayout.map(widget => ({ ...widget, layoutType: layout }))
        const editResponse = await editMutate({ variables: { widgets } })

        if (editResponse && editResponse.data?.editDashboardWidgets) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            onReturn()
        }

        onReturn()
    }

    private handleWidgetClick = (widgetId: number, type: WidgetKey) => {
        this.detailViewWidgetInfo = { id: widgetId, type }
        this.setState({ isOnDetailView: true })
    }

    private handleDeleteIconClick = (widgetId: number) => {
        const { widgetIdsToDelete } = this.state

        const alreadyExists = widgetIdsToDelete.some(id => id === widgetId)
        if (alreadyExists) {
            return
        }

        this.setState({ widgetIdsToDelete: [...widgetIdsToDelete, widgetId] })
    }

    private handleLayoutChange = (newLayout: LayoutInfo[]) => {
        if (this.state.isEditing) {
            this.setState({ newLayout })
        }
    }

    private getCurrentBreakpoint() {
        const containerWidth = this.containerRef.current?.offsetWidth

        if (!containerWidth) {
            return DashboardWidgetLayoutTypeEnum.threecolumn
        }

        if (containerWidth > 1100) {
            return DashboardWidgetLayoutTypeEnum.threecolumn
        }

        if (containerWidth > 768) {
            return DashboardWidgetLayoutTypeEnum.twocolumn
        }

        return DashboardWidgetLayoutTypeEnum.onecolumn
    }
}
