import React from 'react'
import gql from 'graphql-tag'
import { InfiniteScrollOptions, InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    AssessmentSectionsQueryVariables,
    AssessmentSectionType,
} from '~/generated/graphql'

interface Props {
    assessmentId: number
    children: (
        options: InfiniteScrollOptions<AssessmentSectionType, AssessmentSectionsQueryVariables>
    ) => React.ReactNode
}

export const ASSESSMENT_SECTIONS_QUERY = gql`
    query assessmentSections($assessmentId: Int!, $departmentId: Int!, $take: Int, $skip: Int) {
        assessmentSections(assessmentId: $assessmentId, take: $take, skip: $skip) {
            count
            totalCount
            hasNextPage
            nodes {
                id
                order
                norms {
                    selectedCount
                    totalCount
                    norm {
                        id
                        topic {
                            id
                            name
                            expiredLawArticleIds
                            theme {
                                id
                                name
                            }
                        }
                    }
                }
                risks {
                    id
                    name
                    description
                    isAccepted
                    severity
                    severityBruto
                    status
                    linkedControls(departmentId: $departmentId) {
                        id
                        control {
                            id
                            name
                            type
                            revisionDate
                        }
                    }
                }
                controls {
                    id
                    name
                    type
                    description
                    createdAt
                    revisionDate
                }
            }
        }
    }
`

export class AssessmentSectionsInfiniteScrollQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, assessmentId } = this.props

        return (
            <InfiniteScrollQuery<AssessmentSectionType, AssessmentSectionsQueryVariables>
                query={ASSESSMENT_SECTIONS_QUERY}
                variables={{ assessmentId, departmentId: this.context.activeDepartmentId }}
            >
                {options => children(options)}
            </InfiniteScrollQuery>
        )
    }
}
