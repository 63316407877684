import './LabelTag.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'

interface Props {
    className?: ClassValue
    label: string
    subtle?: boolean
    warning?: boolean
}

interface State {}

export class LabelTag extends React.PureComponent<Props, State> {
    private bem = new BEM('LabelTag', () => ({
        'is-subtle': this.props.subtle,
        'is-warning': this.props.warning,
    }))

    public render() {
        const { className, warning, label } = this.props

        return (
            <span className={this.bem.getClassName(className)}>
                {warning && <Icon type={IconType.attention} className={this.bem.getElement('warning-icon')} />}
                {label}
            </span>
        )
    }
}
