import './TableHeaderLabel.scss'

import React from 'react'

import { BEM } from '~/services/BEMService'

interface Props {
    label: string | JSX.Element | null
    hideLabelFromDisplay?: boolean
}

export class TableHeaderLabel extends React.PureComponent<Props> {
    private bem = new BEM('TableHeaderLabel')

    public render() {
        const { label, hideLabelFromDisplay } = this.props

        return (
            <span
                className={this.bem.getElement('label', () => ({
                    [`is-visually-hidden`]: !!hideLabelFromDisplay,
                }))}
            >
                {label ? label : '\u00A0'}
            </span>
        )
    }
}
