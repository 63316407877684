import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { IconType } from '~/components/Core/Icon/IconType'
import { TopicType } from '~/generated/graphql'
import { LinkControlToTopicModal } from './modals/LinkControlToTopicModal'

interface Props {
    topic: TopicType
    onCreate?: () => void
}

export class AddControlToTopicButtonContainer extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.CreateTopicControl)

    public render() {
        const { topic, onCreate } = this.props
        const { topicControlMeasures, id } = topic
        const linkedControlIds = topicControlMeasures ? topicControlMeasures.map(t => t.control.id) : []

        return (
            <ModalManager
                render={openModal => (
                    <Button type={ButtonType.actionLink} icon={IconType.addCircle} onClick={openModal}>
                        {this.loc(t => t.button)}
                    </Button>
                )}
                renderModal={closeModal => (
                    <LinkControlToTopicModal
                        topicId={id}
                        requestClose={closeModal}
                        linkedControlIds={linkedControlIds}
                        onCreate={onCreate}
                    />
                )}
            />
        )
    }
}
