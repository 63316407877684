import React from 'react'
import { UserContext, UserContextValue } from '~/components/Providers/UserProvider'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { ReleaseNotesModal } from '~/components/Domain/ReleaseNotes/ReleaseNotesModal'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

export interface ReleaseNoteAttachment {
    type: 'image' | 'video'
    url: string
}

interface ReleaseNoteChange {
    title: string
    description: string
    attachments?: ReleaseNoteAttachment[]
}

export interface ReleaseNote {
    version: string
    date: string
    changes?: ReleaseNoteChange[]
    preventPopup?: boolean
}

interface Props {
    showOnMount?: boolean
    render?: (requestOpen: () => void, version?: string) => JSX.Element
}

interface State {
    releaseNotes: ReleaseNote[]
    latestVersion?: string
    showIndex: number
}

const SET_APP_VERSION_MUTATION = gql`
    mutation setSeenAppVersion {
        setSeenAppVersion {
            id
            lastAppVersionSeenAt
        }
    }
`

export class ReleaseNotesManager extends React.Component<Props, State> {
    public static managerIsOpen: boolean = false

    public static contextType = UserContext
    public context: UserContextValue

    public state: State = {
        releaseNotes: [],
        latestVersion: undefined,
        showIndex: 0,
    }

    private modalManagerRef = React.createRef<ModalManager>()

    public async componentDidMount() {
        const { user } = this.context
        const { showOnMount } = this.props

        if (showOnMount === false) {
            return
        }

        if (!user) {
            return
        }

        await this.setReleaseNotes()
    }

    public render() {
        const { render } = this.props
        const { latestVersion } = this.state

        return (
            <Mutation<any> mutation={SET_APP_VERSION_MUTATION}>
                {setLatestVerion => (
                    <ModalManager
                        render={render ? open => render(open, latestVersion) : undefined}
                        dismissableByOverlay={false}
                        ref={this.modalManagerRef}
                        hasOpened={setLatestVerion}
                        hasClosed={() => (ReleaseNotesManager.managerIsOpen = false)}
                        renderModal={close => {
                            return (
                                <ReleaseNotesModal
                                    requestClose={close}
                                    startingIndex={this.state.showIndex}
                                    releaseNotes={this.state.releaseNotes}
                                />
                            )
                        }}
                    />
                )}
            </Mutation>
        )
    }

    private async setReleaseNotes() {
        try {
            const releaseNotes: ReleaseNote[] = await fetch('/release-notes/release-notes.json').then(response =>
                response.json()
            )

            const sortedByNewestLast = releaseNotes.sort(
                (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
            )

            // Of all release notes...
            const latestIndex = releaseNotes.length - 1
            const latestChangelog = sortedByNewestLast[latestIndex]

            // Of the release notes with changes
            const releaseNotesWithChanges = sortedByNewestLast.filter(note => note.changes && note.changes.length > 0)
            const latestIndexOfReleaseNotesWithChanges = releaseNotesWithChanges.length - 1
            const latestChangelogWithChanges = releaseNotesWithChanges[latestIndexOfReleaseNotesWithChanges]

            this.setState(
                {
                    latestVersion: latestChangelog.version,
                    releaseNotes: releaseNotesWithChanges,
                    showIndex: latestIndexOfReleaseNotesWithChanges,
                },
                () => {
                    if (!latestChangelogWithChanges.preventPopup) {
                        // Show popup
                        this.openModalIfUserHasNotSeenLatestVersionWithPopup(latestChangelogWithChanges.date)
                    }
                }
            )
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Failed to get release notes', error)
        }
    }

    private openModalIfUserHasNotSeenLatestVersionWithPopup(latestVersionDate: string) {
        if (ReleaseNotesManager.managerIsOpen) {
            return
        }

        if (!this.modalManagerRef.current) {
            return
        }

        const { user } = this.context

        if (!user) {
            return
        }

        if (!user.lastAppVersionSeenAt || new Date(user.lastAppVersionSeenAt) < new Date(latestVersionDate)) {
            this.modalManagerRef.current.toggleModal()
            ReleaseNotesManager.managerIsOpen = true
        }
    }

    // Commented because we might want to open the version that the user last saw
    // private getLatestVersionIndexSinceLastSeen = (releaseNotes: ReleaseNote[]): number => {
    //     const { user } = this.context

    //     if (!user) {
    //         return releaseNotes.length - 1
    //     }

    //     if (!user.lastAppVersionSeenAt) {
    //         return releaseNotes.length - 1
    //     }

    //     const date = user.lastAppVersionSeenAt

    //     const index = releaseNotes.findIndex(note => new Date(note.date) > new Date(date))

    //     if (index === -1) {
    //         return releaseNotes.length - 1
    //     }

    //     if (index === releaseNotes.length - 1) {
    //         return index
    //     }

    //     return index + 1
    // }
}
