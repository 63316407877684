import './TopicDesignAndEffectivenessLinkedArticlesModalContainer.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { ControlTypeType, TopicAssessmentDeLinkedItemType } from '~/generated/graphql'
import { TopicLinkedItems } from '../../TopicLinkedItems'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { TopicDesignAndEffectivenessLinkedArticlesModal } from './TopicDesignAndEffectivenessLinkedArticlesModal'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { BEM } from '~/services/BEMService'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'

interface Props {
    topicId: number
    itemId: number
    itemType: TopicAssessmentDeLinkedItemType
    controlType?: ControlTypeType
    selectedIds: number[]
    linkedAll?: boolean
    expiredArticleCount: number
    onChange?: () => void
}

export class TopicDesignAndEffectivenessLinkedArticlesModalContainer extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessTable)
    private bem = new BEM('TopicDesignAndEffectivenessLinkedArticlesModalContainer', () => ({
        hasExpired: !!this.props.expiredArticleCount,
    }))

    public render() {
        return <ModalManager render={this.renderButton} renderModal={this.renderModal} />
    }

    private renderButton = (openModal: () => void) => {
        const { selectedIds, linkedAll, expiredArticleCount } = this.props

        const tooltipMessage = this.loc(t => t.expiredArticleMessage, { smart_count: expiredArticleCount })
        const buttonLabel = linkedAll
            ? this.loc(t => t.wholeTopic)
            : this.loc(t => t.articles, { smart_count: selectedIds.length })

        if (expiredArticleCount) {
            return (
                <Tooltip message={tooltipMessage} className={this.bem.getClassName()} direction={ToolTipDirection.down}>
                    <Button type={ButtonType.subtleItemLink} onClick={openModal}>
                        <Icon type={IconType.attention} className={this.bem.getElement('expired-icon')} />
                        {buttonLabel}
                    </Button>
                </Tooltip>
            )
        }

        return (
            <Button type={ButtonType.subtleItemLink} onClick={openModal} className={this.bem.getClassName()}>
                {buttonLabel}
            </Button>
        )
    }

    private renderModal = (closeModal: () => void) => {
        const { topicId, controlType, selectedIds, linkedAll, itemId, itemType, onChange } = this.props

        return (
            <TopicLinkedItems topicId={topicId} lawArticlesOnly={true} getExpiredStatus={true}>
                {({ data, loading }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    if (!data) {
                        return <NoResults />
                    }

                    return (
                        <TopicDesignAndEffectivenessLinkedArticlesModal
                            data={data}
                            closeModal={closeModal}
                            loading={false}
                            type={itemType}
                            typeId={itemId}
                            controlType={controlType}
                            defaultArticleIds={selectedIds}
                            allArticles={linkedAll}
                            onChange={onChange}
                        />
                    )
                }}
            </TopicLinkedItems>
        )
    }
}
