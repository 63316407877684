import React from 'react'
import { localize } from '~/bootstrap'
import { Button } from '~/components/Core/Button/Button'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { IconType } from '~/components/Core/Icon/IconType'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { CustomerFeedsDocument, CustomerSubscribedToFeedType } from '~/generated/graphql'
import { CreateFeedModal } from '~/components/Domain/Customer/CustomerFeed/modals/CreateFeedModal'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { CustomerFeedsTable } from '~/components/Domain/Customer/CustomerFeed/CustomerFeedsTable'
import { Row } from '~/components/Core/Layout/Row'

export class CustomerFeedsListView extends React.PureComponent {
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerFeeds)

    public render() {
        return (
            <Page>
                <PageHeader title={this.loc(t => t.title)} actionComponent={this.renderCreateFeedButton()} />
                <PageQuery<CustomerSubscribedToFeedType[]> query={CustomerFeedsDocument}>
                    {feeds => <CustomerFeedsTable feeds={feeds} />}
                </PageQuery>
            </Page>
        )
    }

    private renderCreateFeedButton() {
        return (
            <ModalManager
                render={openModal => (
                    <Row alignRight={true}>
                        <Button onClick={openModal} icon={IconType.addSmall} rounded={true}>
                            {this.loc(t => t.createButton)}
                        </Button>
                    </Row>
                )}
                renderModal={closeModal => <CreateFeedModal onRequestClose={closeModal} />}
            />
        )
    }
}
