import './Truncate.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    truncateMiddle?: string
    multiLineTruncate?: string
    maxMultiLineRows?: number
    maxCharPerMultiLine?: number
    noTitle?: boolean
}

interface State {}

export class Truncate extends React.PureComponent<Props, State> {
    private bem = new BEM('Truncate')

    public render() {
        const { children, className, truncateMiddle, multiLineTruncate, noTitle } = this.props

        if (truncateMiddle) {
            return (
                <span title={truncateMiddle} className={this.bem.getElement('truncate-middle')}>
                    {this.truncate(truncateMiddle)}
                </span>
            )
        }

        if (multiLineTruncate) {
            return (
                <span title={noTitle ? undefined : multiLineTruncate} className={this.bem.getElement('multi-line')}>
                    {this.multiLineTruncate()}
                </span>
            )
        }

        return <span className={this.bem.getClassName(className)}>{children}</span>
    }

    private truncate(text: string) {
        if (text.length < 25) {
            return text
        }

        const trimmedText = `${text.substr(0, 15)}...${text.substr(text.length - 10, text.length)}`
        return trimmedText
    }

    private multiLineTruncate() {
        const { multiLineTruncate, maxMultiLineRows, maxCharPerMultiLine } = this.props

        const charLimit = maxCharPerMultiLine ? maxCharPerMultiLine : 10
        const lineCount = maxMultiLineRows ? maxMultiLineRows : 3
        const words = multiLineTruncate!.split(' ')

        if (!words.length) {
            return null
        }

        const rows: JSX.Element[] = []
        const rangeExcludingLast = lineCount - 1

        let wordIndex: number = 0
        let leftOverText: string = ''
        let currentSection: string = ''

        for (let i = 0; i < rangeExcludingLast; i++) {
            [currentSection, wordIndex] = this.getSectionAndIndex(words, wordIndex, charLimit, leftOverText)

            if (!currentSection) {
                break
            }

            if (currentSection.length >= charLimit) {
                const hasDashBeforeLastChar = currentSection.lastIndexOf('-') === charLimit - 2

                if (hasDashBeforeLastChar) {
                    leftOverText = currentSection.slice(charLimit - 1)
                    rows.push(this.renderRow(`${currentSection.slice(0, charLimit - 1)}`, i))
                    continue
                }

                leftOverText = currentSection.slice(charLimit - 1)
                rows.push(this.renderRow(`${currentSection.slice(0, charLimit - 1)}-`, i))
                continue
            }

            leftOverText = ''
            rows.push(this.renderRow(currentSection, i))
        }

        if (wordIndex >= words.length) {
            if (leftOverText.length) {
                rows.push(this.renderRow(leftOverText, words.length, true, charLimit))
            }

            return rows
        }

        const lastRowSection = leftOverText
            ? `${leftOverText} ${words.slice(wordIndex).join(' ')}`
            : ` ${words.slice(wordIndex).join(' ')}`

        rows.push(this.renderRow(lastRowSection, words.length, true, charLimit))

        return rows
    }

    private getSectionAndIndex(
        words: string[],
        wordIndex: number,
        charLimit: number,
        leftOver: string
    ): [string, number] {
        let stringSection: string = ''

        if (leftOver) {
            if (leftOver.length >= charLimit - 3) {
                return [leftOver, wordIndex]
            }

            if (wordIndex === words.length) {
                return [leftOver, wordIndex]
            }

            stringSection = `${leftOver} ${words[wordIndex].trim()}`
        } else {
            if (wordIndex === words.length) {
                return [stringSection, wordIndex]
            }

            stringSection = words[wordIndex].trim()
        }

        wordIndex += 1

        while (stringSection.length < charLimit - 3) {
            if (wordIndex === words.length) {
                break
            }

            stringSection = `${stringSection} ${words[wordIndex].trim()}`
            wordIndex++
        }

        return [stringSection, wordIndex]
    }

    private renderRow(text: string, key: number, isLast?: boolean, charLimit?: number) {
        if (isLast && charLimit && text.length > charLimit) {
            text = `${text.slice(0, charLimit - 2)}...`
        }

        return (
            <span key={key} className={this.bem.getElement(`multi-line-row`)}>
                {text}
            </span>
        )
    }
}
