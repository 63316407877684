import './EditRiskValueField.scss'

import React from 'react'
import { isNumber } from 'lodash-es'
import { localize, permissions } from '~/bootstrap'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { AttentionIcon } from '~/components/Core/Icon/AttentionIcon/AttentionIcon'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { RiskStatus } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { RiskGraph, RiskGraphIndicatorType } from '../RiskGraph/RiskGraph'
import { Risk, RiskGraphSettings, RiskValues } from './EditRiskModal'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { HintDirection } from '~/components/Core/DataEntry/Form/Slider'
import { RiskIndicator, SeverityLevel } from '../RiskIndicator'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

export enum RiskValueKey {
    brutoProbability = 'brutoProbability',
    brutoImpact = 'brutoImpact',
    nettoProbability = 'nettoProbability',
    nettoImpact = 'nettoImpact',
}

interface RiskSliderConfig {
    name: string
    minLimit?: number
    maxLimit?: number
    value: number
    isUnmoved: boolean
    onChange: (newValue: number) => void
}

interface Props {
    showDistinctBrutoRiskValues: boolean
    isRiskAccepted: boolean
    defaultRiskValues: RiskValues
    riskStatus: RiskStatus | null
    risk: Risk
    riskGraphSettings: RiskGraphSettings
    isForBruto?: boolean
    onRiskValueChange: (key: RiskValueKey, value: number) => void
}

interface State {
    riskValues: RiskValues
    userHasMadeChangesToRiskValuesOrControls: boolean
    showHintToEvaluateNettoRisk: boolean
}

export class EditRiskValueField extends React.Component<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        riskValues: this.props.defaultRiskValues,
        userHasMadeChangesToRiskValuesOrControls: false,
        showHintToEvaluateNettoRisk: false,
    }

    private hideSlidersAndShowWarning = false
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Risks.editRisk)
    private bem = new BEM('EditRiskValueField')

    public render() {
        const { isForBruto, isRiskAccepted, showDistinctBrutoRiskValues } = this.props
        this.hideSlidersAndShowWarning = !isForBruto && showDistinctBrutoRiskValues && isRiskAccepted

        return (
            <>
                <SectionTitle className={this.bem.getElement('title')}>
                    {this.renderAttentionIcon()}
                    {this.renderSectionTitle()}
                </SectionTitle>
                {isForBruto ? this.renderRiskContentForBruto() : this.renderRiskContentForNetto()}
            </>
        )
    }

    private renderSectionTitle() {
        const { isForBruto, showDistinctBrutoRiskValues } = this.props

        if (showDistinctBrutoRiskValues) {
            if (isForBruto) {
                return this.loc(t => t.grossRiskTitle)
            }

            return this.loc(t => t.netRiskTitle)
        }

        return this.loc(t => t.riskTitle)
    }

    private renderAttentionIcon() {
        const { showDistinctBrutoRiskValues, isForBruto, riskStatus } = this.props

        let shouldShowAttentionIcon: boolean = false
        if (showDistinctBrutoRiskValues && riskStatus) {
            if (isForBruto) {
                shouldShowAttentionIcon = false
            } else {
                shouldShowAttentionIcon = RiskStatus.noControlsWhileNettoSeverityIsSet === riskStatus
            }
        } else if (riskStatus) {
            shouldShowAttentionIcon = [
                RiskStatus.noNettoSeverityWhileControlsAreSet,
                RiskStatus.severityNotSet,
            ].includes(riskStatus)
        }

        return (
            <>
                {riskStatus && shouldShowAttentionIcon && (
                    <AttentionIcon
                        tooltipContent={localize.translate(t => t.Customer.Compliance.Risks.status[riskStatus])}
                    />
                )}
            </>
        )
    }

    private renderRiskContentForNetto() {
        const { defaultRiskValues } = this.props
        const { riskValues } = this.state

        const currentRiskValues = riskValues ? riskValues : defaultRiskValues
        const presentedRiskValues = this.getPresentedRiskBrutoNettoValues(currentRiskValues)

        if (this.hideSlidersAndShowWarning) {
            return (
                <Column>
                    <Paragraph className={this.bem.getElement('warning-text')}>
                        {this.loc(t => t.brutoRiskAcceptedWarning)}
                    </Paragraph>
                    <FieldSet>
                        <RiskGraph indicators={this.getRiskIndicators()} />
                    </FieldSet>
                </Column>
            )
        }

        return (
            <Column>
                <FieldSet>
                    <Field
                        label={this.renderSliderLabel()}
                        labelString={this.loc(t => t.probability)}
                        forInput={'nettoProbability'}
                    >
                        {this.renderRiskSlider({
                            name: 'nettoProbability',
                            maxLimit: toNumberOrUndefined(currentRiskValues.brutoProbability),
                            value: presentedRiskValues.nettoProbability,
                            isUnmoved: !isNumber(currentRiskValues.nettoProbability),
                            onChange: value => {
                                this.handleOnRiskValueChange(RiskValueKey.nettoProbability, value)

                                if (!isNumber(currentRiskValues.nettoImpact)) {
                                    this.handleOnRiskValueChange(
                                        RiskValueKey.nettoImpact,
                                        presentedRiskValues.nettoImpact
                                    )
                                }
                            },
                        })}
                    </Field>

                    <Field
                        label={this.renderSliderLabel(true)}
                        labelString={this.loc(t => t.impact)}
                        forInput={'nettoImpact'}
                    >
                        {this.renderRiskSlider({
                            name: 'nettoImpact',
                            maxLimit: toNumberOrUndefined(currentRiskValues.brutoImpact),
                            value: presentedRiskValues.nettoImpact,
                            isUnmoved: !isNumber(currentRiskValues.nettoImpact),
                            onChange: value => {
                                this.handleOnRiskValueChange(RiskValueKey.nettoImpact, value)

                                if (!isNumber(currentRiskValues.nettoProbability)) {
                                    this.handleOnRiskValueChange(
                                        RiskValueKey.nettoProbability,
                                        presentedRiskValues.nettoProbability
                                    )
                                }
                            },
                        })}
                    </Field>
                </FieldSet>

                <FieldSet>
                    <RiskGraph indicators={this.getRiskIndicators()} />
                </FieldSet>
            </Column>
        )
    }

    private renderRiskContentForBruto() {
        const { defaultRiskValues, isRiskAccepted } = this.props
        const { riskValues } = this.state

        const currentRiskValues = riskValues ? riskValues : defaultRiskValues
        const presentedRiskValues = this.getPresentedRiskBrutoNettoValues(currentRiskValues)

        return (
            <Column>
                <FieldSet>
                    <Field
                        label={this.renderSliderLabel()}
                        labelString={this.loc(t => t.probability)}
                        forInput={'brutoProbability'}
                    >
                        {this.renderRiskSlider({
                            name: 'brutoProbability',
                            minLimit: isRiskAccepted
                                ? undefined
                                : toNumberOrUndefined(currentRiskValues.nettoProbability),
                            value: presentedRiskValues.brutoProbability,
                            isUnmoved: !isNumber(currentRiskValues.brutoProbability),
                            onChange: value => {
                                this.handleOnRiskValueChange(RiskValueKey.brutoProbability, value)

                                if (!isNumber(currentRiskValues.brutoImpact)) {
                                    this.handleOnRiskValueChange(
                                        RiskValueKey.brutoImpact,
                                        presentedRiskValues.brutoImpact
                                    )
                                }

                                if (isRiskAccepted) {
                                    // When risk is accepted, netto should move with bruto
                                    this.handleOnRiskValueChange(RiskValueKey.nettoProbability, value)

                                    if (!isNumber(currentRiskValues.nettoImpact)) {
                                        this.handleOnRiskValueChange(
                                            RiskValueKey.nettoImpact,
                                            presentedRiskValues.nettoImpact
                                        )
                                    }
                                }
                            },
                        })}
                    </Field>

                    <Field
                        label={this.renderSliderLabel(true)}
                        labelString={this.loc(t => t.impact)}
                        forInput={'brutoImpact'}
                    >
                        {this.renderRiskSlider({
                            name: 'brutoImpact',
                            minLimit: isRiskAccepted ? undefined : toNumberOrUndefined(currentRiskValues.nettoImpact),
                            value: presentedRiskValues.brutoImpact,
                            isUnmoved: !isNumber(currentRiskValues.brutoImpact),
                            onChange: value => {
                                this.handleOnRiskValueChange(RiskValueKey.brutoImpact, value)

                                if (!isNumber(currentRiskValues.brutoProbability)) {
                                    this.handleOnRiskValueChange(
                                        RiskValueKey.brutoProbability,
                                        presentedRiskValues.brutoProbability
                                    )
                                }

                                if (isRiskAccepted) {
                                    // When risk is accepted, netto should move with bruto
                                    this.handleOnRiskValueChange(RiskValueKey.nettoImpact, value)

                                    if (!isNumber(currentRiskValues.nettoProbability)) {
                                        this.handleOnRiskValueChange(
                                            RiskValueKey.nettoProbability,
                                            presentedRiskValues.nettoProbability
                                        )
                                    }
                                }
                            },
                        })}
                    </Field>
                </FieldSet>

                <FieldSet>
                    <RiskGraph indicators={this.getRiskIndicators()} />
                </FieldSet>
            </Column>
        )
    }

    private getPresentedRiskBrutoNettoValues(values: RiskValues) {
        const { isRiskAccepted } = this.props

        const fallbackValues = {
            // When risk is not accepted, bruto's presented fallback-value is netto
            brutoProbability: isRiskAccepted ? 50 : toNumberOrFallback(values.nettoProbability, 50),
            brutoImpact: isRiskAccepted ? 50 : toNumberOrFallback(values.nettoImpact, 50),

            // Netto's presented fallback-value is bruto
            nettoProbability: toNumberOrFallback(values.brutoProbability, 50),
            nettoImpact: toNumberOrFallback(values.brutoImpact, 50),
        }

        return {
            brutoProbability: toNumberOrFallback(values.brutoProbability, fallbackValues.brutoProbability),
            brutoImpact: toNumberOrFallback(values.brutoImpact, fallbackValues.brutoImpact),
            nettoProbability: toNumberOrFallback(values.nettoProbability, fallbackValues.nettoProbability),
            nettoImpact: toNumberOrFallback(values.nettoImpact, fallbackValues.nettoImpact),
        }
    }

    private renderSliderLabel(isForImpact?: boolean) {
        const { riskGraphSettings } = this.props

        let label = this.loc(t => t.probability)
        let tooltipContent = [
            {
                title: 'Betekenis van hoge kans',
                content: riskGraphSettings.highPropbabiltyDescription,
            },
            {
                title: 'Betekenis van lage kans',
                content: riskGraphSettings.lowPropbabiltyDescription,
            },
        ]

        if (isForImpact) {
            label = this.loc(t => t.impact)
            tooltipContent = [
                {
                    title: 'Betekenis van hoge impact',
                    content: riskGraphSettings.highImpactDescription,
                },
                {
                    title: 'Betekenis van lage impact',
                    content: riskGraphSettings.lowImpactDescription,
                },
            ]
        }

        return (
            <Row smallSpacing={true} className={this.bem.getElement('slider-label')}>
                <span>{label}</span>
                <Tooltip
                    className={this.bem.getElement('tooltip')}
                    content={
                        <Column smallSpacing={true}>
                            {tooltipContent.map((content, i) => (
                                <React.Fragment key={i}>
                                    <div>
                                        <h4>{content.title}</h4>
                                        <Paragraph small={true} preserveLine={true}>
                                            {content.content}
                                        </Paragraph>
                                    </div>
                                    <hr className={this.bem.getElement('line')} />
                                </React.Fragment>
                            ))}
                        </Column>
                    }
                >
                    <Icon type={IconType.info} />
                </Tooltip>
            </Row>
        )
    }

    private renderRiskSlider(config: RiskSliderConfig) {
        return (
            <Form.Slider
                name={name}
                minLabel={'Laag'}
                maxLabel={'Hoog'}
                min={0}
                max={100}
                minLimit={config.minLimit}
                maxLimit={config.maxLimit}
                showAsUnmoved={config.isUnmoved}
                isRanged={false}
                value={config.value}
                onChange={(value: number) => {
                    config.onChange(value)
                }}
                hintDirection={this.getHintDirectionForRiskSliderConfig(config)}
                isDisabled={!permissions.canEditAssessment(this.context.activeDepartmentId)}
            />
        )
    }

    private getHintDirectionForRiskSliderConfig(config: RiskSliderConfig): HintDirection | undefined {
        // Slider overlaps minimum-limit indicator, so hint the direction 'right'
        if (isNumber(config.minLimit) && config.value - config.minLimit < 3) {
            return HintDirection.right
        }

        // Slider overlaps maximum-limit indicator, so hint the direction 'left'
        if (isNumber(config.maxLimit) && config.value - config.maxLimit > -3) {
            return HintDirection.left
        }

        return undefined
    }

    private handleOnRiskValueChange = (riskValueKey: RiskValueKey, newValue: number) => {
        const { defaultRiskValues, onRiskValueChange } = this.props

        this.setState(prevState => {
            const riskValues = prevState.riskValues || defaultRiskValues

            return {
                userHasMadeChangesToRiskValuesOrControls: true,
                riskValues: {
                    ...riskValues,
                    [riskValueKey]: newValue,
                },
            }
        })

        if (riskValueKey === RiskValueKey.nettoImpact || riskValueKey === RiskValueKey.nettoProbability) {
            this.setState({
                userHasMadeChangesToRiskValuesOrControls: true,
                showHintToEvaluateNettoRisk: false,
            })
        }

        onRiskValueChange(riskValueKey, newValue)
    }

    private getRiskIndicators() {
        const { defaultRiskValues, isForBruto } = this.props
        const { riskValues } = this.state

        if (isForBruto) {
            return this.getRiskIndicatorsForBrutoRiskGraph()
        }

        const rawValues = riskValues ? riskValues : defaultRiskValues
        const presentedValues = this.getPresentedRiskBrutoNettoValues(rawValues)

        if (this.hideSlidersAndShowWarning) {
            const brutoIndicator = this.getIndicatorForGraph(
                'Bruto=Netto',
                isNumber(rawValues.brutoProbability) ? rawValues.brutoProbability : presentedValues.brutoProbability,
                isNumber(rawValues.brutoImpact) ? rawValues.brutoImpact : presentedValues.brutoImpact,
                true
            )
            return [brutoIndicator]
        }

        // Get netto indicator
        const isNettoMoved =
            presentedValues.nettoProbability === rawValues.nettoProbability &&
            presentedValues.nettoImpact === rawValues.nettoImpact
        const nettoIndicator = this.getIndicatorForGraph(
            'Netto',
            presentedValues.nettoProbability,
            presentedValues.nettoImpact,
            false,
            !isNettoMoved
        )

        // Show only netto indicator
        return [nettoIndicator]
    }

    private getRiskIndicatorsForBrutoRiskGraph(): RiskGraphIndicatorType[] {
        const { defaultRiskValues } = this.props
        const { riskValues } = this.state

        const rawValues = riskValues ? riskValues : defaultRiskValues
        const presentedValues = this.getPresentedRiskBrutoNettoValues(rawValues)

        // Get bruto indicator
        const isMoved =
            presentedValues.brutoProbability === rawValues.brutoProbability &&
            presentedValues.brutoImpact === rawValues.brutoImpact
        const brutoIndicator = this.getIndicatorForGraph(
            'Bruto',
            presentedValues.brutoProbability,
            presentedValues.brutoImpact,
            false,
            !isMoved
        )

        // When raw netto values are set, then show both
        if (isNumber(rawValues.nettoProbability) && isNumber(rawValues.nettoImpact)) {
            const nettoIndicator = this.getIndicatorForGraph(
                'Netto',
                rawValues.nettoProbability,
                rawValues.nettoImpact,
                true
            )
            return [nettoIndicator, brutoIndicator]
        }

        // Show only bruto indicator
        return [brutoIndicator]
    }

    private getIndicatorForGraph(
        label: string,
        probability: number,
        impact: number,
        inActive?: boolean,
        unmoved?: boolean
    ): RiskGraphIndicatorType {
        const severity = inActive ? undefined : this.calculateRiskSeverity(probability, impact)

        return {
            position: { x: probability, y: impact },
            content: () => (
                <RiskIndicator
                    forGraph={true}
                    severity={severity}
                    isInactive={inActive}
                    label={label}
                    showAsUnmoved={unmoved}
                />
            ),
            foreground: !inActive,
        }
    }

    private calculateRiskSeverity(probability: number, impact: number): SeverityLevel {
        // HIGH calculation
        if (this.isHighRisk(probability, impact)) {
            return SeverityLevel.high
        }

        // LOW/MEDIUM calculation
        return this.isMediumRisk(probability, impact) ? SeverityLevel.medium : SeverityLevel.low
    }

    private isHighRisk(probability: number, impact: number): boolean {
        // Approximated HIGH curve formula: y = -1.202^(5+0.2x) + 102
        const calculatedImpact = -Math.pow(1.202, 5 + 0.2 * probability) + 102

        return impact > calculatedImpact
    }

    private isMediumRisk(probability: number, impact: number): boolean {
        // Approximated curve formula: y = 1.202^(25-0.2x)
        const calculatedImpact = Math.pow(1.202, 25 - 0.2 * probability)

        return impact > calculatedImpact
    }
}

function toNumberOrUndefined(value: number | null | undefined): number | undefined {
    return isNumber(value) ? value : undefined
}

function toNumberOrFallback(value: number | null | undefined, fallback: number): number {
    return isNumber(value) ? value : fallback
}
