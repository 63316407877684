interface Config {
    /**
     * The project prefix used in Javascript
     * Not in CSS!
     */
    projectPrefix: string

    /**
     * If the session expires in X minutes, renew the session
     */
    sessionRenewThresholdInMinutes: number

    /**
     * The URL to the GraphQL API
     */
    graphQLApi: string
}

export const config: Config = {
    projectPrefix: 'rf',
    sessionRenewThresholdInMinutes: 30,
    graphQLApi: window.ENVIRONMENT.SERVER_URL,
}

export const prefixWithAppPrefix = (key: string) => `${config.projectPrefix}-${key}`
