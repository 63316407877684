import './SubTasksTableContainer.scss'

import { addDays } from 'date-fns'
import React from 'react'
import { localize } from '~/bootstrap'
import { CappedProfileList } from '~/components/Core/DataDisplay/CappedUserList/CappedProfileList'
import { Table } from '~/components/Core/DataDisplay/Table/Table'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Row } from '~/components/Core/Layout/Row'
import { TaskDetailFields, TaskEmployeeFields } from '~/graphql/types/Task'
import { BEM } from '~/services/BEMService'
import { RevisionDateFormat } from '../../Compliance/Controls/RevisionDateFormat'
import { TaskNameWithCompleteButton } from '../TaskNameWithCompleteButton'

interface Props {
    subTasks: TaskDetailFields[]
    onChangeSubTask?: () => void
}

export class SubTasksTableContainer extends React.PureComponent<Props> {
    private bem = new BEM('SubTasksTableContainer')
    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks.AllTasksTable.headers)

    public render() {
        return <Table className={this.bem.getClassName()} columns={this.getTableColumns()} data={this.getTableData()} />
    }

    private getTableColumns() {
        return [
            { field: 'name', headerLabel: this.loc(t => t.name), truncateField: true },
            { field: 'employees', headerLabel: this.loc(t => t.employees) },
            { field: 'startAt', headerLabel: this.loc(t => t.startAt) },
            { field: 'dueAt', headerLabel: this.loc(t => t.dueAt) },
        ]
    }

    private getTableData() {
        const { subTasks } = this.props

        return subTasks.map(subTask => {
            const { employees, completedAt, startAt, dueAt } = subTask

            return {
                id: subTask.id,
                columns: {
                    name: this.renderName(subTask),
                    employees: this.renderEmployees(employees),
                    startAt: this.renderStartAt(completedAt, startAt),
                    dueAt: this.renderDueAt(completedAt, dueAt),
                },
                inactive: !!completedAt,
            }
        })
    }

    private renderName(subTask: TaskDetailFields) {
        const { onChangeSubTask } = this.props
        const className = this.bem.getElement('name', () => ({ completed: !!subTask.completedAt }))

        return (
            <TaskNameWithCompleteButton
                task={subTask}
                hideMetadata={true}
                onChange={onChangeSubTask}
                className={className}
            />
        )
    }

    private renderEmployees(employees?: TaskEmployeeFields[]) {
        if (!employees || !employees.length) {
            return
        }

        const users = employees.map(({ user }) => ({
            id: user.id,
            fullName: user.profile.fullName,
            avatar: user.profile.avatar,
        }))

        return (
            <Row smallSpacing={true}>
                <CappedProfileList users={users} />
            </Row>
        )
    }

    private renderStartAt(completedAt: string, startAt?: string) {
        if (!startAt) {
            return
        }

        const className = this.bem.getElement('date', () => ({ completed: !!completedAt }))

        return <DateFormat date={new Date(startAt)} readable={true} noWeekday={true} className={className} />
    }

    private renderDueAt(completedAt: string, dueAt?: string) {
        if (!dueAt) {
            return
        }

        const className = this.bem.getElement('date', () => ({ completed: !!completedAt }))

        return (
            <RevisionDateFormat
                date={new Date(dueAt)}
                thresholdDate={addDays(new Date(dueAt), 1)}
                className={className}
            />
        )
    }
}
