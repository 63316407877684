import React from 'react'

interface Props {
    text?: string
}

export const WithNewlines: React.SFC<Props> = props => {
    if (props.text) {
        return (
            <>
                {props.text.split('\n').map((textPart, key) => {
                    return (
                        <React.Fragment key={key}>
                            {textPart}
                            <br />
                        </React.Fragment>
                    )
                })}
            </>
        )
    }

    return <>{props.text}</>
}
