import React from 'react'
import gql from 'graphql-tag'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AlertDigestFrequency } from '~/graphql/types/User'
import { EmployeeRole } from '~/generated/graphql'

interface Props {
    children: (employee: Employee) => React.ReactNode
    id: number
}

interface Employee {
    id: number
    alertDigestFrequency: AlertDigestFrequency
    user: {
        id: number
        email: string
        role: EmployeeRole
        profile: {
            id: number
            fullName: string
            firstName: string
            lastName: string
            phoneNumber: string | null
            avatar: string | null
        }
    }
    departments: {
        id: number
        name: string
    }[]
}

const EMPLOYEE_QUERY = gql`
    query employee($id: Int!, $customerSlug: String) {
        employee(id: $id, customerSlug: $customerSlug) {
            id
            alertDigestFrequency
            role
            user {
                id
                email
                profile {
                    id
                    fullName
                    firstName
                    lastName
                    phoneNumber
                    avatar
                }
            }
            departments {
                id
                name
            }
        }
    }
`

export class EmployeeQuery extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, id } = this.props
        return (
            <PageQuery<Employee> query={EMPLOYEE_QUERY} variables={{ id, customerSlug: this.context.customer.slug }}>
                {employee => children(employee)}
            </PageQuery>
        )
    }
}
