import React from 'react'
import { LinkList } from '~/components/Core/Layout/LinkList'
import { GroupedItem } from '~/graphql/types/LinkedItem'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { getNameForType, transformItemForLinkList } from '~/utils/linkedItemNaming'
import { ExpiredArticleWarning } from '../LawAndRegulation/ExpiredArticleWarning'

interface Props {
    linkedItems: GroupedItem[]
    showExpiredStatus?: boolean
    topicId?: number
    onDeleteExpired?: () => void
}

interface State {}

export class GroupedLinkedItemsLists extends React.Component<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { linkedItems, showExpiredStatus, topicId, onDeleteExpired } = this.props

        return linkedItems.map((group, index) => (
            <LinkList
                renderExpiredStatus={
                    showExpiredStatus && topicId
                        ? (id, expired) => (
                              <ExpiredArticleWarning
                                  abstractLawArticleId={id}
                                  topicId={topicId}
                                  expired={expired}
                                  showDelete={true}
                                  onDelete={onDeleteExpired}
                              />
                          )
                        : undefined
                }
                key={group.type + index}
                title={getNameForType(group)}
                items={group.items.map(item => transformItemForLinkList(group, item, this.context.customer.slug))}
            />
        ))
    }
}
