import React from 'react'
import { SortState } from '~/components/Core/DataDisplay/Table/Table'
import { InfiniteScrollOptions, InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    MonitoringReportsDocument,
    MonitoringReportsQuery as MonitoringReports,
    MonitoringReportsQueryVariables,
    SortDirectionEnumType,
} from '~/generated/graphql'

interface Props extends Partial<SortState> {
    take?: number
    topicId?: number
    controlId?: number
    searchString?: string
    archived?: boolean
    children: (options: InfiniteScrollOptions<MonitoringReportsQueryType>) => JSX.Element
}

export type MonitoringReportsQueryType = NonNullable<NonNullable<MonitoringReports['monitoringReports']>['nodes']>[0]

export class MonitoringReportsQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, searchString, take, topicId, controlId, archived } = this.props

        return (
            <InfiniteScrollQuery<MonitoringReportsQueryType, MonitoringReportsQueryVariables>
                take={take}
                query={MonitoringReportsDocument}
                variables={{
                    filters: {
                        name: searchString,
                        topicId,
                        archived,
                        controlId,
                    },
                    sort: {
                        assessedAt: SortDirectionEnumType.desc,
                    },
                }}
            >
                {options => children(options)}
            </InfiniteScrollQuery>
        )
    }
}
