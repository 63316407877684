import React from 'react'
import { SortDirection } from '~/components/Core/DataDisplay/Table/Table'
import { AllTasksTable } from '~/views/Customer/Tasks/AllTasks/AllTasksTable'
import { TasksQueryFilters } from '~/views/Customer/Tasks/TasksQuery/TaskQuery'
import { TasksPaginatableQuery } from '~/views/Customer/Tasks/TasksQuery/TasksPaginatableQuery'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { localize } from '~/bootstrap'

interface Props {
    filters?: Omit<TasksQueryFilters, 'onlyOverdueTasks'>
    setRefetchFunction: (refetchFunction: () => void) => void
    refetch: () => void
}

interface State {
    sort?: {
        [field: string]: SortDirection
    }
}

export class OverdueTasksTableContainer extends React.Component<Props, State> {
    public state: State = {
        sort: {
            dueAt: 'ASC',
        },
    }

    public render() {
        const { filters, setRefetchFunction, refetch } = this.props
        const { sort } = this.state

        return (
            <TasksPaginatableQuery filters={{ onlyOverdueTasks: true, ...filters }} sort={sort}>
                {({ data, loading, loadingMore, canFetchMore, fetchMore, refetch: refetchTasks }) => {
                    setRefetchFunction(refetchTasks)

                    const tasks = data && data.nodes ? data.nodes : []

                    const amountLeft = Math.min(data ? data.totalCount - tasks.length : 0, 10)

                    return (
                        <>
                            <AllTasksTable
                                tasks={tasks}
                                loading={loading}
                                loadingMore={loadingMore}
                                onSortDirectionChange={this.handleOnSortDirectionChange}
                                defaultSortDirection={{ field: 'dueAt', direction: 'ASC' }}
                                refetchTasks={refetch}
                                sortableHeaders={['name', 'priority', 'dueAt', 'startAt']}
                            />
                            {!loadingMore && canFetchMore && (
                                <Row alignCenter={true}>
                                    <Button icon={IconType.arrowDown} type={ButtonType.actionLink} onClick={fetchMore}>
                                        {localize.translate(t => t.Customer.Planning.Tasks.showAmountMoreTasks, {
                                            amount: amountLeft,
                                        })}
                                    </Button>
                                </Row>
                            )}
                        </>
                    )
                }}
            </TasksPaginatableQuery>
        )
    }

    private handleOnSortDirectionChange = (field: string, direction: SortDirection) => {
        this.setState({
            sort: {
                [field]: direction,
            },
        })
    }
}
