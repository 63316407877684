import { EmployeeOperationEnum, EmployeePermissionEnum, EmployeeRole, Language, UserRole } from '~/generated/graphql'

export enum UserStatus {
    active = 'ACTIVE',
    inactive = 'INACTIVE',
    invited = 'INVITED',
}

interface Customer {
    id: number
    name: string
    slug: string
    flowAIChat: boolean
    editorialAccess: boolean
}

export interface Employee {
    id: number
    customer: Customer
    onboardedAt: string
    departments: {
        id: number
        name: string
    }[]
}

export interface EmployeeWithPassiveDepartmentEmployees {
    id: number
    customer: Customer
    onboardedAt: string
    role: EmployeeRole
    permissions?: EmployeePermissionEnum[]
    operations?: EmployeeOperationEnum[]
    departments: {
        id: number
        name: string
        passiveEmployees: {
            id: number
        }[]
    }[]
}

export interface Consultant {
    id: number
    organization: {
        id: number
        name: string
    }
}

export enum AlertDigestFrequency {
    daily = 'DAILY',
    weekly = 'WEEKLY',
    never = 'NEVER',
}

export interface User {
    id: number
    email: string
    role?: UserRole
    isCustomerConsultant: boolean
    loggedInWithSSO: boolean
    profile: {
        id: number
        firstName: string
        lastName: string
        fullName: string
        avatar: string
        phoneNumber: string
    }
    phoneNumber: string | null
    invitedAt: string | null
    language: Language
    lastAppVersionSeenAt: string | null
    lastAppUpdateSeen: {
        date?: string
        version?: string
        update?: number
        skip?: boolean
    } | null
    activatedAt: string | null
    lastLoginAt: string | null
    status: UserStatus
}
