import uniqBy from 'lodash-es/uniqBy'
import get from 'lodash-es/get'
import set from 'lodash-es/set'

export const getMergerByPath = <TData = any>(path: string, identifier: string = 'id') => {
    return (previous: TData, current: TData) => {
        const previousArray = get(previous, path) || []
        const currentArray = get(current, path) || []

        const mergedArray = uniqBy([...previousArray, ...currentArray], identifier)

        const result = Object.assign({}, current)
        set(result, path, mergedArray)

        return result
    }
}
