import gql from 'graphql-tag'

export interface Attachment {
    id: number
    path: string
    filename: string
    mimetype: string
}

export const AttachmentFields = gql`
    fragment AttachmentFields on AttachmentType {
        id
        path
        filename
        mimetype
    }
`
