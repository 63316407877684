import React from 'react'
import { SortDirection } from '~/components/Core/DataDisplay/Table/Table'
import { TasksInfiniteScrollQuery } from '~/views/Customer/Tasks/TasksQuery/TasksInfiniteScrollQuery'
import { AllTasksTable } from '~/views/Customer/Tasks/AllTasks/AllTasksTable'
import { TasksQueryFilters } from '~/views/Customer/Tasks/TasksQuery/TaskQuery'

interface Props {
    filters?: Omit<TasksQueryFilters, 'onlyOverdueTasks'>
    includeOverdue?: boolean
    setRefetchFunction: (refetchFunction: () => void) => void
    refetch: () => void
}

interface State {
    sort?: {
        [field: string]: SortDirection
    }
}

export class TasksTableContainer extends React.Component<Props, State> {
    public state: State = {
        sort: {
            dueAt: 'ASC',
        },
    }

    public render() {
        const { filters, includeOverdue, setRefetchFunction, refetch } = this.props
        const { sort } = this.state

        return (
            <TasksInfiniteScrollQuery
                filters={{ onlyOverdueTasks: includeOverdue ? undefined : false, ...filters }}
                sort={sort}
            >
                {({ data, loading, loadingMore, refetch: refetchTasks }) => {
                    setRefetchFunction(refetchTasks)

                    const tasks = data && data.nodes ? data.nodes : []
                    return (
                        <AllTasksTable
                            tasks={tasks}
                            loading={loading}
                            loadingMore={loadingMore}
                            refetchTasks={refetch}
                            onSortDirectionChange={this.handleOnSortDirectionChange}
                            defaultSortDirection={{ field: 'dueAt', direction: 'ASC' }}
                            sortableHeaders={['name', 'priority', 'dueAt', 'startAt']}
                        />
                    )
                }}
            </TasksInfiniteScrollQuery>
        )
    }

    private handleOnSortDirectionChange = (field: string, direction: SortDirection) => {
        this.setState({
            sort: {
                [field]: direction,
            },
        })
    }
}
