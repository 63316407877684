import { isPast } from 'date-fns'
import React from 'react'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { AttentionIcon } from '~/components/Core/Icon/AttentionIcon/AttentionIcon'
import { BEM, ClassValue } from '~/services/BEMService'
import './LinkedControlCard.scss'
import { IconType } from '~/components/Core/Icon/IconType'
import { Icon } from '~/components/Core/Icon/Icon'
import { isDate } from 'lodash-es'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    className?: ClassValue
    label: string
    icon: IconType
    revisionDate?: string | Date | null
    rightComponent?: React.ReactNode
    faded?: boolean
    textOverflow?: boolean
    description?: string
    onClick?: () => void
}

interface State {}

export class LinkedControlCard extends React.Component<Props, State> {
    private bem = new BEM('LinkedControlCard', () => ({
        'is-faded': !!this.props.faded,
        'show-text-overflow': !!this.props.textOverflow,
    }))

    public render() {
        const { onClick, className, label, icon, rightComponent, description } = this.props

        return (
            <Card className={this.bem.getClassName(className)} onClick={onClick}>
                <Row spaceBetween={true}>
                    <h5 className={this.bem.getElement('title')}>
                        <Icon type={icon} className={this.bem.getElement('icon')} />
                        {this.renderRevisionDateExpiredIcon()}
                        {label}
                    </h5>
                </Row>
                {description && (
                    <Row spaceBetween={true}>
                        <Paragraph preserveLine={true} className={this.bem.getElement('description')}>
                            {description}
                        </Paragraph>
                    </Row>
                )}
                {rightComponent}
            </Card>
        )
    }

    private renderRevisionDateExpiredIcon() {
        const { revisionDate } = this.props

        if (!revisionDate) {
            return null
        }

        const hasPast = isDate(revisionDate) ? isPast(revisionDate) : isPast(new Date(revisionDate))

        if (!hasPast) {
            return null
        }

        return <AttentionIcon className={this.bem.getElement('attention-icon')} />
    }
}
