import './RelevantModalOverview.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { DepartmentType, NewsItemType } from '~/generated/graphql'
import { SignalingTitle } from '~/components/Domain/Signaling/SignalingTitle'
import { RelevantMatrix } from '~/components/Domain/Signaling/RelevantMatrix/RelevantMatrix'
import { Topic } from '~/components/Domain/Topic/TopicsQuery'
import { localize } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Input } from '~/components/Core/DataEntry/Form/Input'

interface Props {
    className?: ClassValue
    defaultTopics?: Topic[]
    defaultDepartments?: DepartmentType[]
    topics?: Topic[]
    departments?: DepartmentType[]
    defaultNote?: string
    newsItem: NewsItemType
    selectedTopics?: Topic[]
    setModalState: (modalState: { [key: string]: any }) => void
    setActiveIndex: (index: number) => void
    hideDepartments?: boolean
}

interface State {}

export class RelevantModalOverview extends React.PureComponent<Props, State> {
    private bem = new BEM('RelevantModalOverview')
    private loc = localize.namespaceTranslate(t => t.Customer.Signaling.RelevantModal)

    public render() {
        const { newsItem, className, setActiveIndex, topics, departments, selectedTopics } = this.props
        const { defaultTopics, defaultDepartments, hideDepartments, defaultNote } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <SignalingTitle forModal={true} className={this.bem.getElement('title')} newsItem={newsItem} />
                <RelevantMatrix
                    defaultTopics={defaultTopics}
                    defaultDepartments={defaultDepartments}
                    topics={topics}
                    suggestedTopics={newsItem.suggestedTopics as Topic[]}
                    departments={departments}
                    className={this.bem.getElement('matrix')}
                    onAddTopic={() => setActiveIndex(1)}
                    onAddDepartment={() => setActiveIndex(2)}
                    onRemoveTopic={this.handleRemoveTopic}
                    onRemoveDepartment={this.handleRemoveDepartment}
                    selectedTopics={selectedTopics}
                    hideDepartments={hideDepartments}
                />
                <>
                    <Field label={this.loc(t => t.note)} compact={true} forInput="note">
                        <Input
                            type="textarea"
                            placeholder={this.loc(t => t.placeholder)}
                            name="note"
                            onChange={a => this.props.setModalState({ note: a })}
                            defaultValue={defaultNote}
                        />
                    </Field>
                </>
            </div>
        )
    }

    private handleRemoveTopic = (id: number) => {
        const { topics = [], setModalState } = this.props

        const newTopicState = topics.filter(topic => topic.id !== id)

        setModalState({
            selectedTopics: newTopicState,
        })
    }

    private handleRemoveDepartment = (id: number) => {
        const { departments = [], setModalState } = this.props

        const newDepartmentState = departments.filter(department => department.id !== id)

        setModalState({
            extraDepartments: newDepartmentState,
        })
    }
}
