import './TopicDetailViewInboxTableContainer.scss'

import { isBefore } from 'date-fns'
import { sortBy, uniqBy } from 'lodash-es'
import React from 'react'

import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { CappedProfileList } from '~/components/Core/DataDisplay/CappedUserList/CappedProfileList'
import { ColumnOptions, RowData, SortDirection, Table } from '~/components/Core/DataDisplay/Table/Table'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Column } from '~/components/Core/Layout/Column'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { AlertType, TaskType } from '~/generated/graphql'
import { LinkedTaskItem } from '~/graphql/types/Task'
import { InboxLabel } from '../Inbox/InboxLabel'
import { TaskOpenClosedCounter } from '../Task/TaskOpenClosedCounter'
import { ContextTasksModal } from '../Task/TasksByContext/ContextTasksModal'
import { BEM } from '~/services/BEMService'

interface Props {
    alerts?: AlertType[] | null
    onCompleteOrReopenTasks: () => void
}

interface State {
    sortDirection: 'ASC' | 'DESC'
}

enum InboxTableField {
    item = 'ITEM',
    publishedAt = 'PUBLISHEDAT',
    tasks = 'TASKS',
    employees = 'EMPLOYEES',
}

export class TopicDetailViewInboxTableContainer extends React.PureComponent<Props, State> {
    public state: State = {
        sortDirection: 'DESC',
    }

    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDetailView.InboxItemsTable)
    private bem = new BEM('TopicDetailViewInboxTableContainer')
    private tableColumns: ColumnOptions[] = [
        {
            field: InboxTableField.item,
            headerLabel: this.loc(t => t.inboxItem),
            align: 'left',
            truncateField: true,
        },
        {
            field: InboxTableField.publishedAt,
            headerLabel: this.loc(t => t.date),
            align: 'left',
            sortable: true,
            noAutoWidth: true,
            minWidth: 140,
        },
        {
            field: InboxTableField.tasks,
            headerLabel: this.loc(t => t.tasks),
            align: 'left',
            noAutoWidth: true,
        },
        {
            field: InboxTableField.employees,
            headerLabel: this.loc(t => t.responsible),
            align: 'left',
            noAutoWidth: true,
        },
    ]

    public render() {
        const { alerts } = this.props

        if (!alerts || !alerts.length) {
            return null
        }

        return (
            <Column className={this.bem.getClassName()}>
                <SectionTitle>{this.loc(t => t.title)}</SectionTitle>
                <Table
                    className={this.bem.getElement('table')}
                    data={this.getTableData()}
                    columns={this.tableColumns}
                    onSortDirectionChange={this.handleSortDirectionChange}
                />
            </Column>
        )
    }

    private getTableData(): RowData[] {
        const sortedAlerts = this.sortAlerts()

        return sortedAlerts.map(alert => ({
            id: alert.id,
            columns: this.getTableRow(alert),
        }))
    }

    private handleSortDirectionChange = (field: string, sortDirection: SortDirection) => {
        if (!sortDirection) {
            this.setState({ sortDirection: 'ASC' })
            return
        }

        this.setState({ sortDirection })
    }

    private sortAlerts() {
        const { alerts } = this.props
        const { sortDirection } = this.state

        if (sortDirection === 'ASC') {
            const sortedAlerts = sortBy(alerts, a => new Date(a.publishedAt || 0))
            return sortedAlerts
        }

        const sortedAlerts = sortBy(alerts, a => -new Date(a.publishedAt || 0))
        return sortedAlerts
    }

    private getTableRow(alert: AlertType) {
        return {
            [InboxTableField.item]: this.renderNameColumn(alert),
            [InboxTableField.publishedAt]: this.renderDateColum(alert.publishedAt),
            [InboxTableField.tasks]: this.renderTaskColumn(alert),
            [InboxTableField.employees]: this.renderEmployeesColumn(alert.tasks),
        }
    }

    private renderNameColumn(alert: AlertType) {
        const { id, name, impactLevel } = alert
        const inboxItem = { id, name, impactLevel }

        return <InboxLabel inboxItem={inboxItem} />
    }

    private renderDateColum(publishedAt?: Date | null) {
        if (!publishedAt) {
            return
        }

        const date = new Date(publishedAt)
        const localizedDate = localize.dateFormat(date, { noWeekday: true, readable: true })

        return <Paragraph>{localizedDate}</Paragraph>
    }

    private renderTaskColumn(alert: AlertType) {
        const { onCompleteOrReopenTasks } = this.props
        const { openCount, completedCount, overdueCount } = this.getTaskCounts(alert.tasks)

        return (
            <ModalManager
                render={openModal => (
                    <Button type={ButtonType.noStyling} onClick={openModal}>
                        <TaskOpenClosedCounter
                            openAmount={openCount}
                            closedAmount={completedCount}
                            overDueAmount={overdueCount}
                        />
                    </Button>
                )}
                renderModal={closeModal => (
                    <ContextTasksModal
                        amountOfOpenTasks={openCount}
                        amountOfCompletedTasks={completedCount}
                        linkedItem={alert as LinkedTaskItem}
                        requestClose={closeModal}
                        onCompleteOrReopenTasks={onCompleteOrReopenTasks}
                    />
                )}
            />
        )
    }

    private renderEmployeesColumn(tasks?: TaskType[] | null) {
        if (!tasks || !tasks.length) {
            return
        }

        const allEmployees = tasks.flatMap(({ employees }) => employees)
        const uniqEmployees = uniqBy(allEmployees, 'id')
        const users = uniqEmployees.map(({ user }) => ({
            id: user.id,
            fullName: user.profile.fullName,
            avatar: user.profile.avatar,
        }))

        return <CappedProfileList users={users} />
    }

    private getTaskCounts(tasks?: TaskType[] | null) {
        let openCount = 0
        let completedCount = 0
        let overdueCount = 0

        if (!tasks || !tasks.length) {
            return { openCount, completedCount, overdueCount }
        }

        const now = new Date()

        for (const { completedAt, dueAt } of tasks) {
            if (completedAt) {
                completedCount++
                continue
            }

            const isOverdue = dueAt && isBefore(new Date(dueAt), now)
            if (isOverdue) {
                overdueCount++
                openCount++
                continue
            }

            openCount++
        }

        return { openCount, completedCount, overdueCount }
    }
}
