import './CustomerGroupsOverviewView.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ColumnOptions, RowData, Table } from '~/components/Core/DataDisplay/Table/Table'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { ParamManager, SetParamStateFn } from '~/components/Core/ParamManager/ParamManager'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM } from '~/services/BEMService'
import { routes } from '~/views/routes'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerGroupsDocument, CustomerGroupType, CustomerGroupsQueryVariables } from '~/generated/graphql'

interface Filters {
    search: string
}

export class CustomerGroupsOverviewView extends React.PureComponent {
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerGroupsView.CustomerGroupsOverviewView)
    private bem = new BEM('CustomerGroupsOverviewView')

    private columns: ColumnOptions[] = [
        { field: 'name', headerLabel: this.loc(t => t.columns.name), truncateField: true },
        { field: 'customerCount', headerLabel: this.loc(t => t.columns.customerCount) },
        { field: 'employeeCount', headerLabel: this.loc(t => t.columns.employeeCount) },
    ]

    public render() {
        return (
            <Page className={this.bem.getClassName()}>
                <PageHeader title={this.loc(t => t.title)} />
                <ParamManager<Filters> defaultState={{ search: '' }}>
                    {({ paramState, setParamState }) => (
                        <Column bigSpacing={true}>
                            {this.renderAction(paramState, setParamState)}
                            {this.renderCustomerGroups(paramState)}
                        </Column>
                    )}
                </ParamManager>
            </Page>
        )
    }

    public renderAction(paramState: Filters, setParamState: SetParamStateFn<Filters>) {
        return (
            <Row spaceBetween={true}>
                <div>
                    <Search
                        placeholder={localize.translate(t => t.Generic.search.byName)}
                        defaultValue={paramState.search}
                        onChange={search => setParamState({ search: search || '' })}
                    />
                </div>
                <Button rounded={true} to={routes.consultant.customerGroups.create} icon={IconType.plus}>
                    {this.loc(t => t.newGroup)}
                </Button>
            </Row>
        )
    }

    public renderCustomerGroups(paramState: Filters) {
        const { search } = paramState

        return (
            <InfiniteScrollQuery<CustomerGroupType, CustomerGroupsQueryVariables>
                query={CustomerGroupsDocument}
                variables={{ search }}
            >
                {({ data, loading, loadingMore }) => (
                    <Table
                        className={this.bem.getElement('table')}
                        loading={loading}
                        loadingMore={loadingMore}
                        columns={this.columns}
                        data={this.getTableData(data?.nodes || [])}
                    />
                )}
            </InfiniteScrollQuery>
        )
    }

    private getTableData(customerGroups: CustomerGroupType[]): RowData[] {
        return customerGroups.map(cg => ({
            id: cg.id,
            columns: {
                name: this.renderName(cg.id, cg.name),
                customerCount: <Paragraph>{cg.customers?.map(c => c.id).length}</Paragraph>,
                employeeCount: <Paragraph>{cg.totalUserCount}</Paragraph>,
            },
        }))
    }

    private renderName(id: number, name: string) {
        return (
            <Tooltip
                message={name}
                direction={ToolTipDirection.downStart}
                className={this.bem.getElement('name-tooltip')}
            >
                <Button
                    className={this.bem.getElement('name-button')}
                    type={ButtonType.subtleItemLink}
                    to={routes.consultant.customerGroups.detail(id).view}
                >
                    {name}
                </Button>
            </Tooltip>
        )
    }
}
