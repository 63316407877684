import React from 'react'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import gql from 'graphql-tag'
import { GQLMutation } from '~/graphql/Mutation'
import { CONTROL_DETAIL_QUERY } from '~/components/Domain/Compliance/Controls/ControlDetail/ControlQuery'
import { MutationFn } from 'react-apollo'
import { localize, notification } from '~/bootstrap'
import { DocumentFields } from './DocumentFields'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    requestClose: () => void
    controlId: number
}

interface State {}

const UPLOAD_MUTATION = gql`
    mutation uploadControlDocument($controlId: Int!, $fields: UploadControlDocumentFields!) {
        uploadControlDocument(controlId: $controlId, fields: $fields)
    }
`

interface UploadMutationResponse {
    uploadControlDocument: boolean
}

interface UploadMutationVariables {
    controlId: number
    fields: {
        file?: File | null
        startDate?: Date | null
        revisionDate?: Date | null
        responsibleEmployeeIds?: number[]
        responsibleShadowEmployeeIds?: number[]
        responsibleEmployees?: string[]
    }
}

export class UploadControlDocumentModal extends React.Component<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { requestClose, controlId } = this.props

        return (
            <GQLMutation<UploadMutationResponse, UploadMutationVariables>
                mutation={UPLOAD_MUTATION}
                refetchQueries={[
                    {
                        query: CONTROL_DETAIL_QUERY,
                        variables: { id: controlId, departmentId: this.context.activeDepartmentId },
                    },
                ]}
            >
                {(mutate, { loading }) => (
                    <Form isCompact={true} onSubmit={this.handleOnSubmit(mutate)}>
                        <Modal
                            title={localize.translate(
                                t => t.Customer.Compliance.ControlDocument.UploadControlDocumentModal.title
                            )}
                            requestClose={requestClose}
                            submitForm={true}
                            loading={loading}
                        >
                            <ErrorMessage path={'uploadControlDocument'} />
                            <DocumentFields />
                        </Modal>
                    </Form>
                )}
            </GQLMutation>
        )
    }

    private handleOnSubmit = (mutate: MutationFn<UploadMutationResponse, UploadMutationVariables>) => async (
        formState: FormState
    ) => {
        const { controlId, requestClose } = this.props

        const response = await mutate({
            variables: {
                controlId,
                fields: {
                    file: formState.file,
                },
            },
        })

        if (response && response.data && response.data.uploadControlDocument) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))
            requestClose()
        }
    }
}
