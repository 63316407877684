import React from 'react'

import { RiskSettingsFields } from './RiskSettingsFields'
import { RiskSettingsQuery, RiskSettingsFieldsQueryResponse } from '../RiskSettingsQuery'
import { ClassValue } from '~/services/BEMService'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import get from 'lodash-es/get'

interface Props {
    className?: ClassValue
    isEditable?: boolean
    onChange?: () => void
}

export interface RiskSettingsField {
    type: RiskSettingsType
    description?: string
}

export enum RiskSettingsType {
    highImpact = 'highImpactDescription',
    lowImpact = 'lowImpactDescription',
    highChance = 'highPropbabiltyDescription',
    lowChance = 'lowPropbabiltyDescription',
}

export class RiskSettingsFieldsContainer extends React.Component<Props, {}> {
    public render() {
        const { isEditable } = this.props
        return (
            <RiskSettingsQuery>
                {({ data, loading }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    return <RiskSettingsFields riskFields={this.transform(data)} isEditable={isEditable} />
                }}
            </RiskSettingsQuery>
        )
    }

    private transform(data?: RiskSettingsFieldsQueryResponse): RiskSettingsField[] {
        const highChance = get(data, 'customer.settings.compliance.riskGraph.highPropbabiltyDescription')
        const lowChance = get(data, 'customer.settings.compliance.riskGraph.lowPropbabiltyDescription')
        const highImpact = get(data, 'customer.settings.compliance.riskGraph.highImpactDescription')
        const lowImpact = get(data, 'customer.settings.compliance.riskGraph.lowImpactDescription')

        return [
            {
                type: RiskSettingsType.highChance,
                description: highChance,
            },
            {
                type: RiskSettingsType.lowChance,
                description: lowChance,
            },
            {
                type: RiskSettingsType.highImpact,
                description: highImpact,
            },
            {
                type: RiskSettingsType.lowImpact,
                description: lowImpact,
            },
        ]
    }
}
