import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ArchiveTaskDocument, ArchiveTaskMutation, ArchiveTaskMutationVariables } from '~/generated/graphql'

interface Props {
    children: (
        mutation: MutationFn<ArchiveTaskMutation, ArchiveTaskMutationVariables>,
        options: MutationResult
    ) => JSX.Element
}

export type ArchiveTaskMutationFN = MutationFn<ArchiveTaskMutation, ArchiveTaskMutationVariables>

export class ArchiveTask extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<ArchiveTaskMutation, ArchiveTaskMutationVariables> mutation={ArchiveTaskDocument}>
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
