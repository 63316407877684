import './LoginToEnvironmentButton.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'
import { withRouter, RouteComponentProps } from 'react-router'
import { routes } from '~/views/routes'

interface Props extends RouteComponentProps<{}> {
    className?: ClassValue
    slug?: string
}

const bem = new BEM('LoginToEnvironmentButton')

export const LoginToEnvironmentButton = withRouter<Props, React.ComponentType<Props>>((props: Props) => {
    const { className } = props
    return (
        <Button
            icon={IconType.logOut}
            type={ButtonType.secondary}
            className={bem.getClassName(className)}
            onClick={() =>
                props.history.push({
                    pathname: routes.customer(props.slug).legalFramework.index,
                    state: { lastConsultantRoute: window.location.pathname },
                })
            }
        >
            {localize.translate(t => t.Consultant.DashboardView.goToEnvironment)}
        </Button>
    )
})
