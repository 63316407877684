import React from 'react'
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TaskControlOverviewView } from './TaskControlOverviewView'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { permissions } from '~/bootstrap'
import { EmployeePermissionEnum } from '~/generated/graphql'
import { InboxControlOverviewView } from './InboxControlOverviewView'

interface Props extends RouteComponentProps<{}> {}
interface State {}

export class ControlView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const hasNoPermission = !permissions.hasPermission(EmployeePermissionEnum.canSetControlOperations)
        if (hasNoPermission) {
            return (
                <Switch>
                    <Route component={AppNotFoundView} />
                </Switch>
            )
        }

        const basePath = routes.customer(this.context.customer.slug).control
        const hasTaskControlAccess = permissions.canAccessTaskControlPage()
        const hasInboxControlAccess = permissions.canAccessInboxControlPage()

        return (
            <Switch>
                <Redirect path={basePath.index} to={basePath.taskControl.index} exact={true} />
                <Route
                    path={basePath.taskControl.index}
                    component={hasTaskControlAccess ? TaskControlOverviewView : AppNotFoundView}
                />
                <Route
                    path={basePath.inboxControl}
                    component={hasInboxControlAccess ? InboxControlOverviewView : AppNotFoundView}
                    exact={true}
                />
                <Route component={AppNotFoundView} />
            </Switch>
        )
    }
}
