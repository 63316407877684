import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { InboxControlFilters } from '~/views/Customer/Control/InboxControlOverviewView'
import {
    DepartmentAlertsForMonthDocument,
    DepartmentAlertsForMonthQueryVariables,
    AlertsForMonthSort,
    GroupedDepartmentAlertDataType,
} from '~/generated/graphql'
import { PaginatableOptions, PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'

interface Props {
    month: number
    year: number
    filters?: InboxControlFilters
    sort?: AlertsForMonthSort
    children: (
        options: PaginatableOptions<GroupedDepartmentAlertDataType, DepartmentAlertsForMonthQueryVariables>
    ) => JSX.Element
}

export class DepartmentAlertsForMonth extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { filters, sort, month, year, children } = this.props

        return (
            <PaginatableQuery<GroupedDepartmentAlertDataType, DepartmentAlertsForMonthQueryVariables>
                path="alertsForMonth"
                take={8}
                variables={{ filters, sort, month, year }}
                query={DepartmentAlertsForMonthDocument}
            >
                {children}
            </PaginatableQuery>
        )
    }
}
