import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import {
    EditDepartmentCustomerFrameworksMutationVariables,
    EditDepartmentCustomerFrameworksDocument,
    EditDepartmentCustomerFrameworksMutation,
    AllDepartmentsDocument,
} from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    children: (
        mutation: MutationFn<
            EditDepartmentCustomerFrameworksMutation,
            EditDepartmentCustomerFrameworksMutationVariables
        >,
        options: MutationResult
    ) => JSX.Element
}

export class EditDepartmentCustomerFrameworks extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<EditDepartmentCustomerFrameworksMutation, EditDepartmentCustomerFrameworksMutationVariables>
                mutation={EditDepartmentCustomerFrameworksDocument}
                refetchQueries={[
                    {
                        query: AllDepartmentsDocument,
                        variables: { customerSlug: this.context.customer.slug },
                    },
                ]}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
