import { localize } from '~/bootstrap'
import { routes } from './routes'

const customerLoc = localize.namespaceTranslate(t => t.Customer)
const consultantLoc = localize.namespaceTranslate(t => t.Consultant)

export const breadcrumbs = {
    customer: (customer: string = ':customer') => ({
        risks: {
            index: {
                label: customerLoc(t => t.navigation.Risks),
            },
            assessments: {
                index: {
                    label: customerLoc(t => t.routes['/:customer/risks/assessments']),
                    to: routes.customer(customer).risks.assessments.index,
                },
                view: (label: string, id: string | number = ':id') => ({
                    label,
                    to: routes.customer(customer).risks.assessments.view(id),
                }),
            },
        },
        compliance: {
            index: {
                label: customerLoc(t => t.navigation.Compliance),
            },
            norms: {
                index: {
                    label: customerLoc(t => t.routes['/:customer/compliance/norms']),
                    to: `/${customer}/compliance/norms`,
                },
                theme: (label: string, id: string | number = ':id') => ({
                    label,
                    to: `/${customer}/compliance/norms/${id}`,
                }),
                topic: (label: string, id: string | number = ':id') => ({
                    label,
                    to: `/${customer}/compliance/norms/topic/${id}`,
                }),
            },
            controls: {
                index: {
                    label: customerLoc(t => t.routes['/:customer/compliance/controls']),
                    to: `/${customer}/compliance/controls`,
                },
            },
            monitoring: {
                index: {
                    label: customerLoc(t => t.routes['/:customer/monitoring']),
                    to: `/${customer}/monitoring`,
                },
            },
        },
        legalFramework: {
            index: {
                label: customerLoc(t => t.navigation.LegalFramework),
            },
            themes: {
                index: {
                    label: customerLoc(t => t.navigation.LegalFrameworkThemes),
                    to: `/${customer}/legal-framework`,
                },
                view: (label: string, id: string | number = ':id') => ({
                    label,
                    to: `/${customer}/legal-framework/themes/${id}`,
                }),
                topic: {
                    view: (label: string, id: string | number = ':id') => ({
                        label,
                        to: `/${customer}/legal-framework/topic/${id}`,
                    }),
                },
            },
            radar: {
                index: {
                    label: customerLoc(t => t.routes['/:customer/radar']),
                    to: `/${customer}/radar`,
                },
                view: (label: string, id: string | number = ':id') => ({
                    label,
                    to: `/${customer}/radar/${id}`,
                }),
            },
            consultations: {
                index: {
                    label: customerLoc(t => t.routes['/:customer/consultations']),
                    to: `/${customer}/consultations`,
                },
                view: (label: string, id: string | number = ':id') => ({
                    label,
                    to: `/${customer}/consultations/${id}`,
                }),
            },
        },
        news: {
            index: {
                label: customerLoc(t => t.routes['/:customer/news']),
                to: `/${customer}/news`,
            },
        },
        tasks: {
            index: {
                label: customerLoc(t => t.navigation.Tasks),
            },
        },
        monitoring: {
            index: {
                label: customerLoc(t => t.navigation.Monitoring),
            },
            reports: {
                index: {
                    label: customerLoc(t => t.routes[routes.customer().monitoring.reports.index]),
                    to: routes.customer(customer).monitoring.reports.index,
                },
                view: (label: string, id: string | number = ':id') => ({
                    label,
                    to: routes.customer(customer).monitoring.reports.detail.view(id),
                }),
            },
        },
        settings: {
            index: {
                label: customerLoc(t => t.navigation.Settings),
            },
            employees: {
                index: {
                    label: customerLoc(t => t.routes['/:customer/settings/employees']),
                    to: `/${customer}/settings/employees`,
                },
            },
            frameworks: {
                index: {
                    label: customerLoc(t => t.routes['/:customer/settings/frameworks']),
                    to: `/${customer}/settings/frameworks`,
                },
            },
            departments: {
                index: {
                    label: customerLoc(t => t.routes['/:customer/settings/departments']),
                    to: `/${customer}/settings/departments`,
                },
            },
            compliance: {
                index: {
                    label: customerLoc(t => t.routes['/:customer/settings/compliance']),
                    to: `/${customer}/settings/compliance`,
                },
            },
            news: {
                index: {
                    label: customerLoc(t => t.routes['/:customer/settings/news']),
                    to: `/${customer}/settings/news`,
                },
            },
        },
        lawAndRegulation: {
            index: {
                label: customerLoc(t => t.routes['/:customer/law-and-regulation']),
                to: `/${customer}/law-and-regulation`,
            },
            law: (label: string, id: string | number = ':id') => ({
                label,
                to: `/${customer}/law-and-regulation/${id}`,
            }),
        },
        inbox: {
            index: {
                label: customerLoc(t => t.routes['/:customer/inbox']),
                to: `/${customer}/inbox`,
            },
        },
        signaling: {
            index: {
                label: customerLoc(t => t.navigation.Signaling),
            },
            detail: (id: string | number = ':id') => ({
                label: customerLoc(t => t.navigation.SignalingOverview),
                to: `/${customer}/signaling`,
            }),
        },
    }),
    consultant: () => ({
        customerGroups: {
            index: {
                label: consultantLoc(t => t.routes['/consultant/customer-groups']),
                to: routes.consultant.customerGroups.index,
            },
            detail: (id: number, label: string) => ({
                label,
                to: routes.consultant.customerGroups.detail(id).view,
            }),
        },
        customers: {
            index: {
                label: consultantLoc(t => t.routes['/consultant/customers']),
                to: '/consultant/customers',
            },
        },
        customerfeeds: {
            index: {
                label: consultantLoc(t => t.routes['/consultant/customerfeeds']),
                to: '/consultant/customerfeeds',
            },
        },
        organization: {
            index: {
                label: consultantLoc(t => t.routes['/consultant/organization']),
            },
            employees: {
                index: {
                    label: consultantLoc(t => t.navigation.Employees),
                    to: '/consultant/organization/employees',
                },
            },
            profile: {
                index: (label: string) => ({
                    label,
                    to: '/consultant/organization/profile',
                }),
            },
        },
    }),
}
