export const animations = {
    popIn: 'rf-animation-popIn',
    fadeIn: 'rf-animation-fadeIn',
    rotate180: 'rf-animation-rotate-180deg',
    slideInFromLeft: 'rf-animation-slideInFromLeft',
    menuSlideIn: 'rf-animation-menuSlideIn',
}

export const animationTiming = {
    defaultTiming: 225,
    menuTiming: 300,
}
