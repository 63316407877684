import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { GQLMutation } from '~/graphql/Mutation'
import {
    RevertIgnoreAlertDocument,
    RevertIgnoreAlertMutation,
    RevertIgnoreAlertMutationVariables,
} from '~/generated/graphql'

interface Props {
    children: (
        mutation: MutationFn<RevertIgnoreAlertMutation, RevertIgnoreAlertMutationVariables>,
        options: MutationResult
    ) => JSX.Element
}

export type RevertIgnoreAlertMutationFN = MutationFn<RevertIgnoreAlertMutation, RevertIgnoreAlertMutationVariables>

export class RevertIgnoreAlert extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<RevertIgnoreAlertMutation, RevertIgnoreAlertMutationVariables>
                mutation={RevertIgnoreAlertDocument}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
