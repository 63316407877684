import './IconLabel.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '../Icon/Icon'
import { IconType } from '../Icon/IconType'
import { Truncate } from './Truncate'
import { TextLink } from './TextLink'
import { Paragraph } from '../Typography/Paragraph'

interface Props {
    className?: ClassValue
    iconType: IconType
    to?: string
    href?: string
    externalLink?: boolean
    onClick?: () => void
    isBold?: boolean
    count?: number
    alignRight?: boolean
    leftComponent?: JSX.Element
    strikethrough?: boolean
}

interface State {}

export class IconLabel extends React.Component<Props, State> {
    private bem = new BEM('IconLabel', () => ({
        'is-bold': this.props.isBold,
        'is-button': !!this.props.onClick,
        'is-rtl': this.props.alignRight,
        'is-link': !!this.props.to,
    }))

    public render() {
        const { to, onClick, className, externalLink, href } = this.props

        if (to ?? href) {
            return (
                <TextLink external={externalLink} className={this.bem.getClassName(className)} to={to ?? href}>
                    {this.renderContent()}
                </TextLink>
            )
        }

        if (onClick) {
            return (
                <button type="button" onClick={onClick} className={this.bem.getClassName(className)}>
                    {this.renderContent()}
                </button>
            )
        }

        return <span className={this.bem.getClassName(className)}>{this.renderContent()}</span>
    }

    private renderContent() {
        const { children, iconType, count, strikethrough } = this.props

        return (
            <Truncate className={this.bem.getElement('wrapper')}>
                {this.props.leftComponent}
                <Icon count={count} className={this.bem.getElement('icon')} type={iconType} />
                <Paragraph inline={true} strikethrough={strikethrough} className={this.bem.getElement('label')}>
                    {children}
                </Paragraph>
            </Truncate>
        )
    }
}
