import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { DeleteFeedDocument, DeleteFeedMutation, DeleteFeedMutationVariables } from '~/generated/graphql'

interface Props {
    children: (mutation: DeleteFeedMutationFN, options: MutationResult) => JSX.Element
}

export type DeleteFeedMutationFN = MutationFn<DeleteFeedMutation, DeleteFeedMutationVariables>

export class DeleteFeed extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<DeleteFeedMutation, DeleteFeedMutationVariables> mutation={DeleteFeedDocument}>
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
