import React from 'react'
import { DeleteRiskModalMutation, DeleteRiskMutationType } from './DeleteRiskModalMutation'
import { MutationResult } from 'react-apollo'
import { DeleteRiskModal } from './DeleteRiskModal'
import { localize, notification } from '~/bootstrap'
import { Risk, LinkedControl } from '../EditRiskModal/EditRiskModal'
import { ControlTypeType } from '~/generated/graphql'

interface Props {
    assessmentId: number
    risk: Risk
    onDeleteRisk?: () => void
}

export interface TransformedControl {
    id: number
    name: string
    type: ControlTypeType
    revisionDate?: string | null
}

export const DeleteRiskModalContainer: React.SFC<Props> = props => {
    const { risk, assessmentId, onDeleteRisk } = props

    const handleOnDelete = async (deleteRisk: DeleteRiskMutationType, deleteOptions: MutationResult) => {
        const response = await deleteRisk()

        if (response && response.data && response.data.deleteRisk) {
            notification.success(localize.translate(t => t.Generic.successfullyDeleted))

            if (onDeleteRisk) {
                onDeleteRisk()
            }
        }
    }

    const transformLinkedControls = (): TransformedControl[] => {
        const { risk } = props

        return risk.linkedControls.map((control: LinkedControl) => control.control)
    }

    return (
        <DeleteRiskModalMutation assessmentId={assessmentId} riskId={risk.id}>
            {(deleteRisk, deleteOptions) => (
                <DeleteRiskModal
                    onConfirm={closeModal => {
                        closeModal()
                        handleOnDelete(deleteRisk, deleteOptions)
                    }}
                    name={risk.name}
                    controls={transformLinkedControls() as TransformedControl[]}
                    loading={deleteOptions.loading}
                />
            )}
        </DeleteRiskModalMutation>
    )
}
