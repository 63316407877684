import './OnboardingOverlay.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    clip: Clip
}

interface State {}

export interface Clip {
    x: number
    y: number
    height: number
    width: number
}

export class OnboardingOverlay extends React.PureComponent<Props, State> {
    private bem = new BEM('OnboardingOverlay')

    public render() {
        const { className, clip } = this.props
        const padding = 8
        const rectWidth = ((clip.width + padding * 2) / window.innerWidth) * 100
        const rectHeight = ((clip.height + padding * 2) / window.innerHeight) * 100
        const rectX = ((clip.x - padding) / window.innerWidth) * 100
        const rectY = ((clip.y - padding) / window.innerHeight) * 100
        const rectRadius = (4 / window.innerWidth) * 100

        return (
            <svg viewBox="0 0 100 100" preserveAspectRatio="none" className={this.bem.getClassName(className)}>
                <defs>
                    <mask id="clip">
                        <rect width="100%" height="100%" fill="white" />
                        <rect
                            fill="black"
                            width={`${rectWidth}%`}
                            height={`${rectHeight}%`}
                            x={rectX}
                            y={rectY}
                            rx={rectRadius}
                        />
                    </mask>
                </defs>
                <rect fill="black" opacity="0.3" width="100%" height="100%" mask="url(#clip)" />
            </svg>
        )
    }
}
