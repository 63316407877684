import React from 'react'
import { InfiniteScrollOptions, InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import {
    ConsultantTaskTemplatesDocument,
    ConsultantTaskTemplatesQueryVariables,
    ConsultantTaskTemplateType,
} from '~/generated/graphql'

interface Props {
    customerId?: number
    children: (
        options: InfiniteScrollOptions<ConsultantTaskTemplateType, ConsultantTaskTemplatesQueryVariables>
    ) => React.ReactNode
}

export class ConsultantTaskTemplatesInfiniteScrollQuery extends React.PureComponent<Props> {
    public render() {
        const { children, customerId } = this.props

        return (
            <InfiniteScrollQuery<ConsultantTaskTemplateType, ConsultantTaskTemplatesQueryVariables>
                query={ConsultantTaskTemplatesDocument}
                variables={{ customerId }}
            >
                {options => children(options)}
            </InfiniteScrollQuery>
        )
    }
}
