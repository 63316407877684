import './RadarDot.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { RadarItemNode } from '~/views/Customer/Radar/RadarOverviewView'
import { RadarItemContent } from './RadarItemContent'
import { routes } from '~/views/routes'
import { withRouter, RouteComponentProps } from 'react-router'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    className?: ClassValue
    contentClassName?: ClassValue
    style?: React.CSSProperties
    radarItem: RadarItemNode
    userCanUnfollow: boolean
}

interface State {}

export const RadarDot = withRouter(
    class extends React.PureComponent<Props & RouteComponentProps<{}>, State> {
        public static contextType = CustomerContext
        public context: CustomerContextValue

        private bem = new BEM('RadarDot')

        public render() {
            const { className, style, radarItem, history } = this.props

            return (
                <Tooltip
                    className={this.bem.getElement('tooltip', () => ({ isUnfollowed: !radarItem.following }))}
                    content={this.renderTooltipContent()}
                >
                    <div
                        className={this.bem.getClassName(className)}
                        style={style}
                        onClick={() =>
                            history.push(routes.customer(this.context.customer.slug).radar.detail.view(radarItem.id))
                        }
                    />
                </Tooltip>
            )
        }

        private renderTooltipContent() {
            const { radarItem, userCanUnfollow } = this.props
            return (
                <div className={this.bem.getElement('tooltip-content')}>
                    <RadarItemContent
                        id={radarItem.id}
                        title={radarItem.name}
                        phase={radarItem.phase}
                        lastEditorialChangeAt={radarItem.lastEditorialChangeAt}
                        to={routes.customer(this.context.customer.slug).radar.detail.view(radarItem.id)}
                        expectedDate={radarItem.expectedDate}
                        customerFrameworks={radarItem.customerFrameworks}
                        userCanUnfollow={userCanUnfollow}
                    />
                </div>
            )
        }
    }
)
