import './CustomerFeedSettings.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM } from '~/services/BEMService'
import { parseCustomerFeedSettingToString } from './customerFeedTypeSettingHelpers'

interface Props {
    settings?: CustomerFeedSettings
}

export class CustomerFeedSettings extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerFeeds.CustomerFeedTypeSelect)
    private bem = new BEM('CustomerFeedSettings')

    public render() {
        const { settings } = this.props

        if (!settings) {
            return null
        }

        const keys = Object.keys(settings)

        return <Column className={this.bem.getClassName()}>{keys.map(this.renderSetting)}</Column>
    }

    private renderSetting = (key: string, index: number) => {
        const { settings } = this.props
        const parsedSetting = parseCustomerFeedSettingToString(key, settings![key])

        return (
            <Field
                key={`setting-${index}`}
                className={this.bem.getElement('setting')}
                label={this.loc(t => t.settings[key])}
            >
                <Paragraph>{parsedSetting}</Paragraph>
            </Field>
        )
    }
}
