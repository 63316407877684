import React from 'react'
import { MutationFn } from 'react-apollo'
import { FormState } from '~/components/Core/DataEntry/Form/Form'
import { ControlQuery, Control } from '~/components/Domain/Compliance/Controls/ControlDetail/ControlQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ControlsEditForm } from './ControlEditForm'
import { EditControlMutation, EditControlMutationResponse, EditControlMutationVariables } from './EditControlMutation'
import { DepartmentSwitchPopup } from '~/components/Domain/Department/DepartmentSwitchPopup'
import { routes } from '~/views/routes'
import { ControlVisibility } from '~/generated/graphql'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { getResponsibleEmployeeData } from '~/utils/controls'

interface Props {
    controlId: number
    onEditSuccess: () => void
}

interface State {}

export class ControlsEditFormContainer extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {
        title: null,
    }

    public render() {
        const { controlId } = this.props

        return (
            <ControlQuery controlId={controlId}>
                {control => {
                    const department = control.department

                    if (this.context.activeDepartmentId !== department.id) {
                        return (
                            <DepartmentSwitchPopup
                                departmentIdToToggleTo={department.id}
                                departmentNameToToggleTo={department.name}
                                to={routes.customer(this.context.customer.slug).compliance.controls.index}
                            />
                        )
                    }

                    return (
                        <EditControlMutation>
                            {(editMutate, editOptions) => (
                                <>
                                    <ErrorMessage path="editControl" showAsNotification={true} />
                                    <ControlsEditForm
                                        editOptions={editOptions}
                                        onSubmit={(state: FormState) => this.handleSubmit(editMutate, state, control)}
                                        control={control}
                                    />
                                </>
                            )}
                        </EditControlMutation>
                    )
                }}
            </ControlQuery>
        )
    }

    private handleSubmit = async (
        mutate: MutationFn<EditControlMutationResponse, EditControlMutationVariables>,
        formState: FormState,
        defaultControl: Control
    ) => {
        const { controlId, onEditSuccess: onEdit } = this.props

        let visibility: ControlVisibility = defaultControl.visibility
        let visibleToDepartmentIds: number[] | undefined = undefined

        if (formState.visibility) {
            visibility = formState.visibility.value
        }

        if (visibility === ControlVisibility.selectionOfDepartments) {
            visibleToDepartmentIds = formState.visibleToDepartments
                ? formState.visibleToDepartments.map((option: SelectOption) => option.value)
                : undefined
        }

        const { responsibleEmployeeIds, responsibleShadowEmployeeIds, responsibleEmployees } =
            getResponsibleEmployeeData(formState.responsibleEmployees)

        const response = await mutate({
            variables: {
                controlId,
                fields: {
                    name: formState.name,
                    type: formState.type.value,
                    description: formState.description,
                    startDate: formState.startDate,
                    revisionDate: formState.revisionDate,
                    responsibleEmployeeIds: responsibleEmployeeIds,
                    responsibleShadowEmployeeIds: responsibleShadowEmployeeIds,
                    responsibleEmployees: responsibleEmployees,
                    visibility,
                    visibleToDepartmentIds,
                },
            },
        })

        if (response && response.data && response.data.editControl) {
            onEdit()
        }
    }
}
