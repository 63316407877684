import React from 'react'
import { RadarItemNode, RadarItemsGroupedByPeriod } from '~/views/Customer/Radar/RadarOverviewView'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { Column } from '~/components/Core/Layout/Column'
import { Center } from '~/components/Core/Layout/Center'
import { MediaQuery, Screensize } from '~/components/Core/Layout/MediaQuery'
import { VisualRadar } from '~/components/Domain/Radar/VisualRadar'
import { Section } from '~/components/Core/Layout/Section'
import { isSameYear, getQuarter, getYear, addYears } from 'date-fns'
import { NoRadarItems } from '~/components/Domain/Radar/NoRadarItems'
import { Grid } from '~/components/Core/Layout/Grid'
import { RadarItemCard } from '~/components/Domain/Radar/RadarItemCard'
import { routes } from '~/views/routes'
import { localize, permissions } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    items: RadarItemNode[]
    loading: boolean
    loadingMore: boolean
    isRange?: boolean
    selectedYear?: number
    loadMore: Function
    hasMore: boolean
}

interface State {}

export class RadarDisplay extends React.Component<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.RadarView)

    public componentDidUpdate() {
        if (!this.props.loading && !this.props.loadingMore && this.props.hasMore) {
            this.props.loadMore()
        }
    }

    public render() {
        const { items, loading, isRange, selectedYear } = this.props

        if (loading) {
            return <Spinner delayed={true} />
        }

        if (items.length === 0) {
            return <NoResults />
        }

        const radarData = isRange ? this.groupNodesByYears(items) : this.groupNodesByQuarters(items)

        const visualRadar = (
            <Column>
                <Center>
                    <MediaQuery breakpoint={Screensize.tablet}>
                        <VisualRadar radarItemsGroupedByPeriod={radarData} labelPrefix={!isRange ? 'Q' : undefined} />
                    </MediaQuery>
                </Center>
            </Column>
        )

        if (!isRange) {
            return (
                <>
                    {visualRadar}
                    <Section title={`${selectedYear}`}>{this.renderRadarGroups(radarData)}</Section>
                </>
            )
        }

        return (
            <>
                <MediaQuery breakpoint={Screensize.tablet}>{visualRadar}</MediaQuery>
                {this.renderRadarGroups(radarData)}
            </>
        )
    }

    private groupNodesByQuarters(nodes: RadarItemNode[]): RadarItemsGroupedByPeriod {
        const { selectedYear } = this.props

        const periods = [1, 2, 3, 4]

        const periodGroups = periods.reduce<RadarItemsGroupedByPeriod>((groups, period) => {
            groups[period] = []
            return groups
        }, {})

        return nodes.reduce((structure, node) => {
            const period = periods.find(period => {
                return (
                    isSameYear(new Date(node.expectedDate), new Date(`${selectedYear}`)) &&
                    getQuarter(new Date(node.expectedDate)) === period
                )
            })

            if (!period) {
                return structure
            }

            structure[period].push(node)
            return structure
        }, periodGroups)
    }

    private groupNodesByYears(nodes: RadarItemNode[]): RadarItemsGroupedByPeriod {
        const periods = [
            getYear(new Date()),
            getYear(addYears(new Date(), 1)),
            getYear(addYears(new Date(), 2)),
            getYear(addYears(new Date(), 3)),
        ]

        const periodGroups = periods.reduce<RadarItemsGroupedByPeriod>((groups, period) => {
            groups[period] = []
            return groups
        }, {})

        return nodes.reduce((structure, node) => {
            const period = periods.find(period => {
                return isSameYear(new Date(node.expectedDate), new Date(`${period}`))
            })

            if (!period) {
                return structure
            }

            structure[period].push(node)
            return structure
        }, periodGroups)
    }

    private renderRadarCards = (nodes: RadarItemNode[]) => {
        const { isRange, loading, loadingMore } = this.props
        const canUnfollowRadarItem = permissions.canUnFollowRadarItem(this.context.activeDepartmentId)

        if (loading) {
            return <NoRadarItems>{this.loc(t => t.RadarOverviewView.loading)}</NoRadarItems>
        }

        if (!nodes.length) {
            if (loadingMore) {
                return <NoRadarItems>{this.loc(t => t.RadarOverviewView.loading)}</NoRadarItems>
            }

            return (
                <NoRadarItems>
                    {isRange
                        ? this.loc(t => t.RadarOverviewView.noRadarItemsThisYear)
                        : this.loc(t => t.RadarOverviewView.noRadarItemsThisQuarter)}
                </NoRadarItems>
            )
        }

        return (
            <Grid gridColumns={3}>
                {nodes.map(node => (
                    <RadarItemCard
                        id={node.id}
                        key={node.id}
                        lastEditorialChangeAt={node.lastEditorialChangeAt}
                        to={routes.customer(this.context.customer.slug).radar.detail.view(node.id)}
                        title={node.name}
                        expectedDate={node.expectedDate}
                        customerFrameworks={node.customerFrameworks}
                        unfollowed={!node.following}
                        userCanUnfollow={canUnfollowRadarItem}
                    />
                ))}
            </Grid>
        )
    }

    private renderRadarGroups(radarData: RadarItemsGroupedByPeriod) {
        const { isRange, loading, loadingMore } = this.props

        return Object.entries(radarData).map(([period, nodes]) => {
            const showSpinner = loading || loadingMore

            const title = (
                <Row smallSpacing={true}>
                    <span>{isRange ? period : `Q${period}`}</span>
                    {showSpinner && <Spinner delayed={true} />}
                </Row>
            )

            return (
                <Section key={period} title={title}>
                    {this.renderRadarCards(nodes)}
                </Section>
            )
        })
    }
}
