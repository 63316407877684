import './ErrorMessage.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { ErrorProvider, ErrorProviderValue } from '~/components/Providers/ErrorProvider'
import { ErrorType } from '~/services/ErrorService'
import { localize, notification } from '~/bootstrap'
import get from 'lodash-es/get'
import { GraphQLError } from 'graphql'

interface Props {
    className?: ClassValue
    message?: string
    path?: string
    displayInputErrors?: boolean
    showAsNotification?: boolean
}

export class ErrorMessage extends React.PureComponent<Props> {
    private bem = new BEM('ErrorMessage')

    public render() {
        const { message, showAsNotification, path, className } = this.props

        // If there is not path, but there's a message we want the display just the message.
        // This gives the option to diplay an error whenever you want.
        if (!path && message) {
            return <div className={this.bem.getClassName(className)}>{message}</div>
        }

        if (!path) {
            return null
        }

        return (
            <ErrorProvider.Consumer>
                {error => {
                    if (!error) {
                        return null
                    }

                    const message = this.getMessageFromError(error)

                    if (!message) {
                        return null
                    }

                    if (showAsNotification) {
                        notification.error(message)
                        return null
                    }

                    return <div className={this.bem.getClassName(className)}>{message}</div>
                }}
            </ErrorProvider.Consumer>
        )
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private getMessageFromError = (errors: ErrorProviderValue) => {
        const { path } = this.props

        if (!path || !errors || errors.length === 0) {
            return null
        }

        const errorForThisPath = errors.find(error => !!(error.path && error.path.includes(path)))
        const isValidationError =
            errorForThisPath && errorForThisPath.extensions && errorForThisPath.extensions.code === ErrorType.validation
        const isAuthorizationError =
            errorForThisPath &&
            errorForThisPath.extensions &&
            errorForThisPath.extensions.code === ErrorType.authorization

        if (!errorForThisPath || isValidationError || isAuthorizationError) {
            return null
        }

        return this.getErrorTranslation(errorForThisPath)
    }

    private getErrorTranslation(error: GraphQLError) {
        const { message } = this.props

        if (message) {
            return message
        }

        const code = get(error, 'extensions.code')
        const type = get(error, 'extensions.exception.type')
        const errorMetaData = get(error, 'extensions.exception.metaData')

        if (!code) {
            return error.message
        }

        // If the metaData contains an entity prop we want to translate it to avoid
        // displaying the technical terms in the client.
        if (errorMetaData && errorMetaData.entity) {
            const entityT = localize.translate(t => t.Entities[errorMetaData.entity])
            errorMetaData.entity = entityT ? entityT : errorMetaData.entity
        }

        const translatedMessage = localize.translate(t => get(t.Errors, `${code}.${type}`), errorMetaData)

        return translatedMessage ? translatedMessage : error.message
    }
}
