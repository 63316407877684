import './SelectLinkableControlsTable.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { ColumnOptions, SortOption, Table } from '~/components/Core/DataDisplay/Table/Table'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ControlTypeType } from '~/generated/graphql'
import { BEM, ClassValue } from '~/services/BEMService'
import { routes } from '~/views/routes'
import linkableControlsQuery from './linkableControlsQuery.graphql'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { Icon } from '~/components/Core/Icon/Icon'
import { getIconTypeForControlType } from '~/utils/controls'
import { Radio } from '~/components/Core/DataEntry/Form/Radio'

interface Props {
    className?: ClassValue
    search?: string | null
    linkableToControl: { id: number }
    filterByControlTypes: ControlTypeType[]
    onChangeSelectedControlIds: (newSelectedControlIds: number[]) => void
    linkingParents?: boolean
}

interface State {
    sort: SortOption
    selectedControlIds: number[]
}

export interface LinkableControl {
    id: number
    name: string
    type: ControlTypeType
    linkedControlsCount: number
}

interface RowData {
    id: number
    columns: {
        name: React.ReactNode
    }
}

export class SelectLinkableControlsTable extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public bem = new BEM('SelectLinkableControlsTable')

    public state: State = {
        sort: { field: 'name', direction: 'DESC' },
        selectedControlIds: [],
    }

    public render() {
        const { search } = this.props
        const { sort } = this.state

        return (
            <InfiniteScrollQuery<LinkableControl>
                query={linkableControlsQuery}
                variables={{
                    filters: {
                        name: search,
                        departmentId: this.context.activeDepartmentId,
                        types: this.props.filterByControlTypes,
                        linkableToControlId: this.props.linkableToControl.id,
                    },
                    sort: {
                        [sort.field]: sort.direction,
                    },
                    activeDepartmentId: this.context.activeDepartmentId,
                }}
            >
                {({ data, loading, loadingMore, canFetchMore, hasPaginated }) => {
                    const tableData = data
                        ? data.nodes.map(node => ({
                              id: node.id,
                              columns: {
                                  name: (
                                      <span className={this.bem.getElement('control-title')}>
                                          <Icon
                                              type={getIconTypeForControlType(node.type)}
                                              className={this.bem.getElement('control-icon')}
                                          />
                                          <TableLink
                                              external={true}
                                              to={routes
                                                  .customer(this.context.customer.slug)
                                                  .compliance.controls.view(node.id)}
                                          >
                                              {node.name}
                                          </TableLink>
                                      </span>
                                  ),
                              },
                          }))
                        : []

                    const headers: ColumnOptions[] = [
                        {
                            field: 'name',
                            headerLabel: localize.translate(t => t.Control.attributes.name),
                            sortable: true,
                        },
                    ]

                    return (
                        <>
                            <Table<RowData>
                                loading={loading}
                                loadingMore={loadingMore}
                                endReached={!canFetchMore && hasPaginated}
                                columns={headers}
                                data={tableData}
                                onSortDirectionChange={(field, direction) =>
                                    this.setState({ sort: { field, direction } })
                                }
                                rowAction={({ row: { id } }) =>
                                    this.renderSelectButton(data!.nodes.find(control => control.id === id)!)
                                }
                                className={this.bem.getClassName()}
                                onRowClick={this.onRowClick}
                            />
                        </>
                    )
                }}
            </InfiniteScrollQuery>
        )
    }

    private renderSelectButton(control: LinkableControl) {
        const { linkingParents } = this.props

        if (linkingParents) {
            return (
                <Radio
                    name={`linkableControl-${control.id}`}
                    className={this.bem.getElement('select-interaction')}
                    checked={this.isControlChecked(control)}
                    onChange={(isChecked: boolean) => this.onChangeCheckbox(control, isChecked)}
                    large={true}
                />
            )
        }

        return (
            <Checkbox
                name="linkableControls[]"
                className={this.bem.getElement('select-interaction')}
                large={true}
                checked={this.isControlChecked(control)}
                onChange={(isChecked: boolean) => this.onChangeCheckbox(control, isChecked)}
            />
        )
    }

    private onChangeCheckbox = (control: LinkableControl, isChecked: boolean) => {
        const { onChangeSelectedControlIds, linkingParents } = this.props

        if (linkingParents) {
            return this.setState({ selectedControlIds: [control.id] }, () => {
                if (onChangeSelectedControlIds) {
                    onChangeSelectedControlIds(this.state.selectedControlIds)
                }
            })
        }

        const { selectedControlIds } = this.state
        const alreadySelected = selectedControlIds.includes(control.id)

        const newSelectedControlIds = isChecked
            ? !alreadySelected
                ? [...selectedControlIds, control.id]
                : selectedControlIds
            : selectedControlIds.filter(id => id !== control.id)

        this.setState({ selectedControlIds: newSelectedControlIds }, () => {
            if (onChangeSelectedControlIds) {
                onChangeSelectedControlIds(this.state.selectedControlIds)
            }
        })
    }

    private isControlChecked(control: LinkableControl) {
        return this.state.selectedControlIds.includes(control.id)
    }

    private onRowClick = (rowData: RowData) => {
        const shouldCheck = !this.state.selectedControlIds.includes(rowData.id)
        this.onChangeCheckbox({ id: rowData.id } as LinkableControl, shouldCheck)
    }
}
