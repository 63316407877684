import { routes } from '~/views/routes'
import { GroupedItem } from '~/graphql/types/LinkedItem'
import { IconType } from '~/components/Core/Icon/IconType'
import { localize } from '~/bootstrap'
import { LinkedItemsTypeMap } from '~/graphql/types/Item'
import { NewsPresentationSetting } from '~/generated/graphql'
import { LinkListItem } from '~/components/Core/Layout/LinkList'

export const getNameForItem = (type: string, item: any) => {
    if (type === 'AbstractLawArticle' && item.law) {
        return `${item.law.name} - ${item.title}`
    }

    return item.name || item.title || '-'
}

// tslint:disable-next-line:cyclomatic-complexity
export const getRouteForType = (customerSlug: string, type: string, item: any) => {
    if (isAttachmentLeading(item)) {
        const attachmentPath = item.attachments && item.attachments.length > 0 ? item.attachments[0].path : null
        if (attachmentPath) {
            return attachmentPath
        }
    }

    if (type === 'RadarItem') {
        return routes.customer(customerSlug).radar.detail.view(item.id)
    }

    if (type === 'Consultation') {
        return routes.customer(customerSlug).consultations.detail.view(item.id)
    }

    if (type === 'News') {
        if (item.presentationSetting === NewsPresentationSetting.source) {
            return item.customSourceUrl || item.originalSource
        }

        return routes.customer(customerSlug).news.detail.view(item.id)
    }

    if (type === 'Topic') {
        return routes.customer(customerSlug).legalFramework.topic.view(item.id)
    }

    if (type === 'AbstractLawArticle' && item.isSingleArticle) {
        return routes.customer(customerSlug).lawAndRegulation.detail.singleArticle(item.id)
    }

    if (type === 'AbstractLawArticle') {
        return routes.customer(customerSlug).lawAndRegulation.detail.article(item.law.abstractLawId, item.id)
    }

    if (type === 'LawArticle') {
        return routes
            .customer(customerSlug)
            .lawAndRegulation.detail.article(item.abstractLawArticle.abstractLawId, item.id)
    }

    return '/'
}

export const getNameForType = (group: GroupedItem) => {
    if (group.type === 'RadarItem') {
        return localize.translate(t => t.Customer.LinkedItemNaming.RadarItem)
    }

    if (group.type === 'Consultation') {
        return localize.translate(t => t.Customer.LinkedItemNaming.Consultation)
    }

    if (group.type === 'News') {
        return localize.translate(t => t.Customer.LinkedItemNaming.News)
    }

    if (group.type === 'Topic') {
        return localize.translate(t => t.Customer.LinkedItemNaming.Topic)
    }

    if (group.entityType === 'AbstractLawArticle') {
        if (group.type === 'Wet') {
            return localize.translate(t => t.Customer.LinkedItemNamingAbstractLawArticleType.Wet)
        }
        if (group.type === 'Overig') {
            return localize.translate(t => t.Customer.LinkedItemNamingAbstractLawArticleType.Overig)
        }
        return group.type
    }

    return localize.translate(t => t.Customer.LinkedItemNaming.Other)
}

export const getIconForItem = (item: any) => {
    if (isAttachmentLeading(item)) {
        return IconType.attachment
    }

    if (item.presentationSetting === NewsPresentationSetting.source) {
        return IconType.newTab
    }

    return IconType.file
}

export const getExpirationForType = (type: string, item: any) => {
    if (type === LinkedItemsTypeMap.abstractLawArticle) {
        return item.expired
    }

    return
}

export const transformItemForLinkList = (
    group: any,
    item: any,
    customerSlug: string,
    checkbox?: React.ReactNode
): LinkListItem => {
    const type = group.entityType || group.type

    if (isAttachmentLeading(item) && item.attachments && item.attachments[0]) {
        const routeOrHrefOrPdf = item.attachments[0].mimetype === 'application/pdf' ? 'pdf' : 'href'
        return {
            abstractLawArticleId: item.abstractLawArticleId,
            label: getNameForItem(type, item),
            icon: getIconForItem(item),
            [routeOrHrefOrPdf]: getRouteForType(customerSlug, type, item),
            expired: getExpirationForType(type, item),
            checkbox,
        }
    }

    return {
        abstractLawArticleId: item.abstractLawArticleId,
        label: getNameForItem(type, item),
        icon: getIconForItem(item),
        route: getRouteForType(customerSlug, type, item),
        expired: getExpirationForType(type, item),
        external: item.presentationSetting === NewsPresentationSetting.source,
        checkbox,
    }
}

export function isAttachmentLeading(item: any) {
    return item.attachmentIsLeading || item.presentationSetting === NewsPresentationSetting.attachment
}
