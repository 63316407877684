import { Query, QueryResult } from 'react-apollo'

import React from 'react'
import {
    AssessmentsForTopicDocument,
    AssessmentsForTopicQuery,
    AssessmentsForTopicQueryVariables,
} from '~/generated/graphql'

interface Props {
    topicId: number
    departmentId: number
    children: (options: QueryResult<AssessmentsForTopicQuery, AssessmentsForTopicQueryVariables>) => React.ReactNode
}

export class AssessmentsForTopicsQuery extends React.PureComponent<Props> {
    public render() {
        const { topicId, departmentId, children } = this.props

        return (
            <Query<AssessmentsForTopicQuery, AssessmentsForTopicQueryVariables>
                query={AssessmentsForTopicDocument}
                variables={{ topicId, departmentId }}
            >
                {options => children(options)}
            </Query>
        )
    }
}
