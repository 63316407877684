import './DepartmentsTableWidget.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'
import { InlineTextIcon } from '~/components/Core/Icon/InlineTextIcon/InlineTextIcon'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM } from '~/services/BEMService'
import { RowItemWithNewWarning } from './DepartmentsTableRowContainer'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { isNumber } from 'lodash-es'

interface Props {
    rowItemsWithNewWarningInOrAboveViewport: RowItemWithNewWarning[]
    rowItemsWithNewWarning: RowItemWithNewWarning[]
}

export class DepartmentsTableWidget extends React.PureComponent<Props> {
    private bem = new BEM('DepartmentsTableWidget')

    public render() {
        const { rowItemsWithNewWarningInOrAboveViewport, rowItemsWithNewWarning } = this.props

        if (!rowItemsWithNewWarning.length) {
            return null
        }

        const divider = this.getItemCount(rowItemsWithNewWarning)
        const dividend = this.getItemCount(rowItemsWithNewWarningInOrAboveViewport)

        const hasSeenAll = dividend === divider
        if (hasSeenAll) {
            return null
        }

        const text = localize.translate(t => t.Customer.Settings.DepartmentsOverview.newTopicsWidget, {
            dividend,
            divider,
        })

        return (
            <Button onClick={this.handleOnClick} type={ButtonType.noStyling}>
                <Row smallSpacing={true} className={this.bem.getClassName()}>
                    <InlineTextIcon type={IconType.downArrow} className={this.bem.getElement('widget-icon')} />
                    <Paragraph className={this.bem.getElement('widget-text')}>{text}</Paragraph>
                </Row>
            </Button>
        )
    }

    private getItemCount(itemsWithNewWarning: RowItemWithNewWarning[]) {
        return this.filterExpandedRowItems(itemsWithNewWarning)
            .map(({ newItemCount }) => newItemCount)
            .reduce((a, b) => a + b, 0)
    }

    private handleOnClick = () => {
        const nextItemBelowViewport = this.getNextItemBelowViewport()
        if (!nextItemBelowViewport) {
            return
        }

        const nextItemBottomHeight = nextItemBelowViewport.elementRef.current?.getBoundingClientRect().bottom
        window.scrollTo({
            top: nextItemBottomHeight,
            behavior: 'smooth',
        })
    }

    private getNextItemBelowViewport() {
        const rowItemsBelowViewport = this.getItemsBelowViewport()
        if (!rowItemsBelowViewport.length) {
            return
        }

        const rowItemBottomPoints: number[] = []

        for (const item of rowItemsBelowViewport) {
            const bottom = item.elementRef.current?.getBoundingClientRect().bottom

            if (isNumber(bottom)) {
                rowItemBottomPoints.push(bottom)
            }
        }

        const nextItemIndex = this.getSmallestElementIndex(rowItemBottomPoints)
        if (nextItemIndex === undefined) {
            return
        }

        return rowItemsBelowViewport[nextItemIndex]
    }

    private getItemsBelowViewport() {
        const { rowItemsWithNewWarning, rowItemsWithNewWarningInOrAboveViewport } = this.props

        const applicableItems = this.filterExpandedRowItems(rowItemsWithNewWarning)
        const rowItemUniqIdsInOrAboveViewport = rowItemsWithNewWarningInOrAboveViewport.map(({ uniqId }) => uniqId)

        return applicableItems.filter(({ uniqId }) => !rowItemUniqIdsInOrAboveViewport.includes(uniqId))
    }

    private filterExpandedRowItems(itemsWithNewWarning: RowItemWithNewWarning[]) {
        return itemsWithNewWarning.filter(({ isExpanded }) => isExpanded !== true)
    }

    private getSmallestElementIndex(array: number[]) {
        if (!array.length) {
            return
        }

        return array.indexOf(Math.min(...array))
    }
}
