import './LanguageSwitchModal.scss'

import React from 'react'
import { BEM } from '~/services/BEMService'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { ModalTitle } from '~/components/Core/Text/ModalTitle'
import { Row } from '~/components/Core/Layout/Row'
import { Radio } from '~/components/Core/DataEntry/Form/Radio'
import { Column } from '~/components/Core/Layout/Column'
import { InlineTextIcon } from '~/components/Core/Icon/InlineTextIcon/InlineTextIcon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Icon } from '~/components/Core/Icon/Icon'
import { EditLanguage } from './EditLanguage'
import { MutationFn } from 'react-apollo'
import { EditLanguageMutation, EditLanguageMutationVariables, Language } from '~/generated/graphql'
import { notification, userClient } from '~/bootstrap'
import { UserContext, UserContextValue, UserProvider } from '~/components/Providers/UserProvider'

interface Props {
    closeModal: () => void
}

interface State {
    selectedOption: Language
}

export class LanguageSwitchModal extends React.PureComponent<Props, State> {
    public static contextType = UserContext
    public context: UserContextValue

    public userDefaultLanguage = this.getUserLanguage()
    public state: State = {
        selectedOption: this.userDefaultLanguage,
    }

    private bem = new BEM('LanguageSwitchModal')

    public render() {
        const { closeModal } = this.props

        return (
            <EditLanguage>
                {(mutate, { loading }) => (
                    <Modal
                        title={this.renderTitle()}
                        requestClose={closeModal}
                        className={this.bem.getClassName()}
                        confirmButtonLabel={this.getConfirmButtonLabel()}
                        disabled={this.isInOriginalState()}
                        onAction={() => this.handleSubmit(mutate)}
                        loading={loading}
                    >
                        {this.renderLanguageOptions()}
                    </Modal>
                )}
            </EditLanguage>
        )
    }

    private getUserLanguage() {
        return userClient.getCurrentUserLanguage()
    }

    private renderTitle() {
        // title is hardcoded because it should not be translated
        return (
            <Row smallSpacing={true}>
                {/* tslint:disable-next-line:jsx-use-translation-function */}
                <ModalTitle title="Taal instellen" />
                {/* tslint:disable-next-line:jsx-use-translation-function */}
                <ModalTitle subtle={true} title="Change language" />
            </Row>
        )
    }

    private getConfirmButtonLabel() {
        const willSwitchToDutch = this.userDefaultLanguage === Language.en

        return willSwitchToDutch ? 'Change to Dutch' : 'Change to English'
    }

    private isInOriginalState = () => {
        return this.state.selectedOption === this.userDefaultLanguage
    }

    private async handleSubmit(mutate: MutationFn<EditLanguageMutation, EditLanguageMutationVariables>) {
        const { selectedOption } = this.state
        const { closeModal } = this.props

        const employeeId = this.context.employee?.id

        if (employeeId === undefined) {
            notification.warning('Must be an employee to change environment language')
            return
        }

        const response = await mutate({
            variables: { id: employeeId, fields: { language: selectedOption } },
        })

        if (response && response.data?.editEmployee) {
            closeModal()
            await UserProvider.refetch()
            window.location.reload()
        }
    }

    private renderLanguageOptions() {
        const { selectedOption } = this.state
        const dutchSelected = selectedOption === Language.nl
        const englishSelected = selectedOption === Language.en

        return (
            <Column smallSpacing={true} className={this.bem.getElement('options-container')}>
                <div
                    onClick={() => this.handleOnSelect(Language.nl)}
                    className={this.bem.getElement('option-container')}
                >
                    <Row
                        fullWidth={true}
                        className={this.bem.getElement('option', () => ({ 'is-default': dutchSelected }))}
                    >
                        <Radio name={'dutchLanguageOption'} checked={dutchSelected} />
                        {this.renderDutchOptionLabel()}
                    </Row>
                </div>
                <div
                    onClick={() => this.handleOnSelect(Language.en)}
                    className={this.bem.getElement('option-container')}
                >
                    <Row
                        fullWidth={true}
                        className={this.bem.getElement('option', () => ({ 'is-default': englishSelected }))}
                    >
                        <Radio name={'englishLanguageOption'} checked={englishSelected} />
                        {this.renderEnglishOptionLabel()}
                    </Row>
                </div>
            </Column>
        )
    }

    private handleOnSelect(language: Language) {
        const { selectedOption } = this.state

        if (selectedOption === language) {
            return
        }

        this.setState({ selectedOption: language })
    }

    private renderDutchOptionLabel() {
        // label is hardcoded because it should not be translated
        return (
            <>
                <InlineTextIcon type={IconType.nl} className={this.bem.getElement('language-icon')} />
                <Paragraph className={this.bem.getElement('option-text')}>
                    {/* tslint:disable-next-line:jsx-use-translation-function */}
                    Met uitzondering van artikelen uit Engelse bronnen
                </Paragraph>
            </>
        )
    }

    private renderEnglishOptionLabel() {
        // label is hardcoded because it should not be translated
        return (
            <>
                <InlineTextIcon type={IconType.en} className={this.bem.getElement('language-icon')} />
                <Paragraph className={this.bem.getElement('option-text')}>
                    {/* tslint:disable-next-line:jsx-use-translation-function */}
                    Articles are auto-translated using the powerful
                </Paragraph>
                <Icon type={IconType.deeplLogo} className={this.bem.getElement('deepl-logo')} />
            </>
        )
    }
}
