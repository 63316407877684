import React from 'react'

import { GetTaskLogsQuery } from './GetTaskLogsQuery'
import { TaskEventLogRenderer } from './TaskEventLogRenderer'

interface Props {
    taskId: number
    isSubTask: boolean
    setRefetch: (refetch: () => void) => void
}

interface State {}

export class TaskLogsContainer extends React.PureComponent<Props, State> {
    public render() {
        const { taskId, isSubTask, setRefetch } = this.props

        return (
            <GetTaskLogsQuery taskId={taskId}>
                {({ data, loading, refetch }) => {
                    setRefetch(refetch)
                    const taskLogs = data && data.taskLogs

                    return <TaskEventLogRenderer loading={loading} taskEvents={taskLogs} isSubTask={isSubTask} />
                }}
            </GetTaskLogsQuery>
        )
    }
}
