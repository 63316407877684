import { isEqual, isNumber } from 'lodash-es'
import React from 'react'
import { localize } from '~/bootstrap'
import { AttentionIcon } from '~/components/Core/Icon/AttentionIcon/AttentionIcon'
import { RiskStatus } from '~/generated/graphql'
import { RiskEditSidebar } from '../RiskEditSidebar'
import { Risk, RiskValues } from './EditRiskModal'
import { SelectedControl } from '../AddControlToRiskSidebar'

interface Props {
    isNewRisk?: boolean
    defaultRisk: Risk
    assessmentId: number
    assessmentSectionId: number
    showDistinctBrutoRiskValues: boolean
    riskStatus: RiskStatus | null
    currentRiskValues: RiskValues
    onChangeSelectedControls: (selectedControls: SelectedControl[]) => void
    onChangeIsRiskAccepted: (isRiskAccepted: boolean) => void
}

interface State {
    isRiskAccepted: boolean
    selectedControls?: SelectedControl[]
    showHintToEvaluateNettoRisk: boolean
    userHasMadeChangesToRiskValuesOrControls: boolean
}

export class EditRiskSidebarContainer extends React.Component<Props, State> {
    public state: State = {
        isRiskAccepted: this.props.defaultRisk.isAccepted,
        selectedControls: [],
        showHintToEvaluateNettoRisk: false,
        userHasMadeChangesToRiskValuesOrControls: false,
    }

    private defaultLinkedControlIds = this.props.defaultRisk.linkedControls.map(({ control }) => control.id)

    public componentDidUpdate(prevProps: Props) {
        const { showHintToEvaluateNettoRisk } = this.state
        const userHasChangedRiskValues = !isEqual(prevProps.currentRiskValues, this.props.currentRiskValues)

        if (showHintToEvaluateNettoRisk && userHasChangedRiskValues) {
            this.setState({ showHintToEvaluateNettoRisk: false })
        }
    }

    public render() {
        const { assessmentId, assessmentSectionId, showDistinctBrutoRiskValues } = this.props
        const { showHintToEvaluateNettoRisk, isRiskAccepted } = this.state

        return (
            <>
                {this.renderTitleAttentionIcon()}
                <RiskEditSidebar
                    assessmentId={assessmentId}
                    assessmentSectionId={assessmentSectionId}
                    defaultControls={this.getDefaultSelectedControls()}
                    isRiskAccepted={isRiskAccepted}
                    onChangeSelectedControls={value => this.handleOnChangeSelectedControls(value)}
                    onChangeIsRiskAccepted={value => this.handleOnChangeIsRiskAccepted(value)}
                    showDistinctBrutoRiskValues={showDistinctBrutoRiskValues}
                    showHintToEvaluateNettoRisk={showHintToEvaluateNettoRisk}
                />
            </>
        )
    }

    private renderTitleAttentionIcon() {
        const isRiskNewAndPristine = !!this.props.isNewRisk && !this.state.userHasMadeChangesToRiskValuesOrControls
        const riskStatus = (!isRiskNewAndPristine && this.props.riskStatus) || null

        if (riskStatus === RiskStatus.noControlsWhileNettoSeverityIsSet) {
            return (
                <AttentionIcon
                    tooltipContent={localize.translate(
                        t => t.Customer.Compliance.Risks.status[RiskStatus.noControlsWhileNettoSeverityIsSet]
                    )}
                />
            )
        }

        return null
    }

    private getDefaultRiskBrutoNettoValues(): RiskValues {
        const { defaultRisk } = this.props

        return {
            brutoProbability: isNumber(defaultRisk.brutoProbability) ? defaultRisk.brutoProbability : null,
            brutoImpact: isNumber(defaultRisk.brutoImpact) ? defaultRisk.brutoImpact : null,
            nettoProbability: isNumber(defaultRisk.nettoProbability) ? defaultRisk.nettoProbability : null,
            nettoImpact: isNumber(defaultRisk.nettoImpact) ? defaultRisk.nettoImpact : null,
        }
    }

    private getDefaultSelectedControls(): SelectedControl[] {
        const { defaultRisk } = this.props

        if (!defaultRisk.linkedControls) {
            return []
        }

        return defaultRisk.linkedControls.map(linkedControl => ({
            controlId: linkedControl.control.id,
            description: linkedControl.description,
        }))
    }

    private handleOnChangeSelectedControls(selectedControls: SelectedControl[]) {
        const { onChangeSelectedControls, currentRiskValues } = this.props

        const hasDifferentControlsSelected = this.hasDifferentControlsSelected(selectedControls)
        const rawRiskValues = currentRiskValues ? currentRiskValues : this.getDefaultRiskBrutoNettoValues()
        const hasSetRiskValues = isNumber(rawRiskValues.nettoImpact) || isNumber(rawRiskValues.nettoProbability)

        const userHasMadeChangesToRiskValuesOrControls = hasDifferentControlsSelected || hasSetRiskValues
        const showHintToEvaluateNettoRisk = this.shouldShowHintToEvaluateNettoRisk(selectedControls)

        this.setState({
            selectedControls,
            userHasMadeChangesToRiskValuesOrControls,
            showHintToEvaluateNettoRisk,
        })

        onChangeSelectedControls(selectedControls)
    }

    private shouldShowHintToEvaluateNettoRisk(selectedControls: SelectedControl[], hasAcceptedRisk?: boolean) {
        const { isRiskAccepted } = this.state
        const { currentRiskValues } = this.props

        const hasDifferentControlsSelected = this.hasDifferentControlsSelected(selectedControls)
        const rawRiskValues = currentRiskValues ? currentRiskValues : this.getDefaultRiskBrutoNettoValues()
        const hasSetRiskValues = isNumber(rawRiskValues.nettoImpact) || isNumber(rawRiskValues.nettoProbability)

        // for when isRiskAccepted hasnt yet been set in handleOnChangeIsRiskAccepted
        if (hasAcceptedRisk === false) {
            return hasDifferentControlsSelected && hasSetRiskValues
        }

        return !isRiskAccepted && hasDifferentControlsSelected && hasSetRiskValues
    }

    private hasDifferentControlsSelected(selectedControls: SelectedControl[]) {
        const selectedControlIds = selectedControls.map(({ controlId }) => controlId)

        return !isEqual(selectedControlIds, this.defaultLinkedControlIds)
    }

    private handleOnChangeIsRiskAccepted = (isRiskAccepted: boolean) => {
        const { showDistinctBrutoRiskValues, onChangeIsRiskAccepted, defaultRisk } = this.props

        this.setState(
            prevState => {
                const { currentRiskValues } = this.props
                const prevValues = currentRiskValues || this.getDefaultRiskBrutoNettoValues()
                const showHintToEvaluateNettoRisk = this.shouldShowHintToEvaluateNettoRisk(
                    prevState.selectedControls || [], isRiskAccepted
                )

                if (showDistinctBrutoRiskValues) {
                    if (isRiskAccepted === true) {
                        return {
                            userHasMadeChangesToRiskValuesOrControls: true,
                            showHintToEvaluateNettoRisk: false,
                            isRiskAccepted,

                            /**
                             * Use case: isRiskAccepted is being marked 'true' while showDistinctBrutoRiskValues.
                             *
                             * Since 'isRiskAccepted' means accepting the 'bruto risk' in this case. This
                             * means that the 'netto' risk becomes the same value as the 'bruto' risk.
                             */
                            riskValues: {
                                ...prevValues,
                                nettoImpact: prevValues.brutoImpact,
                                nettoProbability: prevValues.brutoProbability,
                            },
                        }
                    }

                    if (isRiskAccepted === false) {
                        return {
                            userHasMadeChangesToRiskValuesOrControls: true,
                            showHintToEvaluateNettoRisk,
                            isRiskAccepted,

                            /**
                             * Use case: isRiskAccepted is being marked 'false' while showDistinctBrutoRiskValues.
                             *
                             * The 'bruto' risk is not being accepted anymore, so the 'netto' risk should
                             * not be the same value as 'bruto' anymore. We have no logical new value for
                             * the 'netto' risk, so we should reassign their initial values.
                             */
                            riskValues: {
                                ...prevValues,
                                nettoProbability: isNumber(defaultRisk.nettoProbability) ? defaultRisk.nettoProbability : null,
                                nettoImpact: isNumber(defaultRisk.nettoImpact) ? defaultRisk.nettoImpact : null,
                            },
                        }
                    }
                }

                return {
                    userHasMadeChangesToRiskValuesOrControls: true,
                    showHintToEvaluateNettoRisk,
                    isRiskAccepted,
                    riskValues: prevValues,
                }
            },
            () => {
                onChangeIsRiskAccepted(this.state.isRiskAccepted)
            }
        )
    }
}
