import React from 'react'
import ReactDOM from 'react-dom'
import { BEM, ClassValue } from '~/services/BEMService'
import './FilterDropdown.scss'
import { FilterButton } from './FilterButton'

interface Props {
    className?: ClassValue
    renderButton?: (onClick: () => void) => React.ReactNode
    hasActiveFilter?: boolean
    center?: boolean
    autoHeight?: boolean
}

interface State {
    dropdownIsActive: boolean
}

export type FilterDropdownCloseFn = () => void

export interface FilterDropdownContextValue {
    closeDropdown: FilterDropdownCloseFn
}

export const OptionsDropdownContext = React.createContext<FilterDropdownContextValue>({
    closeDropdown: () => {
        /** no-op */
    },
})

export class FilterDropdown extends React.PureComponent<Props, State> {
    public state: State = {
        dropdownIsActive: false,
    }

    private bem = new BEM('FilterDropdown', () => ({
        center: !!this.props.center,
        autoHeight: this.props.autoHeight,
    }))

    public componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true)
    }

    public componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true)
    }

    public render() {
        const { className, children } = this.props

        if (React.Children.count(children) === 0) {
            return null
        }

        return (
            <div className={this.bem.getClassName(className)}>
                {this.renderButton()}
                <OptionsDropdownContext.Provider value={{ closeDropdown: this.closeDropdown }}>
                    {this.renderDropDown()}
                </OptionsDropdownContext.Provider>
            </div>
        )
    }

    private renderDropDown() {
        const { children } = this.props
        const { dropdownIsActive } = this.state

        if (!dropdownIsActive) {
            return null
        }

        return <div className={this.bem.getElement('dropdown-wrapper')}>{children}</div>
    }

    private renderButton() {
        const renderButtonFn = this.props.renderButton ? this.props.renderButton : this.renderDefaultButton

        return renderButtonFn(this.toggleDropdown)
    }

    private renderDefaultButton = (onClick: () => void): React.ReactNode => {
        return (
            <FilterButton
                onClick={onClick}
                isFilterOpen={this.state.dropdownIsActive}
                isFilterActive={this.props.hasActiveFilter}
            />
        )
    }

    private handleClickOutside = (event: MouseEvent) => {
        const domNode = ReactDOM.findDOMNode(this)

        if (!domNode || !domNode.contains(event.target as any)) {
            this.closeDropdown()
        }
    }

    private toggleDropdown = () => this.setState({ dropdownIsActive: !this.state.dropdownIsActive })

    private closeDropdown = () => this.setState({ dropdownIsActive: false })
}
