import './CreateCustomerGroupView.scss'

import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Column } from '~/components/Core/Layout/Column'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { BEM } from '~/services/BEMService'
import { breadcrumbs } from '~/views/breadcrumbs'
import { routes } from '~/views/routes'
import { CustomerGroupFormFields } from '~/components/Domain/Consultants/CustomerGroup/CustomerGroupFormFields'
import { RouteComponentProps } from 'react-router-dom'
import { GQLMutation } from '~/graphql/Mutation'
import {
    CreateCustomerGroupDocument,
    CreateCustomerGroupMutation,
    CreateCustomerGroupMutationVariables,
} from '~/generated/graphql'
import { MutationFn } from 'react-apollo'
import slugify from 'slugify'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'

interface Props extends RouteComponentProps<{}> {}

type CreateCustomerGroupMutationFN = MutationFn<CreateCustomerGroupMutation, CreateCustomerGroupMutationVariables>

export class CreateCustomerGroupView extends React.PureComponent<Props> {
    private bem = new BEM('CreateCustomerGroupView')
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerGroupsView.CreateCustomerGroupView)

    public render() {
        return (
            <Page className={this.bem.getClassName()}>
                <PageHeader
                    title={this.loc(t => t.title)}
                    breadCrumbs={[breadcrumbs.consultant().customerGroups.index]}
                />
                <GQLMutation<CreateCustomerGroupMutation, CreateCustomerGroupMutationVariables>
                    mutation={CreateCustomerGroupDocument}
                >
                    {(mutate, { loading }) => this.renderForm(mutate, loading)}
                </GQLMutation>
            </Page>
        )
    }

    private renderForm(mutate: CreateCustomerGroupMutationFN, loading: boolean) {
        return (
            <Form onSubmit={this.handleSubmit(mutate)}>
                <Column bigSpacing={true} className={this.bem.getElement('form-container')}>
                    <ErrorMessage path="createCustomerGroup" />
                    <CustomerGroupFormFields />
                    {this.renderAction(loading)}
                </Column>
            </Form>
        )
    }

    private handleSubmit = (mutate: CreateCustomerGroupMutationFN) => async (formState: FormState) => {
        const { name, sso } = formState
        let { ssoLoginURL, createUserOnSSOLogin, ssoLogoutURL, ssoCertificate } = formState

        if (!sso) {
            createUserOnSSOLogin = undefined
            ssoLoginURL = undefined
            ssoLogoutURL = undefined
            ssoCertificate = undefined
        }

        const response = await mutate({
            variables: {
                fields: {
                    name: name ? name.trim() : undefined,
                    slug: name ? slugify(name.trim(), { lower: true }) : undefined,
                    ssoLoginEnabled: sso || false,
                    createUserOnSSOLogin,
                    ssoLoginURL,
                    ssoLogoutURL,
                    ssoCertificate,
                },
            },
        })

        if (response && response.data?.createCustomerGroup) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))

            const { id } = response.data.createCustomerGroup
            this.props.history.push(routes.consultant.customerGroups.detail(id).view)
        }
    }

    private renderAction(loading: boolean) {
        return (
            <Row alignRight={true}>
                <Button disabled={loading} type={ButtonType.secondary} to={routes.consultant.customerGroups.index}>
                    {localize.translate(t => t.Generic.cancel)}
                </Button>
                <Button loading={loading} submit={true}>
                    {localize.translate(t => t.Generic.save)}
                </Button>
            </Row>
        )
    }
}
