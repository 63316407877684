import React from 'react'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { PreviewModal } from '~/components/Core/Feedback/Modal/PreviewModal'
import { Column } from '~/components/Core/Layout/Column'
import { ArticleTitle } from '~/components/Core/Text/ArticleTitle'
import { CustomerFrameworkIconList } from '~/components/Domain/CustomerFramework/CustomerFrameworkIconList'
import { PageDetailContent } from '~/components/Core/Layout/PageDetail/PageDetailContent'
import { Markdown } from '~/components/Core/Text/Markdown'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { PageQuery } from '../PageQuery/PageQuery'
import { MinimalTopicDocument, MinimalTopicQuery, MinimalTopicQueryVariables } from '~/generated/graphql'

interface Props {
    topicId: number
    children?: (openModal: () => void) => JSX.Element
    // for use with MultiModal pass requestClose instead of children
    requestClose?: () => void
}

export class TopicPreviewModal extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, requestClose } = this.props

        if (requestClose) {
            return this.renderPreviewModal(requestClose)
        }

        return <ModalManager render={children} renderModal={closeModal => this.renderPreviewModal(closeModal)} />
    }

    private renderPreviewModal(closeModal: () => void) {
        const { topicId } = this.props

        return (
            <PreviewModal requestClose={closeModal}>
                <PageQuery<NonNullable<MinimalTopicQuery['topic']>, MinimalTopicQueryVariables>
                    query={MinimalTopicDocument}
                    variables={{
                        id: topicId,
                        customerSlug: this.context.customer.slug,
                        departmentId: this.context.activeDepartmentId,
                    }}
                >
                    {topic => (
                        <Column bigSpacing={true}>
                            <ArticleTitle
                                title={topic.name}
                                suffix={
                                    <CustomerFrameworkIconList
                                        small={false}
                                        customerFrameworks={topic.customerFrameworks}
                                    />
                                }
                            />
                            <PageDetailContent>
                                <Markdown source={topic.abstract} />
                            </PageDetailContent>
                        </Column>
                    )}
                </PageQuery>
            </PreviewModal>
        )
    }
}
