import React from 'react'
import { MutationResult } from 'react-apollo'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { ActionBar } from '~/components/Core/Layout/ActionBar'
import { PageDetail } from '~/components/Core/Layout/PageDetail/PageDetail'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { Section } from '~/components/Core/Layout/Section'
import { ControlTypeSelect } from '~/components/Domain/Compliance/Controls/ControlTypeSelect'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'
import { Control } from '../ControlDetail/ControlQuery'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { ControlVisibleToDepartmentsSelect } from '../../../Compliance/Controls/ControlVisibleToDepartmentsSelect'
import { ControlVisibilitySelect } from '../../../Compliance/Controls/ControlVisibilitySelect'
import { ControlVisibility, ControlTypeType } from '~/generated/graphql'
import { getIconTypeForControlType, getPossibleTypesWhileControlIsLinked } from '~/utils/controls'
import subDays from 'date-fns/sub_days'
import addDays from 'date-fns/add_days'
import { forEach } from 'lodash-es'
import { EmployeeSelect, EmployeeSelectOption } from '../../../Customer/EmployeeSelect'
import { breadcrumbs } from '~/views/breadcrumbs'
import { Page } from '~/components/Core/Layout/Page'

interface Props {
    control: Control
    onSubmit: (state: FormState) => void
    editOptions: MutationResult
}

interface State {
    startDate: Date | null
    revisionDate: Date | null
    selectedControlVisibility: ControlVisibility
}

export class ControlsEditForm extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        startDate: this.props.control.startDate ? new Date(this.props.control.startDate) : null,
        revisionDate:
            this.props.control && this.props.control.revisionDate ? new Date(this.props.control.revisionDate) : null,
        selectedControlVisibility: this.props.control.visibility,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.EditView)
    private genericLoc = localize.namespaceTranslate(t => t.Generic)

    public render() {
        const { control, onSubmit, editOptions } = this.props
        const { startDate, revisionDate, selectedControlVisibility } = this.state

        return (
            <Page>
                <PageHeader
                    title={control.name}
                    breadCrumbs={[
                        breadcrumbs.customer(this.context.customer.slug).compliance.index,
                        breadcrumbs.customer(this.context.customer.slug).compliance.controls.index,
                    ]}
                    icon={getIconTypeForControlType(control.type)}
                />
                <PageDetail spaceBetween>
                    <Form
                        onSubmit={onSubmit}
                        defaultState={{
                            name: control.name,
                            type: control.type,
                            description: control.description,
                        }}
                    >
                        <Section title={this.loc(t => t.general)}>
                            <FieldSet>
                                <Field label={this.loc(t => t.controlTitle)} forInput={'name'}>
                                    <Form.Input name={'name'} defaultValue={control.name} />
                                </Field>
                                <Field
                                    label={this.loc(t => t.controlType)}
                                    forInput={'type'}
                                    hint={
                                        control.hasControlLinkedControls
                                            ? this.loc(t => t.limitedTypeChangeHint)
                                            : undefined
                                    }
                                >
                                    <ControlTypeSelect
                                        name={'type'}
                                        defaultSelected={[
                                            {
                                                label: localize.translate(t => t.Control.type[control.type]),
                                                value: control.type,
                                            },
                                        ]}
                                        allowedControlTypes={this.getAllowedControlTypeOptions()}
                                    />
                                </Field>
                                <Field label={this.loc(t => t.description)} forInput={'description'}>
                                    <Form.TextEditor name={'description'} defaultValue={control.description} />
                                </Field>
                                <Field label={this.loc(t => t.startDate)} forInput={'startDate'}>
                                    <Form.DatePicker
                                        name={'startDate'}
                                        onChange={(startDate: Date | null) => this.setState({ startDate })}
                                        maxDate={revisionDate ? subDays(revisionDate, 1) : undefined}
                                        defaultValue={control.startDate && new Date(control.startDate)}
                                    />
                                </Field>
                                <Field label={this.loc(t => t.revisionDate)} forInput={'revisionDate'}>
                                    <Form.DatePicker
                                        name={'revisionDate'}
                                        onChange={(revisionDate: Date | null) => this.setState({ revisionDate })}
                                        minDate={startDate ? addDays(startDate, 1) : undefined}
                                        clearable={true}
                                        repeatable={true}
                                        defaultValue={
                                            control && control.revisionDate ? new Date(control.revisionDate) : undefined
                                        }
                                    />
                                </Field>
                                <Field label={this.loc(t => t.responsibleEmployees)} forInput={'responsibleEmployees'}>
                                    <EmployeeSelect
                                        clearable={true}
                                        creatable={true}
                                        name={'responsibleEmployees'}
                                        defaultValue={control && this.getEmployeeDefaultValue(control)}
                                    />
                                </Field>
                                <Field label={this.loc(t => t.visibility)} forInput={'visibility'}>
                                    <ControlVisibilitySelect
                                        name={'visibility'}
                                        defaultVisibility={control.visibility}
                                        onChange={(option: SelectOption) => {
                                            this.setState({
                                                selectedControlVisibility: option.value as ControlVisibility,
                                            })
                                        }}
                                    />
                                </Field>
                                {selectedControlVisibility === ControlVisibility.selectionOfDepartments && (
                                    <Field
                                        label={this.loc(t => t.visibleToDepartments)}
                                        forInput={'visibleToDepartments'}
                                        isNested={true}
                                    >
                                        <ControlVisibleToDepartmentsSelect
                                            name={'visibleToDepartments'}
                                            defaultDepartments={control.visibleToDepartments || []}
                                        />
                                    </Field>
                                )}
                            </FieldSet>
                        </Section>
                        <ActionBar>
                            <Row alignRight={true}>
                                <Button
                                    to={routes
                                        .customer(this.context.customer.slug)
                                        .compliance.controls.view(control.id)}
                                    type={ButtonType.tertiary}
                                >
                                    {this.genericLoc(t => t.cancel)}
                                </Button>
                                <Button loading={editOptions.loading} submit={true}>
                                    {this.genericLoc(t => t.save)}
                                </Button>
                            </Row>
                        </ActionBar>
                    </Form>
                </PageDetail>
            </Page>
        )
    }

    private getAllowedControlTypeOptions(): ControlTypeType[] {
        const { control } = this.props

        return control.hasControlLinkedControls
            ? getPossibleTypesWhileControlIsLinked(control.type)
            : Object.values(ControlTypeType)
    }

    private getEmployeeDefaultValue(existingControl: Control) {
        const defaultValue: EmployeeSelectOption[] = []

        if (existingControl.responsibleEmployees) {
            forEach(existingControl.responsibleEmployees, employee => {
                defaultValue.push({
                    label: employee.user.profile.fullName,
                    value: { employee },
                })
            })
        }

        if (existingControl.responsibleShadowEmployees) {
            forEach(existingControl.responsibleShadowEmployees, shadowEmployee => {
                defaultValue.push({
                    label: shadowEmployee.name || '',
                    value: { shadowEmployee },
                })
            })
        }

        return defaultValue
    }
}
