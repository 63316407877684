import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { localize, notification } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { SelectionBox } from '~/components/Core/Feedback/SelectionBox/SelectionBox'
import { BEM } from '~/services/BEMService'
import { ArchiveControls, ArchiveControlsMutationFN } from '../Compliance/Controls/Mutations/ArchiveControls'
import { Attention } from '~/components/Core/Feedback/Attention/Attention'

interface Props {
    controlIds: number[]
    onClearSelection: () => void
    onSubmit: () => void
}

interface State {
    archivedReason: string | null
}

export class BulkControlArchiveContainer extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        archivedReason: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.ArchiveControlModal)
    private bem = new BEM('BulkControlArchiveContainer')

    public render() {
        const { controlIds, onClearSelection } = this.props

        return (
            <div className={this.bem.getClassName()}>
                <ArchiveControls>
                    {(mutate, { loading }) => {
                        return (
                            <ConfirmModal
                                title={this.loc(t => t.modalTitle)}
                                confirmButtonLabel={localize.translate(t => t.Generic.archive)}
                                loading={loading}
                                renderContent={() => (
                                    <Column>
                                        <Paragraph>
                                            {this.loc(t => t.archiveMessagePart1)}
                                            <strong>{controlIds.length}</strong>
                                            {this.loc(t => t.archiveMultipleMessagePart2)}
                                        </Paragraph>
                                        <Attention
                                            noIcon={true}
                                            attentionText={<Paragraph>{this.loc(t => t.archiveWarning)}</Paragraph>}
                                        />
                                        <Field label={this.loc(t => t.reasonInputLabel)} compact={true}>
                                            <Input
                                                type="textarea"
                                                placeholder={this.loc(t => t.reasonInputPlaceholder)}
                                                name="reason"
                                                onChange={archivedReason => this.setState({ archivedReason })}
                                            />
                                        </Field>
                                    </Column>
                                )}
                                onConfirm={close => this.handleSubmit(close, mutate)}
                            >
                                {openModal => (
                                    <SelectionBox
                                        selectedAmount={controlIds.length}
                                        onClose={onClearSelection}
                                        buttons={[
                                            {
                                                icon: IconType.archive,
                                                label: localize.translate(t => t.Generic.archive),
                                                onClick: openModal,
                                            },
                                        ]}
                                        isOpen={controlIds.length > 0}
                                    />
                                )}
                            </ConfirmModal>
                        )
                    }}
                </ArchiveControls>
            </div>
        )
    }

    private async handleSubmit(close: () => void, mutate: ArchiveControlsMutationFN) {
        const { archivedReason } = this.state
        const { controlIds: toBeArchivedIds, onClearSelection, onSubmit } = this.props

        const response = await mutate({
            variables: {
                ids: toBeArchivedIds,
                departmentId: this.context.activeDepartmentId,
                reason: archivedReason,
            },
        })

        if (response && response.data && response.data.archiveControls) {
            notification.success(localize.translate(t => t.Generic.successfullyArchived))
            close()
            onClearSelection()
            onSubmit()
        }
    }
}
