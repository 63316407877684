import './CustomerFrameworkToggle.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { FrameworkIcon } from '~/components/Domain/Customer/Framework/FrameworkIcon/FrameworkIcon'
import { Toggle } from '~/components/Core/DataEntry/Form/Toggle'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'

interface Props {
    className?: ClassValue
    framework: CustomerFramework
    onChange?: (checked: boolean, name: string) => void
    defaultChecked?: boolean
    disabled?: boolean
    forMobile?: boolean
}

interface State {}

export class CustomerFrameworkToggle extends React.PureComponent<Props, State> {
    private bem = new BEM('CustomerFrameworkToggle')

    public render() {
        const { className, framework, defaultChecked, onChange, disabled, forMobile } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('framework-metadata')}>
                    <FrameworkIcon
                        className={this.bem.getElement('framework-icon')}
                        frameworkType={framework.icon}
                        color={framework.color}
                    />
                    <Tooltip content={framework.name}>
                        <div className={this.bem.getElement('framework-name')}>{framework.name}</div>
                    </Tooltip>
                </div>
                <div className={this.bem.getElement('framework-toggle')}>
                    <Toggle
                        name={framework.name}
                        defaultChecked={defaultChecked}
                        onChange={onChange}
                        disabled={disabled}
                        big={forMobile}
                    />
                </div>
            </div>
        )
    }
}
