import './ControlTaskTooltip.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { TaskType } from '~/generated/graphql'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import { localize } from '~/bootstrap'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { ViewTaskModalContainer } from '../../../Task/ViewTaskModalContainer'

interface Props {
    className?: ClassValue
    reviewTask: TaskType
}

interface State {}

export class ControlTaskTooltip extends React.PureComponent<Props, State> {
    private bem = new BEM('ControlTaskTooltip')

    public render() {
        const { className, reviewTask } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Tooltip
                        distance={1}
                        direction={ToolTipDirection.upEnd}
                        className={this.bem.getClassName(className)}
                        content={this.renderContent()}
                    >
                        <Button
                            className={this.bem.getElement('icon')}
                            type={ButtonType.noStyling}
                            icon={IconType.controlTask}
                            onClick={openModal}
                        />
                    </Tooltip>
                )}
                renderModal={closeModal => <ViewTaskModalContainer taskId={reviewTask.id} requestClose={closeModal} />}
            />
        )
    }

    private renderContent() {
        const { reviewTask } = this.props

        const fullName = reviewTask.createdBy?.user.profile.fullName

        return (
            <div className={this.bem.getElement('content')}>
                {fullName && (
                    <div className={this.bem.getElement('created-by')}>
                        {localize.translate(t => t.Customer.TaskControl.ControlTaskTooltip.createdBy)}
                        <UserProfileIcon
                            small={true}
                            showTooltip={true}
                            fullName={fullName}
                            className={this.bem.getElement('user')}
                        />
                    </div>
                )}
                <DateFormat
                    readable={true}
                    includeTime={true}
                    noWeekday={true}
                    className={this.bem.getElement('date')}
                    date={new Date(reviewTask.createdAt)}
                />
            </div>
        )
    }
}
