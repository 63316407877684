import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { AnnotatedCheckboxList } from '~/components/Core/DataEntry/Form/AnnotatedCheckboxList'
import { localize, permissions } from '~/bootstrap'
import { AnnotatedCheckboxValue } from '~/components/Core/DataEntry/Form/AnnotatedCheckbox'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { getIconTypeForControlType } from '~/utils/controls'
import { Icon } from '~/components/Core/Icon/Icon'
import { ControlTypeType } from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    assessmentId: number
    assessmentSectionId: number
    defaultControls?: SelectedControl[]
    onChange?: (values: SelectedControl[]) => void
}

interface State {
    selectedControls: SelectedControl[]
}

export interface SelectedControl {
    controlId: number
    description?: string | null
}

const GET_ASSESSMENT_CONTROLS = gql`
    query assessmentSectionControlsForRiskSidebar($assessmentSectionId: Int!) {
        assessmentSection(assessmentSectionId: $assessmentSectionId) {
            id
            controls {
                id
                name
                type
            }
        }
    }
`

interface AssessmentControlsResponse {
    assessmentSection: {
        id: number
        controls: Control[]
    }
}

interface Control {
    id: number
    name: string
    type: ControlTypeType
}

export class AddControlToRiskSidebar extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        selectedControls: this.props.defaultControls || [],
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.RiskEditSidebar)

    public render() {
        const { assessmentSectionId } = this.props

        return (
            <Query<AssessmentControlsResponse>
                query={GET_ASSESSMENT_CONTROLS}
                variables={{
                    assessmentSectionId,
                }}
            >
                {({ data, loading }) => {
                    const controls = (data && data.assessmentSection && data.assessmentSection.controls) || []

                    return (
                        <>
                            {loading && this.renderLoading()}
                            {!loading && this.renderControls(controls)}
                        </>
                    )
                }}
            </Query>
        )
    }

    private renderControls(controls: Control[]): React.ReactNode {
        const { defaultControls } = this.props

        if (controls.length === 0) {
            return this.loc(t => t.sidebarEmptyState)
        }

        return (
            <AnnotatedCheckboxList
                items={controls.map(control => {
                    const defaultControl =
                        defaultControls &&
                        defaultControls.find(defaultControl => defaultControl.controlId === control.id)

                    return {
                        id: control.id,
                        label: control.name,
                        labelIcon: <Icon type={getIconTypeForControlType(control.type)} />,
                        defaultChecked: !!defaultControl,
                        defaultAnnotationValue: defaultControl && defaultControl.description,
                        isDisabled: !permissions.canEditAssessment(this.context.activeDepartmentId),
                        singleLine: true,
                    }
                })}
                onChange={this.onCheckboxChange(controls)}
            />
        )
    }

    private renderLoading = () => {
        return <Spinner />
    }

    private onCheckboxChange = (controls: Control[]) => (value: AnnotatedCheckboxValue, _: number, id: number) => {
        const { selectedControls } = this.state
        const { onChange } = this.props

        const newSeletedControl = controls.find(control => control.id === id)

        if (!newSeletedControl) {
            return
        }

        if (value.isChecked) {
            this.setState(
                {
                    selectedControls: [
                        // Remove old control with this is before adding the new one
                        ...selectedControls.filter(
                            selectedControl => selectedControl.controlId !== newSeletedControl.id
                        ),
                        {
                            controlId: newSeletedControl.id,
                            description: value.annotation,
                        },
                    ],
                },
                () => onChange && onChange(this.state.selectedControls)
            )
        } else {
            this.setState(
                {
                    selectedControls: selectedControls.filter(
                        selectedControl => selectedControl.controlId !== newSeletedControl.id
                    ),
                },
                () => onChange && onChange(this.state.selectedControls)
            )
        }
    }
}
