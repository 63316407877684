import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import './ConsultantAlertBanner.scss'
import { MediaQuery, Screensize } from '~/components/Core/Layout/MediaQuery'
import { userClient, localize } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    className?: ClassValue
    isCustomerConsultant?: boolean
}

interface State {}

export class ConsultantAlertBanner extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('ConsultantAlertBanner', () => ({
        isConsultant: !this.props.isCustomerConsultant,
    }))
    private loc = localize.namespaceTranslate(t => t.Chrome.ConsultantAlertBanner)
    private lastConsultantRoute = window.sessionStorage.getItem('lastConsultantRoute')

    public render() {
        const { className, isCustomerConsultant } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <p className={this.bem.getElement('message')}>
                    <MediaQuery breakpoint={Screensize.tablet}>{this.getUserFirstName()}</MediaQuery>
                    {this.loc(t => t.message)}
                    <b>{this.getCustomerName()}</b>
                    {this.loc(t => t.environment)}
                    {isCustomerConsultant ? this.loc(t => t.asCustomerConsultant) : ''}
                </p>
                {this.renderConsultantButton()}
            </div>
        )
    }

    private getUserFirstName() {
        const user = userClient.getCurrentUser()

        if (!user) {
            return localize.translate(t => t.Generic.hello)
        }

        return this.loc(t => t.name, { name: user.profile.firstName })
    }

    private renderConsultantButton() {
        const { isCustomerConsultant } = this.props

        if (isCustomerConsultant) {
            return
        }

        return (
            <Button
                to={this.lastConsultantRoute ? this.lastConsultantRoute : '/'}
                type={ButtonType.secondary}
                className={this.bem.getElement('button')}
            >
                <MediaQuery breakpoint={Screensize.tablet}>{this.loc(t => t.backTo)}</MediaQuery>
                <MediaQuery breakpoint={Screensize.mobile}>{this.loc(t => t.mobileBackTo)}</MediaQuery>
            </Button>
        )
    }

    private getCustomerName() {
        const { customer } = this.context
        return this.loc(t => t.customer, { customer: customer ? customer.name : '?' })
    }
}
