import React from 'react'
import { localize, notification } from '~/bootstrap'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Modal, ModalSize } from '~/components/Core/Feedback/Modal/Modal'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { DeleteTopicNote, DeleteTopicNoteMutationFN } from '../mutations/DeleteTopicNote'

interface Props {
    topicId: number
    assessmentId: number
    requestClose: () => void
    onRemove?: () => void
}

export class RemoveTopicNoteModal extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessTable)

    public render() {
        const { requestClose, topicId } = this.props

        return (
            <DeleteTopicNote topicId={topicId}>
                {(mutate, { loading }) => (
                    <Modal
                        requestClose={requestClose}
                        title={this.loc(t => t.removeAssessment)}
                        confirmButtonLabel={this.loc(t => t.removeAssessment)}
                        onAction={this.handleRemove(mutate)}
                        isDangerousAction={true}
                        modalSize={ModalSize.small}
                        loading={loading}
                        disabled={loading}
                    >
                        <ErrorMessage path="deleteTopicNote" />
                        <Paragraph>{this.loc(t => t.removeAssessmentMessage)}</Paragraph>
                    </Modal>
                )}
            </DeleteTopicNote>
        )
    }

    private handleRemove = (mutate: DeleteTopicNoteMutationFN) => async () => {
        const { topicId, assessmentId, requestClose, onRemove } = this.props

        const response = await mutate({
            variables: {
                topicId,
                topicNoteId: assessmentId,
                departmentId: this.context.activeDepartmentId,
            },
        })

        if (response && response.data?.deleteTopicNote) {
            notification.success(localize.translate(t => t.Generic.successfullyDeleted))

            if (onRemove) {
                onRemove()
            }

            requestClose()
        }
    }
}
