import './FrameworkAbbreviationList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { FrameworkType } from '~/generated/graphql'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    className?: ClassValue
    frameworks: Pick<FrameworkType, 'id' | 'abbreviation'>[]
    truncateAt?: number
}

interface State {}

export class FrameworkAbbreviationList extends React.PureComponent<Props, State> {
    private bem = new BEM('FrameworkAbbreviationList')

    public render() {
        const { className } = this.props

        return <div className={this.bem.getClassName(className)}>{this.renderFrameworks()}</div>
    }

    private renderFrameworks = () => {
        const { frameworks } = this.props

        const frameworksAbbreviations = frameworks.map(({ abbreviation }) => abbreviation)
        const truncateAt = this.props.truncateAt || 5

        if (frameworksAbbreviations.length <= truncateAt) {
            return <Paragraph>{frameworksAbbreviations.join(', ')}</Paragraph>
        }

        const firstFiveFrameworks = frameworksAbbreviations.slice(0, truncateAt)
        const otherFrameworks = frameworksAbbreviations.slice(truncateAt)

        return (
            <Row extraSmallSpacing={true}>
                <Paragraph>{firstFiveFrameworks.join(', ')}</Paragraph>
                <Tooltip
                    className={this.bem.getElement('tooltip')}
                    content={
                        <Paragraph className={this.bem.getElement('content')}>{otherFrameworks.join(', ')}</Paragraph>
                    }
                >
                    <span className={this.bem.getElement('count')}>
                        {localize.translate(t => t.Consultant.FrameworkAbbreviationList.amountOfFrameworks, {
                            frameworkAmount: otherFrameworks.length,
                        })}
                    </span>
                </Tooltip>
            </Row>
        )
    }
}
