import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    CreateControlExportMutation,
    CreateControlExportMutationVariables,
    CreateControlExportDocument,
    CreateTasksExportMutation,
    CreateTasksExportMutationVariables,
    CreateTasksExportDocument,
    CreateComplianceExportMutation,
    CreateComplianceExportMutationVariables,
    CreateComplianceExportDocument,
    ExportRadarItemsMutation,
    ExportRadarItemsMutationVariables,
    ExportRadarItemsDocument,
    CreateNonApplicableTopicsExportMutation,
    CreateNonApplicableTopicsExportMutationVariables,
    CreateNonApplicableTopicsExportDocument,
    CreateRisksExportMutation,
    CreateRisksExportMutationVariables,
    CreateRisksExportDocument,
    CreateCustomerEnabledNewsSourcesExportMutation,
    CreateCustomerEnabledNewsSourcesExportMutationVariables,
    CreateCustomerEnabledNewsSourcesExportDocument,
    CreateInboxExportMutation,
    CreateInboxExportMutationVariables,
    CreateInboxExportDocument,
} from '~/generated/graphql'

interface Props {
    children: (mutations: ExportWidgetMutations) => JSX.Element
}

export interface ExportWidgetMutations {
    controlExport: ControlExportMutation
    taskExport: TaskExportMutation
    complianceExport: ComplianceExportMutation
    radarExport: RadarExportMutation
    topicExport: TopicExportMutation
    riskExport: RiskExportMutation
    signalingExport: SignalingExportMutation
    inboxExport: InboxExportMutation
}

export interface ControlExportMutation {
    mutate: MutationFn<CreateControlExportMutation, CreateControlExportMutationVariables>
    options: MutationResult<CreateControlExportMutation>
}
export interface TaskExportMutation {
    mutate: MutationFn<CreateTasksExportMutation, CreateTasksExportMutationVariables>
    options: MutationResult<CreateTasksExportMutation>
}
export interface ComplianceExportMutation {
    mutate: MutationFn<CreateComplianceExportMutation, CreateComplianceExportMutationVariables>
    options: MutationResult<CreateComplianceExportMutation>
}
export interface RadarExportMutation {
    mutate: MutationFn<ExportRadarItemsMutation, ExportRadarItemsMutationVariables>
    options: MutationResult<ExportRadarItemsMutation>
}
export interface TopicExportMutation {
    mutate: MutationFn<CreateNonApplicableTopicsExportMutation, CreateNonApplicableTopicsExportMutationVariables>
    options: MutationResult<CreateNonApplicableTopicsExportMutation>
}
export interface RiskExportMutation {
    mutate: MutationFn<CreateRisksExportMutation, CreateRisksExportMutationVariables>
    options: MutationResult<CreateRisksExportMutation>
}
export interface SignalingExportMutation {
    mutate: MutationFn<
        CreateCustomerEnabledNewsSourcesExportMutation,
        CreateCustomerEnabledNewsSourcesExportMutationVariables
    >
    options: MutationResult<CreateCustomerEnabledNewsSourcesExportMutation>
}
export interface InboxExportMutation {
    mutate: MutationFn<CreateInboxExportMutation, CreateInboxExportMutationVariables>
    options: MutationResult<CreateInboxExportMutation>
}

export class ExportWidgetMutationsContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return this.renderMutations()
    }

    private renderMutations() {
        return (
            <GQLMutation<CreateControlExportMutation, CreateControlExportMutationVariables>
                mutation={CreateControlExportDocument}
            >
                {(mutate, options) => {
                    const controlExport = { mutate, options }
                    return this.renderTaskExportMutation(controlExport)
                }}
            </GQLMutation>
        )
    }

    private renderTaskExportMutation(controlExport: ControlExportMutation) {
        return (
            <GQLMutation<CreateTasksExportMutation, CreateTasksExportMutationVariables>
                mutation={CreateTasksExportDocument}
            >
                {(mutate, options) => {
                    const taskExport = { mutate, options }
                    return this.renderComplianceExportMutation(controlExport, taskExport)
                }}
            </GQLMutation>
        )
    }

    private renderComplianceExportMutation(controlExport: ControlExportMutation, taskExport: TaskExportMutation) {
        return (
            <GQLMutation<CreateComplianceExportMutation, CreateComplianceExportMutationVariables>
                mutation={CreateComplianceExportDocument}
            >
                {(mutate, options) => {
                    const complianceExport = { mutate, options }
                    return this.renderRadarItemExportMutation(controlExport, taskExport, complianceExport)
                }}
            </GQLMutation>
        )
    }

    private renderRadarItemExportMutation(
        controlExport: ControlExportMutation,
        taskExport: TaskExportMutation,
        complianceExport: ComplianceExportMutation
    ) {
        return (
            <GQLMutation<ExportRadarItemsMutation, ExportRadarItemsMutationVariables>
                mutation={ExportRadarItemsDocument}
            >
                {(mutate, options) => {
                    const radarExport = { mutate, options }
                    return this.renderTopicExportMutation(radarExport, controlExport, taskExport, complianceExport)
                }}
            </GQLMutation>
        )
    }

    private renderTopicExportMutation(
        radarExport: RadarExportMutation,
        controlExport: ControlExportMutation,
        taskExport: TaskExportMutation,
        complianceExport: ComplianceExportMutation
    ) {
        return (
            <GQLMutation<CreateNonApplicableTopicsExportMutation, CreateNonApplicableTopicsExportMutationVariables>
                mutation={CreateNonApplicableTopicsExportDocument}
            >
                {(mutate, options) => {
                    const topicExport = { mutate, options }
                    return this.renderRiskExportMutation(
                        topicExport,
                        radarExport,
                        controlExport,
                        taskExport,
                        complianceExport
                    )
                }}
            </GQLMutation>
        )
    }

    private renderRiskExportMutation(
        topicExport: TopicExportMutation,
        radarExport: RadarExportMutation,
        controlExport: ControlExportMutation,
        taskExport: TaskExportMutation,
        complianceExport: ComplianceExportMutation
    ) {
        return (
            <GQLMutation<CreateRisksExportMutation, CreateRisksExportMutationVariables>
                mutation={CreateRisksExportDocument}
            >
                {(mutate, options) => {
                    const riskExport = { mutate, options }
                    return this.renderSignalingExportMutation(
                        riskExport,
                        topicExport,
                        radarExport,
                        controlExport,
                        taskExport,
                        complianceExport
                    )
                }}
            </GQLMutation>
        )
    }

    private renderSignalingExportMutation(
        riskExport: RiskExportMutation,
        topicExport: TopicExportMutation,
        radarExport: RadarExportMutation,
        controlExport: ControlExportMutation,
        taskExport: TaskExportMutation,
        complianceExport: ComplianceExportMutation
    ) {
        return (
            <GQLMutation<
                CreateCustomerEnabledNewsSourcesExportMutation,
                CreateCustomerEnabledNewsSourcesExportMutationVariables
            >
                mutation={CreateCustomerEnabledNewsSourcesExportDocument}
            >
                {(mutate, options) => {
                    const signalingExport = { mutate, options }
                    return this.renderInboxExportMutation(
                        riskExport,
                        topicExport,
                        radarExport,
                        controlExport,
                        taskExport,
                        complianceExport,
                        signalingExport
                    )
                }}
            </GQLMutation>
        )
    }

    private renderInboxExportMutation(
        riskExport: RiskExportMutation,
        topicExport: TopicExportMutation,
        radarExport: RadarExportMutation,
        controlExport: ControlExportMutation,
        taskExport: TaskExportMutation,
        complianceExport: ComplianceExportMutation,
        signalingExport: SignalingExportMutation
    ) {
        const { children } = this.props

        return (
            <GQLMutation<CreateInboxExportMutation, CreateInboxExportMutationVariables>
                mutation={CreateInboxExportDocument}
            >
                {(mutate, options) => {
                    const inboxExport = { mutate, options }
                    const mutations = {
                        riskExport,
                        topicExport,
                        radarExport,
                        controlExport,
                        taskExport,
                        complianceExport,
                        signalingExport,
                        inboxExport,
                    }
                    return children(mutations)
                }}
            </GQLMutation>
        )
    }
}
