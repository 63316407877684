import './Matrix.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { MatrixLines, MatrixLine } from './MatrixLines/MatrixLines'
import { MatrixList } from './MatrixList/MatrixList'
import { compact, flatten, uniqWith, isEqual } from 'lodash-es'

interface Props {
    className?: ClassValue
    svgWidth?: number
    itemHeight?: number
    itemMargin?: number
    leftItems: MatrixItem[]
    rightItems: MatrixItem[]
    leftListTitle: string
    rightListTitle: string
    hideRightGroup?: boolean
    renderLeftEmptyState?: () => React.ReactNode
    renderLeftAddButton?: () => React.ReactNode
    renderRightEmptyState?: () => React.ReactNode
    renderRightAddButton?: () => React.ReactNode
}

export interface MatrixItem {
    name: string
    id: number
    content: React.ReactNode
    /** Link id's of the items that this item should be connected to */
    linkIds?: number[]
}

interface State {
    activeLeftItemIds?: number[]
    activeRightItemIds?: number[]
}

const SVG_WIDTH = 72
const ITEM_HEIGHT = 40
const ITEM_MARGIN = 8

export class Matrix extends React.PureComponent<Props, State> {
    public state: State = {
        activeLeftItemIds: undefined,
        activeRightItemIds: undefined,
    }

    private bem = new BEM('Matrix', () => ({ noRightGroup: this.props.hideRightGroup }))

    public render() {
        const {
            className,
            svgWidth,
            itemHeight,
            itemMargin,
            leftItems,
            leftListTitle,
            renderLeftAddButton,
            renderLeftEmptyState,
            rightItems,
            rightListTitle,
            renderRightAddButton,
            renderRightEmptyState,
            hideRightGroup,
        } = this.props

        const { activeLeftItemIds, activeRightItemIds } = this.state

        const lines = this.getLines()

        return (
            <div className={this.bem.getClassName(className)}>
                <MatrixList
                    items={leftItems}
                    title={leftListTitle}
                    renderAddButton={renderLeftAddButton}
                    renderEmptyState={renderLeftEmptyState}
                    className={this.bem.getElement('list')}
                    onItemHover={item => this.handleLeftItemHover(item, lines)}
                    onItemLeave={this.handleItemLeave}
                    activeItemIds={activeLeftItemIds}
                />
                {!hideRightGroup && (
                    <>
                        <MatrixLines
                            className={this.bem.getElement('lines')}
                            lines={lines}
                            svgWidth={svgWidth || SVG_WIDTH}
                            itemHeight={itemHeight || ITEM_HEIGHT}
                            itemMargin={itemMargin || ITEM_MARGIN}
                            activeLeftItemIds={activeLeftItemIds}
                            activeRightItemIds={activeRightItemIds}
                        />
                        <MatrixList
                            items={rightItems}
                            title={rightListTitle}
                            renderAddButton={renderRightAddButton}
                            renderEmptyState={renderRightEmptyState}
                            className={this.bem.getElement('list')}
                            onItemHover={item => this.handleRightItemHover(item, lines)}
                            onItemLeave={this.handleItemLeave}
                            activeItemIds={activeRightItemIds}
                        />
                    </>
                )}
            </div>
        )
    }

    private getLines(): MatrixLine[] {
        const { leftItems, rightItems } = this.props

        if (!leftItems.length || !rightItems.length) {
            return []
        }

        const leftLines = flatten(
            leftItems.map((leftItem, i) => {
                if (!leftItem.linkIds) {
                    return null
                }

                return leftItem.linkIds.map(linkId => {
                    const rightItem = rightItems.find(rightItem => rightItem.id === linkId)

                    if (!rightItem) {
                        return null
                    }

                    const rightItemIndex = rightItems.findIndex(item => item.id === rightItem.id)

                    return {
                        leftItemId: leftItem.id,
                        leftItemIndex: i,
                        rightItemId: rightItem.id,
                        rightItemIndex: rightItemIndex,
                    }
                })
            })
        )

        const rightLines = flatten(
            rightItems.map((rightItem, i) => {
                if (!rightItem.linkIds) {
                    return null
                }

                return rightItem.linkIds.map(linkId => {
                    const leftItem = leftItems.find(leftItem => leftItem.id === linkId)

                    if (!leftItem) {
                        return null
                    }

                    const leftItemIndex = leftItems.findIndex(item => item.id === leftItem.id)

                    return {
                        leftItemId: leftItem.id,
                        leftItemIndex: leftItemIndex,
                        rightItemId: rightItem.id,
                        rightItemIndex: i,
                    }
                })
            })
        )

        // Filter all the identical objects
        const allLines: MatrixLine[] = uniqWith([...compact(leftLines), ...compact(rightLines)], isEqual)

        return allLines
    }

    private handleLeftItemHover = (item: MatrixItem, lines: MatrixLine[]) => {
        const activeLines = lines.filter(line => line.leftItemId === item.id)

        const activeRightItemIds = activeLines ? activeLines.map(line => line.rightItemId) : []

        this.setState({
            activeLeftItemIds: [item.id],
            activeRightItemIds: activeRightItemIds,
        })
    }

    private handleRightItemHover = (item: MatrixItem, lines: MatrixLine[]) => {
        const activeLines = lines.filter(line => line.rightItemId === item.id)

        const activeLeftItemIds = activeLines.length ? activeLines.map(line => line.leftItemId) : []

        this.setState({
            activeLeftItemIds: activeLeftItemIds,
            activeRightItemIds: [item.id],
        })
    }

    private handleItemLeave = () => {
        this.setState({
            activeLeftItemIds: undefined,
            activeRightItemIds: undefined,
        })
    }
}
