import 'rc-slider/assets/index.css'
import './Slider.scss'
import * as RCSlider from 'rc-slider'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Paragraph } from '../../Typography/Paragraph'
import isNumber from 'lodash-es/isNumber'
import { Marks } from 'rc-slider'

type TValue = number | null

export enum HintDirection {
    left = 'left',
    right = 'right',
}

interface Props {
    className?: ClassValue
    defaultValue?: TValue | TValue[]
    value?: TValue | TValue[]
    isDisabled?: boolean
    max?: number
    maxLabel?: string
    min?: number
    minLabel?: string
    name: string
    onChange?: (value: number | number[], name: string) => void
    isRanged?: boolean
    showAsUnmoved?: boolean
    minLimit?: number
    maxLimit?: number
    hintDirection?: HintDirection
}

interface State {
    value?: TValue | TValue[]
}

export class Slider extends React.PureComponent<Props, State> {
    private bem = new BEM('Slider', () => ({
        'is-single-slider': !this.props.isRanged,
        'is-ranged-slider': this.props.isRanged,
        'is-unmoved': !!this.props.showAsUnmoved,
        'hint-direction': !!this.props.hintDirection,
        'hint-direction-left': this.props.hintDirection === HintDirection.left,
        'hint-direction-right': this.props.hintDirection === HintDirection.right,
    }))

    private isControlled: boolean = false

    constructor(props: Props) {
        super(props)

        if (props.value !== undefined) {
            this.isControlled = true
        }

        if (!this.isControlled) {
            this.state = {
                value: this.props.defaultValue,
            }
        }
    }

    public render() {
        const { className, isDisabled, min, max, isRanged, minLabel, maxLabel } = this.props
        const value = this.getValue()

        const labels = (
            <div className={this.bem.getElement('label-holder')}>
                <Paragraph small={true} subtle={true}>
                    {minLabel}
                </Paragraph>
                <Paragraph small={true} subtle={true}>
                    {maxLabel}
                </Paragraph>
            </div>
        )

        if (isRanged) {
            return (
                <div>
                    <RCSlider.Range
                        className={this.bem.getClassName(className)}
                        onChange={value => this.handleSliderOnChange(value)}
                        disabled={isDisabled}
                        allowCross={false}
                        min={min}
                        max={max}
                        value={value as number[]}
                        marks={this.getLimitMarks()}
                    />
                    {labels}
                </div>
            )
        }

        return (
            <div>
                <RCSlider.default
                    onChange={value => this.handleSliderOnChange(value)}
                    className={this.bem.getClassName(className)}
                    disabled={isDisabled}
                    min={min}
                    max={max}
                    value={value as number}
                    marks={this.getLimitMarks()}
                />
                {labels}
            </div>
        )
    }

    private getLimitMarks(): Marks {
        const { minLimit, maxLimit } = this.props

        const marks: Marks = {}

        if (isNumber(minLimit)) {
            marks[minLimit] = ''
        }

        if (isNumber(maxLimit)) {
            marks[maxLimit] = ''
        }

        return marks
    }

    private getValue() {
        if (this.isControlled) {
            return this.props.value
        } else {
            return this.state.value
        }
    }

    private handleSliderOnChange(newValue: number | number[]) {
        const { name, onChange } = this.props

        const limitedValue = Array.isArray(newValue)
            ? newValue.map(this.limitSingleValue)
            : this.limitSingleValue(newValue)

        if (!this.isControlled) {
            this.setState({ value: limitedValue })
        }

        if (onChange) {
            onChange(limitedValue, name)
        }
    }

    private limitSingleValue = (value: number): number => {
        const { minLimit, maxLimit } = this.props

        let limitedValue = value

        if (isNumber(minLimit)) {
            limitedValue = Math.max(minLimit, limitedValue)
        }

        if (isNumber(maxLimit)) {
            limitedValue = Math.min(maxLimit, limitedValue)
        }

        return limitedValue
    }
}
