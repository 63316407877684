import React from 'react'

import { AppNotFoundView } from '~/views/AppNotFoundView'
import { RouteComponentProps, Route, Switch } from 'react-router-dom'
import { routes } from '~/views/routes'
import { RadarOverviewView } from './RadarOverviewView'
import { RadarDetailView } from './RadarDetailView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props extends RouteComponentProps<{}> {}

export class RadarView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Switch>
                <Route
                    path={routes.customer(this.context.customer.slug).radar.index}
                    component={RadarOverviewView}
                    exact={true}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).radar.detail.view()}
                    component={RadarDetailView}
                    exact={true}
                />
                <Route component={AppNotFoundView} />
            </Switch>
        )
    }
}
