import './Paragraph.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    subtle?: boolean
    verySubtle?: boolean
    blue?: boolean
    white?: boolean
    emphasis?: boolean
    large?: boolean
    small?: boolean
    extraSmall?: boolean
    preserveLine?: boolean
    strikethrough?: boolean
    wrap?: boolean
    bold?: boolean
    center?: boolean
    inline?: boolean
    truncateEllipsis?: boolean
    noWrap?: boolean
}

export const Paragraph: React.SFC<Props> = props => {
    const { children, className, subtle, emphasis, small, extraSmall, preserveLine, wrap, bold, center } = props
    const { verySubtle, blue, strikethrough, inline, truncateEllipsis, white, large, noWrap } = props

    const bem = new BEM('Paragraph', () => ({
        'is-subtle': subtle,
        'is-very-subtle': verySubtle,
        'is-blue': blue,
        'is-white': white,
        'is-emphasised': emphasis,
        'is-large': large,
        'is-small': small,
        'is-extra-small': extraSmall,
        'preserve-line': preserveLine,
        'is-bold': bold,
        'is-center': center,
        strikethrough: strikethrough,
        wrap: wrap,
        truncateEllipsis,
        noWrap,
    }))

    if (inline) {
        return <span className={bem.getClassName(className)}>{children}</span>
    }

    return <p className={bem.getClassName(className)}>{children}</p>
}
