import React from 'react'
import gql from 'graphql-tag'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationResult, MutationFn } from 'react-apollo'

const CUSTOMER_SETTINGS_MUTATION = gql`
    mutation editCustomerSettings($fields: CustomerSettingsInputFieldsType) {
        editCustomerSettings(fields: $fields) {
            id
            settings {
                compliance {
                    riskGraph {
                        lowImpactDescription
                        highImpactDescription
                        lowPropbabiltyDescription
                        highPropbabiltyDescription
                    }
                }
            }
        }
    }
`

export interface EditRiskSettingsMutationVariables {
    fields?: {
        highImpactDescription?: string | null
        lowImpactDescription?: string | null
        highPropbabiltyDescription?: string | null
        lowPropbabiltyDescription?: string | null
    }
}

export interface EditRiskSettingsMutationResponse {
    editCustomerSettings?: {
        id: number
        settings?: {
            compliance?: {
                riskGraph?: {
                    highImpactDescription?: string
                    lowImpactDescription?: string
                    highPropbabiltyDescription?: string
                    lowPropbabiltyDescription?: string
                }
            }
        }
    }
}

export type EditRiskSettingsMutationFN = MutationFn<EditRiskSettingsMutationResponse, EditRiskSettingsMutationVariables>
export type EditRiskSettingsMutationResult = MutationResult<EditRiskSettingsMutationResponse>

interface Props {
    children: (mutationFN: EditRiskSettingsMutationFN, mutationResult: EditRiskSettingsMutationResult) => JSX.Element
}

export class EditRiskSettingsMutation extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<EditRiskSettingsMutationResponse, EditRiskSettingsMutationVariables>
                mutation={CUSTOMER_SETTINGS_MUTATION}
            >
                {children}
            </GQLMutation>
        )
    }
}
