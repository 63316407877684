import React from 'react'
import { Control } from './ControlQuery'
import { HierarchyList } from '~/components/Core/DataDisplay/HierarchyList/HierarchyList'
import { HierarchyListItem } from '~/components/Core/DataDisplay/HierarchyList/HierarchyListItem'
import { HierarchyControlGroupTile } from '../HierarchyControl/HierarchyControlGroupTile'
import { HierarchyControlTile } from '../HierarchyControl/HierarchyControlTile'

interface Props {
    control: Control
}

interface State {}

export class ControlDetailLinkedControls extends React.PureComponent<Props, State> {
    public render() {
        const { control } = this.props
        const { parentControl, childControls } = control

        const currentControlIndent = parentControl ? 1 : 0
        const childControlsIndent = parentControl ? 2 : 1

        return (
            <HierarchyList>
                {parentControl && (
                    <HierarchyListItem indent={0}>
                        <HierarchyControlGroupTile>
                            <HierarchyControlTile control={parentControl} linkedToControl={control} />
                        </HierarchyControlGroupTile>
                    </HierarchyListItem>
                )}

                {/** Current control */}
                <HierarchyListItem indent={currentControlIndent}>
                    <HierarchyControlTile isCurrentControl={true} control={control} />
                </HierarchyListItem>

                {childControls && childControls.length > 0 && (
                    <HierarchyListItem indent={childControlsIndent}>
                        <HierarchyControlGroupTile>
                            {childControls.map((childControl, i) => (
                                <HierarchyControlTile control={childControl} linkedToControl={control} key={i} />
                            ))}
                        </HierarchyControlGroupTile>
                    </HierarchyListItem>
                )}
            </HierarchyList>
        )
    }
}
