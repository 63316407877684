import './TimeInput.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Time {
    hours: number
    minutes: number
}

interface Props {
    name: string
    placeholder?: string
    className?: ClassValue
    defaultValue?: Time
    disabled?: boolean
    onChange?: (time: Time | null) => void
}

interface State {
    time?: Time
    inputValue: string
}

export class TimeInput extends React.Component<Props, State> {
    public state: State = {
        time: this.props.defaultValue,
        inputValue: this.parseTimeToString(this.props.defaultValue),
    }

    private bem = new BEM('TimeInput', () => ({
        isDisabled: this.props.disabled,
    }))

    public render() {
        const { name, placeholder, className, disabled } = this.props

        return (
            <input
                className={this.bem.getClassName(className)}
                onChange={e => this.handleOnChange(e.target.value)}
                onBlur={e => this.handleOnBlur(e.target.value)}
                name={name}
                placeholder={placeholder ?? '- : -'}
                value={this.state.inputValue}
                disabled={disabled}
            />
        )
    }

    private handleOnChange(value: string) {
        if (!value) {
            this.setState({ inputValue: '' })
            return
        }

        if (value.length > 5) {
            return
        }

        const lastChar = value[value.length - 1]
        const lastNumber = parseInt(lastChar, 10)

        if (isNaN(lastNumber)) {
            this.setState({ inputValue: value.slice(0, value.length - 1) })
            return
        }

        const newInputValue = value.length === 2 ? `${value}:` : value
        this.setState({ inputValue: newInputValue })
    }

    private handleOnBlur(value: string) {
        const { onChange } = this.props
        const time = this.parseStringToTime(value)
        const inputValue = time ? this.parseTimeToString(time) : ''

        this.setState({ time, inputValue }, () => onChange && onChange(time || null))
    }

    private parseTimeToString(time?: Time) {
        if (!time) {
            return ''
        }

        const { hours, minutes } = time

        return `${hours || '00'}:${minutes || '00'}`
    }

    // expects HH:mm
    private parseStringToTime(str?: string) {
        if (!str) {
            return
        }

        const hoursAndMinutes = str.split(':')

        if (!hoursAndMinutes || hoursAndMinutes.length !== 2) {
            return
        }

        const hours = parseInt(hoursAndMinutes[0], 10)
        const minutes = parseInt(hoursAndMinutes[1], 10)

        const isInvalid = this.checkIfTimeInvalid(hours, minutes)
        if (isInvalid) {
            return
        }

        const steppedTime = this.getSteppedTime({ hours, minutes })

        const isInvalidTime = hours === 24 && minutes > 0
        if (isInvalidTime) {
            return
        }

        const isMidnight = hours === 24 && minutes === 0
        if (isMidnight) {
            return { hours: 0, minutes: 0 }
        }

        return steppedTime
    }

    private checkIfTimeInvalid(hours: number, minutes: number) {
        return isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 24 || minutes < 0 || minutes > 59
    }

    // return H:0, HH:15, HH:30, HH:45
    private getSteppedTime({ hours, minutes }: Time) {
        const multiplier = Math.round(minutes / 15)

        if (multiplier === 4) {
            const uppedHour = hours === 23 ? 0 : hours + 1
            return { hours: uppedHour, minutes: 0 }
        }

        return { hours, minutes: multiplier * 15 }
    }
}
