import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { MonitoringReportsQueryType } from '../MonitoringReportsTable/MonitoringReportsQuery'
import {
    CreateMonitoringReportMutationFN,
    MonitoringReportCreateMutation,
} from '../MonitoringReportsCreateForm/MonitoringReportsCreateMutation'
import {
    CheckedMonitoringReportTopics,
    MonitoringReportsCreateFormModal,
} from '../MonitoringReportsCreateForm/MonitoringReportsCreateFormModal'
import { FormState } from '~/components/Core/DataEntry/Form/Form'
import { localize, notification } from '~/bootstrap'

interface Props {
    requestClose?: () => void
    onSubmit?: (monitoringReport: MonitoringReportsQueryType) => void
}

interface State {}

export class AddLinkedMonitoringReportModal extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { requestClose } = this.props

        return (
            <MonitoringReportCreateMutation>
                {(createMonitoringReport, { loading }) => (
                    <MonitoringReportsCreateFormModal
                        loading={loading}
                        onSubmit={this.handleOnSubmit(createMonitoringReport)}
                        onRequestClose={requestClose}
                    />
                )}
            </MonitoringReportCreateMutation>
        )
    }

    private handleOnSubmit =
        (createMonitoringReport: CreateMonitoringReportMutationFN) =>
        async (state: FormState, checkedDepartmentTopics: CheckedMonitoringReportTopics[]) => {
            const { requestClose, onSubmit } = this.props
            const { name, file, assessedAt } = state

            const response = await createMonitoringReport({
                variables: {
                    fields: {
                        name,
                        file,
                        assessedAt: assessedAt || new Date(),
                        checkedDepartmentTopics,
                    },
                },
            })

            if (requestClose && !state.error) {
                notification.success(
                    localize.translate(t => t.Customer.MonitoringView.AddMonitoringRapportModal.successMessage)
                )
                requestClose()
            }

            if (response && response.data && response.data.createMonitoringReport) {
                if (onSubmit) {
                    onSubmit({ id: response.data.createMonitoringReport.id, name } as any)
                }
            }
        }
}
