import './LinkedNormsCard.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { ComplianceIcon } from '~/components/Domain/Compliance/ComplianceIcon'
import { TextLink } from '~/components/Core/Text/TextLink'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'

export interface ThemeNorm {
    id: number
    name: string
    topics: {
        topicId?: number
        name: string
        selectedCount: number
        hasExpiredLawArticles: boolean
        totalCount: number
    }[]
}

interface Props {
    className?: ClassValue
    theme: ThemeNorm
    renderEdit?: () => JSX.Element
}

interface State {}

export class LinkedNormsCard extends React.Component<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    private bem = new BEM('LinkedNormsCard')

    public render() {
        const { className, theme: norm, renderEdit } = this.props

        return (
            <Card className={this.bem.getClassName(className)}>
                {renderEdit && <div className={this.bem.getElement('edit')}>{renderEdit()}</div>}
                <h5 className={this.bem.getElement('title')}>
                    <ComplianceIcon type={'norm'} className={this.bem.getElement('icon')} />
                    {norm.name}
                </h5>
                {norm.topics.map((topic, index) => (
                    <div key={`topic-${index}`} className={this.bem.getElement('topic-details')}>
                        {topic.topicId ? (
                            <TextLink
                                to={routes
                                    .customer(this.context.customer.slug)
                                    .legalFramework.topic.view(topic.topicId)}
                            >
                                <Row className={this.bem.getElement('topic-title')}>
                                    {topic.hasExpiredLawArticles && (
                                        <Icon
                                            type={IconType.attention}
                                            className={this.bem.getElement('expired-icon')}
                                        />
                                    )}
                                    {topic.name}
                                </Row>
                            </TextLink>
                        ) : (
                            <p className={this.bem.getElement('topic-title')}>
                                {topic.hasExpiredLawArticles && (
                                    <Icon type={IconType.attention} className={this.bem.getElement('expired-icon')} />
                                )}
                                {topic.name}
                            </p>
                        )}
                        <div className={this.bem.getElement('article-count')}>
                            {/* tslint:disable-next-line:jsx-use-translation-function */}
                            {topic.selectedCount}/{topic.totalCount}
                        </div>
                    </div>
                ))}
            </Card>
        )
    }
}
