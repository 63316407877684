import React from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { InboxOverviewView } from './InboxOverviewView'
import { InboxDetailContainer } from './InboxDetailContainer'
import { InboxInternalAlertDetailView } from './InboxInternalAlertDetailView'

interface Props extends RouteComponentProps<{}> {}
interface State {}

export class InboxView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Switch>
                <Route
                    path={routes.customer(this.context.customer.slug).inbox.index}
                    component={InboxOverviewView}
                    exact={true}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).inbox.detail.view()}
                    component={InboxDetailContainer}
                    exact={true}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).inbox.internalAlert.view()}
                    component={InboxInternalAlertDetailView}
                    exact={true}
                />
            </Switch>
        )
    }
}
