import './AddAssessmentModal.scss'
import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { localize } from '~/bootstrap'
import gql from 'graphql-tag'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationFn } from 'react-apollo'
import { withRouter, RouteComponentProps } from 'react-router'
import { routes } from '~/views/routes'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Column } from '~/components/Core/Layout/Column'
import { AssessmentArticleSelectionContainer } from './AssessmentArticleSelectionContainer'

interface Props extends RouteComponentProps<{}> {
    topicId?: number
    className?: ClassValue
    requestClose: () => void
}

interface State {
    selectedArticleIds: number[]
    useDistinctBrutoRiskValues: boolean
}

const CREATE_ASSESSMENT = gql`
    mutation createAssessment(
        $name: String
        $description: String
        $departmentId: Int!
        $useDistinctBrutoRiskValues: Boolean
        $linkedToTopic: AssessmentLinkedToTopicInputType
    ) {
        createAssessment(
            name: $name
            description: $description
            departmentId: $departmentId
            useDistinctBrutoRiskValues: $useDistinctBrutoRiskValues
            linkedToTopic: $linkedToTopic
        ) {
            id
            name
            description
        }
    }
`

interface AssessmentResponse {
    createAssessment: {
        id: number
        name: string
        description: string
    }
}

export interface AssessmentVariables {
    name: string
    description: string
    departmentId: number
    useDistinctBrutoRiskValues: boolean
    linkedToTopic?: {
        topicId: number
        selectedArticleIds: number[]
    }
}

export const AddAssessmentModal = withRouter(
    class AddAssessmentModal extends React.PureComponent<Props, State> {
        public static contextType = CustomerContext
        public context: CustomerContextValue

        public state: State = {
            selectedArticleIds: [],
            useDistinctBrutoRiskValues: true,
        }

        private bem = new BEM('AddAssessmentModal')
        private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Assessments.AddAssessmentModal)
        private formNames = {
            name: 'name',
            description: 'description',
        }

        public render() {
            const { className, requestClose, topicId } = this.props

            return (
                <GQLMutation<AssessmentResponse, AssessmentVariables> mutation={CREATE_ASSESSMENT}>
                    {(mutate, { loading }) => (
                        <Form isCompact={true} onSubmit={this.handleSubmit(mutate)}>
                            <Modal
                                title={this.loc(t => t.title)}
                                requestClose={requestClose}
                                className={this.bem.getClassName(className)}
                                submitForm={true}
                                loading={loading}
                                actions={closeModal => (
                                    <Row alignRight={true}>
                                        <Button type={ButtonType.tertiary} onClick={closeModal}>
                                            {localize.translate(t => t.Generic.cancel)}
                                        </Button>
                                        <Button submit={true}>{localize.translate(t => t.Generic.save)}</Button>
                                    </Row>
                                )}
                            >
                                <Column bigSpacing={true}>
                                    {topicId && (
                                        <Field forInput="selectedArticleIds">
                                            <AssessmentArticleSelectionContainer
                                                topicId={topicId}
                                                onChange={selectedArticleIds => this.setState({ selectedArticleIds })}
                                            />
                                        </Field>
                                    )}
                                    <Field forInput={this.formNames.name} label={this.loc(t => t.assessmentTitle)}>
                                        <Form.Input
                                            name={this.formNames.name}
                                            placeholder={this.loc(t => t.assessmentTitlePlaceholder)}
                                        />
                                    </Field>
                                    <Field
                                        forInput={this.formNames.description}
                                        label={this.loc(t => t.assessmentDescription)}
                                    >
                                        <Form.Input
                                            name={this.formNames.description}
                                            placeholder={this.loc(t => t.assessmentDescriptionPlaceholder)}
                                        />
                                    </Field>
                                    <Field
                                        forInput={'shouldDistinguish'}
                                        label={this.loc(t => t.netGrossDistinction.label)}
                                    >
                                        <Row>
                                            <Form.Radio
                                                className={this.bem.getElement('radio')}
                                                name={'shouldDistinguish'}
                                                label={this.loc(t => t.netGrossDistinction.distinguish)}
                                                defaultChecked={true}
                                                value={true}
                                                onChange={() => this.setState({ useDistinctBrutoRiskValues: true })}
                                            />
                                            <Form.Radio
                                                className={this.bem.getElement('radio')}
                                                name={'shouldDistinguish'}
                                                label={this.loc(t => t.netGrossDistinction.doNotDistinguish)}
                                                value={false}
                                                onChange={() => this.setState({ useDistinctBrutoRiskValues: false })}
                                            />
                                        </Row>
                                    </Field>
                                </Column>
                            </Modal>
                        </Form>
                    )}
                </GQLMutation>
            )
        }

        private handleSubmit = (mutate: MutationFn<AssessmentResponse, AssessmentVariables>) => async (
            formState: FormState
        ) => {
            const { requestClose, topicId } = this.props
            const { name, description } = formState
            const { selectedArticleIds, useDistinctBrutoRiskValues } = this.state

            const linkedToTopic = topicId
                ? {
                      topicId,
                      selectedArticleIds,
                  }
                : undefined

            const response = await mutate({
                variables: {
                    name,
                    description,
                    departmentId: this.context.activeDepartmentId,
                    useDistinctBrutoRiskValues,
                    linkedToTopic,
                },
            })

            if (requestClose) {
                requestClose()
            }

            if (response && response.data && response.data.createAssessment) {
                this.props.history.push(
                    routes
                        .customer(this.context.customer.slug)
                        .risks.assessments.view(response.data.createAssessment.id)
                )
            }
        }
    }
)
