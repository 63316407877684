import React from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { SignalingOverviewView } from './SignalingOverviewView'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { SignalingDetailView } from './SignalingDetailView'

interface Props extends RouteComponentProps<{}> {}
interface State {}

export class SignalingView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Switch>
                <Route
                    path={routes.customer(this.context.customer.slug).signaling.index}
                    component={SignalingOverviewView}
                    exact={true}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).signaling.detail.view()}
                    component={SignalingDetailView}
                    exact={true}
                />
                <Route component={AppNotFoundView} />
            </Switch>
        )
    }
}
