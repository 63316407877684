import { GraphQLError } from 'graphql'
import { EventEmitter } from 'events'
export enum ErrorType {
    network = 'NETWORK',
    graphql = 'GRAPHQL',
    validation = 'GRAPHQL_VALIDATION_FAILED',
    authentication = 'AUTHENTICATION_ERROR',
    authorization = 'AUTHORIZATION_ERROR',
    internalServerError = 'INTERNAL_SERVER_ERROR',
    domain = 'DOMAIN_ERROR',
}

export interface InputError {
    context: {
        value: string
        key: string
        label: string
        peers?: string[]
    }
    message: string
    path: string[]
    type: string
}

export interface ValidationError extends GraphQLError {
    extensions: {
        code: ErrorType.validation
        exception: {
            inputErrors: InputError[]
        }
    }
}

export interface DomainError extends GraphQLError {
    extensions: {
        code: ErrorType.domain
        exception: {
            type?: string
            metaData?: Record<string, any>
        }
    }
}

interface ErrorInfo {
    type: ErrorType
    error: GraphQLError | ValidationError | DomainError
    onInputPath?: string
}

export type ErrorCallbackHandler = (result: ErrorInfo) => void

export class ErrorService {
    private eventEmitter = new EventEmitter()

    public publishError(type: ErrorType, error: GraphQLError) {
        this.eventEmitter.emit(type, { type, error })
    }

    public subscribeToType(type: ErrorType, callback: ErrorCallbackHandler) {
        this.eventEmitter.addListener(type, callback)
    }

    public unsubscribeToType(type: ErrorType, callback: ErrorCallbackHandler) {
        this.eventEmitter.removeListener(type, callback)
    }
}
