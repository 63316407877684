import './PageDetail.scss'

import React from 'react'
import { BreadCrumbs, BreadCrumbType } from '~/components/Chrome/BreadCrumbs/BreadCrumbs'
import { BEM, ClassValue } from '~/services/BEMService'
import { Column } from '../Column'

interface Props {
    className?: ClassValue
    containTitle?: boolean
    center?: boolean
    renderSidebar?: () => null | JSX.Element
    renderTitle?: () => JSX.Element
    sidebarLeftOrientation?: boolean
    breadCrumbs?: BreadCrumbType[]
    actionComponent?: React.ReactNode
    smallBottomSpacing?: boolean
    spaceBetween?: boolean
}

interface State {}

interface Modifiers {
    hasSidebar: boolean
}

export class PageDetail extends React.Component<Props, State> {
    private bem = new BEM<Modifiers>('PageDetail', modifiers => ({
        'title-is-not-full-width': !!this.props.containTitle || modifiers.hasSidebar,
        center: !!this.props.center,
        hasSidebar: !!modifiers.hasSidebar,
        sidebarLeft: this.props.sidebarLeftOrientation,
        hasNoBreadCrumbs: !this.props.breadCrumbs,
        smallBottomSpacing: this.props.smallBottomSpacing,
        spaceBetween: this.props.spaceBetween,
    }))

    public render() {
        const {
            children,
            className,
            renderSidebar,
            renderTitle,
            breadCrumbs,
            sidebarLeftOrientation,
            actionComponent,
        } = this.props

        const sidebar = renderSidebar && renderSidebar()

        return (
            <div className={this.bem.getClassName(className)}>
                {sidebar && sidebarLeftOrientation && (
                    <div className={this.bem.getElement('left-content')}>
                        {actionComponent && (
                            <div className={this.bem.getElement('action-component')}>{actionComponent}</div>
                        )}
                        <Column bigSpacing={true} className={this.bem.getElement('sidebar')}>
                            {sidebar}
                        </Column>
                    </div>
                )}

                <div className={this.bem.getElement('middle-content')}>
                    {breadCrumbs && (
                        <BreadCrumbs className={this.bem.getElement('breadcrumbs')} breadCrumbs={breadCrumbs} />
                    )}
                    {renderTitle && <div className={this.bem.getElement('title')}>{renderTitle()}</div>}

                    <div className={this.bem.getElement('content')}>{children}</div>
                </div>
                {sidebar && !sidebarLeftOrientation && (
                    <div className={this.bem.getElement('right-content')}>
                        {actionComponent && (
                            <div className={this.bem.getElement('action-component')}>{actionComponent}</div>
                        )}
                        <Column bigSpacing={true} className={this.bem.getElement('sidebar')}>
                            {sidebar}
                        </Column>
                    </div>
                )}
            </div>
        )
    }
}
