import React from 'react'
import { MutationFn } from 'react-apollo'
import {
    AcceptInternalAlertDocument,
    AcceptInternalAlertMutation,
    AcceptInternalAlertMutationVariables,
    InternalAlertType,
    NewsItemSourceType,
    NewsItemType,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'
import { Topic } from '../../Topic/TopicsQuery'
import { ModalStep, MultiStepModal } from '~/components/Core/Feedback/Modal/MultiStepModal'
import { RelevantModalOverview } from '~/views/Customer/Signaling/RelevantModal/RelevantModalOverview'
import { RelevantModalAddTopic } from '~/views/Customer/Signaling/RelevantModal/RelevantModalAddTopic'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize, notification } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    internalAlert: InternalAlert
    requestClose: () => void
    onAccept: (id: number) => void
}

type InternalAlert = Pick<InternalAlertType, 'id' | 'note'> & { news: News } & { topics?: Topic[] | null }
type News = Pick<NewsItemType, 'id' | 'title' | 'publicationDate'> & { sources?: Source[] | null }
type Source = Pick<NewsItemSourceType, 'id' | 'name'>

type Mutate = MutationFn<AcceptInternalAlertMutation, AcceptInternalAlertMutationVariables>

interface State {
    selectedTopics: Topic[]
    note: string
}

export class AcceptInternalAlertModal extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        selectedTopics: [],
        note: this.props.internalAlert.note || '',
    }

    public render() {
        const { requestClose } = this.props

        return (
            <GQLMutation<AcceptInternalAlertMutation, AcceptInternalAlertMutationVariables>
                mutation={AcceptInternalAlertDocument}
            >
                {(mutate, { loading }) => (
                    <MultiStepModal
                        requestClose={requestClose}
                        steps={this.getSteps(mutate, loading)}
                        hideCloseButton={true}
                    />
                )}
            </GQLMutation>
        )
    }

    private getSteps(mutate: Mutate, loading: boolean): ModalStep[] {
        const { note, selectedTopics } = this.state
        const { internalAlert } = this.props

        return [
            {
                name: 'overview',
                title: internalAlert.news.title,
                content: (setActiveIndex: (index: number) => void) => (
                    <RelevantModalOverview
                        topics={selectedTopics}
                        defaultNote={note}
                        newsItem={internalAlert.news as NewsItemType}
                        setModalState={this.setRelevantModalState}
                        setActiveIndex={setActiveIndex}
                        selectedTopics={selectedTopics}
                        hideDepartments
                        defaultTopics={internalAlert.topics || []}
                    />
                ),
                actions: () => requestClose => this.renderModalActions(mutate, loading, requestClose),
            },
            {
                name: 'addTopic',
                title: localize.translate(t => t.Customer.Signaling.RelevantModal.addTopicTitle),
                content: () => (
                    <RelevantModalAddTopic
                        selectedTopics={selectedTopics}
                        defaultTopics={internalAlert.topics || []}
                        setModalState={this.setRelevantModalState}
                    />
                ),
            },
        ]
    }

    private setRelevantModalState = (newState: State, callBack?: (nextModalContentState: State) => void) => {
        this.setState(
            prevState => ({ ...prevState, ...newState }),
            () => callBack?.(this.state)
        )
    }

    private renderModalActions(mutate: Mutate, loading: boolean, requestClose?: () => void) {
        return (
            <Row alignRight={true}>
                <Button type={ButtonType.tertiary} onClick={requestClose} disabled={loading}>
                    {localize.translate(t => t.Core.Modal.cancel)}
                </Button>
                <Button onClick={() => this.handleSubmit(mutate)} loading={loading}>
                    {localize.translate(t => t.Customer.Inbox.InboxOverview.modal.link)}
                </Button>
            </Row>
        )
    }

    private async handleSubmit(mutate: Mutate) {
        const { selectedTopics, note } = this.state
        const { internalAlert, onAccept, requestClose } = this.props

        const response = await mutate({
            variables: {
                departmentId: this.context.activeDepartmentId,
                internalAlertId: internalAlert.id,
                extraTopicIds: selectedTopics.map(t => t.id),
                note,
            },
        })

        if (response && response.data?.acceptDepartmentInternalAlert) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))
            onAccept(response.data.acceptDepartmentInternalAlert.id)
            requestClose()
        }
    }
}
