import './CustomerGroupCustomersTableContainer.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ColumnOptions, Table } from '~/components/Core/DataDisplay/Table/Table'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM } from '~/services/BEMService'
import { routes } from '~/views/routes'

import {
    CustomersInCustomerGroupQuery,
    CustomersInCustomerGroupDocument,
    CustomersInCustomerGroupQueryVariables,
    FrameworkType,
    CustomerType,
} from '~/generated/graphql'
import { Query } from 'react-apollo'

interface Props {
    customerGroupId: number
    customerGroupName: string
}

interface State {
    search: string | null
}

export class CustomerGroupCustomersTableContainer extends React.PureComponent<Props, State> {
    public state: State = {
        search: null,
    }

    private bem = new BEM('CustomerGroupCustomersTableContainer')
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerGroupsView.CustomerGroupDetailView)

    private columns: ColumnOptions[] = [
        { field: 'name', headerLabel: this.loc(t => t.customerColumns.name), truncateField: true },
        { field: 'employeeCount', headerLabel: this.loc(t => t.customerColumns.employeeCount) },
        {
            field: 'frameworks',
            headerLabel: localize.translate(t => t.Customer.Attributes.frameworks),
            truncateField: true,
        },
    ]

    public render() {
        const { customerGroupId, customerGroupName } = this.props
        const { search } = this.state

        return (
            <Column bigSpacing={true} className={this.bem.getClassName()}>
                <Row spaceBetween={true}>
                    <SectionTitle>{this.loc(t => t.customers)}</SectionTitle>
                    <Row>
                        <Search
                            onChange={s => this.setState({ search: s })}
                            placeholder={localize.translate(t => t.Generic.search.byName)}
                            defaultValue={search}
                        />
                        <Button
                            rounded={true}
                            icon={IconType.plus}
                            to={routes.consultant.customerGroups.detail(customerGroupId).newCustomer(customerGroupName)}
                        >
                            {this.loc(t => t.newCustomer)}
                        </Button>
                    </Row>
                </Row>
                <Query<CustomersInCustomerGroupQuery, CustomersInCustomerGroupQueryVariables>
                    query={CustomersInCustomerGroupDocument}
                    variables={{ id: customerGroupId, search }}
                >
                    {({ data, loading }) => (
                        <Table
                            className={this.bem.getElement('table')}
                            columns={this.columns}
                            loading={loading}
                            data={this.getTableData(data?.customerGroup?.customers as CustomerType[])}
                        />
                    )}
                </Query>
            </Column>
        )
    }

    private getTableData(customers?: CustomerType[]) {
        if (!customers?.length) {
            return []
        }

        return customers.map(c => ({
            id: c.id,
            columns: {
                name: this.renderName(c.id, c.name),
                employeeCount: c.filledSeats,
                frameworks: this.renderFrameworks(c.frameworks),
            },
        }))
    }

    private renderName(id: number, name: string) {
        return (
            <Tooltip
                message={name}
                direction={ToolTipDirection.downStart}
                className={this.bem.getElement('name-tooltip')}
            >
                <Button
                    className={this.bem.getElement('name-button')}
                    type={ButtonType.subtleItemLink}
                    to={routes.consultant.customers.profile(id)}
                >
                    {name}
                </Button>
            </Tooltip>
        )
    }

    private renderFrameworks(frameworks: FrameworkType[]) {
        const frameworkAbbreviations = frameworks.map(f => f.abbreviation).join(', ')

        return (
            <Tooltip message={frameworkAbbreviations} className={this.bem.getElement('framework-tooltip')}>
                <Paragraph className={this.bem.getElement('frameworks')}>{frameworkAbbreviations}</Paragraph>
            </Tooltip>
        )
    }
}
