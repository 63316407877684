import React from 'react'
import gql from 'graphql-tag'
import { PageQuery } from '../PageQuery/PageQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'

interface Props {
    children: (employee: Theme) => React.ReactNode
    themeId: number
}

interface State {}

const GET_THEME_QUERY = gql`
    query theme($id: Int!, $customerSlug: String, $departmentId: Int!) {
        theme(id: $id, customerSlug: $customerSlug) {
            id
            name
            customerFrameworks(departmentId: $departmentId) {
                id
                name
                icon
                color
            }
        }
    }
`

export interface Theme {
    id: number
    name: string
    customerFrameworks: CustomerFramework[]
}

export class ThemeQuery extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, themeId } = this.props

        return (
            <PageQuery<Theme>
                query={GET_THEME_QUERY}
                variables={{
                    id: themeId,
                    customerSlug: this.context.customer.slug,
                    departmentId: this.context.activeDepartmentId,
                }}
            >
                {theme => children(theme)}
            </PageQuery>
        )
    }
}
