import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { localize, notification } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { SelectionBox } from '~/components/Core/Feedback/SelectionBox/SelectionBox'
import { IconType } from '~/components/Core/Icon/IconType'
import {
    ArchiveNewsItemsMutation,
    ArchiveNewsItemsMutationFN,
} from '~/components/Domain/Signaling/ArchiveNewsItems/ArchiveNewsItemsMutation'
import { Bold } from '~/components/Core/Typography/Bold'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'

interface Props {
    toBeArchivedIds: number[]
    toBeArchivedTitle?: string // for cases where toBeArchivedIds.length === 1
    onClearSelection: () => void
    onArchive: () => void
}

interface State {
    archivedReason: string | null
}

export class ArchiveNewsItemsContainer extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        archivedReason: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Signaling.SignalingOverview.confirmModal)

    public render() {
        const { toBeArchivedIds, onClearSelection } = this.props
        const idsLength = toBeArchivedIds.length
        const archiveLabel = localize.translate(t => t.Generic.archive)

        return (
            <ArchiveNewsItemsMutation>
                {(mutate, { loading }) => (
                    <ConfirmModal
                        title={this.loc(t => t.itemArchived)}
                        confirmButtonLabel={archiveLabel}
                        loading={loading}
                        renderContent={() => (
                            <Column>
                                <ErrorMessage path="archiveCustomerNews" />
                                {this.renderArchiveInfoText()}
                                <Field label={this.loc(t => t.fieldTitle)} compact={true}>
                                    <Input
                                        type="textarea"
                                        placeholder={this.loc(t => t.placeholder)}
                                        name="reason"
                                        onChange={archivedReason => this.setState({ archivedReason })}
                                    />
                                </Field>
                            </Column>
                        )}
                        onConfirm={close => this.handleSubmit(close, mutate)}
                    >
                        {openModal => (
                            <SelectionBox
                                selectedAmount={idsLength}
                                onClose={onClearSelection}
                                buttons={[{ icon: IconType.archive, label: archiveLabel, onClick: () => openModal() }]}
                                isOpen={idsLength > 0}
                            />
                        )}
                    </ConfirmModal>
                )}
            </ArchiveNewsItemsMutation>
        )
    }

    private renderArchiveInfoText() {
        const { toBeArchivedIds, toBeArchivedTitle } = this.props

        const idsLength = toBeArchivedIds.length
        const archiveInfo = idsLength > 1 ? `${idsLength} items` : toBeArchivedTitle

        return (
            <Paragraph>
                {this.loc(t => t.archiveMessagePart1)}
                <Bold inline={true}>{archiveInfo || this.loc(t => t.archiveInfoReplacement)}</Bold>
                {this.loc(t => t.archiveMessagePart2)}
            </Paragraph>
        )
    }

    private async handleSubmit(close: () => void, mutate: ArchiveNewsItemsMutationFN) {
        const { archivedReason } = this.state
        const { toBeArchivedIds, onClearSelection, onArchive } = this.props

        const response = await mutate({
            variables: {
                newsIds: toBeArchivedIds,
                archivedReason,
            },
        })

        if (response && response.data && response.data.archiveCustomerNews) {
            notification.success(localize.translate(t => t.Generic.successfullyArchived))
            close()
            onClearSelection()
            onArchive()
        }
    }
}
