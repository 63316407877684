import React from 'react'
import { RouteComponentProps } from 'react-router'
import { ControlDetail } from '~/components/Domain/Compliance/Controls/ControlDetail/ControlDetail'
import { ControlQuery, Control } from '~/components/Domain/Compliance/Controls/ControlDetail/ControlQuery'
import { DepartmentSwitchPopup } from '~/components/Domain/Department/DepartmentSwitchPopup'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ControlVisibility } from '~/generated/graphql'
import { routes } from '~/views/routes'

interface RouteParams {
    id?: string
}

export interface ControlDetailLocationState {
    isNewlyCreated?: boolean
}

interface Props extends RouteComponentProps<RouteParams, {}, ControlDetailLocationState> {}

interface State {}

export class ControlsDetailView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { id } = this.props.match.params
        const isNewlyCreated = this.props.location.state?.isNewlyCreated

        if (!id) {
            return null
        }

        return (
            <ControlQuery controlId={parseInt(id, 10)}>
                {(control, { refetch }) => {
                    if (this.shouldOfferToSwitchDepartment(control)) {
                        return (
                            <DepartmentSwitchPopup
                                departmentIdToToggleTo={control.department.id}
                                departmentNameToToggleTo={control.department.name}
                                to={routes.customer(this.context.customer.slug).compliance.controls.index}
                            />
                        )
                    }

                    return (
                        <ControlDetail
                            clearLocationState={() => history.replaceState({}, '', this.props.location.pathname)}
                            control={control}
                            refetch={() => refetch()}
                            isNewlyCreated={isNewlyCreated}
                        />
                    )
                }}
            </ControlQuery>
        )
    }

    private shouldOfferToSwitchDepartment(control: Control): boolean {
        const { activeDepartmentId } = this.context

        // If Current is Owner, never switch
        if (control.department.id === activeDepartmentId) {
            return false
        }

        switch (control.visibility) {
            case ControlVisibility.ownerDepartment:
                return control.department.id !== activeDepartmentId

            case ControlVisibility.allDepartments:
                // Every department can see control, so don't offer to switch.
                return false

            case ControlVisibility.selectionOfDepartments:
                // Offer to switch if Current is not in Selection
                return !control.visibleToDepartments?.map(dep => dep.id).includes(activeDepartmentId)
            default:
                return false
        }
    }
}
