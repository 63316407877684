import React from 'react'
import { InfiniteScrollOptions, InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { InboxControlFilters } from '~/views/Customer/Control/InboxControlOverviewView'
import {
    AlertsSummaryPerMonthDocument,
    AlertsSummaryPerMonthQueryVariables,
    CustomerAlertSummaryPerMonthType,
} from '~/generated/graphql'

interface Props {
    filters: InboxControlFilters
    children: (
        options: InfiniteScrollOptions<CustomerAlertSummaryPerMonthType, AlertsSummaryPerMonthQueryVariables>
    ) => React.ReactNode
}

export class AlertsSummaryPerMonth extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { filters, children } = this.props

        return (
            <InfiniteScrollQuery<CustomerAlertSummaryPerMonthType, AlertsSummaryPerMonthQueryVariables>
                take={4}
                variables={{
                    filters: {
                        departmentId: filters.departmentId,
                        dateFrom: typeof filters.dateFrom === 'string' ? new Date(filters.dateFrom) : filters.dateFrom,
                        dateTo: typeof filters.dateTo === 'string' ? new Date(filters.dateTo) : filters.dateTo,
                        isIgnored: filters.isIgnored,
                        hasControls: filters.hasControls,
                        allItems: filters.allItems,
                    },
                }}
                query={AlertsSummaryPerMonthDocument}
            >
                {children}
            </InfiniteScrollQuery>
        )
    }
}
