import './CreateCustomerFrameworkModalFrameworkContainer.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { FrameworkType, LegalFrameworkCuratedByEnum } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { AllFrameworksQuery } from '../../Customer/Framework/AllFrameworksQuery'
import { FrameworkCard } from '../../Customer/FrameworkCard'
import { isNumber } from 'lodash-es'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'

interface Props {
    handleOnSelect: (id: number, themeIds: number[]) => void
    selectedFrameworkId: number | null
    usedFrameworkIds: number[]
}

export class CreateCustomerFrameworkModalFrameworkContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Settings.CustomerFrameworks.createMultiModal)
    private bem = new BEM('CreateCustomerFrameworkModalFrameworkContainer')

    public render() {
        const { usedFrameworkIds } = this.props

        return (
            <AllFrameworksQuery>
                {({ data, loading, loadingMore }) => {
                    if (loading) {
                        return <Spinner />
                    }

                    const { handleOnSelect, selectedFrameworkId } = this.props
                    const frameworks = data ? data.nodes : []

                    return (
                        <Column bigSpacing={true} className={this.bem.getElement('field-container')}>
                            <Column smallSpacing={true}>
                                <ComponentTitle
                                    bold={true}
                                    subtle={true}
                                    title={this.loc(t => t.stepIndex, { currentStep: 1, totalSteps: 2 })}
                                />
                                <Paragraph>{this.loc(t => t.frameworkModal.subTitle)}</Paragraph>
                            </Column>
                            <Column smallSpacing={true}>
                                {frameworks.map(framework => {
                                    const { id, name, curatedBy } = framework

                                    if (!id || !name) {
                                        return null
                                    }

                                    const themeIds = this.getThemeIds(framework)

                                    return (
                                        <Row key={id}>
                                            <FrameworkCard
                                                title={name}
                                                isSelected={selectedFrameworkId === framework.id}
                                                alreadySelected={usedFrameworkIds.includes(id)}
                                                onClick={() => handleOnSelect(id, themeIds)}
                                                isCuratedInternally={curatedBy === LegalFrameworkCuratedByEnum.internal}
                                                className={this.bem.getElement('framework-card')}
                                            />
                                        </Row>
                                    )
                                })}
                            </Column>
                            {loadingMore && <Spinner />}
                        </Column>
                    )
                }}
            </AllFrameworksQuery>
        )
    }

    private getThemeIds(framework: FrameworkType) {
        const themeIds: number[] = []

        if (framework.themes?.length) {
            framework.themes.forEach(theme => {
                if (theme?.id && isNumber(theme.id)) {
                    themeIds.push(theme.id)
                }
            })
        }

        return themeIds
    }
}
