import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'
import './UserProfileList.scss'

interface Props {
    className?: ClassValue
}

export class UserProfileList extends React.PureComponent<Props> {
    private bem = new BEM('UserProfileList')
    public render() {
        const { className, children } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('container')}>{children}</div>
            </div>
        )
    }
}
