import React from 'react'

interface Props {
    children: (heightMeasurementRef: React.RefObject<any>) => React.ReactNode
}

export class MakeRoomForChat extends React.Component<Props> {
    private static styleElement = document.createElement('style')
    private static styleTagIsMounted = false

    private heightMeasurementRef = React.createRef<any>()
    private mountedStyleChild: Text | undefined = undefined

    public componentDidMount() {
        this.handleChatUpPosition()
    }

    public componentWillUnmount() {
        this.handleChatDownPosition()
    }

    public render() {
        return this.props.children(this.heightMeasurementRef)
    }

    private handleChatUpPosition() {
        if (!MakeRoomForChat.styleTagIsMounted) {
            const head = document.head || document.getElementsByTagName('head')[0]
            head.appendChild(MakeRoomForChat.styleElement)
            MakeRoomForChat.styleTagIsMounted = true
            MakeRoomForChat.styleElement.type = 'text/css'
        }

        const distance = this.heightMeasurementRef.current ? this.heightMeasurementRef.current.clientHeight : 0
        const css = `#Smallchat { margin-bottom: ${distance}px!important; }`
        this.mountedStyleChild = document.createTextNode(css)
        MakeRoomForChat.styleElement.appendChild(this.mountedStyleChild)
    }

    private handleChatDownPosition() {
        if (MakeRoomForChat.styleTagIsMounted && this.mountedStyleChild) {
            MakeRoomForChat.styleElement.removeChild(this.mountedStyleChild)
        }
    }
}
