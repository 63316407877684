import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { LinkedTaskItemType } from '~/graphql/types/Task'

export const LINKED_TASK_ITEM_STATUS_QUERY = gql`
    query linkedTaskItemStatus($departmentId: Int!, $linkedItemId: Int!, $linkedItemType: LinkedTaskItem!) {
        linkedTaskItemStatus(
            departmentId: $departmentId
            linkedItemId: $linkedItemId
            linkedItemType: $linkedItemType
        ) {
            open
            closed
            overDue
            employees {
                id
                user {
                    id
                    email
                    profile {
                        id
                        fullName
                        phoneNumber
                        avatar
                    }
                }
            }
        }
    }
`

// Defined the response from graphql the field should be possibly undefined by either marking it with a `?` or with null
export interface LinkedTaskItemStatusQueryResponse {
    linkedTaskItemStatus?: {
        open: number
        closed: number
        overDue: number
        employees: {
            id: number
            user: {
                id: number
                email: string
                profile: {
                    id: number
                    fullName: string
                    phoneNumber: string | null
                    avatar: string | null
                }
            }
        }[]
    }
}

// If the query has variables defined the query variables. example:
export interface LinkedTaskItemStatusQueryVariables {
    departmentId: number
    linkedItemId: number
    linkedItemType: LinkedTaskItemType
}

// Defined and export the Result so it can be used in other components
export type LinkedTaskItemStatusQueryResult = QueryResult<LinkedTaskItemStatusQueryResponse>

interface Props extends LinkedTaskItemStatusQueryVariables {
    // Children should always be one result object, like the Query component
    children: (result: LinkedTaskItemStatusQueryResult) => JSX.Element
}

export class LinkedTaskItemStatusQuery extends React.PureComponent<Props> {
    public render() {
        const { children, departmentId, linkedItemId, linkedItemType } = this.props

        return (
            <Query<LinkedTaskItemStatusQueryResponse>
                query={LINKED_TASK_ITEM_STATUS_QUERY}
                variables={{
                    departmentId,
                    linkedItemId,
                    linkedItemType,
                }}
            >
                {children}
            </Query>
        )
    }
}
