import { LinkedTaskItemType, TaskDetailFields } from '~/graphql/types/Task'
import { SortDirection } from '~/components/Core/DataDisplay/Table/Table'
import { TasksDocument } from '~/generated/graphql'

export interface Task extends TaskDetailFields {}

export interface TasksQueryForLinkedItem {
    itemId: number
    itemType: LinkedTaskItemType
}

export interface TasksQueryFilters {
    search?: string
    forEmployeeIds?: number[]
    completedOnly?: boolean
    onlyOverdueTasks?: boolean
    forMonitoring?: boolean
    forLinkedItem?: TasksQueryForLinkedItem
    tagIds?: number[]
    activeDepartmentOnly?: boolean
    linkedItemInboxFromDate?: Date
    linkedItemInboxToDate?: Date
    linkedItemType?: LinkedTaskItemType
}

export interface TaskQuerySort {
    name?: SortDirection
    startAt?: SortDirection
    dueAt?: SortDirection
    priority?: SortDirection
}

export const TASKS_QUERY = TasksDocument
