import './EventLog.scss'

import React from 'react'

import { ClassValue, BEM } from '~/services/BEMService'
import { User } from '~/graphql/types/User'
import { Spinner } from '../../Feedback/Spinner/Spinner'
import { EventLogItem } from './EventLogItem'
import { File } from '~/graphql/types/File'

export interface EventItem {
    date: string
    actor?: User
    title: string
    description?: string
    files?: File[]
    reports?: { id: number; name: string }[]
}

interface Props {
    className?: ClassValue
    loading?: boolean
    events?: EventItem[]
}

export class EventLog extends React.PureComponent<Props> {
    private bem = new BEM('EventLog')

    public render() {
        const { className, events, loading } = this.props

        if (!events || events.length === 0) {
            return <React.Fragment />
        }

        if (loading) {
            return <Spinner delayed={true} />
        }

        return <ul className={this.bem.getClassName(className)}>{this.renderEvents(events)}</ul>
    }

    private renderEvents(events: EventItem[]) {
        return events.map((event, index) => (
            <li key={`${index}-${event.title}`}>
                <EventLogItem event={event} />
            </li>
        ))
    }
}
