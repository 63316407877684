import React from 'react'
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router'
import { Page } from '~/components/Core/Layout/Page'
import { routes } from '~/views/routes'
import { OrganizationEmployeesOverviewView } from './OrganizationEmployeesOverviewView'
import { OrganizationEmployeesDetailView } from './OrganizationEmployeesDetailView'
import { OrganizationEmployeesEditView } from './OrganizationEmployeesEditView'
import { OrganizationProfileDetailView } from './OrganizationProfileDetailView'
import { OrganizationProfileEditView } from './OrganizationProfileEditView'
import { UserContext, UserContextValue } from '~/components/Providers/UserProvider'

interface RouteParams {}

interface Props extends RouteComponentProps<RouteParams> {}

export class OrganizationView extends React.PureComponent<Props> {
    public static contextType = UserContext
    public context: UserContextValue

    public render() {
        const { user, consultant } = this.context

        if (!user || !consultant) {
            return null
        }

        return (
            <Page>
                <Switch>
                    <Route
                        exact={true}
                        component={OrganizationEmployeesOverviewView}
                        path={routes.consultant.organization.employees.index}
                    />
                    <Route
                        component={OrganizationEmployeesEditView}
                        path={routes.consultant.organization.employees.edit()}
                    />
                    <Route
                        component={OrganizationEmployeesDetailView}
                        path={routes.consultant.organization.employees.view()}
                    />
                    <Route
                        exact={true}
                        component={OrganizationProfileDetailView}
                        path={routes.consultant.organization.profile.index}
                    />
                    <Route component={OrganizationProfileEditView} path={routes.consultant.organization.profile.edit} />
                    <Redirect
                        exact={true}
                        path={routes.consultant.organization.index}
                        to={routes.consultant.organization.employees.index}
                    />
                </Switch>
            </Page>
        )
    }
}
