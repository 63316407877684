import { SessionService } from '~/services/SessionService'
import { UserService } from '~/services/UserService'
import { LocalizationService } from '~/services/LocalizationService'
import '~/services/ApolloService'
import { ErrorService } from '~/services/ErrorService'
import { NotificationService } from '~/services/NotificationService'
import { PermissionService } from './services/PermissionService'
import { apolloClient } from '~/services/ApolloService'
import { FileService } from './services/FileService'

let callback: (() => void) | undefined

/**
 * Boostrap the client
 */
export const sessionClient = new SessionService()
export const userClient = new UserService(sessionClient)
export const errorClient = new ErrorService()
export const notification = new NotificationService()
export const localize = new LocalizationService()
export const permissions = new PermissionService(userClient)
export const fileService = new FileService(apolloClient)
;(async () => {
    await localize.initialize()

    if (callback) {
        callback()
    }
})()

/**
 * Register callback for initial load
 *
 * @param callback
 */
export const onInitialLoad = (cb: () => void) => {
    if (callback) {
        throw new Error('Initial load callback already assigned')
    }

    callback = cb
}
