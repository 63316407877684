import './ThemesDetailCard.scss'

import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { PinnableCard } from '~/components/Chrome/Pinnable/PinnableCard/PinnableCard'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TopicType } from '~/generated/graphql'
import { BEM, ClassValue } from '~/services/BEMService'
import { routes } from '~/views/routes'
import { TopicAssessmentIcon } from '../../Topic/TopicDesignAndEffectiveness/modals/TopicAssessment/TopicAssessmentIcon'
import { ThemesDetailCardTopicType } from './ThemesDetailCardListQuery'
import { MarkTopicAsNonApplicableWarningModal } from '../../Topic/MarkTopicAsNonApplicableWarningModal'
import { Toggle } from '~/components/Core/DataEntry/Form/Toggle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Link } from 'react-router-dom'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { TopicOverviewSummaryModal } from '../../Topic/TopicOverviewSummaryModal'
import { RiskIndicator } from '../../Compliance/Risks/RiskIndicator'

interface Props {
    topic: ThemesDetailCardTopicType
    isPinned: boolean
    notAllowedToPin: boolean
    togglePinned: () => void
    showMarkNonApplicable?: boolean
    className?: ClassValue
    hidePin?: boolean
    onTopicApplicableChange?: (
        topic: ThemesDetailCardTopicType,
        applicability: { reason: string | null; isApplicable: boolean }
    ) => void
}

interface State {
    isApplicable: boolean
    reason: string | null
}

export class ThemesDetailCard extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        isApplicable: !!this.props.topic.isApplicable,
        reason: this.props.topic.nonApplicable ? this.props.topic.nonApplicable.reason : null,
    }

    private editLoc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDetailEditView)
    private bem = new BEM('ThemesDetailCard', () => ({
        'has-bottom-component': this.props.showMarkNonApplicable,
    }))

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const { onTopicApplicableChange, topic } = this.props

        if (!onTopicApplicableChange) {
            return
        }

        const hasChanged = prevState.isApplicable !== this.state.isApplicable || prevState.reason !== this.state.reason
        if (hasChanged) {
            onTopicApplicableChange(topic, this.state)
        }
    }

    public render() {
        const { className, topic, isPinned, notAllowedToPin, togglePinned, hidePin } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <PinnableCard
                        className={this.bem.getClassName(className)}
                        to={routes.customer(this.context.customer.slug).legalFramework.topic.view(topic.id)}
                        title={this.renderTitle()}
                        isPinned={isPinned}
                        togglePinned={togglePinned}
                        notPermittedToPin={notAllowedToPin}
                        sidebar={this.renderSidebar()}
                        bottomComponent={this.renderMarkNonApplicableContent()}
                        hidePin={hidePin}
                        onInfoButtonClick={openModal}
                    />
                )}
                renderModal={closeModal => <TopicOverviewSummaryModal requestClose={closeModal} topic={topic} />}
            />
        )
    }

    private renderTitle() {
        const { highestRisk, name } = this.props.topic

        if (!highestRisk || (!highestRisk.severity && !highestRisk.severityBruto)) {
            return name
        }

        const severity = highestRisk.severity || highestRisk.severityBruto

        return (
            <Row smallSpacing flexStart>
                <Tooltip
                    content={
                        <Column smallSpacing className={this.bem.getElement('risk-tooltip')}>
                            <Row>
                                <Row smallSpacing>
                                    <RiskIndicator larger severity={severity} />
                                    <Paragraph small emphasis>
                                        {highestRisk.name}
                                    </Paragraph>
                                </Row>
                                <Paragraph subtle small>
                                    {localize.translate(t =>
                                        highestRisk.severity
                                            ? t.Customer.Compliance.Assessments.Overview.nettoRisk
                                            : t.Customer.Compliance.Assessments.Overview.brutoRisk
                                    )}
                                </Paragraph>
                            </Row>
                        </Column>
                    }
                >
                    <RiskIndicator severity={severity} className={this.bem.getElement('risk')} />
                </Tooltip>
                <Paragraph emphasis>{name}</Paragraph>
            </Row>
        )
    }

    private renderSidebar() {
        const { topic } = this.props
        if (!permissions.canViewTopicDnE()) {
            return
        }

        const { departmentTopicData } = this.props.topic

        const designData = departmentTopicData?.topicAssessmentDesignMetadata
        const effectivenessData = departmentTopicData?.topicAssessmentEffectivenessMetadata

        return (
            <Column noSpacing={true} className={this.bem.getElement('sidebar')}>
                <Row>
                    <Link
                        to={routes
                            .customer(this.context.customer.slug)
                            .legalFramework.topic.designAndEffectiveness(topic.id)}
                        className={this.bem.getElement('sidebar-link')}
                    >
                        <TopicAssessmentIcon status={designData?.grade} />
                        <TopicAssessmentIcon status={effectivenessData?.grade} />
                    </Link>
                </Row>
            </Column>
        )
    }

    private renderMarkNonApplicableContent() {
        const { topic, showMarkNonApplicable } = this.props
        const { departmentTopicData, hasCompliance } = topic

        if (!showMarkNonApplicable) {
            return
        }

        const { isApplicable } = this.state

        const designMetadata = departmentTopicData?.topicAssessmentDesignMetadata
        const effectivenessMetadata = departmentTopicData?.topicAssessmentEffectivenessMetadata

        const assessmentExists = !!(
            hasCompliance ||
            designMetadata?.linkedControlMeasureCount ||
            designMetadata?.linkedNoteCount ||
            effectivenessMetadata?.linkedControlMeasureCount ||
            effectivenessMetadata?.linkedNoteCount
        )

        return (
            <Card className={this.bem.getElement('non-applicable-card')}>
                <Column smallSpacing={true}>
                    <Row smallSpacing={true}>
                        <MarkTopicAsNonApplicableWarningModal
                            onConfirm={this.handleOnConfirm}
                            topic={topic as TopicType}
                        >
                            {openModal => (
                                <Toggle
                                    checked={isApplicable}
                                    name={topic.name}
                                    onChange={this.handleOnChange(openModal, assessmentExists)}
                                />
                            )}
                        </MarkTopicAsNonApplicableWarningModal>
                        {this.renderWarning(assessmentExists)}
                        <Paragraph small={true} subtle={true}>
                            {this.getStateLabel()}
                        </Paragraph>
                    </Row>
                    {!isApplicable && (
                        <Input
                            name={'description'}
                            value={this.state.reason || ''}
                            onChange={reason => this.setState({ ...this.state, reason })}
                            placeholder={this.editLoc(t => t.descriptionInputPlaceholder)}
                        />
                    )}
                </Column>
            </Card>
        )
    }

    private getStateLabel() {
        const { isApplicable } = this.state

        if (!isApplicable) {
            return this.editLoc(t => t.ApplicableStates.notApplicable)
        }

        return this.editLoc(t => t.ApplicableStates.applicable)
    }

    private renderWarning(assessmentExists: boolean) {
        const { isApplicable } = this.state

        if (!assessmentExists || !isApplicable) {
            return
        }

        return (
            <Tooltip
                direction={ToolTipDirection.downEnd}
                content={
                    <Paragraph small={true} className={this.bem.getElement('warning-tooltip')}>
                        {this.editLoc(t => t.warningTooltipMessage)}
                    </Paragraph>
                }
            >
                <Icon type={IconType.attention} className={this.bem.getElement('warning-icon')} />
            </Tooltip>
        )
    }

    private handleOnConfirm = (closeModal: () => void) => {
        this.setState({ ...this.state, isApplicable: false })

        closeModal()
    }

    private handleOnChange = (openModal: () => void, assessmentExists: boolean) => (checked: boolean) => {
        if (checked) {
            this.setState({ ...this.state, isApplicable: true })
            return
        }

        // When the topic has compliance we want to inform the user
        // via a modal to make sure.
        if (assessmentExists) {
            openModal()
            return
        }

        this.setState({ ...this.state, isApplicable: false })
    }
}
