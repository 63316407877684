import './InfoWidget.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { ArticleTitle } from '~/components/Core/Text/ArticleTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM, ClassValue } from '~/services/BEMService'
import { WidgetContainer } from '../components/WidgetContainer'

interface Props {
    className?: ClassValue
    isSmall?: boolean
}

export class InfoWidget extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView.Widgets.InfoWidget)
    private bem = new BEM('InfoWidget', () => ({
        'is-small': this.props.isSmall,
    }))

    public render() {
        const { className } = this.props

        return (
            <WidgetContainer subtle={true} className={this.bem.getClassName(className)}>
                <Column>
                    <ArticleTitle className={this.bem.getElement('title')} title={this.loc(t => t.title)} />
                    <Paragraph className={this.bem.getElement('text')}>{this.loc(t => t.subtitle)}</Paragraph>
                </Column>
            </WidgetContainer>
        )
    }
}
