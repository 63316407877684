import './DashboardDetailViewContainer.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { ResponsiveGrid, ResponsiveGridLayoutItem } from '~/components/Core/Layout/ResponsiveGrid'
import { Row } from '~/components/Core/Layout/Row'
import { BEM } from '~/services/BEMService'
import { gridBreakpoints, gridColumns, WidgetComponentProps, WidgetKey, widgets } from './CreateWidgetWidget/widgets'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { PageQuery } from '../PageQuery/PageQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    DashboardDetailContentType,
    DashboardWidgetDetailDocument,
    DashboardWidgetDetailQueryVariables,
    DepartmentType,
    DashboardWidgetContentFragmentFragment,
    DashboardWidgetLayoutTypeEnum,
} from '~/generated/graphql'

interface Props {
    widgetId: number
    currentBreakpoint: DashboardWidgetLayoutTypeEnum
    type: WidgetKey
    onNavigateBack: () => void
}

export class DashboardDetailViewContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('DashboardDetailViewContainer')

    public render() {
        const { widgetId } = this.props

        return (
            <PageQuery<DashboardDetailContentType, DashboardWidgetDetailQueryVariables>
                variables={{ widgetId }}
                query={DashboardWidgetDetailDocument}
            >
                {data => (
                    <div className={this.bem.getClassName()}>
                        {this.renderHeader()}
                        <ResponsiveGrid
                            className={this.bem.getElement('grid')}
                            items={this.getItems(data)}
                            rowHeight={1}
                            breakpoints={gridBreakpoints}
                            columns={gridColumns}
                            isDraggable={false}
                        />
                    </div>
                )}
            </PageQuery>
        )
    }

    private renderHeader() {
        const { onNavigateBack } = this.props
        const widget = widgets.find(({ widgetKey: translationKey }) => translationKey === this.props.type)

        if (!widget) {
            return
        }

        const { widgetKey: translationKey, iconType } = widget

        const backArrowText = localize.translate(t => t.Customer.DashboardView.backToOverview)
        const title = localize.translate(t => t.Customer.DashboardView.Widgets.DefaultNames[translationKey])

        return (
            <Column bigSpacing={true} className={this.bem.getElement('header')}>
                <Button type={ButtonType.actionLink} icon={IconType.back} onClick={onNavigateBack}>
                    {backArrowText}
                </Button>
                <Row>
                    <Icon className={this.bem.getElement('widget-icon')} type={IconType[iconType]} />
                    <Paragraph className={this.bem.getElement('title')}>{title}</Paragraph>
                </Row>
            </Column>
        )
    }

    private getItems(data: DashboardDetailContentType) {
        const layoutItems: ResponsiveGridLayoutItem[] = []

        const { id, contents } = data

        if (!contents?.length) {
            return []
        }

        const widget = widgets.find(({ widgetKey }) => widgetKey === this.props.type)
        if (!widget) {
            return []
        }

        const { height, component } = widget

        const maxXPosition = this.getMaxXPosition()
        let xPosition = 0

        contents.forEach((widgetDetailContent: any, i: number) => {
            const department = widgetDetailContent.department
            const content = widgetDetailContent.content
            const widgetId = id + i // to make the ids unique
            const props: WidgetComponentProps = this.getWidgetProps(widgetId, department, content)

            layoutItems.push({
                id: widgetId,
                x: { lg: xPosition, md: xPosition, sm: xPosition, xs: xPosition },
                y: { lg: 0, md: 0, sm: 0, xs: 0 },
                w: 1,
                h: height - 46, // to compensate for the lack of widget title
                render: () => component(props),
            })

            xPosition++
            if (xPosition > maxXPosition) {
                xPosition = 0
            }
        })

        return layoutItems
    }

    private getMaxXPosition() {
        const { currentBreakpoint } = this.props

        if (currentBreakpoint === DashboardWidgetLayoutTypeEnum.onecolumn) {
            return 0
        }
        if (currentBreakpoint === DashboardWidgetLayoutTypeEnum.twocolumn) {
            return 1
        }

        return 2
    }

    private getWidgetProps(id: number, department: DepartmentType, content: DashboardWidgetContentFragmentFragment) {
        const metadata = { customName: '', departments: [department], allDepartments: false }

        return {
            id,
            isEditing: false,
            isDetailWidget: true,
            metadata,
            currentBreakpoint: undefined,
            content,
        }
    }
}
