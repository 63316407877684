import gql from 'graphql-tag'
import { User, Consultant, EmployeeWithPassiveDepartmentEmployees } from '~/graphql/types/User'

export interface UserEmployeeWithCustomer {
    id: number
    customer: {
        id: number
        name: string
        slug: string
        customerGroup?: {
            id: number
            name: string
        }
    }
}

export interface CurrentUserQueryResponse {
    getAuthenticatedUser: User
    employee: EmployeeWithPassiveDepartmentEmployees
    userEmployees: UserEmployeeWithCustomer[]
    consultant: Consultant
}

export const CURRENT_USER_QUERY = gql`
    query getAuthenticatedUser {
        getAuthenticatedUser {
            id
            email
            role
            status
            invitedAt
            language
            lastAppVersionSeenAt
            isCustomerConsultant
            loggedInWithSSO
            lastAppUpdateSeen {
                date
                version
                update
                skip
            }
            lastLoginAt
            activatedAt
            profile {
                id
                firstName
                lastName
                fullName
                avatar
                phoneNumber
            }
        }
        consultant {
            id
            organization {
                id
                name
            }
        }
        employee {
            id
            onboardedAt
            permissions
            operations
            role
            departments {
                id
                name
                passiveEmployees {
                    id
                }
            }
            customer {
                id
                flowAIChat
                name
                slug
                editorialAccess
            }
        }
        userEmployees {
            id
            customer {
                id
                name
                slug
                customerGroup {
                    id
                    name
                }
            }
        }
    }
`
