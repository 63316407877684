import './SectionTitle.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    bold?: boolean
    subtle?: boolean
    underline?: boolean
    capitalize?: boolean
    emphasis?: boolean
    larger?: boolean
}

interface State {}

export class SectionTitle extends React.PureComponent<Props, State> {
    private bem = new BEM('SectionTitle', () => ({
        isBold: this.props.bold,
        isSubtle: this.props.subtle,
        isUnderlined: this.props.underline,
        isCapitalized: this.props.capitalize,
        emphasis: this.props.emphasis,
        larger: this.props.larger,
    }))

    public render() {
        const { children, className } = this.props

        return <h3 className={this.bem.getClassName(className)}>{children}</h3>
    }
}
