import React from 'react'
import { Switch, Route } from 'react-router'
import { routes } from '~/views/routes'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { CustomerFeedsListView } from './CustomerFeedsListView'
import { CustomerFeedDetailView } from './CustomerFeedDetailView'

export class CustomerFeedsView extends React.PureComponent {
    public render() {
        return (
            <Switch>
                <Route path={routes.consultant.customerfeeds.index} component={CustomerFeedsListView} exact={true} />
                <Route path={routes.consultant.customerfeeds.view()} component={CustomerFeedDetailView} />
                <Route component={AppNotFoundView} />
            </Switch>
        )
    }
}
