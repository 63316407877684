import './Modal.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Content } from '~/components/Core/Layout/Content'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'
import { ConfirmModal } from './ConfirmModal'
import { MediaQuery, Screensize } from '../../Layout/MediaQuery'

export interface ModalProps {
    className?: ClassValue
    requestClose?: () => void
    onAction?: () => void
    onDelete?: () => void
    actionType?: ButtonType
    isDangerousAction?: boolean
    submitForm?: boolean
    style?: React.CSSProperties
    disabled?: boolean
    loading?: boolean
    title?: string | React.ReactNode
    hideButtons?: boolean
    cancelButtonLabel?: string
    confirmButtonLabel?: string
    fullPage?: boolean
    modalSize?: ModalSize
    actions?: (requestClose?: () => void) => JSX.Element
    renderOnDeleteContent?: () => React.ReactNode
    onDeleteTitle?: string
    onDeleteConfirmButtonType?: ButtonType
    onDeleteConfirmButtonLabel?: string
    onDeleteClassName?: ClassValue
    openDeleteConfirmButtonLabel?: string
    preventCloseOnESC?: boolean
    hideCloseButton?: boolean
}

export enum ModalSize {
    small = 'small',
    regular = 'medium',
}

export class Modal extends React.Component<ModalProps> {
    private bem = new BEM('Modal', () => ({
        'has-button-hidden': this.props.hideButtons,
        'is-full-page': this.props.fullPage,
        [`modal-size-${this.props.modalSize}`]: !!this.props.modalSize,
    }))

    private closeButton = React.createRef<Button>()

    public componentDidMount() {
        document.addEventListener('keyup', this.handleKeyUp)

        if (this.closeButton.current) {
            this.closeButton.current.focus()
        }
    }

    public componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyUp)
    }

    public render() {
        const { children, className, title, style, hideButtons, requestClose, actions, hideCloseButton } = this.props

        return (
            <div style={style} className={this.bem.getClassName(className)}>
                <Row className={this.bem.getElement('header')} spaceBetween={true}>
                    <h2 className={this.bem.getElement('title')}>{title}</h2>
                    {!hideCloseButton && requestClose && (
                        <Button
                            type={ButtonType.noStyling}
                            onClick={requestClose}
                            ref={this.closeButton}
                            icon={IconType.closeModal}
                            ariaLabel={'Closes modal'}
                            className={this.bem.getElement('close-button')}
                        />
                    )}
                </Row>
                <Content className={this.bem.getElement('content')}>
                    {children}

                    {!!actions && (
                        <>
                            <div className={this.bem.getElement('spacer')} />
                            <div className={this.bem.getElement('line')} />
                            <div className={this.bem.getElement('action-container')}> {actions(requestClose)}</div>
                        </>
                    )}

                    {!actions && !hideButtons && (
                        <>
                            <MediaQuery breakpoint={Screensize.tablet}>
                                <div className={this.bem.getElement('spacer')} />
                                <div className={this.bem.getElement('line')} />
                            </MediaQuery>
                            <div className={this.bem.getElement('button-container')}>{this.renderButtons()}</div>
                        </>
                    )}
                </Content>
            </div>
        )
    }

    private renderButtons = () => {
        const { requestClose, disabled, onAction, loading, submitForm, confirmButtonLabel, onDelete } = this.props
        const { renderOnDeleteContent, onDeleteConfirmButtonType, onDeleteConfirmButtonLabel } = this.props
        const { onDeleteTitle, onDeleteClassName, actionType, openDeleteConfirmButtonLabel } = this.props
        const { isDangerousAction, cancelButtonLabel } = this.props

        return (
            <Row spaceBetween={true} className={this.bem.getElement('buttons')}>
                {!!onDelete && (
                    <>
                        <ConfirmModal
                            title={onDeleteTitle}
                            renderContent={renderOnDeleteContent}
                            confirmButtonType={onDeleteConfirmButtonType}
                            confirmButtonLabel={onDeleteConfirmButtonLabel}
                            onConfirm={closeModal => this.onDelete(closeModal)}
                            className={this.bem.getClassName(onDeleteClassName)}
                        >
                            {openModal => (
                                <Button onClick={openModal} type={ButtonType.secondary} danger={true}>
                                    {openDeleteConfirmButtonLabel || localize.translate(t => t.Core.Modal.delete)}
                                </Button>
                            )}
                        </ConfirmModal>
                    </>
                )}
                <Row className={this.bem.getElement('button-container')} alignRight={true}>
                    {requestClose && (
                        <Button
                            type={ButtonType.tertiary}
                            onClick={requestClose}
                            className={this.bem.getElement('cancel-button')}
                            ref={this.closeButton}
                            disabled={loading}
                        >
                            {cancelButtonLabel || localize.translate(t => t.Core.Modal.cancel)}
                        </Button>
                    )}
                    {(onAction || submitForm) && (
                        <Button
                            onClick={onAction}
                            disabled={disabled}
                            type={actionType}
                            loading={loading}
                            submit={!!submitForm}
                            danger={isDangerousAction}
                        >
                            {confirmButtonLabel ? confirmButtonLabel : localize.translate(t => t.Core.Modal.confirm)}
                        </Button>
                    )}
                </Row>
            </Row>
        )
    }

    private onDelete = (closeModal: () => void): void => {
        const { onDelete } = this.props
        if (onDelete) {
            onDelete()
        }
        closeModal()
    }

    private handleKeyUp = (event: KeyboardEvent): void => {
        if (this.props.preventCloseOnESC) {
            return
        }

        // 27 = ESC
        if (event.keyCode !== 27) {
            return
        }

        if (this.props.requestClose) {
            this.props.requestClose()
        }
    }
}
