import './PageNavigation.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Row } from './Row'
import { NavLink } from 'react-router-dom'
import { BreadCrumbType, BreadCrumbs } from '~/components/Chrome/BreadCrumbs/BreadCrumbs'

interface Props {
    className?: ClassValue
    links?: PageNavigationLinks[]
    renderAction?: () => JSX.Element
    breadCrumbs?: BreadCrumbType[]
}

interface PageNavigationLinks {
    route: string
    label: string
    active?: boolean
}

interface State {}

export class PageNavigation extends React.PureComponent<Props, State> {
    private bem = new BEM('PageNavigation', () => ({
        'has-breadcrumbs': !!this.props.breadCrumbs,
    }))

    public render() {
        const { className, links, renderAction, breadCrumbs } = this.props

        return (
            <nav className={this.bem.getClassName(className)}>
                {breadCrumbs && <BreadCrumbs breadCrumbs={breadCrumbs} />}
                <Row className={this.bem.getElement('navigation')} spaceBetween={true}>
                    <ul>
                        {links &&
                            links.map((link, i) => (
                                <NavLink
                                    exact={true}
                                    key={`${link.label} - ${i}`}
                                    to={link.route}
                                    className={this.bem.getElement('link', () => ({
                                        'is-active': link.active,
                                    }))}
                                >
                                    {link.label}
                                </NavLink>
                            ))}
                    </ul>
                    {renderAction && <div className={this.bem.getElement('action')}>{renderAction()}</div>}
                </Row>
            </nav>
        )
    }
}
