import { FormState } from '~/components/Core/DataEntry/Form/Form'
import { CustomerSubscribedToFeedTypeTypesEnumType } from '~/generated/graphql'

/**
 * To add a new type & setting
 *      - add enum with keys from BE
 *      - add enum to CustomerFeedSettings
 *      - update customerFeedSettingKeys
 *      - update CustomerFeedTypeSelect.options
 *      - add keys to nl.json (Consultant.CustomerFeeds.CustomerFeedTypeSelect.settings)
 *      - update customerFeedTypeSettingHelpers.ts
 */

/**
 * To extend existing type
 *      - add setting to the corresponding enum
 *      - add key to nl.json (Consultant.CustomerFeeds.CustomerFeedTypeSelect.settings)
 *      - update customerFeedTypeSettingHelpers.ts
 */

export enum AegonLawChangesSettingKeys {
    subscribedNewsSourceIds = 'subscribedNewsSourceIds',
}

enum AegonSignalingSettingKeys {}

enum APGSettingKeys {}

enum APGAllAlertsSettingKeys {}

export type CustomerFeedSettings =
    | Record<AegonLawChangesSettingKeys, string>
    | Record<AegonSignalingSettingKeys, string>
    | Record<APGSettingKeys, string>
    | Record<APGAllAlertsSettingKeys, string>

export const customerFeedSettingKeys: Record<CustomerSubscribedToFeedTypeTypesEnumType, string[]> = {
    [CustomerSubscribedToFeedTypeTypesEnumType.aegonLawChanges]: Object.keys(AegonLawChangesSettingKeys),
    [CustomerSubscribedToFeedTypeTypesEnumType.aegonSignaling]: Object.keys(AegonSignalingSettingKeys),
    [CustomerSubscribedToFeedTypeTypesEnumType.apgRadarAlerts]: Object.keys(APGSettingKeys),
    [CustomerSubscribedToFeedTypeTypesEnumType.apgAllAlerts]: Object.keys(APGAllAlertsSettingKeys),
}

export function getCustomerFeedSettingsForType(type: CustomerSubscribedToFeedTypeTypesEnumType, formState: FormState) {
    const keys = customerFeedSettingKeys[type]
    if (!keys) {
        return
    }

    const obj: object = {}
    for (const key of keys) {
        const setting = formState[key]

        if (setting) {
            const parsedSetting = getFormattedCustomerFeedSetting(key, setting)

            obj[key] = parsedSetting
        }
    }

    if (obj) {
        return JSON.stringify(obj)
    }

    return
}

export function getFormattedCustomerFeedSetting(settingKey: string, value: string) {
    if (settingKey === AegonLawChangesSettingKeys.subscribedNewsSourceIds) {
        return parseStringToNumbers(value)
    }

    return value
}

export function parseCustomerFeedSettingToString(settingKey: string, value: any) {
    if (settingKey === AegonLawChangesSettingKeys.subscribedNewsSourceIds) {
        return value.join(', ')
    }
}

// expects 1,2,4
function parseStringToNumbers(str: string) {
    return str.split(',').map(s => parseInt(s.trim(), 10))
}
