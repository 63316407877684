import './EmployeeOverviewView.scss'

import React from 'react'
import { Row } from '~/components/Core/Layout/Row'
import { localize, userClient, permissions, fileService } from '~/bootstrap'
import { Table } from '~/components/Core/DataDisplay/Table/Table'
import { AddEmployeeModal } from '~/components/Domain/Settings/Employees/AddEmployeeModal'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { MediaQuery, Screensize } from '~/components/Core/Layout/MediaQuery'
import { MobileItemList } from '~/components/Core/Layout/MobileItemList'
import { PageOffsetPadding } from '~/components/Core/Layout/PageOffsetPadding'
import { EmployeeItem } from '~/components/Domain/Settings/Employees/EmployeeItem'
import { BEM, ClassValue } from '~/services/BEMService'
import { Column } from '~/components/Core/Layout/Column'
import { StickyFooter } from '~/components/Core/Layout/StickyFooter'
import { TextLink } from '~/components/Core/Text/TextLink'
import { routes } from '~/views/routes'
import gql from 'graphql-tag'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { UserStatus } from '~/graphql/types/User'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { UserStatusDisplay } from '~/components/Domain/User/UserStatusDisplay'
import { CustomerQuery, Customer } from '~/components/Domain/Customer/CustomerQuery'
import { Guard } from '~/components/Core/Guard/Guard'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { PermissionLabels } from '~/components/Domain/Settings/Permissions/PermissionLabels'
import { breadcrumbs } from '~/views/breadcrumbs'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { EmployeePermissionEnum, EmployeeRole, UserRole } from '~/generated/graphql'
import { Logo } from '~/components/Chrome/Logo/Logo'
import { CreateCustomerUsersExport, CreateCustomerUsersExportMutationFN } from '~/components/Domain/Customer/CreateCustomerUsersExport'

interface Props {
    className?: ClassValue
}

interface State {}

export const CUSTOMER_EMPLOYEES_QUERY = gql`
    query assignableEmployees($skip: Int, $take: Int, $customerSlug: String) {
        assignableEmployees(skip: $skip, take: $take, customerSlug: $customerSlug)
            @connection(key: "assignableEmployees", filter: ["customerSlug"]) {
            count
            hasNextPage
            nodes {
                id
                employee {
                    id
                    permissions
                    role
                    departments {
                        id
                        name
                    }
                    user {
                        id
                        email
                        role
                        status
                        invitedAt
                        isCustomerConsultant
                        profile {
                            id
                            firstName
                            lastName
                            fullName
                            avatar
                        }
                    }
                }
                shadowEmployee {
                    id
                    name
                }
            }
        }
    }
`

interface ShadowEmployee {
    id: number
    name: string
}

interface AssignableEmployee {
    id: number
    employee: Employee | null
    shadowEmployee: ShadowEmployee | null
}

interface Employee {
    id: number
    departments: { id: number; name: string }[]
    permissions: EmployeePermissionEnum[]
    role: EmployeeRole
    user: {
        id: number
        email: string
        role?: UserRole
        status: UserStatus
        invitedAt: string | null
        isCustomerConsultant: boolean
        profile: {
            id: number
            firstName: string
            lastName: string
            fullName: string
        }
    }
}

export class EmployeeOverviewView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('EmployeeOverviewView')

    private loc = localize.namespaceTranslate(t => t.Settings.Attributes)

    public render() {
        const { className } = this.props

        return (
            <CustomerQuery>
                {customer => (
                    <InfiniteScrollQuery<AssignableEmployee>
                        query={CUSTOMER_EMPLOYEES_QUERY}
                        variables={{ customerSlug: customer.slug }}
                    >
                        {({ data, loading, loadingMore, refetch }) => {
                            const assignableEmployees = data ? data.nodes || [] : []

                            return (
                                <div className={this.bem.getClassName(className)}>
                                    <MediaQuery breakpoint={Screensize.tablet}>
                                        {this.renderContentForTablet(
                                            assignableEmployees,
                                            customer,
                                            loading,
                                            loadingMore,
                                            refetch
                                        )}
                                    </MediaQuery>
                                    <MediaQuery breakpoint={Screensize.mobile}>
                                        {this.renderContentForMobile(assignableEmployees, customer, refetch)}
                                    </MediaQuery>
                                </div>
                            )
                        }}
                    </InfiniteScrollQuery>
                )}
            </CustomerQuery>
        )
    }

    private renderContentForTablet(
        assignableEmployees: AssignableEmployee[],
        customer: Customer,
        loading: boolean,
        loadingMore: boolean,
        refetch: Function
    ) {
        const tableData = assignableEmployees.map(assignableEmployee => ({
            id: assignableEmployee.id,
            columns: {
                name: this.renderAssignableEmployeeNameForTablet(assignableEmployee, customer),
                email: this.renderAssignableEmployeeEmail(assignableEmployee),
                departments: assignableEmployee.employee && (
                    <Tooltip
                        content={
                            <div>
                                <ComponentTitle title={this.loc(t => t.departments)} />
                                {assignableEmployee.employee.departments.map(department => (
                                    <Paragraph key={department.id}>{department.name}</Paragraph>
                                ))}
                            </div>
                        }
                    >
                        <Paragraph>
                            {this.loc(t => t.departmentCount, {
                                count: assignableEmployee.employee.departments.length,
                                smart_count: assignableEmployee.employee.departments.length,
                            })}
                        </Paragraph>
                    </Tooltip>
                ),
                status: this.renderAssignableEmployeeStatus(assignableEmployee),
                role: this.renderRole(assignableEmployee),
                permissions: this.renderPermissions(assignableEmployee),
            },
        }))

        return (
            <Column>
                <PageHeader
                    title={localize.translate(t => t.Customer.Settings.SettingsEmployeeOverviewView.title)}
                    actionComponent={<Row>{this.renderExportEmployeesButton()}{this.renderAddEmployeeButton(refetch)}</Row>}
                    breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).settings.index]}
                />
                <Paragraph>
                    {localize.translate(t => t.Customer.Settings.SettingsEmployeeOverviewView.currentlyFilledSeats, {
                        filledSeats: customer ? customer.filledSeats : '?',
                        availableSeats: customer ? customer.availableSeats : '?',
                    })}
                </Paragraph>
                <Table
                    columns={[
                        { field: 'name', headerLabel: this.loc(t => t.name) },
                        { field: 'email', headerLabel: this.loc(t => t.email) },
                        { field: 'departments', headerLabel: this.loc(t => t.departments) },
                        { field: 'status', headerLabel: this.loc(t => t.status) },
                        { field: 'role', headerLabel: this.loc(t => t.role) },
                        { field: 'permissions', headerLabel: this.loc(t => t.permissions) },
                    ]}
                    data={tableData}
                    loading={loading}
                    loadingMore={loadingMore}
                />
            </Column>
        )
    }

    private renderContentForMobile(assignableEmployees: AssignableEmployee[], customer: Customer, refetch: Function) {
        return (
            <Column>
                <PageOffsetPadding>
                    <MobileItemList>
                        {assignableEmployees.map(assignableEmployee => (
                            <EmployeeItem
                                key={assignableEmployee.id}
                                email={this.renderAssignableEmployeeEmail(assignableEmployee)}
                                name={this.renderAssignableEmployeeNameForMobile(assignableEmployee, customer)}
                                status={this.renderAssignableEmployeeStatus(assignableEmployee)}
                                role={this.renderRole(assignableEmployee)}
                            />
                        ))}
                    </MobileItemList>
                </PageOffsetPadding>
                <PageOffsetPadding className={this.bem.getElement('sticky-footer')}>
                    <StickyFooter>
                        <Paragraph>
                            {localize.translate(
                                t => t.Customer.Settings.SettingsEmployeeOverviewView.currentlyFilledSeats,
                                {
                                    filledSeats: customer ? customer.filledSeats : '?',
                                    availableSeats: customer ? customer.availableSeats : '?',
                                }
                            )}
                        </Paragraph>
                        {this.renderExportEmployeesButton()}
                        {this.renderAddEmployeeButton(refetch)}
                    </StickyFooter>
                </PageOffsetPadding>
            </Column>
        )
    }

    private renderExportEmployeesButton() {
        return (
            <CreateCustomerUsersExport>
            {(mutate, { loading }) => (
                            <Button
                                onClick={this.handleExport(mutate)}
                                loading={loading}
                                type={ButtonType.secondary}
                            >
                                {localize.translate(t => t.Generic.exportToExcel)}
                            </Button>

            )}
        </CreateCustomerUsersExport>
        )
    }

    private renderAddEmployeeButton(refetch: Function) {
        return (
            <Guard condition={permissions.isConsultantUser() || permissions.isCustomerAdministrator()}>
                <ModalManager
                    render={openModal => (
                        <Button onClick={openModal} icon={IconType.plus}>
                            {localize.translate(t => t.Settings.Attributes.newUser)}
                        </Button>
                    )}
                    renderModal={requestClose => (
                        <AddEmployeeModal seatsAreFilled={false} requestClose={requestClose} onAdd={() => refetch()} />
                    )}
                />
            </Guard>
        )
    }

    private renderAssignableEmployeeEmail(assignableEmployee: AssignableEmployee) {
        if (assignableEmployee.employee) {
            return assignableEmployee.employee.user.email
        }

        return ''
    }

    private renderAssignableEmployeeNameForTablet(assignableEmployee: AssignableEmployee, customer: Customer) {
        if (assignableEmployee.employee) {
            return (
                <TableLink
                    to={routes.customer(customer.slug).settings.employees.detail(assignableEmployee.employee.id)}
                >
                    {assignableEmployee.employee.user.profile.fullName}
                    {assignableEmployee.employee.user.isCustomerConsultant && (
                        <Logo inline={true} iconOnly={true} tiny={true} />
                    )}
                </TableLink>
            )
        }

        if (assignableEmployee.shadowEmployee) {
            return assignableEmployee.shadowEmployee.name
        }

        return null
    }

    private renderAssignableEmployeeNameForMobile(assignableEmployee: AssignableEmployee, customer: Customer) {
        if (assignableEmployee.employee) {
            return (
                <TextLink to={routes.customer(customer.slug).settings.employees.detail(assignableEmployee.employee.id)}>
                    {assignableEmployee.employee.user.profile.fullName}
                    {assignableEmployee.employee.user.isCustomerConsultant && (
                        <Logo inline={true} iconOnly={true} tiny={true} />
                    )}
                </TextLink>
            )
        }

        if (assignableEmployee.shadowEmployee) {
            return assignableEmployee.shadowEmployee.name
        }

        return null
    }

    private renderAssignableEmployeeStatus(assignableEmployee: AssignableEmployee) {
        if (assignableEmployee.employee) {
            return <UserStatusDisplay user={assignableEmployee.employee.user} />
        }

        if (assignableEmployee.shadowEmployee) {
            return localize.translate(t => t.User.status.INACTIVE)
        }

        return ''
    }

    private renderRole(assignableEmployee: AssignableEmployee) {
        const role = this.renderAssignableEmployeeRole(assignableEmployee)

        return (
            <Row smallSpacing={true}>
                <span>{role}</span>
            </Row>
        )
    }

    private renderPermissions(assignableEmployee: AssignableEmployee) {
        if (!assignableEmployee.employee) {
            return
        }

        const { permissions } = assignableEmployee.employee
        if (!permissions.length) {
            return
        }

        return <PermissionLabels permissions={permissions} />
    }

    private renderAssignableEmployeeRole(assignableEmployee: AssignableEmployee) {
        if (assignableEmployee.employee) {
            return userClient.getReadableRole(assignableEmployee.employee.role)
        }

        if (assignableEmployee.shadowEmployee) {
            return this.loc(t => t.shadowEmployeeStatus)
        }

        return ''
    }

    private handleExport = (mutate: CreateCustomerUsersExportMutationFN) => async () => {
        const response = await mutate({ variables: { forCustomerId: this.context.customer.id } })

        if (response && response.data?.createCustomerUsersExport) {
            const { token, filename } = response.data.createCustomerUsersExport
            const url = fileService.createFileUrlFromToken(token, filename)
            window.open(url, '_blank')
        }
    }
}
