import './InboxOverviewView.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { localize, permissions } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { Page } from '~/components/Core/Layout/Page'
import { Select, SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { NewsGroupSelect } from '~/components/Domain/News/NewsGroupSelect'
import { InboxItemContainer } from '../../../components/Domain/Inbox/InboxItemList/InboxItemContainer'
import { Column } from '~/components/Core/Layout/Column'
import { InboxAlertFilters } from '../../../components/Domain/Inbox/InboxItemList/InboxItemQuery'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { ParamManager } from '~/components/Core/ParamManager/ParamManager'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { AlertImpactLevel, AlertImpactLevelFilter } from '~/generated/graphql'
import { InboxStatus, InboxStatusSelect } from '~/components/Domain/Inbox/InboxStatusSelect'
import { CustomerGreeting } from '~/components/Domain/Customer/CustomerGreeting'
import { InboxCountQuery } from '~/components/Domain/Inbox/InboxCountQuery'
import { InboxInternalAlertsContainer } from '../../../components/Domain/Inbox/InboxInternalAlertList/InboxInternalAlertsContainer'
import { Guard } from '~/components/Core/Guard/Guard'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    className?: ClassValue
}

interface State {
    filters: InboxAlertFilters
}

interface Filters extends Omit<InboxAlertFilters, 'newsGroupIds'> {
    newsGroupIds?: SelectOption[]
}

export class InboxOverviewView extends React.PureComponent<Props, State> {
    public state: State = {
        filters: {
            hasTasksForDepartment: false,
            isArchivedFromInboxForDepartment: false,
            newsGroupIds: [],
            search: undefined,
            impactLevel: undefined,
        },
    }

    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('InboxOverviewView')
    private loc = localize.namespaceTranslate(t => t.Customer.Inbox.InboxOverview)
    private inboxRef = React.createRef<InboxItemContainer>()

    public render() {
        const { className } = this.props
        const canView = permissions.canAccessInternalAlerts(this.context.activeDepartmentId)

        return (
            <Page className={this.bem.getClassName(className)}>
                <ParamManager<Filters>
                    defaultState={{
                        hasTasksForDepartment: false,
                        isArchivedFromInboxForDepartment: false,
                        newsGroupIds: [],
                        search: undefined,
                    }}
                >
                    {({ paramState, setParamState }) => {
                        const transformedFilters = {
                            ...paramState,
                            newsGroupIds: paramState.newsGroupIds
                                ? paramState.newsGroupIds
                                      .filter(id => {
                                          return !!id.value
                                      })
                                      .map(id => id.value as number)
                                : [],
                        }

                        const showInternalAlerts =
                            canView &&
                            !(paramState.hasTasksForDepartment || paramState.isArchivedFromInboxForDepartment)

                        return (
                            <InboxCountQuery>
                                {count => (
                                    <>
                                        <Column className={this.bem.getElement('page-header')}>
                                            <CustomerGreeting newMessageCount={count} />
                                            {this.renderActions(paramState, setParamState)}
                                        </Column>

                                        <Column className={this.bem.getElement('inbox-container')}>
                                            <Guard condition={showInternalAlerts}>
                                                <InboxInternalAlertsContainer
                                                    refetchInbox={() => this.inboxRef.current?.refetch()}
                                                    groupIds={transformedFilters.newsGroupIds}
                                                    search={paramState.search}
                                                />
                                            </Guard>
                                            <ComponentTitle
                                                title={this.getSubtitle()}
                                                bold={true}
                                                className={this.bem.getElement('title')}
                                            />
                                            <InboxItemContainer ref={this.inboxRef} filters={transformedFilters} />
                                        </Column>
                                    </>
                                )}
                            </InboxCountQuery>
                        )
                    }}
                </ParamManager>
            </Page>
        )
    }

    private getSubtitle(): React.ReactNode {
        const { hasTasksForDepartment, isArchivedFromInboxForDepartment } = this.state.filters

        if (hasTasksForDepartment) {
            return this.loc(t => t.itemsWithTasks)
        }

        if (isArchivedFromInboxForDepartment) {
            return this.loc(t => t.archivedItems)
        }

        return this.loc(t => t.newAlerts)
    }

    private renderActions(paramState: Filters, setParamState: (newState: Filters) => void): JSX.Element | undefined {
        const impactOptions = [
            { label: this.loc(t => t.impactFilter.allImpacts), value: null },
            { label: this.loc(t => t.impactLevel[AlertImpactLevel.high]), value: AlertImpactLevelFilter.high },
            { label: this.loc(t => t.impactLevel[AlertImpactLevel.medium]), value: AlertImpactLevelFilter.medium },
            { label: this.loc(t => t.impactLevel[AlertImpactLevel.low]), value: AlertImpactLevelFilter.low },
        ]

        const getDefaultImpactOption = () => {
            const { impactLevel } = paramState

            if (!impactLevel) {
                return [impactOptions[0]]
            }

            return [impactOptions.find(({ value }) => value === impactLevel)] as SelectOption[]
        }

        return (
            <Row smallSpacing={true}>
                <Search
                    defaultValue={paramState.search}
                    onChange={search =>
                        setParamState({
                            search: search || undefined,
                        })
                    }
                    placeholder={this.loc(t => t.searchInInbox)}
                />
                <FilterDropdown hasActiveFilter={this.isFilterActive(paramState)}>
                    <FilterOption label={localize.translate(t => t.Entities.Source)} forInputName="source">
                        <NewsGroupSelect
                            defaultValue={paramState.newsGroupIds}
                            onChange={option =>
                                setParamState({
                                    newsGroupIds: option ? [option] : [],
                                })
                            }
                            placeholder={this.loc(t => t.allSources)}
                        />
                    </FilterOption>
                    <FilterOption label={localize.translate(t => t.Generic.status)} forInputName="status">
                        <InboxStatusSelect
                            isFilter={true}
                            name={'status'}
                            defaultValue={this.getDefaultValueForState(paramState)}
                            onChange={(option: SelectOption) => setParamState(this.getFiltersForState(option))}
                        />
                    </FilterOption>
                    <FilterOption label={this.loc(t => t.impactFilter.label)} forInputName="impact">
                        <Select
                            isFilter={true}
                            searchable={false}
                            name={'impact'}
                            options={impactOptions}
                            placeholder={this.loc(t => t.impactFilter.allImpacts)}
                            defaultValue={getDefaultImpactOption()}
                            onChange={(option: SelectOption<AlertImpactLevelFilter>) =>
                                setParamState({ impactLevel: option.value })
                            }
                        />
                    </FilterOption>
                </FilterDropdown>
            </Row>
        )
    }

    private getDefaultValueForState(paramState: Filters) {
        if (paramState.isArchivedFromInboxForDepartment) {
            return [{ label: this.loc(t => t.archived), value: InboxStatus.isArchived }]
        }

        if (paramState.hasTasksForDepartment) {
            return [{ label: this.loc(t => t.itemsWithTasks), value: InboxStatus.hasTasks }]
        }

        return [{ label: this.loc(t => t.newItems), value: InboxStatus.new }]
    }

    private getFiltersForState(option?: SelectOption): Filters {
        const newState = {
            isArchivedFromInboxForDepartment: option?.value === InboxStatus.isArchived,
            hasTasksForDepartment: option?.value === InboxStatus.hasTasks,
        }

        this.setState({ filters: { ...this.state.filters, ...newState } })

        return newState
    }

    private isFilterActive(paramState: Filters) {
        if (!paramState.newsGroupIds || paramState.newsGroupIds.length !== 0) {
            return true
        }

        if (this.state.filters.isArchivedFromInboxForDepartment !== false) {
            return true
        }

        if (this.state.filters.hasTasksForDepartment !== false) {
            return true
        }

        if (paramState.impactLevel) {
            return true
        }

        return false
    }
}
