import './TopicLabel.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconLabel } from '~/components/Core/Text/IconLabel'
import { IconType } from '~/components/Core/Icon/IconType'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { matchPath } from 'react-router'

export interface Topic {
    id: number
    name: string
}

interface Props {
    className?: ClassValue
    topic: Topic
    iconOnly?: boolean
}

interface State {}

export class TopicLabel extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('TopicLabel')

    public render() {
        const { className, topic, iconOnly } = this.props

        const link = routes.customer(this.context.customer.slug).legalFramework.topic.view(topic.id)
        const match = matchPath(window.location.pathname, {
            path: link,
        })

        return (
            <IconLabel
                className={this.bem.getClassName(className)}
                iconType={IconType.norm}
                to={match ? undefined : link}
            >
                {iconOnly ? null : topic.name}
            </IconLabel>
        )
    }
}
