import './Cover.scss'

import React from 'react'
import { localize } from '~/bootstrap'

import { BEM } from '~/services/BEMService'
import { Button } from '~/components/Core/Button/Button'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { ErrorProvider } from '~/components/Providers/ErrorProvider'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { Link, NavLink } from 'react-router-dom'
import { Logo } from '~/components/Chrome/Logo/Logo'
import { Mutation, MutationFn } from 'react-apollo'
import { PageTitle } from '~/components/Core/Text/PageTitle'
import { routes } from '~/views/routes'
import gql from 'graphql-tag'

interface State {
    email: string | null
    success: boolean
}

const PASSWORD_FORGOT_MUTATION = gql`
    mutation requestNewPasswordReset($email: String) {
        requestNewPasswordReset(email: $email)
    }
`

export class ForgotPasswordView extends React.PureComponent<{}, State> {
    public state: State = {
        email: null,
        success: false,
    }

    private loc = localize.namespaceTranslate(t => t.Cover.ForgotPassword)
    private bem = new BEM('Cover')

    public render() {
        const { success } = this.state

        return (
            <Mutation<any> mutation={PASSWORD_FORGOT_MUTATION}>
                {(requestNewPassword, { loading, error }) => (
                    <ErrorProvider error={error}>
                        <div className={this.bem.getClassName()}>
                            <div className={this.bem.getElement('brand')}>
                                <NavLink to={routes.cover.index}>
                                    <Logo />
                                </NavLink>
                            </div>

                            <div className={this.bem.getElement('line')} />

                            {success ? (
                                <>
                                    <p className={this.bem.getElement('info')}>{this.loc(t => t.success)}</p>

                                    <Link to={routes.cover.login} className={this.bem.getElement('link')}>
                                        {this.loc(t => t.link)}
                                    </Link>
                                </>
                            ) : (
                                <form onSubmit={this.submitHandler(requestNewPassword)}>
                                    <div className={this.bem.getElement('content')}>
                                        <PageTitle title={this.loc(t => t.title)} />

                                        <Field
                                            forInput={'email'}
                                            compact={true}
                                            label={this.loc(t => t.EmailField.label)}
                                        >
                                            <Input
                                                type="text"
                                                name="email"
                                                placeholder={this.loc(t => t.EmailField.placeholder)}
                                                onChange={email => this.setState({ email })}
                                            />
                                        </Field>

                                        <ErrorMessage
                                            path={'requestNewPasswordReset'}
                                            message={this.loc(t => t.error)}
                                        />
                                    </div>

                                    <Button
                                        submit={true}
                                        className={this.bem.getElement('submit-button')}
                                        loading={loading}
                                        disabled={!this.state.email}
                                    >
                                        {this.loc(t => t.button)}
                                    </Button>

                                    <Link to={routes.cover.login} className={this.bem.getElement('link')}>
                                        {this.loc(t => t.link)}
                                    </Link>
                                </form>
                            )}
                        </div>
                    </ErrorProvider>
                )}
            </Mutation>
        )
    }

    private submitHandler =
        (mutate: MutationFn<{ requestNewPasswordReset: boolean }>) => async (event: React.FormEvent) => {
            event.preventDefault()

            if (!this.state.email) {
                return
            }

            const result = await mutate({ variables: { email: this.state.email } })

            if (result && !result.errors) {
                this.setState({ success: true })
            }
        }
}
