import './RiskGraph.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { RiskGraphBackground } from './RiskGraphBackground'
import { RiskGraphIndicator } from './RiskGraphIndicator'

interface Props {
    className?: ClassValue
    indicators: RiskGraphIndicatorType[]
    loading?: boolean
}

export interface RiskGraphIndicatorType {
    position: RiskGraphIndicatorPosition
    tooltip?: React.ReactNode
    content: () => JSX.Element
    foreground?: boolean
}

export interface RiskGraphIndicatorPosition {
    x: number
    y: number
}

export class RiskGraph extends React.Component<Props> {
    private bem = new BEM('RiskGraph')

    public render() {
        const { className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <RiskGraphBackground />
                <div className={this.bem.getElement('indicators')}>{this.renderIndicators()}</div>
            </div>
        )
    }

    private renderIndicators() {
        const { indicators, loading } = this.props

        if (loading) {
            return
        }

        return indicators.map((indicator, index) => {
            return (
                <RiskGraphIndicator
                    key={index}
                    tooltip={indicator.tooltip}
                    style={this.getIndicatorPosition(indicator.position)}
                    foreground={indicator.foreground}
                >
                    {indicator.content()}
                </RiskGraphIndicator>
            )
        })
    }

    private getIndicatorPosition(position: RiskGraphIndicatorPosition) {
        const left = Math.max(0, Math.min(position.x, 100))
        const top = 100 - Math.max(0, Math.min(position.y, 100))

        const concatinatedLeft = left.toString().concat('%')
        const concatinatedTop = top.toString().concat('%')
        return { left: concatinatedLeft, top: concatinatedTop }
    }
}
