import './MonitoringReportItem.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { Row } from '~/components/Core/Layout/Row'
import { Column } from '~/components/Core/Layout/Column'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'
import { localize, permissions } from '~/bootstrap'
import { MonitoringReportType, ProfileType } from '~/generated/graphql'
import { ActionButton } from '~/components/Core/ActionButton/ActionButton'
import { IconType } from '~/components/Core/Icon/IconType'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import { DepartmentLabelTag } from '../../Department/DepartmentLabelTag/DepartmentLabelTag'

interface Props {
    className?: ClassValue
    monitorReportItem: MonitoringReportItemData
    infoActionIconOnly?: boolean
    checked?: boolean
    handleOnCardCheck?: (checked: boolean) => void
    isFirstItem?: boolean
    isCheckboxActive?: boolean
    refetch: () => void
}

type MonitoringReportItemData = Pick<MonitoringReportType, 'id' | 'assessedAt' | 'name' | 'archivedAt'> & {
    archivedBy?: User | null
    departments?: Department[] | null
}

type User = { user: { profile: Pick<ProfileType, 'fullName' | 'avatar'> } }
type Department = { id: number; name: string }

export class MonitoringReportItem extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.MonitoringOverviewView)
    private bem = new BEM('MonitoringReportItem')

    public render() {
        const { className, handleOnCardCheck, checked, isFirstItem, isCheckboxActive } = this.props
        const { infoActionIconOnly, monitorReportItem } = this.props

        return (
            <Card
                isCheckboxActive={isCheckboxActive}
                isFirstCardInList={isFirstItem}
                className={this.bem.getClassName(className)}
                checked={checked}
                onCheckChange={!infoActionIconOnly ? handleOnCardCheck : undefined}
            >
                {this.renderTitle()}
                <Row noSpacing spaceBetween fullWidth>
                    {!!monitorReportItem.departments?.length && (
                        <Row fullWidth>
                            <DepartmentLabelTag departments={monitorReportItem.departments} />
                        </Row>
                    )}
                    {!!monitorReportItem.archivedAt && this.renderArchivedInfoIcon()}
                </Row>
            </Card>
        )
    }

    private renderTitle() {
        const { name, assessedAt, id } = this.props.monitorReportItem

        return (
            <Column noSpacing className={this.bem.getElement('title-container')}>
                <Row smallSpacing>
                    <Column smallSpacing>
                        <ComponentTitle
                            to={
                                permissions.canViewMonitoringReports()
                                    ? routes.customer(this.context.customer.slug).monitoring.reports.detail.view(id)
                                    : ''
                            }
                            title={name}
                            bold
                            className={this.bem.getElement('title')}
                        />
                        {this.renderDate(assessedAt)}
                    </Column>
                </Row>
            </Column>
        )
    }

    private renderArchivedInfoIcon() {
        const { archivedAt, archivedBy } = this.props.monitorReportItem

        return (
            <Row alignRight fullWidth>
                <ActionButton
                    icon={IconType.info}
                    tertiary
                    tooltipContent={
                        <Row smallSpacing>
                            {archivedBy && (
                                <>
                                    <Paragraph>{this.loc(t => t.archivedBy)}</Paragraph>
                                    <UserProfileIcon
                                        small
                                        fullName={archivedBy?.user.profile.fullName}
                                        uri={archivedBy?.user.profile.avatar}
                                    />
                                </>
                            )}
                            <Paragraph subtle>
                                <DateFormat date={new Date(archivedAt!)} readable includeTime noWeekday />
                            </Paragraph>
                        </Row>
                    }
                />
            </Row>
        )
    }

    private renderDate(date: Date) {
        return (
            <Paragraph subtle>
                <DateFormat date={new Date(date)} readable includeTime={false} noWeekday />
            </Paragraph>
        )
    }
}
