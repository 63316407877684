import gql from 'graphql-tag'
import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { ControlTypeType } from '~/generated/graphql'
import { ControlVisibility } from '~/generated/graphql'

export interface EditControlMutationVariables {
    controlId: number
    fields: {
        name: string
        description: string
        type: ControlTypeType
        visibility?: ControlVisibility
        visibleToDepartmentIds?: number[]
        startDate?: Date | null
        revisionDate?: Date | null
        responsibleEmployeeIds?: number[]
        responsibleShadowEmployeeIds?: number[]
        responsibleEmployees?: string[]
    }
}

export interface EditControlMutationResponse {
    editControl: {
        id: number
        name: string
        description: string
        visibility: ControlVisibility
    }
}

const EDIT_CONTROL = gql`
    mutation editControl($fields: EditControlFieldsType!, $controlId: Int!) {
        editControl(fields: $fields, controlId: $controlId) {
            id
            name
            description
            visibility
        }
    }
`

interface Props {
    children: (
        mutation: MutationFn<EditControlMutationResponse, EditControlMutationVariables>,
        options: MutationResult
    ) => JSX.Element
}

export const EditControlMutation: React.SFC<Props> = ({ children }) => (
    <GQLMutation<EditControlMutationResponse, EditControlMutationVariables> mutation={EDIT_CONTROL}>
        {(mutate, options) => children(mutate, options)}
    </GQLMutation>
)
