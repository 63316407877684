import './TaskStatus.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    amountOfOpenTasks: number
    amountOfCompletedTasks: number
}

interface State {}

export class TaskStatus extends React.PureComponent<Props, State> {
    private bem = new BEM('TaskStatus')
    private loc = localize.namespaceTranslate(t => t.Customer.Task.taskModalView)

    public render() {
        const { className, amountOfOpenTasks, amountOfCompletedTasks } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <ComponentTitle
                    className={this.bem.getElement('open')}
                    title={this.loc(t => t.open, { open: amountOfOpenTasks })}
                />
                <Paragraph className={this.bem.getElement('closed')}>
                    {this.loc(t => t.closed, { closed: amountOfCompletedTasks })}
                </Paragraph>
            </div>
        )
    }
}
