import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { TaskDetailFields } from '~/graphql/types/Task'
import { EditTaskDocument, EditTaskMutationVariables } from '~/generated/graphql'

export interface EditTaskMutationResponse {
    editTask?: TaskDetailFields
}

export type EditTaskMutationFN = MutationFn<EditTaskMutationResponse, EditTaskMutationVariables>
export type EditTaskMutationResult = MutationResult<EditTaskMutationResponse>

interface Props {
    children: (mutationFN: EditTaskMutationFN, mutationResult: EditTaskMutationResult) => JSX.Element
}

export class EditTaskMutation extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<EditTaskMutationResponse, EditTaskMutationVariables> mutation={EditTaskDocument}>
                {children}
            </GQLMutation>
        )
    }
}
