import gql from 'graphql-tag'
import React from 'react'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { ControlVisibleToDepartment } from '../Controls/ControlDetail/ControlQuery'
import { localize } from '~/bootstrap'
import { CustomerConsumer } from '~/components/Providers/CustomerProvider'

interface Props {
    name?: string
    defaultDepartments?: ControlVisibleToDepartment[]
    onChange?: (option: SelectOption | null) => void
    clearable?: boolean
}

const QUERY = gql`
    query listDepartments($take: Int, $skip: Int) {
        listDepartments(take: $take, skip: $skip) {
            totalCount
            totalPages
            hasNextPage
            nodes {
                id
                name
            }
        }
    }
`

export class ControlVisibleToDepartmentsSelect extends React.PureComponent<Props> {
    public static defaultProps: Partial<Props> = {
        clearable: true,
    }

    public render() {
        const { name, onChange, defaultDepartments, clearable } = this.props

        return (
            <CustomerConsumer>
                {context => (
                    <PaginatableQuery<ControlVisibleToDepartment> query={QUERY}>
                        {({ data, loading, loadingMore, fetchMore }) => {
                            if (!data) {
                                return null
                            }

                            const otherDepartments = data.nodes.filter(department => {
                                return department.id !== context?.activeDepartmentId
                            })

                            const options = this.getOptionsFromDepartments(otherDepartments)
                            const defaultOptions =
                                defaultDepartments && this.getOptionsFromDepartments(defaultDepartments)

                            return (
                                <Form.Select
                                    placeholder={localize.translate(
                                        t => t.Customer.Compliance.Controls.EditView.visibleToDepartmentsPlaceholder
                                    )}
                                    name={name || 'visibleToDepartments'}
                                    multi={true}
                                    defaultValue={defaultOptions}
                                    searchable={false}
                                    options={options}
                                    clearable={clearable}
                                    creatable={false}
                                    loading={loading || loadingMore}
                                    onEndReached={fetchMore}
                                    onChange={(option: SelectOption) => {
                                        if (onChange) {
                                            onChange(option)
                                        }
                                    }}
                                    isFilter={false}
                                />
                            )
                        }}
                    </PaginatableQuery>
                )}
            </CustomerConsumer>
        )
    }

    private getOptionsFromDepartments(departments: ControlVisibleToDepartment[]): SelectOption[] {
        return departments.map(dep => ({ label: dep.name, value: dep.id }))
    }
}
