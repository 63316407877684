import './BreadCrumbs.scss'

import * as React from 'react'
import { BEM } from '~/services/BEMService'
import { Link } from 'react-router-dom'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { permissions } from '~/bootstrap'

interface Props {
    className?: string
    breadCrumbs: BreadCrumbType[]
}

export interface BreadCrumbType {
    label: string
    to?: string
}

export class BreadCrumbs extends React.Component<Props> {
    private bem = new BEM('BreadCrumbs')

    public render() {
        const { className, breadCrumbs } = this.props

        return <nav className={this.bem.getClassName(className)}>{breadCrumbs.map(this.renderCrumb)}</nav>
    }

    private renderCrumb = (crumb: BreadCrumbType, index: number, array: BreadCrumbType[]) => {
        return (
            <React.Fragment key={crumb.to || index}>
                {this.renderCrumbContent(crumb, index, array)}

                <Icon className={this.bem.getElement('arrow')} type={IconType.breadcrumb} />
            </React.Fragment>
        )
    }

    private renderCrumbContent = (crumb: BreadCrumbType, index: number, array: BreadCrumbType[]) => {
        const isCustomerPlanner = permissions.isCustomerPlanner()

        // don't use a link even if it has one if the user is a customer planner
        const useLinkComponent = !isCustomerPlanner && !!crumb.to
        const WrapperComponent: any = useLinkComponent ? Link : 'span'
        const classNames = this.bem.getElement('crumb', () => ({ 'is-link': useLinkComponent }))

        if (crumb.label.length > 32) {
            return (
                <Tooltip content={crumb.label}>
                    <WrapperComponent className={classNames} to={crumb.to || ''}>
                        {/* tslint:disable-next-line:jsx-use-translation-function */}
                        {crumb.label.substr(0, 32)}...
                    </WrapperComponent>
                </Tooltip>
            )
        }

        return (
            <WrapperComponent className={classNames} to={crumb.to || ''}>
                {crumb.label}
            </WrapperComponent>
        )
    }
}
