import React from 'react'
import { CreateTaskMutation, CreateTaskMutationFN } from '~/components/Domain/Task/CreateTaskMutation'
import { TaskModal, CreateTaskFormValues } from '~/components/Domain/Task/TaskModal'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { notification, localize } from '~/bootstrap'
import { LinkedTaskItem, getTypenameFromLinkedItemType } from '~/graphql/types/Task'
import { LINKED_TASK_ITEM_STATUS_QUERY } from '~/components/Domain/Task/LinkedTaskItemStatusQuery'
import { EmployeeQuery } from '../Customer/EmployeePageQuery'
import { GET_INBOX_DETAIL_QUERY } from '~/views/Customer/Inbox/InboxDetailContainer'
import { DepartmentType } from '~/generated/graphql'

interface Props {
    requestClose?: () => void
    linkedItem?: LinkedTaskItem
    onCreated?: () => void
    forMonitoring?: boolean
    possibleDepartments?: Pick<DepartmentType, 'id' | 'name'>[]
}

interface State {}

export class CreateTaskModalContainer extends React.Component<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks)

    public render() {
        const { requestClose, linkedItem, forMonitoring, possibleDepartments } = this.props
        const employee = this.context.employee

        if (!employee) {
            return null
        }

        return (
            <EmployeeQuery id={employee.id}>
                {employee => {
                    return (
                        <CreateTaskMutation>
                            {(mutate, { loading }) => {
                                return (
                                    <TaskModal
                                        title={this.loc(t => t.modalTitle)}
                                        submitLoading={loading}
                                        onSubmit={this.handleOnSubmit(mutate)}
                                        errorPath={'createTask'}
                                        requestClose={requestClose}
                                        linkedItem={linkedItem}
                                        defaultSelectedEmployee={employee}
                                        defaultForMonitoring={forMonitoring}
                                        isNew={true}
                                        possibleDepartments={possibleDepartments}
                                    />
                                )
                            }}
                        </CreateTaskMutation>
                    )
                }}
            </EmployeeQuery>
        )
    }

    private handleOnSubmit = (mutate: CreateTaskMutationFN) => async (
        formValues: CreateTaskFormValues,
        closeModal?: () => void
    ) => {
        const { linkedItem, onCreated } = this.props
        const departmentId = this.context.activeDepartmentId
        const customerSlug = this.context.customer.slug

        const { linkedItemId, linkedItemType } = getTypenameFromLinkedItemType(linkedItem)
        const linkedItemStatusQuery = linkedItem
            ? [
                  { query: LINKED_TASK_ITEM_STATUS_QUERY, variables: { departmentId, linkedItemId, linkedItemType } },
                  { query: GET_INBOX_DETAIL_QUERY, variables: { customerSlug, departmentId, alertId: linkedItemId } },
              ]
            : []

        const response = await mutate({
            refetchQueries: [...linkedItemStatusQuery],
            variables: {
                departmentId: this.context.activeDepartmentId,
                fields: { ...formValues, linkedItemId, linkedItemType },
            },
        })

        if (response && response.data && response.data.createTask) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))

            if (onCreated) {
                onCreated()
            }

            if (closeModal) {
                closeModal()
            }
        }
    }
}
