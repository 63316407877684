import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { TaskPriority, NewOrExistingTag, LinkedTaskItemType } from '~/graphql/types/Task'
import { TaskRepeatFrequency, CreateTaskDocument, TaskType } from '~/generated/graphql'

export interface CreateTaskMutationVariables {
    departmentId: number
    fields: {
        name?: string
        description?: string
        dueAt?: Date
        startAt?: Date
        employeeIds: number[]
        priority: TaskPriority
        tags?: NewOrExistingTag[]
        linkedItemId?: number
        linkedItemType?: LinkedTaskItemType
        forMonitoring?: boolean
        repeats?: TaskRepeatFrequency
        taskTemplateId?: number
        subTasks?: SubTaskVariable[]
    }
}

export interface SubTaskVariable {
    name: string
    description?: string
    tagIds?: number[]
}

export interface CreateTaskMutationResponse {
    createTask?: TaskType
}

export type CreateTaskMutationFN = MutationFn<CreateTaskMutationResponse, CreateTaskMutationVariables>
export type CreateTaskMutationResult = MutationResult<CreateTaskMutationResponse>

interface Props {
    children: (mutationFN: CreateTaskMutationFN, mutationResult: CreateTaskMutationResult) => JSX.Element
}

export class CreateTaskMutation extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<CreateTaskMutationResponse, CreateTaskMutationVariables> mutation={CreateTaskDocument}>
                {children}
            </GQLMutation>
        )
    }
}
