import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ArchiveNewsItemsMutation, ArchiveNewsItemsMutationFN } from './ArchiveNewsItemsMutation'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { localize, notification } from '~/bootstrap'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { Bold } from '~/components/Core/Typography/Bold'

interface Props {
    newsId: number
    newsTitle: string
    children: (openModal: () => void) => React.ReactNode
    onArchive?: () => void
}

interface State {
    archivedReason: string | null
}

export class ArchiveNewsItemButtonContainer extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        archivedReason: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Signaling.SignalingOverview.confirmModal)

    public render() {
        const { newsTitle, children } = this.props
        const archiveLabel = localize.translate(t => t.Generic.archive)

        return (
            <ArchiveNewsItemsMutation>
                {(mutate, { loading }) => (
                    <ConfirmModal
                        title={this.loc(t => t.itemArchived)}
                        confirmButtonLabel={archiveLabel}
                        loading={loading}
                        renderContent={() => (
                            <Column>
                                <Paragraph>
                                    {this.loc(t => t.archiveMessagePart1)}
                                    <Bold inline={true}>{newsTitle}</Bold>
                                    {this.loc(t => t.archiveMessagePart2)}
                                </Paragraph>
                                <Field label={this.loc(t => t.fieldTitle)} compact={true}>
                                    <Input
                                        type="textarea"
                                        placeholder={this.loc(t => t.placeholder)}
                                        name="reason"
                                        onChange={archivedReason => this.setState({ archivedReason })}
                                    />
                                </Field>
                            </Column>
                        )}
                        onConfirm={close => this.handleSubmit(close, mutate)}
                    >
                        {openModal => children(openModal)}
                    </ConfirmModal>
                )}
            </ArchiveNewsItemsMutation>
        )
    }

    private async handleSubmit(close: () => void, mutate: ArchiveNewsItemsMutationFN) {
        const { archivedReason } = this.state
        const { newsId, onArchive } = this.props

        const response = await mutate({
            variables: {
                newsIds: [newsId],
                archivedReason,
            },
        })

        if (response && response.data && response.data.archiveCustomerNews) {
            notification.success(localize.translate(t => t.Generic.successfullyArchived))
            close()
            onArchive?.()
        }
    }
}
