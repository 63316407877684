import './TaskFormTemplateField.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Logo } from '~/components/Chrome/Logo/Logo'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { CustomerTaskTemplateType } from '~/generated/graphql'
import { CustomerFrameworkIconList } from '../CustomerFramework/CustomerFrameworkIconList'
import { CustomerTaskTemplatesPaginatableQuery } from './Queries/CustomerTaskTemplatesPaginatableQuery'
import { BEM } from '~/services/BEMService'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'

interface Props {
    onTemplateOptionChange: (taskTemplate?: CustomerTaskTemplateType) => void
}

export interface TaskFormTemplateFieldOptionValue {
    id: number
    customerFrameworkId: number
    type: CustomerTaskTemplateType['__typename']
}

export class TaskFormTemplateField extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('TaskFormTemplateField')

    public render() {
        const placeholder = localize.translate(t => t.Customer.Planning.Tasks.TaskFormFields.templatePlaceholder)
        const label = localize.translate(t => t.Customer.Planning.Tasks.TaskFormFields.templateLabel)

        return (
            <Field forInput="forTemplate" label={label} className={this.bem.getClassName()}>
                <CustomerTaskTemplatesPaginatableQuery>
                    {({ data, loading, loadingMore, fetchMore }) => {
                        const taskTemplates = data ? data.nodes : []
                        const formattedTaskTemplates = this.getFormattedTaskTemplates(taskTemplates)
                        const options = formattedTaskTemplates.map(template => {
                            const customerFramework =
                                'frameworks' in template
                                    ? this.context.profiles.find(p => p.framework.id === template.frameworks[0].id)
                                    : undefined

                            return {
                                label: this.renderLabel(template, customerFramework),
                                value: {
                                    id: template.id,
                                    type: template.__typename,
                                    customerFrameworkId: customerFramework?.id,
                                },
                            }
                        })

                        return (
                            <Form.Select
                                name="forTemplate"
                                placeholder={placeholder}
                                options={options}
                                onChange={this.handleOnChange(taskTemplates)}
                                onEndReached={fetchMore}
                                clearable={true}
                                loading={loading || loadingMore}
                            />
                        )
                    }}
                </CustomerTaskTemplatesPaginatableQuery>
            </Field>
        )
    }

    private getFormattedTaskTemplates(taskTemplates: CustomerTaskTemplateType[]) {
        const formattedData: CustomerTaskTemplateType[] = []
        const activeProfiles = this.context.profiles.filter(p => this.context.activeProfiles.includes(p.id))

        for (const taskTemplate of taskTemplates) {
            if (!('frameworks' in taskTemplate)) {
                formattedData.push(taskTemplate)
                continue
            }

            taskTemplate.frameworks.forEach(f => {
                if (!activeProfiles.some(p => p.framework.id === f.id)) {
                    return
                }

                formattedData.push({ ...taskTemplate, frameworks: [f] })
            })
        }

        return formattedData
    }

    private renderLabel(template: CustomerTaskTemplateType, customerFramework?: CustomerFramework) {
        if (template.__typename === 'TaskTemplateType') {
            return template.name
        }

        const templateType =
            'type' in template && template.type
                ? localize.translate(
                      t => t.Customer.Settings.TaskTemplatesOverviewView.TaskTemplateModal[template.type!]
                  )
                : null

        return (
            <Row spaceBetween={true} className={this.bem.getElement('label')}>
                <Row extraSmallSpacing={true} className={this.bem.getElement('name')}>
                    <Paragraph className={this.bem.getElement('text')}>{template.name}</Paragraph>
                    <Logo tiny={true} iconOnly={true} />
                    <Paragraph subtle={true} className={this.bem.getElement('type')}>
                        {templateType}
                    </Paragraph>
                </Row>
                {this.renderFramework(customerFramework)}
            </Row>
        )
    }

    private handleOnChange =
        (taskTemplates: CustomerTaskTemplateType[]) =>
        (selectedOption: SelectOption<TaskFormTemplateFieldOptionValue>) => {
            const { onTemplateOptionChange } = this.props

            const taskTemplate = selectedOption?.value
                ? taskTemplates.find(
                      t => t.id === selectedOption.value.id && t.__typename === selectedOption.value.type
                  )
                : undefined

            onTemplateOptionChange(taskTemplate)
        }

    private renderFramework(customerFramework?: CustomerFramework) {
        if (!customerFramework) {
            return
        }

        return (
            <Row extraSmallSpacing={true} alignRight={true} className={this.bem.getElement('framework')}>
                <Paragraph subtle={true} className={this.bem.getElement('text')}>
                    {customerFramework.name}
                </Paragraph>
                <CustomerFrameworkIconList customerFrameworks={[customerFramework]} maxVisibleFrameworks={1} />
            </Row>
        )
    }
}
