import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'
import './UserProfilePicker.scss'
import { Icon } from '../../Icon/Icon'
import { IconType } from '../../Icon/IconType'
import { Button, ButtonType } from '../../Button/Button'
import { UserProfilePickerListUser } from './UserProfilePickerList/UserProfilePickerList'
import { UserProfileIcon } from '../../DataDisplay/UserProfileIcon/UserProfileIcon'
import { Paragraph } from '../../Typography/Paragraph'
import { Row } from '../../Layout/Row'
import { Search } from '../Search/Search'
import { localize } from '~/bootstrap'
import gql from 'graphql-tag'
import { PaginatableQuery, PaginationResult } from '../../Pagination/PaginatableQuery'
import { InfiniteScroll } from '../../Pagination/InfiniteScroll'
import { Spinner } from '../../Feedback/Spinner/Spinner'
import { isNode } from '~/utils/isNode'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    className?: ClassValue
    defaultSelectedUsers?: UserProfilePickerListUser[]
    onChange: (user: UserProfilePickerListUser) => void
}

interface State {
    active: boolean
    search?: string | null
}

const LIST_EMPLOYEES = gql`
    query employees($customerSlug: String, $skip: Int, $take: Int, $search: String, $departmentId: Int) {
        employees(customerSlug: $customerSlug, skip: $skip, take: $take, search: $search, departmentId: $departmentId) {
            hasNextPage
            nodes {
                id
                user {
                    id
                    email
                    profile {
                        id
                        fullName
                        avatar
                        phoneNumber
                    }
                }
            }
        }
    }
`

interface EmployeeResponse {
    id: number
    user: {
        id: number
        email: string
        profile: {
            id: number
            fullName: string
            avatar: string | null
            phoneNumber: string
        }
    }
}

export class UserProfilePicker extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        active: false,
        search: null,
    }

    private bem = new BEM('UserProfilePicker')
    private containerRef = React.createRef<HTMLDivElement>()
    private loc = localize.namespaceTranslate(t => t.Customer.Task.userProfile)

    public componentDidMount() {
        document.addEventListener('click', this.handleClick)
    }

    public componentWillUnmount() {
        document.removeEventListener('click', this.handleClick)
    }

    public render() {
        const { className } = this.props
        const { active, search } = this.state

        return (
            <PaginatableQuery<EmployeeResponse>
                query={LIST_EMPLOYEES}
                variables={{ search, departmentId: this.context.activeDepartmentId }}
            >
                {({ data, canFetchMore, fetchMore, loading, loadingMore }) => {
                    if (!data) {
                        return null
                    }

                    const transformedUserData = this.transformData(data)
                    const remainingUsers = this.getRemainingUsers(transformedUserData)

                    const noUsers = remainingUsers.length === 0 || data.nodes.length === 0

                    return (
                        <div className={this.bem.getClassName(className)} ref={this.containerRef}>
                            {this.renderProfilePicker()}
                            {active && (
                                <div className={this.bem.getElement('picker')}>
                                    <Search
                                        onChange={search => this.setState({ search })}
                                        placeholder={this.loc(t => t.searchUser)}
                                        icon={null}
                                    />
                                    <ul className={this.bem.getElement('user-container')}>
                                        {loading && <Spinner delayed={true} />}
                                        <InfiniteScroll
                                            onReachedEnd={() => {
                                                fetchMore()
                                            }}
                                            hasMore={canFetchMore}
                                            useWindow={false}
                                        >
                                            {this.renderUsers(remainingUsers)}

                                            {loadingMore && <Spinner delayed={true} />}

                                            {this.renderEmptyState(noUsers)}
                                        </InfiniteScroll>
                                    </ul>
                                </div>
                            )}
                        </div>
                    )
                }}
            </PaginatableQuery>
        )
    }

    private handleClick = (e: MouseEvent) => {
        if (!isNode(e.target)) {
            // event target is not a node
            return
        }

        if (this.containerRef.current && this.containerRef.current.contains(e.target)) {
            // inside click
            return
        }

        // outside click
        this.setState({ active: false, search: null })
    }

    private renderEmptyState(noUsers: boolean): React.ReactNode {
        return (
            noUsers && (
                <div className={this.bem.getElement('empty')}>
                    <Paragraph subtle={true}>{this.loc(t => t.noMoreUsers)}</Paragraph>
                </div>
            )
        )
    }

    private transformData(data: PaginationResult<EmployeeResponse>) {
        return data.nodes.map(employee => ({
            id: employee.id,
            fullName: employee.user.profile.fullName,
            email: employee.user.email,
            uri: employee.user.profile.avatar,
            phone: employee.user.profile.phoneNumber,
        }))
    }

    private renderUsers(remainingUsers: UserProfilePickerListUser[]): React.ReactNode {
        const { onChange } = this.props

        return remainingUsers.map(user => {
            return (
                <li key={user.id} className={this.bem.getElement('list')}>
                    <Button
                        type={ButtonType.noStyling}
                        onClick={() => onChange(user)}
                        className={this.bem.getElement('button')}
                    >
                        <Row>
                            <UserProfileIcon
                                className={this.bem.getElement('user-profile')}
                                fullName={user.fullName}
                                disabled={true}
                                small={true}
                                email={user.email}
                            />
                            <Paragraph>{user.fullName}</Paragraph>
                        </Row>
                    </Button>
                </li>
            )
        })
    }

    private renderProfilePicker() {
        return (
            <Button
                type={ButtonType.noStyling}
                className={this.bem.getElement('button')}
                onClick={() => this.setState(prevState => ({ active: !prevState.active }))}
            >
                <div className={this.bem.getElement('avatar-container')}>
                    <Icon type={IconType.add} />
                </div>
            </Button>
        )
    }

    private getRemainingUsers(users: UserProfilePickerListUser[]) {
        const { defaultSelectedUsers } = this.props
        const defaultSelectedUserIds = this.getDefaultSelectedUserIds(defaultSelectedUsers)

        return users.filter(user => defaultSelectedUserIds && !defaultSelectedUserIds.includes(user.id))
    }

    private getDefaultSelectedUserIds(defaultSelectedUsers: UserProfilePickerListUser[] | undefined) {
        return defaultSelectedUsers && defaultSelectedUsers.map(user => user.id)
    }
}
