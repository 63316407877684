import './MonitoringReportsCreateFormModal.scss'
import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Row } from '~/components/Core/Layout/Row'
import { BEM } from '~/services/BEMService'
import { MonitoringReportTopicSelectionTableContainer } from './MonitoringReportTopicSelection/MonitoringReportTopicSelectionTableContainer'
import { Column } from '~/components/Core/Layout/Column'
import uniq from 'lodash/uniq'

interface Props {
    onRequestClose?: () => void
    onSubmit: (state: FormState, checkedDepartmentTopics: CheckedMonitoringReportTopics[]) => void
    loading: boolean
}

export interface CheckedMonitoringReportTopics {
    departmentId: number
    topicIds: number[]
}

export class MonitoringReportsCreateFormModal extends React.Component<Props> {
    private tableRef = React.createRef<MonitoringReportTopicSelectionTableContainer>()
    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.AddMonitoringRapportModal)
    private bem = new BEM('MonitoringReportsCreateFormModal')
    private formFields = {
        name: 'name',
        file: 'file',
        assessedAt: 'assessedAt',
    }

    public render() {
        const { onRequestClose, loading } = this.props

        return (
            <Form onSubmit={this.handleOnSubmit} className={this.bem.getClassName()}>
                <Modal
                    preventCloseOnESC
                    title={this.loc(t => t.title)}
                    loading={loading}
                    requestClose={onRequestClose}
                    actions={closeModal => (
                        <Row alignRight={true}>
                            <Button type={ButtonType.tertiary} disabled={loading} onClick={closeModal}>
                                {localize.translate(t => t.Generic.cancel)}
                            </Button>
                            <Button loading={loading} submit={true}>
                                {localize.translate(t => t.Generic.save)}
                            </Button>
                        </Row>
                    )}
                >
                    <Column className={this.bem.getElement('fields-container')}>
                        <Field
                            forInput={this.formFields.name}
                            label={localize.translate(t => t.Generic.title)}
                            compact={true}
                        >
                            <Form.Input name={this.formFields.name} placeholder={this.loc(t => t.placeholderTitle)} />
                        </Field>
                        <Row flexStart fullWidth>
                            <Field
                                className={this.bem.getElement('file-field')}
                                forInput={this.formFields.file}
                                label={localize.translate(t => t.Entities.File)}
                                compact={true}
                            >
                                <Form.FileInput
                                    name={this.formFields.file}
                                    placeholder={this.loc(t => t.fileUploadText)}
                                />
                            </Field>
                            <Field
                                compact
                                className={this.bem.getElement('date-field')}
                                forInput={this.formFields.assessedAt}
                                label={this.loc(t => t.assessedAt)}
                            >
                                <Form.DatePicker name={this.formFields.assessedAt} defaultValue={new Date()} />
                            </Field>
                        </Row>
                    </Column>
                    <MonitoringReportTopicSelectionTableContainer
                        errorPath="createMonitoringReport"
                        ref={this.tableRef}
                    />
                </Modal>
            </Form>
        )
    }

    private handleOnSubmit = (state: FormState) => {
        const { onSubmit } = this.props
        const checkedDepartmentTopics = this.getFormattedCheckedDepartmentTopics()

        onSubmit(state, checkedDepartmentTopics)
    }

    private getFormattedCheckedDepartmentTopics(): CheckedMonitoringReportTopics[] {
        const checkedDepartmentTopics = this.tableRef.current?.state.checkedDepartmentTopics
        if (!checkedDepartmentTopics) {
            return []
        }

        return Object.keys(checkedDepartmentTopics)
            .map(departmentId => ({
                departmentId: parseInt(departmentId, 10),
                topicIds: uniq(
                    Object.values(checkedDepartmentTopics[departmentId])
                        .filter((topicIds?: number[]) => !!topicIds?.length)
                        .flat() as number[]
                ),
            }))
            .filter(d => d.topicIds.length)
    }
}
