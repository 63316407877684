import Query from 'react-apollo/Query'
import React from 'react'
import gql from 'graphql-tag'
import { localize, notification, permissions } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ControlTypeType, RiskStatus } from '~/generated/graphql'
import { ClassValue } from '~/services/BEMService'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { SeverityLevel } from '../RiskIndicator'
import { SelectedControl } from '../AddControlToRiskSidebar'
import { EditRiskModalContainer } from './EditRiskModalContainer'
import { Guard } from '~/components/Core/Guard/Guard'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { DeleteRiskModalContainer } from '../DeleteRiskModal/DeleteRiskModalContainer'
import { MutationFn } from 'react-apollo'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { GQLMutation } from '~/graphql/Mutation'
import { isNil, isNumber, isString } from 'lodash-es'
import { RiskValueKey } from './EditRiskValueField'
import { ASSESSMENT_SECTIONS_QUERY } from '../../Assessments/AssessmentSections/AssessmentSectionsInfiniteScrollQuery'

interface QueryRiskVariables {
    id: number
    departmentId: number
}

interface QueryRiskResponse {
    risk: Risk
    customer?: {
        id: number
        settings?: {
            compliance?: {
                riskGraph?: RiskGraphSettings
            }
        }
    }
}

const GET_RISK_AND_RISK_SETTINGS = gql`
    query riskWithSettings($id: Int!, $departmentId: Int!) {
        risk(id: $id) {
            __typename
            id
            name
            description
            createdAt
            brutoImpact
            brutoProbability
            nettoImpact
            nettoProbability
            isAccepted
            severity
            assessment {
                id
                useDistinctBrutoRiskValues
            }
            types {
                id
                name
            }
            linkedControls(departmentId: $departmentId) {
                id
                control {
                    id
                    name
                    type
                    revisionDate
                }
                description
            }
        }

        customer {
            id
            settings {
                compliance {
                    riskGraph {
                        lowImpactDescription
                        highImpactDescription
                        lowPropbabiltyDescription
                        highPropbabiltyDescription
                    }
                }
            }
        }
    }
`

const EDIT_RISK = gql`
    mutation editRisk($riskId: Int!, $departmentId: Int!, $fields: EditRiskFieldsInputType!) {
        editRisk(riskId: $riskId, fields: $fields) {
            __typename
            id
            name
            description
            createdAt
            brutoImpact
            brutoProbability
            nettoImpact
            nettoProbability
            isAccepted
            severity
            assessment {
                id
                useDistinctBrutoRiskValues
            }
            types {
                id
                name
            }
            linkedControls(departmentId: $departmentId) {
                id
                control {
                    id
                    name
                    type
                    revisionDate
                }
                description
            }
        }
    }
`

interface EditRiskResponse {
    editRisk: {
        id: number
        name: string
    }
}

interface EditRiskVariables {
    riskId: number
    departmentId: number
    fields: {
        name?: string | null
        description?: string | null
        brutoProbability?: number | null
        brutoImpact?: number | null
        nettoProbability?: number | null
        nettoImpact?: number | null
        riskTypes?: RiskTypeInput[] | null
        isAccepted?: boolean
        linkedControls?: SelectedControl[] | undefined
    }
}

interface RiskTypeInput {
    typeId?: number
    newTypeName: string | null
}

interface LinkedControlInput {
    controlId: number
    description?: string | null
}

export interface RiskGraphSettings {
    highImpactDescription?: string
    lowImpactDescription?: string
    highPropbabiltyDescription?: string
    lowPropbabiltyDescription?: string
}

export interface Risk {
    __typename: 'RiskType'
    risk: string
    id: number
    name: string
    description: string
    isAccepted: boolean
    types: RiskType[] | null
    brutoImpact: number | null
    brutoProbability: number | null
    nettoImpact: number
    nettoProbability: number
    linkedControls: LinkedControl[]
    severity: SeverityLevel
    assessment?: {
        useDistinctBrutoRiskValues: boolean
    }
}

export interface RiskType {
    id: number
    name: string
}

export interface LinkedControl {
    id: number
    control: {
        id: number
        name: string
        type: ControlTypeType
        revisionDate?: string | null
    }
    description: string | null
}

export interface RiskValues {
    brutoProbability?: number | null
    brutoImpact?: number | null
    nettoProbability?: number | null
    nettoImpact?: number | null
}

interface Props {
    className?: ClassValue
    requestClose: () => void
    riskId: number
    assessmentId: number
    assessmentSectionId: number
    onChangeRisk?: () => void
    isNewRisk?: boolean
    showDistinctBrutoRiskValues: boolean
    isForBruto?: boolean
}

interface State {
    isRiskAccepted?: boolean
    riskValues?: RiskValues
}

interface FormState {
    isRiskAccepted?: boolean
    selectedControls?: SelectedControl[]
    riskValues: RiskValues
    name?: string | null
    description?: string | null
    riskTypes?: SelectOption[]
}

export class EditRiskModal extends React.Component<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {
        isRiskAccepted: undefined,
        riskValues: undefined,
    }

    private initialStateSet: boolean = false
    private initialRiskStateSet: boolean = false

    private riskGraphSettings: RiskGraphSettings = {}
    private formState: FormState = {
        name: undefined,
        description: undefined,
        riskTypes: undefined,
        riskValues: {
            brutoProbability: undefined,
            brutoImpact: undefined,
            nettoProbability: undefined,
            nettoImpact: undefined,
        },
    }

    public render() {
        const {
            riskId,
            assessmentId,
            requestClose,
            isNewRisk,
            assessmentSectionId,
            showDistinctBrutoRiskValues,
            isForBruto,
        } = this.props

        return (
            <Query<QueryRiskResponse, QueryRiskVariables>
                query={GET_RISK_AND_RISK_SETTINGS}
                variables={{ id: riskId, departmentId: this.context.activeDepartmentId }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    if (!data || !data.risk || !data.customer) {
                        return (
                            <NoResults
                                label={localize.translate(
                                    t => t.Customer.Compliance.Risks.editRisk.riskNotFoundEmptyState
                                )}
                            />
                        )
                    }

                    const { risk } = data
                    this.setInitialFormState(risk)
                    this.setRiskGraphSettingsFromQueryResponse(data)

                    return (
                        <GQLMutation<EditRiskResponse, EditRiskVariables>
                            mutation={EDIT_RISK}
                            refetchQueries={[
                                {
                                    query: ASSESSMENT_SECTIONS_QUERY,
                                    variables: {
                                        assessmentId,
                                        departmentId: this.context.activeDepartmentId,
                                    },
                                },
                            ]}
                        >
                            {(mutate, { loading: mutationLoading }) => (
                                <EditRiskModalContainer
                                    requestClose={requestClose}
                                    risk={risk}
                                    riskGraphSettings={this.riskGraphSettings}
                                    isNewRisk={isNewRisk}
                                    assessmentId={assessmentId}
                                    assessmentSectionId={assessmentSectionId}
                                    onChangeIsRiskAccepted={this.onChangeIsRiskAccepted}
                                    onChangeSelectedControls={this.onChangeSelectedControls}
                                    onRiskTypeChange={this.onRiskTypeChange}
                                    onRiskValueChange={this.onRiskValueChange}
                                    onGeneralFieldInputChange={this.onGeneralFieldInputChange}
                                    actions={() => this.renderModalActions(risk, mutationLoading, mutate)}
                                    isRiskAccepted={this.getIsRiskAccepted(risk)}
                                    defaultRiskValues={this.getDefaultRiskBrutoNettoValues(risk)}
                                    currentRiskValues={this.formState.riskValues}
                                    riskStatus={this.getRiskStatus(risk)}
                                    showDistinctBrutoRiskValues={showDistinctBrutoRiskValues}
                                    isForBruto={isForBruto}
                                />
                            )}
                        </GQLMutation>
                    )
                }}
            </Query>
        )
    }

    private setInitialFormState(risk: Risk) {
        if (!this.initialStateSet) {
            return
        }
        this.initialStateSet = true

        this.formState = {
            name: risk.name,
            description: risk.description,
            riskTypes: risk.types ? risk.types.map(({ id, name }) => ({ label: name, value: id })) : undefined,
            riskValues: this.state.riskValues || this.getDefaultRiskBrutoNettoValues(risk),
            isRiskAccepted: this.getIsRiskAccepted(risk),
            selectedControls: this.getDefaultSelectedControls(risk),
        }

        this.state.isRiskAccepted = this.formState.isRiskAccepted
    }

    private setRiskGraphSettingsFromQueryResponse(riskQueryResponse: QueryRiskResponse) {
        if (this.initialRiskStateSet) {
            return
        }

        this.initialRiskStateSet = true

        const defaultRiskGraphSettings = {
            highImpactDescription: 'Het risico heeft bij het plaatsvinden een grote impact op de organisatie.',
            lowImpactDescription: 'Het risico heeft bij het plaatsvinden weinig impact op de organisatie.',
            highPropbabiltyDescription: 'Het risico heeft aannemelijk een hoge kans van plaatsvinden.',
            lowPropbabiltyDescription: 'Het risico heeft aannemelijk een lage kans van plaatsvinden.',
        }

        this.riskGraphSettings =
            riskQueryResponse?.customer?.settings?.compliance?.riskGraph || defaultRiskGraphSettings
    }

    private onChangeIsRiskAccepted = (isRiskAccepted: boolean) => {
        this.formState.isRiskAccepted = isRiskAccepted
        this.setState({ isRiskAccepted: this.formState.isRiskAccepted })
    }

    private onChangeSelectedControls = (selectedControls: SelectedControl[]) => {
        this.formState.selectedControls = selectedControls
    }

    private onRiskTypeChange = (options: SelectOption[]) => {
        this.formState.riskTypes = !isNil(options) ? options : options
    }

    private onRiskValueChange = (key: RiskValueKey, value: number) => {
        this.formState.riskValues = { ...this.formState.riskValues, [key]: value }
        this.setState({ riskValues: this.formState.riskValues })
    }

    private onGeneralFieldInputChange = (value: string | null, name: string) => {
        switch (name) {
            case 'name':
                this.formState.name = value
                return
            case 'description':
                this.formState.description = value
                return
            default:
                return
        }
    }

    private renderModalActions(
        risk: Risk,
        loading: boolean,
        mutate: MutationFn<EditRiskResponse, EditRiskVariables>,
        setActiveIndex?: (index: number) => void
    ) {
        const { assessmentId, requestClose } = this.props
        const cancelButtonAction = setActiveIndex ? () => setActiveIndex(0) : requestClose

        return (
            <Guard condition={!!permissions.canEditAssessment(this.context.activeDepartmentId)}>
                <Row spaceBetween={true}>
                    <DeleteRiskModalContainer
                        risk={risk}
                        assessmentId={assessmentId}
                        onDeleteRisk={() => requestClose()}
                    />
                    <Row smallSpacing={true}>
                        <Button type={ButtonType.tertiary} onClick={cancelButtonAction} disabled={loading}>
                            {localize.translate(t => t.Generic.cancel)}
                        </Button>
                        <Button onClick={() => this.handleSubmit(mutate, risk)} loading={loading}>
                            {localize.translate(t => t.Generic.save)}
                        </Button>
                    </Row>
                </Row>
            </Guard>
        )
    }

    private handleSubmit = async (mutate: MutationFn<EditRiskResponse, EditRiskVariables>, defaultRisk: Risk) => {
        const { riskId, requestClose, onChangeRisk } = this.props
        const { name, description, riskTypes } = this.formState
        const { riskValues } = this.state

        const trimmedName = !isNil(name) ? name.trim() : name
        const trimmedDescription = !isNil(description) ? description.trim() : description

        const newRiskTypes: RiskTypeInput[] = []
        riskTypes?.forEach(({ label, value, __isNew__ }) => {
            if (__isNew__ && isString(label)) {
                newRiskTypes.push({ newTypeName: label })
            }

            if (isNumber(value)) {
                newRiskTypes.push({ typeId: value, newTypeName: null })
            }
        })

        const response = await mutate({
            variables: {
                riskId,
                departmentId: this.context.activeDepartmentId,
                fields: {
                    name: trimmedName,
                    brutoProbability: riskValues?.brutoProbability,
                    brutoImpact: riskValues?.brutoImpact,
                    nettoProbability: riskValues?.nettoProbability,
                    nettoImpact: riskValues?.nettoImpact,
                    description: trimmedDescription,
                    isAccepted: this.getIsRiskAccepted(defaultRisk),
                    linkedControls: this.getLinkedControlInputs(defaultRisk),
                    riskTypes: !isNil(riskTypes) ? newRiskTypes : riskTypes,
                },
            },
        })

        if (response && response.data && response.data.editRisk) {
            if (onChangeRisk) {
                onChangeRisk()
            }
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            requestClose()
        }
    }

    private getLinkedControlInputs(defaultRisk: Risk): LinkedControlInput[] {
        const isRiskAccepted = this.getIsRiskAccepted(defaultRisk)

        if (isRiskAccepted) {
            return []
        }

        let selectedControls: SelectedControl[]

        if (this.formState.selectedControls !== undefined) {
            selectedControls = this.formState.selectedControls
        } else {
            selectedControls = defaultRisk.linkedControls
                ? defaultRisk.linkedControls.map(linkedControl => ({
                      controlId: linkedControl.control.id,
                      description: linkedControl.description,
                  }))
                : []
        }

        return selectedControls.map(selectedControl => ({
            controlId: selectedControl.controlId,
            description: selectedControl.description,
        }))
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private getRiskStatus(risk: Risk) {
        const { showDistinctBrutoRiskValues } = this.props
        const { riskValues, isRiskAccepted, selectedControls } = this.formState

        const currentRiskValues = riskValues ? riskValues : this.getDefaultRiskBrutoNettoValues(risk)
        const hasAcceptedRisk = isRiskAccepted ? isRiskAccepted : risk.isAccepted
        const hasNetto = isNumber(currentRiskValues.nettoImpact) && isNumber(currentRiskValues.nettoProbability)
        const hasBruto = isNumber(currentRiskValues.brutoImpact) && isNumber(currentRiskValues.brutoProbability)

        if (!showDistinctBrutoRiskValues) {
            if (!hasNetto) {
                return RiskStatus.severityNotSet
            }
        } else {
            if (!hasBruto) {
                if (hasNetto) {
                    return RiskStatus.brutoSeverityNotSet
                } else {
                    return RiskStatus.severityNotSet
                }
            }
        }

        const currentSelectedControls = selectedControls ? selectedControls : this.getDefaultSelectedControls(risk)
        const hasControls = currentSelectedControls.length > 0

        if (hasNetto) {
            if (!hasControls && !hasAcceptedRisk) {
                return RiskStatus.noControlsWhileNettoSeverityIsSet
            }
        } else {
            if (hasControls) {
                return RiskStatus.noNettoSeverityWhileControlsAreSet
            }
        }

        return null
    }

    private getDefaultRiskBrutoNettoValues(risk: Risk): RiskValues {
        return {
            brutoProbability: isNumber(risk.brutoProbability) ? risk.brutoProbability : undefined,
            brutoImpact: isNumber(risk.brutoImpact) ? risk.brutoImpact : undefined,
            nettoProbability: isNumber(risk.nettoProbability) ? risk.nettoProbability : undefined,
            nettoImpact: isNumber(risk.nettoImpact) ? risk.nettoImpact : undefined,
        }
    }

    private getDefaultSelectedControls(risk: Risk): SelectedControl[] {
        if (!risk.linkedControls) {
            return []
        }

        return risk.linkedControls.map(linkedControl => ({
            controlId: linkedControl.control.id,
            description: linkedControl.description,
        }))
    }

    private getIsRiskAccepted(defaultRisk: Risk): boolean {
        if (this.formState.isRiskAccepted !== undefined) {
            return this.formState.isRiskAccepted
        }

        return defaultRisk.isAccepted
    }
}
