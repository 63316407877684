import gql from 'graphql-tag'
import { CustomerFrameworkFieldsFragment } from '~/generated/graphql'

export const CustomerFrameworkFragment = gql`
    fragment CustomerFrameworkFields on CustomerFrameworkType {
        id
        name
        icon
        color
    }
`

export type CustomerFramework = CustomerFrameworkFieldsFragment
