import './AiSummaryVoteButton.scss'

import React from 'react'
import { BEM } from '~/services/BEMService'
import { Button } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { Icon } from '~/components/Core/Icon/Icon'
import { GeneratedSummaryUserVoteEnum } from '~/generated/graphql'

interface Props {
    onClick: () => void
    loading: boolean
    type: GeneratedSummaryUserVoteEnum
    active?: boolean
    disabled?: boolean
}

export class AiSummaryVoteButton extends React.PureComponent<Props> {
    private bem = new BEM('AiSummaryVoteButton', () => ({
        [`${this.props.type}`]: !!this.props.type,
        active: this.props.active,
        disabled: this.props.disabled,
    }))

    public render() {
        const { onClick, loading, active, disabled } = this.props

        return (
            <div onClick={!disabled ? onClick : undefined} className={this.bem.getClassName()}>
                <Button
                    className={this.bem.getElement('button')}
                    loading={loading}
                    icon={IconType.thumbsUp}
                    disabled={disabled}
                />
                {active && <Icon type={IconType.close} className={this.bem.getElement('icon')} />}
            </div>
        )
    }
}
