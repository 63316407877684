import './ConsultationItemList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

interface State {}

export class ConsultationItemList extends React.PureComponent<Props, State> {
    private bem = new BEM('ConsultationItemList', () => ({
        'has-children': !!React.Children.count(this.props.children),
    }))

    public render() {
        const { children, className } = this.props

        return (
            <ul className={this.bem.getClassName(className)}>
                {children}
            </ul>
        )
    }
}
