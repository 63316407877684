import './ExpandableNewsGroupSourcesList.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { NewsSourcesQuery } from '~/views/Customer/Settings/NewsSources/NewsSourcesQuery'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { NewsSourcesListItem } from './NewsSourcesItemList/NewsSourcesListItem'
import { ExpandableNewsGroupSourcesListHeader } from '~/components/Domain/Settings/NewsSources/ExpandableNewsGroupSourcesListHeader'
import { Column } from '~/components/Core/Layout/Column'

interface Props {}

interface State {}

export class ExpandableNewsGroupSourcesList extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('ExpandableNewsGroupSourcesList')
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.NewsSourcesOverview)
    private headers = [
        { text: this.loc(t => t.source), className: '' },
        { text: this.loc(t => t.enabled), className: this.bem.getElement('table-header-right') },
    ]

    public render() {
        return (
            <NewsSourcesQuery>
                {({ data, loading, loadingMore }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    const newsGroups = data ? data.nodes : []

                    return (
                        <Column>
                            <ExpandableNewsGroupSourcesListHeader
                                headers={this.headers}
                                isTwoGrid={true}
                                gridClassName={this.bem.getElement('table-header-grid')}
                            />
                            {newsGroups.map(newsGroup => (
                                <NewsSourcesListItem key={`list-item-${newsGroup.id}`} newsGroup={newsGroup} />
                            ))}
                            {loadingMore && <Spinner />}
                        </Column>
                    )
                }}
            </NewsSourcesQuery>
        )
    }
}
