import React from 'react'
import { RouteComponentProps } from 'react-router'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { localize } from '~/bootstrap'
import { PageDetail } from '~/components/Core/Layout/PageDetail/PageDetail'
import { PageDetailContent } from '~/components/Core/Layout/PageDetail/PageDetailContent'
import { Markdown } from '~/components/Core/Text/Markdown'
import { Column } from '~/components/Core/Layout/Column'
import { Alert } from '~/views/Customer/Inbox/InboxDetailContainer'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { breadcrumbs } from '~/views/breadcrumbs'
import { InboxDetailMeta } from '~/components/Domain/Inbox/InboxDetailMeta'
import { SidebarTaskWidgetContainer } from '~/components/Domain/Task/SidebarTaskWidget/SidebarTaskWidgetContainer'
import { InboxDetailActionButtons } from '~/components/Domain/Inbox/InboxDetailActionButtons'
import { InboxLinkedItems } from '~/components/Domain/Inbox/InboxLinkedItems'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Page } from '~/components/Core/Layout/Page'
import { AlertsPublishedFromSameSignalingNews } from '~/components/Domain/Alerts/AlertsPublishedFromSameSignalingNews'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {
    alert: Alert
    refetch: () => void
    onViewed?: () => void
}

export class InboxDetailView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Inbox.InboxOverview)

    public componentDidMount() {
        if (this.props.onViewed) {
            this.props.onViewed()
        }
    }

    public render() {
        const { alert } = this.props
        const isArchived = alert.inboxStatus ? !!alert.inboxStatus.archivedAt : false

        return (
            <Page>
                <PageHeader
                    title={alert.name}
                    subtle={isArchived}
                    actionButtons={<InboxDetailActionButtons alert={alert} />}
                    breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).inbox.index]}
                />
                <PageDetail smallBottomSpacing center={false} renderSidebar={this.renderSideBar}>
                    <Column extraBigSpacing>
                        <InboxDetailMeta alert={alert} />
                        <PageDetailContent>
                            <Markdown source={alert.abstract} />
                        </PageDetailContent>
                    </Column>
                </PageDetail>
            </Page>
        )
    }

    private renderSideBar = () => {
        const { alert, refetch } = this.props

        const isArchived = alert.inboxStatus ? !!alert.inboxStatus.archivedAt : false

        return (
            <Column bigSpacing={true}>
                <SidebarTaskWidgetContainer
                    linkedItem={alert}
                    refetch={() => {
                        refetch()
                    }}
                />
                {alert.publishedFromCustomerNews?.id && <AlertsPublishedFromSameSignalingNews alertId={alert.id} />}
                <InboxLinkedItems alert={alert} />
                {isArchived && (
                    <>
                        <Column smallSpacing={true}>
                            {alert.inboxStatus!.archivedBy && (
                                <Row smallSpacing={true}>
                                    <Paragraph>
                                        {this.loc(t => t.archivedBy, {
                                            fullName: alert.inboxStatus!.archivedBy!.user.profile.fullName,
                                        })}
                                    </Paragraph>
                                    <UserProfileIcon
                                        small={true}
                                        fullName={alert.inboxStatus!.archivedBy!.user.profile.fullName}
                                        uri={alert.inboxStatus!.archivedBy!.user.profile.avatar}
                                        showTooltip={true}
                                    />
                                </Row>
                            )}
                            <Paragraph>
                                {alert.inboxStatus!.archivedReason
                                    ? alert.inboxStatus?.archivedReason
                                    : this.loc(t => t.noArchivedReason)}
                            </Paragraph>
                            <Paragraph subtle={true}>
                                <DateFormat
                                    date={new Date(alert.inboxStatus!.archivedAt)}
                                    readable={true}
                                    includeTime={true}
                                    noWeekday={true}
                                />
                            </Paragraph>
                        </Column>
                    </>
                )}
            </Column>
        )
    }
}
