import './TaskNameWithCompleteButton.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { TaskCompleteButtonContainer } from './TaskCompleteButtonContainer'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ViewTaskModalContainer } from './ViewTaskModalContainer'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { localize, permissions } from '~/bootstrap'
import { TaskDetailFields } from '~/graphql/types/Task'
import { Task } from '~/views/Customer/Tasks/TasksQuery/TaskQuery'
import { Task as TaskWithContext } from './TasksByContext/ContextOpenTasksTableQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Row } from '~/components/Core/Layout/Row'
import { CappedProfileList } from '~/components/Core/DataDisplay/CappedUserList/CappedProfileList'
import { TaskTags } from './TaskTags/TaskTags'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    isReviewSubTask?: boolean
    task: Task | TaskDetailFields | TaskWithContext
    onChange?: () => void
    to?: string
    className?: ClassValue
    hideMetadata?: boolean
    showDepartment?: boolean
}

export class TaskNameWithCompleteButton extends React.Component<Props, {}> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('TaskNameWithCompleteButton', () => ({
        'is-completed': !!this.props.task.completedAt,
        'is-review-subtask': !!this.props.isReviewSubTask,
    }))

    private isModalDisabled = !permissions.canViewTasks()

    private isActionDisabled =
        !this.props.task.department?.id ||
        !permissions.canEditTasks(this.props.task.department.id, this.props.task.employees?.map(e => e.id) || [])

    public render() {
        const { isReviewSubTask } = this.props

        if (isReviewSubTask) {
            return this.renderReviewSubTask()
        }

        return this.renderTask()
    }

    private renderTask() {
        const { task, onChange, className } = this.props
        const isSubTask = 'isSubTask' in task && task.isSubTask

        return (
            <div className={this.bem.getClassName(className)}>
                <Row spaceBetween={true} fullWidth={true}>
                    <Column noSpacing={true}>
                        {isSubTask && this.renderSubTaskLabel()}
                        <Row smallSpacing={true} className={this.bem.getElement('row-container')}>
                            {isSubTask && this.renderSubTaskIndent()}
                            {onChange && this.renderTaskCompleteButton()}
                            {this.renderTaskName()}
                            {this.renderTooltipForRepeat()}
                            {this.renderReviewTaskIcon()}
                        </Row>
                    </Column>
                    {this.renderMetadata()}
                </Row>
            </div>
        )
    }

    private renderReviewSubTask() {
        const { task, onChange, className, showDepartment } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <ModalManager
                    render={openModal => (
                        <Button
                            type={ButtonType.noStyling}
                            className={this.bem.getElement('task-name')}
                            onClick={openModal}
                            disabled={this.isModalDisabled}
                        >
                            <Row noSpacing={true}>
                                <Icon
                                    className={this.bem.getElement('review-subtask-icon')}
                                    type={task.completedAt ? IconType.check : IconType.taskOpen}
                                />
                                <span className={this.bem.getElement('review-subtask-name')}>{task.name}</span>
                                {this.renderTooltipForRepeat()}
                                {this.renderReviewTaskIcon()}
                            </Row>
                        </Button>
                    )}
                    renderModal={closeModal => (
                        <ViewTaskModalContainer
                            showDepartment={showDepartment}
                            taskId={task.id}
                            requestClose={closeModal}
                            onCompleteOrReopen={onChange}
                            onChangeTask={onChange}
                            onArchiveTask={onChange}
                        />
                    )}
                />
            </div>
        )
    }

    private renderSubTaskLabel() {
        const { task } = this.props
        const parentTask = 'parentTask' in task ? task.parentTask : undefined

        if (!parentTask) {
            return
        }

        return (
            <Tooltip message={parentTask.name} className={this.bem.getElement('column-container')}>
                <Paragraph subtle={true} className={this.bem.getElement('parent-label')}>
                    {parentTask.name}
                </Paragraph>
            </Tooltip>
        )
    }

    private renderSubTaskIndent() {
        return <div className={this.bem.getElement('subtask-indent')} />
    }

    private renderTaskCompleteButton() {
        const { task, onChange } = this.props

        return <TaskCompleteButtonContainer task={task} onChange={onChange!} isDisabled={this.isActionDisabled} />
    }

    private renderTaskName() {
        const { task, onChange, to, showDepartment } = this.props

        if (to) {
            return (
                <Tooltip message={task.name}>
                    <Column extraSmallSpacing={true}>
                        <Button
                            disabled={this.isModalDisabled}
                            type={ButtonType.noStyling}
                            className={this.bem.getElement('task-name')}
                            to={to}
                        >
                            {task.name}
                        </Button>
                    </Column>
                </Tooltip>
            )
        }

        return (
            <ModalManager
                render={openModal => (
                    <Tooltip message={task.name}>
                        <Column extraSmallSpacing={true}>
                            <Button
                                type={ButtonType.noStyling}
                                className={this.bem.getElement('task-name')}
                                onClick={openModal}
                                disabled={this.isModalDisabled}
                            >
                                {task.name}
                            </Button>
                        </Column>
                    </Tooltip>
                )}
                renderModal={closeModal => (
                    <ViewTaskModalContainer
                        showDepartment={showDepartment}
                        taskId={task.id}
                        requestClose={closeModal}
                        onCompleteOrReopen={onChange}
                        onChangeTask={onChange}
                        onArchiveTask={onChange}
                    />
                )}
            />
        )
    }

    private renderTooltipForRepeat() {
        const { task } = this.props

        if (!task.repeats) {
            return null
        }

        return (
            <Tooltip message={localize.translate(t => t.Customer.Task.frequency[task.repeats!])}>
                <Icon type={IconType.repeat} className={this.bem.getElement('repeat-icon')} />
            </Tooltip>
        )
    }

    private renderReviewTaskIcon() {
        const { task } = this.props

        if (!task.isReviewTask) {
            return null
        }

        return (
            <Tooltip message={localize.translate(t => t.Entities.ReviewTask)}>
                <Icon type={IconType.controlTask} className={this.bem.getElement('review-task-icon')} />
            </Tooltip>
        )
    }

    private renderMetadata() {
        const { task, hideMetadata } = this.props

        if (hideMetadata) {
            return
        }

        const users = task.employees?.map(({ user }) => ({
            id: user.id,
            fullName: user.profile.fullName,
            avatar: user.profile.avatar,
        }))

        return (
            <Row smallSpacing={true} className={this.bem.getElement('metadata-container')}>
                {this.renderSubTaskCount()}
                <TaskTags tags={task.tags} showAsTooltip={true} />
                <CappedProfileList users={users} className={this.bem.getElement('profile-list')} />
            </Row>
        )
    }

    private renderSubTaskCount() {
        const { task } = this.props

        const isParentTask = 'isParentTask' in task && task.isParentTask
        if (!isParentTask) {
            return
        }

        const completedSubTaskCount = 'completedSubTaskCount' in task ? task.completedSubTaskCount : 0
        const totalSubTaskCount = 'totalSubTaskCount' in task ? task.totalSubTaskCount : 0
        const count = `${completedSubTaskCount}/${totalSubTaskCount}`

        return (
            <Row extraSmallSpacing={true} className={this.bem.getElement('subtask-count')}>
                <Icon type={IconType.parentTask} />
                <Paragraph>{count}</Paragraph>
            </Row>
        )
    }
}
