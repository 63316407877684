import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { LawArticleType } from '~/generated/graphql'

const TOPIC_ARTICLES_QUERY = gql`
    query topicWithArticles($id: Int!, $customerSlug: String, $departmentId: Int!) {
        topic(id: $id, customerSlug: $customerSlug) {
            id
            linkedLawArticles {
                id
                title
                isSingleArticle
                updatedAt
                createdAt
                abstractLawArticleId
                expired(topicId: $id) {
                    expiredAt
                    willBeDeletedAt
                }
                assessments(departmentId: $departmentId, topicId: $id) {
                    id
                    name
                }
                sectionParents {
                    id
                    name
                    depth
                }
                lawGroup {
                    id
                    name
                    order
                }
                law {
                    id
                    name
                    abstractLawId
                }
            }
        }
    }
`

export interface TopicArticlesFieldsQueryResponse {
    topic?: {
        id: number
        linkedLawArticles: LawArticleType[]
    }
}

export interface TopicArticlesFieldsQueryVariables {
    id: number
    customerSlug: string
    departmentId: number
}

export type TopicArticlesFieldsQueryResult = QueryResult<TopicArticlesFieldsQueryResponse>

interface Props {
    topicId: number
    children: (result: TopicArticlesFieldsQueryResult) => JSX.Element
}

export class TopicArticlesQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, topicId } = this.props

        return (
            <Query<TopicArticlesFieldsQueryResponse, TopicArticlesFieldsQueryVariables>
                query={TOPIC_ARTICLES_QUERY}
                variables={{
                    id: topicId,
                    customerSlug: this.context.customer.slug,
                    departmentId: this.context.activeDepartmentId,
                }}
            >
                {children}
            </Query>
        )
    }
}
