import { AssessmentCoverageType } from '~/generated/graphql'

export enum AssessmentCoverageBarType {
    assessed = 'assessed',
    noted = 'noted',
}

export function getAssessmentPercentages(assessmentCoverage?: AssessmentCoverageType) {
    return {
        assessed: {
            amount:
                assessmentCoverage?.assessed && assessmentCoverage.total
                    ? containAssessmentPercentage(
                          Math.round((assessmentCoverage.assessed * 100) / assessmentCoverage.total)
                      )
                    : 0,
            type: AssessmentCoverageBarType.assessed,
        },
        noted: {
            amount:
                assessmentCoverage?.noted && assessmentCoverage.total
                    ? containAssessmentPercentage(
                          Math.round((assessmentCoverage.noted * 100) / assessmentCoverage.total)
                      )
                    : 0,
            type: AssessmentCoverageBarType.noted,
        },
    }
}

export function containAssessmentPercentage(amount: number) {
    if (isNaN(amount)) {
        return 0
    }

    return Math.max(0, Math.min(100, amount))
}
