import React from 'react'
import { RouteComponentProps } from 'react-router'
import { localize, notification } from '~/bootstrap'
import { Button } from '~/components/Core/Button/Button'
import { ActionBar } from '~/components/Core/Layout/ActionBar'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import { Column } from '~/components/Core/Layout/Column'
import { breadcrumbs } from '~/views/breadcrumbs'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { GQLMutation } from '~/graphql/Mutation'
import {
    CustomerSamlConfigurationDocument,
    CustomerSamlConfigurationQuery,
    CustomerSamlConfigurationQueryVariables,
    EditSamlConfigurationDocument,
    EditSamlConfigurationMutation,
    EditSamlConfigurationMutationVariables,
} from '~/generated/graphql'
import { MutationFn, Query } from 'react-apollo'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { SAMLSettingsFormFields } from '~/components/Domain/SAML/SAMLSettingsFormFields'

interface RouteParams {}

interface Props extends RouteComponentProps<RouteParams> {}

export class SAMLSettingsDetailView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Settings.SAMLSettingsDetailView)

    public render() {
        const baseUrl = window.ENVIRONMENT.NAME === 'development' ? `http://localhost:5000` : window.location.origin

        const entityId = `${baseUrl}/api/saml2/${this.context.customer.slug}/metadata.xml`
        const ascURL = `${baseUrl}/api/saml2/${this.context.customer.slug}/assert`
        const logoutURL = `${baseUrl}/api/saml2/${this.context.customer.slug}/logout`

        return (
            <Column bigSpacing={true}>
                <PageHeader
                    title={localize.translate(t => t.Customer.routes['/:customer/settings/saml'])}
                    breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).settings.index]}
                />
                <Paragraph>{this.loc(t => t.description)}</Paragraph>
                <Query<CustomerSamlConfigurationQuery, CustomerSamlConfigurationQueryVariables>
                    query={CustomerSamlConfigurationDocument}
                >
                    {({ data, loading }) => {
                        if (loading) {
                            return <Spinner />
                        }

                        if (!data || !data.customer) {
                            return <NoResults />
                        }

                        const ssoLoginURL = data.customer.ssoLoginURL
                        const ssoLogoutURL = data.customer.ssoLogoutURL
                        const ssoCertificate = data.customer.ssoCertificate

                        return (
                            <GQLMutation<EditSamlConfigurationMutation, EditSamlConfigurationMutationVariables>
                                mutation={EditSamlConfigurationDocument}
                            >
                                {(mutate, { loading }) => {
                                    return (
                                        <Form onSubmit={this.handleOnSubmit(mutate)}>
                                            <ErrorMessage path={'editSAMLConfiguration'} />
                                            <SAMLSettingsFormFields
                                                entityId={entityId}
                                                ascURL={ascURL}
                                                logoutURL={logoutURL}
                                                ssoCertificate={ssoCertificate}
                                                ssoLoginURL={ssoLoginURL}
                                                ssoLogoutURL={ssoLogoutURL}
                                            />
                                            <ActionBar>
                                                <Row alignRight={true}>
                                                    <Button submit={true} loading={loading}>
                                                        {localize.translate(t => t.Generic.save)}
                                                    </Button>
                                                </Row>
                                            </ActionBar>
                                        </Form>
                                    )
                                }}
                            </GQLMutation>
                        )
                    }}
                </Query>
            </Column>
        )
    }

    private handleOnSubmit = (
        mutate: MutationFn<EditSamlConfigurationMutation, EditSamlConfigurationMutationVariables>
    ) => async (formState: FormState) => {
        const response = await mutate({
            variables: {
                fields: {
                    ...formState,
                },
            },
        })

        if (response && response.data && response.data.editSAMLConfiguration) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
        }
    }
}
