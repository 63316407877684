import React from 'react'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Row } from '~/components/Core/Layout/Row'
import { Button } from '~/components/Core/Button/Button'
import { Column } from '~/components/Core/Layout/Column'
import { localize, permissions } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'
import { LinkedTaskItem, getTypenameFromLinkedItemType } from '~/graphql/types/Task'
import { TaskStatus } from '../TaskStatus/TaskStatus'
import { CreateTaskModalContainer } from '../CreateTaskModalContainer'
import { ContextTasksContainer } from './ContextTasksContainer'
import { Guard } from '~/components/Core/Guard/Guard'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { DepartmentType } from '~/generated/graphql'

interface Props {
    amountOfOpenTasks: number
    amountOfCompletedTasks: number
    requestClose: () => void
    linkedItem: LinkedTaskItem
    onCompleteOrReopenTasks: () => void
    possibleDepartments?: Pick<DepartmentType, 'id' | 'name'>[]
    forCustomer?: boolean
}

export class ContextTasksModal extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks)
    private refetchAllTasks?: () => void

    public render() {
        const { amountOfOpenTasks, amountOfCompletedTasks, requestClose, linkedItem } = this.props
        const { forCustomer, onCompleteOrReopenTasks } = this.props
        const { linkedItemId, linkedItemType } = getTypenameFromLinkedItemType(linkedItem)

        return (
            <Modal
                title={
                    <TaskStatus amountOfOpenTasks={amountOfOpenTasks} amountOfCompletedTasks={amountOfCompletedTasks} />
                }
                requestClose={requestClose}
                actions={() => (
                    <Guard
                        condition={permissions.canCreateTasks(
                            this.props.linkedItem.department?.id || this.context.activeDepartmentId
                        )}
                    >
                        <ModalManager
                            render={openModal => (
                                <Row alignRight={true}>
                                    <Button icon={IconType.taskAdd} onClick={openModal} rounded={true}>
                                        {this.loc(t => t.newGenericTaskButton)}
                                    </Button>
                                </Row>
                            )}
                            renderModal={close => (
                                <CreateTaskModalContainer
                                    linkedItem={linkedItem}
                                    possibleDepartments={this.props.possibleDepartments}
                                    requestClose={close}
                                    onCreated={() => {
                                        onCompleteOrReopenTasks()
                                        if (this.refetchAllTasks) {
                                            this.refetchAllTasks()
                                        }
                                    }}
                                />
                            )}
                        />
                    </Guard>
                )}
                hideButtons={!permissions.canCreateTasks(this.context.activeDepartmentId)}
            >
                <Column>
                    {linkedItemId && linkedItemType && (
                        <>
                            <ContextTasksContainer
                                linkedItemId={linkedItemId}
                                linkedItemType={linkedItemType}
                                linkedItemDepartmentId={this.props.linkedItem.department?.id}
                                forCustomer={forCustomer}
                                onChange={() => {
                                    onCompleteOrReopenTasks()
                                }}
                                registerRefetchFunction={refetch => {
                                    this.refetchAllTasks = refetch
                                }}
                            />
                        </>
                    )}
                </Column>
            </Modal>
        )
    }
}
