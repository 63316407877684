import './ControlsContainer.scss'
import { isEqual } from 'lodash-es'
import React from 'react'
import { SortDirection, SortOption } from '~/components/Core/DataDisplay/Table/Table'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    ControlTypeType,
    GetControlsDocument,
    GetControlsQueryVariables,
    ControlStatus,
    GetControlsQuery,
    SortDirectionEnumType,
} from '~/generated/graphql'
import { ItemListSpacer } from '~/components/Core/DataDisplay/ItemListSpacer/ItemListSpacer'
import { ControlItem } from './ControlItem'
import { BulkControlArchiveContainer } from '../../Controls/BulkControlArchiveContainer'
import { BEM } from '~/services/BEMService'
import { TableHeaderLabel } from '~/components/Core/DataDisplay/Table/TableHeaderLabel'
import { SortHeader } from '~/components/Core/DataDisplay/Table/SortHeader'
import { localize } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'

interface Props {
    search?: string | null
    types?: ControlTypeType[] | null
    assessmentId?: number | null
    topicId?: number | null
    isFilterActive: boolean
    status?: ControlStatus
}

interface State {
    sort: SortOption
    toBeArchivedIds: number[]
    isCheckboxActive: boolean
}

export type GetControlQueryType = NonNullable<NonNullable<GetControlsQuery['controls']>['nodes']>[0]

interface ControlItemType {
    control: GetControlQueryType
    indentLevel?: 1 | 2
}

export class ControlsContainer extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private static defaultSort: SortOption = { field: 'name', direction: SortDirectionEnumType.desc }

    private bem = new BEM('ControlsContainer')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.ControlTable)

    public state: State = {
        sort: ControlsContainer.defaultSort,
        toBeArchivedIds: [],
        isCheckboxActive: false,
    }

    public render() {
        const { status } = this.props
        const { toBeArchivedIds } = this.state

        return (
            <>
                {this.renderHeaders()}
                <InfiniteScrollQuery<GetControlQueryType, GetControlsQueryVariables>
                    path="controls"
                    query={GetControlsDocument}
                    variables={this.getQueryVariables()}
                >
                    {({ data, loading, loadingMore, refetch }) => {
                        if (loading) {
                            return <Spinner delayed={true} />
                        }

                        if (!data || !data.nodes?.length) {
                            return <NoResults />
                        }

                        const controlsData: ControlItemType[] = []
                        this.getCardData(data.nodes, controlsData)

                        return (
                            <>
                                <BulkControlArchiveContainer
                                    controlIds={toBeArchivedIds}
                                    onClearSelection={() =>
                                        this.setState({ toBeArchivedIds: [], isCheckboxActive: false })
                                    }
                                    onSubmit={refetch}
                                />
                                <ItemListSpacer>
                                    {controlsData.map(({ control, indentLevel }, index) => (
                                        <li
                                            key={index}
                                            className={
                                                indentLevel
                                                    ? indentLevel === 1
                                                        ? this.bem.getElement('indent-1')
                                                        : this.bem.getElement('indent-2')
                                                    : ''
                                            }
                                        >
                                            <ControlItem
                                                control={control}
                                                isCheckboxActive={this.state.isCheckboxActive}
                                                isFirstItem={index === 0}
                                                checked={toBeArchivedIds.includes(control.id)}
                                                handleOnCardCheck={this.handleOnControlItemCheck(control)}
                                                indent={indentLevel}
                                                isOpen={status === ControlStatus.open}
                                            />
                                        </li>
                                    ))}
                                </ItemListSpacer>
                                {loadingMore && <Spinner className={this.bem.getElement('loading-more')} />}
                            </>
                        )
                    }}
                </InfiniteScrollQuery>
            </>
        )
    }

    private renderHeaders() {
        return (
            <Row className={this.bem.getElement('header-container')}>
                <SortHeader
                    sortDirection={ControlsContainer.defaultSort.direction}
                    onSort={this.handleSortDirectionChange('name')}
                >
                    <TableHeaderLabel label={this.loc(t => t.headers.name)} />
                </SortHeader>
                <TableHeaderLabel label={this.loc(t => t.headers.linkedTopicsCount)} />
                <TableHeaderLabel label={this.loc(t => t.headers.tasks)} />
            </Row>
        )
    }

    private handleOnControlItemCheck = (item: any) => (checked: boolean) => {
        const { toBeArchivedIds } = this.state

        if (checked) {
            this.setState({
                toBeArchivedIds: [...toBeArchivedIds, item.id],
                isCheckboxActive: true,
            })
            return
        }

        const filteredArchivedIds = toBeArchivedIds.filter(id => id !== item.id)
        const isCheckboxActive = filteredArchivedIds.length > 0

        this.setState({
            toBeArchivedIds: toBeArchivedIds.filter(id => id !== item.id),
            isCheckboxActive,
        })
    }

    private getQueryVariables(): GetControlsQueryVariables {
        const { search, types, assessmentId, topicId, isFilterActive, status } = this.props
        const { sort } = this.state

        const parentControlsOnly = !isFilterActive && isEqual(sort, ControlsContainer.defaultSort) && !search

        return {
            filters: {
                name: search,
                types: types,
                assessmentIds: assessmentId ? [assessmentId] : undefined,
                topicIds: topicId ? [topicId] : undefined,
                departmentId: this.context.activeDepartmentId,
                parentControlsOnly,
                status: status,
            },
            sort: {
                [sort.field]: sort.direction,
            },
            departmentId: this.context.activeDepartmentId,
            withChildControls: parentControlsOnly,
        }
    }

    private getCardData(controls: GetControlQueryType[], acc: ControlItemType[], indentLevel?: 1 | 2) {
        for (const control of controls) {
            acc.push({ control, indentLevel })

            if (control.childControls?.length) {
                const nextIndent = indentLevel ? 2 : 1
                this.getCardData(control.childControls, acc, nextIndent)
            }
        }
    }

    private handleSortDirectionChange = (field: string) => {
        return (direction: SortDirection) => {
            return this.setState({ sort: { field, direction } })
        }
    }
}
