import './OperationsCheckboxList.scss'

import React from 'react'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'
import { EmployeeOperationEnum } from '~/generated/graphql'

interface Props {
    className?: ClassValue
    name: string
    onChange?: (selectedOperations: EmployeeOperationEnum[]) => void
    defaultOperations: EmployeeOperationEnum[]
    defaultSelectedOperations: EmployeeOperationEnum[]
    disabled?: boolean
    noUnderline?: boolean
}

interface State {
    selectedOperations: EmployeeOperationEnum[]
}

export class OperationsCheckboxList extends React.Component<Props, State> {
    public state: State = {
        selectedOperations: this.props.defaultSelectedOperations,
    }

    private bem = new BEM('OperationsCheckboxList', () => ({
        noUnderline: this.props.noUnderline,
    }))
    private loc = localize.namespaceTranslate(t => t.User.Operations)
    private locAbbr = localize.namespaceTranslate(t => t.User.OperationsAbbr)

    public render() {
        const { disabled } = this.props
        const data = this.getCheckboxListData()

        if (!data.length) {
            return null
        }

        return (
            <div className={this.bem.getClassName()}>
                {data.map((item, index) => (
                    <React.Fragment key={`${index}-checkboxlist-item`}>
                        <Row>
                            <Checkbox
                                disabled={disabled}
                                className={this.bem.getElement('checkbox')}
                                name={item.name}
                                checked={item.checked}
                                onChange={item.onChange}
                                label={item.label}
                            />
                        </Row>
                    </React.Fragment>
                ))}
            </div>
        )
    }

    private getCheckboxListData() {
        const { defaultOperations } = this.props
        const { selectedOperations } = this.state

        return defaultOperations.map((operation: EmployeeOperationEnum) => ({
            label: this.loc(t => t[operation]) || '',
            labelAbbr: this.locAbbr(t => t[operation]) || '',
            name: operation,
            onChange: (checked: boolean) => this.onCheckboxChange(checked, operation),
            checked: selectedOperations.includes(operation),
        }))
    }

    private onCheckboxChange(checked: boolean, operation: EmployeeOperationEnum) {
        const { onChange } = this.props
        const { selectedOperations } = this.state

        const newState = checked
            ? Array.from(new Set([...selectedOperations, operation]))
            : selectedOperations.filter(op => op !== operation)

        this.setState({ selectedOperations: newState }, () => {
            if (onChange) {
                onChange(newState)
            }
        })
    }
}
