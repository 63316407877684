import React from 'react'
import { localize, notification, permissions } from '~/bootstrap'
import { Logo } from '~/components/Chrome/Logo/Logo'
import { Button } from '~/components/Core/Button/Button'
import { Table } from '~/components/Core/DataDisplay/Table/Table'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { OptionsDropdown } from '~/components/Core/OptionsDropdown/OptionsDropdown'
import { OptionsDropdownOption } from '~/components/Core/OptionsDropdown/OptionsDropdownOption'
import { FetchMoreButton } from '~/components/Core/Pagination/FetchMoreButton'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import {
    SetConsultantTaskTemplateVisibility,
    SetConsultantTaskTemplateVisibilityMutationFN,
} from '~/components/Domain/Task/ConsultantTaskTemplate/mutations/SetConsultantTaskTemplateVisibility'
import { ConsultantTaskTemplatesInfiniteScrollQuery } from '~/components/Domain/Task/ConsultantTaskTemplate/queries/ConsultantTaskTemplatesInfiniteScrollQuery'
import { TaskTemplatesOverviewPaginatedQuery } from '~/components/Domain/Task/Queries/TaskTemplatesOverviewPaginatedQuery'
import { CreateTaskTemplateModalContainer } from '~/components/Domain/Task/TaskTemplates/CreateTaskTemplateModalContainer'
import { TaskTemplatesTableContainer } from '~/components/Domain/Task/TaskTemplates/TaskTemplatesTableContainer'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { breadcrumbs } from '~/views/breadcrumbs'

export class TaskTemplatesOverviewView extends React.PureComponent {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private refetchTaskTemplates: Function | undefined
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.TaskTemplatesOverviewView)

    public render() {
        return (
            <>
                <PageHeader
                    title={this.loc(t => t.pageTitle)}
                    breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).settings.index]}
                    actionComponent={this.renderAction()}
                />
                <Column extraBigSpacing={true}>
                    <Column>{this.renderCreatedTemplates()}</Column>
                    <Column>{this.renderSuggestedTemplates()}</Column>
                </Column>
            </>
        )
    }

    private renderAction = () => {
        const hasPermission = permissions.isCustomerAdministrator()
        if (!hasPermission) {
            return
        }

        return (
            <ModalManager
                render={openModal => (
                    <Row alignRight={true}>
                        <Button rounded={true} icon={IconType.taskAdd} onClick={openModal}>
                            {this.loc(t => t.createTemplate)}
                        </Button>
                        <OptionsDropdown>{this.renderSetVisibilityOption()}</OptionsDropdown>
                    </Row>
                )}
                renderModal={closeModal => (
                    <CreateTaskTemplateModalContainer
                        requestClose={closeModal}
                        onCreate={() => this.handleOnCreate()}
                    />
                )}
            />
        )
    }

    private renderCreatedTemplates() {
        return (
            <>
                <SectionTitle>{this.loc(t => t.createdTemplates)}</SectionTitle>
                <TaskTemplatesOverviewPaginatedQuery>
                    {({ data, loading, loadingMore, refetch, fetchMore, canFetchMore }) => {
                        this.refetchTaskTemplates = refetch

                        return (
                            <Column>
                                <TaskTemplatesTableContainer data={data} queryLoading={loading} refetch={refetch} />
                                {canFetchMore && (
                                    <FetchMoreButton
                                        label={this.loc(t => t.fetchMore)}
                                        fetchMore={fetchMore}
                                        loadingMore={loadingMore}
                                    />
                                )}
                            </Column>
                        )
                    }}
                </TaskTemplatesOverviewPaginatedQuery>
            </>
        )
    }

    private renderSuggestedTemplates() {
        const { isConsultantTaskTemplatesHidden } = this.context

        return (
            <>
                <Row smallSpacing={true}>
                    <Logo flex={true} extraSmall={true} iconOnly={true} />
                    <SectionTitle>{this.loc(t => t.suggestedTemplates)}</SectionTitle>
                </Row>
                {isConsultantTaskTemplatesHidden ? (
                    <Table columns={[]} data={[]} emptyState={this.loc(t => t.suggestedTemplatesHidden)} />
                ) : (
                    <ConsultantTaskTemplatesInfiniteScrollQuery customerId={this.context.customer.id}>
                        {({ data, loading, loadingMore }) => (
                            <Column>
                                <TaskTemplatesTableContainer
                                    data={data}
                                    queryLoading={loading}
                                    queryLoadingMore={loadingMore}
                                    forSuggestedTemplates={true}
                                />
                            </Column>
                        )}
                    </ConsultantTaskTemplatesInfiniteScrollQuery>
                )}
            </>
        )
    }

    private renderSetVisibilityOption() {
        const { isConsultantTaskTemplatesHidden } = this.context

        let label: string
        let icon: IconType
        if (isConsultantTaskTemplatesHidden) {
            label = this.loc(t => t.showSuggestedTemplates)
            icon = IconType.eye
        } else {
            label = this.loc(t => t.hideSuggestedTemplates)
            icon = IconType.unfollowed
        }

        return (
            <SetConsultantTaskTemplateVisibility>
                {(mutate, { loading }) => (
                    <OptionsDropdownOption
                        icon={icon}
                        label={label}
                        loading={loading}
                        onClick={this.handleVisibilityChange(mutate)}
                    />
                )}
            </SetConsultantTaskTemplateVisibility>
        )
    }

    private handleOnCreate = () => {
        if (this.refetchTaskTemplates) {
            this.refetchTaskTemplates()
        }
    }

    private handleVisibilityChange = (mutate: SetConsultantTaskTemplateVisibilityMutationFN) => async () => {
        const { isConsultantTaskTemplatesHidden } = this.context

        const response = await mutate({ variables: { isHidden: !isConsultantTaskTemplatesHidden } })

        if (response && response.data?.setIsConsultantTaskTemplateHidden) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
        }
    }
}
