import React from 'react'
import { Query, QueryResult } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TopicLinkedItemsDocument, TopicLinkedItemsQuery, TopicLinkedItemsQueryVariables } from '~/generated/graphql'

interface Props {
    topicId: number
    lawArticlesOnly?: boolean
    children: (data: QueryResult<TopicLinkedItemsQuery, TopicLinkedItemsQueryVariables>) => React.ReactNode
    getExpiredStatus?: boolean
    filterDeleted?: boolean
}

export class TopicLinkedItems extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, topicId, lawArticlesOnly, getExpiredStatus, filterDeleted } = this.props

        return (
            <Query<TopicLinkedItemsQuery, TopicLinkedItemsQueryVariables>
                query={TopicLinkedItemsDocument}
                variables={{
                    id: topicId,
                    customerSlug: this.context.customer.slug,
                    lawArticlesOnly,
                    getExpiredStatus: !!getExpiredStatus,
                    filterDeletedArticles: filterDeleted,
                }}
            >
                {data => children(data)}
            </Query>
        )
    }
}
