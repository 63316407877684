import React from 'react'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { Row } from '~/components/Core/Layout/Row'
import { BEM, ClassValue } from '~/services/BEMService'
import { RiskIndicator, SeverityLevel } from '../Risks/RiskIndicator'
import './LinkedRiskCard.scss'
import { RiskStatus } from '~/graphql/types/Risk'
import { localize } from '~/bootstrap'
import { AttentionIcon } from '~/components/Core/Icon/AttentionIcon/AttentionIcon'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { RiskSeverity } from '~/generated/graphql'

interface Props {
    className?: ClassValue
    cardOptions: {
        name: string
        severity?: SeverityLevel | RiskSeverity
        riskStatus?: RiskStatus
        linkedDescription?: string
    }
    faded?: boolean
    textOverflow?: boolean
    onClick?: () => void
    hideAttentionIcon?: boolean
}

interface State {}

export class LinkedRiskCard extends React.Component<Props, State> {
    private risksLoc = localize.namespaceTranslate(t => t.Customer.Compliance.Risks)
    private bem = new BEM('LinkedRiskCard', () => ({
        'is-faded': !!this.props.faded,
        'show-text-overflow': !!this.props.textOverflow,
    }))

    public render() {
        const { onClick, className, cardOptions } = this.props

        return (
            <Card className={this.bem.getClassName(className)} onClick={onClick}>
                <Row spaceBetween={true}>
                    <h5 className={this.bem.getElement('title')}>
                        <RiskIndicator className={this.bem.getElement('icon')} severity={cardOptions.severity} />
                        {cardOptions.riskStatus && this.renderRiskAttentionIcon(cardOptions.riskStatus)}
                        <div className={this.bem.getElement('name')}>{cardOptions.name}</div>
                    </h5>
                </Row>
                {!!cardOptions.linkedDescription && (
                    <Row spaceBetween={true}>
                        <Paragraph preserveLine={true} className={this.bem.getElement('description')}>
                            {cardOptions.linkedDescription}
                        </Paragraph>
                    </Row>
                )}
            </Card>
        )
    }

    private renderRiskAttentionIcon(riskStatus: RiskStatus) {
        const { hideAttentionIcon } = this.props

        if (hideAttentionIcon) {
            return
        }

        return (
            <AttentionIcon
                className={this.bem.getElement('attention-icon')}
                tooltipContent={this.risksLoc(t => t.status[riskStatus])}
            />
        )
    }
}
