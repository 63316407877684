import React from 'react'
import { localize } from '~/bootstrap'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { Markdown } from '~/components/Core/Text/Markdown'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { FilePreviewModal } from '~/components/Domain/FilePreview/FilePreviewModal'
import { EventLogType, TopicAssessmentGradeType } from '~/generated/graphql'
import { TopicAssessmentLabel } from '../modals/TopicAssessment/TopicAssessmentLabel'
import { TopicDesignAndEffectivenessEventCard } from './TopicDesignAndEffectivenessEventCard'
import { FileType as FileTypes } from '~/services/FileService'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import {
    FileMetaData,
    TopicAssessmentDocumentsUpdatedMetadata,
    TopicAssessmentMonitoringReportMetaData,
} from '~/graphql/types/EventMetadata'

interface Props {
    event: EventLogType
    deletedFilePaths?: string[]
}

export enum TopicAssessmentEventType {
    wasCreated = 'TOPIC_ASSESSMENT_WAS_CREATED',
    wasDeleted = 'TOPIC_ASSESSMENT_WAS_DELETED',
    wasUpdated = 'TOPIC_ASSESSMENT_WAS_UPDATED',
    articleLinkWasUpdated = 'TOPIC_ASSESSMENT_ARTICLE_LINK_WAS_UPDATED',
    documentsUpdated = 'TOPIC_ASSESSMENT_DOCUMENTS_UPDATED',
    monitorReportWasUpdated = 'TOPIC_ASSESSMENT_MONITORING_REPORT_UPDATED',
}

export class TopicAssessmentEvent extends React.PureComponent<Props> {
    public render() {
        const mainAssessmentEvents = [
            TopicAssessmentEventType.wasCreated,
            TopicAssessmentEventType.wasUpdated,
            TopicAssessmentEventType.wasDeleted,
        ]

        const { type } = this.props.event

        if (mainAssessmentEvents.includes(type as TopicAssessmentEventType)) {
            return this.renderMainAssessmentEvent()
        }

        if (type === TopicAssessmentEventType.documentsUpdated) {
            return this.renderDocumentEvent()
        }

        if (type === TopicAssessmentEventType.monitorReportWasUpdated) {
            return this.renderMonitoringReportEvent()
        }

        if (type === TopicAssessmentEventType.articleLinkWasUpdated) {
            return this.renderArticleEvent()
        }

        return null
    }

    public renderMainAssessmentEvent() {
        const { event } = this.props
        const { metaData } = event

        const description = metaData?.newData?.description
        const newGrade = metaData?.newData?.newGrade

        if (!description && !newGrade) {
            return null
        }

        return (
            <>
                {newGrade && (
                    <TopicDesignAndEffectivenessEventCard
                        event={event}
                        assessmentType={metaData?.linkedItemDesignOrEffectiveness}
                        content={this.renderGradeContent()}
                    />
                )}
                {description && (
                    <TopicDesignAndEffectivenessEventCard
                        event={event}
                        assessmentType={metaData?.linkedItemDesignOrEffectiveness}
                        content={<Markdown source={description} paragraphLike={true} />}
                    />
                )}
            </>
        )
    }

    public renderDocumentEvent() {
        const { event } = this.props
        const metaData = event.metaData as TopicAssessmentDocumentsUpdatedMetadata | undefined
        const addedDocuments = metaData?.addedTopicAssessmentDocumentFiles
        const removedDocuments = metaData?.removedTopicAssessmentDocumentFiles

        return (
            <>
                {addedDocuments?.map((d, n) => this.renderEvent(this.renderFile(d), n))}
                {removedDocuments?.map((d, n) => this.renderEvent(this.renderFile(d, true), n))}
            </>
        )
    }

    public renderMonitoringReportEvent() {
        const { event } = this.props
        const metaData = event.metaData as TopicAssessmentMonitoringReportMetaData | undefined
        const addedReport = metaData?.addedMonitoringReport
        const removedReport = metaData?.removedMonitoringReport

        return (
            <>
                {addedReport &&
                    this.renderEvent(this.renderName(addedReport.name, IconType.monitoringReport), addedReport.id)}
                {removedReport &&
                    this.renderEvent(
                        this.renderName(removedReport.name, IconType.monitoringReport, true),
                        removedReport.id
                    )}
            </>
        )
    }

    public renderArticleEvent() {
        const { event } = this.props

        const message = localize.translate(
            t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessTable.articlesUpdatedMessage
        )

        return <TopicDesignAndEffectivenessEventCard event={event} content={<Paragraph>{message}</Paragraph>} />
    }

    private renderGradeContent() {
        const { metaData } = this.props.event

        const oldGrade = metaData?.newData?.oldGrade || TopicAssessmentGradeType.assessmentNotGiven
        const newGrade = metaData?.newData?.newGrade // no need for a fallback, its checked in the caller of this method

        return (
            <Row>
                <TopicAssessmentLabel status={oldGrade} />
                <Icon type={IconType.nextArrow} subtle={true} />
                <TopicAssessmentLabel status={newGrade} />
            </Row>
        )
    }

    private renderEvent(content: JSX.Element, key: number) {
        const { event } = this.props
        const { metaData } = event

        return (
            <TopicDesignAndEffectivenessEventCard
                key={key}
                event={{ ...event, type: TopicAssessmentEventType.wasUpdated }}
                assessmentType={metaData?.linkedItemDesignOrEffectiveness}
                content={content}
            />
        )
    }

    private renderFile(inputFile: FileMetaData, removed?: boolean) {
        const deleted = this.props.deletedFilePaths?.includes(inputFile.path)
        if ((removed || deleted) && !inputFile.filePersisted) {
            return this.renderName(inputFile.name, IconType.attachment, true)
        }

        const file = {
            id: inputFile.id,
            name: inputFile.name,
            path: inputFile.path,
            mimetype: inputFile.mimetype || 'application/pdf',
        }

        const buttonType = removed ? ButtonType.subtleItemLink : ButtonType.actionLink

        return (
            <FilePreviewModal key={file.id} file={file} fileType={FileTypes.topicAssessmentFile}>
                {previewFile => (
                    <Button onClick={() => previewFile()} icon={IconType.attachment} type={buttonType}>
                        {removed ? <Paragraph strikethrough>{file.name}</Paragraph> : file.name}
                    </Button>
                )}
            </FilePreviewModal>
        )
    }

    private renderName(name: string, icon: IconType, subtleAndStrikethrough?: boolean) {
        return (
            <Row extraSmallSpacing>
                <Icon subtle={subtleAndStrikethrough} type={icon} />
                <Paragraph subtle={subtleAndStrikethrough} strikethrough={subtleAndStrikethrough} bold={true}>
                    {name}
                </Paragraph>
            </Row>
        )
    }
}
