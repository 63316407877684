import React from 'react'
import { Route, Switch } from 'react-router'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { routes } from '~/views/routes'
import { CreateCustomerGroupView } from './CreateCustomerGroupView'
import { CreateNewCustomerInGroupView } from './CreateNewCustomerInGroupView'
import { CustomerGroupDetailView } from './CustomerGroupDetailView'
import { CustomerGroupEditView } from './CustomerGroupEditView'
import { CustomerGroupsOverviewView } from './CustomerGroupsOverviewView'

export class CustomerGroupsView extends React.PureComponent {
    public render() {
        const basePath = routes.consultant.customerGroups

        return (
            <Switch>
                <Route path={basePath.create} component={CreateCustomerGroupView} />
                <Route path={basePath.detail().newCustomer()} component={CreateNewCustomerInGroupView} />
                <Route path={basePath.detail().edit} component={CustomerGroupEditView} />
                <Route path={basePath.detail().view} component={CustomerGroupDetailView} />
                <Route path={basePath.index} component={CustomerGroupsOverviewView} />
                <Route component={AppNotFoundView} />
            </Switch>
        )
    }
}
