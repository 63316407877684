import './PasswordInput.scss'

import React from 'react'
import { BEM } from '~/services/BEMService'
import { Input, InputProps } from '~/components/Core/DataEntry/Form/Input'
import { localize } from '~/bootstrap'
import zxcvbn from 'zxcvbn'

export interface Props extends InputProps {
    className?: string
    onStrengthChange?: (score: number) => void
}

interface State {
    passwordValue?: string
    passwordInfo?: zxcvbn.ZXCVBNResult | null
}

interface BEMParams {
    strengthScore: number | null
}

export class PasswordInput extends React.PureComponent<Props, State> {
    public state: State = {
        passwordValue: undefined,
        passwordInfo: undefined,
    }

    private bem = new BEM<BEMParams>('PasswordInput', ({ strengthScore }) => ({
        'strength-very-weak': strengthScore === 0,
        'strength-weak': strengthScore === 1,
        'strength-medium': strengthScore === 2,
        'strength-strong': strengthScore === 3,
        'strength-very-strong': strengthScore === 4,
    }))

    private loc = localize.namespaceTranslate(t => t.Core.Form.PasswordInput)

    public render() {
        const { className, ...inputProps } = this.props
        const { passwordInfo } = this.state

        return (
            <div
                className={this.bem.getClassName(className, {
                    strengthScore: passwordInfo ? passwordInfo.score : null,
                })}
            >
                <Input
                    {...inputProps}
                    type="password"
                    className={this.bem.getElement('input')}
                    onChange={this.onPasswordChange}
                />
                <div className={this.bem.getElement('password-check')}>
                    {this.renderStrengthBar()}
                    {this.renderResult()}
                </div>
                {this.renderResultInfo()}
            </div>
        )
    }

    private renderStrengthBar() {
        const { passwordInfo } = this.state

        if (!passwordInfo) {
            return null
        }

        const scoreFactor = (passwordInfo.score + 1) / 5

        return (
            <div className={this.bem.getElement('strength-bar')}>
                <div
                    className={this.bem.getElement('strength-bar-inner')}
                    style={{ transform: `translateX(${scoreFactor * 100 - 100}%)` }}
                />
            </div>
        )
    }

    private renderResult() {
        const { passwordInfo } = this.state

        if (!passwordInfo) {
            return null
        }

        return (
            <span className={this.bem.getElement('strength')}>
                <span className={this.bem.getElement('strength-value')}>
                    {this.loc(t => t.strength[passwordInfo.score])}
                </span>
            </span>
        )
    }

    private renderResultInfo() {
        const { passwordInfo } = this.state

        if (!passwordInfo) {
            return null
        }

        const warning =
            passwordInfo.feedback.warning &&
            this.loc(t => t.warnings[passwordInfo.feedback.warning.replace(/"|'/g, '')])

        if (warning) {
            return <span className={this.bem.getElement('warning')}>{warning}</span>
        } else {
            return null
        }
    }

    private onPasswordChange = (password: string | null) => {
        const { onChange, onStrengthChange, name } = this.props

        this.setState(
            {
                passwordValue: password || '',
                passwordInfo: !password ? undefined : zxcvbn(password),
            },
            () => {
                if (onChange) {
                    onChange(this.state.passwordValue || null, name)
                }

                if (onStrengthChange) {
                    onStrengthChange(this.state.passwordInfo ? this.state.passwordInfo.score : 0)
                }
            }
        )
    }
}
