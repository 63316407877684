import React from 'react'
import gql from 'graphql-tag'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { ShadowEmployeeType } from '~/generated/graphql'

const LIST_ASSIGNABLE_EMPLOYEES = gql`
    query assignableEmployees($skip: Int, $take: Int, $customerSlug: String, $departmentId: Int, $name: String) {
        assignableEmployees(
            skip: $skip
            take: $take
            name: $name
            departmentId: $departmentId
            customerSlug: $customerSlug
        ) {
            hasNextPage
            nodes {
                id
                employee {
                    id
                    user {
                        id
                        profile {
                            id
                            fullName
                        }
                    }
                }
                shadowEmployee {
                    id
                    name
                }
            }
        }
    }
`

interface Employee {
    id: number
    user: {
        id: number
        profile: {
            id: number
            fullName: string
        }
    }
}

type ShadowEmployee = Pick<ShadowEmployeeType, 'id' | 'name'>

interface AssignableEmployee {
    id: number
    employee: Employee | null
    shadowEmployee: ShadowEmployee | null
}

export interface EmployeeSelectOption {
    label: string | JSX.Element
    value: { employee?: Employee; shadowEmployee?: ShadowEmployee; newShadowEmployee?: string }
    __isNew__?: boolean
}

interface Props {
    clearable?: boolean
    creatable?: boolean
    name?: string
    defaultValue?: EmployeeSelectOption[]
    disabled?: boolean
    errorMessage?: string
    onChange?: (selectedOptions: EmployeeSelectOption[] | null, name: string) => void
}

interface State {
    search?: string
}

export class EmployeeSelect extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        search: undefined,
    }

    public render() {
        const { search } = this.state
        const { clearable, creatable, name, defaultValue, disabled, onChange } = this.props

        return (
            <PaginatableQuery<AssignableEmployee>
                query={LIST_ASSIGNABLE_EMPLOYEES}
                variables={{
                    customerSlug: this.context.customer.slug,
                    departmentId: this.context.activeDepartmentId,
                    name: search,
                }}
            >
                {({ data, loading, loadingMore, fetchMore }) => {
                    if (!data) {
                        return null
                    }

                    const transformedDefaults = this.transformDefaultsToSelectOptions(defaultValue)

                    const sources = data.nodes.map(node => ({
                        label: this.getNameFromNode(node),
                        value: node.id,
                    }))

                    return (
                        <Form.Select
                            name={name || 'employeeId'}
                            searchable={true}
                            options={sources}
                            creatable={creatable}
                            onEndReached={fetchMore}
                            clearable={clearable}
                            onSearch={value => this.setState({ search: value })}
                            loading={loading || loadingMore}
                            defaultValue={transformedDefaults}
                            disabled={disabled}
                            multi={true}
                            onChange={(selectedOptions: SelectOption[] | null, name) => {
                                if (onChange) {
                                    if (selectedOptions) {
                                        const options = this.transformSelectedOptionsBackToAssignableEmployees(
                                            data.nodes,
                                            selectedOptions
                                        )
                                        onChange(options, name)
                                    } else {
                                        onChange(null, name)
                                    }
                                }
                            }}
                        />
                    )
                }}
            </PaginatableQuery>
        )
    }

    private transformDefaultsToSelectOptions(defaultValue: EmployeeSelectOption[] | undefined) {
        return defaultValue
            ? defaultValue.map(value => {
                  if (value.value.employee) {
                      return {
                          label: value.label,
                          value: `employee-${value.value.employee.id}`,
                      }
                  }
                  if (value.value.shadowEmployee) {
                      return {
                          label: value.label,
                          value: `shadowEmployee-${value.value.shadowEmployee.id}`,
                      }
                  }
                  throw new Error('No employee or shadow employee')
              })
            : undefined
    }

    private transformSelectedOptionsBackToAssignableEmployees(
        nodes: AssignableEmployee[],
        selectedOptions: SelectOption[]
    ) {
        return selectedOptions.map(option => {
            const o: Partial<EmployeeSelectOption> = {}
            const node = nodes.find(({ id }) => id === option.value)
            o.label = option.label
            o.__isNew__ = option.__isNew__

            if (!node) {
                o.value = { newShadowEmployee: option.value as string }
            }

            if (node && typeof option.value === 'string' && option.value.startsWith('shadowEmployee-')) {
                o.value = { shadowEmployee: node!.shadowEmployee as ShadowEmployee }
            }

            if (node && typeof option.value === 'string' && option.value.startsWith('employee-')) {
                o.value = { employee: node.employee as Employee }
            }

            return o as Required<EmployeeSelectOption>
        })
    }

    private getNameFromNode(node: AssignableEmployee) {
        if (node.employee) {
            return node.employee.user.profile.fullName
        }

        if (node.shadowEmployee) {
            return node.shadowEmployee.name || ''
        }

        return ''
    }
}
