import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    DeleteAssessmentSectionInAssessmentDocument,
    DeleteAssessmentSectionInAssessmentMutationVariables,
    DeleteAssessmentSectionInAssessmentMutation,
} from '~/generated/graphql'

interface Props {
    children: (
        mutate: MutationFn<
            DeleteAssessmentSectionInAssessmentMutation,
            DeleteAssessmentSectionInAssessmentMutationVariables
        >,
        result: MutationResult<DeleteAssessmentSectionInAssessmentMutation>
    ) => JSX.Element
}

export class DeleteAssessmentSectionMutation extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props
        return (
            <GQLMutation<
                DeleteAssessmentSectionInAssessmentMutation,
                DeleteAssessmentSectionInAssessmentMutationVariables
            >
                mutation={DeleteAssessmentSectionInAssessmentDocument}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
