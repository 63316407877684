import React from 'react'
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { MonitoringAgendaView } from './MonitoringAgenda/MonitoringAgendaView'
import { MonitoringReportsView } from './MonitoringRapports/MonitoringReportsView'

interface Props extends RouteComponentProps<{}> {}
interface State {}

export class MonitoringView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Switch>
                <Redirect
                    path={routes.customer(this.context.customer.slug).monitoring.index}
                    to={routes.customer(this.context.customer.slug).monitoring.agenda}
                    exact={true}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).monitoring.agenda}
                    component={MonitoringAgendaView}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).monitoring.reports.index}
                    component={MonitoringReportsView}
                />
            </Switch>
        )
    }
}
