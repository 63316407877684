import React from 'react'

import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { localize } from '~/bootstrap'
import { ControlTypeSelect } from '../../Compliance/Controls/ControlTypeSelect'
import { ControlVisibility } from '~/generated/graphql'
import { ControlVisibleToDepartmentsSelect } from '../../Compliance/Controls/ControlVisibleToDepartmentsSelect'
import { ControlVisibilitySelect } from '../../Compliance/Controls/ControlVisibilitySelect'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import subDays from 'date-fns/sub_days'
import addDays from 'date-fns/add_days'
import { EmployeeSelect } from '../../Customer/EmployeeSelect'

interface Props {}

interface State {
    startDate: Date | null
    revisionDate: Date | null
    selectedControlVisibility: ControlVisibility
}

export class ControlFormFields extends React.PureComponent<Props, State> {
    public state: State = {
        startDate: null,
        revisionDate: null,
        selectedControlVisibility: ControlVisibility.ownerDepartment,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.ControlFormFields)

    public render() {
        const { startDate, revisionDate, selectedControlVisibility } = this.state

        return (
            <>
                <Field label={this.loc(t => t.titleLabel)} forInput={'name'}>
                    <Form.Input name={'name'} placeholder={this.loc(t => t.titlePlaceholder)} autoComplete={false} />
                </Field>
                <Field label={this.loc(t => t.controlTypeLabel)} forInput={'type'}>
                    <ControlTypeSelect name={'type'} />
                </Field>
                <Field label={this.loc(t => t.descriptionLabel)} forInput={'description'}>
                    <Form.TextEditor name="description" placeholder={this.loc(t => t.descriptionPlaceholder)} />
                </Field>
                <Field label={this.loc(t => t.startDate)} forInput={'startDate'}>
                    <Form.DatePicker
                        name={'startDate'}
                        onChange={(startDate: Date | null) => this.setState({ startDate })}
                        maxDate={revisionDate ? subDays(revisionDate, 1) : undefined}
                    />
                </Field>
                <Field label={this.loc(t => t.revisionDate)} forInput={'revisionDate'}>
                    <Form.DatePicker
                        name={'revisionDate'}
                        onChange={(revisionDate: Date | null) => this.setState({ revisionDate })}
                        minDate={startDate ? addDays(startDate, 1) : undefined}
                        clearable={true}
                        repeatable={true}
                    />
                </Field>
                <Field label={this.loc(t => t.responsibleEmployees)} forInput={'responsibleEmployees'}>
                    <EmployeeSelect clearable={true} creatable={true} name={'responsibleEmployees'} />
                </Field>
                <Field label={this.loc(t => t.visibilityLabel)} forInput={'visibility'}>
                    <ControlVisibilitySelect
                        name={'visibility'}
                        defaultVisibility={ControlVisibility.ownerDepartment}
                        onChange={(option: SelectOption) => {
                            this.setState({
                                selectedControlVisibility: option.value as ControlVisibility,
                            })
                        }}
                    />
                </Field>
                {selectedControlVisibility === ControlVisibility.selectionOfDepartments && (
                    <Field
                        label={this.loc(t => t.visibleToDepartmentsLabel)}
                        forInput={'visibleToDepartments'}
                        isNested={true}
                    >
                        <ControlVisibleToDepartmentsSelect name={'visibleToDepartments'} defaultDepartments={[]} />
                    </Field>
                )}
            </>
        )
    }
}
