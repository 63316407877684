import React from 'react'
import { Query, Mutation, MutationFn } from 'react-apollo'
import gql from 'graphql-tag'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { PinnedTopicsDocument, TopicsForThemeFilters } from '~/generated/graphql'
import { DepartmentTopicDataMetadataType } from '~/generated/graphql'

interface Props {
    themeId: number
    children: (context: PinnedTopicsOptions) => React.ReactNode
    topicDnEFilters: TopicsForThemeFilters
}

export interface PinnedTopicsOptions {
    topics: PinnedTopic[]
    loading: boolean
    pin: PinFN
    unpin: PinFN
}

export type PinFN = (id: number, themeId: number) => void

const PIN_TOPIC_MUTATION = gql`
    mutation pinTopic($id: Int!) {
        pinItem(id: $id, type: TOPIC)
    }
`

const UNPIN_TOPIC_MUTATION = gql`
    mutation unpinTopic($id: Int!) {
        unpinItem(id: $id, type: TOPIC)
    }
`

export interface PinnedTopic {
    id: number
    name: string
    customerFrameworks: CustomerFramework[]
    nonApplicable: {
        id: number
        reason: string | null
    }
    departmentTopicData?: {
        id: number
        topicAssessmentDesignMetadata?: DepartmentTopicDataMetadataType
        topicAssessmentEffectivenessMetadata?: DepartmentTopicDataMetadataType
    }
}

interface PinnedTopicsResponse {
    pinned: {
        topics: PinnedTopic[]
    }
}

export class PinnedTopicsQuery extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, themeId, topicDnEFilters } = this.props
        const customerFrameworkIds = this.context.activeProfiles

        const variables = {
            themeIds: [themeId],
            customerFrameworkIds,
            departmentId: this.context.activeDepartmentId,
            topicDnEFilters: {
                ...topicDnEFilters,
                departmentId: this.context.activeDepartmentId,
            },
        }
        const refetchQueries = [{ query: PinnedTopicsDocument, variables }]

        return (
            <Mutation<any> mutation={UNPIN_TOPIC_MUTATION} refetchQueries={refetchQueries}>
                {unpinTopic => (
                    <Mutation<any> mutation={PIN_TOPIC_MUTATION} refetchQueries={refetchQueries}>
                        {pinTopic => (
                            <Query<PinnedTopicsResponse> query={PinnedTopicsDocument} variables={variables}>
                                {({ data, loading, refetch }) =>
                                    children({
                                        topics: this.getTopics(data),
                                        loading,
                                        pin: id => this.handlePinning(id, pinTopic, refetch),
                                        unpin: id => this.handlePinning(id, unpinTopic, refetch),
                                    })
                                }
                            </Query>
                        )}
                    </Mutation>
                )}
            </Mutation>
        )
    }

    private async handlePinning(id: number, mutate: MutationFn, refetch: Function) {
        await mutate({ variables: { id } })

        refetch()
    }

    private getTopics(data?: PinnedTopicsResponse) {
        if (data && data.pinned && data.pinned.topics) {
            return data.pinned.topics
        }

        return []
    }
}
