import './Table.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { SortHeader } from '~/components/Core/DataDisplay/Table/SortHeader'
import { localize } from '~/bootstrap'
import { CSSTransition } from 'react-transition-group'
import { Icon } from '../../Icon/Icon'
import { IconType } from '../../Icon/IconType'
import { Row } from '../../Layout/Row'
import { TableHeaderLabel } from './TableHeaderLabel'
import { Checkbox } from '../../DataEntry/Form/Checkbox'
import { LocalStorageService, LocalStorageKeys } from '~/services/LocalStorageService'

type RowDataChildren = Columns[] | (() => JSX.Element)

export type SortDirection = 'ASC' | 'DESC' | null

export type SortDirectionChangeHandler = (field: string, direction: SortDirection) => void

export type RowActionFn<TRowData extends RowData = RowData> = (context: {
    row: TRowData
    toggleRowExpansion: (() => void) | undefined
    rowIsExpanded: boolean
    index: number
}) => React.ReactNode

interface Columns {
    [fieldName: string]: string | number | JSX.Element | null | any
}

export interface RowData {
    id: string | number
    columns: Columns
    inactive?: boolean
    isSelected?: boolean
    expandable?: () => JSX.Element
    children?: RowDataChildren
    onCheckChange?: (id: number | string, checked: boolean) => void
    isCheckboxActive?: boolean
    isCheckboxChecked?: boolean
}

export interface ColumnOptions {
    field: string
    headerLabel: string | null | JSX.Element
    noAutoWidth?: boolean
    expandsRow?: boolean
    truncateField?: boolean
    align?: 'left' | 'center' | 'right'
    hideLabelFromDisplay?: boolean
    sortable?: boolean
    noLineBreak?: boolean
    minWidth?: number
    maxWidth?: number
}

export interface SortOption {
    field: string
    direction: SortDirection
}

export interface SortState {
    sortDirection: SortOption
}

interface Props<TRowData extends RowData> {
    className?: ClassValue
    data: TRowData[]
    columns: ColumnOptions[]
    loading?: boolean
    loadingMore?: boolean
    emptyState?: string | JSX.Element
    hideColumnsWhenEmpty?: boolean
    onSortDirectionChange?: SortDirectionChangeHandler
    endReached?: boolean
    hideHeaders?: boolean
    hideEmptyState?: boolean
    hideEndReachedMark?: boolean
    rowAction?: RowActionFn<TRowData>
    defaultSortDirection?: SortOption
    onRowClick?: (rowData: TRowData, rowIndex: number) => void
    customLoadingComponent?: JSX.Element
    hideInitialCheckboxAnimation?: boolean
    whiteBackground?: boolean
}

interface State {
    expandedRows: Set<string>
}

export class Table<TRowData extends RowData = RowData> extends React.Component<Props<TRowData>, State> {
    public state: State = {
        expandedRows: new Set<string>(),
    }

    private bem = new BEM('Table', () => ({
        'is-loading': !!this.props.loading,
        'is-loading-more': !!this.props.loadingMore,
        'hide-headers': this.props.hideHeaders,
        'white-background': this.props.whiteBackground,
    }))

    private buttonRefs = new Set<SortHeader>()

    public render() {
        const { className } = this.props

        return <div className={this.bem.getClassName(className)}>{this.renderTable()}</div>
    }

    public componentWillUnmount() {
        const { data } = this.props

        const hasCheckboxes = data.find(row => !!row.onCheckChange)

        if (hasCheckboxes && !this.hasSeenCardAnimation()) {
            LocalStorageService.setItem(LocalStorageKeys.SawCardAnimation, 'true')
        }
    }

    public handleToggleRowExpansion = (id: number | string) => {
        const idStringified = id.toString()
        return () => {
            this.setState(state => {
                if (state.expandedRows.has(idStringified)) {
                    state.expandedRows.delete(idStringified)

                    return {
                        expandedRows: state.expandedRows,
                    }
                }

                state.expandedRows.add(idStringified)

                return {
                    expandedRows: state.expandedRows,
                }
            })
        }
    }

    private renderTable() {
        const { loading, rowAction, loadingMore, endReached, data, hideEndReachedMark, hideColumnsWhenEmpty } =
            this.props

        const shouldShowHeaders = !hideColumnsWhenEmpty || !this.isEmpty()

        return (
            <>
                <table className={this.bem.getElement('table')}>
                    {shouldShowHeaders && (
                        <thead className={this.bem.getElement('head')}>
                            <tr className={this.bem.getElement('row')}>
                                {/* tslint:disable-next-line:jsx-use-translation-function */}
                                {this.isExpandable() && <th style={{ width: 24 }}>&nbsp;</th>}
                                {this.renderHeaders()}
                                {/* tslint:disable-next-line:jsx-use-translation-function */}
                                {rowAction && <th>&nbsp;</th>}
                            </tr>
                        </thead>
                    )}
                    <tbody className={this.bem.getElement('body')}>
                        {this.renderRows()}
                        {loadingMore && (
                            <tr>
                                <td colSpan={this.getColumnLength()}>
                                    <Spinner />
                                </td>
                            </tr>
                        )}
                        {!hideEndReachedMark && endReached && data && data.length > 0 && (
                            <tr className={this.bem.getElement('row')}>
                                <td colSpan={this.getColumnLength()}>
                                    {localize.translate(t => t.Core.Table.endReached)}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {loading && this.renderLoadingState()}
            </>
        )
    }

    private isEmpty() {
        const { data, loading, loadingMore } = this.props

        return !data.length && !loading && !loadingMore
    }

    private renderHeaders() {
        const { columns, defaultSortDirection } = this.props

        return columns.map(col => {
            return (
                <th
                    className={this.bem.getElement('header', () => ({
                        [`align-${col.align}`]: !!col.align,
                        'no-auto-width': col.noAutoWidth,
                        'no-line-break': col.noLineBreak,
                    }))}
                    key={`field-${col.field}`}
                    style={{
                        width: col.noAutoWidth ? '1px' : undefined,
                        minWidth: col.minWidth ? `${col.minWidth}px` : undefined,
                        maxWidth: col.maxWidth ? `${col.maxWidth}px` : undefined,
                    }}
                >
                    {col.sortable ? (
                        <SortHeader
                            key={col.field}
                            sortDirection={
                                defaultSortDirection && defaultSortDirection.field === col.field
                                    ? defaultSortDirection.direction
                                    : null
                            }
                            onSort={this.handleSortDirectionChange(col.field)}
                            ref={ref => (ref ? this.buttonRefs.add(ref) : undefined)}
                        >
                            <TableHeaderLabel label={col.headerLabel} hideLabelFromDisplay={col.hideLabelFromDisplay} />
                        </SortHeader>
                    ) : (
                        <TableHeaderLabel label={col.headerLabel} hideLabelFromDisplay={col.hideLabelFromDisplay} />
                    )}
                </th>
            )
        })
    }

    private renderRows() {
        const {
            data,
            rowAction,
            emptyState,
            loading,
            loadingMore,
            columns,
            hideEmptyState,
            onRowClick,
            customLoadingComponent,
        } = this.props
        const { expandedRows } = this.state

        if (!data && !loading && !loadingMore) {
            return (
                <tr className={this.bem.getElement('row')}>
                    <td className={this.bem.getElement('cell')} colSpan={this.getColumnLength()}>
                        {localize.translate(t => t.Core.Table.noDataFound)}
                    </td>
                </tr>
            )
        }

        if (!data.length && !loading && !loadingMore) {
            if (hideEmptyState) {
                return
            }

            return (
                <tr className={this.bem.getElement('row')}>
                    <td className={this.bem.getElement('cell')} colSpan={this.getColumnLength()}>
                        {emptyState ? emptyState : localize.translate(t => t.Core.Table.noDataFound)}
                    </td>
                </tr>
            )
        }

        if (loading && customLoadingComponent) {
            // Don't render any rows if the data is loading and there is a custom loading component,
            return
        }

        const rowActionClassName = this.bem.getElement('row-action')

        return data.map((rowData, rowIndex) => {
            const rowIsExpanded = expandedRows.has(rowData.id.toString())
            const rowClickHandler = onRowClick ? () => onRowClick(rowData, rowIndex) : undefined

            return (
                <React.Fragment key={`row-${rowData.id}`}>
                    <tr
                        className={this.bem.getElement('row', () => ({
                            'is-expanded': rowIsExpanded,
                            'has-children': !!rowData.children,
                            'is-inactive': !!rowData.inactive,
                            'is-clickable': !!onRowClick,
                        }))}
                        onClick={rowClickHandler}
                    >
                        {rowData.children && (
                            <td className={this.bem.getElement('cell', () => ({ expandable: true }))}>
                                <button
                                    type={'button'}
                                    onClick={this.handleToggleRowExpansion(rowData.id)}
                                    className={this.bem.getElement('expand-button')}
                                >
                                    <Icon type={IconType.arrowDown} />
                                </button>
                            </td>
                        )}
                        {/* tslint:disable-next-line:jsx-use-translation-function */}
                        {!rowData.children && this.isExpandable() && (
                            <td className={this.bem.getElement('cell')}>&nbsp;</td>
                        )}
                        {Object.keys(rowData.columns)
                            .filter(field => columns.find(h => h.field === field))
                            .map((rowField, index) => {
                                const columnOptions = columns.find(h => h.field === rowField) as ColumnOptions

                                return (
                                    <td
                                        className={this.bem.getElement('cell', () => ({
                                            truncate: columnOptions.truncateField,
                                            [`align-${columnOptions.align}`]: !!columnOptions.align,
                                            [`no-auto-width`]: !!columnOptions.noAutoWidth,
                                            'no-line-break': columnOptions.noLineBreak,
                                            'has-checkbox': index === 0 && !!rowData.onCheckChange,
                                            'is-checked':
                                                index === 0 && !!rowData.onCheckChange && rowData.isCheckboxChecked,
                                            'is-checkbox-active':
                                                index === 0 && !!rowData.onCheckChange && rowData.isCheckboxActive,
                                        }))}
                                        key={`${rowData.id}-${index}`}
                                        style={{
                                            minWidth: columnOptions.minWidth
                                                ? `${columnOptions.minWidth}px`
                                                : undefined,
                                            maxWidth: columnOptions.maxWidth
                                                ? `${columnOptions.maxWidth}px`
                                                : undefined,
                                        }}
                                    >
                                        {this.renderTableCellContent(index, rowData, rowField, columnOptions, rowIndex)}
                                    </td>
                                )
                            })}
                        {rowAction && (
                            <td className={rowActionClassName}>
                                <Row alignRight={true}>
                                    {rowAction({
                                        row: rowData,
                                        toggleRowExpansion: rowData.expandable
                                            ? this.handleToggleRowExpansion(rowData.id)
                                            : undefined,
                                        rowIsExpanded: rowIsExpanded,
                                        index: rowIndex,
                                    })}
                                </Row>
                            </td>
                        )}
                    </tr>
                    {rowData.children &&
                        rowIsExpanded &&
                        (Array.isArray(rowData.children) ? (
                            rowData.children.map(this.renderChild)
                        ) : (
                            <tr className={this.bem.getElement('expanded-row')} key={`expandable-row-${rowData.id}`}>
                                <td colSpan={this.getColumnLength()}>{rowData.children()}</td>
                            </tr>
                        ))}
                </React.Fragment>
            )
        })
    }

    private renderTableCellContent(
        index: number,
        rowData: RowData,
        rowField: string,
        columnOptions: ColumnOptions,
        rowIndex: number
    ) {
        if (index === 0 && rowData.onCheckChange) {
            return (
                <>
                    {this.renderCheckboxContainer(
                        rowData.onCheckChange,
                        rowData.id,
                        rowIndex === 0,
                        rowData.isCheckboxChecked
                    )}
                    <div className={this.bem.getElement('checkbox-content-wrapper')}>{rowData.columns[rowField]}</div>
                </>
            )
        }

        if (columnOptions.expandsRow) {
            return (
                <button
                    type="button"
                    onClick={this.handleToggleRowExpansion(rowData.id)}
                    className={this.bem.getElement('plain-button')}
                >
                    {rowData.columns[rowField]}
                </button>
            )
        }

        return rowData.columns[rowField]
    }

    private renderChild = (field: Columns, index: number) => {
        const { columns } = this.props

        return (
            <CSSTransition key={index} in={true} timeout={200} appear={true} classNames={'expandableContainer'}>
                <tr className={this.bem.getElement('row', () => ({ 'is-child': true }))}>
                    {/* tslint:disable-next-line:jsx-use-translation-function */}
                    <td>&nbsp;</td>
                    {Object.keys(field)
                        .filter(field => columns.find(h => h.field === field))
                        .map((rowField, index) => {
                            const header = columns.find(h => h.field === rowField)

                            return (
                                <td
                                    className={this.bem.getElement('cell', () => ({
                                        [`align-${header!.align}`]: !!header!.align,
                                    }))}
                                    key={`cell-${columns.length}-${index}`}
                                >
                                    {field[rowField]}
                                </td>
                            )
                        })}
                </tr>
            </CSSTransition>
        )
    }

    private getColumnLength() {
        const { columns, rowAction } = this.props

        if (this.isExpandable() && rowAction) {
            return columns.length + 2
        }

        if (this.isExpandable() || rowAction) {
            return columns.length + 1
        }

        return columns.length
    }

    private handleSortDirectionChange = (field: string) => {
        const { onSortDirectionChange } = this.props

        return (direction: SortDirection) => {
            this.buttonRefs.forEach(ref => {
                if (field !== (ref as any)._reactInternalFiber.key) {
                    ref.reset()
                }
            })

            if (!onSortDirectionChange) {
                return () => {
                    /* no-op */
                }
            }

            return onSortDirectionChange(field, direction)
        }
    }

    private isExpandable() {
        const { data } = this.props
        return data.some(row => !!row.children)
    }

    private renderLoadingState() {
        const { customLoadingComponent } = this.props

        if (!customLoadingComponent) {
            return (
                <div className={this.bem.getElement('loading')}>
                    <Spinner delayed={true} />
                </div>
            )
        }

        return customLoadingComponent
    }

    private renderCheckboxContainer(
        onCheckChange: (id: number | string, checked: boolean) => void,
        id: number | string,
        isFirst: boolean,
        checked?: boolean
    ) {
        const { hideInitialCheckboxAnimation } = this.props

        if (isFirst && !hideInitialCheckboxAnimation && !this.hasSeenCardAnimation()) {
            return (
                <CSSTransition
                    classNames={'rf-Table__checkbox'}
                    in={true}
                    timeout={1000}
                    appear={true}
                    unmountOnExit={true}
                >
                    <div className={this.bem.getElement('checkbox-container-outer')}>
                        <div className={this.bem.getElement('checkbox-container-inner')}>
                            <Checkbox
                                large
                                checked={checked}
                                name={'check'}
                                onChange={checked => onCheckChange(id, checked)}
                            />
                            <div className={this.bem.getElement('border-container')}></div>
                        </div>
                    </div>
                </CSSTransition>
            )
        }

        return (
            <div className={this.bem.getElement('checkbox-container-outer')}>
                <div className={this.bem.getElement('checkbox-container-inner')}>
                    <Checkbox large checked={checked} name={'check'} onChange={checked => onCheckChange(id, checked)} />
                    <div className={this.bem.getElement('border-container')}></div>
                </div>
            </div>
        )
    }

    private hasSeenCardAnimation() {
        return LocalStorageService.getItem(LocalStorageKeys.SawCardAnimation) === 'true'
    }
}
