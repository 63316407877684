import './TaskTemplateViewModalContainer.scss'

import React from 'react'
import { localize, notification, permissions } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { TaskTags } from '../TaskTags/TaskTags'
import { SubTaskTemplatesTableContainer } from './SubTaskTemplatesTableContainer'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Icon } from '~/components/Core/Icon/Icon'
import { EditTaskTemplateModalContainer } from './EditTaskTemplateModalContainer'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { ArchiveTaskTemplate, ArchiveTaskTemplateFN } from '../Mutations/ArchiveTaskTemplate'
import { PageQuery } from '../../PageQuery/PageQuery'
import {
    TaskTagType,
    TaskTemplateQuery,
    TaskTemplateQueryVariables,
    TaskTemplateDocument,
    TaskTemplateType,
    ConsultantTaskTemplateQuery,
    ConsultantTaskTemplateDocument,
    ConsultantTaskTemplateQueryVariables,
} from '~/generated/graphql'
import { FrameworkAbbreviationList } from '../../Customer/Framework/FrameworkAbbreviationList/FrameworkAbbreviationList'
import {
    ArchiveConsultantTaskTemplate,
    ArchiveConsultantTaskTemplateFN,
} from '../ConsultantTaskTemplate/mutations/ArchiveConsultantTaskTemplate'

interface Props {
    requestClose: () => void
    taskTemplateId: number
    onMutateTaskTemplate?: () => void
    isSubTask?: boolean
    parentTaskTemplateId?: number
    parentTaskTemplateName?: string
    forConsultantTemplate?: boolean
    forSuggestedTemplate?: boolean
}

type QueryResult = ConsultantTaskTemplateQuery['consultantTaskTemplate'] | TaskTemplateQuery['taskTemplate']
type ArchiveMutationFN = ArchiveConsultantTaskTemplateFN | ArchiveTaskTemplateFN

interface State {
    taskTemplate?: QueryResult
}

export class TaskTemplateViewModalContainer extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {}

    private loc = localize.namespaceTranslate(t => t.Customer.Settings.TaskTemplatesOverviewView.TaskTemplateViewModal)
    private bem = new BEM('TaskTemplateViewModalContainer')
    private refetch: Function | undefined

    public render() {
        const { requestClose, onMutateTaskTemplate } = this.props

        return (
            <Modal
                title={this.loc(t => t.title)}
                actions={() => this.renderActionButtons()}
                requestClose={() => {
                    if (onMutateTaskTemplate) {
                        onMutateTaskTemplate()
                    }

                    requestClose()
                }}
                className={this.bem.getClassName()}
            >
                {this.renderModalContent()}
            </Modal>
        )
    }

    private renderModalContent() {
        const { taskTemplateId, forConsultantTemplate, forSuggestedTemplate } = this.props

        if (forConsultantTemplate || forSuggestedTemplate) {
            return (
                <PageQuery<ConsultantTaskTemplateQuery['consultantTaskTemplate'], ConsultantTaskTemplateQueryVariables>
                    query={ConsultantTaskTemplateDocument}
                    variables={{ consultantTaskTemplateId: taskTemplateId }}
                    fetchPolicy="no-cache"
                >
                    {(data, { refetch }) => this.renderData(data, refetch)}
                </PageQuery>
            )
        }

        return (
            <PageQuery<TaskTemplateQuery['taskTemplate'], TaskTemplateQueryVariables>
                query={TaskTemplateDocument}
                variables={{ taskTemplateId: taskTemplateId, customerId: this.context.customer.id }}
                fetchPolicy="no-cache"
            >
                {(data, { refetch }) => this.renderData(data, refetch)}
            </PageQuery>
        )
    }

    private renderActionButtons() {
        const { forConsultantTemplate, forSuggestedTemplate } = this.props
        const { taskTemplate } = this.state

        if (forSuggestedTemplate || !taskTemplate) {
            return <></>
        }

        if (forConsultantTemplate) {
            return (
                <ArchiveConsultantTaskTemplate>
                    {(mutate, { loading }) => (
                        <Row spaceBetween={true}>
                            {this.renderArchiveButton(mutate, loading)}
                            {this.renderEditButton(taskTemplate, loading)}
                        </Row>
                    )}
                </ArchiveConsultantTaskTemplate>
            )
        }

        return (
            <ArchiveTaskTemplate>
                {(mutate, { loading }) => (
                    <Row spaceBetween={true}>
                        {this.renderArchiveButton(mutate, loading)}
                        {this.renderEditButton(taskTemplate, loading)}
                    </Row>
                )}
            </ArchiveTaskTemplate>
        )
    }

    private renderData(taskTemplate: QueryResult, refetch: Function) {
        if (!taskTemplate) {
            return null
        }

        if (!this.state.taskTemplate) {
            this.setState({ taskTemplate })
        }

        if (!this.refetch) {
            this.refetch = refetch
        }

        const { description } = taskTemplate
        const tags = 'tags' in taskTemplate ? taskTemplate.tags : undefined

        return (
            <Column bigSpacing={true}>
                {this.renderConsultantFields(taskTemplate)}
                {this.renderName(taskTemplate.name)}
                <Paragraph className={this.bem.getElement('description')}>{description}</Paragraph>
                {this.renderTags(tags)}
                {this.renderSubTasks(taskTemplate.name, taskTemplate.subTaskTemplates as TaskTemplateType[])}
            </Column>
        )
    }

    private renderArchiveButton(mutate: ArchiveMutationFN, loading: boolean) {
        const { isSubTask } = this.props

        const isDisabled = this.props.forConsultantTemplate
            ? !permissions.isConsultantUser()
            : !permissions.isCustomerAdministrator()

        return (
            <ConfirmModal
                children={openModal => (
                    <Button
                        type={ButtonType.secondary}
                        loading={loading}
                        disabled={isDisabled}
                        onClick={openModal}
                        danger={true}
                    >
                        {localize.translate(t => t.Generic.delete)}
                    </Button>
                )}
                title={localize.translate(t => t.Generic.delete)}
                message={isSubTask ? this.loc(t => t.deleteSubtaskMessage) : this.loc(t => t.deleteMaintaskMessage)}
                loading={loading}
                onConfirm={this.handleArchive(mutate)}
                errorPath="archiveTaskTemplate"
            />
        )
    }

    private renderEditButton(taskTemplate: QueryResult, loading: boolean) {
        const { isSubTask, parentTaskTemplateName, forConsultantTemplate } = this.props
        const isDisabled = forConsultantTemplate
            ? !permissions.isConsultantUser()
            : !permissions.isCustomerAdministrator()

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} loading={loading} disabled={isDisabled}>
                        {localize.translate(t => t.Generic.edit)}
                    </Button>
                )}
                renderModal={closeModal => (
                    <EditTaskTemplateModalContainer
                        refetch={this.handleRefetch}
                        requestClose={closeModal}
                        isSubTask={isSubTask}
                        parentTaskName={parentTaskTemplateName}
                        taskTemplate={taskTemplate as TaskTemplateType}
                        forConsultantTemplate={forConsultantTemplate}
                    />
                )}
            />
        )
    }

    private handleArchive = (mutate: ArchiveMutationFN) => async (closeModal: () => void) => {
        const { onMutateTaskTemplate, requestClose, taskTemplateId, forConsultantTemplate } = this.props

        const customerId = forConsultantTemplate ? undefined : this.context.customer.id

        const response = await mutate({ variables: { taskTemplateId, customerId: customerId! } })

        if (
            response &&
            response.data &&
            ('archiveTaskTemplate' in response.data || 'archiveConsultantTaskTemplate' in response.data)
        ) {
            notification.success(localize.translate(t => t.Generic.successfullyDeleted))
            if (onMutateTaskTemplate) {
                onMutateTaskTemplate()
            }

            closeModal()
            requestClose()
        }
    }

    private renderConsultantFields(taskTemplate: QueryResult) {
        const { forConsultantTemplate, isSubTask } = this.props

        if (isSubTask || !taskTemplate) {
            return
        }

        if (!('type' in taskTemplate)) {
            return
        }

        const { type, frameworks } = taskTemplate

        return (
            <Row smallSpacing={true}>
                <Paragraph subtle={true}>
                    {type &&
                        localize.translate(t => t.Customer.Settings.TaskTemplatesOverviewView.TaskTemplateModal[type])}
                </Paragraph>
                {forConsultantTemplate && <FrameworkAbbreviationList frameworks={frameworks} />}
            </Row>
        )
    }

    private renderName(name: string) {
        const { isSubTask, parentTaskTemplateName, parentTaskTemplateId, onMutateTaskTemplate } = this.props
        const { forConsultantTemplate, forSuggestedTemplate } = this.props

        return (
            <Column smallSpacing={true}>
                {isSubTask && parentTaskTemplateId !== undefined && (
                    <ModalManager
                        render={openModal => (
                            <Button type={ButtonType.noStyling} onClick={() => openModal()}>
                                <Row smallSpacing={true}>
                                    <Icon type={IconType.parentTask} />
                                    <Paragraph bold={true}>{parentTaskTemplateName}</Paragraph>
                                </Row>
                            </Button>
                        )}
                        renderModal={closeModal => (
                            <TaskTemplateViewModalContainer
                                requestClose={closeModal}
                                taskTemplateId={parentTaskTemplateId}
                                isSubTask={false}
                                onMutateTaskTemplate={onMutateTaskTemplate}
                                forConsultantTemplate={forConsultantTemplate}
                                forSuggestedTemplate={forSuggestedTemplate}
                            />
                        )}
                    />
                )}
                <SectionTitle bold={true}>{name}</SectionTitle>
            </Column>
        )
    }

    private renderTags(tags?: TaskTagType[] | null) {
        if (!tags?.length) {
            return
        }

        const label = localize.translate(t => t.Customer.Settings.TaskTemplatesOverviewView.TableHeaders.tags)

        return (
            <Column smallSpacing={true}>
                <Field forInput="tags" label={label} />
                <TaskTags tags={tags} />
            </Column>
        )
    }

    private renderSubTasks(name: string, subTaskTemplates?: TaskTemplateType[]) {
        const { isSubTask, taskTemplateId, forConsultantTemplate } = this.props
        const { forSuggestedTemplate } = this.props

        if (isSubTask) {
            return
        }

        return (
            <SubTaskTemplatesTableContainer
                refetch={this.handleRefetch}
                subTasks={subTaskTemplates || []}
                parentTaskTemplateName={name}
                parentTaskTemplateId={taskTemplateId}
                forConsultantTemplate={forConsultantTemplate}
                forSuggestedTemplate={forSuggestedTemplate}
            />
        )
    }

    private handleRefetch = () => {
        if (this.refetch) {
            this.refetch()
        }
    }
}
