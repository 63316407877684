import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import {
    CreateCustomerUsersExportMutation,
    CreateCustomerUsersExportDocument,
    CreateCustomerUsersExportMutationVariables,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

export type CreateCustomerUsersExportMutationFN = MutationFn<
    CreateCustomerUsersExportMutation,
    CreateCustomerUsersExportMutationVariables
>

interface Props {
    children: (
        mutate: CreateCustomerUsersExportMutationFN,
        result: MutationResult<CreateCustomerUsersExportMutation>
    ) => React.ReactNode
}

export class CreateCustomerUsersExport extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<CreateCustomerUsersExportMutation, CreateCustomerUsersExportMutationVariables>
                mutation={CreateCustomerUsersExportDocument}
            >
                {(mutate, result) => children(mutate, result)}
            </GQLMutation>
        )
    }
}
