import React from 'react'

import { AppNotFoundView } from '~/views/AppNotFoundView'
import { RouteComponentProps, Route, Switch, Redirect } from 'react-router-dom'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AssessmentsView } from './Assessments/AssessmentsView'
import { HeatmapView } from './HeatmapView'

interface Props extends RouteComponentProps<{}> {}

export class RisksView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Switch>
                <Redirect
                    path={routes.customer(this.context.customer.slug).risks.index}
                    to={routes.customer(this.context.customer.slug).risks.heatmap.index}
                    exact={true}
                />
                <Route path={routes.customer(this.context.customer.slug).risks.heatmap.index} component={HeatmapView} />
                <Route
                    path={routes.customer(this.context.customer.slug).risks.assessments.index}
                    component={AssessmentsView}
                />

                <Route component={AppNotFoundView} />
            </Switch>
        )
    }
}
