import React from 'react'

import { UserService } from '~/services/UserService'

import { User, Consultant, EmployeeWithPassiveDepartmentEmployees } from '~/graphql/types/User'
import { Query } from 'react-apollo'
import {
    CURRENT_USER_QUERY,
    CurrentUserQueryResponse,
    UserEmployeeWithCustomer,
} from '~/graphql/queries/CurrentUserQuery'
import { LocalStorageKeys, LocalStorageService } from '~/services/LocalStorageService'
import { Language } from '~/generated/graphql'

export interface UserContextValue {
    user?: User
    employee?: EmployeeWithPassiveDepartmentEmployees
    userEmployees?: UserEmployeeWithCustomer[]
    consultant?: Consultant
    loading: boolean
    refetch: Function
}

interface Props {
    userClient: UserService
}

export const UserContext = React.createContext<UserContextValue>({
    loading: true,
    user: undefined,
    refetch: () => ({}),
})

export const UserConsumer = UserContext.Consumer

const providers = new Set<UserProvider>()

export class UserProvider extends React.Component<Props, UserContextValue> {
    private refetch: Function

    public componentDidMount() {
        providers.add(this)
    }

    public componentWillUnmount() {
        if (providers.has(this)) {
            providers.delete(this)
        }
    }

    public static async refetch() {
        const promises: Promise<any>[] = []
        providers.forEach(provider => {
            promises.push(provider.refetch())
        })

        await Promise.all(promises)
    }

    public render() {
        const { children } = this.props

        return (
            <Query<CurrentUserQueryResponse> query={CURRENT_USER_QUERY} notifyOnNetworkStatusChange={true}>
                {({ data, loading, refetch }) => {
                    this.refetch = refetch

                    const user = data ? data.getAuthenticatedUser || undefined : undefined
                    const employee = data ? data.employee || undefined : undefined
                    const userEmployees = data ? data.userEmployees || undefined : undefined
                    const consultant = data ? data.consultant || undefined : undefined

                    if (user) {
                        const language = user.language ? user.language : Language.nl
                        LocalStorageService.setItem(LocalStorageKeys.Language, language)

                        if (user.loggedInWithSSO) {
                            LocalStorageService.setItem(LocalStorageKeys.LastSuccessfulSSOLoginEmail, user.email)
                        } else {
                            LocalStorageService.removeItem(LocalStorageKeys.LastSuccessfulSSOLoginEmail)
                        }
                    }

                    return (
                        <UserContext.Provider
                            value={{
                                loading,
                                user,
                                employee,
                                userEmployees,
                                consultant,
                                refetch,
                            }}
                        >
                            {children}
                        </UserContext.Provider>
                    )
                }}
            </Query>
        )
    }
}
