import './RadioGroup.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { Row } from '../../Layout/Row'

interface Props {
    className?: ClassValue
}

export class RadioGroup extends React.PureComponent<Props> {
    private bem = new BEM('RadioGroup')

    public render() {
        const { children, className } = this.props

        return <Row className={this.bem.getClassName(className)}>{children}</Row>
    }
}
