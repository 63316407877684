import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { localize, notification, permissions } from '~/bootstrap'
import { ActionButton } from '~/components/Core/ActionButton/ActionButton'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Guard } from '~/components/Core/Guard/Guard'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CreateTaskActionButton } from '~/components/Domain/Task/CreateTaskActionButton'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { DepartmentType, MonitoringReportType } from '~/generated/graphql'
import { LinkedTaskItem } from '~/graphql/types/Task'
import { routes } from '~/views/routes'
import { UnarchiveMonitoringReports, UnarchiveMonitoringReportsMutationFN } from '../UnarchiveMonitoringReports'
import { ArchiveMonitoringReportModal } from './ArchiveMonitoringReportModal'

interface Props extends RouteComponentProps {
    monitoringReport: MonitoringReport
    refetch: () => void
}

type MonitoringReport = Pick<MonitoringReportType, 'id' | '__typename' | 'name' | 'archivedAt'> & {
    departments?: Pick<DepartmentType, 'id' | 'name'>[] | null
}

class MonitoringDetailActionButtons extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { monitoringReport, refetch } = this.props
        const canEdit = permissions.canEditMonitoringReport()

        return (
            <Row>
                <Guard condition={permissions.canCreateTasks(this.context.activeDepartmentId)}>
                    <CreateTaskActionButton
                        linkedItem={monitoringReport as LinkedTaskItem}
                        possibleDepartments={monitoringReport.departments || undefined}
                        refetch={refetch}
                    />
                </Guard>
                <Guard condition={canEdit && !monitoringReport.archivedAt}>{this.renderEditButton()}</Guard>
                <Guard condition={canEdit}>
                    {monitoringReport.archivedAt ? this.renderUnarchiveButton() : this.renderArchiveButton()}
                </Guard>
            </Row>
        )
    }

    private renderEditButton() {
        const { id } = this.props.monitoringReport
        const editPage = routes.customer(this.context.customer.slug).monitoring.reports.detail.edit(id)

        return (
            <ActionButton
                icon={IconType.edit}
                onClick={() => this.props.history.push(editPage)}
                tooltipContent={
                    <Paragraph small emphasis>
                        {localize.translate(t => t.Generic.edit)}
                    </Paragraph>
                }
            />
        )
    }

    private renderUnarchiveButton() {
        return (
            <UnarchiveMonitoringReports>
                {(mutate, { loading }) => (
                    <ActionButton
                        icon={IconType.unarchive}
                        tooltipContent={
                            <Paragraph small emphasis>
                                {localize.translate(t => t.Generic.dearchive)}
                            </Paragraph>
                        }
                        loading={loading}
                        onClick={this.handleUnarchive(mutate)}
                    />
                )}
            </UnarchiveMonitoringReports>
        )
    }

    private renderArchiveButton() {
        return (
            <ModalManager
                renderModal={closeModal => (
                    <ArchiveMonitoringReportModal
                        monitoringReport={this.props.monitoringReport}
                        requestClose={closeModal}
                    />
                )}
                render={openModal => (
                    <ActionButton
                        icon={IconType.archive}
                        onClick={openModal}
                        tooltipContent={
                            <Paragraph small emphasis>
                                {localize.translate(t => t.Generic.archive)}
                            </Paragraph>
                        }
                    />
                )}
            />
        )
    }

    private handleUnarchive = (mutate: UnarchiveMonitoringReportsMutationFN) => async () => {
        const response = await mutate({ variables: { ids: [this.props.monitoringReport.id] } })

        if (response && response.data && response.data.unarchiveMonitoringReports) {
            notification.success(localize.translate(t => t.Generic.successfullyUnarchived))
        }
    }
}

export default withRouter(MonitoringDetailActionButtons)
