import './RiskGraphIndicatorTooltip.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { Assessment, AssessmentLabel } from '../../Assessments/AssessmentLabel'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    className?: ClassValue
    title: string
    description: string
    assessment?: Assessment
}

interface State {}

export class RiskGraphIndicatorTooltip extends React.PureComponent<Props, State> {
    private bem = new BEM('RiskGraphIndicatorTooltip')

    public render() {
        const { className, title, description, assessment } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <ComponentTitle title={title} className={this.bem.getElement('title')} />
                <Paragraph className={this.bem.getElement('description')}>{description}</Paragraph>
                {assessment && (
                    <AssessmentLabel assessment={assessment} className={this.bem.getElement('assessment-label')} />
                )}
            </div>
        )
    }
}
