import React from 'react'

import { RouteComponentProps, Switch, Route } from 'react-router'
import { routes } from '~/views/routes'
import { LawAndRegulationOverviewView } from './LawAndRegulationOverviewView'
import { AppNotFoundView } from '../../AppNotFoundView'
import { LawAndRegulationDetailView } from './LawAndRegulationDetailView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { LawAndRegulationRedirectView } from '~/views/Customer/lawAndRegulation/LawAndRegulationRedirectView'
import { LawAndRegulationArticleDetailView } from '~/views/Customer/lawAndRegulation/LawAndRegulationArticleDetailView'

interface Props extends RouteComponentProps<{}> {}

export class LawAndRegulationView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Switch>
                <Route
                    path={routes.customer(this.context.customer.slug).lawAndRegulation.redirect()}
                    component={LawAndRegulationRedirectView}
                    exact={true}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).lawAndRegulation.detail.singleArticle()}
                    component={LawAndRegulationArticleDetailView}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).lawAndRegulation.index}
                    component={LawAndRegulationOverviewView}
                    exact={true}
                />
                <Route
                    path={routes.customer(this.context.customer.slug).lawAndRegulation.detail.view()}
                    component={LawAndRegulationDetailView}
                />
                <Route component={AppNotFoundView} />
            </Switch>
        )
    }
}
