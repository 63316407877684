import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import {
    DeleteDepartmentMutationVariables,
    DeleteDepartmentDocument,
    DeleteDepartmentMutation,
    AllDepartmentsDocument,
} from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    children: (
        mutation: MutationFn<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>,
        options: MutationResult
    ) => JSX.Element
}

export class DeleteDepartment extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>
                mutation={DeleteDepartmentDocument}
                refetchQueries={[
                    {
                        query: AllDepartmentsDocument,
                        variables: { customerSlug: this.context.customer.slug },
                    },
                ]}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
