import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import {
    CreateCustomersExportMutation,
    CreateCustomersExportDocument,
    CreateCustomersExportMutationVariables,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

export type CreateCustomersExportMutationFN = MutationFn<
    CreateCustomersExportMutation,
    CreateCustomersExportMutationVariables
>

interface Props {
    children: (
        mutate: CreateCustomersExportMutationFN,
        result: MutationResult<CreateCustomersExportMutation>
    ) => React.ReactNode
}

export class CreateCustomersExport extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<CreateCustomersExportMutation, CreateCustomersExportMutationVariables>
                mutation={CreateCustomersExportDocument}
            >
                {(mutate, result) => children(mutate, result)}
            </GQLMutation>
        )
    }
}
