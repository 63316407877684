import './ReviewSubTasksTable.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { TaskNameWithCompleteButton } from './TaskNameWithCompleteButton'
import { TaskDetailFields } from '~/graphql/types/Task'
import { Task } from '~/views/Customer/Tasks/TasksQuery/TaskQuery'
import { LinkedTaskLabel } from './LinkedTaskLabel'
import { Table } from '~/components/Core/DataDisplay/Table/Table'
import { LabelTag } from '~/components/Core/DataDisplay/LabelTag/LabelTag'

interface Props {
    // added to comply with the legacy code
    linkedTasks: (TaskDetailFields | Task)[]
    className?: ClassValue
    isForTooltip?: boolean
}

export class ReviewSubTasksTable extends React.PureComponent<Props> {
    private bem = new BEM('ReviewSubTasksTable', () => ({
        'is-for-tooltip': this.props.isForTooltip,
    }))

    public render() {
        const { linkedTasks, className, isForTooltip } = this.props

        if (!linkedTasks) {
            return null
        }

        const columns = this.getColumns()

        const tableData = linkedTasks.map(linkedTask => ({
            id: linkedTask.id,
            columns: {
                taskName: (
                    <TaskNameWithCompleteButton
                        className={this.bem.getElement('task-name')}
                        isReviewSubTask={true}
                        task={linkedTask}
                    />
                ),
                department:
                    !isForTooltip && linkedTask.department ? (
                        <LabelTag subtle={true} label={linkedTask.department?.name} />
                    ) : undefined,
                topicName: linkedTask.linkedItem ? <LinkedTaskLabel linkedItem={linkedTask.linkedItem} /> : '-',
            },
        }))

        return <Table className={this.bem.getClassName(className)} columns={columns} data={tableData} />
    }

    private getColumns() {
        const { isForTooltip } = this.props

        if (isForTooltip) {
            return [
                {
                    field: 'taskName',
                    headerLabel: localize.translate(t => t.Customer.Task.viewTaskModal.linkedTasksTable.selectedTasks),
                },
                {
                    field: 'topicName',
                    headerLabel: localize.translate(t => t.Customer.Task.viewTaskModal.linkedTasksTable.topic),
                },
            ]
        }

        return [
            {
                field: 'taskName',
                headerLabel: localize.translate(t => t.Customer.Task.viewTaskModal.linkedTasksTable.selectedTasks),
            },
            {
                field: 'department',
                headerLabel: localize.translate(t => t.Entities.Department),
            },
            {
                field: 'topicName',
                headerLabel: localize.translate(t => t.Customer.Task.viewTaskModal.linkedTasksTable.topic),
            },
        ]
    }
}
