import './SearchToolItem.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { MainSearch } from '~/components/Chrome/MainHeader/MainSearch/MainSearch'
import { IconType } from '~/components/Core/Icon/IconType'
import { ToolbarItem } from './ToolbarItem'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    className?: ClassValue
    onToggle?: (show: boolean) => void
}

interface State {
    showSearchBar: boolean
}

export class SearchToolItem extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        showSearchBar: false,
    }
    private bem = new BEM('SearchToolItem')

    public componentDidMount() {
        document.addEventListener('keyup', this.handleKeyUp)
    }

    public componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyUp)
    }

    public render() {
        const { className } = this.props
        const { showSearchBar } = this.state

        return (
            <div className={this.bem.getClassName(className)}>
                {showSearchBar && (
                    <MainSearch
                        className={this.bem.getElement('search')}
                        onClose={() => this.handleOnHide()}
                        customerSlug={this.context.customer.slug}
                    />
                )}
                {!showSearchBar && <ToolbarItem icon={IconType.search} onClick={() => this.handleOnShow()} />}
            </div>
        )
    }

    private handleKeyUp = (event: KeyboardEvent): void => {
        // 27 = ESC
        if (event.keyCode !== 27) {
            return
        }

        this.handleOnHide()
    }

    private handleOnShow() {
        this.setState({ showSearchBar: true })
        this.props.onToggle?.(true)
    }

    private handleOnHide() {
        this.setState({ showSearchBar: false })
        this.props.onToggle?.(false)
    }
}
