import './InboxWidget.scss'

import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { WidgetKey } from '../../CreateWidgetWidget/widgets'
import { WidgetContainer } from '../components/WidgetContainer'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize } from '~/bootstrap'
import { Bold } from '~/components/Core/Typography/Bold'
import {
    DashboardDepartmentSpecificWidgetMetadataType,
    DashboardWidgetContentFragmentFragment,
    DashboardWidgetLayoutTypeEnum,
    DashboardWidgetMetadataFragmentFragment,
    DashboardInboxWidgetFragmentFragment,
} from '~/generated/graphql'
import { WidgetInfoLink } from '../components/WidgetInfoLink'
import { routes } from '~/views/routes'

interface Props {
    isEditing: boolean
    currentBreakpoint: DashboardWidgetLayoutTypeEnum | undefined
    onWidgetClick?: (widgetId: number, type: WidgetKey) => void
    onDeleteIconClick?: (widgetId: number) => void
    onModalStateChange?: ({ isOpening }: { isOpening: boolean }) => void
    isDetailWidget?: boolean
    id: number
    metadata?: DashboardWidgetMetadataFragmentFragment
    content?: DashboardWidgetContentFragmentFragment
}

export class InboxWidget extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('InboxWidget')
    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView.Widgets.InboxWidget)

    public render() {
        const { isEditing, onWidgetClick, onDeleteIconClick, isDetailWidget, currentBreakpoint } = this.props
        const { onModalStateChange } = this.props

        return (
            <WidgetContainer
                widgetInfo={this.getHeaderInfo()}
                subtle={true}
                isEditing={isEditing}
                className={this.bem.getClassName()}
                onWidgetClick={onWidgetClick}
                isDetailWidget={isDetailWidget}
                currentBreakpoint={currentBreakpoint}
                onDeleteIconClick={onDeleteIconClick}
                onModalStateChange={onModalStateChange}
            >
                <Column bigSpacing={true}>{this.renderContent()}</Column>
            </WidgetContainer>
        )
    }

    private getHeaderInfo() {
        const { id, isDetailWidget } = this.props
        const { customName, departments, allDepartments } = this.getMetadata()

        if (isDetailWidget) {
            return {
                widgetId: id,
                widgetKey: WidgetKey.inbox,
                departments,
                allDepartments: false,
            }
        }

        return {
            widgetId: id,
            customName,
            widgetKey: WidgetKey.inbox,
            departments,
            allDepartments,
        }
    }

    private renderContent() {
        const metadata = this.props.metadata as DashboardDepartmentSpecificWidgetMetadataType | undefined
        const inboxPageLink = routes.customer(this.context.customer.slug).inbox.index

        const { newItemCountInPastWeek, totalNewItemsCount } = this.getCounts()
        const emphasizedText = newItemCountInPastWeek > 0 ? `+${newItemCountInPastWeek}` : '0'

        return (
            <>
                <WidgetInfoLink departmentSpecificMetadata={metadata} to={inboxPageLink}>
                    <Row smallSpacing={true} className={this.bem.getElement('main-content-container')}>
                        <Paragraph className={this.bem.getElement('week-count')} bold={true}>
                            {emphasizedText}
                        </Paragraph>
                        <Paragraph className={this.bem.getElement('text')} subtle={true}>
                            {this.loc(t => t.newItemsInPastWeek)}
                        </Paragraph>
                    </Row>
                </WidgetInfoLink>
                <WidgetInfoLink departmentSpecificMetadata={metadata} to={inboxPageLink}>
                    <Row extraSmallSpacing={true} className={this.bem.getElement('sub-content-container')}>
                        <Paragraph className={this.bem.getElement('text')} subtle={true}>
                            <Bold inline={true}>{totalNewItemsCount}</Bold>
                            {this.loc(t => t.totalNewItems)}
                        </Paragraph>
                    </Row>
                </WidgetInfoLink>
            </>
        )
    }

    private getMetadata() {
        const { metadata } = this.props

        if (!metadata) {
            return {
                departments: [],
                allDepartments: false,
            }
        }

        const { name, departments, allDepartments } = metadata as DashboardDepartmentSpecificWidgetMetadataType

        return {
            customName: name ? name : undefined,
            departments: departments?.length ? departments.map(({ id, name }) => ({ id, name })) : [],
            allDepartments,
        }
    }

    private getCounts() {
        let newItemCountInPastWeek = 0
        let totalNewItemsCount = 0

        const { content } = this.props
        if (content) {
            const { totalCount, lastWeekCount } = content as DashboardInboxWidgetFragmentFragment
            newItemCountInPastWeek = lastWeekCount
            totalNewItemsCount = totalCount
        }

        return { newItemCountInPastWeek, totalNewItemsCount }
    }
}
