import React from 'react'
import { PaginatableQuery, PaginationResult } from '~/components/Core/Pagination/PaginatableQuery'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import gql from 'graphql-tag'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import { uniqBy } from 'lodash-es'

interface Props {
    name?: string
    onChange: (option: SelectOption<string> | null) => void
    selectedOptions?: SelectOption<string>[] | null
    placeholder?: string
    isFilter?: boolean
}

interface State {
    search: string | null
}

const QUERY = gql`
    query assessments($take: Int, $skip: Int, $filters: AssessmentsFilters) {
        assessments(take: $take, skip: $skip, filters: $filters) {
            totalCount
            hasNextPage
            nodes {
                id
                name
            }
        }
    }
`

interface Assessment {
    id: number
    name: string
}

export class AssessmentSelect extends React.Component<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        search: '',
    }

    public render() {
        const { name, onChange, selectedOptions, placeholder, isFilter } = this.props
        const { search } = this.state

        return (
            <PaginatableQuery<Assessment>
                query={QUERY}
                variables={{
                    filters: {
                        name: search,
                        departmentId: this.context.activeDepartmentId,
                    },
                }}
            >
                {({ data, loading, loadingMore, fetchMore }) => {
                    if (!data) {
                        // key property is necessary to make sure that
                        // <Form.Select> is fully remounted when loading=false
                        return <Form.Select key="loading-select" name={'loading'} loading={true} />
                    }

                    const assessments = this.mapAssesments(data)

                    return (
                        <Form.Select
                            placeholder={placeholder}
                            name={name || 'assessment'}
                            defaultValue={selectedOptions || undefined}
                            searchable={true}
                            options={assessments}
                            onEndReached={fetchMore}
                            clearable={true}
                            loading={loading || loadingMore}
                            onChange={(option: SelectOption<string>) => {
                                onChange(option)
                            }}
                            onSearch={query => this.setState({ search: query })}
                            isFilter={isFilter}
                        />
                    )
                }}
            </PaginatableQuery>
        )
    }

    private mapAssesments(data: PaginationResult<Assessment>) {
        const { selectedOptions } = this.props

        if (!selectedOptions) {
            return data.nodes.map(source => ({ label: source.name, value: source.id }))
        }

        return uniqBy(
            [...selectedOptions, ...data.nodes.map(source => ({ label: source.name, value: source.id }))],
            'value'
        )
    }
}
