import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import {
    CreateFeedDocument,
    CreateFeedMutation,
    CreateFeedMutationVariables,
    CustomerFeedsDocument,
} from '~/generated/graphql'

interface Props {
    children: (mutation: CreateFeedMutationFN, options: MutationResult) => JSX.Element
}

export type CreateFeedMutationFN = MutationFn<CreateFeedMutation, CreateFeedMutationVariables>

export class CreateFeed extends React.PureComponent<Props> {
    public render() {
        const { children } = this.props

        return (
            <GQLMutation<CreateFeedMutation, CreateFeedMutationVariables>
                mutation={CreateFeedDocument}
                refetchQueries={[{ query: CustomerFeedsDocument }]}
            >
                {(mutate, options) => children(mutate, options)}
            </GQLMutation>
        )
    }
}
