export enum LinkedItemsTypeMap {
    news = 'News',
    topic = 'Topic',
    radarItem = 'RadarItem',
    consultation = 'Consultation',
    abstractLawArticle = 'AbstractLawArticle',
}

export enum ItemType {
    news = 'News',
    radarItem = 'RadarItem',
    alert = 'Alert',
    abstractLawArticle = 'AbstractLawArticle',
}
