import './PageHeader.scss'

import React from 'react'

import { BEM } from '~/services/BEMService'
import { Row } from '../Layout/Row'
import { PageTitle, PageTitleProps } from '../Text/PageTitle'
import { BreadCrumbs, BreadCrumbType } from '~/components/Chrome/BreadCrumbs/BreadCrumbs'
import { Column } from './Column'

interface Props extends PageTitleProps {
    actionComponent?: React.ReactNode
    actionButtons?: React.ReactNode
    backTo?: string
    backToLabel?: string
    unfollowed?: boolean
    breadCrumbs?: BreadCrumbType[]
    alignToTop?: true
}

export const PageHeader: React.SFC<Props> = props => {
    const { title, className, actionComponent, breadCrumbs, unfollowed, alignToTop, actionButtons, ...restProps } =
        props

    const bem = new BEM('PageHeader', () => ({
        'has-breadcrumbs': !!breadCrumbs,
        'is-unfollowed': !!unfollowed,
    }))

    return (
        <header className={bem.getClassName(className)}>
            {breadCrumbs && <BreadCrumbs breadCrumbs={breadCrumbs} />}
            <Row flexStart={alignToTop} spaceBetween={true} className={bem.getElement('title-container')}>
                <Column>
                    <PageTitle title={title} className={bem.getElement('page-title')} {...restProps} />
                    {actionButtons && <div className={bem.getElement('action-buttons')}>{actionButtons}</div>}
                </Column>
                {actionComponent && <div className={bem.getElement('action-component')}>{actionComponent}</div>}
            </Row>
        </header>
    )
}
