import React from 'react'
import { MutationFn } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    CreateTopicAssessmentMutationResult,
    CreateTopicAssessmentDocument,
    CreateTopicAssessmentMutation,
    CreateTopicAssessmentMutationVariables,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

export type CreateTopicAssessmentMutationFN = MutationFn<
    CreateTopicAssessmentMutation,
    CreateTopicAssessmentMutationVariables
>

interface Props {
    children: (mutate: CreateTopicAssessmentMutationFN, result: CreateTopicAssessmentMutationResult) => React.ReactNode
}

export class CreateTopicAssessment extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children } = this.props

        return (
            <GQLMutation<CreateTopicAssessmentMutation, CreateTopicAssessmentMutationVariables>
                mutation={CreateTopicAssessmentDocument}
            >
                {(mutate, result) => children(mutate, result)}
            </GQLMutation>
        )
    }
}
