import './SentBackInternalAlertsContainer.scss'
import React from 'react'
import { SentBackInternalAlert } from './PaginatedSentBackInternalAlertsQuery'
import { ItemListSpacer } from '~/components/Core/DataDisplay/ItemListSpacer/ItemListSpacer'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Table } from '~/components/Core/DataDisplay/Table/Table'
import { localize } from '~/bootstrap'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { SignalingTitle } from '../SignalingTitle'
import { BEM } from '~/services/BEMService'
import { Button } from '~/components/Core/Button/Button'
import { Column } from '~/components/Core/Layout/Column'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { InternalAlertDepartmentLabelTagContainer } from '../InternalAlert/InternalAlertDepartmentLabelTagContainer'
import { InternalAlertStatusState } from '~/generated/graphql'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import { Row } from '~/components/Core/Layout/Row'
import { SentBackInternalAlertsQuery } from './SentBackInternalAlertsQuery'

interface Props {
    search?: string
    groupIds?: number[]
    sourceIds?: number[]
    includePendingAndAccepted?: boolean
}

export class SentBackInternalAlertsContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('SentBackInternalAlertsContainer')
    private loc = localize.namespaceTranslate(t => t.Customer.Signaling.SignalingOverview)

    public render() {
        const { search, sourceIds, groupIds, includePendingAndAccepted } = this.props

        return (
            <SentBackInternalAlertsQuery
                includePendingAndAccepted={includePendingAndAccepted}
                sourceIds={sourceIds}
                groupIds={groupIds}
                search={search}
            >
                {({ data, loading, loadingMore }) => {
                    if (loading) {
                        return <Spinner />
                    }

                    if (!data?.nodes?.length) {
                        return <Table data={[]} columns={[]} hideHeaders whiteBackground />
                    }

                    return (
                        <Column className={this.bem.getClassName()}>
                            <ItemListSpacer>
                                {data.nodes.map((internalAlert, index) => (
                                    <li key={index}>
                                        <Card className={this.bem.getElement('card')}>
                                            <SignalingTitle
                                                className={this.bem.getElement('title')}
                                                showTooltip={true}
                                                newsItem={internalAlert.news}
                                                tags={
                                                    internalAlert.statuses ? (
                                                        <InternalAlertDepartmentLabelTagContainer
                                                            internalAlertStatuses={internalAlert.statuses}
                                                        />
                                                    ) : undefined
                                                }
                                            />
                                            <Row spaceBetween>
                                                {this.renderReturnInfo(internalAlert)}
                                                <div className={this.bem.getElement('action')}>
                                                    <Button
                                                        to={routes
                                                            .customer(this.context.customer.slug)
                                                            .signaling.detail.view(internalAlert.news.id)}
                                                    >
                                                        {localize.translate(t => t.Generic.view)}
                                                    </Button>
                                                </div>
                                            </Row>
                                        </Card>
                                    </li>
                                ))}
                            </ItemListSpacer>
                            {loadingMore && (
                                <li>
                                    <Spinner />
                                </li>
                            )}
                        </Column>
                    )
                }}
            </SentBackInternalAlertsQuery>
        )
    }

    private renderReturnInfo(internalAlert: SentBackInternalAlert) {
        const returnedStatuses = internalAlert.statuses?.filter(s => s.state === InternalAlertStatusState.returned)
        if (!returnedStatuses?.length) {
            return null
        }

        if (returnedStatuses.length > 1) {
            return (
                <Row>
                    <Paragraph noWrap>
                        {this.loc(t => t.askedQuestions1)}
                        <strong>
                            {returnedStatuses.length} {this.loc(t => t.askedQuestions2)}
                        </strong>
                        {this.loc(t => t.askedQuestions3)}
                    </Paragraph>
                </Row>
            )
        }

        if (!returnedStatuses[0].returnedAt) {
            return null
        }

        return (
            <Column smallSpacing>
                <Row extraSmallSpacing alignRight>
                    <Paragraph noWrap>{this.loc(t => t.askedQuestion)}</Paragraph>
                    <UserProfileIcon
                        showTooltip
                        small
                        fullName={returnedStatuses[0].returnedBy?.user.profile.fullName || '-'}
                    />
                </Row>
                <Row alignRight>
                    <Paragraph noWrap subtle>
                        {localize.dateFormat(new Date(returnedStatuses[0].returnedAt), {
                            readable: true,
                            includeTime: true,
                            noWeekday: true,
                        })}
                    </Paragraph>
                </Row>
            </Column>
        )
    }
}
