import React from 'react'
import { Query, QueryProps, OperationVariables } from 'react-apollo'
import { Center } from '../../Core/Layout/Center'
import { Spinner } from '../../Core/Feedback/Spinner/Spinner'
import { NoResults } from '../../Chrome/NoResults/NoResults'

export interface PageQueryOptions {
    refetch: Function
}

interface Props<TData = any, TVariables = OperationVariables> extends Omit<QueryProps<any, TVariables>, 'children'> {
    fetchPolicy?: QueryProps['fetchPolicy']
    children: (data: TData, options: PageQueryOptions) => React.ReactNode
}

export class PageQuery<TData = any, TVariables = OperationVariables> extends React.Component<Props<TData, TVariables>> {
    public render() {
        const { children, ...restProps } = this.props

        return (
            <Query<QueryProps<TData, TVariables>> {...restProps}>
                {({ data, loading, refetch }) => {
                    if (loading) {
                        return (
                            <Center>
                                <Spinner large={true} delayed={true} />
                            </Center>
                        )
                    }

                    if (!data || Object.values(data).some(value => value === null)) {
                        if (data && Object.keys(data).length > 1) {
                            throw new Error(
                                'Query requested more than one field, PageQuery only allows a single root field on the query field'
                            )
                        }

                        return <NoResults />
                    }

                    return children(Object.values(data)[0] as TData, {
                        refetch,
                    })
                }}
            </Query>
        )
    }
}
