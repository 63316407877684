import './DepartmentTableRowWarningText.scss'

import React from 'react'
import { localize, notification, permissions } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM } from '~/services/BEMService'
import { IgnoreNewTopic } from './DepartmentMutations/IgnoreNewTopic'
import { MutationFn } from 'react-apollo'
import { IgnoreNewTopicMutation, IgnoreNewTopicMutationVariables } from '~/generated/graphql'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    warningState: RowWarningState
    addedAt?: Date | null
    customerFrameworkId: number
    topicId: number
    showIgnoreButton: boolean
    onSubmit?: () => void
}

export interface RowWarningState {
    shouldShowNewWarning: boolean
    shouldShowUncheckedWarning: boolean
}

export class DepartmentTableRowWarningText extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Settings.DepartmentsOverview)
    private bem = new BEM('DepartmentTableRowWarningText', () => ({
        'is-new': this.props.warningState.shouldShowNewWarning,
    }))

    public render() {
        const { warningState } = this.props

        const { shouldShowNewWarning, shouldShowUncheckedWarning } = warningState

        if (!shouldShowNewWarning && !shouldShowUncheckedWarning) {
            return null
        }

        if (shouldShowNewWarning) {
            return this.renderNewItemWarning()
        }

        return (
            <Row className={this.bem.getClassName()}>
                <Paragraph className={this.bem.getElement('warning-text')}>
                    {this.loc(t => t.noSelectedTopicsWarning)}
                </Paragraph>
            </Row>
        )
    }

    private renderNewItemWarning() {
        const warningText = this.getNewItemWarningText()

        return (
            <IgnoreNewTopic>
                {(mutate, { loading }) => (
                    <Row className={this.bem.getClassName()}>
                        <Paragraph className={this.bem.getElement('warning-text')}>
                            {warningText}
                            {this.renderIgnoreButton(mutate, loading)}
                        </Paragraph>
                    </Row>
                )}
            </IgnoreNewTopic>
        )
    }

    private getNewItemWarningText() {
        const { addedAt } = this.props

        if (!addedAt) {
            return ''
        }

        const date = new Date(addedAt)
        const parsedDate = localize.dateFormat(date, { readable: true, noWeekday: true, noYear: true })
        const warningTextPart1 = this.loc(t => t.newTopicWarning)

        return `${warningTextPart1} ${parsedDate}`
    }

    private renderIgnoreButton(
        mutate: MutationFn<IgnoreNewTopicMutation, IgnoreNewTopicMutationVariables>,
        loading: boolean
    ) {
        const { showIgnoreButton } = this.props
        const hasPermissionToIgnore = permissions.isCustomerAdministrator()

        if (!showIgnoreButton || !hasPermissionToIgnore) {
            return
        }

        return (
            <Button
                loading={loading}
                type={ButtonType.noStyling}
                className={this.bem.getElement('ignore-button')}
                onClick={() => this.handleSubmit(mutate)}
            >
                {this.loc(t => t.ignoreNewTopicButton)}
            </Button>
        )
    }

    private async handleSubmit(mutate: MutationFn<IgnoreNewTopicMutation, IgnoreNewTopicMutationVariables>) {
        const { customerFrameworkId, topicId, onSubmit } = this.props

        const response = await mutate({
            variables: { customerFrameworkId, topicId },
        })

        if (response && response.data?.ignoreCustomerFrameworkNewTopic) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))

            if (onSubmit) {
                onSubmit()
            }
        }
    }
}
