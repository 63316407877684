import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Button } from '~/components/Core/Button/Button'
import { routes } from '~/views/routes'
import { TextLink } from '~/components/Core/Text/TextLink'
import { localize } from '~/bootstrap'
import gql from 'graphql-tag'
import { Consultant } from './OrganizationEmployeesOverviewView'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { Address } from '~/graphql/types/Address'
import { AddressFormat } from '~/components/Domain/User/AddressFormat'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { breadcrumbs } from '~/views/breadcrumbs'

const GET_ORGANIZATION_QUERY = gql`
    query organization {
        organization {
            id
            name
            address {
                addressLine
                postalCode
                city
                country
            }
            email
            phoneNumber
            contact {
                id
                user {
                    id
                    email
                    profile {
                        id
                        fullName
                    }
                }
            }
        }
    }
`

export interface Organization {
    id: number
    name: string
    email: string
    phoneNumber: string
    address?: Address
    contact?: Consultant
}

export class OrganizationProfileDetailView extends React.PureComponent {
    private loc = localize.namespaceTranslate(t => t.Organization)

    public render() {
        return (
            <PageQuery<Organization> query={GET_ORGANIZATION_QUERY}>
                {organization => {
                    if (!organization || !organization.address) {
                        return null
                    }

                    return (
                        <Column>
                            <PageHeader
                                documentTitle={'Bedrijf gegevens'}
                                title={organization.name}
                                breadCrumbs={[breadcrumbs.consultant().organization.index]}
                            />

                            <FieldSet>
                                <Field label={this.loc(t => t.attributes.name)}>
                                    <p>{organization.name}</p>
                                </Field>
                                <Field label={this.loc(t => t.attributes.address)}>
                                    <AddressFormat address={organization.address} />
                                </Field>
                                <Field label={this.loc(t => t.attributes.phoneNumber)}>
                                    <p>{organization.phoneNumber}</p>
                                </Field>
                                <Field label={this.loc(t => t.attributes.email)}>
                                    <TextLink href={`mailto:${organization.email}`}>{organization.email}</TextLink>
                                </Field>
                            </FieldSet>

                            {this.getContactPerson(organization.contact)}

                            <Button to={routes.consultant.organization.profile.edit}>
                                {this.loc(t => t.changeOrganizationDetails)}
                            </Button>
                        </Column>
                    )
                }}
            </PageQuery>
        )
    }

    private getContactPerson(contactPerson: Consultant | undefined) {
        if (!contactPerson) {
            return (
                <FieldSet>
                    <Field label={this.loc(t => t.attributes.contactPerson)}>
                        <p>{this.loc(t => t.attributes.noContactPerson)}</p>
                    </Field>
                </FieldSet>
            )
        }

        return (
            <FieldSet>
                <Field label={this.loc(t => t.attributes.contactPerson)}>
                    <TextLink href={routes.consultant.organization.employees.view(0)}>
                        {contactPerson.user.profile.fullName}
                    </TextLink>
                </Field>
                <Field label={this.loc(t => t.attributes.contactPersonEmail)}>
                    <TextLink href={`mailto:${contactPerson.user.email}`}>{contactPerson.user.email}</TextLink>
                </Field>
            </FieldSet>
        )
    }
}
