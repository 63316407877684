import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { TaskEventType, PossibleTaskEventType } from './GetTaskLogsQuery'
import { EventLog, EventItem } from '~/components/Core/DataDisplay/EventLog/EventLog'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    taskEvents?: PossibleTaskEventType[]
    loading?: boolean
    isSubTask?: boolean
}

export class TaskEventLogRenderer extends React.PureComponent<Props> {
    private bem = new BEM('TaskEventLog')
    private loc = localize.namespaceTranslate(t => t.Customer.Task.taskEventLog)

    public render() {
        const { taskEvents, className } = this.props

        if (!taskEvents) {
            return <React.Fragment />
        }

        const timelineEvents: EventItem[] = taskEvents.map(event => {
            if (event.type === TaskEventType.wasCompleted || event.type === TaskEventType.wasReopened) {
                return {
                    date: event.occuredOn,
                    actor: event.user,
                    files: event.metaData.files,
                    reports: event.metaData.reports,
                    title: this.getEventTitle(event.type),
                    description: event.metaData.description,
                }
            }

            return {
                date: event.occuredOn,
                actor: event.user,
                title: this.getEventTitle(event.type),
            }
        })

        return <EventLog events={timelineEvents} className={this.bem.getClassName(className)} />
    }

    private getEventTitle(type: TaskEventType) {
        const { isSubTask } = this.props

        if (isSubTask) {
            return this.loc(t => t.subTask[type])
        }

        return this.loc(t => t[type])
    }
}
