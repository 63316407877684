import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { User } from '~/graphql/types/User'
import { AssessmentEventCard } from './AssessmentEventCard'
import format from 'date-fns/format'
import { localize } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'

interface Props {
    className?: ClassValue
    assessmentEvent: AssessmentEvent
}

interface State {}

interface AssessmentEvent {
    date: Date
    type: AssessmentEventType
    user: User
    metaData: AssessmentEventMetaData
}

export interface AssessmentEventMetaData {
    assessmentId: number
    assessmentName: string
    assessmentDescription?: string
    assessmentOldName?: string
    assessmentOldDescription?: string
    normId?: number
    normName?: string
    riskId?: number
    riskName?: string
    controlId?: number
    controlName?: string
}

export enum AssessmentEventType {
    wasCreated = 'ASSESSMENT_WAS_CREATED',
    wasDeleted = 'ASSESSMENT_WAS_DELETED',
    wasUpdated = 'ASSESSMENT_WAS_UPDATED',
    normWasLinked = 'NORM_WAS_LINKED',
    normWasUnlinked = 'NORM_WAS_UNLINKED',
    riskWasCreated = 'RISK_WAS_CREATED',
    riskWasRemoved = 'RISK_WAS_DELETED',
    controlWasLinked = 'CONTROL_WAS_LINKED',
    controlWasUnlinked = 'CONTROL_WAS_UNLINKED',
}

export class AssessmentEventRenderer extends React.PureComponent<Props, State> {
    private bem = new BEM('AssessmentEventRenderer')

    public render() {
        const { className, assessmentEvent } = this.props

        return (
            <AssessmentEventCard
                className={this.bem.getClassName(className)}
                time={format(assessmentEvent.date, 'HH:mm')}
                icon={this.getEventIcon()}
                description={this.getEventDescription()}
                user={assessmentEvent.user.profile.fullName}
            />
        )
    }

    private getEventDescription() {
        const { assessmentEvent } = this.props

        if (assessmentEvent.type === AssessmentEventType.wasUpdated) {
            this.returnUpdatedAssessmentDescription()
        }

        const eventDescription = localize.translate(t => t.Customer.Compliance.AssessmentEvents[assessmentEvent.type], {
            ...assessmentEvent.metaData,
        })

        if (eventDescription === undefined) {
            return localize.translate(t => t.Customer.Compliance.AssessmentEvents.default)
        }

        return eventDescription
    }

    private returnUpdatedAssessmentDescription() {
        const { assessmentEvent } = this.props

        const descriptionUpdated =
            assessmentEvent.metaData.assessmentDescription !== assessmentEvent.metaData.assessmentOldDescription

        const titleAndDescriptionUpdated =
            descriptionUpdated && assessmentEvent.metaData.assessmentName !== assessmentEvent.metaData.assessmentOldName

        if (titleAndDescriptionUpdated) {
            return localize.translate(t => t.Customer.Compliance.AssessmentEvents.titleAndDescriptionUpdated, {
                ...assessmentEvent.metaData,
            })
        }

        return localize.translate(t => t.Customer.Compliance.AssessmentEvents.descriptionUpdated, {
            ...assessmentEvent.metaData,
        })
    }

    private getEventIcon(): IconType | undefined {
        const { assessmentEvent } = this.props
        const type = assessmentEvent.type

        if (type === AssessmentEventType.normWasLinked || type === AssessmentEventType.normWasUnlinked) {
            return IconType.norm
        }

        if (type === AssessmentEventType.riskWasCreated || type === AssessmentEventType.riskWasRemoved) {
            return IconType.risk
        }

        if (type === AssessmentEventType.controlWasLinked || type === AssessmentEventType.controlWasUnlinked) {
            return IconType.control
        }

        return undefined
    }
}
