import React from 'react'
import { PaginatableOptions, PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    ControlTopicsDocument,
    ControlTopicsQuery,
    ControlTopicsQueryVariables,
    SortDirectionEnumType,
} from '~/generated/graphql'

interface Props {
    controlId: number
    children: (options: PaginatableOptions<ControlTopicType, ControlTopicsQueryVariables>) => JSX.Element
}

export type ControlTopicType = NonNullable<NonNullable<ControlTopicsQuery['topicControlMeasures']>['nodes']>[0]

export class ControlTopics extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, controlId } = this.props

        return (
            <PaginatableQuery<ControlTopicType, ControlTopicsQueryVariables>
                path="topicControlMeasures"
                query={ControlTopicsDocument}
                take={8}
                variables={{
                    departmentId: this.context.activeDepartmentId,
                    filters: { controlId },
                    sort: { topic: SortDirectionEnumType.asc },
                }}
            >
                {options => children(options)}
            </PaginatableQuery>
        )
    }
}
