import React from 'react'

import { RouteComponentProps, Switch, Route } from 'react-router'
import { routes } from '~/views/routes'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { SearchOverviewView } from './SearchOverviewView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props extends RouteComponentProps<{}> {}

export class SearchView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Switch>
                <Route
                    path={routes.customer(this.context.customer.slug).search.index}
                    component={SearchOverviewView}
                    exact={true}
                />
                <Route component={AppNotFoundView} />
            </Switch>
        )
    }
}
