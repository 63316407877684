import { WidgetKey } from '~/components/Domain/Dashboard/CreateWidgetWidget/widgets'
import { EmployeeOperationEnum, EmployeePermissionEnum, EmployeeRole, UserRole } from '~/generated/graphql'
import { UserService } from '~/services/UserService'

export class PermissionService {
    constructor(private userClient: UserService) {}
    private widgetPermissions = {
        [WidgetKey.inbox]: [
            EmployeeRole.customerReaderWithCompliance,
            EmployeeRole.customerAdministrator,
            EmployeeRole.customer,
        ],
        [WidgetKey.tasks]: [
            EmployeeRole.customerReaderWithCompliance,
            EmployeeRole.customerPlanner,
            EmployeeRole.customerAdministrator,
            EmployeeRole.customer,
        ],
        [WidgetKey.controls]: [
            EmployeeRole.customerReaderWithCompliance,
            EmployeeRole.customerAdministrator,
            EmployeeRole.customer,
        ],
        [WidgetKey.risk]: [
            EmployeeRole.customerReaderWithCompliance,
            EmployeeRole.customerAdministrator,
            EmployeeRole.customer,
        ],
        [WidgetKey.topic]: [
            EmployeeRole.customerReaderWithCompliance,
            EmployeeRole.customerAdministrator,
            EmployeeRole.customer,
        ],
        [WidgetKey.topicEffectiveness]: [
            EmployeeRole.customerReaderWithCompliance,
            EmployeeRole.customerAdministrator,
            EmployeeRole.customer,
        ],
        [WidgetKey.radar]: [
            EmployeeRole.customerReaderWithCompliance,
            EmployeeRole.customerReader,
            EmployeeRole.customerAdministrator,
            EmployeeRole.customer,
        ],
        [WidgetKey.export]: [EmployeeRole.customerAdministrator],
        [WidgetKey.consultations]: [
            EmployeeRole.customerReaderWithCompliance,
            EmployeeRole.customerReader,
            EmployeeRole.customerAdministrator,
            EmployeeRole.customer,
        ],
        [WidgetKey.monitoringReport]: [
            EmployeeRole.customerReaderWithCompliance,
            EmployeeRole.customerAdministrator,
            EmployeeRole.customer,
        ],
        [WidgetKey.inboxItemImpact]: [
            EmployeeRole.customerReaderWithCompliance,
            EmployeeRole.customerAdministrator,
            EmployeeRole.customer,
        ],
    }

    public getAvailableOperations(role: EmployeeRole, permissions: EmployeePermissionEnum[]) {
        const canSetOperations = [EmployeeRole.customer, EmployeeRole.customerAdministrator].includes(role)
        if (!canSetOperations) {
            return []
        }

        const isControlPermissionSelected = permissions.includes(EmployeePermissionEnum.canSetControlOperations)
        if (isControlPermissionSelected) {
            return [EmployeeOperationEnum.canOperateControls, EmployeeOperationEnum.canOperateInternalAlerts]
        }

        return [EmployeeOperationEnum.canOperateInternalAlerts]
    }

    public employeeCanUseWidget(widgetType: WidgetKey) {
        if (!this.widgetPermissions.hasOwnProperty(widgetType)) {
            return false
        }
        const validRoles = this.widgetPermissions[widgetType]

        return this.userClient.hasRoles(validRoles)
    }

    public isPassiveUserForDepartment(departmentId: number) {
        return this.userClient.isPassiveUserForDepartment(departmentId)
    }

    public isCustomerUser() {
        if (this.isCustomer() || this.isCustomerReader() || this.isCustomerAdministrator()) {
            return true
        }

        return false
    }

    public isConsultantUser() {
        if (this.isConsultant() || this.isConsultantAdministrator()) {
            return true
        }

        return false
    }

    public isCustomerConsultant() {
        return !!this.userClient.getCurrentUser()?.isCustomerConsultant
    }

    public isCurrentUser(id: string | number) {
        return this.userClient.isCurrentUser(id)
    }

    public isCustomer() {
        return this.userClient.hasRoles([EmployeeRole.customer])
    }

    public isCustomerReader() {
        return this.userClient.hasRoles([EmployeeRole.customerReader, EmployeeRole.customerReaderWithCompliance])
    }

    public isCustomerPlanner() {
        return this.userClient.hasRoles([EmployeeRole.customerPlanner])
    }

    public canArchiveInboxItems(departmentId: number) {
        const isPassiveUserForDepartment = this.userClient.isPassiveUserForDepartment(departmentId)

        if (isPassiveUserForDepartment) {
            return false
        }

        if (this.userClient.hasPermissions(EmployeePermissionEnum.canArchiveInboxItems)) {
            return true
        }

        return false
    }

    public canViewComplianceAndDocuments() {
        if (
            this.userClient.hasRoles([
                EmployeeRole.customerAdministrator,
                EmployeeRole.customerReaderWithCompliance,
                EmployeeRole.customer,
            ])
        ) {
            return true
        }

        return false
    }

    public canUpdateComplianceAndDocuments(departmentId: number) {
        const isPassiveUserForDepartment = this.userClient.isPassiveUserForDepartment(departmentId)

        if (isPassiveUserForDepartment) {
            return false
        }

        if (this.userClient.hasRoles([EmployeeRole.customerAdministrator, EmployeeRole.customer])) {
            return true
        }

        return false
    }

    public canChangeNonApplicableTopics() {
        if (this.userClient.hasRoles([EmployeeRole.customerAdministrator])) {
            return true
        }

        return false
    }

    public allowsCustomerWriteActions() {
        if (this.isCustomer() || this.isCustomerAdministrator()) {
            return true
        }

        return false
    }

    public allowedToEditCustomerRoles(other: number) {
        if (this.isConsultantUser()) {
            return true
        }

        if (this.isCurrentUser(other)) {
            return false
        }

        if (this.isCustomerAdministrator()) {
            return true
        }

        return false
    }

    public isCustomerAdministrator(checkIfPassive?: boolean, departmentId?: number) {
        if (checkIfPassive && departmentId) {
            const isPassiveUserForDepartment = this.userClient.isPassiveUserForDepartment(departmentId)

            if (isPassiveUserForDepartment) {
                return false
            }
        }

        return this.userClient.hasRoles([EmployeeRole.customerAdministrator])
    }

    public isCurrentUserOrCustomerAdministrator(id: string | number) {
        if (this.isCurrentUser(id)) {
            return true
        }

        return this.isCustomerAdministrator()
    }

    public isConsultant() {
        return this.userClient.hasRoles([UserRole.consultant])
    }

    public isConsultantAdministrator() {
        return this.userClient.hasRoles([UserRole.consultantAdministrator])
    }

    public isConsultantAdministratorAndNotCurrentUser(id: string | number) {
        if (this.isConsultantAdministrator() && this.isCurrentUser(id) === false) {
            return true
        }

        return false
    }

    public isCurrentUserOrConsultantAdministrator(id: string | number) {
        if (this.isCurrentUser(id)) {
            return true
        }

        return this.isConsultantAdministrator()
    }

    public canViewCompliances() {
        if (this.isCustomer()) {
            return true
        }

        if (this.isCustomerAdministrator()) {
            return true
        }

        return false
    }

    public canExportCompliance() {
        if (this.isCustomerAdministrator()) {
            return true
        }

        return false
    }

    public isAllowedToEditDepartments() {
        if (this.isConsultantUser()) {
            return true
        }

        if (this.isCustomerAdministrator()) {
            return true
        }

        return false
    }

    public isAllowedToExportDepartments() {
        if (this.isCustomerAdministrator()) {
            return true
        }

        return false
    }

    public hasPermission(permissions: EmployeePermissionEnum | EmployeePermissionEnum[] | undefined) {
        return this.userClient.hasPermissions(permissions)
    }

    public hasOperation(operation: EmployeeOperationEnum | EmployeeOperationEnum[] | undefined) {
        return this.userClient.hasOperations(operation)
    }

    public canViewPlanning() {
        if (
            this.userClient.hasRoles([
                EmployeeRole.customerAdministrator,
                EmployeeRole.customerReaderWithCompliance,
                EmployeeRole.customer,
                EmployeeRole.customerPlanner,
            ])
        ) {
            return true
        }

        return false
    }

    public canCreateTasks(departmentId: number) {
        const isPassiveUserForDepartment = this.userClient.isPassiveUserForDepartment(departmentId)

        if (isPassiveUserForDepartment) {
            return false
        }

        if (
            this.userClient.hasRoles([
                EmployeeRole.customerAdministrator,
                EmployeeRole.customerPlanner,
                EmployeeRole.customer,
            ])
        ) {
            return true
        }

        return false
    }

    public canEditTasks(departmentId: number, assignedEmployeeIds: number[]) {
        const isPassiveUserForDepartment = this.userClient.isPassiveUserForDepartment(departmentId)
        const currentUserEmployee = this.userClient.getCurrentUserEmployee()
        const isUserAssignedToTask = currentUserEmployee ? assignedEmployeeIds.includes(currentUserEmployee.id) : false

        if (isPassiveUserForDepartment && !isUserAssignedToTask) {
            return false
        }

        if (
            this.userClient.hasRoles([
                EmployeeRole.customerAdministrator,
                EmployeeRole.customerPlanner,
                EmployeeRole.customer,
            ])
        ) {
            return true
        }

        return false
    }

    public canViewTasks() {
        if (
            this.userClient.hasRoles([
                EmployeeRole.customerAdministrator,
                EmployeeRole.customerPlanner,
                EmployeeRole.customer,
            ])
        ) {
            return true
        }

        return false
    }

    public canEditTaskControls() {
        const hasPermission = this.hasPermission(EmployeePermissionEnum.canSetControlOperations)
        const hasOperation = this.hasOperation(EmployeeOperationEnum.canOperateControls)

        if (hasPermission && hasOperation) {
            return true
        }

        return false
    }

    public canEditInboxControls() {
        const hasPermission = this.hasPermission(EmployeePermissionEnum.canSetControlOperations)
        const hasOperation = this.hasOperation(EmployeeOperationEnum.canOperateControls)

        if (hasPermission && hasOperation) {
            return true
        }

        return false
    }

    public canSetInboxImpact(departmentId: number) {
        return (
            !this.isPassiveUserForDepartment(departmentId) &&
            this.userClient.hasRoles([EmployeeRole.customerAdministrator, EmployeeRole.customer])
        )
    }

    public canUnFollowRadarItem(departmentId: number) {
        return (
            !this.isPassiveUserForDepartment(departmentId) &&
            this.userClient.hasRoles([EmployeeRole.customerAdministrator, EmployeeRole.customer])
        )
    }

    public canEditAssessment(departmentId: number) {
        const isPassiveUserForDepartment = this.userClient.isPassiveUserForDepartment(departmentId)

        if (isPassiveUserForDepartment) {
            return false
        }

        if (this.userClient.hasRoles([EmployeeRole.customerAdministrator, EmployeeRole.customer])) {
            return true
        }

        return false
    }

    public canEditMonitoringReport() {
        return (
            this.userClient.hasRoles([EmployeeRole.customerAdministrator]) ||
            this.userClient.hasPermissions(EmployeePermissionEnum.canAccessMonitoringControls)
        )
    }

    public canViewMonitoringReports() {
        if (
            this.userClient.hasRoles([EmployeeRole.customerAdministrator]) ||
            this.userClient.hasPermissions(EmployeePermissionEnum.canAccessMonitoringControls)
        ) {
            return true
        }

        return false
    }

    public canEditControls(departmentId: number) {
        const isPassiveUserForDepartment = this.userClient.isPassiveUserForDepartment(departmentId)

        if (isPassiveUserForDepartment) {
            return false
        }

        if (this.userClient.hasRoles([EmployeeRole.customerAdministrator, EmployeeRole.customer])) {
            return true
        }

        return false
    }

    public canEditControlDocuments(departmentId: number) {
        const isPassiveUserForDepartment = this.userClient.isPassiveUserForDepartment(departmentId)

        if (isPassiveUserForDepartment) {
            return false
        }

        if (this.userClient.hasRoles([EmployeeRole.customerAdministrator, EmployeeRole.customer])) {
            return true
        }

        return false
    }

    public canDeleteArticle(departmentId: number) {
        const isPassiveUserForDepartment = this.userClient.isPassiveUserForDepartment(departmentId)

        if (isPassiveUserForDepartment) {
            return false
        }

        if (this.userClient.hasRoles([EmployeeRole.customerAdministrator, EmployeeRole.customer])) {
            return true
        }

        return false
    }

    public canAddItemsToMonitoring(departmentId: number, assignedEmployeeIds: number[]) {
        const isPassiveUserForDepartment = this.userClient.isPassiveUserForDepartment(departmentId)
        const currentUserEmployee = this.userClient.getCurrentUserEmployee()
        const isUserAssignedToTask = currentUserEmployee ? assignedEmployeeIds.includes(currentUserEmployee.id) : false

        if (isPassiveUserForDepartment && !isUserAssignedToTask) {
            return false
        }

        if (
            this.userClient.hasRoles([EmployeeRole.customerAdministrator]) ||
            this.userClient.hasPermissions(EmployeePermissionEnum.canAccessMonitoringControls)
        ) {
            return true
        }

        return false
    }

    public canViewSAMLSettings() {
        return this.isCustomerAdministrator() === true
    }

    public canAccessTaskControlPage() {
        const hasPermission = this.hasPermission(EmployeePermissionEnum.canSetControlOperations)
        const hasOperation = this.hasOperation(EmployeeOperationEnum.canOperateControls)

        if (hasPermission && hasOperation) {
            return true
        }

        return false
    }

    public canAccessInboxControlPage() {
        const hasPermission = this.hasPermission(EmployeePermissionEnum.canSetControlOperations)
        const hasOperation = this.hasOperation(EmployeeOperationEnum.canOperateControls)

        if (hasPermission && hasOperation) {
            return true
        }

        return false
    }

    public canChangeComplianceSettings(departmentId: number) {
        const isPassiveUserForDepartment = this.userClient.isPassiveUserForDepartment(departmentId)

        if (isPassiveUserForDepartment) {
            return false
        }

        if (this.userClient.hasRoles([EmployeeRole.customerAdministrator])) {
            return true
        }

        return false
    }

    public canViewTopicDnE() {
        if (
            this.userClient.hasRoles([
                EmployeeRole.customer,
                EmployeeRole.customerAdministrator,
                EmployeeRole.customerPlanner,
                EmployeeRole.customerReaderWithCompliance,
            ])
        ) {
            return true
        }

        return false
    }

    public canEditTopicDnE(departmentId: number) {
        return (
            !this.isPassiveUserForDepartment(departmentId) &&
            this.userClient.hasRoles([
                EmployeeRole.customer,
                EmployeeRole.customerAdministrator,
                EmployeeRole.customerPlanner,
            ])
        )
    }

    public canMarkRelevantNews() {
        return this.hasPermission(EmployeePermissionEnum.canMonitorSignaling)
    }

    public canAccessInternalAlerts(departmentId: number) {
        return !!(
            !this.isPassiveUserForDepartment(departmentId) &&
            this.userClient.getCurrentUserEmployee()?.customer.editorialAccess &&
            this.userClient.hasOperations(EmployeeOperationEnum.canOperateInternalAlerts)
        )
    }
}
