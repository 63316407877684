import React from 'react'
import { ArticleTitle } from '~/components/Core/Text/ArticleTitle'
import { PageDetailContent } from '~/components/Core/Layout/PageDetail/PageDetailContent'
import { RouteComponentProps } from 'react-router'
import { PageDetail } from '~/components/Core/Layout/PageDetail/PageDetail'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import gql from 'graphql-tag'
import { Markdown } from '~/components/Core/Text/Markdown'
import { LinkedItemsFields, GroupedItem } from '~/graphql/types/LinkedItem'
import { GroupedLinkedItemsLists } from '~/components/Domain/LinkedList/GroupedLinkedItemsLists'
import { ArticleRenderer } from '~/components/Domain/LawAndRegulation/ArticleRenderer'
import { Attachment, AttachmentFields } from '~/graphql/types/Attachment'
import { PageDetailMeta } from '~/components/Core/Layout/PageDetail/PageDetailMeta'
import { AttachmentList } from '~/components/Core/DataDisplay/AttachmentList/AttachmentList'
import { breadcrumbs } from '~/views/breadcrumbs'
import { ArticleLinkedTopicList } from '~/components/Domain/LawAndRegulation/ArticleLinkedTopicList'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize } from '~/bootstrap'

interface RouteParams {
    articleId?: string
}

const GET_LAW_ARTICLE_QUERY = gql`
    query lawArticle($articleId: Int!, $customerSlug: String) {
        lawArticle(id: $articleId) {
            id
            abstractLawArticleId
            hasStructuredContent
            content
            type
            title
            lawGroup {
                id
                name
            }
            law {
                id
                name
            }
            attachments {
                ...AttachmentFields
            }
            linkedItems(customerSlug: $customerSlug) {
                ...LinkedItemsFields
            }
        }
    }
    ${LinkedItemsFields}
    ${AttachmentFields}
`

interface LawArticle {
    id: number
    abstractLawArticleId: number
    regulationId: string
    type: string
    linkedItems: GroupedItem[]
    lawLabel: string
    hasStructuredContent: boolean
    content: string
    attachments: Attachment[]
    lawGroup: {
        id: number
        name: string
    } | null
    law: {
        id: number
        name: string
    }
    title: string
}

interface Props extends RouteComponentProps<RouteParams> {}

export class LawAndRegulationArticleDetailView extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const articleId = parseInt(this.props.match.params.articleId!, 10)

        return (
            <PageQuery<LawArticle>
                query={GET_LAW_ARTICLE_QUERY}
                variables={{
                    articleId,
                    customerSlug: this.context.customer.slug,
                    departmentId: this.context.activeDepartmentId,
                }}
            >
                {article => {
                    const hasSidebar = article.linkedItems.length > 0
                    const useRenderer = article.hasStructuredContent

                    return (
                        <PageDetail
                            breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).lawAndRegulation.index]}
                            renderTitle={() => <ArticleTitle title={article.title} />}
                            renderSidebar={() =>
                                hasSidebar ? <GroupedLinkedItemsLists linkedItems={article.linkedItems} /> : null
                            }
                        >
                            <PageDetailContent>
                                <ArticleLinkedTopicList
                                    articleId={article.id}
                                    abstractLawArticleId={article.abstractLawArticleId}
                                    wide={true}
                                />

                                {article.lawGroup && (
                                    <PageDetailMeta
                                        items={[
                                            {
                                                label: localize.translate(
                                                    t => t.Customer.LawAndRegulation.LawAndRegulationOverview.filter.law
                                                ),
                                                value: <Paragraph>{article.lawGroup.name}</Paragraph>,
                                            },
                                        ]}
                                    />
                                )}

                                {useRenderer ? (
                                    <ArticleRenderer regulationId={''} lawLabel={''} content={article.content} />
                                ) : (
                                    <Markdown source={article.content} />
                                )}
                                {article.attachments && article.attachments.length > 0 && (
                                    <AttachmentList attachments={article.attachments} />
                                )}
                            </PageDetailContent>
                        </PageDetail>
                    )
                }}
            </PageQuery>
        )
    }
}
