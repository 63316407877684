import './CustomerGroupFormFields.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { Row } from '~/components/Core/Layout/Row'
import { BEM } from '~/services/BEMService'
import { SAMLSettingsFormFields } from '~/components/Domain/SAML/SAMLSettingsFormFields'
import { Column } from '~/components/Core/Layout/Column'
import { CustomerGroupType } from '~/generated/graphql'
import slugify from 'slugify'

interface Props {
    customerGroup?: CustomerGroupType
}

interface State {
    showSSOFields: boolean
    customerGroupName: string
}

export class CustomerGroupFormFields extends React.PureComponent<Props, State> {
    public state: State = {
        showSSOFields: !!this.props.customerGroup?.ssoLoginEnabled,
        customerGroupName: this.props.customerGroup?.name || '',
    }

    private bem = new BEM('CustomerGroupFormFields')
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerGroupsView.CreateCustomerGroupView)
    private customerLoc = localize.namespaceTranslate(t => t.Customer.Attributes)

    public render() {
        const { customerGroup } = this.props

        return (
            <Column>
                <Field forInput="name" label={this.loc(t => t.name)}>
                    <Form.Input
                        name="name"
                        defaultValue={customerGroup?.name}
                        onChange={name => this.setState({ customerGroupName: name || '' })}
                    />
                </Field>
                {this.renderSSOFields()}
                {this.renderSAMLFields()}
            </Column>
        )
    }

    private renderSSOFields() {
        const { customerGroup } = this.props

        return (
            <>
                <Field forInput="sso" label={localize.translate(t => t.User.attributes.ssoLoginEnabled)}>
                    <Row className={this.bem.getElement('radio-container')}>
                        <Form.Radio
                            name="sso"
                            value={true}
                            label={localize.translate(t => t.Generic.enabled)}
                            onChange={() => this.setState({ showSSOFields: true })}
                            defaultChecked={customerGroup?.ssoLoginEnabled}
                        />
                        <Form.Radio
                            name="sso"
                            value={false}
                            defaultChecked={!customerGroup?.ssoLoginEnabled}
                            onChange={() => this.setState({ showSSOFields: false })}
                            label={localize.translate(t => t.Generic.disabled)}
                        />
                    </Row>
                </Field>
                {this.state.showSSOFields && (
                    <Field forInput="createUserOnSSOLogin" label={this.customerLoc(t => t.createUserOnSSOLogin)}>
                        <Row className={this.bem.getElement('radio-container')}>
                            <Form.Radio
                                name="createUserOnSSOLogin"
                                value={true}
                                label={this.customerLoc(t => t.ssoLoginEnabledYes)}
                                defaultChecked={!!customerGroup?.createUserOnSSOLogin}
                            />
                            <Form.Radio
                                name="createUserOnSSOLogin"
                                value={false}
                                defaultChecked={!customerGroup?.createUserOnSSOLogin}
                                label={this.customerLoc(t => t.ssoLoginEnabledNo)}
                            />
                        </Row>
                    </Field>
                )}
            </>
        )
    }

    private renderSAMLFields() {
        const { showSSOFields, customerGroupName } = this.state

        if (!showSSOFields) {
            return
        }

        const { customerGroup } = this.props
        const slug = slugify(customerGroupName.trim(), { lower: true })
        const baseUrl = window.ENVIRONMENT.NAME === 'development' ? `http://localhost:5000` : window.location.origin

        const entityId = `${baseUrl}/api/saml2/${slug}/metadata.xml`
        const ascURL = `${baseUrl}/api/saml2/${slug}/assert`
        const logoutURL = `${baseUrl}/api/saml2/${slug}/logout`

        return (
            <SAMLSettingsFormFields
                entityId={entityId}
                ascURL={ascURL}
                logoutURL={logoutURL}
                ssoLoginURL={customerGroup?.ssoLoginURL}
                ssoLogoutURL={customerGroup?.ssoLogoutURL}
                ssoCertificate={customerGroup?.ssoCertificate}
            />
        )
    }
}
