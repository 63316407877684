import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Guard } from '~/components/Core/Guard/Guard'
import { IconType } from '~/components/Core/Icon/IconType'
import { OptionsDropdown } from '~/components/Core/OptionsDropdown/OptionsDropdown'
import {
    OptionsDropdownOption,
    OptionsDropdownOptionStyle,
} from '~/components/Core/OptionsDropdown/OptionsDropdownOption'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AssessmentDetail } from '~/views/Customer/Risks/Assessments/AssessmentsDetailView'
import { routes } from '~/views/routes'
import { CreateTaskModalContainer } from '../../Task/CreateTaskModalContainer'

interface Props {
    assessment: AssessmentDetail
}

export class AssessmentOptionsButton extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Assessments.General)

    public render() {
        const { assessment } = this.props
        const customerSlug = this.context.customer.slug

        return (
            <ModalManager
                renderModal={closeModal => (
                    <CreateTaskModalContainer linkedItem={assessment} requestClose={closeModal} />
                )}
                render={openModal => (
                    <OptionsDropdown>
                        <OptionsDropdownOption
                            icon={IconType.log}
                            label={this.loc(t => t.log)}
                            to={routes.customer(customerSlug).risks.assessments.log(assessment.id)}
                        />
                        <OptionsDropdownOption
                            icon={IconType.settings}
                            label={this.loc(t => t.properties)}
                            to={routes.customer(customerSlug).risks.assessments.properties(assessment.id)}
                        />
                        <Guard condition={permissions.canCreateTasks(this.context.activeDepartmentId)}>
                            <OptionsDropdownOption
                                icon={IconType.taskAdd}
                                style={OptionsDropdownOptionStyle.primary}
                                newSection={true}
                                label={localize.translate(t => t.Customer.Task.TaskWidget.createTask)}
                                onClick={() => openModal()}
                            />
                        </Guard>
                    </OptionsDropdown>
                )}
            />
        )
    }
}
