import gql from 'graphql-tag'
import { LinkedItemFragmentFragmentDoc, LinkedItemsFieldsFragmentDoc } from '~/generated/graphql'
import { LinkedItemsTypeMap } from './Item'

export interface GroupedItem {
    type: string
    entityType: string | null
    items: any[]
}

export interface LinkedItem {
    type: string
    entityType: LinkedItemsTypeMap | null
    item: any
}

export const LinkedItemsFields = LinkedItemsFieldsFragmentDoc

export const LinkedItemType = gql`
    fragment LinkedItemType on SingleLinkedItemType {
        type
        entityType
        item {
            ...LinkedItemFragment
        }
    }
    ${LinkedItemFragmentFragmentDoc}
`
