import React from 'react'
import { Link } from 'react-router-dom'
import { localize } from '~/bootstrap'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { SubtleLabel } from '~/components/Core/Typography/SubtleLabel'
import { AssessmentCoverageType } from '~/generated/graphql'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { BEM, ClassValue } from '~/services/BEMService'
import { CustomerFrameworkAmountIndicator } from '../../CustomerFramework/CustomerFrameworkAmountIndicator'
import {
    getAssessmentPercentages,
    containAssessmentPercentage,
} from '../Assessments/AssessmentCoverageBar/AssessmentCoverageBarService'
import { AsssessmentCoverageBarSmall } from '../Assessments/AssessmentCoverageBar/AssessmentCoverageBarSmall'
import './NormThemeCard.scss'

interface Props {
    className?: ClassValue
    title: string
    to?: string
    icons: CustomerFramework[]
    assessmentCoverage?: AssessmentCoverageType
    loadingCoverage?: boolean
    onClick?: () => void
    disabled?: boolean
}

interface State {}

export class NormThemeCard extends React.Component<Props, State> {
    private bem = new BEM('NormThemeCard', () => ({
        'is-disabled': !!this.props.disabled,
    }))

    public render() {
        const { title, className, icons, to, onClick, disabled, loadingCoverage, assessmentCoverage } = this.props

        const ContentComponent: any = to && !disabled ? Link : 'div'
        const percentage = assessmentCoverage ? this.getCombinedCoveragePercentage(assessmentCoverage) : 0

        return (
            <Card className={this.bem.getClassName(className)}>
                <ContentComponent
                    to={to && !disabled ? to : ''}
                    className={this.bem.getElement('content')}
                    onClick={disabled ? undefined : onClick}
                >
                    <div className={this.bem.getElement('card-header')}>
                        <span className={this.bem.getElement('title')}>{title}</span>

                        <CustomerFrameworkAmountIndicator customerFrameworks={icons} />
                    </div>

                    <div className={this.bem.getElement('card-footer')}>
                        <SubtleLabel className={this.bem.getElement('status-label')}>
                            {localize.translate(t => t.Customer.Compliance.TopicCard.status)}
                        </SubtleLabel>
                        <AsssessmentCoverageBarSmall
                            renderLabel={() => (
                                <SubtleLabel className={this.bem.getElement('compliance-count')}>
                                    {localize.translate(t => t.Generic.compliancePercentage, { percentage })}
                                </SubtleLabel>
                            )}
                            assessmentCoverage={assessmentCoverage}
                            loadingCoverage={loadingCoverage}
                        />
                    </div>
                </ContentComponent>
            </Card>
        )
    }

    private getCombinedCoveragePercentage(assessmentCoverage: AssessmentCoverageType) {
        const assessmentValues = getAssessmentPercentages(assessmentCoverage)

        const totalAssessedValue = assessmentValues.assessed.amount + assessmentValues.noted.amount

        return containAssessmentPercentage(totalAssessedValue)
    }
}
