import React from 'react'
import { localize } from '~/bootstrap'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    date: Date
    className?: ClassValue
    dateOnly?: boolean
}

const bem = new BEM('DateTime')

export const DateTime = React.memo<Props>(
    props => {
        const { date, className, dateOnly } = props

        return (
            <time className={bem.getClassName(className)} dateTime={date.toISOString()}>
                {dateOnly ? localize.dateFormat(date) : localize.dateTime(date)}
            </time>
        )
    },
    (prev, next) => prev.date.getTime() === next.date.getTime()
)
