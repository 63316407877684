import './ThemesOverviewView.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { ParamManager, SetParamStateFn } from '~/components/Core/ParamManager/ParamManager'
import { PinnedThemesQuery } from '~/components/Domain/Pinned/PinnedThemesQuery'
import { ThemesOverviewPinnedThemesContainer } from '~/components/Domain/Themes/ThemesOverviewTable/ThemesOverviewPinnedThemesContainer'
import { ThemesOverviewTableContainer } from '~/components/Domain/Themes/ThemesOverviewTable/ThemesOverviewTableContainer'
import {
    ThemesOverviewFilters,
    ThemesOverviewTableQuery,
} from '~/components/Domain/Themes/ThemesOverviewTable/ThemesOverviewTableQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { breadcrumbs } from '~/views/breadcrumbs'
import { BEM } from '~/services/BEMService'
import { Column } from '~/components/Core/Layout/Column'

type SetParamStateFN = SetParamStateFn<ThemesOverviewFilters>

export class ThemesOverviewView extends React.PureComponent {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.ThemesOverviewView)
    private onPin: (themeId: number) => void
    private refetchThemes: () => void
    private bem = new BEM('ThemesOverviewView')

    public render() {
        return (
            <ParamManager<ThemesOverviewFilters> defaultState={{ search: '' }}>
                {({ paramState, setParamState }) => (
                    <div className={this.bem.getClassName()}>
                        <Page>
                            <PageHeader
                                title={this.loc(t => t.title)}
                                breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).legalFramework.index]}
                                actionComponent={this.renderActionComponent(paramState, setParamState)}
                            />
                        </Page>
                        <div className={this.bem.getElement('table-container')}>
                            <Page>
                                <Column bigSpacing>
                                    {this.renderPinnedThemes(paramState)}
                                    {this.renderThemes(paramState)}
                                </Column>
                            </Page>
                        </div>
                    </div>
                )}
            </ParamManager>
        )
    }

    private renderActionComponent(paramState: ThemesOverviewFilters, setParamState: SetParamStateFN) {
        return (
            <Row smallSpacing={true}>
                <Search
                    onChange={search => setParamState({ ...paramState, search: search || '' })}
                    placeholder={this.loc(t => t.searchPlaceholder)}
                    defaultValue={paramState.search}
                />
            </Row>
        )
    }

    private renderPinnedThemes(paramState: ThemesOverviewFilters) {
        return (
            <PinnedThemesQuery filters={paramState}>
                {pinnedThemesOptions => {
                    if (pinnedThemesOptions.loading) {
                        return null
                    }

                    this.onPin = pinnedThemesOptions.pin

                    return (
                        <ThemesOverviewPinnedThemesContainer
                            onUnpin={this.refetchThemes}
                            pinnedThemesOptions={pinnedThemesOptions}
                        />
                    )
                }}
            </PinnedThemesQuery>
        )
    }

    private renderThemes(paramState: ThemesOverviewFilters) {
        return (
            <ThemesOverviewTableQuery filters={paramState}>
                {themeOptions => {
                    if (themeOptions.loading) {
                        return <Spinner delayed={true} />
                    }

                    this.refetchThemes = themeOptions.refetch

                    return <ThemesOverviewTableContainer themesOptions={themeOptions} onPin={this.handlePin} />
                }}
            </ThemesOverviewTableQuery>
        )
    }

    private handlePin = (themeId: number) => {
        if (this.onPin) {
            this.onPin(themeId)
        }

        if (this.refetchThemes) {
            this.refetchThemes()
        }
    }
}
