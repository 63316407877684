import React from 'react'
import { RouteComponentProps } from 'react-router'
import { localize, permissions, notification } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { routes } from '~/views/routes'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Row } from '~/components/Core/Layout/Row'
import { Guard } from '~/components/Core/Guard/Guard'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { Consultant, CONSULTANT_QUERY } from '~/views/Consultant/Organization/OrganizationEmployeesDetailView'
import { MutationFn } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import gql from 'graphql-tag'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { breadcrumbs } from '~/views/breadcrumbs'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

const EDIT_CONSULTANT_MUTATION = gql`
    mutation editConsultant($consultantId: Int!, $fields: EditConsultantFieldsType!) {
        editConsultant(consultantId: $consultantId, fields: $fields) {
            id
            user {
                id
                email
                profile {
                    id
                    firstName
                    lastName
                    fullName
                    avatar
                    phoneNumber
                }
            }
        }
    }
`

export class OrganizationEmployeesEditView extends React.PureComponent<Props> {
    private loc = localize.namespaceTranslate(t => t.Consultant.OrganizationEmployeesEditView)
    private employeeLoc = localize.namespaceTranslate(t => t.Employee)

    public render() {
        const id = parseInt(this.props.match.params.id!, 10)

        return (
            <PageQuery<Consultant> query={CONSULTANT_QUERY} variables={{ consultantId: id }}>
                {consultant => (
                    <Column>
                        <Guard condition={permissions.isCurrentUser(consultant.user.id)}>
                            <PageHeader
                                title={this.loc(t => t.editMyDetails)}
                                breadCrumbs={[
                                    breadcrumbs.consultant().organization.index,
                                    breadcrumbs.consultant().organization.employees.index,
                                ]}
                            />
                        </Guard>

                        <Guard condition={!permissions.isCurrentUser(consultant.user.id)}>
                            <PageHeader
                                documentTitle={'Medewerker bewerken'}
                                title={consultant.user.profile.fullName}
                                breadCrumbs={[
                                    breadcrumbs.consultant().organization.index,
                                    breadcrumbs.consultant().organization.employees.index,
                                ]}
                            />
                        </Guard>
                        <GQLMutation mutation={EDIT_CONSULTANT_MUTATION}>
                            {(mutate, { loading }) => (
                                <Form id={'editEmployee'} onSubmit={this.handleSubmit(mutate)}>
                                    <ErrorMessage path={'editConsultant'} />
                                    <FieldSet>
                                        <Field forInput={'avatar'} label={this.employeeLoc(t => t.attributes.avatar)}>
                                            <Form.AvatarUpload
                                                name={'avatar'}
                                                uri={consultant.user.profile.avatar}
                                                label={consultant.user.profile.fullName}
                                            />
                                        </Field>
                                    </FieldSet>
                                    <FieldSet>
                                        <Field
                                            label={this.employeeLoc(t => t.attributes.firstName)}
                                            forInput={'firstName'}
                                        >
                                            <Form.Input
                                                name={'firstName'}
                                                autoComplete={false}
                                                defaultValue={consultant.user.profile.firstName}
                                            />
                                        </Field>
                                        <Field
                                            label={this.employeeLoc(t => t.attributes.lastName)}
                                            forInput={'lastName'}
                                        >
                                            <Form.Input
                                                name={'lastName'}
                                                autoComplete={false}
                                                defaultValue={consultant.user.profile.lastName}
                                            />
                                        </Field>
                                        <Field
                                            label={this.employeeLoc(t => t.attributes.phoneNumber)}
                                            forInput={'phoneNumber'}
                                        >
                                            <Form.Input
                                                name={'phoneNumber'}
                                                autoComplete={false}
                                                defaultValue={consultant.user.profile.phoneNumber}
                                            />
                                        </Field>
                                        <Field label={this.employeeLoc(t => t.attributes.email)} forInput={'email'}>
                                            <Form.Input
                                                name={'email'}
                                                autoComplete={false}
                                                defaultValue={consultant.user.email}
                                            />
                                        </Field>

                                        <Row smallSpacing={true} alignRight={true}>
                                            <Button
                                                to={routes.consultant.organization.employees.view(id)}
                                                type={ButtonType.secondary}
                                                disabled={loading}
                                            >
                                                {localize.translate(t => t.Generic.cancel)}
                                            </Button>
                                            <Button submit={true} loading={loading}>
                                                {localize.translate(t => t.Generic.save)}
                                            </Button>
                                        </Row>
                                    </FieldSet>
                                </Form>
                            )}
                        </GQLMutation>
                    </Column>
                )}
            </PageQuery>
        )
    }

    private handleSubmit = (mutate: MutationFn) => async (formState: FormState) => {
        const id = parseInt(this.props.match.params.id!, 10)

        const response = await mutate({
            variables: {
                consultantId: id,
                fields: formState,
            },
        })

        if (response && response.data && response.data.editConsultant) {
            notification.success('Succesvol bijgewerkt')
            this.props.history.push(routes.consultant.organization.employees.view(id))
        }
    }
}
