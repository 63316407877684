import React from 'react'
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AllTasksView } from '../Tasks/AllTasksView'

interface Props extends RouteComponentProps<{}> {}
interface State {}

export class TasksView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Switch>
                <Redirect
                    path={routes.customer(this.context.customer.slug).tasks.all}
                    to={routes.customer(this.context.customer.slug).tasks.index}
                    exact={true}
                />
                <Route path={routes.customer(this.context.customer.slug).tasks.index} component={AllTasksView} />
            </Switch>
        )
    }
}
