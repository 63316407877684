import React from 'react'

import { Form } from '~/components/Core/DataEntry/Form/Form'
import { TaskPriority } from '~/graphql/types/Task'
import { PriorityIndicator } from './PriorityIcon/PriorityIndicator'

interface Props {
    name: string
    defaultValue?: TaskPriority
}

interface State {}

export class TaskPrioritySelect extends React.Component<Props, State> {
    public render() {
        const { name, defaultValue } = this.props

        const options = Object.values(TaskPriority).map(priority => {
            return {
                label: <PriorityIndicator priority={priority} />,
                value: priority,
            }
        })

        const defaultSelected = defaultValue
            ? [
                  {
                      label: <PriorityIndicator priority={defaultValue} />,
                      value: defaultValue,
                  },
              ]
            : undefined

        return <Form.Select name={name} options={options} clearable={false} defaultValue={defaultSelected} />
    }
}
