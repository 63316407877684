import './PageDetailMeta.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Paragraph } from '../../Typography/Paragraph'
import { Column } from '../Column'

export interface PageDetailMetaItem {
    label: string
    value: React.ReactNode
    subValue?: React.ReactNode
    elementName?: string
}

interface Props {
    className?: ClassValue
    items: PageDetailMetaItem[]
}

export class PageDetailMeta extends React.PureComponent<Props> {
    private bem = new BEM('PageDetailMeta')

    public render() {
        const { className, items } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {items.map((item, i) => {
                    const elementName = item.elementName ? `container-${item.elementName}` : 'container'

                    return (
                        <Column
                            extraSmallSpacing={true}
                            className={this.bem.getElement(elementName)}
                            key={`${item.label}-${i}`}
                        >
                            <Column smallSpacing={true}>
                                <Paragraph className={this.bem.getElement('label')} bold={true}>
                                    {item.label}
                                </Paragraph>
                                {item.value}
                            </Column>
                            {item.subValue}
                        </Column>
                    )
                })}
            </div>
        )
    }
}
