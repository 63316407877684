import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import {
    EditInboxItemImpactLevelMutation,
    EditInboxItemImpactLevelMutationVariables,
    EditInboxItemImpactLevelDocument,
} from '~/generated/graphql'

export type InboxItemImpactLevelMutationFN = MutationFn<
    EditInboxItemImpactLevelMutation,
    EditInboxItemImpactLevelMutationVariables
>

interface Props {
    children: (mutation: InboxItemImpactLevelMutationFN, options: MutationResult) => JSX.Element
}

export const InboxItemImpactLevelMutation: React.SFC<Props> = ({ children }) => (
    <GQLMutation<EditInboxItemImpactLevelMutation, EditInboxItemImpactLevelMutationVariables>
        mutation={EditInboxItemImpactLevelDocument}
    >
        {(mutate, options) => children(mutate, options)}
    </GQLMutation>
)
