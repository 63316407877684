import React from 'react'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { PdfPreviewModal } from '~/components/Core/Feedback/Modal/PdfPreviewModal'
import { File } from '~/graphql/types/File'
import { FileType } from '~/services/FileService'
import { fileService } from '~/bootstrap'

interface Props {
    file: File
    fileType: FileType
    children: (openFile: () => Promise<void>) => JSX.Element
    modalTitleSuffix?: React.ReactNode
}

interface State {
    filePath: string | null
}

export class FilePreviewModal extends React.Component<Props, State> {
    public state: State = {
        filePath: null,
    }

    public render() {
        const { children, modalTitleSuffix } = this.props
        const { name, mimetype } = this.props.file
        const { filePath } = this.state

        return (
            <ModalManager
                render={openModal => children(this.handleOnDocumentClick(openModal))}
                renderModal={requestclose => (
                    <PdfPreviewModal
                        title={name}
                        viewOnly={true}
                        fileUri={filePath || ''}
                        requestClose={requestclose}
                        titleSuffix={modalTitleSuffix}
                        mimetype={mimetype || undefined}
                    />
                )}
            />
        )
    }

    private handleOnDocumentClick = (openModal: Function) => async () => {
        const { id, name, mimetype } = this.props.file
        const { fileType } = this.props

        if (mimetype !== 'application/pdf') {
            await fileService.viewFile(id, name, fileType)
            return
        }

        const filePath = await fileService.createFileUrl(id, name, fileType)

        if (filePath) {
            this.setState(
                {
                    filePath,
                },
                () => {
                    openModal()
                }
            )
        }
    }
}
