import './WidgetContainer.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { WidgetKey } from '../../CreateWidgetWidget/widgets'
import { WidgetTitle } from './WidgetTitle'
import { WidgetDepartmentsTooltip } from './WidgetDepartmentsTooltip'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { EditWidgetModal } from '../../WidgetModals/EditWidgetModal'
import { DashboardWidgetLayoutTypeEnum } from '~/generated/graphql'

interface Props {
    currentBreakpoint?: DashboardWidgetLayoutTypeEnum
    widgetInfo?: WidgetInfo
    className?: ClassValue
    subtle?: boolean
    blueBackground?: boolean
    isEditing?: boolean
    isEmpty?: boolean
    isDetailWidget?: boolean
    onWidgetClick?: (widgetId: number, type: WidgetKey) => void
    onDeleteIconClick?: (widgetId: number) => void
    onModalStateChange?: ({ isOpening }: { isOpening: boolean }) => void
}

interface WidgetInfo {
    widgetKey: WidgetKey
    widgetId: number
    departments: {
        id: number
        name: string
    }[]
    customName?: string
    allDepartments?: boolean
    hideDepartments?: boolean
    whiteName?: boolean
}

export class WidgetContainer extends React.PureComponent<Props> {
    private isClickable = this.getIsClickable()
    private bem = new BEM('WidgetContainer', () => ({
        'is-empty': this.props.isEmpty,
        subtle: this.props.subtle,
        'has-blue-background': this.props.blueBackground,
        'is-clickable': this.isClickable,
        'is-detail': this.props.isDetailWidget,
    }))

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.isEditing !== this.props.isEditing) {
            this.isClickable = this.getIsClickable()
            this.forceUpdate()
        }
    }

    public render() {
        const { className, children, isEditing } = this.props

        return (
            <div onClick={this.handleWidgetClick} className={this.bem.getClassName(className)}>
                {isEditing && <div className={this.bem.getElement('opaque-layer')} />}
                {this.renderHeader()}
                {children}
                {this.renderActionButtons()}
            </div>
        )
    }

    private getIsClickable() {
        const { onWidgetClick, widgetInfo, isEditing } = this.props

        if (!onWidgetClick || !widgetInfo || isEditing) {
            return false
        }

        const { allDepartments, departments } = widgetInfo
        const isClickable = allDepartments || departments.length > 1

        return isClickable
    }

    private handleWidgetClick = () => {
        if (!this.isClickable) {
            return
        }

        const { onWidgetClick, widgetInfo } = this.props
        if (!onWidgetClick || !widgetInfo) {
            return
        }

        const { widgetId, widgetKey } = widgetInfo
        onWidgetClick(widgetId, widgetKey)
    }

    private renderHeader() {
        const { subtle, blueBackground, widgetInfo, isDetailWidget } = this.props

        if (!widgetInfo) {
            return
        }

        const { customName, widgetKey, allDepartments, departments, whiteName, hideDepartments } = widgetInfo
        const hasWhiteBackground = subtle || blueBackground

        if (isDetailWidget) {
            return (
                <WidgetDepartmentsTooltip
                    whiteBackground={hasWhiteBackground}
                    className={this.bem.getElement('header')}
                    departments={departments}
                />
            )
        }

        return (
            <Row spaceBetween={true} className={this.bem.getElement('header')}>
                <WidgetTitle
                    customName={customName}
                    widgetKey={widgetKey}
                    white={whiteName}
                    className={this.bem.getElement('title')}
                />
                {!hideDepartments && (
                    <WidgetDepartmentsTooltip
                        departments={departments}
                        allDepartments={allDepartments}
                        whiteBackground={hasWhiteBackground}
                        className={this.bem.getElement('departments')}
                    />
                )}
            </Row>
        )
    }

    private renderActionButtons() {
        const { isEditing, onDeleteIconClick, onModalStateChange, widgetInfo } = this.props

        if (!isEditing) {
            return
        }

        let handleDeleteIconClick: () => void

        if (widgetInfo && onDeleteIconClick) {
            const { widgetId } = widgetInfo
            handleDeleteIconClick = () => onDeleteIconClick(widgetId)
        }

        const handleOpenModal = (openModal: () => void) => {
            openModal()

            if (onModalStateChange) {
                onModalStateChange({ isOpening: true })
            }
        }

        return (
            <ModalManager
                renderModal={this.renderEditModal}
                render={openModal => (
                    <Row noSpacing={true} className={this.bem.getElement('buttons')}>
                        <Button
                            type={ButtonType.noStyling}
                            icon={IconType.edit}
                            onClick={() => handleOpenModal(openModal)}
                            className={this.bem.getElement('edit-button')}
                        />
                        <Button
                            type={ButtonType.noStyling}
                            icon={IconType.trashBin}
                            onClick={handleDeleteIconClick}
                            className={this.bem.getElement('delete-button')}
                        />
                    </Row>
                )}
            />
        )
    }

    private renderEditModal = (closeModal: () => void) => {
        const { widgetInfo, currentBreakpoint, onModalStateChange } = this.props

        const handleCloseModal = () => {
            closeModal()

            if (onModalStateChange) {
                onModalStateChange({ isOpening: false })
            }
        }

        if (!widgetInfo) {
            handleCloseModal()
            return
        }

        const { widgetId, customName, departments, allDepartments, widgetKey } = widgetInfo

        const widget = {
            id: widgetId,
            customName: customName,
            departmentIds: departments.length ? departments.map(({ id }) => id) : [],
            forAllDepartments: allDepartments,
            widgetKey,
        }

        return (
            <EditWidgetModal
                layout={currentBreakpoint}
                className={this.bem.getElement('create-modal')}
                widget={widget}
                closeModal={handleCloseModal}
            />
        )
    }
}
