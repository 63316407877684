import './TaskControlSectionTitle.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { TaskSummaryPerMonthType } from '~/generated/graphql'

interface Props {
    className?: ClassValue
    taskSummary: TaskSummaryPerMonthType
}

interface State {}

export class TaskControlSectionTitle extends React.PureComponent<Props, State> {
    private bem = new BEM('TaskControlSectionTitle')
    private loc = localize.namespaceTranslate(t => t.Customer.TaskControl.TaskControlOverview.summary)

    public render() {
        const { taskSummary, className } = this.props

        // Task summary months start at 1
        const date = new Date(taskSummary.year, taskSummary.month - 1)

        return (
            <div className={this.bem.getClassName(className)}>
                <span className={this.bem.getElement('title')}>
                    {localize.dateFormat(date, {
                        readable: true,
                        noDay: true,
                        noWeekday: true,
                    })}
                </span>
                <div className={this.bem.getElement('task-count')}>
                    {this.getOpenTaskText(taskSummary.openTaskCount)}
                    <span className={this.bem.getElement('finished-tasks')}>
                        {this.loc(t => t.totalTasks, {
                            totalTaskCount: taskSummary.completedTaskCount,
                        })}
                    </span>
                </div>
            </div>
        )
    }

    private getOpenTaskText(openTaskCount: number) {
        if (openTaskCount === 1) {
            return this.loc(t => t.openTaskSingle, {
                openTaskCount,
            })
        }

        return this.loc(t => t.openTasks, {
            openTaskCount,
        })
    }
}
