import './ExpandableList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '../../Icon/Icon'
import { IconType } from '../../Icon/IconType'

interface Props {
    className?: ClassValue
    label: JSX.Element | string
    renderList?: () => JSX.Element
    onToggle?: (isOpen: boolean) => void
}

interface State {
    listIsOpen: boolean
}

export class ExpandableList extends React.PureComponent<Props, State> {
    public state: State = {
        listIsOpen: false,
    }

    private bem = new BEM('ExpandableList')

    public render() {
        const { className, label, renderList } = this.props
        const { listIsOpen } = this.state

        return (
            <div className={this.bem.getClassName(className)}>
                <div onClick={this.toggleList} className={this.bem.getElement('label')}>
                    {label}
                    <div className={this.bem.getElement('icon')}>
                        <Icon type={listIsOpen ? IconType.arrowUp : IconType.arrowDown} />
                    </div>
                </div>
                {listIsOpen && <div className={this.bem.getElement('list')}>{renderList && renderList()}</div>}
            </div>
        )
    }

    private toggleList = () => {
        this.setState(
            state => ({
                listIsOpen: !state.listIsOpen,
            }),
            () => this.props.onToggle && this.props.onToggle(this.state.listIsOpen)
        )
    }
}
