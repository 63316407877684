import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import {
    CreateMonitoringReportTopicAssessmentMutation,
    CreateMonitoringReportTopicAssessmentMutationVariables,
    CreateMonitoringReportTopicAssessmentDocument,
} from '~/generated/graphql'

export type CreateMonitoringReportTopicAssessmentFN = MutationFn<
    CreateMonitoringReportTopicAssessmentMutation,
    CreateMonitoringReportTopicAssessmentMutationVariables
>

interface Props {
    children: (mutation: CreateMonitoringReportTopicAssessmentFN, options: MutationResult) => JSX.Element
}

export class CreateMonitoringReportTopicAssessment extends React.PureComponent<Props> {
    public render() {
        return (
            <GQLMutation<
                CreateMonitoringReportTopicAssessmentMutation,
                CreateMonitoringReportTopicAssessmentMutationVariables
            >
                mutation={CreateMonitoringReportTopicAssessmentDocument}
            >
                {(mutate, options) => this.props.children(mutate, options)}
            </GQLMutation>
        )
    }
}
