import './InsetCard.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Card } from '~/components/Core/DataDisplay/Card/Card'

interface Props {
    className?: ClassValue
}

interface State {}

export class InsetCard extends React.PureComponent<Props, State> {
    private bem = new BEM('InsetCard')

    public render() {
        const { children, className } = this.props

        return <Card className={this.bem.getClassName(className)}>{children}</Card>
    }
}
