import React from 'react'
import { Page } from '~/components/Core/Layout/Page'
import { Query } from 'react-apollo'
import { RouteComponentProps } from 'react-router'

import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Row } from '~/components/Core/Layout/Row'
import { routes } from '~/views/routes'
import { Section } from '~/components/Core/Layout/Section'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize, permissions } from '~/bootstrap'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { breadcrumbs } from '~/views/breadcrumbs'
import {
    AssessmentDetailVariables,
    AssessmentDetailResponse,
    GET_ASSESSMENT_DETAIL,
    AssessmentDetail,
} from './AssessmentsDetailView'
import { Guard } from '~/components/Core/Guard/Guard'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {}

export class AssessmentsPropertiesView extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { id } = this.props.match.params

        if (!id) {
            return null
        }

        return (
            <Query<AssessmentDetailResponse, AssessmentDetailVariables>
                query={GET_ASSESSMENT_DETAIL}
                variables={{ id: parseInt(id, 10) }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    if (!data || !data.assessment) {
                        return <NoResults />
                    }

                    const { name, description, useDistinctBrutoRiskValues } = data.assessment
                    const editViewLoc = localize.namespaceTranslate(t => t.Customer.Compliance.Assessments.EditView)

                    return (
                        <>
                            <Page>
                                <PageHeader
                                    title={data.assessment.name}
                                    actionComponent={this.renderAction(data.assessment)}
                                    breadCrumbs={[
                                        breadcrumbs.customer(this.context.customer.slug).risks.index,
                                        breadcrumbs.customer(this.context.customer.slug).risks.assessments.index,
                                        breadcrumbs
                                            .customer(this.context.customer.slug)
                                            .risks.assessments.view(data.assessment.name, id),
                                    ]}
                                />
                                <Section title={localize.translate(t => t.Generic.general)}>
                                    <Field label={localize.translate(t => t.Generic.title)} hasSmallLabelWidth={true}>
                                        <Paragraph>{name}</Paragraph>
                                    </Field>
                                    <Field
                                        label={localize.translate(t => t.Generic.description)}
                                        hasSmallLabelWidth={true}
                                    >
                                        <Paragraph>{description}</Paragraph>
                                    </Field>
                                    <Field label={editViewLoc(t => t.label)} hasSmallLabelWidth={true}>
                                        <Paragraph>
                                            {useDistinctBrutoRiskValues
                                                ? editViewLoc(t => t.distinguish)
                                                : editViewLoc(t => t.doNotDistinguish)}
                                        </Paragraph>
                                    </Field>
                                </Section>
                            </Page>
                        </>
                    )
                }}
            </Query>
        )
    }

    private renderAction(assessment: AssessmentDetail) {
        return (
            <Row alignRight={true}>
                <Guard condition={permissions.canEditAssessment(this.context.activeDepartmentId)}>
                    <Button
                        to={routes.customer(this.context.customer.slug).risks.assessments.edit(assessment.id)}
                        type={ButtonType.secondary}
                    >
                        {localize.translate(t => t.Generic.edit)}
                    </Button>
                </Guard>
            </Row>
        )
    }
}
