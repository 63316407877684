import './RevertIgnoreTaskControlButton.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { ButtonType, Button } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { GQLMutation } from '~/graphql/Mutation'
import {
    RevertIgnoreTaskDocument,
    RevertIgnoreTaskMutation,
    RevertIgnoreTaskMutationVariables,
} from '~/generated/graphql'
import { MutationFn } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { notification, localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    refetch?: Function
    taskId: number
}

interface State {}

export class RevertIgnoreTaskControlButton extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('RevertIgnoreTaskControlButton')

    public render() {
        const { className } = this.props

        return (
            <GQLMutation<RevertIgnoreTaskMutation> mutation={RevertIgnoreTaskDocument}>
                {(mutate, options) => (
                    <Button
                        className={this.bem.getClassName(className)}
                        type={ButtonType.secondary}
                        icon={IconType.revert}
                        onClick={() => this.handleSubmit(mutate)}
                    />
                )}
            </GQLMutation>
        )
    }

    private async handleSubmit(mutate: MutationFn<RevertIgnoreTaskMutation, RevertIgnoreTaskMutationVariables>) {
        const { taskId, refetch } = this.props

        const response = await mutate({
            variables: {
                taskId,
                departmentId: this.context.activeDepartmentId,
            },
        })

        if (response && response.data && response.data.undoIgnoreTask) {
            notification.success(localize.translate(t => t.Generic.successfullyUnarchived))

            if (refetch) {
                refetch()
            }
        }
    }
}
