import React from 'react'
import { ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { LinkedItemsTable } from '../LinkedItems/LinkedItemsTable'
import { CustomerFrameworkType, ItemImpactLevel, TopicType } from '~/generated/graphql'
import { LinkedItemData } from '../LinkedItems/LinkedItemCard'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    className?: ClassValue
    topics: LinkedTopic[]
    wide?: boolean
}

type LinkedTopic = Pick<TopicType, 'id' | 'name'> & {
    customerFrameworks?: CustomerFrameworkType[]
    impact?: ItemImpactLevel | null
}

export class LinkedTopicsTableContainer extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Topic.LinkedTopicList)

    public render() {
        const { className, wide } = this.props

        return (
            <LinkedItemsTable
                title={this.getTitle}
                noItemsTitle={this.loc(t => t.noItems)}
                tableItems={this.getItems()}
                className={className}
                wide={wide}
            />
        )
    }

    private getTitle = (isOpen: boolean) => {
        const { topics } = this.props

        const part1 = this.loc(t => (isOpen ? t.hide : t.open))
        const part2 = this.loc(t => t.linkedTopics, { smart_count: topics.length })

        return `${part1}${part2}`
    }

    private getItems(): LinkedItemData[] {
        const { topics } = this.props

        return topics.map(t => ({ ...t, to: this.getLink(t.id) }))
    }

    private getLink(id: number) {
        return routes.customer(this.context.customer.slug).legalFramework.topic.view(id)
    }
}
