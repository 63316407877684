import './OrganizationEmployeesOverviewView.scss'

import React from 'react'
import { localize, notification } from '~/bootstrap'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { TextLink } from '~/components/Core/Text/TextLink'
import { Column } from '~/components/Core/Layout/Column'
import { PageActions } from '~/components/Core/Layout/PageActions'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { Button } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { routes } from '~/views/routes'
import { PageOffsetPadding } from '~/components/Core/Layout/PageOffsetPadding'
import { StickyFooter } from '~/components/Core/Layout/StickyFooter'
import { MediaQuery, Screensize } from '~/components/Core/Layout/MediaQuery'
import { BEM } from '~/services/BEMService'
import { TableList } from '~/components/Core/DataDisplay/TableList/TableList'
import { TableListItem } from '~/components/Core/DataDisplay/TableList/TableListItem'
import gql from 'graphql-tag'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { Table } from '~/components/Core/DataDisplay/Table/Table'
import { Organization } from './OrganizationProfileDetailView'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationFn } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { UserStatus } from '~/graphql/types/User'
import { UserStatusDisplay } from '~/components/Domain/User/UserStatusDisplay'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { breadcrumbs } from '~/views/breadcrumbs'
import { ParamManager } from '~/components/Core/ParamManager/ParamManager'

const LIST_CONSULTANTS_QUERY = gql`
    query consultants($skip: Int, $take: Int, $filters: ConsultantsFiltersType) {
        consultants(skip: $skip, take: $take, filters: $filters) {
            hasNextPage
            nodes {
                id
                customerCount
                user {
                    id
                    lastLoginAt
                    invitedAt
                    email
                    status
                    profile {
                        id
                        fullName
                    }
                }
            }
        }
    }
`

export interface Consultant {
    id: number
    customerCount: number
    organization: Organization
    user: {
        id: number
        email: string
        lastLoginAt: string
        invitedAt: string
        status: UserStatus
        profile: {
            id: number
            fullName: string
        }
    }
}

interface Props extends RouteComponentProps<{}> {}

interface State {}

interface Filters {
    search: string | null
}

const CREATE_CONSULTANT_MUTATION = gql`
    mutation createConsultant($fields: CreateConsultantFieldsType!) {
        createConsultant(fields: $fields) {
            id
        }
    }
`

interface MutationResponse {
    createConsultant: {
        id: number
    } | null
}

interface MutationVariables {
    fields: {
        firstName: string
        lastName: string
        email: string
    }
}

export class OrganizationEmployeesOverviewView extends React.Component<Props, State> {
    public state: State = {
        search: null,
    }

    private bem = new BEM('OrganizationEmployeesOverviewView')
    private loc = localize.namespaceTranslate(t => t.Consultant.OrganizationEmployeesOverviewView)

    public render() {
        return (
            <ParamManager<Filters>
                defaultState={{
                    search: null,
                }}
            >
                {({ paramState, setParamState }) => {
                    const { search } = paramState

                    return (
                        <InfiniteScrollQuery<Consultant>
                            query={LIST_CONSULTANTS_QUERY}
                            variables={{
                                filters: { search: search },
                            }}
                        >
                            {({ data, loading }) => {
                                if (!data) {
                                    return null
                                }

                                return (
                                    <Column className={this.bem.getClassName()}>
                                        <PageHeader
                                            title={this.loc(t => t.title)}
                                            breadCrumbs={[breadcrumbs.consultant().organization.index]}
                                        />
                                        <PageActions
                                            primaryComponent={
                                                <Search
                                                    defaultValue={search}
                                                    placeholder={localize.translate(t => t.Generic.search.byName)}
                                                    onChange={search => setParamState({ search })}
                                                />
                                            }
                                            secondaryComponent={
                                                <MediaQuery breakpoint={Screensize.tablet}>
                                                    {this.renderModal()}
                                                </MediaQuery>
                                            }
                                        />

                                        <MediaQuery breakpoint={Screensize.mobile}>
                                            {this.renderTableList(data.nodes, loading)}
                                        </MediaQuery>
                                        <MediaQuery breakpoint={Screensize.tablet}>
                                            {this.renderTable(data.nodes, loading)}
                                        </MediaQuery>

                                        <MediaQuery breakpoint={Screensize.mobile}>
                                            <PageOffsetPadding className={this.bem.getElement('sticky-footer')}>
                                                <StickyFooter>{this.renderModal()}</StickyFooter>
                                            </PageOffsetPadding>
                                        </MediaQuery>
                                    </Column>
                                )
                            }}
                        </InfiniteScrollQuery>
                    )
                }}
            </ParamManager>
        )
    }

    private renderTable(data: Consultant[], loading: boolean) {
        const tableData = data.map((consultant: Consultant) => ({
            id: consultant.id,
            columns: {
                name: (
                    <TableLink to={routes.consultant.organization.employees.view(consultant.id)}>
                        {consultant.user.profile.fullName}
                    </TableLink>
                ),
                customers: localize.translate(t => t.Consultant.OrganizationEmployeesOverviewView.customerCount, {
                    count: localize.numberFormat(consultant.customerCount),
                    smart_count: consultant.customerCount,
                }),
                status: <UserStatusDisplay user={consultant.user} />,
                last_login: consultant.user.lastLoginAt ? (
                    <DateFormat date={new Date(consultant.user.lastLoginAt)} includeTime={true} readable={true} />
                ) : (
                    this.loc(t => t.noLastlogin)
                ),
            },
        }))

        return (
            <Table
                defaultSortDirection={{ field: 'last_login', direction: 'DESC' }}
                loading={loading}
                columns={[
                    { field: 'name', headerLabel: localize.translate(t => t.User.attributes.name) },
                    { field: 'customers', headerLabel: this.loc(t => t.customers) },
                    {
                        field: 'status',
                        headerLabel: localize.translate(t => t.User.attributes.status),
                        noAutoWidth: true,
                    },
                    { field: 'last_login', headerLabel: this.loc(t => t.lastLogin), noAutoWidth: true, sortable: true },
                ]}
                data={tableData}
            />
        )
    }

    private renderTableList(data: Consultant[], loading: boolean) {
        return (
            <TableList loading={loading}>
                {data.map((consultant: Consultant) => (
                    <TableListItem key={consultant.user.id}>
                        <TextLink to={routes.consultant.organization.employees.view(consultant.user.id)}>
                            {consultant.user.profile.fullName}
                        </TextLink>
                    </TableListItem>
                ))}
            </TableList>
        )
    }

    private renderModal() {
        return (
            <ModalManager
                render={requestOpen => (
                    <Button icon={IconType.add} onClick={requestOpen}>
                        {localize.translate(t => t.Employee.attributes.addNewEmployee)}
                    </Button>
                )}
                renderModal={closeModal => (
                    <GQLMutation<MutationResponse, MutationVariables> mutation={CREATE_CONSULTANT_MUTATION}>
                        {(mutate, { loading }) => (
                            <Form onSubmit={this.handleOnAddNewEmployeeSubmit(mutate)}>
                                <Modal
                                    requestClose={closeModal}
                                    title={localize.translate(t => t.Employee.attributes.newEmployee)}
                                    loading={loading}
                                    submitForm={true}
                                    confirmButtonLabel={localize.translate(t => t.Generic.saveAndInvite)}
                                >
                                    <ErrorMessage path={'createConsultant'} />
                                    <Field
                                        label={localize.translate(t => t.Employee.attributes.firstName)}
                                        forInput={'firstName'}
                                    >
                                        <Form.Input
                                            name={'firstName'}
                                            type={'text'}
                                            placeholder={localize.translate(t => t.Employee.attributes.firstName)}
                                            autoComplete={false}
                                        />
                                    </Field>
                                    <Field
                                        label={localize.translate(t => t.Employee.attributes.lastName)}
                                        forInput={'lastName'}
                                    >
                                        <Form.Input
                                            name={'lastName'}
                                            type={'text'}
                                            placeholder={localize.translate(t => t.Employee.attributes.lastName)}
                                            autoComplete={false}
                                        />
                                    </Field>
                                    <Field
                                        label={localize.translate(t => t.Employee.attributes.email)}
                                        forInput={'email'}
                                    >
                                        <Form.Input
                                            name={'email'}
                                            type={'email'}
                                            placeholder={localize.translate(t => t.Employee.attributes.email)}
                                            autoComplete={true}
                                        />
                                    </Field>
                                </Modal>
                            </Form>
                        )}
                    </GQLMutation>
                )}
            />
        )
    }

    private handleOnAddNewEmployeeSubmit = (mutate: MutationFn<MutationResponse, MutationVariables>) => async (
        formState: FormState
    ) => {
        const { firstName, lastName, email } = formState

        const response = await mutate({
            variables: {
                fields: {
                    firstName,
                    lastName,
                    email,
                },
            },
        })

        if (response && response.data && response.data.createConsultant) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))
            this.props.history.push(routes.consultant.organization.employees.view(response.data.createConsultant.id))
        }
    }
}
