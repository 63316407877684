import './SignalingTitle.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { routes } from '~/views/routes'
import { NewsItemTag, NewsItemTagEnum } from '~/components/Domain/Signaling/NewsItemTag'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { NewsItemType, TopicType } from '~/generated/graphql'
import { localize } from '~/bootstrap'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    className?: ClassValue
    newsItem: Pick<NewsItemType, 'id' | 'title' | 'publicationDate'> & {
        suggestedTopics?: SuggestedTopic[] | null
        publishedStatus?: PublishedStatus | null
        sources?: IdAndName[] | null
    }
    forModal?: true
    showTooltip?: true
    tags?: JSX.Element
}

type SuggestedTopic = Pick<TopicType, 'id' | 'name'>
type PublishedStatus = {
    departments?: IdAndName[] | null
    topics: IdAndName[]
}

type IdAndName = { id: number; name?: string | null }

interface State {}

export class SignalingTitle extends React.PureComponent<Props, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('SignalingTitle', () => ({
        'is-for-modal': !!this.props.forModal,
    }))

    public render() {
        const { className, newsItem } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {this.renderTitle()}
                <Row smallSpacing className={this.bem.getElement('meta')}>
                    {this.renderTags()}
                    {this.renderDate(newsItem.publicationDate)}
                </Row>
            </div>
        )
    }

    private renderTitle() {
        const { forModal, newsItem, showTooltip } = this.props

        if (forModal) {
            return
        }

        if (showTooltip) {
            return (
                <Tooltip message={newsItem.title} className={this.bem.getElement('tooltip')}>
                    <ComponentTitle
                        to={routes.customer(this.context.customer.slug).signaling.detail.view(newsItem.id)}
                        title={newsItem.title}
                        bold={true}
                        className={this.bem.getElement('title')}
                    />
                </Tooltip>
            )
        }

        return (
            <ComponentTitle
                to={routes.customer(this.context.customer.slug).signaling.detail.view(newsItem.id)}
                title={newsItem.title}
                bold={true}
                className={this.bem.getElement('title')}
            />
        )
    }

    private renderTags() {
        if (this.props.tags) {
            return this.props.tags
        }

        const { newsItem, forModal } = this.props
        const { suggestedTopics, publishedStatus, sources } = newsItem
        const tags = []

        if (!forModal && suggestedTopics?.length) {
            suggestedTopics.forEach(({ id, name }) => {
                tags.push(
                    <NewsItemTag
                        key={`suggestedTopic-${id}`}
                        className={this.bem.getElement('tag')}
                        label={name}
                        type={NewsItemTagEnum.suggestedTopic}
                    />
                )
            })
        }

        if (publishedStatus) {
            publishedStatus.departments?.forEach(department => {
                tags.push(
                    <NewsItemTag
                        key={`department-${department.id}`}
                        className={this.bem.getElement('tag')}
                        label={department.name || ''}
                        type={NewsItemTagEnum.department}
                    />
                )
            })

            publishedStatus.topics.forEach(topic => {
                tags.push(
                    <NewsItemTag
                        key={`topic-${topic.id}`}
                        className={this.bem.getElement('tag')}
                        label={topic.name || ''}
                        type={NewsItemTagEnum.topic}
                    />
                )
            })
        }

        if (sources?.length) {
            if (sources.length === 1 && sources[0].name) {
                tags.push(
                    <NewsItemTag
                        key={`source-${sources[0].id}`}
                        className={this.bem.getElement('tag')}
                        label={sources[0].name}
                    />
                )
            } else {
                tags.push(
                    <NewsItemTag
                        key={`source-${sources[0].id}`}
                        className={this.bem.getElement('tag')}
                        label={localize.translate(t => t.News.Attributes.sources, { count: sources.length })}
                        tooltipContent={sources.map(s => (
                            <div key={s.id}>
                                <Paragraph>{s.name}</Paragraph>
                            </div>
                        ))}
                    />
                )
            }
        }

        return tags
    }

    private renderDate(date?: Date | null) {
        if (!date) {
            return null
        }

        return (
            <Paragraph className={this.bem.getElement('date')} subtle={true}>
                <DateFormat date={new Date(date)} readable={true} includeTime={true} noWeekday={true} />
            </Paragraph>
        )
    }
}
