import { prefixWithAppPrefix } from '~/config'

export enum LocalStorageKeys {
    Language = 'Language',
    CustomerFrameworkFilter = 'CustomerFrameworkFilter',
    DepartmentFilter = 'DepartmentFilter',
    SessionToken = 'SessionToken',
    SessionUserId = 'SessionUser',
    RadarDotPosition = 'RadarDotPosition',
    SawCardAnimation = 'SawCardAnimation',
    UserLoginEmail = 'UserEmail',
    LastSuccessfulSSOLoginEmail = 'LastSuccessfulSSOLoginEmail',
}

export class LocalStorageService {
    /**
     * Set an item to local storage
     *
     * @param key
     */
    public static setItem(key: LocalStorageKeys, value: string) {
        window.localStorage.setItem(prefixWithAppPrefix(key), value)
    }

    /**
     * Get an item from local storage
     *
     * @param key
     */
    public static getItem(key: LocalStorageKeys): string | null {
        return window.localStorage.getItem(prefixWithAppPrefix(key))
    }

    /**
     * Remove an item to local storage
     *
     * @param key
     */
    public static removeItem(key: LocalStorageKeys) {
        window.localStorage.removeItem(prefixWithAppPrefix(key))
    }

    public static clear() {
        window.localStorage.clear()
    }
}
