import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { localize, notification } from '~/bootstrap'
import { FormState } from '~/components/Core/DataEntry/Form/Form'
import {
    EditMonitoringReportType,
    EditMonitoringReportVariables,
    MonitoringRapportEditMutation as MonitoringReportEditMutation,
} from '~/components/Domain/Monitoring/MonitoringReportEditForm/MonitoringReportEditMutation'
import { MonitoringReportDetailType } from '../MonitoringReportsDetail/MonitoringReportQuery'
import { MonitoringReportEditForm } from './MonitoringReportEditForm'

interface Props {
    monitoringReport: MonitoringReportDetailType
    onSubmitSuccess: () => void
}

export class MonitoringReportEditFormContainer extends React.PureComponent<Props, {}> {
    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.MonitoringEditView)

    public render() {
        const { monitoringReport } = this.props

        return (
            <MonitoringReportEditMutation>
                {(editMonitoringRapport, editOptions) => {
                    return (
                        <MonitoringReportEditForm
                            onSubmit={formState => this.handleSubmit(formState, editMonitoringRapport, editOptions)}
                            monitoringReport={monitoringReport}
                            editOptions={editOptions}
                        />
                    )
                }}
            </MonitoringReportEditMutation>
        )
    }

    private handleSubmit = async (
        formState: FormState,
        editMonitoringReport: MutationFn<EditMonitoringReportType, EditMonitoringReportVariables>,
        editOptions: MutationResult<EditMonitoringReportType>
    ) => {
        const { onSubmitSuccess, monitoringReport } = this.props
        const variables = {
            variables: {
                monitoringReportId: monitoringReport.id,
                fields: {
                    file: formState.file,
                    name: formState.name,
                },
            },
        }

        await editMonitoringReport(variables)

        if (!editOptions.error) {
            notification.success(this.loc(t => t.successEdit))
            onSubmitSuccess()
        }
    }
}
