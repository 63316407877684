import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import {
    UnarchiveMonitoringReportsDocument,
    UnarchiveMonitoringReportsMutation,
    UnarchiveMonitoringReportsMutationVariables,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

interface Props {
    children: (mutation: UnarchiveMonitoringReportsMutationFN, options: MutationResult) => JSX.Element
}

export type UnarchiveMonitoringReportsMutationFN = MutationFn<
    UnarchiveMonitoringReportsMutation,
    UnarchiveMonitoringReportsMutationVariables
>

export const UnarchiveMonitoringReports: React.SFC<Props> = ({ children }) => (
    <GQLMutation<UnarchiveMonitoringReportsMutation, UnarchiveMonitoringReportsMutationVariables>
        mutation={UnarchiveMonitoringReportsDocument}
    >
        {(mutate, options) => children(mutate, options)}
    </GQLMutation>
)
