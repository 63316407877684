import './CustomerFrameworkRadio.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '~/components/Core/Icon/Icon'
import { CustomerFrameworkColorEnumType, CustomerFrameworkIconEnumType } from '~/generated/graphql'

export interface RadioItem {
    color?: CustomerFrameworkColorEnumType
    icon?: CustomerFrameworkIconEnumType
}

interface Props {
    className?: ClassValue
    defaultOption?: RadioItem
    options: RadioItem[]
    name: string
    optionType: 'color' | 'icon'
    onChange: (value: RadioItem | null, name: string) => void
}

interface State {
    selectedOption?: RadioItem
}

export class CustomerFrameworkRadio extends React.PureComponent<Props, State> {
    public state: State = {
        selectedOption: this.props.defaultOption,
    }

    private bem = new BEM('CustomerFrameworkRadio', () => ({
        [`option-type-${this.props.optionType}`]: !!this.props.optionType,
    }))

    public render() {
        const { className } = this.props

        return <div className={this.bem.getClassName(className)}>{this.renderRadioOptions()}</div>
    }

    private renderRadioOptions() {
        const { options, optionType, name } = this.props
        const { selectedOption } = this.state

        return options.map((option, index) => {
            const isSelected = selectedOption && selectedOption[optionType] === option[optionType]

            return (
                <div
                    key={name + index}
                    className={this.bem.getElement('radio-item', () => ({
                        'is-selected': isSelected,
                        'is-not-selected': !isSelected,
                        [`color-${option.color}`]: !!option.color,
                        [`icon-${option.icon}`]: !!option.icon,
                    }))}
                >
                    <input
                        id={`${name}-${option[optionType]}`}
                        onChange={this.handleOnChange(option)}
                        defaultChecked={isSelected}
                        type="radio"
                        name={name}
                    />
                    <label htmlFor={`${name}-${option[optionType]}`}>
                        <div className={this.bem.getElement('swatch')} />
                        {option.icon && <Icon className={this.bem.getElement('icon')} type={option.icon} />}
                    </label>
                </div>
            )
        })
    }

    private handleOnChange = (option: RadioItem) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange, name } = this.props

        this.setState({ selectedOption: option }, () => {
            onChange(option, name)
        })
    }
}
