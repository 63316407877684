import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { DepartmentSwitchPopup } from '~/components/Domain/Department/DepartmentSwitchPopup'
import { Switch, Route, Redirect } from 'react-router'
import { routes } from '~/views/routes'
import { LawArticleRedirectView } from '~/views/Customer/LawArticleRedirectView'
import { DashboardView } from '~/views/Customer/Dashboard/DashboardView'
import { SettingsView } from '~/views/Customer/Settings/SettingsView'
import { LegalFrameworkView } from '~/views/Customer/LegalFramework/LegalFrameworkView'
import { RadarView } from '~/views/Customer/Radar/RadarView'
import { ConsultationsView } from '~/views/Customer/Consultations/ConsultationsView'
import { ComplianceView } from './Compliance/ComplianceView'
import { InboxView } from '~/views/Customer/Inbox/InboxView'
import { NewsView } from '~/views/Customer/News/NewsView'
import { LawAndRegulationView } from '~/views/Customer/lawAndRegulation/LawAndRegulationView'
import { SearchView } from '~/views/Customer/Search/SearchView'
import { KitchensinkView } from '~/views/Customer/Kitchensink/KitchensinkView'
import { StyleguideView } from '~/views/Customer/Styleguide/StyleguideView'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { SignalingView } from './Signaling/SignalingView'
import { ControlView } from './Control/ControlView'
import { RisksView } from './Risks/RisksView'
import { TasksView } from './Tasks/TasksView'
import { MonitoringView } from './Monitoring/MonitoringView'
interface Props {
    customer: any
    history: any
    location: any
}

export class CustomerRoutes extends React.Component<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { customer } = this.props
        const params = new URLSearchParams(this.props.location.search)

        const departmentId = params.get('departmentId')
        const department = this.context.departments.find(({ id }) => id === Number(departmentId))

        if (department && this.context.activeDepartmentId !== department.id) {
            return (
                <DepartmentSwitchPopup
                    departmentIdToToggleTo={department.id}
                    departmentNameToToggleTo={department.name}
                />
            )
        }

        return (
            <Switch>
                <Route path={routes.customer(customer).lawAndRegulationRedirect()} component={LawArticleRedirectView} />
                <Route path={routes.customer(customer).dashboard.index} component={DashboardView} />
                <Route path={routes.customer(customer).settings.index} component={SettingsView} />
                <Route path={routes.customer(customer).legalFramework.index} component={LegalFrameworkView} />
                <Route path={routes.customer(customer).radar.index} component={RadarView} />
                <Route path={routes.customer(customer).radar.index} component={DashboardView} />
                <Route path={routes.customer(customer).monitoring.index} component={MonitoringView} />
                <Route path={routes.customer(customer).consultations.index} component={ConsultationsView} />
                <Route path={routes.customer(customer).compliance.index} component={ComplianceView} />
                <Route path={routes.customer(customer).risks.index} component={RisksView} />
                <Route path={routes.customer(customer).inbox.index} component={InboxView} />
                <Route path={routes.customer(customer).tasks.index} component={TasksView} />
                <Route path={routes.customer(customer).news.index} component={NewsView} />
                <Route path={routes.customer(customer).lawAndRegulation.index} component={LawAndRegulationView} />
                <Route path={routes.customer(customer).search.index} component={SearchView} />
                <Route path={routes.customer(customer).signaling.index} component={SignalingView} />
                <Route path={routes.customer(customer).kitchensink.index} component={KitchensinkView} />
                <Route path={routes.customer(customer).styleguide.index} component={StyleguideView} />
                <Route path={routes.customer(customer).control.index} component={ControlView} />
                <Redirect exact={true} path={routes.customer().index} to={routes.customer(customer).dashboard.index} />
                <Route component={AppNotFoundView} />
            </Switch>
        )
    }
}
